import { hourMinuteFormatter } from '@/constants/formats';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useContext, useMemo } from 'react';
import { Context } from '../store';
import { CardHeaderLine, CardHeaderText, CardLine, CardText, ListCard } from './blocks';
import { css } from '@emotion/react';
import { OnwardLink } from '@/styles/blocks';

const SupplyRouteCard = ({ route }) => {
    const { state, callbacks } = useContext(Context);

    const carrier = route.external_carrier_name || route.external_carrier_id;
    const EyeIcon = state.hiddenRoutes[route.route_id] ? VisibilityOff : Visibility;
    const linkText = state.routeStopsOpen?.route_id === route.route_id ? 'Hide Stops' : 'View Stops';

    const routeTime = useMemo(() => {
        const startTime = route.activity_stops[0]?.arrival_time;
        const lastStop = route.activity_stops[route.activity_stops.length - 1];
        const endTime =
            lastStop?.arrival_time && lastStop?.service_time
                ? new Date(lastStop.arrival_time).getTime() + lastStop.service_time
                : lastStop?.timeframe_end;

        if (startTime && endTime) {
            const diff = new Date(endTime).getTime() - new Date(startTime).getTime();
            const minutes = diff / 60000;
            return hourMinuteFormatter.format(minutes);
        }

        return null;
    }, [route]);

    return (
        <ListCard
            clickable={true}
            selected={state.selectedRoute?.route_id === route.route_id}
            onClick={() => callbacks.selectRoute(route)}
        >
            <CardHeaderLine>
                <CardHeaderText>RX-{route.route_id.substring(0, 6)}</CardHeaderText>
                <IconButton
                    color="primary"
                    css={css`
                        padding: 0;
                    `}
                >
                    <EyeIcon
                        onClick={(e) => {
                            e.stopPropagation();
                            callbacks.hideRoute(route.route_id);
                        }}
                    />
                </IconButton>
            </CardHeaderLine>
            <CardLine>
                <CardText>Carrier: {carrier}</CardText>
            </CardLine>
            <CardLine>
                <CardText>External ID: {route.external_route_id}</CardText>
            </CardLine>
            <CardLine>
                <CardText>
                    {route.activity_stops[0]?.city} - {route.activity_stops[route.activity_stops.length - 1]?.city}
                </CardText>
            </CardLine>
            <CardLine>
                <CardText>{route.activity_stops.length} Stops</CardText>
            </CardLine>
            <CardLine>
                <CardText>Total Capacity: {route.capacity} cu ft.</CardText>
            </CardLine>
            <CardLine>
                <CardText>Est Route Time: {routeTime || 'N/A'}</CardText>
            </CardLine>
        </ListCard>
    );
};

export default SupplyRouteCard;
