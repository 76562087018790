import React, { useEffect, useRef } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { NoopAlgorithm } from './NoopAlgorithm';

const renderer = (color) => {
    return {
        render: ({ count, position }) => {
            const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                <circle cx="120" cy="120" stroke="white" stroke-width="4" opacity=".6" r="70" />
            </svg>`;

            return new window.google.maps.Marker({
                position,
                icon: {
                    url: `data:image/svg+xml;base64,${btoa(svg)}`,
                    scaledSize: new window.google.maps.Size(60, 60),
                },
                label: {
                    text: count.toString(),
                    color: 'white',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                },
                zIndex: 500 + count,
            });
        },
    };
};

function MarkerCluster({ map, markers = [], color = 'black', disabled = false, callbacks }) {
    const cluster = useRef(null);

    useEffect(() => {
        if (map.current && (!cluster.current || cluster.current.noop !== disabled)) {
            cluster.current = new MarkerClusterer({
                map: map.current,
                markers: markers,
                renderer: renderer(color),
                onClusterClick: callbacks.onClick ? callbacks.onClick : undefined,
                ...(disabled
                    ? {
                          algorithm: new NoopAlgorithm(),
                      }
                    : {}),
            });

            cluster.current.noop = disabled;
        }
    }, [map.current, disabled]);

    useEffect(() => {
        if (map.current && cluster.current) {
            cluster.current.clearMarkers(true);

            cluster.current.addMarkers(markers);
        }
    }, [map, markers]);

    return null;
}

export default MarkerCluster;
