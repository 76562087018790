import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { useSubscription, useMutation, useQuery } from '@apollo/client';
import { CARRIER_QUERY } from '../../graphql/queries/clients';
import withAdminRights from '../Auth/withAdminRights';
import withErrorBoundary from '../ErrorBoundary';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

function _CarrierAutocomplete({handleCarrierSelect, selectedCarrier, disabled, ...props}) {
    // const { handleCarrierSelect, selectedCarrier, disabled } = props;
    const [carriers, setCarriers] = useState([]);
    const [carrierInput, setCarrierInput] = useState("");
    const { loading, error, data } = useQuery(CARRIER_QUERY, {
        onCompleted: (data) => {
            setCarriers(data.clients);
        },
        onError: (error) => {
            console.log(error);
        },
    });
    const handleSelect = (e, newVal) => {
        const carrier = carriers.find((carrier) => carrier.client_id === newVal);
        if (carrier) {
            handleCarrierSelect(carrier);
        }
    };
    const getCarrierName = (client_id) => {
        const carrier = carriers.find((carrier) => carrier.client_id === client_id);
        return carrier ? carrier.business_name : '';
    };

    return (
        <Autocomplete
            options={carriers.map((carrier) => carrier.client_id)}
            value={selectedCarrier}
            defaultValue={selectedCarrier}
            onChange={handleSelect}
            inputValue={carrierInput}
            onInputChange={(event, newValue) => setCarrierInput(newValue)}
            getOptionLabel={(option) => getCarrierName(option)}
            clearOnBlur
            autoSelect
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Select Carrier"
                    label="Carrier"
                    InputLabelProps={{
                        shrink: !!selectedCarrier || !!carrierInput,
                    }}
                />
            )}
            fullWidth
            style={{ width: '400px' }}
            {...props}
        />
    );
}
const CarrierAutocomplete = compose(withAdminRights, withErrorBoundary)(_CarrierAutocomplete);

CarrierAutocomplete.propTypes = {
    handleCarrierSelect: PropTypes.func.isRequired,
    /** Database key of selected carrier */
    selectedCarrier: PropTypes.string.isRequired,
};
export default CarrierAutocomplete;
