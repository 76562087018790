import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Grid } from '@material-ui/core';
import { ResponsiveSidebarDialog, StickyModalActions, ModalContent } from '@/styles/blocks';
import { getTimezoneOffset } from 'date-fns-tz';
import { LocalizationProvider, StaticDatePicker, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const DatePickerModal = ({
    handleClose,
    open,
    orderTZ,
    disabled,
    label,
    onChange,
    selectedDates,
    maxSelections,
    minDate,
}) => {
    //This input allows for multiple date selection (if selectedDates value is an array of date strings & maxSelections > 1,
    // or normal single date selection (maxSelections===1, selectedDate is isoString))

    const convertedSelectedDates = useMemo(() => {
        if (!selectedDates) return [];
        let _converted;
        if (!Array.isArray(selectedDates)) {
            _converted = [selectedDates];
        } else _converted = selectedDates;
        return _converted;
    }, [selectedDates]);

    return (
        <ResponsiveSidebarDialog open={open} onClose={() => handleClose()}>
            <ModalContent
                css={css`
                    padding: 0 !important;
                    width: 100%;
                `}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                        sx={{ width: '100%' }}
                        orientation="portrait"
                        label={label}
                        minDate={minDate}
                        timezone={orderTZ}
                        disabled={disabled}
                        value={convertedSelectedDates[0] ? new Date(convertedSelectedDates[0]) : null}
                        onChange={(date) => {
                            let dateISO = date.toISOString();
                            let _selectedDates = (
                                Array.isArray(selectedDates) ? [...selectedDates] : [selectedDates]
                            ).filter((e) => !!e);

                            if (_selectedDates.includes(dateISO)) {
                                _selectedDates = _selectedDates.filter((date) => date !== dateISO);
                            } else {
                                if (_selectedDates.length < maxSelections) {
                                    _selectedDates.push(dateISO);
                                } else {
                                    _selectedDates.shift();
                                    _selectedDates.push(dateISO);
                                }
                            }

                            if (maxSelections === 1) {
                                onChange(_selectedDates[0]);
                            } else {
                                onChange(_selectedDates);
                            }
                        }}
                        closeOnSelect={false}
                        disableHighlightToday={true}
                        slotProps={{
                            toolbar: { hidden: true },
                            actionBar: { hidden: true },
                        }}
                        slots={{
                            day: (props) => {
                                const isSelected = convertedSelectedDates.includes(props.day.toISOString());

                                return (
                                    <PickersDay
                                        style={{
                                            backgroundColor: isSelected ? '#71B77A' : 'white',
                                            color: isSelected && 'white',
                                            borderRadius: '50%',
                                        }}
                                        {...props}
                                    />
                                );
                            },
                        }}
                    />
                </LocalizationProvider>
            </ModalContent>
            <StickyModalActions>
                <Grid container direction="column">
                    <Grid container item justifyContent="center">
                        <PrimaryButton onClick={() => handleClose()}>Done</PrimaryButton>
                    </Grid>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default DatePickerModal;
