import { ROUTE_FIELDS, ROUTE_ORDER_FIELDS } from '@/graphql/queries/routes';
import { ORDER_ROUTE_FIELDS } from '@/graphql/queries/orders';
import { STOP_FIELDS } from '@/graphql/queries/stops';
import { gql } from '@apollo/client';

export const UPDATE_ROUTE = gql`
    ${ROUTE_FIELDS}
    ${ROUTE_ORDER_FIELDS}
    ${ORDER_ROUTE_FIELDS}
    ${STOP_FIELDS}
    mutation UpdateRoute($route_id: uuid!, $route_update: routes_set_input!, $order_updates: [orders_updates!] = []) {
        update_routes(where: { route_id: { _eq: $route_id } }, _set: $route_update) {
            returning {
                ...RouteFields
                ...RouteOrderFields
                stopsByRouteId {
                    ...StopFields
                }
            }
        }

        update_orders_many(updates: $order_updates) {
            returning {
                ...OrderFields
                ...OrderRouteFields
                itemsByOrderId {
                    ...ItemFields
                }
                items @client {
                    ...ItemFields
                }
                returns @client {
                    ...ItemFields
                }
            }
        }
    }
`;
