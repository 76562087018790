import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { gql } from '@apollo/client';

export const UPDATE_CLIENT_BY_ID = gql`
    ${CLIENT_FIELDS}
    mutation UpdateClientById($client_id: uuid!, $update: clients_set_input!) {
        update_clients(where: { client_id: { _eq: $client_id } }, _set: $update) {
            affected_rows
            returning {
                ...ClientFields
            }
        }
    }
`;
