import { gql } from '@apollo/client';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { STOP_FIELDS } from '@/graphql/queries/stops';
import { ROUTE_FIELDS } from '@/graphql/queries/routes';
import { USER_FIELDS } from '@/graphql/queries/users';
import { TEAMMATE_FIELDS } from '@/graphql/queries/teammates';
import { ORDER_ROUTE_MAPPINGS } from '@/graphql/queries/order_route_mappings';

export const QUERY_BY_ID = gql`
    ${ORDER_FIELDS}
    ${CLIENT_FIELDS}
    ${USER_FIELDS}
    ${ITEM_FIELDS}
    ${ROUTE_FIELDS}
    ${STOP_FIELDS}
    ${ORDER_ROUTE_MAPPINGS}

    query OrdersByOrderID($where: orders_bool_exp = {}) {
        results: orders(where: $where) {
            ...OrderFields
            payment_id
            order_shipper {
                ...ClientFields
                user {
                    ...UserFields
                }
            }
            order_carrier {
                ...ClientFields
                user {
                    ...UserFields
                }
            }
            itemsByOrderId {
                ...ItemFields
            }
            routes(order_by: { created_at: desc }) {
                mapping_id
                order_id
                route_id
                type
                created_at
                route {
                    ...RouteFields
                    stopsByRouteId {
                        ...StopFields
                    }
                    orders {
                        ...OrderRouteMappings
                        order {
                            order_id
                            order_number
                            order_type
                        }
                    }
                }
            }
        }
    }
`;

export const DRIVER_BY_ID = gql`
    ${TEAMMATE_FIELDS}
    query GetDriverById($driver_id: uuid!) {
        teammates_by_pk(teammate_id: $driver_id) {
            ...TeammateFields
        }
    }
`;
