import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Grid, CircularProgress } from '@material-ui/core';
import { colors } from '@/styles';
import * as Sentry from '@sentry/react';
import { Container, Card, Body1, Header1, PrimaryButton, local } from './blocks';
import { toNational } from '@/utilities/formatPhoneNumber';
import { post } from '@/utilities/onwardClient';
import { CONFIRM_STOP } from '@/constants/apiRoutes';
import { compose } from 'recompose';
import withTokenAuth from '../Auth/withTokenAuth';

function CustomerConfirmTimeframe({ jwt, claims = {} }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const response = await post(
                CONFIRM_STOP,
                {
                    order_ids: claims.stop.orders,
                },
            );

            if (response.status >= 400) {
                setErrorMessage(
                    'Something seems to have gone wrong, please try again or contact our customer support.'
                );
                Sentry.captureException(new Error('Error confirming stop time frame on web confirm time frame page'));
            } else if (response.status === 200) {
                setSuccessfullySaved(true);
            }
        } catch (error) {
            setErrorMessage('Something seems to have gone wrong, please try again or contact our customer support.');
            Sentry.captureException(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container>
            <Grid
                container
                direction="column"
                css={css`
                    margin: 0 12px;
                `}
            >
                <Grid item>
                    <Header1
                        css={css`
                            color: ${local.greys[1]};
                        `}
                    >
                        Delivery Details
                    </Header1>
                </Grid>
                <Card
                    container
                    direction="column"
                    css={css`
                        margin: 8px 0;
                    `}
                >
                    <Grid item>
                        <Body1>{`Address: ${claims?.stop.address}`}</Body1>
                    </Grid>
                    <Grid item>
                        <Body1>{`Delivery Date: ${claims?.delivery_date}`}</Body1>
                    </Grid>
                    <Grid item>
                        <Body1>{`Time Frame: ${claims?.delivery_timeframe}`}</Body1>
                    </Grid>
                    <Grid item className="mt-2">
                        <Body1>{`We look forward to delivering your ${claims?.business} order! Click the confirm button below to confirm that the above time frame works for you.`}</Body1>
                    </Grid>
                    {claims?.support_phone && (
                        <Grid item className="mt-2">
                            <Body1>{`If you have any questions about your delivery or need to reschedule, please call ${toNational(
                                claims?.support_phone
                            )}.`}</Body1>
                        </Grid>
                    )}
                </Card>

                <Grid
                    item
                    css={css`
                        width: 100%;
                        margin-top: 40px;
                    `}
                >
                    <PrimaryButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting || successfullySaved}
                    >
                        {isSubmitting ? (
                            <CircularProgress
                                size={24}
                                css={css`
                                    color: ${local.greys[0]};
                                `}
                            />
                        ) : (
                            <Body1
                                css={css`
                                    color: ${colors.white.primary};
                                    font-weight: 700;
                                    letter-spacing: 1.23 px;
                                `}
                            >
                                Confirm
                            </Body1>
                        )}
                    </PrimaryButton>
                </Grid>
                <Grid
                    container
                    item
                    css={css`
                        justify-content: center;
                        width: 100%;
                        margin-top: 8px;
                        margin-bottom: 62px;
                    `}
                >
                    {successfullySaved && (
                        <Body1
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            Time frame confirmed!
                        </Body1>
                    )}

                    {errorMessage && (
                        <Body1
                            css={css`
                                color: ${colors.reds.primary};
                            `}
                        >
                            {errorMessage}
                        </Body1>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}

export default compose(withTokenAuth({ role: 'customer' }))(CustomerConfirmTimeframe);
