import React from 'react';
import FooterComponent from '../Footer';
import { Row, Col } from 'reactstrap';

const CancelPage = () => (
  <>
    <Col style={{ paddingLeft: '40px', paddingRight: '40px' }}>
    <Row className="my-md">
        <h2 className="homefont textmargin">Cancellation Policy</h2>
        <hr />
    </Row>

    <Row className="my-md">
        <h3>Carriers:</h3>
    </Row>

    <Row className="my-md" style={{ textAlign: "left" }}>
        <ol>
        <li>You are only paid the delivery fee if the customer cancels their order the day of delivery. </li>
        <li>You are not paid if the order is cancelled prior to delivery date.</li>
        <li>If you are attempting a delivery and the customer is not home, you will be paid full delivery fee for the attempt. A one hour pre call must be made to the customer in order for an “Attempt” to be approved. </li>
        <li>Driver must wait at least 30 minutes before moving on to the next stop. </li>
        <li>The driver must notate “Attempt” on the BOL. </li>
        <li>Once the Driver brings the BOL back to their local customer service team, they will be responsible for updating the notes on the Onward Load Board that an attempt was made.</li>
        <li>The customer service team must also scan the BOL with “Attempt” written on it.</li>
        <li>Once you have rescheduled the delivery, update the new date on the Load Board.</li>
        <li>You will be paid in full for the attempted delivery. </li>
        </ol>
    </Row>

    <Row className="row-grid align-items-center my-md">
        <h3>Shippers:</h3>
    </Row>

    <Row className="my-md" style={{ textAlign: "left" }}>
        <ol>
        <li>If you or your customer cancel the order prior to delivery date you will not be charged for delivery fee. You are responsible for canceling the order on the Onward Load Board.</li>
        <li>If you or your customer cancels the order the day of delivery, you will be charged 100% of the delivery fee.</li>
        <li>If the carrier attempts the delivery and the customer is not home to accept, you will be charged 100% of the delivery fee. Carriers must follow all of the above steps in order for an attempt to be processed.</li>
        <li>You will be able to track status of an attempted delivery and new dates through the Load Board.</li>
        </ol>
    </Row>
    </Col>

    <FooterComponent style={{ marginTop: "90%" }} />
  </>
);

export default CancelPage;
