import React, { useEffect, useCallback, useRef, useState, useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTable, useSortBy, useRowSelect, useFlexLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { TableContainer, Table, TableHead, Button } from '@material-ui/core';

import HeaderCell from './table/HeaderCell';
import BodyCell from './table/BodyCell';
import BodyRow from './table/BodyRow';
import { UserContext } from '../App';
import InfiniteLoader from 'react-window-infinite-loader';

export const StickyTable = styled(({ children, ...props }) => <Table {...props}>{children}</Table>)`
    overflow: scroll;
`;

export const StickyHeader = styled(({ children, ...props }) => <TableHead {...props}>{children}</TableHead>)`
    position: sticky;
    background-color: blue;
    z-index: 1;
    width: fit-content;
`;

function InventoryTable({ state, loading, callbacks = {} }) {
    const inf = useRef(null);
    const [sortBy, setSortBy] = useState([{ id: 'delivery_date', desc: true }]);
    const { columns, itemsBySku } = state;
    const { sidebarCollapsed } = useContext(UserContext);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: tableState,
    } = useTable(
        {
            columns,
            data: itemsBySku,
        },
        useSortBy,
        useRowSelect,
        useSticky,
        useFlexLayout
    );

    useEffect(() => {
        if (tableState?.sortBy?.length) {
            setSortBy(tableState.sortBy);
        }
    }, [tableState?.sortBy]);

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.reduce(
                            (acc, subgroup) => [
                                ...acc,
                                subgroup.headers.map((col) => (
                                    <HeaderCell {...col.getHeaderProps()} key={col.id} cell={col} align={col.align} />
                                )),
                            ],
                            []
                        )}
                    </div>
                );
            }

            //BODY
            const row = rows[index - 1];
            prepareRow(row);
            return (
                <BodyRow
                    {...row.getRowProps({
                        style,
                    })}
                    row={row}
                    callbacks={callbacks}
                    onClick={() => {
                        if (callbacks && typeof callbacks.setSelectedSku === 'function') {
                            callbacks.setSelectedSku(row.original);
                        } else {
                            console.error('setSelectedSku is not a function or callbacks is undefined', callbacks);
                        }
                    }}
                >
                    {row.cells.map((cell) => (
                        <BodyCell
                            {...cell.getCellProps()}
                            cell={cell}
                            align={cell.column.align}
                            span={cell.column.span}
                        />
                    ))}
                </BodyRow>
            );
        },
        [prepareRow, rows, callbacks]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
                background-color: white;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <InfiniteLoader
                        ref={inf}
                        itemCount={state.hasMore ? rows.length + 1 : rows.length}
                        threshold={1}
                        isItemLoaded={(index) => index < state.orders.length}
                        loadMoreItems={(start, stop) => {
                            if (!loading.init && state.hasMore) {
                                callbacks.loadMore();
                            }
                        }}
                    >
                        {({ onItemsRendered, ref }) => (
                            <AutoSizer>
                                {({ height, width }) => (
                                    <FixedSizeList
                                        ref={ref}
                                        onItemsRendered={onItemsRendered}
                                        height={height}
                                        itemCount={rows.length + 1}
                                        itemSize={57}
                                        width={width}
                                    >
                                        {Row}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        )}
                    </InfiniteLoader>
                </div>
            </div>
        </TableContainer>
    );
}

export default React.memo(InventoryTable);
