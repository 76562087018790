export const STATUSES = {
    QUEUED: 'QUEUED',
    SCHEDULED: 'SCHEDULED',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
};

export const STATUS_MAPPINGS = {
    [STATUSES.QUEUED]: 'Scheduled',
    [STATUSES.SCHEDULED]: 'Scheduled',
    [STATUSES.FAILED]: 'Failed',
    [STATUSES.SUCCESS]: 'Delivered',
};
