/* eslint-disable no-fallthrough */

import React, { useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import { Close as CloseIcon } from '@material-ui/icons';
import { colors } from '@/styles';
import { useClientUser } from '@/hooks';
import { asUTCDate, asDateInTZ } from '@/utilities/convertToISO';
import { OnwardSwitch, SecondaryButton } from '@/styles/blocks';
import {
    Grid,
    MenuItem,
    TextField,
    IconButton,
    RadioGroup,
    Radio,
    FormControlLabel,
    ListItemText,
    Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { genName as genSubregionName } from '@/components/Subregions/utils';

import {
    ResponsiveSidebarDialog,
    StickyModalActions,
    ModalContent,
    ModalTitle,
    PrimaryButton,
    WarningButton,
} from '@/styles/blocks';

import RadioLabel from './RadioLabel';

import { scheduleResource, genDay } from '../../utils';
import { Header2, Body2, Body1, HR } from '../../blocks';
import { RECURRING_TYPES, RECURRING_TYPE_DISPLAYS, UPDATE_TYPES, STEPS } from '../../constants';
import { useGqlCalculations } from './hooks';

const EditResourceModal = ({
    resource = {},
    rule = {},
    day,
    resources,
    subregions,
    subregionsOrdered,
    entities,
    isLoading,
    callbacks,
    tz = 'America/New_York',
}) => {
    const { shipping_partners } = useClientUser();
    const [updates, setUpdates] = useState({});
    const [selected, setSelected] = useState(null);
    const [type, setType] = useState(RECURRING_TYPES.OVERRIDE);
    const [shippingPartners, setShippingPartners] = useState([]);
    const [step, setStep] = useState(STEPS.RESOURCE);
    const [updateType, setUpdateType] = useState(UPDATE_TYPES.SPLIT);
    const [regions, setRegions] = useState([]);

    const reset = () => {
        setUpdates({});
        setSelected(null);
        setRegions([]);
        setUpdateType(UPDATE_TYPES.SPLIT);
        setStep(STEPS.RESOURCE);
        setType(RECURRING_TYPES.OVERRIDE);
        setShippingPartners([]);
    };

    const closeModal = () => {
        reset();
        callbacks.onClose();
    };

    const selectedResource = useMemo(() => {
        return {
            ...(resource || {}),
            ...(resources?.[selected] || {}),
        };
    }, [resource.resource_id, selected]);

    const entity = useMemo(() => {
        if (!rule?.type) {
            return null;
        }

        return entities[rule.type][rule.id];
    }, [rule, entities]);

    const [enabledToday, todayEntity] = useMemo(() => {
        if (!selectedResource.resource_id) {
            return [false, null];
        }

        return scheduleResource({ resource: selectedResource, day });
    }, [day, selectedResource]);

    useEffect(() => {
        setShippingPartners(
            (selectedResource?.shipping_partners || [])
                .filter((mapping) => mapping.partner_client_id !== null)
                .map((mapping) => mapping.partner_client.client_id)
        );
    }, [selectedResource]);

    useEffect(() => {
        setRegions(
            (selectedResource.subregions || []).map((subregion) => {
                const idx = subregionsOrdered.findIndex((resource) => resource.subregion_id === subregion.subregion_id);
                return [subregion.subregion_id, idx];
            })
        );
    }, [selectedResource, subregionsOrdered]);

    useEffect(() => {
        let type = RECURRING_TYPES.OVERRIDE;
        if (entity && rule.type === 'rules' && !selected) {
            type = entity.rule_type;
        }

        setType(type);
    }, [selected, entity, rule?.id]);

    const updated = useMemo(() => {
        return {
            ...selectedResource,
            ...updates,
        };
    }, [updates, selectedResource]);

    const gql = useGqlCalculations({
        day,
        entity,
        regions,
        rule,
        selectedResource,
        step,
        subregions,
        type,
        updated,
        updateType,
        shippingPartners,
    });

    const isValid = useMemo(() => {
        const isValid = (field, obj) => {
            return obj[field] && obj[field].length > 0;
        };

        return (
            (step === STEPS.RESOURCE && isValid('name', updated) && regions.length > 0) ||
            ([STEPS.UPDATE, STEPS.DELETE].includes(step) && !!updateType)
        );
    }, [updated, regions, step]);

    let content = null;
    switch (step) {
        case STEPS.RESOURCE: {
            content = (
                <>
                    {Object.values(resources).length > 0 ? (
                        <>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 8px;
                                `}
                            >
                                <Body1>Choose Existing</Body1>
                            </Grid>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 20px;
                                    flex: 0;
                                    flex-basis: 0;
                                `}
                            >
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    label="Resource"
                                    value={updated?.resource_id || ''}
                                    onChange={(e) => {
                                        setSelected(e.target.value);
                                    }}
                                >
                                    {Object.values(resources).map((option, idx) => (
                                        <MenuItem key={option.resource_id} value={option.resource_id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <HR />
                        </>
                    ) : null}
                    {!selectedResource.name ? (
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            <Body1>Create New</Body1>
                        </Grid>
                    ) : null}
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 20px;
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            value={updated?.name || ''}
                            onChange={(e) => {
                                setUpdates((prev) => ({ ...prev, name: e.target.value }));
                            }}
                        />
                    </Grid>
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 20px;
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Recurring"
                            value={type || ''}
                            onChange={(e) => {
                                setType(e.target.value);
                            }}
                        >
                            {Object.values(RECURRING_TYPES).map((key) => (
                                <MenuItem key={key} value={key}>
                                    {RECURRING_TYPE_DISPLAYS[key]}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 20px;
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Shipping Partner"
                            value={shippingPartners || []}
                            SelectProps={{
                                multiple: true,
                                renderValue: (selected) => {
                                    return selected
                                        .map((id) => {
                                            const sp = shipping_partners.find((partner) => partner.shipper_id === id);
                                            return sp ? sp.shipper.business_name : '--';
                                        })
                                        .join(', ');
                                },
                            }}
                            onChange={(e) => {
                                setShippingPartners(e.target.value);
                            }}
                            css={css`
                                background-color: white;
                                width: 100%;
                                padding-right: 2px;
                                margin-top: 0.2rem;
                            `}
                        >
                            {shipping_partners.map((partner) => {
                                return (
                                    <MenuItem key={partner.shipper_id} value={partner.shipper_id}>
                                        <Checkbox
                                            color="primary"
                                            checked={shippingPartners.includes(partner.shipper_id)}
                                        />
                                        <ListItemText primary={partner.shipper.business_name} />
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 20px;
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <Autocomplete
                            multiple
                            fullWidth
                            disableClearable
                            options={subregionsOrdered}
                            getOptionLabel={(option) => {
                                const idx = subregionsOrdered.findIndex(
                                    (resource) => resource.subregion_id === option.subregion_id
                                );

                                return genSubregionName(option, idx);
                            }}
                            getOptionDisabled={(option) => regions.map(([id]) => id).includes(option.subregion_id)}
                            value={regions || []}
                            onChange={(e, value, reason) => {
                                switch (reason) {
                                    case 'select-option': {
                                        const added = value.slice(-1)[0];
                                        const idx = subregionsOrdered.findIndex(
                                            (resource) => resource.subregion_id === added.subregion_id
                                        );

                                        setRegions((prev) => {
                                            return [...prev, [added.subregion_id, idx]];
                                        });
                                        break;
                                    }
                                }
                            }}
                            renderTags={() => null}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Subregions"
                                        placeholder="Select a subregion"
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid
                        direction="row"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            max-height: 125px;
                            overflow: scroll;
                        `}
                    >
                        <Grid
                            container
                            direction="column"
                            css={css`
                                width: 100%;
                            `}
                        >
                            {regions.map(([id, ordering], idx) => {
                                return (
                                    <Grid
                                        key={id}
                                        direction="row"
                                        container
                                        css={css`
                                            padding: 12px 0;
                                            padding-right: 12px;
                                            border-bottom: ${idx === regions?.length - 1 ? 0 : 1}px solid
                                                ${colors.greys[4]};
                                        `}
                                    >
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex: 1;
                                                flex-basis: 0;
                                                justify-content: center;
                                                padding: 0 12px;
                                            `}
                                        >
                                            <Body2>{genSubregionName(subregions?.[id] || {}, ordering)}</Body2>
                                        </Grid>
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex: 0;
                                                flex-basis: 0;
                                            `}
                                        >
                                            <IconButton
                                                css={css`
                                                    padding: 8px;
                                                `}
                                                onClick={() => {
                                                    setRegions((prev) => {
                                                        return prev.filter(([old]) => old !== id);
                                                    });
                                                }}
                                            >
                                                <CloseIcon
                                                    css={css`
                                                        font-size: 14px;
                                                    `}
                                                />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </>
            );
            break;
        }
        case STEPS.UPDATE: {
            content = (
                <Grid direction="row" container>
                    <RadioGroup
                        value={updateType || ''}
                        onChange={(e) => {
                            setUpdateType(e.target.value);
                        }}
                    >
                        {Object.values(UPDATE_TYPES)
                            .filter((val) => ![UPDATE_TYPES.ONE_OFF, UPDATE_TYPES.DELETE_RESOURCE].includes(val))
                            .map((type) => (
                                <FormControlLabel
                                    key={type}
                                    value={type}
                                    control={<Radio color="primary" />}
                                    label={<RadioLabel type={type} />}
                                    css={css`
                                        margin-bottom: 12px;
                                    `}
                                />
                            ))}
                    </RadioGroup>
                </Grid>
            );
            break;
        }
        case STEPS.DELETE: {
            content = (
                <Grid direction="row" container>
                    <RadioGroup
                        value={updateType || ''}
                        onChange={(e) => {
                            setUpdateType(e.target.value);
                        }}
                    >
                        {Object.values(UPDATE_TYPES).map((type) => (
                            <FormControlLabel
                                key={type}
                                value={type}
                                control={<Radio color="primary" />}
                                label={<RadioLabel type={type} />}
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            />
                        ))}
                    </RadioGroup>
                </Grid>
            );
            break;
        }
    }

    return (
        <ResponsiveSidebarDialog open={!!day} onClose={closeModal} maxWidth="md" fullWidth>
            <ModalTitle onClose={closeModal}>
                <Header2
                    css={css`
                        color: #4c4c4c;
                    `}
                >
                    Resource
                </Header2>
            </ModalTitle>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                {content}
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <SecondaryButton
                        onClick={closeModal}
                        css={css`
                            max-width: 125px;
                        `}
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <Grid
                        container
                        direction="row"
                        css={css`
                            flex-wrap: nowrap;
                            justify-content: flex-end;
                        `}
                    >
                        {step === STEPS.RESOURCE && !!selectedResource?.resource_id ? (
                            <>
                                {enabledToday ? (
                                    <Grid
                                        item
                                        direction="column"
                                        css={css`
                                            margin-right: 12px;
                                        `}
                                    >
                                        <WarningButton
                                            disabled={isLoading}
                                            onClick={() => {
                                                const {
                                                    __typename,
                                                    overrides: _overrides,
                                                    rules: _rules,
                                                    subregions: _subregions,
                                                    ...clean
                                                } = selectedResource;

                                                callbacks
                                                    .saveResource({
                                                        resource: clean,
                                                        rules: {},
                                                        overrides: {
                                                            add: [
                                                                ...(!todayEntity || todayEntity.type !== 'overrides'
                                                                    ? [
                                                                          {
                                                                              rule_id: rule.id,
                                                                              override_start: day.start,
                                                                              override_end: day.end,
                                                                              omit: true,
                                                                          },
                                                                      ]
                                                                    : []),
                                                            ],
                                                            update: [
                                                                ...(todayEntity && todayEntity.type === 'overrides'
                                                                    ? [
                                                                          {
                                                                              mapping_id: todayEntity.id,
                                                                              resource_id: clean.resource_id,
                                                                              rule_id: rule.id,
                                                                              override_start: day.start,
                                                                              override_end: day.end,
                                                                              omit: true,
                                                                          },
                                                                      ]
                                                                    : []),
                                                            ],
                                                        },
                                                        shipping_partners: {},
                                                        regions: {},
                                                    })
                                                    .then(closeModal);
                                            }}
                                            css={css`
                                                max-width: 250px;
                                            `}
                                        >
                                            Turn off scheduling today
                                        </WarningButton>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        direction="column"
                                        css={css`
                                            margin-right: 12px;
                                        `}
                                    >
                                        <PrimaryButton
                                            disabled={isLoading}
                                            onClick={() => {
                                                const {
                                                    __typename,
                                                    overrides: _overrides,
                                                    rules: _rules,
                                                    subregions: _subregions,
                                                    ...clean
                                                } = selectedResource;

                                                callbacks
                                                    .saveResource({
                                                        resource: clean,
                                                        rules: {},
                                                        overrides: {
                                                            add: [
                                                                ...(!todayEntity || todayEntity.type !== 'overrides'
                                                                    ? [
                                                                          {
                                                                              rule_id: rule.id,
                                                                              override_start: day.start,
                                                                              override_end: day.end,
                                                                              omit: false,
                                                                          },
                                                                      ]
                                                                    : []),
                                                            ],
                                                            update: [
                                                                ...(todayEntity && todayEntity.type === 'overrides'
                                                                    ? [
                                                                          {
                                                                              mapping_id: todayEntity.id,
                                                                              resource_id: clean.resource_id,
                                                                              rule_id: rule.id,
                                                                              override_start: day.start,
                                                                              override_end: day.end,
                                                                              omit: false,
                                                                          },
                                                                      ]
                                                                    : []),
                                                            ],
                                                        },
                                                        shipping_partners: {},
                                                        regions: {},
                                                    })
                                                    .then(closeModal);
                                            }}
                                            css={css`
                                                max-width: 250px;
                                            `}
                                        >
                                            Turn on scheduling today
                                        </PrimaryButton>
                                    </Grid>
                                )}

                                <Grid
                                    item
                                    direction="column"
                                    css={css`
                                        margin-right: 12px;
                                    `}
                                >
                                    <WarningButton
                                        disabled={isLoading}
                                        onClick={() => {
                                            const isEditingRule = rule.type === 'rules';
                                            if (!isEditingRule) {
                                                callbacks
                                                    .deleteRule({
                                                        rules: [],
                                                        overrides: [rule.id],
                                                    })
                                                    .then(closeModal);
                                            } else {
                                                setStep(STEPS.DELETE);
                                            }
                                        }}
                                        css={css`
                                            max-width: 125px;
                                        `}
                                    >
                                        Delete
                                    </WarningButton>
                                </Grid>
                            </>
                        ) : null}
                        <Grid item direction="column">
                            <PrimaryButton
                                disabled={!isValid || isLoading}
                                onClick={() => {
                                    const isNewRule = !rule?.type;
                                    const isEditingRule = rule.type === 'rules';
                                    const containsEdit =
                                        gql.rules?.add?.length > 0 ||
                                        gql.rules?.update?.length > 0 ||
                                        gql.overrides?.update?.length > 0 ||
                                        gql.overrides?.update?.length > 0;
                                    const isOverride = type === RECURRING_TYPES.OVERRIDE;
                                    const { overrides: prevOverrides } = entity || {};
                                    const {
                                        __typename,
                                        overrides: _overrides,
                                        rules: _rules,
                                        subregions: _subregions,
                                        ...clean
                                    } = updated;

                                    if (!isNewRule) {
                                        if (step === STEPS.RESOURCE && containsEdit) {
                                            setStep(STEPS.UPDATE);
                                            return;
                                        }

                                        if (step === STEPS.DELETE) {
                                            if (updateType === UPDATE_TYPES.DELETE_RESOURCE) {
                                                return callbacks
                                                    .deleteResource({
                                                        resource: selectedResource,
                                                    })
                                                    .then(closeModal);
                                            }

                                            if (isOverride || updateType === UPDATE_TYPES.OVERWRITE) {
                                                return callbacks
                                                    .deleteRule({
                                                        rules: isEditingRule ? [rule.id] : [],
                                                        overrides: isEditingRule
                                                            ? prevOverrides.map((mapping) => mapping.override_id)
                                                            : [rule.id],
                                                    })
                                                    .then(closeModal);
                                            }
                                        }
                                    }

                                    callbacks
                                        .saveResource({
                                            resource: clean,
                                            rules: gql.rules,
                                            overrides: gql.overrides,
                                            shipping_partners: gql.shipping_partners,
                                            regions: step === STEPS.DELETE ? {} : gql.regions,
                                        })
                                        .then(closeModal);
                                }}
                                css={css`
                                    max-width: 125px;
                                `}
                            >
                                Save
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default EditResourceModal;
