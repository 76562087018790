import React, { useMemo } from 'react';
import { CardHeaderLine, CardHeaderText, CardLine, CardText, ListCard } from './blocks';
import { recommendedEquipmentLabels } from '@/constants/recommendedEquipmentLabels';

const RouteRequirementsCard = ({ route, stops }) => {
    const stopOrders = useMemo(() => {
        if (!route?.orders?.length) return [];
        const ordersByKey = Object.fromEntries(route?.orders?.map((mapping) => [mapping.order_id, mapping.order]));
        return stops.reduce((acc, stop) => {
            let orders = (stop.orders || []).map((order_id) => ordersByKey[order_id]);
            return [...acc, ...orders];
        }, []);
    }, [route, stops]);

    const {
        ['2_man_team']: twoManTeam,
        ['3_man_team']: threeManTeam,
        lift_gate,
        ...otherEquipment
    } = useMemo(() => {
        const equipmentObject = {};

        stopOrders.forEach((order) => {
            const filteredEquipment = Object.keys(order.recommended_equipment || {}).filter(
                (key) => order.recommended_equipment[key]
            );
            filteredEquipment.forEach((equip) => {
                equipmentObject[equip] = recommendedEquipmentLabels[equip];
            });
        });
        return equipmentObject;
    }, [stopOrders]);

    const whiteGlove = useMemo(() => {
        return stopOrders.some((order) => order.dropoff_location_type === 'whiteGlove');
    }, [stopOrders]);

    return (
        <ListCard>
            <CardHeaderLine>
                <CardHeaderText>Route Requirements</CardHeaderText>
            </CardHeaderLine>
            {/* Truck Size TBD */}
            {/* <CardLine>
                <CardText>
                    Truck Size: 
                </CardText>
            </CardLine> */}
            <CardLine>
                <CardText>Lift Gate: {lift_gate ? 'Yes' : 'No'}</CardText>
            </CardLine>
            <CardLine>
                <CardText>Total People: {threeManTeam ? '3' : twoManTeam ? '2' : '1'}</CardText>
            </CardLine>
            <CardLine>
                <CardText>White Glove: {whiteGlove ? 'Yes' : 'No'}</CardText>
            </CardLine>
            {/* Appliance Install TBD */}
            {/* <CardLine>
                <CardText>
                    Appliance Install: 
                </CardText>
            </CardLine> */}
        </ListCard>
    );
};

export default RouteRequirementsCard;
