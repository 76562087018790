import React, { useContext, useMemo } from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { css } from '@emotion/react';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { UPDATE_ORDERS_MANY } from '../graphql/mutations';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { useClientUser } from '@/hooks/useClientUser';

const DriverOverlapModal = () => {
    const { modalOpen, setModalOpen, selectedRoute, clashingRoutes, setClashingRoutes } = useContext(PlanningContext);

    const handleModalClose = () => {
        setModalOpen(null);
        setClashingRoutes(null);
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.DRIVER_OVERLAP} onClose={handleModalClose}>
            <ModalTitle
                css={css`
                    border-bottom: none;
                `}
            >
                <GridItemRow
                    css={css`
                        background-color: #d23e3e;
                        border-radius: 5px;
                        padding: 1rem;
                        color: #ffffff;
                    `}
                >
                    <ReportProblemIcon css={css``} />
                    <span
                        css={css`
                            font-size: 16px;
                            flex-grow: 1;
                            padding-left: 1rem;
                        `}
                    >
                        {`The driver is already assigned to another route that overlaps with this timeframe.`}
                    </span>
                </GridItemRow>
            </ModalTitle>
            <ModalContent
                css={css`
                    font-size: 16px;
                `}
            >
                <GridItemRow>
                    <span
                        css={css`
                            font-weight: 700;
                        `}
                    >
                        Overlapping routes: {(clashingRoutes || []).join(', ')}
                    </span>
                </GridItemRow>
                <br />
                <GridItemRow>
                    <span
                        css={css`
                            font-weight: 600;
                        `}
                    >
                        Please choose a different driver or alter the route's start time.
                    </span>
                </GridItemRow>
            </ModalContent>
            <ModalActions
                css={css`
                    border-top: none;
                    justify-content: end;
                `}
            >
                <PrimaryButton
                    css={css`
                        justify-content: center;
                        width: 128px;
                    `}
                    onClick={handleModalClose}
                >
                    Okay
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default DriverOverlapModal;
