import { colors } from '@/styles';
import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem 1rem;
    background-color: white;
    border-bottom: 1px solid ${colors.greys.border};
`;

export const Content = styled.div`
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    overflow-y: hidden;
`;
