import { useMemo } from 'react';

const normalizeRange = (metric) => {
    if (!metric) return 0;
    return Array.isArray(metric) ? Math.max(...metric.map((weight) => parseFloat(weight))) : parseFloat(metric);
};

export const useTotalWeight = (orders) => {
    return useMemo(() => {
        const fields = ['items', 'returns'];
        if (orders?.length > 0) {
            return orders.reduce((totalWeight, order) => {
                let weight = normalizeRange(order.items_total_weight) + normalizeRange(order?.totalWeight);

                if (!weight) {
                    weight = 0;
                    fields.forEach((field) => {
                        if (order[field]?.length > 0) {
                            order[field].forEach((item) => {
                                const itemWeight = normalizeRange(item.total_weight) || normalizeRange(item.weight);
                                weight += itemWeight;
                            });
                        }
                    });
                }

                return totalWeight + weight;
            }, 0);
        }

        return 0;
    }, [orders]);
};

export const useTotalCubes = (orders) => {
    return useMemo(() => {
        const fields = ['items', 'returns'];
        if (orders?.length > 0) {
            return orders.reduce((totalCubes, order) => {
                let cubes = normalizeRange(order.items_total_cubes) + normalizeRange(order?.totalCubes);

                if (!cubes) {
                    cubes = 0;
                    fields.forEach((field) => {
                        if (order[field]?.length > 0) {
                            order[field].forEach((item) => {
                                const itemCubes =
                                    normalizeRange(item.total_cubes) || normalizeRange(item.cubes_per_unit);
                                cubes += itemCubes;
                            });
                        }
                    });
                }

                return totalCubes + cubes;
            }, 0);
        }

        return 0;
    }, [orders]);
};
