import { gql } from '@apollo/client';

export const ACTIVITY_ROUTE_FIELDS = gql`
    fragment ActivityRouteFields on activity_routes {
        route_id
        external_route_id
        service_id

        external_carrier_id
        external_carrier_name
        delivery_date
        capacity

        created_at
        last_modified_at
    }
`;
