import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { OnwardCard } from './Card';
import { CardTitle, CardItem, CardItemBold } from './blocks';

export default function BrokerCard(props) {
    const { order } = props;

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    const HalfGridItem = ({children, props}) => {
        return <Grid item style={{maxWidth: '50%', textAlign: 'right'}} {...props}>{children}</Grid>
    }

    const margin = useMemo(() => {
        if (order?.broker_billed_rate && order?.broker_paid_rate) {
            const diff = order?.broker_billed_rate - order?.broker_paid_rate;
            return `$${diff.toFixed(2)} (${((diff / order?.broker_billed_rate) * 100).toFixed(2)}%)`;
        }
        return 'N/A';
    }, [order]);

    return (
        <>
            <OnwardCard>
                <CardRow style={{marginBottom: 0}}>
                    <Grid item>
                        <CardTitle>
                            Broker Info
                        </CardTitle>
                    </Grid>
                </CardRow>

                <CardRow>
                    <Grid item>
                        <CardItem>Billed to Customer Rate</CardItem>
                    </Grid>
                    <HalfGridItem>
                            <CardItemBold>
                                {order?.broker_billed_rate ? `$${order?.broker_billed_rate.toFixed(2)}` : '--'}
                            </CardItemBold>
                        </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                            <CardItem>Paid to Carrier Rate</CardItem>
                        </Grid>
                        <HalfGridItem>
                            <CardItemBold>
                                {order?.broker_paid_rate ? `$${order?.broker_paid_rate.toFixed(2)}` : '--'}
                            </CardItemBold>
                        </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                            <CardItem>Margin</CardItem>
                        </Grid>
                        <HalfGridItem>
                            <CardItemBold>
                                {margin}
                            </CardItemBold>
                        </HalfGridItem>
                </CardRow>
            </OnwardCard>
        </>
    );
}
