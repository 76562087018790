import { gql } from '@apollo/client';

export const MESSAGE_FIELDS = gql`
    fragment MessageFields on messages {
        created_at
        message_id
        order_id
        from_number
        to_number
        link
        link_clicked
        link_type
        message
        scheduled_timestamp
        sid
        source
        status
        widget_name
        timestamp @client
    }
`;

export const MESSAGES_BY_ORDER_ID = gql`
    ${MESSAGE_FIELDS}
    query MessagesByOrderID($order_id: uuid!) {
        results: messages(where: { order_id: { _eq: $order_id } }) {
            ...MessageFields
        }
    }
`;
