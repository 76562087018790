import React from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { H3 } from './blocks';
import { COLUMNS } from './constants';

const Header = () => {
    return (
        <Grid
            container
            direction="row"
            css={css`
                border-bottom: 1px solid lightgrey;
                padding: 20px 20px;
            `}
        >
            {COLUMNS.map(({ key, label, width, align }) => {
                return (
                    <Grid
                        key={key}
                        container
                        direction="column"
                        css={css`
                            flex: ${width ? width : 1};
                            flex-basis: 0;
                            align-content: ${align === 'left'
                                ? 'flex-start'
                                : align === 'right'
                                ? 'flex-end'
                                : 'center'};
                        `}
                    >
                        <H3>{label}</H3>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default Header;
