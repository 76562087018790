import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import cache from '@/graphql';
import { subMonths } from 'date-fns';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import useQuery from '@/utilities/useQuery';
import { useClientUser } from '@/hooks';
import { genResources } from '@/components/Resources/utils';

import { GET_ORDERS, GET_SHIPPERS, SET_DELIVERY_DATE } from './graphql';
import { retryMessages } from './queries/retryMessages';
import { sendPredelivery } from './queries/sendPredelivery';
import { scheduleCall } from './queries/scheduleCall';
import { COLUMNS } from './columns';

export const Context = createContext();

const TODAY = new Date().toISOString();
const cutoff = subMonths(new Date(TODAY), 1);

export const ContextProvider = ({ children }) => {
    const { user_id } = useClientUser();
    const [notification, setNotification] = useState({});
    const [toSchedule, setToSchedule] = useState([]);
    const [selected, setSelected] = useState({});
    const [hasMore, setHasMore] = useState(false);
    const [filter, setFilter] = useState({
        search: null,
        shipper: null,
        resources: [],
    });

    const [getOrders, { data, loading: initInflight, fetchMore }] = useLazyQuery(GET_ORDERS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        onError: (err) => {
            setNotification({ message: 'Failed to retrieve orders', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const [getShippers, { data: shipperData }] = useLazyQuery(GET_SHIPPERS, {
        onError: (err) => {
            setNotification({ message: 'Failed to retrieve shippers', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const [setDeliveryDate, { loading: scheduleInflight }] = useMutation(SET_DELIVERY_DATE, {
        onError: (err) => {
            setNotification({ message: 'Failed to set delivery date', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const orders = useMemo(() => {
        return data?.results || [];
    }, [data]);

    const resources = useMemo(() => {
        if (!orders || orders.length === 0) {
            return [];
        }

        if (orders?.[0].oms) {
            return orders?.[0]?.order_shipper.available_resources || [];
        }

        return orders?.[0]?.order_carrier.available_resources || [];
    }, [orders]);

    const shippers = useMemo(() => {
        return shipperData?.results?.map((order) => order.order_shipper) || [];
    }, [shipperData]);

    const SEARCHABLE = [
        'order_number',
        'po_number',
        'dropoff_name',
        'dropoff_state',
        'dropoff_city',
        'dropoff_zip',
        'pickup_state',
        'pickup_city',
        'pickup_zip',
    ];

    const filtered = useMemo(() => {
        return orders.filter((order) => {
            if (filter.resources.length > 0) {
                let valid;
                if (order.oms) {
                    const { default: shipperDefault, override: shipperOverride } = genResources({
                        client: order.order_shipper || {},
                        order,
                    });
                    valid = shipperOverride.length > 0 ? shipperOverride : shipperDefault;
                } else {
                    const { default: carrierDefault, override: carrierOverride } = genResources({
                        client: order.order_carrier || {},
                        order,
                    });
                    valid = carrierOverride.length > 0 ? carrierOverride : carrierDefault;
                }

                const filtered = filter.resources.map((resource) => resource.resource_id);
                return valid.some((resource) => {
                    return filtered.includes(resource.resource_id);
                });
            }

            return true;
        });
    }, [orders, filter]);

    const ordersById = useMemo(() => {
        return Object.fromEntries(orders.map((order) => [order.order_id, order]));
    }, [orders]);

    const where = useMemo(() => {
        return [
            ...(filter.search
                ? [
                      {
                          _or: SEARCHABLE.map((field) => ({ [field]: { _ilike: `%${filter.search}%` } })),
                      },
                  ]
                : []),
            ...(filter.shipper ? [{ shipper_id: { _eq: filter.shipper } }] : []),

            { delivery_date: { _is_null: true } },
            { planning: { _eq: false } },
            { order_status: { _in: ['pending', 'active', 'open', 'claimed'] } },
            {
                _or: [
                    {
                        _and: [{ oms: { _eq: true } }, { shipper_id: { _eq: user_id } }],
                    },
                    { carrier_id: { _eq: user_id } },
                ],
            },
        ];
    }, [filter]);

    const [retry, { loading: retryInflight }] = useQuery(retryMessages, {
        onComplete: (resp) => {
            const added = resp?.data?.messages || [];

            cache.updateQuery(
                {
                    query: GET_ORDERS,
                    variables: {
                        cutoff,
                        where: {
                            _and: where,
                        },
                    },
                },
                (data) => {
                    const clone = [...data.results];

                    added.forEach((message) => {
                        const idx = clone.findIndex((order) => order.order_id === message.order_id);
                        if (idx >= 0) {
                            clone[idx] = {
                                ...clone[idx],
                                messages: [message, ...(clone[idx]?.messages || [])],
                            };
                        }
                    });

                    return {
                        results: clone,
                    };
                }
            );
        },
        onError: (err) => {
            setNotification({ message: 'Failed to retry sms message', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const [predelivery, { loading: sendInflight }] = useQuery(sendPredelivery, {
        onError: (err) => {
            setNotification({ message: 'Failed to retry sms message', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const [scheduleOrderDelivery, { loading: scheduleCallInflight }] = useQuery(scheduleCall, {
        onError: (err) => {
            setNotification({ message: 'Failed to create schedule delivery call', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    useEffect(() => {
        getOrders({
            variables: {
                cutoff,
                where: {
                    _and: where,
                },
            },
        });

        getShippers({
            variables: {
                where: {
                    _and: where,
                },
            },
        });
    }, []);

    const getOrdersDebounced = useMemo(
        () =>
            debounce((payload) => {
                return getOrders(payload);
            }, 500),
        []
    );

    useEffect(() => {
        if (!initInflight) {
            getOrdersDebounced({
                variables: {
                    cutoff,
                    where: {
                        _and: where,
                    },
                },
            });

            setHasMore(true);
        }
    }, [where, getOrdersDebounced, initInflight]);

    const cursor = useMemo(() => {
        if (orders.length === 0) {
            return null;
        }

        return orders[orders.length - 1].created_at;
    }, [orders]);

    const loadMore = useCallback(() => {
        fetchMore({
            variables: {
                where: {
                    _and: [...where, { created_at: { _lte: cursor } }],
                },
            },
            updateQuery: (data, { fetchMoreResult }) => {
                const prev = Object.fromEntries(data.results.map((order) => [order.order_id, true]));
                const clone = [...data.results, ...fetchMoreResult.results.filter((order) => !prev[order.order_id])];
                return {
                    results: clone,
                };
            },
        }).then((result) => {
            if (result.data.results.length < 100) {
                setHasMore(false);
            }
        });
    }, [where, cursor, fetchMore]);

    const selectedIds = useMemo(() => {
        return Object.keys(selected).filter((attr) => selected[attr]);
    }, [selected]);

    const selectedResources = useMemo(() => {
        const [resources, count] = selectedIds
            .map((id) => ordersById[id])
            .reduce(
                ([all, count], order) => {
                    let resources;
                    if (order.oms) {
                        const { default: shipperDefault, override: shipperOverride } = genResources({
                            client: order.order_shipper || {},
                            order: order,
                        });
                        resources = shipperOverride.length > 0 ? shipperOverride : shipperDefault;
                    } else {
                        const { default: carrierDefault, override: carrierOverride } = genResources({
                            client: order.order_carrier || {},
                            order: order,
                        });
                        resources = carrierOverride.length > 0 ? carrierOverride : carrierDefault;
                    }

                    resources.forEach((resource) => {
                        all[resource.resource_id] = resource;
                        count[resource.resource_id] = count[resource.resource_id] ? count[resource.resource_id] + 1 : 1;
                    });

                    return [all, count];
                },
                [{}, {}]
            );

        return Object.entries(count)
            .filter(([, count]) => {
                return count === selectedIds.length;
            })
            .map(([id]) => resources[id]);
    }, [selectedIds, ordersById]);

    const callbacks = {
        closeModal: () => {
            setToSchedule([]);
        },
        selectOrders: setSelected,
        scheduleOrder: setToSchedule,
        setFilter,
        loadMore,
        setNotification,
        scheduleCall: (ids) => {
            return Promise.all(
                ids.map((id) => {
                    const order = ordersById[id];
                    return scheduleOrderDelivery({ order });
                })
            );
        },
        retryMessages: (ids) => {
            const messages = ids.map((id) => {
                const order = ordersById[id];

                const first = order.messages?.[0];
                return first?.message_id;
            });

            const resend = messages.filter((x) => x);
            const force = messages.filter((x) => !x);

            return Promise.all([
                retry({ messages: resend }),
                ...force.map((id) => {
                    const order = ordersById[id];

                    return predelivery({ order });
                }),
            ]);
        },
        setDeliveryDate: ({ updates }) => {
            return setDeliveryDate({
                variables: {
                    updates: updates.map(({ order, updates }) => {
                        return {
                            where: {
                                order_id: { _eq: order.order_id },
                            },
                            _set: updates,
                        };
                    }),
                },
            });
        },
        clearNotification: () => {
            setNotification({});
        },
    };

    return (
        <Context.Provider
            value={{
                state: {
                    columns: COLUMNS,
                    filter,
                    hasMore,
                    notification,
                    orders: filtered,
                    ordersById,
                    selected: selectedIds,
                    shippers,
                    toSchedule,
                    selectedResources,
                    resources,
                },
                loading: {
                    scheduling: scheduleInflight,
                    retry: retryInflight || sendInflight,
                    call: scheduleCallInflight,
                    init: initInflight,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
