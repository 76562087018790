import React, { useEffect, useState } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import { CustomInput } from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { Select, FormControl, FormControlLabel, InputLabel, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { ADD_DEFAULT_STORE_TO_CLIENT } from '@/components/Account/mutations';

import deliveryHours from '../../../constants/deliveryHours.json';
import { hoursOpenArray, holiday_Closures, updatedHolidayClosures } from '@/constants/hoursConstants';

function AdminShipper(props) {
    const { shipper } = props;
    const GreenCheckbox = withStyles({
        root: {
            color: '#71b77a',
            '&$checked': {
                color: '#71b77a',
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const [hoursOpen, setHoursOpen] = useState(hoursOpenArray);
    const [holidayClosures, setHolidayClosures] = useState(holiday_Closures);
    const [validationMessage, setValidationMessage] = useState('');

    useEffect(() => {
        setHoursOpen(hoursOpenArray);
        setHolidayClosures(holiday_Closures);
        loadHours();
    }, [shipper]);

    const setHolidays = (holiday) => {
        let tmpHoliday = _.cloneDeep(holidayClosures);
        tmpHoliday[holiday] = !tmpHoliday[holiday];
        setHolidayClosures(tmpHoliday);
    };

    const loadHours = () => {
        let tmpHoliday = _.cloneDeep(updatedHolidayClosures);

        Object.keys(tmpHoliday).forEach((key) => {
            if (shipper.hasOwnProperty(key)) {
                tmpHoliday[key] = shipper[key];
            }
        });

        setHolidayClosures(tmpHoliday);

        if (shipper.default_store_hours) {
            setHoursOpen(shipper.default_store_hours);
        }
    };

    const [editDefaultHours] = useMutation(ADD_DEFAULT_STORE_TO_CLIENT, {
        onError: (error) => {
            console.log(error);
        },
    });

    const handleSave = () => {
        let errors = false;
        hoursOpen.forEach((day) => {
            if (day.start !== 'closed' && day.close !== 'closed' && day.start >= day.close) {
                setValidationMessage(
                    'Changes not saved yet, please check your selected hours and make sure the closing times are after opening times'
                );
                errors = true;
            }
        });

        if (errors) return;

        editDefaultHours({
            variables: {
                client_id: props.shipperId,
                default_store_hours: hoursOpen,
                ...holidayClosures,
            },
        });

        setValidationMessage('Changes saved!');
    };

    return (
        <React.Fragment>
            <h3 style={{ 'margin-top': '25px', marginBottom: '25px' }}>
                Default Hours
                <OverlayTrigger overlay={<Tooltip>Use the table below to set default company-wide hours.</Tooltip>}>
                    <InfoCircleFill size={17} className="mx-1 mb-1" />
                </OverlayTrigger>
            </h3>
            <h4>
                {shipper.business_zip && zipcode_to_timezone.lookup(shipper.business_zip) ||
                    'America/New_York'}
            </h4>
            <Row className="hours-table">
                {/* {daysOfTheWeekMap.map((elt, i) => (
                    <Col className="me-1 px-0 d-flex flex-column align-items-center">
                        <FormControlLabel
                            control={<GreenCheckbox name="checkedG" />}
                            label={elt.label}
                            className="align-self-center my-0"
                            checked={typeof hoursOpen[i].start === 'number'}
                            onChange={() => setHours(elt.day, 'open', i)}
                        />
                        <FormControl className=" my-1 w-100" variant="outlined">
                            <InputLabel>Open</InputLabel>
                            <Select
                                native
                                label="Open"
                                value={hoursOpen[i].start}
                                onChange={(e) => setHours(elt.day, 'start', i, e.target.value)}
                            >
                                <option aria-label="None" value="" />
                                {deliveryHours.hours.map((hour) => (
                                    <option key={hour.value} value={hour.value}>
                                        {hour.text}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className=" my-1 w-100" variant="outlined">
                            <InputLabel>Close</InputLabel>
                            <Select
                                native
                                label="Close"
                                value={hoursOpen[i].close}
                                onChange={(e) => setHours(elt.day, 'close', i, e.target.value)}
                            >
                                <option aria-label="None" value="" />
                                {deliveryHours.hours.map((hour) => (
                                    <option key={hour.value} value={hour.value}>
                                        {hour.text}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                ))} */}

                {[
                    {
                        value: 'sunday',
                        display: 'Sunday',
                    },
                    {
                        value: 'monday',
                        display: 'Monday',
                    },
                    {
                        value: 'tuesday',
                        display: 'Tuesday',
                    },
                    {
                        value: 'wednesday',
                        display: 'Wednesday',
                    },
                    {
                        value: 'thursday',
                        display: 'Thursday',
                    },
                    {
                        value: 'friday',
                        display: 'Friday',
                    },
                    {
                        value: 'satuday',
                        display: 'Saturday',
                    },
                ].map(({ value, display }, idx) => {
                    return (
                        <Col className="me-1 px-0 d-flex flex-column align-items-center" key={value}>
                            <FormControlLabel
                                control={<GreenCheckbox name="checkedG" />}
                                label={display}
                                className="align-self-center my-0"
                                checked={hoursOpen[idx]?.start !== 'closed'}
                                onChange={(e) => {
                                    const clone = [...hoursOpen];

                                    clone[idx] = {
                                        ...clone[idx],
                                        start: clone[idx].start === 'closed' ? 12 : 'closed',
                                        close: clone[idx].close === 'closed' ? 12 : 'closed',
                                    };

                                    // const closed = clone
                                    //     .filter((day) => day.start === 'closed')
                                    //     .map((_day) => _day.day_num);

                                    setHoursOpen(clone);
                                }}
                            />
                            <FormControl className=" my-1 w-100" variant="outlined">
                                <InputLabel>Open</InputLabel>
                                <Select
                                    native
                                    label="Open"
                                    value={hoursOpen[idx].start}
                                    onChange={(e) => {
                                        const clone = [...hoursOpen];
                                        clone[idx] = {
                                            ...clone[idx],
                                            start: e.target.value,
                                        };
                                        // const closed = clone
                                        //     .filter((day) => day.start === 'closed')
                                        //     .map((_day) => _day.day_num);

                                        setHoursOpen(clone);
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {deliveryHours.hours.map((hour) => (
                                        <option key={hour.value} value={hour.value}>
                                            {hour.text}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl className=" my-1 w-100" variant="outlined">
                                <InputLabel>Close</InputLabel>
                                <Select
                                    native
                                    label="Close"
                                    value={hoursOpen[idx].close}
                                    onChange={(e) => {
                                        const clone = [...hoursOpen];
                                        clone[idx] = {
                                            ...clone[idx],
                                            close: e.target.value,
                                        };
                                        clone.filter((day) => day.start === 'closed').map((_day) => _day.day_num);

                                        setHoursOpen(clone);
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {deliveryHours.hours.map((hour) => (
                                        <option key={hour.value} value={hour.value}>
                                            {hour.text}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                    );
                })}
            </Row>
            <Row className="d-flex px-0 w-100 my-2">
                <Row className="my-2 px-0 w-100">
                    <Col className="my-2 px-0 w-100" style={{ marginRight: 0 }}>
                        <h5 className="">Please select the holidays you are closed for:</h5>
                        <Row>
                            <Col md={5} xl={2} className="w-auto">
                                <CustomInput
                                    disabled={props.viewOnly}
                                    checked={holidayClosures.xmas}
                                    onChange={() => setHolidays('xmas')}
                                    size="xxl"
                                    type="switch"
                                    id="Christmas"
                                    style={{
                                        textAlign: 'center !important',
                                        position: 'relative !important',
                                        marginLeft: '',
                                    }}
                                    name="Christmas"
                                    label="Christmas"
                                />
                                <CustomInput
                                    disabled={props.viewOnly}
                                    checked={holidayClosures.xmas_eve}
                                    onChange={() => setHolidays('xmas_eve')}
                                    size="lg"
                                    type="switch"
                                    id="Christmas Eve"
                                    style={{
                                        textAlign: 'center !important',
                                        position: 'relative !important',
                                        marginLeft: '',
                                    }}
                                    name="Christmas Eve"
                                    label="Christmas Eve"
                                />
                                <CustomInput
                                    disabled={props.viewOnly}
                                    checked={holidayClosures.new_years_eve}
                                    onChange={() => setHolidays('new_years_eve')}
                                    size="lg"
                                    type="switch"
                                    id="New Years Eve"
                                    style={{
                                        textAlign: 'center !important',
                                        position: 'relative !important',
                                        marginLeft: '',
                                    }}
                                    name="New Years Eve"
                                    label="New Years Eve"
                                />
                                <CustomInput
                                    disabled={props.viewOnly}
                                    checked={holidayClosures.new_years_day}
                                    onChange={() => setHolidays('new_years_day')}
                                    size="lg"
                                    type="switch"
                                    id="New Years Day"
                                    style={{
                                        textAlign: 'center !important',
                                        position: 'relative !important',
                                        marginLeft: '',
                                    }}
                                    name="New Years Day"
                                    label="New Years Day"
                                />
                            </Col>
                            <Col md={5} xl={2} className="w-auto">
                                <CustomInput
                                    disabled={props.viewOnly}
                                    checked={holidayClosures.labor_day}
                                    onChange={() => setHolidays('labor_day')}
                                    size="lg"
                                    type="switch"
                                    id="Labor Day"
                                    style={{
                                        textAlign: 'center !important',
                                        position: 'relative !important',
                                        marginTop: '',
                                    }}
                                    name="Labor Day"
                                    label="Labor Day"
                                />
                                <CustomInput
                                    disabled={props.viewOnly}
                                    checked={holidayClosures.memorial_day}
                                    onChange={() => setHolidays('memorial_day')}
                                    size="lg"
                                    type="switch"
                                    id="Memorial day"
                                    style={{
                                        textAlign: 'center !important',
                                        position: 'relative !important',
                                        marginTop: '5%',
                                    }}
                                    name="Memorial day"
                                    label="Memorial day"
                                />
                                <CustomInput
                                    disabled={props.viewOnly}
                                    checked={holidayClosures.thanksgiving}
                                    onChange={() => setHolidays('thanksgiving')}
                                    size="lg"
                                    type="switch"
                                    id="Thanksgiving"
                                    style={{
                                        textAlign: 'center !important',
                                        position: 'relative !important',
                                        marginLeft: '',
                                    }}
                                    name="Thanksgiving"
                                    label="Thanksgiving"
                                />
                                <CustomInput
                                    disabled={props.viewOnly}
                                    checked={holidayClosures.july_fourth}
                                    onChange={() => setHolidays('july_fourth')}
                                    size="lg"
                                    type="switch"
                                    id="July 4th"
                                    style={{
                                        textAlign: 'center !important',
                                        position: 'relative !important',
                                        marginLeft: '',
                                    }}
                                    name="July 4th"
                                    label="July 4th"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
            <Row className="d-flex flex-column align-items-start">
                {validationMessage && <div className="my-1">{validationMessage}</div>}
                <Button variant="success" style={{ marginBottom: '25px', width: '25%' }} onClick={handleSave}>
                    Save Changes
                </Button>
            </Row>
        </React.Fragment>
    );
}

export default AdminShipper;
