const devConfig = {
    HASURA_URL: 'http://localhost:8080/v1/graphql',
    WS_HASURA_URL: 'ws://localhost:8080/v1/graphql',
    STRIPE_PK: 'pk_test_HrMrx9FzlVtiZdchU0ptwIK100cCrxV6l1',
    MAPBOX_PK: 'pk.eyJ1Ijoia2V2aW5td2Fsc2giLCJhIjoiY2xxNXYyNXJkMGt6cTJsbXh6NjdrbjZ0YSJ9.ndnTxGkCC6udz5s5x0w2kw',
    SMPLR_SPACE_TOKEN: "pub_f3e7f2c8bded4f4ab786cb44cc5b02c7",
};

const stagingConfig = {
    HASURA_URL: 'https://genuine-warthog-74.hasura.app/v1/graphql',
    WS_HASURA_URL: 'wss://genuine-warthog-74.hasura.app/v1/graphql',
    STRIPE_PK: 'pk_test_HrMrx9FzlVtiZdchU0ptwIK100cCrxV6l1',
    MAPBOX_PK: 'pk.eyJ1Ijoia2V2aW5td2Fsc2giLCJhIjoiY2xxNXYyNXJkMGt6cTJsbXh6NjdrbjZ0YSJ9.ndnTxGkCC6udz5s5x0w2kw',
    SMPLR_SPACE_TOKEN: "pub_f3e7f2c8bded4f4ab786cb44cc5b02c7",
};

const prodConfig = {
    HASURA_URL: 'https://hasura.onwarddelivery.com/v1/graphql',
    WS_HASURA_URL: 'wss://hasura.onwarddelivery.com/v1/graphql',
    STRIPE_PK: 'pk_live_GkLHbJguG8egPK2kovoxdBCa006YTIcMMU',
    MAPBOX_PK: 'pk.eyJ1Ijoia2V2aW5td2Fsc2giLCJhIjoiY2xxNXY4MHZ3MG16bjJqcTBzZjc3cTZ6biJ9.Oc8sh6wFbrpKCVGF0lI-Sw',
    SMPLR_SPACE_TOKEN: "pub_f3e7f2c8bded4f4ab786cb44cc5b02c7",
};

const ENV_CONFIG = {
    development: devConfig,
    staging: stagingConfig,
    production: prodConfig,
};

const env = process.env.REACT_APP_ENVIRONMENT || 'development';

export const CONFIG = {
    ...ENV_CONFIG[env],
    ENV: env,
};
