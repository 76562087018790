import React, { useContext, useState, useMemo, useEffect } from 'react';
import { PlanningContext } from '../context';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import { MODALS } from '../constants';
import { useMutation } from '@apollo/client';
import { UPDATE_ROUTE_STOPS } from '../graphql/mutations';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import * as Sentry from '@sentry/react';
import { cloneDeep } from 'lodash';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { useClientUser } from '@/hooks';
import StartTimeSelect from '../../misc/StartTimeSelect';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { genAttributes } from '@onward-delivery/core';
const CustomTimeframeModal = () => {
    const {
        actionableRoute,
        setActionableRoute,
        actionableStop,
        setActionableStop,
        modalOpen,
        setModalOpen,
        setError,
        deliveryDate,
    } = useContext(PlanningContext);
    const { user_id } = useClientUser();
    const [startDelWindow, setStartDelWindow] = useState();
    const [endDelWindow, setEndDelWindow] = useState();
    const [timeError, setTimeError] = useState(null);

    useEffect(() => {
        setStartDelWindow(actionableStop?.del_window_start);
        setEndDelWindow(actionableStop?.del_window_end);
    }, [actionableStop]);

    const routeTimeZone = useMemo(() => {
        const order = actionableRoute?.orders?.[0]?.order || {};
        const { zip } = genAttributes(order);
        return zipcode_to_timezone.lookup(order[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [actionableRoute]);

    const [updateStops, { loading }] = useMutation(UPDATE_ROUTE_STOPS);

    const handleModalClose = () => {
        setModalOpen(null);
        setActionableRoute(null);
        setActionableStop(null);
        setTimeError(null);
    };
    const handleTimeFrameSelect = (e, type) => {
        let date = new Date(e.target.value).toISOString();
        if (type === 'start') {
            if (date > endDelWindow) {
                setTimeError('Start time must be before end time');
                return;
            }
            setStartDelWindow(date);
        } else {
            if (date < startDelWindow) {
                setTimeError('End time must be after start time');
                return;
            }
            setEndDelWindow(date);
        }
        setTimeError(null);
    };

    const handleTimeframeUpdate = () => {
        let newStops = cloneDeep(actionableRoute?.stopsByRouteId || []);
        if (actionableStop.del_window_end === endDelWindow && actionableStop.del_window_start === startDelWindow)
            return;
        const updatedStops = newStops.map((stop) =>
            stop.stop_id === actionableStop.stop_id
                ? { ...stop, del_window_start: startDelWindow, del_window_end: endDelWindow }
                : stop
        );

        updateStops({
            variables: {
                route_id: actionableRoute?.route_id,
                route_update: {},
                order_updates:
                    actionableStop?.stopOrders?.map((order) => ({
                        where: { order_id: { _eq: order.order_id } },
                        _set: {
                            del_window_start: startDelWindow,
                            del_window_end: endDelWindow,
                        },
                    })) || [],
                ...FTLStopHelpers.gqlStopUpdates(updatedStops, actionableRoute),
            },
            onError: (error) => {
                setError(
                    error,
                    `Failed to update timesframes for stop(s) ${actionableStop?.ordering} from route ${actionableRoute?.route_number}`
                );
            },
            onCompleted: () => {
                handleModalClose();
            },
        });
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.CUSTOM_TIMEFRAME} onClose={handleModalClose}>
            <ModalTitle>{`Customize timeframes for Stop ${actionableStop?.ordering} on Route ${actionableRoute?.route_number}`}</ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <div>Start Time</div>
                    <div>
                        <StartTimeSelect
                            timeZone={routeTimeZone}
                            value={startDelWindow ? new Date(startDelWindow).getTime() : 0}
                            onChange={(e) => handleTimeFrameSelect(e, 'start')}
                            label="Start Time"
                            deliveryDate={deliveryDate}
                            interval={15}
                        />
                    </div>
                </GridItemRow>
                <GridItemRow>
                    <div>End Time</div>
                    <div>
                        <StartTimeSelect
                            timeZone={routeTimeZone}
                            value={endDelWindow ? new Date(endDelWindow).getTime() : 0}
                            onChange={(e) => handleTimeFrameSelect(e, 'end')}
                            label="End Time"
                            deliveryDate={deliveryDate}
                            interval={15}
                        />
                    </div>
                </GridItemRow>
                {timeError && (
                    <GridItemRow>
                        <Body2 style={{ color: 'red', marginTop: '10px', marginLeft: '10%', alignContent: 'center' }}>
                            Error: {timeError}
                        </Body2>
                    </GridItemRow>
                )}
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading || timeError} onClick={handleTimeframeUpdate}>
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default CustomTimeframeModal;
