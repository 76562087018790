import React, { useContext, useEffect, useState } from 'react';
import withAdminRights from '@/components/Auth/withAdminRights';
import { compose } from 'recompose';
import { Container, Content, Header } from './blocks';
import { TextField } from '@material-ui/core';
import { Context, withContext } from './store';
import MarketplaceSidebar from './sidebars/MarketplaceSidebar';
import SupplySidebar from './sidebars/SupplySidebar';
import Map from './map/Map';
import SupplyStopsSidebar from './sidebars/SupplyStopsSidebar';
import hubspotControls from '@/utilities/hubspotControls';
import { css } from '@emotion/react';

const AdminMatchingTool = () => {
    const { state, callbacks } = useContext(Context);
    const [distanceDebouncer, setDistanceDebouncer] = useState(null);
    const [distanceInput, setDistanceInput] = useState(state.distance);

    useEffect(() => {
        hubspotControls.hide();
        return () => {
            hubspotControls.show();
        };
    });

    const onDistanceChange = (e) => {
        const value = e.target.value;
        setDistanceInput(value);
        if (distanceDebouncer) {
            clearTimeout(distanceDebouncer);
        }
        setDistanceDebouncer(
            setTimeout(() => {
                callbacks.setDistance(value);
            }, 500)
        );
    };

    return (
        <Container>
            <Header>
                <TextField
                    type="date"
                    variant="outlined"
                    label="Scheduled Delivery"
                    disabled={state.loading}
                    value={state.deliveryDate}
                    onChange={(e) => {
                        const value = e.target.value;
                        window.localStorage?.setItem('onward.adminMatchingTool.deliveryDate', value);
                        callbacks.setDeliveryDate(value);
                    }}
                />
                <TextField
                    type="number"
                    variant="outlined"
                    label="Distance (miles)"
                    disabled={state.loading}
                    value={distanceInput}
                    onChange={onDistanceChange}
                    css={css`
                        margin-left: 2rem;
                    `}
                />
            </Header>
            <Content>
                <SupplySidebar />
                {state.selectedRoute && state.selectedOrder?.__typename === 'orders' && <SupplyStopsSidebar />}
                <Map />
                <MarketplaceSidebar />
            </Content>
        </Container>
    );
};

export default compose(withAdminRights, withContext)(AdminMatchingTool);
