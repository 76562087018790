export default function geocodeByLatLng(latLng) {
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK') {
                const toReturn = {};
                const components = results[0].address_components;

                const streetNum = components.find((component) => component.types.includes('street_number'));
                const street = components.find((component) => component.types.includes('route'));
                const city = components.find((component) => component.types.includes('locality'));
                const state = components.find((component) => component.types.includes('administrative_area_level_1'));
                const zip = components.find((component) => component.types.includes('postal_code'));

                // Resolve false if no street number or street found (need those for mailing address)
                // This check may prove to be inconsistent
                if (!streetNum || !street) {
                    resolve(false);
                }

                if (streetNum?.long_name || street?.long_name) {
                    toReturn.street =
                        (streetNum?.long_name ? streetNum.long_name : '') +
                        (street?.long_name ? ' ' + street.long_name : '');
                }

                if (city?.long_name) {
                    toReturn.city = city.long_name;
                }

                if (state?.long_name) {
                    toReturn.state = state.long_name;
                }

                if (zip?.long_name) {
                    toReturn.zip = zip.long_name;
                }

                if (results[0].formatted_address) {
                    toReturn.fulladdress = results[0].formatted_address;
                    toReturn.address = results[0].formatted_address;
                }

                resolve(toReturn);
            } else {
                reject('Geocode unsuccessful: ' + status);
            }
        });
    });
}
