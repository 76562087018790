import React from 'react';
import { useNavigate } from 'react-router';
import Notification from '..';
import { NotificationMetadata, NotificationTimestamp, NotificationTitle } from '../blocks';
import { format } from 'date-fns';

const WatchedLtlOpenedNotification = ({ notification, handleCloseNotification }) => {
    const navigate = useNavigate();

    const hadPrebid = notification?.notification_data?.hadPrebid;

    return (
        <Notification
            notification={notification}
            onClickHandler={() => {
                navigate(`/carrier/loads/ltl/${notification?.notification_data?.order_id}`);
                handleCloseNotification();
            }}
        >
            {hadPrebid ? (
                <>
                    <NotificationTitle>Prebid Load Available</NotificationTitle>
                    <NotificationMetadata>
                        A load that you placed a pre-bid on is now available and your bid has now been placed on it!
                    </NotificationMetadata>
                </>
            ) : (
                <>
                    <NotificationTitle>LTL Available</NotificationTitle>
                    <NotificationMetadata>
                        A pending LTL order that you followed is now available for bidding / claiming{' '}
                    </NotificationMetadata>
                </>
            )}
            <NotificationMetadata>Order: {notification.notification_data?.order_number || 'N/A'}</NotificationMetadata>
            <NotificationTimestamp>
                {format(new Date(notification.created_at), 'MMM dd, yyyy @ hh:mm a')}
            </NotificationTimestamp>
        </Notification>
    );
};

export default WatchedLtlOpenedNotification;

