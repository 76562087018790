import freightTypes from './freightTypes';

export default {
    [freightTypes.household.value]: {
        Furniture: {
            value: 'Furniture',
            label: 'Furniture',
            displayOrder: 0,
        },
        Mattress: {
            value: 'Mattress',
            label: 'Mattress',
            displayOrder: 1,
        },
        Boxspring: {
            value: 'Box Spring',
            label: 'Box Spring',
            displayOrder: 2,
        },
        Bedframe: {
            value: 'Bed Frame',
            label: 'Bed Frame',
            displayOrder: 3,
        },
        Appliance: {
            value: 'Appliance',
            label: 'Appliance',
            displayOrder: 4,
        },
        Other: {
            value: 'Other',
            label: 'Other',
            displayOrder: 5,
        },
    },
    [freightTypes.remodelling.value]: {
        cabinet: {
            value: 'Cabinets',
            label: 'Cabinets',
            displayOrder: 0,
        },
        carpet: {
            value: 'Carpets',
            label: 'Carpets',
            displayOrder: 1,
        },
        door: {
            value: 'Doors',
            label: 'Doors',
            displayOrder: 2,
        },
        window: {
            value: 'Windows',
            label: 'Windows',
            displayOrder: 3,
        },
        other: {
            value: 'Other',
            label: 'Other',
            displayOrder: 4,
        },
    },
};
