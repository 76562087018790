import { ACTIVITY_ORDER_FIELDS } from '@/graphql/fragments/activity_orders';
import { ACTIVITY_ROUTE_FIELDS } from '@/graphql/fragments/activity_routes';
import { ACTIVITY_STOP_FIELDS } from '@/graphql/fragments/activity_stops';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ROUTE_FIELDS, ROUTE_ORDER_FIELDS } from '@/graphql/queries/routes';
import { STOP_FIELDS } from '@/graphql/queries/stops';
import { gql } from '@apollo/client';

export const QUERY_ONWARD_ROUTES = gql`
    ${ROUTE_FIELDS}
    ${ROUTE_ORDER_FIELDS}
    ${STOP_FIELDS}
    query GetOnwardRoutes($start: timestamptz, $end: timestamptz, $geolocation: routes_bool_exp!, $limit: Int) {
        routes(
            where: {
                _and: [{ scheduled_delivery: { _gte: $start } }, { scheduled_delivery: { _lt: $end } }, $geolocation]
            }
            limit: $limit
        ) {
            ...RouteFields
            ...RouteOrderFields
            stopsByRouteId {
                ...StopFields
                point
            }
        }
    }
`;

export const QUERY_SUPPLY_ROUTES = gql`
    ${ACTIVITY_ROUTE_FIELDS}
    ${ACTIVITY_STOP_FIELDS}
    query GetSupplyRoutes(
        $start: timestamptz
        $end: timestamptz
        $geolocation: activity_routes_bool_exp!
        $limit: Int
    ) {
        activity_routes(
            where: { _and: [{ delivery_date: { _gte: $start } }, { delivery_date: { _lt: $end } }, $geolocation] }
            limit: $limit
        ) {
            ...ActivityRouteFields
            activity_stops {
                ...ActivityStopFields
            }
        }
    }
`;

export const QUERY_SUPPLY_ORDERS = gql`
    ${ACTIVITY_ORDER_FIELDS}
    query GetSupplyOrders($start: timestamptz, $end: timestamptz) {
        activity_orders(
            where: {
                _and: [
                    { delivery_date: { _gte: $start } }
                    { delivery_date: { _lt: $end } }
                    { route_id: { _is_null: true } }
                ]
            }
        ) {
            ...ActivityOrderFields
        }
    }
`;

export const QUERY_MARKETPLACE_ORDERS = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    query GetMarketplaceOrders($start: timestamptz, $end: timestamptz, $test: Boolean) {
        orders(
            where: {
                _and: [
                    {
                        _or: [
                            { _and: [{ delivery_date: { _gte: $start } }, { delivery_date: { _lt: $end } }] }
                            { first_available_date: { _lt: $end } }
                        ]
                    }
                    {
                        _or: [
                            { order_status: { _eq: "open" } }
                            { _and: [{ order_status: { _eq: "pending" } }, { oms: { _eq: false } }] }
                        ]
                    }
                    { order_shipper: { test_acc: { _eq: $test } } }
                    { carrier_id: { _is_null: true } }
                ]
            }
        ) {
            ...OrderFields
            itemsByOrderId {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
            returns @client {
                ...ItemFields
            }
            order_shipper {
                client_id
                business_name
            }
        }
    }
`;
