import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { SecondaryButton } from '@/styles/blocks';
import { Grid, TextField } from '@material-ui/core';
import { ResponsiveSidebarDialog, StickyModalActions, ModalContent, ModalTitle, PrimaryButton } from '@/styles/blocks';
import { Header2 } from '../Crossdocking/blocks';
import { ItemPieceCountSelect } from '@/components/ShipmentForm/modals/InputFields';
import { UPDATE_ITEM_PIECE_COUNTS } from '@/components/ShipmentForm/graphql/mutations';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useClientUser } from '@/hooks';

const UpdateItemPieceCountModal = ({ order, open, onClose, onConfirm = null, items = [] }) => {
    const { user_id } = useClientUser();

    const currentItemCounts = useMemo(() => {
        return Object.fromEntries(
            items.map((i) => {
                return [
                    i.item_id,
                    {
                        ...i,
                    },
                ];
            })
        );
    }, [items]);

    const [updates, setUpdates] = useState({});

    const updated = useMemo(() => {
        return {
            ...currentItemCounts,
            ...updates,
        };
    }, [items, updates]);

    const [updateItemPieceCounts, {loading}] = useMutation(UPDATE_ITEM_PIECE_COUNTS, {
        onCompleted: () => {
            !!onConfirm && onConfirm();
            onClose();
        },
        onError: (err) => {
            console.log(err);
            Sentry.captureException(err);
        }
    });


    return (
        <ResponsiveSidebarDialog open={!!open} onClose={onClose} maxWidth="lg">
            <ModalTitle onClose={onClose}>
                <Header2
                    css={css`
                        color: #4c4c4c;
                    `}
                >
                    Confirm Box / Piece Count for {order?.order_number || '-'}
                </Header2>
            </ModalTitle>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                <Grid
                    direction="column"
                    container
                    justifyContent="center"
                    css={css`
                        gap: 2.5rem;
                    `}
                >
                    {Object.entries(updated).map(([key, i]) => (
                        <Grid
                            item
                        >
                            <div
                                css={css`
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19.5px;
                                    color: #2b2b2b;
                                    margin-bottom: 1rem;
                                `}
                            >
                                {i?.item_type_details?.length
                                    ? `${i.item_type_details?.[0].toUpperCase()}${i.item_type_details.slice(
                                          1,
                                          i.item_type_details.length
                                      )}`
                                    : ''}
                                {i?.sku && ` - ${i.sku}`}
                            </div>
                            <ItemPieceCountSelect
                                value={i.item_piece_count}
                                onChange={(e) => {
                                    setUpdates((prev) => {
                                        return {
                                            ...prev,
                                            [key]: {
                                                ...currentItemCounts[key],
                                                item_piece_count: e.target.value,
                                            }
                                        };
                                    });
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton
                        disabled={loading || Object.entries(updated).every(i => !!i?.item_piece_count)}
                        onClick={() => {
                            const itemUpdatesArray = Object.values(updated)?.reduce((acc, item) => {
                                return [
                                    ...acc,
                                    { 
                                        where: {
                                            item_id: {_eq: item.item_id}}, 
                                            _set: {item_piece_count: item.item_piece_count} 
                                    },
                                ];
                            }, []);

                            updateItemPieceCounts({
                                variables: {
                                    item_updates: itemUpdatesArray,
                                    order_id_string: `%${order.order_id}%`,
                                    client_id: user_id,
                                    order_id: order.order_id,
                                }
                            });
                        }}
                    >
                        Done
                    </PrimaryButton>


                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default UpdateItemPieceCountModal;
