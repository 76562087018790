import { useEffect, useRef } from 'react';

function Layer({ map, id, type = 'fill', paint, filter, ...props }) {
    useEffect(() => {
        if (map) {
            map.on('styledata', () => {
                if (!map.getLayer(id)) {
                    map.addLayer({
                        ...props,
                        id,
                        type,
                        paint,
                        ...(filter ? { filter } : {}),
                    });
                }
            });
        }
    }, [map]);

    useEffect(() => {
        if (map && map.getLayer(id)) {
            map.setFilter(id, filter);
        }
    }, [id, filter]);

    useEffect(() => {
        if (map && map.getLayer(id)) {
            Object.entries(paint).forEach(([key, value]) => {
                map.setPaintProperty(id, key, value);
            });
        }
    }, [map, paint, id]);

    return null;
}

export default Layer;
