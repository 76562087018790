import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Grid, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import statesInverted from '@/constants/statesInverted.json';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import { LocationOn, NotListedLocation } from '@material-ui/icons';
import { BodyCellText } from '../MyOrders/blocks';
import { useOrderCarrierPricing } from '@/hooks';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DoneIcon from '@mui/icons-material/Done';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import Modal from '@mui/material/Modal';
import { CardTitle, CardSubtitle } from '../OrderDetailsPage/blocks';
import { Text, View } from '@react-pdf/renderer';
import Image from 'react-bootstrap/Image';

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const dateShort = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
});

export const useTableColumns = ({ internalTab, shipping_partners, subTab, callbacks }) => {
    return useMemo(() => {
        return [
            {
                Header: 'Order Details',
                id: 'order-details-group',
                columns: [
                    {
                        Header: 'Order',
                        id: 'order_number',
                        width: 100,
                        disableSortBy: false,
                        accessor: (order) => order.order_number,
                    },
                    ...(subTab === 2 || subTab === 3
                        ? [
                              {
                                  Header: 'Invoice No.',
                                  id: 'invoice_number',
                                  width: 150,
                                  disableSortBy: true,
                                  accessor: (order) => order.carrier_invoice?.invoice_number || 'N/A',
                              },
                          ]
                        : []),
                    {
                        Header: 'PO',
                        id: 'po_number',
                        width: 150,
                        disableSortBy: false,
                        accessor: (order) => (!order.po_number ? 'N/A' : order.po_number),
                    },
                    {
                        Header: 'Shipper',
                        id: 'shipper',
                        width: 200,
                        disableSortBy: false,
                        accessor: (order) => order.order_shipper?.business_name || '-',
                    },
                    {
                        Header: 'Completed',
                        id: 'completed_date',
                        width: 175,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const order = row.original;
                            return order.delivery_date_formatted || order.delivery_date_formatted || '-';
                        },
                    },
                    {
                        Header: 'POD',
                        id: 'pod',
                        width: 110,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const order = row.original;

                            return order.delivery_photo?.length >= 1 && order.pod?.length >= 1 ? (
                                <>
                                    <DoneIcon
                                        css={css`
                                            color: ${colors.greens.primary};
                                        `}
                                    />
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            callbacks.setPODModalOrder(order);
                                        }}
                                        css={css`
                                            color: ${colors.greens.primary};
                                            text-decoration: underline;
                                            margin: 5px;
                                            font-weight: 500;
                                            background: none;
                                            border: none;
                                            cursor: pointer;
                                        `}
                                    >
                                        View
                                    </button>
                                </>
                            ) : (
                                <CloseIcon
                                    css={css`
                                        color: ${colors.reds.primary};
                                        background: none;
                                    `}
                                />
                            );
                        },
                    },
                    ...(subTab === 0
                        ? [
                              {
                                  Header: 'Pending Issues',
                                  id: 'pending_issues',
                                  width: 150,
                                  disableSortBy: true,
                                  accessor: (order) => {
                                      return (
                                          order.exceptions.filter((mapping) => mapping.exception.status !== 'RESOLVED')
                                              .length > 0
                                      );
                                  },
                                  Cell: ({ value }) => {
                                      if (value) {
                                          return (
                                              <WarningIcon
                                                  css={css`
                                                      color: ${colors.reds.primary};
                                                  `}
                                              />
                                          );
                                      }

                                      return '--';
                                  },
                              },
                          ]
                        : []),
                    {
                        Header: 'Customer',
                        id: 'dropoff_name',
                        width: 200,
                        disableSortBy: false,
                        accessor: (order) => order.dropoff_name,
                    },
                    {
                        Header: 'Pick Up',
                        id: 'pickup',
                        width: 250,
                        disableSortBy: false,
                        accessor: (order) => [order.pickup_city, order.pickup_state, order.pickup_zip].join(':'),
                        Cell: ({ row: order }) => {
                            const { state, zip, city } = useSwappedAttributes(order.original, true);
                            const stateLookup = statesInverted[order.original[state]]
                                ? statesInverted[order.original[state]]
                                : order.original[state];
                            return `${order.original[city]}, ${stateLookup} ${order.original[zip]}`;
                        },
                    },
                    {
                        Header: 'Drop Off',
                        id: 'dropoff',
                        width: 250,
                        disableSortBy: false,
                        accessor: (order) => [order.dropoff_city, order.dropoff_state, order.dropoff_zip].join(':'),
                        Cell: ({ value, row: order }) => {
                            const { address, state, zip, city } = useSwappedAttributes(order.original);
                            const stateLookup = statesInverted[order.original[state]]
                                ? statesInverted[order.original[state]]
                                : order.original[state];

                            if (order.original.dropoff_address_confirmed) {
                                return (
                                    <Grid
                                        container
                                        css={css`
                                            align-items: center;
                                            flex-wrap: nowrap;
                                        `}
                                    >
                                        <Grid
                                            item
                                            css={css`
                                                margin-right: 4px;
                                            `}
                                        >
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={`Customer confirmed: ${order.original[address]}`}
                                            >
                                                <CheckCircleIcon
                                                    css={css`
                                                        color: ${colors.greens.primary};
                                                    `}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        {`${order.original[city]}, ${stateLookup} ${order.original[zip]}`}
                                    </Grid>
                                );
                            } else {
                                return `${order.original[city]}, ${stateLookup} ${order.original[zip]}`;
                            }
                        },
                    },
                    {
                        Header: 'Subtotal',
                        id: 'subtotal',
                        width: 125,
                        disableSortBy: true,
                        Cell: ({ row }) => {
                            const order = row.original;
                            const breakdown = internalTab ? 'internalBreakdown' : 'carrierBreakdown';
                            return dollarFormatter.format(
                                FIXED_CHARGES.map(({ key: attr }) => {
                                    return order?.price_breakdown?.[breakdown]?.[attr] || 0;
                                }).reduce((acc, val) => {
                                    return acc + val;
                                }, 0)
                            );
                        },
                    },
                    {
                        Header: 'Accessorials',
                        id: 'accessorials',
                        width: 125,
                        disableSortBy: true,
                        Cell: ({ row }) => {
                            const order = row.original;
                            const breakdown = internalTab ? 'internalBreakdown' : 'carrierBreakdown';
                            return dollarFormatter.format(
                                (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce(
                                    (acc, { quantity, rate }) => {
                                        return acc + quantity * rate;
                                    },
                                    0
                                )
                            );
                        },
                    },
                    {
                        Header: 'Revenue',
                        id: 'revenue',
                        width: 125,
                        disableSortBy: true,
                        Cell: ({ row }) => {
                            const order = row.original;
                            if (!internalTab) return useOrderCarrierPricing(order);

                            return dollarFormatter.format(order.order_revenue || 0);
                        },
                    },
                ],
            },
            {
                sticky: 'right',
                Header: 'Actions',
                id: 'actions-group',
                columns: [
                    ...(subTab === 0
                        ? [
                              {
                                  Header: '',
                                  id: 'action-1',
                                  width: subTab === 0 ? 45 : 0,
                                  Cell: ({ row }) => {
                                      const order = row.original;
                                      const [anchorEl, setAnchorEl] = React.useState(null);

                                      const handleClick = (event) => {
                                          event.stopPropagation();
                                          event.preventDefault();
                                          setAnchorEl(event.currentTarget);
                                      };

                                      const handleClose = (event) => {
                                          event?.stopPropagation();
                                          event?.preventDefault();
                                          setAnchorEl(null);
                                      };

                                      const handleReadyClick = (order_id) => {
                                          callbacks.handleReadyToInvoice([order_id]);
                                          handleClose();
                                      };

                                      if (subTab === 0) {
                                          return (
                                              <div
                                                  css={css`
                                                      color: #59b863;
                                                      font-weight: 600;
                                                      display: flex-start;
                                                      justify-content: flex-start;
                                                      align-items: center;
                                                  `}
                                              >
                                                  <IconButton
                                                      onClick={handleClick}
                                                      css={css`
                                                          font-weight: 600;
                                                          width: 15px;
                                                          padding: 0px;
                                                          margin: 0px;
                                                          display: flex-start;
                                                          justify-content: center;
                                                      `}
                                                  >
                                                      <MoreVertIcon />
                                                  </IconButton>
                                                  <Popover
                                                      open={Boolean(anchorEl)}
                                                      anchorEl={anchorEl}
                                                      onClose={handleClose}
                                                      anchorOrigin={{
                                                          vertical: 'bottom',
                                                          horizontal: 'center',
                                                      }}
                                                      transformOrigin={{
                                                          vertical: 'top',
                                                          horizontal: 'center',
                                                      }}
                                                      style={{ padding: '10px', borderRadius: '20px' }}
                                                  >
                                                      <Button
                                                          style={{ padding: '10px' }}
                                                          onClick={(event) => {
                                                              event.stopPropagation();
                                                              handleReadyClick(order.order_id);
                                                          }}
                                                      >
                                                          Approve for Invoicing
                                                      </Button>
                                                  </Popover>
                                              </div>
                                          );
                                      }
                                      return null;
                                  },
                                  disableSortBy: true,
                                  span: false,
                                  show: subTab !== 1,
                              },
                          ]
                        : []),
                ],
            },
        ];
    }, [internalTab, shipping_partners, subTab]);
};
