import { gql } from '@apollo/client';
import { ROUTE_FIELDS } from '@/graphql/queries/routes';

export const SEND_TO_PLANNING_BY_ID = gql`
    ${ROUTE_FIELDS}

    mutation SendToPlanning($route_id: uuid) {
        update_routes(where: { route_id: { _eq: $route_id } }, _set: { status: "planning", planning: true }) {
            affected_rows
            returning {
                ...RouteFields
            }
        }
    }
`;

export const SET_DRIVER_BY_ID = gql`
    ${ROUTE_FIELDS}

    mutation SetDriverByID($route_id: uuid, $driver_id: uuid) {
        update_routes(where: { route_id: { _eq: $route_id } }, _set: { driver_id: $driver_id }) {
            affected_rows
            returning {
                ...RouteFields
            }
        }
    }
`;

export const SET_FINAL_RETURN_DRIVER_BY_ID = gql`
    ${ROUTE_FIELDS}
    mutation SetFinalReturnDriverByID($route_id: uuid, $driver_id_final_return: uuid) {
        update_routes(where: { route_id: { _eq: $route_id } }, _set: { driver_id_final_return: $driver_id_final_return }) {
            affected_rows
            returning {
                ...RouteFields
            }
        }
    }
`;