import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import useQuery from '@/utilities/useQuery';
import { Grid, TextField } from '@material-ui/core';

import { trackOrder } from './queries/trackOrder';
import { FORM_STATUS } from './constants';
import { local, FlexRow, FlexColumn, Header1, PrimaryButton, SecondaryButton } from './blocks';

function Track({ isDesktop, callbacks }) {
    const navigate = useNavigate();
    const [form, setForm] = useState({});
    const [error, setError] = useState(null);

    const [getToken, { loading: inFlight }] = useQuery(trackOrder, {
        onError: (err) => {
            console.error(err);
            callbacks.onTrackFailed();
        },
    });

    const handleTrackOrder = () => {
        if (!form.order_number) {
            setError('Please enter your order number, including the OD prefix.');
            return;
        }
        if (!form.name) {
            setError('Please enter your full name as it appears on the order.');
            return;
        }
        setError(null);
        getToken(form).then((resp) => {
            if (!!resp?.token) {
                navigate(`/customer/track?token=${resp.token}`);
            }
            if (resp?.body) {
                setError(resp.body);
            } else {
                setError(
                    'Unexpected error, please try again. Contact help@onwarddelivery.com if the problem persists.'
                );
            }
        });
    };

    return (
        <>
            <Grid
                container
                css={css`
                    justify-content: center;
                    text-align: center;
                    color: ${local.greys[4]};
                    margin-top: -20px;
                    margin-bottom: 20px;
                `}
            >
                <Header1>Track Order</Header1>
            </Grid>
            <FlexRow>
                <FlexColumn
                    css={css`
                        max-width: 260px;
                        margin-bottom: 20px;
                    `}
                >
                    <TextField
                        variant="outlined"
                        label="Order Number (OD123)"
                        InputLabelProps={{ shrink: !!form.order_number }}
                        value={form.order_number || ''}
                        onChange={(e) => {
                            setForm((prev) => ({ ...prev, order_number: e.target.value.toUpperCase() }));
                        }}
                    />
                </FlexColumn>
                <FlexColumn
                    css={css`
                        max-width: 260px;
                        margin-bottom: 20px;
                    `}
                >
                    <TextField
                        variant="outlined"
                        label="Full Name"
                        InputLabelProps={{ shrink: !!form.name }}
                        value={form.name || ''}
                        onChange={(e) => {
                            setForm((prev) => ({ ...prev, name: e.target.value }));
                        }}
                    />
                </FlexColumn>
            </FlexRow>

            <Grid
                container
                css={css`
                    justify-content: center;
                    text-align: center;
                    padding: 0 20px;
                `}
            >
                <SecondaryButton
                    disabled={inFlight}
                    fullWidth
                    css={css`
                        max-width: ${isDesktop ? '100%' : '260px'};
                    `}
                    onClick={handleTrackOrder}
                >
                    Track Order
                </SecondaryButton>
            </Grid>

            {error && (
                <Grid
                    container
                    css={css`
                        justify-content: center;
                        margin-left: ${isDesktop ? '0px' : '25px'};
                        padding: 0 20px;
                        margin-top: 20px;
                    `}
                >
                    <div style={{ fontWeight: '600', color: 'red' }}>{error}</div>
                </Grid>
            )}
        </>
    );
}

export default Track;
