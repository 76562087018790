import { useMutation } from '@apollo/client';
import React, { useContext, useMemo } from 'react';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { UPDATE_ORDERS } from '../graphql/mutations';
import * as Sentry from '@sentry/react';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';

const OrdersUnassignedToOnward = () => {
    const {
        modalOpen,
        setModalOpen,
        setError,
        state: { orders },
    } = useContext(PlanningContext);

    const unassignedOrders = useMemo(() => {
        return orders.filter(
            (order) => !(order.crossdock_leg === 'pickup' ? order.pickup_route_id : order.dropoff_route_id)
        );
    }, [orders]);

    const [updateOrders, { loading }] = useMutation(UPDATE_ORDERS);

    const handleModalClose = () => {
        setModalOpen(null);
    };

    const sendUnassignedOrdersToOnward = () => {
        updateOrders({
            variables: {
                order_ids: unassignedOrders.map((order) => order.order_id),
                update: {
                    oms: false,
                    planning: false,
                },
            },
            onCompleted: handleModalClose,
            onError: (error) => {
                setError(error, 'Error sending unassigned orders to Onward');
            },
        });
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ORDER_UNASSIGNED_MARKETPLACE} onClose={handleModalClose}>
            <ModalTitle>{`Send all unassigned orders to Onward?`}</ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <Body2>Are you sure you want to send all unassigned orders to Onward?</Body2>
                </GridItemRow>
                <GridItemRow>
                    <Body2>Orders: {unassignedOrders.map((o) => o.order_number).join(', ')}</Body2>
                </GridItemRow>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={sendUnassignedOrdersToOnward}>
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default OrdersUnassignedToOnward;
