import React, { useContext } from 'react';
import { compose } from 'recompose';
import { ADVANCED_SCHEDULING } from '@/constants/featureFlags';
import withFeatureFlag from '@/components/Auth/withFeatureFlag';
import { css } from '@emotion/react';
import { Search } from '@material-ui/icons';
import { TextField, Grid, InputAdornment, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import Snackbar from '@/components/Snackbar';

import Table from './Table';
import Footer from './Footer';
import { Context, ContextProvider } from './store';
import { H1, Body1 } from './blocks';
import ScheduleOrderModal from './modals/ScheduleOrderModal';

export const ResourceNotifications = ({ context }) => {
    const { state, loading, callbacks } = useContext(context);

    return (
        <>
            <Grid
                direction="column"
                container
                css={css`
                    width: 100%;
                    height: 100%;
                `}
            >
                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        padding: 40px;
                        padding-bottom: 0;
                        margin-bottom: 12px;
                    `}
                >
                    <H1>Notifications</H1>
                </Grid>

                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        flex-wrap: nowrap;
                        padding: 0 40px;
                        padding-bottom: 0;
                        margin-bottom: 12px;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            min-width: 250px;
                            margin-right: 20px;
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            select
                            fullWidth
                            label="Resources"
                            value={state?.filter?.resources || []}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                callbacks.setFilter((prev) => {
                                    return {
                                        ...prev,
                                        resources: e.target.value,
                                    };
                                });
                            }}
                            SelectProps={{
                                multiple: true,
                                renderValue: (selected) => {
                                    return selected.map((resource) => resource.name).join(', ');
                                },
                            }}
                            css={css`
                                background-color: white;
                                width: 100%;
                                padding-right: 2px;
                                margin-top: 0.2rem;
                            `}
                        >
                            {state.resources.map((resource) => (
                                <MenuItem key={resource.resource_id} value={resource}>
                                    <Checkbox
                                        color="primary"
                                        checked={
                                            !!(state?.filter?.resources || []).find((sel) => {
                                                return sel.resource_id === resource.resource_id;
                                            })
                                        }
                                    />
                                    <ListItemText primary={resource.name} />
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid
                        container
                        item
                        direction="column"
                        css={css`
                            min-width: 250px;
                            margin-right: 20px;
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <TextField
                            select
                            variant="outlined"
                            color="primary"
                            label="Shipper"
                            value={state.filter.shipper || ''}
                            onChange={(e, value) => {
                                callbacks.setFilter((prev) => {
                                    return { ...prev, shipper: e.target.value };
                                });
                            }}
                            css={css`
                                background-color: white;
                            `}
                        >
                            {state.shippers.map((shipper) => (
                                <MenuItem key={shipper.client_id} value={shipper.client_id}>
                                    <Body1>{shipper.business_name}</Body1>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <TextField
                            value={state.filter?.search || ''}
                            placeholder={'Search'}
                            onChange={(e) => {
                                callbacks.setFilter((prev) => {
                                    return { ...prev, search: e.target.value };
                                });
                            }}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            css={css`
                                background-color: white;
                            `}
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    css={css`
                        overflow: hidden;
                        flex-grow: 1;
                        padding: 0 40px;
                    `}
                >
                    <Table state={state} loading={loading} callbacks={callbacks} />
                </Grid>

                <Footer state={state} loading={loading} callbacks={callbacks} />
            </Grid>

            <ScheduleOrderModal
                orders={state.toSchedule}
                loading={loading.scheduling}
                callbacks={{
                    onClose: () => {
                        callbacks.closeModal();
                    },
                    onSubmit: ({ updates }) => {
                        callbacks.setDeliveryDate({ updates }).then(() => {
                            callbacks.closeModal();
                        });
                    },
                }}
            />

            <Snackbar
                open={!!state.notification?.message}
                {...state.notification}
                handleClose={callbacks.clearNotification}
            />
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} />
        </ContextProvider>
    );

export default compose(withFeatureFlag(ADVANCED_SCHEDULING), withContext)(ResourceNotifications);
