import { gql } from '@apollo/client';

export const CORPORATE_ADMIN_FIELDS = gql`
    fragment CorporateAdminFields on corporate_admins {
        corporate_admin_id
        service_id
        username
        email
        phone
    }
`;
