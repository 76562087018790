import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { useClientUser, useItemQuantity, useTotalCubes, useTotalWeight, useOrderCarrierPricing } from '@/hooks';
import React, { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { Body, border, Price, RowItem, Subtitle, Title, BodyBold, LoadPrimaryRow } from './blocks';
import { useLoadSummary } from './hooks';
import { PrimaryButton } from '@/styles/blocks';
import { withStyles } from '@material-ui/core/styles';
import { recommendedEquipmentLabels } from '@/constants/recommendedEquipmentLabels';
import { BIDDING_DISABLED } from '@/constants/featureFlags';
import { colors } from '@/styles';
import { Close } from '@material-ui/icons';

const CustomTooltip = withStyles(() => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

const fragments = {
    fullWidthButton: css`
        width: 100%;
    `,
    halfWidthButton: css`
        width: calc(50% - 0.5rem);
    `,
    marginLeft: css`
        margin-left: 5%;
    `,
    grey: css`
        color: ${colors.greys.primary};
    `,
};

const ClaimLoads = ({ loads = [], existingBid = {}, callbacks, ...props }) => {
    const { circles, approved } = useClientUser();

    const price = useOrderCarrierPricing(loads, 'N/A');
    const items = useItemQuantity(loads);
    const volume = parseInt(useTotalCubes(loads));
    const weight = parseInt(useTotalWeight(loads));
    const {
        order_numbers,
        shipper,
        available,
        pickupAddress,
        dropoffAddress,
        pickupCity,
        pickupTypes,
        dropoffCity,
        deliveryTypes,
        details,
        custAvailableDates,
        recommendedEquipment,
        itemDetails,
        haulawayDetails,
        orderTypes,
        pickupComments,
        dropoffComments,
        pickupDetails,
        pickupStairs,
        dropoffStairs,
        specialInstructions,
        assemblyTypes,
        bidCount,
        lowestBid,
        isMiddleMileDelivery,
        palletizeReturns,
        bol_file,
        piece_count,
    } = useLoadSummary(loads);

    const isReturn = useMemo(() => {
        return loads.every((o) => o.order_type === 'return');
    }, [loads]);

    const recEqWithLabels = recommendedEquipment.reduce(
        (acc, val) => ({ ...acc, [val]: recommendedEquipmentLabels[val] }),
        {}
    );

    const { ['2_man_team']: twoManTeam, ['3_man_team']: threeManTeam, lift_gate, ...otherEquipment } = recEqWithLabels;

    const anyOrdersPending = loads.some((l) => l.order_status === 'pending');

    const enableBidding =
        loads.length === 1 &&
        !circles?.[BIDDING_DISABLED] &&
        ['all', 'auction'].includes(loads[0]?.listing?.listing_type);

    if (loads.length === 0) return null;
    if (!approved) return null;

    return (
        <Grid
            container
            css={css`
                background-color: white;
                width: 500px;
                position: fixed;
                overflow-y: hidden;
                right: 0px;
                top: 0px;
                bottom: 0px;
                border: 1px solid rgba(0, 0, 0, 0.32);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px 5px 0px 0px;
                flex-direction: column;
                flex-wrap: nowrap;
            `}
            {...props}
        >
            <Grid
                item
                css={css`
                    display: flex;
                    justify-content: space-between;
                    padding: 1rem;
                    border-bottom: ${border};
                    position: sticky;
                    top: 0;
                    background-color: white;
                `}
            >
                <Subtitle>Load Details</Subtitle>
                <Body> {loads.length} load(s) selected</Body>
                <IconButton
                    onClick={callbacks.onClose}
                    css={css`
                        padding: 0;
                    `}
                >
                    <Close />
                </IconButton>
            </Grid>
            <Grid
                item
                css={css`
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    flex-grow: 1;
                    padding: 1rem;

                    overflow-y: scroll;

                    ::-webkit-scrollbar {
                        -webkit-appearance: none;
                        width: 7px;
                    }
                    ::-webkit-scrollbar-thumb {
                        border-radius: 4px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                    }
                `}
            >
                {anyOrdersPending && (
                    <RowItem
                        css={css`
                            margin-bottom: 1.5rem;
                        `}
                    >
                        <Body
                            css={css`
                                background-color: ${colors.oranges.primary};
                                color: white;
                                border-radius: 5px;
                                padding: 7.5px;
                            `}
                        >
                            This order is being reveiwed by Onward admins and is currently pending. It will be available
                            soon to claim.
                        </Body>
                    </RowItem>
                )}
                <RowItem
                    css={css`
                        display: flex;
                        justify-content: space-between;
                    `}
                >
                    <Title>{order_numbers}</Title>
                    <Price>{price ? price : 'N/A'}</Price>
                </RowItem>
                {existingBid.carrier_rate && (
                    <RowItem
                        css={css`
                            display: flex;
                            justify-content: space-between;
                        `}
                    >
                        <BodyBold>Your Bid:</BodyBold>
                        <Price>${existingBid.carrier_rate.toFixed(2)}</Price>
                    </RowItem>
                )}
                {bidCount > 0 && (
                    <RowItem>
                        <Body css={fragments.grey}>Bids: {bidCount}</Body>
                    </RowItem>
                )}
                {lowestBid?.carrier_rate && (
                    <RowItem>
                        <Body css={fragments.grey}>Lowest Bid: ${lowestBid.carrier_rate.toFixed(0)}</Body>
                    </RowItem>
                )}
                <RowItem>
                    <BodyBold>Requirements</BodyBold>
                </RowItem>
                <RowItem>
                    <Body>Lift Gate: {lift_gate ? 'Yes' : 'No'}</Body>
                </RowItem>
                <RowItem>
                    <Body>Total People: {threeManTeam ? '3' : twoManTeam ? '2' : '1'}</Body>
                </RowItem>
                <RowItem>
                    <Body>White Glove: {deliveryTypes === 'White Glove' ? 'Yes' : 'No'}</Body>
                </RowItem>
                <RowItem>
                    <Body>Equipment: {Object.values(otherEquipment).join(', ')}</Body>
                </RowItem>
                {isMiddleMileDelivery && (
                    <RowItem>
                        <Body>Warehouse to receive and hold order before delivery to end customer</Body>
                    </RowItem>
                )}
                {palletizeReturns && (
                    <RowItem>
                        <Body>Palletize upon return to warehouse</Body>
                    </RowItem>
                )}
                {assemblyTypes && (
                    <RowItem>
                        <Body>Assemblies: {assemblyTypes}</Body>
                    </RowItem>
                )}
                <RowItem>
                    <BodyBold>Order Details</BodyBold>
                </RowItem>
                <RowItem>
                    <Body>Order type: {orderTypes}</Body>
                </RowItem>
                <RowItem>
                    <Body>Shipper: {shipper}</Body>
                </RowItem>
                <RowItem>
                    <Body>First Available: {available}</Body>
                </RowItem>
                {bol_file && (
                    <RowItem>
                        <Body>
                            <a
                                href={bol_file}
                                css={css`
                                    color: ${colors.greens.primary};
                                `}
                                target="_blank"
                            >
                                View BOL
                            </a>
                        </Body>
                    </RowItem>
                )}
                {isMiddleMileDelivery && !isReturn ? (
                    <>
                        <RowItem>
                            <Body>Pickup: Carrier Warehouse</Body>
                        </RowItem>
                    </>
                ) : (
                    <>
                        <RowItem>
                            <Body>Pickup:</Body>
                        </RowItem>
                        <RowItem>
                            <Body css={fragments.marginLeft}>{pickupAddress}</Body>
                        </RowItem>
                        {pickupTypes && (
                            <RowItem>
                                <Body css={fragments.marginLeft}>
                                    Pick up type: {pickupTypes} - {pickupDetails}
                                </Body>
                            </RowItem>
                        )}
                        {pickupStairs && (
                            <RowItem>
                                <Body css={fragments.marginLeft}>Flights of Stairs: {pickupStairs}</Body>
                            </RowItem>
                        )}
                        {pickupComments && (
                            <RowItem>
                                <Body css={fragments.marginLeft}>Comments: {pickupComments}</Body>
                            </RowItem>
                        )}
                    </>
                )}
                {isMiddleMileDelivery && isReturn ? (
                    <>
                        <RowItem>
                            <Body>Drop off: Carrier Warehouse</Body>
                        </RowItem>
                    </>
                ) : (
                    <>
                        <RowItem>
                            <Body>Drop off:</Body>
                        </RowItem>
                        <RowItem>
                            <Body css={fragments.marginLeft}>{dropoffAddress}</Body>
                        </RowItem>
                        <RowItem>
                            <Body css={fragments.marginLeft}>
                                Delivery type: {deliveryTypes} - {details}
                            </Body>
                        </RowItem>
                        {dropoffStairs && (
                            <RowItem>
                                <Body css={fragments.marginLeft}>Flights of Stairs: {dropoffStairs}</Body>
                            </RowItem>
                        )}
                        {dropoffComments && (
                            <RowItem>
                                <Body css={fragments.marginLeft}>Comments: {dropoffComments}</Body>
                            </RowItem>
                        )}
                    </>
                )}
                <RowItem>
                    <Body>Customer availability: {custAvailableDates}</Body>
                </RowItem>
                <RowItem>
                    <Body>{`${items} Item(s) /${piece_count ? ` ${piece_count} piece(s) /` : ''} ${volume} cu ft. / ${weight} lbs`}</Body>
                </RowItem>
                {itemDetails.map((i) => (
                    <>
                        <RowItem>
                            <Body>{i.details}</Body>
                        </RowItem>
                        {i.pic && (
                            <RowItem>
                                <Body>
                                    <a
                                        href={i.pic}
                                        css={css`
                                            color: ${colors.greens.primary};
                                        `}
                                        target="_blank"
                                    >
                                        See Picture
                                    </a>
                                </Body>
                            </RowItem>
                        )}
                    </>
                ))}
                {haulawayDetails?.length > 0 ? (
                    <>
                        <RowItem>
                            <Body>{`${haulawayDetails.length} Haulaway(s)`}</Body>
                        </RowItem>
                        {haulawayDetails.map((i) => (
                            <>
                                <RowItem>
                                    <Body>{i.details}</Body>
                                </RowItem>
                                {i.pic && (
                                    <RowItem>
                                        <Body>
                                            <a
                                                href={i.pic}
                                                css={css`
                                                    color: ${colors.greens.primary};
                                                `}
                                                target="_blank"
                                            >
                                                See Picture
                                            </a>
                                        </Body>
                                    </RowItem>
                                )}
                            </>
                        ))}
                    </>
                ) : null}
                <RowItem>
                    <Body>
                        Special Delivery Instructions:{' '}
                        {!specialInstructions.length
                            ? 'none'
                            : specialInstructions.length === 1
                            ? specialInstructions[0]
                            : specialInstructions.map((i, idx) => (
                                  <>
                                      <div>{i}</div>
                                      {idx !== specialInstructions.length - 1 && <br />}
                                  </>
                              ))}
                    </Body>
                </RowItem>
            </Grid>
            <Grid
                item
                css={css`
                    display: flex;
                    justify-content: space-between;
                    padding: 1rem;
                    border-top: ${border};
                    position: sticky;
                    bottom: 0;
                    background-color: white;
                `}
            >
                {anyOrdersPending ? (
                    <>
                        {enableBidding && (
                            <PrimaryButton
                                css={fragments.halfWidthButton}
                                onClick={() => callbacks.setPlaceBidModalOpen(true)}
                            >
                                {existingBid?.bid_id ? 'Update Pre-Bid' : 'Place Pre-Bid'}
                            </PrimaryButton>
                        )}
                        <PrimaryButton
                            disabled
                            css={enableBidding ? fragments.halfWidthButton : fragments.fullWidthButton}
                        >
                            Claim Load
                        </PrimaryButton>
                    </>
                ) : (
                    <>
                        {enableBidding && (
                            <PrimaryButton
                                css={fragments.halfWidthButton}
                                onClick={() => callbacks.setPlaceBidModalOpen(true)}
                            >
                                {existingBid?.bid_id ? 'Update Bid' : 'Place Bid'}
                            </PrimaryButton>
                        )}
                        <PrimaryButton
                            css={enableBidding ? fragments.halfWidthButton : fragments.fullWidthButton}
                            onClick={() => callbacks.setTCModalOpen(true)}
                        >
                            Claim Load
                        </PrimaryButton>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default ClaimLoads;
