import React, { useEffect, useState } from 'react';

import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    Typography,
    TextField,
    Grid,
} from '@material-ui/core';

export default function EditPriceDialog(props) {
    const { showEditPrice, setShowEditPrice, route, priceChangeSubmit, allOrdersSameShipper } = props;
    const [carrRate, setCarrRate] = useState(route.total_carrier_rate);
    const [shipRate, setShipRate] = useState(route.total_shipper_rate);
    const [margin, setMargin] = useState(route.margin && !isNaN(route.margin) ? route.margin : 0);

    useEffect(() => {
        if (shipRate && carrRate) {
            setMargin(((shipRate - carrRate) / shipRate) * 100);
        } else {
            setMargin(0);
        }
    }, [carrRate, shipRate]);

    useEffect(() => {
        setCarrRate(route.total_carrier_rate);
        setShipRate(route.total_shipper_rate);
    }, [route]);

    return (
        <Dialog className="sidenav-margin-responsive" open={showEditPrice} onClose={() => setShowEditPrice(false)}>
            <DialogTitle>Edit pricing for route #{route.route_number}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Shipper Rate $"
                            variant="outlined"
                            type="number"
                            value={shipRate}
                            disabled={!allOrdersSameShipper}
                            onChange={(e) => setShipRate(Number(e.target.value))}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Carrier Rate $"
                            variant="outlined"
                            type="number"
                            value={carrRate}
                            disabled={!allOrdersSameShipper}
                            onChange={(e) => setCarrRate(Number(e.target.value))}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ margin: '20px 0px' }}>
                    Margin: {margin && !isNaN(margin) ? margin.toFixed(2) : 0}%
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setShowEditPrice(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        priceChangeSubmit({ total_carrier_rate: carrRate, total_shipper_rate: shipRate, margin });
                        setShowEditPrice(false);
                    }}
                    style={{
                        backgroundColor: '#71b77a',
                        color: '#eee',
                    }}
                    disabled={!allOrdersSameShipper}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
