import { compose } from 'recompose';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import * as Sentry from '@sentry/react';
import withTokenAuth from '../Auth/withTokenAuth';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    UPSERT_EVENT,
    INSERT_EXCEPTION,
    INSERT_EXCEPTION_EVENT,
    UPDATE_EXCEPTION,
} from '../OrderDetailsPage/graphql/mutations';
import ExceptionModal from '../OrderDetailsPage/modals/Exceptions';
import { useExceptionCallbacks } from '../OrderDetailsPage/hooks';
import { QUERY_BY_ORDER } from '@/graphql/queries/order_wh_events';
import { QUERY_BY_LEADER } from '@/graphql/queries/teammates';
import { captureException } from '@sentry/react';
import { useWarehouseAggregate } from '@onward-delivery/core';
import { toArrayLiteral } from '@/utilities/toArrayLiteral';
import { useMemo } from 'react';
import { addFormattedDateFields } from '@/utilities/addFormattedDateFields';
import { ORDER_BY_ID } from '../OrderDetailsPage/subscriptions';
import { omit } from 'lodash';
import { ITEM_READONLY_FIELDS, ORDER_READONLY_FIELDS } from '@/constants/readonlyFields';
import { CircularProgress, Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import DriverSummaryCard from './DriverSummaryCard';
import DriverCrossdockStepper from './DriverCrossdockStepper';
import DriverItemsList from './DriverItemsList';
import ChecklistModal from './modals/ChecklistModal';
import { OrderStatus } from './blocks';

const OrderDetailsPageDriver = ({ claims }) => {
    const { order_id } = useParams();
    // driver should container name, phone, and possibly driver_id
    const { driver, event_id } = claims;

    const [order, setOrder] = useState({});
    const [exceptionParams, setExceptionParams] = useState(null);
    const [checklist, setChecklist] = useState({});
    const [notification, setNotification] = useState({});

    const {
        loading: orderLoading,
        error: orderError,
        data: orderData,
    } = useSubscription(ORDER_BY_ID, {
        variables: {
            order_id: order_id,
        },
        onData: ({ data }) => {
            const _order = data?.data?.orders?.[0];
            if (!_order) return;
            setOrder({ ..._order, ...addFormattedDateFields(_order) });
        },
        onError: (error) => {
            console.error(error);
            captureException(error);
        },
    });

    const [insertException, { loading: insertExceptionLoading }] = useMutation(INSERT_EXCEPTION, {
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to add exception. Please try again' });
        },
    });
    const [updateException, { loading: updateExceptionLoading }] = useMutation(UPDATE_EXCEPTION, {
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to update exception. Please try again' });
        },
    });

    const [insertExceptionEvent, { loading: insertExceptionEventLoading }] = useMutation(INSERT_EXCEPTION_EVENT, {
        update: (cache, { data: { mappings, order } }) => {
            cache.updateQuery(
                {
                    query: ORDER_BY_ID,
                    variables: {
                        order_id: order_id,
                    },
                },
                (data) => {
                    if (!order) {
                        return data;
                    }

                    const clone = {
                        ...data.orders[0],
                        ...order,
                    };

                    console.log(data, clone, order);

                    return {
                        ...data,
                        orders: [clone],
                    };
                }
            );

            cache.updateQuery(
                {
                    query: QUERY_BY_ORDER,
                    variables: { order_id },
                },
                (data) => {
                    if (!order) {
                        return data;
                    }
                    const clone = [...order.wh_events];

                    return {
                        ...data,
                        results: clone,
                    };
                }
            );
        },
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to insert event. Please try again' });
        },
    });

    const [upsertEvent, { loading: eventUpsertLoading }] = useMutation(UPSERT_EVENT, {
        update: (cache, { data: { event, order } }) => {
            cache.updateQuery(
                {
                    query: ORDER_BY_ID,
                    variables: {
                        order_id: order_id,
                    },
                },
                (data) => {
                    if (!event) {
                        return data;
                    }
                    const clone = {
                        ...data.orders[0],
                        ...order,
                    };

                    return {
                        ...data,
                        orders: [clone],
                    };
                }
            );

            cache.updateQuery(
                {
                    query: QUERY_BY_ORDER,
                    variables: { order_id },
                },
                (data) => {
                    const idx = data.results.findIndex((e) => e.event_id === event.event_id);
                    const clone = [...data.results];
                    if (idx > -1) {
                        clone.splice(idx, 1, event);

                        return {
                            ...data,
                            results: clone,
                        };
                    }

                    return {
                        ...data,
                        results: [...data.results, event],
                    };
                }
            );
        },
        onError: (error) => {
            captureException(error);
            setNotification({ severity: 'error', message: 'Failed to insert event. Please try again' });
        },
    });

    const { data: warehouseEvents } = useQuery(QUERY_BY_ORDER, {
        skip: !order.order_id,
        variables: { order_id },
    });
    const events = useMemo(() => {
        return warehouseEvents?.results || [];
    }, [warehouseEvents]);
    const warehouse = useWarehouseAggregate(warehouseEvents?.results);

    const { data: teammates } = useQuery(QUERY_BY_LEADER, {
        skip: !order.carrier_id,
        variables: { leader_id: order.carrier_id },
    });
    const drivers = useMemo(() => {
        return (teammates?.results || []).filter((teammate) => teammate?.roles?.driver);
    }, [teammates]);

    const { createException } = useExceptionCallbacks(
        { order, exceptionParams },
        { insertExceptionEvent, insertException, updateException }
    );

    if (orderLoading) {
        return (
            <div
                css={css`
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <CircularProgress size={100} />
            </div>
        );
    }

    if (order?.routes?.length > 0) {
        return (
            <div
                css={css`
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <div css={css`
                    font-size: 22px;
                    font-weight: 500;
                    width: 80%;
                    text-align: center;
                `}>
                    This order has now been routed and this page can no longer be used to view and modify the order.
                </div>
            </div>
        )
    }

    return (
        <>
            <Grid
                container
                css={css`
                    width: 100%;
                    justify-content: center;
                    padding: 12px;
                `}
            >
                <OrderStatus status={order?.order_status} />
            </Grid>
            <DriverSummaryCard order={order} events={events} />
            <DriverCrossdockStepper
                order={order}
                events={events}
                isLoading={eventUpsertLoading}
                warehouse={warehouse}
                callbacks={{
                    editCrossdockPickup: (params) => {
                        console.debug('not available');
                    },
                    editCrossdock: (params) => {
                        console.debug('not available');
                    },
                    upsertEvent: ({ event, order: update = {} }) => {
                        const { event_id, action, order_id, ...rest } = event;

                        return upsertEvent({
                            variables: {
                                event,
                                order: { ...update, order_id: order.order_id },
                                update_cols: Object.keys(rest),
                                order_update_cols: Object.keys(update),
                            },
                        });
                    },
                    createException: (params) => {
                        setExceptionParams(params);
                    },
                    confirmAction: (content, callback) => {
                        console.debug('not available');
                    },
                    openChecklist: (params, callback) => {
                        setChecklist({
                            ...params,
                            callback,
                        });
                    },
                    sendWeblink: (event) => {
                        console.debug('not available');
                    },
                    gotoFileTab: () => {
                        console.debug('not available');
                    },
                }}
            />
            <DriverItemsList order={order} />
            <ChecklistModal
                {...checklist}
                open={checklist.content}
                order={order}
                items={order.itemsByOrderId}
                callbacks={{
                    onClose: () => setChecklist({}),
                    onConfirm: (params) => checklist.callback(params).then(() => setChecklist({})),
                }}
            />
            <ExceptionModal
                {...exceptionParams}
                loading={insertExceptionEventLoading || updateExceptionLoading || insertExceptionLoading || eventUpsertLoading}
                order={order}
                callbacks={{
                    onError: (e) => {
                        setNotification({
                            severity: 'error',
                            message: 'Failed to upload image. Please try again',
                        });
                    },
                    onSubmitCreateAnother: ({ exception }) => {
                        return createException(exception);
                    },
                    onSubmit: ({ exception }) => {
                        return createException(exception).then(() => {
                            setExceptionParams(null);
                        });
                    },
                    onClose: () => setExceptionParams(null),
                }}
            />
        </>
    );
};

export default compose(withTokenAuth({ role: 'temp-driver', reqClaims: { path: ['order_id'] } }))(
    OrderDetailsPageDriver
);
