import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import { Body1, Body2, fragments } from './blocks';

const CarrierLocationCard = ({ cardText, address, iconColor }) => {
    return (
        <Grid
            container
            css={css`
                ${fragments.card}
            `}
        >
            <Grid container direction="column" xs={10}>
                <Grid item>
                    <Body2>{cardText}</Body2>
                </Grid>
                <Grid item>
                    <Body1>{address}</Body1>
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                xs={2}
            >
                <FlagIcon
                    fontSize="small"
                    css={css`
                        color: ${iconColor};
                    `}
                />
            </Grid>
        </Grid>
    );
};

export default CarrierLocationCard;
