import React, { useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import { compose } from 'recompose';
import Snackbar from '@/components/Snackbar';
import { TextField, Grid, InputAdornment, LinearProgress } from '@material-ui/core';
import EditOrderModal from '@/components/ShipmentForm/modals/EditOrderModal';
// import Table from './table';
import Footer from './Footer';
import _, { isObject } from 'lodash';
import Filter from './Filter.js';
import { PrimaryHeaderLabel } from './blocks';
import { Search } from '@material-ui/icons';
import withFeatureFlag from '../Auth/withFeatureFlag';
import { SAAS_V1 } from '@/constants/featureFlags';
import { OnwardTabContainer, OnwardTab } from '../Tabs';
import { Context, ContextProvider } from './store';
import { MODALS, UNASSIGNED_TABS } from './constants';
import PlanningModal from './modals/PlanningModal';
import MarketplaceModal from '../ShipmentForm/modals/MarketplaceModal';
import CreditCardModal from '../ShipmentForm/modals/CreditCardModal';
import CancellationModal from '../CancellationModal';
import { useState } from 'react';
import MultiListingsModal from './modals/MultiListingsModal';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { addDays } from 'date-fns';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { useClientUser } from '@/hooks';
import { BIDDING_DISABLED } from '@/constants/featureFlags';
import Table from '../Table';
import { useTableColumns } from './columns';
import { useNavigate } from 'react-router';
import { validate } from '@onward-delivery/core';
import CrossdockModal from './modals/CrossdockModal';

const DispatchUnassigned = () => {
    const {
        state: { loading, tab, modal, editingOrder, notification, selectedOrders, listings, orders },
        callbacks,
    } = useContext(Context);
    const { circles, user_id, accountType, payment_type, stripe_payment_method } = useClientUser();

    const [searchText, setSearchText] = useState('');
    const [debouncer, setDebouncer] = useState(null);

    const navigate = useNavigate();

    const columns = useTableColumns({
        editEnabled: tab.editEnabled,
        marketplaceEnabled: circles?.['ltl-marketplace'],
        accountType,
        user_id,
    });

    const handleSearch = (e) => {
        const input = e.target.value;
        setSearchText(input);
        if (debouncer) {
            clearTimeout(debouncer);
        }
        setDebouncer(
            setTimeout(() => {
                callbacks.setFilters((prev) => ({ ...prev, search: e.target.value }));
            }, 500)
        );
    };

    useEffect(() => {
        let _listings = {};
        selectedOrders.forEach((order) => {
            let orderTZ = order?.dropoff_zip
                ? zipcode_to_timezone.lookup(order?.dropoff_zip)
                : Intl.DateTimeFormat().resolvedOptions().timeZone;
            const utc = addDays(
                new Date(asUTCDate(new Date().toISOString()).setUTCHours(0, 0, 0, 0)),
                21
            ).toISOString();

            _listings[order.order_id] = {
                order_id: order.order_id,
                listing_type: circles?.[BIDDING_DISABLED] ? 'claim_now' : 'all',
                shipper_rate: order.shipper_rate,
                expiration_date: asDateInTZ(utc, orderTZ).toISOString(),
            };
        });
        callbacks.setListings(_listings);
    }, [selectedOrders]);

    return (
        <Grid container direction="column" wrap="nowrap" className="h-100">
            <Grid
                item
                css={css`
                    display: flex;
                    justify-content: center;
                    background-color: white;
                `}
            >
                <OnwardTabContainer
                    value={tab.value}
                    onChange={(e, t) => callbacks.setTab(UNASSIGNED_TABS[t])}
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                >
                    {UNASSIGNED_TABS.map((tab) => (
                        <OnwardTab
                            key={`unassigned-tab-${tab.label}`}
                            label={tab.label}
                            value={tab.value}
                            style={{ outline: 0 }}
                        />
                    ))}
                </OnwardTabContainer>
            </Grid>

            <Grid
                item
                css={css`
                    display: flex;
                    justify-content: space-between;
                    padding: 2rem;
                `}
            >
                <PrimaryHeaderLabel>Unscheduled Orders</PrimaryHeaderLabel>
                <span>
                    <TextField
                        value={searchText}
                        placeholder="Search Orders"
                        onChange={handleSearch}
                        variant="outlined"
                        color="primary"
                        size="small"
                        InputProps={{
                            style: { backgroundColor: 'white' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        css={css`
                            margin-right: 1rem;
                        `}
                    />
                    <Filter
                        saveFilters={(updates) => {
                            callbacks.setFilters((prev) => ({ ...prev, ...updates }));
                        }}
                    />
                </span>
            </Grid>

            <Grid
                item
                css={css`
                    display: flex;
                    flex-grow: 1;
                    margin: 0 2rem;
                `}
            >
                {loading ? (
                    <LinearProgress
                        color="primary"
                        css={css`
                            width: 100%;
                        `}
                    />
                ) : (
                    <Table
                        data={orders || []}
                        columns={columns}
                        callbacks={{
                            getRowId: (order) => `${order.order_id}_${order.crossdock_leg}`,
                            rowOnClick: (e, order) => {
                                const href = `/order/${order.order_id}`;
                                e.preventDefault();
                                if (e.ctrlKey) {
                                    window.open(href, '_blank');
                                } else {
                                    navigate(href);
                                }
                            },
                            setSelectedIds: (selected) => {
                                callbacks.setSelectedOrderIds(selected);
                            },
                            onClickPlan: (order) => {
                                const rowId = `${order.order_id}_${order.crossdock_leg}`;
                                callbacks.setSelectedOrderIds({ [rowId]: true });
                                callbacks.setModal(MODALS.PLANNING);
                            },
                            onClickCrossdock: (order) => {
                                const rowId = `${order.order_id}_${order.crossdock_leg}`;
                                callbacks.setSelectedOrderIds({ [rowId]: true });
                                callbacks.setModal(MODALS.CROSS_DOCK);
                            },
                            onEditOrder: (order) => callbacks.editOrder(order),
                            onClickOnward: (order) => {
                                const rowId = `${order.order_id}_${order.crossdock_leg}`;
                                callbacks.setSelectedOrderIds({ [rowId]: true });

                                const [invalid, missingFields] = validate({ ...order, oms: false });
                                if (missingFields?.length > 0) {
                                    callbacks.editOrder(invalid);
                                    callbacks.setNotification({
                                        severity: 'warning',
                                        message: 'Cannot submit to Onward, order incomplete',
                                    });
                                } else if (payment_type === 'Invoice' || stripe_payment_method) {
                                    callbacks.setModal(
                                        circles?.[BIDDING_DISABLED] ? MODALS.MARKETPLACE : MODALS.LISTING_OPTIONS
                                    );
                                } else {
                                    callbacks.setModal(MODALS.CREDIT_CARD);
                                }
                            },
                        }}
                        options={{
                            select: true,
                            sortAsync: false,
                        }}
                    />
                )}
            </Grid>

            <Footer />

            <EditOrderModal
                order={editingOrder}
                open={isObject(editingOrder)}
                opt={{
                    readOnly: !editingOrder?.oms && !!editingOrder?.carrier_invoice_id,
                    enableSingleOrderManifest: true,
                }}
                callbacks={{
                    onSave: callbacks.onSaveOrder,
                    onClose: () => {
                        callbacks.editOrder(null);
                    },
                    onError: (error) => {
                        callbacks.setNotification({
                            severity: 'error',
                            message: error?.message || error || `We're sorry, something went wrong.`,
                        });
                    },
                }}
            />

            <PlanningModal
                orderCount={selectedOrders?.length}
                open={modal === MODALS.PLANNING}
                onClose={() => callbacks.setModal(null)}
            />
            <CrossdockModal
                orderCount={selectedOrders?.length}
                open={modal === MODALS.CROSS_DOCK}
                onClose={() => callbacks.setModal(null)}
            />
            <MarketplaceModal
                open={modal === MODALS.MARKETPLACE}
                onClose={() => callbacks.setModal(null)}
                onSubmit={callbacks.sendToMarketplace}
                orders={selectedOrders}
                listings={listings}
            />
            <MultiListingsModal open={modal === MODALS.LISTING_OPTIONS} onClose={() => callbacks.setModal(null)} />
            <CreditCardModal
                open={modal === MODALS.CREDIT_CARD}
                handleClose={() => callbacks.setModal(null)}
                onSuccess={() => {
                    callbacks.setNotification({
                        severity: 'success',
                        message: 'Card added successfully. You may now send your orders to the Onward Marketplace!',
                    });
                    callbacks.setModal(MODALS.MARKETPLACE);
                }}
            />
            <CancellationModal
                orders={selectedOrders}
                open={modal === MODALS.CANCELLATION}
                onClose={() => callbacks.setModal(null)}
                ordersCancelledCallback={(ordersCancelled, error) => {
                    if (error) {
                        callbacks.setNotification({
                            severity: 'error',
                            message: 'Error cancelling orders. Please try again.',
                        });
                    } else {
                        callbacks.setNotification({
                            severity: 'success',
                            message: `Succesfully cancelled ${ordersCancelled.length} orders.`,
                        });
                        callbacks.refetch();
                    }
                }}
            />

            <Snackbar
                severity={notification?.severity}
                open={Boolean(notification?.message)}
                message={notification?.message}
                handleClose={() => callbacks.setNotification(null)}
                customAction={notification?.action}
            />
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withFeatureFlag(SAAS_V1), withContext)(DispatchUnassigned);
