import { PrimaryButton, ResponsiveSidebarDialog, SecondaryButton, StickyModalActions } from '@/styles/blocks';
import { Alert, AlertTitle } from '@material-ui/lab';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { Body2 } from '../blocks';

const PODuplicateConfirmationModal = ({ open, count, callbacks, dupes }) => {
    return (
        <ResponsiveSidebarDialog open={open} onClose={callbacks.onClose} maxWidth="md">
            <Grid
                container
                direction="column"
                css={css`
                    padding: 20px;
                `}
            >
                <Grid item>
                    <Alert
                        severity="error"
                        css={css`
                            background: #d23e3e;
                            padding: 24px 16px;
                            align-items: center;
                            color: ${colors.white};

                            .MuiAlert-icon {
                                color: ${colors.white};
                            }
                        `}
                    >
                        <AlertTitle
                            css={css`
                                margin: 0;
                            `}
                        >
                            You are about to upload duplicate Purchase Orders
                        </AlertTitle>
                    </Alert>
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-top: 20px;
                    `}
                >
                    <Body2
                        css={css`
                            color: #4c4c4c;
                        `}
                    >{`You have ${count} duplicates`}</Body2>
                </Grid>
                {dupes?.length > 0 && (
                    <Grid
                        item
                        css={css`
                            margin-top: 20px;
                        `}
                    >
                        <Body2
                            css={css`
                                color: #4c4c4c;
                            `}
                        >
                            {`The following POs are duplicates:`}
                        </Body2>
                    </Grid>
                )}
                {dupes?.map((dupe) => (
                    <Grid
                        item
                        css={css`
                            margin-top: 20px;
                        `}
                    >
                        <Body2
                            css={css`
                                color: #4c4c4c;
                            `}
                        >
                            - {dupe.po_number}
                        </Body2>
                    </Grid>
                ))}
                <Grid
                    item
                    css={css`
                        margin-top: 20px;
                    `}
                >
                    <Body2
                        css={css`
                            color: #4c4c4c;
                        `}
                    >
                        Would you like to keep duplicates or delete them?
                    </Body2>
                </Grid>
            </Grid>
            <StickyModalActions border="true">
                <PrimaryButton red onClick={callbacks.onDelete}>
                    Delete
                </PrimaryButton>
                <PrimaryButton onClick={callbacks.onSubmit}>Keep</PrimaryButton>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default PODuplicateConfirmationModal;
