import React, { useContext } from 'react';
import OnwardStopCard from '../cards/OnwardStopCard';
import SupplyStopCard from '../cards/SupplyStopCard';
import { Context } from '../store';
import { Sidebar, SidebarScrollable, SidebarScrollableContainer } from './blocks';
import RouteRequirementsCard from '../cards/RouteRequirementsCard';

const SupplyStopsSidebar = () => {
    const { state } = useContext(Context);

    const stops = state.selectedRoute?.activity_stops || state.selectedRoute?.stopsByRouteId || [];

    return (
        <Sidebar>
            <SidebarScrollableContainer>
                <SidebarScrollable>
                    {!!state.selectedRoute?.stopsByRouteId?.length && (
                        <RouteRequirementsCard 
                            route={state.selectedRoute}
                            stops={stops}
                        />
                    )}
                    {[...stops]
                        .sort((a, b) => a.ordering - b.ordering)
                        .map((stop) =>
                            stop.__typename === 'stops' ? (
                                <OnwardStopCard route={state.selectedRoute} stop={stop} id={stop.stop_id} />
                            ) : (
                                <SupplyStopCard route={state.selectedRoute} stop={stop} id={stop.stop_id} />
                            )
                        )}
                </SidebarScrollable>
            </SidebarScrollableContainer>
        </Sidebar>
    );
};

export default SupplyStopsSidebar;
