import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useMutation } from '@apollo/client';
import { UPDATE_BID_BY_ID } from '@/graphql/mutations/bids';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles({
    modalTitle: {
        color: '#0C0B1D',
        fontWeight: '800',
        fontSize: '28px',
        textAlign: 'center',
    },
    dialogContent: {
        width: 450,
    },
    modalLabel: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        color: '#2B2B2B',
        alignSelf: 'center',
        marginLeft: 20,
    },
});

export default function CancelBidModal(props) {
    const { isOpen, onClose, bid } = props;
    const classes = useStyles();
    const analytics = getAnalytics();

    const [updateBid] = useMutation(UPDATE_BID_BY_ID);

    const handleSubmit = async () => {
        try {
            await updateBid({
                variables: {
                    bid_id: bid.bid_id,
                    update: {
                        bid_status: 'cancelled',
                    },
                },
            });
            logEvent(analytics, 'bid_cancelled', bid);
            onClose();
        } catch (e) {
            Sentry.captureException(e);
            console.log(e);
        }
    };

    return (
        <>
            <Dialog
                className="carrier-review-modal"
                open={isOpen}
                onClose={onClose}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                <DialogTitle style={{ margin: 0, padding: '16px 24px 0 24px' }}>
                    {onClose ? (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            style={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                    <Typography variant="h6" className={classes.modalTitle}>
                        Cancel Bid
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    Please confirm that you wish to cancel this bid.
                </DialogContent>
                <DialogActions
                    className="customer-track-rating-modal d-flex justify-content-between"
                    style={{ padding: '24px' }}
                >
                    <Button variant="outlined" onClick={onClose} style={{ color: 'red' }}>
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#59B863',
                            color: 'white',
                        }}
                        onClick={handleSubmit}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
