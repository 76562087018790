import React, { useContext, useState, useMemo } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { PlanningContext } from '../context';
import { useMutation } from '@apollo/client';
import { MODALS } from '../constants';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import * as Sentry from '@sentry/react';
import { RESCHEDULE_ROUTE } from '../graphql/mutations';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { genAttributes } from '@onward-delivery/core';
import { asDateInTZ } from '@/utilities/convertToISO';
import { css } from '@emotion/react';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';

const RouteRescheduleModal = () => {
    const {
        modalOpen,
        setModalOpen,
        actionableRoute,
        deliveryDate,
        selectedRoute,
        setSelectedRoute,
        setError,
        callbacks,
    } = useContext(PlanningContext);

    const [newDeliveryDate, setNewDeliveryDate] = useState(deliveryDate);

    const [rescheduleRoute, { loading }] = useMutation(RESCHEDULE_ROUTE, {
        update: (cache, { data: { update_routes_by_pk } }) => {
            cache.evict(cache.identify(update_routes_by_pk));
        },
    });

    const handleModalClose = () => {
        if (selectedRoute === actionableRoute?.route_id) {
            setSelectedRoute(null);
        }
        setModalOpen(null);
    };

    const handleRescheduleRoute = () => {
        const order = actionableRoute?.orders?.[0]?.order || {};
        const { zip } = genAttributes(order);
        const tz = zipcode_to_timezone.lookup(order[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;

        const pickups = actionableRoute.orders.filter((mapping) => mapping.type === 'PICKUP');
        const dropoffs = actionableRoute.orders.filter((mapping) => mapping.type !== 'PICKUP');

        rescheduleRoute({
            variables: {
                route_id: actionableRoute?.route_id,
                delivery_date: asDateInTZ(newDeliveryDate, tz).toISOString(),
                pickup_ids: pickups.map((mapping) => mapping.order_id),
                dropoff_ids: dropoffs.map((mapping) => mapping.order_id),
            },
            onCompleted: () => {
                callbacks.refetch();
                handleModalClose();
            },
            onError: (error) => {
                setError(error, 'Error rescheduling route');
            },
        });
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ROUTE_RESCHEDULE} onClose={handleModalClose}>
            <ModalTitle>{`Reschedule Route ${actionableRoute?.route_number}`}</ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <Grid container>
                        <Body2>Select a new delivery date for this route.</Body2>
                        <Body2>Timeframes will not be saved.</Body2>
                    </Grid>
                    <TextField
                        type="date"
                        variant="outlined"
                        disabled={loading}
                        value={newDeliveryDate}
                        onChange={(e) => setNewDeliveryDate(e.target.value)}
                        css={css`
                            input {
                                font-size: 14px;
                                font-weight: 400;
                                padding: 8px 12px 12px 12px;
                            }
                        `}
                    />
                </GridItemRow>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={handleRescheduleRoute}>
                    Reschedule
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default RouteRescheduleModal;
