import { genAttributes } from '@onward-delivery/core';

export const getEditMultiPickupRequiredFields = (order) => {
    const {
        is_custom: pu_is_custom,
        street: pu_street,
        city: pu_city,
        state: pu_state,
        zip: pu_zip,
        lat: pu_lat,
        long: pu_long,
        location: pu_location,
        location_type: pu_location_type,
    } = genAttributes(order, true);

    return [
        'pickup_name',
        'pickup_phone',
        pu_street,
        pu_city,
        pu_state,
        ...(order[pu_is_custom] ? [pu_zip, pu_lat, pu_long] : []),
        pu_location,
        pu_location_type,
    ];
};
