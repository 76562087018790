import React from 'react';
import { css } from '@emotion/react';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { BodyCellText, fragments, Body2 } from '@/components/MyOrders/blocks';
import { StickyCell } from '@/components/DispatchUnassigned/blocks';

export default function HeaderCell({ cell, ...props }) {
    let sortIcon = null;
    if (cell.canSort) {
        if (cell.isSorted) {
            sortIcon = (
                <IconButton
                    onClick={() => {
                        cell.toggleSortBy(!cell.isSortedDesc);
                    }}
                    css={css`
                        padding: 4px;
                        margin-left: 4px;
                        fontsize: 10px;

                        & .fa-w-10 {
                            ${fragments.cellIcon}
                        }
                    `}
                >
                    <FontAwesomeIcon
                        style={{ fontSize: '1.25rem', color: '#71b77a' }}
                        icon={cell.isSortedDesc ? faSortDown : faSortUp}
                    />
                </IconButton>
            );
        } else {
            sortIcon = (
                <IconButton
                    onClick={cell.toggleSortBy}
                    css={css`
                        padding: 4px;
                        margin-left: 4px;
                        fontsize: 10px !important;
                        & .fa-w-8 {
                            ${fragments.cellIcon}
                        }
                    `}
                >
                    <FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faSort} />
                </IconButton>
            );
        }
    }
    return (
        <StickyCell {...props}>
            <Body2>{cell.render('Header')}</Body2>
            {sortIcon}
        </StickyCell>
    );
}
