import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { CenteredCard } from './blocks';

const LoadingCard = () => {
    return (
        <CenteredCard>
            <CircularProgress size={100} />
        </CenteredCard>
    );
};

export default LoadingCard;
