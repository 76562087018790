const greens = ['#59b863'];
greens.primary = '#59b863';

const reds = ['#dc3545', '#d23e3e'];
reds.primary = '#dc3545';
reds.cancelled = '#D23E3E';

const white = ['#ffffff', '#FAFAFA'];
white.primary = '#ffffff';
white.secondary = '#FAFAFA';

const greys = ['#979797', '#8D8D8D', '#4C4C4C', '#2B2B2B', '#E2E2E2', '#ECECEC', '#A6A6A6'];
greys.primary = '#8D8D8D';
greys.secondary = '#4C4C4C';
greys.border = '#E2E2E2';
greys.selected = '#ECECEC';
greys.subtitle = '#A6A6A6';

const golds = ['#E28700', '#e89e2e', '#FF9800'];
golds.primary = '#E28700';

const oranges = ['#F18D18', '#FF903D', '#bb6a0b'];
oranges.primary = '#F18D18';
oranges.rescheduled = '#FF903D';

const blues = ['#007BFF'];
blues.inTransit = '#007BFF';

const onwardNavy = 'rgb(12, 11, 29)';

export { greys, greens, white, golds, reds, oranges, onwardNavy, blues };
