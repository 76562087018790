import { useMemo } from 'react';
import { calcItemCount, calcItemVolume } from '@/utilities/calculateItemMetrics';
import { formatInTimeZone } from 'date-fns-tz';

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const timeNumeric = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    hour12: true,
});

const minuteString = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'minute',
});

export const useColumns = () => {
    return useMemo(() => {
        return [
            {
                header: 'Route Id',
                getValue: ({ route }) => route.route_number,
            },
            {
                header: 'Route Name',
                getValue: ({ stop }) => stop.obj.address,
                format: (value) => {
                    return `"${value}"`;
                },
            },
            {
                header: 'Order Status',
                getValue: ({ stop }) => stop.orders?.map((order) => order?.order_status),
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'Reference Id',
                getValue: ({ orders }) => orders?.map((order) => order?.reference_id),
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'Manufacturer',
                getValue: ({ orders }) => orders?.map((order) => order?.manufacturer),
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'Address 1',
                getValue: ({ stop }) => {
                    if (stop.obj.ordering === 0) {
                        return stop.orders?.map((order) => order?.dropoff_street);
                    }
                    return stop.orders[0]?.dropoff_street;
                },
                format: (value) => {
                    return Array.isArray(value) ? `"${value.join(', ')}"` : value;
                },
            },
            {
                header: 'Address 2',
                getValue: ({ stop }) => {
                    if (stop.obj.ordering === 0) {
                        let allUnits = stop.orders?.reduce((secondaryAddresses, order) => {
                            if (order.dropoff_unit) {
                                secondaryAddresses.push(order.dropoff_unit);
                            }
                            return secondaryAddresses;
                        }, []);
                        return allUnits;
                    }
                    return stop.orders[0]?.dropoff_unit || '';
                },
                format: (value) => {
                    return Array.isArray(value) ? `"${value.join(', ')}"` : value;
                },
            },
            {
                header: 'City',
                getValue: ({ stop }) => stop.orders?.map((order) => order?.dropoff_city),
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'State',
                getValue: ({ stop }) => stop.orders?.map((order) => order?.dropoff_state),
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'Zip',
                getValue: ({ stop }) => stop.orders?.map((order) => order?.dropoff_zip),
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'Customer Names',
                getValue: ({ stop }) => stop.orders?.map((order) => order?.dropoff_name),
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'Customer Phone #s',
                getValue: ({ stop }) => stop.orders?.map((order) => order?.dropoff_phone),
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'Customer Secondary Phone #s',
                getValue: ({ stop }) =>
                    stop.orders?.reduce((secondaryNums, order) => {
                        if (order.secondary_dropoff_contact_phone) {
                            secondaryNums.push(order.secondary_dropoff_contact_phone);
                        }
                        return secondaryNums;
                    }, []),
                format: (value) => {
                    return Array.isArray(value) ? `"${value.join(', ')}"` : '';
                },
            },
            {
                header: 'Customer Emails',
                getValue: ({ stop }) =>
                    stop.orders?.reduce((customerEmails, order) => {
                        if (order.dropoff_email) {
                            customerEmails.push(order.dropoff_email);
                        }
                        return customerEmails;
                    }, []),
                format: (value) => {
                    return Array.isArray(value) ? `"${value.join(', ')}"` : '';
                },
            },
            {
                header: 'Driver Name',
                getValue: ({ driver }) => driver.username,
            },
            {
                header: 'Stop #',
                getValue: ({ stop }) => stop.obj.ordering,
            },
            {
                header: 'Order #s',
                getValue: ({ stop }) => stop.orders?.map((order) => order?.order_number),
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'PO #s',
                getValue: ({ stop }) => {
                    const poNumbers = [];
                    (stop.orders || []).forEach((o) => {
                        o?.po_number && poNumbers.push(o.po_number);
                    });
                    return poNumbers;
                },
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'SKUs',
                getValue: ({ stop }) => {
                    let skus = new Set();

                    (stop.orders || []).forEach((o) => {
                        o.items && o.items.forEach((i) => i.sku && skus.add(i.sku));
                        o.returns && o.returns.forEach((i) => i.sku && skus.add(i.sku));
                    });

                    return Array.from(skus);
                },
                format: (value) => {
                    return `"${value.join(', ')}"`;
                },
            },
            {
                header: 'Item Quantity',
                getValue: ({ stop }) => {
                    return calcItemCount(stop.orders);
                },
                format: (value) => value,
            },
            {
                header: 'Cubic Ft.',
                getValue: ({ stop }) => {
                    return calcItemVolume(stop.orders);
                },
                format: (value) => value,
            },
            {
                header: 'Stop Start Date',
                getValue: ({ stop }) => {
                    const date = new Date(stop.obj.del_window_start);
                    return isNaN(date) ? undefined : date;
                },
                format: (value) => `"${dateNumeric.format(value)}"`,
            },
            {
                header: 'Stop Start Time',
                getValue: ({ stop }) => {
                    const date = new Date(stop.obj.del_window_start);
                    return isNaN(date) ? undefined : date;
                },
                format: (value, { tz = 'America/New_York' }) => `"${formatInTimeZone(value, tz, 'HH:mm')}"`,
            },
            {
                header: 'Stop End Date',
                getValue: ({ stop }) => {
                    const date = new Date(stop.obj.del_window_end);
                    return isNaN(date) ? undefined : date;
                },
                format: (value) => `"${dateNumeric.format(value)}"`,
            },
            {
                header: 'Stop End Time',
                getValue: ({ stop }) => {
                    const date = new Date(stop.obj.del_window_end);
                    return isNaN(date) ? undefined : date;
                },
                format: (value, { tz = 'America/New_York' }) => `"${formatInTimeZone(value, tz, 'HH:mm')}"`,
            },
            {
                header: 'Stop Service Time',
                getValue: ({ stop }) => {
                    const times = stop.orders?.map((order) => order?.service_time);
                    return times.some((time) => !!time) ? Math.max(...times) / 60 / 1000 : undefined;
                },
                format: (value) => `"${minuteString.format(value)}"`,
            },
            {
                header: 'Order Type',
                getValue: ({ stop }) => stop.obj.type,
            },
            {
                header: 'Shipper Notes',
                getValue: ({ stop }) => {
                    return stop.orders?.length !== 1 ? '' : stop.orders?.[0]?.shipper_notes;
                },
            },
            {
                header: 'Carrier Notes',
                getValue: ({ stop }) => {
                    return stop.orders?.length !== 1 ? '' : stop.orders?.[0]?.carrier_notes;
                },
            },
        ];
    }, []);
};
