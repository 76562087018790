import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Clear } from '@material-ui/icons';
import AddressAutocomplete from '../../misc/AddressAutocomplete';
import { Body2, GridItemRow, PlanningListItem, editiconStyle, Body1 } from '../blocks';
import { css } from '@emotion/react';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { delWindowToFormattedDateRange } from '@/utilities/delWindowToFormattedDate';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '@/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import { routeTimeFormatterTZ } from '@/constants/formats';
import { InfoCircleOutlined } from '@ant-design/icons';

const ItemTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: 12,
            border: '1px solid #dadde9',
        },
    })
);

const StopBookend = ({ label, open, value, callbacks, isLocked, location, stop, timeZone, overnight }) => {
    if (isLocked && !value) {
        return null;
    }

    const { onInput, onSelect, onBlur, onFocus, onClear, isLoading } = callbacks;

    const requiresAddress = overnight && !value;

    return (
        <PlanningListItem selected={true}>
            <GridItemRow>
                <Body2
                    css={css`
                        ${requiresAddress && `color: ${colors.reds.primary};`}
                    `}
                >
                    {label}
                    {overnight && ' (required)'}
                </Body2>
            </GridItemRow>
            {stop?.stop_start_time && (
                <GridItemRow
                    css={css`
                        justify-content: flex-start;
                    `}
                >
                    <Body2>
                        {stop.start ? 'Start Time: ' : 'End Time: '}
                        {routeTimeFormatterTZ(timeZone).format(new Date(stop.stop_start_time))}
                    </Body2>
                    {stop.end && (
                        <ItemTooltip
                            title={
                                <Body1
                                    css={css`
                                        font-size: 10 !important;
                                        font-weight: 500 !important;
                                    `}
                                >
                                    End Time is calculated as the last stop's end time plus the driving time to the end
                                    location.
                                </Body1>
                            }
                        >
                            <InfoCircleOutlined css={editiconStyle} fontSize="small" />
                        </ItemTooltip>
                    )}
                </GridItemRow>
            )}

            {!isLocked ? (
                <GridItemRow>
                    <AddressAutocomplete
                        showLabel={false}
                        value={value}
                        onInput={onInput}
                        onSelect={onSelect}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        open={open}
                        disabled={isLoading}
                        location={location}
                        error={requiresAddress}
                        css={css`
                            input {
                                background-color: white;
                                ${requiresAddress &&
                                `
                                    color: ${colors.reds.primary};
                                    &:: placeholder{
                                        opacity: 1;
                                    }
                                `}
                            }
                        `}
                    />
                    {!isLoading && value?.length ? <Clear onClick={onClear} style={{ cursor: 'pointer' }} /> : null}
                </GridItemRow>
            ) : (
                <GridItemRow>
                    <Body2>{value}</Body2>
                </GridItemRow>
            )}
        </PlanningListItem>
    );
};

export default StopBookend;
