import React from 'react';

import { TableCell } from '@material-ui/core';
import { TableBody } from '../blocks';

export default function BodyCell({ cell, ...props }) {
    return (
        <TableCell align="center" {...props}>
            <TableBody>{cell.render('Cell')}</TableBody>
        </TableCell>
    );
}
