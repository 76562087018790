export const mergeArrays = (existing, incoming) => {
    const allMap = {};
    [...existing, ...incoming].forEach((item) => {
        allMap[item.__ref] = item;
    });
    return Object.values(allMap);
};

export const removeRefs = (existingRefs = [], objectsToRemove = [], { toReference }) => {
    if (objectsToRemove.length) {
        return [...existingRefs].filter(
            (objectRef) =>
                !objectsToRemove.find((removedObject) => toReference(removedObject).__ref === objectRef.__ref)
        );
    }

    return existingRefs;
};

export const addRefs = (existingRefs = [], objectsToAdd = [], { toReference }) => {
    if (objectsToAdd.length) {
        return [...existingRefs, ...objectsToAdd.map((object) => toReference(object))];
    }

    return existingRefs;
};
