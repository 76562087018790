import React, { useState, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
// import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';

import getAllOrdersFiles from '../../utilities/getAllOrdersFiles';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Backdrop,
} from '@material-ui/core';
import { TABLE_COLUMNS } from './columns';
import HeaderCell from './table/HeaderCell';
import BodyCell from './table/BodyCell';
import HeaderRow from './table/HeaderRow';
import BodyRow from './table/BodyRow';
import { fragments } from './blocks';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { useClientUser } from '../../hooks';

const OrderTable = ({ orders, route }) => {
    const navigate = useNavigate();
    const { accountType } = useClientUser();
    const [processingFileDL, setProcessingFileDL] = useState(false);

    const sortedOrders = useMemo(() => {
        if (!orders?.length) return;

        return orders.map((order) => {
            const dropoff = route?.stopsByRouteId?.find(
                (stop) => stop.type === 'DROPOFF' && stop?.orders?.includes(order.order_id)
            );
            const pickup = route?.stopsByRouteId?.find(
                (stop) => stop.type === 'PICKUP' && stop?.orders?.includes(order.order_id)
            );

            if (!dropoff || !pickup) {
                return [
                    { ...order, stopNum: -1 },
                    { pickup, dropoff },
                ];
            }

            if (order.order_type === 'return') {
                return [
                    { ...order, stopNum: pickup.ordering },
                    { pickup, dropoff },
                ];
            } else {
                return [
                    { ...order, stopNum: dropoff.ordering },
                    { pickup, dropoff },
                ];
            }
        });
    }, [orders]);

    const { headerGroups, prepareRow, rows } = useTable(
        {
            columns: TABLE_COLUMNS,
            data: sortedOrders,
            initialState: { sortBy: [{ id: 'stop', description: false }] },
            getRowId: ([order]) => order.order_id,
        },
        useSortBy
    );

    const handleFileDownload = async () => {
        setProcessingFileDL(true);

        try {
            const zip = await getAllOrdersFiles(orders);

            if (zip) {
                const zipBlob = await zip.generateAsync({ type: 'blob' });
                saveAs(zipBlob, 'Onward Route #' + route.route_number + ' - all files');
            }
        } catch (error) {
            console.error(error);
            // dispatch(
            //     notificationActions.enqueueSnackbar(
            //         getErrorSnackbar('There was an error generating your files.', {}, dispatch)
            //     )
            // );
        } finally {
            setProcessingFileDL(false);
        }
    };

    const handleRowClick = ([order]) =>
        navigate(`/order/${order.order_id}`, {
            state: {
                order: order,
                userType: accountType,
                source: 'route',
                routeSource: route.source_form || 'singleOrder',
                routeNumber: route.route_number,
                routeAlias: route.route_alias,
            },
        });

    return (
        <TableContainer component={Paper}>
            <Table aria-label="orders table">
                <TableHead>
                    {headerGroups.map((group) => (
                        <HeaderRow>
                            {group.headers.map((col) => (
                                <HeaderCell key={col.id} cell={col} align={col.align} />
                            ))}
                        </HeaderRow>
                    ))}
                </TableHead>

                <TableBody>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <BodyRow onClick={() => handleRowClick(row.original)}>
                                {row.cells.map((cell) => (
                                    <BodyCell cell={cell} align={cell.column.align} />
                                ))}
                            </BodyRow>
                        );
                    })}

                    {/* Footer row */}
                    <TableRow>
                        <TableCell
                            css={css`
                                border-bottom: 0;
                            `}
                            colSpan={TABLE_COLUMNS.length}
                            align="right"
                        >
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: flex-end;
                                `}
                            >
                                <ButtonWithSpinner
                                    loading={processingFileDL}
                                    buttonProps={{
                                        variant: 'contained',
                                        color: 'primary',
                                        onClick: handleFileDownload,
                                    }}
                                >
                                    Download All Files
                                </ButtonWithSpinner>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Backdrop
                open={processingFileDL}
                css={css`
                    ${fragments.sideNavMargin}

                    z-index: 999;
                    padding: 10px 15px;
                    padding-bottom: 88px;
                `}
            >
                <CircularProgress />
            </Backdrop>
        </TableContainer>
    );
};

export default OrderTable;
