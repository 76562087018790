import { LOCATION_TYPES } from '@/constants/locationTypes';
import { useItemQuantity, useTotalCubes, useTotalWeight } from '@/hooks';
import { useRates } from '@/hooks/useRates';
import { startCase } from 'lodash';
import React, { useContext, useState, useMemo } from 'react';
import { Context } from '../store';
import { CardHeaderLine, CardHeaderText, CardLine, CardText, IndentedCardLine, ListCard } from './blocks';
import statesMap from '@/constants/statesMap';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IconButton } from '@material-ui/core';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { recommendedEquipmentLabels } from '@/constants/recommendedEquipmentLabels';

const MarketplaceOrderCard = ({ order }) => {
    const { state, callbacks } = useContext(Context);
    const [showMore, setShowMore] = useState(false);

    const [shipRate, carrRate] = useRates(order);
    const weight = useTotalWeight([order]);
    const cubes = useTotalCubes([order]);
    const qty = useItemQuantity([order]);

    const pickupLabel = order.order_type === 'return' ? 'Return Pickup' : 'Pickup';
    const dropoffLabel = order.order_type === 'return' ? 'Return Dropoff' : startCase(order.order_type);

    const showMoreClickHandler = (e) => {
        setShowMore(!showMore);
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
    };

    const itemTypes = (order.itemsByOrderId || [])
        .map((item) => item.item_type_details || item.description)
        .filter((x) => x)
        .map((x) => startCase(x));

    const {
        ['2_man_team']: twoManTeam,
        ['3_man_team']: threeManTeam,
        lift_gate,
        ...otherEquipment
    } = useMemo(() => {
        return order.recommended_equipment
            ? Object.keys(order.recommended_equipment)
                  .filter((key) => order.recommended_equipment[key])
                  .reduce((acc, val) => ({ ...acc, [val]: recommendedEquipmentLabels[val] }), {})
            : {};
    }, []);

    return (
        <ListCard
            clickable={true}
            selected={state.selectedOrder?.order_id === order.order_id}
            onClick={() => callbacks.selectOrder(order)}
        >
            <CardHeaderLine>
                <CardHeaderText>{order.order_number}</CardHeaderText>
                <CardHeaderText>
                    ${shipRate?.toFixed(0) || '?'} / ${carrRate?.toFixed(0) || '?'}
                </CardHeaderText>
            </CardHeaderLine>
            <CardLine>
                <CardText>Shipper: {order.order_shipper?.business_name}</CardText>
            </CardLine>
            <CardLine>
                <CardText>
                    {order.pickup_city}, {statesMap[order.pickup_state] || order.pickup_state} - {order.dropoff_city},{' '}
                    {statesMap[order.dropoff_state] || order.dropoff_state}
                </CardText>
            </CardLine>

            <CardLine>
                <CardText>Available: {order.first_available_date_formatted}</CardText>
            </CardLine>

            {/* Items */}
            <CardLine>
                <CardText>Freight Type: {startCase(order.freight_type)}</CardText>
            </CardLine>

            {!showMore && (
                <CardLine onClick={(e) => showMoreClickHandler(e)}>
                    <span
                        css={css`
                            color: ${colors.greens.primary};
                            font-weight: 700;
                            font-size: 14px;
                        `}
                    >
                        Show More{' '}
                    </span>
                    <IconButton
                        color="primary"
                        css={css`
                            padding: 0;
                        `}
                    >
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                </CardLine>
            )}

            {showMore && (
                <>
                    {order.delivery_date && (
                        <CardLine>
                            <CardText>Scheduled: {order.delivery_date_formatted}</CardText>
                        </CardLine>
                    )}
                    <CardLine>
                        <CardText>
                            {qty} Items / {cubes?.toFixed(0)} cu ft. / {weight?.toFixed(0)} lbs
                        </CardText>
                    </CardLine>
                    {itemTypes.length > 0 && (
                        <CardLine>
                            <CardText>Item Type(s): {itemTypes.join(', ')}</CardText>
                        </CardLine>
                    )}

                    {/* Requirements */}
                    <CardLine>
                        <CardText
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            Requirements
                        </CardText>
                    </CardLine>
                    {/* Truck Size TBD */}
                    {/* <IndentedCardLine>
                        <CardText>Truck Size: </CardText>
                    </IndentedCardLine> */}
                    <IndentedCardLine>
                        <CardText>Lift Gate: {lift_gate ? 'Yes' : 'No'}</CardText>
                    </IndentedCardLine>
                    <IndentedCardLine>
                        <CardText>Total People: {threeManTeam ? '3' : twoManTeam ? '2' : '1'}</CardText>
                    </IndentedCardLine>
                    <IndentedCardLine>
                        <CardText>White Glove: {order.dropoff_location_type === 'whiteGlove' ? 'Yes' : 'No'}</CardText>
                    </IndentedCardLine>
                    {/* Appliance Install TBD */}
                    {/* <IndentedCardLine>
                        <CardText>Appliance Install: </CardText>
                    </IndentedCardLine> */}
                    <IndentedCardLine>
                        <CardText>Other Equipment: {Object.values(otherEquipment).join(', ') || 'None'}</CardText>
                    </IndentedCardLine>

                    {/* Pickup */}
                    <CardLine>
                        <CardText>
                            {pickupLabel}
                            {`: `}
                        </CardText>
                    </CardLine>
                    <IndentedCardLine>
                        <CardText>{order.pickup_full_address}</CardText>
                    </IndentedCardLine>
                    <IndentedCardLine>
                        <CardText>
                            {order.pickup_location_info || order.pickup_location}
                            {` / `}
                            {LOCATION_TYPES[order.pickup_location_type]}
                        </CardText>
                    </IndentedCardLine>

                    {/* Dropoff */}
                    <CardLine>
                        <CardText>
                            {dropoffLabel}
                            {`: `}
                        </CardText>
                    </CardLine>
                    <IndentedCardLine>
                        <CardText>{order.dropoff_full_address}</CardText>
                    </IndentedCardLine>
                    <IndentedCardLine>
                        <CardText>
                            {order.dropoff_location_info || order.dropoff_location}
                            {` / `}
                            {LOCATION_TYPES[order.dropoff_location_type]}
                        </CardText>
                    </IndentedCardLine>
                    {!!order.delivery_stair_quantity && (<IndentedCardLine>
                        <CardText>
                            Flights of stairs: {order.delivery_stair_quantity}
                        </CardText>
                    </IndentedCardLine>)}
                    {!!order.customer_instructions && (<IndentedCardLine>
                        <CardText>
                            Special Del Instru.: {order.customer_instructions}
                        </CardText>
                    </IndentedCardLine>)}

                    {showMore && (
                        <CardLine onClick={(e) => showMoreClickHandler(e)}>
                            <span
                                css={css`
                                    color: ${colors.greens.primary};
                                    font-weight: 700;
                                    font-size: 14px;
                                `}
                            >
                                Show Less{' '}
                            </span>
                            <IconButton
                                color="primary"
                                css={css`
                                    padding: 0;
                                `}
                            >
                                <KeyboardArrowDownIcon />
                            </IconButton>
                        </CardLine>
                    )}
                </>
            )}
        </ListCard>
    );
};

export default MarketplaceOrderCard;
