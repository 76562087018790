import { parsePhoneNumber } from 'libphonenumber-js';

export const validatePhoneNumber = (phone) => {
    if (phone && phone.length) {
        try {
            const parsedPhoneNumber = parsePhoneNumber(phone, 'US');
            if (parsedPhoneNumber.isValid()) {
                return true;
            }
        } catch (e) {
            // console.error(e);
        }
    }

    return false;
};
