import React, { useMemo } from 'react';
import { calcItemCount, calcItemWeight, calcItemVolume, calcItemVolumeSubscription, calcItemWeightSubscription, calcAnyItemTypeCount, calcExchangeVolumes } from '@/utilities/calculateItemMetrics';

export const useItemQuantity = (orders) => {
    return useMemo(() => {
        return calcItemCount(orders);
    }, [orders]);
};

export const useTotalWeight = (orders) => {
    return useMemo(() => {
        return calcItemWeight(orders);
    }, [orders]);
};

export const useTotalCubes = (orders) => {
    return useMemo(() => {
        return calcItemVolume(orders);
    }, [orders]);
};

export const useTotalCubesSubscription = (orders) => {
    return useMemo(() => {
        return calcItemVolumeSubscription(orders);
    }, [orders]);
};

export const useExchangeCubes = (orders) => {
    return useMemo(() => {
        return calcExchangeVolumes(orders);
    }, [orders]);
};

export const useTotalWeightSubscription = (orders) => {
    return useMemo(() => {
        return calcItemWeightSubscription(orders);
    }, [orders]);
};

export const useTotalAnyItemType = (orders) => {
    return useMemo(() => {
        return calcAnyItemTypeCount(orders);
    }, [orders]);
};