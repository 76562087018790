import React, { useState, useEffect } from 'react';
import { Row, Container, Card, Col } from 'react-bootstrap';
import { LocationPermissionsTitle, LocationPermissionsTitleSpan, LocationPermissionsText, CardSubtext } from './blocks';
import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { useClientUser } from '@/hooks';
import { UPDATE_FEATURE_FLAGS } from '@/graphql/mutations/users';
import { QUERY_BY_LEADER } from '@/graphql/queries/teammates';
import { Grid } from '@material-ui/core';
import { OnwardToggle } from '../ShipmentForm/blocks';

export default function Communications({ comms }) {
    const user = useClientUser();
    const [loading, setLoading] = useState(false);
    const [teammates, setTeammates] = useState([]);

    useQuery(QUERY_BY_LEADER, {
        variables: { leader_id: user.user_id },
        onCompleted: (data) => setTeammates(data.results.map((teammate) => teammate.teammate_id)),
    });

    const [updateCircles] = useMutation(UPDATE_FEATURE_FLAGS, {
        onError: (e) => console.log(e),
        onCompleted: (data) => console.log(data),
    });

    const toggleUserComms = async (featureFlag) => {
        setLoading(true);

        const circles = {
            ...(user?.circles || {}),
            [featureFlag]: !user?.circles?.[featureFlag],
        };

        let userList = [user.user_id, ...teammates];

        try {
            await updateCircles({
                variables: {
                    user_list: userList,
                    circles,
                },
            });
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    };

    return (
        <>
            <Card
                css={css`
                    margin-top: 30px;
                `}
            >
                <Card.Body
                    css={css`
                        padding: 28px 26px 28px 26px;
                    `}
                >
                    <LocationPermissionsTitle>
                        <LocationPermissionsTitleSpan>Communications</LocationPermissionsTitleSpan>
                    </LocationPermissionsTitle>
                    <Grid
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 30px;
                        `}
                    >
                        {comms.map((comm) => (
                            <>
                                <Grid
                                    container
                                    css={css`
                                        flex-wrap: nowrap;
                                    `}
                                >
                                    <Grid item>
                                        <OnwardToggle
                                            value={!!user?.circles?.[comm.featureFlag]}
                                            onChange={() => toggleUserComms(comm.featureFlag)}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        css={css`
                                            margin-left: 13px;
                                        `}
                                    >
                                        <Grid item>
                                            <LocationPermissionsText>{comm.display}</LocationPermissionsText>
                                        </Grid>
                                        <Grid item>
                                            <CardSubtext>{comm.description}</CardSubtext>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Card.Body>
            </Card>
        </>
    );
}
