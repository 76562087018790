import { gql } from '@apollo/client';

import { EXCEPTION_MAPPINGS_FIELD } from '@/graphql/fragments/exception_mappings';
import { EXCEPTION_FIELDS } from '@/graphql/queries/db_exceptions';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { WAREHOUSE_EVENT_FIELDS } from '@/graphql/queries/order_wh_events';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const GET_SHIPPERS = gql`
    ${CLIENT_FIELDS}

    query GetShippers($where: orders_bool_exp!) {
        results: orders(distinct_on: [shipper_id], where: $where) {
            order_id
            shipper_id
            order_shipper {
                ...ClientFields
            }
        }
    }
`;

export const GET_CARRIERS = gql`
    ${CLIENT_FIELDS}

    query GetCarriers($where: orders_bool_exp!) {
        results: orders(distinct_on: [carrier_id], where: $where) {
            order_id
            carrier_id
            order_carrier {
                ...ClientFields
            }
        }
    }
`;

export const GET_EXCEPTIONS = gql`
    ${EXCEPTION_MAPPINGS_FIELD}
    ${EXCEPTION_FIELDS}
    ${ITEM_FIELDS}
    ${ORDER_FIELDS}
    ${WAREHOUSE_EVENT_FIELDS}
    ${CLIENT_FIELDS}

    query GetExceptions($where: exception_mappings_bool_exp!) {
        results: exception_mappings(
            where: $where
            order_by: [{ created_at: desc }, { exception_type: desc }]
            limit: 100
        ) {
            ...ExceptionMappingsFields

            order {
                ...OrderFields

                order_shipper {
                    ...ClientFields
                }

                order_carrier {
                    ...ClientFields
                }

                itemsByOrderId {
                    ...ItemFields
                }
            }

            item {
                ...ItemFields
            }

            exception {
                ...ExceptionFields
            }
        }
    }
`;

export const RESOLVE_EXCEPTION = gql`
    ${ORDER_FIELDS}
    ${WAREHOUSE_EVENT_FIELDS}
    ${ITEM_FIELDS}
    ${CLIENT_FIELDS}

    mutation ResolveException(
        $exceptions: [uuid!]
        $events: [uuid!]
        $orders: [uuid!]
        $items: [uuid!]
        $order_update: orders_set_input = {}
        $event_update: order_wh_events_set_input = {}
        $exception_update: exceptions_set_input = {}
        $route_id: uuid
        $route_update: routes_set_input = {}
        $include_route_update: Boolean!
    ) {
        event: update_order_wh_events(where: { event_id: { _in: $events } }, _set: $event_update) {
            affected_rows
        }

        exception: update_exceptions(where: { exception_id: { _in: $exceptions } }, _set: $exception_update) {
            affected_rows
            returning {
                exception_id
                status
            }
        }

        items_removed: delete_items(where: { item_id: { _in: $items } }) {
            affected_rows
            returning {
                item_id
            }
        }

        order: update_orders(where: { order_id: { _in: $orders } }, _set: $order_update) {
            affected_rows
            returning {
                ...OrderFields

                order_shipper {
                    ...ClientFields
                }

                order_carrier {
                    ...ClientFields
                }

                itemsByOrderId {
                    ...ItemFields
                }
            }
        }
        route: update_routes(where: { route_id: { _eq: $route_id } }, _set: $route_update)
            @include(if: $include_route_update) {
            affected_rows
            returning {
                route_id
                route_number
                estimated_driving_distance
                estimated_driving_time
            }
        }
    }
`;
