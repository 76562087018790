import JSZip from 'jszip';

import getOrdersFilesAsPDF from './getOrdersFilesAsPDF';
import getOrdersPhotosAsZip from './getOrdersPhotosAsZip';

// import notificationActions from '../redux/actions/notificationActions'
// import { getErrorSnackbar } from './defaultSnackbars'

/**
 * Zip together all POD, BOL, and final delivery photo files from a list of orders
 *
 * @param {array} orders List of order objects to process
 * @returns Either a JSZip object with all relevant files zipped or false if no files found
 */
export default async function getAllOrdersFiles(orders, dispatch) {
    const zip = new JSZip();

    let hasAnyFiles = false;
    const corruptFiles = [];
    for (const order of orders) {
        if (order.bol_file) {
            const bols = await getOrdersFilesAsPDF(order, 'bol_file', corruptFiles);

            if (bols) {
                hasAnyFiles = true;
                zip.file(`${order.order_number} - BOL.pdf`, await bols.save());
            }
        }

        if (order.pod) {
            const pods = await getOrdersFilesAsPDF(order, 'pod', corruptFiles);

            if (pods) {
                hasAnyFiles = true;
                zip.file(`${order.order_number} - POD.pdf`, await pods.save());
            }
        }

        const orderPhotosZip = await getOrdersPhotosAsZip(order, corruptFiles);

        if (orderPhotosZip) {
            hasAnyFiles = true;
            const zipBlob = await orderPhotosZip.generateAsync({ type: 'blob' });
            await zip.loadAsync(zipBlob, { createFolders: true });
        }
    }

    if (hasAnyFiles) {
        if (corruptFiles.length > 0) {
            // dispatch(
            //     notificationActions.enqueueSnackbar(
            //         getErrorSnackbar(
            //             'Some files were corrupt and could not be downloaded.',
            //             { disableWindowBlurListener: false },
            //             dispatch
            //         )
            //     )
            // )
        }
        return zip;
    } else {
        return false;
    }
}
