import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { typography, colors } from '@/styles';

export const H1 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 28px;
    line-height: 1.2;
`;

export const H2 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
`;

export const H3 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

export const Body2 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
`;

export const StatusBadge = styled.div`
    padding: 8px 18px;
    border-radius: 5px;
    border: 3px solid transparent;
    border-color: ${(props) => {
        if (props.error) {
            return '#D23E3E';
        }

        return colors.greens.primary;
    }};

    color: ${(props) => {
        if (props.error) {
            return '#D23E3E';
        }

        return colors.greens.primary;
    }};
`;
