import { gql } from '@apollo/client';

export const JOB_FIELDS = gql`
    fragment JobFields on jobs {
        batches_complete
        batches_total
        client_id
        created_at
        filename
        job_id
        last_modified_at
        status
        type
    }
`;
