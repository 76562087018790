import { gql } from '@apollo/client';

export const SUBREGION_CARRIER_FIELDS = gql`
    fragment SubregionCarrierFields on subregion_carrier_mappings {
        mapping_id
        subregion_id
        carrier_id
        warehouse_id
        is_direct
    }
`;
