import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import * as typography from '@/styles/typography';

export const local = {
    greys: ['#0C0B1D'],
};

export const HR = styled.div`
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    border: 1px solid rgba(76, 76, 76, 0.5);
`;

export const Tag = styled.div`
    padding: 0 8px;
    background: #d9d9d9;
    border-radius: 5px;
    margin: 2px 8px;

    :hover {
        background: #bfbfbf;
    }
`;

export const PageTitle = styled.span`
    ${typography.sansSerif}
    font-size: 28px;
    font-weight: 800;
    line-height: 34px;
`;

export const Header1 = styled.span`
    ${typography.sansSerif}
    font-size: 22px;
    font-weight: 800;
    line-height: 27px;
`;

export const Header2 = styled.span`
    ${typography.sansSerif}
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
`;

export const Body2 = styled.span`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
`;
