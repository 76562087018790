const driver =
    'The user can only view/update orders that they are specifically assigned to. They cannot view the Load Board. Neither can they submit, claim, or unclaim orders.';

export const shipperRoles = {
    viewer: 'Provides ability to track orders, view orders and view FTL Routes only without prices listed.',
    admin: 'The user has full access to the account including creating and editing shipments and FTL Routes.',
};

export const saasShipperRoles = {
    driver,
    viewer: 'Provides ability to track orders, view orders and view FTL Routes only without prices listed.',
    admin: 'The user has full access to the account including creating and editing shipments and FTL Routes.',
};

export const carrierRoles = {
    driver,
    admin: 'The user has access to the Load Board, as well as to submit, claim, update, and unclaim orders.',
};

export const extraRoles = {
    accounting: 'The user has access to the Accounting tab.',
    warehouse: 'The user has access to WMS features - IB/OB Manifests and Warehousing Setup',
};

export const teammateRoles = {
    admin: 'The user has full access to the account, including invoicing features',
    viewer: 'The user has limited access to the account',
    driver: 'The user can only view and update orders that are assigned to them',
    helper: 'The user can only view and update orders that are assigned to them',
    warehouse: 'The user has access to WMS features',
};
