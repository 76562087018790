import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';

import { Body1 } from './blocks';

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const ORDER_INFO = [
    {
        label: 'Reported At',
        value: ({ order, exception }) => {
            return dateShort.format(new Date(exception.created_at));
        },
    },
    {
        label: 'Shipper',
        value: ({ order, exception }) => {
            return order?.order_shipper?.business_name || '--';
        },
    },
    {
        label: 'Carrier',
        value: ({ order, exception }) => {
            return order?.order_carrier?.business_name || '--';
        },
    },
];

const OrderInfo = ({ order, exception }) => {
    return (
        <>
            {ORDER_INFO.map(({ label, value }) => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <Grid
                            direction="column"
                            container
                            css={css`
                                flex: 0;
                                flex-basis: 0;
                                margin-right: 12px;
                            `}
                        >
                            <Body1
                                css={css`
                                    white-space: nowrap;
                                `}
                            >
                                {label}:
                            </Body1>
                        </Grid>
                        <Grid
                            direction="column"
                            container
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                            `}
                        >
                            <Body1>{value({ order, exception })}</Body1>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

export default OrderInfo;
