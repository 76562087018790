import React from 'react';
import { View } from '@react-pdf/renderer';
import BodyRow from './BodyRow';
import HeaderRow from './HeaderRow';

const Table = ({ schema, data }) => {
    return (
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} wrap>
            <HeaderRow schema={schema} />

            {data.map((stop) => {
                return <BodyRow schema={schema} data={stop} />;
            })}
        </View>
    );
};

export default Table;
