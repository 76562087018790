import React from 'react';

import { TableCell } from '@material-ui/core';
import { TableHeader } from '../blocks';

export default function HeaderCell({ cell, ...props }) {
    return (
        <TableCell align="center" {...props}>
            <TableHeader>{cell.render('Header')}</TableHeader>
        </TableCell>
    );
}
