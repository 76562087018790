import { FIXED_CHARGES } from '@/components/Accessorials/constants';

/**
 * Calculates the subtotal for an invoice order
 * @param {Object} order - The order object
 * @param {string} breakdown - The breakdown type (e.g., 'carrierBreakdown')
 * @returns {number} The subtotal for the order
 */
export const calcOrderSubtotal = (order) => {
    const breakdown = order.oms ? 'internalBreakdown' : 'carrierBreakdown';
    if (order.oms) {
        return FIXED_CHARGES.map(({ key: attr }) => {
            return order?.price_breakdown?.[breakdown]?.[attr] || 0;
        }).reduce((acc, val) => acc + val, 0);
    }

    const subTotal =
        order?.admin_carrier_rate_override != null
            ? order?.admin_carrier_rate_override
            : FIXED_CHARGES.map(({ key: attr }) => {
                  return order?.price_breakdown?.[breakdown]?.[attr] || 0;
              }).reduce((acc, val) => {
                  return acc + val;
              }, 0);

    const subTotalAdj =
        order?.admin_carrier_rate_override != null
            ? order?.admin_carrier_rate_override
            : subTotal > order.carrier_rate
            ? order.carrier_rate
            : subTotal;

    return subTotalAdj;
};
