import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SingleOrderItem from '../OrderDetailsPage/OrderItems/SingleOrderItem';
import Haulaway from '../OrderDetailsPage/OrderItems/Haulaway';
import { OnwardCard } from '../OrderDetailsPage/Card';

function OrderItems(props) {
    const order = props.order;
    const deliveries = order?.itemsByOrderId?.filter((i) => !i.is_return) || [];
    const deliveryCount = deliveries.reduce((acc, o) => acc + parseInt(o.quantity), 0);
    const returns = order?.itemsByOrderId?.filter((i) => i.is_return) || [];
    const returnCount = returns.reduce((acc, o) => acc + parseInt(o.quantity), 0);

    return (
        <Grid container className="overflow-auto h-100">
            <>
                {/* Todo: clean this up to match orderdetailspage redesign eng-5127 once it is finished */}
                <Grid item sm={12} className="d-flex align-items-center">
                    <Typography variant="h6" className="mt-3" color="secondary">
                        Items
                    </Typography>
                    <Typography variant="h6" className="item-qty-text mt-3">
                        {deliveryCount}
                    </Typography>
                </Grid>

                <OnwardCard>
                    {deliveries.length && deliveries?.map((_item) => SingleOrderItem({ item: _item, order }))}
                </OnwardCard>

                {returns.length ? (
                    <>
                        <Grid item sm={12} className="d-flex align-items-center">
                            <Typography variant="h6" className="mt-3" color="secondary">
                                Return Items
                            </Typography>
                            <Typography variant="h6" className="item-qty-text mt-3">
                                {returnCount}
                            </Typography>
                        </Grid>

                        <OnwardCard>
                            {returns.length && returns?.map((_item) => SingleOrderItem(_item, order))}
                        </OnwardCard>
                    </>
                ) : null}

                <Haulaway order={order} />
            </>
        </Grid>
    );
}

export default OrderItems;
