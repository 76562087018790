import styled from '@emotion/styled';
import { typography } from '@/styles';
import { colors } from '@/styles';

export const Card = styled.div`
    background: ${colors.white.primary};
    border-radius: 5px;
    padding: 12px 24px;

    box-shadow: 0px 1px 3px 0px #3f3f4426;
    box-shadow: 0px 0px 0px 1px #3f3f440d;
`;

export const Container = styled.div`
    flex-direction: column;
    display: flex;
    width: fit-content;
    padding-left: 60px;
    padding-right: 60px;
    height: 100%;
    margin: auto;
    margin-top: 40px;
    padding-bottom: 68px;
`;

export const H1 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;
