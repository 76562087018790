export const SUPPORT_PHONE_NUMBER = '+19708710003';

export const PROGRESSION_TYPES = {
    RESIDENTIAL: 'RESIDENTIAL',
    BUSINESS: 'BUSINESS',
    CREATE_ROOT: 'CREATE_ROOT',
};

export const STAGES = {
    PICKUP_RESIDENTIAL: 'PICKUP_RESIDENTIAL',
    PICKUP_BUSINESS: 'PICKUP_BUSINESS',
    DROPOFF: 'DROPOFF',
    ITEM_TYPES: 'ITEM_TYPES',
    MATTRESS: 'MATTRESS',
    BOX_SPRINGS: 'BOX_SPRINGS',
    BED_FRAMES: 'BED_FRAMES',
    FURNITURE: 'FURNITURE',
    OTHER: 'OTHER',
    APPLIANCES: 'APPLIANCES',
    HAULAWAY: 'HAULAWAY',
    PAYMENT: 'PAYMENT',
};

export const FORM_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETE: 'COMPLETE',
    FAILED: 'FAILED',
};

export const ITEM_TYPE_STAGE_MAPPING = {
    furniture: STAGES.FURNITURE,
    mattress: STAGES.MATTRESS,
    boxSpring: STAGES.BOX_SPRINGS,
    bedFrame: STAGES.BED_FRAMES,
    appliance: STAGES.APPLIANCES,
    other: STAGES.OTHER,
};

export const CUSTOMER_FORM_PROGRESSION = {
    //residential taken out of flow as of mid feb 2024
    [PROGRESSION_TYPES.RESIDENTIAL]: [
        STAGES.PICKUP_RESIDENTIAL,
        STAGES.DROPOFF,
        STAGES.ITEM_TYPES,
        STAGES.HAULAWAY,
        STAGES.PAYMENT,
    ],
    [PROGRESSION_TYPES.BUSINESS]: [
        STAGES.PICKUP_BUSINESS,
        STAGES.DROPOFF,
        STAGES.ITEM_TYPES,
        STAGES.HAULAWAY,
        STAGES.PAYMENT,
    ],
};

export const DEFAULT_ORDER = {
    po_number: 'n/a',
    order_type: 'delivery',
    freight_type: 'household',
    order_status: 'pending',
    known_availability: false,
    source_form: 'CUSTOMER',
    oms: false,
    planning: false,
    itemsByOrderId: [],
    haulaway_items: [],
};
