import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { CONFIG } from './config';

const commonConfig = {
    apiKey: 'AIzaSyDU7msBN2PAZbaFiTOJerWAlvBrQKfHSZI',
    authDomain: 'onward-new.firebaseapp.com',
    projectId: 'onward-new',
    messagingSenderId: '639264279089',
    appId: '1:639264279089:web:1fcb9088e6d49bb664a310',
    measurementId: 'G-K0N4NVXXRC',
    redditMeasurementId: 't2_c188clru2',
    metaMeasurementId: '676827894221064',
    twitterMeasurementId: 'of7nd',
};

const stagingFirebaseConfig = {
    apiKey: 'AIzaSyC-6iz0umOSeONJsf8x3mvhA08InhyawZA',
    authDomain: 'onward-staging.firebaseapp.com',
    projectId: 'onward-staging',
    storageBucket: 'onward-staging.appspot.com',
    messagingSenderId: '768012175379',
    appId: '1:768012175379:web:9a0933f2362d100a7dd7e6',
    measurementId: 'G-B807YQLNTC',
};

const localFirebaseConfig = {
    ...commonConfig,
    storageBucket: 'onward-new.appspot.com',
};

const prodFirebaseConfig = {
    ...commonConfig,
    storageBucket: 'onward-prod',
};

const configs = {
    development: localFirebaseConfig,
    production: prodFirebaseConfig,
    staging: stagingFirebaseConfig,
};

export const initFirebase = () => {
    const app = initializeApp(configs[CONFIG.ENV]);

    const fbAuth = getAuth(app);
    const fbStorage = getStorage(app);

    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        console.log('Connecting to local authentication emulator');
        connectAuthEmulator(fbAuth, 'http://127.0.0.1:9099');
        console.log('Connection to local storage emulator');
        connectStorageEmulator(fbStorage, 'localhost', 9199);
    }

    return app;
};
