import { useMemo } from 'react';

export const useNotePermissions = (route, user_id) => {
    return useMemo(() => {
        if (!route || !user_id)
            return {
                shipper_notes: {
                    view: true,
                    edit: true,
                },
                carrier_notes: {
                    view: true,
                    edit: true,
                },
            };
        if (!route?.carrier_id || route?.carrier_id === route?.shipper_id) {
            // If I'm the shipper and there is no carrier, or I am the carrier
            // I should be able to see and edit both notes
            return {
                shipper_notes: {
                    view: true,
                    edit: true,
                },
                carrier_notes: {
                    view: true,
                    edit: true,
                },
            };
        } else if (route?.shipper_id === user_id && !!route?.carrier_id && route?.carrier_id !== user_id) {
            // If I am the shipper and there is a carrier who is not me
            if (['planning', 'pending', 'open'].includes(route?.status)) {
                // If the route is not active yet, I can still edit both of the notes
                return {
                    shipper_notes: {
                        view: true,
                        edit: true,
                    },
                    carrier_notes: {
                        view: true,
                        edit: true,
                    },
                };
            } else {
                // If the route is active,
                // I can only view and edit the shipper note, but can only see and not edit carrier notes
                return {
                    shipper_notes: {
                        view: true,
                        edit: true,
                    },
                    carrier_notes: {
                        view: true,
                        edit: false,
                    },
                };
            }
        } else {
            // If I am the carrier and not the shipper
            // I can view the shipper notes from the shipper, but cannot edit them
            // I can view and edit the carrier notes
            return {
                shipper_notes: {
                    view: true,
                    edit: true,
                },
                carrier_notes: {
                    view: true,
                    edit: true,
                },
            };
        }
    }, [route, user_id]);
};
