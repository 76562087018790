import React from 'react';
import { css } from '@emotion/react';
import { SecondaryButton } from '@/styles/blocks';
import { Grid } from '@material-ui/core';

import { ResponsiveSidebarDialog, StickyModalActions, ModalContent, PrimaryButton } from '@/styles/blocks';

const ConfirmationModal = ({ open, content, callbacks }) => {
    return (
        <ResponsiveSidebarDialog open={open} onClose={callbacks.onClose}>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                {content}
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton onClick={callbacks.onConfirm}>Yes</PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default ConfirmationModal;
