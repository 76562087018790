import { colors } from '@/styles';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { OnwardCheckbox } from '../ShipmentForm/blocks';
import { css } from '@emotion/react';
import { loadStripe } from '@stripe/stripe-js';
import { CONFIG } from '@/config';
import OrderSummary from '../Customer/ShipmentForm/OrderSummary';
import { CircularProgress, Grid } from '@material-ui/core';
import { Body1, Body2 } from './blocks';
import ExpectationsModal from '../Customer/modals/Expectations';
import { PrimaryButton } from '@/styles/blocks';

const Checkout = ({ state, callbacks }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [accepted, setAccepted] = useState(false);

    return (
        <>
            <Grid
                item
                css={css`
                    margin-top: 20px;
                `}
            >
                <Body2>Enter Card Information</Body2>
            </Grid>
            <PaymentElement
                css={css`
                    height: fit-content;
                `}
            />
            <Grid
                item
                direction="row"
                css={css`
                    margin-top: 40px;
                    margin-bottom: 40px;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid
                    item
                    container
                    direction="column"
                    css={css`
                        justify-content: center;
                    `}
                >
                    <Body1>
                        <OnwardCheckbox
                            color="primary"
                            checked={accepted}
                            onChange={() => setAccepted((prev) => !prev)}
                        />
                        I agree to the{' '}
                        <a
                            target="_blank"
                            href="https://www.onwarddelivery.com/terms-and-conditions"
                            css={css`
                                text-decoration: none;
                            `}
                        >
                            <Body1
                                css={css`
                                    font-weight: 700;
                                    cursor: pointer;
                                    color: ${colors.greens.primary};
                                `}
                            >
                                Terms and Conditions
                            </Body1>
                        </a>
                    </Body1>
                </Grid>
            </Grid>
            <Grid
                item
                css={css`
                    background: #ececec;
                    margin: 0 12px;
                    padding: 14px;
                    border-radius: 5px;
                    margin-bottom: 20px;
                `}
            >
                <Grid container direction="column">
                    <Grid item>
                        <Body1>What should I expect after submitting?</Body1>
                    </Grid>
                    <Grid item>
                        <Body1
                            css={css`
                                font-weight: 700;
                                cursor: pointer;
                                color: ${colors.greens.primary};
                            `}
                            onClick={callbacks.showExpectations}
                        >
                            Find out here &gt;
                        </Body1>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                css={css`
                    margin-top: 40px;
                    margin-bottom: 64px;
                `}
            >
                <PrimaryButton
                    disabled={state.isLoading || !accepted}
                    onClick={() => callbacks.confirmPayment({ stripe, elements })}
                    css={css`
                        padding: 12px 24px;
                    `}
                >
                    {'Submit'}
                </PrimaryButton>
            </Grid>
        </>
    );
};

const Payment = ({ isDesktop, state, callbacks }) => {
    const [showExpectationsModal, setShowExpectationsModal] = useState(false);

    const stripePromise = loadStripe(CONFIG.STRIPE_PK);
    const stripeOptions = {
        clientSecret: state.paymentInfo.clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: colors.greens.primary,
            },
        },
    };

    return (
        <Grid
            container
            css={css`
                width: ${isDesktop ? '500px' : 'auto'};
                padding: 0 ${isDesktop ? '35px' : '12px'};
            `}
        >
            <Grid item container direction="column">
                <OrderSummary state={state} />
                {state.paymentInfo?.clientSecret ? (
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <Checkout
                            callbacks={{
                                ...callbacks,
                                showExpectations: () => setShowExpectationsModal(true),
                            }}
                            state={state}
                        />
                    </Elements>
                ) : (
                    <div
                        css={css`
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            margin-top: 40px;
                            margin-bottom: 40px;
                        `}
                    >
                        <CircularProgress
                            size={200}
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        />
                    </div>
                )}
            </Grid>
            <ExpectationsModal
                open={showExpectationsModal}
                callbacks={{
                    onClose: () => setShowExpectationsModal(false),
                }}
            />
        </Grid>
    );
};

export default Payment;
