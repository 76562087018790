import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { colors } from '@/styles';
import Loader from '../Loader';
import React, { forwardRef } from 'react';

export const Body1 = styled.span`
    font-size: 14px;
    font-weight: 700;
`;

export const Body2 = styled.span`
    font-size: 14px;
    font-weight: 500;
`;
export const Body3 = styled.span`
    font-size: 12px;
    font-weight: 400;
    margin-left: 12px;
`;

export const H1 = styled.h1`
    font-size: 18px !important;
    font-weight: 700;
`;

export const H2 = styled.h2`
    font-size: 16px !important;
    font-weight: 800;
`;

export const H3 = styled.h3`
    font-size: 16px !important;
    font-weight: 700;
`;

export const H4 = styled.h4`
    font-size: 16px !important;
    font-weight: 600;
`;

export const RouteCardGrayText = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.greys.primary};
    font-style: italic;
`;

export const FullHeightGrid = styled((props) => <Grid {...props} />)`
    height: 100%;
`;

export const GrowingGrid = styled((props) => <Grid {...props} />)`
    flex-grow: 1 !important;
    flex-wrap: nowrap !important;
    overflow-y: hidden !important;
    align-items: flex-start !important;
`;

export const Divider = styled.div`
    width: 100%;
    margin: 14px 0;
    border-bottom: 1px solid #e2e2e2;
`;

export const SubmitLoader = styled((props) => (
    <div {...props}>
        <CircularProgress
            size={200}
            css={css`
                color: ${colors.greens.primary};
            `}
        />
        <Body1
            css={css`
                margin-top: 20px;
            `}
        >
            Please wait
        </Body1>
        <Body2>Do not refresh the page</Body2>
    </div>
))`
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const GridItemRow = styled((props) => <Grid item xs={12} {...props} />)`
    display: flex;
    flex: 0;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.1rem !important;
    margin-bottom: 0.1rem !important;
`;

export const GridScrollY = styled((props) => <Grid container alignContent="flex-start" {...props} />)`
    height: 100%;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #666;
        border-radius: 20px;
    }
`;

export const PlanningIconButton = styled((props) => <IconButton {...props} />)`
    padding: 0 !important;
    margin-left: 1rem !important;
`;

export const iconButtonCSS = css`
    padding: 0 !important;
    margin-left: 4px;
    margin-right: 4px;
`;

export const iconStyle = css`
    color: black;
    margin-left: 1rem !important;
`;

export const editiconStyle = css`
    color: grey;
    size: 0.1rem;
    margin-left: 5px !important;
`;

export const circleStyle = css`
    color: ${colors.greens.primary};
    cursor: pointer;
`;

export const RouteDetailsIconButton = styled(
    ({ disabled, Icon, onClick, tooltip, disabledTooltip, loading, ...props }) => {
        if (Icon) {
            return (
                <Tooltip
                    placement="top"
                    title={disabled ? (typeof disabledTooltip === 'string' ? disabledTooltip : tooltip) : tooltip}
                    {...props}
                >
                    <span
                        onClick={onClick}
                        css={css`
                            cursor: pointer;
                        `}
                    >
                        <IconButton
                            disabled
                            css={css`
                                padding: 0px;
                            `}
                        >
                            <Icon
                                style={{
                                    color: disabled ? colors.greys.primary : colors.greens.primary,
                                }}
                            />
                        </IconButton>
                    </span>
                </Tooltip>
            );
        }
        return <></>;
    }
)`
    padding: 0 !important;
)}`;

const confirmationStatusColors = {
    pending: colors.golds[1],
    recalculate: colors.golds[1],
    'response unclear - call customer': colors.golds[1],
    confirmed: colors.greens.primary,
    rejected: colors.reds.primary,
    'SMS failed': colors.reds.primary,
};

export const ConfirmationBadge = styled((props) => <Body2 {...props} />)`
    border-radius: 15px;
    background-color: ${(props) => confirmationStatusColors[props.status]};
    color: white;
    padding: 0 0.5rem;
    max-height: 42px;
    white-space: pre-line;
`;

export const ConfirmationText = styled((props) => <Body2 {...props} />)`
    color: ${(props) => confirmationStatusColors[props.status]};
`;

export const greyText = css`
    color: ${colors.greys.primary};
`;

export const PrimaryButton = styled((props) => <Button variant="contained" disableElevation {...props} />)`
    color: white !important;
    background-color: ${(props) => (props.disabled ? colors.greys.disabled : colors.greens.primary)} !important;
`;

export const PrimaryButtonFullW = styled((props) => <PrimaryButton {...props} />)`
    width: 100%;
`;

export const SecondaryButton = styled((props) => <Button variant="outlined" disableElevation {...props} />)`
    background-color: white;
    color: black;
`;

export const SecondaryButtonFullW = styled((props) => <SecondaryButton {...props} />)`
    width: 100%;
`;

export const DangerButton = styled((props) => <Button variant="contained" disableElevation {...props} />)`
    color: white !important;
    background-color: ${colors.reds.primary} !important;
`;

const UnstyledPlanningListItem = forwardRef((props, ref) => {
    return <Grid ref={ref} container {...props} />;
});

export const PlanningListItem = styled(UnstyledPlanningListItem)`
    flex-direction: column;
    justify-content: center;
    padding: 0.375rem 1.5rem 0.375rem 1rem;
    border-bottom: 1px solid #e2e2e2;
    background-color: ${(props) => (props.selected ? '#ececec' : 'white')};
    ${(props) => props.hover && ':hover { background-color: #ececec; }'}
    ${(props) => props.hover && 'cursor: pointer;'}
`;

export const ModalTitle = styled.span`
    padding: 1rem;
    font-size: 16px;
    font-weight: 700;
`;

export const ModalSubtitle = styled.span`
    padding: 0 1rem;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.greys.primary};
`;

export const ModalContent = styled(({ loading, children, ...props }) => (
    <DialogContent {...props}>{loading ? <Loader /> : children}</DialogContent>
))`
    padding: 1rem;
    border-bottom: 1px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
`;

export const ModalActions = styled((props) => <DialogActions {...props} />)`
    padding: 1rem;
    display: flex;
    justify-content: space-between;
`;

export const JiraTitleField = styled((props) => (
    <TextField variant="outlined" InputLabelProps={{ shrink: false }} {...props} />
))`
    & div {
        font-size: 14px;
        font-weight: 700;
        fieldset {
            border: none;
        }
        input {
            padding: 6px 0;
        }
        input::placeholder {
            opacity: 1;
        }
        input:focus {
            border: 1px solid black !important;
            border-radius: 4px;
        }
    }
`;
