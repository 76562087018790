import { gql } from '@apollo/client';

export const RESOURCE_OVERRIDE_MAPPING_FIELDS = gql`
    fragment ResourceOverrideMappingFields on resource_override_mappings {
        mapping_id
        omit
        override_end
        override_start
        resource_id
        rule_id
    }
`;
