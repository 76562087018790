import React from 'react';
import { css } from '@emotion/react';
import { Grid, Typography, LinearProgress } from '@material-ui/core';

function ProgressBar({ step, maxSteps, containerStyles }) {
    return (
        <Grid
            container
            direction="column"
            css={css`
                margin-bottom: 40px;
                ${containerStyles}
            `}
        >
            <Grid item css={css`
                margin-bottom: 2px;
                text-align: end;
            `}>
                <Typography css={css`
                    font-weight: 500;
                    font-size: 14px;
                `}>
                    {step} of {maxSteps} Steps
                </Typography>
            </Grid>
            <Grid item>
                <LinearProgress 
                    variant="determinate" 
                    value={step / maxSteps * 100} 
                    css={css`
                        height: 8px;
                        border-radius: 8px;

                        .MuiLinearProgress-root {
                            height: 8px;
                            border-radius: 8px;
                        }
                    `}    
                />
            </Grid>
        </Grid>
    );
}

export default ProgressBar;
