import React, { useState } from 'react';
import { useSubscription, useMutation, useQuery } from '@apollo/client';
import { compose } from 'recompose';
import withAdminRights from '../../Auth/withAdminRights';
import { Row, Col, Container, Input, Button } from 'reactstrap';
import { Switch } from '@material-ui/core';
import { ListGroup, Collapse } from 'react-bootstrap';
import _ from 'lodash';
import withErrorBoundary from '../../ErrorBoundary';
import api from '../../../utilities/api';
import { COMMUNICATIONS_SUBSCRIPTION } from '../../../graphql/subscriptions/communications';
import { COMMUNICATIONS_MUTATION } from '../../../graphql/mutations/communications';
import { OVERRIDE_MUTATION } from '../../../graphql/mutations/overrides';
import { QUERY_BY_ID as OVERRIDE_QUERY } from '../../../graphql/queries/overrides';

const anchorStyle = {
    textDecoration: 'underline',
    color: 'blue',
};

function AdminCommunications(props) {
    const [comms, setComms] = useState({});
    const [override, setOverride] = useState(false);

    const [updateEnablement] = useMutation(COMMUNICATIONS_MUTATION, {
        onCompleted: (data) => {
            console.log(data);
        },
        onError: (error) => {
            console.log(error);
        },
    });
    const [overrideEnablement] = useMutation(OVERRIDE_MUTATION, {
        onCompleted: (data) => {
            console.log(data);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const { loading, error } = useSubscription(COMMUNICATIONS_SUBSCRIPTION, {
        onData: ({ data }) => {
            setComms(data.data.communications);
        },
    });

    const {
        loading: overrideLoading,
        error: overrideError,
        data: overrideData,
    } = useQuery(OVERRIDE_QUERY, {
        // Including a locally generated UUID temporarily, will need to swap this out in prod
        // Also will need to create a row in the overrides table for this UUID locally to get this to work
        variables: { override_id: '48c71504-829d-4af0-80d1-68eec7dba702' },
        onCompleted: (data) => {
            setOverride(data.override.enabled);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    if (error) {
        console.log(error);
        return <div>Error! {error.message}</div>;
    }

    const toggleCollapse = (e) => {
        const children = [...e.target.parentElement.children];

        const collapsableEl = children.find((el) => el.className.includes('collapse'));

        if (collapsableEl && collapsableEl.classList) {
            collapsableEl.classList.toggle('show');
        }
    };

    const updateComms = (comm) => {
        updateEnablement({
            variables: {
                communication_id: comm.communication_id,
                enabled: !comm.enabled,
            },
            optimisticResponse: {
                update_communications: {
                    affected_rows: 1,
                    returning: [
                        {
                            communication_id: comm.communication_id,
                            enabled: !comm.enabled,
                        },
                    ],
                },
            },
        });
    };

    const overrideHandler = () => {
        overrideEnablement({
            variables: {
                override_id: '48c71504-829d-4af0-80d1-68eec7dba702',
                enabled: true,
            },
        });
    };

    const revert = () => {
        overrideEnablement({
            variables: {
                override_id: '48c71504-829d-4af0-80d1-68eec7dba702',
                enabled: false,
            },
        });
    };

    const sendCustomerTrackingUrlSMSs = async () => {
        const inputEl = document.querySelector(`#onDeliveryDayCustomerTrackingSms`);

        const phoneNumber = inputEl.value;

        if (!phoneNumber) {
            alert('A phone number must be entered into the text input');
            return;
        }

        const res = await api.post({
            routeName: 'sendCustomerTrackingSMSs',
            data: { phoneNumber },
        });
    };

    return (
        <Container className="sidenav-margin-responsive">
            <h3 style={{fontWeight: 600}}className="text-center">Communications</h3>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                <Button color="warning" style={{ marginRight: '1rem' }} onClick={overrideHandler} disabled={override}>
                    Override
                </Button>

                <Button color="success" onClick={revert} disabled={!override}>
                    Revert
                </Button>
            </div>

            <ListGroup className="mb-5">
                {Object.values(comms).map((comm, index) => (
                    <ListGroup.Item key={comm.communication_id}>
                        <Row>
                            <Col>
                                <div>
                                    <strong>{comm.display}</strong>
                                </div>

                                <a onClick={toggleCollapse} style={anchorStyle}>
                                    Description
                                </a>

                                <Collapse in={false}>
                                    <div>{comm.description}</div>
                                </Collapse>
                            </Col>

                            <Col className="d-flex justify-content-end">
                                {comm.name !== 'onDeliveryDayCustomerTrackingSms' ? (
                                    <Switch
                                        color="primary"
                                        checked={comm.enabled}
                                        onChange={() => updateComms(comm)}
                                        disabled={override}
                                    />
                                ) : (
                                    <>
                                        <div>
                                            <Input type="text" id={comm.name} />

                                            <Button
                                                color="success"
                                                onClick={sendCustomerTrackingUrlSMSs}
                                                className="mr-5"
                                                disabled={override}
                                            >
                                                Send SMS's
                                            </Button>
                                        </div>
                                        <Switch
                                            color="primary"
                                            checked={comm.enabled}
                                            onChange={() => updateComms(comm)}
                                            disabled={override}
                                        />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Container>
    );
}

export default React.memo(compose(withAdminRights, withErrorBoundary)(AdminCommunications));
