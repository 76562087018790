import { gql } from '@apollo/client';

export const ORDER_ROUTE_MAPPINGS = gql`
    fragment OrderRouteMappings on order_route_mappings {
        mapping_id
        order_id
        route_id
        type
        created_at
    }
`;
