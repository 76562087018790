import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { colors } from '@/styles';

import { Body1, Body2 } from './blocks';

export const poundFormatter = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'pound',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const ItemInfo = ({ items }) => {
    return (
        <>
            {items.map((item) => {
                return (
                    <React.Fragment key={item.item_id}>
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-top: 24px;
                                margin-bottom: 8px;
                            `}
                        >
                            <Body2>Items</Body2>
                        </Grid>
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            <Body1>{`${item.description} - ${item.sku}`}</Body1>
                        </Grid>
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    margin-right: 12px;
                                `}
                            >
                                <Body1>Quantity:</Body1>
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                `}
                            >
                                <Body1>{integerFormatter.format(item.quantity)}</Body1>
                            </Grid>
                        </Grid>
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            <Body1
                                css={css`
                                    color: ${colors.greys[0]};
                                `}
                            >{`${poundFormatter.format(item.total_weight)} ${item.length}L ${item.width}W ${
                                item.height
                            }H`}</Body1>
                        </Grid>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default ItemInfo;
