import React, { useContext, useState, useEffect } from 'react';

import { css } from '@emotion/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid } from '@material-ui/core';
import { compose } from 'recompose';

import ErrorModal from './modals/Error';
import { FORM_STATUS } from './constants';
import { Context, ContextProvider } from './store';
import { RootContainer } from './blocks';
import PitchCard from './PitchCard';
import ClientForm from './ClientForm';
import Success from './Success';

const CarrierSignUp = () => {
    const { callbacks, state, errors } = useContext(Context);
    const isDesktop = useMediaQuery('(min-width: 750px)');
    let content = null;

    if (state.status === FORM_STATUS.COMPLETE) {
        content = <Success />;
    } else {
        content = (
            <RootContainer>
                <Grid container direction="row">
                    <Grid
                        item
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin: 0 12px;
                            min-width: 456px;
                            margin-bottom: ${isDesktop ? `` : `32px`};
                        `}
                    >
                        <PitchCard />
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-shrink: 0;
                            margin: 0 12px;
                        `}
                    >
                        <ClientForm />
                    </Grid>
                </Grid>
            </RootContainer>
        );
    }

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
            `}
        >
            <Grid
                container
                css={css`
                    height: 100%;

                    padding-top: ${isDesktop ? `60px` : `30px`};
                `}
            >
                {content}
            </Grid>

            <ErrorModal
                open={state.status === FORM_STATUS.FAILED}
                callbacks={{
                    onClose: callbacks.resetError,
                }}
            />
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withContext)(CarrierSignUp);
