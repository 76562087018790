import { gql } from '@apollo/client';

export const BID_COMMENT_FIELDS = gql`
    fragment BidCommentFields on bid_comments {
        bid_comment_id
        bid_id
        client_id
        comment_text
        created_at
        last_modified_at
    }
`;

export const INSERT_BID_COMMENT = gql`
    ${BID_COMMENT_FIELDS}

    mutation InsertBidComment($bid_id: uuid!, $client_id: uuid!, $comment_text: String) {
        insert_bid_comments(objects: {
            bid_id: $bid_id
            client_id: $client_id
            comment_text: $comment_text
        }) {
            ...BidCommentFields
        }
    }
`;
