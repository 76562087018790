import { useMemo } from 'react';
import { genAttributes } from '@onward-delivery/core';

export const useSwappedAttributes = (order, isPickup = false) => {
    return useMemo(() => {
        if (order) {
            return genAttributes(order, isPickup);
        }

        return {};
    }, [order]);
};
