// Constants for generating viewer data
// Base values (ratios)
const baseItemW = 5;
const baseItemH = 5;
const baseItemD = 5;
const baseBinGap = 1.3;
const baseRackThickness = 0.15;

// Scaled dimensions
const scaleModifier = 8;
const itemW = baseItemW / scaleModifier;
const itemH = baseItemH / scaleModifier;
const itemD = baseItemD / scaleModifier;
const binGap = baseBinGap / scaleModifier;
const rackThickness = baseRackThickness / scaleModifier;


const createLabels = (data, elevation = 1) => {
    return data.map(d => {
        const midpointX = (d.coordinates[0].x + d.coordinates[1].x + d.coordinates[2].x + d.coordinates[3].x)/4;
        const midpointZ = (d.coordinates[0].z + d.coordinates[1].z + d.coordinates[2].z + d.coordinates[3].z)/4;

        return {
            id: `${d.id}-point`,
            text: d.baseRackData?.customName || (d.baseRackData?.isPool ? `Pool ${d.baseRackData.rackIdentifier}` : `Rack ${d.baseRackData.rackIdentifier}`),
            position: {
                levelIndex: 0,
                x: midpointX,
                z: midpointZ,
                elevation: elevation,
            }
        }
    });
};

const generate3DRackData = (baseRackData) => {
    const { rows, columns, rackDepth, rotation, flipped, baseX, baseZ, rackIdentifier } = baseRackData;

    // Apply modifiers for rotations and flipped shelfs
    const patterns = {
        offset: {
            x: {
                x: 1,
                z: 0,
                xFlip: 1,
                zFlip: 1,
            },
            z: {
                x: 0,
                z: 1,
                xFlip: 1,
                zFlip: 1,
            },
        },
    };

    switch (rotation) {
        case 90:
            patterns.offset.x.x = 0;
            patterns.offset.x.z = 1;
            patterns.offset.z.x = -1;
            patterns.offset.z.z = 0;

            if (flipped) {
                patterns.offset.x.xFlip = 1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = -1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        case 180:
            patterns.offset.x.x = -1;
            patterns.offset.x.z = 0;
            patterns.offset.z.x = 0;
            patterns.offset.z.z = -1;

            if (flipped) {
                patterns.offset.x.xFlip = -1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = 1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        case 270:
            patterns.offset.x.x = 0;
            patterns.offset.x.z = -1;
            patterns.offset.z.x = 1;
            patterns.offset.z.z = 0;

            if (flipped) {
                patterns.offset.x.xFlip = 1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = -1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        default:
            if (flipped) {
                patterns.offset.x.xFlip = -1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = 1;
                patterns.offset.z.zFlip = 1;
            }
            break;
    }

    // Base objects
    const baseVerticalShelf = {
        height: columns * (itemH + binGap + rackThickness) + rackThickness,
        width: rackThickness,
        depth: rackDepth * (binGap + itemD + binGap),
        type: 'vertical-shelf',
    };
    const baseHorizontalShelf = {
        height: rackThickness,
        width: rows * (itemW + binGap * 2 + rackThickness) + rackThickness,
        depth: rackDepth * (binGap + itemD + binGap),
        type: 'horizontal-shelf',
    };

    const baseItem = {
        height: itemH,
        width: itemW,
        depth: itemD,
        type: 'item',
    };

    let itemData = [];
    let rackData = [];

    // Construct rectangles to represent shelving of racks
    for (let columnCounter = 0; columnCounter < columns; columnCounter++) {
        const y = columnCounter * (rackThickness + itemH + binGap)
        const horizontalShelf = {
            ...baseHorizontalShelf,
            startingCoord: {
                x: baseX,
                z: baseZ,
            },
            y: y,
            id: `3d-${rackIdentifier}-horizontalShelf-${baseX}-${baseZ}-${y}`,
        };
        rackData.push(horizontalShelf);
    }
    for (let rowCounter = 0; rowCounter < rows; rowCounter++) {
        const xOffset = rowCounter * (rackThickness + itemW + binGap + binGap);
        const x = baseX + patterns.offset.x.x * xOffset * patterns.offset.x.xFlip;
        const z = baseZ + patterns.offset.z.x * xOffset * patterns.offset.z.xFlip
        const verticalShelf = {
            ...baseVerticalShelf,
            startingCoord: {
                x: x,
                z: z,
            },
            y: 0,
            id: `3d-${rackIdentifier}-verticalShelf-${x}-${z}-0`,
        };
        rackData.push(verticalShelf);
    }

    // Construct cubes to represent items/bins in shelves
    for (let depthCounter = 0; depthCounter < rackDepth; depthCounter++) {
        for (let rowCounter = 0; rowCounter < rows; rowCounter++) {
            for (let columnCounter = 0; columnCounter < columns; columnCounter++) {
                // Color generation for items/bins
                const s = '30%';
                const l = '50%';
                // Higher colorConsistency = less amount of colors
                const colorConsistency = 3;
                const colorOptions = 15 * colorConsistency;
                const mult = 360 / colorOptions;

                let h;
                if (depthCounter === 0) {
                    h = (20 * (columnCounter + rowCounter)) % 360;
                } else {
                    h = 360 - ((20 * (columnCounter + rowCounter)) % 360);
                }
                // const h = Math.floor(Math.random() * mult) * colorOptions;

                const xOffset = rackThickness + binGap + rowCounter * (rackThickness + itemW + binGap + binGap);
                const zOffset = binGap + depthCounter * (binGap + binGap + itemD);

                const tooltipLabel = baseRackData?.customName ? baseRackData?.customName : rackIdentifier;

                const item = {
                    ...baseItem,
                    startingCoord: {
                        x:
                            baseX +
                            patterns.offset.x.x * xOffset * patterns.offset.x.xFlip +
                            patterns.offset.x.z * zOffset * patterns.offset.x.zFlip,
                        z:
                            baseZ +
                            patterns.offset.z.x * xOffset * patterns.offset.z.xFlip +
                            patterns.offset.z.z * zOffset * patterns.offset.z.zFlip,
                    },
                    y: rackThickness + rackThickness + rackThickness + columnCounter * (itemH + binGap + rackThickness),
                    color: `hsl(${h}, ${s}, ${l})`,
                    tooltip: `Bin: ${tooltipLabel}-${rowCounter + 1}-${columnCounter + 1}-${depthCounter + 1}`,
                };
                itemData.push(item);
            }
        }
    }

    // Final horizontal rack on top
    const finalHorizontalShelfY = columns * (rackThickness + itemH + binGap)
    const horizontalShelf = {
        ...baseHorizontalShelf,
        startingCoord: {
            x: baseX,
            z: baseZ,
        },
        y: finalHorizontalShelfY,
        id: `3d-${rackIdentifier}-horizontalShelf-${baseX}-${baseZ}-${finalHorizontalShelfY}`,
    };
    rackData.push(horizontalShelf);

    const xOffset = rows * (rackThickness + itemW + binGap + binGap);
    const finalVerticalShelfX = baseX + patterns.offset.x.x * xOffset * patterns.offset.x.xFlip;
    const finalVerticalShelfZ = baseZ + patterns.offset.z.x * xOffset * patterns.offset.z.xFlip;
    const verticalShelf = {
        ...baseVerticalShelf,
        startingCoord: {
            x: finalVerticalShelfX,
            z: finalVerticalShelfZ,
        },
        y: 0,
        id: `3d-${rackIdentifier}-verticalShelf-${finalVerticalShelfX}-${finalVerticalShelfZ}-0`,
    };
    rackData.push(verticalShelf);

    // Transform data
    const transformData = (data) => {
        const offsetX = data.width;
        const offsetZ = data.depth;

        return {
            ...data,
            coordinates: [
                {
                    levelIndex: 0,
                    x: data.startingCoord.x,
                    z: data.startingCoord.z,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + patterns.offset.x.x * offsetX * patterns.offset.x.xFlip,
                    z: data.startingCoord.z + patterns.offset.z.x * offsetX * patterns.offset.z.xFlip,
                },
                {
                    levelIndex: 0,
                    x:
                        data.startingCoord.x +
                        patterns.offset.x.x * offsetX * patterns.offset.x.xFlip +
                        patterns.offset.x.z * offsetZ * patterns.offset.x.zFlip,
                    z:
                        data.startingCoord.z +
                        patterns.offset.z.x * offsetX * patterns.offset.z.xFlip +
                        patterns.offset.z.z * offsetZ * patterns.offset.z.zFlip,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + patterns.offset.x.z * offsetZ * patterns.offset.x.zFlip,
                    z: data.startingCoord.z + patterns.offset.z.z * offsetZ * patterns.offset.z.zFlip,
                },
            ],
        };
    };

    const transformedRackData = rackData.map(transformData);
    const transformedItemData = itemData.map(transformData);

    const labelObject = {
        ...transformedRackData?.[transformedRackData.length-2],
        baseRackData,
    }
    const labelPoints = createLabels([labelObject]);

    return [transformedRackData, transformedItemData, labelPoints];
};

const generate3DPoolData = (baseRackData) => {
    const { rows, columns, rotation, flipped, baseX, baseZ, rackIdentifier, poolWidth, poolDepth } = baseRackData;

    // Apply modifiers for rotations and flipped shelfs
    const patterns = {
        offset: {
            x: {
                x: 1,
                z: 0,
                xFlip: 1,
                zFlip: 1,
            },
            z: {
                x: 0,
                z: 1,
                xFlip: 1,
                zFlip: 1,
            },
        },
    };

    switch (rotation) {
        case 90:
            patterns.offset.x.x = 0;
            patterns.offset.x.z = 1;
            patterns.offset.z.x = -1;
            patterns.offset.z.z = 0;

            if (flipped) {
                patterns.offset.x.xFlip = 1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = -1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        case 180:
            patterns.offset.x.x = -1;
            patterns.offset.x.z = 0;
            patterns.offset.z.x = 0;
            patterns.offset.z.z = -1;

            if (flipped) {
                patterns.offset.x.xFlip = -1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = 1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        case 270:
            patterns.offset.x.x = 0;
            patterns.offset.x.z = -1;
            patterns.offset.z.x = 1;
            patterns.offset.z.z = 0;

            if (flipped) {
                patterns.offset.x.xFlip = 1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = -1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        default:
            if (flipped) {
                patterns.offset.x.xFlip = -1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = 1;
                patterns.offset.z.zFlip = 1;
            }
            break;
    }

    // Base objects
    const baseHorizontalShelf = {
        height: rackThickness,
        width: poolWidth * (itemW + binGap * 2 + rackThickness) + rackThickness,
        depth: poolDepth * (binGap + itemD + binGap),
        type: 'pool-floor',
    };

    let rackData = [];

    // Rectangle representing floor pool
    const horizontalShelf = {
        ...baseHorizontalShelf,
        startingCoord: {
            x: baseX,
            z: baseZ,
        },
        y: 0,
        id: `3d-${rackIdentifier}-pool`,
    };
    rackData.push(horizontalShelf);

    // Transform data
    const transformData = (data) => {
        const offsetX = data.width;
        const offsetZ = data.depth;

        return {
            ...data,
            coordinates: [
                {
                    levelIndex: 0,
                    x: data.startingCoord.x,
                    z: data.startingCoord.z,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + patterns.offset.x.x * offsetX * patterns.offset.x.xFlip,
                    z: data.startingCoord.z + patterns.offset.z.x * offsetX * patterns.offset.z.xFlip,
                },
                {
                    levelIndex: 0,
                    x:
                        data.startingCoord.x +
                        patterns.offset.x.x * offsetX * patterns.offset.x.xFlip +
                        patterns.offset.x.z * offsetZ * patterns.offset.x.zFlip,
                    z:
                        data.startingCoord.z +
                        patterns.offset.z.x * offsetX * patterns.offset.z.xFlip +
                        patterns.offset.z.z * offsetZ * patterns.offset.z.zFlip,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + patterns.offset.x.z * offsetZ * patterns.offset.x.zFlip,
                    z: data.startingCoord.z + patterns.offset.z.z * offsetZ * patterns.offset.z.zFlip,
                },
            ],
        };
    };

    const transformedRackData = rackData.map(transformData);
    const labelObject = {
        ...transformedRackData?.[0],
        baseRackData,
    }
    const labelPoints = createLabels([labelObject], 0);

    return [transformedRackData, [], labelPoints];
};

const generate3DViewerData = (baseRackData) => {
    if (baseRackData?.isPool) {
        return generate3DPoolData(baseRackData);
    } else {
        return generate3DRackData(baseRackData);
    }
};


const generate2DRackData = (baseRackData) => {
    const { rows, columns, rackDepth, rotation, flipped, baseX, baseZ, rackIdentifier } = baseRackData;

    // Apply modifiers for rotations and flipped shelfs
    const patterns = {
        offset: {
            x: {
                x: 1,
                z: 0,
                xFlip: 1,
                zFlip: 1,
            },
            z: {
                x: 0,
                z: 1,
                xFlip: 1,
                zFlip: 1,
            },
        },
    };

    switch (rotation) {
        case 90:
            patterns.offset.x.x = 0;
            patterns.offset.x.z = 1;
            patterns.offset.z.x = -1;
            patterns.offset.z.z = 0;

            if (flipped) {
                patterns.offset.x.xFlip = 1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = -1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        case 180:
            patterns.offset.x.x = -1;
            patterns.offset.x.z = 0;
            patterns.offset.z.x = 0;
            patterns.offset.z.z = -1;

            if (flipped) {
                patterns.offset.x.xFlip = -1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = 1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        case 270:
            patterns.offset.x.x = 0;
            patterns.offset.x.z = -1;
            patterns.offset.z.x = 1;
            patterns.offset.z.z = 0;

            if (flipped) {
                patterns.offset.x.xFlip = 1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = -1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        default:
            if (flipped) {
                patterns.offset.x.xFlip = -1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = 1;
                patterns.offset.z.zFlip = 1;
            }
            break;
    }

    const baseHorizontalShelf = {
        width: rows * (itemW + binGap * 2 + rackThickness) + rackThickness,
        depth: rackDepth * (binGap + itemD + binGap),
        type: 'horizontal-shelf',
    };

    const rackData = [];

    // Construct rectangles to represent shelving of racks
    rackData.push({
        id: `floorplan-${rackIdentifier}`,
        ...baseHorizontalShelf,
        startingCoord: {
            x: baseX,
            z: baseZ,
        },
        baseRackData: baseRackData,
    });

    // extra TODO - create little 'square' boxes to represent the bins for user's viewability
    //     // Construct cubes to represent items/bins in shelves
    //     for (let depthCounter = 0; depthCounter < rackDepth; depthCounter++) {
    //         for (let rowCounter = 0; rowCounter < rows; rowCounter++) {
    //             for (let columnCounter = 0; columnCounter < columns; columnCounter++) {
    //                 // Color generation for items/bins
    //                 const s = '30%';
    //                 const l = '50%';
    //                 // Higher colorConsistency = less amount of colors
    //                 const colorConsistency = 3;
    //                 const colorOptions = 15 * colorConsistency;
    //                 const mult = 360 / colorOptions;

    //                 let h;
    //                 if (depthCounter === 0) {
    //                     h = 20 * (columnCounter + rowCounter) % 360;
    //                 } else {
    //                     h = 360 - (20 * (columnCounter + rowCounter) % 360);
    //                 }
    //                 // const h = Math.floor(Math.random() * mult) * colorOptions;

    //                 const xOffset = rackThickness + binGap + rowCounter * (rackThickness + itemW + binGap + binGap)
    //                 const zOffset = binGap + depthCounter * (binGap + binGap + itemD)

    //                 const item = {
    //                     ...baseItem,
    //                     startingCoord: {
    //                         x: baseX + patterns.offset.x.x * xOffset * patterns.offset.x.xFlip + patterns.offset.x.z * zOffset * patterns.offset.x.zFlip,
    //                         z: baseZ + patterns.offset.z.x * xOffset * patterns.offset.z.xFlip + patterns.offset.z.z * zOffset * patterns.offset.z.zFlip,
    //                     },
    //                     y: rackThickness + rackThickness + rackThickness + columnCounter * (itemH + binGap + rackThickness),
    //                     color: `hsl(${h}, ${s}, ${l})`,
    //                     tooltip: `Bin: ${rackIdentifier}-${rowCounter + 1}-${columnCounter + 1}-${depthCounter + 1}`,
    //                 };
    //                 itemData.push(item);
    //             }
    //         }
    //     }

    // Transform data
    const transformData = (data) => {
        const offsetX = data.width;
        const offsetZ = data.depth;

        const frontCornerSize = Math.min(0.85, 0.3 * offsetZ);

        return {
            ...data,
            coordinates: [
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + frontCornerSize * patterns.offset.x.x * patterns.offset.x.xFlip,
                    z: data.startingCoord.z + frontCornerSize * patterns.offset.z.x * patterns.offset.z.xFlip,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + patterns.offset.x.x * offsetX * patterns.offset.x.xFlip,
                    z: data.startingCoord.z + patterns.offset.z.x * offsetX * patterns.offset.z.xFlip,
                },
                {
                    levelIndex: 0,
                    x:
                        data.startingCoord.x +
                        patterns.offset.x.x * offsetX * patterns.offset.x.xFlip +
                        patterns.offset.x.z * offsetZ * patterns.offset.x.zFlip,
                    z:
                        data.startingCoord.z +
                        patterns.offset.z.x * offsetX * patterns.offset.z.xFlip +
                        patterns.offset.z.z * offsetZ * patterns.offset.z.zFlip,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + patterns.offset.x.z * offsetZ * patterns.offset.x.zFlip,
                    z: data.startingCoord.z + patterns.offset.z.z * offsetZ * patterns.offset.z.zFlip,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + frontCornerSize * patterns.offset.x.z * patterns.offset.x.zFlip,
                    z: data.startingCoord.z + frontCornerSize * patterns.offset.z.z * patterns.offset.z.zFlip,
                },
            ],
        };
    };

    const transformedRackData = rackData.map(transformData);
    // const transformedItemData = itemData.map(transformData);

    const labelPoints = createLabels(transformedRackData);

    return [transformedRackData, labelPoints];
};

const generate2DPoolData = (baseRackData) => {
    const { rows, columns, rackDepth, rotation, flipped, baseX, baseZ, rackIdentifier, poolWidth, poolDepth } = baseRackData;

    // Apply modifiers for rotations and flipped shelfs
    const patterns = {
        offset: {
            x: {
                x: 1,
                z: 0,
                xFlip: 1,
                zFlip: 1,
            },
            z: {
                x: 0,
                z: 1,
                xFlip: 1,
                zFlip: 1,
            },
        },
    };

    switch (rotation) {
        case 90:
            patterns.offset.x.x = 0;
            patterns.offset.x.z = 1;
            patterns.offset.z.x = -1;
            patterns.offset.z.z = 0;

            if (flipped) {
                patterns.offset.x.xFlip = 1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = -1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        case 180:
            patterns.offset.x.x = -1;
            patterns.offset.x.z = 0;
            patterns.offset.z.x = 0;
            patterns.offset.z.z = -1;

            if (flipped) {
                patterns.offset.x.xFlip = -1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = 1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        case 270:
            patterns.offset.x.x = 0;
            patterns.offset.x.z = -1;
            patterns.offset.z.x = 1;
            patterns.offset.z.z = 0;

            if (flipped) {
                patterns.offset.x.xFlip = 1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = -1;
                patterns.offset.z.zFlip = 1;
            }
            break;
        default:
            if (flipped) {
                patterns.offset.x.xFlip = -1;
                patterns.offset.x.zFlip = 1;
                patterns.offset.z.xFlip = 1;
                patterns.offset.z.zFlip = 1;
            }
            break;
    }

    const baseHorizontalShelf = {
        width: poolWidth * (itemW + binGap * 2 + rackThickness) + rackThickness,
        depth: poolDepth * (binGap + itemD + binGap),
        type: 'pool',
    };

    const rackData = [];

    // Construct rectangles to represent shelving of racks
    rackData.push({
        id: `floorplan-pool-${rackIdentifier}`,
        ...baseHorizontalShelf,
        startingCoord: {
            x: baseX,
            z: baseZ,
        },
        baseRackData: baseRackData,
    });

    // Transform data
    const transformData = (data) => {
        const offsetX = data.width;
        const offsetZ = data.depth;

        const frontCornerSize = Math.min(0.85, 0.3 * offsetZ);

        return {
            ...data,
            coordinates: [
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + frontCornerSize * patterns.offset.x.x * patterns.offset.x.xFlip,
                    z: data.startingCoord.z + frontCornerSize * patterns.offset.z.x * patterns.offset.z.xFlip,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + patterns.offset.x.x * offsetX * patterns.offset.x.xFlip,
                    z: data.startingCoord.z + patterns.offset.z.x * offsetX * patterns.offset.z.xFlip,
                },
                {
                    levelIndex: 0,
                    x:
                        data.startingCoord.x +
                        patterns.offset.x.x * offsetX * patterns.offset.x.xFlip +
                        patterns.offset.x.z * offsetZ * patterns.offset.x.zFlip,
                    z:
                        data.startingCoord.z +
                        patterns.offset.z.x * offsetX * patterns.offset.z.xFlip +
                        patterns.offset.z.z * offsetZ * patterns.offset.z.zFlip,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + patterns.offset.x.z * offsetZ * patterns.offset.x.zFlip,
                    z: data.startingCoord.z + patterns.offset.z.z * offsetZ * patterns.offset.z.zFlip,
                },
                {
                    levelIndex: 0,
                    x: data.startingCoord.x + frontCornerSize * patterns.offset.x.z * patterns.offset.x.zFlip,
                    z: data.startingCoord.z + frontCornerSize * patterns.offset.z.z * patterns.offset.z.zFlip,
                },
            ],
        };
    };
    const transformedRackData = rackData.map(transformData);
    const labelPoints = createLabels(transformedRackData);
    return [transformedRackData, labelPoints];
}

const generate2DViewerData = (baseRackData) => {
    if (baseRackData?.isPool) {
        return generate2DPoolData(baseRackData);
    } else {
        return generate2DRackData(baseRackData);
    }
};

const stringToRackNumber = (text) => {
    let rackLetter = text.split('').reverse();
    const identifierCharCode = rackLetter[0].charCodeAt(0);
    const sectionCharCode = rackLetter[1]?.charCodeAt(0);

    const firstNum = identifierCharCode - 65;
    const secondNum = sectionCharCode - 64 || 0;

    return firstNum + secondNum * 26;
};

let rackNumberToString = (rackNum) => {
    let rackLetter = '';
    if (rackNum > 25) {
        let charTracker = 0;
        let rackNumTracker = rackNum;
        while (rackNumTracker > 25) {
            rackNumTracker -= 25;
            charTracker++;
        }
        rackLetter += String.fromCharCode(64 + charTracker);
    }
    rackLetter += String.fromCharCode((rackNum % 26) + 65);
    return rackLetter;
};

module.exports = {
    rackNumberToString,
    stringToRackNumber,
    generate3DViewerData,
    generate2DViewerData,
};
