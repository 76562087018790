import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { Grid } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { ModalContent, ModalTitle, PrimaryButton, StickyModalActions, SecondaryButton } from '@/styles/blocks';

const Title1 = styled.span`
    color: ${colors.greys.secondary};
    font-size: 20px;
    font-weight: 900;
`;

const Row = styled((props) => <Grid item {...props} />)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TrucksDaysAvailableModal = ({ open, onClose, modifyTruck, days_available = {}, }) => {
    const [days, setDays] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
    });

    useEffect(() => {
        if (Object.keys(days_available || {}).length) {
            const daysClone = {...days};

            Object.keys(daysClone).forEach(day => {
                if (days_available[day]) {
                    daysClone[day] = days_available[day];
                }
            });

            setDays(daysClone);
        }
    }, [days_available]);

    const handleSaveClick = () => {
        modifyTruck({ days_available: days })
        onClose();
    }

    return (
        <NavResponsiveModal open={open} onClose={onClose} css={css`
            & .MuiDialog-paper{
                width: auto;
                max-width: none;
            }
        `}>
            <ModalTitle onClose={onClose}>
                <Title1>Days Available</Title1>
            </ModalTitle>
            <ModalContent
                css={css`
                    padding-left: 20px;
                    padding-right: 20px;
                `}
            >
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    css={css`
                        justify-content: space-between;
                    `}
                >
                    {Object.entries(days).map(([day, checked]) => (
                        <ToggleButton
                            value="check"
                            selected={checked}
                            css={css`
                                background-color: ${checked ? `${colors.greens.primary}` : `${colors.greys.border}`} !important;
                                color: ${checked ? 'white' : `${colors.greys.secondary}`} !important;
                                border: none;
                                border-radius: 45px;
                                width: 45px;
                                height: 45px;
                                font-weight: 600;
                            `}
                            onChange={() => {
                                setDays({
                                    ...days,
                                    [day]: !checked,
                                })
                            }}
                        >
                            {day.slice(0, 1).toUpperCase()}
                        </ToggleButton>)
                        
                    )}
                </Grid>
            </ModalContent>
            <StickyModalActions css={css`
                margin-left: 8px;
                margin-right: 8px;
            `}>
                <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleSaveClick}>
                    Save
                </PrimaryButton>
            </StickyModalActions>
        </NavResponsiveModal>
    );
};

export default TrucksDaysAvailableModal;
