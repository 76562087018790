import { calculateTimeframes } from '@/utilities/calculateTimeframes';
import { hourString, minuteString, mileString } from '@/constants/formats';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';

// Match google directions to stops by index
const stopMatch = (leg, legIndex) => (stop, stopIndex) => {
    return legIndex === stopIndex;
};

export const calculateGoogleTimeframes = (
    stopSequence,
    legs,
    routeStartTime,
    ordersByKey,
    useTrafficEstimates,
    clientUser,
    default_end_location,
    preferences_start_estimate,
    preferences_warehouse_return_estimate,
    preferences_end_estimate
) => {
    let overnightPickupStops = [];
    let alteredStops = stopSequence.filter((s, idx) => {
        if (s.overnight && FTLStopHelpers.isWarehousePickup(s)) {
            overnightPickupStops.push({ idx, stop: s });
            return false;
        }
        return true;
    });

    const containsStart = alteredStops.some((stop) => stop.start);

    const defaultFullAddress = default_end_location
        ? `${default_end_location.business_address}, ${default_end_location.business_city}, ${default_end_location.business_state}, ${default_end_location.business_zip}`
        : null;

    legs.forEach((leg, idx) => {
        if (containsStart && idx === 0 && !(preferences_start_estimate && !overnightPickupStops.length)) {
            leg.omit = true;
        }

        // Find the matching original stop
        const stop = alteredStops.find(stopMatch(leg, idx + 1));
        if (!preferences_warehouse_return_estimate && FTLStopHelpers.isFinalDropoff(stop)) {
            leg.omit = true;
        }

        if (!preferences_end_estimate && stop.end) {
            leg.omit = true;
        }
    });

    // Get Driving Distance and Time from directions leg
    const getDriveDistance = (leg) => {
        return leg.distance.value;
    };

    const getDriveTime = (leg) => {
        const inTraffic = leg?.duration_in_traffic?.value;
        const defaultDuration = leg?.duration?.value;
        return useTrafficEstimates && inTraffic ? inTraffic : defaultDuration;
    };

    // Create artificial first leg to match directions to stop
    const firstLeg = {
        end_address: legs[0].start_address,
        duration: {
            value: 0,
        },
        duration_in_traffic: {
            value: 0,
        },
        distance: {
            value: 0,
        },
    };

    const { stopsWithTimeframes, totalDrivingDistance, totalDrivingTime } = calculateTimeframes(
        alteredStops,
        [firstLeg, ...legs],
        routeStartTime,
        ordersByKey,
        stopMatch,
        getDriveDistance,
        getDriveTime,
        clientUser,
        false
    );

    // meters to miles, seconds to hours/minutes
    const estimatedDrivingDistance = mileString.format(totalDrivingDistance * 0.000621371);
    const estimatedDrivingTime =
        totalDrivingTime < 3600
            ? minuteString.format(totalDrivingTime / 60)
            : hourString.format(totalDrivingTime / 60 / 60);

    overnightPickupStops.forEach((i) => {
        stopsWithTimeframes.splice(i.idx, 0, i.stop);
    });

    return {
        stopsWithTimeframes,
        estimatedDrivingDistance,
        estimatedDrivingTime,
    };
};
