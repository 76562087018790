import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { IconButton, TextField, MenuItem, Grid } from '@material-ui/core';
import { DeleteForeverOutlined, SaveOutlined } from '@material-ui/icons';
import { useClientUser } from '@/hooks';
import { useMutation } from '@apollo/client';
import {
    CONNECT_SHIPPING_PARTNER,
    CREATE_SHIPPING_PARTNER,
    DELETE_SHIPPING_PARTNER,
    UPDATE_SHIPPING_PARTNER,
    UPSERT_SHIPPING_PARTNER,
} from './mutations';
import { captureException } from '@sentry/react';
import { Body1, TabCard } from './blocks';
import Snackbar from '../Snackbar';
import { Body, PrimaryButton } from '@/styles/blocks';
import { QUERY_BY_ID } from '@/graphql/queries/users';
import { getTariffName } from './Tariffs/utils';
import { OnwardToggle } from '../ShipmentForm/blocks';
import { H3 } from '../DispatchPlan/blocks';
import { colors } from '@/styles';
import AddShipperModal from './ShippingPartners/AddShipperModal';
import { post } from '@/utilities/onwardClient';
import { INVITE_PARTNER } from '@/constants/apiRoutes';

const ShippingPartner = ({ shipper, tariffs, overrides, callbacks, disabled }) => {
    const [overrideUpdates, updateOverride] = useState({});
    const [alias, setAlias] = useState(null);

    const tariffsById = useMemo(() => {
        return Object.fromEntries((tariffs || []).map((tariff) => [tariff.tariff_id, tariff]));
    }, [tariffs]);

    const override = useMemo(() => {
        return (
            overrides.find(
                (o) => o.oms && o.partner_client_id === shipper.shipper_id && o.retailer_id == shipper.retailer_id
            ) || {}
        );
    }, [overrides, shipper]);

    const updatedOverride = useMemo(() => {
        return {
            tariff_id: overrideUpdates.tariff_id || override.tariff_id,
            add_fuel_surcharge: Boolean(overrideUpdates.add_fuel_surcharge ?? override.add_fuel_surcharge),
        };
    }, [override, overrideUpdates]);

    const shipperName = useMemo(() => {
        return alias || shipper.shipper_alias || shipper.shipper.business_name;
    }, [alias, shipper]);

    const tariff = useMemo(() => {
        return tariffsById[updatedOverride.tariff_id];
    }, [tariffsById, updatedOverride]);

    const modified = Object.keys(overrideUpdates).length > 0 || !!alias;

    const statusColors = {
        PENDING: colors.golds.primary,
        APPROVED: colors.greens.primary,
        DENIED: colors.reds.primary,
    };

    const statusLabels = {
        PENDING: 'Invite Pending',
        APPROVED: 'Connected',
        DENIED: 'Not Connected',
    };

    return (
        <Grid
            container
            direction="column"
            css={css`
                padding: 1rem 0rem;
                border-bottom: 1px solid ${colors.greys.border};
            `}
        >
            <Grid
                item
                css={css`
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 4px;
                `}
            >
                <Body
                    css={css`
                        font-weight: 700;
                        font-size: 14px;
                        color: ${statusColors[shipper.status] || 'black'};
                        width: 150px;
                    `}
                >
                    {statusLabels[shipper.status] || 'Status Unknown'}
                </Body>
                <Body
                    css={css`
                        font-weight: 500;
                        font-size: 14px;
                        color: #8d8d8d;
                    `}
                >
                    Network ID: {shipper.shipper.client_id}
                    {shipper.retailer ? ` / Retailer: ${shipper.retailer.name}` : ''}
                </Body>
            </Grid>
            <Grid
                item
                css={css`
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 74px;
                `}
            >
                <Grid
                    item
                    css={css`
                        width: 400px;
                        margin-right: 12px;
                    `}
                >
                    <TextField
                        variant="outlined"
                        label="Shipper Name"
                        value={shipperName}
                        onChange={(e) => setAlias(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        width: 300px;
                        margin: 0 12px;
                    `}
                >
                    <TextField
                        select
                        variant="outlined"
                        label="Tariff"
                        value={updatedOverride?.tariff_id || ''}
                        onChange={(e) => updateOverride((prev) => ({ ...prev, tariff_id: e.target.value }))}
                        fullWidth
                    >
                        {tariffs.map((tariff, idx) => (
                            <MenuItem key={tariff.tariff_id} value={tariff.tariff_id}>
                                {getTariffName(tariff, idx)}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid
                    item
                    css={css`
                        margin: 0 36px;
                        align-items: center;
                        flex-wrap: nowrap;
                        text-align: center;
                    `}
                >
                    <OnwardToggle
                        value={updatedOverride.add_fuel_surcharge}
                        onChange={(e) => updateOverride((prev) => ({ ...prev, add_fuel_surcharge: e.target.checked }))}
                    />
                    <Body1
                        css={css`
                            text-align: center;
                            margin-left: 12px;
                        `}
                    >
                        Add FSC
                    </Body1>
                </Grid>
                <Grid
                    item
                    css={css`
                        margin: 0 12px;
                    `}
                >
                    <IconButton
                        disabled={disabled || !modified}
                        onClick={() =>
                            callbacks
                                .onSave(
                                    {
                                        partner_client_id: shipper.shipper.client_id,
                                        retailer_id: shipper.retailer_id || null,
                                        tariff_id: tariff?.tariff_id || null,
                                        algo_type: tariff?.algo_type || 'DEFAULT',
                                        add_fuel_surcharge: !!updatedOverride.add_fuel_surcharge,
                                    },
                                    { partnership_id: shipper.partnership_id, shipper_alias: shipperName }
                                )
                                .then(() => {
                                    updateOverride({});
                                    setAlias(null);
                                })
                        }
                    >
                        <SaveOutlined fontSize="large" color={disabled || !modified ? 'disabled' : 'primary'} />
                    </IconButton>
                </Grid>
                <Grid
                    item
                    css={css`
                        margin: 0 12px;
                    `}
                >
                    <IconButton disabled={disabled} onClick={() => callbacks.onDelete(shipper)}>
                        <DeleteForeverOutlined fontSize="large" color={disabled ? 'disabled' : 'error'} />
                    </IconButton>
                </Grid>
                {shipper.status === 'PENDING' ? (
                    <Grid
                        item
                        css={css`
                            margin: 0 12px;
                        `}
                    >
                        <PrimaryButton onClick={() => callbacks.onInvite(shipper)}>Resend Invite</PrimaryButton>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};

const ShippingPartners = ({ tariffs, overrides, callbacks }) => {
    const [notification, setNotification] = useState({});
    const [addingShipper, addShipper] = useState(false);
    const { user_id, shipping_partners, test_acc, businessName } = useClientUser();

    const [createShipper, { loading: createLoading }] = useMutation(CREATE_SHIPPING_PARTNER, {
        refetchQueries: [QUERY_BY_ID],
        awaitRefetchQueries: true,
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error creating new shipper',
            });
        },
    });

    const [connectShipper, { loading: connectLoading }] = useMutation(CONNECT_SHIPPING_PARTNER, {
        refetchQueries: [QUERY_BY_ID],
        awaitRefetchQueries: true,
        onCompleted: ({ insert_partnerships_one }) => {
            setNotification({
                severity: 'success',
                message: `Successfully connected to ${insert_partnerships_one.shipper.business_name}!`,
            });
        },
        onError: (e) => {
            if (e?.message?.includes('partnerships_unique_idx')) {
                setNotification({
                    severity: 'warning',
                    message: 'You have already connected to this shipper',
                });
                return;
            }
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error connecting to shipper',
            });
        },
    });

    const [updateShipper, { loading: updateLoading }] = useMutation(UPDATE_SHIPPING_PARTNER);

    const [deleteShipper, { loading: deleteLoading }] = useMutation(DELETE_SHIPPING_PARTNER, {
        onCompleted: () => {
            setNotification({
                severity: 'success',
                message: `Shipper removed`,
            });
        },
        update: (cache, { data: { delete_partnerships_by_pk } }) => {
            cache.evict(cache.identify(delete_partnerships_by_pk));
        },
    });

    const loading = createLoading || connectLoading || deleteLoading || updateLoading;

    return (
        <TabCard>
            <Snackbar open={notification.message} handleClose={() => setNotification({})} {...notification} />
            <AddShipperModal
                open={addingShipper}
                callbacks={{
                    onClose: () => addShipper(false),
                    onCreate: (shipper) => {
                        return createShipper({
                            variables: {
                                user: {
                                    username: shipper.business_name,
                                    email: shipper.business_email,
                                    roles: {
                                        SHIPPER: 'SHIPPER',
                                    },
                                    client: {
                                        data: {
                                            ...shipper,
                                            test_acc,
                                            partnerships_enabled: true,
                                            shipper_partnerships: {
                                                data: [
                                                    {
                                                        carrier_id: user_id,
                                                        retailer_id: null,
                                                        status: 'APPROVED',
                                                        shipper_alias: shipper.business_name,
                                                    },
                                                ],
                                            },
                                        },
                                    },
                                },
                            },
                        }).then((response) => {
                            const name = response?.data?.insert_users_one?.client?.business_name;
                            return setNotification({
                                severity: name ? 'success' : 'error',
                                message: name
                                    ? `Successfully connected to ${name}!`
                                    : 'Failed to create shipper. Please try again.',
                            });
                        });
                    },
                    onInvite: (shipper) => {
                        return createShipper({
                            variables: {
                                user: {
                                    username: shipper.business_name,
                                    email: shipper.business_email,
                                    roles: {
                                        SHIPPER: 'SHIPPER',
                                    },
                                    client: {
                                        data: {
                                            ...shipper,
                                            test_acc,
                                            partnerships_enabled: true,
                                            shipper_partnerships: {
                                                data: [
                                                    {
                                                        carrier_id: user_id,
                                                        retailer_id: null,
                                                        status: 'PENDING',
                                                        shipper_alias: shipper.business_name,
                                                    },
                                                ],
                                            },
                                        },
                                    },
                                },
                            },
                        }).then((response) => {
                            const client = response?.data?.insert_users_one?.client;
                            if (!client) {
                                setNotification({
                                    severity: 'warning',
                                    message: 'Error creating partnership, could not send invite.',
                                });
                                return;
                            }

                            return post(INVITE_PARTNER, {
                                inviter_name: businessName,
                                inviter_type: 'carrier',
                                invitee_id: client.client_id,
                                invitee_name: client.business_name,
                                invitee_email: client.email,
                                invitee_type: 'shipper',
                            })
                                .then(() => {
                                    return setNotification({
                                        severity: 'success',
                                        message: 'Shipping partner invited via email!',
                                    });
                                })
                                .catch((err) => {
                                    console.error(err);
                                    return setNotification({
                                        severity: 'error',
                                        message: 'Error inviting partner. Please try again',
                                    });
                                });
                        });
                    },
                    onConnect: (shipper_id) => {
                        return connectShipper({
                            variables: {
                                partnership: {
                                    shipper_id,
                                    retailer_id: null,
                                    carrier_id: user_id,
                                    status: 'APPROVED',
                                },
                            },
                        });
                    },
                }}
            />
            <div
                css={css`
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    padding: 1rem 2rem;
                `}
            >
                <div>
                    <PrimaryButton
                        css={css`
                            margin: 1rem 0rem;
                        `}
                        onClick={() => addShipper(true)}
                    >
                        Add Shipper
                    </PrimaryButton>
                    {shipping_partners.map((shipper) => (
                        <ShippingPartner
                            id={shipper.partnership_id}
                            shipper={shipper}
                            tariffs={tariffs}
                            overrides={overrides}
                            callbacks={{
                                onSave: ({ ...override }, { partnership_id, ...partnership_update }) => {
                                    return Promise.all([
                                        callbacks.upsertPo({
                                            variables: {
                                                override: {
                                                    client_id: user_id,
                                                    oms: true,
                                                    ...override,
                                                },
                                            },
                                        }),
                                        updateShipper({
                                            variables: {
                                                partnership_id,
                                                update: partnership_update,
                                            },
                                        }),
                                    ]);
                                },
                                onDelete: (partnership) =>
                                    deleteShipper({
                                        variables: { partnership_id: partnership.partnership_id },
                                    }),
                                onInvite: (partnership) =>
                                    post(INVITE_PARTNER, {
                                        inviter_name: businessName,
                                        inviter_type: 'carrier',
                                        invitee_id: partnership.shipper_id,
                                        invitee_name: partnership.shipper?.business_name,
                                        invitee_email: partnership.shipper?.email,
                                        invitee_type: 'shipper',
                                    })
                                        .then(() => {
                                            return setNotification({
                                                severity: 'success',
                                                message: 'Shipping partner invited via email!',
                                            });
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            return setNotification({
                                                severity: 'error',
                                                message: 'Error inviting partner. Please try again',
                                            });
                                        }),
                            }}
                            disabled={loading}
                        />
                    ))}
                </div>
            </div>
        </TabCard>
    );
};

export default ShippingPartners;
