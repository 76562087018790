import { colors } from '@/styles';
import { css } from '@emotion/react';
import { useMemo } from 'react';

export const OrderStatus = ({ status }) => {
    const [statusLabel, color] = useMemo(() => {
        switch (status) {
            case 'open':
            case 'pending':
            case 'claimed': {
                return ['Pre Transit', colors.greys[2]];
            }
            case 'active':
            case 'inProgress': {
                return ['In Transit', colors.blues[0]];
            }
            case 'complete': {
                return ['Complete', colors.greens[0]];
            }
            default: {
                return ['Status Unknown', colors.greys[2]];
            }
        }
    }, [status]);

    return (
        <span
            css={css`
                width: 100%;
                text-align: center;
                color: ${color};
                border: 3px solid ${color};
                border-radius: 2px;
                padding: 8px;
                font-size: 16px;
                font-weight: 700;
            `}
        >
            {statusLabel}
        </span>
    );
};
