import { hourMinuteFormatter } from '@/constants/formats';
import { useTotalCubes, useTotalWeight } from '@/hooks';
import { delWindowToFormattedDateRange, delWindowToFormattedDateSingle } from '@/utilities/delWindowToFormattedDate';
import React, { useMemo } from 'react';
import { CardText } from 'reactstrap';
import { CardHeaderLine, CardHeaderText, IndentedCardLine, ListCard } from './blocks';

const OnwardStopCard = ({ route, stop }) => {
    const type = useMemo(() => {
        if (stop.start) {
            return 'Start';
        } else if (stop.end) {
            return 'End';
        } else if (stop.type === 'PICKUP') {
            if (stop.returns?.length) {
                return 'Return Pickup';
            }
            return 'Pickup';
        } else if (stop.type === 'DROPOFF') {
            if (stop.returns?.length) {
                return 'Final Return';
            }
            if (stop.excahnges?.length) {
                return 'Exchange';
            }
            return 'Delivery';
        } else {
            return 'N/A';
        }
    }, [stop]);

    const stopOrders = useMemo(() => {
        const ordersByKey = Object.fromEntries(route.orders.map((mapping) => [mapping.order_id, mapping.order]));
        return (stop.orders || []).map((order_id) => ordersByKey[order_id]);
    }, [stop, route]);

    const cubes = useTotalCubes(stopOrders);
    const weight = useTotalWeight(stopOrders);

    return (
        <ListCard>
            <CardHeaderLine>
                <CardHeaderText>
                    {stop.ordering + 1}. {type}
                </CardHeaderText>
            </CardHeaderLine>
            <IndentedCardLine>
                <CardText>
                    {Math.abs(cubes).toFixed(0)} cu ft. / {weight?.toFixed(0) || '-'} lbs
                </CardText>
            </IndentedCardLine>
            {stop.del_window_start && stop.del_window_end && (
                <IndentedCardLine>
                    <CardText>
                        Timeframe: {delWindowToFormattedDateRange(stop.del_window_start, stop.del_window_end)}
                    </CardText>
                </IndentedCardLine>
            )}
            {stop.arrival_time && (
                <IndentedCardLine>
                    <CardText>Arrival Time: {delWindowToFormattedDateSingle(stop.arrival_time)}</CardText>
                </IndentedCardLine>
            )}
            {stop.service_time && (
                <IndentedCardLine>
                    <CardText>Service Time: {hourMinuteFormatter.format(stop.service_time / 60000)}</CardText>
                </IndentedCardLine>
            )}
        </ListCard>
    );
};

export default OnwardStopCard;
