import React from 'react';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { colors } from '@/styles';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { css } from '@emotion/react';
import { genAttributes } from '@onward-delivery/core';
import { IconButton } from '@material-ui/core';
import { toNational } from '@/utilities/formatPhoneNumber';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { genResources } from '@/components/Resources/utils';
import { Body1 } from './blocks';

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

export const COLUMNS = [
    {
        Header: 'Data',
        id: 'data-columns',
        columns: [
            {
                Header: 'Order',
                id: 'order_number',
                accessor: (order) => order.order_number,
            },
            {
                Header: 'PO #',
                id: 'po_number',
                accessor: (order) => order.po_number || '--',
            },
            {
                Header: 'Customer',
                id: 'dropoff_name',
                accessor: (order) => order.dropoff_name,
            },
            {
                Header: 'Address',
                id: 'full_address',
                span: false,
                accessor: (order) => {
                    const { full_address } = genAttributes(order);

                    return order[full_address];
                },
                Cell: ({ value }) => {
                    return (
                        <div
                            css={css`
                                overflow: hidden;
                                height: 100%;
                            `}
                        >
                            <Body1
                                css={css`
                                    white-space: normal;
                                `}
                            >
                                {value}
                            </Body1>
                        </div>
                    );
                },
            },
            {
                Header: 'Service Type',
                id: 'location_type',
                accessor: (order) => {
                    const { location_type } = genAttributes(order);

                    return LOCATION_TYPES[order[location_type]] || '--';
                },
            },
            {
                Header: 'Dropoff Location Type',
                id: 'location',
                accessor: (order) => {
                    const { location } = genAttributes(order);

                    return order[location] || '--';
                },
            },
            {
                Header: 'Zip',
                id: 'zip',
                accessor: (order) => {
                    const { zip } = genAttributes(order);

                    return order[zip];
                },
            },
            {
                Header: 'Resource',
                id: 'resources',
                accessor: (order) => {
                    let resources;
                    if (order.oms) {
                        const { default: shipperDefault, override: shipperOverride } = genResources({
                            client: order.order_shipper || {},
                            order,
                        });
                        resources = shipperOverride.length > 0 ? shipperOverride : shipperDefault;
                    } else {
                        const { default: carrierDefault, override: carrierOverride } = genResources({
                            client: order.order_carrier || {},
                            order,
                        });
                        resources = carrierOverride.length > 0 ? carrierOverride : carrierDefault;
                    }

                    return resources;
                },
                Cell: ({ value }) => {
                    if (value.length === 0) {
                        return (
                            <Body1
                                css={css`
                                    color: #f44336;
                                `}
                            >
                                No Resource
                            </Body1>
                        );
                    }

                    return value.map((resource) => resource.name).join(', ');
                },
            },
            {
                Header: 'Email',
                id: 'dropoff_email',
                accessor: (order) => order.dropoff_email || '--',
            },
            {
                Header: 'Phone',
                id: 'dropoff_phone',
                accessor: (order) => (order.dropoff_phone ? toNational(order.dropoff_phone) : '--'),
            },
            {
                Header: 'Invitation Sent',
                id: 'last_invitation',
                accessor: (order) => {
                    const sent = order.messages.filter((message) => message.status === 'SUCCESS');
                    if (sent.length === 0) {
                        return '--';
                    }

                    return dateShort.format(new Date(sent[0].created_at));
                },
            },
            {
                Header: 'Next Invitation At',
                id: 'next_invitation',
                accessor: (order) => {
                    const scheduled = order.messages.filter((message) => message.status === 'SCHEDULED');
                    if (scheduled.length === 0 || !scheduled?.[0]?.scheduled_timestamp) {
                        return '--';
                    }

                    const ts = asUTCDate(scheduled[0].scheduled_timestamp);

                    return dateShort.format(ts);
                },
            },
            {
                Header: 'Times Sent',
                id: 'invitation_times',
                accessor: (order) => {
                    if (order.messages.length === 0) {
                        return '--';
                    }

                    return order.messages.length;
                },
            },
        ],
    },
    {
        sticky: 'right',
        Header: 'Sticky',
        id: 'sticky-group',
        columns: [
            {
                Header: '',
                id: 'schedule-action',
                width: 80,
                Cell: ({ row, callbacks }) => {
                    const order = row.original;
                    let resources;
                    if (order.oms) {
                        const { default: shipperDefault, override: shipperOverride } = genResources({
                            client: order.order_shipper || {},
                            order,
                        });
                        resources = shipperOverride.length > 0 ? shipperOverride : shipperDefault;
                    } else {
                        const { default: carrierDefault, override: carrierOverride } = genResources({
                            client: order.order_carrier || {},
                            order,
                        });
                        resources = carrierOverride.length > 0 ? carrierOverride : carrierDefault;
                    }

                    return (
                        <IconButton
                            disabled={resources.length === 0}
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                callbacks.scheduleOrder([row.original]);
                            }}
                            css={css`
                                padding: 8px;
                                color: ${colors.greens.primary};

                                &.Mui-disabled {
                                    color: ${colors.greys[0]};
                                    background: rgba(206, 217, 224, 0.5);
                                }
                            `}
                        >
                            <CalendarTodayIcon />
                        </IconButton>
                    );
                },
                disableSortBy: true,
            },
        ],
    },
];
