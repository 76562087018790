import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Body1, Body2, Header1, Subtitle1 } from './blocks';
import { parseDriverUsernames } from '../MyRoutes/utils';
import { toNational } from '@/utilities/formatPhoneNumber';

const ClientInfo = ({ order, driver, hideDriverPhone }) => {
    const { order_shipper, order_carrier } = order;
    const onwardDefaultId = 'b925c4f7-df87-42c1-8f5b-1ee19b62fd67';

    const shipperContent = useMemo(() => {
        // Default Onward shipper_id for personal orders.
        if (order?.shipper_id === onwardDefaultId) {
            return (
                <>
                    <Grid item xs={12}>
                        <Header1>Shipper Information</Header1>
                    </Grid>
                    <Grid item xs={12}>
                        <Body1>Onward Delivery Personal Order</Body1>
                    </Grid>
                </>
            );
        } else {
            const shipper_address1 = `${order_shipper?.business_address || ''} ${
                order_shipper?.business_address_unit || ''
            }`;
            const shipper_address2 = `${order_shipper?.business_city || ''}, ${order_shipper?.business_state || ''} ${
                order_shipper?.business_zip || ''
            }`;
            return (
                <>
                    <Grid item xs={12}>
                        <Header1>Shipper Information</Header1>
                    </Grid>
                    <Grid item xs={12}>
                        <Body1>{order_shipper?.business_name || ''}</Body1>
                    </Grid>
                    <Grid item xs={12}>
                        <Body1>{shipper_address1}</Body1>
                    </Grid>
                    <Grid item xs={12}>
                        <Body1>{shipper_address2}</Body1>
                    </Grid>
                </>
            );
        }
    }, []);

    const driverContent = useMemo(() => {
        if (!driver || !driver?.username) {
            return <></>;
        }

        const driverName = parseDriverUsernames(driver?.username);
        const driverPhone = toNational(driver?.phone);

        return (
            <>
                <Grid item xs={12}>
                    <Body1>{driverName}</Body1>
                </Grid>
                {!hideDriverPhone && (
                    <Grid item xs={12}>
                        <Body1>{driverPhone}</Body1>
                    </Grid>
                )}
            </>
        );
    }, [order, driver]);

    const carrierContent = useMemo(() => {
        if (order?.oms && !order?.carrier_id && order?.shipper_id !== onwardDefaultId) {
            // Shipper is planning to deliver their own order
            return (
                <>
                    <Grid item xs={12}>
                        <Body1>{order_shipper?.business_name || ''}</Body1>
                    </Grid>
                </>
            );
        } else if (order?.carrier_id) {
            // There is a carrier, display its info
            return (
                <>
                    <Grid item xs={12}>
                        <Body1>{order_carrier?.business_name || ''}</Body1>
                    </Grid>
                </>
            );
        } else {
            // OMS is false and there is no carrier
            return (
                <>
                    <Grid item xs={12}>
                        <Subtitle1>Delivery not yet assigned</Subtitle1>
                    </Grid>
                </>
            );
        }
    }, [order]);

    return (
        <Grid container>
            {shipperContent}
            <Grid item xs={12}>
                <Body2>Delivered by:</Body2>
            </Grid>
            {carrierContent}
            {driverContent}
        </Grid>
    );
};

export default ClientInfo;
