import { gql } from '@apollo/client';

export const SHIPPING_PARTNER_FIELDS = gql`
    fragment ShippingPartnerFields on shipping_partners {
        shipping_partner_id
        client_id
        name
        email
        phone
        address_full
        address_street
        address_unit
        address_city
        address_state
        address_zip
        created_at
        last_modified_at
    }
`;
