import { gql } from '@apollo/client';

export const PRICING_OVERRIDE_FIELDS = gql`
    fragment PricingOverrideFields on pricing_overrides {
        add_fuel_surcharge
        algo_type
        client_id
        oms
        partner_client_id
        pricing_override_id
        retailer_id
        tariff_id
    }
`;
