import { gql } from '@apollo/client';
import { CORE_CLIENT_ACCOUNT_FIELDS } from '../fragments/clients';
import { CLIENT_FIELDS } from '../queries/clients';

export const UPDATE_CLIENT_ADMIN_SHIPPER = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation updateClientAdmin(
        $client_id: uuid!
        $business_name: String
        $username: String
        $email: String
        $business_phone: String
        $business_address: String
        $billing_email: String
        $billing_address: String
        $billing_city: String
        $billing_state: String
        $billing_zip: String
        $payment_type: String
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                business_name: $business_name
                username: $username
                email: $email
                business_phone: $business_phone
                business_address: $business_address
                billing_email: $billing_email
                billing_address: $billing_address
                billing_city: $billing_city
                billing_state: $billing_state
                billing_zip: $billing_zip
                payment_type: $payment_type
            }
        ) {
            ...CoreClientAccountFields
        }
        update_users(where: { client: { client_id: { _eq: $client_id } } }, _set: { username: $username }) {
            affected_rows
            returning {
                user_id
                username
                roles
                circles
            }
        }
    }
`;
export const UPDATE_CLIENT_ADMIN_CARRIER = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation updateClientAdmin(
        $client_id: uuid!
        $business_name: String
        $username: String
        $email: String
        $business_phone: String
        $billing_email: String
        $billing_address: String
        $billing_city: String
        $billing_state: String
        $billing_zip: String
        $business_address: String
        $business_city: String
        $business_state: String
        $business_zip: String
        $business_ein: String
        $dot_number: String
        $mc_num: String
        $payment_type: String
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                business_name: $business_name
                username: $username
                email: $email
                business_phone: $business_phone
                billing_email: $billing_email
                billing_address: $billing_address
                billing_city: $billing_city
                billing_state: $billing_state
                billing_zip: $billing_zip
                business_address: $business_address
                business_city: $business_city
                business_state: $business_state
                business_zip: $business_zip
                business_ein: $business_ein
                dot_number: $dot_number
                mc_num: $mc_num
                payment_type: $payment_type
            }
        ) {
            ...CoreClientAccountFields
        }
        update_users(where: { client: { client_id: { _eq: $client_id } } }, _set: { username: $username }) {
            affected_rows
            returning {
                user_id
                username
                roles
                circles
            }
        }
    }
`;

export const DEACTIVATE_CLIENT_ADMIN = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation deactivateClientAdmin($client_id: uuid!, $deactivated: Boolean) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: { deactivated: $deactivated }) {
            ...CoreClientAccountFields
        }
    }
`;

export const UPDATE_CLIENT_ORDER_DRAFTS = gql`
    mutation UpdateClientDrafts($client_id: uuid = "", $order_drafts: jsonb = "") {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: { order_drafts: $order_drafts }) {
            client_id
            order_drafts
        }
    }
`;

export const UPDATE_CLIENT_BY_PK = gql`
    ${CLIENT_FIELDS}

    mutation UpdateClientByPk($client_id: uuid!, $update: clients_set_input = {}) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: $update) {
            ...ClientFields
        }
    }
`;
