import React, { useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { colors } from '@/styles';
import { Incrementor, OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { useItemDynamicFields } from '@/components/ShipmentForm/hooks/items';
import {
    FurnitureType,
    Furniture,
    ItemTypeDetails,
    Appliance,
    BedSize,
} from '@/components/ShipmentForm/modals/InputFields';
import _ from 'lodash';

import { local, Header1, BodyLabel1, BodyDescription1, PrimaryButton, SubHeader1 } from '../blocks';

import Footer from './Footer';
import ProgressBar from './ProgressBar';

const Item = ({ item, callbacks, idx, section, setSection }) => {
    useItemDynamicFields(item, (changes) => {
        callbacks.modifyItem((prev) => ({ ...prev, ...changes }));
    });

    let itemForm;
    switch (item.item_type_details) {
        case 'furniture':
            itemForm = (
                <>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <FurnitureType
                            value={item._furniture_type || ''}
                            onChange={(type) => {
                                callbacks.modifyItem((prev) => ({
                                    ...prev,
                                    _furniture_type: type,
                                }));
                            }}
                        />
                    </Grid>
                    {!!item._furniture_type && item._furniture_type.length > 0 ? (
                        <Grid
                            item
                            css={css`
                                margin-bottom: 20px;
                            `}
                        >
                            <Furniture
                                value={{ height: item.height, width: item.width, length: item.length }}
                                furnitureType={item._furniture_type}
                                InputLabelProps={{ shrink: !!item.height }}
                                onChange={({ height, length, weight, width, name }) =>
                                    callbacks.modifyItem((prev) => ({
                                        ...prev,
                                        height,
                                        length,
                                        weight,
                                        width,
                                        description: `${item._furniture_type} - ${name}`,
                                    }))
                                }
                            />
                        </Grid>
                    ) : null}
                </>
            );
            break;
        case 'mattress':
        case 'boxSpring':
        case 'bedFrame':
            itemForm = (
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <BedSize
                        value={{ height: item.height, width: item.width, length: item.length }}
                        itemType={item.item_type_details}
                        InputLabelProps={{ shrink: !!item.height }}
                        onChange={({ height, length, weight, width, display }) =>
                            callbacks.modifyItem((prev) => ({
                                ...prev,
                                height,
                                length,
                                weight,
                                width,
                                description: `${item.item_type_details} - ${display}`,
                            }))
                        }
                    />
                </Grid>
            );
            break;
        case 'appliance':
            itemForm = (
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <Appliance
                        value={{
                            height: item.height,
                            width: item.width,
                            length: item.length,
                            description: item?.description,
                        }}
                        InputLabelProps={{ shrink: !!item.height }}
                        onChange={({ height, length, weight, width, name }) =>
                            callbacks.modifyItem((prev) => ({
                                ...prev,
                                height,
                                length,
                                weight,
                                width,
                                description: `${item.item_type_details} - ${name}`,
                            }))
                        }
                    />
                </Grid>
            );
            break;
        default:
            itemForm = null;
    }

    return (
        <Grid
            container
            direction="row"
            css={css`
                margin-bottom: 30px;
            `}
        >
            <Grid container direction="column">
                <Grid
                    item
                    css={css`
                        margin-bottom: 8px;
                    `}
                >
                    <BodyLabel1
                        css={css`
                            color: ${local.greys[4]};
                        `}
                    >{`Haul away ${idx + 1}`}</BodyLabel1>
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <ItemTypeDetails
                        value={item.item_type_details || ''}
                        freightType={item.freight_type}
                        onChange={(e) =>
                            callbacks.modifyItem((prev) => ({ ...prev, item_type_details: e.target.value }))
                        }
                    />
                </Grid>

                {itemForm}

                <Grid
                    container
                    css={css`
                        margin-top: 20px;
                        justify-content: space-between;
                    `}
                >
                    <PrimaryButton
                        disabled={section?.haulaway_items?.length === 0}
                        onClick={() => {
                            setSection((prev) => {
                                const clone = [...prev.haulaway_items];
                                clone.splice(idx, 1);

                                return {
                                    ...prev,
                                    haulaway_items: clone,
                                };
                            });
                        }}
                        css={css`
                            padding: 12px 24px;
                            width: 100%;
                            background: ${colors.reds.primary};
                        `}
                    >
                        Remove Item
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

function Haulaway({ isDesktop, callbacks, state, customerUpgrade = false }) {
    const DEFAULT_HAULAWAY = {
        quantity: 1,
        item_type: 'household',
        is_return: false,
        return_or_dispose: 'dispose',
    };
    const [section, setSection] = useState(
        _.isEmpty(state.sections[state.progression.current])
            ? { haulaway_items: [] }
            : state.sections[state.progression.current]
    );
    const [hasError, setHasError] = useState(false);

    const enable = useMemo(() => {
        return section.haulaway_items?.length > 0;
    }, [section]);

    useEffect(() => {
        const isEnabled = section.haulaway_items?.length > 0;
        if (customerUpgrade) {
            if (!isEnabled) {
                setHasError(true);
                return;
            }
        }

        let err = false;
        if (isEnabled) {
            err = section.haulaway_items.some((item) => {
                return ['height', 'width', 'length', 'item_type_details', 'return_or_dispose'].some(
                    (field) => !item[field]
                );
            });
        }
        setHasError(err);
    }, [section]);

    return (
        <Grid
            container
            direction="column"
            css={css`
                color: ${local.greys[3]};
            `}
        >
            {!customerUpgrade && <ProgressBar step={state.progression.current + 1} maxSteps={7} />}
            <Grid
                container
                css={css`
                    margin-bottom: 6px;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid item direction="column">
                    <Header1
                        css={css`
                            color: ${local.greys[4]};
                        `}
                    >
                        Haul Away
                    </Header1>
                </Grid>
                {!customerUpgrade && (
                    <Grid item direction="column">
                        <IconButton
                            css={css`
                                padding: 4px;
                            `}
                            onClick={callbacks.showInfo}
                        >
                            <HelpIcon
                                css={css`
                                    color: ${colors.greens.primary};
                                `}
                            />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
            <Grid item direction="row">
                <SubHeader1
                    css={css`
                        color: ${local.greys[2]};
                    `}
                >
                    We can remove your old and unwanted items during the delivery process and dispose of them.
                </SubHeader1>
            </Grid>

            <Grid
                container
                item
                css={css`
                    margin-top: 20px;
                    margin-bottom: 20px;
                `}
            >
                <FormControlLabel
                    control={
                        <OnwardCheckbox
                            color="primary"
                            checked={enable}
                            onChange={() =>
                                setSection((prev) => {
                                    if (prev.haulaway_items.length > 0) {
                                        return {
                                            ...prev,
                                            haulaway_items: [],
                                        };
                                    }

                                    return {
                                        ...prev,
                                        haulaway_items: [{ ...DEFAULT_HAULAWAY }],
                                    };
                                })
                            }
                        />
                    }
                    label="Remove unwanted items."
                    css={css`
                        margin-left: 0;
                    `}
                />
            </Grid>
            {enable ? (
                <>
                    <Grid
                        wrap="nowrap"
                        container
                        item
                        css={css`
                            align-items: center;
                            margin-bottom: 20px;
                            height: 56px;
                        `}
                    >
                        <Grid
                            item
                            css={css`
                                margin-right: 16px;
                            `}
                        >
                            <BodyLabel1>Quantity:</BodyLabel1>
                        </Grid>
                        <Grid item>
                            <Incrementor
                                body={section.haulaway_items.length}
                                incrementCallback={() => {
                                    setSection((prev) => ({
                                        ...prev,
                                        haulaway_items: [...prev.haulaway_items, { ...DEFAULT_HAULAWAY }],
                                    }));
                                }}
                                decrementCallback={() => {
                                    setSection((prev) => {
                                        const clone = [...prev.haulaway_items];
                                        clone.pop();

                                        return {
                                            ...prev,
                                            haulaway_items: clone,
                                        };
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                    {section.haulaway_items.map((item, idx) => {
                        return (
                            <Item
                                key={idx}
                                idx={idx}
                                item={item}
                                section={section}
                                setSection={setSection}
                                callbacks={{
                                    modifyItem: (callback) => {
                                        setSection((prev) => {
                                            const clone = [...prev.haulaway_items];
                                            const modified = { ...clone[idx], ...callback(clone[idx]) };
                                            clone.splice(idx, 1, modified);

                                            return {
                                                ...prev,
                                                haulaway_items: clone,
                                            };
                                        });
                                    },
                                }}
                            />
                        );
                    })}
                </>
            ) : null}

            <Footer
                state={state}
                isFirst={state.isFirstStage}
                isLast={state.isLastStage}
                hasErrors={hasError}
                callbacks={{ advance: () => callbacks.advance(enable ? section : {}), revert: callbacks.revert }}
            />
        </Grid>
    );
}

export default Haulaway;
