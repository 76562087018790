import React, { useState, useMemo, useCallback } from 'react';
import hash from 'object-hash';
import {
    CUSTOMER_FORM_PROGRESSION,
    ITEM_TYPE_STAGE_MAPPING,
    FORM_STATUS,
    DEFAULT_ORDER,
    STAGES,
    PROGRESSION_TYPES,
} from '../constants';
import { postUnauth } from '@/utilities/onwardClient';
import { CUSTOMER_SHIPMENT } from '@/constants/apiRoutes';
import * as Sentry from '@sentry/react';

export const useCustomerFormCallbacks = (state, callbacks) => {
    const setProgressionType = useCallback((type) => {
        callbacks.setProgression({
            current: 0,
            type,
            stages: [...(CUSTOMER_FORM_PROGRESSION[type] || [])],
        });
    }, []);

    const reset = useCallback(() => {
        callbacks.setDynamicFields({
            key: `${new Date().getTime()}-${hash({
                type: DEFAULT_ORDER.order_type,
                freight_type: DEFAULT_ORDER.freight_type,
            })}`,
        });
        callbacks.setPaymentInfo({});
        callbacks.setStatus(FORM_STATUS.IN_PROGRESS);
        callbacks.setSections([]);
        callbacks.setProgression({
            current: 0,
            type: null,
            stages: [],
        });
    }, []);

    const presubmitOrder = useCallback(async () => {
        postUnauth(CUSTOMER_SHIPMENT, { shipment: state.order, redundantItems: state.redundantItems })
            .then(({ data }) => {
                const { itemsByOrderId, haulaway_items, ...remaining } = data.shipment;
                callbacks.setDynamicFields(remaining);
                callbacks.setPaymentInfo(data.paymentInfo);
                callbacks.setRedundantItems(itemsByOrderId.map((item) => item.item_id));
            })
            .catch((err) => {
                console.error(err);
                Sentry.captureException(err);
                callbacks.setStatus(FORM_STATUS.FAILED);
                callbacks.setErrorInfo(err.response.data.error);
            });
    }, [state.order]);

    const confirmPayment = useCallback(async (stripe, elements) => {
        if (!stripe || !elements) return;

        callbacks.setLoading(true);
        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {},
        });

        if (result.error) {
            console.error(result.error.message);
            callbacks.setErrorInfo(result.error.message);
            callbacks.setStatus(FORM_STATUS.FAILED);
        } else {
            callbacks.setStatus(FORM_STATUS.COMPLETE);
        }
        callbacks.setLoading(false);
    }, []);

    const advance = useCallback(
        (section) => {
            const stage = state.progression.stages[state.progression.current];
            const nextStage = state.progression.stages[state.progression.current + 1];

            if (stage === STAGES.PAYMENT && !nextStage) {
                confirmPayment(section.stripe, section.elements);
            }

            if (nextStage) {
                callbacks.setSections((prev) => {
                    const clone = [...prev];
                    clone[state.progression.current] = section;

                    return clone;
                });
                callbacks.setProgression((prev) => {
                    return { ...prev, current: prev.current + 1 };
                });
            }
        },
        [state.progression]
    );

    const revert = useCallback(() => {
        callbacks.setSections((prev) => {
            const clone = [...prev];
            return clone.slice(0, state.progression.current);
        });
        callbacks.setDynamicFields((prev) => {
            const { shipper_rate, carrier_rate, price_breakdown, ...remaining } = prev;
            return remaining;
        });
        callbacks.setProgression((prev) => {
            if (prev.current > 0) {
                return { ...prev, current: prev.current - 1 };
            }

            return {
                current: 0,
                type: null,
                stages: [],
            };
        });
    }, [state, state.progression]);

    const configureItemTypes = useCallback(
        (types) => {
            callbacks.setProgression((prev) => {
                const clone = [...CUSTOMER_FORM_PROGRESSION[prev.type]];
                clone.splice(prev.current + 1, 0, ...types.map((type) => ITEM_TYPE_STAGE_MAPPING[type]));

                return { ...prev, stages: clone };
            });
        },
        [callbacks]
    );

    return {
        presubmitOrder,
        configureItemTypes,
        setProgressionType,
        advance,
        revert,
        reset,
    };
};
