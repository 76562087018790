import React, { useContext } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { Context } from '../store';
import { toNational } from '@/utilities/formatPhoneNumber';
import { PrimaryButton } from '@/styles/blocks';
import { PROGRESSION_TYPES } from '../constants';

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

function PickUpCard(props) {
    const { state, callbacks } = useContext(Context);
    const pickupInfo = !state.pickupInfo.is_middle_mile ? state.pickupInfo : {};

    return (
        <Card
            css={css`
                margin-bottom: 1rem;
                height: 100px;
            `}
        >
            <CardContent
                css={css`
                    font-size: 14px;
                    padding: 16px;
                `}
            >
                <Grid container spacing={1}>
                    <Grid
                        item
                        css={css`
                            flex: 2;
                            flex-basis: 0;
                        `}
                    >
                        <Grid item xs={12}>
                            {pickupInfo.store}
                        </Grid>
                        <Grid item xs={12}>
                            First Available:{' '}
                            {pickupInfo.firstAvailableDate
                                ? dateNumeric.format(new Date(pickupInfo.firstAvailableDate))
                                : '--'}
                        </Grid>
                        <Grid item xs={12}>
                            {pickupInfo.pickupaddress}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            flex: 2;
                            flex-basis: 0;
                        `}
                    >
                        <Grid item xs={12}>
                            {pickupInfo.pickupname}
                        </Grid>
                        <Grid item xs={12}>
                            {toNational(pickupInfo.pickupphone, '--')}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            align-self: center;
                        `}
                    >
                        <PrimaryButton
                            onClick={() => {
                                callbacks.setProgression((prev) => {
                                    return {
                                        ...prev,
                                        type: PROGRESSION_TYPES.EDIT_ORDER_PICKUP,
                                    };
                                });
                            }}
                        >
                            Edit
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default PickUpCard;
