import React, { useState, useEffect, useMemo, useRef } from 'react';
import { compose } from 'recompose';
import { useNavigate, useParams } from 'react-router-dom';
import { asDateInTZ } from '@/utilities/convertToISO';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { genAttributes } from '@onward-delivery/core';
import { useMutation, useQuery } from '@apollo/client';
import { Typography, Grid, FormControl, Select, MenuItem, Paper, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import dateFns from '../../../utilities/dateFns.js';
import withAdminRights from '../../Auth/withAdminRights';
import withErrorBoundary from '../../ErrorBoundary';
import { format } from 'date-fns';
import statusOptions from '../../../constants/routeStatusOptions';
import { CustomDayPicker, CarrierAutocomplete } from '../../Inputs';
import { QUERY_BY_ID as ROUTE_QUERY } from '../../../graphql/queries/routes';
import { UPDATE_ROUTE } from './graphql.js';
import EditPriceDialog from './EditPriceDialog.js';
import ButtonWithSpinner from '@/components/ButtonWithSpinner/index.js';
import { captureException } from '@sentry/react';

function AdminEditFTLRoute() {
    const navigate = useNavigate();
    const { uid } = useParams();
    const [route, setRoute] = useState({});
    const [editError, setEditError] = useState(null);
    const [originalRoute, setOriginalRoute] = useState({});
    const [unavailableDropoffDates, setUnavailableDropoffDates] = useState([]);
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [incompleteStops, setIncompleteStops] = useState([]);
    const [pickUps, setPickUps] = useState([]);
    const [dropOffs, setDropOffs] = useState([]);
    const [saving, setSaving] = useState(false);
    const [saveMessage, setSaveMessage] = useState(null);
    const [showEditPrice, setShowEditPrice] = useState(false);
    const [routeTimeZone, setRouteTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [routeIsLocked, setRouteIsLocked] = useState(false);
    const [routePriceChanged, setRoutePriceChanged] = useState(false);
    const [allOrdersSameShipper, setAllOrdersSameShipper] = useState(false);
    const [carrRate, setCarrRate] = useState(null);
    const [shipRate, setShipRate] = useState(null);
    const [margin, setMargin] = useState(null);
    const [routeStatus, setRouteStatus] = useState('');
    const [routeStartTime, setRouteStartTime] = useState(null);
    const [routeDelDate, setRouteDelDate] = useState('');

    const { loading, error, data } = useQuery(ROUTE_QUERY, {
        variables: { route_id: uid },
        fetchPolicy: 'network-only',
        onError: (error) => {
            console.error(error);
            captureException(error);
        },
    });

    useEffect(() => {
        const res = data?.routes_by_pk;
        if (res) {
            setRoute(res);
            checkSameShipper(res);
            setCarrRate(res?.total_carrier_rate);
            setShipRate(res?.total_shipper_rate);
            setMargin(res?.margin && !isNaN(res.margin) ? res.margin : 0);
            setRouteStatus(res?.status);
            setRouteStartTime(res?.start_time);
            setRouteDelDate(res?.scheduled_delivery_formatted);
            if (res?.carrier_id) {
                setSelectedCarrier(res.carrier_id);
            }
            if (res?.stopsByRouteId) {
                const locPickUps = res.stopsByRouteId.filter((stop) => stop.type === 'PICKUP');
                const locDropOffs = res.stopsByRouteId.filter((stop) => stop.type === 'DROPOFF');
                const locIncomplete = res.stopsByRouteId.filter((stop) => !stop.stop_completion_time);
                setPickUps(locPickUps.length);
                setDropOffs(locDropOffs.length);
                setIncompleteStops(locIncomplete);
            }
        }
    }, [data]);

    const [updateRoute] = useMutation(UPDATE_ROUTE, {
        onCompleted: (data) => {
            setSaving(false);
            setSaveMessage('Route Saved!');
        },
        onError: (error) => {
            setSaving(false);
            console.log(error);
        },
    });

    const handleSave = () => {
        setSaving(true);

        const pickups = route.orders.filter((mapping) => mapping.type === 'PICKUP');
        const dropoffs = route.orders.filter((mapping) => mapping.type !== 'PICKUP');

        const order = route.orders?.[0]?.order || {};
        const { zip } = genAttributes(order);
        const tz = zipcode_to_timezone.lookup(order[zip]) || 'America/New_York';

        const delivery_date = asDateInTZ(routeDelDate, tz).toISOString();

        const statusMap = {
            planning: 'routed',
            action: 'routed',
            pending: 'routed',
            open: 'routedOpen',
            inProgress: 'routedInProgress',
            complete: 'routedComplete',
        };

        const NOW = new Date().toISOString();

        updateRoute({
            variables: {
                route_id: route.route_id,
                route_update: {
                    carrier_id: selectedCarrier || null,
                    total_carrier_rate: carrRate,
                    total_shipper_rate: shipRate,
                    margin: margin,
                    start_time: routeStartTime,
                    scheduled_delivery: delivery_date,
                    ...(routeStatus !== route.status
                        ? {
                              status: routeStatus,
                              completed: NOW,
                          }
                        : {}),
                },
                order_updates: [
                    {
                        where: { order_id: { _in: dropoffs.map((mapping) => mapping.order_id) } },
                        _set: {
                            delivery_date: delivery_date,
                            ...(routeStatus !== route.status
                                ? {
                                      order_status: statusMap[routeStatus],
                                      completion_time: NOW,
                                      completion_source: 'WEB_ADMIN',
                                  }
                                : {}),
                        },
                    },
                    {
                        where: { order_id: { _in: pickups.map((mapping) => mapping.order_id) } },
                        _set: {
                            pickup_date: delivery_date,
                        },
                    },
                ],
            },
        });
    };

    const checkSameShipper = (route) => {
        if (!route) {
            return;
        }
        //Get all orders, check if shipper_id is the same
        let routeShippers = route.orders.map((mapping) => mapping.order.shipper_id);
        setAllOrdersSameShipper(routeShippers.every((val, i, arr) => val === arr[0]));
    };

    const handleCarrierSelect = (carrier) => {
        setSelectedCarrier(carrier.client_id);
        setSaveMessage(null);
    };
    const priceChangeSubmit = (priceChanges) => {
        setCarrRate(priceChanges.total_carrier_rate);
        setShipRate(priceChanges.total_shipper_rate);
        setMargin(priceChanges.margin);
        setRoutePriceChanged(true);
        setSaveMessage(null);
    };
    const calculateRouteTotalCubes = () => {
        if (route?.total_cubes) {
            return route?.total_cubes?.toFixed?.(2);
        }
        let totalCubes = 'N/A';
        return totalCubes;
    };
    return (
        <div className={`'collapse-margin'} sidenav-margin-responsive`} style={{ marginBottom: '100px' }}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    style={{ color: '#000', marginBottom: '1rem' }}
                    onClick={() => navigate(-1)}
                >
                    <ArrowBack /> Back
                </Button>
                <h2 style={{ flex: 1, flexDirection: 'row', textAlign: 'center' }}>
                    Edit FTL Route: {route?.route_number}
                </h2>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '20px', justifyContent: 'center' }}>
                <Grid item lg={6} md={8} xs={12}>
                    <Paper style={{ padding: '10px 15px' }} elevation={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography style={{ marginRight: '12px', flex: 1 }}>Carrier:</Typography>
                                    <div style={{ flex: 1 }}>
                                        <CarrierAutocomplete
                                            handleCarrierSelect={handleCarrierSelect}
                                            selectedCarrier={selectedCarrier}
                                            disabled={
                                                saving || loading || route.status === statusOptions.COMPLETE.value
                                            }
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography style={{ marginRight: '12px', flex: 1 }}>
                                        Scheduled Delivery:
                                    </Typography>
                                    <CustomDayPicker
                                        style={{
                                            textAlign: 'center',
                                            width: '300px',
                                            flex: 1,
                                        }}
                                        disabled={
                                            saving || loading || route.status === statusOptions.COMPLETE.value
                                                ? true
                                                : false
                                        }
                                        formatDate={dateFns.formatDate}
                                        format={'MM/dd/yyyy'}
                                        parseDate={dateFns.parseDate}
                                        placeholder={routeDelDate}
                                        value={routeDelDate}
                                        onDayChange={(day) => {
                                            setRouteDelDate(format(day, 'MM/dd/yyyy'));
                                            setSaveMessage(null);
                                        }}
                                        customerUnavailableDays={unavailableDropoffDates}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography style={{ marginRight: '12px', flex: 1 }}>Status:</Typography>
                                    <FormControl size="small">
                                        <Select
                                            variant="outlined"
                                            value={routeStatus}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                if (
                                                    route?.status.toLowerCase() === 'planning' &&
                                                    newValue.toLowerCase() === 'active'
                                                ) {
                                                    setEditError(
                                                        'Cannot directly change a route from Planning to Active status. Please do this via the Planning page by submitting it.'
                                                    );
                                                    return;
                                                }
                                                setRouteStatus(newValue);
                                                setSaveMessage(null);
                                            }}
                                            style={{ width: '200px' }}
                                            disabled={saving || loading}
                                        >
                                            {Object.values(statusOptions).map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.display}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <hr
                                style={{
                                    height: 3,
                                    width: '100%',
                                    color: 'grey',
                                }}
                            />
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography style={{ marginRight: '5px', flex: 1 }}>Pricing: </Typography>
                                    <Button
                                        onClick={() => setShowEditPrice(true)}
                                        variant="outlined"
                                        style={{ marginLeft: '5px' }}
                                        size="small"
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography style={{ marginRight: '5px', flex: 1 }}>Shipper Rate: </Typography>
                                    <Typography>${shipRate ? shipRate.toFixed(2) : '0.00'}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography style={{ marginRight: '5px', flex: 1 }}>Carrier Rate: </Typography>
                                    <Typography>${carrRate ? carrRate.toFixed(2) : '0.00'}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography style={{ marginRight: '5px', flex: 1 }}>Margin: </Typography>
                                    <Typography>
                                        {margin
                                            ? margin
                                            : shipRate && carrRate
                                            ? ((shipRate - carrRate) / shipRate) * 100
                                            : 0}
                                        %
                                    </Typography>
                                </div>
                            </Grid>
                            <hr
                                style={{
                                    height: 3,
                                    width: '100%',
                                    color: 'grey',
                                }}
                            />
                            <Grid item xs={12}>
                                <div style={{ display: 'flex' }}>
                                    <Typography style={{ marginRight: '5px' }}>Pick Ups: </Typography>
                                    <Typography>{pickUps}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex' }}>
                                    <Typography style={{ marginRight: '5px' }}>Drop-offs: </Typography>
                                    <Typography>{dropOffs}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex' }}>
                                    <Typography style={{ marginRight: '5px' }}>Total Cubes: </Typography>
                                    <Typography>{calculateRouteTotalCubes()}</Typography>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{ display: 'flex' }}>
                                    <Typography style={{ marginRight: '5px' }}>Incomplete Stops: </Typography>
                                    {incompleteStops.length > 0 ? (
                                        incompleteStops.map((stop) => (
                                            <Typography key={stop.ordering}>{stop.ordering + ', '}</Typography>
                                        ))
                                    ) : (
                                        <Typography>All stops completed</Typography>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{ display: 'flex' }}>
                                    <Typography style={{ marginRight: '5px' }}>Type:</Typography>
                                    <Typography>{route?.oms ? 'Internal' : 'Onward'}</Typography>
                                </div>
                            </Grid>

                            {route?.bulk_bol && (
                                <Grid item xs={12}>
                                    <Button
                                        onClick={() => window.open(route?.bulk_bol)}
                                        variant="contained"
                                        color="primary"
                                        style={{ color: '#eee' }}
                                        disableElevation
                                        size="large"
                                    >
                                        View BOL
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <div style={{ display: 'flex' }}>
                                    <Typography style={{ marginRight: '50px', flex: 1, color: 'red' }}>
                                        {editError ? 'Error: ' + editError : null}
                                    </Typography>
                                    <ButtonWithSpinner
                                        loading={saving}
                                        buttonProps={{
                                            onClick: () => handleSave(),
                                            disableElevation: true,
                                            disabled: loading || saving,
                                        }}
                                    >
                                        Save
                                    </ButtonWithSpinner>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    <div
                        style={{
                            display: 'flex',
                            color: 'green',
                            alignContent: 'center',
                            justifyContent: 'center',
                            fontWeight: '700',
                        }}
                    >
                        {saveMessage}
                    </div>
                </Grid>
            </Grid>
            <EditPriceDialog
                showEditPrice={showEditPrice}
                setShowEditPrice={setShowEditPrice}
                priceChangeSubmit={priceChangeSubmit}
                allOrdersSameShipper={allOrdersSameShipper}
                route={route}
            />
        </div>
    );
}

export default React.memo(compose(withAdminRights, withErrorBoundary)(AdminEditFTLRoute));
