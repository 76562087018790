import React from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';
import { colors } from '@/styles';

import { local, Card, Header1, Body1 } from './blocks';

function PitchCard({ isDesktop, callbacks, state }) {
    return (
        <Card>
            <Grid container direction="column">
                <Grid
                    container
                    css={css`
                        color: ${local.black};
                        margin-bottom: 8px;
                    `}
                >
                    <Header1>Sign Up Now for FREE</Header1>
                </Grid>
                <Grid
                    container
                    css={css`
                        color: ${local.black};
                        margin-bottom: 40px;
                    `}
                >
                    <Body1>No risk whatsoever, just free opportunities up for grabs!</Body1>
                </Grid>
                <Grid
                    container
                    css={css`
                        color: ${local.black};
                        margin-bottom: 8px;
                    `}
                >
                    <Header1>Full Trucks, Full Freedom</Header1>
                </Grid>
                <Grid
                    container
                    css={css`
                        color: ${local.black};
                        margin-bottom: 40px;
                    `}
                >
                    <Body1>
                        Onward makes it easy to pick up and deliver pieces here and there, or claim full truckloads at a
                        time.
                    </Body1>
                </Grid>

                <Grid
                    container
                    css={css`
                        color: ${local.black};
                        margin-bottom: 8px;
                    `}
                >
                    <Header1>LTL and FTL options</Header1>
                </Grid>
                <Grid
                    container
                    css={css`
                        color: ${local.black};
                    `}
                >
                    <Body1>
                        Onward gives you instant access to our entire network of professional delivery crews who are
                        ready to serve you now.
                    </Body1>
                </Grid>
            </Grid>
        </Card>
    );
}

export default PitchCard;
