import { gql } from '@apollo/client';

export const RATING_FIELDS = gql`
    fragment RatingFields on ratings {
        chips
        comment
        created_at
        last_modified_at
        legacy_rating_id
        order_id
        rating
        rating_id
        reviewee_id
        reviewee_type
        reviewer_type
        reviewer_id
    }
`;

export const FULL_QUERY = gql`
    ${RATING_FIELDS}
    query GetRatings {
        ratings(limit: 100) {
            ...RatingFields
        }
    }
`;

export const QUERY_BY_ID = gql`
    ${RATING_FIELDS}
    query ratings($rating_id: String) {
        ratings(limit: 100, where: { rating_id: { _eq: $rating_id } }) {
            ...RatingFields
        }
    }
`;

export const QUERY_BY_ORDER = gql`
    ${RATING_FIELDS}
    query RatingsByOrderID($order_id: uuid) {
        results: ratings(where: { order_id: { _eq: $order_id } }) {
            ...RatingFields
        }
    }
`;

export const QUERY_BY_ROUTE = gql`
    ${RATING_FIELDS}
    query RatingsByRouteID($orders: [uuid]) {
        results: ratings(where: { order_id: { _in: $orders } }) {
            ...RatingFields
        }
    }
`;

export const QUERY_BY_REVIEWEE = gql`
    ${RATING_FIELDS}
    query RatingsByRevieweeID($reviewee_id: uuid) {
        results: ratings(where: { reviewee_id: { _eq: $reviewee_id } }) {
            ...RatingFields
        }
    }
`;

export const AGGREGATE_RATING_BY_REVIEWEE = gql`
    query AggregateRatingsByRevieweeID($reviewee_id: uuid) {
        results: ratings_aggregate(where: { reviewee_id: { _eq: $reviewee_id } }) {
            aggregate {
                avg {
                    rating
                }
            }
        }
    }
`;

export const AGGREGATE_RATING_BY_DRIVER = gql`
    query AggregateRatingsByDriverID($driver_id: uuid) {
        results: ratings_aggregate(where: { driver_id: { _eq: $driver_id } }) {
            aggregate {
                avg {
                    rating
                }
            }
        }
    }
`;
