import QRCode from 'qrcode';
import printJS from 'print-js';
import { captureException } from '@sentry/react';

export const deliveryTypeLabels = {
    curbside: 'Curbside',
    dock: 'Dock Delivery',
    inStore: 'In Store',
    rollUpDoor: 'Warehouse/Roll up door',
    install: 'Installation',
    installAndRemove: 'Installation and Removal',
    firstDrySpace: 'First Dry Space',
    roomOfChoice: 'Room of Choice',
    whiteGlove: 'White Glove',
};

// Styles properties defined here must also be stated in 'targetStyle' in the printJS function below or else they will be ignored.

const getItemLabelBody = (item, qrSvg) => `
    <div class="label-border" style="
        margin: 0;
        padding: 0;
        border: 1px solid black; margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    ">
        <div class="header no-margin" style="
            background-color: black;
            color: white;
            text-align: center;
            -webkit-print-color-adjust: exact;
            font-size: 2px;
            margin: 0; !important;"
        >
            <h1 class="header no-margin" style="
                background-color: black;
                color: white;
                text-align: center;
                -webkit-print-color-adjust: exact;
                font-size: 22px !important;
                margin: 0 !important;
            ">
                ONWARD
            </h1>
        </div>
        <div class="info" style="text-align: center;">
            <h2 class="less-margin">SKU:${item.sku ? item.sku : 'Not Provided'}</h2>
            <h4 class="less-margin">
                Shipper: ${item.order?.order_shipper?.business_name ? item.order.order_shipper.business_name : 'N/A'}
            </h4>
        </div>
        <div class="border-line"></div>
        <div class="info-grid horizontal-padding" style="
            display: flex;
            flex-direction: column;
            gap: 5px;
        ">
            <div style="
                display: flex;
                flex-direction: row;
            ">
                <div class="info-grid-item" style="width: 40%">Customer:</div>
                <div class="info-grid-item" style="width: 100%">${
                    item.order?.dropoff_name ? item.order.dropoff_name : 'N/A'
                }</div>
            </div>
            <div style="
                display: flex;
                flex-direction: row;
            ">
                <div class="info-grid-item" style="width: 40%">Address:</div>
                <div class="info-grid-item" style="width: 100%">${item.order?.dropoff_address}</div>
            </div>
        </div>
        <div class="border-line"></div>
        <h2 class="less-margin horizontal-padding">Item Description</h2>
        <div class="info-grid horizontal-padding" style="
            display: flex;
            flex-direction: column;
            gap: 5px;
        ">
            <div style="
                display: flex;
                flex-direction: row;
            ">
                <div class="info-grid-item" style="width: 50%">Order: ${item.order?.order_number}</div>
                <div class="info-grid-item" style="width: 50%">MFG: ${
                    item.order?.manufacturer ? item.order.manufacturer : 'N/A'
                }</div>
            </div>

            <div style="
                display: flex;
                flex-direction: row;
            ">
                <div class="info-grid-item" style="width: 50%">PO: ${item.order?.po_number}</div>
                <div class="info-grid-item" style="width: 50%">Dropoff type: ${
                    deliveryTypeLabels[item.order.dropoff_location_type] || ''
                }</div>
            </div>
        </div>
        <div class="svg-div" style="
            height: 100% !important;
            width: 100% !important;
            text-align: center !important;
            overflow: auto !important;
        ">
            ${qrSvg}
        </div>
    </div>`;

const getPalletLabelBody = (pallet, qrSvg) => `
    <div class="label-border" style="
        margin: 0;
        padding: 0;
        border: 1px solid black;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    ">
        <div class="header no-margin" style="
            background-color: black;
            color: white;
            text-align: center;
            -webkit-print-color-adjust: exact;
            font-size: 2px;
            margin: 0; !important;
            flex-shrink: 0;
        ">
            <h1 class="header no-margin" style="
                background-color: black;
                color: white;
                text-align: center;
                -webkit-print-color-adjust: exact;
                font-size: 22px !important;
                margin: 0 !important;
            ">
                ONWARD
            </h1>
        </div>
        <div class="info" style="text-align: center;">
            <h2 class="less-margin">Pallet: ${pallet.pallet_name ? pallet.pallet_name : pallet.pallet_number}</h2>
            <h4 class="less-margin">
                Shipper: ${
                    pallet.items[0].order?.order_shipper?.business_name
                        ? pallet.items[0].order.order_shipper.business_name
                        : 'N/A'
                }
            </h4>
        </div>
        <div class="border-line"></div>
        <div class="info-grid horizontal-padding" style="
            display: flex;
            flex-direction: column;
            overflow: auto;
            gap: 5px;
            flex-shrink: 0;
        ">
            <div style="
                display: flex;
                flex-direction: row;
            ">
                <div class="info-grid-item" style="width: 40%">Customer: </div>
                <div class="info-grid-item" style="width: 100%">${
                    pallet.items[0].order?.dropoff_name ? pallet.items[0].order.dropoff_name : 'N/A'
                }</div>
            </div>
            <div style="
                display: flex;
                flex-direction: row;
            ">
                <div class="info-grid-item" style="width: 40%">Address:</div>
                <div class="info-grid-item" style="width: 100%">${pallet.items[0].order?.dropoff_address}</div>
            </div>
        </div>
        <div class="border-line"></div>
        <h2 class="less-margin horizontal-padding">Order: ${pallet.items[0].order?.order_number}</h2>
        <div class="horizontal-padding" style="
            margin-top: 10px;
            display: flex;
            flex-direction: row;
        ">
            <div class="info-grid-item" style="
                width: 50%;
            ">
                Items: ${pallet.items.length}
            </div>
            <div class="info-grid-item" style="
                width: 50%;
            ">
                PO: ${pallet.items[0].order?.po_number}
            </div>
        </div>

        <div style="
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            flex-shrink: 1;
            overflow: hidden;
            font-size: 14px !important;
            margin: 0 !important;
            padding: 0 15px 0 15px !important;
            display: flex;
            flex-direction: column;
        ">
        ${pallet.items
            .map(
                (item) =>
                    `<div class="info-grid-item">
                        ${item.description ? item.description : 'No Desc. Given'}${item?.sku ? ` - ${item.sku}` : ''}
                    </div>`
            )
            .join('')}
        </div>

        <div class="svg-div" style="
            display: flex;
            flex-shrink: 1000;
            width: 100%;
            min-height: 210px;
        ">
            ${qrSvg}
        </div>
    </div>`;

const getLabelHtml = (labelBody) => `
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
            </style>
          </head>
          <body>
            ${labelBody.map((label, i) => (i > -1 ? `<div class="page">${label}</div>` : label)).join('')}
          </body>
        </html>
        `;

export const getItemQR = (item) => {
    let svgString;
    const qrValues = JSON.stringify({
        labelType: 'item',
        value: item.item_id,
    });
    QRCode.toString(qrValues, { type: 'svg', width: 600 }, function (err, string) {
        if (err) throw err;
        svgString = string;
    });
    return svgString;
};

export const getPalletQR = (pallet) => {
    let svgString;
    const qrValues = JSON.stringify({
        labelType: 'pallet',
        value: pallet.pallet_id,
        nameOrderId: pallet.pallet_name ? `palletName=${pallet.pallet_name}&orderId=${pallet.items[0].order_id}` : null,
    });
    QRCode.toString(qrValues, { type: 'svg', width: 600 }, function (err, string) {
        if (err) throw err;
        svgString = string;
    });
    return svgString;
};

export const printPalletAndItemLabels = async (items, pallets) => {
    let palletQrCodes = [];
    pallets.forEach((pallet) => {
        palletQrCodes.push(getPalletQR(pallet));
    });
    let itemQrCodes = [];
    items.forEach((item) => {
        itemQrCodes.push(getItemQR(item));
    });

    let palletLabelBody = pallets.map((pallet, i) => {
        return getPalletLabelBody(pallet, palletQrCodes[i]);
    });
    let itemLabelBody = items.map((item, i) => {
        return getItemLabelBody(item, itemQrCodes[i]);
    });
    const allLabelBodies = [...palletLabelBody, ...itemLabelBody];
    const htmlString = getLabelHtml(allLabelBodies);

    try {
        const html = new DOMParser().parseFromString(htmlString, 'text/html');
        let toPrint = html.children[0];
        const head = toPrint.children[0];
        const body = toPrint.children[1];
        const svgHolder = document.createElement('div');
        svgHolder.setAttribute('id', 'svg-holder');
        for (let i = 0; i < head.children.length; i++) {
            document.head.appendChild(head.children[i]);
        }
        for (let bodyNode of body.children) {
            svgHolder.appendChild(bodyNode.cloneNode(true));
        }
        document.body.appendChild(svgHolder);
        printJS({
            printable: 'svg-holder',
            type: 'html',
            style: `
                .header {
                    background-color: black;
                    color: white;
                    -webkit-print-color-adjust: exact;
                    text-align: center;
                    width: 100%;
                    font-size: 22px !important;
                }
                .no-margin {
                    margin: 0 !important;
                }
                .label-border {
                    margin: 0;
                    padding: 0;
                    border: 1px solid black;
                }
                .info {
                    text-align: center;
                }
                .less-margin {
                    margin-top: 5px !important;
                    margin-bottom: 5px !important;
                }
                .border-line {
                    border-bottom: 3px solid black;
                }
                .horizontal-padding {
                    padding: 0 15px 0 15px !important;
                }
                .info-grid {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
                .info-grid-item {
                    font-size: 14px !important;
                    margin: 0 !important;
                    padding: 0 !important;
                }
                body, div, h1, h2, h4, p {
                    font-family: 'Montserrat', sans-serif;
                }
                .page {
                    page-break-after: always;
                    page-break-inside: avoid;
                    page-break-before: avoid;
                    break-after: always;
                    break-inside: avoid;
                    break-before: avoid;
                    display: flex;
                    flex-direction: column;
                    height: 97vh;
                }
                svg {
                    max-height: 100%;
                    max-width: 100%;
                    object-fit: contain;
                }
            `,
            targetStyle: [
                'width',
                'height',
                'text-align',
                'font-size',
                'font-family',
                'display',
                'flex-direction',
                'justify-content',
                'object-fit',
                'overflow',
                'flex-shrink',
                'background-color',
                'color',
                'margin',
                'margin-top',
                'margin-left',
                'margin-right',
                'margin-bottom',
                'padding',
                'padding-left',
                'padding-right',
                'padding-top',
                'padding-bottom',
                'border',
                'border-top',
                'border-bottom',
                'border-right',
                'border-left',
                'flex-wrap',
                'break-after',
                'max-height',
                'max-width',
            ],
            scanStyles: false,
            targetStyles: ['break', 'flex', '*', 'border', 'margin', 'padding'],
            font_size: '',
            onPrintDialogClose: () => {
                svgHolder.remove();
            },
        });
    } catch (err) {
        console.error(err);
        captureException(err);
    }
};
