import { colors } from '@/styles';
import styled from '@emotion/styled';

const notificationText = `
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0px;
`;

export const NotificationMetadata = styled.span`
    ${notificationText}
    font-weight: 500;
    margin-top: 10px;
    color: #2b2b2b;
`;

export const NotificationTitle = styled.span`
    ${notificationText}
    font-weight: 700;
    color: #2b2b2b;
`;

export const NotificationTimestamp = styled.span`
    ${notificationText}
    font-weight: 500;
    margin-top: 10px;
    color: ${colors.greys.secondary};
`;
