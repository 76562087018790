import React, { useState, useContext, useMemo, useEffect } from 'react';
import { compose } from 'recompose';
import { useNavigate } from 'react-router';
import {
    Table,
    Tabs,
    Tab,
    Typography,
    TableHead,
    TableFooter,
    TablePagination,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Button,
    TextField,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

import withAdminRights from '../../Auth/withAdminRights';
import * as ROUTES from '../../../constants/routes';
import { UserContext } from '../../App';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { QUERY_USERS_BY_ROLE } from './queries';
import { COLUMNS, SEARCHABLE, TABS } from './constants';
import { UPDATE_CLIENT_BY_ID } from './mutations';
import { QUERY_BY_ID } from '@/graphql/queries/users';

const AdminUsers = () => {
    const [tab, setTab] = useState(TABS[0]);
    const [page, setPage] = useState(0);
    const [searchInput, setSearchInput] = useState('');

    const { sidebarCollapsed } = useContext(UserContext);
    const { user: authUser, setSuperUser } = useContext(UserContext);
    const navigate = useNavigate();

    const { loading, error, data } = useQuery(QUERY_USERS_BY_ROLE);

    const [updateClient, { loading: updateLoading }] = useMutation(UPDATE_CLIENT_BY_ID);
    const [getUser] = useLazyQuery(QUERY_BY_ID);

    const filteredUsers = useMemo(() => {
        const tabUsers = data?.[tab.data] || [];

        if (searchInput && searchInput.length) {
            return tabUsers.filter((tabUser) =>
                SEARCHABLE.some((searchField) => {
                    const tabUserField = _.get(tabUser, searchField);
                    if (tabUserField && tabUserField.length) {
                        const fieldLower = tabUserField.toLowerCase();
                        const searchLower = searchInput.toLowerCase();
                        return fieldLower.includes(searchLower) || searchLower.includes(fieldLower);
                    }
                    return false;
                })
            );
        }

        return tabUsers;
    }, [data, tab, searchInput]);

    const changeTab = (...[, newTab]) => {
        setTab(TABS[newTab]);
        setPage(0);
    };

    const logInAsUser = async (entity) => {
        const { user_id, roles } = entity.user;

        if (roles?.ADMIN) {
            alert('Logging in as another admin is not allowed');
            return;
        }

        getUser({
            variables: { user_id },
            onCompleted: ({ user }) => {
                let userObj = {
                    ...user,
                    authUserRoles: authUser.roles,
                    adminLoggedIn: true,
                    originalAdminEmail: authUser.email,
                    originalAdminName: authUser.username,
                    isTestAccount: user.roles.TEAMMATE ? user.teammate.test_acc : user.client.test_acc,
                };

                window?.sessionStorage?.setItem('admin_user_object', JSON.stringify(userObj));

                setSuperUser(userObj);

                if (user.roles?.CARRIER) {
                    navigate(ROUTES.CARRIER_ACCOUNT);
                } else if (user.roles?.SHIPPER) {
                    navigate(ROUTES.SHIPPER_ACCOUNT);
                } else if (user.roles?.TEAMMATE) {
                    navigate(ROUTES.TEAMMATE_ACCOUNT);
                }

                alert(
                    `You will now see the same side bar and navigate around as if you were logged in as ${user.email}. To return to your admin account, click 'Back to Admin' at the bottom of the navbar, or refresh the page.`
                );
            },
            onError: (err) => {
                console.error(err);
            },
        });
    };

    const goToUser = (entity) => {
        if (entity.user.roles.SHIPPER) {
            navigate(`/admin/shipper/${entity.client_id}`);
        } else if (entity.user.roles.CARRIER) {
            navigate(`/admin/carrier/${entity.client_id}`);
        }
    };

    const colFilter = (column) => !column.tabs || column.tabs.includes(tab.header);

    return (
        <div
            style={{ margin: '16px' }}
            className={`${sidebarCollapsed && 'collapse-margin'} sidenav-margin-responsive`}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography style={{ fontWeight: 600 }} variant="h4">
                    Super Admin
                </Typography>

                <TextField
                    size="small"
                    variant="outlined"
                    color="primary"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search..."
                />
            </div>

            <Tabs textColor="primary" indicatorColor="primary" value={tab.index} onChange={changeTab}>
                {TABS.map((tab) => (
                    <Tab label={tab.header} key={tab.header} />
                ))}
            </Tabs>

            <Paper>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {COLUMNS.filter(colFilter).map((column) => (
                                <TableCell key={column.label} align="center">
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell />
                            {tab.header !== 'Teammates' && <TableCell />}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {loading
                            ? [...Array(5)].map((...[, i]) => (
                                  <TableRow key={i}>
                                      <TableCell colSpan={COLUMNS.filter(colFilter).length + 1}>
                                          <Skeleton variant="text" />
                                      </TableCell>
                                  </TableRow>
                              ))
                            : filteredUsers.slice(page * 10, page * 10 + 10).map((entity) => (
                                  <TableRow key={entity.user.user_id}>
                                      {COLUMNS.filter(colFilter).map((column) => (
                                          <TableCell align="center" key={`${entity.user.user_id}-${column.label}`}>
                                              {column.value(entity, loading || updateLoading, {
                                                  updateClient: (client_id, update = {}) =>
                                                      updateClient({ variables: { client_id, update } }),
                                              })}
                                          </TableCell>
                                      ))}
                                      <TableCell align="center">
                                          <Button
                                              variant="contained"
                                              style={{ color: '#eee' }}
                                              color="primary"
                                              onClick={() => logInAsUser(entity)}
                                          >
                                              Log&nbsp;In
                                          </Button>
                                      </TableCell>
                                      {tab.header !== 'Teammates' && (
                                          <TableCell align="center">
                                              <Button
                                                  variant="contained"
                                                  style={{ color: '#eee' }}
                                                  color="primary"
                                                  onClick={() => goToUser(entity)}
                                              >
                                                  Details
                                              </Button>
                                          </TableCell>
                                      )}
                                  </TableRow>
                              ))}
                    </TableBody>

                    <TableFooter>
                        <TablePagination
                            count={filteredUsers.length}
                            page={page}
                            rowsPerPage={10}
                            rowsPerPageOptions={[]}
                            onPageChange={(...[, newPage]) => setPage(newPage)}
                            style={{ borderBottom: 'none' }}
                        />
                    </TableFooter>
                </Table>
            </Paper>
        </div>
    );
};

export default React.memo(compose(withAdminRights)(AdminUsers));
