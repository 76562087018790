import React, { useState, useEffect } from 'react';
import { Row, Container, Card, Form, } from 'react-bootstrap';
import {
    TextField,
    Button,
    Grid,
} from '@material-ui/core';
import './styles.css';
import { useClientUser } from '@/hooks';
import { post } from '@/utilities/onwardClient';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { INVITE_PARTNER_CARRIER } from '@/constants/apiRoutes';
import { useLazyQuery } from '@apollo/client';
import { USER_BY_EMAIL } from '../queries';

const theme = createTheme({
    palette: {
        primary: {
            main: '#71b77a',
        },
    },
});

const oneDay = 24 * 60 * 60 * 1000;
const oneWeek = oneDay * 7;

export default function PartnerCarriers(props) {
    const { user_id, partner_carriers, accountType } = useClientUser();

    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailToInvite, setEmailToInvite] = useState('');

    const [getUserByEmail, { called: userCalled, loading: userLoading, data: userData }] = useLazyQuery(USER_BY_EMAIL);

    const sendEmailInvite = async () => {
        if (!emailToInvite) {
            setSuccessMessage(null);
            setError('You must enter an email.');
            return;
        }

        const email = emailToInvite.trim();

        setLoading(true);

        const userResponse = await getUserByEmail({ variables: { email } });
        if (userResponse?.data?.users?.length > 0) {
            setError('This email is already in use with an Onward account.');
            setLoading(false);
            return;
        }

        return post(INVITE_PARTNER_CARRIER, {
            shipperId: user_id,
            email: email,
        })
            .then(() => {
                setError(null);
                setSuccessMessage(`Your email invite was successfully sent to ${email}`);
            })
            .catch(({ response }) => {
                setSuccessMessage(null);
                setError(`Error sending invite to ${email}. Please contact support for assistance.`);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Card className="tabCard">
            <ThemeProvider theme={theme}>
                <Card.Body className="">
                    <Container fluid>
                        <Form>
                            <Row className="d-flex flex-column mt-3">
                                <h5>Partner Carriers</h5>

                                <div>
                                    Here, you can invite carriers that you like to partner with to the Onward platform.
                                    Once invited, we will then send them a signup link that they may use for their
                                    Onward account to be automatically linked with yours as a Partner Carrier. Partner
                                    Carriers are carriers that your account is linked with so that you may more easily
                                    interact with them across the Onward platform.
                                </div>

                                <h6 className="mt-4">Currently Partnered Carriers:</h6>

                                {partner_carriers?.length ? (
                                    partner_carriers.map((c) => (
                                        <div key={`${c.business_name}-key`} className="mb-1">Carrier Name: {c.business_name}</div>
                                    ))
                                ) : (
                                    <div>None</div>
                                )}

                                <h6 className="mt-4">Invite New Partner Carrier</h6>

                                <Grid
                                    container
                                    style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid item xs={7}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={emailToInvite}
                                            variant="outlined"
                                            label="Enter partner carrier email"
                                            onChange={(e) => setEmailToInvite(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            onClick={sendEmailInvite}
                                            fullWidth
                                            className="my-1 account-btn"
                                            disabled={loading}
                                            style={{ fontWeight: '600' }}
                                        >
                                            {loading ? <CircularProgress color='white' /> : 'Send Email Invite'}
                                        </Button>
                                    </Grid>
                                </Grid>

                                {error && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {error}
                                    </div>
                                )}
                                {successMessage && (
                                    <div
                                        style={{
                                            color: 'green',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {successMessage}
                                    </div>
                                )}
                            </Row>
                        </Form>
                    </Container>
                </Card.Body>
            </ThemeProvider>
        </Card>
    );
}
