import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { OnwardCard } from '../Card';
import HaulawaySingleOrder from './HaulawaySingleOrder';
import HaulawayRoutedOrder from './HaulawayRoutedOrder';

import './style.css';

function Haulaway(props) {
    const order = props.order;

    return (
        <>
            <Typography variant="h6" className="mt-5" color="secondary">
                Haul-away
            </Typography>
            {order.source_form === 'SHIPPER_CREATEROUTE' ? (
                HaulawayRoutedOrder(order)
            ) : order.haulaway_items ? (
                order.haulaway_items?.map((_item) => HaulawaySingleOrder(_item))
            ) : (
                <OnwardCard>
                    <Grid container>
                        <Grid item md={8}>
                            <Typography variant="body2">
                                No haul-away items.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            style={{ textAlign: 'right' }}
                        ></Grid>
                    </Grid>
                </OnwardCard>
            )}
        </>
    );
}

export default Haulaway;
