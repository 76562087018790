import React from 'react';
import { css } from '@emotion/react';
import { Grid, DialogContent, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ResponsiveSidebarDialog } from '@/styles/blocks';

import { local, BodyDescription1 } from '../blocks';

const ExpectationsModal = ({ open, callbacks }) => {
    return (
        <ResponsiveSidebarDialog open={open} onClose={callbacks.onClose} fullWidth={true} maxWidth="sm">
            <DialogContent
                css={css`
                    width: 100%;
                    color: ${local.greys[3]};

                    &.MuiDialogContent-root {
                        padding: 42px;
                        padding-top: 24px;
                    }
                `}
            >
                <Grid
                    container
                    css={css`
                        justify-content: flex-end;
                    `}
                >
                    <Grid item direction="column">
                        <IconButton
                            css={css`
                                padding: 4px;
                            `}
                            onClick={callbacks.onClose}
                        >
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid
                        item
                        css={css`
                            margin-bottom: 4px;
                        `}
                    >
                        <BodyDescription1
                            css={css`
                                font-weight: 700;
                                color: ${local.greys[4]};
                            `}
                        >
                            What to expect after submitting a delivery.
                        </BodyDescription1>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <BodyDescription1>
                            After placing your order, you will receive a text within 24 hours to schedule your delivery
                            date.
                        </BodyDescription1>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <BodyDescription1>
                            Note: Most orders are delivered within 7 days even when the delivery location is over 100
                            miles away.
                        </BodyDescription1>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 4px;
                        `}
                    >
                        <BodyDescription1
                            css={css`
                                font-weight: 700;
                                color: ${local.greys[4]};
                            `}
                        >
                            Day Before Delivery
                        </BodyDescription1>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <BodyDescription1>We will text you with your 4 hour time frame.</BodyDescription1>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 4px;
                        `}
                    >
                        <BodyDescription1
                            css={css`
                                font-weight: 700;
                                color: ${local.greys[4]};
                            `}
                        >
                            Date of Delivery
                        </BodyDescription1>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <BodyDescription1>
                            We will text 30 minutes prior to arriving to your delivery address to notify your of our
                            estimated arrival time.
                        </BodyDescription1>
                    </Grid>
                </Grid>
            </DialogContent>
        </ResponsiveSidebarDialog>
    );
};

export default ExpectationsModal;
