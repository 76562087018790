import React, { useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Button } from '@material-ui/core';
import { ACCEPT_BID } from '@/constants/apiRoutes';
import { post } from '@/utilities/onwardClient';

export default function AwardBidModal({ bid, onClose, open, listing, selectedCounter }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [acceptLoading, setAcceptLoading] = useState(false);

    const handleSubmit = async () => {
        setAcceptLoading(true);
        try {
            await post(ACCEPT_BID, {
                listing_id: listing.listing_id,
                bid_id: bid.bid_id,
                counter_offer_id: selectedCounter ? selectedCounter.counter_offer_id : null,
            });
            onClose();
        } catch (e) {
            setErrorMessage('There was a problem selecting the bid.');
            setAcceptLoading(false);
        }
        setAcceptLoading(false);
    };

    const price = useMemo(() => {
        if (selectedCounter) {
            if (bid.isCarrier) {
                return selectedCounter.carrier_rate.toFixed(2);
            } else return selectedCounter.shipper_rate.toFixed(2);
        } else {
            if (bid.isCarrier) {
                return bid.carrier_rate.toFixed(2);
            } else return bid.shipper_rate.toFixed(2);
        }
    }, [selectedCounter, bid]);

    return (
        <>
            <Dialog
                className="carrier-review-modal"
                open={open}
                onClose={onClose}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                <DialogContent>
                    <Typography className="mb-2">{`Are you sure you want to accept this price ${
                        bid.isCarrier ? 'and claim this order?' : 'and award the order to this carrier?'
                    }`}</Typography>
                    <Typography className="mb-2">You cannot undo this.</Typography>
                    <Typography className="mb-2">{`Price: $${price}`}</Typography>
                    {errorMessage && <Typography className={`text-danger`}>{errorMessage}</Typography>}
                </DialogContent>

                <DialogActions
                    className="customer-track-rating-modal d-flex justify-content-between"
                    style={{ padding: '24px' }}
                >
                    <Button variant="outlined" onClick={onClose} style={{ color: 'black' }}>
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#59B863',
                            color: 'white',
                            marginLeft: '55px',
                            width: '100px',
                        }}
                        disabled={acceptLoading}
                        onClick={handleSubmit}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
