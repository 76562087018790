import { gql } from '@apollo/client';
import { CLIENT_FIELDS } from '../queries/clients';
import { USER_FIELDS } from '../queries/users';

export const UPDATE_FEATURE_FLAGS = gql`
    mutation UpdateFeatureFlags($user_list: [uuid!] = "", $circles: jsonb) {
        update_users(where: { user_id: { _in: $user_list } }, _set: { circles: $circles }) {
            affected_rows
            returning {
                user_id
                email
                circles
                roles
            }
        }
    }
`;

export const INSERT_USER_AND_CLIENT = gql`
    ${USER_FIELDS}
    ${CLIENT_FIELDS}

    mutation InsertUser(
        $user_insert_object: users_insert_input! = []
    ) {
        insert_users(
            objects: 
                [$user_insert_object]
        ) {
            affected_rows
            returning {
                ...UserFields
                user_id
                username
                email
                roles
                created_at
                legacy_user_id
                test_acc
                client {
                    ...ClientFields
                    client_id
                    username
                    email
                    created_at
                    legacy_client_id
                    business_name
                    business_phone
                    approved
                    test_acc
                    payment_type
                }
            }
        }
    }
`;

export const UPDATE_GEOLOCATION = gql`
    mutation UpdateGeolocation($user_id: uuid!, $geolocation: jsonb, $geolocation_expiration: timestamptz) {
        update_users_by_pk(
            pk_columns: { user_id: $user_id }
            _set: { geolocation: $geolocation, geolocation_expiration: $geolocation_expiration }
        ) {
            user_id
            geolocation
            geolocation_expiration
        }
    }
`;

export const UPDATE_CLIENT_COMMS_RANGE = gql`
    mutation UpdateClientCommsRange($client_id: uuid!, $update: clients_set_input = {}) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: $update
        ) {
            client_id
            customer_comms_range_start
            customer_comms_range_end
        }
    }
`;
