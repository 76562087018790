export const LassoOverlay = function ({ onSelect }) {
    this.anchor = {};
    this.listeners = [];
    this.callback = onSelect;
};

const calcRectangle = (a, b) => {
    return {
        left: Math.min(a.x, b.x),
        top: Math.min(a.y, b.y),
        width: Math.abs(a.x - b.x),
        height: Math.abs(a.y - b.y),
    };
};

const overlayBase = (ele) => {
    ele.style.position = 'absolute';
    ele.style.zIndex = 999;
    ele.style.cursor = 'crosshair';

    return ele;
};

const lassoBase = (ele) => {
    ele.style.position = 'absolute';
    ele.style.background = 'rgba(77, 163, 255, 0.4)';
    ele.style.border = '1px solid #4DA3FF';
    ele.style.visibility = 'hidden';

    return ele;
};

const move = (ele, pos) => {
    ele.style.left = `${pos.left}px`;
    ele.style.top = `${pos.top}px`;
    ele.style.width = `${pos.width}px`;
    ele.style.height = `${pos.height}px`;

    return ele;
};

LassoOverlay.prototype = new window.google.maps.OverlayView();

LassoOverlay.prototype.onAdd = function () {
    this.lasso = lassoBase.call(null, document.createElement('div'));
    this.overlay = overlayBase.call(null, document.createElement('div'));
    this.overlay.appendChild(this.lasso);

    window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.overlay);

    [
        [
            'mousedown',
            (e) => {
                const overlay = e.target.getBoundingClientRect();
                this.anchor = { x: e.clientX - overlay.left, y: e.clientY - overlay.top };

                this.lasso.style.visibility = 'visible';
            },
        ],
        [
            'mousemove',
            (e) => {
                if (!this.anchor.x || !this.anchor.y) {
                    return;
                }

                let target = e.target;
                if (e.target !== this.overlay) {
                    target = e.target.parentElement;
                }

                const overlay = target.getBoundingClientRect();

                const x = e.clientX - overlay.left;
                const y = e.clientY - overlay.top;

                const { left, top, width, height } = calcRectangle(this.anchor, { x, y });

                move.call(null, this.lasso, {
                    left,
                    top,
                    width,
                    height,
                });
            },
        ],
        [
            'mouseout',
            (e) => {
                if (!this.anchor.x || !this.anchor.y || e.toElement === this.lasso || e.target === this.lasso) {
                    return;
                }

                const overlay = e.target.getBoundingClientRect();
                const x = e.clientX - overlay.left;
                const y = e.clientY - overlay.top;

                const { left, top, width, height } = calcRectangle(this.anchor, { x, y });

                const TL = new window.google.maps.Point(left, top);
                const BR = new window.google.maps.Point(left + width, top + height);

                const TLCoord = this.projection.fromContainerPixelToLatLng(TL);
                const BRCoord = this.projection.fromContainerPixelToLatLng(BR);

                const bound = new window.google.maps.LatLngBounds();
                [TLCoord, BRCoord].forEach((coord) => bound.extend(coord));

                this.callback(bound);

                this.anchor = {};
                this.lasso.style.visibility = 'hidden';
                move.call(null, this.lasso, {
                    left: 0,
                    top: 0,
                    width: 0,
                    height: 0,
                });
            },
        ],
        [
            'mouseup',
            (e) => {
                let target = e.target;
                if (e.target !== this.overlay) {
                    target = e.target.parentElement;
                }

                const overlay = target.getBoundingClientRect();
                const x = e.clientX - overlay.left;
                const y = e.clientY - overlay.top;

                const { left, top, width, height } = calcRectangle(this.anchor, { x, y });

                const TL = new window.google.maps.Point(left, top);
                const BR = new window.google.maps.Point(left + width, top + height);

                const TLCoord = this.projection.fromContainerPixelToLatLng(TL);
                const BRCoord = this.projection.fromContainerPixelToLatLng(BR);

                const bound = new window.google.maps.LatLngBounds();
                [TLCoord, BRCoord].forEach((coord) => bound.extend(coord));

                this.callback(bound);

                this.anchor = {};
                this.lasso.style.visibility = 'hidden';
                move.call(null, this.lasso, {
                    left: 0,
                    top: 0,
                    width: 0,
                    height: 0,
                });
            },
        ],
    ].forEach(([event, callback]) => {
        this.overlay.addEventListener(event, callback);

        this.listeners.push([event, callback]);
    });

    const panes = this.getPanes();
    panes.overlayMouseTarget.appendChild(this.overlay);
};

LassoOverlay.prototype.draw = function () {
    const projection = this.getProjection();
    const bounds = projection.getVisibleRegion().latLngBounds;

    const sw = projection.fromLatLngToDivPixel(bounds.getSouthWest());
    const ne = projection.fromLatLngToDivPixel(bounds.getNorthEast());

    move.call(null, this.overlay, {
        left: sw.x,
        top: ne.y,
        width: ne.x - sw.x,
        height: sw.y - ne.y,
    });
};

LassoOverlay.prototype.onRemove = function () {
    if (this.overlay) {
        if (this.listeners.length > 0) {
            this.listeners.forEach(([event, callback]) => {
                this.overlay.removeEventListener(event, callback);
            });

            this.listeners = [];
        }

        this.overlay.parentNode.removeChild(this.overlay);

        delete this.overlay;
        delete this.lasso;
    }
};
