import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';

const Metric = ({ label, value }) => {
    const render = [...(label ? [label] : []), value];
    return (
        <Grid
            item
            css={css`
                margin: 2px 0;
            `}
        >
            <div style={{ fontWeight: 500, fontSize: '14px' }}>{render.join(': ')}</div>
        </Grid>
    );
};

const BoldMetric = ({ label, value }) => {
    const render = [...(label ? [label] : []), value];
    return (
        <Grid
            item
            css={css`
                margin: 4px 0;
            `}
        >
            <div style={{ fontWeight: 700, fontSize: '14px' }}>{render.join(': ')}</div>
        </Grid>
    );
};

const SupplyInfoWindow = ({ orders, stop, type }) => {
    return (
        <Grid container direction="column">
            {type === 'load' ? (
                <>
                    <BoldMetric label="External Supply Order" value={orders[0].external_order_id} />
                    <Metric label="External Carrier" value={orders[0].external_carrier_name} />
                    <Metric label="Pick Up Address" value={orders[0].pickup_address} />
                    <Metric
                        label="Pick Up Details"
                        value={
                            (orders[0].dropoff_location || 'n/a') + ' - ' + (orders[0].dropoff_location_info || 'n/a')
                        }
                    />
                    <Metric label="Drop Off Address" value={orders[0].dropoff_address} />
                    <Metric
                        label="Drop Off Details"
                        value={
                            (orders[0].dropoff_location || 'n/a') + ' - ' + (orders[0].dropoff_location_info || 'n/a')
                        }
                    />
                </>
            ) : (
                <>
                    <BoldMetric label="External Route Stop" />
                    <Metric label="Route Id" value={stop.route_id} />
                    <Metric label="Type" value={stop.type} />
                    <Metric label="Stop Ordering" value={stop.ordering} />
                    <Metric label="Volume" value={stop.cubes || 'n/a'} />
                    <Metric label="Weight" value={stop.weight || 'n/a'} />
                    <Metric label="Address" value={stop.address} />
                </>
            )}
        </Grid>
    );
};

export default SupplyInfoWindow;
