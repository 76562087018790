import { useClientUser } from '@/hooks';
import React, { useContext, useState } from 'react';
import { compose } from 'recompose';
import { useNavigate } from 'react-router-dom';

import { css } from '@emotion/react';
import { TextField, Grid, InputAdornment, LinearProgress } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { Search } from '@material-ui/icons';
import { ACCOUNTING_V2 } from '@/constants/featureFlags';
import * as ROUTES from '@/constants/routes';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import Table from './Table';
import Footer from './Footer';
import { H1 } from './blocks';
import { Context, ContextProvider } from './store';

export const Invoices = ({ context, title, Footer }) => {
    const { state, loading, callbacks } = useContext(context);
    const navigate = useNavigate();
    const { circles, roles } = useClientUser();
    const enabled = !!circles?.[ACCOUNTING_V2] || roles['ADMIN'];

    return (
        <Grid
            direction="column"
            container
            css={css`
                width: 100%;
                height: 100%;
            `}
        >
            {state?.filters?.length > 0 ? (
                <Grid
                    direction="row"
                    item
                    css={css`
                        display: flex;
                        justify-content: center;
                        background-color: white;
                    `}
                >
                    <OnwardTabContainer value={0} textColor="primary" indicatorColor="primary" centered>
                        <OnwardTab
                            label={
                                !enabled ? (
                                    <Grid
                                        direction="row"
                                        container
                                        css={css`
                                            justify-content: center;
                                        `}
                                    >
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex-basis: 0;
                                            `}
                                        >
                                            <LockIcon
                                                css={css`
                                                    font-size: 20px;
                                                    justify-content: center;

                                                    margin-right: 8px;
                                                `}
                                            />
                                        </Grid>
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex-basis: 0;
                                            `}
                                        >
                                            Internal
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <>Internal</>
                                )
                            }
                            disabled={!enabled}
                        />
                        <OnwardTab
                            label="Onward"
                            onClick={() => {
                                navigate(ROUTES.CARRIER_ACCOUNTING_V2);
                            }}
                        />
                    </OnwardTabContainer>
                </Grid>
            ) : null}
            <Grid
                container
                item
                direction="row"
                css={css`
                    padding: 40px;
                    padding-bottom: 0;
                    margin-bottom: 12px;
                `}
            >
                <Grid
                    direction="column"
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <H1>{title}</H1>
                </Grid>
                <Grid
                    direction="column"
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <TextField
                        value={state.search}
                        placeholder="Search..."
                        onChange={callbacks.setSearch}
                        variant="outlined"
                        color="primary"
                        size="medium"
                        css={css`
                            width: 100%;
                        `}
                        InputProps={{
                            style: { backgroundColor: 'white' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>

            {loading.init && (
                <Grid
                    item
                    css={css`
                        margin: 0 2rem;
                    `}
                >
                    <LinearProgress
                        color="primary"
                        css={css`
                            width: 100%;
                        `}
                    />
                </Grid>
            )}

            <Grid
                direction="row"
                item
                css={css`
                    display: flex;
                    justify-content: center;
                    background-color: white;
                    margin: 0 40px;
                    justify-content: flex-start;
                `}
            >
                <OnwardTabContainer
                    value={state.status}
                    onChange={(e, t) => {
                        callbacks.setStatus(t);
                        callbacks.setSelected({});
                    }}
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                >
                    {state.tabs.map((tab) => (
                        <OnwardTab key={tab.value} label={tab.label} value={tab.value} style={{ outline: 0 }} />
                    ))}
                </OnwardTabContainer>
            </Grid>
            <Grid
                item
                css={css`
                    overflow: hidden;
                    flex-grow: 1;
                    padding: 0 40px;
                `}
            >
                <Table state={state} callbacks={callbacks} />
            </Grid>

            <Footer state={state} callbacks={callbacks} />
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} title="Invoices" Footer={Footer} />
        </ContextProvider>
    );

export default compose(withContext)(Invoices);
