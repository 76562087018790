import React from 'react';
import { css } from '@emotion/react';
import { Grid, DialogContent, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { colors } from '@/styles';
import { ResponsiveSidebarDialog } from '@/styles/blocks';
import { toNational, toE164 } from '@/utilities/formatPhoneNumber';

import { SUPPORT_PHONE_NUMBER } from '../constants';
import { BodyDescription1, local } from '../blocks';

const InfoModal = ({ open, callbacks }) => {
    return (
        <ResponsiveSidebarDialog open={open} onClose={callbacks.onClose} fullWidth={true} maxWidth="sm">
            <DialogContent
                css={css`
                    width: 100%;
                    color: ${local.greys[3]};

                    &.MuiDialogContent-root {
                        padding: 42px;
                        padding-top: 24px;
                    }
                `}
            >
                <Grid
                    container
                    css={css`
                        justify-content: flex-end;
                    `}
                >
                    <Grid item direction="column">
                        <IconButton
                            css={css`
                                padding: 4px;
                            `}
                            onClick={callbacks.onClose}
                        >
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid
                        item
                        css={css`
                            margin-bottom: 24px;
                        `}
                    >
                        <BodyDescription1>Questions? Contact us at:</BodyDescription1>
                    </Grid>
                    <Grid item>
                        <a
                            href={`tel:${toE164(SUPPORT_PHONE_NUMBER)}`}
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            <BodyDescription1>{toNational(SUPPORT_PHONE_NUMBER)}</BodyDescription1>
                        </a>
                    </Grid>
                </Grid>
            </DialogContent>
        </ResponsiveSidebarDialog>
    );
};

export default InfoModal;
