import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { LocationPermissionsTitle, LocationPermissionsTitleSpan, CardSubtext } from './blocks';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { UPDATE_CLIENT_COMMS_RANGE } from '@/graphql/mutations/users';
import { Grid } from '@material-ui/core';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PrimaryButton } from '@/styles/blocks';
import * as Sentry from '@sentry/react';
import Snackbar from '../Snackbar';

export default function CommunicationsRange({ client }) {
    const [rangeStart, setRangeStart] = useState();
    const [rangeEnd, setRangeEnd] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (Number.isInteger(client?.customer_comms_range_start)) {
            let _date = new Date();
            _date.setHours(client?.customer_comms_range_start);
            setRangeStart(_date);
        }
        if (Number.isInteger(client?.customer_comms_range_end)) {
            let _date = new Date();
            _date.setHours(client?.customer_comms_range_end);
            setRangeEnd(_date);
        }
    }, [client]);

    const [updateClient, { loading }] = useMutation(UPDATE_CLIENT_COMMS_RANGE, {
        onError: (e) => {
            console.error(e);
            setErrorMessage('There was an error when attempting to save.')
            Sentry.captureException(e)
        },
        onCompleted: () => {
            setSuccessMessage('Customer communications range successfully saved.')
        }
    });

    const handleSave = async () => {
        if (!rangeStart || !rangeEnd) {
            setErrorMessage('Both a start time and an end time are required to save the customer communications range.')
            return;
        }
        if (rangeStart >= rangeEnd) {
            setErrorMessage('The range end must be after the start range, or no comms will send.')
            return;
        }
        
        await updateClient({
            variables: {
                client_id: client.client_id,
                update: {
                    customer_comms_range_start: rangeStart?.getHours() || null,
                    customer_comms_range_end: rangeEnd?.getHours() || null,
                },
            }
        })
    }

    return (
        <>
            <Card
                css={css`
                    margin-top: 30px;
                `}
            >
                <Card.Body
                    css={css`
                        padding: 28px 26px 28px 26px;
                    `}
                >
                    <LocationPermissionsTitle>
                        <LocationPermissionsTitleSpan>Communications</LocationPermissionsTitleSpan>
                    </LocationPermissionsTitle>
                    <Grid
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 30px;
                        `}
                    >
                        <Grid item container>
                            <Grid item>
                                <CardSubtext>
                                    Certain automated onward communications to customers will be scheduled to only send within the provided range.
                                </CardSubtext>
                            </Grid>
                            <Grid item>
                                <CardSubtext>
                                    If a range is not set, then the default range of 8:00am to 8:00pm will be used instead.
                                </CardSubtext>
                            </Grid>
                        </Grid>

                        <Grid item container>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid item css={css`
                                        width: 150px;
                                    `}>
                                    <DesktopTimePicker
                                        label="Range Start"
                                        value={rangeStart}
                                        onChange={(newValue) => setRangeStart(newValue)}
                                        views={['hours']}
                                        slotProps={{
                                            actionBar: {
                                                actions: ['clear', 'accept'],
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    css={css`
                                        margin-left: 50px;
                                        width: 150px;
                                    `}
                                >
                                    <DesktopTimePicker
                                        label="Range End"
                                        value={rangeEnd}
                                        onChange={(newValue) => setRangeEnd(newValue)}
                                        views={['hours']}
                                        slotProps={{
                                            actionBar: {
                                                actions: ['cancel', 'accept'],
                                            },
                                        }}
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item>
                            <PrimaryButton
                                disabled={loading}
                                onClick={handleSave}
                            >
                                Save
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>

            <Snackbar
                open={!!successMessage}
                handleClose={() => setSuccessMessage('')}
                severity="success"
                message={successMessage}
            />

            <Snackbar
                open={!!errorMessage}
                handleClose={() => setErrorMessage('')}
                severity="error"
                message={errorMessage}
            />
        </>
    );
}
