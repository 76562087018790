import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';

const uploadPhotoAsync = async (uri, type, file_type) => {
    const path = `orders/${type}/${v4()}.${file_type}`;

    const response = await fetch(uri);
    const blob = await response.blob();

    const storageRef = ref(getStorage(), path);
    const uploadTask = uploadBytesResumable(storageRef, blob);

    const downloadUrl = await uploadTask
        .then((snapshot) => {
            if (snapshot.state === 'success') {
                return getDownloadURL(uploadTask.snapshot.ref);
            }
        })
        .then((downloadUrl) => downloadUrl)
        .catch((err) => console.error(err));

    return downloadUrl;
};

export default uploadPhotoAsync;
