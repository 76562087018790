export default {
    PLANNING: {
        value: 'planning',
        display: 'Planning',
    },
    PENDING: {
        value: 'pending',
        display: 'Pending',
    },
    OPEN: {
        value: 'open',
        display: 'Open',
    },
    ACTIVE: {
        value: 'active',
        display: 'Active',
    },
    INPROGRESS: {
        value: 'inProgress',
        display: 'In Progress',
    },
    COMPLETE: {
        value: 'complete',
        display: 'Complete',
    },
};
