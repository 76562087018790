import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useState } from 'react';
import { css } from '@emotion/react';

const DebouncedSearch = ({ onChange, ...props }) => {
    const [input, setInput] = useState('');
    const [debouncer, setDebouncer] = useState(null);

    const handleSearch = (e) => {
        const value = e.target.value;
        setInput(value);
        if (debouncer) {
            clearTimeout(debouncer);
        }
        setDebouncer(
            setTimeout(() => {
                onChange(value);
            }, 300)
        );
    };

    return (
        <TextField
            value={input}
            placeholder={props.placeholder || 'Search ...'}
            onChange={handleSearch}
            variant="outlined"
            color="primary"
            size="small"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }}
            css={css`
                background-color: white;
            `}
            {...props}
        />
    );
};

export default DebouncedSearch;
