import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    IconButton,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { colors } from '@/styles';
import { INSERT_BID_COMMENT } from '@/graphql/mutations/bid_comments';
import { useClientUser } from '@/hooks';

const useStyles = makeStyles({
    modalTitle: {
        color: colors.greys.secondary,
        fontWeight: '800',
        fontSize: '20px',
    },
    dialogContent: {
        width: 450,
    },
    modalLabel: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        color: '#2B2B2B',
        alignSelf: 'center',
        marginLeft: 20,
    },
});

export default function NewCommentModal(props) {
    const { open, onClose, bidDetails } = props;
    const {user_id} = useClientUser();
    const [comment, setComment] = useState('');

    useEffect(() => {
        setComment('');
    }, [open]);

    const classes = useStyles();

    const [insertComment, { loading }] = useMutation(INSERT_BID_COMMENT);

    const handleSubmit = async () => {
        try {
            await insertComment({
                variables: {
                    client_id: user_id,
                    bid_id: bidDetails.bid_id,
                    comment_text: comment,
                },
            });
            onClose();
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
        }
    };

    return (
        <>
            <Dialog
                className="carrier-review-modal"
                open={open}
                onClose={onClose}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            color: 'black',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
                <DialogTitle style={{ margin: 0, padding: '16px 24px 0 24px' }}>
                    <Typography variant="h6" className={classes.modalTitle}>
                        New Comment
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Comments"
                        multiline
                        minRows={4}
                        fullWidth
                        style={{
                            marginTop: '24px',
                        }}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </DialogContent>
                <DialogActions
                    className="customer-track-rating-modal d-flex justify-content-between"
                    style={{ padding: '24px' }}
                >
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        style={{
                            minWidth: '110px',
                        }}
                        disabled={loading}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#59B863',
                            color: 'white',
                            minWidth: '110px',
                        }}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
