import DebouncedSearch from '@/components/DebouncedSearch';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import React, { useContext, useMemo, useState } from 'react';
import { Context } from '../store';
import {
    Dot,
    LegendContainer,
    Sidebar,
    SidebarItem,
    SidebarLabel,
    SidebarScrollable,
    SidebarScrollableContainer,
} from './blocks';
import { css } from '@emotion/react';
import LoadingCard from '../cards/LoadingCard';
import TextCard from '../cards/TextCard';
import SupplyOrderCard from '../cards/SupplyOrderCard';
import SupplyRouteCard from '../cards/SupplyRouteCard';
import { MARKER_COLORS } from '../constants';
import { ArrowRightAlt } from '@material-ui/icons';
import OnwardRouteCard from '../cards/OnwardRouteCard';

const ORDER_SEARCHABLE = [
    'dropoff_city',
    'pickup_city',
    'external_carrier_name',
    'external_carrier_id',
    'external_order_id',
    'order_id',
];

const ROUTE_SEARCHABLE = ['route_number', 'route_id'];

const SupplySidebar = () => {
    const { state, callbacks } = useContext(Context);

    const [tab, setTab] = useState(0);

    const [search, setSearch] = useState('');

    const filteredOrders = useMemo(() => {
        return state.supplyOrders.filter(
            (order) => !search || ORDER_SEARCHABLE.some((field) => order[field]?.search(search) >= 0)
        );
    }, [search, state.supplyOrders]);

    const filteredRoutes = useMemo(() => {
        return [...state.supplyRoutes, ...state.onwardRoutes].filter(
            (route) => !search || ROUTE_SEARCHABLE.some((field) => route[field]?.toString()?.search(search) >= 0)
        );
    }, [search, state.supplyRoutes, state.onwardRoutes]);

    const content = useMemo(() => {
        switch (tab) {
            case 0:
                if (state.selectedOrder?.__typename !== 'orders') {
                    return <TextCard text={`Select a marketplace order to see nearby routes`} />;
                }
                if (state.supplyRoutesLoading || state.onwardRoutesLoading) {
                    return <LoadingCard />;
                }
                if (filteredRoutes.length === 0) {
                    return (
                        <TextCard
                            text={`No routes scheduled for delivery on ${state.deliveryDateFormatted} nearby ${state.selectedOrder.order_number}`}
                        />
                    );
                }
                return filteredRoutes.map((route) =>
                    route.__typename === 'routes' ? (
                        <OnwardRouteCard route={route} key={route.route_id} />
                    ) : (
                        <SupplyRouteCard route={route} key={route.route_id} />
                    )
                );
            case 1:
                if (state.supplyOrdersLoading) {
                    return <LoadingCard />;
                }
                if (filteredOrders.length === 0) {
                    return (
                        <TextCard
                            text={`No supply activity orders scheduled for delivery on ${state.deliveryDateFormatted}`}
                        />
                    );
                }
                return filteredOrders.map((order) => <SupplyOrderCard order={order} key={order.order_id} />);
            default:
                return null;
        }
    }, [
        tab,
        state.selectedOrder,
        state.supplyOrdersLoading,
        filteredOrders,
        state.supplyRoutesLoading,
        filteredRoutes,
    ]);

    return (
        <Sidebar>
            <SidebarItem>
                <SidebarLabel>Supply Activity</SidebarLabel>
                <LegendContainer>
                    <Dot color={MARKER_COLORS.SUPPLY_PICKUP} />
                    <ArrowRightAlt />
                    <Dot color={MARKER_COLORS.SUPPLY_DROPOFF} />
                </LegendContainer>
            </SidebarItem>
            <SidebarItem>
                <DebouncedSearch onChange={(value) => setSearch(value)} />
            </SidebarItem>
            <SidebarItem
                css={css`
                    padding: 0;
                `}
            >
                <OnwardTabContainer
                    value={tab}
                    onChange={(_, tab) => setTab(tab)}
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                    css={css`
                        width: 100%;
                    `}
                >
                    <OnwardTab label="Routes" value={0} />
                    <OnwardTab label="Orders" value={1} />
                </OnwardTabContainer>
            </SidebarItem>
            <SidebarScrollableContainer>
                <SidebarScrollable>{content}</SidebarScrollable>
            </SidebarScrollableContainer>
        </Sidebar>
    );
};

export default SupplySidebar;
