import React from 'react';

import { TableCell } from '@material-ui/core';
import { Body2 } from '../blocks';

export default function HeaderCell({ cell, ...props }) {
    return (
        <TableCell align="center" {...props}>
            <Body2>{cell.render('Header')}</Body2>
        </TableCell>
    );
}
