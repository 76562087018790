import { colors } from '@/styles';
import { CircularProgress, Grid, MenuItem, TextField } from '@material-ui/core';
import React, { useContext } from 'react';
import { compose } from 'recompose';
import { Context, withContext } from './store';
import { css } from '@emotion/react';
import { OnwardTab, OnwardTabContainer } from '../Tabs';
import { PrimaryButton } from '@/styles/blocks';
import { useClientUser } from '@/hooks';
import { PrimaryHeader } from '@/styles/typography';
import SearchInput from '../misc/SearchInput';
import { TABS } from './constants';
import Table from '../Table';
import CreateManifest from './modals/CreateManifest';
import Snackbar from '../Snackbar';
import { useNavigate } from 'react-router';
import { MANIFEST_STATUS_LABELS, MANIFEST_TYPE_LABELS } from '@/constants/manifestStatuses';

const Manifests = () => {
    const { state, callbacks, loading } = useContext(Context);
    const { user_id } = useClientUser();
    const navigate = useNavigate();

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
                flex-wrap: nowrap;
            `}
        >
            <Snackbar
                open={!!state.notification.message}
                {...state.notification}
                handleClose={callbacks.clearNotification}
            />
            <CreateManifest />
            <Grid
                container
                direction="row"
                css={css`
                    padding: 0rem 1rem;
                    border-bottom: 1px solid ${colors.greys.border};
                    background: ${colors.white.primary};
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <Grid container xs={3} />
                <Grid container xs={6} justifyContent="center">
                    <OnwardTabContainer
                        value={state.tab.value}
                        onChange={(_, tab) => {
                            callbacks.setTab(TABS[tab]);
                            callbacks.setFilters(TABS[tab]?.defaultFilters || {});
                        }}
                    >
                        {Object.values(TABS).map((tab) => (
                            <OnwardTab key={`manifest-tab-${tab.value}`} label={tab.label} value={tab.value} />
                        ))}
                    </OnwardTabContainer>
                </Grid>
                <Grid container xs={3} justifyContent="flex-end">
                    <PrimaryButton
                        onClick={() =>
                            callbacks.setNewManifest({
                                warehouse_id: (
                                    (state.warehouses || []).find((location) => location.preferred_inbound) ||
                                    state.warehouses?.[0]
                                )?.location_id,
                                receiving_date: new Date().toISOString(),
                                ...(state.tab.typeOptions?.length === 1
                                    ? {
                                          type: state.tab.typeOptions[0],
                                      }
                                    : {}),
                            })
                        }
                    >
                        Create Manifest
                    </PrimaryButton>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    padding: 2rem 1rem 2rem 2rem;
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <Grid container xs={3}>
                    <PrimaryHeader>Manifests</PrimaryHeader>
                </Grid>
                <Grid container xs={9} justifyContent="flex-end">
                    <TextField
                        select
                        variant="outlined"
                        label="Status"
                        size="small"
                        value={state.filters.status || 'ALL'}
                        onChange={(e) => {
                            const val = e.target.value !== 'ALL' ? e.target.value : null;
                            callbacks.setFilters((prev) => ({ ...prev, status: val }));
                        }}
                        css={css`
                            width: 200px;
                            background-color: white;
                            margin-right: 1rem;
                        `}
                    >
                        <MenuItem key={'ALL'} value={'ALL'}>
                            All
                        </MenuItem>
                        {state.tab.statusOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {MANIFEST_STATUS_LABELS[option]?.label || option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        variant="outlined"
                        label="Type"
                        size="small"
                        value={state.filters.type || 'ALL'}
                        onChange={(e) => {
                            const val = e.target.value !== 'ALL' ? e.target.value : null;
                            callbacks.setFilters((prev) => ({ ...prev, type: val }));
                        }}
                        css={css`
                            width: 200px;
                            background-color: white;
                            margin-right: 1rem;
                        `}
                    >
                        <MenuItem key={'ALL'} value={'ALL'}>
                            All
                        </MenuItem>
                        {state.tab.typeOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {MANIFEST_TYPE_LABELS[option] || option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <SearchInput
                        placeholder="Search Manifests"
                        onChange={(search) => callbacks.setSearchManifests(search)}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                css={css`
                    padding: 0rem 1rem 2rem 2rem;
                    flex-grow: 1;
                    justify-content: center;
                    align-items: flex-start;
                `}
            >
                {loading.queryLoading ? (
                    <Grid
                        item
                        css={css`
                            margin-top: 2rem;
                        `}
                    >
                        <CircularProgress color="primary" size="200px" />
                    </Grid>
                ) : (
                    <Table
                        data={state.manifests}
                        columns={state.tab.columns}
                        callbacks={{
                            getRowId: (manifest) => manifest.manifest_id,
                            rowOnClick: (e, manifest) => {
                                const href = `/manifests/${manifest.manifest_id}`;
                                e.preventDefault();
                                if (e.ctrlKey) {
                                    window.open(href, '_blank');
                                } else {
                                    navigate(href);
                                }
                            },
                        }}
                        options={{
                            select: false,
                            sortAsync: false,
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default compose(withContext)(Manifests);
