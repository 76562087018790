import React from 'react';
import Notification from '../../Notification';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate, Link } from 'react-router-dom';

const NotificationMetadata = styled.span`
    margin-top: 10px;
    font-family: Montserrat, Roboto, Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0571429px;
    margin-bottom: 0px;
    color: #2b2b2b;
`;

export default function ExceptionNotification({ notification, handleCloseNotification }) {
    return (
        <Notification notification={notification}>
            <NotificationMetadata
                css={css`
                    margin-top: 0px;
                    font-weight: 700;
                `}
            >
                {notification.notification_data.exception_level === 'item'
                    ? 'Exception Reported For One or More Items'
                    : 'Order Exception Reported'}
            </NotificationMetadata>
            <NotificationMetadata>Order: {notification?.notification_data?.order_number}</NotificationMetadata>
            <NotificationMetadata>
                <Link style={{ color: '#59b863' }} to={`/order/${notification?.notification_data?.order_id}`}>
                    See Details
                </Link>{' '}
            </NotificationMetadata>
            <NotificationMetadata
                css={css`
                    color: grey;
                `}
            >
                {format(new Date(notification.created_at), 'MMM dd, yyyy @ hh:mm a')}
            </NotificationMetadata>
        </Notification>
    );
}
