import { useEffect, useRef } from 'react';

function Source({ map, name, type = 'vector', ...props }) {
    useEffect(() => {
        if (map) {
            map.on('styledata', () => {
                if (!map.getSource(name)) {
                    map.addSource(name, {
                        ...props,
                        type: 'vector',
                        promoteId: 'mapbox_id',
                    });
                }
            });
        }
    }, [map]);

    return null;
}

export default Source;
