import React, { useMemo } from 'react';
const { setDay, setWeek, startOfWeek, endOfWeek } = require('date-fns');
import { startCase } from 'lodash';
import { colors } from '@/styles';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { CheckCircleOutline, HelpOutline, HighlightOffOutlined, SyncOutlined } from '@material-ui/icons';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@material-ui/icons/Close';
import { asBrowserDate } from '@/utilities/convertToISO';
import { BodyText } from '@/components/CarrierAccountingOrders/blocks';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';
import { calcOrderInvoiceTotals } from '@/utilities/calcOrderInvoiceTotals';
const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const COLUMNS = [
    {
        Header: 'Data',
        id: 'data-columns',
        columns: [
            {
                Header: 'Invoice',
                id: 'invoice_number',
                accessor: (invoice) => {
                    if (!invoice.is_pending) {
                        return invoice.invoice_number;
                    }

                    return invoice.this_week ? 'Current' : 'Pending';
                },
            },
            {
                Header: 'Week',
                id: 'week_number',
                accessor: (invoice) => invoice.week_number,
            },
            {
                Header: 'Pay Period',
                id: 'period',
                span: false,
                accessor: (invoice) => {
                    let created = new Date(new Date().setHours(0, 0, 0, 0));
                    if (invoice.created_at) {
                        created = new Date(new Date(invoice.created_at).setHours(0, 0, 0, 0));
                    }
                    const week = setWeek(created, invoice.week_number, { weekStartsOn: 1 });
                    const sow = startOfWeek(week, { weekStartsOn: 1 });
                    const eow = endOfWeek(week, { weekStartsOn: 1 });

                    return `${dateNumeric.format(sow)} - ${dateNumeric.format(eow)}`;
                },
                Cell: ({ value }) => {
                    return (
                        <div
                            css={css`
                                overflow: hidden;
                                height: 100%;
                            `}
                        >
                            <BodyText
                                css={css`
                                    white-space: normal;
                                `}
                            >
                                {value}
                            </BodyText>
                        </div>
                    );
                },
            },
            {
                Header: 'Edits Due',
                id: 'edits_due',
                span: false,
                accessor: (invoice) => {
                    let created = new Date(new Date().setHours(0, 0, 0, 0));
                    if (invoice.created_at) {
                        created = new Date(new Date(invoice.created_at).setHours(0, 0, 0, 0));
                    }
                    const due = setWeek(created, invoice.week_number + 1, { weekStartsOn: 1 });
                    const thurs = setDay(due, 4);

                    if (!invoice.is_pending) {
                        return `${dateNumeric.format(new Date(thurs))} (submitted)`;
                    }

                    return dateNumeric.format(thurs);
                },
                Cell: ({ value }) => {
                    return (
                        <div
                            css={css`
                                overflow: hidden;
                                height: 100%;
                            `}
                        >
                            <BodyText
                                css={css`
                                    white-space: normal;
                                `}
                            >
                                {value}
                            </BodyText>
                        </div>
                    );
                },
            },
            {
                Header: 'Due',
                id: 'due_date',
                width: 150,
                disableSortBy: false,
                Cell: ({ row }) => {
                    const invoice = row.original;
                    return invoice.due_date ? dateNumeric.format(asBrowserDate(invoice.due_date)) : '--';
                },
            },
            {
                Header: 'POD',
                id: 'pod',
                width: 110,
                Cell: ({ row }) => {
                    const invoice = row.original;
                    const isMissing = !invoice.orders.every((order) => {
                        const hasPhoto = order.delivery_photo?.length >= 1 || order.pod?.length >= 1;
                        const hasSignature = order.customer_signature?.length >= 1;
                        return hasSignature || hasPhoto;
                    });

                    return !isMissing ? (
                        <>
                            <DoneIcon
                                css={css`
                                    color: ${colors.greens.primary};
                                `}
                            />
                        </>
                    ) : (
                        <CloseIcon
                            css={css`
                                color: ${colors.reds.primary};
                                background: none;
                            `}
                        />
                    );
                },
            },
            {
                Header: 'Status',
                id: 'status',
                width: 150,
                span: false,
                disableSortBy: false,
                Cell: ({ row }) => {
                    const invoice = row.original;
                    const label = startCase(invoice.status.toLowerCase());
                    let Icon = HelpOutline;
                    let color = 'black';
                    switch (invoice.status) {
                        case 'PAID':
                            color = colors.greens.primary;
                            Icon = CheckCircleOutline;
                            break;
                        case 'APPROVED':
                            color = colors.greens.primary;
                            Icon = CheckCircleOutline;
                            break;
                        case 'UNPAID':
                        case 'CANCELLED':
                            color = colors.reds.cancelled;
                            Icon = HighlightOffOutlined;
                            break;
                        case 'PROCESSING':
                            color = colors.oranges.primary;
                            Icon = SyncOutlined;
                            break;
                        default:
                            color = 'black';
                            Icon = HelpOutline;
                            break;
                    }

                    return (
                        <Grid
                            container
                            direction="row"
                            css={css`
                                color: ${color};
                                height: 100%;
                                align-items: center;
                                flex-wrap: nowrap;
                            `}
                        >
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    margin-right: 4px;
                                `}
                            >
                                <Icon />
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                `}
                            >
                                <BodyText>{label}</BodyText>
                            </Grid>
                        </Grid>
                    );
                },
            },
            {
                Header: 'Subtotal',
                id: 'subtotal',
                width: 125,
                canSort: false,
                Cell: ({ row }) => {
                    const invoice = row.original;
                    const amount = (invoice.orders || []).reduce((acc, order) => {
                        const breakdown = 'carrierBreakdown';

                        const subTotalAdj = calcOrderSubtotal(order);
                        return acc + subTotalAdj;
                    }, 0);
                    return dollarFormatter.format(amount);
                },
            },
            {
                Header: 'Accessorials',
                id: 'accessorials',
                width: 125,
                canSort: false,
                Cell: ({ row }) => {
                    const invoice = row.original;
                    const amount = (invoice.orders || []).reduce((acc, order) => {
                        const breakdown = 'carrierBreakdown';

                        const total = (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce(
                            (acc, { quantity, rate }) => {
                                return acc + quantity * rate;
                            },
                            0
                        );

                        return acc + total;
                    }, 0);

                    return dollarFormatter.format(amount);
                },
            },
            {
                Header: 'Total Due',
                id: 'revenue',
                width: 125,
                canSort: false,
                Cell: ({ row }) => {
                    const invoice = row.original;
                    const amount = (invoice.orders || []).reduce((acc, order) => {
                        const total = calcOrderInvoiceTotals(order);
                        return acc + total;
                    }, 0);
                    return dollarFormatter.format(amount);
                },
            },
        ],
    },
];
