import React, { useState, useEffect } from 'react';
import { getAuth, updateEmail } from 'firebase/auth';
import { Row, Button, Container, Card, Form, Col, Modal } from 'react-bootstrap';
import { TextField, Select, FormControl, InputLabel } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import states from './constants/states';
import * as Sentry from '@sentry/react';
import LocationPermissions from '../LocationPermissions';
import Communications from '../Communications';
import DataSharing from '../DataSharing';
import { UPDATE_CARRIER_CLIENT_INFO, UPDATE_USER_EMAIL } from '../mutations';
import { useMutation } from '@apollo/client';
import { toNational, toE164, validate } from '@/utilities/formatPhoneNumber';
import FleetSizeInput from '../FleetSizeInput';

const userComms = [
    {
        featureFlag: 'disable-customer-availability',
        display: 'Disable Predelivery Survey and Customer Availability',
        description: 'Disables the SMS asking users basic predelivery and customer availability information',
    },
    {
        featureFlag: 'delay-predelivery-comms',
        display: 'Delay Predelivery SMS until warehouse receipt',
        description: 'Dont send predelivery survey until the carrier has received the order',
    },
    {
        featureFlag: 'business-type-autoconfirm',
        display: 'Auto confirm timeframes for business type deliveries',
        description: 'Automatically confirms stop timeframes in planning for business type deliveries.',
    },
    {
        featureFlag: 'hide-driver-phone-number',
        display: 'Hide driver phone number on customer tracking links',
        description: 'On the customer tracking link, do not show the driver phone number.',
    },
];

function AccountDetails(props) {
    const [validated, setValidated] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [primaryName, setPrimaryName] = useState('');
    const [primaryEmail, setPrimaryEmail] = useState('');
    const [primaryPhone, setPrimaryPhone] = useState('');
    const [supportPhone, setSupportPhone] = useState('');
    const [updateMessage, setUpdateMessage] = useState('');
    const [billingState, setBillingState] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [billingZip, setBillingZip] = useState('');
    const [emailModal, setEmailModal] = useState(false);
    const [mcNumber, setMcNumber] = useState('');
    const [einNumber, setEinNumber] = useState('');
    const [dotNumber, setDotNumber] = useState('');
    const [supportEmail, setSupportEmail] = useState('');
    const [fleetSize, setFleetSize] = useState(null);

    const [updateInfo] = useMutation(UPDATE_CARRIER_CLIENT_INFO, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const [updateUserEmail] = useMutation(UPDATE_USER_EMAIL, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const EmailModal = (props) => {
        const { show, onHide, adminLoggedIn } = props;

        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header>Email Change</Modal.Header>
                <Modal.Body>
                    Please be aware that changing your primary contact email will also change the login email for this
                    account.
                    <div className="text-danger">
                        {props.adminLoggedIn ? 'Super admins cannot change account emails at this time.' : ''}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            let userUpdatePayload = {
                username: primaryName,
                email: props.adminLoggedIn ? props.currentCarrier.email : primaryEmail,
                business_phone: validate(primaryPhone) ? toE164(primaryPhone) : primaryPhone,
                support_phone: validate(supportPhone) ? toE164(supportPhone) : supportPhone,
                support_email: supportEmail,
                business_name: businessName,
                billing_city: billingCity,
                billing_zip: billingZip,
                billing_state: billingState,
                billing_address: billingAddress,
                mc_num: mcNumber.toString(),
                business_ein: einNumber.toString(),
                dot_number: dotNumber.toString(),
                ...(Number.isInteger(fleetSize) ? { fleet_size: fleetSize } : {}),
            };

            try {
                await updateInfo({
                    variables: {
                        client_id: props.currentCarrierRef,
                        ...userUpdatePayload,
                    },
                });

                if (!props.adminLoggedIn && primaryEmail !== props.currentCarrier.email) {
                    // Update firebase email
                    const auth = getAuth();
                    const user = auth.currentUser;

                    await updateEmail(user, primaryEmail);

                    // Updates the email in the user table
                    await updateUserEmail({
                        variables: {
                            user_id: props.currentCarrierRef,
                            email: primaryEmail,
                        },
                    });
                }

                setUpdateMessage('Profile updated!');
            } catch (error) {
                console.log({ error });
                Sentry.captureException(error);
                setUpdateMessage(error);
            }
        } else {
            setUpdateMessage('Please fill out required fields');
        }
        setValidated(true);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    useEffect(() => {
        setPrimaryName(props.currentCarrier.username);
        setPrimaryEmail(props.currentCarrier.email);
        setBusinessName(props.currentCarrier.business_name);
        setBillingCity(props.currentCarrier.billing_city);
        setBillingAddress(props.currentCarrier.billing_address);
        setBillingZip(props.currentCarrier.billing_zip);
        setMcNumber(props.currentCarrier.mc_num);
        setEinNumber(props.currentCarrier.business_ein);
        setDotNumber(props.currentCarrier.dot_number);
        setSupportEmail(props.currentCarrier.support_email || '');
        setFleetSize(props.currentCarrier.fleet_size);

        const _primaryPhone = props.currentCarrier.business_phone
            ? validate(props.currentCarrier.business_phone)
                ? toNational(props.currentCarrier.business_phone)
                : props.currentCarrier.business_phone
            : '';
        const _supportPhone = props.currentCarrier.support_phone
            ? validate(props.currentCarrier.support_phone)
                ? toNational(props.currentCarrier.support_phone)
                : props.currentCarrier.support_phone
            : '';
        setPrimaryPhone(_primaryPhone);
        setSupportPhone(_supportPhone);

        if (props.currentCarrier.billing_state) {
            let trimmedBillingState = props.currentCarrier.billing_state.trim();
            setBillingState(trimmedBillingState);
        }
    }, [props.currentCarrier]);

    return (
        <>
            <Card className="tabCard">
                <Card.Body className="overflow-auto">
                    <Container fluid>
                        <ThemeProvider theme={theme}>
                            <Form id="accountDetails" noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="my-2">
                                    <Col>
                                        <h5>
                                            Account Status:{' '}
                                            <span
                                                style={{
                                                    color: props.currentCarrier.approved ? 'green' : 'red',
                                                }}
                                            >
                                                {props.currentCarrier.approved ? 'Active' : 'Pending Admin Approval'}
                                            </span>
                                        </h5>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-1">
                                    <Col>
                                        <h5>Contact Info</h5>
                                    </Col>
                                </Row>
                                <Row className="d-flex">
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                fullWidth
                                                className=""
                                                error={!primaryName && validated}
                                                value={primaryName || ''}
                                                variant="outlined"
                                                label={'Name'}
                                                onChange={(e) => setPrimaryName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                fullWidth
                                                className=""
                                                value={primaryEmail || ''}
                                                error={!primaryEmail && validated}
                                                variant="outlined"
                                                label={'Email'}
                                                onChange={(e) => setPrimaryEmail(e.target.value)}
                                                onClick={(e) => setEmailModal(true)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                label="Phone"
                                                variant="outlined"
                                                className="w-100"
                                                required
                                                onChange={(e) => setPrimaryPhone(e.target.value)}
                                                name="phone"
                                                value={primaryPhone}
                                                error={!primaryPhone && validated}
                                                id="formatted-phone-input"
                                                InputProps={{
                                                    inputComponent: PhoneNumberInput,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                label="Support Phone"
                                                variant="outlined"
                                                className="w-100"
                                                onChange={(e) => setSupportPhone(e.target.value)}
                                                name="phone"
                                                value={supportPhone}
                                                id="formatted-phone-input"
                                                InputProps={{
                                                    inputComponent: PhoneNumberInput,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                label="Support Email"
                                                variant="outlined"
                                                className="w-100"
                                                onChange={(e) => setSupportEmail(e.target.value)}
                                                name="supportEmail"
                                                value={supportEmail}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-1">
                                    <Col>
                                        <h5>Business Details</h5>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                fullWidth
                                                className=""
                                                error={!businessName && validated}
                                                value={businessName || ''}
                                                variant="outlined"
                                                label={'Business Name'}
                                                onChange={(e) => setBusinessName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                fullWidth
                                                className=""
                                                error={!mcNumber && validated}
                                                value={mcNumber || ''}
                                                variant="outlined"
                                                label={'MC #'}
                                                onChange={(e) => setMcNumber(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                fullWidth
                                                error={!dotNumber && validated}
                                                className=""
                                                value={dotNumber || ''}
                                                variant="outlined"
                                                label={'DOT #'}
                                                onChange={(e) => setDotNumber(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                fullWidth
                                                className=""
                                                error={!einNumber && validated}
                                                value={einNumber || ''}
                                                variant="outlined"
                                                label={'EIN #'}
                                                onChange={(e) => setEinNumber(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col>
                                        <FleetSizeInput fleetSize={fleetSize} setFleetSize={setFleetSize} />
                                    </Col>
                                </Row>

                                <Row className="mt-2 mb-1">
                                    <Col>
                                        <h5>Billing Details</h5>
                                    </Col>
                                </Row>
                                {/* <Row className="d-flex">

                                </Row> */}
                                <Row className="d-flex">
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                error={!billingAddress && validated}
                                                fullWidth
                                                className=""
                                                value={billingAddress}
                                                variant="outlined"
                                                label={'Street Address'}
                                                onChange={(e) => setBillingAddress(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    error={!billingCity && validated}
                                                    fullWidth
                                                    className=""
                                                    value={billingCity}
                                                    variant="outlined"
                                                    label={'City'}
                                                    onChange={(e) => setBillingCity(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <FormControl className="w-100 my-2" variant="outlined">
                                            <InputLabel>State</InputLabel>
                                            <Select
                                                error={!billingState && validated}
                                                required
                                                native
                                                value={billingState}
                                                label="State"
                                                onChange={(e) => setBillingState(e.target.value)}
                                            >
                                                <option aria-label="None" value="" />
                                                {states.map((state) => (
                                                    <option key={state.abbreviation} value={state.abbreviation}>
                                                        {state.abbreviation}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                type="number"
                                                required
                                                error={!billingZip && validated}
                                                fullWidth
                                                className=""
                                                value={billingZip}
                                                variant="outlined"
                                                label={'Zip Code'}
                                                onChange={(e) => setBillingZip(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            variant="success"
                                            type="submit"
                                            form="accountDetails"
                                            className="my-2 ml-3"
                                        >
                                            Save
                                        </Button>
                                        {updateMessage && <div>{updateMessage}</div>}
                                    </Col>
                                </Row>
                            </Form>
                        </ThemeProvider>
                    </Container>
                </Card.Body>
                <EmailModal adminLoggedIn={props.adminLoggedIn} show={emailModal} onHide={() => setEmailModal(false)} />
            </Card>
            <Communications comms={userComms} />
            <LocationPermissions device_settings={props.currentCarrier?.user?.device_settings} />
            <DataSharing dataSharing={props.currentCarrier?.data_share} />
        </>
    );
}

export default AccountDetails;
