import React from 'react';
import { css } from '@emotion/react';
import { IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { HeaderText, StickyCell, fragments } from './blocks';

export default function HeaderCell({ cell, callbacks = {}, isAllChecked, ...props }) {
    let sortIcon = null;
    if (cell.canSort) {
        if (cell.isSorted) {
            sortIcon = (
                <IconButton
                    onClick={() => {
                        cell.toggleSortBy(!cell.isSortedDesc);
                    }}
                    css={css`
                        padding: 4px;
                        margin-left: 4px;
                        font-size: 1rem;
                        & .fa-w-10 {
                            ${fragments.cellIcon}
                        }
                    `}
                >
                    <FontAwesomeIcon
                        css={css`
                            color: black;
                        `}
                        icon={cell.isSortedDesc ? faSortDown : faSortUp}
                    />
                </IconButton>
            );
        } else {
            sortIcon = (
                <IconButton
                    onClick={cell.toggleSortBy}
                    css={css`
                        padding: 4px;
                        margin-left: 4px;
                        font-size: 1rem;

                        & .fa-w-10 {
                            ${fragments.cellIcon}
                        }
                    `}
                >
                    <FontAwesomeIcon
                        css={css`
                            color: black;
                        `}
                        icon={faSort}
                    />
                </IconButton>
            );
        }
    }

    const content = (
        <StickyCell align="left" {...props}>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                `}
            >
                <HeaderText>{cell.render('Header', { callbacks, isAllChecked })}</HeaderText>
                {sortIcon}
            </div>
        </StickyCell>
    );

    return (
        <>
            {cell.tooltip ? (
                <Tooltip title={cell.tooltip} placement="top" arrow>
                    {content}
                </Tooltip>
            ) : (
                <>{content}</>
            )}
        </>
    );
}
