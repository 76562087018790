import React, { ChangeEventHandler, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { format, isValid, parse } from 'date-fns';

import FocusTrap from 'focus-trap-react';
import DayPicker from 'react-day-picker-legacy';
import { usePopper } from 'react-popper';
import 'react-day-picker/dist/style.css';
import {
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    Grid,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    TextField,
} from '@material-ui/core/';
import dateFns from '../../utilities/dateFns';

const FORMAT = 'MM/dd/yyyy';

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
    return (
        <div className={classNames.overlayWrapper} style={{ marginLeft: -100 }} {...props}>
            <div className={classNames.overlay}>{children}</div>
        </div>
    );
}

CustomOverlay.propTypes = {
    classNames: PropTypes.object.isRequired,
    selectedDay: PropTypes.instanceOf(Date),
    children: PropTypes.node.isRequired,
};

export default function CustomDayPicker(props) {
    const [anchorEl, setAnchorEl] = useState(null); // Element that the popper attaches to.
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const { daysClosed } = props;

    const customerUnavailDays = !props.customerUnavailableDays
        ? []
        : props.customerUnavailableDays.map((day) => {
              return new Date(day);
          });
    const handleClick = (event) => {
        // Material ui has a bug for using a Select inside of a ClickAwayListener, this is a workaround for it.
        if (event.target.nodeName === 'BODY' && event.type === 'click') {
            return;
        }

        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <div>
            <div>
                <input
                    type="text"
                    placeholder={format(new Date(), 'y-MM-dd')}
                    value={props.value}
                    disabled
                    // onChange={handleInputChange}
                    className="input-reset pa2 ma2 bg-white black ba"
                />
                <button
                    // ref={buttonRef}
                    type="button"
                    className="pa2 bg-white button-reset ba"
                    aria-label="Pick a date"
                    onClick={(e) => setAnchorEl(e.target)}
                >
                    <span role="img" aria-label="calendar icon">
                        📅
                    </span>
                </button>
            </div>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                style={{ width: '400px', marginRight: '35px', marginTop: '10px', zIndex: 99 }}
            >
                <ClickAwayListener onClickAway={handleClick}>
                    <Paper>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className="ftl-filters-header"
                                style={{ backgroundColor: 'lightgrey', color: 'black' }}
                            >
                                Select Date
                            </Grid>
                            <DayPicker
                                disabled={props.disabled}
                                mode="single"
                                formatDate={dateFns.formatDate}
                                format={FORMAT}
                                parseDate={dateFns.parseDate}
                                placeholder={props.placeholder}
                                selected={new Date(props.value)}
                                onSelect={props.onDayChange}
                                dayPickerProps={
                                    props.version !== 'delivery'
                                        ? {
                                              disabledDays: [
                                                  ...customerUnavailDays,
                                                  { daysOfWeek: daysClosed },
                                                  {
                                                      before: new Date(props.firstAvail),
                                                  },
                                              ],
                                          }
                                        : {
                                              disabledDays: [
                                                  props.disabledDays,
                                                  ...customerUnavailDays,
                                                  { daysOfWeek: daysClosed },
                                                  {
                                                      before: new Date(props.firstAvail),
                                                  },
                                              ],
                                          }
                                }
                                {...props}
                            />
                        </Grid>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    );
}
