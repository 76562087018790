import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Container, Row, Col, Card } from 'reactstrap';
import * as ROUTES from '../../constants/routes';
import * as Sentry from '@sentry/react';
import FooterComponent from '../Footer';
import { getAuth, sendPasswordResetEmail, sendCustomerPasswordResetEmail } from 'firebase/auth';
import { captureException } from '@sentry/react';
import { Alert, AlertTitle } from '@material-ui/lab';

function AuthResetPasswordRequest() {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        sendPasswordResetEmail(getAuth(), email?.trim())
            .then(() => {
                console.log('Email Sent!');
                setEmailSent(true);
            })
            .catch((err) => {
                console.error(err);
                setErrors([err.message]);
                captureException(err);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Container className="mt-5">
                <Row>
                    <Col className="d-flex justify-content-center mt-4">
                        <Card className="shadow border-0 signupform mt-5">
                            <Form style={{ margin: '5rem' }}>
                                <Container className="d-flex justify-content-center mb-3">
                                    <h4>Request password reset email</h4>
                                </Container>

                                {emailSent && (
                                    <Alert severity="success" style={{ marginBottom: '10px' }}>
                                        <AlertTitle>Success</AlertTitle>
                                        Password reset link has been sent to your email
                                    </Alert>
                                )}

                                <Form.Control
                                    name="email"
                                    type="text"
                                    placeholder="Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    style={{ marginBottom: '10px' }}
                                />

                                <Container style={{ marginBottom: '10px' }} className="d-flex justify-content-center">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={onSubmit}
                                        disabled={loading || !email}
                                    >
                                        {emailSent ? 'Email Sent!' : 'Send Email'}
                                    </Button>
                                </Container>

                                {!!errors.length && errors.map((error) => <p style={{ color: 'red' }}>{error}</p>)}
                                <Container
                                    style={{ textAlign: 'center' }}
                                    className="d-flex flex-column justify-content-center"
                                >
                                    <Link to={ROUTES.SIGNIN}>
                                        <b>Back to sign in</b>
                                    </Link>
                                </Container>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <FooterComponent />
        </>
    );
}

export default AuthResetPasswordRequest;
