import { IconButton } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { Context } from '../store';
import { CardHeaderLine, CardHeaderText, CardLine, ListCard } from './blocks';
import { css } from '@emotion/react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CardText } from 'reactstrap';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';

const OnwardRouteCard = ({ route }) => {
    const { state, callbacks } = useContext(Context);

    const carrier = route.route_carrier?.business_name || route.route_shipper?.business_name;
    const EyeIcon = state.hiddenRoutes[route.route_id] ? VisibilityOff : Visibility;

    const stopSequence = FTLStopHelpers.getStopSequence(route.stopsByRouteId).filter((stop) =>
        FTLStopHelpers.isCustomerStop(stop, route)
    );

    const ordersByKey = useMemo(() => {
        return Object.fromEntries(route.orders.map((mapping) => [mapping.order_id, mapping.order]));
    }, [route]);

    const [startCity, endCity] = useMemo(() => {
        const firstOrder = stopSequence?.[0]?.orders?.find((order_id) => ordersByKey[order_id]);
        const lastOrder = stopSequence?.[stopSequence.length - 1]?.orders?.find((order_id) => ordersByKey[order_id]);
        return [ordersByKey?.[firstOrder]?.pickup_city, ordersByKey?.[lastOrder]?.dropoff_city];
    }, [stopSequence, ordersByKey]);

    return (
        <ListCard
            clickable={true}
            selected={state.selectedRoute?.route_id === route.route_id}
            onClick={() => callbacks.selectRoute(route)}
        >
            <CardHeaderLine>
                <CardHeaderText>Route {route.route_number}</CardHeaderText>
                <IconButton
                    color="primary"
                    css={css`
                        padding: 0;
                    `}
                >
                    <EyeIcon
                        onClick={(e) => {
                            e.stopPropagation();
                            callbacks.hideRoute(route.route_id);
                        }}
                    />
                </IconButton>
            </CardHeaderLine>
            <CardLine>
                <CardText>Carrier: {carrier}</CardText>
            </CardLine>
            <CardLine>
                <CardText>
                    {startCity || 'N/A'} - {endCity || 'N/A'}
                </CardText>
            </CardLine>
            <CardLine>
                <CardText>{route.stopsByRouteId.length} Stops</CardText>
            </CardLine>
            <CardLine>
                <CardText>Total Capacity: 1200 cu ft.</CardText>
            </CardLine>
        </ListCard>
    );
};

export default OnwardRouteCard;
