import React from 'react';
import { Grid } from '@material-ui/core';
import { OnwardCard } from './Card';
import { CardTitle, CardItem } from './blocks';
import { toNational } from '@/utilities/formatPhoneNumber';

export default function CarrierInfoCard(props) {
    const { carrier } = props;

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: '0.25rem', marginBottom: '0.25rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    return (
        <OnwardCard>
            <CardTitle style={{ display: 'block', marginBottom: '1.5rem' }}>Carrier Info</CardTitle>
            <CardRow>
                <Grid item>
                    <CardItem>{carrier?.business_name}</CardItem>
                </Grid>
            </CardRow>

            <CardRow>
                <Grid item>
                    <CardItem>{carrier.billing_address || 'No address on file'}</CardItem>
                </Grid>
            </CardRow>

            <CardRow>
                <Grid item>
                    <CardItem>{toNational(carrier?.business_phone)}</CardItem>
                </Grid>
            </CardRow>

            <CardRow>
                <Grid item>
                    <CardItem>{carrier?.email}</CardItem>
                </Grid>
            </CardRow>
        </OnwardCard>
    );
}
