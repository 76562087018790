import styled from '@emotion/styled';
import { typography, colors } from '@/styles';
import { Grid, Paper } from '@material-ui/core';

export const Subtitle1 = styled.span`
    ${typography.sansSerif}

    color: ${colors.greys.primary} !important;

    font-weight: 500;
    font-size: 14px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
`;

export const Body2 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 14px;
`;

export const Header1 = styled.span`
    ${typography.sansSerif}

    font-weight: 900;
    font-size: 16px;
    color: #4c4c4c;
`;

export const Header2 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 18px;
`;

const DEL_WINDOW_STATUS_COLORS = {
    error: colors.reds.cancelled,
    delay: colors.golds.primary,
    success: colors.greens.primary,
};

export const DeliveryWindow = styled((props) => <span {...props} />)`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 22px;

    color: ${({ status }) => DEL_WINDOW_STATUS_COLORS[status]};
`;

export const LoadingContainer = styled((props) => <Grid container {...props} />)`
    padding-top: 64px;
    height: 100%;
    margin: auto;
    max-width: 360px;

    @media only screen and (min-width: 600px) {
        max-width: 600px;
    }
`;

export const Container = styled((props) => <Grid container {...props} />)`
    width: 100%
    height: 100%;
`;

export const MapContainer = styled((props) => <Grid item {...props} />)`
    @media only screen and (max-width: 900px) {
        aspect-ratio: 1 / 1;
        width: 100%;
        min-height: 75vw;
    }

    @media only screen and (max-width: 600px) {
        min-height: 100vw;
    }

    @media only screen and (min-width: 900px) {
        position: fixed;
        top: 0px;
        right: 0px;
        height: calc(100vh - 64px);
        margin-top: 64px;
        width: 50%;
    }
`;

export const ContentContainer = styled(({ children, ...props }) => (
    <Grid item {...props}>
        <Grid container spacing={3}>
            {children}
        </Grid>
    </Grid>
))`
    padding: 0px 20px;

    @media only screen and (max-width: 900px) {
        margin-top: 12px;
    }
`;

export const HeaderContainer = styled(({ shipper, phone, ...props }) => <Grid item {...props} />)`
    ${typography.sansSerif}
    font-size: 12px;
    font-weight: 500;
    color: ${colors.greys.primary};
    padding: 12px 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 100%;

    @media only screen and (max-width: 900px) {
        text-align: center;
        justify-content: center;
    }
`;

export const OrderNumbers = styled(({ orderNumbers = [], ...props }) => (
    <div {...props}>{`Order${orderNumbers.length > 1 ? 's' : ''} ${orderNumbers.join(', ')}`}</div>
))`
    ${typography.sansSerif}
    font-weight: 800;
    font-size: 28px;
`;

export const AlertText = styled.span`
    ${typography.sansSerif}
    color: ${colors.reds.cancelled};
    font-weight: 600;
    font-size: 16px;
`;

export const ModalLinkText = styled.span`
    ${typography.sansSerif}
    color: ${colors.greens.primary};
    text-decoration: underline;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
`;

export const Hr = styled.hr`
    width: 100%;
`;
