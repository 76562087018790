import React from 'react';
import Notification from '../../Notification';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const NotificationMetadata = styled.span`
    margin-top: 10px;
    font-family: Montserrat, Roboto, Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0571429px;
    margin-bottom: 0px;
    color: #2b2b2b;
`;

export default function SMSFailedNotification({ notification, handleCloseNotification }) {
    const navigate = useNavigate();

    const onClickHandler = () => {
        const date = notification?.notification_data?.delivery_date;
        if (date) {
            sessionStorage?.setItem('plan_page_del_date', format(new Date(date), 'yyyy-MM-dd'));
            navigate('/dispatch/plan');
            handleCloseNotification();
        }
    };

    return (
        <Notification notification={notification} onClickHandler={onClickHandler}>
            <NotificationMetadata
                css={css`
                    margin-top: 0px;
                    font-weight: 700;
                `}
            >
                SMS Failed
            </NotificationMetadata>
            <NotificationMetadata>
                Order: {notification.notification_data?.order_numbers?.join(', ')}
            </NotificationMetadata>
            <NotificationMetadata>{notification.notification_data?.customer_name}</NotificationMetadata>
            <NotificationMetadata
                css={css`
                    color: grey;
                `}
            >
                {format(new Date(notification.created_at), 'MMM dd, yyyy @ hh:mm a')}
            </NotificationMetadata>
        </Notification>
    );
}
