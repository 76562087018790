import React, { useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { SingleDatePicker } from '@/components/ShipmentForm/modals/InputFields';

const AdminCarrierDocument = ({
    title,
    name,
    uploadDoc,
    form,
    showFormExp,
    handleExpUpdate,
    formExp,
    formExpName,
    formUploaded,
}) => {
    const inputRef = useRef();

    return (
        <Row className="d-flex align-items-center">
            <Col className="d-flex align-items-center">
                <h5>{title}: </h5>
            </Col>
            <Col>
                <Button onClick={() => inputRef.current.click()}>Upload File</Button>
                <input type="file" name={name} onChange={uploadDoc} ref={inputRef} style={{ display: 'none' }} />
            </Col>
            <Col>
                {form ? (
                    <Button className="mb-1 mx-2" variant="primary" size="sm" target="_blank" href={form}>
                        Show Preview
                    </Button>
                ) : (
                    <Button className="mb-1 mx-2" variant="danger" size="sm" target="_blank" disabled>
                        Not Uploaded Yet
                    </Button>
                )}
            </Col>
            {showFormExp ? (
                <Col className="d-flex align-items-center justify-content-center">
                    <p className="me-1 mt-2">Expiration:</p>
                    <SingleDatePicker
                        label={'Expiration Date'}
                        value={formExp || ''}
                        onChange={(e) => {
                            if (e.target.value) {
                                handleExpUpdate(new Date(e.target.value), formExpName);
                            }
                        }}
                    />
                </Col>
            ) : (
                <Col />
            )}
            <Col>{formUploaded && <p className="ms-2">Date Provided: {new Date(formUploaded).toDateString()}</p>}</Col>
        </Row>
    );
};

export default AdminCarrierDocument;
