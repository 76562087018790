import React, { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';

import * as Sentry from '@sentry/react';
import useQuery from '@/utilities/useQuery';
import * as ROUTES from '@/constants/routes';

import { useSignupCallbacks } from './hooks';
import { FORM_STATUS } from './constants';
import createClientQuery from './queries/createClient';
import verifyTokenQuery from './queries/verifyToken';
import validateEmailQuery from './queries/validateEmail';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const analytics = getAnalytics();
    const navigate = useNavigate();

    const [status, setStatus] = useState(FORM_STATUS.IN_PROGRESS);
    const [dynamicFields, setDynamicFields] = useState({});
    const [sections, setSections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [progression, setProgression] = useState({
        current: 0,
        stages: [1, 2, 3],
    });

    useEffect(() => {
        let currUser = getAuth()?.currentUser;

        if (currUser) {
            if (currUser.uid === 'anonymous-user') {
                signOut(getAuth());
            } else {
                navigate(ROUTES.ACCOUNT);
            }
        } else {
            logEvent(analytics, 'carrier_signup_page_landed', {});
        }
    }, []);

    const client = useMemo(() => {
        return sections.reduce(
            (acc, section) => {
                const { key, ...filtered } = section;

                return {
                    ...acc,
                    ...filtered,
                };
            },
            { ...dynamicFields }
        );
    }, [sections]);

    const [verify] = useQuery(verifyTokenQuery, {
        onComplete: (result) => {
            setDynamicFields({
                email: result?.verified.carrierEmail,
                partner_shipper_id: result?.verified.shipperId,
            });
        },
        onError: (err) => {
            Sentry.captureException(err);
        },
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get('invite_token');

        if (token && token.length > 0) {
            verify(token);
        }
    }, [location?.search]);

    const [checkEmail] = useQuery(validateEmailQuery, {});

    const [createClient] = useQuery(createClientQuery, {
        onComplete: () => {
            setIsLoading(false);
            setStatus(FORM_STATUS.COMPLETE);
            logEvent(analytics, 'carrier_signup_successful_signup', {
                username: client.name,
                email: client.email,
                business: client.business_name,
                phone: client.business_phone,
            });
        },
        onError: (err) => {
            setIsLoading(false);
            setStatus(FORM_STATUS.FAILED);
            logEvent(analytics, 'carrier_signup_error_signing_up', {
                username: client.name,
                email: client.email,
                business: client.business_name,
                phone: client.business_phone,
                error: err.message,
            });
            Sentry.captureException(err);
        },
    });

    const callbacks = useSignupCallbacks(
        { sections, progression, client, analytics },
        { createClient, checkEmail, setSections, setProgression, setIsLoading, setStatus, logEvent }
    );

    return (
        <Context.Provider
            value={{
                state: {
                    sections,
                    status,
                    progression,
                    client,
                    isLoading,
                    current: progression.stages[progression.current],
                },
                callbacks: {
                    ...callbacks,
                    setStatus,
                    resetError: () => {
                        setStatus(FORM_STATUS.IN_PROGRESS);
                    },
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};
