import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import React, { useRef } from 'react';
import { Body1, Header1 } from '../OrderDetailsPage/Crossdocking/blocks';
import { colors } from '@/styles';
import ReactSignatureCanvas from 'react-signature-canvas';

const Signature = ({ name, required, callbacks }) => {
    const sigRef = useRef();

    return (
        <>
            <Grid
                container
                css={css`
                    margin-top: 12px;
                    margin-bottom: 4px;
                `}
            >
                <Header1>{name}</Header1>
                {required ? (
                    <Body1
                        css={css`
                            font-style: italic;
                            color: ${colors.greys[0]};
                        `}
                    >
                        &nbsp;(required)
                    </Body1>
                ) : null}
            </Grid>
            <div
                css={css`
                    width: 100%;
                    aspect-ratio: 6.67;
                    border: 1px solid ${colors.greys[0]};
                    canvas {
                        width: 100%;
                        aspect-ratio: 6.67;
                    }
                `}
            >
                <ReactSignatureCanvas
                    ref={sigRef}
                    onEnd={() => {
                        try {
                            const dataURI = sigRef?.current?.toDataURL('image/png');
                            const byteStr = atob(dataURI.split(',')[1]);
                            const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];

                            const arrayBuffer = new ArrayBuffer(byteStr.length);
                            const intArr = new Uint8Array(arrayBuffer);

                            for (var i = 0; i < byteStr.length; i++) {
                                intArr[i] = byteStr.charCodeAt(i);
                            }

                            const blob = new Blob([arrayBuffer], { type: mime });

                            const file = { name: 'signature', type: mime, src: URL.createObjectURL(blob) };
                            callbacks.setFile(file);
                        } catch (e) {
                            console.error(e);
                        }
                    }}
                />
            </div>
            <Grid
                container
                direction="row"
                css={css`
                    justify-content: flex-end;
                `}
            >
                <Header1
                    css={css`
                        color: ${colors.greens.primary};
                    `}
                    onClick={() => {
                        sigRef?.current?.clear();
                        callbacks.setFile(null);
                    }}
                >
                    Redo
                </Header1>
            </Grid>
        </>
    );
};

export default Signature;
