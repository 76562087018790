import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const ACCOUNTING_ORDERS = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${CLIENT_FIELDS}

    query AccountingOrders($where: orders_bool_exp!) {
        orders_aggregate(where: $where) {
            aggregate {
                totalCount: count
            }
        }

        orders(where: $where, order_by: { created_at: asc_nulls_last }) {
            ...OrderFields
            order_shipper {
                ...ClientFields
            }
            itemsByOrderId {
                ...ItemFields
            }
            returns @client {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
            carrier_invoice {
                carrier_invoice_id
                invoice_number
                due_date
                status
                created_at
            }
        }
    }
`;
