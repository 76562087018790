import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import {
    ResponsiveSidebarDialog,
    StickyModalActions,
    ModalContent,
    PrimaryButton,
    SecondaryButton,
    OnwardSwitch,
} from '@/styles/blocks';
import { genAttributes } from '@onward-delivery/core';
import { Body1, DatePicker, DateTimeRangePicker, Header1 } from '../Crossdocking/blocks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { Alert } from '@material-ui/lab';

const TimeframeModal = ({ event, order, callbacks, isReceived, isCrossdocked }) => {
    const [updates, setUpdates] = useState({});

    const updated = useMemo(() => {
        return {
            ...(event || {}),
            del_window_start:
                order?.del_window_start ||
                (order?.order_type === 'return' ? order.pickup_date : order.delivery_date) ||
                new Date().toISOString(),
            del_window_end:
                order?.del_window_end ||
                (order?.order_type === 'return' ? order.pickup_date : order.delivery_date) ||
                new Date().toISOString(),
            sendText: false,
            ...updates,
        };
    }, [updates, event]);

    const tz = useMemo(() => {
        const { zip } = genAttributes(order);
        return zipcode_to_timezone.lookup(order[zip]) || 'America/New_York';
    }, [order]);

    const submit = () => {
        callbacks.onSubmit({
            event: {
                ...(event.event_id ? { event_id: event.event_id } : {}),
                order_id: order.order_id,
                action: event.action,
            },
            order: {
                order_id: order.order_id,
                del_window_start: updated.del_window_start,
                del_window_end: updated.del_window_end,
            },
            sendText: updated.sendText,
        });
    };

    return (
        <ResponsiveSidebarDialog open={!!event} onClose={callbacks.onClose}>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Body1>
                        Schedule the customer {order.order_type === 'return' ? 'return pickup' : 'delivery'} timeframe
                    </Body1>
                </Grid>
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <DateTimeRangePicker
                        dateLabel={order.order_type === 'return' ? 'Return Pickup Date' : 'Delivery Date'}
                        tz={tz}
                        range={[updated.del_window_start, updated.del_window_end]}
                        disableDate={true}
                        onChange={([start, end]) =>
                            setUpdates((prev) => ({
                                ...prev,
                                del_window_start: start.toISOString(),
                                del_window_end: end.toISOString(),
                            }))
                        }
                    />
                </Grid>
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <OnwardSwitch
                        checked={updated.sendText}
                        onChange={() => setUpdates((prev) => ({ ...prev, sendText: !prev.sendText }))}
                        label={<Header1>Text Customer?</Header1>}
                    />
                </Grid>
                <Alert severity="warning">
                    <Body1>{'Customer timeframes can be updated later.'}</Body1>
                </Alert>
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton onClick={submit}>Save</PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default TimeframeModal;
