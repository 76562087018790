import React, { useEffect, useState } from 'react';

function Marker({ forwarded, ...rest }) {
    const { callbacks = {}, ...options } = rest;
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (!marker) {
            const ref = new window.google.maps.Marker();

            if (forwarded) {
                forwarded.current = ref;
            }

            setMarker(ref);
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    useEffect(() => {
        let click;
        let mouseOver;
        let mouseOut;
        let onContextMenu;

        if (marker) {
            if (callbacks.onClick) {
                click = marker.addListener('click', callbacks.onClick);
            }

            if (callbacks.onContextMenu) {
                onContextMenu = marker.addListener('contextmenu', callbacks.onContextMenu);
            }

            if (callbacks.onMouseOver) {
                mouseOver = marker.addListener('mouseover', callbacks.onMouseOver);
            }

            if (callbacks.onMouseOut) {
                mouseOut = marker.addListener('mouseout', callbacks.onMouseOut);
            }
        }

        return () => {
            if (marker) {
                const ids = [click, mouseOver, mouseOut, onContextMenu];
                ids.forEach((id) => {
                    if (id) {
                        window.google.maps.event.removeListener(id);
                    }
                });
            }
        };
    }, [marker, callbacks]);

    return null;
}

export default React.forwardRef((props, ref) => {
    return <Marker {...props} forwarded={ref} />;
});
