const orderStatusStrings = {
    active: 'Active',
    complete: 'Complete',
    inProgress: 'In Progress',
    open: 'Open',
    pending: 'Pending',
    planning: 'Planning',
    cancelled: 'Cancelled',
    claimed: 'Claimed',
    deleted: 'Deleted',
    routed: 'Routed',
    routedActive: 'Routed - Active',
    routedComplete: 'Routed - Complete',
    routedInProgress: 'Routed - In Progress',
    routedOpen: 'Routed - Open',
    rescheduled: 'Rescheduled'
};

export default orderStatusStrings;
