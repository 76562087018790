import React, { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import hash from 'object-hash';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useEditModalDynamicFields } from '@/components/ShipmentForm/hooks/modal';
import { UPSERT_ORDERS } from '@/components/ShipmentForm/graphql/mutations';
import { CUSTOMER_FORM_PROGRESSION, DEFAULT_ORDER, FORM_STATUS, PROGRESSION_TYPES, STAGES } from './constants';
import { useCustomerFormCallbacks } from './hooks';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const [isLoadingOverride, setLoading] = useState(false);
    const [status, setStatus] = useState(FORM_STATUS.IN_PROGRESS);
    const [dynamicFields, setDynamicFields] = useState({
        key: `${new Date().getTime()}-${hash({
            type: DEFAULT_ORDER.order_type,
            freight_type: DEFAULT_ORDER.freight_type,
        })}`,
    });
    const [sections, setSections] = useState([]);
    const [progression, setProgression] = useState({
        current: 0,
        type: null,
        stages: [],
    });
    const [paymentInfo, setPaymentInfo] = useState({});
    const [errorInfo, setErrorInfo] = useState('');
    const [redundantItems, setRedundantItems] = useState([]);

    const order = useMemo(() => {
        return sections.reduce(
            (acc, section) => {
                const filteredItems = (section.itemsByOrderId || []).map((item) => {
                    const { _furniture_type, ...filtered } = item;
                    return filtered;
                });
                const filteredHaulaways = (section.haulaway_items || []).map((item) => {
                    const { _furniture_type, ...filtered } = item;
                    return filtered;
                });

                const { key, _item_types, ...filtered } = section;

                return {
                    ...acc,
                    ...filtered,
                    itemsByOrderId: [...acc.itemsByOrderId, ...filteredItems],
                    haulaway_items: [...acc.haulaway_items, ...filteredHaulaways],
                };
            },
            { ...DEFAULT_ORDER, ...dynamicFields }
        );
    }, [sections, dynamicFields]);

    useEditModalDynamicFields(order, (callback) =>
        setDynamicFields((prev) => {
            return callback(prev);
        })
    );

    const [submitOrders, { loading: inFlight }] = useMutation(UPSERT_ORDERS, {
        onCompleted: () => {
            setStatus(FORM_STATUS.COMPLETE);
        },
        onError: (e) => {
            setStatus(FORM_STATUS.FAILED);
            Sentry.captureException(e);
        },
    });

    const isLoading = useMemo(() => {
        return isLoadingOverride || inFlight;
    }, [isLoadingOverride, inFlight]);

    const callbacks = useCustomerFormCallbacks(
        { sections, progression, order, redundantItems },
        {
            setSections,
            setProgression,
            setStatus,
            submitOrders,
            setDynamicFields,
            setLoading,
            setPaymentInfo,
            setErrorInfo,
            setRedundantItems,
        }
    );

    useEffect(() => {
        const stage = progression.stages[progression.current];
        if (stage === STAGES.PAYMENT) {
            callbacks.presubmitOrder();
        }
    }, [progression]);

    return (
        <Context.Provider
            value={{
                state: {
                    sections,
                    isLoading,
                    status,
                    progression,
                    isFirstStage: progression.current === 0,
                    isLastStage: progression.current === progression.stages.length,
                    current: progression.stages[progression.current],
                    order,
                    paymentInfo,
                    errorInfo,
                    redundantItems,
                },
                callbacks: {
                    ...callbacks,
                    setSections,
                    setStatus,
                    resetError: () => {
                        setStatus(FORM_STATUS.IN_PROGRESS);
                        setErrorInfo('');
                    },
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};
