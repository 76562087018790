import React from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { Row, Col } from 'reactstrap';
import { Button } from '@material-ui/core';
import CardSection from './CardSection';
import * as Sentry from '@sentry/react';
import { UPDATE_CLIENT_CC } from '../Account/mutations';
import { useMutation } from '@apollo/client';
import { post } from '@/utilities/onwardClient';
import { STRIPE_CREATE_SETUP_INTENT } from '@/constants/apiRoutes';

class InjectedCardSetupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cc_error: false,
            cc_error_message: '',
        };
    }

    getClientKey = async (str_id) => {
        try {
            const { status, data } = await post(STRIPE_CREATE_SETUP_INTENT, { stripe_user_id: str_id });

            if (data?.clientSecret) {
                return data.clientSecret;
            }

            console.log('error!');
            return 'error';
        } catch (err) {
            this.setState({ cc_error: true, cc_error_message: 'Error Saving Card. Try again.' });
            Sentry.captureException(err);
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        const { stripe, elements } = this.props;
        const { client_id, username, stripe_user_id } = this.props.user;

        if (!stripe || !elements) {
            return;
        }

        const sec_key = await this.getClientKey(stripe_user_id);

        const result = await stripe.confirmCardSetup(sec_key, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: username,
                },
            },
        });

        if (result.error) {
            // Display result.error.message in your UI.
            this.setState({ cc_error: true, cc_error_message: result.error.message });
        } else {
            this.props.updateClientCC({
                variables: {
                    client_id: client_id,
                    cc_valid: true,
                    stripe_payment_method: result.setupIntent.payment_method,
                },
            });
            this.setState({ cc_error: false, cc_error_message: '' });
        }
    };

    render() {
        return (
            <form style={{ width: '100%' }}>
                <Row>
                    <Col xs={9}>
                        <CardSection />
                    </Col>
                    <Col xs={3}>
                        <Button
                            className="account-btn"
                            disabled={!this.props.stripe}
                            onClick={(e) => {
                                this.handleSubmit(e);
                            }}
                        >
                            Add Card
                        </Button>
                    </Col>
                </Row>
                <div style={{ display: this.state.cc_error, color: 'red' }}>{this.state.cc_error_message}</div>
            </form>
        );
    }
}

export default function CardSetupForm(props) {
    const { user } = props;

    const [updateClientCC] = useMutation(UPDATE_CLIENT_CC, {
        onCompleted: (data) => {
            props.onSuccess();
        },
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    return (
        <ElementsConsumer sid={user.stripe_user_id} curr_user={user}>
            {({ stripe, elements }) => (
                <InjectedCardSetupForm {...props} stripe={stripe} elements={elements} updateClientCC={updateClientCC} />
            )}
        </ElementsConsumer>
    );
}
