import { useMutation } from '@apollo/client';
import React, { useContext, useMemo, useState } from 'react';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { UPDATE_ORDERS_MANY } from '../graphql/mutations';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { TextField } from '@material-ui/core';
import { asUTCDate } from '@/utilities/convertToISO';

const OrdersReschedule = () => {
    const {
        modalOpen,
        setModalOpen,
        setError,
        setNotification,
        selectedOrders,
        setSelectedOrders,
        deliveryDate,
        state: { orders },
        callbacks,
    } = useContext(PlanningContext);

    const [rescheduleDate, setRescheduleDate] = useState(deliveryDate);

    const [updateOrders, { loading }] = useMutation(UPDATE_ORDERS_MANY);

    const onClose = () => {
        setModalOpen(null);
    };

    const ordersToReschedule = useMemo(() => {
        const ordersByKey = Object.fromEntries(
            orders.map((order) => [`${order.order_id}_${order.crossdock_leg}`, order])
        );
        return Object.entries(selectedOrders)
            .filter(([_, selected]) => !!selected)
            .map(([key]) => ordersByKey[key]);
    }, [orders, selectedOrders]);

    const onConfirm = () => {
        const routedOrders = ordersToReschedule.filter((order) =>
            order.crossdock_leg === 'pickup' ? !!order.pickup_route_id : !!order.dropoff_route_id
        );
        if (routedOrders.length > 0) {
            setNotification({
                severity: 'warning',
                message: `Cannot reschedule orders that are assigned to a route. Please remove them from the route first. Orders: ${routedOrders
                    .map((o) => o.order_number)
                    .join(', ')}`,
            });
            return onClose();
        }

        const pickups = ordersToReschedule.filter((o) => o.crossdock_leg === 'pickup');
        const dropoffs = ordersToReschedule.filter((o) => o.crossdock_leg !== 'pickup');

        updateOrders({
            variables: {
                updates: [
                    ...(pickups.length > 0
                        ? [
                              {
                                  where: { order_id: { _in: pickups.map((o) => o.order_id) } },
                                  _set: {
                                      pickup_date: asUTCDate(rescheduleDate).toISOString(),
                                  },
                              },
                          ]
                        : []),
                    ...(dropoffs.length > 0
                        ? [
                              {
                                  where: { order_id: { _in: dropoffs.map((o) => o.order_id) } },
                                  _set: {
                                      delivery_date: asUTCDate(rescheduleDate).toISOString(),
                                  },
                              },
                          ]
                        : []),
                ],
            },
            onCompleted: () => {
                setNotification({
                    severity: 'success',
                    message: `Orders rescheduled to ${rescheduleDate}!`,
                });
                setSelectedOrders({});
                callbacks.refetch();
                onClose();
            },
            onError: (error) => {
                setError(error, 'Error rescheduling orders. Please refresh the page and try again.');
            },
        });
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ORDER_RESCHEDULE} onClose={onClose}>
            <ModalTitle>{`Reschedule Orders`}</ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <Body2>Select a new delivery date for these orders</Body2>
                </GridItemRow>
                <GridItemRow>
                    <TextField
                        type="date"
                        variant="outlined"
                        disabled={loading}
                        value={rescheduleDate}
                        onChange={(e) => setRescheduleDate(e.target.value)}
                    />
                </GridItemRow>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={onClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={onConfirm}>
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default OrdersReschedule;
