import React, { useEffect, createContext, useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { QUERY_BY_ID } from '@/graphql/queries/orders';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useClientUser, useOrderCarrierPricing } from '@/hooks';
import { DUPLICATE_PRICING_TARIFF, CREATE_TARIFF, DELETE_TARIFF } from '@/components/Account/Tariffs/graphql';

import { LIST_GLOBAL_TARIFFS } from './graphql';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();

    const { data, loading: listInflight } = useQuery(LIST_GLOBAL_TARIFFS, {
        onError: (e) => {
            console.log(e);
            Sentry.captureException(e);
        },
    });

    const [duplicateTariff] = useMutation(DUPLICATE_PRICING_TARIFF, {
        onCompleted: (resp) => {
            if (resp?.duplicated.length > 0) {
                navigate(`/account/tariff/${resp.duplicated[0].tariff_id}`);
            }
        },
        onError: (e) => {
            console.log(e);
            Sentry.captureException(e);
        },
    });

    const [deleteTariff, { loading: deleteLoading }] = useMutation(DELETE_TARIFF, {
        update: (cache, { data: { deleted } }) => {
            cache.updateQuery(
                {
                    query: LIST_GLOBAL_TARIFFS,
                },
                (data) => {
                    const { tariffs } = data;
                    let clone = [...tariffs];
                    clone = clone.filter((tariff) => tariff.tariff_id !== deleted.tariff_id);

                    return {
                        tariffs: clone,
                    };
                }
            );
        },
        onError: (e) => {
            console.log(e);
            Sentry.captureException(e);
        },
    });

    const tariffs = useMemo(() => {
        return data?.tariffs ? data.tariffs : [];
    }, [data]);

    return (
        <Context.Provider
            value={{
                state: {
                    tariffs,
                },
                loading: {
                    init: listInflight,
                    delete: deleteLoading,
                },
                callbacks: {
                    deleteTariff: (tariff_id) => {
                        deleteTariff({
                            variables: {
                                tariff_id,
                            },
                        });
                    },
                    duplicateTariff: (tariff_id) => {
                        duplicateTariff({
                            variables: {
                                tariff_id,
                            },
                        });
                    },
                },
                errors: {},
            }}
        >
            {children}
        </Context.Provider>
    );
};
