import React from 'react';
import { Text, View } from '@react-pdf/renderer';

const BodyRow = ({ schema, data }) => {
    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                borderBottom: '1px solid #000',
            }}
            key={data.stop.obj.ordering}
        >
            {schema.map((col) => {
                const formatter = col.format || ((x) => x);
                const value = col.getValue(data);
                const placeholder = col.default || '';
                const formatted =
                    value !== undefined && value !== null ? formatter(value, data.opt || {}) : placeholder;

                return (
                    <Text
                        style={{
                            overflow: 'hidden',
                            padding: '16px 4px',
                            fontSize: '7px',
                            display: 'flex',
                            ...col.styles,
                        }}
                    >
                        {formatted}
                    </Text>
                );
            })}
        </View>
    );
};

export default BodyRow;
