import React, { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTable, useFlexLayout } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { TableContainer } from '@material-ui/core';
import { useBidColumns } from './BidColumns';
import HeaderCell from './table/HeaderCell';
import BodyCell from './table/BodyCell';
import BodyRow from './table/BodyRow';
import { useListingColumns } from './ListingColumns';
import { useMutation } from '@apollo/client';
import { UPDATE_LISTING_BY_ID } from '@/graphql/mutations/listings';
import { UPDATE_BID_BY_ID } from '@/graphql/mutations/bids';
import { useSticky } from 'react-table-sticky';

const OrdersTable = ({ listings, viewType }) => {
    const navigate = useNavigate();
    const listingCols = useListingColumns(listings);
    const bidCols = useBidColumns(listings);
    const TABLE_COLUMNS = useMemo(() => {
        return viewType === 'listings' ? listingCols : bidCols;
    }, [viewType]);

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state } = useTable(
        {
            columns: TABLE_COLUMNS,
            data: listings,
            getRowId: (listing) => listing.order_id,
        },
        useSticky,
        useFlexLayout
    );

    const [updateListing] = useMutation(UPDATE_LISTING_BY_ID);
    const [updateBid] = useMutation(UPDATE_BID_BY_ID);

    const onClick = async (listing) => {
        if (viewType === 'listings') {
            await updateListing({
                variables: {
                    listing_id: listing.listing_id,
                    update: {
                        last_viewed_shipper: new Date().toISOString(),
                    },
                },
                onError: (err) => {
                    console.error(err);
                },
            });
        } else {
            await updateBid({
                variables: {
                    bid_id: listing.carrierBid.bid_id,
                    update: {
                        last_viewed_carrier: new Date().toISOString(),
                    },
                },
                onError: (err) => {
                    console.error(err);
                },
            });
        }
        navigate(`/listing/${listing.listing_id}`);
    };

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                console.log(group);

                if (viewType === 'listings') {
                    return (
                        <div {...group.getHeaderGroupProps({ style })}>
                            {group.headers.reduce(
                                (acc, subgroup) => [
                                    ...acc,
                                    subgroup.headers.map((col) => (
                                        <HeaderCell
                                            {...col.getHeaderProps()}
                                            key={col.id}
                                            cell={col}
                                            align={col.align}
                                        />
                                    )),
                                ],
                                []
                            )}
                        </div>
                    );
                } else
                    return (
                        <div {...group.getHeaderGroupProps({ style })}>
                            {group.headers.map((col) => (
                                <HeaderCell {...col.getHeaderProps()} key={col.id} cell={col} align={col.align} />
                            ))}
                        </div>
                    );
            }

            //BODY
            const row = rows[index - 1];
            prepareRow(row);

            return (
                <BodyRow
                    {...row.getRowProps({
                        style,
                    })}
                    row={row}
                    callbacks={{ onClick }}
                >
                    {row.cells.map((cell) => (
                        <BodyCell {...cell.getCellProps()} cell={cell} align={cell.column.align} />
                    ))}
                </BodyRow>
            );
        },
        [prepareRow, rows, viewType]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList height={height} itemCount={rows.length + 1} itemSize={57} width={width}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </TableContainer>
    );
};

export default OrdersTable;
