import React, { useContext, useState, useMemo } from 'react';
import { TextField, Grid, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import {
    ModalActions,
    ModalContent,
    ResponsiveSidebarDialog,
    PrimaryButton,
    SecondaryButton,
    ModalBody,
    Body,
} from '@/styles/blocks';
import { HeaderText } from '../blocks';
import { Context } from '../store';
import { css } from '@emotion/react';
import { colors } from '@/styles';

export default function PlanningModal({ open, onClose, orderCount }) {
    const [confirmAttempted, setConfirmAttempted] = useState(false);
    const [planningDate, setPlanningDate] = useState(null);

    const [sendMarketplaceOrders, setSendMarketplaceOrders] = useState(null);

    const {
        state: { selectedOrders },
        callbacks: { sendToPlanning },
    } = useContext(Context);

    const handleConfirmClicked = () => {
        setConfirmAttempted(true);
        if (!planningDate) return;
        sendToPlanning(planningDate, sendMarketplaceOrders === 'yes');
        setSendMarketplaceOrders(null);
    };

    const hasMarketplaceOrders = useMemo(() => {
        return !!selectedOrders.find((o) => !o.oms);
    }, [selectedOrders]);

    return (
        <ResponsiveSidebarDialog open={open} onClose={onClose}>
            <ModalContent width={490}>
                {hasMarketplaceOrders && (
                    <>
                        <div>
                            <HeaderText>You have selected Onward marketplace orders.</HeaderText>
                        </div>
                        <div>
                            <Body>Are you sure you want to send these orders to planning?</Body>
                        </div>

                        <ModalBody>
                            <Grid container>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="send-marketplace-to-planning"
                                        name="send-marketplace-to-planning"
                                        value={sendMarketplaceOrders}
                                        onChange={(e) => setSendMarketplaceOrders(e.target.value)}
                                    >
                                        <FormControlLabel
                                            control={<Radio />}
                                            value="yes"
                                            label="Yes, send these to planning."
                                            css={css`
                                                & .Mui-checked {
                                                    color: ${colors.greens.primary} !important;
                                                }
                                            `}
                                        />
                                        <FormControlLabel
                                            control={<Radio />}
                                            value="no"
                                            label="No, exclude these from being sent to planning."
                                            css={css`
                                                & .Mui-checked {
                                                    color: ${colors.greens.primary} !important;
                                                }
                                            `}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </ModalBody>
                    </>
                )}

                <HeaderText>
                    Please confirm the scheduled delivery date for the {orderCount} selected order(s):
                </HeaderText>

                <ModalBody>
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        label="Scheduled Date"
                        InputLabelProps={{ shrink: true }}
                        name="deliveryDate"
                        value={planningDate}
                        onChange={(e) => setPlanningDate(e.target.value)}
                        error={confirmAttempted && !planningDate}
                        helperText={confirmAttempted && !planningDate && 'Select a date'}
                    />
                </ModalBody>

                <ModalActions>
                    <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                    <PrimaryButton
                        disabled={hasMarketplaceOrders && !sendMarketplaceOrders}
                        onClick={handleConfirmClicked}
                    >
                        Confirm
                    </PrimaryButton>
                </ModalActions>
            </ModalContent>
        </ResponsiveSidebarDialog>
    );
}
