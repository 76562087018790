import api from '@/utilities/api';

export const trackOrder = (data) => {
    return api
        .post({
            routeName: 'trackOrder',
            data,
        })
        .then((resp) => {
            if (!resp.error) {
                return resp;
            }

            return Promise.reject(resp.errors);
        });
};
