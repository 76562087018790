import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Grid, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import statesInverted from '@/constants/statesInverted.json';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import EditIcon from '@material-ui/icons/Edit';
import { useOrderCarrierPricing, useOrderShipperPricing } from '@/hooks';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@mui/icons-material/Done';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';
import { calcOrderInvoiceTotals } from '@/utilities/calcOrderInvoiceTotals';

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

export const useTableColumns = ({ perspective, isAdmin, callbacks }) => {
    return useMemo(() => {
        return [
            {
                Header: 'Order Details',
                id: 'order-details-group',
                columns: [
                    {
                        Header: 'Order',
                        id: 'order_number',
                        width: 100,
                        accessor: (order) => order.order_number,
                    },
                    {
                        Header: 'PO',
                        id: 'po_number',
                        width: 150,
                        accessor: (order) => (!order.po_number ? 'N/A' : order.po_number),
                    },
                    {
                        Header: 'Shipper',
                        id: 'shipper',
                        width: 200,
                        Cell: ({ row }) => {
                            const order = row.original;
                            return order.order_shipper?.business_name || '--';
                        },
                    },
                    {
                        Header: 'Delivered',
                        id: 'delivery_date',
                        width: 175,
                        Cell: ({ row }) => {
                            const order = row.original;
                            return order.delivery_date_formatted || '--';
                        },
                    },
                    {
                        Header: 'Completed',
                        id: 'completed_date',
                        width: 175,
                        Cell: ({ row }) => {
                            const order = row.original;
                            return order.billing_completion_date_formatted || '--';
                        },
                    },
                    {
                        Header: 'POD',
                        id: 'pod',
                        width: 110,
                        Cell: ({ row }) => {
                            const order = row.original;
                            const hasPhoto = order.delivery_photo?.length >= 1 || order.pod?.length >= 1;
                            const hasSignature = order.customer_signature?.length >= 1;

                            return hasSignature || hasPhoto ? (
                                <>
                                    <DoneIcon
                                        css={css`
                                            color: ${colors.greens.primary};
                                        `}
                                    />
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            callbacks.viewOrderPod(order);
                                        }}
                                        css={css`
                                            color: ${colors.greens.primary};
                                            text-decoration: underline;
                                            margin: 5px;
                                            font-weight: 500;
                                            background: none;
                                            border: none;
                                            cursor: pointer;
                                        `}
                                    >
                                        View
                                    </button>
                                </>
                            ) : (
                                <CloseIcon
                                    css={css`
                                        color: ${colors.reds.primary};
                                        background: none;
                                    `}
                                />
                            );
                        },
                    },
                    {
                        Header: 'Pending Issues',
                        id: 'pending_issues',
                        width: 150,
                        accessor: (order) => {
                            return (
                                order.exceptions.filter((mapping) => mapping.exception.status !== 'RESOLVED').length > 0
                            );
                        },
                        Cell: ({ value }) => {
                            if (value) {
                                return (
                                    <WarningIcon
                                        css={css`
                                            color: ${colors.reds.primary};
                                        `}
                                    />
                                );
                            }

                            return '--';
                        },
                    },
                    {
                        Header: 'Customer',
                        id: 'dropoff_name',
                        width: 200,
                        accessor: (order) => order.dropoff_name,
                    },
                    {
                        Header: 'Pick Up',
                        id: 'pickup',
                        width: 250,
                        accessor: (order) => [order.pickup_city, order.pickup_state, order.pickup_zip].join(':'),
                        Cell: ({ row: order }) => {
                            const { state, zip, city } = useSwappedAttributes(order.original, true);
                            const stateLookup = statesInverted[order.original[state]]
                                ? statesInverted[order.original[state]]
                                : order.original[state];
                            return `${order.original[city]}, ${stateLookup} ${order.original[zip]}`;
                        },
                    },
                    {
                        Header: 'Drop Off',
                        id: 'dropoff',
                        width: 250,
                        accessor: (order) => [order.dropoff_city, order.dropoff_state, order.dropoff_zip].join(':'),
                        Cell: ({ value, row: order }) => {
                            const { address, state, zip, city } = useSwappedAttributes(order.original);
                            const stateLookup = statesInverted[order.original[state]]
                                ? statesInverted[order.original[state]]
                                : order.original[state];

                            if (order.original.dropoff_address_confirmed) {
                                return (
                                    <Grid
                                        container
                                        css={css`
                                            align-items: center;
                                            flex-wrap: nowrap;
                                        `}
                                    >
                                        <Grid
                                            item
                                            css={css`
                                                margin-right: 4px;
                                            `}
                                        >
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={`Customer confirmed: ${order.original[address]}`}
                                            >
                                                <CheckCircleIcon
                                                    css={css`
                                                        color: ${colors.greens.primary};
                                                    `}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        {`${order.original[city]}, ${stateLookup} ${order.original[zip]}`}
                                    </Grid>
                                );
                            } else {
                                return `${order.original[city]}, ${stateLookup} ${order.original[zip]}`;
                            }
                        },
                    },
                    {
                        Header: perspective === 'shipper' ? 'Carrier Subtotal' : 'Subtotal',
                        id: 'carrier_subtotal',
                        disableSortBy: true,
                        Cell: ({ row }) => {
                            const order = row.original;
                            const finalSubTotal = calcOrderSubtotal(order);
                            return dollarFormatter.format(finalSubTotal);
                        },
                    },
                    {
                        Header: perspective === 'shipper' ? 'Carrier Accessorials' : 'Accessorials',
                        id: 'carrier_accessorials',
                        Cell: ({ row }) => {
                            const order = row.original;
                            return dollarFormatter.format(
                                (order?.price_breakdown?.carrierBreakdown?.accessorials || []).reduce(
                                    (acc, { quantity, rate }) => {
                                        return acc + quantity * rate;
                                    },
                                    0
                                )
                            );
                        },
                    },
                    {
                        Header: 'Manual Adj',
                        id: 'carrier_accessorials_custom',
                        Cell: ({ row }) => {
                            const order = row.original;

                            return order?.order_revenue_adjustments?.carrier ? (
                                <>
                                    <DoneIcon
                                        css={css`
                                            color: ${colors.greens.primary};
                                            background: none;
                                        `}
                                    />
                                </>
                            ) : (
                                <CloseIcon
                                    css={css`
                                        color: ${colors.reds.primary};
                                        background: none;
                                    `}
                                />
                            );
                        },
                    },
                    {
                        Header: perspective === 'shipper' ? 'Carrier Revenue' : 'Revenue',
                        id: 'carrier_revenue',
                        Cell: ({ row }) => {
                            const order = row.original;
                            const total = calcOrderInvoiceTotals(order);
                            return dollarFormatter.format(total);
                        },
                    },
                    ...(isAdmin
                        ? [
                              {
                                  Header: 'Shipper Invoiced',
                                  id: 'shipper_invoiced',
                                  Cell: ({ row }) => {
                                      const order = row.original;

                                      return order?.shipper_invoice?.status === 'APPROVED' ? (
                                          <>
                                              <DoneIcon
                                                  css={css`
                                                      color: ${colors.greens.primary};
                                                      background: none;
                                                  `}
                                              />
                                          </>
                                      ) : (
                                          <CloseIcon
                                              css={css`
                                                  color: ${colors.reds.primary};
                                                  background: none;
                                              `}
                                          />
                                      );
                                  },
                              },
                          ]
                        : []),
                    ...(perspective === 'shipper'
                        ? [
                              {
                                  Header: 'Shipper Subtotal',
                                  id: 'shipper_subtotal',
                                  disableSortBy: true,
                                  Cell: ({ row }) => {
                                      const order = row.original;
                                      return dollarFormatter.format(
                                          FIXED_CHARGES.map(({ key: attr }) => {
                                              return order?.price_breakdown?.shipperBreakdown?.[attr] || 0;
                                          }).reduce((acc, val) => {
                                              return acc + val;
                                          }, 0)
                                      );
                                  },
                              },
                              {
                                  Header: 'Shipper Accessorials',
                                  id: 'shipper_accessorials',
                                  Cell: ({ row }) => {
                                      const order = row.original;
                                      return dollarFormatter.format(
                                          (order?.price_breakdown?.shipperBreakdown?.accessorials || []).reduce(
                                              (acc, { quantity, rate }) => {
                                                  return acc + quantity * rate;
                                              },
                                              0
                                          )
                                      );
                                  },
                              },
                              {
                                  Header: 'Shipper Revenue',
                                  id: 'shipper_revenue',
                                  Cell: ({ row }) => {
                                      const order = row.original;
                                      return useOrderShipperPricing(order);
                                  },
                              },
                          ]
                        : []),
                ],
            },
            {
                sticky: 'right',
                Header: 'Sticky',
                id: 'sticky-group',
                columns: [
                    {
                        Header: 'Carrier',
                        id: 'edit-carrier',
                        width: 80,
                        Cell: ({ row, callbacks }) => {
                            const order = row.original;
                            return (
                                <IconButton
                                    disabled={!order.oms && !isAdmin && order.carrier_invoice_id}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        callbacks.onEdit(order, 'carrier');
                                    }}
                                    css={css`
                                        padding: 8px;

                                        color: ${colors.greens.primary};
                                    `}
                                >
                                    <EditIcon />
                                </IconButton>
                            );
                        },
                    },
                    ...(perspective === 'shipper'
                        ? [
                              {
                                  Header: 'Shipper',
                                  id: 'edit-shipper',
                                  width: 80,
                                  Cell: ({ row, callbacks }) => {
                                      const order = row.original;

                                      return (
                                          <IconButton
                                              disabled={!order.oms && !isAdmin && order.shipper_invoice_id}
                                              size="small"
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  callbacks.onEdit(order, 'shipper');
                                              }}
                                              css={css`
                                                  padding: 8px;

                                                  color: ${colors.greens.primary};
                                              `}
                                          >
                                              <EditIcon />
                                          </IconButton>
                                      );
                                  },
                              },
                          ]
                        : []),
                ],
            },
        ];
    }, [perspective, callbacks]);
};
