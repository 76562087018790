import { colors } from '@/styles';
import { DialogContent, Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import NavResponsiveModal from '../Navigation/NavResponsiveModal';
import { Row } from '@/styles/blocks';
import { css } from '@emotion/react';
import { H3 } from './blocks';
import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { START_ROUTE } from './graphql';
import { toArrayLiteral } from '@/utilities/toArrayLiteral';

const Title = styled.span`
    color: ${colors.greys.secondary};
    font-size: 20px;
    font-weight: 800;
`;

const Body = styled.span`
    font-size: 16px;
    font-weight: 500;
`;

const StartRouteModal = ({ open, onClose, route, username, setErrorMessage }) => {
    const [startRoute, { loading }] = useMutation(START_ROUTE);
    // Add Start Route Mutation

    const handleStartRoute = () => {
        let routeUpdates = {
            last_modified_by: username,
            last_update_source: 'web',
            status: 'inProgress',
        };

        startRoute({
            variables: {
                route_id: route.route_id,
                _set: routeUpdates,
            },
            onError: (error) => {
                console.error(error);
                captureException(error);
                setErrorMessage('Error manually starting route!');
            },
            onCompleted: () => onClose(),
        });
    };

    return (
        <NavResponsiveModal open={open} onClose={onClose}>
            <DialogContent>
                <Grid>
                    <Row>
                        <Title>
                            Are you sure that you want to start this route?
                        </Title>
                    </Row>
                    <Row>
                        <Body>This cannot be undone.</Body>
                    </Row>
                    <Row>
                        <Body>
                            Note: This should be reserved only for situations where the route is in a stuck state due to
                            driver app issues.
                        </Body>
                    </Row>
                    <Row
                        css={css`
                            justify-content: space-between;
                        `}
                    >
                        <SecondaryButton onClick={onClose}>
                            <H3>Cancel</H3>
                        </SecondaryButton>
                        <PrimaryButton variant="contained" onClick={handleStartRoute} disabled={loading}>
                            <H3>Start Route</H3>
                        </PrimaryButton>
                    </Row>
                </Grid>
            </DialogContent>
        </NavResponsiveModal>
    );
};

export default StartRouteModal;
