import { asUTCDate } from '@/utilities/convertToISO';
import { useQuery } from '@apollo/client';
import { addDays, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React, { createContext, useMemo, useState } from 'react';
import { QUERY_MARKETPLACE_ORDERS, QUERY_ONWARD_ROUTES, QUERY_SUPPLY_ORDERS, QUERY_SUPPLY_ROUTES } from './graphql';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const [deliveryDate, setDeliveryDate] = useState(
        window.localStorage?.getItem('onward.adminMatchingTool.deliveryDate') || format(new Date(), 'yyyy-MM-dd')
    );
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [test, setTest] = useState(false);
    const [hiddenRoutes, setHiddenRoutes] = useState({});
    const [bounds, setBounds] = useState(null);
    const [distance, setDistance] = useState(10);

    const [start, end, deliveryDateFormatted] = useMemo(() => {
        const start = asUTCDate(deliveryDate);
        const end = addDays(start, 1);
        const formatted = formatInTimeZone(start, 'utc', 'MM/dd/yyyy');

        return [start, end, formatted];
    }, [deliveryDate]);

    const [geolocation, limit] = useMemo(() => {
        if (selectedOrder && selectedOrder.__typename === 'orders') {
            const meters = (distance || 9999) * 1609.34;
            return [
                {
                    _or: ['pickup_point', 'dropoff_point'].map((point) => ({
                        point: {
                            _st_d_within: {
                                distance: meters,
                                from: selectedOrder[point],
                            },
                        },
                    })),
                },
                25,
            ];
        } else if (bounds) {
            const { west, east, north, south } = bounds.toJSON();
            return [
                {
                    point: {
                        _cast: {
                            geometry: {
                                _st_within: {
                                    type: 'Polygon',
                                    coordinates: [
                                        [
                                            [west, north],
                                            [east, north],
                                            [east, south],
                                            [west, south],
                                            [west, north],
                                        ],
                                    ],
                                },
                            },
                        },
                    },
                },
                1000,
            ];
        }

        return [null, 0];
    }, [bounds, selectedOrder, distance]);

    const { data: supplyOrdersData, loading: supplyOrdersLoading } = useQuery(QUERY_SUPPLY_ORDERS, {
        variables: { start, end },
        onCompleted: () => {
            if (selectedOrder?.__typename === 'activity_orders') {
                setSelectedOrder(null);
            }
        },
    });
    const supplyOrders = useMemo(() => {
        return supplyOrdersData?.activity_orders || [];
    }, [supplyOrdersData]);

    const { data: supplyRoutesData, loading: supplyRoutesLoading } = useQuery(QUERY_SUPPLY_ROUTES, {
        variables: { start, end, geolocation: { activity_stops: geolocation }, limit },
        skip: !geolocation,
        onCompleted: () => {
            setSelectedRoute(null);
        },
    });
    const supplyRoutes = useMemo(() => {
        return supplyRoutesData?.activity_routes || [];
    }, [supplyRoutesData]);

    const { data: onwardRoutesData, loading: onwardRoutesLoading } = useQuery(QUERY_ONWARD_ROUTES, {
        variables: { start, end, geolocation: { stopsByRouteId: geolocation }, limit },
        skip: !geolocation,
        onCompleted: () => {
            setSelectedRoute(null);
        },
    });
    const onwardRoutes = useMemo(() => {
        return onwardRoutesData?.routes || [];
    }, [onwardRoutesData]);

    const { data: marketplaceOrdersData, loading: marketplaceOrdersLoading } = useQuery(QUERY_MARKETPLACE_ORDERS, {
        variables: { start, end, test },
        onCompleted: () => {
            if (selectedOrder?.__typename === 'orders') {
                setSelectedOrder(null);
            }
        },
    });
    const marketplaceOrders = useMemo(() => {
        return marketplaceOrdersData?.orders || [];
    });

    const loading = useMemo(() => {
        return supplyOrdersLoading || marketplaceOrdersLoading || supplyRoutesLoading || onwardRoutesLoading;
    }, [supplyOrdersLoading, marketplaceOrdersLoading, supplyRoutesLoading, onwardRoutesLoading]);

    const state = {
        loading,
        test,
        distance,
        marketplaceOrdersLoading,
        supplyOrdersLoading,
        supplyRoutesLoading,
        onwardRoutesLoading,
        deliveryDate,
        deliveryDateFormatted,
        selectedOrder,
        selectedRoute,
        hiddenRoutes,
        marketplaceOrders,
        supplyOrders,
        supplyRoutes,
        onwardRoutes,
    };

    const setters = {
        setTest,
        setDeliveryDate,
        setBounds,
        setDistance,
    };

    const callbacks = {
        ...setters,
        selectOrder: (order) => setSelectedOrder((prev) => (order.order_id === prev?.order_id ? null : order)),
        selectRoute: (route) => setSelectedRoute((prev) => (route.route_id === prev?.route_id ? null : route)),
        hideRoute: (route_id) => setHiddenRoutes((prev) => ({ ...prev, [route_id]: !prev[route_id] })),
    };

    return (
        <Context.Provider
            value={{
                state,
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );
