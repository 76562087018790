export const getStopCompletionTimes = (orderKey, route) => {
    let pickupStop = (route.stopsByRouteId || []).filter((stop) => {
        return stop.orders?.includes(orderKey) && stop.type === 'PICKUP';
    });

    let dropOffStop = (route.stopsByRouteId || []).filter((stop) => {
        return stop.orders?.includes(orderKey) && stop.type === 'DROPOFF';
    });

    const stopPickupTime =
        pickupStop.length > 0 && pickupStop[0].stop_completion_time
            ? new Date(pickupStop[0].stop_completion_time)
            : null;
    const stopDeliveryTime =
        dropOffStop.length > 0 && dropOffStop[0].stop_completion_time
            ? new Date(dropOffStop[0].stop_completion_time)
            : null;

    return { stopPickupTime, stopDeliveryTime };
};
