import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { colors } from '@/styles';
import { Container, Card, Header2, Body1 } from './blocks';
import { STAGES } from './constants';

const Result = ({ stage, updated, contactPhone }) => {
    let content;

    switch (stage) {
        case STAGES.AVAILABILITY_SUCCESS:
            content = (
                <>
                    <Grid
                        container
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <Body1>We will reach out soon to let you know your delivery date!</Body1>
                    </Grid>

                    <Grid
                        container
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <Body1>We will reach out to give you your delivery timeframe on the day of delivery</Body1>
                    </Grid>
                </>
            );
            break;
        case STAGES.SCHEDULE_SUCCESS:
            content = (
                <>
                    <Grid
                        container
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <Body1>You are scheduled for delivery</Body1>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <Body1>We will reach out to give you your delivery timeframe on the day of delivery</Body1>
                    </Grid>

                    <Grid
                        container
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <Body1>{`Date: ${updated.delivery_date_formatted}`}</Body1>
                    </Grid>
                </>
            );
            break;
        case STAGES.CONTACT:
            content = (
                <>
                    <Grid
                        container
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <Body1>Next Step:</Body1>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <Body1>Please contact our customer service to schedule a delivery date</Body1>
                    </Grid>
                </>
            );
            break;
        case STAGES.SCHEDULE_FAILED:
            content = (
                <>
                    <Grid
                        container
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <Body1>We’re sorry none of the dates work with your schedule</Body1>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <Body1>Please contact customer service to schedule your delivery</Body1>
                    </Grid>
                </>
            );
            break;
    }

    return (
        <Container
            css={css`
                height: 50%;
            `}
        >
            <Card
                container
                direction="column"
                css={css`
                    margin: 20px 0;
                `}
            >
                <Grid
                    container
                    css={css`
                        margin-bottom: 20px;
                        justify-content: center;
                        text-align: center;
                    `}
                >
                    <Header2>Thank You!</Header2>
                </Grid>
                {content}
                <Grid
                    container
                    css={css`
                        margin-bottom: 8px;
                    `}
                >
                    <Body1>{`Flights of Stairs: ${updated.delivery_stair_quantity}`}</Body1>
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-bottom: 8px;
                    `}
                >
                    <Body1>{`Special Instructions: ${updated.customer_instructions}`}</Body1>
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <Body1>{`Gate Code: ${updated.gate_code}`}</Body1>
                </Grid>
                <Grid
                    container
                    css={css`
                        justify-content: center;
                        text-align: center;
                    `}
                >
                    <Body1>
                        {`Customer Service: `}
                        <a
                            href={`tel:${toE164(contactPhone)}`}
                            css={css`
                                color: ${colors.greens.primary};

                                &:hover {
                                    text-decoration: none;
                                }
                            `}
                        >
                            {toNational(contactPhone)}
                        </a>
                    </Body1>
                </Grid>
            </Card>
        </Container>
    );
};

export default Result;
