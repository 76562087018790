import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import sub from 'date-fns/sub';
import lastDayOfWeek from 'date-fns/lastDayOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import formatISO from 'date-fns/formatISO';
import isValid from 'date-fns/isValid';
// import { DateUtils } from 'react-day-picker';

const defaultOptions = {
    includeWeekDay: false,
    returnWeekDay: false,
    twoDigitYear: false,
};

const dateFns = {};

dateFns.parseDate = (str, format, locale) => {
    const parsed = dateFnsParse(str, format, new Date(), { locale });

    if (isValid(parsed)) {
        return parsed;
    }

    return;
};

dateFns.formatDate = (date, format, locale) => {
    const timestamp = Date.parse(date);
    if (!isNaN(timestamp)) {
        return dateFnsFormat(new Date(date), format, { locale });
    }
    return '---';
};

dateFns.getLastMonth = () => {
    const lastDay = sub(startOfMonth(new Date()), { days: 1 });
    const firstDay = startOfMonth(lastDay);

    return [formatISO(firstDay, { representation: 'date' }), formatISO(lastDay, { representation: 'date' })];
};

dateFns.getLastWeek = () => {
    const lastDay = sub(lastDayOfWeek(new Date()), { days: 7 });
    const firstDay = sub(lastDay, { days: 6 });

    return [formatISO(firstDay, { representation: 'date' }), formatISO(lastDay, { representation: 'date' })];
};
dateFns.getLastSevenDays = () => {
    const lastDay = (new Date());
    const firstDay = sub(lastDay, { days: 7 });

    return [formatISO(firstDay, { representation: 'date' }), formatISO(lastDay, { representation: 'date' })];
};

dateFns.getLastThirtyDays = () => {
    const lastDay = (new Date());
    const firstDay = sub(lastDay, { days: 30 });

    return [formatISO(firstDay, { representation: 'date' }), formatISO(lastDay, { representation: 'date' })];
};
dateFns.parseUnixTimestamp = (ts) => {
    if (!ts || ts.constructor !== Number) return ts;

    const date = new Date(ts);

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
};

dateFns.custom = (date, opts = defaultOptions) => {
    let realDate = date;

    if (!realDate) {
        return;
    }

    let year;
    let month;
    let day;

    if (realDate.constructor !== Date) {
        // expected date format year-month-day

        var formatDate = date.includes('T') ? date.split('T')[0] : date;
        [year, month, day] = formatDate.split('-');

        // decrement operator is applied to month because its
        // zero indexed
        realDate = new Date(+year, +month - 1, +day);
    } else {
        month = realDate.getMonth() + 1;
        day = realDate.getDate();
        year = realDate.getFullYear();
    }

    const weekDay = realDate.toString().slice(0, 3);

    if (opts.returnWeekDay) {
        return weekDay;
    }

    if (opts.twoDigitYear) {
        year = `${year}`.slice(2);
    }

    let formatted = `${month}/${day}/${year}`;

    if (opts.includeWeekDay) {
        formatted = `${weekDay}. ${formatted}`;
    }

    if (opts.separator) {
        formatted = formatted.split('/').join(opts.separator);
    }

    return formatted;
};

dateFns.isoToFormatted = (date) => {
    if (date) {
        // Extract date only and set time to midday
        const dateOnly = date.split('T')[0];
        const middayDate = `${dateOnly}T12:00:00+00:00`;
        return dateFnsFormat(new Date(middayDate), 'MM/dd/yyyy')
    }
    return date;
}

export default dateFns;
