import api from '@/utilities/api';

export const guestToken = () => {
    return api
        .post({
            routeName: 'guestToken',
            data: {},
        })
        .then((resp) => {
            if (!resp.error) {
                return resp;
            }

            return Promise.reject(resp.errors);
        });
};
