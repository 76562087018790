import { gql } from '@apollo/client';
import { BID_FIELDS } from '@/graphql/queries/bids';

export const UPDATE_BID_BY_ID = gql`
    ${BID_FIELDS}

    mutation UpdateBidById($bid_id: uuid!, $update: bids_set_input = {}) {
        update_bids_by_pk(pk_columns: { bid_id: $bid_id }, _set: $update) {
            ...BidFields
        }
    }
`;
