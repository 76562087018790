import React, { createContext, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import * as Sentry from '@sentry/react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useAccessorials } from '@/components/Account/Tariffs/utils';
import { useExport } from '@/components/admin/AdminFinancials/payables/hooks';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import { MARK_AS_PAID } from '@/components/CarrierAccountingInvoice/graphql';

import { QUICKBOOKS_CSV_COLUMNS } from './columns';
import { CARRIER_INVOICE } from './graphql';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { invoice_id } = useParams();

    const { data, loading } = useQuery(CARRIER_INVOICE, {
        variables: { invoice_id },
        skip: !invoice_id,
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
        },
    });

    const [markAsPaid, { loading: markInFlight }] = useMutation(MARK_AS_PAID, {
        variables: { invoice_id },
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
        },
    });

    const invoice = useMemo(() => {
        return data?.result || {};
    }, [data]);

    const [fetchOverrides, { data: resp }] = useLazyQuery(GET_PRICING_OVERRIDES);

    useEffect(() => {
        if (invoice) {
            fetchOverrides({
                variables: {
                    shipper_ids: [invoice.client_id],
                    carrier_ids: [],
                    client_ids: [],
                    partner_client_ids: [],
                    retailer_ids: [],
                },
            });
        }
    }, [invoice]);

    const [type, types] = useMemo(() => {
        if (!resp) {
            return ['DEFAULT', {}];
        }

        const { shipper } = resp;
        const type = shipper.length > 0 ? shipper[0]?.algo_type : 'DEFAULT';

        return [type, Object.fromEntries(invoice.orders.map((order) => [order.order_id, type]))];
    }, [resp, invoice]);

    const [subtotal, adjustments] = useMemo(() => {
        return (invoice?.orders || []).reduce(
            ([subtotalAcc, adjustmentsAcc], order) => {
                const breakdown = 'shipperBreakdown';
                const subtotal = FIXED_CHARGES.map(({ key: attr }) => {
                    return order?.price_breakdown?.[breakdown]?.[attr] || 0;
                }).reduce((acc, val) => {
                    return acc + val;
                }, 0);

                const accessorials = (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce(
                    (acc, { quantity, rate }) => {
                        return acc + quantity * rate;
                    },
                    0
                );

                return [subtotalAcc + subtotal, adjustmentsAcc + accessorials];
            },
            [0, 0]
        );
    }, [invoice]);

    const accessorials = useAccessorials(type);

    const { exportSelected } = useExport({
        accessorials,
        invoices: [invoice],
        columns: QUICKBOOKS_CSV_COLUMNS,
        breakdown: 'shipperBreakdown',
    });

    return (
        <Context.Provider
            value={{
                state: {
                    invoice,
                    subtotal,
                    adjustments,
                    types,
                },
                callbacks: {
                    markAsPaid,
                    exportCSV: exportSelected,
                },
                loading: {
                    init: loading,
                    approve: markInFlight,
                },
                errors: {},
            }}
        >
            {children}
        </Context.Provider>
    );
};
