const dropOffLocation = {
    Business: 'Business',
    Residence: 'Residence',
};

const pickUpLocation = {
    Business: 'Business',
    Residence: 'Residence',
};

const pickUpLocationInfo = {
    Apartment: 'Apartment',
    House: 'House',
};
const dropOffLocationInfo = {
    Apartment: 'Apartment',
    House: 'House',
};
const deliveryTypes = {
    delivery: "Delivery",
    return: "Return",
    exchange: "Exchange",
}

const pickupLocationType = {
    curbside: "Curbside",
    firstDrySpace: "First Dry Space",
    roomOfChoice: "Room of Choice",
    whiteGlove: "White Glove",
    dock: "Dock Delivery",
    inStore: "In Store Delivery",
    warehouse: "Warehouse/Roll Up Door",
    regularInstall: "Installation",
    installAndRemoval: "Installation and Removal",
};

const dropOffLocationType = {
    curbside: "Curbside",
    firstDrySpace: "First Dry Space",
    roomOfChoice: "Room of Choice",
    whiteGlove: "White Glove",
    dock: "Dock Delivery",
    inStore: "In Store Delivery",
    warehouse: "Warehouse/Roll Up Door",
    regularInstall: "Installation",
    installAndRemoval: "Installation and Removal",
};

const deliveryStrings = {
    dropOffLocation,
    pickUpLocation,
    pickUpLocationInfo,
    dropOffLocationInfo,
    pickupLocationType,
    dropOffLocationType,
    deliveryTypes,

};

export default deliveryStrings;
