import { useMutation } from '@apollo/client';
import React, { useContext, useMemo } from 'react';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { UPDATE_ORDERS_MANY } from '../graphql/mutations';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { useClientUser } from '@/hooks/useClientUser';

const OrdersToUnassigned = () => {
    const {
        modalOpen,
        setModalOpen,
        setNotification,
        setError,
        selectedOrders,
        setSelectedOrders,
        state: { orders },
    } = useContext(PlanningContext);
    const { accountType, user_id } = useClientUser();

    const ordersByKey = useMemo(() => {
        return Object.fromEntries(orders.map((order) => [`${order.order_id}_${order.crossdock_leg}`, order]));
    }, [orders]);

    const [updateOrders, { loading }] = useMutation(UPDATE_ORDERS_MANY, {
        update: (cache, { data }) => {
            const mutationResponses = data?.update_orders_many || [];
            mutationResponses.forEach((response) => {
                const removedOrders = response?.returning || [];
                removedOrders.forEach((order) => {
                    cache.evict(cache.identify(order));
                });
            });
        },
    });

    const handleModalClose = () => {
        setModalOpen(null);
    };

    const handleSendSelectedOrdersToOMS = () => {
        const selectedOrderObjs = Object.entries(selectedOrders)
            .filter(([key, selected]) => selected && ordersByKey[key])
            .map(([key]) => ordersByKey[key]);

        const assignedOrders = selectedOrderObjs
            .filter((order) => !!(order.crossdock_leg === 'pickup' ? order.pickup_route_id : order.dropoff_route_id))
            .map((order) => order.order_number);

        if (assignedOrders.length) {
            setNotification({
                severity: 'warning',
                message: `Cannot send assigned orders back to unassigned, please remove these orders from their route first: ${assignedOrders.join(
                    ', '
                )}`,
            });
        } else {
            updateOrders({
                variables: {
                    updates: selectedOrderObjs.map((order) => ({
                        where: { order_id: { _eq: order.order_id } },
                        _set: {
                            ...(order.crossdock_leg === 'pickup'
                                ? {
                                      pickup_date: null,
                                      pickup_planning: false,
                                  }
                                : {
                                      del_window_end: null,
                                      del_window_start: null,
                                      delivery_date: null,
                                      delivery_time_confirmed: null,
                                      original_del_window_end: null,
                                      original_del_window_start: null,
                                      planning: false,
                                  }),
                            order_status: order.oms ? 'pending' : 'claimed',
                        },
                    })),
                },
                onCompleted: () => {
                    handleModalClose();
                    setSelectedOrders({});
                },
                onError: (error) => {
                    setError(error, 'Error sending selected orders back to Unassigned');
                },
            });
        }
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ORDER_OMS} onClose={handleModalClose}>
            <ModalTitle>{`Send all selected orders back to Unassigned?`}</ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <Body2>Are you sure you want to send all selected orders back to Unassigned?</Body2>
                </GridItemRow>
                <GridItemRow>
                    <Body2>
                        Orders:{' '}
                        {Object.entries(selectedOrders)
                            .filter(([_, selected]) => selected)
                            .map(([key]) => ordersByKey[key].order_number)
                            .join(', ')}
                    </Body2>
                </GridItemRow>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={handleSendSelectedOrdersToOMS}>
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default OrdersToUnassigned;
