import React, { useState, useMemo, useContext, useEffect } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { PrimaryButton, Row, GreyBody } from '@/styles/blocks';
import { css } from '@emotion/react';
import { Grid, MenuItem } from '@material-ui/core';
import GoogleMap, { Marker } from '@/components/GoogleMap';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { colors } from '@/styles';
import { asDateInTZ } from '@/utilities/convertToISO';
import { SecondaryContactBtn } from '../InputFields';
import dateFns from '@/utilities/dateFns';

import { useSwappedAttributes } from '../../hooks';
import {
    TextField,
    OnwardToggle,
    RadioLabelInfo,
    ToggleBlurb,
    ErrorText,
    Body2,
    OnwardRadio,
    GreyText,
} from '../../blocks';
import { ModalContext } from './';
import DatePickerModal from '../DatePickerModal';
import { useClientUser } from '@/hooks';
import { Alert } from '@material-ui/lab';

const ContactFields = ({ isDirty, order, attrs, hasError, callbacks, opt }) => {
    const [name, phone, email] = attrs;

    return (
        <Row
            css={css`
                margin-top: 16px;
                align-items: center;
            `}
        >
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                    margin-right: 16px;
                `}
            >
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={order[name]}
                    onBlur={() => callbacks.makeDirty([name])}
                    onChange={(e) => callbacks.modifyOrder({ [name]: e.target.value })}
                    error={hasError[name] && (opt.startDirty || isDirty[name])}
                />
            </Grid>
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                    margin-right: 16px;
                `}
            >
                <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneNumberInput,
                    }}
                    value={toNational(order[phone])}
                    onBlur={() => callbacks.makeDirty([phone])}
                    onChange={(e) => callbacks.modifyOrder({ [phone]: toE164(e.target.value) })}
                    error={hasError[phone] && (opt.startDirty || isDirty[phone])}
                />
            </Grid>
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                `}
            >
                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={order[email]}
                    onBlur={() => callbacks.makeDirty([phone])}
                    onChange={(e) => callbacks.modifyOrder({ [email]: e.target.value })}
                />
            </Grid>
        </Row>
    );
};

const ContactTab = ({ opt }) => {
    const { disableAddressEditing, disableGeocoding, startDirty } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { isDirty, order, hasError, errors } = modalState;
    const { circles, shipping_partners, user_id } = useClientUser();

    const [hasSecondaryContactOverride, setHasSecondaryContact] = useState(null);
    const [viewMapOverride, setViewMap] = useState(false);

    const [openPrefDate, setOpenPrefDate] = useState(false);
    const [openAltDate, setOpenAltDate] = useState(false);
    const [openDelDate, setOpenDelDate] = useState(false);
    const [openDelRangeStart, setOpenDelRangeStart] = useState(false);
    const [openDelRangeEnd, setOpenDelRangeEnd] = useState(false);

    const { is_custom, geocode_failed, address, city, state, street, zip, lat, long, comments, unit } =
        useSwappedAttributes(order);
    const {
        address: pu_address,
        street: pu_street,
        city: pu_city,
        state: pu_state,
        zip: pu_zip,
    } = useSwappedAttributes(order, true);

    const coords = useMemo(() => {
        if (order && lat && long) {
            return order[lat] && order[long] ? { lat: order[lat], lng: order[long] } : null;
        }

        return null;
    }, [order, lat, long]);

    const orderTZ = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [order, zip]);

    const viewMap = useMemo(() => {
        return order[is_custom] || viewMapOverride;
    }, [viewMapOverride, order, is_custom]);

    const allowCustom = useMemo(() => {
        return order[lat] || order[long] || order[geocode_failed];
    }, [order, lat, long, geocode_failed]);

    const hasSecondaryContact = useMemo(() => {
        return hasSecondaryContactOverride !== null
            ? hasSecondaryContactOverride
            : order?.secondary_dropoff_contact_name?.length > 0;
    }, [order, hasSecondaryContactOverride]);

    let addressError = null;
    if (
        [address, street, city, state, zip, lat, long].some(
            (attr) => hasError[attr] && (startDirty || isDirty[attr])
        ) ||
        order[geocode_failed]
    ) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Address is invalid</ErrorText>
            </Grid>
        );
    } else if (
        ['distance', 'miles', 'duration_seconds'].some((attr) => hasError[attr] && (startDirty || isDirty[attr]))
    ) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Failed to find directions between pick up and dropoff address</ErrorText>
            </Grid>
        );
    }

    return (
        <Grid container direction="column">
            {order?.is_middle_mile && (
                <Grid>
                    <Row>
                        <Grid
                            container
                            item
                            css={css`
                                flex: 0;
                                flex-basis: 0;
                                justify-content: center;
                            `}
                        >
                            <OnwardToggle
                                css={css`
                                    margin: 0;
                                `}
                                value={order.palletize_returns}
                                onChange={(e) => {
                                    callbacks.modifyOrder({
                                        palletize_returns: !order?.palletize_returns,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid
                            direction="column"
                            container
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                margin-left: 12px;
                                justify-content: center;
                            `}
                        >
                            <Grid container item>
                                <RadioLabelInfo
                                    css={css`
                                        color: #000;
                                    `}
                                >
                                    Palletize upon return to warehouse (if order is a return)
                                </RadioLabelInfo>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    padding-top: 5px;
                                `}
                            >
                                <GreyText>
                                    Orders must be wrapped, palletized, and dimensions updated by carrier’s warehouse.
                                </GreyText>
                            </Grid>
                        </Grid>
                    </Row>
                </Grid>
            )}
            <ContactFields
                isDirty={isDirty}
                order={order}
                attrs={['dropoff_name', 'dropoff_phone', 'dropoff_email']}
                hasError={hasError}
                callbacks={callbacks}
                opt={opt}
            />

            <Grid
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <SecondaryContactBtn
                    hasSecondaryContact={hasSecondaryContact}
                    onClick={() => {
                        setHasSecondaryContact(!hasSecondaryContact);
                        callbacks.modifyOrder({
                            secondary_dropoff_contact_name: null,
                            secondary_dropoff_contact_phone: null,
                            secondary_dropoff_contact_email: null,
                        });
                    }}
                />
            </Grid>

            {hasSecondaryContact && (
                <ContactFields
                    isDirty={isDirty}
                    order={order}
                    attrs={[
                        'secondary_dropoff_contact_name',
                        'secondary_dropoff_contact_phone',
                        'secondary_dropoff_contact_email',
                    ]}
                    hasError={hasError}
                    callbacks={callbacks}
                    opt={opt}
                />
            )}
            <hr />
            <Row>
                <Grid
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        margin-left: 12px;
                        justify-content: center;
                        align-self: center;
                    `}
                >
                    <Grid container item>
                        <RadioLabelInfo
                            css={css`
                                color: ${hasError?.pickup_scheduling_required ? '#f44336' : '#000'};
                            `}
                        >
                            Carrier required to schedule an appointment for pickup:
                        </RadioLabelInfo>
                    </Grid>
                    <Grid container item>
                        <GreyBody
                            css={css`
                                margin-bottom: 0px;
                            `}
                        >
                            This setting only applies to Onward marketplace orders that
                            <br />
                            are getting picked up from your store or warehouse.
                        </GreyBody>
                    </Grid>
                </Grid>
                <Grid
                    item
                    css={css`
                        justify-content: center;
                        align-items: center;
                        display: flex;
                    `}
                >
                    <OnwardToggle
                        checked={order.pickup_scheduling_required}
                        onChange={(e) => {
                            callbacks.modifyOrder({
                                pickup_scheduling_required: e.target.checked,
                            });
                        }}
                    />
                </Grid>
            </Row>
            <hr
                css={css`
                    margin-top: 0;
                    margin-bottom: 15px;
                `}
            />

            {order?.job_type !== 'WILL_CALL' ? (
                <>
                    {opt.customerAvailabilityToggle && !circles?.['disable-customer-availability'] && (
                        <Row>
                            <Grid
                                container
                                item
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                            >
                                <OnwardToggle
                                    css={css`
                                        margin: 0;
                                    `}
                                    value={!order.known_availability}
                                    onChange={(e) => {
                                        callbacks.modifyOrder({
                                            known_availability: !e.target.checked,
                                            selected_type: e.target.checked ? 'manual' : 'auto',
                                            preferred_delivery_date: null,
                                            alternative_delivery_dates: null,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    margin-left: 12px;
                                    justify-content: center;
                                `}
                            >
                                <Grid container item>
                                    <RadioLabelInfo
                                        css={css`
                                            color: #000;
                                        `}
                                    >
                                        Reach out to customers via text/email to receive preferred alternative delivery
                                        dates.
                                    </RadioLabelInfo>
                                </Grid>
                            </Grid>
                        </Row>
                    )}
                    {(order?.known_availability || !opt.customerAvailabilityToggle) && (
                        <Row>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item xs={6}>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Preferred Delivery"
                                        multiline={false}
                                        disabled={opt.customerAvailabilityToggle && !order.known_availability}
                                        error={hasError.preferred_delivery_date}
                                        value={
                                            order.preferred_delivery_date
                                                ? dateFns.formatDate(
                                                      new Date(order.preferred_delivery_date),
                                                      'MM/dd/yyyy'
                                                  )
                                                : null
                                        }
                                        onClick={() => {
                                            if (!opt.customerAvailabilityToggle || order.known_availability) {
                                                setOpenPrefDate(true);
                                            }
                                        }}
                                    />
                                    <DatePickerModal
                                        open={openPrefDate}
                                        handleClose={() => setOpenPrefDate(false)}
                                        orderTZ={orderTZ}
                                        disabled={opt.customerAvailabilityToggle && !order.known_availability}
                                        onChange={(date) => {
                                            callbacks.modifyOrder({
                                                preferred_delivery_date: date,
                                            });
                                            callbacks.makeDirty(['preferred_delivery_date']);
                                            setOpenPrefDate(false);
                                        }}
                                        selectedDates={order.preferred_delivery_date}
                                        maxSelections={1}
                                        minDate={
                                            order.first_available_date
                                                ? asDateInTZ(order.first_available_date, orderTZ)
                                                : undefined
                                        }
                                    />
                                    {(!opt.customerAvailabilityToggle || order.known_availability) && (
                                        <Body2>Required to send to Onward.</Body2>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Alternative Delivery Dates"
                                        multiline={false}
                                        disabled={opt.customerAvailabilityToggle && !order.known_availability}
                                        error={hasError.alternative_delivery_dates}
                                        value={
                                            order.alternative_delivery_dates
                                                ? order.alternative_delivery_dates
                                                      ?.map((date) => dateFns.formatDate(new Date(date), 'MM/dd/yyyy'))
                                                      .join(', ')
                                                : null
                                        }
                                        onClick={() => {
                                            if (!opt.customerAvailabilityToggle || order.known_availability) {
                                                setOpenAltDate(true);
                                            }
                                        }}
                                    />
                                    {(!opt.customerAvailabilityToggle || order.known_availability) && (
                                        <Body2>3 additional dates required to send to Onward.</Body2>
                                    )}
                                </Grid>
                                <DatePickerModal
                                    open={openAltDate}
                                    handleClose={() => setOpenAltDate(false)}
                                    orderTZ={orderTZ}
                                    disabled={opt.customerAvailabilityToggle && !order.known_availability}
                                    onChange={(dates) => {
                                        callbacks.modifyOrder({
                                            alternative_delivery_dates: dates,
                                        });
                                        callbacks.makeDirty(['alternative_delivery_dates']);
                                    }}
                                    selectedDates={order.alternative_delivery_dates}
                                    maxSelections={21}
                                    minDate={
                                        order.first_available_date
                                            ? asDateInTZ(order.first_available_date, orderTZ)
                                            : undefined
                                    }
                                />
                            </Grid>
                        </Row>
                    )}
                    <Row>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label="Estimated Delivery Start Date (optional)"
                                    multiline={false}
                                    value={
                                        order.estimated_delivery_range_start
                                            ? dateFns.formatDate(
                                                  new Date(order.estimated_delivery_range_start),
                                                  'MM/dd/yyyy'
                                              )
                                            : null
                                    }
                                    onClick={() => {
                                        setOpenDelRangeStart(true);
                                    }}
                                    error={
                                        hasError.estimated_delivery_range_start &&
                                        (startDirty || isDirty.estimated_delivery_range_start)
                                    }
                                />
                                <DatePickerModal
                                    open={openDelRangeStart}
                                    handleClose={() => setOpenDelRangeStart(false)}
                                    orderTZ={orderTZ}
                                    onChange={(date) => {
                                        callbacks.modifyOrder({
                                            estimated_delivery_range_start: date,
                                        });
                                        callbacks.makeDirty(['estimated_delivery_range_start']);
                                        setOpenDelRangeStart(false);
                                    }}
                                    selectedDates={order.estimated_delivery_range_start}
                                    maxSelections={1}
                                    minDate={
                                        order.first_available_date
                                            ? asDateInTZ(order.first_available_date, orderTZ)
                                            : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label="Estimated Delivery End Date (optional)"
                                    multiline={false}
                                    value={
                                        order.estimated_delivery_range_end
                                            ? dateFns.formatDate(
                                                  new Date(order.estimated_delivery_range_end),
                                                  'MM/dd/yyyy'
                                              )
                                            : null
                                    }
                                    onClick={() => {
                                        setOpenDelRangeEnd(true);
                                    }}
                                    error={
                                        hasError.estimated_delivery_range_end &&
                                        (startDirty || isDirty.estimated_delivery_range_end)
                                    }
                                />
                                <DatePickerModal
                                    open={openDelRangeEnd}
                                    handleClose={() => setOpenDelRangeEnd(false)}
                                    orderTZ={orderTZ}
                                    onChange={(date) => {
                                        callbacks.modifyOrder({
                                            estimated_delivery_range_end: date,
                                        });
                                        callbacks.makeDirty(['estimated_delivery_range_end']);
                                        setOpenDelRangeEnd(false);
                                    }}
                                    selectedDates={order.estimated_delivery_range_end}
                                    maxSelections={1}
                                    minDate={
                                        order.first_available_date
                                            ? asDateInTZ(order.first_available_date, orderTZ)
                                            : undefined
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Row>
                    <Row>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label="Scheduled Delivery Date (optional)"
                                    multiline={false}
                                    disabled={order.routes?.length}
                                    value={
                                        order.delivery_date
                                            ? dateFns.formatDate(new Date(order.delivery_date), 'MM/dd/yyyy')
                                            : null
                                    }
                                    onClick={() => {
                                        setOpenDelDate(true);
                                    }}
                                    error={hasError.delivery_date && (startDirty || isDirty.delivery_date)}
                                />
                                <DatePickerModal
                                    open={openDelDate}
                                    handleClose={() => setOpenDelDate(false)}
                                    orderTZ={orderTZ}
                                    onChange={(date) => {
                                        callbacks.modifyOrder({
                                            delivery_date: date,
                                        });
                                        callbacks.makeDirty(['delivery_date']);
                                    }}
                                    selectedDates={order.delivery_date}
                                    maxSelections={1}
                                    minDate={
                                        order.first_available_date
                                            ? asDateInTZ(order.first_available_date, orderTZ)
                                            : undefined
                                    }
                                />
                                {opt.customerAvailabilityToggle &&
                                    !circles?.['disable-customer-availability'] &&
                                    order.delivery_date &&
                                    !order.known_availability && (
                                        <Alert
                                            severity="warning"
                                            css={css`
                                                margin-top: 8px;
                                            `}
                                        >
                                            You have opted to collect the customer's availability via text/email.
                                            Customer will not be contacted if a scheduled delivery date is already set
                                            here.
                                        </Alert>
                                    )}
                                {order.routes?.length > 0 && (
                                    <Alert
                                        severity="warning"
                                        css={css`
                                            margin-top: 8px;
                                        `}
                                    >
                                        Cannot change delivery date of an order that is already assigned to a route.
                                        Remove the order from the route and send it back to unassigned to edit.
                                    </Alert>
                                )}
                            </Grid>

                            {order.oms && shipping_partners.length > 0 ? (
                                <Grid item xs={6}>
                                    <TextField
                                        select
                                        variant="outlined"
                                        fullWidth
                                        label="Shipper"
                                        value={order.shipper_id || ''}
                                        onChange={(e) => {
                                            const shipper_id = e.target.value;
                                            callbacks.modifyOrder({
                                                shipper_id,
                                                carrier_id: shipper_id !== user_id ? user_id : null,
                                            });
                                        }}
                                    >
                                        <MenuItem value={user_id}>None</MenuItem>
                                        {shipping_partners.map((shipper) => (
                                            <MenuItem key={shipper.shipper_id} value={shipper.shipper_id}>
                                                {shipper.shipper.business_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Row>
                    <Row
                        css={css`
                            align-items: center;
                        `}
                        container
                        direction="row"
                    >
                        <TextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            label="Comments"
                            multiline={true}
                            value={order[comments]}
                            onChange={(e) => callbacks.modifyOrder({ [comments]: e.target.value })}
                        />
                    </Row>

                    {allowCustom ? addressError : null}

                    <Row>
                        {order[is_custom] ? (
                            <TextField
                                variant="outlined"
                                color="primary"
                                label="Address"
                                value={order[street]}
                                error={hasError[street] && (startDirty || isDirty[street])}
                                disabled={disableAddressEditing}
                                onBlur={() =>
                                    callbacks.makeDirty([street, ...(order.is_middle_mile ? [pu_street] : [])])
                                }
                                onChange={(e) => {
                                    callbacks.modifyOrder({
                                        [street]: e.target.value,
                                        [geocode_failed]: false,
                                        auto_assign_failed: false,
                                    });
                                    callbacks.makeDirty([
                                        address,
                                        street,
                                        ...(order.is_middle_mile ? [pu_address, pu_street] : []),
                                    ]);
                                }}
                                fullWidth
                            />
                        ) : (
                            <AddressAutocomplete
                                disabled={disableAddressEditing}
                                state={{
                                    street: order[street],
                                    city: order[city],
                                    state: order[state],
                                    zip: order[zip],
                                }}
                                handleAddressUpdate={async (value, split) => {
                                    if (disableGeocoding) {
                                        callbacks.modifyOrder({
                                            [geocode_failed]: false,
                                            [address]: value,
                                            [street]: split.street,
                                            [city]: split.city,
                                            [state]: split.state,
                                            [zip]: split.zip,
                                            auto_assign_failed: false,
                                        });
                                    } else {
                                        const results = await callbacks.enrichOrder(address, value);
                                        if (results.errors.geocodeFailed) {
                                            callbacks.onError('Geocode failed. Please try again.');
                                        }
                                    }

                                    callbacks.makeDirty([
                                        address,
                                        street,
                                        city,
                                        state,
                                        zip,
                                        ...(order.is_middle_mile
                                            ? [pu_address, pu_street, pu_city, pu_state, pu_zip]
                                            : []),
                                    ]);
                                }}
                                error={
                                    [
                                        address,
                                        street,
                                        city,
                                        state,
                                        zip,
                                        lat,
                                        long,
                                        'distance',
                                        'miles',
                                        'duration_seconds',
                                    ].some((attr) => hasError[attr] && (startDirty || isDirty[attr])) ||
                                    order[geocode_failed]
                                }
                            />
                        )}
                    </Row>
                    <Row>
                        <TextField
                            variant="outlined"
                            color="primary"
                            label="Unit/Suite #"
                            value={order[unit]}
                            onChange={(e) => callbacks.modifyOrder({ [unit]: e.target.value })}
                            fullWidth
                        />
                    </Row>
                    <Row>
                        <Grid
                            container
                            item
                            css={css`
                                flex-grow: 1;
                                margin-right: 16px;
                            `}
                        >
                            <TextField
                                fullWidth
                                label="City"
                                variant="outlined"
                                color="primary"
                                value={order[city]}
                                disabled={!order[is_custom]}
                                error={hasError[city] && order[is_custom] && (startDirty || isDirty[city])}
                                onBlur={() => callbacks.makeDirty([city])}
                                onChange={(e) =>
                                    callbacks.modifyOrder({
                                        [city]: e.target.value,
                                    })
                                }
                                css={css`
                                    margin-right: 16px;
                                `}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            css={css`
                                flex-grow: 1;
                                margin-right: 16px;
                            `}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="primary"
                                label="State"
                                disabled={!order[is_custom]}
                                value={order[state]}
                                error={hasError[state] && order[is_custom] && (startDirty || isDirty[state])}
                                onBlur={() => callbacks.makeDirty([state])}
                                onChange={(e) =>
                                    callbacks.modifyOrder({
                                        [state]: e.target.value,
                                    })
                                }
                                css={css`
                                    margin-right: 16px;
                                `}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            css={css`
                                flex-grow: 1;
                            `}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="primary"
                                label="Zip"
                                value={order[zip]}
                                error={hasError[zip] && order[is_custom] && (startDirty || isDirty[zip])}
                                disabled={!order[is_custom]}
                                onBlur={() => callbacks.makeDirty([zip])}
                                onChange={(e) =>
                                    callbacks.modifyOrder({
                                        [zip]: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                    </Row>

                    {allowCustom ? (
                        <Row>
                            <Grid
                                container
                                item
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                            >
                                <OnwardToggle
                                    css={css`
                                        margin: 0;
                                    `}
                                    disabled={disableAddressEditing}
                                    value={order[is_custom] || false}
                                    onChange={(e) => {
                                        callbacks.modifyOrder({
                                            [is_custom]: e.target.checked,
                                            [geocode_failed]: false,
                                        });
                                        callbacks.makeDirty([
                                            address,
                                            street,
                                            ...(order.is_middle_mile ? [pu_address, pu_street] : []),
                                        ]);
                                    }}
                                />
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    margin-left: 12px;
                                    justify-content: center;
                                `}
                            >
                                <Grid container item>
                                    <RadioLabelInfo
                                        css={css`
                                            color: #000;
                                        `}
                                    >
                                        Having trouble with your address?&nbsp;
                                        <a
                                            css={css`
                                                cursor: pointer;
                                            `}
                                            onClick={() => {
                                                callbacks.modifyOrder({
                                                    [is_custom]: true,
                                                    [geocode_failed]: false,
                                                });
                                                callbacks.makeDirty([
                                                    address,
                                                    street,
                                                    ...(order.is_middle_mile ? [pu_address, pu_street] : []),
                                                ]);
                                            }}
                                        >
                                            Place a pin on the map
                                        </a>
                                    </RadioLabelInfo>
                                </Grid>

                                {order[is_custom] ? (
                                    <Grid
                                        container
                                        item
                                        css={css`
                                            margin-top: 8px;
                                        `}
                                    >
                                        <ToggleBlurb />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Row>
                    ) : null}

                    {!order[is_custom] && !disableGeocoding ? (
                        <Grid item>
                            <PrimaryButton
                                css={css`
                                    color: #fff;
                                `}
                                onClick={() => setViewMap((prevState) => !prevState)}
                                variant="contained"
                                color="primary"
                            >
                                {viewMap ? 'Hide Map' : 'Find Address on Map'}
                            </PrimaryButton>
                        </Grid>
                    ) : null}

                    {viewMap && (
                        <Grid
                            item
                            css={css`
                                height: 350px;
                                margin-top: 24px;
                                border-radius: 6px;
                                border: ${hasError[lat] || hasError[long]
                                    ? `1px solid #f44336;`
                                    : '1px solid transparent;'};
                            `}
                        >
                            <GoogleMap
                                zoom={15}
                                onClick={(e) => {
                                    if (disableGeocoding) {
                                        const x = e.latLng.lat();
                                        const y = e.latLng.lng();

                                        callbacks.modifyOrder({
                                            [lat]: x,
                                            [long]: y,
                                        });
                                    } else {
                                        callbacks.geocodeLatLong(e);
                                    }

                                    callbacks.makeDirty([lat, long]);
                                }}
                                draggableCursor="pointer"
                                gestureHandling="greedy"
                                center={coords ? coords : undefined}
                            >
                                {coords && <Marker position={coords} />}
                            </GoogleMap>
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    {order.oms && shipping_partners.length > 0 ? (
                        <Grid item xs={6}>
                            <TextField
                                select
                                variant="outlined"
                                fullWidth
                                label="Shipper"
                                value={order.shipper_id || ''}
                                onChange={(e) => {
                                    const shipper_id = e.target.value;
                                    callbacks.modifyOrder({
                                        shipper_id,
                                        carrier_id: shipper_id !== user_id ? user_id : null,
                                    });
                                }}
                            >
                                <MenuItem value={user_id}>None</MenuItem>
                                {shipping_partners.map((shipper) => (
                                    <MenuItem key={shipper.shipper_id} value={shipper.shipper_id}>
                                        {shipper.shipper.business_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    ) : null}
                </>
            )}
        </Grid>
    );
};

export default ContactTab;
