import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '../queries/orders';

const CANCEL_ORDER_MUTATION = gql`
    mutation cancelOrder($order_ids: [uuid]) {
        update_orders(where: { order_id: { _in: $order_ids } }, _set: { order_status: "cancelled" }) {
            affected_rows
            returning {
                order_id
                order_status
                order_number
            }
        }
    }
`;

const ORDERS_UNASSIGNED_TO_MARKETPLACE_MUTATION = gql`
    ${ORDER_FIELDS}
    mutation ordersUnassignedToMarketplace($order_id: uuid) {
        update_orders(
            where: { order_id: { _eq: $order_id } }
            _set: {
                shipper_notes: null
                claimed_date: null
                delivery_date: null
                pickup_date: null
                pod: null
                delivery_photo: null
                order_status: "pending"
                oms: false
            }
        ) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;

const ORDERS_UNASSIGNED_TO_PLANNING = gql`
    mutation ordersUnassignedToPlanning($order_id: uuid, $delivery_date: timestamptz) {
        update_orders(
            where: { order_id: { _eq: $order_id } }
            _set: { delivery_date: $delivery_date, planning: true }
        ) {
            affected_rows
            returning {
                order_id
                delivery_date
                planning
                order_number
            }
        }
    }
`;

export const UPDATE_ORDER_BY_ID = gql`
    ${ORDER_FIELDS}

    mutation UpdateOrderById($order_id: uuid!, $update: orders_set_input = {}) {
        update_orders_by_pk(pk_columns: { order_id: $order_id }, _set: $update) {
            ...OrderFields
            order_shipper {
                client_id
                business_name
            }
        }
    }
`;

export { CANCEL_ORDER_MUTATION, ORDERS_UNASSIGNED_TO_MARKETPLACE_MUTATION, ORDERS_UNASSIGNED_TO_PLANNING };
