import React, { useState, useRef, useEffect } from 'react';
import { Grid, Button, createTheme, ThemeProvider } from '@material-ui/core';
import { fabric } from 'fabric';
import {
    ResponsiveSidebarDialog,
    ModalTitle,
    ModalContent,
    StickyModalActions,
    PrimaryButton,
    SecondaryButton,
} from '@/styles/blocks';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import * as pdfjsLib from 'pdfjs-dist';
import * as Sentry from '@sentry/react';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDER_BY_ID } from '@/graphql/mutations/orders';
import { css } from '@emotion/react';

function SuperimposeSignatureModal(props) {
    const { order, editable } = props;
    const [open, setOpen] = useState(false);
    const canvasRef = useRef(null);
    const canvasInstance = useRef(null);

    const checkType = async (fileSource) => {
        try {
            const res = await fetch(fileSource.url ? fileSource.url : fileSource);
            const blob = await res.blob();

            if (blob.type === 'application/pdf') {
                return 'pdf';
            } else if (blob.type === 'image/jpeg' || blob.type === 'image/png') {
                return 'image';
            } else return blob.type;
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    };

    const [saveImage] = useMutation(UPDATE_ORDER_BY_ID, {
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
    });

    useEffect(() => {
        if (open && order.bol_file.length && order.customer_signature) {
            const initCanvas = async () => {
                const canvas = new fabric.Canvas(canvasRef.current);
                canvasInstance.current = canvas;

                const bolFileType = await checkType(order.bol_file[0]);

                if (bolFileType === 'pdf') {
                    const loadingTask = pdfjsLib.getDocument(order.bol_file[0]);
                    const pdf = await loadingTask.promise;
                    const page = await pdf.getPage(1);
                    const viewport = page.getViewport({ scale: 1.5 });
                    const canvasEl = document.createElement('canvas');
                    const context = canvasEl.getContext('2d');
                    canvasEl.height = viewport.height;
                    canvasEl.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                    };
                    await page.render(renderContext).promise;

                    const img = new fabric.Image(canvasEl);
                    canvas.setWidth(img.width);
                    canvas.setHeight(img.height);
                    canvas.add(img);

                    addSignature(canvas, order.customer_signature);
                } else {
                    fabric.Image.fromURL(
                        order.bol_file[0],
                        (img) => {
                            canvas.setWidth(img.width);
                            canvas.setHeight(img.height);
                            canvas.add(img);

                            addSignature(canvas, order.customer_signature);
                        },
                        { crossOrigin: 'anonymous' }
                    );
                }
            };

            requestAnimationFrame(initCanvas);

            return () => {
                if (canvasInstance.current) {
                    canvasInstance.current.dispose();
                    canvasInstance.current = null;
                }
            };
        }
    }, [open, order.bol_file, order.customer_signature]);

    const addSignature = (canvas, signatureUrl) => {
        fabric.Image.fromURL(
            signatureUrl,
            (sigImg) => {
                sigImg.set({
                    left: 100,
                    top: 100,
                    scaleX: 0.5,
                    scaleY: 0.5,
                });
                canvas.add(sigImg);
                canvas.bringToFront(sigImg);
                canvas.renderAll();
            },
            { crossOrigin: 'anonymous' }
        );
    };

    const handleSave = async () => {
        if (canvasInstance.current) {
            const dataUrl = canvasInstance.current.toDataURL({
                format: 'png',
                quality: 1,
            });

            const blob = await (await fetch(dataUrl)).blob();
            const objectUrl = URL.createObjectURL(blob);

            try {
                const fbFileUrl = await uploadPhotoAsync(objectUrl, 'bol_file', 'png');
                saveImage({
                    variables: {
                        order_id: order.order_id,
                        update: {
                            bol_file: [...order.bol_file, fbFileUrl],
                        },
                    },
                });

                setOpen(false);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const theme = createTheme({
        palette: {
            text: {
                secondary: '#4c4c4c',
            },
            primary: {
                main: '#59b863',
                contrastText: '#fff',
            },
            secondary: {
                main: '#4c4c4c',
                contrastText: '#fff',
            },
        },
        typography: {
            fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
            h6: {
                color: '#8d8d8d',
                fontWeight: '850',
            },
            body1: {
                color: '#878686',
                fontWeight: '530',
                fontSize: 14,
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Grid item>
                <Button
                    style={{
                        padding: '5px',
                        marginTop: '10px',
                    }}
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => setOpen(true)}
                >
                    Superimpose Signature on to BOL
                </Button>
            </Grid>
            <ResponsiveSidebarDialog open={open} onClose={() => setOpen(false)}>
                <ModalTitle title="Add signature to image" onClose={() => setOpen(false)} />
                <ModalContent width={1000}>
                    <Grid className="mb-3" alignItems="center" container spacing={2}>
                        <canvas
                            css={css`
                                border: 1px solid black;
                            `}
                            ref={canvasRef}
                        />
                    </Grid>
                </ModalContent>
                <StickyModalActions>
                    <SecondaryButton onClick={() => setOpen(false)}>Cancel</SecondaryButton>
                    <PrimaryButton onClick={handleSave}>Save</PrimaryButton>
                </StickyModalActions>
            </ResponsiveSidebarDialog>
        </ThemeProvider>
    );
}

export default SuperimposeSignatureModal;
