import debounce from 'lodash/debounce';
import { useClientUser } from '@/hooks';
import { useLazyQuery, useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import { GET_ALL_ORDER_SHIPPERS, GET_ORDERS_INVENTORY } from './graphql/queries';
import { useTableColumns } from './columns';
import { formatInTimeZone } from 'date-fns-tz';
import csvDownload from 'json-to-csv-export';
import { INVENTORY_CSV_COLUMNS, INVENTORY_TABS } from './constants';
import { genAttributes } from '@onward-delivery/core';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const { user_id } = useClientUser();

    const [modal, setModal] = useState(null);
    const [notification, setNotification] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedSku, setSelectedSku] = useState(null);
    const [filters, setFilters] = useState([]);
    const SEARCHABLE = [
        'order_number',
        'po_number',
        'dropoff_phone',
        'dropoff_name',
        'itemsByOrderId.sku',
        'itemsByOrderId.description',
        'itemsByOrderId.pallet.pallet_name',
        'order_shipper.business_name',
    ];

    const where = useMemo(() => {
        const query = [
            {
                _or: [{ carrier_id: { _eq: user_id } }, { shipper_id: { _eq: user_id } }],
            },
            { _or: [{ wh_events: { action: { _ilike: `START:RECEIVING` } } }, { job_type: { _ilike: 'WILL_CALL' } }] },
        ];

        if (search) {
            query.push({
                _or: SEARCHABLE.map((attr) => {
                    if (attr.includes('.')) {
                        const parts = attr.split('.');
                        return parts.reduceRight((acc, part, index) => {
                            if (index === parts.length - 1) {
                                return { [part]: { _ilike: `%${search.trim()}%` } };
                            }
                            return { [part]: acc };
                        }, {});
                    }
                    return { [attr]: { _ilike: `%${search.trim()}%` } };
                }),
            });
        }

        if (INVENTORY_TABS[tabIndex].itemQuery) {
            query.push(INVENTORY_TABS[tabIndex].itemQuery);
        }
        if (INVENTORY_TABS[tabIndex].orderQuery) {
            query.push(INVENTORY_TABS[tabIndex].orderQuery);
        }

        return { _and: [...query, ...filters] };
    }, [search, filters, tabIndex]);

    const [getOrders, { data, loading: initInflight, fetchMore }] = useLazyQuery(GET_ORDERS_INVENTORY, {
        variables: {
            client_id: user_id,
            where: where,
        },
        onError: (e) => {
            console.log(e);
            captureException(e);
        },
    });

    const { data: shipperData } = useQuery(GET_ALL_ORDER_SHIPPERS, {
        variables: {
            user_id: user_id,
        },
        onError: (e) => {
            console.error(e);
            captureException(e);
        },
    });

    const shipperOptions = useMemo(() => {
        return Object.fromEntries(
            (shipperData?.orders || [])
                .map((o) => [o.shipper_id, o.order_shipper.business_name])
                .filter(([shipper_id]) => shipper_id && shipper_id !== user_id)
        );
    }, [shipperData]);

    const orders = useMemo(() => {
        if (!data?.orders) return [];

        const uniqueOrders = new Map();
        data.orders.forEach((order) => {
            if (!uniqueOrders.has(order.order_id)) {
                uniqueOrders.set(order.order_id, order);
            }
        });

        return Array.from(uniqueOrders.values());
    }, [data]);

    const itemsBySku = useMemo(() => {
        const skuMap = orders.reduce((acc, order) => {
            const { zip: orderZip } = genAttributes(order);
            let nullSkuCounter = 1;

            order.itemsByOrderId.forEach((item) => {
                let sku = item.sku;

                if (!sku) {
                    sku = `no_sku_${nullSkuCounter++}`;
                }
                const uniqueKey = `${order.order_id}_${sku}`;
                if (acc.has(uniqueKey)) {
                    const existingItem = acc.get(uniqueKey);
                    existingItem.totalQuantity += item.quantity;
                    existingItem.receivedQuantity +=
                        item.pallet && item.pallet.warehouse_status !== 'NOT_RECEIVED' ? item.quantity : 0;
                    existingItem.outQuantity +=
                        item.pallet &&
                        (item.pallet.warehouse_status === 'STAGED' || item.pallet.warehouse_status === 'PICKED_UP')
                            ? item.quantity
                            : 0;
                    if (item.pallet && !existingItem.palletIds.has(item.pallet.pallet_id)) {
                        existingItem.palletIds.add(item.pallet.pallet_id);
                        existingItem.pallets += 1;
                    }
                    if (
                        item.pallet &&
                        item.pallet.warehouse_location &&
                        !existingItem.wh_locations.has(item.pallet.warehouse_location)
                    ) {
                        existingItem.wh_locations.add(item.pallet.warehouse_location);
                    }
                    if (item.pallet && item.pallet.logs) {
                        item.pallet.logs.forEach((log) => {
                            existingItem.palletLogs.push({
                                ...log,
                                quantity: item.quantity,
                                item_id: item.item_id,
                                pallet_label: item.pallet.pallet_name || item.pallet.pallet_number,
                            });
                        });
                    }

                    if (item.pallet && item.pallet.warehouse) {
                        existingItem.tzZip = item.pallet.warehouse.business_zip;
                    }

                    item.manifests.forEach((manifestMapping) => {
                        const manifest = manifestMapping.manifest;
                        if (manifest) {
                            if (['INBOUND', 'CROSS_DOCK'].includes(manifest.type)) {
                                existingItem.dateIn = manifest.receiving_date
                                    ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                                    : '-';
                                if (!existingItem.inboundManifests.includes(manifest.manifest_number)) {
                                    existingItem.inboundManifests.push(manifest.manifest_number);
                                }
                            } else if (['OUTBOUND', 'RETURN_TO_SENDER', 'WILL_CALL'].includes(manifest.type)) {
                                existingItem.dateOut =
                                    manifest?.route?.scheduled_delivery_formatted ||
                                    (manifest.receiving_date
                                        ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                                        : '-');
                                if (!existingItem.outboundManifests.includes(manifest.manifest_number)) {
                                    existingItem.outboundManifests.push(manifest.manifest_number);
                                }
                            }
                        }
                    });
                } else {
                    const newItem = {
                        sku: sku,
                        status: item.item_sku_status,
                        totalQuantity: item.quantity,
                        receivedQuantity:
                            item.pallet && item.pallet.warehouse_status !== 'NOT_RECEIVED' ? item.quantity : 0,
                        outQuantity:
                            item.pallet &&
                            (item.pallet.warehouse_status === 'STAGED' || item.pallet.warehouse_status === 'PICKED_UP')
                                ? item.quantity
                                : 0,
                        order_number: order.order_number,
                        po_number: order.po_number,
                        shipper: order.order_shipper?.business_name || '-',
                        dateIn: '-',
                        dateOut: '-',
                        inboundManifests: [],
                        outboundManifests: [],
                        pallets: item.pallet ? 1 : 0,
                        palletIds: new Set(item.pallet ? [item.pallet.pallet_id] : []),
                        wh_locations: new Set(item.pallet?.warehouse_location ? [item.pallet.warehouse_location] : []),
                        palletLogs: item.pallet?.logs
                            ? item.pallet.logs.map((log) => ({
                                  ...log,
                                  quantity: item.quantity,
                                  item_id: item.item_id,
                                  pallet_label: item.pallet.pallet_name || item.pallet.pallet_number,
                              }))
                            : [],
                        tzZip: item.pallet?.warehouse ? item.pallet.warehouse.business_zip : orderZip,
                    };

                    item.manifests.forEach((manifestMapping) => {
                        const manifest = manifestMapping.manifest;
                        if (manifest) {
                            if (['INBOUND', 'CROSS_DOCK'].includes(manifest.type)) {
                                newItem.dateIn = manifest.receiving_date
                                    ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                                    : '-';
                                if (!newItem.inboundManifests.includes(manifest.manifest_number)) {
                                    newItem.inboundManifests.push(manifest.manifest_number);
                                }
                            } else if (['OUTBOUND', 'RETURN_TO_SENDER', 'WILL_CALL'].includes(manifest.type)) {
                                newItem.dateOut =
                                    manifest?.route?.scheduled_delivery_formatted ||
                                    (manifest.receiving_date
                                        ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                                        : '-');
                                if (!newItem.outboundManifests.includes(manifest.manifest_number)) {
                                    newItem.outboundManifests.push(manifest.manifest_number);
                                }
                            }
                        }
                    });

                    acc.set(uniqueKey, newItem);
                }
            });
            return acc;
        }, new Map());

        const filteredItems = Array.from(skuMap.values()).map((item) => {
            item.palletLogs.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            item.wh_locations = Array.from(item.wh_locations);
            delete item.palletIds;
            delete item.palletStatuses;
            return item;
        });

        if (INVENTORY_TABS[tabIndex].value !== 0) {
            return filteredItems.filter(
                (item) => item.status === INVENTORY_TABS[tabIndex].itemQuery.itemsByOrderId.item_sku_status._eq
            );
        }

        return filteredItems;
    }, [orders]);

    const getOrdersDebounced = useMemo(
        () =>
            debounce((payload) => {
                return getOrders(payload);
            }, 500),
        []
    );

    useEffect(() => {
        getOrders({
            variables: {
                where: {
                    _and: where,
                },
            },
        });
    }, []);

    useEffect(() => {
        if (!initInflight) {
            getOrdersDebounced({
                variables: {
                    where: {
                        _and: where,
                    },
                },
            });

            setHasMore(true);
        }
    }, [getOrdersDebounced, initInflight, where]);

    const cursor = useMemo(() => {
        if (orders.length === 0) {
            return null;
        }
        return orders[orders.length - 1].created_at;
    }, [orders]);

    const loadMore = useCallback(() => {
        fetchMore({
            variables: {
                where: {
                    _and: [where, { created_at: { _lte: cursor } }],
                },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                const newResults = fetchMoreResult.orders;
                return {
                    ...prev,
                    orders: Array.isArray(prev.orders) ? [...prev.orders, ...newResults] : newResults,
                };
            },
        }).then((result) => {
            if (result.data.orders.length < 30) {
                setHasMore(false);
            }
        });
    }, [where, cursor, fetchMore]);

    const exportCsv = useCallback(() => {
        const rowData = itemsBySku.flatMap((skuItem) => {
            if (skuItem.palletLogs.length === 0) {
                return [
                    {
                        sku: skuItem.sku,
                        order_number: skuItem.order_number,
                        po_number: skuItem.po_number,
                        totalQuantity: skuItem.totalQuantity,
                        action: null,
                        pallet: null,
                        warehouse_location: null,
                        qtyIn: null,
                        qtyOut: null,
                        timestamp: null,
                        shipper: skuItem.shipper,
                    },
                ];
            }

            let runningQtyIn = 0;
            let runningQtyOut = 0;

            return skuItem.palletLogs.map((log) => {
                let qtyIn = null;
                let qtyOut = null;

                if (log.warehouse_status === 'RECEIVED') {
                    runningQtyIn += log.quantity;
                    qtyIn = runningQtyIn;
                    qtyOut = runningQtyOut;
                } else if (log.warehouse_status === 'STAGED' || log.warehouse_status === 'PICKED_UP') {
                    runningQtyOut += log.quantity;
                    qtyOut = runningQtyOut;
                    qtyIn = runningQtyIn;
                } else {
                    qtyOut = runningQtyOut;
                    qtyIn = runningQtyIn;
                }

                return {
                    sku: skuItem.sku,
                    logId: log.log_id,
                    order_number: skuItem.order_number,
                    po_number: skuItem.po_number,
                    totalQuantity: skuItem.totalQuantity,
                    action: log.warehouse_status,
                    pallet: log.pallet_label,
                    warehouse_location: log.warehouse_location,
                    qtyIn,
                    qtyOut,
                    timestamp: log.created_at,
                    tzZip: skuItem.tzZip,
                };
            });
        });

        csvDownload({
            headers: INVENTORY_CSV_COLUMNS.map((col) => col.header),
            data: rowData.map((row) => INVENTORY_CSV_COLUMNS.map((col) => col.value(row))),
            filename: `onward-inventory-${new Date().toISOString()}.csv`,
            delimiter: ',',
        });
    });

    const callbacks = {
        setTabIndex,
        setNotification,
        setModal,
        loadMore,
        exportCsv,
        setSearch,
        setSelectedSku,
        setFilters,
        hasMore,
    };

    const columns = useTableColumns({
        callbacks,
    });

    return (
        <Context.Provider
            value={{
                state: {
                    tabIndex,
                    itemsBySku,
                    orders,
                    notification,
                    modal,
                    columns,
                    hasMore,
                    search,
                    selectedSku,
                    shipperOptions,
                    filters,
                },
                loading: {
                    init: initInflight,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
