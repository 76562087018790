import React from 'react';
import { Grid } from '@material-ui/core';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import { Body1, Body2, Header1, Subtitle1 } from './blocks';
import { LOCATION_TYPES } from '@/constants/locationTypes';

const OrderExtra = ({ order }) => {
    const { address, location, location_info, location_type } = useSwappedAttributes(order);

    const displayDescriptions = order.order_shipper?.user?.circles?.['customer-tracking-descriptions'] || true;
    const deliveryTypeDescriptions = {
        whiteGlove: '',
        curbside:
            'Our drivers will place your items outside of your residence. Common locations to deliver items include - next to the garage, front porch, or a covered area near the exterior of the building. You are not required to be home.',
        firstDrySpace:
            'Our drivers will place the items in your garage or the first dry space of the residence. 95% of deliveries are placed in the garage. It’s best to leave space for a small compact car, although this is not always necessary. Signatures to confirm delivery are required so please be home to accept your delivery.',
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Header1>Customer Information</Header1>
            </Grid>
            <Grid item xs={12}>
                <Body1>{order.dropoff_name}</Body1>
            </Grid>
            <Grid item xs={12}>
                <Body1>{order[address]}</Body1>
            </Grid>
            <Grid item xs={12}>
                <Body1>
                    Location Type:{' '}
                    {`${order[location] || ''}${order[location_info] ? `, ${order[location_info]}` : ''}`}
                </Body1>
            </Grid>
            {order[location_type] && (
                <Grid item xs={12}>
                    <Body1>
                        Service Level: {`${LOCATION_TYPES[order[location_type]]}`}
                        {displayDescriptions && deliveryTypeDescriptions[order[location_type]] && (
                            <Subtitle1>: {deliveryTypeDescriptions[order[location_type]]}</Subtitle1>
                        )}
                    </Body1>
                </Grid>
            )}
            {order.dropoff_email && (
                <Grid item xs={12}>
                    <Body1>Email: {order.dropoff_email}</Body1>
                </Grid>
            )}
            {order.dropoff_phone && (
                <Grid item xs={12}>
                    <Body1>Phone: {order.dropoff_phone}</Body1>
                </Grid>
            )}
            {order.dropoff_comments && (
                <Grid item xs={12}>
                    <Body1>{'Comments:' + order.dropoff_comments}</Body1>
                </Grid>
            )}
        </Grid>
    );
};

export default OrderExtra;
