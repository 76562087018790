import { gql } from '@apollo/client';

export const COMBINED_RATINGS_BY_ID = gql`
    query CombinedRatingsById($client_ids: [uuid!]!) {
        clients(where: { client_id: { _in: $client_ids } }) {
            client_id
            google_rating
            ratings_aggregate {
                aggregate {
                    avg {
                        rating
                    }
                }
            }
        }
    }
`;
