import React from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';
import { FILTERS } from './constants';

function Footer({ state, loading, callbacks }) {
    return (
        <>
            <Grid
                container
                css={css`
                    padding: 1rem;
                    background-color: white;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-right: 16px;
                    `}
                >
                    <PrimaryButton
                        loading={loading.exportCsv}
                        onClick={callbacks.exportCsv}
                        disabled={state.selected.length === 0}
                    >
                        Export
                    </PrimaryButton>
                </Grid>
                {state.filter.status === FILTERS.SUBMITTED ? (
                    <Grid item>
                        <PrimaryButton
                            loading={loading.paid}
                            onClick={callbacks.setApproved}
                            disabled={state.selected.length === 0}
                        >
                            Approve
                        </PrimaryButton>
                    </Grid>
                ) : null}
                {state.filter.status === FILTERS.APPROVED ? (
                    <Grid item>
                        <PrimaryButton
                            loading={loading.paid}
                            onClick={callbacks.setPaid}
                            disabled={state.selected.length === 0}
                        >
                            Mark as Paid
                        </PrimaryButton>
                    </Grid>
                ) : null}
            </Grid>
        </>
    );
}

export default Footer;
