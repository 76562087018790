import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { typography } from '@/styles';
import { colors } from '@/styles';
import { Card } from '@material-ui/core';

export const LocationPermissionsTitleSpan = styled.span`
    font-family: Montserrat, Roboto, Arial;
    font-weight: 800;
    font-size: 20px;
    line-height: 24.38px;
    color: #2b2b2b;
`;

export const LocationPermissionsTitle = styled.div`
    margin-bottom: 23px;
`;

const colorMap = {
    granted: `${colors.greens.primary}`,
    Undetermined: '#FF903D',
    denied: `${colors.reds.primary}`,
};

export const LocationPermissionsText = styled.div`
    font-family: Montserrat, Roboto, Arial;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #2b2b2b;
    color: ${(props) => {
        return colorMap[props.text];
    }};
`;

export const CardSubtext = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: #2b2b2b;
`;

export const TabCard = styled((props) => <Card {...props} />)`
    border-top: 0;
`;

export const Title = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;
