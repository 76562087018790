import { gql } from '@apollo/client';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const SHIPPER_PARTNERS = gql`
    ${CLIENT_FIELDS}

    query ShipperPartners($allow_test: clients_bool_exp!) {
        results: clients(
            where: $allow_test
            order_by: { business_name: asc_nulls_last }
        ) {
            ...ClientFields
        }
    }
`;
