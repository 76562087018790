import React from 'react';
import { Typography } from '@material-ui/core';
import { exceptionReportedAtMap } from '@/constants/exceptionConstants';
import { css } from '@emotion/react';
import Image from 'react-bootstrap/Image';

import '../style.css';

const Exception = ({ exception, item }) => {
    return (
        <>
            {exception?.reported_at && (
                <Typography className="exception-source">
                    Reported at {exceptionReportedAtMap[exception?.reported_at]}
                </Typography>
            )}

            {item && (
                <Typography className="exception-item-desc">Item: {item?.description || ''}</Typography>
            )}

            {exception?.reason && (
                <Typography className="exception-text">Reason: {exception.reason}</Typography>
            )}

            {exception?.notes && (
                <Typography className="exception-text">{exception.notes}</Typography>
            )}

            {!!exception?.images?.length && (
                <div style={{ display: 'flex', paddingBottom: '1rem', flexWrap: 'wrap', marginTop: '20px'}}>
                    {exception?.images?.map((imgSrc) => (
                        <div
                            css={css`
                                margin-right: 10px;
                                margin-bottom: 10px;
                                border: 1px solid #A6A6A6;
                            `}
                        >
                            <a href={imgSrc.url || imgSrc} target="_blank">
                                <Image
                                    style={{
                                        height: '148px',
                                        width: '148px',
                                    }}
                                    src={imgSrc.url || imgSrc}
                                />
                            </a>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}

export default Exception;
