import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
    Checkbox,
    FormControlLabel,
    Radio,
    Switch,
    Button,
    TextField as MuiTextField,
    Select as MuiSelect,
    Grid,
    Slider,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { colors } from '@/styles/';
import * as typography from '@/styles/typography';
import { Body, GreyBody, OnwardSwitch } from '@/styles/blocks';
import BidIcon from '../misc/BidIcon';
import { CustomMuiDatePicker, PriceInput } from './modals/InputFields';
import { addDays, subDays } from 'date-fns';

export const TextField = styled(({ value, defaultValue, children, ...props }) => {
    return (
        <MuiTextField
            value={value}
            defaultValue={defaultValue}
            inputProps={{
                min: props.min ? props.min : 0,
            }}
            onFocus={(e) => {
                if (e.target.select) {
                    e.target.select();
                }
            }}
            InputLabelProps={!!defaultValue ? undefined : { shrink: value !== undefined && value !== null }}
            {...props}
        >
            {children}
        </MuiTextField>
    );
})``;

export const Select = styled(({ children, ...props }) => <MuiSelect {...props}>{children}</MuiSelect>)`
    & .MuiSelect-root {
        padding: 8px 12px;
    }
`;

export const PrimaryButton = styled((props) => <Button {...props} />)`
    ${typography.bold}
    ${typography.sansSerif}

    background: ${colors.greens.primary};
    color: ${colors.white.primary};
    text-transform: none;

    &:hover {
        background: #3e8045;
    }
`;

export const H1 = styled.span`
    ${typography.sansSerif}

    font-weight: 900;
    font-size: 20px;
    line-height: 24px;

    color: ${colors.greys[2]};
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 600;
    font-size: 17px;
    line-height: 21px;

    color: ${colors.greys[1]};
`;

export const Body2 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: ${colors.greys[1]};
`;

export const RadioLabelInfo = styled.span`
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: ${colors.greys.primary};
`;

export const ToggleInfo = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
`;

export const ToggleBlurb = () => {
    return (
        <ToggleInfo
            css={css`
                color: ${colors.greys[0]};
            `}
        >
            Allows free form address entry. Enter address and place pin anywhere on map. Use when:
            <ul
                css={css`
                    margin: 0;
                `}
            >
                <li>Shipping to new housing development</li>
                <li>Google pin placement is wrong from address entered</li>
            </ul>
        </ToggleInfo>
    );
};

const RadioLabel = styled(({ label, info, ...props }) => (
    <span {...props}>
        <>
            {label}
            {info && <RadioLabelInfo>{` ${info} `}</RadioLabelInfo>}
        </>
    </span>
))`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
`;

export const OnwardRadio = styled(({ label, value, info, ...props }) => (
    <FormControlLabel value={value} control={<Radio />} label={<RadioLabel label={label} info={info} />} {...props} />
))`
    & .Mui-checked {
        color: ${colors.greens.primary};
    }
`;

export const OnwardCheckbox = styled(({ label, checked, onChange, labelPlacement = 'end', ...props }) => (
    <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        control={<Checkbox color="primary" checked={checked} onChange={onChange} />}
        {...props}
    />
))`
    margin: 0;
    & .MuiIconButton-root {
        padding: 4px;
    }

    & .MuiSvgIcon-root {
        height: 30px;
        width: 30px;
    }

    & .Mui-checked {
        color: ${colors.greens.primary};
    }

    & [class*='MuiFormControlLabel-label'] {
        ${typography.sansSerif}
        font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
        font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
    }
`;

export const Incrementor = styled((props) => (
    <Grid
        container
        item
        css={css`
            align-items: center;
        `}
        {...props}
    >
        <RemoveCircleIcon
            onClick={props.decrementCallback}
            css={css`
                margin-right: 8px;
                font-size: 30px;
            `}
        />
        <Body1>{props.body}</Body1>
        <AddCircleIcon
            onClick={props.incrementCallback}
            css={css`
                margin-left: 8px;
                font-size: 30px;
            `}
        />
    </Grid>
))`
    & svg {
        cursor: pointer;
        color: ${colors.greens.primary};
    }
`;

export const OnwardToggle = styled(({ label, value, info, ...props }) => (
    <Switch padding={2} checked={value} {...props} />
))`
    &.MuiSwitch-root {
        width: 51px;
        height: 24px;
        padding: 0;
        margin: 1px;
    }

    & .MuiSwitch-switchBase {
        padding: 2px 0px 0px 3px;
    }

    & .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(26px);
        color: ${colors.greens.primary} !important;
    }

    & .MuiIconButton-label {
        color: white;
    }

    & .Mui-checked {
        color: ${colors.greens.primary} !important;
    }

    & .MuiSwitch-thumb {
        box-shadow: none;
        width: 20px;
        height: 20px;
    }

    & .MuiSwitch-track {
        background-color: ${colors.greys.primary} !important;
        border-color: ${colors.greens.primary} !important;
        opacity: 1 !important;
        border-radius: 12px;
    }

    & .Mui-checked + .MuiSwitch-track {
        background-color: ${colors.greens.primary} !important;
    }
`;

export const ErrorText = styled.span`
    ${typography.sansSerif}

    font-weight: 600;
    font-size: 14px;
`;

export const GreyText = styled.span`
    ${typography.sansSerif}

    color: ${colors.greys.primary} !important;

    font-weight: 500;
    font-size: 16px;
    line-height: 19.5px;
`;

export const PriceText = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 18px;
`;

export const TableBody = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;

export const TableHeader = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
`;

export const BiddingPrice = ({ value, sliderMax, sliderSuggested, sliderMin = 0, onChange, label, ...props }) => {
    return (
        <div {...props}>
            <Grid
                item
                container
                xs={12}
                className="mt-3"
                css={css`
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid item>
                    <Body>{label || 'Claim Now Price'}</Body>
                </Grid>
                <Grid item>
                    <PriceInput
                        label=""
                        value={value || 0}
                        onChange={(val) => onChange(val)}
                        css={css`
                            max-width: 120px;
                        `}
                    />
                    {/* <PriceText>{value || value === 0 ? `$${value.toFixed(2)}` : '--'}</PriceText> */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Slider
                    aria-label="Claim Now Price"
                    value={value || 0}
                    min={sliderMin}
                    max={sliderMax}
                    marks={sliderSuggested ? [{ value: sliderSuggested, label: `Suggested Price` }] : []}
                    getAriaValueText={(val) => `$${val?.toFixed(2)}`}
                    valueLabelDisplay="auto"
                    onChange={(e, val) => onChange(val)}
                />
            </Grid>
        </div>
    );
};

export const BiddingSwitch = ({ checked, onChange }) => {
    return (
        <>
            <Grid item xs={12} className="mt-3">
                <OnwardToggle
                    css={css`
                        margin: 0;
                    `}
                    value={checked}
                    onChange={onChange}
                />
                <BidIcon />
                <Body>Allow bidding</Body>
            </Grid>
            <Grid item xs={12}>
                <GreyBody>Carriers may place a bid on an order if they do not like the claim now price.</GreyBody>
            </Grid>
        </>
    );
};

export const BiddingDate = ({ selectedDate, tz = 'America/New_York', onChange }) => {
    return (
        <>
            <Grid item xs={12} className="mt-3">
                <CustomMuiDatePicker
                    label={'Expiration Date'}
                    selectedDate={selectedDate ? subDays(new Date(selectedDate), 1).toISOString() : null}
                    timeZone={tz}
                    onChange={(val) => {
                        const addDayforDb = addDays(new Date(val), 1).toISOString();
                        onChange(addDayforDb);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <GreyBody>Carriers can no longer bid or claim after this date</GreyBody>
            </Grid>
        </>
    );
};
