import React, { useState, createContext } from 'react';

export const Context = createContext();

const Provider = (props) => {
    const [carrierReviewOpen, setCarrierReviewOpen] = useState(null);
    const [PODModalOpen, setPODModalOpen] = useState(false);
    const [exceptionsModalOpen, setsExceptionsModalOpen] = useState(false);
    const [upgradeDialog, setUpgradeDialog] = useState(null);

    const closeCarrierReviewModal = () => {
        setCarrierReviewOpen(false);
    };
    const openCarrierReviewModal = () => {
        setCarrierReviewOpen(true);
    };

    const closeExceptionsModal = () => {
        setsExceptionsModalOpen(false);
    };
    const openExceptionsModal = () => {
        setsExceptionsModalOpen(true);
    };

    const closePODModal = () => {
        setPODModalOpen(false);
    };
    const openPODModal = () => {
        setPODModalOpen(true);
    };

    const closeUpgradeDialog = () => {
        setUpgradeDialog(null);
    }

    return (
        <Context.Provider
            value={{
                carrierReviewOpenOverride: carrierReviewOpen,
                closeCarrierReviewModal,
                openCarrierReviewModal,
                PODModalOpen,
                exceptionsModalOpen,
                openExceptionsModal,
                closeExceptionsModal,
                openPODModal,
                closePODModal,
                upgradeDialogOpen: !!upgradeDialog,
                upgradeDialog,
                setUpgradeDialog,
                closeUpgradeDialog,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default Provider;
