export const hoursOpenArray = [
    {day: 'Sunday',
    close: "closed",
    start: "closed",
    day_num: 0
    },
    {day: 'Monday',
    close: "closed",
    start: "closed",
    day_num: 1
    },
    {day: 'Tuesday',
    close: "closed",
    start: "closed",
    day_num: 2
    },
    {day: 'Wednesday',
    close: "closed",
    start: "closed",
    day_num: 3
    },
    {day: 'Thursday',
    close: "closed",
    start: "closed",
    day_num: 4
    },
    {day: 'Friday',
    close: "closed",
    start: "closed",
    day_num: 5
    },
    {day: 'Saturday',
    close: "closed",
    start: "closed",
    day_num: 6
    }
]

export const weekly_Hours = {
    sunday_open: false,
    sunday_start: "null",
    sunday_close: "null",
    monday_open: false,
    monday_start: "null",
    monday_close: "null",
    tuesday_open: false,
    tuesday_start: "null",
    tuesday_close: "null",
    wednesday_open: false,
    wednesday_start: "null",
    wednesday_close: "null",
    thursday_open: false,
    thursday_start: "null",
    thursday_close: "null",
    friday_open: false,
    friday_start: "null",
    friday_close: "null",
    saturday_open: false,
    saturday_start: "null",
    saturday_close: "null",
}

export const updatedWeeklyHours = [
    { day: 'Sunday', close: 'closed', start: 'closed', day_num: 0 },
    { day: 'Monday', close: 'closed', start: 'closed', day_num: 1 },
    { day: 'Tuesday', close: 'closed', start: 'closed', day_num: 2 },
    { day: 'Wednesday', close: 'closed', start: 'closed', day_num: 3 },
    { day: 'Thursday', close: 'closed', start: 'closed', day_num: 4 },
    { day: 'Friday', close: 'closed', start: 'closed', day_num: 5 },
    { day: 'Saturday', close: 'closed', start: 'closed', day_num: 6 },
];

export const holiday_Closures = {
    xmas: false,
    xmas_eve: false,
    newyears_eve: false,
    newyears_day: false,
    laborday: false,
    mem_day: false,
    thanksgiving: false,
    julyfourth: false,
}

export const updatedHolidayClosures = {
    xmas: false,
    xmas_eve: false,
    new_years_eve: false,
    new_years_day: false,
    labor_day: false,
    memorial_day: false,
    thanksgiving: false,
    july_fourth: false,
};

export const holidayMap = {
    xmas: 'xmas',
    xmas_eve: 'xmas_eve',
    new_years_eve: 'newyears_eve',
    new_years_day: 'newyears_day',
    labor_day: 'laborday',
    memorial_day: 'mem_day',
    thanksgiving: 'thanksgiving',
    july_fourth: 'julyfourth',
};