import React, { useMemo } from 'react';

const getRate = (admin, base) => {
    if (admin || admin === 0) return admin;
    return base || null;
};

export const useRates = (order) => {
    return useMemo(() => {
        return [
            getRate(order.admin_shipper_rate_override, order.shipper_rate),
            getRate(order.admin_carrier_rate_override, order.carrier_rate),
        ];
    }, [order]);
};
