import React, { useContext } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { css } from '@emotion/react';
import Papa from 'papaparse';
import { Grid } from '@material-ui/core';
import { SectionTitle, ModalContent, ResponsiveSidebarDialog } from '@/styles/blocks';
import CSVDropzone from '@/components/CSVDropzone';
import { Context } from '../store';
import { MODALS } from '../constants';
import TemplateCard from '../cards/TemplateCard';
import Loader from '../Loader';

const CSVHeadersModal = () => {
    const { state, callbacks } = useContext(Context);

    const open = state.current === MODALS.CSV_UPLOAD;

    const onUpload = (file) => {
        if (file.type !== 'text/csv' && !file.name.includes('.csv') && file.type !== 'application/vnd.ms-excel') {
            callbacks.setErrorMsg('Incorrect file type (expected .csv)');
        } else {
            Papa.parse(file, {
                complete: async (result, file) => {
                    callbacks.setUploadedFile({ data: result.data, name: file.name });
                    callbacks.advance();
                },
                error: (err) => {
                    callbacks.setErrorMsg('Error reading .csv file');
                },
            });
        }
    };

    const handleFileAdd = (file) => {
        onUpload(file);
    };

    return (
        <ResponsiveSidebarDialog open={open} onClose={callbacks.reset}>
            <ModalContent width={740}>
                <Grid
                    css={css`
                        display: flex;
                        justify-content: space-between;
                    `}
                >
                    <SectionTitle>Upload Spreadsheet</SectionTitle>
                    <CloseIcon
                        css={css`
                            cursor: pointer;
                        `}
                        onClick={callbacks.revert}
                    />
                </Grid>

                <Grid
                    css={css`
                        margin: 12px 32px;
                    `}
                >
                    {state.isLoading && <Loader />}
                    {!state.isLoading && (
                        <CSVDropzone label={'Select a file to upload'} handleFileAdd={handleFileAdd} />
                    )}
                </Grid>

                <br />

                {/* Download CSV Template */}
                <Grid>
                    <SectionTitle>Help with Upload</SectionTitle>
                </Grid>

                <Grid
                    container
                    direction="row"
                    css={css`
                        margin-top: 12px;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin: 0px 32px;
                            flex-grow: 1;
                            flex-basis: 0;
                        `}
                    >
                        <TemplateCard />
                    </Grid>
                </Grid>
            </ModalContent>
        </ResponsiveSidebarDialog>
    );
};

export default CSVHeadersModal;
