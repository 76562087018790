import { gql } from '@apollo/client';

const COMMUNICATIONS_MUTATION = gql`
    mutation updateCommunications($communication_id: String, $enabled: Boolean) {
        update_communications(where: { communication_id: { _eq: $communication_id } }, _set: { enabled: $enabled }) {
            affected_rows
            returning {
                communication_id
                enabled
            }
        }
    }
`;

export { COMMUNICATIONS_MUTATION };
