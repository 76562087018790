import React, { useContext } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { Context } from '../store';

function FreightTypeCard() {
    const { state } = useContext(Context);

    return (
        <Card
            css={css`
                margin-bottom: 1rem;
            `}
        >
            <CardContent
                css={css`
                    font-size: 14px;
                    padding: 16px;
                `}
            >
                <Grid container>
                    <Grid item xs={12}>
                        {state.freightType[0].toUpperCase() + state.freightType.slice(1)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default FreightTypeCard;
