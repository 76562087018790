import React, { useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Grid, MenuItem } from '@material-ui/core';

import ItemDetails from './ItemDetails';
import { TextField } from '../../../blocks';
import { SectionSubtitle2, PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { ModalContext } from '..';
import { PriceInput } from '../../InputFields';
import { ITEM_LABELS_BY_FREIGHT } from '@/components/ShipmentForm/constants/freightTypes';
import { AIRLINE_OPTIONS } from '@/components/ShipmentForm/constants';
import { useClientUser } from '@/hooks';
import UploadedFilesCard from '@/components/OrderDetailsPage/UploadedFilesCard';
import UploadBOL from '../UploadBOL';

const HR = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
    border: 1px solid rgba(76, 76, 76, 0.5);
`;

function ItemsEditTab({ hasError, itemHasError, isDirty, opt }) {
    const { startDirty } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;
    const { user_id, isOnwardAdmin } = useClientUser();

    const showRevenueOption = useMemo(() => {
        if (order) {
            return order.wh_events?.some((event) => event.action.includes('ADD_CD'));
        }
        return false;
    }, [order]);

    return (
        <Grid container direction="column">
            <Grid
                container
                css={css`
                    margin-bottom: 24px;
                `}
            >
                <Grid
                    item
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        variant="outlined"
                        color="primary"
                        label="PO #"
                        onBlur={() => callbacks.makeDirty(['po_number'])}
                        error={hasError.po_number && (opt.startDirty || isDirty.po_number)}
                        value={order.po_number}
                        onChange={(e) => callbacks.modifyOrder({ po_number: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid
                    item
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        variant="outlined"
                        color="primary"
                        label="Ref #"
                        value={order.reference_id}
                        onChange={(e) => callbacks.modifyOrder({ reference_id: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid
                    item
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <TextField
                        variant="outlined"
                        color="primary"
                        label="Manufacturer"
                        value={order.manufacturer}
                        onChange={(e) => callbacks.modifyOrder({ manufacturer: e.target.value })}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid
                container
                css={css`
                    margin-bottom: 24px;
                `}
            >
                <Grid
                    xs={showRevenueOption ? 3 : 5}
                    item
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <PriceInput label="Order Revenue" value={order.order_revenue} disabled fullWidth />
                </Grid>
                <Grid
                    xs={showRevenueOption ? 3 : 5}
                    item
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        margin-left: 16px;
                    `}
                >
                    <PriceInput
                        label="Order Revenue Override"
                        value={
                            order.order_revenue_override || order.order_revenue_override === 0
                                ? order.order_revenue_override
                                : ''
                        }
                        onChange={(val) => callbacks.modifyOrder({ order_revenue_override: val })}
                        fullWidth
                    />
                </Grid>
                <Grid
                    xs={2}
                    item
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        margin-left: 16px;
                    `}
                >
                    <SecondaryButton
                        css={css`
                            width: 100%;
                            height: 100%;
                        `}
                        onClick={() => callbacks.modifyOrder({ order_revenue_override: null })}
                    >
                        Reset
                    </SecondaryButton>
                </Grid>
                {showRevenueOption && (
                    <Grid
                        xs={4}
                        item
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-left: 16px;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            select
                            label="Order revenue applied to"
                            name="warehouse_id"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={order.show_revenue_on}
                            onChange={(e) => callbacks.modifyOrder({ show_revenue_on: e.target.value })}
                        >
                            <MenuItem key={'DROPOFF'} value={'DROPOFF'}>
                                Delivery
                            </MenuItem>
                            <MenuItem key={'PICKUP'} value={'PICKUP'}>
                                Pickup
                            </MenuItem>
                        </TextField>
                    </Grid>
                )}
            </Grid>

            {order.freight_type === 'pallet' && (
                <Grid
                    container
                    css={css`
                        margin-bottom: 24px;
                    `}
                >
                    <Grid
                        xs={6}
                        item
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            select
                            label="Airline"
                            name="airline"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={order.airline}
                            onChange={(e) => callbacks.modifyOrder({ airline: e.target.value })}
                        >
                            {AIRLINE_OPTIONS.map((airline) => (
                                <MenuItem key={airline} value={airline}>
                                    {airline}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            )}

            {(order.shipper_id === user_id || !order.shipper_id || isOnwardAdmin) && (
                <Grid
                    container
                    css={css`
                        margin-bottom: 24px;
                        width: 100%;
                        padding-left: 5px;
                    `}
                >
                    <UploadBOL order={order} />
                </Grid>
            )}

            {(order.itemsByOrderId || []).map((item, idx) => (
                <React.Fragment key={idx}>
                    <ItemDetails
                        order={order}
                        item={item}
                        index={idx}
                        callbacks={callbacks}
                        hasError={itemHasError[idx]}
                        isDirty={(isDirty.itemsByOrderId || [])?.[idx] || {}}
                        startDirty={startDirty}
                    />
                    <HR />
                </React.Fragment>
            ))}

            <Grid item>
                <PrimaryButton onClick={() => callbacks.addItem()}>{`Add ${
                    ITEM_LABELS_BY_FREIGHT[order.freight_type] || 'Item'
                }`}</PrimaryButton>
            </Grid>
        </Grid>
    );
}

export default ItemsEditTab;
