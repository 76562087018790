import React, { useState, useEffect, useMemo, useRef } from 'react';
import GoogleMap, { DirectionsRenderer } from '@/components/GoogleMap';
import FTLStopHelpers from '../../utilities/FTLStopHelpers';
import { colors } from '@/styles';
import { DropOff, PickUp, StartLocation, EndLocation, Truck } from '../DispatchPlan/map/MapMarkers';
import { useDriverLocation } from '../../hooks';

import { LastUpdatedControl } from '../GoogleMap/controls/LastUpdatedControl';
import routeStatusOptions from '../../constants/routeStatusOptions';

const DEFAULT_CENTER = { lat: 39.74, lng: -104.99 };
const DEFAULT_ZOOM = 13;

function RouteMap(props) {
    const { stops, driverId, routeKey, routeStatus, deliveryDate } = props;

    const mapRef = useRef();
    const [directions, setDirections] = useState(null);
    const [route, setRoute] = useState(null);
    const [stopSequence, setStopSequence] = useState(null);

    const truckLocation = useDriverLocation(driverId, routeKey, deliveryDate, routeStatus);

    const bounds = useMemo(() => {
        if (!stopSequence?.length) {
            return;
        }

        return stopSequence.map((stop) => ({
            lat: stop.lat,
            lng: stop.lng,
        }));
    }, [stopSequence]);

    useEffect(() => {
        getDirections();
    }, [stops]);

    const getDirections = async () => {
        if (!stops) return;

        const stopsWithoutLunch = stops.filter((s) => s.type !== 'LUNCH');

        const _stopSequence = FTLStopHelpers.getStopSequence(stopsWithoutLunch);
        if (!_stopSequence.length) return;

        if (!_stopSequence.every((_stop) => _stop.lat && _stop.lng)) {
            await FTLStopHelpers.addLatLngToStops(_stopSequence);
        }

        setStopSequence(_stopSequence);

        try {
            const directionsResult = await FTLStopHelpers.getGMapsDirections(_stopSequence);
            if (directionsResult.status === 'OK') {
                setDirections(directionsResult);
                const _route = directionsResult.routes[0];
                setRoute(_route);
            } else {
                console.error(directionsResult.status);
                // dispatch(
                //     notificationActions.enqueueSnackbar(
                //         getErrorSnackbar('There was an error computing directions for this route.', {}, dispatch)
                //     )
                // );
            }
        } catch (error) {
            console.error(error);
            // dispatch(
            //     notificationActions.enqueueSnackbar(
            //         getErrorSnackbar('There was an error computing directions for this route.', {}, dispatch)
            //     )
            // );
        }
    };

    const renderStopMarker = (stop) => {
        const color = stop.stop_completion_time ? colors.greens.primary : colors.reds.primary;

        if (stop.start) {
            return <StartLocation key="start-marker" position={{ lat: stop.lat, lng: stop.lng }} color={color} />;
        } else if (stop.end) {
            return <EndLocation key="end-marker" position={{ lat: stop.lat, lng: stop.lng }} color={color} />;
        } else if (stop.type === 'DROPOFF' || (stop.type === 'PICKUP' && stop.returns?.length)) {
            return (
                <DropOff
                    key={`dropoff-marker-${stop.ordering}`}
                    iconLabel={stop.ordering}
                    stopNumber={stop.ordering}
                    position={{ lat: stop.lat, lng: stop.lng }}
                    color={color}
                />
            );
        } else if (stop.type === 'PICKUP' && !stop.returns?.length) {
            return (
                <PickUp
                    key={`pickup-marker-${stop.ordering}`}
                    position={{ lat: stop.lat, lng: stop.lng }}
                    color={color}
                />
            );
        }
    };

    const renderStopMarkers = (stops = []) => {
        const stopAddresses = stops.reduce((acc, stop) => [...acc, stop.address], []);

        return stops.map((stop) => {
            const addresses = stopAddresses.filter((stopAddress) => stopAddress === stop.address);

            if (addresses.length > 1) {
                const min = 0.99999;
                const max = 1.00001;
                const offsetLat = stop.lat * (Math.random() * (max - min) + min);
                const offsetLng = stop.lng * (Math.random() * (max - min) + min);

                stop.lat = offsetLat;
                stop.lng = offsetLng;
            }

            return renderStopMarker(stop);
        });
    };

    return (
        <>
            <GoogleMap
                ref={mapRef}
                bounds={bounds}
                center={!bounds || bounds.length === 0 ? DEFAULT_CENTER : undefined}
                zoom={!bounds || bounds.length === 0 ? DEFAULT_ZOOM : undefined}
                mapTypeControl={false}
                fullscreenControl={false}
                zoomControl={false}
                streetViewControl={false}
            >
                {directions && route && (
                    <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />
                )}
                {directions && stopSequence && <>{renderStopMarkers(stopSequence)}</>}
                {truckLocation?.lat && truckLocation?.lng && (
                    <Truck
                        key={'truck-marker'}
                        position={{ lat: truckLocation.lat, lng: truckLocation.lng }}
                        // css rotation direction is flipped, and truck icon without rotation is at 90 degrees
                        rotation={90 - truckLocation.direction}
                    />
                )}
            </GoogleMap>
            {driverId && routeStatus === routeStatusOptions.INPROGRESS.value && (
                <LastUpdatedControl
                    mapRef={mapRef}
                    lastUpdated={truckLocation?.lastUpdated}
                    onRefresh={() => {
                        truckLocation?.refresh();
                    }}
                />
            )}
        </>
    );
}

export default React.memo(RouteMap);
