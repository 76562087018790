import { useCallback, useEffect } from 'react';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import { ACCOUNTING_COLUMNS, useColumns, useRowHighlight } from './constants';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { TableContainer } from '@material-ui/core';
import HeaderCell from '@/components/DispatchUnassigned/table/HeaderCell';

const Header = styled.span`
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
`;

const Body = styled.span`
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
`;

const Cell = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 24px 6px 16px;
    border-bottom: 1px solid #e0e0e0;
`;

const TableRow = styled((props) => <div {...props} />)`
    display: flex;
    cursor: pointer;

    color: ${(props) => (props.error ? '#f44336' : '#2b2b2b')};

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`;

const BodyCell = ({ cell, ...props }) => (
    <Cell {...props}>
        <Body>{cell.render('Cell')}</Body>
    </Cell>
);

const OrdersTable = ({ orders, editOrder, tab, handleSortBy, sortBy }) => {
    const onClick = (order) => {
        switch (tab.value) {
            case 'INCOMPLETE':
                window.open(`/job/${order.job_id}`, '_blank');
                break;
            default:
                editOrder(order);
                break;
        }
    };

    const highlightRow = useRowHighlight(tab);

    const COLUMNS = useColumns(tab.value);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
        {
            columns: tab.value === 'FINANCIALS' ? ACCOUNTING_COLUMNS : COLUMNS,
            data: orders,
            initialState: { sortBy },
            manualSortBy: true,
            getRowId: (order) => order.order_id,
            autoResetHiddenColumns: false,
        },
        useSortBy,
        useFlexLayout,
        (hooks) => {
            hooks.prepareRow.push(highlightRow);
        }
    );

    useEffect(() => {
        if (state?.sortBy?.length) {
            handleSortBy(state.sortBy[0]);
        }
    }, [state?.sortBy]);

    const Row = useCallback(
        ({ index, style }) => {
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.map((col) => (
                            <HeaderCell {...col.getHeaderProps()} key={col.id} cell={col} align={col.align} />
                        ))}
                    </div>
                );
            }

            const row = rows[index - 1];
            prepareRow(row);

            return (
                <TableRow
                    {...row.getRowProps({ style })}
                    row={row}
                    onClick={() => onClick(row.original)}
                    error={row.red}
                >
                    {row.cells.map((cell) => (
                        <BodyCell {...cell.getCellProps()} cell={cell} />
                    ))}
                </TableRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
                background-color: white;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList height={height} itemCount={rows.length + 1} itemSize={57} width={width}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </TableContainer>
    );
};

export default OrdersTable;
