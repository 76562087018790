import React, { useState, useCallback } from 'react';
import { Grid, TextField, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { css } from '@emotion/react';
import { genName as genSubregionName } from '@/components/Subregions/utils';
import { colors } from '@/styles';

import { Circle, Header1 } from './blocks';

const SubregionListItem = ({ subregion, idx, isEditing, isSelected, callbacks }) => {
    const [name, setName] = useState(null);

    const updateSubregion = useCallback(() => {
        if (!name || name === subregion.name) return;

        callbacks
            .updateSubregion({
                subregion_id: subregion.subregion_id,
                name,
            })
            .then(() => {
                setName(null);
                callbacks.setEdit(subregion.subregion_id, false);
            });
    }, [name, subregion]);

    return (
        <Grid
            container
            direction="row"
            css={css`
                cursor: pointer;
                padding: 25px;
                border-bottom: 1px solid ${colors.greys[4]};
                background: ${isSelected ? colors.greys[4] : colors.white.primary};
            `}
            onClick={() => {
                callbacks.resetEdit();
                callbacks.toggleSubregion(subregion.subregion_id);
            }}
            onMouseEnter={() => {
                callbacks.toggleSubregionHover(subregion.subregion_id);
            }}
            onMouseLeave={() => {
                callbacks.toggleSubregionHover(subregion.subregion_id);
            }}
        >
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                    margin-right: 12px;
                `}
            >
                <Circle
                    css={css`
                        background: ${callbacks.genColor(subregion)};
                    `}
                />
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    justify-content: center;
                    cursor: ${isSelected ? 'text' : 'pointer'};
                    margin-right: 12px;
                `}
                onClick={(e) => {
                    if (isSelected) {
                        if (name?.length > 0) {
                            updateSubregion();
                        } else {
                            callbacks.toggleEdit(subregion.subregion_id);
                        }

                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
            >
                {isEditing ? (
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        value={name || subregion.name || ''}
                        onBlur={(e) => {
                            if (e.target.value.length > 0) {
                                updateSubregion();
                            }
                        }}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        css={css`
                            input {
                                font-size: 14px;
                                padding: 7px;
                            }
                        `}
                    />
                ) : (
                    <Header1>{genSubregionName(subregion, idx)}</Header1>
                )}
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                `}
            >
                <IconButton
                    css={css`
                        padding: 8px;
                    `}
                    onClick={() => callbacks.deleteSubregion(subregion)}
                >
                    <CloseIcon
                        css={css`
                            font-size: 14px;
                        `}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default SubregionListItem;
