import { add, sub } from 'date-fns';
import { mileString } from '@/constants/formats';
import { cloneDeep } from 'lodash';
import { DEFAULT_DELIVERY_TIMEFRAME, DEFAULT_SERVICE_TIME } from '@/constants/deliveryTimeframes';

const convertServiceTimeToHourMinutes = (serviceTime) => {
    const totalMinutes = serviceTime / 60000;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
};

const fakeLunchDirections = {
    distance: {
        value: 0,
    },
    duration: {
        value: 0,
    },
};

const calcLoadTime = (items) => {
    if (items >= 20) {
        return { hours: 1, minutes: 0 };
    } else if (items >= 15) {
        return { hours: 0, minutes: 45 };
    } else if (items >= 10) {
        return { hours: 0, minutes: 30 };
    } else {
        return { hours: 0, minutes: 15 };
    }
};

const isServiceType = (ordersByKey, orders = [], type) => {
    return orders.some((key) => {
        const order = ordersByKey[key];
        // account for weird edge case where order is undefined
        if (!order) {
            return 'dock';
        }
        const serviceType = order.order_type === 'return' ? order.pickup_location_type : order.dropoff_location_type;
        return serviceType === type;
    });
};

const roundTimeframe = (timestamp, buffer) => {
    const newTimestamp = new Date(timestamp);
    const down = Math.floor(timestamp.getMinutes() / 30) * 30;
    const rem = timestamp.getMinutes() % 30;
    const up = !buffer && rem > 15 ? 30 : 0;

    newTimestamp.setMinutes(down + up);
    newTimestamp.setSeconds(0);
    newTimestamp.setMilliseconds(0);
    return newTimestamp;
};

export const calcDefaultServiceTime = (stop, ordersByKey, timeframeOptions) => {
    if (stop.start) {
        return { hours: 0, minutes: 0 };
    }

    const orders = stop.orders || [];
    // Warehouse pickup, calc based on # items being loaded
    if (stop.type === 'PICKUP' && !stop?.returns?.length) {
        // Only add up delivery items here, no return items will be loaded at this stop
        const itemCount = orders.reduce((acc, id) => {
            const itemsForThisOrder = (ordersByKey[id]?.items || []).reduce((acc, item) => {
                return acc + (item.quantity || 0);
            }, 0);
            return acc + (itemsForThisOrder || 0);
        }, 0);

        return calcLoadTime(itemCount);
    }

    const items = orders.reduce((acc, id) => {
        const itemsForThisOrder = (ordersByKey[id]?.items || []).reduce((acc, item) => {
            return [...acc, item];
        }, []);
        return [...acc, ...itemsForThisOrder];
    }, []);

    let itemsServiceTime;

    if (items.some((i) => i.service_time)) {
        itemsServiceTime = items.reduce((acc, i) => {
            return acc + (i.service_time * i.quantity || 0);
        }, 0);
    }

    // Deliveries and customer return pickups, 30min
    if (stop.type === 'DROPOFF' || (stop.type === 'PICKUP' && stop?.returns?.length)) {
        let _minutes;

        if (isServiceType(ordersByKey, orders, 'whiteGlove')) {
            const serviceDefault =
                timeframeOptions.preferences_service_time_white_glove || timeframeOptions.preferences_service_time;

            if (timeframeOptions.preferences_use_service_default_as_minimum) {
                _minutes = itemsServiceTime > serviceDefault ? itemsServiceTime : serviceDefault;
            } else {
                _minutes = itemsServiceTime || serviceDefault;
            }
            return {
                hours: 0,
                minutes: _minutes,
            };
        }

        if (isServiceType(ordersByKey, orders, 'roomOfChoice')) {
            const serviceDefault =
                timeframeOptions.preferences_service_time_room_of_choice || timeframeOptions.preferences_service_time;

            if (timeframeOptions.preferences_use_service_default_as_minimum) {
                _minutes = itemsServiceTime > serviceDefault ? itemsServiceTime : serviceDefault;
            } else {
                _minutes = itemsServiceTime || serviceDefault;
            }
            return {
                hours: 0,
                minutes: _minutes,
            };
        }
        if (isServiceType(ordersByKey, orders, 'firstDrySpace')) {
            const serviceDefault =
                timeframeOptions.preferences_service_time_first_dry_space || timeframeOptions.preferences_service_time;

            if (timeframeOptions.preferences_use_service_default_as_minimum) {
                _minutes = itemsServiceTime > serviceDefault ? itemsServiceTime : serviceDefault;
            } else {
                _minutes = itemsServiceTime || serviceDefault;
            }
            return {
                hours: 0,
                minutes: _minutes,
            };
        }
        if (isServiceType(ordersByKey, orders, 'curbside')) {
            const serviceDefault =
                timeframeOptions.preferences_service_time_curbside || timeframeOptions.preferences_service_time;

            if (timeframeOptions.preferences_use_service_default_as_minimum) {
                _minutes = itemsServiceTime > serviceDefault ? itemsServiceTime : serviceDefault;
            } else {
                _minutes = itemsServiceTime || serviceDefault;
            }
            return {
                hours: 0,
                minutes: _minutes,
            };
        }

        if (timeframeOptions.preferences_use_service_default_as_minimum) {
            _minutes =
                itemsServiceTime > timeframeOptions.preferences_service_time
                    ? itemsServiceTime
                    : timeframeOptions.preferences_service_time;
        } else {
            _minutes = itemsServiceTime || timeframeOptions.preferences_service_time;
        }

        return { hours: 0, minutes: _minutes };
    }
    return { hours: 0, minutes: 0 };
};

export const calculateTimeframes = (
    stopSequence,
    directions,
    routeStartTime,
    ordersByKey,
    stopMatch,
    getDriveDistance,
    getDriveTime,
    timeframeOptions = {
        preferences_service_time: DEFAULT_SERVICE_TIME,
        preferences_delivery_timeframe: DEFAULT_DELIVERY_TIMEFRAME,
    },
    updateIndex = false
) => {
    let clonedDirections = cloneDeep(directions);

    const lunchStop = stopSequence.find((s) => s.type === 'LUNCH');
    if (lunchStop) {
        clonedDirections.splice(lunchStop.ordering, 0, fakeLunchDirections);
    }

    let totalService = { hours: 0, minutes: 0 };
    let totalDrivingTime = 0; // seconds
    let routeStatDrivingTime = 0;
    let totalDrivingDistance = 0; // meters

    const newStops = cloneDeep(stopSequence);

    clonedDirections.forEach((seq, idx) => {
        // Find the matching original stop
        const stop = newStops.find(stopMatch(seq, idx));

        // Update stop index
        if (updateIndex) {
            stop.ordering = idx;
        }

        // Get driving time and distance from the previous stop to this stop
        const drivingTime = getDriveTime(seq, totalDrivingTime);
        const drivingDistance = getDriveDistance(seq);

        // Only omit from total route stat, still need to add up for correct arrival times on final returns
        totalDrivingTime += drivingTime;
        if (!seq.omit) routeStatDrivingTime += drivingTime;
        if (!seq.omit) totalDrivingDistance += drivingDistance;

        // Calculate this stops service time
        let serviceTime;
        if (stop.service_time === 0 || stop.service_time) {
            serviceTime = convertServiceTimeToHourMinutes(stop.service_time);
        } else {
            serviceTime = calcDefaultServiceTime(stop, ordersByKey, timeframeOptions);
            stop.service_time = (serviceTime.hours * 60 + serviceTime.minutes) * 60000;
        }

        // Stop start time = routeStartTime + totalDriving time + totalService time up until this stop
        const stopStartTime = add(add(routeStartTime, { seconds: totalDrivingTime }), totalService);

        // Stop end time is stop start time + service time
        const stopEndTime = add(stopStartTime, serviceTime);

        // Calculated start/end times
        stop.stop_start_time = stopStartTime;
        stop.stop_end_time = stopEndTime;

        // Apply buffering and rounding to customer timeframes
        let timeframeStart = stopStartTime;

        const buffer =
            timeframeOptions.preferences_timeframe_buffer_apply && timeframeOptions.preferences_timeframe_buffer;
        if (buffer && !stop.end) {
            timeframeStart = sub(timeframeStart, { hours: 0, minutes: buffer });
            stop.del_window_buffer = buffer * 60 * 1000;
        }

        if (timeframeOptions.preferences_timeframe_round && !stop.end) {
            timeframeStart = roundTimeframe(timeframeStart, buffer);
            stop.del_window_rounded = true;
        }

        // Timeframe end is equal to timeframe start + delivery window.
        // For warehouse pickups, this is equal to the service time,
        // For deliveries and customer return pickups it uses the delivery window set on their profile, or defaults to 2 hours if this has not been set.
        let timeframeEnd = add(
            timeframeStart,
            (stop.type === 'PICKUP' && !stop?.returns?.length) || stop.type === 'LUNCH'
                ? serviceTime
                : { hours: timeframeOptions.preferences_delivery_timeframe, minutes: 0 }
        );

        stop.del_window_start = timeframeStart;
        stop.del_window_end = timeframeEnd;

        stop.driving_time = drivingTime * 1000;
        stop.driving_distance = mileString.format(drivingDistance * 0.000621371);

        // Increment total service time spent at this stop
        totalService.hours += serviceTime.hours;
        totalService.minutes += serviceTime.minutes;
    });

    return {
        stopsWithTimeframes: newStops,
        totalDrivingDistance,
        totalDrivingTime: routeStatDrivingTime,
    };
};
