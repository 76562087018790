import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Grid, Button, } from '@material-ui/core';
import { OnwardCard } from './Card';
import { ExceptionNotesText, ExceptionReasonText, CardItemBold } from './blocks';
import { colors } from '@/styles';
import { RESEND_CUSTOMER_PREDELIVERY_SMS } from '@/constants/apiRoutes';
import useAction from '@/utilities/useQuery';
import { post } from '@/utilities/onwardClient';
import * as Sentry from '@sentry/react';

export default function CustomerAddressConfirmationCard(props) {
    const { order, goToCommsTab, setNotification } = props;

    const [resendAddressConfirmationSms, { loading }] = useAction(async (body) => post(RESEND_CUSTOMER_PREDELIVERY_SMS, body), {
        onComplete: (response) => {
            if (response?.request?.status === 200) {
                setNotification({
                    severity: 'success',
                    message: 'Address confirmation text successfully resent to customer.',
                });
            } else {
                Sentry.captureException(response?.data?.error || 'Error resending address confirmation to customer.');
                setNotification({
                    severity: 'error',
                    message: 'Error resending address confirmation to customer.',
                });
            }
        },
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
            setNotification({
                severity: 'error',
                message: 'Error resending address confirmation to customer. Please try again.',
            });
        },
    });


    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    const addressConfirmed = order?.dropoff_address_confirmed;

    return (
        <OnwardCard
            className="mb-5"
            contentProps={{
                style: {
                    padding: '5px 16px',
                },
            }}
            css={css`
                ${addressConfirmed && `background-color: ${colors.greens.primary};`}
            `}
        >
            {addressConfirmed ? (
                <CardRow>
                    <Grid item>
                        <ExceptionReasonText css={css`
                            color: white;
                        `}>Customer address confirmed</ExceptionReasonText>
                    </Grid>
                    <Grid item>
                        <ExceptionReasonText
                            className="my-1"
                            css={css`
                                color: white;
                                cursor: pointer;
                                text-decoration: underline;
                            `}
                            onClick={() => goToCommsTab()}
                        >
                            View
                        </ExceptionReasonText>
                    </Grid>
                </CardRow>
            ) : (
                <CardRow>
                    <Grid item>
                        <ExceptionNotesText>Customer address confirmation</ExceptionNotesText>
                    </Grid>
                    <Grid
                        item
                        container
                        css={css`
                            width: auto;
                            align-items: center;
                            gap: 1.5rem;
                        `}
                    >
                        <Grid item>
                            <ExceptionNotesText
                                className="my-1"
                                css={css`
                                    color: ${colors.reds.cancelled};
                                    font-style: italic;
                                `}
                            >
                                Not Confirmed
                            </ExceptionNotesText>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => resendAddressConfirmationSms({ 
                                    order_id: order.order_id,
                                    dropoff_name: order.dropoff_name,
                                    shipper_id: order.shipper_id,
                                })}
                                variant="contained"
                                color="primary"
                                className="updateTime-btn"
                                disabled={loading}
                            >
                                <CardItemBold>Resend Confirmation</CardItemBold>
                            </Button>
                        </Grid>
                    </Grid>
                </CardRow>
            )}
        </OnwardCard>
    );
}
