import React, { useContext, useEffect } from 'react';
import { compose } from 'recompose';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

import { css } from '@emotion/react';
import { colors } from '@/styles';
import HubSpotControls from '@/utilities/hubspotControls';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SearchIcon from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import MessageIcon from '@material-ui/icons/Message';
import { Grid } from '@material-ui/core';
import useQuery from '@/utilities/useQuery';
import MetaPixel from '@/components/Measurement/meta';
import RedditPixel from '@/components/Measurement/reddit';
import TwitterPixel from '@/components/Measurement/twitter';

import ErrorModal from './modals/Error';
import {
    RootContainer,
    BodyDescription1,
    Header1,
    Header2,
    SubHeader1,
    PrimaryButton,
    SecondaryButton,
    HR,
    VR,
} from './blocks';
import { guestToken } from './queries/guestToken';
import ShipmentForm from './ShipmentForm';
import FormSelect from './FormSelect';
import Track from './Track';
import { Context, ContextProvider } from './store';
import { PROGRESSION_TYPES, FORM_STATUS } from './constants';
import { local } from './blocks';

const STEPS = [
    { icon: ListAltIcon, description: 'Tell us about your items and when you’re available.' },
    { icon: SearchIcon, description: 'We find a carrier to deliver your items.' },
    { icon: PhonelinkRingIcon, description: 'Confirm delivery date through text and track your delivery.' },
    { icon: MessageIcon, description: 'Leave the carrier a review and tell us how it went.' },
];

const StepHorizontal = ({ icon: Icon, description, isLast }) => {
    return (
        <>
            <Grid
                container
                direction="column"
                css={css`
                    max-width: 200px;
                    flex: 1;
                    flex-basis: 0;
                `}
            >
                <Grid
                    container
                    css={css`
                        justify-content: center;
                        margin-bottom: 12px;
                    `}
                >
                    <Icon
                        css={css`
                            font-size: 36px;
                        `}
                    />
                </Grid>
                <Grid
                    container
                    css={css`
                        text-align: center;
                    `}
                >
                    <BodyDescription1>{description}</BodyDescription1>
                </Grid>
            </Grid>
            {!isLast && (
                <Grid
                    container
                    direction="column"
                    css={css`
                        position: relative;
                        flex: 1;
                        max-width: 75px;
                        margin: 0 -25px;
                        flex-basis: 0;
                    `}
                >
                    <HR />
                </Grid>
            )}
        </>
    );
};

const StepVertical = ({ icon: Icon, description, isLast }) => {
    return (
        <>
            <Grid
                container
                css={css`
                    max-width: 270px;
                    flex: 1;
                    flex-basis: 0;
                `}
            >
                <Grid
                    container
                    css={css`
                        justify-content: center;
                        margin-bottom: 12px;
                    `}
                >
                    <Icon
                        css={css`
                            font-size: 36px;
                        `}
                    />
                </Grid>
                <Grid
                    container
                    css={css`
                        text-align: center;
                    `}
                >
                    <BodyDescription1>{description}</BodyDescription1>
                </Grid>
            </Grid>
            {!isLast && (
                <Grid
                    container
                    css={css`
                        justify-content: center;
                        position: relative;
                        flex: 1;
                        flex-basis: 0;
                        margin: 20px 0;
                    `}
                >
                    {/* <VR /> */}
                </Grid>
            )}
        </>
    );
};

function CustomerRoot() {
    const { callbacks, state, errors } = useContext(Context);
    const isDesktop = useMediaQuery('(min-width: 600px)');
    const [getGuestToken] = useQuery(guestToken, {
        onError: (err) => {
            console.log(err);
        },
    });

    useEffect(() => {
        getGuestToken().then(({ token }) => {
            if (token) {
                signInWithCustomToken(getAuth(), token);
            }
        });
    }, []);

    useEffect(() => {
        HubSpotControls.hide();

        return () => {
            HubSpotControls.show();
        };
    }, []);

    useEffect(() => {
        MetaPixel.pageView();
        RedditPixel.pageView();
        TwitterPixel.pageView();
    }, []);

    let content = (
        <RootContainer>
            <Grid container direction="column">
                <ShipmentForm isDesktop={isDesktop} />
            </Grid>
        </RootContainer>
    );
    if (state.progression.type === PROGRESSION_TYPES.CREATE_ROOT) {
        content = (
            <RootContainer>
                <Grid container direction="column">
                    <FormSelect callbacks={callbacks} isDesktop={isDesktop} />
                </Grid>
            </RootContainer>
        );
    } else if (!state.progression.type) {
        content = (
            <Grid
                container
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                `}
            >
                <Grid
                    container
                    css={css`
                        padding-bottom: 60px;
                    `}
                >
                    <RootContainer>
                        <Grid container direction="column">
                            <Grid
                                container
                                css={css`
                                    margin-bottom: 4px;
                                    justify-content: center;
                                    text-align: center;
                                    color: ${local.greys[4]};
                                `}
                            >
                                <Header1>Effortless Delivery with Onward: Your Trusted Delivery Partner</Header1>
                            </Grid>
                            <Grid
                                container
                                css={css`
                                    margin-bottom: 24px;
                                    justify-content: center;
                                    text-align: center;
                                    color: ${local.greys[1]};
                                `}
                            >
                                <SubHeader1>
                                    From Stores to Friends, We've Got Your Back. Simplify Your Delivery Process Today.
                                </SubHeader1>
                            </Grid>
                            <Grid
                                container
                                css={css`
                                    justify-content: center;
                                `}
                            >
                                <PrimaryButton
                                    css={css`
                                        width: 337px;
                                        box-shadow: 1px 1px 3px 0px #00000040;
                                    `}
                                    onClick={() => callbacks.setProgressionType(PROGRESSION_TYPES.BUSINESS)}
                                >
                                    Create a Delivery
                                </PrimaryButton>
                                <SecondaryButton
                                    css={css`
                                        width: 337px;
                                        box-shadow: 1px 1px 3px 0px #00000040;
                                        margin-top: 20px;
                                    `}
                                    onClick={() =>
                                        window.scrollTo({
                                            top: document.getElementById('Track').offsetTop,
                                            behavior: 'smooth',
                                        })
                                    }
                                >
                                    Track an Order
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </RootContainer>
                </Grid>
                <Grid
                    container
                    css={css`
                        padding-top: 60px;
                        padding-bottom: 60px;
                        background: ${colors.white.primary};
                    `}
                >
                    <RootContainer>
                        <Grid container direction="column">
                            <Grid
                                container
                                css={css`
                                    justify-content: center;
                                    text-align: center;
                                    color: ${local.greys[4]};

                                    margin-bottom: 32px;
                                `}
                            >
                                <Header1>How It Works</Header1>
                            </Grid>
                            <Grid
                                container
                                css={css`
                                    margin-bottom: 60px;
                                `}
                            >
                                {isDesktop ? (
                                    <>
                                        {STEPS.map(({ icon: Icon, description }, idx) => {
                                            return (
                                                <StepHorizontal
                                                    icon={Icon}
                                                    description={description}
                                                    isLast={idx === STEPS.length - 1}
                                                />
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            align-items: center;
                                        `}
                                    >
                                        {STEPS.map(({ icon: Icon, description }, idx) => {
                                            return (
                                                <StepVertical
                                                    icon={Icon}
                                                    description={description}
                                                    isLast={idx === STEPS.length - 1}
                                                />
                                            );
                                        })}
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                container
                                css={css`
                                    justify-content: center;
                                `}
                            >
                                <PrimaryButton
                                    css={css`
                                        width: 337px;
                                        box-shadow: 1px 1px 3px 0px #00000040;
                                    `}
                                    onClick={() => callbacks.setProgressionType(PROGRESSION_TYPES.BUSINESS)}
                                >
                                    Create a Delivery
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </RootContainer>
                </Grid>
                <Grid
                    container
                    css={css`
                        padding-top: 60px;
                        padding-bottom: 60px;
                    `}
                >
                    <RootContainer>
                        <Grid container direction="column">
                            <Grid
                                container
                                css={css`
                                    justify-content: center;
                                    text-align: center;
                                    color: ${local.greys[4]};
                                    margin-bottom: 24px;
                                `}
                            >
                                <Header1>What Our Customers Think</Header1>
                            </Grid>
                            <Grid
                                container
                                css={css`
                                    border-radius: 10px;
                                    background: ${colors.white.primary};
                                    margin-bottom: 30px;
                                    padding: 20px 60px;
                                `}
                            >
                                <Grid container direction="column">
                                    <Grid
                                        container
                                        css={css`
                                            justify-content: center;
                                            margin-bottom: 16px;
                                        `}
                                    >
                                        {Array(5)
                                            .fill(0)
                                            .map((_, idx) => {
                                                return (
                                                    <Grid
                                                        key={idx}
                                                        container
                                                        direction="column"
                                                        css={css`
                                                            flex: 0;
                                                            flex-basis: 0;
                                                        `}
                                                    >
                                                        <StarIcon
                                                            css={css`
                                                                color: ${colors.greens.primary};
                                                                font-size: 24px;
                                                            `}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                    <Grid
                                        container
                                        css={css`
                                            margin-bottom: 12px;
                                        `}
                                    >
                                        <BodyDescription1>
                                            Loved being able to track the delivery, and communication was excellent.
                                            Delivery gentlemen were professional and efficient. Very happy with the
                                            entire experience!
                                        </BodyDescription1>
                                    </Grid>
                                    <Grid
                                        container
                                        css={css`
                                            justify-content: center;
                                        `}
                                    >
                                        <Header2
                                            css={css`
                                                color: ${colors.greens.primary};
                                            `}
                                        >
                                            Sarah B
                                        </Header2>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </RootContainer>
                </Grid>
                <Grid
                    container
                    css={css`
                        padding-top: 60px;
                        padding-bottom: 60px;
                        background: ${colors.white.primary};
                    `}
                >
                    <RootContainer id="Track">
                        <Grid container direction="column">
                            <Track
                                isDesktop={isDesktop}
                                callbacks={{
                                    ...callbacks,
                                    onTrackFailed: () => {
                                        callbacks?.setStatus(FORM_STATUS.FAILED);
                                    },
                                }}
                            />
                        </Grid>
                    </RootContainer>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
            `}
        >
            <Grid
                container
                css={css`
                    height: 100%;

                    padding-top: ${isDesktop ? `60px` : `30px`};
                `}
            >
                {content}
            </Grid>

            <ErrorModal
                open={state.status === FORM_STATUS.FAILED}
                callbacks={{
                    onClose: callbacks.resetError,
                }}
            />
        </Grid>
    );
}

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withContext)(CustomerRoot);
