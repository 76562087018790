import React from 'react';
import { css } from '@emotion/react';
import { Grid, TextField } from '@material-ui/core';

import { Body1 } from '../../Crossdocking/blocks';

const Default = ({ exception, required, callbacks }) => {
    return (
        <>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>Notes{required.includes('notes') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}</Body1>
            </Grid>
            <Grid direction="row" container>
                <TextField
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={exception.notes || ''}
                    onChange={(e) =>
                        callbacks.setException((prev) => ({
                            ...prev,
                            notes: e.target.value,
                        }))
                    }
                    multiline
                    rows={5}
                />
            </Grid>
        </>
    );
};

export default Default;
