import React, { useContext, useMemo } from 'react';
import { iconcss, LinkContainer, LinkLabel, SublinkIcon } from './blocks';
import { ChevronLeft, Menu } from '@material-ui/icons';
import { NavDivider, white } from './blocks';
import { css } from '@emotion/react';
import onwardLogo from '../../assets/onwardlogotransparent.webp';
import Header from './Header';
import { UserContext } from '../App';
import { LINKS } from './links';
import NavLink from './NavLink';
import { useLocation, useNavigate } from 'react-router';
import { Drawer, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import { BoxArrowLeft } from 'react-bootstrap-icons';
import { useClientUser } from '@/hooks';
import CustomerHeader from './CustomerHeader';
import { onwardNavy } from '@/styles/colors';
import * as ROUTES from '@/constants/routes';

const Navigation = () => {
    const navigate = useNavigate();
    const { user, superUser, setSuperUser } = useContext(UserContext);
    const { sidebarCollapsed, setSidebarCollapsed } = useContext(UserContext);
    const { customHeader, customColor } = useContext(UserContext);
    const { accountType, teammateRoles, circles, roles } = useClientUser();

    const links = useMemo(() => {
        let links = [];

        if (roles?.ADMIN) {
            links = LINKS.ADMIN;
        } else if (roles?.CORPORATE_ADMIN) {
            links = LINKS.CORPORATE_ADMIN;
        } else if (accountType === 'shipper') {
            links = teammateRoles?.driver ? LINKS.SHIPPER_DRIVER_TEAMMATE : LINKS.SHIPPER;
        } else if (accountType === 'carrier') {
            links = teammateRoles?.driver ? LINKS.CARRIER_DRIVER_TEAMMATE : LINKS.CARRIER;
        }

        return links.filter((link) => {
            let allow = false;
            if (!link.feature) allow = true;
            if (Array.isArray(link.feature) && link?.feature?.some((feat) => circles?.[feat])) {
                allow = true;
            } else if (circles?.[link.feature]) {
                allow = true;
            }
            if (link?.restrictionFeature && link?.restrictionFeature?.some((feat) => circles?.[feat])) {
                allow = false;
            }
            return allow;
        });
    }, [user, accountType, teammateRoles, roles]);

    const resetAdmin = () => {
        setSuperUser({});
        window?.sessionStorage?.removeItem('admin_user_object');
        if (superUser?.authUserRoles?.CORPORATE_ADMIN) {
            navigate(ROUTES.CORPORATE_USERS);
        } else {
            navigate(ROUTES.ADMIN_USERS);
        }
    };

    if (window.self !== window.top) {
        return <></>;
    }

    return user ? (
        <>
            <Header authUser={user} />
            <Drawer
                anchor="left"
                variant="permanent"
                css={css`
                    .MuiPaper-root {
                        background-color: ${onwardNavy};
                    }
                `}
            >
                {/* Nav Control */}
                {sidebarCollapsed ? (
                    <IconButton onClick={() => setSidebarCollapsed(false)}>
                        <Menu css={white} />
                    </IconButton>
                ) : (
                    <div
                        css={css`
                            display: flex;
                            justify-content: center;
                            margin-left: 45px;
                        `}
                    >
                        <img
                            css={css`
                                width: 130px;
                                height: 50px;
                                object-fit: cover;
                            `}
                            src={onwardLogo}
                        />
                        <div
                            css={css`
                                margin-top: 15px;
                            `}
                        >
                            <LinkLabel
                                css={css`
                                    color: white;
                                    font-size: 10px;
                                    font-weight: 700;
                                    font-family: 'Copperplate', sans-serif !important;
                                `}
                            >
                                <i>v3.0</i>
                            </LinkLabel>
                        </div>
                        <IconButton
                            css={css`
                                width: 50px;
                            `}
                            onClick={() => setSidebarCollapsed(true)}
                        >
                            <ChevronLeft css={white} />
                        </IconButton>
                    </div>
                )}

                <NavDivider />

                {/* Nav Links */}
                {links.map((link) => (
                    <NavLink link={link} key={`nav-${link.label}`} />
                ))}

                <NavDivider />

                {LINKS.FOOTER.map((link) => (
                    <NavLink link={link} key={`nav-${link.label}`} />
                ))}

                {superUser && superUser.user_id && (
                    <LinkContainer onClick={resetAdmin}>
                        <ListItemIcon style={{ minWidth: '44px', marginLeft: '10px' }}>
                            <BoxArrowLeft style={{ fontSize: '24px', color: 'red', marginRight: '0' }} />
                        </ListItemIcon>
                        {!sidebarCollapsed && (
                            <ListItemText style={{ fontWeight: '700' }} className="text-danger font-weight-extra-bold">
                                <span style={{ fontWeight: 700 }}>BACK TO ADMIN</span>
                            </ListItemText>
                        )}
                    </LinkContainer>
                )}
            </Drawer>
        </>
    ) : (
        <CustomerHeader customHeader={customHeader} customColor={customColor} />
    );
};

export default Navigation;
