import React, { useContext, useState, useCallback, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { compose } from 'recompose';
import { colors } from '@/styles';
import Map from '@/components/Mapbox/Map';
import Source from '@/components/Mapbox/Source';
import { useClientUser } from '@/hooks';
import ZoomControl from '@/components/Mapbox/Controls/Zoom';
import LassoControl from '@/components/Mapbox/Controls/Lasso';
import Layer from '@/components/Mapbox/Layer';
import withFeatureFlag from '@/components/Auth/withFeatureFlag';
import { ADVANCED_SCHEDULING } from '@/constants/featureFlags';

import { Context, ContextProvider } from './store';
import { PageTitle } from './blocks';
import SubregionList from './SubregionList';
import ZipList from './ZipList';
import AssignToSubregionPopover from './AssignToSubregionPopover';
import Snackbar from '../Snackbar';
import { OnwardSwitch } from '@/styles/blocks';

const DEFAULT_CENTER = [-104.99, 39.74];

const SubRegions = () => {
    const mapRef = useRef(null);
    const [root, setRoot] = useState(null);
    const user = useClientUser();
    const { callbacks, state, loading } = useContext(Context);
    const [highlightOverride, setHighlightOverride] = useState(false);

    const initRoot = useCallback((node) => {
        if (node !== null) {
            setRoot(node);
        }
    }, []);

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
                background: ${colors.white.primary};
                flex-wrap: nowrap;
            `}
        >
            <Grid
                container
                direction="row"
                css={css`
                    padding: 23px 45px;
                    border-bottom: 1px solid ${colors.greys[4]};
                    flex: 0;
                    flex-basis: 0;
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <PageTitle>Regions</PageTitle>
                <OnwardSwitch
                    label={'Highlight All'}
                    checked={highlightOverride}
                    onChange={() => setHighlightOverride((prev) => !prev)}
                />
            </Grid>

            <Grid
                container
                direction="row"
                css={css`
                    flex-basis: 0;
                    flex: 1;
                    flex-wrap: nowrap;
                `}
            >
                <Grid
                    item
                    container
                    direction="column"
                    css={css`
                        min-width: 320px;
                        border-right: 1px solid ${colors.greys[0]};
                        flex: 0;
                        flex-basis: 0;
                    `}
                >
                    <SubregionList
                        loading={loading}
                        selected={state.selectedSubregion}
                        subregions={state.subregions}
                        filter={state.filter}
                        profile={state.profile}
                        types={state.types}
                        callbacks={callbacks}
                    />
                </Grid>
                {state.selectedSubregion && (
                    <Grid
                        item
                        container
                        direction="column"
                        css={css`
                            min-width: ${user.isOnwardAdmin ? '500px' : '250px'};
                            border-right: 1px solid ${colors.greys[0]};
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <ZipList
                            loading={loading}
                            subregion={state.selectedSubregion}
                            zips={state?.selectedSubregion?.zips || []}
                            carriers={state?.selectedSubregion?.carriers || []}
                            carriersById={state.carriersById || {}}
                            filter={state.filter}
                            callbacks={callbacks}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        overflow: hidden;

                        position: relative;
                    `}
                >
                    <div
                        ref={initRoot}
                        css={css`
                            width: 100%;
                            height: 100%;
                            display: flex;
                        `}
                    />

                    {state.selectedZips.length > 0 ? (
                        <AssignToSubregionPopover
                            selectedZips={state.selectedZips}
                            subregions={state.subregions}
                            loading={loading}
                            callbacks={{
                                insertMappings: callbacks.insertMappings,
                                removeMappings: callbacks.removeMappings,
                            }}
                        />
                    ) : null}
                </Grid>
            </Grid>

            <Snackbar
                open={!!state.notification.message}
                {...state.notification}
                handleClose={callbacks.clearNotification}
            />

            <Map
                ref={mapRef}
                center={DEFAULT_CENTER}
                zoom={7}
                minZoom={5}
                node={root}
                callbacks={{
                    onLoad: (map) => {
                        const [long, lat] = user.locations.reduce((acc, location) => {
                            if (location.lng && location.lat) {
                                acc = [location.lng, location.lat];
                            }

                            return acc;
                        }, []);

                        if (long && lat) {
                            map.jumpTo({
                                center: [long, lat],
                            });
                        }
                    },
                }}
            >
                {({ map }) => {
                    return (
                        <>
                            <Source map={map} url="mapbox://kevinmwalsh.zcta-2020" name="zcta.2020" />
                            <ZoomControl map={map} location="bottom-left" />
                            <LassoControl
                                map={map}
                                callbacks={{
                                    onClear: () => {
                                        callbacks.selectObject((prev) => {
                                            return {
                                                ...prev,
                                                zip: {},
                                            };
                                        });
                                    },
                                    onSelect: (start, end) => {
                                        const features = map.queryRenderedFeatures([start, end], {
                                            layers: ['outlines-layer'],
                                        });

                                        callbacks.selectObject((prev) => {
                                            const next = features.reduce(
                                                (acc, feature) => {
                                                    acc[feature.properties.NAME20] = acc[feature.properties.NAME20]
                                                        ? !acc[feature.properties.NAME20]
                                                        : true;

                                                    return acc;
                                                },
                                                { ...prev.zip }
                                            );

                                            return {
                                                ...prev,
                                                zip: next,
                                            };
                                        });
                                    },
                                }}
                            />

                            <Layer
                                id="outlines-layer"
                                map={map}
                                source="zcta.2020"
                                source-layer="zctas"
                                type="fill"
                                paint={{
                                    'fill-color': 'rgba(0,0,0,0)',
                                    'fill-outline-color': '#000',
                                }}
                            />
                            {state.subregions.map((subregion) => (
                                <Layer
                                    id={`subregion-layer-${subregion.subregion_id}`}
                                    map={map}
                                    source="zcta.2020"
                                    source-layer="zctas"
                                    type="fill"
                                    paint={{
                                        'fill-color': highlightOverride
                                            ? callbacks.genColor(subregion || {}) || 'rgba(0,0,0,0)'
                                            : '#4C4C4C',
                                        'fill-opacity': highlightOverride ? 0.7 : 0.3,
                                        'fill-outline-color': '#000',
                                    }}
                                    filter={['in', 'NAME20', ...subregion.zips.map((zip) => zip.zip)]}
                                />
                            ))}
                            <Layer
                                id="selected-subregion-layer"
                                map={map}
                                source="zcta.2020"
                                source-layer="zctas"
                                type="fill"
                                paint={{
                                    'fill-color': callbacks.genColor(state.selectedSubregion || {}) || 'rgba(0,0,0,0)',
                                    'fill-opacity': 0.7,
                                    'fill-outline-color': '#000',
                                }}
                                filter={['in', 'NAME20', ...(state?.selectedSubregion?.zips.map((o) => o.zip) || [])]}
                            />
                            <Layer
                                id="hover-subregion-layer"
                                map={map}
                                source="zcta.2020"
                                source-layer="zctas"
                                type="fill"
                                paint={{
                                    'fill-color': callbacks.genColor(state.hoveredSubregion || {}) || 'rgba(0,0,0,0)',
                                    'fill-opacity': 0.7,
                                    'fill-outline-color': '#000',
                                }}
                                filter={['in', 'NAME20', ...(state?.hoveredSubregion?.zips.map((o) => o.zip) || [])]}
                            />
                            <Layer
                                id="hover-zip-layer"
                                map={map}
                                source="zcta.2020"
                                source-layer="zctas"
                                type="fill"
                                paint={{
                                    'fill-color': '#000',
                                    'fill-opacity': 0.2,
                                    'fill-outline-color': '#000',
                                }}
                                filter={[
                                    'in',
                                    'NAME20',
                                    ...(state.hoveredSubregionZip ? [state.hoveredSubregionZip] : []),
                                ]}
                            />
                            <Layer
                                id="selected-zip-layer"
                                map={map}
                                source="zcta.2020"
                                source-layer="zctas"
                                type="fill"
                                paint={{
                                    'fill-color': colors.greens.primary,
                                    'fill-opacity': 0.7,
                                    'fill-outline-color': '#000',
                                }}
                                filter={['in', 'NAME20', ...state.selectedZips]}
                            />
                        </>
                    );
                }}
            </Map>
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withFeatureFlag(ADVANCED_SCHEDULING), withContext)(SubRegions);
