import React, { useContext, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { validate } from '@onward-delivery/core';
import { PlanningContext } from '../context';
import { GridItemRow, SecondaryButtonFullW } from '../blocks';
import { MODALS } from '../constants';

const SendToOnward = ({ orders }) => {
    const { setModalOpen } = useContext(PlanningContext);

    const modalDisabled = useMemo(() => {
        return orders?.some((order) => {
            const alreadyRouted = order.routes?.length;
            const [, hasMissingField] = validate({ ...order, oms: false });
            const isInvalid = hasMissingField?.length > 0;

            return alreadyRouted || isInvalid;
        });
    }, [orders]);

    return (
        <GridItemRow
            css={css`
                padding: 0.25rem 1rem 0.375rem 1rem;
            `}
        >
            <SecondaryButtonFullW disabled={modalDisabled} onClick={() => setModalOpen(MODALS.SENT_TO_ONWARD)}>
                Send to Onward
            </SecondaryButtonFullW>
        </GridItemRow>
    );
};

export default SendToOnward;
