import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@/styles';
import { Grid } from '@material-ui/core';

export const Title = styled.span`
    font-size: 16px;
    font-weight: 800;
`;

export const Subtitle = styled.span`
    font-size: 16px;
    font-weight: 700;
    color: ${colors.greys.subtitle};
`;

export const Body = styled.span`
    font-size: 14px;
    font-weight: 500;
`;
export const BodyBold = styled.span`
    font-size: 15px;
    font-weight: 700;
`;

export const Price = styled.span`
    font-size: 14px;
    font-weight: 800;
    color: ${colors.greens.primary};
`;

export const TooltipTitle = styled.span`
    font-size: 12px;
    font-weight: 700;
`;

export const TooltipPrice = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: ${colors.greens.primary};
`;

export const LoadContainer = styled((props) => <Grid container {...props} />)`
    padding: 1rem;
    flex-direction: column;
`;

export const LoadPrimaryRow = styled((props) => <Grid item {...props} />)`
    display: flex;
    justify-content: space-between;
`;

export const RowItem = styled((props) => <Grid item {...props} />)`
    display: flex;
    margin-bottom: 0.5rem;
`;

export const border = `1px solid ${colors.greys.border}`;

export const FilterRow = styled(({ ...props}) => <Grid item container {...props} />)`
    display: flex;
    margin-bottom: 0.75rem;
    flex-wrap: nowrap;
    align-items: center;
`;
