import React, { useEffect, useRef } from 'react';
import * as ReactDOMServer from 'react-dom/server';

export default function InfoWindow({ map, marker, content, opts, callbacks = {} }) {
    const windowInst = useRef();

    useEffect(() => {
        let onClose;
        windowInst.current = new window.google.maps.InfoWindow({ ...opts });

        if (callbacks.onClose) {
            onClose = windowInst.current.addListener('closeclick', callbacks.onClose);
        }

        return () => {
            if (onClose) {
                window.google.maps.event.removeListener(onClose);
            }
        };
    }, [opts]);

    useEffect(() => {
        if (content) {
            const contentString = ReactDOMServer.renderToStaticMarkup(content);

            windowInst.current.setContent(contentString);
        }
    }, [content]);

    useEffect(() => {
        if (marker) {
            windowInst.current.open({ anchor: marker, map: map.current, shouldFocus: false });
        } else {
            windowInst.current.close();
        }
    }, [marker]);

    return null;
}
