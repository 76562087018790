import { convertToISO } from './convertToISO';
import dateFns from './dateFns';
import FTLStopHelpers from './FTLStopHelpers';
import dateFnsFormat from 'date-fns/format';

import { geocodeByAddress } from 'react-places-autocomplete';

const dateShort = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const getNumberOfStops = (route) => {
    return (route.stopsByRouteId || []).filter((stop) => FTLStopHelpers.isCustomerStop(stop, route)).length;
};

const getCompleteStopCount = (route) => {
    return (route.stopsByRouteId || []).filter(
        (stop) => FTLStopHelpers.isCustomerStop(stop, route) && stop.stop_completion_time
    ).length;
};

const getNumberOfDropoffs = (route) => {
    return (route.stopsByRouteId || []).filter(
        (stop) => FTLStopHelpers.isCustomerStop(stop, route) && stop.type === 'DROPOFF'
    ).length;
};

const getNumberOfPickUps = (route) => {
    return (route.stopsByRouteId || []).filter(
        (stop) => FTLStopHelpers.isCustomerStop(stop, route) && stop.type === 'PICKUP'
    ).length;
};

const getStartLocationString = async (route, showZip) => {
    const stopSequence = FTLStopHelpers.getStopSequence(route.stopsByRouteId);

    if (stopSequence && stopSequence.length > 0) {
        const start = stopSequence[0].address;

        const startGeocode = await geocodeByAddress(start);
        const startComponents = startGeocode[0].address_components;

        let startCity = startComponents.find((component) => component.types.includes('locality')).long_name;
        let startState = startComponents.find((component) =>
            component.types.includes('administrative_area_level_1')
        ).short_name;
        let startZip = startComponents.find((component) => component.types.includes('postal_code')).short_name;

        if (showZip) {
            return startCity + ', ' + startState + ' ' + startZip;
        }
        return startCity + ', ' + startState;
    }
};

const getEndLocationString = async (route, showZip) => {
    const stopSequence = FTLStopHelpers.getStopSequence(route.stopsByRouteId);

    if (stopSequence && stopSequence.length > 0) {
        const end = stopSequence[stopSequence.length - 1].address;
        const endGeocode = await geocodeByAddress(end);

        const endComponents = endGeocode[0].address_components;

        let endCity = endComponents.find((component) => component.types.includes('locality')).long_name;
        let endState = endComponents.find((component) =>
            component.types.includes('administrative_area_level_1')
        ).short_name;
        let endZipObject = endComponents.find((component) => component.types.includes('postal_code'));
        let endZip = endZipObject && endZipObject.short_name ? endZipObject.short_name : '';

        if (showZip) {
            return endCity + ', ' + endState + ' ' + endZip;
        }
        return endCity + ', ' + endState;
    }
};

const getLatestFirstAvailable = (orders) => {
    if (!orders || orders.length === 0) {
        return;
    }

    let latestDate = convertToISO(orders[0].first_available_date);

    for (let i = 1; i < orders.length; i++) {
        const iDate = convertToISO(orders[i].first_available_date);
        if (iDate > latestDate) {
            latestDate = iDate;
        }
    }

    let finalDate = new Date(latestDate);
    if (!isNaN(finalDate)) {
        return dateShort.format(finalDate);
    }

    return undefined;
};

const getRouteStartCityState = (route) => {
    if (!route.stopsByRouteId) return '';

    const pickups = route.stopsByRouteId.filter((stop) => stop.type === 'PICKUP');
    if (route.stopsByRouteId.length === 0 || pickups.length === 0) {
        return '';
    }

    const startingAddress = pickups[0].address;
    if (startingAddress) {
        let zip;
        let [, city, state, country] = startingAddress.split(', ');
        const stateSplit = state.split(' ');

        if (stateSplit.length === 2) {
            [state, zip] = stateSplit;
        }

        return `${city}, ${state}`;
    }

    return '';
};

const getRouteEndCityState = (route) => {
    if (!route.stopsByRouteId) return '';

    const dropoffs = route.stopsByRouteId.filter((stop) => stop.type === 'DROPOFF');
    if (route.stopsByRouteId.length === 0 || dropoffs.length === 0) {
        return '';
    }

    const endAddress = dropoffs[dropoffs.length - 1].address;
    if (endAddress) {
        let zip;
        let [, city, state, country] = endAddress.split(', ');
        const stateSplit = state.split(' ');

        if (stateSplit.length === 2) {
            [state, zip] = stateSplit;
        }

        return `${city}, ${state}`;
    }

    return '';
};

const getRouteStartAddress = (route) => {
    const pickups = route.stopsByRouteId.filter((stop) => stop.type === 'PICKUP');
    if (route.stopsByRouteId.length === 0 || pickups.length === 0) {
        return '';
    }

    const startingAddress = pickups[0].address;
    if (startingAddress) {
        let zip;
        let [, city, state, country] = startingAddress.split(', ');
        const stateSplit = state.split(' ');

        if (stateSplit.length === 2) {
            [state, zip] = stateSplit;
        } else {
            // If the state is not split, then the zip is the country field above
            zip = country;
        }

        return `${city}, ${state}, ${zip}`;
    }

    return '';
};

const getRouteEndAddress = (route) => {
    const dropoffs = route.stopsByRouteId.filter((stop) => stop.type === 'DROPOFF');
    if (route.stopsByRouteId.length === 0 || dropoffs.length === 0) {
        return '';
    }

    const endAddress = dropoffs[dropoffs.length - 1].address;

    if (endAddress) {
        let zip;
        let [, city, state, country] = endAddress.split(', ');
        const stateSplit = state.split(' ');

        if (stateSplit.length === 2) {
            [state, zip] = stateSplit;
        } else {
            // If the state is not split, then the zip is the country field above
            zip = country;
        }

        return `${city}, ${state}, ${zip || null}`;
    }

    return '';
};

export default {
    getNumberOfDropoffs,
    getNumberOfStops,
    getNumberOfPickUps,
    getLatestFirstAvailable,
    getStartLocationString,
    getEndLocationString,
    getRouteStartCityState,
    getRouteEndCityState,
    getRouteStartAddress,
    getRouteEndAddress,
    getCompleteStopCount,
};
