import { post } from '@/utilities/onwardClient';
import { PARSE_PDF } from '@/constants/apiRoutes';

export const parsePdf = async ({ client_id, filename, file }) => {
    return post(PARSE_PDF, {
        clientId: client_id,
        filename,
        base64pdf: file.base64,
    });
};
