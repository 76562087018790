import React from 'react';

import { TableCell } from '@material-ui/core';
import { BodyCellText } from '../blocks';

export default function BodyCell({ cell, ...props }) {
    return (
        <TableCell align="center" {...props}>
            <BodyCellText>{cell.render('Cell')}</BodyCellText>
        </TableCell>
    );
}
