import React, { useEffect, useState } from 'react';

import AddressAutocompleteBase from '../misc/AddressAutocomplete';

export default function AddressAutocomplete(props) {
    const { state, handleAddressUpdate, onBlur, error, helperText } = props;

    const [addressEntry, setAddressEntry] = useState([]);
    const [focused, setFocused] = useState(false);

    const handleAddressEntry = (entry) => setAddressEntry((prev) => [entry, prev[1]]);

    const focus = () => setFocused(true);

    const blur = () => {
        if (onBlur) onBlur();
        setFocused(false);

        if (!addressEntry[0]) {
            const empty = {
                city: null,
                state: null,
                zip: null,
                street: null,
            };
            setFocused(false);
            setAddressEntry((prev) => [prev[0], empty]);
            handleAddressUpdate('', empty);
            return;
        }

        let addressUpdateString = '';
        if (state.street) {
            addressUpdateString += state.street + ', ';
        }
        if (state.city) {
            addressUpdateString += state.city + ' ';
        }
        if (state.state) {
            addressUpdateString += state.state + ' ';
        }
        if (state.zip) {
            addressUpdateString += state.zip;
        }

        handleAddressUpdate(addressUpdateString, {
            city: state.city,
            state: state.state,
            zip: state.zip,
            street: state.street,
        });
    };

    const selectAddress = (address, place, suggestion) => {
        // Show address as invalid if it can geocode but isn't an address of a specific location
        const geoLengthCheck = suggestion?.formattedSuggestion?.secondaryText?.split(', ');
        if (geoLengthCheck.length < 3 || ['postal_code', 'route'].some((i) => suggestion?.types?.includes(i))) {
            handleAddressUpdate(address, {
                city: '',
                state: '',
                street: suggestion.formattedSuggestion.mainText,
            });
            return;
        }

        const [city, state] = suggestion.formattedSuggestion.secondaryText.split(', ');

        setAddressEntry((prev) => [
            address,
            {
                city,
                state,
                street: suggestion.formattedSuggestion.mainText,
            },
        ]);
        handleAddressUpdate(address, {
            city,
            state,
            street: suggestion.formattedSuggestion.mainText,
        });
    };

    useEffect(() => {
        if (state.street !== addressEntry[1]?.street || state.street !== addressEntry[0]) {
            setAddressEntry([
                state.street,
                {
                    city: state.city,
                    state: state.state,
                    zip: state.zip,
                    street: state.street,
                },
            ]);
        }
    }, [state.street]);

    return (
        <AddressAutocompleteBase
            disabled={props.disabled}
            label="Address"
            value={addressEntry[0] || ''}
            onInput={handleAddressEntry}
            onSelect={selectAddress}
            onBlur={blur}
            onFocus={focus}
            open={focused}
            error={error}
            helperText={helperText}
            size="medium"
        />
    );
}
