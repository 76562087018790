import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { typography, colors } from '../../styles';
import { Button } from '@material-ui/core';

export const PrimaryButton = styled((props) => <Button {...props} />)`
    background: ${colors.greens.primary};
    color: ${colors.white.primary};
`;

export const Status = styled.div`
    font-weight: 700;
    color: ${(props) => {
        if (props.status === 'Complete') {
            return colors.greens.primary;
        } else if (props.status === 'In Transit') {
            return local.blue;
        }
        return colors.greys.primary;
    }};
`;

export const H1 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 28px;
    line-height: 1.25;
`;

export const H2 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 16px;
    line-height: 1.65;
`;

export const H3 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 1.65;
`;

export const GreyBody = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 1.65;
    color: ${colors.greys.primary};
`;

export const Body2 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 14px;
    line-height: 1.65;
    white-space: nowrap;
`;

export const RedBody = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 14px;
    line-height: 1.65;
    color: ${colors.reds.cancelled};
`;

export const Subtitle1 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 16px;
`;

export const BodyCellText = styled.span`
    ${typography.sansSerif}

    font-weight: 400;
    font-size: 16px;
`;

export const local = {
    red: '#D23E3E',
    blue: '#008BD9',
};

export const fragments = {
    card: css`
        background: ${colors.white.primary};
        border-radius: 4px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 16px;
        box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.2);
        height: fit-content;
    `,
    sideNavCollapsed: css`
        margin-left: 56px;
    `,
    sideNavExpanded: css`
        margin-left: 240px;
    `,
};
