export function calcStopVolumes(orders) {
    const fields = ['items', 'returns', 'haulaway_items'];
    if (orders?.length > 0) {
        return orders.reduce(
            (totalCubes, order) => {
                let returnsCubes = 0;
                let itemsCubes = 0;
                let haulawayCubes = 0;
                fields.forEach((field) => {
                    if (order[field]?.length > 0) {
                        order[field].forEach((item) => {
                            if (field === 'returns') {
                                returnsCubes += item.total_cubes;
                            } else if (field === 'items') {
                                itemsCubes += item.total_cubes;
                            } else if (field === 'haulaway_items') {
                                if (item?.cubes_per_unit) {
                                    haulawayCubes += item.cubes_per_unit * item.quantity;
                                } else {
                                    haulawayCubes += ((item.height * item.width * item.length) / 1728) * item.quantity;
                                }
                            }
                        });
                    }
                });

                totalCubes.returnsCubes += returnsCubes;
                totalCubes.itemsCubes += itemsCubes;
                totalCubes.haulawayCubes += haulawayCubes;
                return totalCubes;
            },
            { returnsCubes: 0, itemsCubes: 0, haulawayCubes: 0 }
        );
    }

    return 0;
}

export function calcStopTruckCubes(stops) {
    let truckCubes = 0;
    return stops?.map((stop) => {
        // logic for each stop goes here
        if (stop.type === 'PICKUP') {
            if (stop.returns) {
                truckCubes += stop.exchangeCubes.returnsCubes;
            } else {
                truckCubes += stop.exchangeCubes.itemsCubes;
            }
        } else if (stop.type === 'DROPOFF') {
            if (stop.exchanges) {
                let cubeDiff = stop.exchangeCubes.returnsCubes - stop.exchangeCubes.itemsCubes;
                truckCubes += cubeDiff;
            } else if (stop.returns) {
                truckCubes -= stop.exchangeCubes.returnsCubes;
            } else {
                truckCubes -= stop.exchangeCubes.itemsCubes;
            }
        }
        stop.totalTruckCubes = truckCubes;
        return stop;
    });
}
