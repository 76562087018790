import { CARRIER_INVOICE_FIELDS } from '@/graphql/queries/carrier_invoices';
import { SHIPPING_PARTNER_FIELDS } from '@/graphql/fragments/shipping_partners';
import { gql } from '@apollo/client';

export const CARRIER_INVOICE = gql`
    ${CARRIER_INVOICE_FIELDS}
    ${SHIPPING_PARTNER_FIELDS}
    query CarrierInvoice($invoice_id: uuid!) {
        carrier_invoices_by_pk(carrier_invoice_id: $invoice_id) {
            ...CarrierInvoiceFields
            partner_client {
                client_id
                business_name
            }
            orders {
                order_id
                carrier_id
                completion_time
                delivery_date
                order_type
                order_revenue
                carrier_rate
                shipper_rate
                order_revenue_adjustments
                order_status
                order_number
                po_number
                dropoff_name
                pickup_name
                dropoff_city
                dropoff_state
                dropoff_zip
                price_breakdown
                oms
                pickup_city
                pickup_state
                pickup_zip
                qbo_tag
                invoice_class
                shipper_id
                itemsByOrderId {
                    item_id
                    item_type
                    item_type_details
                    description
                    quantity
                }
                items @client {
                    item_id
                    item_type
                    item_type_details
                    description
                    quantity
                }
                returns @client {
                    item_id
                    item_type
                    item_type_details
                    description
                    quantity
                }
            }
        }
    }
`;

export const MARK_AS_PAID = gql`
    ${CARRIER_INVOICE_FIELDS}
    mutation MarkInvoiceAsPaid($invoice_id: uuid!) {
        update_carrier_invoices_by_pk(pk_columns: { carrier_invoice_id: $invoice_id }, _set: { status: "PAID" }) {
            ...CarrierInvoiceFields
            orders {
                carrier_id
                order_id
                order_revenue
                order_revenue_adjustments
                order_status
                order_number
                po_number
                dropoff_name
                pickup_name
                dropoff_city
                dropoff_state
                dropoff_zip
                pickup_city
                pickup_state
                pickup_zip
                shipper_id
            }
        }
    }
`;
