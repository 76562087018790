import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';

import { PrimaryButton } from '@/styles/blocks';

function Footer({ isFirst, isLast, hasErrors, callbacks, state }) {
    return (
        <Grid
            container
            css={css`
                margin-top: 24px;
                justify-content: space-between;
            `}
        >
            <PrimaryButton
                css={css`
                    padding: 12px 24px;
                    visibility: ${isFirst ? 'hidden' : 'visible'};
                `}
                onClick={callbacks.revert}
            >
                Back
            </PrimaryButton>

            <PrimaryButton
                disabled={hasErrors || state.isLoading}
                onClick={callbacks.advance}
                css={css`
                    padding: 12px 24px;
                `}
            >
                {isLast ? 'Create' : 'Next'}
            </PrimaryButton>
        </Grid>
    );
}

export default Footer;
