import React from 'react';

import {
   ThemeProvider,
   createTheme,
   Grid,
   Box,
   Typography,
   Button,
   Tab,
   Tabs,
   Card,
   CardContent,
   Paper
} from '@material-ui/core';

import './style.css';

function HaulawayRoutedOrder(order) {

   let text = order.haulaway ? 'Yes' : 'No haulaway items';

   return (
      <Card className="items-card">
         <CardContent>
            <Grid container spacing={1}>
               <Grid item md={8}>
                  {text}
               </Grid>
               <Grid item md={4} style={{textAlign: 'right'}}></Grid>
            </Grid>
         </CardContent>
      </Card>
   )
}

export default HaulawayRoutedOrder;
