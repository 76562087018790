import { CORPORATE_ADMIN_FIELDS } from '@/graphql/queries/corporate_admins';
import { USER_FIELDS } from '@/graphql/queries/users';
import { gql } from '@apollo/client';

export const GET_CORPORATE_USER = gql`
    ${USER_FIELDS}
    ${CORPORATE_ADMIN_FIELDS}
    query GetCorporateUser($user_id: uuid!) {
        user: users_by_pk(user_id: $user_id) {
            ...UserFields
            corporate_admin {
                ...CorporateAdminFields
            }
        }
    }
`;

export const SAVE_CORPORATE_USER = gql`
    ${USER_FIELDS}
    ${CORPORATE_ADMIN_FIELDS}
    mutation SaveCorporateUser(
        $user_id: uuid!
        $user_update: users_set_input = {}
        $corporate_update: corporate_admins_set_input = {}
    ) {
        update_users(where: { user_id: { _eq: $user_id } }, _set: $user_update) {
            affected_rows
            returning {
                ...UserFields
            }
        }
        update_corporate_admins(where: { corporate_admin_id: { _eq: $user_id } }, _set: $corporate_update) {
            affected_rows
            returning {
                ...CorporateAdminFields
            }
        }
    }
`;
