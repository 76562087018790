export const recommendedEquipmentLabels = {
    ['1_man_team']: '1 Man Team',
    ['2_man_team']: '2 Man Team',
    ['3_man_team']: '3 Man Team',
    ['2_wheel_dolly']: '2-Wheel Dolly',
    ['4_wheel_cart']: '4-Wheel Cart',
    appliance_dolly: 'Appliance Dolly',
    moving_straps: 'Moving Straps',
    packing_materials: 'Moving Blankets/Moving Pads/Shrink Wrap',
    installation_tools: 'Installation Tool Kit',
    lift_gate: 'Lift Gate',
    window_cart: 'Window Cart',
    pallet_jack: 'Pallet Jack',
    electric_pallet_jack: 'Electric Pallet Jack',
    oversized_pallet_jack: 'Oversized Pallet Jack',
};
