export default {
    household: {
        value: 'household',
        dbValue: 'Furniture',
        label: 'Household Items',
        displayOrder: 0,
    },
    pallet: {
        value: 'pallet',
        dbValue: 'Pallet',
        label: 'Palletized Goods',
        displayOrder: 1,
    },
    remodelling: {
        value: 'remodelling',
        dbValue: 'Remodelling',
        label: 'Remodelling Items',
        displayOrder: 2,
    },
};
