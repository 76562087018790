import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { USER_FIELDS } from '@/graphql/queries/users';
import { RATING_FIELDS } from '@/graphql/queries/ratings';

export const QUERY_BY_ID = gql`
    ${ORDER_FIELDS}
    ${CLIENT_FIELDS}
    ${USER_FIELDS}
    ${RATING_FIELDS}

    query OrdersByOrderID($order_id: uuid!) {
        order: orders_by_pk(order_id: $order_id) {
            ...OrderFields
            routes(order_by: { created_at: desc }) {
                mapping_id
                order_id
                route_id
                type
                created_at
                route {
                    route_id
                    route_number
                }
            }
            ratings(order_by: { created_at: desc }) {
                ...RatingFields
            }
            order_shipper {
                ...ClientFields
                user {
                    ...UserFields
                }
            }
            order_carrier {
                ...ClientFields
                user {
                    ...UserFields
                }
            }
        }
    }
`;
