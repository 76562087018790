import React from 'react';
import { Grid, } from '@material-ui/core';
import { OnwardCard } from './Card';
import { CardTitle, } from './blocks';
import {css} from '@emotion/react';
import { colors } from '@/styles';

export default function EmptyCard(props) {
    const { title, text } = props;

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    return (
        <OnwardCard 
            css={css`height: 100%;`} 
            contentProps={{style: { height: '100%' }}}
        >
            <CardTitle style={{ display: 'block', marginBottom: '1.5rem' }}>{title}</CardTitle>
            <CardRow>
                <Grid item>
                    <span css={css`
                        font-weight: 500;
                        font-size: 14px;
                        color: ${colors.greys.primary}
                    `}>
                        {text}
                    </span>
                </Grid>
            </CardRow>
        </OnwardCard>
    );
}
