import { gql } from '@apollo/client';
import { ITEM_FIELDS } from '../queries/items';

export const UPDATE_ITEM_BY_ID = gql`
    ${ITEM_FIELDS}

    mutation UpdateItemById($item_id: uuid!, $update: items_set_input = {}) {
        update_items_by_pk(pk_columns: { item_id: $item_id }, _set: $update) {
            ...ItemFields
        }
    }
`;
