export const OWNERSHIP_TYPES = {
    INTERNAL: 'INTERNAL',
    ONWARD: 'ONWARD',
    COMINGLED: 'COMINGLED',
};

export const getRouteOwnership = ({ route, orders, user }) => {
    const isShipper = user.user_id === route.shipper_id;
    const isInternal = orders.every((order) => order.oms);
    const isComingled = new Set(orders.map((order) => order.shipper_id)).size > 1;

    if (!route.oms) {
        return OWNERSHIP_TYPES.ONWARD;
    }

    if (route.shipper_id === route.carrier_id || isInternal) {
        return OWNERSHIP_TYPES.INTERNAL;
    }

    if (isComingled) {
        if (isShipper) {
            return OWNERSHIP_TYPES.ONWARD;
        } else {
            return OWNERSHIP_TYPES.COMINGLED;
        }
    }

    return OWNERSHIP_TYPES.ONWARD;
};

export const getOrderOwnership = ({ order, user_id }) => {
    const isInternal = order.oms;

    if (
        (isInternal && order.shipper_id === order.carrier_id) ||
        (isInternal && !order.carrier_id) ||
        (isInternal && order.carrier_id === user_id)
    ) {
        return OWNERSHIP_TYPES.INTERNAL;
    }

    return OWNERSHIP_TYPES.ONWARD;
};
