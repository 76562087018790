import { gql } from '@apollo/client';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { USER_FIELDS } from '@/graphql/queries/users';

export const SERVICE_CLIENTS = gql`
    ${CLIENT_FIELDS}
    ${USER_FIELDS}
    query clients($user_id: uuid!) {
        corporate_admins_by_pk(corporate_admin_id: $user_id) {
            corporate_admin_id
            service {
                service_clients {
                    client {
                        ...ClientFields
                        user {
                            ...UserFields
                        }
                    }
                }
            }
        }
    }
`;
