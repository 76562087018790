import React, { useEffect, useState, useMemo, useContext } from 'react';

import { css } from '@emotion/react';
import { asBrowserDate } from '@/utilities/convertToISO';
import { Grid, TextField, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

import { Body1 } from './blocks';

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const Recommendations = ({ recommendations, deliveryDate, callbacks }) => {
    return (
        <>
            <Grid
                item
                css={css`
                    margin-bottom: 4px;
                `}
            >
                <Body1>Delivery Date</Body1>
            </Grid>

            {recommendations.slice(0, 3).map((recommendation) => {
                const inLocal = asBrowserDate(recommendation.start);
                return (
                    <Grid
                        item
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <FormControlLabel
                            checked={deliveryDate === callbacks.toDropoffTz(inLocal)}
                            control={<Radio color="primary" />}
                            label={<Body1>{dateShort.format(inLocal)}</Body1>}
                            onChange={() => {
                                callbacks.setDeliveryDate(callbacks.toDropoffTz(inLocal));
                            }}
                        />
                    </Grid>
                );
            })}
            <Grid
                item
                css={css`
                    margin-bottom: 12px;
                `}
            >
                <FormControlLabel
                    checked={deliveryDate === null}
                    control={<Radio color="primary" />}
                    label={<Body1>None of these dates work</Body1>}
                    onChange={() => {
                        callbacks.setDeliveryDate(null);
                    }}
                />
            </Grid>
        </>
    );
};

export default Recommendations;
