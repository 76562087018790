import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UnsureResponseNotification from '../Notification/NotificationTypes/UnsureResponseNotification';
import NoResponseNotification from '../Notification/NotificationTypes/NoResponseNotification';
import SMSFailedNotification from '../Notification/NotificationTypes/SMSFailedNotification';
import ExceptionNotification from '../Notification/NotificationTypes/ExceptionNotification';
import FinalReturnNotification from '../Notification/NotificationTypes/FinalReturnNotification';
import WarehouseReceivedNotification from '../Notification/NotificationTypes/WarehouseReceivedNotificaion';
import WatchedLtlOpenedNotification from '../Notification/NotificationTypes/WatchedLtlOpenedNotification';
import { css } from '@emotion/react';
import { useMutation } from '@apollo/client';
import { RESOLVE_MANY_NOTIFICATIONS } from '../../graphql/mutations/notifications';
import BidPlacedNotification from '../Notification/NotificationTypes/BidPlacedNotification';
import BidAcceptedNotification from '../Notification/NotificationTypes/BidAcceptedNotification';
import ListingClaimedNotification from '../Notification/NotificationTypes/ListingClaimedNotification';
import BidRejectedNotification from '../Notification/NotificationTypes/BidRejectedNotification';
import NewCounterNotification from '../Notification/NotificationTypes/NewCounterNotification';
import PickupAvailabilityNeededNotification from '../Notification/NotificationTypes/PickupAvailabilityNeeded';
import CarrierAutoAssignedOrderByZipNotification from '../Notification/NotificationTypes/CarrierAutoAssignedOrderByZipNotification';
import OrderPutOnHold from '../Notification/NotificationTypes/OrderPutOnHold';
import PieceCountVerificationNotif from '../Notification/NotificationTypes/PieceCountVerificationNotif';

export default function NotificationsModal({ handleCloseNotification, notifications }) {
    const [resolveManyNotifications] = useMutation(RESOLVE_MANY_NOTIFICATIONS, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const renderNotification = (notification) => {
        switch (notification.notification_type) {
            case 'MARKETPLACE_BID_PLACED':
                return (
                    <BidPlacedNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'MARKETPLACE_BID_ACCEPTED':
                return (
                    <BidAcceptedNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'MARKETPLACE_LISTING_CLAIMED':
                return (
                    <ListingClaimedNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'MARKETPLACE_COUNTER_PLACED':
                return (
                    <NewCounterNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'MARKETPLACE_BID_REJECTED':
                return (
                    <BidRejectedNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'FINAL_RETURN':
                return (
                    <FinalReturnNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'CUSTOMER_SMS_UNSURE_RESPONSE':
                return (
                    <UnsureResponseNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'CUSTOMER_SMS_FLOW_NO_RESPONSE':
                return (
                    <NoResponseNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'CUSTOMER_SMS_FLOW_FAILED':
                return (
                    <SMSFailedNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'EXCEPTION':
                return (
                    <ExceptionNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'WAREHOUSE_RECEIVED':
                return (
                    <WarehouseReceivedNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'LTL_TO_OPEN':
                return (
                    <WatchedLtlOpenedNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'CUSTOMER_SHIPMENT_FORM_FIRST_AVAILABLE':
                return (
                    <PickupAvailabilityNeededNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'ORDER_AUTO_ASSIGNED_TO_CARRIER':
                return (
                    <CarrierAutoAssignedOrderByZipNotification
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'ORDER_PUT_ON_HOLD':
                return (
                    <OrderPutOnHold
                        handleCloseNotification={handleCloseNotification}
                        notification={notification}
                        key={notification.key}
                    />
                );
            case 'CUSTOMER_SHIPMENT_FORM_PIECE_COUNT_VERIFICATION':
                    return (
                        <PieceCountVerificationNotif
                            handleCloseNotification={handleCloseNotification}
                            notification={notification}
                            key={notification.key}
                        />
                    );
            default:
                return null;
        }
    };

    const resolveAll = async (event) => {
        event.preventDefault();
        await resolveManyNotifications({
            variables: {
                notification_ids: notifications.map((n) => n.notification_id),
            },
        });
    };

    return (
        <div
            css={css`
                min-width: 32vw;
                max-width: 32vw;
                padding: 20px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <Button onClick={handleCloseNotification}>
                    <CloseIcon
                        css={css`
                            color: rgba(0, 0, 0, 0.87);
                        `}
                    />
                </Button>
            </div>
            <Grid
                container
                direction="row"
                css={css`
                    padding: 20px;
                    justify-content: space-between;
                `}
            >
                <Grid
                    item
                    css={css`
                        padding-right: 40px;
                    `}
                >
                    <p
                        css={css`
                            font-family: Montserrat, Roboto, Arial;
                            font-size: 28px;
                            font-weight: 800;
                            line-height: 34.13px;
                            color: #0c0b1d;
                        `}
                    >
                        Notifications
                    </p>
                </Grid>
                <Grid item>
                    <Button
                        css={css`
                            height: 36px;
                            // width: 36px;
                            margin-top: 0px;
                            border-radius: 4px;
                            background-color: #59b863;
                            color: white;
                            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
                            &:hover {
                                background-color: #28a745;
                                color: white;
                            }
                        `}
                        onClick={resolveAll}
                    >
                        <Typography
                            css={css`
                                font-weight: 500;
                                font-size: 16px;
                                padding-left: 10px;
                                padding-right: 10px;
                            `}
                        >
                            Resolve All
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <>
                <div
                    css={css`
                        min-width: 29vw;
                        max-width: 29;
                        max-height: 77.5vh;
                        overflow-y: scroll;
                        overflow-x: hidden;
                        margin-right: -15px;
                    `}
                >
                    {notifications?.length > 0 ? (
                        <>{notifications.map((notification) => renderNotification(notification))}</>
                    ) : (
                        <div
                            css={css`
                                padding: 30px;
                            `}
                        >
                            <p>You have no notifications</p>
                            <p>When you receive one, it will appear here.</p>
                        </div>
                    )}
                </div>
            </>
        </div>
    );
}
