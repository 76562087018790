export const RATES = [
    {
        name: 'Curbside',
        xLabels: ['', '', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles', 'Additional items(s)'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return ['MILES_CURBSIDE_BASE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
            [
                {
                    type: 'MILES_DEFAULT_INCREMENT_RATE',
                    bucket: 1,
                    decorator: 'per mile',
                },
            ],
            [
                {
                    type: 'ITEM_COUNT_CURBSIDE_RATE',
                    bucket: 0,
                    decorator: 'per item',
                },
            ],
        ],
    },
    {
        name: 'Threshold / FDS',
        xLabels: ['', '', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles', 'Additional items(s)'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return ['MILES_FIRST_DRY_SPACE_BASE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
            [
                {
                    type: 'MILES_DEFAULT_INCREMENT_RATE',
                    bucket: 1,
                    decorator: 'per mile',
                },
            ],
            [
                {
                    type: 'ITEM_COUNT_FIRST_DRY_SPACE_RATE',
                    bucket: 0,
                    decorator: 'per item',
                },
            ],
        ],
    },
    {
        name: 'Room of Choice',
        xLabels: ['', '', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles', 'Additional items(s)'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return ['MILES_ROOM_OF_CHOICE_BASE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
            [
                {
                    type: 'MILES_DEFAULT_INCREMENT_RATE',
                    bucket: 1,
                    decorator: 'per mile',
                },
            ],
            [
                {
                    type: 'ITEM_COUNT_ROOM_OF_CHOICE_RATE',
                    bucket: 0,
                    decorator: 'per item',
                },
            ],
        ],
    },
    {
        name: 'White Glove',
        xLabels: ['', '', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles', 'Additional items(s)'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return ['MILES_WHITE_GLOVE_BASE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
            [
                {
                    type: 'MILES_DEFAULT_INCREMENT_RATE',
                    bucket: 1,
                    decorator: 'per mile',
                },
            ],
            [
                {
                    type: 'ITEM_COUNT_WHITE_GLOVE_RATE',
                    bucket: 0,
                    decorator: 'per item',
                },
            ],
        ],
    },
];

export const DEFAULT_RATES = {
    MILES_CURBSIDE_BASE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 'infinity' },
    ],
    MILES_FIRST_DRY_SPACE_BASE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 'infinity' },
    ],
    MILES_ROOM_OF_CHOICE_BASE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 'infinity' },
    ],
    MILES_WHITE_GLOVE_BASE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 'infinity' },
    ],

    MILES_DEFAULT_INCREMENT_RATE: [
        { min: 0, max: 100, rate: 0 },
        { min: 100, max: 'infinity' },
    ],

    ITEM_COUNT_CURBSIDE_RATE: [{ min: 3, max: 'infinity' }],
    ITEM_COUNT_FIRST_DRY_SPACE_RATE: [{ min: 3, max: 'infinity' }],
    ITEM_COUNT_ROOM_OF_CHOICE_RATE: [{ min: 3, max: 'infinity' }],
    ITEM_COUNT_WHITE_GLOVE_RATE: [{ min: 3, max: 'infinity' }],

    STAIRS_COUNT_DEFAULT_RATE: [
        { min: 0, max: 2, rate: 0 },
        { min: 2, max: 'infinity' },
    ],
    LABOR_MAX_RATE: [{ min: 0, max: 'infinity' }],
    EXCHANGE_MODIFIER_RATE: [{ min: 0, max: 'infinity' }],
    ATTEMPT_MODIFIER_RATE: [{ min: 0, max: 'infinity' }],
    HAULAWAY_COUNT_DEFAULT_RATE: [{ min: 0, max: 'infinity' }],
    LABOR_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    ADDITIONAL_LABOR_RATE: [{ min: 0, max: 'infinity' }],
    PACKAGING_BASE_RATE: [{ min: 0, max: 'infinity' }],
};

export const ACCESSORIALS = [
    { label: 'Additional Person (3rd Person)', type: 'LABOR_MAX_RATE', adornment: '$', decorator: 'per hr' },
    { label: 'Additional Piece (Curbside)', type: 'ITEM_COUNT_CURBSIDE_RATE', adornment: '$', decorator: 'per item' },
    { label: 'Additional Piece (FDS)', type: 'ITEM_COUNT_FIRST_DRY_SPACE_RATE', adornment: '$', decorator: 'per item' },
    { label: 'Additional Piece (ROC)', type: 'ITEM_COUNT_ROOM_OF_CHOICE_RATE', adornment: '$', decorator: 'per item' },
    {
        label: 'Additional Piece (White glove)',
        type: 'ITEM_COUNT_WHITE_GLOVE_RATE',
        adornment: '$',
        decorator: 'per item',
    },
    {
        label: 'Wait Time/Additional Time',
        type: 'LABOR_INCREMENTAL_RATE',
        adornment: '$',
        decorator: 'per 15 min',
    },
    { label: 'Exchange', type: 'EXCHANGE_MODIFIER_RATE', adornment: '%', isPercentage: true },
    {
        label: 'Appliance/Furniture Disposal',
        type: 'HAULAWAY_COUNT_DEFAULT_RATE',
        adornment: '$',
        decorator: 'first 2 pieces',
    },
    {
        label: 'Stairs',
        type: 'STAIRS_COUNT_DEFAULT_RATE',
        adornment: '$',
    },
    {
        label: 'Storage',
        type: 'STORAGE_RATE',
        adornment: '$',
        decorator: 'per day (after 14 days)',
    },
    {
        label: 'Less that 2 hour window',
        type: 'RUSH_MODIFIER',
        adornment: '%',
        isPercentage: true,
    },
    {
        label: 'Open and Inspect',
        type: 'INSPECTION_BASE_RATE',
        adornment: '$',
        decorator: 'first 2 pieces',
    },
    {
        label: 'Open and Inspect',
        type: 'INSPECTION_INCREMENTAL_RATE',
        adornment: '$',
        decorator: 'additional',
    },
    {
        label: 'Repackaging for Transit',
        type: 'PACKAGING_BASE_RATE',
        adornment: '$',
    },
    { label: 'Attempt', type: 'ATTEMPT_MODIFIER_RATE', adornment: '%', isPercentage: true },
    {
        label: 'Fuel Modifier',
        type: 'FUEL_PRICE_MODIFIER',
        adornment: '%',
        isPercentage: true,
        decorator: 'of subtotal',
    },
    {
        label: 'Additional Mileage',
        type: 'MILEAGE_RATE',
        adornment: '$',
        decorator: 'per mile over limit',
    },
];

export const RenderAFAccessorial = ({ DefaultAccessorial, accessorial, rates, errors, callbacks }) => {
    switch (accessorial.type) {
        case 'FUEL_PRICE_MODIFIER':
        case 'ITEM_COUNT_CURBSIDE_RATE':
        case 'ITEM_COUNT_FIRST_DRY_SPACE_RATE':
        case 'ITEM_COUNT_ROOM_OF_CHOICE_RATE':
        case 'ITEM_COUNT_WHITE_GLOVE_RATE':
        case 'MILEAGE_RATE':
            return null;
        default: {
            return (
                <DefaultAccessorial
                    key={accessorial.type}
                    {...accessorial}
                    rates={rates}
                    errors={errors}
                    callbacks={callbacks}
                />
            );
        }
    }
};
