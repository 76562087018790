import { AppBar, useMediaQuery } from '@material-ui/core';
import React from 'react';
import onwardLogo from '../../assets/onwardlogotransparent.webp';
import { css } from '@emotion/react';
import { onwardNavy } from '@/styles/colors';

const CustomerHeader = ({ customHeader, customColor }) => {
    const mobile = useMediaQuery('(max-width: 600px)');
    return (
        <header
            css={css`
                position: sticky;
                top: 0;
                z-index: 10;
            `}
        >
            <AppBar>
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        background-color: ${customHeader && customColor ? customColor : mobile ? 'white' : onwardNavy};
                    `}
                >
                    <img
                        css={css`
                            ${!customHeader && `width: 130px;`}
                            height: 50px;
                            object-fit: cover;
                        `}
                        src={customHeader || onwardLogo}
                    />
                </div>
            </AppBar>
        </header>
    );
};

export default CustomerHeader;
