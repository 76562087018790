import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Global, css } from '@emotion/react';
import GoogleMap, { DirectionsRenderer, InfoWindow } from '@/components/GoogleMap';
import { Context } from '../store';
import { genAttributes } from '@onward-delivery/core';
import OrderMarker from './OrderMarker';
import { MARKER_COLORS } from '../constants';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { DropOff, EndLocation, PickUp, StartLocation } from '@/components/DispatchPlan/map/MapMarkers';
import zIndex from '@material-ui/core/styles/zIndex';
import MarketplaceInfoWindow from './MarketplaceInfoWindow';
import SupplyInfoWindow from './SupplyInfoWindow';

const DEFAULT_CENTER = { lat: 39.74, lng: -104.99 };
const DEFAULT_ZOOM = 13;
const TOOLTIP_OPTIONS = {
    minWidth: 290,
    maxWidth: 290,
    disableAutoPan: true,
    pixelOffset: new window.google.maps.Size(0, -5),
};

const Map = () => {
    const { state, callbacks } = useContext(Context);

    const mapRef = useRef();
    const [mapDebouncer, setMapDebouncer] = useState(null);

    const [routeDirections, setRouteDirections] = useState({});
    const [loadDirections, setLoadDirections] = useState({});
    const [hoveredOrder, setHoveredOrder] = useState(null);
    const [hoveredStop, setHoveredStop] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);

    const orderHoverCallbacks = (order) => ({
        onMouseOver: (ref) => {
            setHoveredOrder(order);
            setSelectedMarker(ref);
        },
        onMouseOut: () => {
            setHoveredOrder(null);
            setSelectedMarker(null);
        },
    });

    const stopHoverCallbacks = (stop) => ({
        onMouseOver: (ref) => {
            setHoveredStop(stop);
            setSelectedMarker(ref);
        },
        onMouseOut: () => {
            setHoveredStop(null);
            setSelectedMarker(null);
        },
    });

    useEffect(() => {
        if (state.selectedOrder) {
            const { lat, long } = genAttributes(state.selectedOrder);
            const { lat: pulat, long: pulong } = genAttributes(state.selectedOrder, true);

            const bounds = {
                east: Math.max(state.selectedOrder[long], state.selectedOrder[pulong]),
                west: Math.min(state.selectedOrder[long], state.selectedOrder[pulong]),
                north: Math.max(state.selectedOrder[lat], state.selectedOrder[pulat]),
                south: Math.min(state.selectedOrder[lat], state.selectedOrder[pulat]),
            };
            mapRef?.current?.fitBounds(bounds);
        }
    }, [state.selectedOrder]);

    const onBoundsChange = (bounds) => {
        if (mapDebouncer) {
            clearTimeout(mapDebouncer);
        }
        setMapDebouncer(
            setTimeout(() => {
                callbacks.setBounds(bounds);
            }, 500)
        );
    };

    const remainingStops = useMemo(() => {
        if (state.selectedOrder?.__typename === 'orders') {
            return [];
        }

        return [...state.supplyRoutes, ...state.onwardRoutes].reduce((acc, route) => {
            return [...acc, ...(route.activity_stops || route.stopsByRouteId || [])];
        }, []);
    }, [state.selectedOrder, state.supplyRoutes, state.onwardRoutes]);

    const remainingOrders = useMemo(() => {
        if (state.selectedOrder) {
            return state.marketplaceOrders.filter((order) => order.order_id !== state.selectedOrder.order_id);
        }
        return state.marketplaceOrders;
    }, [state.selectedOrder, state.marketplaceOrders]);

    const shownRoutes = useMemo(() => {
        return [...state.supplyRoutes, ...state.onwardRoutes].filter((route) => !state.hiddenRoutes[route.route_id]);
    }, [state.supplyRoutes, state.onwardRoutes, state.hiddenRoutes]);

    useEffect(() => {
        if (!state.selectedOrder || loadDirections[state.selectedOrder.order_id]) {
            return;
        }

        FTLStopHelpers.getLoadDirections(state.selectedOrder).then((result) => {
            if (result.status === 'OK') {
                setLoadDirections((prev) => ({
                    ...prev,
                    [state.selectedOrder.order_id]: result,
                }));
            }
        });
    }, [state.selectedOrder]);

    useEffect(() => {
        if (state.selectedOrder?.__typename === 'orders') {
            [...state.supplyRoutes, ...state.onwardRoutes].forEach((route) => {
                const stops =
                    route.__typename === 'routes'
                        ? FTLStopHelpers.getStopSequence(route.stopsByRouteId)
                        : route.activity_stops;
                FTLStopHelpers.getGMapsDirections(stops).then((result) => {
                    console.log(result);
                    if (result.status === 'OK') {
                        setRouteDirections((prev) => ({
                            ...prev,
                            [route.route_id]: result,
                        }));
                    }
                });
            });
        }
    }, [state.selectedOrder, state.supplyRoutes, state.onwardRoutes]);

    const renderLoadMarker = (order, pickup = false) => {
        const { lat, long } = genAttributes(order, pickup);

        const isSupply = order.__typename === 'activity_orders';

        if (pickup) {
            return (
                <PickUp
                    position={{
                        lat: order[lat],
                        lng: order[long],
                    }}
                    color={isSupply ? MARKER_COLORS.SUPPLY_DROPOFF : MARKER_COLORS.MARKETPLACE_DROPOFF}
                    callbacks={orderHoverCallbacks(order)}
                />
            );
        } else {
            return (
                <DropOff
                    position={{
                        lat: order[lat],
                        lng: order[long],
                    }}
                    color={isSupply ? MARKER_COLORS.SUPPLY_DROPOFF : MARKER_COLORS.MARKETPLACE_DROPOFF}
                    iconLabel={isSupply ? 'X' : 'O'}
                    callbacks={orderHoverCallbacks(order)}
                />
            );
        }
    };

    const renderStopMarkers = (routes) => {
        const markers = routes.reduce((acc, route) => {
            const stops = (route.__typename === 'routes' ? route.stopsByRouteId : route.activity_stops || []).map(
                (stop) => ({
                    ...stop,
                    color: MARKER_COLORS.NEARBY_ROUTE,
                    zIndex: 2,
                    ...(route.route_id === state.selectedRoute?.route_id
                        ? {
                              color: MARKER_COLORS.SELECTED_ROUTE,
                              zIndex: 5,
                          }
                        : {}),
                })
            );
            return [...acc, ...stops];
        }, []);

        return markers.map((stop) => {
            const props = {
                color: stop.color,
                zIndex: stop.zIndex,
                position: { lat: stop.lat, lng: stop.lng },
                callbacks: stopHoverCallbacks(stop),
            };
            const iconLabel = (stop.ordering + 1).toString();

            if (stop.type === 'START' || (stop.type === 'PICKUP' && stop.start)) {
                return <StartLocation {...props} />;
            }
            if (stop.type === 'PICKUP') {
                return <PickUp {...props} />;
            }
            if (stop.type === 'END' || (stop.type === 'DROPOFF' && stop.end)) {
                return <EndLocation {...props} />;
            }
            if (stop.type === 'DROPOFF') {
                return <DropOff {...props} iconLabel={iconLabel} />;
            }

            return null;
        });
    };

    const tooltip = useMemo(() => {
        if (hoveredOrder && !hoveredOrder.external_carrier_id) {
            return <MarketplaceInfoWindow orders={[hoveredOrder]} type="load" />;
        } else if (hoveredOrder && hoveredOrder.external_carrier_id) {
            return <SupplyInfoWindow orders={[hoveredOrder]} type="load" />;
        }

        if (hoveredStop && hoveredStop.orders && shownRoutes) {
            const stopOrders = shownRoutes
                .find((route) => route.route_id === hoveredStop.route_id)
                ?.orders?.filter((mapping) => hoveredStop.orders.includes(mapping.order_id))
                ?.map((mapping) => mapping.order);
            return <MarketplaceInfoWindow stop={hoveredStop} orders={stopOrders} type="stop" />;
        } else if (hoveredStop && hoveredStop.point) {
            return <SupplyInfoWindow stop={hoveredStop} type="stop" />;
        }

        return null;
    }, [hoveredOrder, hoveredStop]);

    return (
        <div
            css={css`
                display: flex;
                flex-grow: 1;
            `}
        >
            <Global
                styles={css`
                    .gm-style .gm-style-iw-c {
                        & button[title='Close'] {
                            visibility: hidden;
                        }
                    }
                `}
            />
            <GoogleMap
                ref={mapRef}
                style={{ height: '100%' }}
                // bounds={bounds}
                // center={!useBounds ? DEFAULT_CENTER : undefined}
                // zoom={!useBounds ? DEFAULT_ZOOM : undefined}
                zoom={DEFAULT_ZOOM}
                center={DEFAULT_CENTER}
                onBoundsChange={onBoundsChange}
            >
                {state.selectedOrder?.__typename === 'orders' && shownRoutes.length > 0 && (
                    <>
                        {shownRoutes.map((route) =>
                            routeDirections[route.route_id] ? (
                                <DirectionsRenderer
                                    directions={routeDirections[route.route_id]}
                                    options={{
                                        suppressMarkers: true,
                                        polylineOptions: {
                                            strokeColor: MARKER_COLORS.NEARBY_ROUTE,
                                            strokeOpacity: 1,
                                            ...(state.selectedRoute?.route_id === route.route_id
                                                ? {
                                                      strokeColor: MARKER_COLORS.SELECTED_ROUTE,
                                                      zIndex: 10,
                                                  }
                                                : {}),
                                        },
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        )}
                    </>
                )}
                {state.selectedOrder?.__typename === 'orders' && shownRoutes.length > 0 && (
                    <>{renderStopMarkers(shownRoutes)}</>
                )}
                {state.selectedOrder && loadDirections[state.selectedOrder.order_id] && (
                    <>
                        <DirectionsRenderer
                            directions={loadDirections[state.selectedOrder.order_id]}
                            options={{
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor:
                                        state.selectedOrder.__typename === 'orders'
                                            ? MARKER_COLORS.MARKETPLACE_PICKUP
                                            : MARKER_COLORS.SUPPLY_PICKUP,
                                    strokeOpacity: 1,
                                    zIndex: 10,
                                },
                            }}
                        />

                        {renderLoadMarker(state.selectedOrder)}
                        {renderLoadMarker(state.selectedOrder, true)}
                    </>
                )}
                {remainingOrders.map((order) => {
                    const { lat, long } = genAttributes(order);
                    return (
                        <OrderMarker
                            key={`dropoff-${order.order_id}`}
                            position={{ lat: order[lat], lng: order[long] }}
                            color={MARKER_COLORS.MARKETPLACE_DROPOFF}
                            callbacks={orderHoverCallbacks(order)}
                        />
                    );
                })}
                {remainingOrders.map((order) => {
                    const { lat, long } = genAttributes(order, true);
                    return (
                        <OrderMarker
                            key={`pickup-${order.order_id}`}
                            position={{ lat: order[lat], lng: order[long] }}
                            color={MARKER_COLORS.MARKETPLACE_PICKUP}
                            callbacks={orderHoverCallbacks(order)}
                        />
                    );
                })}
                {state.supplyOrders.map((order) => {
                    const { lat, long } = genAttributes(order);
                    return (
                        <OrderMarker
                            key={`dropoff-${order.order_id}`}
                            position={{ lat: order[lat], lng: order[long] }}
                            color={MARKER_COLORS.SUPPLY_DROPOFF}
                            callbacks={orderHoverCallbacks(order)}
                        />
                    );
                })}
                {state.supplyOrders.map((order) => {
                    const { lat, long } = genAttributes(order, true);
                    return (
                        <OrderMarker
                            key={`pickup-${order.order_id}`}
                            position={{ lat: order[lat], lng: order[long] }}
                            color={MARKER_COLORS.SUPPLY_PICKUP}
                            callbacks={orderHoverCallbacks(order)}
                        />
                    );
                })}
                {remainingStops.map((stop) => {
                    const { lat, lng, stop_id } = stop;
                    return (
                        <OrderMarker
                            key={`stop-${stop_id}`}
                            position={{ lat, lng }}
                            color={MARKER_COLORS.NEARBY_ROUTE}
                            callbacks={stopHoverCallbacks(stop)}
                        />
                    );
                })}
            </GoogleMap>
            <InfoWindow content={tooltip} map={mapRef} marker={selectedMarker?.current} opts={TOOLTIP_OPTIONS} />
        </div>
    );
};

export default Map;
