import { gql } from '@apollo/client';

export const QUERY_CARRIERS = gql`
    query GetCarriers($test: Boolean!) {
        results: clients(
            where: {
                user: { roles: { _has_key: "CARRIER" } }
                test_acc: { _eq: $test }
                deactivated: { _eq: false }
                approved: { _eq: true }
            }
            order_by: { business_name: asc_nulls_last }
        ) {
            client_id
            business_name
            email
            locations {
                location_id
                location_name
            }
        }
    }
`;
