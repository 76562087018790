import React from 'react';

import { css } from '@emotion/react';
import { colors } from '@/styles';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import HouseOutlinedIcon from '@material-ui/icons/HouseOutlined';
import { Grid } from '@material-ui/core';
import {
    local,
    IconBubble,
    Icon,
    FlexRow,
    FlexColumn,
    BodyLabel1,
    BodyDescription1,
    Header1,
    PrimaryButton,
} from './blocks';
import { PROGRESSION_TYPES, FORM_STATUS } from './constants';

function FormSelect({ callbacks }) {
    return (
        <Grid container direction="column">
            <Grid
                container
                css={css`
                    margin-bottom: 4px;
                    justify-content: center;
                    text-align: center;
                    color: ${local.greys[4]};
                `}
            >
                <Header1>Create a Delivery</Header1>
            </Grid>
            <FlexRow>
                <FlexColumn
                    container
                    css={css`
                        max-width: 260px;
                    `}
                >
                    <Grid
                        container
                        css={css`
                            justify-content: center;
                            margin-bottom: 12px;
                        `}
                    >
                        <IconBubble
                            css={css`
                                background: ${colors.white.primary};
                                color: ${local.greys[3]};
                            `}
                        >
                            <Icon>
                                <StoreOutlinedIcon />
                            </Icon>
                        </IconBubble>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            justify-content: center;
                            margin-bottom: 4px;
                            color: ${local.greys[3]};
                        `}
                    >
                        <BodyLabel1>Store-to-Door Delivery</BodyLabel1>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            justify-content: center;
                            text-align: center;
                            margin-bottom: 20px;
                            color: ${local.greys[1]};
                        `}
                    >
                        <BodyDescription1>
                            Bought your items from a store and need them conveniently delivered to your home or
                            business? Onward has got you covered.
                        </BodyDescription1>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            justify-content: center;
                            margin-bottom: 30px;
                        `}
                    >
                        <PrimaryButton onClick={() => callbacks.setProgressionType(PROGRESSION_TYPES.BUSINESS)}>
                            Start Store Delivery
                        </PrimaryButton>
                    </Grid>
                </FlexColumn>
                <FlexColumn
                    container
                    css={css`
                        max-width: 260px;
                    `}
                >
                    <Grid
                        container
                        css={css`
                            justify-content: center;
                            margin-bottom: 12px;
                        `}
                    >
                        <IconBubble
                            css={css`
                                color: ${local.greys[3]};
                                background: ${colors.white};
                            `}
                        >
                            <Icon>
                                <HouseOutlinedIcon />
                            </Icon>
                        </IconBubble>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            justify-content: center;
                            margin-bottom: 4px;
                            color: ${local.greys[4]};
                        `}
                    >
                        <BodyLabel1>Effortless Personal Delivery</BodyLabel1>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            justify-content: center;
                            text-align: center;
                            margin-bottom: 20px;
                            color: ${local.greys[1]};
                        `}
                    >
                        <BodyDescription1>
                            Bought items from an individual or need to move items to a new location? Onward makes it
                            easy to get your personal items delivered hassle-free.
                        </BodyDescription1>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            justify-content: center;
                            margin-bottom: 30px;
                        `}
                    >
                        <PrimaryButton onClick={() => callbacks.setProgressionType(PROGRESSION_TYPES.RESIDENTIAL)}>
                            Start Personal Delivery
                        </PrimaryButton>
                    </Grid>
                </FlexColumn>
            </FlexRow>
        </Grid>
    );
}

export default FormSelect;
