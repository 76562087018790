import bidImage from '@/assets/bid.png';
import { css } from '@emotion/react';

const BidIcon = ({ ...props }) => {
    return (
        <img
            {...props}
            src={bidImage}
            css={css`
                width: 17px;
                height: 25px;
                margin: 4px;
            `}
        />
    );
};

export default BidIcon;
