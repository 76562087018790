import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { addDays, getWeek, setWeek, startOfWeek, endOfWeek, format } from 'date-fns';

import { genAccessorials } from '@/components/Account/Tariffs/utils';
import { useClientUser } from '@/hooks';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';

import { QUICKBOOKS_CSV_COLUMNS } from '../payables/columns';
import { genCallback } from '../payables/hooks';
import {
    CARRIER_INVOICES,
    SET_INVOICE_STATUS,
    GET_SHIPPERS,
    SET_INVOICE_FLAG,
    GET_ORDERS_TO_INVOICE,
    CREATE_INVOICES,
} from '../payables/graphql';
import { useColumns } from './columns';
import { FILTERS } from './constants';

export const Context = createContext();

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
const thisWeek = getWeek(TODAY, { weekStartsOn: 1 });
const lastMo = setWeek(TODAY, thisWeek - 4, { weekStartsOn: 1 });
const sow = startOfWeek(lastMo, { weekStartsOn: 1 });

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { user_id } = useClientUser();
    const [createReceivable, openCreateReceivable] = useState(false);
    const [createInprogress, setCreateInprogress] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [selectedMap, setSelected] = useState({});
    const [filter, setFilter] = useState({
        status: FILTERS.SUBMITTED,
        shippers: [],
    });

    const [getInvoices, { data, loading: initInflight, fetchMore }] = useLazyQuery(CARRIER_INVOICES, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [getShippers, { data: shipperData }] = useLazyQuery(GET_SHIPPERS, {
        onError: (err) => {
            captureException(err);
        },
    });

    const [getReceivableOrders, { loading: queryReceivableOrdersInflight }] = useLazyQuery(GET_ORDERS_TO_INVOICE, {
        onError: (err) => {
            captureException(err);
        },
    });

    const [setInvoiceStatus, { loading: invoiceStatusInflight }] = useMutation(SET_INVOICE_STATUS, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [setInvoiceFlag] = useMutation(SET_INVOICE_FLAG, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [createInvoices, { loading: createInflight }] = useMutation(CREATE_INVOICES, {
        update: (cache, { data: { created } }) => {
            const invoices = created?.returning || [];
            cache.updateQuery(
                {
                    query: CARRIER_INVOICES,
                    variables: {
                        where: {
                            _and: where,
                        },
                    },
                },
                (data) => {
                    return { results: [...data.results, ...invoices] };
                }
            );
        },
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [fetchOverrides, { loading: exportInflight }] = useLazyQuery(GET_PRICING_OVERRIDES);

    const shippers = useMemo(() => {
        const shippers =
            (shipperData?.results || [])
                .map((order) => {
                    return {
                        label: order?.order_shipper?.business_name,
                        value: order.shipper_id,
                    };
                })
                .sort((l, r) => l.label.localeCompare(r.label)) || [];

        return shippers;
    }, [shipperData]);

    const where = useMemo(() => {
        let status = [];
        switch (filter.status) {
            case FILTERS.SUBMITTED:
                status = [{ status: { _eq: 'UNPAID' } }];
                break;
            case FILTERS.APPROVED:
                status = [{ status: { _eq: 'APPROVED' } }];
                break;
            case FILTERS.PAID:
                status = [{ status: { _eq: 'PAID' } }];
                break;
        }

        return [
            ...status,
            ...(filter?.shippers?.length > 0 ? [{ client_id: { _in: filter?.shippers } }] : []),

            { type: { _eq: 'RECEIVABLE' } },
        ];
    }, [filter]);

    const invoices = useMemo(() => {
        return data?.results || [];
    }, [data]);

    const selected = useMemo(() => {
        return Object.keys(selectedMap).filter((attr) => selectedMap[attr]);
    }, [selectedMap]);

    const invoicesMap = useMemo(() => {
        return Object.fromEntries(invoices.map((invoice) => [invoice.carrier_invoice_id, invoice]));
    }, [invoices]);

    const selectedObj = useMemo(() => {
        return selected.map((id) => invoicesMap[id]);
    }, [selected, invoicesMap]);

    const filtered = useMemo(() => {
        let status;
        switch (filter.status) {
            case FILTERS.SUBMITTED:
                status = 'UNPAID';
                break;
            case FILTERS.APPROVED:
                status = 'APPROVED';
                break;
            case FILTERS.PAID:
                status = 'PAID';
                break;
        }

        return invoices.filter((invoice) => {
            return invoice.status === status && (!filter?.week || invoice.week_number === filter?.week?.week_number);
        });
    }, [invoices, filter]);

    const getInvoicesDebounced = useMemo(
        () =>
            debounce((payload) => {
                return getInvoices(payload);
            }, 500),
        []
    );

    useEffect(() => {
        getInvoices({
            variables: {
                where: {
                    _and: where,
                },
            },
        });

        getShippers({
            variables: {
                start: sow,
            },
        });
    }, []);

    useEffect(() => {
        if (!initInflight) {
            getInvoicesDebounced({
                variables: {
                    where: {
                        _and: where,
                    },
                },
            });

            setHasMore(true);
        }
    }, [where, getInvoicesDebounced, initInflight]);

    const cursor = useMemo(() => {
        if (invoices.length === 0) {
            return null;
        }

        return invoices[invoices.length - 1].created_at;
    }, [invoices]);

    const weeks = useMemo(() => {
        return Array(5)
            .fill(0)
            .map((_, idx) => idx + 1)
            .map((mod) => {
                const day = addDays(TODAY, -1 * mod * 7);
                const week = setWeek(day, -1 * mod + thisWeek, { weekStartsOn: 1 });
                const sow = startOfWeek(week, { weekStartsOn: 1 });
                const eow = endOfWeek(week, { weekStartsOn: 1 });

                return {
                    sow,
                    eow,
                    week_number: -1 * mod + thisWeek,
                    label: `${dateNumeric.format(sow)} - ${dateNumeric.format(eow)}`,
                };
            });
    }, []);

    const loadMore = useCallback(() => {
        fetchMore({
            variables: {
                where: {
                    _and: [...where, { created_at: { _lte: cursor } }],
                },
            },
            updateQuery: (data, { fetchMoreResult }) => {
                const prev = Object.fromEntries(data.results.map((invoice) => [invoice.carrier_invoice_id, true]));
                const clone = [
                    ...data.results,
                    ...fetchMoreResult.results.filter((invoice) => !prev[invoice.carrier_invoice_id]),
                ];
                return {
                    results: clone,
                };
            },
        }).then((result) => {
            if (result.data.results.length < 100) {
                setHasMore(false);
            }
        });
    }, [where, cursor, fetchMore]);

    const callbacks = {
        exportCsv: () => {
            fetchOverrides({
                variables: {
                    shipper_ids: selectedObj.reduce((acc, invoice) => {
                        return [...acc, ...invoice.orders.map((order) => order.shipper_id)];
                    }, []),
                    carrier_ids: selectedObj.reduce((acc, invoice) => {
                        return [...acc, ...invoice.orders.map((order) => order.carrier_id)];
                    }, []),
                    client_ids: [],
                    partner_client_ids: [],
                    retailer_ids: [],
                },
            }).then(({ data: { shipper_defaults, shipper } }) => {
                let types = [...(shipper_defaults || []), ...(shipper || [])].map((override) => override.algo_type);
                if (types.length === 0) {
                    types = ['DEFAULT'];
                }

                const accessorials = types.reduce((acc, type) => {
                    return [...acc, ...genAccessorials(type)];
                }, []);

                return genCallback({
                    accessorials,
                    invoices: selectedObj,
                    breakdown: 'shipperBreakdown',
                    columns: QUICKBOOKS_CSV_COLUMNS,
                })();
            });
        },
        setFilter,
        loadMore,
        onRowClick: (invoice) => {
            return navigate(`/shipper/accounting/marketplace/invoice/${invoice.carrier_invoice_id}`);
        },
        getRowId: (invoice) => {
            return invoice.carrier_invoice_id;
        },
        setApproved: () => {
            setInvoiceStatus({
                variables: {
                    ids: selected,
                    status: 'APPROVED',
                },
            });
        },
        approveId: (id) => {
            setInvoiceStatus({
                variables: {
                    ids: [id],
                    status: 'APPROVED',
                },
            });
        },
        setPaid: () => {
            setInvoiceStatus({
                variables: {
                    ids: selected,
                    status: 'PAID',
                },
            });
        },
        payId: (id) => {
            setInvoiceStatus({
                variables: {
                    ids: [id],
                    status: 'PAID',
                },
            });
        },
        flagRowToggle: (invoice) => {
            setInvoiceFlag({
                variables: {
                    ids: [invoice.carrier_invoice_id],
                    flag: !invoice.flagged,
                },
            });
        },
        onCreateReceivable: ({ week, shippers }) => {
            const dueDate = addDays(TODAY, 30);
            setCreateInprogress(true);
            return getReceivableOrders({
                variables: {
                    shipper_ids: shippers,
                    start: week.sow,
                    end: week.eow,
                },
            })
                .then((resp) => {
                    const orders = resp?.data?.results || [];

                    const grouped = orders.reduce((acc, order) => {
                        acc[order.shipper_id] = [...(acc[order.shipper_id] || []), order];
                        return acc;
                    }, {});

                    return Object.entries(grouped).map(([shipper_id, orders]) => {
                        return {
                            client_id: shipper_id,
                            description: `Invoice for ${format(week.sow, 'yyyy-MM-dd')} - ${format(
                                week.eow,
                                'yyyy-MM-dd'
                            )}`,
                            due_date: dueDate.toISOString(),
                            pay_period_start: week.sow.toISOString(),
                            pay_period_end: week.eow.toISOString(),
                            week_number: week.week_number,
                            status: 'UNPAID',
                            type: 'RECEIVABLE',
                            shipper_orders: {
                                data: orders.map((order) => ({
                                    order_id: order.order_id,
                                })),
                                on_conflict: {
                                    constraint: 'orders_pkey',
                                    update_columns: ['shipper_invoice_id'],
                                },
                            },
                        };
                    });
                })
                .then((invoices) => {
                    return createInvoices({
                        variables: { invoices },
                    });
                })
                .then(() => {
                    return setCreateInprogress(false);
                });
        },
        selectRows: setSelected,
        hasMore,
        create: () => {
            openCreateReceivable(true);
        },
        onClose: () => {
            openCreateReceivable(false);
        },
    };

    const COLUMNS = useColumns({ callbacks, filter });

    return (
        <Context.Provider
            value={{
                state: {
                    weeks,
                    hasMore,
                    createReceivable,
                    shippers,
                    selected,
                    invoices: filtered,
                    filter,
                    columns: COLUMNS,
                },
                loading: {
                    export: exportInflight,
                    init: initInflight,
                    paid: invoiceStatusInflight,
                    create: createInflight || queryReceivableOrdersInflight || createInprogress,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
