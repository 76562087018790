import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Body1 = styled.span`
    font-size: 14px;
    font-weight: 800;
`;

export const Body2 = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

export const Body3 = styled.span`
    font-size: 16px;
    font-weight: 500;
`;

export const Body4 = styled.span`
    font-size: 16px;
    font-weight: 700;
`;
