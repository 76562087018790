import styled from '@emotion/styled';
import { typography } from '@/styles';

export const Title = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;
