import { gql } from '@apollo/client';
import { CARRIER_INVOICE_FIELDS } from '@/graphql/queries/carrier_invoices';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { ORDER_FIELDS } from '@/graphql/queries/orders';

export const CARRIER_INVOICES = gql`
    ${CARRIER_INVOICE_FIELDS}
    ${CLIENT_FIELDS}
    ${ORDER_FIELDS}

    query GetAllCarrierInvoices($where: carrier_invoices_bool_exp!, $order_by: [carrier_invoices_order_by!]) {
        results: carrier_invoices(where: $where, order_by: $order_by, limit: 100) {
            ...CarrierInvoiceFields

            client {
                ...ClientFields
            }

            orders {
                ...OrderFields

                shipper_invoice {
                    ...CarrierInvoiceFields
                }
            }

            shipper_orders {
                ...OrderFields
            }
        }
    }
`;

export const SET_INVOICE_STATUS = gql`
    ${CARRIER_INVOICE_FIELDS}
    mutation SetInvoiceStatus($ids: [uuid!]!, $status: String!) {
        update_carrier_invoices(where: { carrier_invoice_id: { _in: $ids } }, _set: { status: $status }) {
            returning {
                ...CarrierInvoiceFields
            }

            affected_rows
        }
    }
`;

export const SET_INVOICE_FLAG = gql`
    ${CARRIER_INVOICE_FIELDS}
    mutation SetInvoiceStatus($ids: [uuid!]!, $flag: Boolean!) {
        update_carrier_invoices(where: { carrier_invoice_id: { _in: $ids } }, _set: { flagged: $flag }) {
            returning {
                ...CarrierInvoiceFields
            }

            affected_rows
        }
    }
`;

export const GET_CARRIERS = gql`
    ${CLIENT_FIELDS}

    query GetCarriers($where: carrier_invoices_bool_exp!) {
        results: carrier_invoices(distinct_on: [client_id], where: $where) {
            carrier_invoice_id
            client_id
            client {
                ...ClientFields
            }
        }
    }
`;

export const GET_SHIPPERS = gql`
    ${CLIENT_FIELDS}

    query GetShippers($start: timestamptz) {
        results: orders(
            distinct_on: [shipper_id]
            where: {
                _and: [
                    { oms: { _eq: false } }
                    { billing_completion_time: { _gte: $start } }
                    { shipper_invoice_id: { _is_null: true } }
                    { order_status: { _in: ["complete", "routedComplete"] } }
                ]
            }
        ) {
            order_id
            shipper_id
            order_shipper {
                ...ClientFields
            }
        }
    }
`;

export const GET_ORDERS_TO_INVOICE = gql`
    query GetOrdersToInvoice($shipper_ids: [uuid!]!, $start: timestamptz, $end: timestamptz) {
        results: orders(
            where: {
                _and: [
                    { shipper_id: { _in: $shipper_ids } }
                    { shipper_invoice_id: { _is_null: true } }
                    { carrier_invoice_id: { _is_null: false } }
                    { oms: { _eq: false } }
                    { order_status: { _in: ["complete", "routedComplete"] } }
                    { billing_completion_time: { _gte: $start } }
                    { billing_completion_time: { _lt: $end } }
                ]
            }
        ) {
            order_id
            shipper_id
        }
    }
`;

export const CREATE_INVOICES = gql`
    ${CARRIER_INVOICE_FIELDS}
    ${CLIENT_FIELDS}
    ${ORDER_FIELDS}

    mutation CreateCarrierInvoices($invoices: [carrier_invoices_insert_input!]!) {
        created: insert_carrier_invoices(objects: $invoices) {
            returning {
                ...CarrierInvoiceFields

                client {
                    ...ClientFields
                }

                orders {
                    ...OrderFields
                }

                shipper_orders {
                    ...OrderFields
                }
            }

            affected_rows
        }
    }
`;
