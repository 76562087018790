import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DropOff, PickUp } from '../DispatchPlan/map/MapMarkers';
import { Global, css } from '@emotion/react';
import GoogleMap, { DirectionsRenderer, InfoWindow } from '../GoogleMap';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import LoadTooltip from './LoadTooltip';

const SELECTED_COLOR = '#187522';
const UNSELECTED_COLOR = '#59B863';

const DEFAULT_CENTER = { lat: 39.74, lng: -104.99 };
const DEFAULT_ZOOM = 13;
const TOOLTIP_OPTIONS = {
    minWidth: 290,
    maxWidth: 290,
    disableAutoPan: true,
    pixelOffset: new window.google.maps.Size(0, -5),
};

const LoadsMap = ({ orders, selectedLoads, callbacks }) => {
    const mapRef = useRef();

    const [bounds, useBounds] = useMemo(() => {
        if (!orders?.length) {
            return [null, false];
        }
        return [
            [
                ...orders.map((order) => ({ lat: order.pickup_lat, lng: order.pickup_lng })),
                ...orders.map((order) => ({ lat: order.dropoff_lat, lng: order.dropoff_lng })),
            ],
            true,
        ];
    }, [orders]);

    const [loadMapData, setLoadMapData] = useState({});
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedStop, setSelectedStop] = useState(null);

    useEffect(() => {
        setLoadMapData({});
        if (orders?.length) {
            orders.forEach((load) => {
                if (loadMapData[load.order_id]) {
                    return;
                } else {
                    FTLStopHelpers.getLoadDirections(load).then((result) => {
                        if (result.status === 'OK') {
                            setLoadMapData((prev) => ({
                                ...prev,
                                [load.order_id]: {
                                    directions: result,
                                },
                            }));
                        }
                    });
                }
            });
        }
    }, [orders]);

    const tooltip = useMemo(() => {
        if (selectedStop?.orders?.length) {
            return <LoadTooltip orders={orders.filter((order) => selectedStop.orders.includes(order.order_id))} />;
        }

        return null;
    }, [selectedStop]);

    const reduceMarkers = (orders, type) => {
        const markers = orders.reduce((acc, order) => {
            const position =
                type === 'PICKUP'
                    ? { lat: order.pickup_lat, lng: order.pickup_lng }
                    : { lat: order.dropoff_lat, lng: order.dropoff_lng };
            const key = `${type}-${position.lat}-${position.lng}`;

            if (acc[key]) {
                const orderKeys = [...acc[key].orderKeys, order.order_id];
                return {
                    ...acc,
                    [key]: {
                        ...acc[key],
                        orderKeys,
                        color: orderKeys.every((order_id) => selectedLoads[order_id])
                            ? SELECTED_COLOR
                            : UNSELECTED_COLOR,
                    },
                };
            } else {
                return {
                    ...acc,
                    [key]: {
                        orderKeys: [order.order_id],
                        color: selectedLoads[order.order_id] ? SELECTED_COLOR : UNSELECTED_COLOR,
                        position,
                        key,
                    },
                };
            }
        }, {});
        return markers;
    };

    const renderPickupMarkers = (orders) => {
        const markers = reduceMarkers(orders, 'PICKUP');

        return Object.entries(markers).map(([key, marker]) => {
            return <PickUp key={`marker-${key}`} position={marker.position} color={marker.color} />;
        });
    };

    const renderDropoffMarkers = (orders) => {
        const markers = reduceMarkers(orders, 'DROPOFF');

        return Object.entries(markers).map(([key, marker]) => {
            return (
                <DropOff
                    key={`marker-${key}`}
                    position={marker.position}
                    color={marker.color}
                    iconLabel={'O'}
                    callbacks={{
                        onClick: () => {
                            callbacks?.selectOrders(marker.orderKeys);
                        },
                        onMouseOver: (ref) => {
                            setSelectedMarker(ref);
                            setSelectedStop({ orders: marker.orderKeys });
                        },
                        onMouseOut: () => {
                            setSelectedMarker(null);
                        },
                    }}
                />
            );
        });
    };

    const renderDirections = (mapData) => {
        return Object.entries(mapData).map(([order_id, { directions }]) => {
            return (
                <DirectionsRenderer
                    key={`directions-${order_id}`}
                    directions={directions}
                    options={{
                        suppressMarkers: true,
                        polylineOptions: {
                            strokeColor: UNSELECTED_COLOR,
                            strokeOpacity: 1,
                            ...(selectedLoads[order_id]
                                ? {
                                      zIndex: 10,
                                      strokeColor: SELECTED_COLOR,
                                  }
                                : {}),
                        },
                    }}
                />
            );
        });
    };

    return (
        <>
            <Global
                styles={css`
                    .gm-style .gm-style-iw-c {
                        & button[title='Close'] {
                            visibility: hidden;
                        }
                    }
                    .gm-style .gm-style-iw-chr {
                        display: none;
                    }

                    .gm-style-iw-t > .gm-style-iw-c {
                        padding-top: 12px !important;
                    }
                `}
            />
            <GoogleMap
                ref={mapRef}
                style={{ height: '100%' }}
                bounds={bounds}
                center={!useBounds ? DEFAULT_CENTER : undefined}
                zoom={!useBounds ? DEFAULT_ZOOM : undefined}
            >
                {orders?.length && orders.length <= 25 && <>{renderPickupMarkers(orders)}</>}
                {orders?.length && <>{renderDropoffMarkers(orders)}</>}
                {Object.keys(loadMapData).length && orders.length <= 25 && <>{renderDirections(loadMapData)}</>}
            </GoogleMap>
            <InfoWindow content={tooltip} map={mapRef} marker={selectedMarker?.current} opts={TOOLTIP_OPTIONS} />
        </>
    );
};

export default LoadsMap;
