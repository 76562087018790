import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { LocationOn } from '@material-ui/icons';
import { BodyCellText } from '../MyOrders/blocks';

export const useTableColumns = ({ callbacks }) => {
    return useMemo(() => {
        return [
            {
                Header: 'Inventory Management',
                id: 'inventory-details-group',
                columns: [
                    {
                        Header: 'SKU',
                        id: 'completed_date',
                        width: 200,
                        disableSortBy: false,
                        accessor: (itemBySku) => itemBySku.sku,
                    },
                    {
                        Header: 'Order',
                        id: 'order_number',
                        width: 100,
                        disableSortBy: false,
                        accessor: (itemBySku) => itemBySku.order_number,
                    },
                    {
                        Header: 'PO',
                        id: 'po_number',
                        width: 155,
                        disableSortBy: false,
                        accessor: (itemBySku) => (!itemBySku.po_number ? 'N/A' : itemBySku.po_number),
                        Cell: ({ value, column }) => (
                            <div
                                css={css`
                                    max-width: ${column.width - 5}px;
                                    overflow-x: auto;
                                    white-space: nowrap;
                                    padding-right: 10px; /* Add padding to ensure text doesn't touch the edge */
                                    margin-right: 10px; /* Add margin to separate from next column */
                                    &::-webkit-scrollbar {
                                        height: 2px; /* For Chrome, Safari, and Opera */
                                    }
                                    &::-webkit-scrollbar-thumb {
                                        background-color: rgba(0, 0, 0, 0.2);
                                        border-radius: 2px;
                                    }
                                    scrollbar-width: thin; /* For Firefox */
                                    &:hover {
                                        overflow-x: scroll; /* Show scrollbar on hover */
                                    }
                                `}
                            >
                                <BodyCellText>{value}</BodyCellText>
                            </div>
                        ),
                    },
                    {
                        Header: 'Shipper',
                        id: 'shipper',
                        width: 200,
                        disableSortBy: false,
                        accessor: (itemBySku) => itemBySku.shipper,
                        Cell: ({ value, column }) => (
                            <div
                                css={css`
                                    max-width: ${column.width - 5}px;
                                    overflow-x: auto;
                                    white-space: nowrap;
                                    padding-right: 10px; /* Add padding to ensure text doesn't touch the edge */
                                    margin-right: 10px; /* Add margin to separate from next column */
                                    &::-webkit-scrollbar {
                                        height: 2px; /* For Chrome, Safari, and Opera */
                                    }
                                    &::-webkit-scrollbar-thumb {
                                        background-color: rgba(0, 0, 0, 0.2);
                                        border-radius: 2px;
                                    }
                                    scrollbar-width: thin; /* For Firefox */
                                    &:hover {
                                        overflow-x: scroll; /* Show scrollbar on hover */
                                    }
                                `}
                            >
                                <BodyCellText>{value}</BodyCellText>
                            </div>
                        ),
                    },
                    {
                        Header: 'Date In',
                        id: 'dateIn',
                        width: 150,
                        disableSortBy: false,
                        accessor: (itemBySku) => itemBySku.dateIn,
                    },
                    {
                        Header: 'Qty In',
                        id: 'qtyIn',
                        width: 80,
                        disableSortBy: false,
                        accessor: (itemBySku) => `${itemBySku.receivedQuantity}/${itemBySku.totalQuantity}`,
                    },
                    {
                        Header: 'Inbound Manifests',
                        id: 'inManifests',
                        width: 175,
                        disableSortBy: false,
                        accessor: (itemBySku) =>
                            itemBySku.inboundManifests.length ? itemBySku.inboundManifests.join(', ') : '-',
                    },
                    {
                        Header: 'Date Out',
                        id: 'dateOut',
                        width: 150,
                        disableSortBy: false,
                        accessor: (itemBySku) => itemBySku.dateOut,
                    },
                    {
                        Header: 'Qty Out',
                        id: 'qtyOut',
                        width: 80,
                        disableSortBy: false,
                        accessor: (itemBySku) => `${itemBySku.outQuantity}/${itemBySku.totalQuantity}`,
                    },
                    {
                        Header: 'Outbound Manifests',
                        id: 'outManifests',
                        width: 175,
                        disableSortBy: false,
                        accessor: (itemBySku) =>
                            itemBySku.outboundManifests.length ? itemBySku.outboundManifests.join(', ') : '-',
                    },
                    {
                        Header: 'Pallets',
                        id: 'pallets',
                        width: 100,
                        disableSortBy: false,
                        accessor: (itemBySku) => itemBySku.pallets,
                    },
                    {
                        Header: 'Bins/Locations',
                        id: 'locations',
                        width: 175,
                        disableSortBy: false,
                        accessor: (itemBySku) =>
                            itemBySku.wh_locations.length ? itemBySku.wh_locations.join(', ') : '-',
                    },
                ],
            },
            {
                sticky: 'right',
                Header: 'Actions',
                id: 'actions-group',
                columns: [
                    {
                        Header: 'Status',
                        id: 'status',
                        width: 150,
                        disableSortBy: false,
                        accessor: (itemBySku) => {
                            let fragment;
                            switch (itemBySku.status) {
                                case 'NOT_RECEIVED':
                                    fragment = css`
                                        color: #d23e3e;
                                    `;
                                    break;
                                case 'IN_STOCK':
                                    fragment = css`
                                        color: #007bff;
                                    `;
                                    break;
                                case 'COMPLETE':
                                    fragment = css`
                                        color: #59b863;
                                    `;
                                    break;
                                default:
                                    fragment = css`
                                        color: inherit;
                                    `;
                            }
                            return (
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        white-space: nowrap;
                                    `}
                                >
                                    <LocationOn
                                        css={css`
                                            ${fragment}
                                        `}
                                    />
                                    <BodyCellText>
                                        {itemBySku.status === 'NOT_RECEIVED'
                                            ? 'Not Received'
                                            : itemBySku.status === 'IN_STOCK'
                                            ? 'In Stock'
                                            : itemBySku.status === 'COMPLETE'
                                            ? 'Complete'
                                            : itemBySku.status}
                                    </BodyCellText>
                                </div>
                            );
                        },
                    },
                ],
            },
        ];
    }, []);
};
