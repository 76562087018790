import React, { useContext } from 'react';
import { sidebarWidth, sidebarCollapsedWidth } from './constants';

import { css } from '@emotion/react';
import { UserContext } from '../App';

const NavResponsiveContainer = ({ children }) => {
    const { sidebarCollapsed, user } = useContext(UserContext);

    // iframe has 0/0
    // logged in user has 50/247 left and 0 top (side nav)
    // not logged in user has 0 left and 50 top (dark header)
    const marginLeft = user && window.self === window.top ? (sidebarCollapsed ? sidebarCollapsedWidth + 'px' : sidebarWidth + 'px') : '0px';
    const marginTop = !user && window.self === window.top ? '50px' : '0px';

    return (
        <div
            css={css`
                margin-left: ${marginLeft};
                margin-top: ${marginTop};
                height: calc(100vh - 50px);
            `}
        >
            {children}
        </div>
    );
};

export default NavResponsiveContainer;
