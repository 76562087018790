import React, { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useClientUser } from '@/hooks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { startCase } from 'lodash';
import { hourMinuteFormatter } from '@/constants/formats';
import { recommendedEquipmentLabels } from '@/constants/recommendedEquipmentLabels';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { OnwardCard } from '../OrderDetailsPage/Card';
import { CardTitle, CardItem, CardSubtitle, CardItemBold } from '../OrderDetailsPage/blocks';
import { Select, MenuItem } from '@material-ui/core';
export default function OrderSummaryCard({ order, handleSaveQBOClass, invoicingAccess }) {
    const { user_id, accountType, locations } = useClientUser();

    const [orderClass, setOrderClass] = useState(order.invoice_class);

    const qbo_classes = useMemo(() => {
        return [
            ...new Set(
                locations
                    .map((location) => location.qbo_class)
                    .filter((qbo_class) => qbo_class !== '' && qbo_class !== null)
            ),
        ];
    }, [locations]);

    const displayDeliveryTime = order?.carrier_id && user_id !== order?.carrier_id && !!order?.del_window_start;
    const displayNonSaasDeliveryTime =
        order?.carrier_id && user_id !== order?.carrier_id && !!order?.del_hour && !!order?.del_end;

    let timeRangeStart = '';
    let timeRangeEnd = '';
    if (displayDeliveryTime) {
        timeRangeStart = order.del_window_start;
        timeRangeEnd = order.del_window_end;
    } else if (displayNonSaasDeliveryTime) {
        const tz = zipcode_to_timezone.lookup(order.dropoff_zip) || 'America/New_York';

        timeRangeStart = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));
        timeRangeEnd = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));

        timeRangeStart.setHours(order.del_hour);
        timeRangeEnd.setHours(order.del_end);

        if (order.del_hour % 1 !== 0) {
            timeRangeStart.setMinutes(30);
        }
        if (order.del_end % 1 !== 0) {
            timeRangeEnd.setMinutes(30);
        }

        timeRangeStart = timeRangeStart.toISOString();
        timeRangeEnd = timeRangeEnd.toISOString();
    }

    const [pickupRoute, dropoffRoute] = useMemo(() => {
        return [
            (order?.routes || []).find((mapping) => mapping.type === 'PICKUP')?.route,
            (order?.routes || []).find((mapping) => mapping.type !== 'PICKUP')?.route,
        ];
    }, [order]);

    const {
        ['2_man_team']: twoManTeam,
        ['3_man_team']: threeManTeam,
        lift_gate,
        ...otherEquipment
    } = useMemo(() => {
        return order.recommended_equipment
            ? Object.keys(order.recommended_equipment)
                  .filter((key) => order.recommended_equipment[key])
                  .reduce((acc, val) => ({ ...acc, [val]: recommendedEquipmentLabels[val] }), {})
            : {};
    }, [order]);

    const orderTypeText = useMemo(() => {
        const isInternal =
            order?.carrier_id === user_id ||
            (order?.shipper_id === user_id &&
                order.oms &&
                (order?.carrier_id === user_id || order?.carrier_id === null));

        if (accountType === 'carrier') {
            const isClaimed = order?.shipper_id !== order?.carrier_id && order?.carrier_id === user_id;
            if (isClaimed) {
                return <>Claimed Order / Delivery</>;
            } else if (!isClaimed && order.oms) {
                return <>Internal Order / Delivery</>;
            } else
                return (
                    <>
                        <span color="#59b863">Sent to Onward </span>/ Delivery
                    </>
                );
        } else {
            return (
                <>
                    {isInternal ? (
                        'Internal'
                    ) : (
                        <span
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            Onward Order
                        </span>
                    )}

                    {order?.order_type && ` / ${startCase(order.order_type)}`}
                </>
            );
        }
    }, [order]);

    const duration = order?.duration_seconds && hourMinuteFormatter.format(order?.duration_seconds / 60);

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    const HalfGridItem = ({ children, props }) => {
        return (
            <Grid item style={{ maxWidth: '50%', textAlign: 'right' }} {...props}>
                {children}
            </Grid>
        );
    };

    return (
        <>
            <OnwardCard>
                <CardRow style={{ marginBottom: 0 }}>
                    <Grid item>
                        <CardTitle>
                            {order.pickup_city}, {order.pickup_state} - {order.dropoff_city}, {order.dropoff_state}
                        </CardTitle>
                    </Grid>
                </CardRow>
                <CardRow style={{ marginTop: 0, marginBottom: '1.75rem' }}>
                    <Grid item>
                        <CardSubtitle>{orderTypeText}</CardSubtitle>
                    </Grid>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>QBO Class</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <Select
                            disabled={!invoicingAccess}
                            value={orderClass || ''}
                            onChange={(event) => {
                                // handle change event here
                                setOrderClass(event.target.value);
                                handleSaveQBOClass(order.order_id, event.target.value);
                            }}
                        >
                            {qbo_classes.map((locationClass, index) => (
                                <MenuItem key={index} value={locationClass}>
                                    {locationClass}
                                </MenuItem>
                            ))}
                            {order.invoice_class && !qbo_classes.includes(order.invoice_class) && (
                                <MenuItem disabled value={order.invoice_class}>
                                    {order.invoice_class}
                                </MenuItem>
                            )}
                        </Select>
                    </HalfGridItem>
                </CardRow>

                <CardRow>
                    <Grid item>
                        <CardItem>Shipper</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.order_shipper?.business_name || 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>

                <CardRow>
                    <Grid item>
                        <CardItem>PO / Ref #</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.po_number || order?.reference_id
                                ? `${order?.po_number || '--'} / ${order?.reference_id || '--'}`
                                : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                <CardRow>
                    <Grid item>
                        <CardItem>Pick Up</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order.pickup_city}, {order.pickup_state}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                <CardRow>
                    <Grid item>
                        <CardItem>Drop off</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order.dropoff_city}, {order.dropoff_state}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                <CardRow>
                    <Grid item>
                        <CardItem>Customer</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.dropoff_name}</CardItemBold>
                    </HalfGridItem>
                </CardRow>

                <CardRow>
                    <Grid item>
                        <CardItem>Delivery Date</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold style={{ cursor: 'pointer' }}>
                            <u>{order.delivery_date_formatted || 'TBD'}</u>
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Distance</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.distance ? `${order.distance}` : 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Trip Time</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{duration || 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Driver</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {[
                                ...(pickupRoute ? [pickupRoute?.teammateByDriverId?.username || '-'] : []),
                                dropoffRoute?.teammateByDriverId?.username || '-',
                            ].join(' / ')}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Service Level</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{LOCATION_TYPES[order.dropoff_location_type]}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Equipment</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{Object.values(otherEquipment).join(', ')}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
            </OnwardCard>
        </>
    );
}
