export const ORDER_READONLY_FIELDS = [
    '__typename',
    'alternative_delivery_dates_formatted',
    'billing_completion_time',
    'billing_completion_date_formatted',
    'completion_date_formatted',
    'crossdock_leg',
    'delivery_date_formatted',
    'dropoff_point',
    'dropoff_route_id',
    'dropoff_route',
    'estimated_delivery_range_end_formatted',
    'estimated_delivery_range_start_formatted',
    'event_state',
    'exceptions',
    'first_available_date_formatted',
    'items',
    'listing',
    'messages',
    'order_carrier',
    'order_exception',
    'order_shipper',
    'pickup_date_formatted',
    'pickup_point',
    'pickup_route_id',
    'pickup_route',
    'preferred_delivery_date_formatted',
    'ratings',
    'returns',
    'route',
    'routes',
    'shipping_partner',
    'target_delivery_date_formatted',
    'warehouse_status',
    'wh_events',
];

export const ITEM_READONLY_FIELDS = [
    '__typename',
    'item_exception',
    'pallet',
    'exceptions',
    'manifests',
    'item_sku_status',
];
