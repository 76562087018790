import React, { useState, useEffect, useContext } from 'react';
import { compose } from 'recompose';
import { useSubscription } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { FTLBOARD_SUBSCRIPTION } from '@/graphql/subscriptions/routes';
import routeHelpers from '../../utilities/routeHelpers';
import dateFnsFormat from 'date-fns/format';
import withErrorBoundary from '../ErrorBoundary';
import { UserContext } from '../App';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import withAuthorization, { carrierAuthCondition } from '../Auth/withAuthorization';
import track from '../../utilities/track';
import { useClientUser } from '../../hooks';

const columns = [
    {
        label: 'Route #',
        value: (route) => route.route_number,
    },
    {
        label: 'Start',
        value: (route) => routeHelpers.getRouteStartCityState(route),
    },
    {
        label: 'End',
        value: (route) => routeHelpers.getRouteEndCityState(route),
    },
    {
        label: '# of Stops',
        value: (route) => routeHelpers.getNumberOfStops(route),
    },
    {
        label: '# of Drop-offs',
        value: (route) => routeHelpers.getNumberOfDropoffs(route),
    },
    {
        label: 'Cubic ft.',
        value: (route) => (route.total_cubes ? parseFloat(route.total_cubes).toFixed(2) : '--'),
    },
    {
        label: 'Price',
        value: (route) => (route.total_carrier_rate ? '$' + parseFloat(route.total_carrier_rate).toFixed(2) : '--'),
    },
    {
        label: 'Delivery Date',
        value: (route) => {
            if (route.scheduled_delivery) {
                let _date = new Date(route.scheduled_delivery);
                let _formattedDate = dateFnsFormat(_date, 'MM/dd/yyyy');
                return _formattedDate;
            }
            return '--';
        },
    },
];

function CarrierFTLBoard(props) {
    const user = useClientUser();
    const { sidebarCollapsed } = useContext(UserContext);
    const [approved, setApproved] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [isTestUser, setIsTestUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const testCondition = { route_shipper: { test_acc: { _eq: isTestUser } } };
    const statusCondition = { status: { _eq: 'open' } };
    useEffect(() => {
        setApproved(user.approved);
        setIsTestUser(user.testAccount);
    }, [user]);
    const {
        loading: loadingSub,
        error,
        data,
    } = useSubscription(FTLBOARD_SUBSCRIPTION, {
        variables: {
            testCondition: testCondition,
            statusCondition: statusCondition,
        },
        onData: ({ data }) => {
            if (data.data.routes) {
                const newRoutes = data.data.routes;
                setRoutes(newRoutes);
            }

            setLoading(false);
        },
        onError: (error) => {
            Sentry.captureException(error);
        },
    });

    const selectRoute = async (route) => {
        setSelectedRoute(route);
        await track('Route Viewed', {
            routeNumber: route.route_number,
            viewedBy: user?.originalAdminEmail || user?.email,
        });
    };

    return (
        <>
            {!approved && (
                <h3 style={{ alignContent: 'center' }} className="text-center text-danger">
                    Account Pending Approval
                </h3>
            )}

            <div className={`${sidebarCollapsed && 'collapse-margin'} ${approved ? '' : 'blurstuff'}`}>
                <Typography style={{ marginBottom: '10px' }} align="center" variant="h3">
                    FTL Board
                </Typography>
            </div>
            <Paper>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.label} align="center">
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            [...Array(5)].map((...[, i]) => (
                                <TableRow key={i}>
                                    <TableCell colSpan={columns.length + 1}>
                                        <Skeleton variant="text" />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : routes.length > 0 ? (
                            routes.map((route, i) => (
                                <TableRow key={route.route_number}>
                                    {columns.map((column) => (
                                        <TableCell key={column.label} align="center">
                                            {column.value(route)}
                                        </TableCell>
                                    ))}
                                    <TableCell style={{ width: '60px' }} align="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            style={{ color: '#eee' }}
                                            disabled={!approved}
                                            onClick={() => selectRoute(route)}
                                        >
                                            Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length + 1}>
                                    <Typography align="center" variant="h6">
                                        No Routes Found
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </>
    );
}

export default compose(withAuthorization(carrierAuthCondition), withErrorBoundary)(CarrierFTLBoard);
