import React, { useCallback, useMemo } from 'react';
import { useClientUser } from '@/hooks';
import { uniq, zipObject } from 'lodash';

export const useSubregionCallbacks = (state, callbacks) => {
    const user = useClientUser();

    const removeMapping = useCallback((mapping) => {
        return callbacks.deleteZipMappings({
            variables: {
                mappings: [mapping.mapping_id],
            },
        });
    }, []);

    const insertMappings = useCallback(({ subregion_id, zips }) => {
        return callbacks
            .insertZipMappings({
                variables: {
                    mappings: zips.map((zip) => ({ subregion_id, zip })),
                },
            })
            .then(() => {
                callbacks.selectObject((prev) => {
                    return {
                        ...prev,
                        zip: {},
                    };
                });
            });
    }, []);

    const removeMappings = useCallback((mappings) => {
        return callbacks
            .deleteZipMappings({
                variables: {
                    mappings,
                },
            })
            .then(() => {
                callbacks.selectObject((prev) => {
                    return {
                        ...prev,
                        zip: {},
                    };
                });
            });
    }, []);

    const removeCarrierMapping = useCallback((mapping) => {
        return callbacks.deleteCarrierMappings({
            variables: {
                mappings: [mapping.mapping_id],
            },
        });
    }, []);

    const insertCarrierMapping = useCallback((mapping) => {
        return callbacks.insertCarrierMappings({
            variables: {
                mappings: [mapping],
            },
        });
    }, []);

    const createSubregion = useCallback(
        (idx) => {
            return callbacks.addSubregion({
                optimisticResponse: {
                    created: {
                        __typename: 'subregions',
                        subregion_id: `temp-${idx}`,
                        name: '--',
                        zips: [],
                        carriers: [],
                        ...(user.isOnwardAdmin
                            ? {
                                  client_id: null,
                                  type: state.profile || 'DEFAULT',
                              }
                            : {
                                  client_id: user?.user_id,
                                  type: 'CLIENT',
                              }),
                    },
                },
            });
        },
        [user, state.profile]
    );

    const deleteSubregion = useCallback((subregion) => {
        return callbacks.removeSubregion({
            variables: {
                subregion_id: subregion.subregion_id,
            },
        });
    }, []);

    const updateSubregion = useCallback((subregion) => {
        return callbacks.updateSubregion({
            variables: {
                update: subregion,
                subregion_id: subregion.subregion_id,
            },
        });
    }, []);

    const toggleSubregion = useCallback((id) => {
        callbacks.selectObject((prev) => {
            return {
                ...prev,
                subregion: id === prev.subregion ? null : id,
            };
        });
    }, []);

    const toggleSubregionHover = useCallback((id) => {
        callbacks.hoverObject((prev) => {
            return {
                ...prev,
                subregion: id === prev.subregion ? null : id,
            };
        });
    }, []);

    const toggleZipHover = useCallback((id) => {
        callbacks.hoverObject((prev) => {
            return {
                ...prev,
                zip: id === prev.zip ? null : id,
            };
        });
    }, []);

    const toggleZips = useCallback((zips) => {
        callbacks.selectObject((prev) => {
            const updates = Object.fromEntries(zips.map((zip) => [zip, !prev.zip[zip]]));

            return {
                ...prev,
                zip: {
                    ...(prev.zip || {}),
                    ...updates,
                },
            };
        });
    }, []);

    const uploadMappings = useCallback(
        (headers, body = []) => {
            const mappings = body.reduce((acc, row) => {
                const parsedRow = zipObject(headers, row);
                if (Object.values(parsedRow).every((cell) => !cell)) {
                    return acc;
                }

                const { Subregion, Zip, Carrier, Warehouse, Direct } = parsedRow;

                const prevCarriers = acc[Subregion]?.carriers || [];
                const nextCarrier = {
                    carrier_id: Carrier,
                    warehouse_id: Warehouse,
                    is_direct: Direct && !['no', 'false'].includes(Direct.toString().toLowerCase()),
                };

                return {
                    ...acc,
                    [Subregion]: {
                        zips: uniq([...(acc[Subregion]?.zips || []), Zip]),
                        carriers: [
                            ...prevCarriers,
                            ...(prevCarriers.find((c) => c.carrier_id === Carrier) ? [] : [nextCarrier]),
                        ],
                    },
                };
            }, {});

            if (Object.values(mappings).some(({ zips }) => zips.some((zip) => zip.length !== 5))) {
                callbacks.setNotification({
                    severity: 'error',
                    message: 'CSV may only contain 5-digit zip codes',
                });
                return;
            }

            callbacks.insertSubregionsMany({
                variables: {
                    subregions: Object.entries(mappings).map(([name, { zips, carriers }]) => ({
                        name,
                        zips: {
                            data: zips.map((zip) => ({ zip })),
                        },
                        ...(user.isOnwardAdmin
                            ? {
                                  type: state.profile || 'DEFAULT',
                                  carriers: {
                                      data: carriers.map((carrier) => ({
                                          carrier_id: carrier.carrier_id,
                                          warehouse_id: carrier.warehouse_id || null,
                                          is_direct: !!carrier.is_direct,
                                      })),
                                  },
                              }
                            : {
                                  client_id: user.user_id,
                              }),
                    })),
                },
            });
        },
        [user, state.profile]
    );

    const selectProfile = useCallback((profile) => {
        callbacks.setProfile(profile);
        if (!!profile) {
            callbacks.querySubregions({
                variables: {
                    where: {
                        type: { _eq: profile },
                    },
                },
            });
        }
    }, []);

    const editProfile = useCallback((profile) => {
        callbacks.setProfile(profile);
        return callbacks.querySubregionsDebounced({
            variables: {
                where: {
                    type: { _eq: profile },
                },
            },
        });
    }, []);

    return {
        removeMapping,
        updateSubregion,
        insertMappings,
        removeMappings,
        toggleSubregion,
        toggleSubregionHover,
        createSubregion,
        deleteSubregion,
        toggleZips,
        toggleZipHover,
        uploadMappings,
        insertCarrierMapping,
        removeCarrierMapping,
        selectProfile,
        editProfile,
    };
};
