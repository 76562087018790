import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { asDateInTZ } from '@/utilities/convertToISO';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { formatInTimeZone } from 'date-fns-tz';
import { useClientUser } from '@/hooks';
import { Alert, AlertTitle } from '@material-ui/lab';

const EditManifest = ({ manifest, loading, callbacks }) => {
    const { locations: warehouses } = useClientUser();

    const [updates, setUpdates] = useState({});
    const update = (patch) => setUpdates((prev) => ({ ...prev, ...patch }));

    const updated = useMemo(() => {
        return {
            ...(manifest || {}),
            ...updates,
        };
    }, [manifest, updates]);

    const isValid = useMemo(() => {
        return ['receiving_date', 'warehouse_id'].every((attr) => updated[attr]);
    }, [updated]);

    const cancel = () => {
        setUpdates({});
        callbacks.editManifest(null);
    };

    const save = () => {
        callbacks
            .saveManifest({
                manifest_id: manifest.manifest_id,
                ...updates,
            })
            .then(() => {
                setUpdates({});
                callbacks.editManifest(null);
            });
    };

    return (
        <NavResponsiveModal open={!!manifest} onClose={cancel}>
            <ModalHeader title={'Edit Manifest'} subtitle={manifest?.manifest_number || '0000'} onClose={cancel} />
            <ModalContent
                css={css`
                    width: 600px;
                `}
            >
                <Grid container direction="column">
                    <TextField
                        variant="outlined"
                        label="Origin"
                        name="origin"
                        fullWidth
                        value={updated.origin || ''}
                        onChange={(e) => update({ origin: e.target.value })}
                        css={css`
                            margin-bottom: 1rem;
                        `}
                    />
                    <TextField
                        variant="outlined"
                        label="Destination"
                        name="origin"
                        fullWidth
                        value={updated.destination || ''}
                        onChange={(e) => update({ destination: e.target.value })}
                        css={css`
                            margin-bottom: 1rem;
                        `}
                    />
                    <TextField
                        type="date"
                        variant="outlined"
                        label="Receiving Date"
                        name="receiving_date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={
                            updated.receiving_date
                                ? formatInTimeZone(new Date(updated.receiving_date), 'UTC', 'yyyy-MM-dd')
                                : '--'
                        }
                        onChange={(e) =>
                            update({
                                receiving_date: e.target.value ? asDateInTZ(e.target.value, 'UTC').toISOString() : null,
                            })
                        }
                        css={css`
                            margin-bottom: 1rem;
                        `}
                        error={!updated.receiving_date}
                    />
                    {warehouses.length > 0 ? (
                        <TextField
                            variant="outlined"
                            required
                            select
                            label="Warehouse"
                            name="warehouse_id"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={updated.warehouse_id || ''}
                            onChange={(e) => update({ warehouse_id: e.target.value })}
                            error={!updated.warehouse_id}
                        >
                            {warehouses.map((warehouse) => (
                                <MenuItem key={warehouse.location_id} value={warehouse.location_id}>
                                    {warehouse.location_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        <Alert severity="warning">
                            <AlertTitle>No Warehouse(s)</AlertTitle>Please add a new warehouse to get started
                        </Alert>
                    )}
                </Grid>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading.updateLoading} onClick={cancel}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading.updateLoading || !isValid} onClick={save}>
                    Save
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default EditManifest;
