import React, { useEffect, useState } from 'react';
import { Row, Container, Card, Form, Col } from 'react-bootstrap';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import CardSetupForm from '@/components/Stripe/CardSetupForm';
import { UPDATE_CLIENT_WITH_STRIPE_ID } from '../mutations';
import { useMutation } from '@apollo/client';
import { useClientUser } from '@/hooks';
import { css } from '@emotion/react';
import Snackbar from '../../Snackbar';
import { post } from '@/utilities/onwardClient';
import { STRIPE_CREATE_CUSTOMER, STRIPE_GET_CARD_DETAILS, REMOVE_CARD } from '@/constants/apiRoutes';
import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox, Button } from '@material-ui/core';
import { UPDATE_CLIENT_BY_PK } from '@/graphql/mutations/clients';

const getStripeKey = () =>
    process.env.REACT_APP_ENVIRONMENT === 'production'
        ? 'pk_live_GkLHbJguG8egPK2kovoxdBCa006YTIcMMU'
        : 'pk_test_HrMrx9FzlVtiZdchU0ptwIK100cCrxV6l1';

function AccountCardSetup(props) {
    const { currentClient } = props;
    const [stripePromise] = useState(() => loadStripe(getStripeKey()));
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { user_id } = useClientUser();
    const [cards, setCards] = useState(null);

    const [updateStripeID] = useMutation(UPDATE_CLIENT_WITH_STRIPE_ID, {
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
        },
    });

    const [updateStripePM] = useMutation(UPDATE_CLIENT_BY_PK, {
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
        },
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    useEffect(() => {
        getCardDetails();
    }, [currentClient]);

    const createStripeCustomer = async (user = {}) => {
        const isValidUser = !!Object.keys(user).length;

        if (!isValidUser) {
            console.warn('Invalid User');
            return;
        }

        const { data, status } = await post(STRIPE_CREATE_CUSTOMER, {
            name: user.username,
            email: user.email,
            phone: user.business_phone,
        });

        if (status !== 200) return;

        const stripe_user_id = data?.customer?.id;

        await updateStripeID({
            variables: {
                client_id: user_id,
                stripe_user_id,
            },
        });
    };

    const getCardDetails = async () => {
        const { stripe_user_id } = currentClient;

        const shipperIsLoaded = !!Object.keys(currentClient).length;

        if (shipperIsLoaded && !stripe_user_id) {
            await createStripeCustomer(currentClient);
            return;
        }

        if (!stripe_user_id) return;

        try {
            const { status, data } = await post(STRIPE_GET_CARD_DETAILS, { stripe_user_id });
            setCards(data?.cardinfo);

            if (!currentClient.stripe_payment_method && data.cardinfo[0]) {
                await updateStripePM({
                    variables: {
                        client_id: user_id,
                        update: {
                            stripe_payment_method: data.cardinfo[0].id,
                        },
                    },
                });
            }
        } catch (err) {
            Sentry.captureException(err);
            console.log(err);
        }
    };

    const successfullySavedCard = () => {
        setSuccessMessage('Added / Updated card successfully!');
        getCardDetails();
    };

    const handleRemoveCard = async (removedCard) => {
        if (removedCard.id === currentClient.stripe_payment_method) {
            let index;
            cards.forEach((card, i) => {
                if (card.id === removedCard.id) {
                    index = i;
                }
            });
            const cardsAfterRemoval = cards.toSpliced(index, 1);

            await updateStripePM({
                variables: {
                    client_id: user_id,
                    update: {
                        stripe_payment_method: cardsAfterRemoval[0].id,
                    },
                },
            });
        }

        const { data, status } = await post(REMOVE_CARD, {
            pm_id: removedCard.id,
        });

        getCardDetails();
    };

    const columns = [
        { label: 'Type', value: (card) => card.card.brand.toUpperCase() },
        { label: 'Credit Card (last 4)', value: (card) => card.card.last4 },
        { label: 'EXP. Date:', value: (card) => `${card.card.exp_month} / ${card.card.exp_year}` },
    ];

    const GreenCheckbox = withStyles({
        root: {
            color: '#71b77a',
            '&$checked': {
                color: '#71b77a',
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <>
            <Card
                className="tabCard"
                css={css`
                    margin-top: 2rem;
                `}
            >
                <Card.Body className="">
                    <Container fluid>
                        {cards ? (
                            <>
                                <Form.Label style={{ marginTop: '.5rem' }}>Current Cards on File:</Form.Label>
                                <Table className="mb-5" size="small">
                                    <TableHead>
                                        <TableRow>
                                            {cards.length > 1 && (
                                                <TableCell key={'active'} align="center">
                                                    Active
                                                </TableCell>
                                            )}
                                            {columns.map((column) => (
                                                <TableCell key={column.label} align="center">
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {cards.map((card) => (
                                            <TableRow key={card.last4}>
                                                {cards.length > 1 && (
                                                    <TableCell align="center" key={'active'}>
                                                        <GreenCheckbox
                                                            color="green"
                                                            checked={currentClient.stripe_payment_method === card.id}
                                                            onClick={() =>
                                                                updateStripePM({
                                                                    variables: {
                                                                        client_id: user_id,
                                                                        update: {
                                                                            stripe_payment_method: card.id,
                                                                        },
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </TableCell>
                                                )}
                                                {columns.map((column, i) => (
                                                    <TableCell align="center" key={i}>
                                                        {column.value(card)}
                                                    </TableCell>
                                                ))}
                                                {cards.length > 1 && (
                                                    <TableCell align="center">
                                                        <Button
                                                            variant="contained"
                                                            style={{ color: 'white' }}
                                                            color="primary"
                                                            onClick={() => handleRemoveCard(card)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>
                        ) : (
                            <Row className="mt-2 mb-1">
                                <Col>
                                    <h6>No card currently on file.</h6>
                                </Col>
                            </Row>
                        )}
                        <ThemeProvider theme={theme}>
                            <Row className="d-flex my-4">
                                <Elements
                                    stripe={stripePromise}
                                    id={currentClient.stripe_user_id}
                                    curr_user={user_id}
                                    email={currentClient.email}
                                >
                                    <CardSetupForm user={currentClient} onSuccess={successfullySavedCard} />
                                </Elements>
                            </Row>
                        </ThemeProvider>
                    </Container>
                </Card.Body>
            </Card>

            <Snackbar
                open={!!successMessage}
                handleClose={() => setSuccessMessage('')}
                severity="success"
                message={successMessage}
            />

            <Snackbar
                open={!!errorMessage}
                handleClose={() => setErrorMessage('')}
                severity="error"
                message={errorMessage}
            />
        </>
    );
}

export default AccountCardSetup;
