import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';
import * as ROUTES from '@/constants/routes';
import { colors } from '@/styles';

import { Context } from './store';
import { local, Header2, Body1, Card, Step, HR } from './blocks';
import CarrierNumber from './stages/CarrierNumber';
import CarrierContact from './stages/CarrierContact';
import CarrierLogin from './stages/CarrierLogin';

function ClientForm() {
    const { callbacks, state, errors } = useContext(Context);

    let stage = null;
    switch (state.progression.current) {
        case 0: {
            stage = <CarrierNumber callbacks={callbacks} state={state} />;
            break;
        }
        case 1: {
            stage = <CarrierContact callbacks={callbacks} state={state} />;
            break;
        }
        case 2: {
            stage = <CarrierLogin callbacks={callbacks} state={state} />;
            break;
        }
    }

    return (
        <Card>
            <Grid container direction="column">
                <Grid container>
                    {state.progression.stages.map((stage, index) => {
                        return (
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    position: relative;
                                    align-items: center;
                                    margin-bottom: 24px;
                                `}
                            >
                                <Step key={stage} isSelected={state.progression.current === index} current={stage} />
                                {index < state.progression.stages.length - 1 ? <HR /> : null}
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid
                    container
                    css={css`
                        color: ${local.black};
                        margin-bottom: 20px;
                    `}
                >
                    <Header2>Create your Onward Carrier Account</Header2>
                </Grid>

                {stage}

                <Grid
                    container
                    css={css`
                        color: ${colors.greys[2]};
                        margin-top: 30px;
                        margin-bottom: 20px;
                        justify-content: center;
                    `}
                >
                    <Body1>
                        Already have an account?&nbsp;
                        <Link
                            to={ROUTES.SIGNIN}
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            Sign in
                        </Link>
                    </Body1>
                </Grid>
            </Grid>
        </Card>
    );
}

export default ClientForm;
