import React, { useContext, useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { cloneDeep } from 'lodash';
import { UPDATE_ROUTE_STOPS } from '../graphql/mutations';
import { useMutation } from '@apollo/client';
import { toArrayLiteral } from '@/utilities/toArrayLiteral';
import { addRefs, removeRefs } from '@/graphql/util';

const lunchDurations = [
    {
        text: '10 min',
        value: 10,
    },
    {
        text: '15 min',
        value: 15,
    },
    {
        text: '30 min',
        value: 30,
    },
    {
        text: '45 min',
        value: 45,
    },
    {
        text: '60 min',
        value: 60,
    },
    {
        text: '75 min',
        value: 75,
    },
    {
        text: '90 min',
        value: 90,
    },
    {
        text: '105 min',
        value: 105,
    },
    {
        text: '120 min',
        value: 120,
    },
];

const DriverLunchModal = () => {
    const {
        modalOpen,
        setModalOpen,
        selectedRoute,
        setError,
        state: { routes },
    } = useContext(PlanningContext);

    const [lunchDuration, setLunchDuration] = useState(null);
    const [lunchStop, setLunchStop] = useState(null);
    const [previousLunchStop, setPreviousLunchStop] = useState(null);

    const [updateStops, { loading: stopsLoading }] = useMutation(UPDATE_ROUTE_STOPS, {
        update: (cache, { data }) => {
            const newStops = data?.insert_stops?.returning || [];
            const removedStops = data?.delete_stops?.returning || [];

            cache.modify({
                id: cache.identify(route),
                fields: {
                    stopsByRouteId: (prev, { toReference }) => {
                        const withNew = addRefs(prev, newStops, { toReference });
                        return removeRefs(withNew, removedStops, { toReference });
                    },
                },
            });

            removedStops.forEach((stop) => cache.evict(cache.identify(stop)));
        },
    });

    const handleModalClose = () => {
        setModalOpen(null);
    };

    const route = useMemo(() => {
        return routes?.find((route) => route.route_id === selectedRoute);
    }, [routes, selectedRoute]);

    const stops = FTLStopHelpers.getStopSequence(route?.stopsByRouteId).filter((stop) => !stop.start && !stop.end);

    useEffect(() => {
        if (!route) return;
        const stops = FTLStopHelpers.getStopSequence(route?.stopsByRouteId).filter((stop) => !stop.start && !stop.end);
        if (!stops) return;
        const previousLunchStop = stops.find((s) => s.type === 'LUNCH');
        if (!previousLunchStop) {
            setPreviousLunchStop(null);
            setLunchDuration(null);
            setLunchStop(null);
            return;
        }

        setLunchDuration(previousLunchStop.service_time / 60000);
        setLunchStop(previousLunchStop.ordering - 1);
        setPreviousLunchStop(previousLunchStop);
    }, [route, routes]);

    const createLunchStop = () => {
        const clonedStops = cloneDeep(route.stopsByRouteId);
        const stopUpdates = clonedStops.filter((stop) => stop.ordering > lunchStop);
        const customerStops = stopUpdates.filter((stop) => FTLStopHelpers.isCustomerStop(stop, route));

        const newStop = {
            route_id: route.route_id,
            ordering: lunchStop + 1,
            type: 'LUNCH',
            start: false,
            end: false,
            service_time: lunchDuration * 60000,
        };

        const ordersThatNeedRecalculating = customerStops.reduce((acc, stop) => {
            return [...acc, ...(stop?.orders || [])];
        }, []);

        updateStops({
            variables: {
                route_id: route.route_id,
                route_update: { need_to_optimize: true },
                order_updates: [
                    {
                        where: { order_id: { _in: ordersThatNeedRecalculating } },
                        _set: {
                            delivery_time_confirmed: 'recalculate',
                        },
                    },
                ],
                updates: stopUpdates.map((updatedStop) => ({
                    where: { stop_id: { _eq: updatedStop.stop_id } },
                    _set: {
                        ordering: updatedStop.ordering + 1,
                        orders: updatedStop.orders?.length ? toArrayLiteral(updatedStop.orders) : null,
                        returns: updatedStop.returns?.length ? toArrayLiteral(updatedStop.returns) : null,
                        exchanges: updatedStop.exchanges?.length ? toArrayLiteral(updatedStop.exchanges) : null,
                        ...Object.fromEntries(
                            FTLStopHelpers.stopFieldsToUpdate.map((key) => [key, updatedStop[key] || null])
                        ),
                    },
                })),
                inserts: newStop,
            },
            onError: (error) => {
                setError(error, 'Error adding lunch stop');
            },
        });
    };

    const updateLunchStop = () => {
        const newStops = cloneDeep(route.stopsByRouteId);

        const from = previousLunchStop.ordering;
        const to = lunchStop < from ? lunchStop + 1 : lunchStop;

        let stopsToUpdate = [];
        if (to < from) {
            newStops.forEach((stop) => {
                if (stop.ordering >= to && stop.ordering < from) {
                    stopsToUpdate.push({
                        ...stop,
                        ordering: stop.ordering + 1,
                    });
                }
            });
        }
        if (to > from) {
            newStops.forEach((stop) => {
                if (stop.ordering > from && stop.ordering <= to) {
                    stopsToUpdate.push({
                        ...stop,
                        ordering: stop.ordering - 1,
                    });
                }
            });
        }

        stopsToUpdate.push({
            ...previousLunchStop,
            ordering: to,
            service_time: lunchDuration * 60000,
        });

        const customerStops = stopsToUpdate.filter((stop) => FTLStopHelpers.isCustomerStop(stop, route));

        const ordersThatNeedRecalculating = customerStops.reduce((acc, stop) => {
            return [...acc, ...(stop?.orders || [])];
        }, []);

        updateStops({
            variables: {
                route_id: route.route_id,
                route_update: { need_to_optimize: true },
                order_updates: [
                    {
                        where: { order_id: { _in: ordersThatNeedRecalculating } },
                        _set: {
                            delivery_time_confirmed: 'recalculate',
                        },
                    },
                ],
                updates: stopsToUpdate.map((updatedStop) => ({
                    where: { stop_id: { _eq: updatedStop.stop_id } },
                    _set: {
                        ordering: updatedStop.ordering,
                        orders: updatedStop.orders?.length ? toArrayLiteral(updatedStop.orders) : null,
                        returns: updatedStop.returns?.length ? toArrayLiteral(updatedStop.returns) : null,
                        exchanges: updatedStop.exchanges?.length ? toArrayLiteral(updatedStop.exchanges) : null,
                        ...Object.fromEntries(
                            FTLStopHelpers.stopFieldsToUpdate.map((key) => [key, updatedStop[key] || null])
                        ),
                    },
                })),
            },
            onError: (error) => {
                console.log(error);
                setError(error, 'Error adding lunch stop');
            },
        });
    };

    const handleModalSave = () => {
        if (previousLunchStop) {
            updateLunchStop();
        } else {
            createLunchStop();
        }
        setLunchDuration(null);
        setLunchStop(null);
        setModalOpen(null);
    };

    const isNormalPickup = (stop) => {
        return stop.type === 'PICKUP' && !stop.returns?.length;
    };
    const isFinalDropoff = (stop) => {
        return stop.type === 'DROPOFF' && stop.returns?.length;
    };

    const prevStopNumber = previousLunchStop ? previousLunchStop.ordering : Infinity;

    return (
        <NavResponsiveModal open={modalOpen === MODALS.DRIVER_LUNCH} onClose={handleModalClose}>
            <ModalTitle
                css={css`
                    border-bottom: none;
                    font-weight: 900;
                    font-size: 20px;
                    width: 350px;
                `}
            >
                Driver Lunch
            </ModalTitle>
            <ModalContent
                css={css`
                    font-size: 16px;
                `}
            >
                <GridItemRow>
                    <span
                        css={css`
                            font-weight: 700;
                        `}
                    >
                        Lunch Duration
                    </span>
                </GridItemRow>
                <FormControl fullWidth variant="outlined">
                    <Select
                        value={lunchDuration || 'NONE'}
                        onChange={(e) => setLunchDuration(e.target.value)}
                        css={css`
                            & .MuiSelect-root {
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }
                        `}
                    >
                        <MenuItem value="NONE" key="NONE">
                            Select Duration...
                        </MenuItem>
                        {lunchDurations.map((i) => (
                            <MenuItem value={i.value} key={`lunch-duration-menu-item-${i.value}`}>
                                {i.text}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <GridItemRow>
                    <span
                        css={css`
                            font-weight: 700;
                            margin-top: 40px;
                        `}
                    >
                        After
                    </span>
                </GridItemRow>
                <FormControl fullWidth variant="outlined">
                    <Select
                        value={lunchStop === null ? 'NONE' : lunchStop}
                        onChange={(e) => setLunchStop(e.target.value)}
                        css={css`
                            & .MuiSelect-root {
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }
                        `}
                    >
                        <MenuItem value="NONE" key="NONE">
                            Select Stop...
                        </MenuItem>
                        {stops
                            .filter((stop) => stop.type !== 'LUNCH')
                            .map((stop) => (
                                <MenuItem value={stop.ordering} key={`lunch-stop-menu-item-${stop.ordering}`}>
                                    {`${
                                        isNormalPickup(stop)
                                            ? 'Pickup'
                                            : isFinalDropoff(stop)
                                            ? 'Final Return'
                                            : `Stop ${
                                                  stop.ordering > prevStopNumber ? stop.ordering - 1 : stop.ordering
                                              }`
                                    }`}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </ModalContent>
            <ModalActions>
                <SecondaryButton
                    css={css`
                        justify-content: center;
                        width: 128px;
                    `}
                    onClick={handleModalClose}
                >
                    Cancel
                </SecondaryButton>
                <PrimaryButton
                    css={css`
                        justify-content: center;
                        width: 128px;
                    `}
                    disabled={
                        !lunchDuration ||
                        lunchStop === null ||
                        lunchDuration === 'NONE' ||
                        lunchStop === 'NONE' ||
                        (lunchStop === previousLunchStop?.ordering - 1 &&
                            lunchDuration === previousLunchStop.service_time / 60000)
                    }
                    onClick={handleModalSave}
                >
                    Save
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default DriverLunchModal;
