const startFormat = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
});

const endFormat = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

export const getDeliveryWindowFormatted = (start, end) => {
    return `${startFormat.format(start)} - ${endFormat.format(end)}`;
};

export const getDeliveryEndFormatted = (end) => {
    return endFormat.format(end);
};
