import { gql } from '@apollo/client';
import { PRICING_OVERRIDE_FIELDS } from '@/graphql/fragments/pricing_overrides';
import { PRICING_TARIFF_FIELDS } from '../fragments/pricing_tariffs';
import { RATE_FIELDS } from '../fragments/rates';

export const GET_PRICING_OVERRIDES = gql`
    ${PRICING_OVERRIDE_FIELDS}
    ${PRICING_TARIFF_FIELDS}
    ${RATE_FIELDS}

    query GetPricingOverrides(
        $shipper_ids: [uuid!]!
        $carrier_ids: [uuid!]!
        $client_ids: [uuid!]!
        $partner_client_ids: [uuid!]!
        $retailer_ids: [uuid!]!
    ) {
        shipper: pricing_overrides(
            where: {
                _and: [
                    { oms: { _eq: false } }
                    { client_id: { _in: $shipper_ids } }
                    { _or: [{ partner_client_id: { _is_null: true } }, { partner_client_id: { _in: $carrier_ids } }] }
                    { _or: [{ retailer_id: { _is_null: true } }, { retailer_id: { _in: $retailer_ids } }] }
                ]
            }
            order_by: [{ partner_client_id: desc_nulls_last }, { retailer_id: desc_nulls_last }]
        ) {
            ...PricingOverrideFields
            tariff {
                ...PricingTariffFields
                rates {
                    ...RateFields
                }
            }
        }

        internal: pricing_overrides(
            where: {
                _and: [
                    { oms: { _eq: true } }
                    { client_id: { _in: $client_ids } }
                    {
                        _or: [
                            { partner_client_id: { _is_null: true } }
                            { partner_client_id: { _in: $partner_client_ids } }
                        ]
                    }
                    { _or: [{ retailer_id: { _is_null: true } }, { retailer_id: { _in: $retailer_ids } }] }
                ]
            }
            order_by: [{ partner_client_id: desc_nulls_last }, { retailer_id: desc_nulls_last }]
        ) {
            ...PricingOverrideFields
            tariff {
                ...PricingTariffFields
                rates {
                    ...RateFields
                }
            }
        }

        carrier: pricing_overrides(
            where: {
                _and: [
                    { oms: { _eq: false } }
                    { client_id: { _in: $carrier_ids } }
                    { _or: [{ partner_client_id: { _is_null: true } }, { partner_client_id: { _in: $shipper_ids } }] }
                    { _or: [{ retailer_id: { _is_null: true } }, { retailer_id: { _in: $retailer_ids } }] }
                ]
            }
            order_by: [{ partner_client_id: desc_nulls_last }, { retailer_id: desc_nulls_last }]
        ) {
            ...PricingOverrideFields
            tariff {
                ...PricingTariffFields
                rates {
                    ...RateFields
                }
            }
        }

        carrier_defaults: pricing_overrides(where: { algo_type: { _eq: "CARRIER_GLOBAL_DEFAULT" } }) {
            ...PricingOverrideFields
            tariff {
                ...PricingTariffFields
                rates {
                    ...RateFields
                }
            }
        }

        shipper_defaults: pricing_overrides(where: { algo_type: { _eq: "SHIPPER_GLOBAL_DEFAULT" } }) {
            ...PricingOverrideFields
            tariff {
                ...PricingTariffFields
                rates {
                    ...RateFields
                }
            }
        }
    }
`;

export const GET_PRICING_OVERRIDES_BY_CLIENT_ID = gql`
    ${PRICING_OVERRIDE_FIELDS}

    query GetPricingOverrides($client_id: uuid!) {
        mktplace: pricing_overrides(
            where: { _and: [{ oms: { _eq: false } }, { client_id: { _eq: $client_id } }] }
            order_by: { partner_client_id: desc }
        ) {
            ...PricingOverrideFields
        }

        internal: pricing_overrides(
            where: { _and: [{ oms: { _eq: true } }, { client_id: { _eq: $client_id } }] }
            order_by: { partner_client_id: desc }
        ) {
            ...PricingOverrideFields
        }
    }
`;
