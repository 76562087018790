import { useNavigate } from 'react-router';
import Notification from '..';
import { NotificationMetadata, NotificationTimestamp, NotificationTitle } from '../blocks';
import { format } from 'date-fns';

const ListingClaimedNotification = ({ notification, handleCloseNotification }) => {
    const navigate = useNavigate();

    return (
        <Notification
            notification={notification}
            onClickHandler={() => {
                navigate('/auction/listings');
                handleCloseNotification();
            }}
        >
            <NotificationTitle>Auction Claimed</NotificationTitle>
            <NotificationMetadata>An order you have put up for auction has been claimed</NotificationMetadata>
            <NotificationMetadata>Order: {notification.notification_data?.order_number || 'N/A'}</NotificationMetadata>
            <NotificationTimestamp>
                {format(new Date(notification.created_at), 'MMM dd, yyyy @ hh:mm a')}
            </NotificationTimestamp>
        </Notification>
    );
};

export default ListingClaimedNotification;
