import api from '@/utilities/api';

export const submitSurvey = (data, header) => {
    return api
        .post(
            {
                routeName: 'submitSurvey',
                data,
            },
            header
        )
        .then((resp) => {
            if (!resp.errors) {
                return resp;
            }

            return Promise.reject(resp.errors);
        });
};
