export const routeTimeZoneFormatter = (timeZone) =>
    new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone,
        timeZoneName: 'short',
    });

export const localFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});
