import { gql } from '@apollo/client';

export const CORE_CLIENT_ACCOUNT_FIELDS = gql`
    fragment CoreClientAccountFields on clients {
        approved
        billing_address
        billing_address_unit
        billing_city
        billing_email
        billing_state
        billing_zip
        business_address
        business_address_unit
        business_city
        business_ein
        business_name
        business_phone
        business_state
        business_zip
        client_id
        deactivated
        data_share
        email
        personal_email
        personal_phone
        secondary_email
        secondary_phone
        secondary_username
        support_email
        support_phone
        teammates
        test_acc
        username
        xmas
        xmas_eve
        new_years_eve
        new_years_day
        labor_day
        memorial_day
        thanksgiving
        july_fourth
        cc_valid
        stripe_payment_method
        stripe_user_id
    }
`;
