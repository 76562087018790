import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Container, Row, Col, Card } from 'reactstrap';
import * as ROUTES from '../../constants/routes';
import * as Sentry from '@sentry/react';
import FooterComponent from '../Footer';
import { confirmPasswordReset, getAuth, verifyPasswordResetCode } from 'firebase/auth';
import { captureException } from '@sentry/react';
import { Alert, AlertTitle } from '@material-ui/lab';

function AuthResetPassword(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [actionCode, setActionCode] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (!params.get('oobCode')) {
            goHome();
            return;
        }

        const mode = params.get('mode');
        const actionCode = params.get('oobCode');
        if (mode !== 'resetPassword') {
            goHome();
        }

        verifyPasswordResetCode(getAuth(), actionCode)
            .then((email) => {
                console.log(`Email sent to ${email}`);
                setLoading(false);
                setActionCode(actionCode);
            })
            .catch((res) => {
                console.error(res);
                goHome();
            });
    }, []);

    const goHome = () => navigate('/');

    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (password !== passwordConfirmation) {
            setErrors(['Password and confirmation must match']);
            setLoading(false);
            return;
        }

        return confirmPasswordReset(getAuth(), actionCode, password)
            .then(() => {
                console.log('Password Reset!');
                setSuccess(true);
            })
            .catch((err) => {
                console.error(err);
                captureException(err);
                setErrors([err.message]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Container className="mt-5">
                <Row>
                    <Col className="d-flex justify-content-center mt-4">
                        <Card className="shadow border-0 signupform mt-5">
                            <Form style={{ margin: '5rem' }}>
                                <Container className="d-flex justify-content-center mb-3">
                                    <h4>Password Reset Form</h4>
                                </Container>

                                {success && (
                                    <Alert severity="success" style={{ marginBottom: '10px' }}>
                                        <AlertTitle>Success</AlertTitle>
                                        Password has been reset - click <a href="/signin">here</a> to sign in again
                                    </Alert>
                                )}

                                <Form.Control
                                    name="Password"
                                    type="password"
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    style={{ marginBottom: '10px' }}
                                />

                                <Form.Control
                                    name="Confirmation"
                                    type="password"
                                    placeholder="Password Confirmation"
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    value={passwordConfirmation}
                                    style={{ marginBottom: '10px' }}
                                />

                                <Container style={{ marginBottom: '10px' }} className="d-flex justify-content-center">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={onSubmit}
                                        disabled={loading || !password}
                                    >
                                        Reset Password
                                    </Button>
                                </Container>

                                {!!errors.length && errors.map((error) => <p style={{ color: 'red' }}>{error}</p>)}
                                <Container
                                    style={{ textAlign: 'center' }}
                                    className="d-flex flex-column justify-content-center"
                                >
                                    <Link to={ROUTES.SIGNIN}>
                                        <b>Back to sign in</b>
                                    </Link>
                                </Container>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <FooterComponent />
        </>
    );
}

export default AuthResetPassword;
