export default async function (message = '', data = {}) {
    if (process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.REACT_APP_ENVIRONMENT === 'staging') return;

    // If data object already has a test_acc status on it, use this. Otherwise we will default to
    // the status of the current user (below).
    if (data.hasOwnProperty('test_acc')) {
        await window.analytics.track(message, data);
        return;
    }

    const isTestAccount = localStorage.getItem('test_acc');
    if (isTestAccount === 'true') {
        data.test_acc = true;
    } else {
        data.test_acc = false;
    }
    await window.analytics.track(message, data);
}
