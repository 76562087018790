import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Row = styled.div`
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`;

export default function BodyRow({ row, children, callbacks, ...props }) {
    return (
        <Row
            {...props}
            onClick={() => {
                callbacks.onClick(row.original);
            }}
            css={css`
                width: auto !important;
            `}
        >
            {children}
        </Row>
    );
}
