import { gql } from '@apollo/client';

const COMMUNICATIONS_SUBSCRIPTION = gql`
    subscription communications {
        communications(order_by: { display: asc }) {
            communication_id
            description
            display
            enabled
            name
            override {
                enabled
                override_id
            }
        }
    }
`;

export { COMMUNICATIONS_SUBSCRIPTION };
