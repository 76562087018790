import * as React from 'react';
import { asDateInTZ } from '@/utilities/convertToISO';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { Typography, Grid, Stepper, Step, StepLabel } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { OnwardCard } from './Card';
import { isAfter } from 'date-fns';
import { css } from '@emotion/react';

const QontoStepIconRoot = styled('div')(({ theme, owner_state }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(owner_state.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 16,
        height: 16,
        borderRadius: '50%',
        backgroundColor: '#555',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot owner_state={{ active }} className={className}>
            {completed ? (
                <CheckCircleIcon style={{ color: '#59B863' }} className="float-right" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

function configureSteps(order, route) {
    let progress = 1;
    const info = {
        date: '',
        time: '',
    };
    const timeline = {
        Created: { ...info },
        Assigned: { ...info },
        'Picked up': { ...info },
        'Delivery Enroute': { ...info },
        Delivered: { ...info },
    };

    const timezone = order.dropoff_zip ? zipcode_to_timezone.lookup(order.dropoff_zip) : 'America/New_York';
    let newDate = new Date(order.created_at);
    //Can fix and use this later
    const createdDate = asDateInTZ(order?.created_at, timezone);

    timeline['Created'] = {
        date: newDate.toDateString(),
        time: newDate.toLocaleTimeString(),
    };

    // there might be the code about 'assigned' here.
    if (order.carrier_id) {
        progress = 2;
    }

    if (route?.stopsByRouteId) {
        const pickingUpStops = route.stopsByRouteId.filter((s) => s.type === 'PICKUP');
        const droppingOffStops = route.stopsByRouteId.filter((s) => s.type === 'DROPOFF');

        for (const d of pickingUpStops) {
            const { stop_completion_time } = d;
            if (stop_completion_time && d.orders && d.orders.includes(order.order_id)) {
                timeline['Picked up'] = {
                    date: new Date(stop_completion_time).toDateString(),
                    time: new Date(stop_completion_time).toLocaleTimeString(),
                };
                progress = 3;
                break;
            }
        }

        // there might be the code about 'Drop-off Enroute' here.
        let prevDropOffComplete = false;
        for (const d of droppingOffStops) {
            const { stop_completion_time } = d;
            if (d.orders && d.orders.includes(order.order_id)) {
                if (stop_completion_time) {
                    timeline['Delivered'] = {
                        date: new Date(stop_completion_time).toDateString(),
                        time: new Date(stop_completion_time).toLocaleTimeString(),
                    };
                    progress = 5;
                } else if (prevDropOffComplete) {
                    progress = 4;
                }
                break;
            }
            prevDropOffComplete = !!stop_completion_time;
        }
    }

    if (!route) {
        if (order.ltl_pickup_completed) {
            timeline['Picked up'] = {
                date: new Date(order.ltl_pickup_completed).toDateString(),
                time: new Date(order.ltl_pickup_completed).toLocaleTimeString(),
            };
            progress = 3;
        }

        if (
            order.ltl_pickup_completed &&
            !order.ltl_dropoff_arrival &&
            isAfter(new Date(), new Date(order.delivery_date))
        ) {
            progress = 4;
        }

        if (order.completion_time) {
            timeline['Delivered'] = {
                date: new Date(order.completion_time).toDateString(),
                time: new Date(order.completion_time).toLocaleTimeString(),
            };
        }
    }

    // for FTL complete routes or LTL orders that are complete: automatically fill the stepper
    if (['complete', 'routedComplete'].includes(order.order_status) && (!route || route.status === 'complete')) {
        progress = 5;
    }

    return { progress, timeline };
}

export default function OrderProgress(props) {
    const { order } = props;

    const route = (order.routes || []).find((mapping) => ['FULL', 'DROPOFF'].includes(mapping.type))?.route;

    const { progress, timeline } = configureSteps(order, route);

    return (
        <OnwardCard
            css={css`
                margin-bottom: 2rem;
            `}
        >
            <Stepper alternativeLabel activeStep={progress}>
                {Object.keys(timeline).map((key, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                            <div className="text-center">
                                <Typography variant="body2">
                                    <b>{key}</b>
                                </Typography>
                                <Typography variant="body2">
                                    <span>{timeline[key].date !== 'Invalid Date' ? timeline[key].date : null}</span>
                                </Typography>
                                <Typography variant="body2">
                                    <span>{timeline[key].time !== 'Invalid Date' ? timeline[key].time : null}</span>
                                </Typography>
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </OnwardCard>
    );
}
