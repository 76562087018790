import { gql } from '@apollo/client';

export const ACTIVITY_STOP_FIELDS = gql`
    fragment ActivityStopFields on activity_stops {
        stop_id
        route_id
        ordering
        cubes
        weight
        type
        timeframe_start
        timeframe_end
        arrival_time
        service_time
        lat
        lng
        point
        full_address
        address
        unit
        city
        state
        zip
        location
        location_info
        location_type
        created_at
        last_modified_at
    }
`;
