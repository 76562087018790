import { useMemo } from 'react';

export const useRouteRevenue = (route) => {
    return useMemo(() => {
        if (!Array.isArray(route?.orders) || route.orders.length === 0) return '0.00';
        const totalRevenue = route.orders.reduce((acc, orderMapping) => {
            const showRevOnDropoff = orderMapping.order.show_revenue_on === 'DROPOFF'; //default
            const showRevOnPickup = orderMapping.order.show_revenue_on === 'PICKUP';
            const isReturnOrder = orderMapping.order.order_type === 'return';
            const isFullRoute = orderMapping.type === 'FULL';
            const isPickupRoute = orderMapping.type === 'PICKUP';
            const isDropoffRoute = orderMapping.type === 'DROPOFF';

            const latestException = orderMapping?.order?.exceptions
                .filter(
                    (exception) =>
                        exception.exception?.route_rev_action !== null &&
                        exception.exception?.route_id === route.route_id
                )
                .sort((a, b) => new Date(b.exception.last_modified_at) - new Date(a.exception.last_modified_at))[0];

            if (
                (showRevOnDropoff &&
                    ((isPickupRoute && isReturnOrder) || (isDropoffRoute && !isReturnOrder) || isFullRoute)) ||
                (showRevOnPickup &&
                    ((isDropoffRoute && isReturnOrder) || (isPickupRoute && !isReturnOrder) || isFullRoute))
            ) {
                if (orderMapping?.order?.oms) {
                    if (latestException && latestException?.exception?.route_rev_action === 'remove') {
                        return acc;
                    } else if (latestException && latestException?.exception?.route_rev_action === 'custom') {
                        return acc + latestException?.route_custom_charge;
                    } else if (latestException && latestException?.exception?.route_rev_action === 'keep') {
                        return acc + (orderMapping?.order?.order_revenue || 0);
                    } else {
                        return acc + (orderMapping?.order?.order_revenue || 0);
                    }
                } else {
                    return (
                        acc +
                        (orderMapping?.order?.admin_carrier_rate_override ||
                            orderMapping?.order?.listing?.final_accepted_carrier_rate ||
                            orderMapping?.order?.carrier_rate ||
                            0)
                    );
                }
            }
            return acc;
        }, 0);
        return totalRevenue ? totalRevenue.toFixed(2) : 0;
    }, [route]);
};
