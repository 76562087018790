import React, { useState, useMemo, useEffect } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { css } from '@emotion/react';
import { useNotePermissions, useClientUser } from '@/hooks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import {
    GridItemRow,
    ModalActions,
    ModalContent,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
    Body1,
} from '@/components/DispatchPlan/blocks';
import { UPDATE_ORDERS_MANY } from '@/components/DispatchPlan/graphql/mutations';
import { useMutation } from '@apollo/client';
import { TextField } from '@/components/ShipmentForm/blocks';
import * as Sentry from '@sentry/react';

const StopNotes = ({ route, stop, orders }) => {
    const { user_id } = useClientUser();
    const notePermissions = useNotePermissions(route, user_id);

    const [notes, setNotes] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const [updateOrders, { loading }] = useMutation(UPDATE_ORDERS_MANY);

    const displayNotesIcon = useMemo(() => {
        return orders?.some((o) => !!o.shipper_notes || (!!o.carrier_notes && notePermissions?.carrier_notes?.view));
    }, [route, stop]);

    useEffect(() => {
        if (!orders?.length) {
            setNotes({});
            return;
        }
        const _notes = {};
        orders.forEach((o) => {
            _notes[o.order_id] = {};
            _notes[o.order_id].shipper_notes = o.shipper_notes;
            _notes[o.order_id].carrier_notes = o.carrier_notes;
        });
        setNotes(_notes);
    }, [orders]);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleSave = async () => {
        await updateOrders({
            variables: {
                updates: orders.map((o) => ({
                    where: { order_id: { _eq: o.order_id } },
                    _set: {
                        shipper_notes: notes[o?.order_id]?.shipper_notes,
                        carrier_notes: notes[o?.order_id]?.carrier_notes,
                    },
                })),
            },
            onCompleted: () => {
                handleModalClose();
            },
            onError: (error) => {
                Sentry.captureException(error);
            },
        });
    };

    const NotesRow = (order) => {
        return (
            <>
                <GridItemRow
                    css={css`
                        padding-bottom: 5px;
                    `}
                >
                    <Body1>{order?.order_number}</Body1>
                </GridItemRow>
                {notePermissions.shipper_notes.view && (
                    <GridItemRow
                        css={css`
                            padding-bottom: 15px;
                        `}
                    >
                        <TextField
                            label={notePermissions.shipper_notes.edit ? `Shipper Notes` : `Notes from Shipper`}
                            variant="outlined"
                            name="desc"
                            fullWidth
                            value={notes[order?.order_id]?.shipper_notes || null}
                            onChange={(e) => {
                                setNotes({
                                    ...notes,
                                    [order?.order_id]: {
                                        ...notes[order?.order_id],
                                        shipper_notes: e.target.value,
                                    },
                                });
                            }}
                            multiline
                            rows={5}
                            disabled={!notePermissions.shipper_notes.edit}
                        />
                    </GridItemRow>
                )}
                {notePermissions.carrier_notes.view && (
                    <GridItemRow
                        css={css`
                            padding-bottom: 25px;
                        `}
                    >
                        <TextField
                            label="Carrier Notes"
                            variant="outlined"
                            name="desc"
                            fullWidth
                            value={notes[order?.order_id]?.carrier_notes || null}
                            onChange={(e) => {
                                setNotes({
                                    ...notes,
                                    [order?.order_id]: {
                                        ...notes[order?.order_id],
                                        carrier_notes: e.target.value,
                                    },
                                });
                            }}
                            multiline
                            rows={5}
                            disabled={!notePermissions.carrier_notes.edit}
                        />
                    </GridItemRow>
                )}
            </>
        );
    };

    if (displayNotesIcon) {
        return (
            <>
                <Grid
                    container
                    css={css`
                        height: 0px;
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <IconButton
                        css={css`
                            top: -24px;
                            padding: 0px;
                        `}
                        onClick={() => setModalOpen(true)}
                    >
                        <StickyNote2OutlinedIcon
                            css={css`
                                color: black;
                            `}
                        />
                    </IconButton>
                </Grid>
                {modalOpen && (
                    <NavResponsiveModal open={modalOpen} onClose={handleModalClose}>
                        <ModalTitle>{`Notes`}</ModalTitle>
                        <ModalContent
                            css={css`
                                width: 600px;
                            `}
                        >
                            {orders?.map((o) => NotesRow(o))}
                        </ModalContent>
                        <ModalActions>
                            <SecondaryButton disabled={loading} onClick={handleModalClose}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton disabled={loading} onClick={handleSave}>
                                Save
                            </PrimaryButton>
                        </ModalActions>
                    </NavResponsiveModal>
                )}
            </>
        );
    }

    return <></>;
};

export default StopNotes;
