import React from 'react';
import { Grid } from '@material-ui/core';

import { colors } from '@/styles';
import { css } from '@emotion/react';
import { Body1, H3, H2 } from './blocks';

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const Totals = ({ state }) => {
    return (
        <Grid
            container
            direction="row"
            css={css`
                padding: 0 40px;
            `}
        >
            <Grid
                container
                direction="column"
                css={css`
                    background: ${colors.white.primary};
                    flex: 1;
                    flex-basis: 0;
                `}
            />
            <Grid
                container
                direction="column"
                css={css`
                    background: ${colors.white.primary};
                    flex: 1;
                    flex-basis: 0;
                `}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        margin-top: 45px;
                        margin-bottom: 8px;
                        padding: 0px 25px;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Body1>Subtotal</Body1>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            align-items: flex-end;
                        `}
                    >
                        <H2>{state?.subtotal ? dollarFormatter.format(state?.subtotal) : '--'}</H2>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    css={css`
                        margin-bottom: 20px;
                        padding: 0px 25px;
                        padding-bottom: 20px;

                        border-bottom: 1px solid #4c4c4c;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Body1>Adjustments</Body1>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            align-items: flex-end;
                        `}
                    >
                        <H2
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            {state?.adjustments ? dollarFormatter.format(state?.adjustments) : '--'}
                        </H2>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    css={css`
                        margin-bottom: 44px;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    />
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Grid
                            container
                            direction="row"
                            css={css`
                                padding: 0 25px;
                            `}
                        >
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                            >
                                <Body1>Total</Body1>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    justify-content: center;
                                    align-items: flex-end;
                                `}
                            >
                                <H3>{dollarFormatter.format(state?.total || 0)}</H3>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Totals;
