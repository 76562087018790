import { gql } from '@apollo/client';

// search for companies to potentially add to client's blacklist
export const QUERY_BY_BUSINESS_NAME = gql`
    query QueryByBusinessName($client_type_wanted: String, $business_name: String) {
        clients(
            where: {
                business_name: { _ilike: $business_name }
                user: { roles: { _has_key: $client_type_wanted } }
                _not: { user: { roles: { _has_key: "ADMIN" } } }
            }
        ) {
            business_name
            client_id
            user {
                roles
                user_id
            }
        }
    }
`;
