import React, { useContext } from 'react';
import { UserContext } from '@/components/App';

export default function StickyFooter({ children, styles = {} }) {
    const { sidebarCollapsed } = useContext(UserContext);

    return (
        <footer
            style={{
                position: 'fixed',
                bottom: '0px',
                paddingRight: '95px',
                paddingLeft: '15px',
                marginLeft: '-15px',
                marginRight: '-15px',
                height: '80px',
                width: sidebarCollapsed ? 'calc(100% - 50px)' : 'calc(100% - 240px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: '#fff',
                zIndex: 999,
                boxShadow: '0px -4px 4px rgba(0,0,0,0.25)',
                borderTop: '1px solid rgba(0,0,0,0.05)',
                ...styles,
            }}
        >
            {children}
        </footer>
    );
}
