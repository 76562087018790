import { QUERY_TRACKED_COMMUNICATIONS_BY_ORDER } from '@/graphql/queries/tracked_communications';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

const useTrackedComms = (order_id, skip = false) => {
    const { loading, data } = useQuery(QUERY_TRACKED_COMMUNICATIONS_BY_ORDER, {
        variables: {
            order_id,
        },
        skip: !order_id || skip,
    });

    return useMemo(() => {
        const tracked_communications = data?.tracked_communications || [];
        return { loading, tracked_communications };
    }, [loading, data]);
};

export default useTrackedComms;
