import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './components/App';
import { StylesProvider } from '@material-ui/core/styles';
import './index.css';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { initFirebase } from './firebaseConfig';
import LogRocket from 'logrocket';
import ReactGA from 'react-ga4';
import MetaPixel from '@/components/Measurement/meta';
import RedditPixel from '@/components/Measurement/reddit';
import TwitterPixel from '@/components/Measurement/twitter';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

window.VERSION = process.env.REACT_APP_VERSION;

const firebaseApp = initFirebase();

// Prod + Staging -> Sentry
if (['production'].includes(process.env.REACT_APP_ENVIRONMENT)) {
    Sentry.init({
        dsn: 'https://e04216f5bbac422abba49698e8bf8a77@o1054628.ingest.sentry.io/6046664',
        integrations: [new Sentry.Replay(), new Integrations.BrowserTracing()],

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,
        release: process.env.REACT_APP_SENTRY_RELEASE,
    });

    Sentry.setTag('app_version', process.env.REACT_APP_VERSION);
    Sentry.setTag('environment', process.env.REACT_APP_ENVIRONMENT);
}

// Prod -> logrocket + analytics
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    LogRocket.init('pteuru/onward');

    ReactGA.initialize(firebaseApp.options.measurementId);
    RedditPixel.init(firebaseApp.options.redditMeasurementId);
    MetaPixel.init(firebaseApp.options.metaMeasurementId);
    TwitterPixel.init(firebaseApp.options.twitterMeasurementId);
}

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <StylesProvider injectFirst>
            <App />
        </StylesProvider>
    </React.StrictMode>
);
