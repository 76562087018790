import React, { useState, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import { Grid, TextField as MuiTextField } from '@material-ui/core';
import { css } from '@emotion/react';

import TermsAndConditionsModal from '@/components/ShipperSignUp/TermsAndConditionsModal';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { colors } from '@/styles';

import Footer from './Footer';
import { TextField, Body1, Header1, local } from '../blocks';

const REQUIRED_FIELDS = ['email', 'password'];

function CarrierLogin({ state, callbacks }) {
    const [section, setSection] = useState(state.sections[state.progression.current] || {});
    const [isDirty, setDirty] = useState({});
    const [confirmation, setConfirmation] = useState('');
    const [emailValidation, setEmailValidation] = useState({ isValid: false, inFlight: false });
    const [tocOpen, setTocOpen] = useState(false);
    const [tocAgreed, setTocAgreed] = useState(false);

    const validate = useMemo(
        () =>
            debounce((email) => {
                setEmailValidation((prev) => {
                    return { ...prev, inFlight: true };
                });
                callbacks.validateEmail(email).then((res) => {
                    setEmailValidation(() => {
                        return { isValid: res, inFlight: false };
                    });
                });
            }, 500),
        []
    );

    const isEmpty = useCallback(
        (field) => {
            const invalid = REQUIRED_FIELDS.includes(field) && (!section[field] || section[field].length === 0);
            switch (field) {
                case 'password':
                    return invalid || section[field].length < 7;
                default:
                    return invalid;
            }
        },
        [section, confirmation]
    );

    const hasError = useMemo(() => {
        return REQUIRED_FIELDS.some((field) => isEmpty(field)) || !tocAgreed || confirmation !== section.password;
    }, [isEmpty, tocAgreed]);

    let adornment = null;
    if (emailValidation.inFlight) {
        adornment = (
            <InputAdornment position="end">
                <CircularProgress
                    css={css`
                        height: 20px !important;
                        width: 20px !important;
                        color: ${colors.greys[1]};
                    `}
                />
            </InputAdornment>
        );
    } else if (emailValidation.isValid) {
        adornment = (
            <InputAdornment position="end">
                <CheckIcon
                    css={css`
                        color: ${colors.greens.primary};
                    `}
                />
            </InputAdornment>
        );
    }

    return (
        <>
            <Grid
                container
                css={css`
                    margin-bottom: 16px;
                    color: ${local.black};
                `}
            >
                <Header1>Login Info</Header1>
            </Grid>
            <Grid
                container
                css={css`
                    margin-bottom: 16px;
                `}
            >
                <MuiTextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    InputLabelProps={{ shrink: !!section.email }}
                    value={section.email}
                    InputProps={{
                        endAdornment: adornment,
                    }}
                    helperText={
                        section?.email?.length &&
                        !emailValidation.isValid &&
                        isDirty.email &&
                        !emailValidation.inFlight ? (
                            <span
                                css={css`
                                    color: #f44336;
                                `}
                            >
                                Email already exists
                            </span>
                        ) : undefined
                    }
                    onBlur={(e) => {
                        setDirty((prev) => ({ ...prev, email: true }));
                    }}
                    onChange={(e) => {
                        let next = e.target.value;
                        validate(next);
                        setSection((prev) => ({
                            ...prev,
                            email: next,
                        }));
                    }}
                    error={
                        section?.email?.length && !emailValidation.isValid && isDirty.email && !emailValidation.inFlight
                    }
                />
            </Grid>
            <Grid
                container
                css={css`
                    margin-bottom: 16px;
                `}
            >
                <TextField
                    display="Password"
                    value="password"
                    state={section}
                    isDirty={isDirty}
                    helper={isEmpty('password') && isDirty.password ? 'Password must contain 7 characters' : undefined}
                    callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                />
            </Grid>
            <Grid
                container
                css={css`
                    margin-bottom: 16px;
                `}
            >
                <MuiTextField
                    fullWidth
                    variant="outlined"
                    label="Confirm Password"
                    InputLabelProps={{ shrink: !!confirmation }}
                    value={confirmation || ''}
                    helperText={
                        confirmation !== section.password && isDirty._password ? 'Passwords must match' : undefined
                    }
                    onBlur={(e) => {
                        setDirty((prev) => ({ ...prev, _password: true }));
                    }}
                    onChange={(e) => {
                        let next = e.target.value;
                        setConfirmation(next);
                    }}
                    error={confirmation !== section.password && isDirty._password}
                />
            </Grid>

            <Body1>
                <OnwardCheckbox color="primary" checked={tocAgreed} onChange={() => setTocAgreed((prev) => !prev)} />I
                agree to the&nbsp;
                <Body1
                    onClick={() => setTocOpen(true)}
                    css={css`
                        font-weight: 700;
                        cursor: pointer;
                        color: ${colors.greens.primary};
                    `}
                >
                    Terms and Conditions
                </Body1>
            </Body1>

            <Footer
                state={state}
                isFirst={state.progression.current === 0}
                isLast={state.progression.current === state.progression.stages.length - 1}
                hasErrors={hasError}
                callbacks={{
                    advance: () => callbacks.advance(section),
                    revert: callbacks.revert,
                }}
            />

            <TermsAndConditionsModal
                open={tocOpen}
                acceptTerms={() => {
                    setTocAgreed(true);
                    setTocOpen(false);
                }}
                onClose={() => setTocOpen(false)}
            />
        </>
    );
}

export default CarrierLogin;
