import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

function Zoom({ map, location = 'top-right' }) {
    const control = useRef(null);

    useEffect(() => {
        if (map) {
            control.current = new mapboxgl.NavigationControl({ showCompass: false });
            map.addControl(control.current, location);
        }

        return () => {
            if (control.current) {
                map?.removeControl(control.current);
            }
        };
    }, [map]);

    return null;
}

export default Zoom;
