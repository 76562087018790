import StartTimeSelect from '@/components/misc/StartTimeSelect';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    Body1,
    Body2,
    GridItemRow,
    ModalActions,
    ModalContent,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
} from '../blocks';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { useRouteStartTime } from '../hooks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { useMutation } from '@apollo/client';
import { UPDATE_ROUTE_STOPS } from '../graphql/mutations';
import { genAttributes } from '@onward-delivery/core';

const StopWindowModal = () => {
    const {
        actionableRoute,
        setActionableRoute,
        actionableStop,
        setActionableStop,
        setError,
        modalOpen,
        setModalOpen,
    } = useContext(PlanningContext);

    const [earliestStart, setEarliestStart] = useState();
    const [latestEnd, setLatestEnd] = useState();

    const [updateStops, { loading }] = useMutation(UPDATE_ROUTE_STOPS);

    const routeStartTime = useRouteStartTime(actionableRoute);
    const routeTimeZone = useMemo(() => {
        const order = actionableRoute?.orders?.[0]?.order || {};
        const zip = genAttributes(order);
        return zipcode_to_timezone.lookup(order[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [actionableRoute]);

    useEffect(() => {
        if (actionableStop && routeStartTime) {
            setEarliestStart(actionableStop.earliest_start ? new Date(actionableStop.earliest_start) : routeStartTime);
            setLatestEnd(
                actionableStop.latest_end
                    ? new Date(actionableStop.latest_end)
                    : new Date(routeStartTime.getTime() + 14400000)
            );
        }
    }, [routeStartTime, actionableStop]);

    const onClose = () => {
        setModalOpen(null);
        setActionableRoute(null);
        setActionableStop(null);
    };

    const onSave = () => {
        updateStops({
            variables: {
                route_id: actionableRoute.route_id,
                route_update: {
                    need_to_optimize: true,
                },
                updates: [
                    {
                        where: { stop_id: { _eq: actionableStop.stop_id } },
                        _set: {
                            earliest_start: earliestStart >= routeStartTime ? earliestStart.toISOString() : null,
                            latest_end: latestEnd >= routeStartTime ? latestEnd.toISOString() : null,
                        },
                    },
                ],
            },
            onCompleted: () => {
                onClose();
            },
            onError: (error) => {
                setError(error, 'Error saving window for stop.');
            },
        });
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.STOP_WINDOW} onClose={onClose}>
            <ModalTitle>{`Customize optimized window for stop`}</ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <Body2>
                        It is recommended that possible windows be set to 4 hours or longer to find the most optimal
                        route
                    </Body2>
                </GridItemRow>
                {actionableRoute && routeTimeZone && (
                    <>
                        <GridItemRow>
                            <Body1>Earliest Start Time</Body1>
                            <StartTimeSelect
                                timeZone={routeTimeZone}
                                interval={60}
                                value={earliestStart?.getTime() || 0}
                                deliveryDate={actionableRoute?.scheduled_delivery}
                                onChange={(e) => setEarliestStart(new Date(e.target.value))}
                                css={css`
                                    & .MuiFormControl-root {
                                        width: 100%;
                                    }
                                `}
                            />
                        </GridItemRow>
                        <GridItemRow>
                            <Body1>Latest End Time</Body1>
                            <StartTimeSelect
                                timeZone={routeTimeZone}
                                interval={60}
                                value={latestEnd?.getTime() || 0}
                                deliveryDate={actionableRoute?.scheduled_delivery}
                                onChange={(e) => setLatestEnd(new Date(e.target.value))}
                                css={css`
                                    & .MuiFormControl-root {
                                        width: 100%;
                                    }
                                `}
                            />
                        </GridItemRow>
                    </>
                )}
            </ModalContent>
            <ModalActions>
                <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={onSave} disabled={loading}>
                    Save
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default StopWindowModal;
