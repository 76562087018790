import { useCallback, useState } from 'react';

/**
 * Return a function that runs execute and saves the response in component state
 *
 * @param {function} execute a function that returns a promise
 * @param {object} callbacks object with the following optional callbacks
 * 'onComplete' runs once execute resolves successfully
 * 'onError' runs once execute rejects successfully
 * @returns a callback, and the component state
 */
export default function useQuery(execute, callbacks = {}) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { onComplete, onError } = callbacks;

    const action = useCallback(
        (...args) => {
            setLoading(true);
            setData(undefined);
            setError(null);

            return Promise.resolve(execute(...args))
                .then((resp) => {
                    setData(resp);
                    setLoading(false);

                    if (onComplete) {
                        return onComplete(resp);
                    }

                    return resp;
                })
                .catch((err) => {
                    setError(err);
                    setLoading(false);

                    if (onError) {
                        return onError(err);
                    }

                    return err;
                });
        },
        [execute, onComplete, onError]
    );

    return [action, { data, loading, error }];
}
