import zipcode_to_timezone from 'zipcode-to-timezone';
import { getTimezoneOffset } from 'date-fns-tz';

const tzTimeFormat = (tz) =>
    new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: tz,
        timeZoneName: 'short',
    });

const localTimeFormat = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
});

const startFormat = (tz) =>
    new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: tz,
    });

const endFormat = (tz) =>
    new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: tz,
        timeZoneName: 'short',
    });

const getDeliveryWindowFormatted = ({ start, end, zip }) => {
    let tz = zipcode_to_timezone.lookup(zip) || undefined;
    const tzLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isLocal = getTimezoneOffset(tz) === getTimezoneOffset(tzLocal);

    const customerWindow = `${startFormat(tz).format(start)} - ${endFormat(tz).format(end)}`;

    if (!tz || isLocal) {
        return customerWindow;
    } else {
        const localWindow = `${startFormat(tzLocal).format(start)} - ${endFormat(tzLocal).format(end)}`;
        return `${localWindow}\n(${customerWindow})`;
    }
};

const endStopGetDeliveryWindowFormatted = ({ start, zip }) => {
    let tz = zipcode_to_timezone.lookup(zip) || undefined;
    const tzLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isLocal = getTimezoneOffset(tz) === getTimezoneOffset(tzLocal);

    const customerWindow = `${startFormat(tz).format(start)}`;

    if (!tz || isLocal) {
        return customerWindow;
    } else {
        const localWindow = `${startFormat(tzLocal).format(start)}`;
        return `${localWindow}\n(${customerWindow})`;
    }
};

// When given a time and a zipcode:
// returns the time in '11:00AM' format.
// If the timezone is different than the one on the user's local computer, then it will
// append the timezone abbrevation as well as the time in the timezone of the delivery.
// e.g. '11:00AM EST (9:00AM MST)'
export const delWindowToFormattedDateSingle = (_delWindow, zipcode) => {
    let dateObj = new Date(_delWindow);

    let tz = zipcode_to_timezone.lookup(zipcode) || undefined;
    const tzLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isLocal = getTimezoneOffset(tz) === getTimezoneOffset(tzLocal);

    if (!tz || isLocal) {
        return tzTimeFormat(tzLocal).format(dateObj);
    } else {
        return `${tzTimeFormat(tzLocal).format(dateObj)} (${tzTimeFormat(tz).format(dateObj)})`;
    }
};

// When given a start time, end time and delivery timezone:
// returns the range in '11:00AM - 1:00PM' format.
// If the timezone is different than the one on the user's local computer, then it will
// append the timezone abbreviation as well as the window in the timezone of the delivery.
// e.g. '11:00AM - 1:00PM EST (9:00AM - 11:00AM MST)'
export const delWindowToFormattedDateRange = (delWindowStart, delWindowEnd, zipcode = null) => {
    if (!delWindowStart || !delWindowEnd) return;
    return getDeliveryWindowFormatted({ start: new Date(delWindowStart), end: new Date(delWindowEnd), zip: zipcode });
};

export const endStopDelWindowToFormattedDateRange = (delWindowStart, zipcode = null) => {
    if (!delWindowStart) return;
    return endStopGetDeliveryWindowFormatted({ start: new Date(delWindowStart), zip: zipcode });
};

export const dateToFormattedDate = (date, zipcode = null) => {
    if (!date) return;

    const dateObj = new Date(date);
    const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zipTZ = zipcode_to_timezone.lookup(zipcode);
    if (!zipcode || !zipTZ || zipTZ === localTZ) {
        return localTimeFormat.format(dateObj);
    } else {
        return `${tzTimeFormat(localTZ).format(dateObj)}\n(${tzTimeFormat(zipTZ).format(dateObj)})`;
    }
};

export const customerTimeframe = (start, end, zip) => {
    if (!start || !end) return null;

    const startDate = new Date(start);
    const endDate = new Date(end);

    const tz = zipcode_to_timezone.lookup(zip) || Intl.DateTimeFormat().resolvedOptions().timeZone;

    return `${tzTimeFormat(tz).format(startDate)} - ${tzTimeFormat(tz).format(endDate)}`;
};
