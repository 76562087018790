import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import {
    ResponsiveSidebarDialog,
    StickyModalActions,
    ModalContent,
    PrimaryButton,
    SecondaryButton,
} from '@/styles/blocks';
import { genAttributes } from '@onward-delivery/core';
import { Body1, DatePicker } from '../Crossdocking/blocks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { Alert } from '@material-ui/lab';

const ScheduleModal = ({ event, order, callbacks, isReceived, isCrossdocked }) => {
    const [updates, setUpdates] = useState({});

    const updated = useMemo(() => {
        return {
            ...(event || {}),
            est_pickup_date:
                event?.est_pickup_date || order?.pickup_date || order?.first_available_date || new Date().toISOString(),
            est_delivery_date:
                event?.est_delivery_date ||
                order?.delivery_date ||
                order?.target_delivery_date ||
                new Date().toISOString(),
            ...updates,
        };
    }, [updates, event]);

    const tz = useMemo(() => {
        const { zip } = genAttributes(order);
        return zipcode_to_timezone.lookup(order[zip]) || 'America/New_York';
    }, [order]);

    const submit = () => {
        callbacks.onSubmit({
            event: {
                ...(event.event_id ? { event_id: event.event_id } : {}),
                order_id: order.order_id,
                action: event.action,
                est_pickup_date: updated.est_pickup_date,
                est_delivery_date: updated.est_delivery_date,
            },
            order: {
                order_id: order.order_id,
                pickup_date: updated.est_pickup_date,
                delivery_date: updated.est_delivery_date,
                ...(order.order_type === 'return' && updated.est_pickup_date !== order.pickup_date
                    ? {
                          del_window_start: null,
                          del_window_end: null,
                          delivery_time_confirmed: null,
                      }
                    : {}),
                ...(order.order_type !== 'return' && updated.est_delivery_date !== order.delivery_date
                    ? {
                          del_window_start: null,
                          del_window_end: null,
                          delivery_time_confirmed: null,
                      }
                    : {}),
            },
        });
    };

    return (
        <ResponsiveSidebarDialog open={!!event} onClose={callbacks.onClose}>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Body1>
                        Schedule the target{' '}
                        {order.order_type === 'return'
                            ? 'return pickup and delivery dates'
                            : isReceived
                            ? 'delivery date'
                            : `pickup and ${isCrossdocked ? 'cross-dock' : 'delivery'} dates`}
                    </Body1>
                </Grid>
                {isReceived ? null : (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <DatePicker
                            dateLabel={order.order_type === 'return' ? 'Return Pickup Date' : 'Pickup Date'}
                            tz={tz}
                            timestamp={updated.est_pickup_date}
                            onChange={(date) =>
                                setUpdates((prev) => ({ ...prev, est_pickup_date: date ? date.toISOString() : null }))
                            }
                        />
                    </Grid>
                )}
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <DatePicker
                        dateLabel={isCrossdocked ? 'Cross-dock Date' : 'Delivery Date'}
                        tz={tz}
                        timestamp={updated.est_delivery_date}
                        onChange={(date) =>
                            setUpdates((prev) => ({
                                ...prev,
                                est_delivery_date: date ? date.toISOString() : null,
                                ...(isReceived ? { est_pickup_date: date ? date.toISOString() : null } : {}),
                            }))
                        }
                    />
                </Grid>
                <Alert severity="warning">
                    <Body1>{'Estimated dates and timeframes can be updated later'}</Body1>
                </Alert>
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton onClick={submit}>Save</PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default ScheduleModal;
