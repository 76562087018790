import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { typography } from '@/styles';
import { Grid } from '@material-ui/core';

export const BodyCellText = styled.span`
    ${typography.sansSerif}

    font-weight: 400;
    text-overflow: auto;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: block;
        scroll-behavior: smooth;
        scroll-snap-type: mandatory;
        display: none;
        width: 1px;
        height: 1px;
    }
    font-size: 14px;
`;

export const Body2 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 14px;
    line-height: 1.65;
    white-space: nowrap;
`;

export const fragments = {
    cellIcon: css`
        width: 16px;
        height: 16px;
    `,
    cell: css`
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        align-items: center;
    `,
};

export const PrimaryHeaderContainer = styled((props) => <Grid {...props} />)`
    margin: 40px 3rem;
`;

export const PrimaryHeaderLabel = styled.span`
    font-family: Montserrat, Roboto, Arial;
    font-weight: 800;
    font-size: 28px;
    line-height: 1.2;
    color: #0c0b1d;
`;
