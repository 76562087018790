import React from 'react';
import { Grid } from '@material-ui/core';
import { OnwardCard } from './Card';
import { CardTitle, CardItem } from './blocks';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';
import useAction from '@/utilities/useQuery';
import { post } from '@/utilities/onwardClient';
import { MANUAL_DISPATCH_PARTNER_SUBMIT, MANUAL_DISPATCH_PARTNER_UPDATE } from '@/constants/apiRoutes';
import * as Sentry from '@sentry/react';
import { useClientUser } from '@/hooks';

const IntegrationPartnerCard = ({ order, integrationName, setNotification }) => {
    const { isOnwardAdmin, isImposter } = useClientUser();
    const [submitToIntegrationPartner, { loading }] = useAction(
        async (body) => post(MANUAL_DISPATCH_PARTNER_SUBMIT, body),
        {
            onComplete: (response) => {
                if (response?.request?.status === 200) {
                    setNotification({
                        severity: 'success',
                        message: 'Order sent to dispatch partner platform',
                    });
                } else {
                    Sentry.captureException(
                        response?.data?.error || 'Error sending order to dispatch partner platform'
                    );
                    setNotification({
                        severity: 'error',
                        message: 'Error sending order to dispatch partner platform',
                    });
                }
            },
            onError: (err) => {
                console.error(err);
                Sentry.captureException(err);
                setNotification({
                    severity: 'error',
                    message: 'Error sending order to dispatch partner platform',
                });
            },
        }
    );

    const [manualUpdate, { loading: manualUpdateLoading }] = useAction(
        async (body) => post(MANUAL_DISPATCH_PARTNER_UPDATE, body),
        {
            onComplete: (response) => {
                if (response?.request?.status === 200) {
                    setNotification({
                        severity: 'success',
                        message: 'Order updated successfully',
                    });
                } else {
                    Sentry.captureException(response?.data?.error || 'Error updating order');
                    setNotification({
                        severity: 'error',
                        message: response?.data?.error || 'Error updating order',
                    });
                }
            },
            onError: (err) => {
                console.error(err);
                Sentry.captureException(err);
                setNotification({
                    severity: 'error',
                    message: err?.response?.data?.error || 'Error updating order',
                });
            },
        }
    );

    return (
        <OnwardCard
            css={css`
                height: 100%;
            `}
            contentProps={{ style: { height: '100%' } }}
        >
            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} direction="column">
                <CardTitle>Integration Partner Information</CardTitle>
                {integrationName && order.dispatch_integration_meta ? (
                    <>
                        <CardItem className="my-1">
                            Information for this order from the {integrationName} platform can be found below. Updates
                            from {integrationName} may take 1-2 hours to appear on Onward.
                        </CardItem>
                        {order.dispatch_integration_meta.infoFromDispatchPartner ? (
                            <>
                                <CardItem className="my-1">Information from {integrationName}: </CardItem>
                                {Object.entries(order.dispatch_integration_meta.infoFromDispatchPartner).map(
                                    (entry, i) => (
                                        <CardItem key={i} className="my-1">
                                            {entry[0]}: {entry[1]}
                                        </CardItem>
                                    )
                                )}
                            </>
                        ) : null}
                        {(isOnwardAdmin || isImposter) && (
                            <>
                                <CardItem className="my-1">
                                    Onward Admins Only - Click the button below to attempt to manually get information
                                    from {integrationName} and update the order. This feature may not be set up yet for
                                    all integration types.
                                </CardItem>
                                <PrimaryButton
                                    variant="contained"
                                    style={{ width: 'fit-content' }}
                                    disabled={manualUpdateLoading}
                                    onClick={() =>
                                        manualUpdate({
                                            type: integrationName,
                                            order_id: order.order_id,
                                            carrier: {
                                                client_id: order.order_carrier.client_id,
                                                dispatch_integration_type:
                                                    order.order_carrier.dispatch_integration_type,
                                                dispatch_integration_meta:
                                                    order.order_carrier.dispatch_integration_meta,
                                            },
                                        })
                                    }
                                >
                                    Manually Update Order
                                </PrimaryButton>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <CardItem className="my-1">
                            Information for this order has not yet been sent to your partnered integration platform (
                            {integrationName}). Click the button below if you would like to manually submit it.
                        </CardItem>
                        <PrimaryButton
                            variant="contained"
                            style={{ width: 'fit-content' }}
                            disabled={loading}
                            onClick={() =>
                                submitToIntegrationPartner({
                                    type: integrationName,
                                    order_id: order.order_id,
                                    carrier: {
                                        client_id: order.order_carrier.client_id,
                                        dispatch_integration_type: order.order_carrier.dispatch_integration_type,
                                        dispatch_integration_meta: order.order_carrier.dispatch_integration_meta,
                                    },
                                })
                            }
                        >
                            Send to {integrationName}
                        </PrimaryButton>
                    </>
                )}
            </Grid>
        </OnwardCard>
    );
};

export default IntegrationPartnerCard;
