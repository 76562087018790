import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { typography, colors } from '@/styles';
import { Button, Grid, TextField as MuiTextField, IconButton } from '@material-ui/core';

export const local = {
    black: '#000000',
    greys: ['#D9D9D9'],
};

export const HR = styled.div`
    position: absolute;
    top: 14px;
    flex: 1;
    width: 100%;
    left: 50%;
    height: 1px;
    border-bottom: 1px solid ${colors.greys[1]};
`;

export const RootContainer = styled((props) => <Grid container {...props} />)`
    height: 100%;
    margin: auto;
    max-width: 480px;

    @media only screen and (min-width: 960px) {
        max-width: 960px;
    }
`;

export const Card = styled((props) => <Grid container {...props} />)`
    border-radius: 5px;
    background: ${colors.white};
    box-shadow: 0px 1px 3px 0px #3f3f4426;
    box-shadow: 0px 0px 0px 1px #3f3f440d;
    padding: 32px;
`;

export const Header1 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 16px;
    line-height: 19.5px;
`;

export const Header2 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 20px;
    line-height: 19.5px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

export const Step = ({ isSelected, current }) => {
    return (
        <>
            <Grid
                container
                direction="column"
                css={css`
                    width: 30px;
                    height: 30px;
                    border-radius: 999px;
                    z-index: 1;
                    justify-content: center;
                    align-items: center;
                    background: ${!isSelected ? local.greys[0] : colors.greens.primary};
                    color: ${colors.white};
                `}
            >
                <Header1>{current}</Header1>
            </Grid>
        </>
    );
};

export const TextField = ({
    disabled = false,
    display,
    value,
    isDirty,
    number,
    state,
    hasError,
    helper,
    callbacks,
}) => {
    return (
        <MuiTextField
            fullWidth
            variant="outlined"
            disabled={disabled}
            label={display}
            InputLabelProps={{ shrink: !!state[value] }}
            value={state[value] || ''}
            helperText={helper}
            onBlur={(e) => {
                callbacks.setDirty((prev) => ({ ...prev, [value]: true }));
            }}
            onChange={(e) => {
                let next = e.target.value;
                if (number) {
                    next = parseInt(next, 10);
                }

                callbacks.setPayload((prev) => ({ ...prev, [value]: next }));
            }}
            error={hasError ? hasError : callbacks.isEmpty(value) && isDirty[value]}
        />
    );
};
