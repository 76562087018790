import React, { useMemo } from 'react';
import { RECURRING_TYPES, STEPS, UPDATE_TYPES } from '../../constants';

export const useGqlCalculations = ({
    day,
    entity,
    regions,
    rule,
    selectedResource,
    step,
    subregions,
    type,
    updated,
    updateType,
    shippingPartners,
}) => {
    const gqlRules = useMemo(() => {
        const isNewRule = !rule?.type;
        const isEditingRule = rule.type === 'rules';
        const isOverride = type === RECURRING_TYPES.OVERRIDE;
        const { overrides: prevOverrides, ...prevEntity } = entity || {};

        if (!day) {
            return {};
        }

        if (isNewRule) {
            return {
                ...(isOverride
                    ? { add: [] }
                    : {
                          add: [{ rule_start: day.start, rule_type: type }],
                      }),
            };
        }

        if (step === STEPS.DELETE) {
            return {
                ...(updateType === UPDATE_TYPES.SPLIT
                    ? {
                          update: [
                              {
                                  ...prevEntity,
                                  rule_end: day.start,
                              },
                          ],
                      }
                    : {}),
            };
        }

        if (!isOverride) {
            return {
                remove: [],
                ...(updateType === UPDATE_TYPES.SPLIT
                    ? {
                          add: [{ rule_start: day.start, rule_type: type }],
                          update: [{ ...prevEntity, rule_end: day.start }],
                      }
                    : {
                          add: [],
                          update: [{ ...prevEntity, rule_type: type }],
                      }),
            };
        }

        return {
            remove: isEditingRule ? [prevEntity] : [],
        };
    }, [day, selectedResource, subregions, step, entity, updated, rule.type, type, updateType]);

    const gqlOverrides = useMemo(() => {
        const isNewRule = !rule?.type;
        const isEditingRule = rule.type === 'rules';
        const isOverride = type === RECURRING_TYPES.OVERRIDE;
        const { overrides: prevOverrides, ...prevEntity } = entity || {};
        const isDifferent = isEditingRule || isNewRule || !isOverride;

        if (!day || !isDifferent) {
            return {};
        }

        if (isNewRule) {
            return {
                ...(isOverride
                    ? {
                          add: [
                              {
                                  override_start: day.start,
                                  override_end: day.end,
                                  omit: false,
                              },
                          ],
                      }
                    : {}),
            };
        }

        if (step === STEPS.DELETE) {
            return {
                ...(updateType === UPDATE_TYPES.ONE_OFF
                    ? {
                          add: [
                              {
                                  rule_id: rule.id,
                                  override_start: day.start,
                                  override_end: day.end,
                                  omit: true,
                              },
                          ],
                      }
                    : {}),
            };
        }

        const overrides = {
            remove: isEditingRule ? prevOverrides : [],
        };
        if (isOverride) {
            return {
                ...overrides,
                ...(isEditingRule
                    ? {
                          add: [
                              {
                                  override_start: day.start,
                                  override_end: day.end,
                                  omit: false,
                              },
                          ],
                          update: [],
                      }
                    : {
                          update: [
                              {
                                  ...prevEntity,
                                  override_start: day.start,
                                  override_end: day.end,
                                  omit: false,
                              },
                          ],
                          add: [],
                      }),
            };
        }

        return overrides;
    }, [day, selectedResource, subregions, step, entity, updated, rule.type, type, updateType]);

    const gqlRegions = useMemo(() => {
        if (!day) {
            return {};
        }

        return (selectedResource.subregions || []).reduce(
            ({ add, update, remove }, { __typename, ...subregion }) => {
                if (regions.map(([id]) => id).includes(subregion.subregion_id)) {
                    update.push(subregion);
                } else {
                    remove.push(subregion);
                }

                add = add.filter((o) => o.subregion_id !== subregion.subregion_id);

                return { add, update, remove };
            },
            { add: regions.map(([id]) => subregions[id]), update: [], remove: [] }
        );
    }, [day, selectedResource, subregions, regions]);

    const gqlShippingPartners = useMemo(() => {
        return (selectedResource?.shipping_partners || [])
            .filter((mapping) => mapping.partner_client_id !== null)
            .map((mapping) => mapping.partner_client)
            .reduce(
                ({ add, update, globals }, partnerClient) => {
                    if (shippingPartners.includes(partnerClient.client_id)) {
                        if (selectedResource.resource_id) {
                            update.push({
                                resource_id: selectedResource.resource_id,
                                partner_client_id: partnerClient.client_id,
                                enabled: true,
                            });

                            add = add.filter((mapping) => mapping.partner_client_id !== partnerClient.client_id);
                        }
                    } else {
                        update.push({
                            resource_id: selectedResource.resource_id,
                            partner_client_id: partnerClient.client_id,
                            enabled: false,
                        });
                    }

                    return { add, update, globals };
                },
                {
                    add: [
                        ...(!selectedResource.resource_id
                            ? [
                                  {
                                      partner_client_id: null,
                                      enabled: shippingPartners.length === 0,
                                  },
                              ]
                            : []),
                        ...shippingPartners.map((id) => ({
                            partner_client_id: id,
                            enabled: true,
                        })),
                    ],
                    update: [],
                    globals: [
                        ...(selectedResource.resource_id
                            ? [
                                  {
                                      resource_id: selectedResource.resource_id,
                                      partner_client_id: null,
                                      enabled: shippingPartners.length === 0,
                                  },
                              ]
                            : []),
                    ],
                }
            );
    }, [selectedResource, shippingPartners]);

    return {
        rules: gqlRules,
        overrides: gqlOverrides,
        regions: gqlRegions,
        shipping_partners: gqlShippingPartners,
    };
};
