import React from 'react';
import styled from '@emotion/styled';
import { Button, TextField as MuiTextField, Select as MuiSelect, Grid } from '@material-ui/core';

import { colors } from '@/styles/';
import * as typography from '@/styles/typography';

export const FormContainer = styled((props) => <Grid item {...props} />)`
    display: flex;
    width: 100%;
    padding-top: 2.25rem;
    flex-wrap: wrap;
`;

export const TextField = styled(({ value, defaultValue, children, ...props }) => {
    return (
        <MuiTextField
            value={value}
            defaultValue={defaultValue}
            inputProps={{
                min: props.min ? props.min : 0,
            }}
            InputLabelProps={!!defaultValue ? undefined : { shrink: !!value }}
            {...props}
        >
            {children}
        </MuiTextField>
    );
})``;

export const Select = styled(({ children, ...props }) => <MuiSelect {...props}>{children}</MuiSelect>)`
    & .MuiSelect-root {
        padding: 8px 12px;
    }
`;

export const PrimaryButton = styled((props) => <Button {...props} />)`
    ${typography.bold}
    ${typography.sansSerif}

    background: ${colors.greens.primary};
    color: ${colors.white.primary};
    text-transform: none;

    &:hover {
        background: #3e8045;
    }
`;

export const H1 = styled.span`
    ${typography.sansSerif}

    font-weight: 900;
    font-size: 20px;
    line-height: 24px;

    color: ${colors.greys[2]};
`;

export const Title1 = styled.span`
    font-weight: 800;
    font-size: 40px;
    @media only screen and (max-width: 600px) {
        font-size: 28px;
    }
`;

export const Title2 = styled.span`
    font-weight: 800;
    font-size: 28px;
`;

export const Title3 = styled.span`
    font-weight: 700;
    font-size: 16px;
`;

export const Price = styled.span`
    color: #59b863;
    font-size: 40px;
    font-weight: 800;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 600;
    font-size: 17px;
    line-height: 21px;

    color: ${colors.greys[1]};
`;

export const Body2 = styled.span`
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
`;

export const Body3 = styled.div`
    font-size: 10px;
    font-weight: 500;
    color: #4c4c4c;
`;

export const InputLabel1 = styled.div`
    font-weight: 700;
    text-wrap: nowrap;
`;

export const GreyText = styled.span`
    ${typography.sansSerif}

    color: ${colors.greys.primary} !important;

    font-weight: 500;
    font-size: 16px;
    line-height: 19.5px;
`;
