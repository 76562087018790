import React, { useCallback, createContext, useState, useMemo, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';

import { useAccessorials } from './utils';
import { GET_TARIFFS_BY_TARIFF_ID, UPSERT_RATES, DELETE_TARIFF, GET_SUBREGIONS } from './graphql';
import { DEFAULT_RATES } from './types/default';
import { DEFAULT_RATES as LIVING_SPACES_RATES } from './types/livingSpaces';
import { DEFAULT_RATES as AMERICAN_FREIGHT_RATES } from './types/americanFreight';
import { DEFAULT_RATES as ONWARD_CONSOLIDATED } from './types/onwardConsolidated';
import { SERVICE_LEVELS as ONWARD_CONSOLIDATED_SERVICE_LEVELS } from './types/onwardConsolidated';
import { cloneDeep } from 'lodash';
import { useClientUser } from '@/hooks';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { tariff_id } = useParams();
    const { user_id } = useClientUser();
    const [updates, setUpdates] = useState({});
    const [rates, setRates] = useState({});

    const { data: tariff, loading: tariffLoading } = useQuery(GET_TARIFFS_BY_TARIFF_ID, {
        variables: {
            tariff_id,
        },
    });

    const [upsertRates] = useMutation(UPSERT_RATES, {
        update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'pricing_tariffs' });
        },
        onCompleted: () => {
            navigate(`/account`);
        },
        onError: (e) => {
            captureException(e);
        },
    });

    const [deleteTariff] = useMutation(DELETE_TARIFF, {
        variables: {
            tariff_id,
        },
        update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'pricing_tariffs' });
        },
        onCompleted: () => {
            navigate(`/account`);
        },
        onError: (e) => {
            captureException(e);
        },
    });

    const algoType = useMemo(() => {
        return tariff?.result?.algo_type;
    }, [tariff]);

    const defaults = useMemo(() => {
        switch (algoType) {
            case 'SHIPPER_GLOBAL_DEFAULT':
            case 'CARRIER_GLOBAL_DEFAULT':
            case 'DEFAULT':
                return DEFAULT_RATES;
            case 'LIVING_SPACES':
                return LIVING_SPACES_RATES;
            case 'ROCKET_SHIPPING':
                return AMERICAN_FREIGHT_RATES;
            case 'ONWARD_CONSOLIDATED':
                return ONWARD_CONSOLIDATED;
            default:
                return {};
        }
    }, [algoType]);

    const { data: subregionsData, loading: subregionsLoading } = useQuery(GET_SUBREGIONS, {
        variables: {
            client_id: user_id,
        },
        skip: algoType !== 'ONWARD_CONSOLIDATED',
    });

    const subregions = useMemo(() => {
        return subregionsData?.results || [];
    }, [subregionsData]);

    const accessorials = useAccessorials(algoType);

    const required = useMemo(() => {
        return (tariff?.result?.requirements || []).map((req) => req.rate_type);
    }, [tariff]);

    const old = useMemo(() => {
        return (tariff?.result?.rates || []).reduce((acc, rate) => {
            const next = [...(acc[rate.type] || []), rate];
            acc[rate.type] = next.sort((l, r) => {
                if (l.min2 === r.min2) {
                    return l.min - r.min;
                }
                return l.min2 - r.min2;
            });

            return acc;
        }, {});
    }, [tariff]);

    const updated = useMemo(() => {
        return {
            name: updates.name || tariff?.result?.name,
            base_rates_axis: updates.base_rates_axis || tariff?.result?.base_rates_axis,
            base_rates_axis2:
                updates.base_rates_axis2 || updates.base_rates_axis2 === null
                    ? updates.base_rates_axis2
                    : tariff?.result?.base_rates_axis2,
            base_rates_qty: updates.base_rates_qty || tariff?.result?.base_rates_qty,
        };
    }, [tariff, updates]);

    const missing = useMemo(() => {
        let custom = [];
        switch (algoType) {
            case 'ONWARD_CONSOLIDATED':
                custom.push(
                    ...Object.keys(ONWARD_CONSOLIDATED_SERVICE_LEVELS)
                        .map((serviceLevel) => `${serviceLevel}_BASE_RATE`)
                        .filter((type) => {
                            if ([updated.base_rates_axis, updated.base_rates_axis2].includes('SUBREGION')) {
                                return (rates[type] || []).some((bucket) => !bucket.subregion_id);
                            }
                        })
                );
                break;
            default:
                break;
        }

        return {
            ...Object.fromEntries(
                required
                    .filter((type) => {
                        return !(rates[type] || []).every((bucket) => bucket?.rate !== null && bucket?.rate >= 0);
                    })
                    .map((attr) => [attr, true])
            ),
            ...Object.fromEntries(custom.map((attr) => [attr, true])),
        };
    }, [algoType, rates, required, updated]);

    console.debug(required);
    console.debug(rates);
    console.debug(missing);

    useEffect(() => {
        const keys = new Set([...required, ...Object.keys(defaults), ...Object.keys(old)]);
        setRates(
            [...keys].reduce((acc, type) => {
                const fromDefault = defaults[type] || [];
                const fromOld = cloneDeep(old[type] || []);
                return {
                    ...acc,
                    [type]: fromOld.length > 0 ? fromOld : fromDefault,
                };
            }, {})
        );
    }, [old, defaults, required]);

    const addFuelRate = useCallback(() => {
        setRates((prev) => {
            const next = rates?.['FUEL_PRICE_MODIFIER'] || [];
            next[next.length - 1] = {
                ...next[next.length - 1],
                max: null,
            };

            return {
                ...prev,
                FUEL_PRICE_MODIFIER: [
                    ...next,
                    {
                        min: 0,
                        max: 'infinity',
                        rate: null,
                    },
                ],
            };
        });
    }, [rates]);

    const setFuelRate = useCallback(
        ({ bucket, rate, max = null }) => {
            setRates((prev) => {
                const next = [...(rates?.['FUEL_PRICE_MODIFIER'] || [])];
                next[bucket] = {
                    ...(next?.[bucket] || {}),
                    rate,
                    ...(max !== null
                        ? {
                              max,
                          }
                        : {}),
                };

                if (max !== null) {
                    next[bucket + 1] = {
                        ...(next?.[bucket + 1] || {}),
                        min: max,
                    };
                }

                return {
                    ...prev,
                    FUEL_PRICE_MODIFIER: next,
                };
            });
        },
        [rates]
    );

    const deleteFuelRate = useCallback(
        ({ bucket }) => {
            setRates((prev) => {
                const next = [...(rates?.['FUEL_PRICE_MODIFIER'] || [])];
                const rem = next.splice(bucket, 1);

                next[next.length - 1] = {
                    ...(next?.[next.length - 1] || {}),
                    max: rem[0].max,
                };

                return {
                    ...prev,
                    FUEL_PRICE_MODIFIER: next,
                };
            });
        },
        [rates]
    );

    return (
        <Context.Provider
            value={{
                state: {
                    loading: tariffLoading || subregionsLoading,
                    algoType,
                    accessorials,
                    rates,
                    subregions,
                    ...updated,
                },
                callbacks: {
                    updateTariff: (patch) => setUpdates((prev) => ({ ...prev, ...patch })),
                    delete: () => {
                        deleteTariff();
                    },
                    save: () => {
                        upsertRates({
                            variables: {
                                update: updates,
                                tariff_id,
                                rates: Object.entries(rates).reduce((acc, [type, buckets]) => {
                                    acc = [
                                        ...acc,
                                        ...buckets
                                            .filter((bucket) => Number.isFinite(bucket.rate))
                                            .map((bucket) => {
                                                const { __typename, ...remaining } = bucket;
                                                return {
                                                    tariff_id,
                                                    ...remaining,
                                                    type,
                                                };
                                            }),
                                    ];
                                    return acc;
                                }, []),
                            },
                        });
                    },
                    addFuelRate,
                    setFuelRate,
                    deleteFuelRate,
                    setRatesMulti: ({ rates }) => {
                        setRates((prev) => {
                            return {
                                ...prev,
                                ...rates,
                            };
                        });
                    },
                    setRates: ({ type, buckets }) => {
                        setRates((prev) => {
                            return {
                                ...prev,
                                [type]: buckets,
                            };
                        });
                    },
                    setRate: ({ type, bucket, rate, min }) => {
                        setRates((prev) => {
                            const clone = cloneDeep(prev?.[type] || []);
                            clone[bucket] = {
                                ...(clone?.[bucket] || {}),
                                ...(rate || rate === 0 ? { rate } : {}),
                                ...(min || min === 0 ? { min } : {}),
                            };

                            return {
                                ...prev,
                                [type]: clone,
                            };
                        });
                    },
                    updateRate: ({ type, min, min2, subregion_id, rate }) => {
                        setRates((prev) => {
                            const clone = cloneDeep(prev?.[type] || []);
                            const match =
                                clone.find(
                                    (r) =>
                                        r.min === min &&
                                        (min2 >= 0 ? r.min2 === min2 : true) &&
                                        (!!subregion_id ? r.subregion_id === subregion_id : true)
                                ) || {};
                            match.rate = rate;

                            return {
                                ...prev,
                                [type]: clone,
                            };
                        });
                    },
                },
                errors: {
                    missing,
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};
