import React, { useEffect, useState, useContext } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
    Typography,
    Grid,
    Button,
    createTheme,
    ThemeProvider,
    Dialog,
    DialogActions,
    DialogTitle,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import Image from 'react-bootstrap/Image';
import FileDropZone from './FileDropZone';
import * as Sentry from '@sentry/react';
import { UserContext } from '../App';
import track from '../../utilities/track';
import getOrdersFilesAsPDF from '../../utilities/getOrdersFilesAsPDF';
import getOrdersPhotosAsZip from '../../utilities/getOrdersPhotosAsZip';
import { OnwardCard } from './Card';

import { FileEarmarkPdf } from 'react-bootstrap-icons';
import uploadPhotoAsync from '../../utilities/uploadPhotoAsync';
import { saveAs } from 'file-saver';
import parseJson from '../../utilities/stringJsonParser';

const UPDATE_ORDER_FILES = gql`
    mutation UpdateOrderFiles(
        $order_id: uuid
        $pod: jsonb
        $bol_file: jsonb
        $warehouse_file: jsonb
        $delivery_photo: jsonb
        $proof_of_pickup: jsonb
    ) {
        update_orders(
            where: { order_id: { _eq: $order_id } }
            _set: {
                pod: $pod
                bol_file: $bol_file
                warehouse_file: $warehouse_file
                delivery_photo: $delivery_photo
                proof_of_pickup: $proof_of_pickup
            }
        ) {
            returning {
                order_id
                order_number
                pod
                bol_file
                warehouse_file
                delivery_photo
                proof_of_pickup
            }
        }
    }
`;

function UploadedFilesCard(props) {
    const { order, category, userType, editable, loadOrder } = props;
    const [fileCategory, setFileCategory] = useState('');
    const [fileArray, setFileArray] = useState([]);
    const [fileArrayWithTypes, setFileArrayWithTypes] = useState([]);
    const [fileIndex, setFileIndex] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [orderRef, setOrderRef] = useState('orderpatch');
    // const userState = useSelector((state) => state.user);
    const { user: userState } = useContext(UserContext);
    const [updateOrderFiles, { loading, error }] = useMutation(UPDATE_ORDER_FILES);

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        switch (category) {
            case 'Warehouse':
                setFileCategory('warehouse_file');
                if (typeof order.warehouse_file === 'string' && order.warehouse_file !== '') {
                    setFileArray([order.warehouse_file]);
                } else {
                    setFileArray(order.warehouse_file);
                }
                break;
            case 'POD':
                setFileCategory('pod');
                //older orders have pod/bol/final d files stored as a single string instead of an array, this makes the component compatible with both.
                if (typeof order.pod === 'string' && order.pod !== '') {
                    setFileArray([order.pod]);
                } else {
                    setFileArray(order.pod);
                }
                break;
            case 'BOL':
                setFileCategory('bol_file');
                if (typeof order.bol_file === 'string' && order.bol_file !== '') {
                    setFileArray([order.bol_file]);
                } else {
                    setFileArray(order.bol_file);
                }
                break;
            case 'Final Delivery Photos':
                setFileCategory('delivery_photo');
                if (typeof order.delivery_photo === 'string' && order.delivery_photo !== '') {
                    setFileArray([order.delivery_photo]);
                } else {
                    setFileArray(order.delivery_photo);
                }
                break;
            case 'Proof of Pickup':
                setFileCategory('proof_of_pickup');
                if (typeof order.proof_of_pickup === 'string' && order.proof_of_pickup !== '') {
                    setFileArray([order.proof_of_pickup]);
                } else {
                    setFileArray(order.proof_of_pickup);
                }
                break;
        }

        if (order.order_number && order.order_number.includes('RT')) {
            setOrderRef('routedOrder');
        }
    }, [order]);

    useEffect(() => {
        if (fileArray) {
            addFileTypes();
        }
    }, [fileArray]);

    const handleFileDelete = async () => {
        try {
            let updatedFileArray = [...fileArray];
            updatedFileArray.splice(fileIndex, 1);

            await updateOrderFiles({
                variables: {
                    ...order,
                    [fileCategory]: updatedFileArray,
                },
            });
            setFileArray(updatedFileArray);
            setFileIndex(null);

            if (loadOrder) {
                loadOrder();
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
        }
        handleClose();
    };

    const handleFileAdd = async (file, fileType) => {
        if (file) {
            try {
                const fileUrl = URL.createObjectURL(file);
                const fbFileUrl = await uploadPhotoAsync(fileUrl, fileCategory, fileType);
                const fbFile =
                    category === 'POD' || category === 'Final Delivery Photos' || category === 'Proof of Pickup'
                        ? {
                              url: fbFileUrl,
                              environment: 'web',
                          }
                        : fbFileUrl;
                const updatedFileArray = fileArray ? [...fileArray, fbFile] : [fbFile];

                await updateOrderFiles({
                    variables: {
                        ...order,
                        [fileCategory]: updatedFileArray,
                    },
                });

                setFileArray(updatedFileArray);

                if (loadOrder) {
                    loadOrder();
                }

                track(`${userType} uploaded a file`, {
                    order_number: order.order_number,
                    type: category,
                    userEmail: userState.email,
                    environmentType: 'web',
                    test_acc: (userState.shipper || userState.carrier)?.test_acc,
                });
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        }
    };

    // const handleFileAdd = async (file, fileType) => {
    //     if (file) {
    //         try {
    //             const fileUrl = URL.createObjectURL(file);
    //             const fbFileUrl = await uploadPhotoAsync(fileUrl, fileCategory, fileType);
    //         } catch (error) {
    //             Sentry.captureException(error);
    //             console.error(error);
    //         }
    //     }
    // };

    const addFileTypes = async () => {
        let resultArray = [];

        for (const file of fileArray) {
            let type = await checkType(file);
            resultArray.push([file, type]);
        }

        setFileArrayWithTypes(resultArray);
    };

    const checkType = async (fileSource) => {
        try {
            const res = await fetch(fileSource.url ? fileSource.url : fileSource);
            const blob = await res.blob();

            if (blob.type === 'application/pdf') {
                return 'pdf';
            } else if (blob.type === 'image/jpeg') {
                return 'jpg';
            } else if (blob.type === 'image/png') {
                return 'png';
            } else {
                return blob.type;
            }
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    };

    function ConfirmDeleteDialog() {
        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Are you sure you want to delete this file?'}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleFileDelete} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    async function handleDownload() {
        if (category === 'Final Delivery Photos') {
            const zip = await getOrdersPhotosAsZip(order);
            if (zip) {
                const zipBlob = await zip.generateAsync({ type: 'blob' });
                saveAs(zipBlob, `${order.order_number ? order.order_number + ' - ' : ''}Final Delivery Photos.zip`);
            } else {
                const error = new Error(
                    `Could not successfully retrieve Final Delivery Photo zip file for order ${order.order_number}`
                );
                Sentry.captureException(error);
                console.error(error);
            }
        } else {
            const pdfDoc = await getOrdersFilesAsPDF(order, fileCategory);

            if (pdfDoc) {
                saveAs(await pdfDoc.saveAsBase64({ dataUri: true }), order.order_number + ' - ' + category);
            }
        }
    }

    const theme = createTheme({
        palette: {
            text: {
                secondary: '#4c4c4c',
            },
            primary: {
                main: '#59b863',
                contrastText: '#fff',
            },
            secondary: {
                main: '#4c4c4c',
                contrastText: '#fff',
            },
        },
        typography: {
            fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
            h6: {
                color: '#8d8d8d',
                fontWeight: '850',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <div className="d-flex flex-row align-items-center mt-5">
                <Typography variant="h6" className="mt-3" color="secondary">
                    {category}
                </Typography>
            </div>

            <OnwardCard>
                <Grid container direction="column" spacing={1}>
                    <Grid container spacing={1}>
                        {fileArrayWithTypes &&
                            fileArrayWithTypes.map((file, i) => {
                                const fileUrl = file[0]?.hasOwnProperty('url') ? file[0].url : file[0];
                                if (file[1] === 'pdf') {
                                    return (
                                        <Grid item key={i} className="d-flex position-relative">
                                            {editable && (
                                                <div
                                                    onClick={() => {
                                                        setFileIndex(i);
                                                        handleClickOpen();
                                                    }}
                                                    style={{
                                                        backgroundColor: 'black',
                                                        cursor: 'pointer',
                                                        opacity: '0.6',
                                                        height: '25px',
                                                        width: '25px',
                                                        position: 'absolute',
                                                        top: '5px',
                                                        left: '5px',
                                                        borderRadius: '50%',
                                                        zIndex: 2,
                                                    }}
                                                >
                                                    <Clear
                                                        style={{
                                                            position: 'relative',
                                                            zIndex: 3,
                                                            top: '-2px',
                                                            left: '1px',
                                                            color: 'white',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <a href={fileUrl} target="_blank">
                                                <FileEarmarkPdf size={100} />
                                            </a>
                                        </Grid>
                                    );
                                } else
                                    return (
                                        <Grid item key={i} className="d-flex position-relative">
                                            {editable && (
                                                <div
                                                    onClick={() => {
                                                        setFileIndex(i);
                                                        handleClickOpen();
                                                    }}
                                                    style={{
                                                        backgroundColor: 'black',
                                                        cursor: 'pointer',
                                                        opacity: '0.6',
                                                        height: '25px',
                                                        width: '25px',
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px',
                                                        borderRadius: '50%',
                                                        zIndex: 2,
                                                    }}
                                                >
                                                    <Clear
                                                        style={{
                                                            position: 'relative',
                                                            zIndex: 3,
                                                            top: '-2px',
                                                            left: '1px',
                                                            color: 'white',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <a href={fileUrl} target="_blank">
                                                <Image
                                                    style={{
                                                        height: '100px',
                                                        width: '100px',
                                                    }}
                                                    src={fileUrl}
                                                />
                                            </a>
                                        </Grid>
                                    );
                            })}

                        {editable && (
                            <Grid item className="d-flex">
                                <FileDropZone handleFileAdd={handleFileAdd} />
                            </Grid>
                        )}

                        {!editable && fileArrayWithTypes.length < 1 && (
                            <Grid item className="d-flex">
                                <Typography variant="body2">No files uploaded yet</Typography>
                            </Grid>
                        )}
                    </Grid>

                    {fileArrayWithTypes.length > 0 && (
                        <Grid container className="mt-3">
                            <Grid item>
                                <Button
                                    style={{
                                        padding: '5px',
                                    }}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={handleDownload}
                                    disabled={!fileArray || fileArray.length === 0}
                                >
                                    Download
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </OnwardCard>
            <ConfirmDeleteDialog />
        </ThemeProvider>
    );
}

export default UploadedFilesCard;
