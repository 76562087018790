import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    QUERY_ORDERS_BY_OD_OR_PO,
    QUERY_ORDERS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID,
    QUERY_ORDER_SHIPPERS,
    UPDATE_ORDERS_MANY,
} from './queries';
import {
    QUERY_DROPOFF_LOCATIONS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID,
    QUERY_ACTIONABLE_ORDER_AGGREGATES,
} from './queries';
import {
    createTheme,
    Box,
    Grid,
    ThemeProvider,
    TextField,
    InputAdornment,
    LinearProgress,
    Button,
    CircularProgress,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { Search } from '@material-ui/icons';
import FilterPopover from './FilterPopover';
import OrdersTable from './OrdersTable';
import { OnwardTabContainer, OnwardTab } from '../Tabs';
import { UserContext } from '../App';
import { useClientUser } from '@/hooks';
import './styles.css';
import _, { zipObject } from 'lodash';
import dateFns from '../../utilities/dateFns';
import add from 'date-fns/add';
import { subDays } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { captureException } from '@sentry/react';
import { Pagination } from '@material-ui/lab';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import { PrimaryHeaderContainer, PrimaryHeaderLabel } from './blocks';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import csvDownload from 'json-to-csv-export';
import { CSV_BULK_UPDATE_COLUMNS, CSV_EXPORT_COLUMNS } from './constants';
import { PrimaryButton } from '@/styles/blocks';
import Papa from 'papaparse';
import Snackbar from '../Snackbar';
import { useTabs } from '../admin/AdminOrders/constants';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import TabScrollButton from '@material-ui/core/TabScrollButton';
import { calcOrderPricing, calcOrderOwner } from '@/utilities/calcOrderPricing';

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
        h2: {
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: 1.25,
            color: '#2B2B2B',
        },
        h3: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: 1.25,
            color: '#2B2B2B',
        },
    },
    palette: {
        text: {
            secondary: '#4c4c4c',
        },
        primary: {
            main: '#59b863',
            contrastText: '#fff',
        },
    },
});

const searchable = [
    'order_number',
    'po_number',
    'pickup_city',
    'pickup_state',
    'pickup_zip',
    'dropoff_name',
    'dropoff_city',
    'dropoff_state',
    'dropoff_zip',
    'reference_id',
    'dropoff_phone',
    'manufacturer',
    'middle_mile_integration_meta.tracking_number',
];

const addOneDay = (date) => {
    const alteredDate = add(new Date(date), { days: 2 });
    return formatISO(alteredDate, { representation: 'date' });
};

const MyOrders = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [orderLocations, setOrderLocations] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [schedDelStart, setSchedDelStart] = useState(null);
    const [schedDelEnd, setSchedDelEnd] = useState(null);
    const [estShipStart, setEstShipStart] = useState(null);
    const [estShipEnd, setEstShipEnd] = useState(null);
    const [actualReceivedStart, setActualReceivedStart] = useState(null);
    const [actualReceivedEnd, setActualReceivedEnd] = useState(null);
    const [warehouseStartDate, setWarehouseStartDate] = useState(null);
    const [warehouseEndDate, setWarehouseEndDate] = useState(null);
    const [sortBy, setSortBy] = useState({ id: 'deldate', desc: true });
    const [totalCount, setTotalCount] = useState(0);
    const [warehouseStatus, setWarehouseStatus] = useState(null);
    const [manufacturer, setManufacturer] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [actionableAggregates, setActionableAggregates] = useState({});

    const [notification, setNotification] = useState({});

    const [selectedOrderIds, setSelectedOrderIds] = useState({});

    const { user, sidebarCollapsed } = useContext(UserContext);
    const { user_id, accountType, circles, isImposter } = useClientUser();

    const TABS = useTabs({ page: 'oms' });
    const NOW = new Date();

    // Reflects the "useRowHighlight" hook's filters for highlighting rows in the table.
    const highlightFilterMap = {
        CLAIMED: [{ claimed_date: { _lte: subDays(NOW, 7) } }],
        RECEIVED: [
            {
                wh_events: {
                    action: { _eq: 'START:RECEIVING' },
                    created_at: { _lte: subDays(NOW, 1) },
                },
            },
        ],
        DNF: [],
        DELIVERED: [{ delivery_date: { _lte: subDays(NOW, 2) } }],
        EXCEPTION_REPORTED: [
            {
                _or: [
                    {
                        exceptions: {
                            exception: {
                                status: { _neq: 'RESOLVED' },
                            },
                            created_at: { _lte: subDays(NOW, 1) },
                        },
                    },
                    {
                        itemsByOrderId: {
                            exceptions: {
                                exception: {
                                    status: { _neq: 'RESOLVED' },
                                },
                                created_at: { _lte: subDays(NOW, 1) },
                            },
                        },
                    },
                ],
            },
        ],
    };

    useEffect(() => {
        setTabIndex(TABS.find((tab) => tab.value === 'ALL') ? TABS.findIndex((tab) => tab.value === 'ALL') : 0);
    }, [TABS]);

    const getWhereFilters = useCallback(
        (tabIdx, extraFilters = null) => {
            const whereFilters = [
                {
                    _or: [{ shipper_id: { _eq: user_id } }, { carrier_id: { _eq: user_id } }],
                },
                ...(TABS?.[tabIdx]?.filters || []),
            ];

            if (startDate) {
                whereFilters.push({ created_at: { _gte: startDate } });
            }

            if (endDate) {
                whereFilters.push({ created_at: { _lt: addOneDay(endDate) } });
            }
            if (schedDelStart) {
                whereFilters.push({ delivery_date: { _gte: schedDelStart } });
            }

            if (schedDelEnd) {
                whereFilters.push({ delivery_date: { _lt: addOneDay(schedDelEnd) } });
            }

            if (estShipStart) {
                filters.push({
                    wh_events: {
                        _and: [{ action: { _eq: 'START:RECEIVING' } }, { est_ship_date: { _gte: estShipStart } }],
                    },
                });
            }

            if (estShipEnd) {
                filters.push({
                    wh_events: {
                        _and: [
                            { action: { _eq: 'START:RECEIVING' } },
                            { est_ship_date: { _lte: addOneDay(estShipEnd) } },
                        ],
                    },
                });
            }

            if (actualReceivedStart) {
                filters.push({
                    wh_events: {
                        _and: [
                            { action: { _eq: 'START:RECEIVING' } },
                            { received_date: { _gte: actualReceivedStart } },
                        ],
                    },
                });
            }

            if (actualReceivedEnd) {
                filters.push({
                    wh_events: {
                        _and: [{ action: { _eq: 'START:RECEIVING' } }, { received_date: { _gte: actualReceivedEnd } }],
                    },
                });
            }

            if (warehouseStartDate) {
                filters.push({
                    wh_events: {
                        _and: [
                            { action: { _eq: 'START:RECEIVING' } },
                            { est_received_date: { _gte: warehouseStartDate } },
                        ],
                    },
                });
            }

            if (warehouseEndDate) {
                filters.push({
                    wh_events: {
                        _and: [
                            { action: { _eq: 'START:RECEIVING' } },
                            { est_received_date: { _lte: addOneDay(warehouseEndDate) } },
                        ],
                    },
                });
            }

            if (warehouseStatus && warehouseStatus === 'NOT_DELIVERED') {
                filters.push({
                    _not: {
                        wh_events: {
                            action: { _eq: 'START:RECEIVING' },
                            status: { _eq: 'RECEIVED' },
                        },
                    },
                });
            } else if (warehouseStatus) {
                filters.push({
                    wh_events: { _and: [{ action: { _eq: 'START:RECEIVING' } }, { status: { _eq: warehouseStatus } }] },
                });
            }

            if (manufacturer) {
                whereFilters.push({ manufacturer: { _eq: manufacturer } });
            }

            if (orderType) {
                whereFilters.push({ order_type: { _eq: orderType } });
            }

            if (searchTerm) {
                whereFilters.push({
                    _or: searchable.map((searchField) => {
                        const [col, nested] = searchField.split('.');
                        if (!!nested) {
                            return { [col]: { _contains: { [nested]: searchTerm } } };
                        }
                        return { [searchField]: { _ilike: `%${searchTerm}%` } };
                    }),
                });
            }

            if (filters?.length) {
                whereFilters.push({ _and: filters });
            }

            if (extraFilters?.length) {
                whereFilters.push({ _and: extraFilters });
            }

            return { _and: whereFilters };
        },
        [
            searchTerm,
            startDate,
            endDate,
            filters,
            schedDelStart,
            schedDelEnd,
            warehouseEndDate,
            warehouseStartDate,
            estShipStart,
            estShipEnd,
            actualReceivedStart,
            actualReceivedEnd,
            manufacturer,
            warehouseStatus,
            orderType,
            TABS,
        ]
    );

    const where = useMemo(() => {
        return getWhereFilters(tabIndex);
    }, [getWhereFilters, tabIndex]);

    const order_by = useMemo(() => {
        const ascOrDesc = sortBy?.desc ? 'desc_nulls_last' : 'asc_nulls_last';
        switch (sortBy?.id) {
            case 'deldate':
                return [{ delivery_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'est_ship_date':
                return [{ warehouse_estimated_ship_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'est_received_date':
                return [{ warehouse_estimated_delivery_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'received_date':
                return [{ warehouse_actual_received_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'po':
                return [{ po_number: ascOrDesc }];
            case 'ordernum':
                return [{ created_at: ascOrDesc }];
            case 'dropoff':
                return [
                    { dropoff_state: ascOrDesc },
                    { dropoff_city: ascOrDesc },
                    { dropoff_zip: ascOrDesc },
                    { order_number: 'desc_nulls_last' },
                ];
            case 'warehousestatus':
                return [{ warehouse_delivery_status: ascOrDesc }];
            default:
                return [{ delivery_date: 'desc_nulls_last' }, { order_number: 'desc_nulls_last' }];
        }
    }, [sortBy]);

    const PAGE_SIZE = 100;
    const [page, setPage] = useState(0);

    const { loading: loadingOrdersByUserId, data } = useQuery(QUERY_ORDERS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID, {
        variables: {
            limit: PAGE_SIZE,
            offset: page * PAGE_SIZE,
            where,
            order_by,
        },
        onCompleted: (data) => {
            setTotalCount(data.orders_aggregate.aggregate.totalCount);
        },
        onError: (error) => {
            captureException(error);
            console.log(error);
        },
    });

    const { data: shipperData } = useQuery(QUERY_ORDER_SHIPPERS, { variables: { user_id } });
    const shipperOptions = useMemo(() => {
        return (shipperData?.orders || []).map((o) => ({
            value: o.shipper_id,
            label: o.order_shipper.business_name,
        }));
    }, [shipperData]);

    const [getActionableOrderCounts] = useLazyQuery(QUERY_ACTIONABLE_ORDER_AGGREGATES);
    useEffect(() => {
        const fetchAggregates = async () => {
            let result = {};
            for (const [idx, tab] of TABS.entries()) {
                if (!tab.red) continue;

                const extraFilters = highlightFilterMap?.[tab?.value] || [];
                const whereFilters = getWhereFilters(idx, extraFilters);

                try {
                    let { data } = await getActionableOrderCounts({
                        variables: {
                            where: whereFilters,
                        },
                    });
                    let count = data.orders_aggregate.aggregate.totalCount;
                    result[tab.label] = count;
                } catch (err) {
                    console.log(err);
                    captureException(err);
                }
            }
            setActionableAggregates(result);
        };

        fetchAggregates();
    }, [TABS, getWhereFilters]);

    const [updateOrders, { loading: updateLoading }] = useMutation(UPDATE_ORDERS_MANY, {
        onError: (error) => {
            captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Error updating orders' });
        },
    });

    const [getOrdersByNumber, { loading: preupdateLoading }] = useLazyQuery(QUERY_ORDERS_BY_OD_OR_PO, {
        onError: (error) => {
            captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Error finding orders' });
        },
    });

    const { loading: loadingLocationsByUserId, data: dropoffLocations } = useQuery(
        QUERY_DROPOFF_LOCATIONS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID,
        {
            variables: {
                user_id,
            },
            onCompleted: (dropoffLocations) => {
                getOrderLocations(dropoffLocations.orders);
            },
            onError: (error) => {
                captureException(error);
                console.log(error);
            },
        }
    );

    const getOrderLocations = async (orders) => {
        let pulocationMap = {};
        let dolocationMap = {};

        orders.forEach((order) => {
            const pickupLocationKey = `${order.pickup_city}|${order.pickup_state}|${order.pickup_zip}`;
            if (!pulocationMap[pickupLocationKey]) {
                pulocationMap[pickupLocationKey] = {
                    value: pickupLocationKey,
                    label: `${order.pickup_city}, ${order.pickup_state}  ${order.pickup_zip}`,
                };
            }

            const dropoffLocationKey = `${order.dropoff_city}|${order.dropoff_state}|${order.dropoff_zip}`;
            if (!dolocationMap[dropoffLocationKey]) {
                dolocationMap[dropoffLocationKey] = {
                    value: dropoffLocationKey,
                    label: `${order.dropoff_city}, ${order.dropoff_state}  ${order.dropoff_zip}`,
                };
            }
        });
        setOrderLocations({
            pickupKeys: Object.keys(pulocationMap)
                .sort()
                .map((key) => pulocationMap[key]),
            dropoffKeys: Object.keys(dolocationMap)
                .sort()
                .map((key) => dolocationMap[key]),
        });
    };

    const allOrders = useMemo(() => {
        return data?.orders || [];
    }, [data]);

    const manufacturersList = useMemo(() => {
        let manufacturerSet = new Set();
        allOrders.forEach((order) => {
            if (order.manufacturer) {
                manufacturerSet.add({
                    value: order.manufacturer,
                    label: order.manufacturer,
                });
            }
        });

        return [...manufacturerSet];
    }, [allOrders]);

    const handleSearch = (e) => {
        const searchText = e.target.value;
        setSearch(searchText);
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        setDebounceTimer(
            setTimeout(() => {
                setSearchTerm(searchText);
            }, 300)
        );
    };

    const handleTabChange = (e, tab) => {
        setTabIndex(tab);
    };

    const handleSortBy = (newSortBy) => {
        if (newSortBy?.id !== sortBy.id || newSortBy.desc !== sortBy.desc) {
            setSortBy(newSortBy);
            setPage(0);
        }
    };

    const handlePage = (_, p) => {
        const pageIdx = p - 1;
        setPage(pageIdx);
    };

    const exportOrderRevenue = (order) => {
        return calcOrderPricing(order, user_id, circles);
    };

    const exportOrderOwner = (order) => {
        return calcOrderOwner(order, user_id);
    };

    const exportSelectedOrders = () => {
        const ordersById = Object.fromEntries((allOrders || []).map((order) => [order.order_id, order]));
        const rowData = Object.entries(selectedOrderIds)
            .filter(([_, selected]) => selected)
            .reduce((acc, [order_id]) => {
                const order = ordersById[order_id];
                if (!order) return acc;
                let revenue = exportOrderRevenue(order);
                let owner = exportOrderOwner(order);

                const rows = order.itemsByOrderId.map((item) => {
                    return { order, item, revenue, owner };
                });
                return [...acc, ...rows];
            }, []);

        csvDownload({
            headers: CSV_EXPORT_COLUMNS.map((col) => col.header),
            data: rowData.map((row) => CSV_EXPORT_COLUMNS.map((col) => col.value(row))),
            filename: `onward-oms-${new Date().toISOString()}.csv`,
            delimiter: ',',
        });
    };

    const bulkUpdateOrders = (e) => {
        try {
            const file = e.target.files?.[0];
            Papa.parse(file, {
                complete: ({ data }) => {
                    try {
                        const [headers, ...body] = data;

                        const updates = body.map((row, i) => {
                            const raw = zipObject(headers, row);
                            const [formatted, errors] = CSV_BULK_UPDATE_COLUMNS.reduce(
                                ([formattedAcc, errorsAcc], column) => {
                                    const cell = raw[column.header];
                                    const next = {
                                        ...formattedAcc,
                                        ...(cell
                                            ? {
                                                  [column.key]: column.value(cell, formattedAcc),
                                              }
                                            : {}),
                                    };
                                    return [next, [...errorsAcc, ...(column.valid(next) ? [] : [column.errorMsg])]];
                                },
                                [{}, []]
                            );

                            if (errors.length > 0) {
                                throw new Error(`Errors in CSV row ${i + 2}: ${errors.join(', ')}`);
                            }

                            return {
                                ...formatted,
                                ...(formatted.completion_time
                                    ? {
                                          order_status: 'complete',
                                          completion_source: `WEB_${isImposter ? 'ONWARD_ADMIN' : 'CARRIER'}`,
                                      }
                                    : {}),
                            };
                        });

                        getOrdersByNumber({
                            variables: {
                                user_id,
                                po_numbers: updates.reduce((acc, update) => {
                                    return update.po_number ? [...acc, update.po_number] : acc;
                                }, []),
                                order_numbers: updates.reduce((acc, update) => {
                                    return update.order_number ? [...acc, update.order_number] : acc;
                                }, []),
                            },
                        }).then(({ data }) => {
                            const [odMap, poMap] = (data?.orders || []).reduce(
                                ([odAcc, poAcc], order) => {
                                    return [
                                        { ...odAcc, [order.order_number]: order },
                                        { ...poAcc, [order.po_number]: order },
                                    ];
                                },
                                [{}, {}]
                            );

                            const missing = updates.filter(
                                (update) => !odMap[update.order_number] && !poMap[update.po_number]
                            );
                            if (missing.length > 0) {
                                setNotification({
                                    severity: 'error',
                                    message: `Cannot find orders: ${missing
                                        .map((update) => update.order_number || update.po_number)
                                        .join(', ')}`,
                                });
                                return;
                            }

                            updateOrders({
                                variables: {
                                    updates: updates.map(({ received_date, po_number, order_number, ...update }) => ({
                                        where: {
                                            order_id: {
                                                _eq: odMap[order_number]?.order_id || poMap[po_number]?.order_id,
                                            },
                                        },
                                        _set: update,
                                    })),
                                    events: updates
                                        .filter((update) => !!update.received_date)
                                        .map((update) => ({
                                            action: 'START:RECEIVING',
                                            status: 'RECEIVED',
                                            received_date: update.received_date,
                                            order_id:
                                                odMap[update.order_number]?.order_id ||
                                                poMap[update.po_number]?.order_id,
                                        })),
                                },
                            });
                        });
                    } catch (error) {
                        console.error(error);
                        setNotification({ severity: 'error', message: error.message || 'Error processing CSV file' });
                    }
                },
                error: (error) => {
                    throw error;
                },
            });
        } catch (error) {
            console.error(error);
            setNotification({ severity: 'error', message: error.message || 'Error processing CSV file' });
        }
    };

    const tabScrollComponent = ({ style, onClick, ...props }, b) => {
        const getAlertDisplay = (direction) => {
            let display = false;

            const alertDisplayCheck = Object.entries(actionableAggregates).some(([label, count]) => {
                if (count <= 0) return false;
                const tabIdx = TABS.findIndex((t) => t.label === label);
                return direction === 'right' ? tabIdx > tabIndex : tabIdx < tabIndex;
            });

            if (alertDisplayCheck) {
                display = true;
            }
            return display;
        };

        let displayRightAlert = getAlertDisplay('right');
        let displayLeftAlert = getAlertDisplay('left');

        return (
            <>
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        ${props.direction === 'right' &&
                        `
                        padding-left: 12px;
                        border-left: 2px solid lightgray;
                    `}
                        ${props.direction === 'left' &&
                        `
                        padding-right: 12px;
                        border-right: 2px solid lightgray;
                    `}
                    ${props.disabled && 'opacity: 0;'}
                    `}
                    onClick={onClick}
                >
                    {displayRightAlert && props.direction === 'right' && (
                        <NotificationImportantIcon
                            css={css`
                                color: red;
                            `}
                        />
                    )}
                    <TabScrollButton
                        sx={{
                            color: 'red',
                            fontSize: 'large',
                        }}
                        style={{
                            fontSize: 'large',
                        }}
                        {...props}
                        css={css`
                            & svg {
                                font-size: 2.5rem;
                                color: black;
                            }
                        `}
                    />
                    {displayLeftAlert && props.direction === 'left' && (
                        <NotificationImportantIcon
                            css={css`
                                color: red;
                            `}
                        />
                    )}
                </div>
            </>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                className={`${sidebarCollapsed && 'collapse-margin'}  table-height`}
                sx={{ flexGrow: 1, padding: 0, height: 'calc(100vh - 50px)' }}
            >
                <Snackbar
                    open={!!notification?.message}
                    {...(notification || {})}
                    handleClose={() => setNotification({})}
                />
                <Grid container direction="column" wrap="nowrap" className="h-100">
                    <Grid container justifyContent="center" className="bg-white border-bottom">
                        <OnwardTabContainer
                            value={tabIndex}
                            onChange={handleTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            css={css`
                                background-color: white;
                            `}
                            ScrollButtonComponent={tabScrollComponent}
                        >
                            {TABS.map((tab, idx) => (
                                <OnwardTab
                                    label={tab.label}
                                    value={idx}
                                    style={{ outline: 0 }}
                                    key={`tab-${idx}`}
                                    error={tab.red && actionableAggregates?.[tab?.label] > 0}
                                    {...(tab.red && actionableAggregates?.[tab?.label] > 0
                                        ? {
                                              icon: <NotificationImportantIcon fontSize="small" />,
                                          }
                                        : {})}
                                />
                            ))}
                        </OnwardTabContainer>
                    </Grid>
                    <PrimaryHeaderContainer item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <PrimaryHeaderLabel>My Orders</PrimaryHeaderLabel>
                            </Grid>
                            <Grid item>
                                <TextField
                                    value={search}
                                    placeholder="Search orders"
                                    onChange={handleSearch}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className="me-3"
                                    InputProps={{
                                        style: { backgroundColor: 'white' },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FilterPopover
                                    applyFilters={(filters) => setFilters(filters)}
                                    email={user?.email}
                                    userType={accountType}
                                    locations={orderLocations}
                                    shippers={shipperOptions}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    warehouseStartDate={warehouseStartDate}
                                    warehouseEndDate={warehouseEndDate}
                                    setWarehouseEndDate={setWarehouseEndDate}
                                    setWarehouseStartDate={setWarehouseStartDate}
                                    schedDelStart={schedDelStart}
                                    setSchedDelStart={setSchedDelStart}
                                    schedDelEnd={schedDelEnd}
                                    setSchedDelEnd={setSchedDelEnd}
                                    estShipStart={estShipStart}
                                    setEstShipStart={setEstShipStart}
                                    estShipEnd={estShipEnd}
                                    setEstShipEnd={setEstShipEnd}
                                    actualReceivedStart={actualReceivedStart}
                                    setActualReceivedStart={setActualReceivedStart}
                                    actualReceivedEnd={actualReceivedEnd}
                                    setActualReceivedEnd={setActualReceivedEnd}
                                    warehouseStatus={warehouseStatus}
                                    setWarehouseStatus={setWarehouseStatus}
                                    manufacturersList={manufacturersList}
                                    manufacturer={manufacturer}
                                    setManufacturer={setManufacturer}
                                    orderType={orderType}
                                    setOrderType={setOrderType}
                                    user_id={user_id}
                                />
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    onClick={exportSelectedOrders}
                                    css={css`
                                        margin-left: 1rem;
                                        height: 100%;
                                    `}
                                    endIcon={<FileDownloadIcon />}
                                >
                                    Export
                                </PrimaryButton>
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    disabled={updateLoading || preupdateLoading}
                                    component="label"
                                    css={css`
                                        margin-left: 1rem;
                                        height: 100%;
                                    `}
                                    endIcon={
                                        updateLoading || preupdateLoading ? (
                                            <CircularProgress size="24px" color="white" />
                                        ) : (
                                            <FileUploadIcon />
                                        )
                                    }
                                >
                                    Bulk Update
                                    <input
                                        disabled={updateLoading}
                                        type="file"
                                        hidden
                                        accept=".csv"
                                        onChange={bulkUpdateOrders}
                                    />
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </PrimaryHeaderContainer>
                    {loadingOrdersByUserId && (
                        <Grid item className="ms-5 me-5">
                            <LinearProgress
                                color="primary"
                                css={css`
                                    width: 100%;
                                `}
                            />
                        </Grid>
                    )}
                    <Grid item className="ms-5 me-5 bg-white" style={{ overflow: 'hidden', flexGrow: 1 }}>
                        <OrdersTable
                            orders={allOrders}
                            selectedOrderIds={selectedOrderIds}
                            sortBy={[sortBy]}
                            callbacks={{
                                handleSortBy,
                                setSelectedOrderIds,
                            }}
                            tab={TABS[tabIndex]}
                        />
                    </Grid>
                    <Grid
                        container
                        className="m-5"
                        justifyContent="center"
                        css={css`
                            width: auto;
                        `}
                    >
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            css={css`
                                .Mui-selected {
                                    background-color: ${colors.greens.primary};
                                    color: white;
                                }
                            `}
                            count={Math.ceil(totalCount / PAGE_SIZE)}
                            page={page + 1}
                            onChange={handlePage}
                        />
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default MyOrders;
