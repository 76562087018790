import { useClientUser } from '@/hooks';
import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { GreySubheader, PrimaryButton } from '@/styles/blocks';
import { toNational } from '@/utilities/formatPhoneNumber';
import { format } from 'date-fns';
import { CheckCircle, Error, KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons';
import styled from '@emotion/styled';
import OrderCard from './OrderCard';
import { colors } from '@/styles';
import { EDIT_ORDER_TABS } from '../constants';

const Mask = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 999;
`;

const Body = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

const Body2 = styled.span`
    font-size: 16px;
    font-weight: 600;
`;

const StoreName = styled.span`
    color: ${colors.greys.primary};
    font-style: italic;
`;

const ErrorText = styled.span`
    font-size: 14px;
    font-weight: 700;
    color: #d23e3e;
`;

const PickUpGroupCard = ({ address, orders, listings, validation, clientLocations = [], callbacks }) => {
    const { locations, circles } = useClientUser();

    const [open, setOpen] = useState(false);

    const client = useMemo(() => {
        if (orders.length === 0) {
            return null;
        }

        return clientLocations?.[orders[0].carrier_id];
    }, [clientLocations, orders]);

    const store = useMemo(() => {
        return locations.find(
            (location) => location.address === address || address.includes(location.business_address)
        );
    }, [locations, address]);

    const isSaved = orders.every((o) => !!o.order_id);
    const isLoading = orders.some((o) => o.order_status === 'processing');
    const { pickup_name, pickup_phone, first_available_date } = orders[0];
    const Icon = open ? KeyboardArrowDown : KeyboardArrowLeft;

    const hasPickupErrors = useMemo(() => {
        return orders.some((order) => validation.hasErrorTab(order, EDIT_ORDER_TABS.PICKUP));
    }, [orders, validation]);

    const groupStatus = useMemo(() => {
        if (isLoading) return null;

        const errors = orders.filter((order) => {
            if (!isSaved) {
                return validation.hasError(order);
            }

            return (
                order.order_status === 'incomplete' ||
                (order.order_status === 'duplicate' && validation.hasError(order))
            );
        });
        if (errors.length > 0) {
            return (
                <ErrorText
                    css={css`
                        margin-right: 32px;
                    `}
                >
                    Errors: {errors.length}
                </ErrorText>
            );
        } else if (isSaved) {
            return (
                <CheckCircle
                    css={css`
                        color: ${colors.greens.primary};
                        margin-right: 32px;
                    `}
                />
            );
        }

        return null;
    }, [isSaved, isLoading, orders, validation]);

    return (
        <Card
            css={css`
                position: relative;
                margin-bottom: 1rem;
                opacity: ${isLoading ? 0.3 : 1};
            `}
        >
            {isLoading ? <Mask /> : null}
            <CardContent>
                <Grid
                    container
                    css={css`
                        align-items: center;
                        ${open ? 'margin-bottom: 1rem;' : ''}
                    `}
                >
                    <Grid
                        container
                        xs={12}
                        css={css`
                            cursor: pointer;
                            align-items: center;
                        `}
                        onClick={() => setOpen((prev) => !prev)}
                    >
                        <Grid
                            container
                            xs={9}
                            direction="column"
                            css={css`
                                align-items: flex-start;
                                justify-content: flex-start;
                            `}
                        >
                            {circles?.['auto-assign-by-zip'] && client ? (
                                <Grid direction="row" container item>
                                    <GreySubheader
                                        css={css`
                                            margin-bottom: 4px;
                                        `}
                                    >
                                        Auto-assigned:{' '}
                                        <StoreName>{client?.business_name ? client?.business_name : '--'}</StoreName>
                                    </GreySubheader>
                                </Grid>
                            ) : null}
                            <Grid direction="row" container item>
                                <GreySubheader
                                    css={css`
                                        margin-bottom: 4px;
                                    `}
                                >
                                    {address.length > 0 ? address : 'No Address'}
                                    {store && <StoreName> ({store.location_name})</StoreName>}
                                    {hasPickupErrors && (
                                        <ErrorText
                                            css={css`
                                                margin-left: 16px;
                                            `}
                                        >
                                            Pickup / Return Warehouse Error
                                        </ErrorText>
                                    )}
                                </GreySubheader>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            xs={3}
                            css={css`
                                align-items: center;
                                justify-content: flex-end;
                            `}
                        >
                            <Grid direction="row" container>
                                <Grid
                                    direction="column"
                                    container
                                    item
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        justify-content: center;
                                    `}
                                >
                                    {groupStatus}
                                </Grid>
                                <Grid
                                    direction="column"
                                    container
                                    item
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        justify-content: center;
                                    `}
                                >
                                    <Body2
                                        css={css`
                                            font-size: 14px;
                                            color: #8d8d8d;
                                            margin-right: 32px;
                                        `}
                                    >
                                        Orders: {orders.length}
                                    </Body2>
                                </Grid>
                                <Grid
                                    direction="column"
                                    container
                                    item
                                    css={css`
                                        flex: 0;
                                        flex-basis: 0;
                                    `}
                                >
                                    <IconButton
                                        css={css`
                                            padding: 4px;
                                        `}
                                    >
                                        <Icon
                                            css={css`
                                                color: black;
                                            `}
                                        />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {open ? (
                        <Grid container item xs={12} direction="column">
                            <Body>{toNational(pickup_phone)}</Body>
                            <Body>{pickup_name}</Body>
                            <Body>
                                First Available:{' '}
                                {first_available_date ? format(new Date(first_available_date), 'MM/dd/yyyy') : '--'}
                            </Body>
                            <PrimaryButton
                                css={css`
                                    margin-top: 1rem;
                                    width: 120px;
                                `}
                                onClick={() => callbacks.openEditPickupModal(orders)}
                            >
                                Edit
                            </PrimaryButton>
                        </Grid>
                    ) : null}
                </Grid>
                {open ? (
                    <>
                        {orders.map((order, idx) => (
                            <Grid
                                item
                                key={order.order_id || order.key}
                                css={css`
                                    border-top: 1px solid ${colors.greys.border};
                                    padding-top: 1rem;
                                    ${idx < orders.length - 1 ? 'padding-bottom: 1rem;' : ''}
                                `}
                            >
                                <OrderCard
                                    accordion={true}
                                    order={order}
                                    listing={listings?.[order.order_id]}
                                    orderNumber={idx + 1}
                                    validation={{
                                        hasError: () => {
                                            return validation.hasError(order);
                                        },
                                        hasErrorTab: (tab) => {
                                            return validation.hasErrorTab(order, tab);
                                        },
                                    }}
                                    callbacks={callbacks}
                                />
                            </Grid>
                        ))}
                    </>
                ) : null}
            </CardContent>
        </Card>
    );
};

export default PickUpGroupCard;
