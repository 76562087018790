import { Form } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { css } from '@emotion/react';

const FleetSizeInput = ({ fleetSize, setFleetSize }) => {
    return (
        <Form.Group className="my-2 w-100">
            <TextField
                css={css`
                    width: 50%;
                    padding-right: 12px;
                `}
                className=""
                value={fleetSize}
                variant="outlined"
                type="text"
                name="fleetSize"
                label={'Number of trucks in fleet'}
                onChange={(e) => {
                    let parsed = e.target.value.replace(/[^0-9]/g, '');
                    if (parsed === '') {
                        setFleetSize('');
                    } else {
                        setFleetSize(Number(parsed));
                    }
                }}
            />
        </Form.Group>
    );
};

export default FleetSizeInput;
