import React, { useEffect, useState } from 'react';
import { Row, Container, Card, Form, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { TextField, Select, FormControl, InputLabel } from '@material-ui/core';
import states from './constants/states';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { ADD_BILLING_INFO_TO_CLIENT, UPDATE_CLIENT_WITH_STRIPE_ID } from '../mutations';
import { useMutation } from '@apollo/client';
import { useClientUser } from '@/hooks';
import Snackbar from '../../Snackbar';
import AccountCardSetup from './AccountCardSetup';

function Billing(props) {
    const { currentShipper } = props;

    const [validated, setValidated] = useState(false);
    const [streetAddress, setStreetAddress] = useState('');
    const [billingEmail, setBillingEmail] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');
    const [billingState, setBillingState] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { user_id } = useClientUser();

    const [updateBillingInfo] = useMutation(ADD_BILLING_INFO_TO_CLIENT, {
        onCompleted: (data) => {
            setSuccessMessage('Billing details updated successfully!');
        },
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
        },
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    useEffect(() => {
        setStreetAddress(currentShipper.billing_address);
        setBillingEmail(currentShipper.billing_email);
        setZipcode(currentShipper.billing_zip);
        setCity(currentShipper.billing_city);
        if (currentShipper.billing_state) {
            let trimmedState = currentShipper.billing_state.trim();
            setBillingState(trimmedState);
        }
    }, [currentShipper]);

    const saveBillingChanges = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            await updateBillingInfo({
                variables: {
                    client_id: user_id,
                    billing_address: streetAddress,
                    billing_city: city,
                    billing_state: billingState,
                    billing_zip: zipcode,
                    billing_email: billingEmail,
                },
            });
        }
        setValidated(true);
    };

    return (
        <>
            <Card className="tabCard">
                <Card.Body className="">
                    <Container fluid>
                        <ThemeProvider theme={theme}>
                            <Form id="billingInfo" noValidate validated={validated} onSubmit={saveBillingChanges}>
                                <Row className="mt-2 mb-1">
                                    <Col>
                                        <h6>Billing Email</h6>
                                    </Col>
                                </Row>
                                <Row className="d-flex">
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                error={!streetAddress && validated}
                                                fullWidth
                                                className=""
                                                value={billingEmail}
                                                variant="outlined"
                                                label={'Billing Email'}
                                                onChange={(e) => setBillingEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-1">
                                    <Col>
                                        <h6>Billing Adress</h6>
                                    </Col>
                                </Row>
                                <Row className="d-flex">
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                error={!streetAddress && validated}
                                                fullWidth
                                                className=""
                                                value={streetAddress}
                                                variant="outlined"
                                                label={'Street Address'}
                                                onChange={(e) => setStreetAddress(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    error={!city && validated}
                                                    fullWidth
                                                    className=""
                                                    value={city}
                                                    variant="outlined"
                                                    label={'City'}
                                                    onChange={(e) => setCity(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <FormControl className="w-100 my-2" variant="outlined">
                                            <InputLabel>State</InputLabel>
                                            <Select
                                                error={!billingState && validated}
                                                required
                                                native
                                                value={billingState}
                                                label="State"
                                                onChange={(e) => setBillingState(e.target.value)}
                                            >
                                                <option aria-label="None" value="" />
                                                {states.map((state) => (
                                                    <option key={state.abbreviation} value={state.abbreviation}>
                                                        {state.abbreviation}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                type="number"
                                                required
                                                error={!zipcode && validated}
                                                fullWidth
                                                className=""
                                                value={zipcode}
                                                variant="outlined"
                                                label={'Zip Code'}
                                                onChange={(e) => setZipcode(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-1">
                                    <Col>
                                        <Button type="submit" form="billingInfo" className="my-2 ml-3 account-btn">
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                                </Form>
                        </ThemeProvider>
                    </Container>
                </Card.Body>
            </Card>
            <AccountCardSetup currentClient={currentShipper} />

        <Snackbar
            open={!!successMessage}
            handleClose={() => setSuccessMessage('')}
            severity="success"
            message={successMessage}
        />

        <Snackbar
            open={!!errorMessage}
            handleClose={() => setErrorMessage('')}
            severity="error"
            message={errorMessage}
        />

        </>
    );
}

export default Billing;
