import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Icon, Switch } from '@material-ui/core';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PrimaryButton, DangerButton } from '@/components/DispatchPlan/blocks';
import _ from 'lodash';
import { ShapeSelect } from './ShapeControl';

const Body1 = styled.span`
    font-size: 14px;
    font-weight: 700;
`;

const Combination = ({
    shapeFieldTypeOptions,
    combo,
    index,
    setUpdatedCombinations,
    updatedCombinations,
    comboPotentialChoices,
    removeCombo,
}) => {
    const usedFields = useMemo(() => {
        return Object.keys(combo);
    });

    const updateCombo = (update) => {
        let newUpdatedCombinations = [...updatedCombinations];
        newUpdatedCombinations[index] = update;
        setUpdatedCombinations(newUpdatedCombinations);
    };

    const handleAddField = () => {
        for (let option of shapeFieldTypeOptions) {
            if (!usedFields.includes(option)) {
                let _update = { ...combo, [option]: null };
                updateCombo(_update);
                break;
            }
        }
    };

    const handleFieldChange = (e, fieldType) => {
        let _update = { ...combo };
        delete _update[fieldType];
        if (e.target.value !== 'remove') {
            _update[e.target.value] = null;
        }
        updateCombo(_update);
    };

    const handleValueChange = (e, fieldType) => {
        let _update = { ...combo };
        _update[fieldType] = e.target.value;
        updateCombo(_update);
    };

    const changeComboShape = (e) => {
        let _update = { ...combo };
        _update.shape = e.target.value;
        updateCombo(_update);
    };

    const isAddFieldDisabled = shapeFieldTypeOptions.every((option) => usedFields.includes(option));

    const labels = {
        shipper: 'Shipper',
        manufacturer: 'Manufacturer',
        item_type: 'Item Type',
        template_name: 'Template',
        custom_shape: 'Custom Shape',
        order_type: 'Order Type',
    };

    return (
        <Grid
            container
            direction="row"
            css={css`
                border-bottom: 1px solid lightgrey;
                padding-bottom: 1rem;
                padding-top: 1rem;
            `}
        >
            <Grid
                item
                container
                direction="column"
                xs={9}
                css={css`
                    min-height: 100px;
                `}
            >
                <Body1
                    css={css`
                        margin-left: 0.5rem;
                    `}
                >
                    Field Combination
                </Body1>
                <Grid
                    item
                    css={css`
                        margin-top: 0.5rem;
                    `}
                >
                    {Object.keys(combo)
                        .filter((el) => el !== 'shape')
                        .map((fieldType) => (
                            <Grid
                                item
                                css={css`
                                    margin-top: 0.5rem;
                                `}
                            >
                                <FormControl
                                    css={css`
                                        margin-right: 0.5rem;
                                        width: 155px;
                                    `}
                                >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="field-select"
                                        value={fieldType}
                                        onChange={(e) => handleFieldChange(e, fieldType)}
                                    >
                                        {shapeFieldTypeOptions
                                            .filter((opt) => !usedFields.includes(opt) || fieldType === opt)
                                            .map((opt) => (
                                                <MenuItem value={opt}>
                                                    <Body1>{labels[opt]}</Body1>
                                                </MenuItem>
                                            ))}
                                        <MenuItem value={'remove'}>
                                            <Body1
                                                css={css`
                                                    color: red;
                                                `}
                                            >
                                                Remove
                                            </Body1>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl
                                    css={css`
                                        margin-left: 0.5rem;
                                        width: 155px;
                                    `}
                                >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="field-select"
                                        displayEmpty
                                        value={combo[fieldType]}
                                        onChange={(e) => handleValueChange(e, fieldType)}
                                    >
                                        <MenuItem value={null}>
                                            <Body1>Choose...</Body1>
                                        </MenuItem>
                                        {comboPotentialChoices[fieldType].map((choice) => (
                                            <MenuItem value={choice}>
                                                <Body1>{choice}</Body1>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))}
                </Grid>
            </Grid>
            <Grid
                item
                container
                direction="column"
                xs={3}
                css={css`
                    min-height: 100px;
                `}
            >
                <Body1
                    css={css`
                        margin-left: 0.5rem;
                    `}
                >
                    Shape
                </Body1>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    css={css`
                        margin-top: 1rem;
                    `}
                >
                    <ShapeSelect entry={combo} showComboShapes={true} changeComboShape={changeComboShape} />
                </Grid>
            </Grid>
            <PrimaryButton disabled={isAddFieldDisabled} onClick={handleAddField} style={{ marginTop: 15 }}>
                Add Field
            </PrimaryButton>
            <DangerButton onClick={() => removeCombo(index)} style={{ marginTop: 15, marginLeft: 15 }}>
                Remove
            </DangerButton>
        </Grid>
    );
};

export default Combination;
