import { asDateInTZ } from '@/utilities/convertToISO';
import { formatInTimeZone } from 'date-fns-tz';
import zipcode_to_timezone from 'zipcode-to-timezone';

export const INVENTORY_TABS = [
    {
        label: 'All',
        value: 0,
        orderQuery: {
            order_status: {
                _in: [
                    'pending',
                    'active',
                    'routed',
                    'claimed',
                    'inProgress',
                    'routedInProgress',
                    'complete',
                    'routedComplete',
                ],
            },
        },
    },
    {
        label: 'Not Received',
        value: 1,
        itemQuery: { itemsByOrderId: { item_sku_status: { _eq: `NOT_RECEIVED` } } },
        orderQuery: {
            _or: [
                {
                    _and: [
                        { order_type: { _eq: 'delivery' } },
                        {
                            order_status: {
                                _in: ['pending', 'active', 'routed', 'claimed', 'inProgress', 'routedInProgress'],
                            },
                        },
                    ],
                },
                {
                    _and: [
                        { order_type: { _neq: 'delivery' } },
                        {
                            order_status: {
                                _in: [
                                    'pending',
                                    'active',
                                    'routed',
                                    'claimed',
                                    'inProgress',
                                    'routedInProgress',
                                    'complete',
                                    'routedComplete',
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    },
    {
        label: 'In Stock',
        value: 2,
        itemQuery: { itemsByOrderId: { item_sku_status: { _eq: `IN_STOCK` } } },
        orderQuery: {
            _or: [
                {
                    _and: [
                        { order_type: { _eq: 'delivery' } },
                        {
                            order_status: {
                                _in: ['pending', 'active', 'routed', 'claimed', 'inProgress', 'routedInProgress'],
                            },
                        },
                    ],
                },
                {
                    _and: [
                        { order_type: { _neq: 'delivery' } },
                        {
                            order_status: {
                                _in: [
                                    'pending',
                                    'active',
                                    'routed',
                                    'claimed',
                                    'inProgress',
                                    'routedInProgress',
                                    'complete',
                                    'routedComplete',
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    },
    {
        label: 'Complete',
        value: 3,
        itemQuery: {
            itemsByOrderId: { item_sku_status: { _eq: `COMPLETE` } },
        },
        orderQuery: {
            order_status: {
                _in: [
                    'pending',
                    'active',
                    'routed',
                    'claimed',
                    'inProgress',
                    'routedInProgress',
                    'complete',
                    'routedComplete',
                ],
            },
        },
    },
];

export const INVENTORY_CSV_COLUMNS = [
    {
        header: 'SKU',
        value: (skuItemLog) => {
            return skuItemLog.sku || '';
        },
    },
    {
        header: 'Order Number',
        value: (skuItemLog) => {
            return skuItemLog.order_number || '';
        },
    },
    {
        header: 'PO Number',
        value: (skuItemLog) => {
            return skuItemLog.po_number || '';
        },
    },
    {
        header: 'Shipper',
        value: (skuItemLog) => {
            return skuItemLog.shipper || '';
        },
    },
    {
        header: 'Pallet',
        value: (skuItemLog) => {
            return skuItemLog.pallet || '';
        },
    },
    {
        header: 'Bin/Location',
        value: (skuItemLog) => {
            return skuItemLog.warehouse_location || '';
        },
    },
    {
        header: 'Action',
        value: (skuItemLog) => {
            return skuItemLog.action || '';
        },
    },
    {
        header: 'Qty In',
        value: (skuItemLog) => {
            return `${skuItemLog.qtyIn || 0}/${skuItemLog.totalQuantity}`;
        },
    },
    {
        header: 'Qty Out',
        value: (skuItemLog) => {
            return `${skuItemLog.qtyOut || 0}/${skuItemLog.totalQuantity}`;
        },
    },
    {
        header: 'Date',
        value: (skuItemLog) => {
            const tz = zipcode_to_timezone.lookup(skuItemLog.tzZip) || 'America/New_York';
            return skuItemLog.timestamp
                ? formatInTimeZone(asDateInTZ(skuItemLog.timestamp, tz), tz, 'EEE, MMM d, yyyy')
                : '';
        },
    },
    {
        header: 'Timestamp',
        value: (skuItemLog) => {
            return skuItemLog.timestamp || '';
        },
    },
];
