import { OnwardSwitch } from '@/styles/blocks';
import React, { useContext, useMemo, useState } from 'react';
import LoadingCard from '../cards/LoadingCard';
import MarketplaceOrderCard from '../cards/MarketplaceOrderCard';
import TextCard from '../cards/TextCard';
import { Context } from '../store';
import {
    Sidebar,
    SidebarLabel,
    SidebarItem,
    SidebarScrollable,
    SidebarScrollableContainer,
    Dot,
    LegendContainer,
} from './blocks';
import ScienceIcon from '@mui/icons-material/Science';
import DebouncedSearch from '@/components/DebouncedSearch';
import { css } from '@emotion/react';
import { MARKER_COLORS } from '../constants';
import { ArrowRightAlt } from '@material-ui/icons';

const SEARCHABLE = ['order_number', 'dropoff_city', 'pickup_city'];

const MarketplaceSidebar = () => {
    const { state, callbacks } = useContext(Context);

    const [search, setSearch] = useState('');

    const filteredOrders = useMemo(() => {
        return state.marketplaceOrders.filter(
            (order) => !search || SEARCHABLE.some((field) => order[field]?.search(search) >= 0)
        );
    }, [state.marketplaceOrders, search]);

    const loading = state.marketplaceOrdersLoading;
    const empty = !loading && state.marketplaceOrders.length === 0;

    return (
        <Sidebar>
            <SidebarItem>
                <SidebarLabel>Marketplace Orders</SidebarLabel>
                <LegendContainer>
                    <Dot color={MARKER_COLORS.MARKETPLACE_PICKUP} />
                    <ArrowRightAlt />
                    <Dot color={MARKER_COLORS.MARKETPLACE_DROPOFF} />
                </LegendContainer>
            </SidebarItem>
            <SidebarItem>
                <DebouncedSearch
                    onChange={(value) => setSearch(value)}
                    css={css`
                        margin-right: 2rem;
                    `}
                />
                <OnwardSwitch
                    label={<ScienceIcon />}
                    checked={state.test}
                    onChange={() => callbacks.setTest((prev) => !prev)}
                    disabled={state.marketplaceOrdersLoading}
                />
            </SidebarItem>
            <SidebarScrollableContainer>
                <SidebarScrollable>
                    {loading && <LoadingCard />}
                    {empty && (
                        <TextCard
                            text={`No marketplace ${state.test ? 'test ' : ''}orders scheduled for delivery on ${
                                state.deliveryDateFormatted
                            }`}
                        />
                    )}
                    {!loading &&
                        !empty &&
                        filteredOrders.map((order) => <MarketplaceOrderCard key={order.order_id} order={order} />)}
                </SidebarScrollable>
            </SidebarScrollableContainer>
        </Sidebar>
    );
};

export default MarketplaceSidebar;
