import { useClientUser } from '@/hooks';
import { useApolloClient, useQuery } from '@apollo/client';
import { Grid, InputAdornment, Snackbar, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { LTL_LOADS } from './graphql';
import { css } from '@emotion/react';
import LoadsMap from './LoadsMap';
import { border } from './blocks';
import Load from './Load';
import { captureException } from '@sentry/react';
import ClaimLoads from './ClaimLoads';
import hubspotControls from '@/utilities/hubspotControls';
import STATES_INVERTED from '@/constants/statesInverted';
import STATES from '@/constants/states';
import { Alert } from '@material-ui/lab';
import OrderClaimTCModal from '../OrderClaimTCModal';
import { useParams } from 'react-router';
import PlaceBidModal from './PlaceBidModal';
import useAction from '@/utilities/useQuery';
import { post } from '@/utilities/onwardClient';
import { CLAIM_NOW, PLACE_BID } from '@/constants/apiRoutes';
import Filters from './Filters';
import { CircularProgress } from '@material-ui/core';

const SEARCHABLE = ['dropoff_city', 'pickup_city', 'order_number'];

const LoadBoardLTL = () => {
    const { order_id: orderIdParam } = useParams();
    const { cache } = useApolloClient();
    const { test_acc, businessState, user_id, circles } = useClientUser();
    const [selectedLoads, setSelectedLoads] = useState({});
    const [searchText, setSearchText] = useState('');
    const [search, setSearch] = useState('');
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [stateFilters, setStateFilters] = useState([]);
    const [deliveryTypeFilters, setDeliveryTypeFilters] = useState([]);
    const [firstAvailableFilter, setFirstAvailableFilter] = useState(null);
    const [maxCubes, setMaxCubes] = useState(null);
    const [totalPeople, setTotalPeople] = useState(null);
    const [withinRangeOrderPoint, setWithinRangeOrderPoint] = useState(null);
    const [withinRangeMiles, setWithinRangeMiles] = useState(null);
    const [withinRangeLocation, setWithinRangeSetLocation] = useState(null);
    const [takingBids, setTakingBids] = useState(false);
    const [notification, setNotification] = useState(null);
    const [tcModalOpen, setTCModalOpen] = useState(false);
    const [placeBidModalOpen, setPlaceBidModalOpen] = useState(false);
    const [bid, setBid] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [bidComments, setBidComments] = useState('');

    // Used to track first load of the page, so the linked order will stay selected on load.
    const [viewLinkedOrder, setViewLinkedOrder] = useState(orderIdParam ? true : false);

    const condition = useMemo(() => {
        const filters = [
            { shipper_id: { _neq: user_id } },
            test_acc
                ? { order_shipper: { test_acc: { _eq: true } } }
                : {
                      _or: [
                          { order_shipper: { test_acc: { _eq: false } } },
                          { order_shipper: { test_acc: { _is_null: true } } },
                      ],
                  },
        ];

        if (takingBids) {
            filters.push({
                listing: {
                    listing_type: { _in: ['all', 'auction'] },
                },
            });
        }

        if (search) {
            filters.push({
                _or: SEARCHABLE.map((field) => ({
                    [field]: { _ilike: `%${search}%` },
                })),
            });
        }
        if (stateFilters?.length) {
            filters.push({
                _or: ['dropoff_state', 'pickup_state'].map((field) => ({
                    [field]: {
                        _in: [...stateFilters, ...stateFilters.map((stateCode) => STATES[stateCode])].filter((s) => s),
                    },
                })),
            });
        }
        if (deliveryTypeFilters?.length) {
            filters.push({
                _or: ['dropoff_location_type', 'pickup_location_type'].map((field) => ({
                    [field]: {
                        _in: deliveryTypeFilters,
                    },
                })),
            });
        }

        if (firstAvailableFilter) {
            filters.push({
                first_available_date: { _gte: firstAvailableFilter },
            });
        }

        if (maxCubes) {
            filters.push({
                itemsByOrderId_aggregate: { sum: { arguments: 'total_cubes', predicate: { _lte: maxCubes } } },
            });
        }

        if (totalPeople) {
            if (totalPeople === 1) {
                filters.push({
                    recommended_equipment: { _has_key: `1_man_team` },
                });
            } else if (totalPeople === 2) {
                filters.push({
                    recommended_equipment: { _has_keys_any: ['1_man_team', '2_man_team'] },
                });
            }
        }

        if (withinRangeOrderPoint && withinRangeMiles && withinRangeLocation) {
            const orderPoint = withinRangeOrderPoint === 'Order pickup' ? 'pickup_point' : 'dropoff_point';
            const meters = withinRangeMiles * 1609.34;

            filters.push({
                [orderPoint]: {
                    _st_d_within: {
                        distance: meters,
                        from: withinRangeLocation,
                    },
                },
            });
        }

        return { _and: filters };
    }, [
        test_acc,
        takingBids,
        search,
        stateFilters,
        deliveryTypeFilters,
        firstAvailableFilter,
        maxCubes,
        totalPeople,
        withinRangeOrderPoint,
        withinRangeMiles,
        withinRangeLocation,
    ]);

    const currentTime = useMemo(() => {
        return new Date().toISOString()
    }, []);

    const { data, loading, refetch } = useQuery(LTL_LOADS, {
        variables: {
            condition,
            currentTime: currentTime,
        },
        onCompleted: (data) => {
            // Ensure that on the first load, if there is a linked order, it is selected by default.
            if (!viewLinkedOrder) {
                setSelectedLoads({});
            } else {
                if (orderIdParam) {
                    const linkedOrder = data?.orders?.find((o) => o.order_id === orderIdParam);
                    if (linkedOrder) {
                        setSelectedLoads({ [linkedOrder.order_id]: true });
                    } else {
                        setNotification({
                            severity: 'warning',
                            message: `This order is no longer available, it may have already been claimed by another carrier.`,
                        });
                    }
                    setViewLinkedOrder(false);
                }
            }
        },
        onError: (error) => {
            console.log(error);
            captureException(error);
            console.error(error);
        },
    });

    const orders = useMemo(() => {
        return data?.orders || [];
    }, [data]);

    const loads = useMemo(() => {
        return orders.filter((order) => selectedLoads[order.order_id]);
    }, [orders, selectedLoads]);

    const existingBid = useMemo(() => {
        if (loads.length === 1) {
            return loads[0].listing?.bids?.find((bid) => bid.carrier_id === user_id);
        }
        return undefined;
    }, [loads]);

    const handleSearch = (input) => {
        setSearchText(input);
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        setDebounceTimer(
            setTimeout(() => {
                setSearch(input);
            }, 300)
        );
    };

    useEffect(() => {
        hubspotControls.hide();
        return () => {
            hubspotControls.show();
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
        };
    }, []);

    const selectOrder = (order_id) => {
        setSelectedLoads((prev) => ({ [order_id]: !prev[order_id] }));
    };

    const selectOrders = (order_ids) => {
        const selected = order_ids.every((order_id) => selectedLoads[order_id]);
        setSelectedLoads((prev) => ({
            ...Object.fromEntries(order_ids.map((order_id) => [order_id, !selected])),
        }));
    };

    const reset = () => {
        setBid(null);
        setTCModalOpen(false);
        setPlaceBidModalOpen(false);
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'listings' });
        refetch();
    };

    const [placeBid] = useAction(async (body) => post(PLACE_BID, body), {
        onComplete: ({ data }) => {
            setIsLoading(false);
            if (data?.success) {
                setNotification({
                    severity: 'success',
                    message: 'Bid successfully placed!',
                });
            } else {
                setNotification({
                    severity: 'error',
                    message: data?.error || 'Error placing Bid. Please try again.',
                });
            }
            reset();
        },
        onError: (err) => {
            console.error(err);
            setIsLoading(false);
            captureException(err);
            setNotification({
                severity: 'error',
                message: 'Error placing bid. Please try again.',
            });
            reset();
        },
    });

    const [claimNow] = useAction(async (body) => post(CLAIM_NOW, body), {
        onComplete: ({ data }) => {
            // All orders claimed and payments processed successfully
            setIsLoading(false);
            if (data?.success?.length === loads.length) {
                setNotification({
                    severity: 'success',
                    message: `Successfully claimed orders! ${data.success
                        .map((order) => order.order_number)
                        .join(', ')}`,
                });
                // Some orders failed to process payment and were cancelled
                // The orders that did succeed have been claimed
            } else if (data?.success?.length > 0) {
                setNotification({
                    severity: 'warning',
                    message: `Only claimed ${data.success
                        .map((order) => order.order_number)
                        .join(', ')}. Some other orders have already been claimed or have since been cancelled.`,
                });
                // All orders failed to lock/claim
            } else {
                setIsLoading(false);
                setNotification({
                    severity: 'error',
                    message: data?.error || 'Error claiming orders. Please try again.',
                });
            }
            reset();
        },
        onError: (err) => {
            console.error(err);
            captureException(err);
            setNotification({
                severity: 'error',
                message: err?.response?.data?.error || 'Error claiming orders. Please try again.',
            });
            reset();
        },
    });

    return (
        <Grid
            container
            css={css`
                height: 100%;
                flex-wrap: nowrap;
            `}
        >
            <div css={css`
                min-width: fit-content;
            `}>
            <Grid
                item
                xs={3}
                css={css`
                    background-color: white;
                    min-width: 320px;
                    max-width: none;
                    border-right: ${border};
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                `}
            >
                <Grid
                    item
                    css={css`
                        padding: 1rem;
                        border-bottom: ${border};
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <TextField
                        value={searchText}
                        placeholder="Search ..."
                        onChange={(e) => handleSearch(e.target.value)}
                        variant="outlined"
                        color="primary"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        css={css`
                            flex-grow: 1;
                        `}
                    />
                    <Filters
                        setTakingBids={setTakingBids}
                        setDeliveryTypeFilters={setDeliveryTypeFilters}
                        setStateFilters={setStateFilters}
                        setFirstAvailableFilter={setFirstAvailableFilter}
                        setMaxCubes={setMaxCubes}
                        setTotalPeople={setTotalPeople}
                        setWithinRangeOrderPoint={setWithinRangeOrderPoint}
                        setWithinRangeMiles={setWithinRangeMiles}
                        setWithinRangeSetLocation={setWithinRangeSetLocation}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex-grow: 1;
                        overflow-y: scroll;
                        ::-webkit-scrollbar {
                            width: 10px;
                        }

                        ::-webkit-scrollbar-track {
                            border-radius: 20px;
                        }

                        ::-webkit-scrollbar-thumb {
                            background-color: #666;
                            border-radius: 20px;
                        }
                    `}
                >
                    <Grid container direction="column">
                        {loading ? (
                            <Grid item container direction="column" alignItems="center">
                                <span className="mt-4 mb-3">Fetching Orders...</span>
                                <CircularProgress size={100} />
                            </Grid>
                        ) : !orders.length ? (
                            <Grid item container direction="column" alignItems="center">
                                <span
                                    className="mt-5"
                                    style={{ textAlign: 'center', marginLeft: '2rem', marginRight: '2rem' }}
                                >
                                    No orders found for the given filter criteria
                                </span>
                            </Grid>
                        ) : (
                            orders.map((order) => (
                                <Load
                                    key={`load-list-${order.order_id}`}
                                    order={order}
                                    callbacks={{ selectOrder, setPlaceBidModalOpen }}
                                    selected={selectedLoads[order.order_id]}
                                />
                            ))
                        )}
                    </Grid>
                </Grid>
            </Grid>
            </div>
            <Grid item xs={9}>
                <LoadsMap orders={orders} selectedLoads={selectedLoads} callbacks={{ selectOrders }} />
                <ClaimLoads
                    loads={loads}
                    existingBid={existingBid}
                    callbacks={{ setPlaceBidModalOpen, setTCModalOpen, onClose: () => setSelectedLoads({}) }}
                    css={css`
                        // profile header + padd
                        top: 70px;
                    `}
                />
            </Grid>
            <Snackbar
                open={Boolean(notification?.message)}
                onClose={() => setNotification(null)}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                autoHideDuration={10000}
            >
                <Alert
                    onClose={() => setNotification(null)}
                    severity={notification?.severity}
                    elevation={6}
                    variant="filled"
                >
                    {notification?.message}
                </Alert>
            </Snackbar>
            <PlaceBidModal
                load={loads?.[0]}
                allLoads={loads}
                existingBid={existingBid}
                open={placeBidModalOpen}
                isLoading={isLoading}
                onClose={() => {
                    setPlaceBidModalOpen(false);
                    setBid(null);
                }}
                callbacks={{
                    placeBid: (bid, bidComments) => {
                        setBid(bid);
                        setBidComments(bidComments);
                        setPlaceBidModalOpen(false);
                        setTCModalOpen(true);
                    },
                }}
            />
            <OrderClaimTCModal
                loads={loads}
                open={tcModalOpen}
                bid={bid}
                isLoading={isLoading}
                onClose={() => {
                    setTCModalOpen(false);
                    setBid(null);
                    setIsLoading(false);
                }}
                callbacks={{
                    claimNow: (loads, deliveryDate, targetDeliveryDate, locationId) => {
                        setIsLoading(true);
                        claimNow({
                            carrier_id: user_id,
                            order_ids: loads.map((load) => load.order_id),
                            delivery_date: deliveryDate,
                            target_delivery_date: targetDeliveryDate,
                            planning: false,
                            locationId: locationId,
                        });
                    },
                    placeBid: (bid, locationId) => {
                        setIsLoading(true);
                        placeBid({
                            carrier_id: user_id,
                            bid,
                            comments: bidComments,
                            locationId: locationId,
                        });
                    },
                }}
            />
        </Grid>
    );
};

export default LoadBoardLTL;
