import { gql } from '@apollo/client';
import { ITEM_FIELDS } from './items';
import { WAREHOUSE_EVENT_FIELDS } from './order_wh_events';

export const INBOUND_ITEM_FIELDS = gql`
    ${ITEM_FIELDS}
    ${WAREHOUSE_EVENT_FIELDS}
    fragment InboundItemFields on manifests_inbound_items {
        mapping_id
        manifest_id
        item_id
        item {
            ...ItemFields
            order {
                carrier_id
                order_id
                order_number
                order_status
                po_number
                dropoff_name
                dropoff_address
                manufacturer
                dropoff_location_type
                pickup_location_type
                shipper_id
                wh_events(order_by: [{ created_at: asc }, { transition: { current: asc } }]) {
                    ...WarehouseEvents
                }
                itemsByOrderId {
                    ...ItemFields
                }
                order_shipper {
                    client_id
                    business_name
                }
            }
        }
    }
`;
