import { gql } from '@apollo/client';
import { COMMUNICATION_FIElDS } from './communications';

export const TRACKED_COMMUNICATION_FIELDS = gql`
    ${COMMUNICATION_FIElDS}
    fragment TrackedCommunicationFields on tracked_communications {
        tracked_communication_id
        communication_id
        client_id
        order_id
        created_at
        communication {
            ...CommunicationFields
        }
    }
`;

export const QUERY_TRACKED_COMMUNICATIONS_BY_ORDER = gql`
    ${TRACKED_COMMUNICATION_FIELDS}
    query GetTrackedCommunicationsByOrder($order_id: uuid!) {
        tracked_communications(where: { order_id: { _eq: $order_id } }) {
            ...TrackedCommunicationFields
            client {
                client_id
                business_name
            }
        }
    }
`;
