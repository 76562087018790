import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { startCase } from 'lodash';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { CheckCircleOutline, HelpOutline, HighlightOffOutlined, SyncOutlined } from '@material-ui/icons';
import { BodyCellText } from '../MyOrders/blocks';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';
import { calcOrderInvoiceTotals } from '@/utilities/calcOrderInvoiceTotals';

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const useInvoiceColumns = ({ shipping_partners, status, callbacks, invoiceType }) => {
    return useMemo(() => {
        return [
            {
                id: 'invoices-group',
                columns: [
                    {
                        Header: 'Invoice',
                        id: 'invoice_number',
                        width: 100,
                        disableSortBy: false,
                        accessor: (invoice) => invoice.invoice_number,
                    },
                    {
                        Header: 'Shipper',
                        id: 'shipper',
                        width: 250,
                        disableSortBy: false,
                        accessor: (invoice) =>
                            shipping_partners?.find((shipper) => shipper.shipper_id === invoice.partner_client_id)
                                ?.name || '--',
                    },
                    {
                        Header: 'Issued',
                        id: 'created_at',
                        width: 150,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            return format(new Date(invoice.created_at), 'MMM d, yyyy');
                        },
                    },
                    {
                        Header: 'Due',
                        id: 'due_date',
                        width: 150,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            return format(new Date(invoice.due_date), 'MMM d, yyyy');
                        },
                    },
                    {
                        Header: 'Status',
                        id: 'status',
                        width: 150,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const label = startCase(invoice.status.toLowerCase());
                            let Icon = HelpOutline;
                            let color = 'black';
                            switch (invoice.status) {
                                case 'PAID':
                                    color = colors.greens.primary;
                                    Icon = CheckCircleOutline;
                                    break;
                                case 'APPROVED':
                                    color = colors.greens.primary;
                                    Icon = CheckCircleOutline;
                                    break;
                                case 'UNPAID':
                                case 'CANCELLED':
                                    color = colors.reds.cancelled;
                                    Icon = HighlightOffOutlined;
                                    break;
                                case 'PROCESSING':
                                    color = colors.oranges.primary;
                                    Icon = SyncOutlined;
                                    break;
                                default:
                                    color = 'black';
                                    Icon = HelpOutline;
                                    break;
                            }

                            return (
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        white-space: nowrap;
                                    `}
                                >
                                    <Icon
                                        css={css`
                                            color: ${color};
                                        `}
                                    />
                                    <BodyCellText>{label}</BodyCellText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Subtotal',
                        id: 'subtotal',
                        width: 125,
                        disableSortBy: true,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const amount = (invoice.orders || []).reduce((acc, order) => {
                                const subTotalAdj = calcOrderSubtotal(order);
                                return acc + subTotalAdj;
                            }, 0);
                            return dollarFormatter.format(amount);
                        },
                    },
                    {
                        Header: 'Accessorials',
                        id: 'accessorials',
                        width: 125,
                        disableSortBy: true,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const amount = (invoice.orders || []).reduce((acc, order) => {
                                const breakdown = order.oms ? 'internalBreakdown' : 'carrierBreakdown';

                                const total = (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce(
                                    (acc, { quantity, rate }) => {
                                        return acc + quantity * rate;
                                    },
                                    0
                                );

                                return acc + total;
                            }, 0);
                            return dollarFormatter.format(amount);
                        },
                    },
                    {
                        Header: 'Total Due',
                        id: 'revenue',
                        width: 125,
                        disableSortBy: true,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const amount = (invoice.orders || []).reduce((acc, order) => {
                                const total = calcOrderInvoiceTotals(order);
                                return acc + total;
                            }, 0);
                            return dollarFormatter.format(amount);
                        },
                    },
                ],
            },
            {
                sticky: 'right',
                Header: 'Actions',
                id: 'actions-group',
                columns: [
                    {
                        Header: '',
                        id: 'action-1',
                        width: 45,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const [anchorEl, setAnchorEl] = React.useState(null);

                            const handleClick = (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setAnchorEl(event.currentTarget);
                            };

                            const handleClose = (event) => {
                                event?.stopPropagation();
                                event?.preventDefault();
                                setAnchorEl(null);
                            };

                            return (
                                <div
                                    css={css`
                                        color: #59b863;
                                        font-weight: 600;
                                        display: flex-start;
                                        justify-content: flex-start;
                                        align-items: center;
                                    `}
                                >
                                    <IconButton
                                        disabled={invoiceType === 'PAYABLE' || status !== 'UNPAID'}
                                        onClick={handleClick}
                                        css={css`
                                            font-weight: 600;
                                            padding: 4px;
                                            justify-content: center;
                                        `}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        style={{ padding: '10px', borderRadius: '20px' }}
                                    >
                                        <Button
                                            style={{ padding: '10px' }}
                                            onClick={(event) => {
                                                callbacks.paidId(invoice.invoice_id);
                                                handleClose();
                                            }}
                                        >
                                            Mark as Paid
                                        </Button>
                                    </Popover>
                                </div>
                            );
                        },
                        canSort: false,
                        span: false,
                    },
                ],
            },
        ];
    }, [invoiceType, status, shipping_partners]);
};
