import { useEffect, useMemo, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { io } from 'socket.io-client';
import routeStatusOptions from '../constants/routeStatusOptions';

const SOCKET_ENDPOINTS = {
    development: 'ws://localhost:8080',
    staging: 'wss://real-time-tracking-2jynz7s47q-uc.a.run.app',
    production: 'wss://real-time-tracking-jaedn6xdla-uc.a.run.app',
};

/**
 * Calculate the direction that the truck is facing in cartesian coordinates
 * @param {} pointA Previous Truck Location
 * @param {*} pointB Current Truck Location
 * @returns Direction in degrees
 */
const getDirection = (pointA, pointB) => {
    const x = pointB.lng - pointA.lng;
    const y = pointB.lat - pointA.lat;
    if (isNaN(x) || isNaN(y)) return 0;
    // Math.atan2(y2-y1 / x2-x1) returns radians from the x axis
    // Converting to degrees gives a number in the range (-180, 180]
    return (Math.atan2(y, x) * 180) / Math.PI;
};

const createSocket = (params, token) => {
    const socket = io(SOCKET_ENDPOINTS[process.env.REACT_APP_ENVIRONMENT || 'production'], {
        reconnectionDelayMax: 1000,
        query: params,
        auth: {
            jwt: true,
            token,
        },
        path: '/driver',
    });

    socket.on('connect', () => {
        console.log('Socket connected');
    });

    socket.on('connect_error', (err) => {
        console.error(`Socket connection error: ${err.message}`);
    });

    socket.on('disconnect', (reason) => {
        console.log(`Socket disconnected: ${reason}`);
    });

    return socket;
};

export const useDriverLocation = (driverId, routeKey, deliveryDate, routeStatus, { disabled } = {}) => {
    const [socket, setSocket] = useState();
    const [location, setLocation] = useState({ curr: {}, prev: {} });

    const onDriverLocation = (data, callback) => {
        const { lat, lng, timestamp } = data || {};
        console.debug(data);
        if (lat && lng) {
            setLocation((oldLocation) => ({
                curr: { lat, lng },
                prev: { ...oldLocation.curr },
                lastUpdated: new Date(timestamp),
            }));
            callback();
        }
    };

    const refresh = () => {
        if (socket?.connected) {
            socket.disconnect();
        }
        socket?.connect();
        setLocation({ curr: {}, prev: {} });
    };

    useEffect(() => {
        const NOW = new Date().getTime();
        const ONE_DAY = 86400000;

        if (
            disabled ||
            !driverId ||
            !routeKey ||
            !(deliveryDate && Math.abs(new Date(deliveryDate).getTime() - NOW) < ONE_DAY) ||
            routeStatus !== routeStatusOptions.INPROGRESS.value
        )
            return;

        let newSocket;

        const auth = getAuth();
        auth.currentUser.getIdToken(true).then((token) => {
            newSocket = createSocket({ driverId, routeKey }, token);
            newSocket.on('driver_location', onDriverLocation);
            setSocket(newSocket);
        });

        return () => {
            socket?.disconnect();
            socket?.off('connect');
            socket?.off('connect_error');
            socket?.off('disconnect');
            socket?.off('driver_location');
        };
    }, [driverId, routeKey, deliveryDate, routeStatus, disabled]);

    return useMemo(() => {
        const direction = getDirection(location.prev, location.curr);
        return { ...location.curr, direction, lastUpdated: location.lastUpdated, refresh };
    }, [location]);
};

export default useDriverLocation;
