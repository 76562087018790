import { Box, Button, Chip, Grid, MenuItem, Popover, TextField, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React, { useState } from 'react';
import { useClientUser } from '@/hooks';
import { Body2 } from '../MyOrders/blocks';
import { OnwardCheckbox } from '../ShipmentForm/blocks';

const volumeOptions = [
    {
        value: '0|100',
        label: '0 - 100',
    },
    {
        value: '100|200',
        label: '100 - 200',
    },
    {
        value: '200|300',
        label: '200 - 300',
    },
    {
        value: '300|400',
        label: '300 - 400',
    },
    {
        value: '400|500',
        label: '400 - 500',
    },
    {
        value: '500|9999999',
        label: '500 +',
    },
];

const distanceOptions = [
    {
        value: '0|5',
        label: '0 - 5',
    },
    {
        value: '5|10',
        label: '5 - 10',
    },
    {
        value: '10|25',
        label: '10 - 25',
    },
    {
        value: '25|50',
        label: '25 - 50',
    },
    {
        value: '50|100',
        label: '50 - 100',
    },
    {
        value: '100|9999999',
        label: '100 +',
    },
];

const FilterPopover = ({
    applyFilters,
    locations,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    onlyNew,
    setOnlyNew,
}) => {
    const [anchor, setAnchor] = useState(null);
    const [pickup, setPickup] = useState('');
    const [dropoff, setDropoff] = useState('');
    const [volume, setVolume] = useState('');
    const [distance, setDistance] = useState('');
    const { circles } = useClientUser();

    const open = !!anchor;
    const id = open ? 'myorders-popover-filter' : undefined;

    const handleClearFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setPickup('');
        setDropoff('');
        setVolume('');
        setDistance('');
        setOnlyNew(false);

        applyFilters([]);
    };

    const handleApplyFilters = () => {
        let filters = [];

        if (startDate) {
            const utcDate = new Date(startDate);
            const startDateTimestamp = utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000;
            filters.push(
                (order) => order.delivery_date && new Date(order.delivery_date).getTime() >= startDateTimestamp
            );
        }

        if (endDate) {
            const utcDate = new Date(endDate);
            const endDateTimestamp = utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000;
            filters.push((order) => order.delivery_date && new Date(order.delivery_date).getTime() <= endDateTimestamp);
        }

        if (pickup) {
            const [city, state, zip] = pickup.split('|');
            filters.push((order) => {
                return order.pickup_city === city && order.pickup_state === state && order.pickup_zip === zip;
            });
        }

        if (dropoff) {
            const [city, state, zip] = dropoff.split('|');
            filters.push((order) => {
                return order.dropoff_city === city && order.dropoff_state === state && order.dropoff_zip === zip;
            });
        }

        if (volume) {
            const [minVolume, maxVolume] = volume.split('|');
            filters.push((order) => {
                let orderVolume;
                if (order.order_type === 'return' && order.returns_total_cubes) {
                    orderVolume = parseFloat(order.returns_total_cubes).toFixed(2);
                } else {
                    orderVolume = parseInt(
                        Array.isArray(order.items_total_cubes)
                            ? order.items_total_cubes[1]
                            : order.items_total_cubes || 0
                    );
                }
                return orderVolume >= minVolume && orderVolume <= maxVolume;
            });
        }

        if (distance) {
            const [minDistance, maxDistance] = distance.split('|');
            filters.push((order) => {
                const orderDistance = parseInt(order.miles) || 0;
                return orderDistance >= minDistance && orderDistance <= maxDistance;
            });
        }

        applyFilters(filters);
    };

    const PopoverSelect = ({ label, value, onChange, options = [] }) => {
        return (
            <>
                <Grid item xs={12}>
                    <Body2>{label}</Body2>
                </Grid>
                <Grid item xs={12}>
                    <TextField select style={{ width: '300px' }} variant="outlined" value={value} onChange={onChange}>
                        {options.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </>
        );
    };

    return (
        <>
            <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={(e) => setAnchor(e.currentTarget)}
            >
                <FilterList style={{ color: 'white' }} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box className="bg-white p-3" style={{ border: 'solid 1px #4C4C4C', borderRadius: '4px' }}>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <OnwardCheckbox
                            label={'Only New Alerts'}
                            checked={onlyNew}
                            onChange={() => setOnlyNew(!onlyNew)}
                        />
                        <Grid item xs={12}>
                            <Body2>Order Creation Date</Body2>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Body2 style={{ height: 'fit-content' }}>-</Body2>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Pick up"
                                value={pickup}
                                onChange={(e) => setPickup(e.target.value)}
                                options={locations || []}
                            />
                        </Grid>
                        <Grid item>
                            <PopoverSelect
                                label="Drop off"
                                value={dropoff}
                                onChange={(e) => setDropoff(e.target.value)}
                                options={locations || []}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Cubic Feet"
                                value={volume}
                                onChange={(e) => setVolume(e.target.value)}
                                options={volumeOptions}
                            />
                        </Grid>
                        <Grid item>
                            <PopoverSelect
                                label="Miles"
                                value={distance}
                                onChange={(e) => setDistance(e.target.value)}
                                options={distanceOptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                            <Button onClick={handleClearFilters}>Clear</Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" onClick={handleApplyFilters}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </>
    );
};

export default FilterPopover;
