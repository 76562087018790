import React, { useState } from 'react';
import Notification from '../../Notification';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate, Link } from 'react-router-dom';
import { PrimaryButton } from '@/styles/blocks';
import AssignDriverModal from '../../OrderDetailsPage/modals/AssignDriverModal';

const NotificationMetadata = styled.span`
    margin-top: 10px;
    font-family: Montserrat, Roboto, Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0571429px;
    margin-bottom: 0px;
    color: #2b2b2b;
`;

export default function FinalReturnNotification({ notification, handleCloseNotification }) {
    const [assignModalOpen, setAssignModalOpen] = useState(true);

    return (
        <Notification notification={notification}>
            <NotificationMetadata
                css={css`
                    margin-top: 0px;
                    font-weight: 700;
                `}
            >
                Return marked for next day
            </NotificationMetadata>
            <NotificationMetadata>Route: {notification?.notification_data?.route_number}</NotificationMetadata>
            <NotificationMetadata>
                <Link style={{ color: '#59b863' }} to={`/routedetails/${notification?.notification_data?.route_id}`}>
                    See Details
                </Link>{' '}
            </NotificationMetadata>
            <NotificationMetadata
                css={css`
                    color: grey;
                `}
            >
                {format(new Date(notification.created_at), 'MMM dd, yyyy @ hh:mm a')}
            </NotificationMetadata>
            <NotificationMetadata>
                <PrimaryButton
                    onClick={() => setAssignModalOpen(true)}
                    css={css`
                        width: 100%;
                    `}
                >
                    Assign Driver
                </PrimaryButton>
            </NotificationMetadata>
            <AssignDriverModal
                setOpenModal={setAssignModalOpen}
                isOpen={assignModalOpen}
                onClose={() => setAssignModalOpen(false)}
                route={notification?.notification_data?.route_id}
                isFinalReturn={true}
            />
        </Notification>
    );
}
