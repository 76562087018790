import React, { useState, useEffect } from 'react';

import { Grid, Typography, Button } from '@material-ui/core';
import { css } from '@emotion/react';

import orderStatusStrings from '@/constants/orderStatusStrings';
import { OnwardCard } from './Card';
import {
    ResponsiveSidebarDialog,
    ModalTitle,
    ModalContent,
    StickyModalActions,
    PrimaryButton,
    SecondaryButton,
} from '../../styles/blocks';
import { TextField } from '../ShipmentForm/blocks';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDER_NOTES } from './mutations';
import { useNotePermissions, useClientUser } from '@/hooks';

function Notes(props) {
    const { order, editable } = props;
    const { user_id } = useClientUser();
    const notePermissions = useNotePermissions(order?.routes?.[0]?.route, user_id);
    const [modalOpen, setModalOpen] = useState(false);
    const [shipperNotes, setShipperNotes] = useState('');
    const [carrierNotes, setCarrierNotes] = useState('');

    useEffect(() => {
        setShipperNotes(order?.shipper_notes);
        setCarrierNotes(order?.carrier_notes);
    }, [order]);

    const [updateOrderNotes] = useMutation(UPDATE_ORDER_NOTES);

    const handleSave = () => {
        updateOrderNotes({
            variables: {
                order_id: order.order_id,
                shipper_notes: shipperNotes,
                carrier_notes: carrierNotes,
            },
            onError: (err) => {
                console.log(err);
            },
        });
        setModalOpen(false);
    };

    return (
        <>
            <Grid
                container
                className="overflow-auto h-100"
                css={css`
                    padding-bottom: 10px;
                `}
            >
                <Grid item sm={1}></Grid>
                <Grid container sm={10}>
                    <Grid item sm={12} className="d-flex justify-content-between align-items-center mt-4">
                        <Typography variant="h1">{order.order_number}</Typography>
                        <Typography variant="subtitle2">{orderStatusStrings[order.order_status]}</Typography>
                    </Grid>

                    {notePermissions.shipper_notes.view && (
                        <>
                            <Typography
                                variant="h6"
                                color="secondary"
                                css={css`
                                    margin-top: 55px;
                                    margin-bottom: 5px;
                                `}
                            >
                                Shipper Notes
                            </Typography>
                            <OnwardCard
                                css={css`
                                    margin-bottom: 30px;
                                `}
                            >
                                {order?.shipper_notes || 'None'}
                            </OnwardCard>
                        </>
                    )}

                    {notePermissions.carrier_notes.view && (
                        <>
                            <Typography
                                variant="h6"
                                color="secondary"
                                css={css`
                                    margin-bottom: 5px;
                                `}
                            >
                                Carrier Notes
                            </Typography>
                            <OnwardCard
                                css={css`
                                    margin-bottom: 30px;
                                `}
                            >
                                {order?.carrier_notes || 'None'}
                            </OnwardCard>
                        </>
                    )}

                    {editable && (
                        <Grid
                            item
                            container
                            css={css`
                                justify-content: flex-end;
                            `}
                        >
                            <Button
                                onClick={() => setModalOpen(true)}
                                variant="contained"
                                color="primary"
                                css={css`
                                    width: 115px;
                                `}
                            >
                                <Typography variant="body1">Edit</Typography>
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid item sm={1}></Grid>
            </Grid>

            {modalOpen && (
                <ResponsiveSidebarDialog open={modalOpen} onClose={() => setModalOpen(false)}>
                    <ModalTitle title="Notes" onClose={() => setModalOpen(false)} />
                    <ModalContent width={700}>
                        <Grid className="mb-3" alignItems="center" container spacing={2}>
                            <Typography
                                variant="h6"
                                color="secondary"
                                css={css`
                                    margin-bottom: 5px;
                                `}
                            >
                                {order.order_number}
                            </Typography>
                            <Grid container direction="column">
                                {notePermissions.shipper_notes.view && (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        css={css`
                                            margin-bottom: 20px;
                                        `}
                                    >
                                        <TextField
                                            label={
                                                notePermissions.shipper_notes.edit
                                                    ? `Shipper Notes`
                                                    : `Notes from Shipper`
                                            }
                                            variant="outlined"
                                            name="desc"
                                            fullWidth
                                            value={shipperNotes || null}
                                            onChange={(e) => setShipperNotes(e.target.value)}
                                            multiline
                                            rows={5}
                                            disabled={!notePermissions.shipper_notes.edit}
                                            css={css`
                                                textarea {
                                                    font-weight: 400;
                                                }
                                            `}
                                        />
                                    </Grid>
                                )}
                                {notePermissions.carrier_notes.view && (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        css={css`
                                            margin-bottom: 20px;
                                        `}
                                    >
                                        <TextField
                                            label="Carrier Notes"
                                            variant="outlined"
                                            name="desc"
                                            fullWidth
                                            value={carrierNotes || null}
                                            onChange={(e) => setCarrierNotes(e.target.value)}
                                            multiline
                                            rows={5}
                                            disabled={!notePermissions.carrier_notes.edit}
                                            css={css`
                                                textarea {
                                                    font-weight: 400;
                                                }
                                            `}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </ModalContent>
                    <StickyModalActions>
                        <SecondaryButton onClick={() => setModalOpen(false)}>Cancel</SecondaryButton>
                        <PrimaryButton onClick={handleSave}>Save</PrimaryButton>
                    </StickyModalActions>
                </ResponsiveSidebarDialog>
            )}
        </>
    );
}

export default Notes;
