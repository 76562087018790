import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Body1, Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, SecondaryButton } from '../blocks';
import { css } from '@emotion/react';
import { useInvalidEventStates } from '../hooks';

const OrdersAwaitingCDModal = ({ open, onClose, route }) => {
    const ordersAwaitingCD = useInvalidEventStates(route);

    if (ordersAwaitingCD) {
        return (
            <NavResponsiveModal open={open} onClose={() => onClose()}>
                <ModalTitle>{`Incomplete Cross-docking`}</ModalTitle>
                <ModalContent>
                    <GridItemRow>
                        <Body2>
                            The following orders are cross-docked and have not yet been marked as picked up. You can
                            still modify and submit this route on the planning page as usual, but the pickup leg of
                            these orders must be completed before this route can be started by a driver. Current
                            location of the respective pickup legs can be found below:
                        </Body2>
                    </GridItemRow>
                    <ul>
                        {Object.keys(ordersAwaitingCD).map((orderNum) => (
                            <li key={orderNum}>
                                <Body1
                                    css={css`
                                        text-align: center;
                                    `}
                                >
                                    {orderNum}: {ordersAwaitingCD[orderNum]}
                                </Body1>
                            </li>
                        ))}
                    </ul>
                </ModalContent>
                <ModalActions>
                    <SecondaryButton onClick={() => onClose()}>Close</SecondaryButton>
                </ModalActions>
            </NavResponsiveModal>
        );
    }
};

export default OrdersAwaitingCDModal;
