import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WarningButton } from '@/styles/blocks';

const useStyles = makeStyles({
    dialogContent: {
        width: 450,
    },
});

export default function BackToUnassignedModal(props) {
    const { isOpen, onClose, handleSubmit } = props;
    const classes = useStyles();

    return (
        <>
            <Dialog
                className="carrier-review-modal"
                open={isOpen}
                onClose={onClose}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                <DialogContent className={classes.dialogContent}>
                    <Typography className="mb-2">Are you sure you want to cancel this order?</Typography>
                    <Typography className="mb-2">Order will be sent back to unassigned.</Typography>
                </DialogContent>
                <DialogActions
                    className="customer-track-rating-modal d-flex justify-content-between"
                    style={{ padding: '24px' }}
                >
                    <Button variant="outlined" onClick={onClose}>
                        Back
                    </Button>

                    <WarningButton onClick={handleSubmit}>Cancel Order</WarningButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
