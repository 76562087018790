import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { LISTING_FIELDS } from '@/graphql/queries/listings';
import { BID_FIELDS } from '@/graphql/queries/bids';
import { COUNTER_OFFER_FIELDS } from '@/graphql/queries/counterOffers';
import { BID_COMMENT_FIELDS } from '@/graphql/mutations/bid_comments';

export const QUERY_LISTINGS_AUCTIONED_ORDERS_TABLE = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${LISTING_FIELDS}
    ${BID_FIELDS}
    ${COUNTER_OFFER_FIELDS}
    ${BID_COMMENT_FIELDS}

    query listings($limit: Int!, $offset: Int!, $where: listings_bool_exp!) {
        listings(limit: $limit, offset: $offset, where: $where) {
            ...ListingFields
            bids {
                ...BidFields
                counter_offers {
                    ...CounterOfferFields
                }
                bid_comments {
                    ...BidCommentFields
                }
            }
            order {
                ...OrderFields
            }
        }
    }
`;
