import React, { useContext } from 'react';

import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';

import { WarehousingContext } from './context';

const WarehouseViewer = () => {
    const { tooltipState, hideTooltips, hideSpecificTooltip, view, setView } = useContext(WarehousingContext);

    return (
        <>
            <Grid
                id="smplr-container"
                css={css`
                    height: 100%;
                    width: 100%;
                    ${view === 'view' && `
                        div > div {
                            cursor: grab !important;
                        }
                    `}
                `}
            ></Grid>

            {!hideTooltips &&
                tooltipState?.map((d) => {
                    if (hideSpecificTooltip === d?.id) return null;
                    return (
                        <div
                            key={`tooltip-${d.id}`}
                            style={{
                                transform: 'translate(-50%, -50%)',
                                position: 'absolute',
                                top: d.screenY,
                                left: d.screenX,
                                color: 'black',
                                fontWeight: 600,
                                padding: '0.2em 0.5em',
                                borderRadius: '4px',
                                pointerEvents: 'none',
                            }}
                        >
                            {d.text}
                        </div>
                    );
                })}
        </>
    );
};

export default WarehouseViewer;
