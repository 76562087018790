import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDER_BUDGET } from '../mutations';

const useStyles = makeStyles({
    modal: {
        width: '400px',
        height: '225px',
    },
    modalTitle: {
        color: '#0C0B1D',
        fontWeight: '800',
        fontSize: '28px',
    },
    dialogContent: {
        width: 640,
        height: 400,
    },
    modalLabel: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        color: '#2B2B2B',
    },
    budgetInput: {
        width: '130px',
        'text-align-last': 'right',
    },
});

export default function UpdateBudgetModal(props) {
    const { isOpen, onClose, order } = props;
    const classes = useStyles();

    const [budget, setBudget] = useState(order.customerBudget);
    const [errorMessage, setErrorMessage] = useState('');

    const [updateOrderBudget] = useMutation(UPDATE_ORDER_BUDGET);

    const handleChange = (e) => {
        setBudget(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            await updateOrderBudget({
                variables: {
                    order_id: order.order_id,
                    customer_budget: budget,
                },
            });
            onClose();
        } catch (e) {
            console.log(e);
            setErrorMessage('There was a problem updating the budget.');
        }
    };

    return (
        <>
            <Dialog
                className="carrier-review-modal"
                open={isOpen}
                onClose={onClose}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                <DialogTitle
                    style={{
                        margin: 0,
                        padding: '16px 24px 0 24px',
                    }}
                >
                    <Typography variant="h6" className={classes.modalTitle}>
                        Budget
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        className={`budget-input ${classes.budgetInput}`}
                        variant="outlined"
                        type="number"
                        value={budget}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        sx={{ width: 130 }}
                    />

                    {errorMessage && (
                        <Typography className={`${classes.modalLabel} text-danger`}>{errorMessage}</Typography>
                    )}
                </DialogContent>

                <DialogActions
                    className="customer-track-rating-modal d-flex justify-content-between"
                    style={{ padding: '24px' }}
                >
                    <Button variant="outlined" onClick={onClose} style={{ color: 'black' }}>
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#59B863',
                            color: 'white',
                            marginLeft: '55px',
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
