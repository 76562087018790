import { gql } from '@apollo/client';

export const CARRIER_INVOICE_FIELDS = gql`
    fragment CarrierInvoiceFields on carrier_invoices {
        carrier_invoice_id
        client_id
        created_at
        description
        due_date
        flagged
        invoice_number
        invoice_comments
        last_modified_at
        partner_client_id
        pay_period_end
        pay_period_start
        status
        type
        week_number
    }
`;
