import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { css } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { colors } from '@/styles';

import { Body2 } from './blocks';

const PdfPreview = ({ pdf, page, callbacks }) => {
    const [ref, setPdf] = useState();

    return (
        <Grid
            direction="column"
            css={css`
                flex: 1;
                flex-basis: 0;
                background: ${colors.white.primary};
            `}
        >
            <Grid
                direction="row"
                container
                css={css`
                    padding: 24px 32px;
                `}
            >
                <Grid
                    direction="column"
                    container
                    css={css`
                        flex: 0;
                        margin-right: 12px;
                    `}
                >
                    <IconButton
                        disabled={!ref || page === 1}
                        css={css`
                            border-radius: 5px;
                            color: white;
                            background: ${!ref || page === 1 ? colors.greys.disabled : colors.greens.primary};
                        `}
                        onClick={() => {
                            callbacks.setPdf((prev) => ({ ...prev, page: prev.page - 1 }));
                        }}
                    >
                        <NavigateBeforeIcon />
                    </IconButton>
                </Grid>
                <Grid
                    direction="column"
                    container
                    css={css`
                        flex: 0;
                        margin-right: 12px;
                    `}
                >
                    <IconButton
                        disabled={!ref || ref.numPages === page}
                        css={css`
                            border-radius: 5px;
                            color: white;
                            background: ${!ref || ref.numPages === page
                                ? colors.greys.disabled
                                : colors.greens.primary};
                        `}
                        onClick={() => {
                            callbacks.setPdf((prev) => ({ ...prev, page: prev.page + 1 }));
                        }}
                    >
                        <NavigateNextIcon />
                    </IconButton>
                </Grid>
                <Grid
                    direction="column"
                    container
                    css={css`
                        flex: 0;
                        justify-content: center;
                    `}
                >
                    <Body2
                        css={css`
                            white-space: nowrap;
                        `}
                    >{`Page ${page} of ${ref?.numPages ? ref.numPages : '--'}`}</Body2>
                </Grid>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    justify-content: center;
                `}
            >
                <Document
                    file={pdf}
                    onLoadSuccess={(pdf) => {
                        setPdf(pdf);
                    }}
                >
                    <Page
                        pageNumber={page}
                        width={500}
                        css={css`
                            margin: auto;
                        `}
                    />
                </Document>
            </Grid>
        </Grid>
    );
};

export default PdfPreview;
