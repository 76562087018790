import { gql } from '@apollo/client';

export const RATE_FIELDS = gql`
    fragment RateFields on rates {
        max
        min
        rate
        tariff_id
        type
        min2
        max2
        subregion_id
    }
`;
