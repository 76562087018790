import React, { useState } from 'react';
import { Grid, TextField, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { css } from '@emotion/react';
import { colors } from '@/styles';

import { Body1 } from './blocks';

const ZipListItem = ({ zip, callbacks }) => {
    return (
        <Grid
            container
            direction="row"
            css={css`
                padding: 15px 20px;
                border-bottom: 1px solid ${colors.greys[4]};
            `}
            onMouseEnter={() => {
                callbacks.toggleZipHover(zip.zip);
            }}
            onMouseLeave={() => {
                callbacks.toggleZipHover(zip.zip);
            }}
        >
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    justify-content: center;
                    margin-right: 12px;
                `}
            >
                <Body1>{zip.zip}</Body1>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                `}
            >
                <IconButton
                    css={css`
                        padding: 8px;
                    `}
                    onClick={() => callbacks.removeMapping(zip)}
                >
                    <CloseIcon
                        css={css`
                            font-size: 14px;
                        `}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default ZipListItem;
