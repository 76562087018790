import React from 'react';
import { css } from '@emotion/react';
import { Dialog } from '@material-ui/core';
import { FormContainer } from '@/components/Customer/blocks';
import { Grid } from '@material-ui/core';

const UpgradeDialog = ({ children, open, onClose }) => {
    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <FormContainer>
                <Grid container direction="column" css={css`
                    margin-top: 40px;
                `}>
                    {children}
                </Grid>
            </FormContainer>
        </Dialog>
    );
};

export default UpgradeDialog;
