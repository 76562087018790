import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        fontFamily: 'Roboto, sans-serif',
    },
    errorText: {
        fontFamily: 'Montserrat',
        fontWeight: '800',
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'center',
        marginBottom: '50px',
    },
    errorDesc: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#4C4C4C',
    },
};

export const ErrorFallback = ({ error }) => {
    return (
        <div>
            <div style={styles.container}>
                <div>
                    <div style={styles.errorText}>Oops, something went wrong. Try refreshing the page.</div>
                    <div style={styles.errorDesc}>Contact support@onwarddelivery.com if you need additional assistance.</div>
                    <div className="errorBoundaryImg"></div>
                </div>
            </div>
        </div>
    );
};

const withErrorBoundary = (Component) => (props) =>
    (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <Component {...props} />
        </Sentry.ErrorBoundary>
    );

export default withErrorBoundary;
