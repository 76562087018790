import { Box, Card, CardContent, Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { GreySubheader, GreyBody, OnwardGreenButton } from '@/styles/blocks';
import csvDownload from 'json-to-csv-export';
import { FREIGHT_TYPES } from '../constants/freightTypes';
import { Context } from '../store';

const transpose = (matrix) => {
    const maxl = matrix.reduce((acc, col) => Math.max(acc, col?.length || 0), 0);
    return Array(maxl)
        .fill(0)
        .map((_, column) => matrix.map((row) => row[column] || ''));
};

export default function TemplateCard() {
    const { state } = useContext(Context);

    const downloadTemplate = (freightType) => {
        switch (freightType) {
            case 'household':
                csvDownload({
                    data: [],
                    filename: 'household_freight_template',
                    headers: Object.values(state.csvOptions).map((option) => option.display),
                    delimiter: ',',
                });
                break;
            default:
                console.log('no template yet');
                break;
        }
    };

    const downloadAcceptableValues = (freightType) => {
        switch (freightType) {
            case 'household':
                csvDownload({
                    data: transpose(Object.values(state.csvOptions).map((option) => option.acceptableValues || [])),
                    filename: 'household_acceptable_values',
                    headers: Object.entries(state.csvOptions).map(([_, option]) => option.display),
                    delimiter: ',',
                });
                break;
            default:
                console.log('no template yet');
                break;
        }
    };

    return (
        <Grid container>
            <Grid
                item
                xs={6}
                css={css`
                    padding-right: 1rem;
                `}
            >
                <Card
                    css={css`
                        height: 100%;
                    `}
                >
                    <CardContent
                        css={css`
                            height: 100%;
                        `}
                    >
                        <Grid
                            container
                            direction="column"
                            css={css`
                                height: 100%;
                            `}
                        >
                            <GreySubheader>Download our template</GreySubheader>
                            <GreyBody>
                                Please use our templates to help you combine all your order data or understand what
                                headers are required.
                            </GreyBody>
                            <Grid
                                item
                                container
                                direction="column"
                                css={css`
                                    flex: 1;
                                    justify-content: flex-end;
                                `}
                            >
                                {FREIGHT_TYPES.map(
                                    (freight) =>
                                        !freight.hidden && (
                                            <Grid
                                                item
                                                css={css`
                                                    margin-top: 1rem;
                                                `}
                                            >
                                                <OnwardGreenButton
                                                    disabled={freight.value !== 'household'}
                                                    fullWidth
                                                    onClick={() => downloadTemplate('household')}
                                                >
                                                    {freight.label}
                                                </OnwardGreenButton>
                                            </Grid>
                                        )
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                item
                xs={6}
                css={css`
                    padding-left: 1rem;
                `}
            >
                <Card
                    css={css`
                        height: 100%;
                    `}
                >
                    <CardContent
                        css={css`
                            height: 100%;
                        `}
                    >
                        <Grid
                            container
                            direction="column"
                            css={css`
                                height: 100%;
                            `}
                        >
                            <GreySubheader>Download acceptable values</GreySubheader>
                            <GreyBody>
                                Please use this list of acceptable values to help fill out spreadsheets correctly.
                            </GreyBody>
                            <Grid
                                item
                                container
                                direction="column"
                                css={css`
                                    flex: 1;
                                    justify-content: flex-end;
                                `}
                            >
                                {FREIGHT_TYPES.map(
                                    (freight) =>
                                        !freight.hidden && (
                                            <Grid
                                                item
                                                css={css`
                                                    margin-top: 1rem;
                                                `}
                                            >
                                                <OnwardGreenButton
                                                    disabled={freight.value !== 'household'}
                                                    fullWidth
                                                    onClick={() => downloadAcceptableValues('household')}
                                                >
                                                    {freight.label}
                                                </OnwardGreenButton>
                                            </Grid>
                                        )
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
