import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import SingleOrderItem from './SingleOrderItem';
import Haulaway from './Haulaway';

import { OnwardCard } from '../Card';
import orderStatusStrings from '@/constants/orderStatusStrings';
import './style.css';

function OrderItems(props) {
    const order = props.order;

    const deliveries = order?.itemsByOrderId?.filter((i) => !i.is_return) || [];
    const deliveryCount = deliveries.reduce((acc, o) => acc + parseInt(o.quantity), 0);
    const returns = order?.itemsByOrderId?.filter((i) => i.is_return) || [];
    const returnCount = returns.reduce((acc, o) => acc + parseInt(o.quantity), 0);

    return (
        <Grid container className="overflow-auto h-100">
            <Grid item sm={1}></Grid>
            <Grid container sm={10}>
                <Grid item sm={12} className="d-flex justify-content-between align-items-center mt-4">
                    <Typography variant="h1">{order.order_number}</Typography>
                    <Typography variant="subtitle2">{orderStatusStrings[order.order_status]}</Typography>
                </Grid>

                <>
                    <Grid item sm={12} className="d-flex align-items-center mt-5">
                        <Typography variant="h6" className="mt-3" color="secondary">
                            Items
                        </Typography>
                        <Typography variant="h6" className="item-qty-text mt-3">
                            {deliveryCount}
                        </Typography>
                    </Grid>

                    <OnwardCard>
                        {deliveries.length &&
                            deliveries?.map((_item) => (
                                <SingleOrderItem
                                    item={_item}
                                    order={order}
                                    setExceptionParams={props.setExceptionParams}
                                    setResolveExceptionParams={props.setResolveExceptionParams}
                                />
                            ))}
                    </OnwardCard>

                    {returns.length ? (
                        <>
                            <Grid item sm={12} className="d-flex align-items-center mt-5">
                                <Typography variant="h6" className="mt-3" color="secondary">
                                    Return Items
                                </Typography>
                                <Typography variant="h6" className="item-qty-text mt-3">
                                    {returnCount}
                                </Typography>
                            </Grid>

                            <OnwardCard>
                                {returns.length &&
                                    returns?.map((_item) => (
                                        <SingleOrderItem
                                            item={_item}
                                            order={order}
                                            setExceptionParams={props.setExceptionParams}
                                            setResolveExceptionParams={props.setResolveExceptionParams}
                                        />
                                    ))}
                            </OnwardCard>
                        </>
                    ) : null}

                    <Haulaway order={order} />
                </>
            </Grid>
            <Grid item sm={1}></Grid>
        </Grid>
    );
}

export default OrderItems;
