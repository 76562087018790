import React, { useState, useMemo, useCallback } from 'react';
import hash from 'object-hash';
import { CUSTOMER_FORM_PROGRESSION, ITEM_TYPE_STAGE_MAPPING, FORM_STATUS, DEFAULT_ORDER, STAGES } from './constants';
import { postUnauth } from '@/utilities/onwardClient';
import { CUSTOMER_SHIPMENT } from '@/constants/apiRoutes';
import * as Sentry from '@sentry/react';

export const useSignupCallbacks = (state, callbacks) => {
    const reset = useCallback(() => {
        callbacks.setStatus(FORM_STATUS.IN_PROGRESS);
        callbacks.setSections([]);
        callbacks.setProgression({
            current: 0,
            type: null,
            stages: [],
        });
    }, []);

    const advance = useCallback(
        (section) => {
            const stage = state.progression.stages[state.progression.current];
            const nextStage = state.progression.stages[state.progression.current + 1];

            switch (stage) {
                case 1:
                    callbacks.logEvent(state.analytics, 'carrier_signup_page_one_info_entered', section);
                    break;
            }

            if (nextStage) {
                callbacks.setSections((prev) => {
                    const clone = [...prev];
                    clone[state.progression.current] = section;

                    return clone;
                });
                callbacks.setProgression((prev) => {
                    return { ...prev, current: prev.current + 1 };
                });
            } else {
                callbacks.setIsLoading(true);
                const payload = { ...state.client, ...section };
                callbacks.logEvent(state.analytics, 'carrier_signup_clicked_sign_up', {
                    username: payload.name,
                    email: payload.email,
                    business: payload.business_name,
                    phone: payload.business_phone,
                });
                callbacks.createClient(payload);
            }
        },
        [state.progression, state.client]
    );

    const revert = useCallback(() => {
        callbacks.setSections((prev) => {
            const clone = [...prev];
            return clone.slice(0, state.progression.current);
        });
        callbacks.setProgression((prev) => {
            if (prev.current > 0) {
                return { ...prev, current: prev.current - 1 };
            }

            return {
                current: 0,
                type: null,
            };
        });
    }, [state, state.progression]);

    const validateEmail = useCallback((email) => {
        return callbacks.checkEmail(email).then((result) => {
            return result?.data?.valid;
        });
    }, []);

    return {
        advance,
        revert,
        reset,
        validateEmail,
    };
};
