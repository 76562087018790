import React, { useState, useMemo, useCallback, useContext } from 'react';
import { css } from '@emotion/react';
import { Grid, TextField, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { SecondaryButton } from '@/styles/blocks';
import { colors } from '@/styles';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { enrich } from '@/components/ShipmentForm/utilities/processOrders';
import { validatePhoneNumber } from '@/utilities/validatePhoneNumber';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import { LocationType, PickupType } from '@/components/ShipmentForm/modals/InputFields';

import Footer from './Footer';
import ProgressBar from './ProgressBar';

import {
    local,
    FlexRow,
    FlexColumn,
    DefaultResponsiveTextField,
    Header1,
    Header2,
    SubHeader1,
    PrimaryButton,
} from '../blocks';
import { SUPPORT_PHONE_NUMBER } from '../constants';

const REQUIRED_FIELDS = [
    'pickup_name',
    'pickup_phone',
    'pickup_street_address',
    'pickup_state',
    'pickup_city',
    'pickup_zip',
    'pickup_lat',
    'pickup_lng',
    'pickup_address',
    'pickup_full_address',
    'pickup_location',
    'pickup_location_type',
];

function PickupResidential({ isDesktop, callbacks, state }) {
    const [section, setSection] = useState(state.sections[state.progression.current] || {});
    const [isDirty, setDirty] = useState({});

    const isEmpty = useCallback(
        (field) => {
            const invalid = REQUIRED_FIELDS.includes(field) && (!section[field] || section[field].length === 0);
            switch (field) {
                case 'pickup_phone':
                    return invalid || !validatePhoneNumber(section[field]);
                default:
                    return invalid;
            }
        },
        [section]
    );

    const hasError = useMemo(() => {
        return REQUIRED_FIELDS.some((field) => isEmpty(field));
    }, [isEmpty]);

    return (
        <Grid
            container
            direction="column"
            css={css`
                color: ${local.greys[3]};
            `}
        >
            <ProgressBar step={state.progression.current + 1} maxSteps={7} />
            <Grid
                container
                css={css`
                    margin-bottom: 28px;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid item direction="column">
                    <Header1
                        css={css`
                            color: ${local.greys[4]};
                        `}
                    >
                        Pickup Information
                    </Header1>
                </Grid>
                <Grid item direction="column">
                    <IconButton
                        css={css`
                            padding: 4px;
                        `}
                        onClick={callbacks.showInfo}
                    >
                        <HelpIcon
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        />
                    </IconButton>
                </Grid>
            </Grid>

            <FlexRow>
                {[
                    { display: 'Pickup Contact Name', value: 'pickup_name' },
                    { display: 'Email', value: 'pickup_email' },
                ].map(({ display, value }) => (
                    <DefaultResponsiveTextField
                        key={value}
                        display={display}
                        value={value}
                        state={section}
                        isDirty={isDirty}
                        callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                    />
                ))}
                <FlexColumn
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <TextField
                        variant="outlined"
                        label="Phone"
                        InputProps={{
                            inputComponent: PhoneNumberInput,
                        }}
                        InputLabelProps={{ shrink: !!section.pickup_phone }}
                        value={section.pickup_phone ? toNational(section.pickup_phone) : ''}
                        onBlur={(e) => {
                            setDirty((prev) => ({ ...prev, pickup_phone: true }));
                        }}
                        onChange={(e) => {
                            setSection((prev) => ({ ...prev, pickup_phone: toE164(e.target.value) }));
                        }}
                        error={isEmpty('pickup_phone') && isDirty.pickup_phone}
                    />
                </FlexColumn>
                <FlexColumn
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    {['pickup_address', 'pickup_street_address', 'pickup_city', 'pickup_state', 'pickup_zip'].some(
                        (attr) => isEmpty(attr) && isDirty[attr]
                    ) ? (
                        <Grid
                            item
                            css={css`
                                margin-bottom: 20px;
                            `}
                        >
                            <SubHeader1
                                css={css`
                                    color: #d23e3e;
                                `}
                            >
                                {`Address not found. For help please contact us at ${toNational(
                                    SUPPORT_PHONE_NUMBER
                                )}.`}
                            </SubHeader1>
                        </Grid>
                    ) : null}
                    <Grid item>
                        <AddressAutocomplete
                            state={{
                                street: section.pickup_street_address,
                                city: section.pickup_city,
                                state: section.pickup_state,
                                zip: section.pickup_zip,
                            }}
                            onBlur={(e) => {
                                setDirty((prev) => ({
                                    ...prev,
                                    ...Object.fromEntries(
                                        [
                                            'pickup_address',
                                            'pickup_street_address',
                                            'pickup_city',
                                            'pickup_state',
                                            'pickup_zip',
                                        ].map((field) => [field, true])
                                    ),
                                }));
                            }}
                            handleAddressUpdate={async (value) => {
                                const [{ geocodePartialMatch, geocodeFailed, ...enriched }] = await enrich(
                                    { pickup_address: value },
                                    {},
                                    { pickup: true }
                                );
                                if (geocodePartialMatch || geocodeFailed) {
                                    setSection((prev) => ({
                                        ...prev,
                                        pickup_address: value,
                                        pickup_street_address: null,
                                        pickup_city: null,
                                        pickup_zip: null,
                                        pickup_state: null,
                                    }));
                                } else {
                                    setSection((prev) => ({ ...prev, ...enriched }));
                                }
                            }}
                            error={[
                                'pickup_address',
                                'pickup_street_address',
                                'pickup_city',
                                'pickup_state',
                                'pickup_zip',
                            ].some((attr) => isEmpty(attr) && isDirty[attr])}
                        />
                    </Grid>
                </FlexColumn>
                {[
                    { display: 'Unit/Suite/Lot', value: 'pickup_unit' },
                    { display: 'City', value: 'pickup_city', disabled: true },
                    { display: 'State', value: 'pickup_state', disabled: true },
                    { display: 'Zip', value: 'pickup_zip', disabled: true },
                ].map(({ display, value, disabled }) => (
                    <DefaultResponsiveTextField
                        disabled={!!disabled}
                        key={value}
                        display={display}
                        value={value}
                        state={section}
                        isDirty={isDirty}
                        callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                    />
                ))}
                <FlexColumn
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <LocationType
                        value={section.pickup_location}
                        onBlur={(e) => {
                            setDirty((prev) => ({ ...prev, pickup_location: true }));
                        }}
                        onChange={(e) =>
                            setSection((prev) => ({
                                ...prev,
                                pickup_location: e.target.value,
                            }))
                        }
                        error={isEmpty('pickup_location') && isDirty.pickup_location}
                    />
                </FlexColumn>
                <FlexColumn
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <PickupType
                        disabled={isEmpty('pickup_location')}
                        value={section.pickup_location_type}
                        locationType={section.pickup_location}
                        onBlur={(e) => {
                            setDirty((prev) => ({ ...prev, pickup_location: true }));
                        }}
                        onChange={(e) =>
                            setSection((prev) => ({
                                ...prev,
                                pickup_location_type: e.target.value,
                            }))
                        }
                        error={isEmpty('pickup_location_type') && isDirty.pickup_location_type}
                    />
                </FlexColumn>
            </FlexRow>

            <Footer
                state={state}
                isFirst={state.isFirstStage}
                isLast={state.isLastStage}
                hasErrors={hasError}
                callbacks={{ advance: () => callbacks.advance(section), revert: callbacks.revert }}
            />
        </Grid>
    );
}

export default PickupResidential;
