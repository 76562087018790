import { useItemQuantity, useTotalCubes, useTotalWeight, useOrderCarrierPricing, useClientUser } from '@/hooks';
import { Grid, StylesProvider } from '@material-ui/core';
import React from 'react';
import { colors } from '@/styles';
import { useLoadSummary } from './hooks';

// workaround since emotion is not generating the styles for this component?
const STYLES = {
    price: {
        fontWeight: 700,
        fontSize: '12px',
        color: colors.greens.primary,
    },
    title: {
        fontWeight: 800,
        fontSize: '12px',
    },
    body: {
        fontWeight: 500,
        fontSize: '12px',
    },
    primaryRow: {
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
    },
    container: {
        flexDirection: 'column',
    },
};

const LoadTooltip = ({ orders = [] }) => {
    const price = useOrderCarrierPricing(orders, 'N/A');
    const items = useItemQuantity(orders);
    const volume = parseInt(useTotalCubes(orders));
    const weight = parseInt(useTotalWeight(orders));

    const { order_numbers, shipper, pickups, pickupTypes, dropoffs, deliveryTypes, details, bidCount, piece_count } =
        useLoadSummary(orders);

    return (
        <StylesProvider injectFirst>
            <Grid container style={STYLES.container}>
                <Grid item style={STYLES.primaryRow}>
                    <span style={STYLES.title}>{order_numbers}</span>
                    <span style={STYLES.price}>{price ? price : 'N/A'}</span>
                </Grid>
                {bidCount > 0 && (
                    <Grid item style={STYLES.body}>
                        Bids: {bidCount}
                    </Grid>
                )}
                <Grid item style={STYLES.body}>
                    Shipper: {shipper}
                </Grid>
                <Grid item style={STYLES.body}>
                    Pickup: {pickups}
                </Grid>
                {pickupTypes && (
                    <Grid item style={STYLES.body}>
                        Pickup type: {pickupTypes}
                    </Grid>
                )}
                <Grid item style={STYLES.body}>
                    Dropoff: {dropoffs}
                </Grid>
                <Grid item style={STYLES.body}>
                    Delivery type: {deliveryTypes || 'Unknown'}
                </Grid>
                <Grid item style={STYLES.body}>
                    Drop off details: {details}
                </Grid>
                <Grid item style={STYLES.body}>{`${items} Item(s) /${piece_count ? ` ${piece_count} piece(s) /` : ''} ${volume} cu ft. / ${weight} lbs`}</Grid>
            </Grid>
        </StylesProvider>
    );
};

export default LoadTooltip;
