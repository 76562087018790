import React from 'react';

export const RATES = [
    {
        name: 'Curbside',
        xLabels: ['1 - 300 lbs', '', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return ['DEFAULT_SMALL_ONE_MAN_CURBSIDE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'DEFAULT_SMALL_ONE_MAN_CURBSIDE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
            ],
        ],
    },
    {
        name: 'Threshold Plus',
        xLabels: ['1 - 300 lbs', '', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return ['DEFAULT_SMALL_ONE_MAN_THRESHOLD_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'DEFAULT_SMALL_ONE_MAN_THRESHOLD_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
            ],
        ],
    },
    {
        name: '1 Man White Glove (ROC)',
        xLabels: ['1 - 300 lbs', '', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return ['DEFAULT_SMALL_ONE_MAN_ROOM_OF_CHOICE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'DEFAULT_SMALL_ONE_MAN_ROOM_OF_CHOICE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
            ],
        ],
    },
    {
        name: '2 Man Curbside',
        xLabels: ['1 - 300 lbs', '301 - 500 lbs', '501+ lbs'],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return [
                    'DEFAULT_SMALL_TWO_MAN_CURBSIDE_RATE',
                    'DEFAULT_MEDIUM_TWO_MAN_CURBSIDE_RATE',
                    'DEFAULT_LARGE_TWO_MAN_CURBSIDE_RATE',
                ].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'DEFAULT_SMALL_TWO_MAN_CURBSIDE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
                {
                    type: 'DEFAULT_MEDIUM_TWO_MAN_CURBSIDE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
                {
                    type: 'DEFAULT_LARGE_TWO_MAN_CURBSIDE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
            ],
        ],
    },
    {
        name: '2 Man Threshold Plus',
        xLabels: ['1 - 300 lbs', '301 - 500 lbs', '501+ lbs'],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return [
                    'DEFAULT_SMALL_TWO_MAN_THRESHOLD_RATE',
                    'DEFAULT_MEDIUM_TWO_MAN_THRESHOLD_RATE',
                    'DEFAULT_LARGE_TWO_MAN_THRESHOLD_RATE',
                ].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'DEFAULT_SMALL_TWO_MAN_THRESHOLD_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
                {
                    type: 'DEFAULT_MEDIUM_TWO_MAN_THRESHOLD_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
                {
                    type: 'DEFAULT_LARGE_TWO_MAN_THRESHOLD_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
            ],
        ],
    },
    {
        name: '2 Man White Glove (ROC)',
        xLabels: ['1 - 300 lbs', '301 - 500 lbs', '501+ lbs'],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return [
                    'DEFAULT_SMALL_TWO_MAN_ROOM_OF_CHOICE_RATE',
                    'DEFAULT_MEDIUM_TWO_MAN_ROOM_OF_CHOICE_RATE',
                    'DEFAULT_LARGE_TWO_MAN_ROOM_OF_CHOICE_RATE',
                ].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'DEFAULT_SMALL_TWO_MAN_ROOM_OF_CHOICE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
                {
                    type: 'DEFAULT_MEDIUM_TWO_MAN_ROOM_OF_CHOICE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
                {
                    type: 'DEFAULT_LARGE_TWO_MAN_ROOM_OF_CHOICE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
            ],
        ],
    },
    {
        name: '2 Man White Glove Plus',
        xLabels: ['1 - 300 lbs', '301 - 500 lbs', '501+ lbs'],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76+ miles'],
        buckets: [
            ...[0, 1, 2].map((idx) => {
                return [
                    'DEFAULT_SMALL_TWO_MAN_WHITE_GLOVE_RATE',
                    'DEFAULT_MEDIUM_TWO_MAN_WHITE_GLOVE_RATE',
                    'DEFAULT_LARGE_TWO_MAN_WHITE_GLOVE_RATE',
                ].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'DEFAULT_SMALL_TWO_MAN_WHITE_GLOVE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
                {
                    type: 'DEFAULT_MEDIUM_TWO_MAN_WHITE_GLOVE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
                {
                    type: 'DEFAULT_LARGE_TWO_MAN_WHITE_GLOVE_INCREMENTAL_RATE',
                    bucket: 0,
                    decorator: 'per mile',
                },
            ],
        ],
    },
];

export const DEFAULT_RATES = {
    DEFAULT_EXCHANGE_BASE_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_EXCHANGE_MODIFIER: [{ min: 0, max: 'infinity' }],
    DEFAULT_HAULAWAY_INCREMENTAL_BEDDING_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_HAULAWAY_INCREMENTAL_ITEM_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_INCREMENTAL_ITEM_RATE: [{ min: 3, max: 'infinity' }],
    DEFAULT_LARGE_TWO_MAN_CURBSIDE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_LARGE_TWO_MAN_CURBSIDE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_LARGE_TWO_MAN_ROOM_OF_CHOICE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_LARGE_TWO_MAN_ROOM_OF_CHOICE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_LARGE_TWO_MAN_THRESHOLD_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_LARGE_TWO_MAN_THRESHOLD_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_LARGE_TWO_MAN_WHITE_GLOVE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_LARGE_TWO_MAN_WHITE_GLOVE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_MEDIUM_TWO_MAN_CURBSIDE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_MEDIUM_TWO_MAN_CURBSIDE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_MEDIUM_TWO_MAN_ROOM_OF_CHOICE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_MEDIUM_TWO_MAN_ROOM_OF_CHOICE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_MEDIUM_TWO_MAN_THRESHOLD_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_MEDIUM_TWO_MAN_THRESHOLD_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_MEDIUM_TWO_MAN_WHITE_GLOVE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_MEDIUM_TWO_MAN_WHITE_GLOVE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_PACKAGING_BASE_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_SMALL_ONE_MAN_CURBSIDE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_SMALL_ONE_MAN_CURBSIDE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_SMALL_ONE_MAN_ROOM_OF_CHOICE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_SMALL_ONE_MAN_ROOM_OF_CHOICE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_SMALL_ONE_MAN_THRESHOLD_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_SMALL_ONE_MAN_THRESHOLD_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_SMALL_TWO_MAN_CURBSIDE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_SMALL_TWO_MAN_CURBSIDE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_SMALL_TWO_MAN_ROOM_OF_CHOICE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_SMALL_TWO_MAN_ROOM_OF_CHOICE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_SMALL_TWO_MAN_THRESHOLD_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_SMALL_TWO_MAN_THRESHOLD_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_SMALL_TWO_MAN_WHITE_GLOVE_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    DEFAULT_SMALL_TWO_MAN_WHITE_GLOVE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
    ],
    DEFAULT_STAIR_BASE_RATE: [{ min: 2, max: 'infinity' }],
    DEFAULT_STAIR_INCREMENTAL_RATE: [{ min: 2, max: 'infinity' }],
    DEFAULT_ATTEMPT_MODIFIER: [{ min: 0, max: 'infinity' }],
    DEFAULT_ATTEMPT_BASE_RATE: [{ min: 0, max: 'infinity' }],
    FUEL_PRICE_MODIFIER: [{ min: 0, max: 'infinity' }],
};

export const ACCESSORIALS = [
    { label: 'Additional 3rd Person', type: 'DEFAULT_3_MAN_INCREMENTAL_RATE', adornment: '$' },
    { label: 'Additional Piece(s)', type: 'DEFAULT_INCREMENTAL_ITEM_RATE', adornment: '$' },
    {
        label: 'Appliance/Furniture Disposal',
        type: 'DEFAULT_HAULAWAY_INCREMENTAL_ITEM_RATE',
        adornment: '$',
    },
    { label: 'Cancellation at dock', type: 'DEFAULT_CANCELLATION_RATE', adornment: '$' },
    { label: 'Cargo Coverage', type: 'DEFAULT_CARGO_COVERAGE_RATE', adornment: '$', decorator: 'per lb' },
    {
        label: 'Less that 2 hour window',
        type: 'DEFAULT_RUSH_MODIFIER',
        adornment: '%',
        isPercentage: true,
    },
    { label: 'Exchange (percentage)', type: 'DEFAULT_EXCHANGE_MODIFIER', adornment: '%', isPercentage: true },
    { label: 'Exchange (flat)', type: 'DEFAULT_EXCHANGE_BASE_RATE', adornment: '$' },
    { label: 'Attempt (percentage)', type: 'DEFAULT_ATTEMPT_MODIFIER', adornment: '%', isPercentage: true },
    { label: 'Attempt (flat)', type: 'DEFAULT_ATTEMPT_BASE_RATE', adornment: '$' },
    {
        label: 'Mattress/White good disposal',
        type: 'DEFAULT_HAULAWAY_INCREMENTAL_BEDDING_RATE',
        adornment: '$',
        decorator: 'per set (up to 3 pcs)',
    },
    {
        label: 'Onsite Relocation',
        type: 'DEFAULT_ONSITE_RELOCATION_RATE',
        adornment: '$',
        decorator: 'per item',
    },
    {
        label: 'Open and Inspect',
        type: 'DEFAULT_INSPECTION_BASE_RATE',
        adornment: '$',
        decorator: 'first 2 pieces',
    },
    {
        label: 'Open and Inspect',
        type: 'DEFAULT_INSPECTION_INCREMENTAL_RATE',
        adornment: '$',
        decorator: 'additional',
    },
    {
        label: 'Repackaging for Transit',
        type: 'DEFAULT_PACKAGING_BASE_RATE',
        adornment: '$',
    },
    {
        label: 'Stairs',
        type: 'DEFAULT_STAIR_BASE_RATE',
        adornment: '$',
    },
    {
        label: 'Stair Incremental',
        type: 'DEFAULT_STAIR_INCREMENTAL_RATE',
        adornment: '$',
        decorator: 'after 2 flights',
    },
    {
        label: 'Storage',
        type: 'DEFAULT_STORAGE_RATE',
        adornment: '$',
        decorator: 'per day (after 14 days)',
    },
    {
        label: 'Wait Time / Additional Time',
        type: 'DEFAULT_WAIT_TIME_RATE',
        adornment: '$',
        decorator: 'per 15min',
    },
    {
        label: 'Fuel Modifier',
        type: 'FUEL_PRICE_MODIFIER',
        adornment: '%',
        isPercentage: true,
        decorator: 'of subtotal',
    },
    {
        label: 'Additional Mileage',
        type: 'MILEAGE_RATE',
        adornment: '$',
        decorator: 'per mile over limit',
    },
];

export const RenderDefaultAccessorial = ({
    DefaultAccessorial,
    MaxSplitAccessorial,
    accessorial,
    rates,
    errors,
    callbacks,
}) => {
    switch (accessorial.type) {
        case 'FUEL_PRICE_MODIFIER':
        case 'DEFAULT_EXCHANGE_MODIFIER':
        case 'DEFAULT_ATTEMPT_MODIFIER':
        case 'MILEAGE_RATE':
            return null;
        case 'DEFAULT_EXCHANGE_BASE_RATE': {
            return (
                <MaxSplitAccessorial
                    label="Exchange"
                    percentage="DEFAULT_EXCHANGE_MODIFIER"
                    flat="DEFAULT_EXCHANGE_BASE_RATE"
                    rates={rates}
                    callbacks={callbacks}
                />
            );
        }
        case 'DEFAULT_ATTEMPT_BASE_RATE': {
            return (
                <MaxSplitAccessorial
                    label="Delivery Attempt"
                    percentage="DEFAULT_ATTEMPT_MODIFIER"
                    flat="DEFAULT_ATTEMPT_BASE_RATE"
                    rates={rates}
                    callbacks={callbacks}
                />
            );
        }
        default: {
            return (
                <DefaultAccessorial
                    key={accessorial.type}
                    {...accessorial}
                    rates={rates}
                    errors={errors}
                    callbacks={callbacks}
                />
            );
        }
    }
};
