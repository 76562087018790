import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { poundFormatter, integerFormatter, minuteString } from '@/constants/formats';
import {
    useItemQuantity,
    useTotalWeight,
    useTotalCubes,
    useOrderShipperPricing,
    useOrderCarrierPricing,
    useTotalRevenue,
} from '@/hooks';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import deliveryStrings from '@/constants/deliveryStrings';
import { Row, Col } from 'react-bootstrap';
import { capitalize } from 'lodash';
import { Body1 } from '@/components/DispatchPlan/blocks';

const Metric = ({ label, value }) => {
    const render = [...(label ? [label] : []), value];
    return (
        <Grid
            item
            css={css`
                margin: 2px 0;
            `}
        >
            <div style={{ fontWeight: 500, fontSize: '14px' }}>{render.join(': ')}</div>
        </Grid>
    );
};

const BoldMetric = ({ label, value }) => {
    const render = [...(label ? [label] : []), value];
    return (
        <Grid
            item
            css={css`
                margin: 4px 0;
            `}
        >
            <div style={{ fontWeight: 700, fontSize: '14px' }}>{render.join(': ')}</div>
        </Grid>
    );
};

const MarketplaceInfoWindow = ({ orders, stop, type }) => {
    if (!orders?.length) return <Body1>Error! Missing Order Information</Body1>;

    const totalRevenue = useTotalRevenue(orders);
    const totalItems = useItemQuantity(orders);
    const totalWeight = useTotalWeight(orders);
    const totalVolume = useTotalCubes(orders);
    const rate = useOrderShipperPricing(orders);
    const carrierRate = useOrderCarrierPricing(orders);

    const customerNames = useMemo(() => {
        const names = new Set(orders.map((order) => order.dropoff_name).filter((o) => o));

        return Array.from(names);
    }, [orders]);

    const address = useMemo(() => {
        if (stop) {
            return stop.address;
        }

        return orders[0].order_type === 'return'
            ? orders[0].pickup_full_address || orders[0].pickup_address
            : orders[0].dropoff_full_address || orders[0].dropoff_address;
    }, [stop, orders]);

    const skus = useMemo(() => {
        const _skus = new Set();
        orders.forEach((order) => {
            order.itemsByOrderId?.forEach((item) => {
                if (item.sku) {
                    _skus.add(item.sku);
                }
            });
        });
        return Array.from(_skus);
    }, [orders]);

    const orderLocationTypes = useMemo(() => {
        const uniqueTypes = new Set(
            orders
                .map((order) =>
                    order.order_type === 'return' ? order.pickup_location_type : order.dropoff_location_type
                )
                .filter((o) => o)
        );
        return Array.from(uniqueTypes);
    }, [orders]);

    const freightTypes = useMemo(() => {
        const freights = new Set();
        orders.forEach((order) => {
            if (order?.freight_type) freights.add(capitalize(order.freight_type));
        });
        return Array.from(freights);
    }, [orders]);

    return (
        <Grid container direction="column">
            {orders.length === 1 ? (
                <>
                    {type === 'load' ? (
                        <>
                            <Row style={{ height: '20px' }}>
                                <Col style={{ padding: '0rem !important', textAlign: 'start' }}>
                                    <BoldMetric style={{ padding: '0' }} value={orders[0].order_number} />
                                </Col>
                                <Col style={{ color: 'green', textAlign: 'end', padding: '0rem !important' }}>
                                    <BoldMetric
                                        style={{ padding: '0' }}
                                        label="Rate"
                                        value={
                                            orders[0].order_status === 'open' && orders[0].oms === false
                                                ? carrierRate
                                                : rate
                                        }
                                    />
                                </Col>
                            </Row>
                            <Metric label="Shipper" value={orders[0].order_shipper.business_name} />
                            <Metric label="Pickup" value={orders[0].pickup_city} />
                            <Metric label="Dropoff" value={orders[0].dropoff_city} />

                            <Metric
                                label="Order Type"
                                value={deliveryStrings.deliveryTypes[orders[0].order_type] || orders[0].order_type}
                            />

                            <Metric
                                label="Delivery Type"
                                value={orderLocationTypes.map(
                                    (locationType) => LOCATION_TYPES[locationType] || locationType
                                )}
                            />
                            <Metric label="Freight Type" value={freightTypes} />
                            <Metric
                                value={[
                                    `${integerFormatter.format(totalItems)} items`,
                                    `${integerFormatter.format(totalVolume)} cu ft.`,
                                    poundFormatter.format(totalWeight),
                                ].join(' / ')}
                            />
                            <Metric
                                label="Dropoff Details"
                                value={orders[0].dropoff_location + ' - ' + orders[0].dropoff_location_info}
                            />
                            {orders[0].manufacturer && (
                                <Metric label="Manufacturer ID" value={orders[0].manufacturer} />
                            )}
                        </>
                    ) : (
                        <>
                            {orders[0].oms === false && (
                                <Col style={{ backgroundColor: 'green', color: 'white', textAlign: 'center' }}>
                                    <BoldMetric value={'Onward - Claimed Order'} />
                                </Col>
                            )}
                            <BoldMetric value={orders[0].dropoff_name} />
                            <Metric value={address} />
                            <Metric label="Order" value={orders[0].order_number} />
                            <Metric label="Order Type" value={orders[0].order_type} />
                            <Metric
                                value={[
                                    `${integerFormatter.format(totalItems)} items`,
                                    `${integerFormatter.format(totalVolume)} cu ft.`,
                                    poundFormatter.format(totalWeight),
                                ].join(' / ')}
                            />
                            <Metric
                                label="Delivery Type"
                                value={orderLocationTypes.map(
                                    (locationType) => LOCATION_TYPES[locationType] || locationType
                                )}
                            />
                            <Metric label="Freight Type" value={freightTypes} />
                            <Metric label="PO" value={orders[0].po_number} />
                            {orders[0].manufacturer && (
                                <Metric
                                    label="Manufacturer ID"
                                    value={orders
                                        .map((order) => order.manufacturer)
                                        .filter((n) => n)
                                        .join(', ')}
                                />
                            )}
                            {orders[0].reference_id && (
                                <Metric
                                    label="Reference ID"
                                    value={orders
                                        .map((order) => order.reference_id)
                                        .filter((n) => n)
                                        .join(', ')}
                                />
                            )}
                            {skus?.[0] && <Metric label="SKU" value={skus} />}
                            {totalRevenue > 0 && <Metric label="Revenue" value={`$${totalRevenue}`} />}
                        </>
                    )}
                </>
            ) : (
                <>
                    {type === 'load' ? (
                        <>
                            <Row style={{ padding: '0rem !important', marginBottom: '0 !important' }}>
                                <Col style={{ padding: '0', textAlign: 'start' }}>
                                    <BoldMetric value={`${orders.length} Orders`} />
                                </Col>
                                <Col style={{ color: 'green', textAlign: 'end', padding: '0' }}>
                                    <BoldMetric style={{ padding: '0' }} label="Rate" value={rate} />
                                </Col>
                            </Row>
                            <Metric label="Orders" value={orders.map((o) => o.order_number).join(', ')} />
                            <Metric label="Pickup" value={orders[0].pickup_city} />
                            <Metric label="Dropoff" value={orders[0].dropoff_city} />
                            <Metric label="Order Type" value={orders[0].order_type} />
                            <Metric
                                label="Delivery Type"
                                value={orderLocationTypes.map(
                                    (locationType) => LOCATION_TYPES[locationType] || locationType
                                )}
                            />
                            <Metric label="Freight Type" value={freightTypes} />
                            <Metric
                                value={[
                                    `${integerFormatter.format(totalItems)} items`,
                                    `${integerFormatter.format(totalVolume)} cu ft.`,
                                    poundFormatter.format(totalWeight),
                                ].join(' / ')}
                            />
                            {orders[0].manufacturer && (
                                <Metric label="Manufacturer ID" value={orders[0].manufacturer} />
                            )}
                        </>
                    ) : (
                        <>
                            {orders[0].oms === false && (
                                <Col style={{ backgroundColor: 'green', color: 'white', textAlign: 'center' }}>
                                    <BoldMetric value={'Onward - Claimed Order(s)'} />
                                </Col>
                            )}
                            <BoldMetric value={`${orders.length} Orders`} />
                            <BoldMetric value={customerNames.join(', ')} />
                            <Metric value={address} />
                            <Metric label="Orders" value={orders.map((o) => o.order_number).join(', ')} />
                            <Metric label="Order Type" value={orders[0].order_type} />

                            <Metric
                                value={[
                                    `${integerFormatter.format(totalItems)} items`,
                                    `${integerFormatter.format(totalVolume)} cu ft.`,
                                    poundFormatter.format(totalWeight),
                                ].join(' / ')}
                            />
                            <Metric
                                label="Delivery Type"
                                value={orderLocationTypes.map(
                                    (locationType) => LOCATION_TYPES[locationType] || locationType
                                )}
                            />
                            <Metric label="Freight Type" value={freightTypes} />
                            <Metric
                                label="PO"
                                value={orders
                                    .map((order) => order.po_number)
                                    .filter((n) => n)
                                    .join(', ')}
                            />
                            {orders[0].manufacturer && (
                                <Metric
                                    label="Manufacturer ID"
                                    value={orders
                                        .map((order) => order.manufacturer)
                                        .filter((n) => n)
                                        .join(', ')}
                                />
                            )}
                            {orders[0].reference_id && (
                                <Metric
                                    label="Reference ID"
                                    value={orders
                                        .map((order) => order.reference_id)
                                        .filter((n) => n)
                                        .join(', ')}
                                />
                            )}
                            {skus?.[0] && <Metric label="SKU" value={skus} />}
                            {totalRevenue > 0 && <Metric label="Revenue" value={`$${totalRevenue}`} />}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
};

export default MarketplaceInfoWindow;
