import React, { useContext, useMemo } from 'react';
import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import zipcode_to_timezone from 'zipcode-to-timezone';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { toNational } from '@/utilities/formatPhoneNumber';
import { colors } from '@/styles';
import { EDIT_ORDER_TABS } from '../constants';
import { GreySubheader, Body, GreyBody, GrayBgCard, PrimaryButton } from '../../../styles/blocks';
import { PriceText } from '@/components/ShipmentForm/blocks';
import { DROPOFF_TYPES } from '@/components/ShipmentForm/constants/dropoffOptions';
import dateFns from '@/utilities/dateFns';
import { asDateInTZ } from '@/utilities/convertToISO';
import { useSwappedAttributes } from '../hooks';
import { useOrderShipperPricing, useClientUser } from '@/hooks';
import BidIcon from '@/components/misc/BidIcon';
import { BIDDING_DISABLED } from '@/constants/featureFlags';
import EditIcon from '@material-ui/icons/Edit';
import { startCase } from 'lodash';

const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const GreyBox = styled.div`
    display: inline-block;
    background: #e4e4e4;
    border-radius: 4px;
    padding: 0.5rem;
    width: ${(props) => (props.fullWidth ? '100%' : '')};
    cursor: ${(props) => (props.pointer ? 'pointer' : '')};
`;

const Mask = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 999;
`;

const SubCardTitle = styled((props) => <Body {...props} />)`
    font-weight: 600;
`;

const Icon = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 16px;

    & svg {
        vertical-align: super;
    }
`;

function OrderCard({ accordion = false, order, orderNumber, listing, validation, callbacks }) {
    const { location_type, location, zip } = useSwappedAttributes(order);
    const isSaved = !!order.order_id;
    const isLoading = order.order_status === 'processing';
    const { circles, payment_type } = useClientUser();
    const shipperRate = useOrderShipperPricing({ ...order, listing }, circles, payment_type, '--');
    const internalRate = `$${(order.order_revenue || 0).toFixed(2)}`;

    const isInternal = !!order.oms;

    let icon;
    if (isSaved) {
        switch (order.order_status) {
            case 'processing':
                icon = null;
                break;
            case 'pending_confirmation':
                icon = (
                    <Icon
                        css={css`
                            color: ${colors.greens.primary};
                        `}
                    >
                        <CheckCircleIcon />
                    </Icon>
                );
                break;
            case 'incomplete':
                icon = (
                    <Icon
                        css={css`
                            color: #d23e3e;
                        `}
                    >
                        <ErrorIcon />
                    </Icon>
                );
                break;

            default:
                if (validation.hasError()) {
                    icon = (
                        <Icon
                            css={css`
                                color: #d23e3e;
                            `}
                        >
                            <ErrorIcon />
                        </Icon>
                    );
                } else {
                    icon = (
                        <Icon
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            <CheckCircleIcon />
                        </Icon>
                    );
                }
                break;
        }
    }

    const items = useMemo(() => {
        return order?.itemsByOrderId?.filter((item) => !item.is_return);
    }, [order]);
    const returns = useMemo(() => {
        return order?.itemsByOrderId?.filter((item) => item.is_return);
    }, [order]);
    const orderTZ = useMemo(() => {
        return (order[zip] && zipcode_to_timezone.lookup(order[zip])) || 'America/New_York';
    }, [order, zip]);

    const getLabel = (type, value, pickupOrDropoff) => {
        switch (type) {
            case 'Residence':
                return (
                    DROPOFF_TYPES[type].find((dropoff) => dropoff.value === value)?.label(pickupOrDropoff) ||
                    value ||
                    ''
                );
            case 'Business':
                let label =
                    DROPOFF_TYPES[type].find((dropoff) => dropoff.value === value || dropoff.label === value)?.label ||
                    value ||
                    '';
                return typeof label === 'function' ? label(pickupOrDropoff) : label;
            default:
                return value;
        }
    };

    const orderLabel = useMemo(() => {
        switch (order.job_type) {
            case 'WILL_CALL':
                return 'Will Call';
            case 'PICKUP_AND_WILL_CALL':
                return 'Return Pickup & Will Call';
            case 'INVENTORY':
                return 'Inventory';
            case 'SERVICE':
                return 'Service';
            case 'SHIPMENT':
            default:
                return startCase(order.order_type || 'delivery');
        }
    }, [order]);

    const content = (
        <>
            <Grid
                container
                css={css`
                    margin-bottom: 1rem;
                `}
            >
                <Grid
                    container
                    item
                    xs={6}
                    css={css`
                        align-items: center;
                    `}
                >
                    <GreySubheader
                        css={css`
                            margin: 0;
                        `}
                    >
                        {`Order ${order.order_number || orderNumber || ''} - ${orderLabel}`}
                    </GreySubheader>
                </Grid>
                <Grid
                    container
                    item
                    xs={6}
                    css={css`
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    {icon}

                    <IconButton
                        css={css`
                            padding: 4px;
                        `}
                    >
                        <CloseIcon
                            onClick={() => {
                                callbacks.deleteOrder(order);
                            }}
                            css={css`
                                color: black;
                            `}
                        />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                css={css`
                    margin-bottom: 1rem;
                `}
            >
                <Grid item xs={3}>
                    <Grid item xs={12}>
                        <SubCardTitle>Contact</SubCardTitle>
                    </Grid>
                    <GrayBgCard
                        pointer="true"
                        onClick={() => !callbacks.openEditModal(order, EDIT_ORDER_TABS.CONTACT)}
                        validationError={validation.hasErrorTab(EDIT_ORDER_TABS.CONTACT)}
                    >
                        {order.dropoff_name && <Body>{order.dropoff_name}</Body>}
                        {order.pickup_street_address && order.dropoff_street && (
                            <Body>
                                {order.order_type === 'return' ? order.pickup_street_address : order.dropoff_street}
                            </Body>
                        )}
                        <Body>{toNational(order.dropoff_phone)}</Body>
                    </GrayBgCard>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={12}>
                        <SubCardTitle>Items</SubCardTitle>
                    </Grid>
                    <GrayBgCard
                        fullWidth
                        pointer="true"
                        onClick={() => callbacks.openEditModal(order, EDIT_ORDER_TABS.ITEMS)}
                        validationError={validation.hasErrorTab(EDIT_ORDER_TABS.ITEMS)}
                    >
                        {order.itemsByOrderId.length > 0 ? (
                            <>
                                <Body>
                                    {!!items.length && `${items.reduce((acc, item) => acc + item.quantity, 0)} item(s)`}
                                </Body>
                                {!!items.length && !!returns.length && <br />}
                                <Body>
                                    {!!returns.length &&
                                        `${returns.reduce((acc, item) => acc + item.quantity, 0)} return(s)`}
                                </Body>
                            </>
                        ) : (
                            <Body>None</Body>
                        )}
                    </GrayBgCard>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={12}>
                        <SubCardTitle>
                            Add {order.order_type === 'return' ? 'Return Pickup' : 'Delivery'} Type
                        </SubCardTitle>
                    </Grid>
                    <GrayBgCard
                        fullWidth
                        pointer="true"
                        onClick={() => callbacks.openEditModal(order, EDIT_ORDER_TABS.DELIVERY_TYPE)}
                        validationError={validation.hasErrorTab(EDIT_ORDER_TABS.DELIVERY_TYPE)}
                    >
                        {order[location] && order[location_type] ? (
                            <>
                                <Body>Location Type: {order[location]}</Body>
                                <Body
                                    css={css`
                                        margin-top: 8px;
                                    `}
                                >
                                    Dropoff Type:{' '}
                                    {getLabel(
                                        order[location],
                                        order[location_type],
                                        order.order_type === 'return' ? 'pickUp' : 'dropOff'
                                    )}
                                </Body>
                            </>
                        ) : (
                            <Body>None</Body>
                        )}
                    </GrayBgCard>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={12}>
                        <SubCardTitle>Add haul-away</SubCardTitle>
                    </Grid>
                    <GrayBgCard
                        pointer
                        onClick={() => callbacks.openEditModal(order, EDIT_ORDER_TABS.HAUL_AWAY)}
                        css={css`
                            justify-content: center;
                        `}
                    >
                        <Body>
                            {order?.haulaway_items?.length ? order?.haulaway_items?.length : 'None'}
                            {order?.haulaway_items?.length > 0 ? ` Item(s)` : ''}
                        </Body>
                        <GreyBody>(Optional)</GreyBody>
                    </GrayBgCard>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid
                    container
                    item
                    xs={3}
                    css={css`
                        align-items: center;
                    `}
                >
                    <SubCardTitle
                        css={css`
                            margin: 0;
                            margin-right: 12px;
                        `}
                    >
                        Delivery Date{' '}
                    </SubCardTitle>
                    <GreyBox>
                        <GreyBody>
                            {dateFns.formatDate(asDateInTZ(order.delivery_date, orderTZ), 'yyyy-MM-dd') || 'TBD'}
                        </GreyBody>
                    </GreyBox>
                </Grid>
                <Grid
                    container
                    item
                    xs={3}
                    css={css`
                        align-items: center;
                    `}
                >
                    <SubCardTitle
                        css={css`
                            margin: 0;
                            margin-right: 12px;
                        `}
                    >
                        PO
                    </SubCardTitle>
                    <GrayBgCard
                        pointer="true"
                        onClick={() => callbacks.openEditModal(order, EDIT_ORDER_TABS.ITEMS)}
                        validationError={validation.hasErrorTab(EDIT_ORDER_TABS.ITEMS)}
                        css={css`
                            padding: 8px;
                            height: auto;
                        `}
                    >
                        <GreyBody
                            css={css`
                                margin: 0;
                            `}
                        >
                            {order.po_number ? order.po_number : 'TBD'}
                        </GreyBody>
                    </GrayBgCard>
                </Grid>
                {isSaved ? (
                    <Grid
                        container
                        item
                        xs={6}
                        css={css`
                            align-items: center;
                            justify-content: flex-end;
                        `}
                    >
                        {!order.oms && !circles?.[BIDDING_DISABLED] && listing ? (
                            <div
                                css={css`
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                {['all', 'auction'].includes(listing?.listing_type) && <BidIcon />}
                                <SubCardTitle
                                    css={css`
                                        margin-right: 12px;
                                        cursor: pointer;
                                    `}
                                >
                                    Claim Now Price:
                                </SubCardTitle>
                                <PriceText>{shipperRate}</PriceText>
                                <PrimaryButton
                                    disabled={isInternal}
                                    onClick={() => callbacks.editListing({ listing, order })}
                                    css={css`
                                        min-width: 0;
                                        padding-left: 6px;
                                        padding-right: 6px;
                                        margin-left: 6px;
                                    `}
                                >
                                    Edit Listing
                                </PrimaryButton>
                            </div>
                        ) : (
                            <>
                                <SubCardTitle
                                    css={css`
                                        color: ${colors.greens.primary};
                                        margin-right: 12px;
                                        text-decoration: underline;
                                    `}
                                >
                                    {isInternal ? 'Onward Income: ' : 'Onward Shipping Price: '}
                                </SubCardTitle>
                                <PriceText>{isInternal ? internalRate : shipperRate}</PriceText>
                            </>
                        )}
                    </Grid>
                ) : null}
            </Grid>
        </>
    );

    return accordion ? (
        content
    ) : (
        <Card
            css={css`
                position: relative;
                margin-bottom: 1rem;
                opacity: ${isLoading ? 0.3 : 1};
            `}
        >
            {isLoading ? <Mask /> : null}
            <CardContent>{content}</CardContent>
        </Card>
    );
}

export default OrderCard;
