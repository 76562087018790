import { gql } from '@apollo/client';
import { ORDER_COMMUNICATIONS_FIELDS } from '../queries/order_communications';

export const ORDER_COMMUNICATIONS_EVENT_TYPES = {
    LTL_TO_OPEN: 'LTL_TO_OPEN',
}

export const INSERT_ORDER_COMMUNICATIONS_ENTRY = gql`
    ${ORDER_COMMUNICATIONS_FIELDS}
    mutation InsertOrderCommunicationsEntry($client_id: uuid!, $order_id: uuid!, $event_type: String) {
        insert_order_communications_one(object: {
            client_id: $client_id, 
            order_id: $order_id,
            event_type: $event_type,
        }
    ) {
        ...OrderCommunicationsFields
    }
}`

export const REMOVE_ORDER_COMMUNICATIONS_ENTRY = gql`
    ${ORDER_COMMUNICATIONS_FIELDS}
    mutation RemoveOrderCommunicationsEntry($order_communication_id: uuid!) {
        delete_order_communications_by_pk(order_communication_id: $order_communication_id) {
            ...OrderCommunicationsFields
        }
    }
`;
