import { getDeliveryWindowFormatted } from './getDeliveryWindowFormatted';

const getDeliveryWindow = (stop, deliveryDate) => {
    const { del_window_start, del_window_end, stop_start_time, stop_end_time } = stop;

    if (del_window_start && del_window_end) {
        return getDeliveryWindowFormatted(new Date(del_window_start), new Date(del_window_end));
    } else if (stop_start_time && stop_end_time && deliveryDate) {
        const startTime = new Date(deliveryDate);
        startTime.setHours(Math.floor(stop_start_time));
        startTime.setMinutes(parseInt((stop_start_time % 1) * 60));

        const endTime = new Date(deliveryDate);
        endTime.setHours(Math.floor(stop_end_time));
        endTime.setMinutes(parseInt((stop_end_time % 1) * 60));

        return getDeliveryWindowFormatted(startTime, endTime);
    }

    return 'TBD';
};

export default getDeliveryWindow;
