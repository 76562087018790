import { MobileDialog, ModalContent, PrimaryButton, SecondaryButton, StickyModalActions } from '@/styles/blocks';
import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { Body1, Header1, Header2, ItemCheckbox } from '@/components/OrderDetailsPage/Crossdocking/blocks';
import { colors } from '@/styles';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import FileUpload from '../FileUpload';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import { captureException } from '@sentry/react';
import Signature from '../Signature';

const FILE_TYPES = {
    'application/pdf': 'pdf',
    'image/png': 'png',
    'image/jpeg': 'jpg',
    'image/jpg': 'jpg',
};

const ChecklistModal = ({
    open,
    title,
    itemsHelperText,
    order,
    items = [],
    documents = [],
    signatures = [],
    callbacks,
}) => {
    const [override, setSelected] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [uploadedSignatures, setUploadedSignatures] = useState({});
    const [loading, setLoading] = useState(false);

    const selected = useMemo(() => {
        return {
            ...Object.fromEntries(items.map((item) => [item.item_id, false])),
            ...override,
        };
    }, [override, items]);

    const isValid = useMemo(() => {
        return (
            items.every((item) => selected[item.item_id]) &&
            documents.every((doc) => !doc.required || uploadedFiles?.[doc.field]?.length > 0) &&
            signatures.every((sig) => !sig.required || !!uploadedSignatures?.[sig.field])
        );
    }, [selected, items, uploadedFiles, documents, uploadedSignatures, signatures]);

    const onConfirm = async () => {
        setLoading(true);
        try {
            let orderUpdates = {};

            for (const [field, files] of Object.entries(uploadedFiles)) {
                const databaseFiles = [];
                for (const file of files) {
                    const firebaseFile = await uploadPhotoAsync(file.src, field, FILE_TYPES[file.type]);
                    databaseFiles.push({ url: firebaseFile, environment: 'web' });
                }
                orderUpdates[field] = [...(order[field] || []), ...databaseFiles];
            }

            for (const [field, file] of Object.entries(uploadedSignatures)) {
                const databaseFile = await uploadPhotoAsync(file.src, field, FILE_TYPES[file.type]);
                orderUpdates[field] = databaseFile;
            }

            callbacks.onConfirm(orderUpdates).then(() => {
                setSelected({});
                setUploadedFiles({});
                setUploadedSignatures({});
                setLoading(false);
            });
        } catch (e) {
            captureException(e);
            setLoading(false);
        }
    };

    return (
        <MobileDialog
            open={open}
            onClose={() => {
                setSelected({});
                callbacks.onClose();
            }}
        >
            <ModalContent
                css={css`
                    width: auto;
                `}
            >
                <Grid
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Header2>{title}</Header2>
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-top: 30px;
                        margin-bottom: 4px;
                    `}
                >
                    <Header1>Items</Header1>
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-bottom: 8px;
                        color: ${colors.greys[0]};
                    `}
                >
                    <Body1>{itemsHelperText}</Body1>
                </Grid>
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 30px;
                    `}
                >
                    <Grid direction="column" container>
                        <Grid
                            direction="row"
                            container
                            item
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                            >
                                <OnwardCheckbox
                                    checked={items.map((item) => item.item_id).every((id) => selected[id])}
                                    onChange={() => {
                                        setSelected((prev) => {
                                            const ids = items.map((item) => item.item_id);
                                            const checked = !ids.every((id) => selected[id]);

                                            return {
                                                ...prev,
                                                ...Object.fromEntries(ids.map((id) => [id, checked ? true : false])),
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                            >
                                <Header1>Select All</Header1>
                            </Grid>
                        </Grid>

                        {items.map((item) => {
                            return (
                                <ItemCheckbox
                                    key={item.item_id}
                                    item={item}
                                    checked={selected[item.item_id]}
                                    callbacks={{
                                        onCheck: (id) =>
                                            setSelected((prev) => {
                                                return {
                                                    ...prev,
                                                    [id]: !prev[id],
                                                };
                                            }),
                                    }}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-top: 30px;
                        margin-bottom: 4px;
                    `}
                >
                    <Header1>Documents</Header1>
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-bottom: 8px;
                        color: ${colors.greys[0]};
                    `}
                >
                    <Body1>Upload all required documents.</Body1>
                </Grid>
                {documents.map((doc) => (
                    <FileUpload
                        key={`file-upload-${doc.field}`}
                        name={doc.name}
                        required={doc.required}
                        files={uploadedFiles[doc.field] || []}
                        callbacks={{
                            setFiles: (newFiles) => setUploadedFiles((prev) => ({ ...prev, [doc.field]: newFiles })),
                        }}
                    />
                ))}
                {signatures.map((sig) => (
                    <Signature
                        key={`signature-${sig.field}`}
                        name={sig.name}
                        required={sig.required}
                        file={uploadedFiles[sig.field] || {}}
                        callbacks={{
                            setFile: (newFile) => setUploadedSignatures((prev) => ({ ...prev, [sig.field]: newFile })),
                        }}
                    />
                ))}
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton
                        onClick={() => {
                            setSelected({});
                            callbacks.onClose();
                        }}
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton disabled={!isValid || loading} onClick={onConfirm}>
                        Complete
                    </PrimaryButton>
                </Grid>
            </StickyModalActions>
        </MobileDialog>
    );
};

export default ChecklistModal;
