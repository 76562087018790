import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Grid,
    InputAdornment,
    LinearProgress,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { asBrowserDate } from '@/utilities/convertToISO';
import { useClientUser } from '@/hooks';
import { ACCOUNTING_V2 } from '@/constants/featureFlags';
import * as ROUTES from '@/constants/routes';
import Table from './table';
import { css } from '@emotion/react';
import { OnwardTab, OnwardTabContainer } from '../Tabs';
import { CustomCheckbox, PrimaryHeaderLabel, FilterHeader } from './blocks';
import { Search } from '@material-ui/icons';

const CompletedOrders = ({ state, loading, callbacks, Footer }) => {
    const { filters, shippers } = state;
    const navigate = useNavigate();
    const { circles, roles } = useClientUser();
    const enabled = !!circles?.[ACCOUNTING_V2] || roles['ADMIN'];

    return (
        <>
            <Grid
                direction="row"
                item
                css={css`
                    display: flex;
                    justify-content: center;
                    background-color: white;
                `}
            >
                <OnwardTabContainer value={0} textColor="primary" indicatorColor="primary" centered>
                    <OnwardTab
                        label={
                            !enabled ? (
                                <Grid
                                    direction="row"
                                    container
                                    css={css`
                                        justify-content: center;
                                    `}
                                >
                                    <Grid
                                        direction="column"
                                        container
                                        css={css`
                                            flex-basis: 0;
                                        `}
                                    >
                                        <LockIcon
                                            css={css`
                                                font-size: 20px;
                                                justify-content: center;

                                                margin-right: 8px;
                                            `}
                                        />
                                    </Grid>
                                    <Grid
                                        direction="column"
                                        container
                                        css={css`
                                            flex-basis: 0;
                                        `}
                                    >
                                        Internal
                                    </Grid>
                                </Grid>
                            ) : (
                                <>Internal</>
                            )
                        }
                        disabled={!enabled}
                    />
                    <OnwardTab
                        label="Onward"
                        onClick={() => {
                            navigate(ROUTES.CARRIER_ACCOUNTING_V2);
                        }}
                    />
                </OnwardTabContainer>
            </Grid>

            <Grid
                container
                item
                direction="row"
                css={css`
                    padding: 40px;
                    padding-bottom: 0;
                    margin-bottom: 12px;
                `}
            >
                <PrimaryHeaderLabel>Completed Orders</PrimaryHeaderLabel>
            </Grid>

            <Grid
                container
                item
                direction="row"
                justifyContent="flex-start"
                css={css`
                    padding: 0 40px;
                    margin-bottom: 30px;
                    align-items: flex-end;
                `}
            >
                <Grid
                    direction="column"
                    container
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        align-items: flex-end;
                        margin-right: 8px;
                    `}
                >
                    <Grid
                        direction="row"
                        item
                        css={css`
                            align-self: flex-start;
                            margin-bottom: 4px;
                        `}
                    >
                        <FilterHeader>Completion Date</FilterHeader>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        css={css`
                            flex-wrap: nowrap;
                        `}
                    >
                        <Grid
                            item
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                margin-right: 8px;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="medium"
                                InputLabelProps={{ shrink: true }}
                                name="completedTo"
                                label="Start Date"
                                value={filters.completedFrom}
                                onChange={(e) =>
                                    callbacks.setFilters((prev) => ({
                                        completedFrom: asBrowserDate(e.target.value).getTime(),
                                    }))
                                }
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    padding-right: 2px;
                                    margin-top: 0.2rem;
                                `}
                            />
                        </Grid>

                        <Grid
                            item
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="medium"
                                InputLabelProps={{ shrink: true }}
                                name="completedTo"
                                label="End Date"
                                value={filters.completedTo}
                                onChange={(e) =>
                                    callbacks.setFilters(() => ({
                                        completedTo: asBrowserDate(e.target.value).getTime(),
                                    }))
                                }
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    padding-left: 2px;
                                    margin-top: 0.2rem;
                                `}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    direction="column"
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        margin-right: 8px;
                    `}
                >
                    <FormControl
                        css={css`
                            width: 100%;
                            background-color: white;
                        `}
                        variant="outlined"
                    >
                        <InputLabel
                            shrink
                            id="partner-shipper"
                            css={css`
                                background: #fff;
                            `}
                        >
                            Shipper
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="partner-shipper"
                            id="select-partner-shipper"
                            multiple
                            value={filters?.selectedShippers || []}
                            renderValue={(selectedShippersLocal) =>
                                selectedShippersLocal.length > 0
                                    ? shippers
                                          .reduce((acc, shipper) => {
                                              if (selectedShippersLocal.includes(shipper.key)) {
                                                  acc.push(shipper.label);
                                              }
                                              return acc;
                                          }, [])
                                          .join(', ')
                                    : ''
                            }
                        >
                            {shippers?.map((shipper, i) => (
                                <MenuItem key={shipper + i} value={shipper.label}>
                                    <CustomCheckbox
                                        checked={
                                            filters?.selectedShippers.length > 0
                                                ? filters?.selectedShippers.indexOf(shipper.key) > -1
                                                : false
                                        }
                                        onClick={() => {
                                            callbacks.handleShipperChange(shipper.key);
                                        }}
                                    />

                                    <ListItemText
                                        primary={shipper.label}
                                        onClick={() => {
                                            callbacks.handleShipperChange(shipper.key);
                                        }}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    direction="column"
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <TextField
                        value={filters.search}
                        placeholder="Search Orders"
                        onChange={callbacks.handleSearch}
                        variant="outlined"
                        color="primary"
                        size="medium"
                        css={css`
                            width: 100%;
                        `}
                        InputProps={{
                            style: { backgroundColor: 'white' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>

            {loading.init && (
                <Grid
                    item
                    css={css`
                        margin: 0 2rem;
                    `}
                >
                    <LinearProgress
                        color="primary"
                        css={css`
                            width: 100%;
                        `}
                    />
                </Grid>
            )}

            <Grid
                item
                css={css`
                    overflow: hidden;
                    flex-grow: 1;
                    padding: 0 40px;
                `}
            >
                <Table loading={loading} state={state} callbacks={callbacks} />
            </Grid>

            <Footer state={state} callbacks={callbacks} />
        </>
    );
};

export default CompletedOrders;
