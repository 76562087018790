import React, { useState, useEffect } from 'react';
import { TextField, Grid } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { genAttributes } from '@onward-delivery/core';
import { asDateInTZ } from '@/utilities/convertToISO';
import dateFns from '@/utilities/dateFns';

import {
    ResponsiveSidebarDialog,
    ModalTitle,
    ModalContent,
    StickyModalActions,
    PrimaryButton,
    SecondaryButton,
} from '../../../styles/blocks';

import { UPDATE_ORDER_DELIVERY_DATE } from '../mutations';
import { useMutation } from '@apollo/client';

const DateInput = ({ value, onChange, error }) => {
    const today = new Date();
    const year = today.getFullYear();
    let temp = today.getMonth() + 1; // month returns 0 - 11
    const month = temp < 10 ? `0${temp}` : temp;
    temp = today.getDate();
    const day = temp < 10 ? `0${temp}` : temp;
    const minDate = `${year}-${month}-${day}`;

    const transformDate = (date) => {
        if (date && date.includes('/')) {
            const [month, day, year] = date.split('/');
            return `${year}-${month}-${day}`;
        }

        return date || '';
    };

    return (
        <TextField
            type="date"
            variant="outlined"
            size="small"
            label="Scheduled delivery date"
            style={{ width: '100%' }}
            InputProps={{
                style: { fontWeight: 500 },
                inputProps: {
                    min: minDate,
                },
            }}
            InputLabelProps={{ shrink: true }}
            name="delivery_date"
            value={transformDate(value)}
            onChange={onChange}
            error={error}
            helperText={null}
        />
    );
};

const ScheduledDeliveryDateModal = (props) => {
    const { order, open, onClose } = props;

    const [deliveryDate, setDeliveryDate] = useState(order.delivery_date);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (deliveryDate || !order) return;
        setDeliveryDate(order.delivery_date);
    }, [order]);

    const [updateDeliveryDate] = useMutation(UPDATE_ORDER_DELIVERY_DATE);

    const getLocalTimeDate = (date) => {
        const { zip } = genAttributes(order);
        const tz = order[zip] ? zipcode_to_timezone.lookup(order[zip]) : 'America/New_York';
        return asDateInTZ(date, tz).toISOString();
    };

    const onSave = async () => {
        setLoading(true);

        try {
            await updateDeliveryDate({
                variables: {
                    order_id: order.order_id,
                    delivery_date: getLocalTimeDate(deliveryDate),
                },
            });
        } catch (e) {
            console.error(e);
            Sentry.captureException({
                deliveryDate,
                formattedDeliveryDate: getLocalTimeDate(deliveryDate),
                order_number: order.order_number,
                error_message: e.message,
            });
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <ResponsiveSidebarDialog open={open} onClose={onClose}>
            <ModalTitle title="Scheduled Delivery Date" onClose={onClose} />
            <ModalContent width={500}>
                <Grid className="mb-3" alignItems="center" container spacing={2}>
                    <Grid item xs={6}>
                        <DateInput
                            value={dateFns.isoToFormatted(deliveryDate)}
                            onChange={(e) => setDeliveryDate(getLocalTimeDate(e.target.value))}
                            error={null}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <PrimaryButton red onClick={() => setDeliveryDate('')}>
                            Clear date
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <SecondaryButton disabled={loading} onClick={onClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={onSave}>
                    Save
                </PrimaryButton>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default ScheduledDeliveryDateModal;
