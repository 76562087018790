import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSticky } from 'react-table-sticky';
import { useTable, useFlexLayout, useSortBy, useRowSelect } from 'react-table';

import { TableContainer } from '@material-ui/core';
import HeaderCell from '@/components/CarrierAccountingOrders/table/HeaderCell';
import BodyCell from '@/components/CarrierAccountingOrders/table/BodyCell';
import BodyRow from '@/components/CarrierAccountingOrders/table/BodyRow';
import { IndeterminateCheckbox } from '@/components/DispatchUnassigned/table';

const InvoiceTable = ({ state, callbacks = {} }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: tableState,
        setSortBy,
    } = useTable(
        {
            columns: state.invoiceColumns,
            data: state.invoices,
            getRowId: (invoice) => callbacks.getId(invoice),
            autoResetHiddenColumns: false,
            initialState: { selectedRowIds: state.selected, sortBy: [{ id: 'invoice_number', desc: true }] },
            enableRowSelection: true,
            enableMultiRowSelection: true,
            autoResetSelectedRows: true,
        },
        useSortBy,
        useRowSelect,
        useSticky,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    sticky: 'left',
                    width: 50,
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            onClick={(e) => e.stopPropagation()}
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        callbacks.setSelected(tableState?.selectedRowIds);
    }, [tableState?.selectedRowIds]);

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.reduce(
                            (acc, subgroup) => [
                                ...acc,
                                subgroup.headers.map((col) => {
                                    return (
                                        <HeaderCell
                                            {...col.getHeaderProps()}
                                            key={col.id}
                                            cell={col}
                                            align={col.align}
                                        />
                                    );
                                }),
                            ],
                            []
                        )}
                    </div>
                );
            }

            //BODY
            const row = rows[index - 1];
            prepareRow(row);

            return (
                <BodyRow
                    {...row.getRowProps({
                        style,
                    })}
                    row={row}
                    onClick={() => callbacks.gotoInvoice(row)}
                >
                    {row.cells.map((cell) => (
                        <BodyCell
                            {...cell.getCellProps()}
                            cell={cell}
                            align={cell.column.align}
                            css={css`
                                overflow: hidden;
                            `}
                        />
                    ))}
                </BodyRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
                background: #fff;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList height={height} itemCount={rows.length + 1} itemSize={57} width={width}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </TableContainer>
    );
};

export default InvoiceTable;
