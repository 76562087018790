import { CARRIER_INVOICE_FIELDS } from '@/graphql/queries/carrier_invoices';
import { gql } from '@apollo/client';

export const INSERT_INVOICES = gql`
    ${CARRIER_INVOICE_FIELDS}
    mutation InsertCarrierInvoices($invoices: [carrier_invoices_insert_input!]!) {
        insert_carrier_invoices(objects: $invoices) {
            affected_rows
            returning {
                ...CarrierInvoiceFields
                orders {
                    order_id
                    carrier_invoice_id
                    shipper_invoice_id
                }
            }
        }
    }
`;

export const READY_TO_INVOICE = gql`
    mutation ReadyToInvoice($order_ids: [uuid!]!) {
        update_orders(where: { order_id: { _in: $order_ids } }, _set: { ready_to_invoice: true }) {
            affected_rows
            returning {
                order_id
                ready_to_invoice
            }
        }
    }
`;
