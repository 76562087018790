import React from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        width: 'fit-content',
        height: 'fit-content',
    },
    buttonProgress: {
        color: '#42b52c',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

function ButtonWithSpinner(props) {
    const classes = useStyles();

    return (
        <div className={classes.wrapper} {...props.wrapperProps}>
            <Button
                variant="contained"
                color={props.buttonProps.disablePrimaryColor ? undefined : 'primary'}
                className={classes.button}
                disabled={props.disabled !== undefined ? props.disabled : props.loading}
                {...props.buttonProps}
            >
                {props.buttonLabel ? props.buttonLabel : props.children}
            </Button>
            {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
}

ButtonWithSpinner.propTypes = {
    /** State variable to indicate a process is 'loading' */
    loading: PropTypes.bool.isRequired,
    /** Label (string or JSX node) for button label */
    buttonLabel: PropTypes.any,
    /** Additional props for the button element */
    buttonProps: PropTypes.object,
    /** Additional props for the button's wrapper */
    wrapperProps: PropTypes.object,
};

export default ButtonWithSpinner;
