import React, { useState, useContext, useMemo, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Typography, Grid, Button, createTheme, ThemeProvider, TextField } from '@material-ui/core';
import Image from 'react-bootstrap/Image';
import * as Sentry from '@sentry/react';
import { UserContext } from '../App';
import { OnwardCard } from './Card';
import uploadPhotoAsync from '../../utilities/uploadPhotoAsync';
import { saveAs } from 'file-saver';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {
    ResponsiveSidebarDialog,
    ModalTitle,
    ModalContent,
    StickyModalActions,
    PrimaryButton,
    SecondaryButton,
} from '../../styles/blocks';
import { UPDATE_ORDER_BY_ID } from '@/graphql/mutations/orders';
import { asBrowserDate, asDateInTZ } from '@/utilities/convertToISO';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { format } from 'date-fns';

function CustomerSignature(props) {
    const { order, category, editable } = props;
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const cropperRef = useRef(null);
    const [signeeName, setSigneeName] = useState('');
    const [signatureDate, setSignatureDate] = useState('');
    const [metaSaved, setMetaSaved] = useState(false);

    const [saveSignature] = useMutation(UPDATE_ORDER_BY_ID, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
        onCompleted: () => {
            setOpen(false);
        },
    });

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    };

    const signatureUri = useMemo(() => {
        return order.customer_signature;
    }, [order]);

    const orderTZ = useMemo(() => {
        return order.pickup_zip ? zipcode_to_timezone.lookup(order.pickup_zip) : 'America/New_York';
    }, [order]);

    useEffect(() => {
        setSigneeName(order?.customer_signature_meta?.name || '');
        setSignatureDate(order?.customer_signature_meta?.date || '');
    }, [order]);

    const handleSave = async () => {
        const fbUrl = await uploadPhotoAsync(croppedImage, 'signature', 'jpg');
        saveSignature({
            variables: {
                order_id: order.order_id,
                update: {
                    customer_signature: fbUrl,
                },
            },
        });
    };

    const handleSaveSigMeta = async () => {
        saveSignature({
            variables: {
                order_id: order.order_id,
                update: {
                    customer_signature_meta: {
                        date: signatureDate,
                        name: signeeName,
                    },
                },
            },
            onCompleted: () => setMetaSaved(true),
        });
    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleChange = async (event) => {
        const url = await uploadPhotoAsync(URL.createObjectURL(event.target.files[0]), 'signature', 'jpg');
        setSelectedImage(url);
        setOpen(true);
    };

    async function handleDownload() {
        saveAs(signatureUri, `${order.order_number}-CustomerSignature`);
    }

    const theme = createTheme({
        palette: {
            text: {
                secondary: '#4c4c4c',
            },
            primary: {
                main: '#59b863',
                contrastText: '#fff',
            },
            secondary: {
                main: '#4c4c4c',
                contrastText: '#fff',
            },
        },
        typography: {
            fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
            h6: {
                color: '#8d8d8d',
                fontWeight: '850',
            },
            body1: {
                color: '#878686',
                fontWeight: '530',
                fontSize: 14,
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <div className="d-flex flex-row align-items-center mt-5">
                <Typography variant="h6" className="mt-3" color="secondary">
                    Customer Signature
                </Typography>
            </div>
            <div className="d-flex flex-row align-items-center mb-2">
                <Typography variant="body1">
                    By signing below, Consignee acknowledges that freight was received in good order and no damage
                    occurred to Consignee's property.
                </Typography>
            </div>

            <OnwardCard>
                <Grid container direction="column" spacing={1}>
                    <Grid container spacing={1}>
                        <a href={signatureUri} target="_blank">
                            <Image src={signatureUri} />
                        </a>

                        {!signatureUri && (
                            <Grid item className="d-flex">
                                <Typography variant="body2">No signature uploaded yet</Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container direction="row" spacing={2} alignItems="center" className="mt-3">
                        <Grid item sm={4}>
                            <TextField
                                fullWidth
                                label="Date & Time"
                                type="datetime-local"
                                variant="outlined"
                                value={signatureDate ? format(new Date(signatureDate), 'yyyy-MM-dd HH:mm:ss') : ''}
                                onChange={(e) => {
                                    let dateStr = null;
                                    if (e.target.value) {
                                        dateStr = new Date(e.target.value).toISOString();
                                    }
                                    setSignatureDate(dateStr);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                fullWidth
                                label="Signee Name"
                                variant="outlined"
                                value={signeeName}
                                onChange={(e) => setSigneeName(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={4} className="px-3">
                            <Button
                                style={{
                                    width: '50%',
                                }}
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={handleSaveSigMeta}
                            >
                                {metaSaved ? 'Saved!' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </OnwardCard>
            <Grid container className="mt-3" justifyContent="space-between">
                {signatureUri && (
                    <Grid item>
                        <Button
                            style={{
                                padding: '5px',
                            }}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={handleDownload}
                            disabled={!signatureUri}
                        >
                            Download
                        </Button>
                    </Grid>
                )}
                {editable && (
                    <Grid item>
                        <Button
                            style={{
                                padding: '5px',
                            }}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={handleClick}
                        >
                            Upload New Signature
                        </Button>
                        <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
                    </Grid>
                )}
            </Grid>
            <ResponsiveSidebarDialog open={open} onClose={() => setOpen(false)}>
                <ModalTitle title="Crop Signature" onClose={() => setOpen(false)} />
                <ModalContent width={700}>
                    <Grid className="mb-3" alignItems="center" container spacing={2}>
                        <Cropper
                            src={selectedImage}
                            aspectRatio={3.5 / 1}
                            guides={false}
                            crop={onCrop}
                            ref={cropperRef}
                            dragMode="move"
                        />
                    </Grid>
                </ModalContent>
                <StickyModalActions>
                    <SecondaryButton onClick={() => setOpen(false)}>Cancel</SecondaryButton>
                    <PrimaryButton onClick={handleSave}>Save</PrimaryButton>
                </StickyModalActions>
            </ResponsiveSidebarDialog>
        </ThemeProvider>
    );
}

export default CustomerSignature;
