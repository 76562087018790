import { gql } from '@apollo/client';
import { STOP_FIELDS } from '../queries/stops';
import { ROUTE_FIELDS } from '../queries/routes';
import { ORDER_FIELDS } from '../queries/orders';
import { ORDER_ROUTE_MAPPINGS } from '../queries/order_route_mappings';

const ROUTES_SUBSCRIPTION = gql`
    ${ROUTE_FIELDS}
    ${ORDER_FIELDS}
    ${ORDER_ROUTE_MAPPINGS}
    ${STOP_FIELDS}
    subscription routes(
        $testCondition: routes_bool_exp!
        $searchCondition: routes_bool_exp!
        $shipperFilterCondition: routes_bool_exp!
        $carrierFilterCondition: routes_bool_exp!
        $startDateFilterCondition: routes_bool_exp!
        $endDateFilterCondition: routes_bool_exp!
        $stateFilterCondition: routes_bool_exp!
    ) {
        routes(
            limit: 250
            order_by: { created_at: desc }
            where: {
                _and: [
                    $testCondition
                    $searchCondition
                    $shipperFilterCondition
                    $carrierFilterCondition
                    $startDateFilterCondition
                    $endDateFilterCondition
                    $stateFilterCondition
                ]
            }
        ) {
            ...RouteFields
            stopsByRouteId {
                ...StopFields
            }
            orders {
                ...OrderRouteMappings
                order {
                    ...OrderFields
                }
            }
        }
    }
`;

const FTLBOARD_SUBSCRIPTION = gql`
    ${ROUTE_FIELDS}
    ${ORDER_FIELDS}
    ${ORDER_ROUTE_MAPPINGS}
    ${STOP_FIELDS}
    subscription routes($testCondition: routes_bool_exp!, $statusCondition: routes_bool_exp!) {
        routes(limit: 25, order_by: { created_at: desc }, where: { _and: [$testCondition, $statusCondition] }) {
            ...RouteFields
            stopsByRouteId {
                ...StopFields
            }
            orders {
                ...OrderRouteMappings
                order {
                    ...OrderFields
                }
            }
        }
    }
`;

export { ROUTES_SUBSCRIPTION, FTLBOARD_SUBSCRIPTION };
