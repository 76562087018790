import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../App';

const withAdminRights = (Component) => (props) => {
    const navigate = useNavigate();

    const { user } = useContext(UserContext);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (user) {
            if (user.roles?.ADMIN) {
                setIsAdmin(true);
            } else {
                navigate('/account');
            }
        }
    }, [user]);

    return isAdmin ? <Component {...props} /> : null;
};

export default withAdminRights;
