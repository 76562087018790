import { gql } from '@apollo/client';
import { RESOURCE_OVERRIDE_MAPPING_FIELDS } from './resource_override_mappings';

export const RESOURCE_RULE_MAPPING_FIELDS = gql`
    ${RESOURCE_OVERRIDE_MAPPING_FIELDS}

    fragment ResourceRuleMappingFields on resource_rule_mappings {
        mapping_id
        resource_id
        rule_end
        rule_start
        rule_type
        overrides {
            ...ResourceOverrideMappingFields
        }
    }
`;
