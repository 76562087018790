import { ITEM_TYPES, FREIGHT_TYPES } from '@/components/ShipmentForm/constants/freightTypes';
import { startCase } from 'lodash';

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
});

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const QUICKBOOKS_CSV_COLUMNS = [
    {
        header: 'Invoice No.',
        getValue: ({ invoice, order }) =>
            invoice.invoice_number ? invoice.invoice_number + '-' + order.order_number : '',
    },
    {
        header: 'Customer',
        getValue: ({ invoice }) => {
            let customer = invoice?.partner_client?.business_name || '';
            return `"${customer}"`;
        },
    },
    {
        header: 'Email',
        getValue: ({ invoice }) => invoice?.partner_client?.business_email || '',
    },
    {
        // No place for them to enter this in our app
        header: 'Terms',
        getValue: () => '',
    },
    {
        header: 'Invoice Date',
        getValue: ({ invoice }) => {
            if (!invoice.created_at) {
                return undefined;
            }
            const date = new Date(invoice?.created_at);
            return isNaN(date) ? undefined : date;
        },
        format: (value) => `"${dateNumeric.format(value)}"`,
    },
    {
        header: 'Due Date',
        getValue: ({ invoice }) => {
            if (!invoice.due_date) {
                return undefined;
            }
            const date = new Date(invoice?.due_date);
            return isNaN(date) ? undefined : date;
        },
        format: (value) => `"${dateNumeric.format(value)}"`,
    },
    {
        // No place for them to enter this in our app
        header: 'Location',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Location Of Sale',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Shipping To',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Shipping Via',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Shipping Date',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Tracking No.',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Purchase Order',
        getValue: ({ order }) => {
            return order?.oms ? order?.po_number : order?.order_number || '';
        },
    },
    {
        // No place for them to enter this in our app
        header: 'Account Number',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Cubic Feet',
        getValue: () => '',
    },
    // No place for them to enter this in our app
    {
        header: 'Product/Service',
        getValue: ({ row }) => {
            return row?.inv_product || '';
        },
    },
    {
        // No place for them to enter this in our app
        header: 'Service Date',
        getValue: ({ order }) => {
            if (!order?.delivery_date) {
                return undefined;
            }
            const date = new Date(order.delivery_date);
            return isNaN(date) ? undefined : date;
        },
        format: (value) => {
            if (value instanceof Date && !isNaN(value)) {
                return `"${dateNumeric.format(value)}"`;
            }
            return '""'; // Return an empty string in quotes if the date is invalid
        },
    },
    {
        // No place for them to enter this in our app
        header: 'SKU',
        getValue: () => '',
    },
    {
        header: 'Description',
        getValue: ({ order, description }) => {
            let descriptors;
            if (order.itemsByOrderId) {
                descriptors = order.itemsByOrderId.map((item) => {
                    return `${
                        item.item_type
                            ? ITEM_TYPES[item.item_type]?.find((el) => el.value === item.item_type_details)?.label ||
                              FREIGHT_TYPES.find((el) => el.value === item.item_type)?.label ||
                              'Item'
                            : 'Item'
                    }: ${item.description ? `${item.description} ` : ''}${item.quantity} Count - `;
                });
            } else {
                descriptors = [];
            }

            let orderNumber = order.oms ? order?.po_number : order.order_number;

            let text = orderNumber + ' : ' + descriptors.join(', ');
            text += description;
            return `"${text}"`;
        },
    },
    {
        header: 'Qty',
        getValue: ({ quantity }) => {
            return quantity;
        },
    },
    {
        // Rate = amount / qty
        header: 'Rate',
        getValue: ({ rate }) => {
            return dollarFormatter.format(rate || 0);
        },
    },
    {
        header: 'Amount',
        getValue: ({ total }) => {
            let amount = dollarFormatter.format(total || 0);
            return `"${amount}"`;
        },
    },
    {
        // No place for them to enter this in our app
        header: 'Class',
        getValue: ({ qbo_class }) => {
            return qbo_class;
        },
    },
    {
        // No place for them to enter this in our app
        header: 'Tax',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Memo',
        getValue: ({ order }) => {
            return order.oms ? order?.po_number : order.order_number;
        },
    },
    {
        // No place for them to enter this in our app
        header: 'Message on Invoice',
        getValue: () => '',
    },
    {
        // No place for them toggle this in our app.
        header: 'Send Later',
        getValue: () => '',
    },
    {
        // Leave this. We put subtotal under 'amount' column
        header: 'Subtotal',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Taxable Subtotal',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Sales Tax Amount',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Shipping Amt',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Tax on Shipping',
        getValue: () => '',
    },
    {
        // Leave this for quickbooks to auto-fill
        header: 'Total',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Attachments',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Order Type',
        getValue: ({ order }) => {
            return startCase(order?.order_type || '');
        },
    },
];
