import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import * as Sentry from '@sentry/react';
/* eslint-disable no-async-promise-executor */

/**
 * Use react-places-autocomplete's `geocodeByAddress` utility to hit Google's
 * geocoding API with an address string
 *
 * @param {object}  addressUpdate   object of the form `{ address: <string> }` where
 *                                  `address` is a string of the address to geocode
 * @returns         Promise to return an object with the results of the call to the geocoding API
 */

export default async function handleGeocode({ address }) {
    return new Promise(async (resolve, reject) => {
        try {
            const toReturn = {};
            const results = await geocodeByAddress(address);
            const valid = results.filter((result) => !result.partial_match);
            const components = valid[0].address_components;

            // console.clear()
            // console.log(components)

            // Geocoding sometimes finds incorrect locations with similar addresses. This prevents extreme cases such as Australia
            let country = components.find((component) => component.types.includes('country'));
            if (country?.short_name && !['US', 'USA'].includes(country.short_name))
                throw new Error(`Geocoding returned non US location ${address} -> ${results[0].formatted_address}`);

            let streetNum = components.find((component) => component.types.includes('street_number'));
            let street = components.find((component) => component.types.includes('route'));
            let premise = components.find((component) => component.types.includes('premise'));

            // Identify "City" in google geocode response by either 'locality', 'sublocality', 'political', or 'neighborhood'
            let city = components.find((component) => component.types.includes('locality'));

            if (!city) {
                city = components.find((component) => component.types.includes('sublocality'));
            }

            if (!city) {
                city = components.find((component) => component.types.includes('political'));
            }

            if (!city) {
                city = components.find((component) => component.types.includes('neighborhood'));
            }

            let state = components.find((component) => component.types.includes('administrative_area_level_1'));
            let zip = components.find((component) => component.types.includes('postal_code'));

            if (streetNum?.long_name || street?.long_name) {
                toReturn.street =
                    (streetNum?.long_name ? streetNum.long_name : '') +
                    (street?.long_name ? ' ' + street.long_name : '');
            }
            if (city && city.long_name) {
                toReturn.city = city.long_name;
            }
            if (state && state.long_name) {
                toReturn.state = state.long_name;
            }
            if (zip && zip.long_name) {
                toReturn.zip = zip.long_name;
            }
            if (valid[0].formatted_address) {
                toReturn.fulladdress = valid[0].formatted_address;
                toReturn.address = valid[0].formatted_address;
            }
            if (premise) {
                //some addresses have premise, which autocomplete adds to the formatted address for some reason, which completely messes up the address.
                //example 1445 W commerce ave, Boise, ID, USA, if left unmodified, turns into '1445 W, 1445 W Commerce Ave, Boise, ID 83705, USA'
                let correctedAddress =
                    toReturn.street +
                    ', ' +
                    toReturn.city +
                    ', ' +
                    toReturn.state +
                    ', ' +
                    toReturn.zip +
                    ', ' +
                    components.find((component) => component.types.includes('country')).short_name;

                toReturn.fulladdress = correctedAddress;
                toReturn.address = correctedAddress;
            }

            const { lat, lng } = await getLatLng(valid[0]);
            toReturn.lat = lat;
            toReturn.lng = lng;

            resolve(toReturn);
        } catch (err) {
            console.log(err);
            reject(err);
        }
    });
}
