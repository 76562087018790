import {
    ModalTitle,
    PrimaryButton,
    ResponsiveSidebarDialog,
    SecondaryButton,
    StickyModalActions,
} from '@/styles/blocks';
import { useContext, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { BiddingDate, BiddingPrice, BiddingSwitch } from '../blocks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { UserContext } from '@/components/App';
import { useClientUser } from '@/hooks';
import { Alert } from '@material-ui/lab';

const AuctionModal = ({ open, order, listing, loading, callbacks }) => {
    const [modifiedListing, setModifiedListing] = useState(listing);
    const { superUser } = useContext(UserContext);
    const { circles } = useClientUser();

    const isAdmin = !!superUser?.user_id;
    const contractRates = circles?.['hide-market-rates'];

    const tz = useMemo(() => {
        return order?.dropoff_zip
            ? zipcode_to_timezone.lookup(order?.dropoff_zip)
            : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [order]);

    useEffect(() => {
        setModifiedListing(listing);
    }, [listing]);

    if (!order || !listing) {
        return null;
    }

    return (
        <ResponsiveSidebarDialog
            open={open}
            onClose={callbacks.onClose}
            maxWidth="md"
            css={css`
                height: fit-content;
                .MuiDialog-container {
                    align-items: normal;
                }
            `}
        >
            <ModalTitle border title={`Onward Marketplace - ${order.order_number}`} onClose={callbacks.onClose} />
            <Grid
                container
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                    width: 100%;
                    max-width: 450px;
                    padding: 1rem;
                `}
            >
                <BiddingSwitch
                    checked={modifiedListing?.listing_type === 'all'}
                    onChange={(_, checked) =>
                        setModifiedListing((prev) => ({ ...prev, listing_type: checked ? 'all' : 'claim_now' }))
                    }
                />
                <BiddingDate
                    selectedDate={modifiedListing?.expiration_date}
                    tz={tz}
                    onChange={(val) => setModifiedListing((prev) => ({ ...prev, expiration_date: val }))}
                />
            </Grid>
            <StickyModalActions border="true">
                <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                <PrimaryButton
                    disabled={loading}
                    onClick={() => callbacks.onSave({ listing: { ...modifiedListing, order_id: order.order_id } })}
                >
                    Save
                </PrimaryButton>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default AuctionModal;
