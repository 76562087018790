import React, { useState, useMemo } from 'react';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import { PrimaryButton } from '@/styles/blocks';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { genName as genSubregionName } from '@/components/Subregions/utils';
import { Header1, Body1 } from './blocks';

const AssignToSubregionPopover = ({ subregions, selectedZips, loading, callbacks }) => {
    const [id, setId] = useState(null);

    return (
        <Grid
            container
            direction="column"
            css={css`
                position: absolute;
                width: 275px;
                bottom: 0;
                right: 32px;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                border: 1px solid #00000052;
                background: ${colors.white.primary};
            `}
        >
            <Grid
                container
                direction="row"
                css={css`
                    align-items: space-between;
                    padding: 16px 10px;
                    border-bottom: 1px solid #e2e2e2;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        justify-content: center;
                        align-items: flex-start;
                    `}
                >
                    <Header1>Assign to</Header1>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        justify-content: center;
                        align-items: flex-end;
                    `}
                >
                    <Body1>{`${selectedZips.length} zipcodes`}</Body1>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    padding: 24px 10px;
                    border-bottom: 1px solid #e2e2e2;
                `}
            >
                <TextField
                    select
                    disabled={subregions?.length === 0}
                    variant="outlined"
                    label="Subregion"
                    value={id || ''}
                    onChange={(e) => {
                        setId(e.target.value);
                    }}
                    InputLabelProps={{
                        shrink: !!id,
                    }}
                    fullWidth
                >
                    {subregions.map((subregion, idx) => (
                        <MenuItem key={subregion.subregion_id} value={subregion.subregion_id}>
                            {genSubregionName(subregion, idx)}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    padding: 16px 10px 8px;
                `}
            >
                <PrimaryButton
                    fullWidth
                    onClick={() => {
                        callbacks.insertMappings({ subregion_id: id, zips: selectedZips });
                    }}
                    disabled={loading.insertZipMappings}
                >
                    <Header1>Assign</Header1>
                </PrimaryButton>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    padding: 8px 10px 16px;
                `}
            >
                <PrimaryButton
                    fullWidth
                    onClick={() => {
                        const mappings = subregions.reduce((acc, subregion) => {
                            return [
                                ...acc,
                                ...subregion.zips
                                    .filter((mapping) => selectedZips.includes(mapping.zip))
                                    .map((mapping) => mapping.mapping_id),
                            ];
                        }, []);
                        callbacks.removeMappings(mappings);
                    }}
                    disabled={loading.insertZipMappings}
                >
                    <Header1>Remove</Header1>
                </PrimaryButton>
            </Grid>
        </Grid>
    );
};

export default AssignToSubregionPopover;
