import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    IconButton,
    Grid,
    FormControl,
    Select,
    MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { UPDATE_ORDER_DRIVER } from '../mutations';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles({
    modalTitle: {
        color: '#0C0B1D',
        fontWeight: '800',
        fontSize: '28px',
        textAlign: 'center',
    },
    dialogContent: {
        width: 450,
    },
    modalLabel: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        color: '#2B2B2B',
        alignSelf: 'center',
        marginLeft: 20,
    },
});

export default function AssignDriverModal(props) {
    const { isOpen, onClose, drivers, order } = props;
    const classes = useStyles();
    const [driver, setDriver] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (order) {
            setDriver(order.driver_id);
        }
    }, [order]);

    const [updateOrderDriver] = useMutation(UPDATE_ORDER_DRIVER);

    const handleSubmit = () => {
        if (order.routes?.length > 0) {
            setErrorMessage('Cannot assign driver to a routed order');
            return;
        }

        updateOrderDriver({
            variables: { order_id: order.order_id, driver_id: driver },
            onError: (err) => {
                console.error(err);
                setErrorMessage('Error assigning driver');
            },
            onCompleted: () => {
                onClose();
            },
        });
    };

    return (
        <>
            <Dialog
                className="carrier-review-modal"
                open={isOpen}
                onClose={onClose}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                <DialogTitle style={{ margin: 0, padding: '16px 24px 0 24px' }}>
                    {onClose ? (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            style={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                    <Typography variant="h6" className={classes.modalTitle}>
                        Assign Driver
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2} className="my-2">
                        <Grid item className="d-flex" xs={3}>
                            <Typography className={classes.modalLabel}>Driver:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl style={{ width: '225px' }} variant="outlined" size="small">
                                <Select
                                    value={driver}
                                    onChange={(e) => setDriver(e.target.value)}
                                    MenuProps={{
                                        disableScrollLock: true,
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {drivers.map((driver) => (
                                        <MenuItem value={driver.teammate_id} key={driver.teammate_id}>
                                            {driver.username}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {errorMessage && (
                        <Typography className={`${classes.modalLabel} text-danger`}>{errorMessage}</Typography>
                    )}
                </DialogContent>
                <DialogActions
                    className="customer-track-rating-modal d-flex justify-content-between"
                    style={{ padding: '24px' }}
                >
                    <Button variant="outlined" onClick={onClose} style={{ color: 'red' }}>
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#59B863',
                            color: 'white',
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
