import React from 'react';
import { Grid, IconButton, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { css } from '@emotion/react';
import { SectionSubtitle2, GreyBody } from '@/styles/blocks';

import { Body1, Incrementor, TextField } from '../../../blocks';

const HaulAwayItem = ({ order, item, index, callbacks }) => {
    return (
        <Grid container direction="column">
            <Grid
                container
                item
                direction="row"
                css={css`
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                `}
            >
                <Grid
                    item
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <SectionSubtitle2>{` Item ${index + 1} - DISPOSAL`}</SectionSubtitle2>
                </Grid>
                <Grid item>
                    <IconButton
                        css={css`
                            padding: 4px;
                        `}
                    >
                        <CloseIcon
                            onClick={() => {
                                callbacks.deleteItem(index, { type: 'haulaway' });
                            }}
                            css={css`
                                color: black;
                            `}
                        />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid
                wrap="nowrap"
                container
                item
                css={css`
                    align-items: center;
                    margin-bottom: 20px;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-right: 16px;
                    `}
                >
                    <Body1
                        css={css`
                            color: #2b2b2b;
                            font-weight: 500;
                        `}
                    >
                        Quantity:
                    </Body1>
                </Grid>
                <Grid item>
                    <Incrementor
                        body={item.quantity || 0}
                        incrementCallback={() =>
                            callbacks.applyItemOperations(
                                index,
                                {
                                    quantity: (prev) => (prev.quantity || 0) + 1,
                                },
                                { type: 'haulaway' }
                            )
                        }
                        decrementCallback={() =>
                            callbacks.applyItemOperations(
                                index,
                                {
                                    quantity: (prev) => (prev.quantity > 0 ? prev.quantity - 1 : 0),
                                },
                                { type: 'haulaway' }
                            )
                        }
                    />
                </Grid>
            </Grid>

            <Grid
                item
                css={css`
                    margin-top: 12px;
                    margin-bottom: 20px;
                `}
            >
                <TextField
                    label="Description"
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={item.description}
                    onChange={(e) => callbacks.editItem(index, { description: e.target.value }, { type: 'haulaway' })}
                />
            </Grid>

            <Grid
                item
                container
                direction="row"
                css={css`
                    margin-top: 12px;
                `}
            >
                <Grid
                    item
                    css={css`
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        label="Length (in)"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.length}
                        onChange={(e) =>
                            callbacks.editItem(index, { length: parseInt(e.target.value, 10) }, { type: 'haulaway' })
                        }
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        label="Width (in)"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.width}
                        onChange={(e) =>
                            callbacks.editItem(index, { width: parseInt(e.target.value, 10) }, { type: 'haulaway' })
                        }
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1;
                    `}
                >
                    <TextField
                        label="Height (in)"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.height}
                        onChange={(e) =>
                            callbacks.editItem(index, { height: parseInt(e.target.value, 10) }, { type: 'haulaway' })
                        }
                    />
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                css={css`
                    align-items: baseline;
                    margin-top: 20px;
                    margin-bottom: 20px;
                `}
            >
                <GreyBody
                    css={css`
                        margin: 0;
                    `}
                >
                    Enter dimensions or cubic feet.
                </GreyBody>
            </Grid>

            <Grid
                item
                container
                direction="row"
                css={css`
                    margin-top: 12px;
                    margin-bottom: 20px;
                `}
            >
                <Grid
                    item
                    css={css`
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        label="Cubic Feet"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.cubes_per_unit}
                        onChange={(e) =>
                            callbacks.editItem(
                                index,
                                { cubes_per_unit: parseInt(e.target.value, 10) },
                                { type: 'haulaway' }
                            )
                        }
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        label="Weight"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.weight}
                        onChange={(e) =>
                            callbacks.editItem(index, { weight: parseInt(e.target.value, 10) }, { type: 'haulaway' })
                        }
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1;
                    `}
                />
            </Grid>
        </Grid>
    );
};
export default HaulAwayItem;
