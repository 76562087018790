import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { PICKUP_TYPES } from '@/components/ShipmentForm/constants/dropoffOptions';
import { useFormatters } from './hooks';
import { HR, Header1, Body1, Tag, Step, SubStepDesktop, SubStepMobile, Anchor } from './blocks';
import Dropoff from './Dropoff';

function Pickup({ isDesktop, isLoading, warehouse, before, after, order, callbacks }) {
    const CROSSDOCK_PICKUP_STEPS = useMemo(() => {
        return [
            ...(isDesktop
                ? [
                      {
                          description: '1. Set warehouse pickup and customer drop off date and time',
                          cta: 'Complete Task',
                          complete: ({ order, before, after }) => {
                              const isValid = (obj, field) => obj?.[field] && obj?.[field].length > 0;
                              return isValid(before, 'est_pickup_date');
                          },
                          callback: () => {
                              callbacks.editCrossdock({ event: before, stage: 'CUSTOMER' });
                          },
                      },
                  ]
                : []),
            {
                description: isDesktop ? (
                    `2. Pick up items from warehouse and update status to 'In-transit'`
                ) : (
                    <>
                        <Grid direction="row">
                            <Body1>{before.location ? before.location.location_name : '--'}</Body1>
                        </Grid>
                        <Grid direction="row">
                            <Body1>{before.location ? before.location.address : '--'}</Body1>
                        </Grid>
                        <Grid direction="row">
                            <Body1>
                                {`${before?.location?.location_type || '--'}, ${
                                    (PICKUP_TYPES[before?.location?.location_type] || []).find(
                                        (o) => o.value === before?.location?.location_info
                                    )?.label || '--'
                                }`}
                            </Body1>
                        </Grid>
                    </>
                ),
                cta: isDesktop ? 'Mark In-transit' : 'Arrived',
                complete: ({ order, before, after }) => {
                    return after?.pickup_date;
                },
                callback: ({ order, before, after }) => {
                    const NOW = new Date();
                    const prompt = (
                        <>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                <Body1>Are you sure you want to mark the order as 'In-Transit'?</Body1>
                            </Grid>
                            <Grid direction="row" container>
                                <Body1>This will let the customer and shipper know the order is on the way</Body1>
                            </Grid>
                        </>
                    );

                    const callback = () =>
                        callbacks.upsertEvent({
                            event: {
                                action: 'CD_PENDING:CONFIRM',
                                order_id: order.order_id,

                                pickup_date: NOW.toISOString(),
                            },
                            order_id: order.order_id,
                            order: {
                                order_status: 'inProgress',
                                ltl_pickup_completed: NOW.toISOString(),
                            },
                        });

                    callbacks.openChecklist(
                        {
                            content: prompt,
                            documents: [{ field: 'proof_of_pickup', name: 'Proof of Pickup', required: false }],
                            signatures: [],
                            title: 'Pick Up Order',
                            itemsHelperText: 'Checkmark each item after loading.',
                        },
                        callback
                    );
                },
            },
        ];
    }, [isDesktop, before]);

    const formatters = useFormatters(order);
    const isComplete = useMemo(() => {
        return CROSSDOCK_PICKUP_STEPS.map((step) => {
            return step.complete({ order, before, after, warehouse });
        });
    }, [before, after, order, warehouse]);

    const inProgressIdx = useMemo(() => {
        return isComplete.findIndex((x) => !x);
    }, [isComplete]);

    const allComplete = isComplete.every((x) => x);

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={allComplete}
            inProgress={!allComplete && before.status === 'RECEIVED'}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Picked up</Header1>
                        </Grid>
                        {isDesktop || allComplete ? (
                            <Grid
                                direction="column"
                                css={css`
                                    margint-right: 8px;
                                `}
                            >
                                <Tag>
                                    {isDesktop ? (
                                        <Body1
                                            css={css`
                                                margin-right: 20px;
                                            `}
                                        >{`Scheduled Pickup: ${
                                            before.est_pickup_date
                                                ? formatters.dateShort.format(new Date(before.est_pickup_date))
                                                : 'Pending'
                                        }`}</Body1>
                                    ) : null}
                                    <Body1
                                        css={css`
                                            margin-right: 20px;
                                        `}
                                    >{`Completed: ${
                                        after?.pickup_date
                                            ? formatters.dateShort.format(new Date(after.pickup_date))
                                            : 'Pending'
                                    }`}</Body1>
                                    {isDesktop ? (
                                        <Anchor
                                            onClick={() => {
                                                callbacks.editCrossdock({ event: before, stage: 'CUSTOMER' });
                                            }}
                                        >
                                            <Header1>Edit</Header1>
                                        </Anchor>
                                    ) : null}
                                </Tag>
                            </Grid>
                        ) : null}
                    </Grid>
                );
            }}
        >
            {() => (
                <>
                    {CROSSDOCK_PICKUP_STEPS.map(({ description, cta, callback }, idx) => {
                        const SubStep = isDesktop ? SubStepDesktop : SubStepMobile;

                        return (
                            <SubStep
                                isComplete={isComplete[idx]}
                                inProgress={inProgressIdx === idx}
                                isLoading={isLoading}
                                isLast={idx === CROSSDOCK_PICKUP_STEPS.length - 1}
                                description={description}
                                cta={cta}
                                secondaryCta={null}
                                callback={
                                    callback
                                        ? () => {
                                              callback({ before, after, order });
                                          }
                                        : null
                                }
                                secondaryCallback={null}
                            />
                        );
                    })}
                </>
            )}
        </Step>
    );
}

export default function CrossdockPickup({ isDesktop, ...rest }) {
    return (
        <>
            <Pickup {...rest} isDesktop={isDesktop} />
            {isDesktop ? <Dropoff {...rest} isDesktop={isDesktop} /> : null}
        </>
    );
}
