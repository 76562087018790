import {
    ModalTitle,
    PrimaryButton,
    ResponsiveSidebarDialog,
    SecondaryButton,
    StickyModalActions,
    SectionTitle,
    ModalContent,
} from '@/styles/blocks';
import React, { useContext, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { BiddingDate, BiddingPrice, BiddingSwitch } from '@/components/ShipmentForm/blocks';
import { Context } from '../store';
import { MODALS } from '../constants';
import { UserContext } from '@/components/App';
import { useClientUser } from '@/hooks';
import { Alert } from '@material-ui/lab';

const ListingEditor = ({ order, listings, setListings }) => {
    const { superUser } = useContext(UserContext);
    const { circles } = useClientUser();

    const isAdmin = !!superUser?.user_id;
    const contractRates = circles?.['hide-market-rates'];

    const listing = useMemo(() => {
        return listings[order.order_id];
    }, [order, listings]);

    const tz = useMemo(() => {
        return order?.dropoff_zip
            ? zipcode_to_timezone.lookup(order?.dropoff_zip)
            : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [order]);

    if (!order) {
        return null;
    }

    return (
        <>
            <SectionTitle
                css={css`
                    margin: 1rem 1rem -18px 1rem;
                `}
            >
                {`Onward Marketplace - ${order.order_number}`}
            </SectionTitle>
            <Grid
                container
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                    width: 100%;
                    padding: 1rem;
                    border-bottom: 1px solid lightgrey;
                    margin-bottom: 5px;
                `}
            >
                <BiddingSwitch
                    checked={listing?.listing_type === 'all'}
                    onChange={(_, checked) =>
                        setListings((prev) => ({
                            ...prev,
                            [order.order_id]: {
                                ...prev[order.order_id],
                                listing_type: checked ? 'all' : 'claim_now',
                            },
                        }))
                    }
                />
                <BiddingDate
                    selectedDate={listing?.expiration_date}
                    tz={tz}
                    onChange={(val) =>
                        setListings((prev) => ({
                            ...prev,
                            [order.order_id]: { ...prev[order.order_id], expiration_date: val },
                        }))
                    }
                />
            </Grid>
        </>
    );
};

const MultiListingsModal = ({ open, onClose }) => {
    const {
        state: { selectedOrders, listings },
        callbacks: { setModal, setListings },
    } = useContext(Context);

    return (
        <ResponsiveSidebarDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            css={css`
                .MuiDialog-container {
                    align-items: normal;
                }
            `}
        >
            <ModalTitle border title={`Set Auction Options`} onClose={onClose} />
            <ModalContent width={500}>
                <div
                    css={css`
                        height: ${selectedOrders.length < 3 ? 'fit-content' : '750px'};
                    `}
                >
                    {selectedOrders.map((order, i) => (
                        <ListingEditor key={i} order={order} listings={listings} setListings={setListings} />
                    ))}
                </div>
            </ModalContent>
            <StickyModalActions border="true">
                <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={() => setModal(MODALS.MARKETPLACE)}>Save</PrimaryButton>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default MultiListingsModal;
