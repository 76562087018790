import React, { useContext } from 'react';

import { css } from '@emotion/react';
import { UserContext } from '../App';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { colors, typography } from '@/styles';

export const ModalHeader = ({ title, subtitle, onClose, ...props }) => {
    return (
        <DialogTitle
            css={css`
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                width: 100%;
                padding-top: 1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-bottom: 0.5rem;
            `}
            {...props}
        >
            <Grid
                item
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <span
                    css={css`
                        ${typography.sansSerif}
                        font-size: 20px;
                        font-weight: 800;
                        color: ${colors.greys[2]};
                    `}
                >
                    {title}
                </span>
                {onClose ? (
                    <Close
                        css={css`
                            margin-left: 1rem;
                            color: ${colors.greys[2]};
                            cursor: pointer;
                        `}
                        onClick={onClose}
                    />
                ) : null}
            </Grid>
            {subtitle ? (
                <Grid item>
                    <span
                        css={css`
                            ${typography.sansSerif}
                            font-size: 14px;
                            font-weight: 800;
                            color: ${colors.greys[3]};
                        `}
                    >
                        {subtitle}
                    </span>
                </Grid>
            ) : null}
        </DialogTitle>
    );
};

export const ModalContent = ({ children, ...props }) => {
    return (
        <DialogContent
            css={css`
                padding: 0.5rem 1rem;
            `}
            {...props}
        >
            {children}
        </DialogContent>
    );
};

export const ModalActions = ({ children, ...props }) => {
    return (
        <DialogActions
            css={css`
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding-top: 0.5rem;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-bottom: 1rem;
            `}
            {...props}
        >
            {children}
        </DialogActions>
    );
};

const NavResponsiveModal = ({ children, ...props }) => {
    const { sidebarCollapsed, user } = useContext(UserContext);

    return (
        <Dialog
            css={css`
                margin-left: ${user ? (sidebarCollapsed ? '50px' : '240px') : '0px'};
                height: calc(100vh - 50px);
                margin-top: 50px;
            `}
            {...props}
        >
            {children}
        </Dialog>
    );
};

export default NavResponsiveModal;
