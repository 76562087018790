import React, { useMemo, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { genAttributes } from '@onward-delivery/core';
import { OnwardCard } from './Card';
import { toNational } from '@/utilities/formatPhoneNumber';
import deliveryStrings from '@/constants/deliveryStrings';
import { CardTitle, CardItem } from './blocks';
import { css } from '@emotion/react';
import { delWindowToFormattedDateRange } from '@/utilities/delWindowToFormattedDate';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { formatInTimeZone } from 'date-fns-tz';
import { asDateInTZ } from '@/utilities/convertToISO';

export default function DropOffCard({ order }) {
    const { full_address, address, unit, zip, location, location_info, location_type } = genAttributes(order);

    const timeframe = useMemo(() => {
        if (!order) return '-';
        return order.del_window_start
            ? delWindowToFormattedDateRange(order.del_window_start, order.del_window_end, order[zip])
            : '-';
    }, [order, zip]);

    const formatTimestamp = useCallback(
        (datestr) => {
            if (!datestr || !order || !zip) return 'N/A';
            const tz = zipcode_to_timezone.lookup(order[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;
            return formatInTimeZone(asDateInTZ(datestr, tz), tz, 'EEE, MMM d, yyyy');
        },
        [order, zip]
    );

    return (
        <OnwardCard
            css={css`
                height: 100%;
            `}
            contentProps={{ style: { height: '100%' } }}
        >
            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} direction="column">
                <CardTitle>{order.order_type === 'return' ? 'Return Pickup' : 'Delivery'}</CardTitle>
                <CardItem className="my-1">
                    {order.dropoff_name}
                    {['complete', 'routedComplete'].includes(order.order_status) && (
                        <CheckCircleIcon style={{ color: '#59B863' }} className="float-right" />
                    )}
                </CardItem>
                <CardItem className="my-1">{toNational(order.dropoff_phone)}</CardItem>
                <CardItem className="my-1">{order.dropoff_email || 'Email Not Provided'}</CardItem>
                <CardItem className="my-1">
                    {order[full_address] || `${order[address]}${order[unit] ? `, Unit ${order[unit]}` : ''}`}
                </CardItem>
                <CardItem className="my-1">
                    {order[location]}
                    {order[location_info] ? ', ' + order[location_info] : ''}
                    {order[location_type] ? ', ' + deliveryStrings.dropOffLocationType[order[location_type]] : ''}
                </CardItem>
                <CardItem className="my-1">
                    Scheduled Date:{' '}
                    {(order.order_type === 'return' ? order.pickup_date_formatted : order.delivery_date_formatted) ||
                        'TBD'}
                </CardItem>
                <CardItem className="my-1">Scheduled Timeframe: {timeframe}</CardItem>
                <CardItem className="my-1">Arrival Time: {formatTimestamp(order.ltl_dropoff_arrival)}</CardItem>
                <CardItem className="my-1">Completion Time: {formatTimestamp(order.completion_time)}</CardItem>
                <CardItem className="mt-1 mb-4">
                    Comments: {order.dropoff_comments ? order.dropoff_comments : 'None'}
                </CardItem>
            </Grid>
        </OnwardCard>
    );
}
