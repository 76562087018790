import React, { useState, useContext, useMemo } from 'react';
import { Row, Container, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button, InputAdornment } from '@material-ui/core';
import {
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Menu,
    MenuItem,
    Dialog,
    DialogActions,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Table } from 'reactstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import * as Sentry from '@sentry/react';
import { teammateRoles } from '../../constants/teammateRoles';
import './ShipperAccount/styles.css';
import { UserContext } from '../App';
import { useClientUser } from '@/hooks';
import { USER_BY_EMAIL } from './queries';
import { REMOVE_TEAMMATE, UPDATE_TEAMMATE } from './mutations';
import { useLazyQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { post } from '@/utilities/onwardClient';
import { INVITE_TEAMMATE } from '@/constants/apiRoutes';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

const capitalize = (string = '') => string.slice(0, 1).toUpperCase() + string.slice(1);

const TeammateRow = ({ invoiceAccess, teamLeaderType, teammate, callbacks }) => {
    const [update, setUpdate] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const role = useMemo(() => {
        return Object.keys(teammateRoles).find((role) => teammate.roles?.[role]);
    }, [teammate]);

    const onSave = (field) => {
        const val = update[field];
        if (update[field] && update[field] !== teammate[field]) {
            callbacks.updateTeammate(teammate.teammate_id, { [field]: val }).then(() => {});
        }
    };

    return (
        <tr key={teammate.teammate_id}>
            <td>{teammate.username}</td>
            <td>{teammate.email}</td>
            <td>
                <TextField
                    variant="outlined"
                    size="small"
                    value={update.teammate_type || teammate.teammate_type || ''}
                    onChange={(e) => setUpdate((prev) => ({ ...prev, teammate_type: e.target.value }))}
                    onBlur={() => onSave('teammate_type')}
                />
            </td>
            <td>
                <CurrencyTextField
                    variant="outlined"
                    size="small"
                    value={update.hourly_rate || teammate.hourly_rate || 0}
                    onChange={(e) => setUpdate((prev) => ({ ...prev, hourly_rate: e.target.value }))}
                    onBlur={(e) => onSave('hourly_rate')}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </td>
            <td>{role ? capitalize(role) : ''}</td>
            <td>
                <div>
                    <Button className="account-btn" onClick={(e) => setAnchorEl(e.currentTarget)}>
                        Edit Roles
                    </Button>

                    <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={() => setAnchorEl(null)}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <MenuItem>
                            <RadioGroup
                                aria-label="roles"
                                name="roles00"
                                value={role}
                                onChange={(e) =>
                                    callbacks.updateTeammate(teammate.teammate_id, {
                                        roles: { [e.target.value]: true, invoicing: !!teammate.roles.invoicing },
                                    })
                                }
                            >
                                {!!teamLeaderType && (
                                    <>
                                        {Object.entries(teammateRoles).map(([role, description]) => (
                                            <FormControlLabel
                                                key={role}
                                                value={role}
                                                control={<Radio color="primary" />}
                                                label={capitalize(role)}
                                            />
                                        ))}
                                    </>
                                )}
                            </RadioGroup>
                        </MenuItem>
                    </Menu>
                </div>
            </td>
            <td>
                <Button onClick={(e) => callbacks.removeTeammate(teammate.teammate_id)} className="account-btn">
                    Remove Teammate
                </Button>
            </td>
            <td style={{ textAlign: 'center' }}>
                {capitalize(teammate.user?.device_settings?.location?.foreground.status || 'Undetermined')}
            </td>
            <td style={{ textAlign: 'center' }}>
                {capitalize(teammate.user?.device_settings?.location?.background.status || 'Undetermined')}
            </td>
            {teamLeaderType !== 'shipper' && (
                <td style={{ textAlign: 'center' }}>
                    <input
                        type="checkbox"
                        checked={teammate?.roles?.invoicing}
                        disabled={!invoiceAccess}
                        onChange={(e) =>
                            callbacks.updateTeammate(teammate.teammate_id, {
                                roles: { ...(teammate.roles || {}), invoicing: e.target.checked },
                            })
                        }
                    />
                </td>
            )}
        </tr>
    );
};

export default function Teammates(props) {
    const { currentUser, userId, teamLeaderType } = props;
    const [teammateEmail, setTeammateEmail] = useState('');
    const [teammateRole, setTeammateRole] = useState('none');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [teammateToRemoveUid, setTeammateToRemoveUid] = useState(null);
    const { user, superUser } = useContext(UserContext);
    const { user_id, circles, teammates } = useClientUser();

    const [updates, setUpdates] = useState({});

    const invoiceAccess =
        user?.roles['ADMIN'] || user?.roles['CARRIER'] || (superUser?.adminLoggedIn && teamLeaderType !== 'shipper')
            ? true
            : false;

    const [updateTeammate] = useMutation(UPDATE_TEAMMATE, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const [removeTeammate] = useMutation(REMOVE_TEAMMATE, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
        update: (cache, { data: { update_teammates } }) => {
            const removed = update_teammates?.returning?.[0];
            cache.evict(cache.identify(removed));
        },
    });

    const handleClickOpen = (_uid) => {
        setDialogOpen(true);
        setTeammateToRemoveUid(_uid);
    };

    const handleClose = () => {
        setDialogOpen(false);
        setTeammateToRemoveUid(null);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    const [getUserByEmail] = useLazyQuery(USER_BY_EMAIL);

    const sendEmailInvite = async () => {
        if (!teammateEmail) {
            setError('You must enter an email!');
            return;
        }

        setLoading(true);

        const userResponse = await getUserByEmail({ variables: { email: teammateEmail.toLowerCase().trim() } });
        if (userResponse?.data?.users?.length > 0) {
            setError('A user with this email already exists!');
            setLoading(false);
            return;
        }

        setSuccessMessage('Sending invite ...');
        return post(INVITE_TEAMMATE, {
            teamLeaderId: user_id,
            teammateRoles: { [teammateRole]: true },
            teamLeaderType,
            teammateEmail: teammateEmail.trim(),
        })
            .then(() => {
                setError(null);
                setSuccessMessage(`Your email invite was successfully sent to ${teammateEmail}!`);
            })
            .catch(({ response }) => {
                setError(
                    response?.data?.error || `Error inviting teammate` + `. Contact your Onward rep for assistance`
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleRemoveTeammate = () => {
        removeTeammate({
            variables: {
                teammate_id: teammateToRemoveUid,
            },
            onCompleted: () => {
                handleClose();
            },
        });
    };

    return (
        <Card className="tabCard">
            <ThemeProvider theme={theme}>
                <Card.Body className="">
                    <Container fluid>
                        <Form>
                            <Row className="d-flex flex-column mt-3">
                                <h5>
                                    Invite new teammates
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip placement="right">
                                                Use the text input below to invite a new team member to help you manage
                                                your Onward Delivery account. Privilages can be granted post
                                                registration.
                                            </Tooltip>
                                        }
                                    >
                                        <InfoCircleFill size={17} className="mx-1 mb-1" />
                                    </OverlayTrigger>
                                </h5>

                                <Form.Group>
                                    <TextField
                                        type="text"
                                        value={teammateEmail}
                                        variant="outlined"
                                        label="Teammate Email"
                                        onChange={(e) => setTeammateEmail(e.target.value)}
                                    />

                                    <br />
                                    <br />
                                    <FormLabel component="legend" style={{ color: 'black' }}>
                                        <b>Roles</b>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="roles"
                                        name="roles"
                                        defaultValue={teammateRole}
                                        onChange={(_, role) => setTeammateRole(role)}
                                    >
                                        {Object.entries(teammateRoles).map(([role, description]) => (
                                            <FormControlLabel
                                                key={role}
                                                value={role}
                                                control={<Radio color="primary" />}
                                                label={`${capitalize(role)}: ${description}`}
                                            />
                                        ))}
                                    </RadioGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Button
                                        onClick={sendEmailInvite}
                                        className="my-1 account-btn"
                                        disabled={loading}
                                        style={{ fontWeight: '600' }}
                                    >
                                        Send Email Invite
                                    </Button>
                                    {error && (
                                        <p
                                            style={{
                                                color: 'red',
                                                width: 'fit-content',
                                                paddingTop: '6px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            {error}
                                        </p>
                                    )}

                                    {successMessage && (
                                        <p
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'green',
                                                width: 'fit-content',
                                                padding: '6px',
                                                borderRadius: '5px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            {successMessage}
                                        </p>
                                    )}
                                </Form.Group>
                            </Row>

                            {!!teammates.length && (
                                <>
                                    <h5 className="mt-5">Manage your Teammates</h5>
                                    <Row>
                                        <Table hover style={{ width: '100%' }}>
                                            <thead>
                                                <tr
                                                    style={{
                                                        textAlign: 'center',
                                                        whitespace: 'nowrap',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    <th>Teammate</th>
                                                    <th>Email</th>
                                                    <th>Type</th>
                                                    <th>Hourly Rate</th>
                                                    <th>Current Role</th>
                                                    <th>Manage Role</th>
                                                    <th>Remove</th>
                                                    <th>Foreground Location Permission</th>
                                                    <th>Background Location Permission</th>
                                                    {teamLeaderType !== 'shipper' && <th>Invoicing Permissions</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {teammates.map((teammate) => (
                                                    <TeammateRow
                                                        invoiceAccess={invoiceAccess}
                                                        teamLeaderType={teamLeaderType}
                                                        teammate={teammate}
                                                        callbacks={{
                                                            updateTeammate: (teammate_id, update) =>
                                                                updateTeammate({ variables: { teammate_id, update } }),
                                                            removeTeammate: (teammate_id) =>
                                                                handleClickOpen(teammate_id),
                                                        }}
                                                    />
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </Container>
                </Card.Body>
                <Dialog open={dialogOpen} onClose={handleClose}>
                    <DialogTitle id="alert-dialog-title">Are you sure you want to remove this teammate?</DialogTitle>
                    <DialogActions>
                        <Button className="account-btn" onClick={handleRemoveTeammate}>
                            Yes
                        </Button>
                        <Button className="account-btn" onClick={handleClose} autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </Card>
    );
}
