import { CLIENTS } from '@/components/admin/AdminOrders/graphql';
import { useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useMemo } from 'react';

const useShippersAndCarriers = (test) => {
    const { loading, data } = useQuery(CLIENTS, {
        variables: {
            test: test
                ? { test_acc: { _eq: true } }
                : { _or: [{ test_acc: { _eq: false } }, { test_acc: { _is_null: true } }] },
        },
        onError: (e) => {
            console.error(e);
        },
    });

    return useMemo(() => {
        return [data?.shippers || [], data?.carriers || []];
    }, [data]);
};

export default useShippersAndCarriers;
