import React from 'react';
import Notification from '../../Notification';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';

const NotificationMetadata = styled.span`
    margin-top: 10px;
    font-family: Montserrat, Roboto, Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0571429px;
    margin-bottom: 0px;
    color: #2b2b2b;
`;

export default function WarehouseReceivedNotification({ notification, handleCloseNotification }) {
    const isShipper = notification.notification_data?.isShipper;

    const navigate = useNavigate();

    return (
        <Notification
            notification={notification}
            onClickHandler={() => {
                navigate(`/order/${notification.notification_data?.order_id}`);
                handleCloseNotification();
            }}
        >
            <NotificationMetadata
                css={css`
                    margin-top: 0px;
                    font-weight: 700;
                `}
            >
                Order Received by {isShipper ? 'Shipper' : 'Carrier'}
            </NotificationMetadata>
            <NotificationMetadata>Order: {notification.notification_data?.order_number}</NotificationMetadata>
            {isShipper ? (
                <NotificationMetadata>Shipper: {notification.notification_data?.shipper_name}</NotificationMetadata>
            ) : (
                <NotificationMetadata>Carrier: {notification.notification_data?.carrier_name}</NotificationMetadata>
            )}
            <NotificationMetadata
                css={css`
                    color: grey;
                `}
            >
                {format(new Date(notification.created_at), 'MMM dd, yyyy @ hh:mm a')}
            </NotificationMetadata>
        </Notification>
    );
}
