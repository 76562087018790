import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { UNLOCK_ROUTE } from '../graphql/mutations';
import * as Sentry from '@sentry/react';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';

const RouteUnlockModal = () => {
    const { modalOpen, setModalOpen, actionableRoute, setError } = useContext(PlanningContext);

    const [unlockRoute, { loading }] = useMutation(UNLOCK_ROUTE);

    const handleModalClose = () => setModalOpen(null);

    const handleUnlockRoute = () => {
        unlockRoute({
            variables: { route_id: actionableRoute?.route_id },
            onCompleted: handleModalClose,
            onError: (error) => {
                setError(error, 'Error unlocking route');
            },
        });
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ROUTE_UNLOCK} onClose={handleModalClose}>
            <ModalTitle>{`Unlock Route ${actionableRoute?.route_number}`}</ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <Body2>Are you sure you want to make changes to this route?</Body2>
                </GridItemRow>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={handleUnlockRoute}>
                    Unlock
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default RouteUnlockModal;
