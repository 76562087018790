import {
    SAAS_V1,
    BIDDING_DISABLED,
    LTL_MARKETPLACE,
    SHIPPER_BIDDING_V1,
    BROKER,
    ADVANCED_SCHEDULING,
    ACCOUNTING_V2,
} from '@/constants/featureFlags';
import EmailIcon from '@material-ui/icons/Email';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Build from '@material-ui/icons/Build';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import WarningIcon from '@material-ui/icons/Warning';
import NearMe from '@material-ui/icons/NearMe';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TableChart from '@material-ui/icons/TableChart';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ListAlt from '@material-ui/icons/ListAlt';
import LocalShipping from '@material-ui/icons/LocalShipping';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';

import * as ROUTES from '../../constants/routes';
import { AttachMoney, Map } from '@material-ui/icons';

const CONTACT = {
    label: 'Contact Us',
    path: '/contactus',
    Icon: EmailIcon,
};

const HOME = {
    label: 'Home',
    path: '/account',
    Icon: HomeIcon,
};

const IMPORT = {
    label: 'Import',
    path: '/import',
    Icon: LocalShippingIcon,
    feature: [SAAS_V1, LTL_MARKETPLACE, BROKER],
};

const EXCEPTION_MANAGEMENT = {
    label: 'Exception Manager',
    path: ROUTES.EXCEPTIONS,
    Icon: WarningIcon,
};

const SCHEDULING = {
    label: 'Scheduling',
    path: ROUTES.RESOURCES,
    Icon: CalendarTodayIcon,
    sublinks: [
        {
            label: 'Notifications',
            path: ROUTES.NOTIFICATIONS,
        },
        {
            label: 'Regions',
            path: ROUTES.SUBREGION,
        },
        {
            label: 'Resources',
            path: ROUTES.RESOURCES,
        },
    ],
    feature: [ADVANCED_SCHEDULING],
};

const WAREHOUSING = {
    label: 'Warehousing',
    path: ROUTES.WAREHOUSES,
    Icon: WarehouseOutlinedIcon,
    sublinks: [
        {
            label: 'Inventory Management',
            path: ROUTES.INVENTORY,
        },
        {
            label: 'Warehouses',
            path: ROUTES.WAREHOUSES,
        },
        {
            label: 'Manifests',
            path: ROUTES.MANIFESTS,
        },
    ],
};

const DISPATCH = {
    label: 'Dispatch',
    path: '/dispatch',
    Icon: NearMe,
    sublinks: [
        {
            label: 'Unscheduled',
            path: '/dispatch/unassigned',
        },
        {
            label: 'Plan',
            path: '/dispatch/plan',
        },
    ],
    feature: SAAS_V1,
};

const TRACKING = (entity) => ({
    label: 'Tracking',
    path: `/${entity}/tracking/all`,
    Icon: LocationOnIcon,
});

const SHIPPER_OMS = {
    label: 'OMS',
    path: '/oms',
    Icon: ListAlt,
    sublinks: [
        {
            label: 'Incomplete',
            path: '/import',
        },
        {
            label: 'My Orders',
            path: '/shipper/oms/myorders',
        },
        {
            label: 'My Routes',
            path: '/shipper/oms/myroutes',
        },
    ],
};

const CARRIER_OMS = {
    label: 'OMS',
    path: '/oms',
    Icon: ListAlt,
    sublinks: [
        {
            label: 'Incomplete',
            path: '/import',
        },
        {
            label: 'My Orders',
            path: '/carrier/oms/myorders',
        },
        {
            label: 'My Routes',
            path: '/carrier/oms/myroutes',
        },
    ],
};

const CARRIER_ACCOUNTING = {
    label: 'Accounting',
    path: '/carrier/accounting/completeorders',
    Icon: AttachMoney,
    sublinks: [
        {
            label: 'Completed Orders',
            path: '/carrier/accounting/completeorders',
            feature: ACCOUNTING_V2,
        },
        {
            label: 'Invoices',
            path: ROUTES.CARRIER_ACCOUNTING_V2,
        },
        // {
        //     label: 'Statements',
        //     path: '/statements',
        // },
    ],
};

const SHIPPER_AUCTIONED_ORDERS = {
    label: 'Auction',
    path: '/auction',
    Icon: SellOutlinedIcon,
    sublinks: [
        {
            label: 'Listings',
            path: '/auction/listings',
        },
        {
            label: 'Bids',
            path: '/auction/bids',
            feature: SHIPPER_BIDDING_V1,
        },
    ],
    restrictionFeature: [BIDDING_DISABLED],
};

const CARRIER_AUCTIONED_ORDERS = {
    label: 'Auction',
    path: '/auction',
    Icon: SellOutlinedIcon,
    sublinks: [
        {
            label: 'Listings',
            path: '/auction/listings',
        },
        {
            label: 'Bids',
            path: '/auction/bids',
        },
    ],
    restrictionFeature: [BIDDING_DISABLED],
};

const CARRIER_LOADBOARD = {
    label: 'Load Board',
    path: '/carrier/loads',
    Icon: TableChart,
    sublinks: [
        {
            label: 'LTL',
            path: '/carrier/loads/ltl',
        },
        {
            label: 'FTL',
            path: '/carrier/loads/ftl',
        },
    ],
};

export const LINKS = {
    ADMIN: [
        {
            label: 'Super Admin',
            path: '/admin/users',
            Icon: SupervisorAccountIcon,
        },
        {
            label: 'Load Board',
            path: ROUTES.ADMIN_ORDERS,
            Icon: TableChart,
        },
        {
            label: 'FTL Board',
            path: '/admin/ftl',
            Icon: LocalShipping,
        },
        {
            label: 'Control Tower',
            path: '/admin/tracking',
            Icon: SettingsRemoteIcon,
            sublinks: [
                {
                    label: 'FTL',
                    path: '/admin/tracking/FTL',
                },
            ],
        },
        {
            label: 'Delivery Profiles',
            path: ROUTES.SUBREGION,
            Icon: Map,
        },
        {
            label: 'Matching Tool',
            path: ROUTES.ADMIN_MATCHING_TOOL,
            Icon: LocalShipping,
        },
        {
            label: 'Auction',
            path: ROUTES.AUCTIONED_ORDERS_LISTINGS,
            Icon: SellOutlinedIcon,
        },
        {
            label: 'Accounting',
            path: ROUTES.ADMIN_FINANCIALS_ORDERS,
            Icon: AttachMoneyIcon,
            sublinks: [
                {
                    label: 'Receivables',
                    path: ROUTES.ADMIN_FINANCIALS_RECEIVABLES,
                },
                {
                    label: 'Payables',
                    path: ROUTES.ADMIN_FINANCIALS_PAYABLES,
                },
            ],
        },
        {
            label: 'Tariffs',
            path: ROUTES.ADMIN_TARIFFS,
            Icon: LocalAtmIcon,
        },
        {
            label: 'Exception Manager',
            path: ROUTES.ADMIN_EXCEPTIONS,
            Icon: WarningIcon,
        },
        {
            label: 'Communications',
            path: '/admin/communications',
            Icon: QuestionAnswer,
        },
    ],
    SHIPPER: [
        HOME,
        IMPORT,
        WAREHOUSING,
        SCHEDULING,
        SHIPPER_OMS,
        SHIPPER_AUCTIONED_ORDERS,
        DISPATCH,
        EXCEPTION_MANAGEMENT,
        TRACKING('shipper'),
        CARRIER_ACCOUNTING,
    ],
    CARRIER: [
        HOME,
        CARRIER_LOADBOARD,
        IMPORT,
        WAREHOUSING,
        SCHEDULING,
        CARRIER_OMS,
        CARRIER_AUCTIONED_ORDERS,
        DISPATCH,
        EXCEPTION_MANAGEMENT,
        TRACKING('carrier'),
        CARRIER_ACCOUNTING,
    ],
    CORPORATE_ADMIN: [
        HOME,
        {
            label: 'Users',
            path: ROUTES.CORPORATE_USERS,
            Icon: SupervisorAccountIcon,
        },
    ],
    SHIPPER_DRIVER_TEAMMATE: [HOME],
    CARRIER_DRIVER_TEAMMATE: [HOME],
    FOOTER: [CONTACT],
};
