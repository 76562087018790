import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { REMOVE_ROUTE } from '../graphql/mutations';
import * as Sentry from '@sentry/react';
import { Body2, DangerButton, GridItemRow, ModalActions, ModalContent, ModalTitle, SecondaryButton } from '../blocks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { useClientUser } from '@/hooks';

const RouteRemoveModal = () => {
    const { modalOpen, setModalOpen, actionableRoute, selectedRoute, setSelectedRoute, setError, callbacks } =
        useContext(PlanningContext);
    const { accountType, user_id } = useClientUser();

    const [removeRoute, { loading }] = useMutation(REMOVE_ROUTE, {
        update: (cache, { data: { update_routes_by_pk } }) => {
            cache.evict(cache.identify(update_routes_by_pk));
        },
    });

    const handleModalClose = () => {
        if (selectedRoute === actionableRoute?.route_id) {
            setSelectedRoute(null);
        }
        setModalOpen(null);
    };

    const handleRemoveRoute = () => {
        const pickups = actionableRoute.orders
            .filter((mapping) => mapping.type === 'PICKUP')
            .map((mapping) => mapping.order);
        const deliveries = actionableRoute.orders
            .filter((mapping) => mapping.type !== 'PICKUP')
            .map((mapping) => mapping.order);

        removeRoute({
            variables: {
                route_id: actionableRoute?.route_id,
                order_updates: deliveries.map((order) => ({
                    where: { order_id: { _eq: order.order_id } },
                    _set: {
                        del_window_start: null,
                        del_window_end: null,
                        original_del_window_start: null,
                        original_del_window_end: null,
                        delivery_time_confirmed: null,
                        order_status:
                            order.shipper_id !== order.carrier_id && order.carrier_id === user_id
                                ? 'claimed'
                                : 'pending',
                    },
                })),
                events: [
                    ...pickups.map((order) => ({
                        order_id: order.order_id,
                        action: `${order.event_state}:REMOVE_PU`,
                        notes: `Removed route ${actionableRoute.route_number}`,
                    })),
                    ...deliveries.map((order) => ({
                        order_id: order.order_id,
                        action: `${order.event_state}:${order.event_state === 'PICKED_UP' ? 'REMOVE' : 'REMOVE_DO'}`,
                        notes: `Removed route ${actionableRoute.route_number}`,
                    })),
                ],
            },
            onCompleted: () => {
                callbacks.refetch();
                handleModalClose();
            },
            onError: (error) => {
                setError(error, 'Error removing route');
            },
        });
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ROUTE_REMOVE} onClose={handleModalClose}>
            <ModalTitle>{`Delete Route ${actionableRoute?.route_number}`}</ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <Body2>Are you sure you want to delete this route?</Body2>
                </GridItemRow>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <DangerButton disabled={loading} onClick={handleRemoveRoute}>
                    Delete
                </DangerButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default RouteRemoveModal;
