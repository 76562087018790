import React, { useMemo, useContext } from 'react';
import { compose } from 'recompose';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useClientUser } from '@/hooks';
import { colors } from '@/styles';
import { getTariffName } from '@/components/Account/Tariffs/utils';
import { PrimaryButton } from '@/styles/blocks';

import { Context, ContextProvider } from './store';
import { Title, Body1 } from './blocks';

const AdminTariffs = () => {
    const navigate = useNavigate();
    const { user_id } = useClientUser();
    const { state, loading, callbacks } = useContext(Context);

    return (
        <Grid
            container
            direction="column"
            css={css`
                max-width: 684px;
                margin: auto;
                padding-bottom: 48px;
            `}
        >
            <Grid
                container
                direction="row"
                css={css`
                    padding: 28px 0;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        justify-content: center;
                    `}
                >
                    <Title>Tariffs</Title>
                </Grid>
            </Grid>
            {state.tariffs.map((tariff, idx) => {
                return (
                    <Grid
                        key={tariff.tariff_id}
                        container
                        direction="row"
                        css={css`
                            cursor: pointer;
                            border: 1px solid #e2e2e2;
                            padding: 16px 12px;
                            border-bottom: ${idx === state.tariffs.length - 1 ? '1px solid #e2e2e2' : '0'};
                            border-bottom-left-radius: ${idx === state.tariffs.length - 1 ? '4px' : '0'};
                            border-bottom-right-radius: ${idx === state.tariffs.length - 1 ? '4px' : '0'};
                            border-top-left-radius: ${idx === 0 ? '4px' : '0'};
                            border-top-right-radius: ${idx === 0 ? '4px' : '0'};
                        `}
                    >
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                justify-content: center;
                            `}
                            onClick={() => {
                                navigate(`/account/tariff/${tariff.tariff_id}`);
                            }}
                        >
                            <Grid container direction="row">
                                <Grid container direction="column">
                                    <Grid container direction="row">
                                        <Body1>{getTariffName(tariff, idx)}</Body1>
                                    </Grid>
                                    <Grid container direction="row">
                                        <Body1
                                            css={css`
                                                color: #a6a6a6;
                                            `}
                                        >
                                            {tariff.algo_type}
                                        </Body1>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 0;
                                flex-basis: 0;
                                justify-content: center;
                            `}
                        >
                            <Grid
                                container
                                direction="row"
                                css={css`
                                    flex-wrap: nowrap;
                                `}
                            >
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    css={css`
                                        flex: 0;
                                        flex-basis: 0;
                                        justify-content: center;
                                        margin-right: 12px;
                                    `}
                                >
                                    <IconButton
                                        disabled={loading.init || loading.delete}
                                        onClick={() => {
                                            callbacks.duplicateTariff(tariff.tariff_id);
                                        }}
                                        css={css`
                                            padding: 4px;
                                        `}
                                    >
                                        <FileCopyIcon color={loading.init || loading.delete ? 'disabled' : undefined} />
                                    </IconButton>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    direction="column"
                                    css={css`
                                        flex: 0;
                                        flex-basis: 0;
                                        justify-content: center;
                                    `}
                                >
                                    <IconButton
                                        disabled={loading.init || loading.delete}
                                        onClick={() => {
                                            callbacks.deleteTariff(tariff.tariff_id);
                                        }}
                                        css={css`
                                            padding: 4px;
                                        `}
                                    >
                                        <DeleteForeverOutlined
                                            color={loading.init || loading.delete ? 'disabled' : 'error'}
                                        />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withContext)(AdminTariffs);
