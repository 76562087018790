const { getTimezoneOffset } = require('date-fns-tz');

const ISO_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}/;
const MMDDYYYY_FORMAT = /^[0-9]{1,2}\/[0-9]{1,2}\/((\d{2})|(\d{4}))$/;
const MMDDYYYY_FORMAT_DASHES = /^[0-9]{1,2}-[0-9]{1,2}-((\d{2})|(\d{4}))$/;
const YYYYMMDD_FORMAT = /^((\d{2})|(\d{4}))\/[0-9]{1,2}\/[0-9]{1,2}$/;

export const convertToISO = (date) => {
    // yyyy-mm-dd
    if (ISO_REGEX.test(date)) return date;

    // yyyy/mm/dd
    if (YYYYMMDD_FORMAT.test(date)) {
        let [year, month, day] = date.split('/');
        return `${year}-${month.length === 1 ? '0' + month : month}-${day.length === 1 ? '0' + day : day}`;
    }

    // mm/dd/yyyy
    if (MMDDYYYY_FORMAT.test(date)) {
        let [month, day, year] = date.split('/');
        if (year.length === 2) {
            year = new Date(date).getFullYear();
        }

        return `${year}-${month.length === 1 ? '0' + month : month}-${day.length === 1 ? '0' + day : day}`;
    }

    // mm-dd-yyyy
    if (MMDDYYYY_FORMAT_DASHES.test(date)) {
        let [month, day, year] = date.split('-');
        if (year.length === 2) {
            year = new Date(date).getFullYear();
        }

        return `${year}-${month.length === 1 ? '0' + month : month}-${day.length === 1 ? '0' + day : day}`;
    }

    return '';
};

export const asUTCDate = (datestring) => {
    return new Date(convertToISO(datestring));
};

export const asBrowserDate = (datestring) => {
    const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utc = new Date(convertToISO(datestring));

    return new Date(utc.getTime() - getTimezoneOffset(localTZ, utc));
};

export const asDateInTZ = (datestring, tz = 'America/New_York') => {
    const utc = asUTCDate(datestring);

    return new Date(utc.getTime() - getTimezoneOffset(tz, utc));
};
