import { createTheme } from '@material-ui/core';

export const colorCodes = Object.freeze({
    GREEN: '#59B863',
    LIGHT_GREY: '#F0F2F5',
    DARK_GREY: '#A6A6A6',
    LIGHT_BLACK: '#2B2B2B',
    DARKER_GRAY: '#4c4c4c',
    ALMOST_WHITE: '#FAFAFA',
});

export const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
        fontWeightRegular: '500',
        body1: {
            fontWeight: '500',
        },
        body2: {
            fontWeight: '700',
        },
        subtitle1: {
            color: '#979797',
            fontWeight: '600',
        },
        h1: { fontWeight: '800' },
        h3: {
            fontWeight: '800',
        },
        h5: {
            fontWeight: '900',
            color: '#4c4c4c',
        },
        h6: {
            color: '#4c4c4c',
            fontWeight: '700',
        },
        button: {
            color: '#fff',
            fontSize: '16px',
            fontWeight: '600',
            textTransform: 'capitalize',
        },
    },
    palette: {
        primary: {
            main: colorCodes.GREEN,
            contrastText: '#fff',
        },
        text: {
            secondary: '#4c4c4c',
        },
        blue: {
            main: '#197EF3',
        },
    },
});
