import { colors } from '@/styles';
import styled from '@emotion/styled';

export const Sidebar = styled.div`
    height: 100%;
    width: 20%;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: white;
    border-left: 1px solid ${colors.greys.border};
    border-right: 1px solid ${colors.greys.border};
`;

export const SidebarLabel = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greys.primary};
`;

export const SidebarItem = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid ${colors.greys.border};
    justify-content: space-between;
`;

export const SidebarScrollableContainer = styled.div`
    display: flex;
    flex-grow: 1;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #ccc;
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #666;
        border-radius: 20px;
    }
`;

export const SidebarScrollable = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`;

export const LegendContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Dot = styled((props) => <span {...props} />)`
    display: flex;
    width: 14px;
    height: 14px;
    border: 2px solid white;
    border-radius: 7px;
    ${(props) => `background-color: ${props.color || colors.greys.secondary};`}
`;
