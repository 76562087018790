import { gql } from '@apollo/client';

export const ACTIVITY_ORDER_FIELDS = gql`
    fragment ActivityOrderFields on activity_orders {
        order_id
        external_order_id
        service_id

        route_id
        external_carrier_id
        external_carrier_name
        delivery_date

        pickup_lat
        pickup_lng
        pickup_point
        pickup_full_address
        pickup_address
        pickup_unit
        pickup_city
        pickup_state
        pickup_zip
        pickup_location
        pickup_location_info
        pickup_location_type

        dropoff_lat
        dropoff_lng
        dropoff_point
        dropoff_full_address
        dropoff_address
        dropoff_unit
        dropoff_city
        dropoff_state
        dropoff_zip
        dropoff_location
        dropoff_location_info
        dropoff_location_type

        created_at
        last_modified_at
    }
`;
