import React, { createContext, useState, useMemo, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useClientUser } from '@/hooks';
import { ROUTE_COLORS } from '@/components/DispatchPlan/constants';
import * as Sentry from '@sentry/react';
import { QUERY_BY_CLIENT, QUERY_SUBREGIONS, QUERY_SUBREGION_TYPES } from '@/graphql/queries/subregions';

import {
    INSERT_SUBREGION,
    DELETE_SUBREGION,
    INSERT_ZIP_MAPPINGS,
    UPDATE_SUBREGION,
    DELETE_ZIP_MAPPINGS,
    INSERT_SUBREGIONS_MANY,
    INSERT_CARRIER_MAPPINGS,
    DELETE_CARRIER_MAPPINGS,
} from './graphql/mutations';
import { useSubregionCallbacks } from './hooks';
import { QUERY_CARRIERS } from './graphql/queries';
import { cloneDeep, debounce, pick } from 'lodash';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const user = useClientUser();
    const [profile, setProfile] = useState(null);
    const [selected, selectObject] = useState({});
    const [hovered, hoverObject] = useState({});
    const [notification, setNotification] = useState({});
    const [filter, setFilter] = useState({});

    const { where, skip } = useMemo(() => {
        const where = user.isOnwardAdmin ? { type: { _eq: profile } } : { client_id: { _eq: user.user_id } };
        const skip = (user.isOnwardAdmin && !profile) || !user.user_id;
        return { where, skip };
    }, [user, profile]);

    const [querySubregions, { data: subregions }] = useLazyQuery(QUERY_SUBREGIONS, {});

    const querySubregionsDebounced = debounce((params) => querySubregions(params), 500);

    useEffect(() => {
        if (!!user?.user_id && !user.isOnwardAdmin) {
            querySubregions({ variables: { where: { client_id: { _eq: user.user_id } } } });
        }
    }, [user]);

    const { data: types } = useQuery(QUERY_SUBREGION_TYPES, {
        skip: !user.isOnwardAdmin,
    });

    const { data: carriers } = useQuery(QUERY_CARRIERS, {
        variables: { test: false },
        skip: !user.isOnwardAdmin,
    });

    const colors = useMemo(() => {
        if (!subregions) {
            return {};
        }

        return Object.fromEntries(
            subregions.results.map((o, idx) => [o.subregion_id, ROUTE_COLORS[idx % ROUTE_COLORS.length]])
        );
    }, [ROUTE_COLORS, subregions]);

    const selectedSubregion = useMemo(() => {
        if (!subregions) {
            return null;
        }

        return subregions.results.find((subregion) => subregion.subregion_id === selected.subregion);
    }, [selected, subregions]);

    const hoveredSubregion = useMemo(() => {
        if (!subregions) {
            return null;
        }

        return subregions.results.find((subregion) => subregion.subregion_id === hovered.subregion);
    }, [hovered, subregions]);

    const selectedZips = useMemo(() => {
        return Object.entries(selected.zip || {})
            .filter(([, selected]) => selected)
            .map(([zip]) => zip);
    }, [selected]);

    const includedZips = useMemo(() => {
        if (!subregions) {
            return null;
        }

        return subregions?.results.reduce((acc, subregion) => {
            return [...acc, ...subregion.zips.map((zip) => zip.zip)];
        }, []);
    }, [subregions]);

    const hoveredZips = useMemo(() => {
        return hoveredSubregion?.zips.map((zip) => zip.zip) || [];
    }, [hoveredSubregion]);

    const [updateSubregion] = useMutation(UPDATE_SUBREGION);

    const [deleteZipMappings, { loading: deleteZipMappingsInFlight }] = useMutation(DELETE_ZIP_MAPPINGS, {
        update: (cache, { data: { removed } }) => {
            if (removed.returning.length === 0) {
                return;
            }

            cache.updateQuery(
                {
                    query: QUERY_SUBREGIONS,
                    variables: {
                        where,
                    },
                },
                (data) => {
                    const removedZips = Object.fromEntries(removed.returning.map((o) => [o.zip, true]));
                    const results = [...data.results];
                    const idx = results.findIndex(
                        (subregion) => subregion.subregion_id === removed.returning[0].subregion_id
                    );
                    const clone = { ...results[idx] };
                    clone.zips = clone.zips.filter((o) => !removedZips[o.zip]);
                    results[idx] = clone;

                    return { ...data, results };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to delete mapping' });
        },
    });

    const [insertZipMappings, { loading: insertZipMappingsInFlight }] = useMutation(INSERT_ZIP_MAPPINGS, {
        update: (cache, { data: { created } }) => {
            if (created.returning.length === 0) {
                return;
            }

            cache.updateQuery(
                {
                    query: QUERY_SUBREGIONS,
                    variables: {
                        where,
                    },
                },
                (data) => {
                    const results = [...data.results];
                    const idx = results.findIndex(
                        (subregion) => subregion.subregion_id === created.returning[0].subregion_id
                    );
                    const clone = { ...results[idx] };
                    clone.zips = [...clone.zips, ...created.returning];
                    results[idx] = clone;

                    return { ...data, results };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to insert mapping' });
        },
    });

    const [insertCarrierMappings, { loading: insertCarrierMappingsInFlight }] = useMutation(INSERT_CARRIER_MAPPINGS, {
        update: (cache, { data: { created } }) => {
            if (created.returning.length === 0) return;

            cache.updateQuery(
                {
                    query: QUERY_SUBREGIONS,
                    variables: {
                        where,
                    },
                },
                (data) => {
                    const results = [...data.results];
                    const idx = results.findIndex((s) => s.subregion_id === created.returning[0].subregion_id);
                    const clone = cloneDeep(results[idx]);
                    created.returning.forEach((createdMapping) => {
                        const mappingIdx = clone.carriers.findIndex(
                            (carrier) => carrier.mapping_id === createdMapping.mapping_id
                        );
                        if (mappingIdx >= 0) {
                            clone.carriers[mappingIdx] = createdMapping;
                        } else {
                            clone.carriers = [...clone.carriers, createdMapping];
                        }
                    });

                    results[idx] = clone;
                    return { ...data, results };
                }
            );
        },
    });

    const [deleteCarrierMappings, { loading: deleteCarrierMappingsInFlight }] = useMutation(DELETE_CARRIER_MAPPINGS, {
        update: (cache, { data: { removed } }) => {
            if (removed.returning.length === 0) return;

            cache.updateQuery(
                {
                    query: QUERY_SUBREGIONS,
                    variables: {
                        where,
                    },
                },
                (data) => {
                    const removedMappings = Object.fromEntries(
                        removed.returning.map((mapping) => [mapping.mapping_id, true])
                    );
                    return {
                        ...data,
                        results: data.results.map((result) => {
                            if (result.subregion_id === removed.returning[0].subregion_id) {
                                return {
                                    ...result,
                                    carriers: result.carriers.filter((mapping) => !removedMappings[mapping.mapping_id]),
                                };
                            }
                            return result;
                        }),
                    };
                }
            );
        },
    });

    const [removeSubregion, { loading: removeSubregionInFlight }] = useMutation(DELETE_SUBREGION, {
        update: (cache, { data: { removed } }) => {
            cache.updateQuery(
                {
                    query: QUERY_SUBREGIONS,
                    variables: {
                        where,
                    },
                },
                (data) => {
                    return {
                        ...data,
                        results: data.results.filter((subregion) => subregion.subregion_id !== removed.subregion_id),
                    };
                }
            );
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'resources' });
        },
        onError: (e) => {
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to delete subregion' });
        },
    });

    const [addSubregion, { loading: addSubregionInFlight }] = useMutation(INSERT_SUBREGION, {
        variables: {
            subregion: {
                ...(user.isOnwardAdmin ? { type: profile || 'DEFAULT' } : { client_id: user.user_id }),
            },
        },
        update: (cache, { data: { created } }) => {
            const isTemp = created.subregion_id.startsWith('temp-');

            cache.updateQuery(
                {
                    query: QUERY_SUBREGION_TYPES,
                },
                (data = {}) => {
                    return {
                        ...data,
                        results: {
                            nodes: [
                                ...(data?.results?.nodes || []).filter((node) => node.type !== created.type),
                                created,
                            ],
                        },
                    };
                }
            );

            cache.updateQuery(
                {
                    query: QUERY_SUBREGIONS,
                    variables: {
                        where,
                    },
                },
                (data) => {
                    return {
                        ...data,
                        results: [
                            ...data.results.filter(
                                (subregion) => !subregion.subregion_id.startsWith('temp-') || isTemp
                            ),
                            created,
                        ],
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to create subregion' });
        },
    });

    const [insertSubregionsMany, { loading: insertSubregionsManyInFlight }] = useMutation(INSERT_SUBREGIONS_MANY, {
        update: (cache, { data: { created } }) => {
            cache.updateQuery(
                {
                    query: QUERY_SUBREGION_TYPES,
                },
                (data = {}) => {
                    const node = created?.returning?.[0] || {};
                    return {
                        ...data,
                        results: {
                            ...(data?.results || {}),
                            nodes: [...(data?.results?.nodes || []).filter((node) => node.type !== node.type), node],
                        },
                    };
                }
            );
            cache.updateQuery(
                {
                    query: QUERY_SUBREGIONS,
                    variables: {
                        where,
                    },
                },
                (data) => {
                    return {
                        ...data,
                        results: [...data.results, ...created.returning],
                    };
                }
            );
        },
        onError: (e) => {
            console.error(e);
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to upload subregions' });
        },
    });

    const callbacks = useSubregionCallbacks(
        { profile },
        {
            updateSubregion,
            insertZipMappings,
            deleteZipMappings,
            insertCarrierMappings,
            deleteCarrierMappings,
            addSubregion,
            removeSubregion,
            hoverObject,
            selectObject,
            insertSubregionsMany,
            setNotification,
            querySubregions,
            querySubregionsDebounced,
            setProfile,
        }
    );

    return (
        <Context.Provider
            value={{
                state: {
                    selectedZips,
                    selectedSubregion,
                    includedZips: includedZips || [],
                    hoveredSubregion,
                    hoveredSubregionZip: hovered?.zip,
                    hoveredZips,
                    filter,
                    profile,
                    notification,
                    subregions: subregions?.results || [],
                    types: types?.results?.nodes?.map((node) => node.type) || [],
                    carriersById: Object.fromEntries(
                        (carriers?.results || []).map((carrier) => [carrier.client_id, carrier])
                    ),
                },
                loading: {
                    addSubregion: addSubregionInFlight,
                    removeSubregion: removeSubregionInFlight,
                    insertZipMappings: insertZipMappingsInFlight,
                    deleteZipMappings: deleteZipMappingsInFlight,
                    uploadSubregions: insertSubregionsManyInFlight,
                    insertCarrierMappings: insertCarrierMappingsInFlight,
                    deleteCarrierMappings: deleteCarrierMappingsInFlight,
                },
                callbacks: {
                    ...callbacks,
                    setNotification,
                    setFilter,
                    setProfile,
                    selectObject,
                    genColor: (subregion) => {
                        return colors[subregion.subregion_id];
                    },
                    clearNotification: () => {
                        setNotification({});
                    },
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};
