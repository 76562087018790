import { gql } from '@apollo/client';
import { ROUTE_FIELDS } from '@/graphql/queries/routes';
import { TRUCK_FIELDS } from '@/graphql/queries/trucks';
import { STOP_FIELDS } from '@/graphql/queries/stops';
import { TEAMMATE_FIELDS } from '@/graphql/queries/teammates';

export const QUERY_ROUTES_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID = gql`
    ${ROUTE_FIELDS}
    ${TRUCK_FIELDS}
    ${STOP_FIELDS}

    query routes($limit: Int!, $offset: Int!, $where: routes_bool_exp!, $order_by: [routes_order_by!]) {
        routes_aggregate(where: $where) {
            aggregate {
                totalCount: count
            }
        }
        routes(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
            ...RouteFields
            scheduled_delivery_formatted @client
            teammateByDriverId {
                teammate_id
                username
            }
            stopsByRouteId {
                ...StopFields
            }
        }
    }
`;

export const QUERY_DISTINCT_DRIVERS = gql`
    ${ROUTE_FIELDS}
    ${TEAMMATE_FIELDS}

    query routes($where: routes_bool_exp!) {
        routes(where: $where, distinct_on: driver_id) {
            ...RouteFields
            teammateByDriverId {
                ...TeammateFields
            }
        }
    }
`;
