import React from 'react';
import { css } from '@emotion/react';
import { Image } from 'react-bootstrap';
import { Grid, TextField, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';

import { EXCEPTION_REASONS } from '../../constants';
import FileDropZone from '../../FileDropZone';
import { Body1, Header1, ItemCheckbox, StyledRadio } from '../../Crossdocking/blocks';

const Refusal = ({ exception, required, order, callbacks }) => {
    return (
        <>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>Items{required.includes('items') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}</Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <Grid direction="column" container>
                    <Grid
                        direction="row"
                        container
                        item
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <Grid
                            direction="column"
                            container
                            css={css`
                                flex: 0;
                                flex-basis: 0;
                                justify-content: center;
                            `}
                        >
                            <OnwardCheckbox
                                checked={(order?.itemsByOrderId || [])
                                    .map((item) => item.item_id)
                                    .every((item_id) => exception?.items?.[item_id])}
                                onChange={() => {
                                    callbacks.setException((prev) => {
                                        const items = order.itemsByOrderId.map((item) => item.item_id);
                                        const checked = !items.every((id) => prev?.items?.[id]);

                                        return {
                                            ...prev,
                                            items: Object.fromEntries(
                                                order.itemsByOrderId.map((item) => [
                                                    item.item_id,
                                                    checked ? item : null,
                                                ])
                                            ),
                                        };
                                    });
                                }}
                            />
                        </Grid>
                        <Grid
                            direction="column"
                            container
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                justify-content: center;
                            `}
                        >
                            <Body1>Select All</Body1>
                        </Grid>
                    </Grid>

                    {(order?.itemsByOrderId || []).map((item) => {
                        return (
                            <ItemCheckbox
                                key={item.item_id}
                                item={item}
                                checked={exception?.items?.[item.item_id]}
                                callbacks={{
                                    onCheck: (id) =>
                                        callbacks.setException((prev) => ({
                                            ...prev,
                                            items: {
                                                ...(prev.items || {}),
                                                [id]: !prev?.items?.[id] ? item : null,
                                            },
                                        })),
                                }}
                            />
                        );
                    })}
                </Grid>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>Reason{required.includes('reason') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}</Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <RadioGroup
                    value={exception.reason || ''}
                    onChange={(e) => {
                        callbacks.setException((prev) => ({
                            ...prev,
                            reason: e.target.value,
                        }));
                    }}
                >
                    {EXCEPTION_REASONS[exception.type].map((reason) => (
                        <StyledRadio
                            key={reason}
                            value={reason}
                            label={reason}
                        />
                    ))}
                </RadioGroup>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>Image{required.includes('images') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}</Body1>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 30px;
                `}
            >
                {(exception.images || []).map((image) => (
                    <a href={image} target="_blank">
                        <Image
                            css={css`
                                height: 100px;
                                width: 100px;
                            `}
                            src={image}
                        />
                    </a>
                ))}
                <FileDropZone handleFileAdd={callbacks.handleFileAdd} />
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>Notes{required.includes('notes') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}</Body1>
            </Grid>
            <Grid direction="row" container>
                <TextField
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={exception.notes || ''}
                    onChange={(e) =>
                        callbacks.setException((prev) => ({
                            ...prev,
                            notes: e.target.value,
                        }))
                    }
                    multiline
                    rows={5}
                />
            </Grid>
        </>
    );
};

export default Refusal;
