import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@material-ui/core';
import UpdateBudgetModal from './modals/UpdateBudgetModal';

export default function CarrierEditBudget(props) {
    const { order } = props;
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Card className="w-100" style={{ backgroundColor: 'white' }}>
                <CardContent>
                    <div className="d-flex justify-content-between">
                        <Typography color="secondary" variant="h6">
                            Budget
                        </Typography>
                        <Typography className="carrier-budget">${order.customer_budget}</Typography>

                        <Button
                            onClick={() => setOpenModal(true)}
                            variant="contained"
                            color="success"
                            className="editBudget-btn"
                        >
                            <Typography variant="body1">Edit Budget</Typography>
                        </Button>
                    </div>
                </CardContent>
            </Card>
            <UpdateBudgetModal isOpen={openModal} onClose={() => setOpenModal(false)} order={order} />
        </>
    );
}
