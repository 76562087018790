import { gql } from '@apollo/client';
import { TEAMMATE_FIELDS } from '@/graphql/queries/teammates';
import { USER_FIELDS } from '@/graphql/queries/users';
import { TRUCK_FIELDS } from '@/graphql/queries/trucks';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const USER_BY_EMAIL = gql`
    ${USER_FIELDS}

    query users($email: String) {
        users(where: { email: { _eq: $email } }) {
            ...UserFields
        }
    }
`;

export const TRUCKS_BY_CLIENT = gql`
    ${TRUCK_FIELDS}
    query Trucks($client_id: uuid!) {
        trucks(where: { client_id: { _eq: $client_id } }) {
            ...TruckFields
        }
    }
`;

export const SEARCH_SHIPPERS = gql`
    ${CLIENT_FIELDS}
    query SearchShippers($search: clients_bool_exp!) {
        shippers: clients(where: $search) {
            ...ClientFields
        }
    }
`;
