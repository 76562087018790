import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { genAttributes } from '@onward-delivery/core';
import { toNational, toE164 } from '@/utilities/formatPhoneNumber';
import { PICKUP_TYPES } from '@/components/ShipmentForm/constants/dropoffOptions';
import { Header1, Body1, Tag, Step, SubStepDesktop, SubStepMobile, Anchor } from './blocks';
import Dropoff from './Dropoff';
import { formatInTimeZone } from 'date-fns-tz';

function Pickup({ isReceived, isDesktop, isLoading, warehouse, before, after, order, callbacks, tz }) {
    const { full_address, location, location_type } = genAttributes(order, true);

    const STEPS = useMemo(() => {
        return [
            {
                description: isDesktop ? (
                    `${isReceived ? 'Load' : 'Pick up'} items and update status to 'In-transit'`
                ) : (
                    <>
                        <Grid direction="row">
                            <Body1>{order.pickup_name}</Body1>
                        </Grid>
                        <Grid direction="row">
                            {order.pickup_phone ? (
                                <Anchor href={`tel:${toE164(order.pickup_phone)}`}>
                                    {toNational(order.pickup_phone)}
                                </Anchor>
                            ) : (
                                <Body1>--</Body1>
                            )}
                        </Grid>
                        <Grid direction="row">
                            <Body1>{order[full_address]}</Body1>
                        </Grid>
                        <Grid direction="row">
                            <Body1>{`${order[location]}, ${
                                typeof PICKUP_TYPES[order[location]]?.find((o) => o.value === order[location_type])
                                    ?.label === 'function'
                                    ? PICKUP_TYPES[order[location]]
                                          ?.find((o) => o.value === order[location_type])
                                          ?.label('pickUp')
                                    : PICKUP_TYPES[order[location]]?.find((o) => o.value === order[location_type])
                                          ?.label
                            }`}</Body1>
                        </Grid>
                    </>
                ),
                cta: isDesktop ? 'Mark In-transit' : 'Arrived',
                complete: ({ order, before, after, warehouse }) => {
                    return after?.pickup_date;
                },
                callback: ({ order, before, after }) => {
                    const NOW = new Date();
                    const prompt = isDesktop ? (
                        <>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                <Body1>Are you sure you want to mark the order as 'In-Transit'?</Body1>
                            </Grid>
                            <Grid direction="row" container>
                                <Body1>This will let the customer and shipper know the order is on the way</Body1>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                <Header1>{`${isReceived ? 'Load' : 'Pick Up'} Order`}</Header1>
                            </Grid>
                        </>
                    );
                    const callback = (orderUpdates = {}) =>
                        callbacks.upsertEvent({
                            event: {
                                ...(after?.event_id
                                    ? { event_id: after.event_id, action: after.action }
                                    : { action: 'PICKED_UP:CONFIRM' }),
                                order_id: order.order_id,
                                pickup_date: orderUpdates?.ltl_pickup_completed || NOW.toISOString(),
                            },
                            orderId: order.order_id,
                            order: {
                                order_status: 'inProgress',
                                ltl_pickup_completed: NOW.toISOString(),
                                ...orderUpdates,
                            },
                        });

                    callbacks.openChecklist(
                        {
                            content: prompt,
                            documents: [{ field: 'proof_of_pickup', name: 'Proof of Pickup', required: false }],
                            signatures: [],
                            timestamps: [
                                {
                                    field: 'ltl_pickup_completed',
                                    name: `${isReceived ? 'Load' : 'Pick Up'}`,
                                    required: true,
                                },
                            ],
                            title: `${isReceived ? 'Load' : 'Pick Up'} Order`,
                            itemsHelperText: 'Checkmark each item after loading.',
                        },
                        callback
                    );
                },
            },
        ];
    }, [isDesktop, full_address, location, location_type, order]);

    const isComplete = useMemo(() => {
        return STEPS.map((step) => {
            return step.complete({ order, before, after, warehouse });
        });
    }, [before, after, order]);

    const inProgressIdx = useMemo(() => {
        return isComplete.findIndex((x) => !x);
    }, [isComplete]);

    const allComplete = isComplete.every((x) => x);

    return (
        <>
            <Step
                isDesktop={isDesktop}
                isComplete={allComplete}
                inProgress={!allComplete && before.est_pickup_date}
                label={() => {
                    return (
                        <Grid
                            direction="row"
                            container
                            css={css`
                                align-items: center;
                            `}
                        >
                            <Grid
                                direction="column"
                                css={css`
                                    margin-right: 8px;
                                `}
                            >
                                <Header1>{isReceived ? 'Loaded' : 'Picked Up'}</Header1>
                            </Grid>
                            <Grid
                                direction="column"
                                css={css`
                                    margin-right: 8px;
                                `}
                            >
                                {after?.pickup_date ? (
                                    <Tag>
                                        <Body1
                                            css={css`
                                                margin-right: 20px;
                                            `}
                                        >{`${isReceived ? 'Loaded' : 'Picked up'}: ${formatInTimeZone(
                                            after.pickup_date,
                                            tz,
                                            'EEE, MMM dd, yyyy h:mm a zzz'
                                        )}`}</Body1>
                                        <Anchor
                                            onClick={() => {
                                                const NOW = new Date();
                                                const prompt = isDesktop ? (
                                                    <>
                                                        <Grid
                                                            direction="row"
                                                            container
                                                            css={css`
                                                                margin-bottom: 12px;
                                                            `}
                                                        >
                                                            <Body1>
                                                                Are you sure you want to mark the order as 'In-Transit'?
                                                            </Body1>
                                                        </Grid>
                                                        <Grid direction="row" container>
                                                            <Body1>
                                                                This will let the customer and shipper know the order is
                                                                on the way
                                                            </Body1>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            direction="row"
                                                            container
                                                            css={css`
                                                                margin-bottom: 12px;
                                                            `}
                                                        >
                                                            <Header1>{`${
                                                                isReceived ? 'Load' : 'Pick Up'
                                                            } Order`}</Header1>
                                                        </Grid>
                                                    </>
                                                );
                                                const callback = (orderUpdates = {}) =>
                                                    callbacks.upsertEvent({
                                                        event: {
                                                            ...(after?.event_id
                                                                ? { event_id: after.event_id, action: after.action }
                                                                : { action: 'PICKED_UP:CONFIRM' }),
                                                            order_id: order.order_id,
                                                            pickup_date:
                                                                orderUpdates?.ltl_pickup_completed || NOW.toISOString(),
                                                        },
                                                        orderId: order.order_id,
                                                        order: {
                                                            order_status: 'inProgress',
                                                            ltl_pickup_completed: NOW.toISOString(),
                                                            ...orderUpdates,
                                                        },
                                                    });

                                                callbacks.openChecklist(
                                                    {
                                                        event: after,
                                                        content: prompt,
                                                        documents: [
                                                            {
                                                                field: 'proof_of_pickup',
                                                                name: 'Proof of Pickup',
                                                                required: false,
                                                            },
                                                        ],
                                                        signatures: [],
                                                        timestamps: [
                                                            {
                                                                field: 'ltl_pickup_completed',
                                                                name: `${isReceived ? 'Load' : 'Pick Up'}`,
                                                                required: true,
                                                            },
                                                        ],
                                                        title: `${isReceived ? 'Load' : 'Pick Up'} Order`,
                                                        itemsHelperText: 'Checkmark each item after loading.',
                                                    },
                                                    callback
                                                );
                                            }}
                                        >
                                            <Header1>Edit</Header1>
                                        </Anchor>
                                    </Tag>
                                ) : (
                                    <Tag>
                                        <Body1>{`Scheduled: ${
                                            before?.est_pickup_date
                                                ? formatInTimeZone(before.est_pickup_date, tz, 'EEE, MMM dd, yyyy')
                                                : 'Pending'
                                        }`}</Body1>
                                    </Tag>
                                )}
                            </Grid>
                        </Grid>
                    );
                }}
            >
                {() => (
                    <>
                        {STEPS.map(({ description, cta, callback }, idx) => {
                            const SubStep = isDesktop ? SubStepDesktop : SubStepMobile;

                            return (
                                <SubStep
                                    isComplete={isComplete[idx]}
                                    inProgress={inProgressIdx === idx}
                                    isLoading={isLoading}
                                    isLast={idx === STEPS.length - 1}
                                    description={description}
                                    cta={cta}
                                    secondaryCta={null}
                                    callback={
                                        callback
                                            ? () => {
                                                  callback({ before, after, order });
                                              }
                                            : null
                                    }
                                    secondaryCallback={null}
                                />
                            );
                        })}
                    </>
                )}
            </Step>
            {isDesktop && (
                <Step
                    isDesktop={isDesktop}
                    isComplete={false}
                    inProgress={false}
                    label={() => {
                        return (
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    align-items: center;
                                `}
                            >
                                <Grid
                                    direction="column"
                                    css={css`
                                        margin-right: 8px;
                                    `}
                                >
                                    <Header1>Cross-docked</Header1>
                                </Grid>
                                <Grid
                                    direction="column"
                                    css={css`
                                        margin-right: 8px;
                                    `}
                                >
                                    {isComplete.every((x) => x) || before.est_pickup_date ? (
                                        <Body1
                                            css={css`
                                                color: #2b2b2b;
                                                opacity: 0.5;
                                            `}
                                        >
                                            not applicable for this order
                                        </Body1>
                                    ) : (
                                        <Anchor
                                            onClick={() => {
                                                callbacks.editCrossdock({
                                                    event: {},
                                                    action: !!before?.transition?.next
                                                        ? `${before.transition.next}:ADD_CD`
                                                        : 'START:ADD_CD',
                                                });
                                            }}
                                        >
                                            <Header1>Add Cross-dock</Header1>
                                        </Anchor>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    }}
                ></Step>
            )}
        </>
    );
}

export default function PickedUp({ isDesktop, ...rest }) {
    return (
        <>
            <Pickup {...rest} isDesktop={isDesktop} />
            {isDesktop ? <Dropoff {...rest} isDesktop={isDesktop} /> : null}
        </>
    );
}
