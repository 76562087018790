import { gql } from '@apollo/client';

export const UPDATE_CLIENT_BLACKLIST = gql`
    mutation updateClientBlacklist($client_id: uuid = "", $blacklisted_clients: jsonb = "") {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: { blacklisted_clients: $blacklisted_clients }
        ) {
            blacklisted_clients
            client_id
        }
    }
`;
