export const locationTypes = {
    BUSINESS_DELIVERY: 'BUSINESS_DELIVERY',
    RESIDENCE_DELIVERY: 'RESIDENCE_DELIVERY',
};

const businessDelivery = [
    {
        value: 'dock',
        display: 'Dock Delivery',
        type: locationTypes.BUSINESS_DELIVERY,
        checked: true,
    },
    {
        value: 'inStore',
        display: 'In Store Delivery',
        type: locationTypes.BUSINESS_DELIVERY,
        checked: true,
    },
    {
        value: 'rollUpDoor',
        display: 'Warehouse/Roll Up Door',
        type: locationTypes.BUSINESS_DELIVERY,
        checked: true,
    },
    {
        value: 'Regular Install',
        display: 'Installation',
        type: locationTypes.BUSINESS_DELIVERY,
        checked: true,
    },
];

const residenceDelivery = [
    {
        value: 'curbside',
        display: 'Curbside Delivery',
        type: locationTypes.RESIDENCE_DELIVERY,
        checked: true,
    },
    {
        value: 'firstDrySpace',
        display: 'First Dry Space',
        type: locationTypes.RESIDENCE_DELIVERY,
        checked: true,
    },
    {
        value: 'roomOfChoice',
        display: 'Room of Choice',
        type: locationTypes.RESIDENCE_DELIVERY,
        checked: true,
    },
    {
        value: 'whiteGlove',
        display: 'White Glove',
        type: locationTypes.RESIDENCE_DELIVERY,
        checked: true,
    },
];

const transportTypes = {
    all: [...businessDelivery, ...residenceDelivery],

    businessDelivery,
    residenceDelivery,

    dropoffFilter: (orders = [], activeFilters) =>
        orders.filter((order) => {
            let isActiveFilter = false;

            const { dropoff_location_type = '', dropoff_location_info = '' } = order;

            if (!dropoff_location_type && !dropoff_location_info) return;

            isActiveFilter = activeFilters.find(
                ({ value }) => (dropoff_location_type || dropoff_location_info) === value
            );

            return isActiveFilter;
        }),
};

export default transportTypes;
