import React, { useContext, useMemo } from 'react';
import { compose } from 'recompose';
import { css } from '@emotion/react';
import StickyFooter from '@/components/StickyFooter';
import { Grid, TextField } from '@material-ui/core';
import { PrimaryButton } from '@/styles/blocks';

import { Container } from './blocks';
import { Context, ContextProvider } from './store';

import { RATES as DEFAULT_RATES } from './types/default';
import { RATES as LIVING_SPACES_RATES } from './types/livingSpaces';
import { RATES as AMERICAN_FREIGHT_RATES } from './types/americanFreight';

import RateGrid from './RateGrid';
import Accessorials from './Accessorials';
import FuelRates from './FuelRates';
import { OnwardConsolidated } from './types/onwardConsolidated';

const Tariffs = () => {
    const { state, errors, callbacks } = useContext(Context);

    const RATES = useMemo(() => {
        switch (state.algoType) {
            case 'SHIPPER_GLOBAL_DEFAULT':
            case 'CARRIER_GLOBAL_DEFAULT':
            case 'DEFAULT':
                return DEFAULT_RATES;
            case 'LIVING_SPACES':
                return LIVING_SPACES_RATES;
            case 'ROCKET_SHIPPING':
                return AMERICAN_FREIGHT_RATES;
            default:
                return [];
        }
    }, [state.algoType]);

    let content;
    switch (state.algoType) {
        case 'ONWARD_CONSOLIDATED':
            content = <OnwardConsolidated />;
            break;
        default:
            content = (
                <>
                    {RATES.map((rate) => {
                        return (
                            <RateGrid
                                key={rate.name}
                                {...rate}
                                rates={state.rates}
                                callbacks={callbacks}
                                errors={errors.missing}
                                css={css`
                                    margin-bottom: 30px;
                                `}
                            />
                        );
                    })}

                    <Accessorials
                        algoType={state.algoType}
                        accessorials={state.accessorials}
                        rates={state.rates}
                        callbacks={callbacks}
                        errors={errors.missing}
                        css={css`
                            margin-bottom: 30px;
                        `}
                    />
                </>
            );
            break;
    }

    if (state.loading) {
        return <></>;
    }

    return (
        <>
            <Container>
                <Grid
                    container
                    direction="row"
                    css={css`
                        width: 50%;
                        margin-bottom: 30px;
                    `}
                >
                    <TextField
                        fullWidth
                        label="Name"
                        variant="outlined"
                        value={state.name || ''}
                        onChange={(e) => {
                            callbacks.updateTariff({ name: e.target.value });
                        }}
                    />
                </Grid>

                {content}

                <FuelRates
                    rates={state.rates}
                    callbacks={callbacks}
                    errors={errors.missing}
                    css={css`
                        margin-bottom: 30px;
                    `}
                />

                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        padding-bottom: 120px;
                    `}
                />
            </Container>
            <StickyFooter
                styles={{
                    margin: 0,
                    boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.25)',
                }}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        justify-content: space-between;
                        flex-wrap: nowrap;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            align-content: flex-start;
                        `}
                    >
                        <PrimaryButton red onClick={callbacks.delete}>
                            Delete
                        </PrimaryButton>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            align-content: flex-end;
                        `}
                    >
                        <PrimaryButton disabled={Object.keys(errors.missing).length > 0} onClick={callbacks.save}>
                            Save
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </StickyFooter>
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withContext)(Tariffs);
