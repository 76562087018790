import {
    ModalContent,
    PrimaryButton,
    ResponsiveSidebarDialog,
    SecondaryButton,
    SectionTitle,
    StickyModalActions,
} from '@/styles/blocks';
import { Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { MODALS } from '../constants';
import { Context } from '../store';
import { css } from '@emotion/react';
import { OnwardRadio } from '../blocks';

const MultiPickupModal = () => {
    const { state, callbacks } = useContext(Context);

    return (
        <ResponsiveSidebarDialog open={state.current === MODALS.MULTI_PICKUP} onClose={callbacks.reset}>
            <ModalContent width={620}>
                <Grid
                    css={css`
                        margin-bottom: 24px;
                    `}
                >
                    <SectionTitle>How many pickup locations are in this upload?</SectionTitle>
                </Grid>
                <Grid container direction="column">
                    <OnwardRadio
                        checked={!state.multiPickup}
                        onChange={() => callbacks.setMultiPickup(false)}
                        value="One"
                        label="One"
                    />
                    <OnwardRadio
                        checked={state.multiPickup}
                        onChange={() => callbacks.setMultiPickup(true)}
                        value="Many"
                        label="Many"
                    />
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <SecondaryButton onClick={callbacks.reset}>Cancel</SecondaryButton>
                <PrimaryButton onClick={callbacks.advance}>Next</PrimaryButton>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default MultiPickupModal;
