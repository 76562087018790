import { getDeliveryWindowFormatted, getDeliveryEndFormatted } from './getDeliveryWindowFormatted';

const getDeliveryWindowDelayed = (stop, deliveryDate) => {
    const { del_window_start, del_window_end, del_window_delay = 0, stop_start_time, stop_end_time } = stop;

    if (del_window_start && del_window_end) {
        // Convert start/end to Date and use getTime() just in case they are string instead of numeric
        const delayedStart = new Date(new Date(del_window_start).getTime() + del_window_delay);
        const delayedEnd = new Date(new Date(del_window_end).getTime() + del_window_delay);

        return getDeliveryWindowFormatted(delayedStart, delayedEnd);
    } else if (stop_start_time && stop_end_time && deliveryDate) {
        const startTime = new Date(deliveryDate);
        startTime.setHours(Math.floor(stop_start_time));
        startTime.setMinutes(parseInt((stop_start_time % 1) * 60));
        const delayedStart = new Date(startTime.getTime() + del_window_delay);

        const endTime = new Date(deliveryDate);
        endTime.setHours(Math.floor(stop_end_time));
        endTime.setMinutes(parseInt((stop_end_time % 1) * 60));
        const delayedEnd = new Date(endTime.getTime() + del_window_delay);

        return getDeliveryWindowFormatted(delayedStart, delayedEnd);
    }

    return 'TBD';
};

export default getDeliveryWindowDelayed;
