import React from 'react';
import { css } from '@emotion/react';
import { Grid, CircularProgress } from '@material-ui/core';
import { Container } from './blocks';

const Loading = () => {
    return (
        <Container
            css={css`
                justify-content: center;
                align-items: center;
            `}
        >
            <Grid
                item
                css={css`
                    margin-top: 128px;
                `}
            >
                <CircularProgress />
            </Grid>
        </Container>
    );
};

export default Loading;
