import { gql } from '@apollo/client';

export const ORDER_COMMUNICATIONS_FIELDS = gql`
    fragment OrderCommunicationsFields on order_communications {
        order_communication_id
        order_id
        client_id
        event_type
        created_at
    }
`;
