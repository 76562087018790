import React from 'react';
import { Header1, Step } from './blocks';

export default function Dropoff({ isDesktop, inProgress, before, after, order, callbacks }) {
    const isComplete = !!after?.pickup_date;

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={isComplete}
            inProgress={inProgress}
            label={() => {
                return <Header1>In-transit</Header1>;
            }}
        ></Step>
    );
}
