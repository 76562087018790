export const UNASSIGNED_TABS = [
    {
        label: 'All',
        value: 0,
        filters: [],
    },
    {
        label: 'Received',
        value: 1,
        filters: [
            {
                wh_events: {
                    status: { _eq: 'RECEIVED' },
                },
            },
        ],
    },
    {
        label: 'Not Received',
        value: 2,
        filters: [
            {
                _not: {
                    wh_events: {
                        action: { _eq: 'RECEIVED' },
                    },
                },
            },
        ],
    },
    {
        label: 'Reschedules',
        value: 3,
        filters: [
            {
                wh_events: {
                    action: {
                        _in: [
                            'CD_COMPLETE:ATTEMPT',
                            'CD_PENDING:ATTEMPT',
                            'PICKED_UP:ATTEMPT',
                            'CD_COMPLETE:DO_ATTEMPT',
                        ],
                    },
                },
            },
        ],
    },
    {
        label: 'Cross-docks',
        value: 4,
        filters: [
            {
                event_state: {
                    _in: ['CD_PENDING_PO', 'ROUTED_PU', 'ROUTED_DO', 'CD_RECEIVED_SKIP_DO'],
                },
            },
        ],
    },
];

export const MODALS = {
    CROSS_DOCK: 'CROSS_DOCK',
    PLANNING: 'PLANNING',
    MARKETPLACE: 'MARKETPLACE',
    CANCELLATION: 'CANCELLATION',
    CREDIT_CARD: 'CREDIT_CARD',
    LISTING_OPTIONS: 'LISTING_OPTIONS',
};
