import { ModalTitle, ResponsiveSidebarDialog } from '@/styles/blocks';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid, Typography } from '@material-ui/core';
import { toNational } from '@/utilities/formatPhoneNumber';
import { genAttributes } from '@onward-delivery/core';
import deliveryStrings from '@/constants/deliveryStrings';
import ExceptionCard from '@/components/OrderDetailsPage/ExceptionCard';
import * as typography from '@/styles/typography';
import { poundFormatter } from '@/constants/formats';
import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Image from 'react-bootstrap/Image';

import styled from '@emotion/styled';
import { colors } from '@/styles';

const HR = styled.div`
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    border: 1px solid #e2e2e2;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    text-decoration: ${(props) => (props.strikethrough ? 'line-through' : 'initial')};

    font-weight: 500;
    font-size: 16px;
    line-height: 19.5px;
`;

export const CardTitle = styled.span`
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 16px;
`;

const StopDetailsModal = ({ open, stop, orders, onClose, title }) => {
    const { location, location_info, location_type, full_address } = genAttributes(orders[0]);

    const orderLevelExceptions = useMemo(() => {
        return orders.filter((o) => o?.exceptions?.some((e) => e.exception_type === 'ORDER'));
    }, [orders]);

    const haulaways = useMemo(() => {
        return orders?.reduce((hauls, o) => {
            if (o?.haulaway_items?.length) {
                return [...hauls, ...o.haulaway_items];
            }
            return hauls;
        }, []);
    });

    const finalDeliveryPhotos = useMemo(() => {
        return orders?.reduce((finalPhotos, o) => {
            if (o?.delivery_photo?.length) {
                return [...finalPhotos, ...o.delivery_photo];
            }
            return finalPhotos;
        }, []);
    });

    const pods = useMemo(() => {
        return orders?.reduce((podPhotos, o) => {
            if (o?.pod?.length) {
                return [...podPhotos, ...o.pod];
            }
            return podPhotos;
        }, []);
    });

    return (
        <ResponsiveSidebarDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            css={css`
                height: fit-content;
                .MuiDialog-container {
                    align-items: normal;
                }
            `}
        >
            <ModalTitle border title={title} onClose={onClose} />

            <Grid
                container
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                    width: 100%;
                    min-width: 450px;
                    padding: 1rem;
                    height: 75vh;
                    overflow-y: scroll;
                    max-width: 530px;
                `}
            >
                {/* Stop contact info overview */}
                <Grid item>
                    <Typography>Customer: {orders[0]?.dropoff_name}</Typography>
                    <Typography>{toNational(orders[0]?.dropoff_phone)}</Typography>
                    <Typography>{orders[0]?.[full_address]}</Typography>
                    <Typography>{`${orders[0]?.[location]}, ${
                        deliveryStrings.pickupLocationType[orders[0]?.[location_type]] ||
                        deliveryStrings.dropOffLocationType[orders[0]?.[location_type]]
                    }, ${orders[0]?.[location_info]}`}</Typography>
                    <Typography>Comments: {orders[0]?.customer_instructions || 'None'}</Typography>
                </Grid>

                {/* Order-Level Exceptions */}
                {!!orderLevelExceptions?.length && (
                    <Grid item>
                        {orderLevelExceptions.map((order) =>
                            order?.exceptions?.map((exception) => <ExceptionCard exception={exception} order={order} />)
                        )}
                    </Grid>
                )}

                <HR />

                {/* Items */}
                <Grid item>
                    <CardTitle>Item(s)</CardTitle>

                    <Grid container direction="column" spacing={3}>
                        {orders?.map((o) =>
                            o?.itemsByOrderId?.map((item) => (
                                <>
                                    <Grid
                                        item
                                        direction="column"
                                        container
                                        css={css`
                                            flex: 1;
                                            flex-basis: 0;
                                        `}
                                    >
                                        <Grid
                                            direction="row"
                                            container
                                            item
                                            css={css`
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <Body1>
                                                {item.description || item.item_type_details} -{' '}
                                                {item.sku ? `${item.sku} / ` : ''}
                                            </Body1>
                                        </Grid>
                                        <Grid
                                            direction="row"
                                            container
                                            item
                                            css={css`
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <Body1>{`Quantity: ${item.quantity}`}</Body1>
                                        </Grid>
                                        {item?.width && (
                                            <Grid direction="row" container item>
                                                <Body1
                                                    css={css`
                                                        color: ${colors.greys[0]};
                                                    `}
                                                >{`${poundFormatter.format(item.total_weight)} ${item.length}L ${
                                                    item.width
                                                }W ${item.height}H`}</Body1>
                                            </Grid>
                                        )}

                                        {item?.item_exception && (
                                            <Grid item>
                                                <Grid container className="mt-4">
                                                    <ExceptionCard
                                                        exception={item.item_exception}
                                                        order={o}
                                                        exceptionItem={item}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                            ))
                        )}
                    </Grid>
                </Grid>

                {!!haulaways?.length && (
                    <>
                        <HR />

                        <Grid item>
                            <CardTitle>Haulaways(s)</CardTitle>

                            {/* Haulaways */}
                            {haulaways?.map((haulaway) => (
                                <Grid
                                    item
                                    direction="column"
                                    container
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                >
                                    <Grid
                                        direction="row"
                                        container
                                        item
                                        css={css`
                                            margin-bottom: 8px;
                                        `}
                                    >
                                        <Body1>{haulaway.description || haulaway.item_type}</Body1>
                                    </Grid>
                                    <Grid
                                        direction="row"
                                        container
                                        item
                                        css={css`
                                            margin-bottom: 8px;
                                        `}
                                    >
                                        <Body1>{`Quantity: ${haulaway.quantity}`}</Body1>
                                    </Grid>
                                    {haulaway?.width && (
                                        <Grid
                                            direction="row"
                                            container
                                            item
                                            css={css`
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <Body1
                                                css={css`
                                                    color: ${colors.greys[0]};
                                                `}
                                            >{`${poundFormatter.format(haulaway.weight)} ${haulaway.length}L ${
                                                haulaway.width
                                            }W ${haulaway.height}H`}</Body1>
                                        </Grid>
                                    )}
                                    <Grid direction="row" container item>
                                        <Body1>
                                            Return or Dispose?{' '}
                                            <span
                                                css={css`
                                                    font-weight: 700;
                                                `}
                                            >
                                                {haulaway?.return_or_dispose === 'dispose' ? 'Dispose' : 'Return'}
                                            </span>
                                        </Body1>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}

                {!!pods?.length && (
                    <>
                        <HR />

                        <Grid item>
                            <CardTitle>Signed Delivery Ticket</CardTitle>

                            {/* Signed Delivery Tickets */}
                            <Grid item container spacing={1} sm={12}>
                                {pods.map((image) => (
                                    <Grid item>
                                        <a
                                            href={image.url}
                                            target="_blank"
                                            css={css`
                                                position: relative;
                                            `}
                                        >
                                            <Image
                                                className="doc-thumbnail"
                                                css={css`
                                                    width: 116px;
                                                    height: 116px;
                                                `}
                                                src={image.url}
                                            />
                                            <OpenInNewIcon
                                                css={css`
                                                    position: absolute;
                                                    padding: 2.5px;
                                                    right: 0;
                                                    background-color: rgba(100, 100, 100, 0.5);
                                                    color: white;
                                                    border-radius: 100%;
                                                    height: 15px;
                                                    width: 15px;
                                                    margin-right: 3px;
                                                    margin-top: 3px;
                                                `}
                                            />
                                        </a>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </>
                )}

                {!!finalDeliveryPhotos?.length && (
                    <>
                        <HR />
                        {/* Final Delivery Photos */}
                        <Grid item container spacing={1} sm={12}>
                            {finalDeliveryPhotos.map((image) => (
                                <Grid item>
                                    <a
                                        href={image.url}
                                        target="_blank"
                                        css={css`
                                            position: relative;
                                        `}
                                    >
                                        <Image
                                            className="doc-thumbnail"
                                            css={css`
                                                width: 116px;
                                                height: 116px;
                                            `}
                                            src={image.url}
                                        />
                                        <OpenInNewIcon
                                            css={css`
                                                position: absolute;
                                                padding: 2.5px;
                                                right: 0;
                                                background-color: rgba(100, 100, 100, 0.5);
                                                color: white;
                                                border-radius: 100%;
                                                height: 15px;
                                                width: 15px;
                                                margin-right: 3px;
                                                margin-top: 3px;
                                            `}
                                        />
                                    </a>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </Grid>
        </ResponsiveSidebarDialog>
    );
};

export default StopDetailsModal;
