import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { OnwardButtonIcon, PrimaryButton, SecondaryButton } from '@/styles/blocks';
import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Body1, Body2 } from '../blocks';
import { colors } from '@/styles';
import { Grid } from '@material-ui/core';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ConsolidatePallets = ({ manifest, pallets = [], loading, callbacks }) => {
    const [newPallets, setNewPallets] = useState([]);

    useEffect(() => {
        setNewPallets(pallets);
    }, [pallets]);

    const moveItem = (item, prevIdx, nextIdx) => {
        setNewPallets((prev) => {
            const removed = {
                ...prev[prevIdx],
                items: (prev[prevIdx]?.items || []).filter(({ item_id }) => item.item_id !== item_id),
            };

            const added = {
                ...prev[nextIdx],
                items: [...(prev[nextIdx]?.items || []), item],
            };

            return [
                ...prev.slice(0, Math.min(prevIdx, nextIdx)),
                ...(nextIdx < prevIdx ? [added] : [removed]),
                ...(nextIdx < prevIdx ? [removed] : [added]),
                ...prev.slice(Math.max(prevIdx, nextIdx) + 1),
            ];
        });
    };

    const onClose = () => {
        callbacks.consolidatePalletsInit(null);
    };

    const itemCount = useMemo(() => {
        if (pallets) {
            return pallets.reduce((acc, pallet) => {
                return acc + pallet.items?.length || 0;
            }, 0);
        } else return 0;
    }, [pallets]);

    return (
        <NavResponsiveModal open={!!pallets?.length} onClose={onClose}>
            <ModalHeader title={'Consolidate'} />
            <ModalContent
                css={css`
                    width: 400px;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                    `}
                >
                    {newPallets?.map((pallet, palletIdx) => (
                        <Grid
                            key={pallet.pallet_id}
                            container
                            direction="column"
                            css={css`
                                flex-wrap: nowrap;
                            `}
                        >
                            <Grid
                                item
                                css={css`
                                    padding: 0.5rem 0;
                                    ${palletIdx > 0 ? `border-top: 1px solid ${colors.greys.border};` : ''}
                                `}
                            >
                                <Body2>Pallet {pallet.pallet_number || palletIdx + 1}</Body2>
                            </Grid>
                            {pallet.items && pallet.items.length ? (
                                (pallet.items || []).map((item) => (
                                    <Grid
                                        key={item.item_id}
                                        container
                                        css={css`
                                            flex-wrap: nowrap;
                                            justify-content: space-between;
                                            align-items: center;
                                            padding: 0 0 0 1rem;
                                        `}
                                    >
                                        <Grid container direction="column">
                                            <Body2>SKU: {item.sku || '--'}</Body2>
                                            <Body1>
                                                {item.description || item.item_type_details} ({item.quantity || 1})
                                            </Body1>
                                        </Grid>
                                        <Grid
                                            container
                                            css={css`
                                                justify-content: flex-end;
                                            `}
                                        >
                                            <Grid item>
                                                <OnwardButtonIcon
                                                    small
                                                    Icon={ArrowUpwardIcon}
                                                    disabled={palletIdx === 0}
                                                    onClick={() => moveItem(item, palletIdx, palletIdx - 1)}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                css={css`
                                                    margin-left: 1rem;
                                                `}
                                            >
                                                <OnwardButtonIcon
                                                    small
                                                    Icon={ArrowDownwardIcon}
                                                    disabled={palletIdx === newPallets.length - 1}
                                                    onClick={() => moveItem(item, palletIdx, palletIdx + 1)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            ) : (
                                <Body1>Empty pallets will be deleted.</Body1>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading.palletLoading} onClick={onClose}>
                    {'Cancel'}
                </SecondaryButton>
                <PrimaryButton
                    disabled={loading.palletLoading || newPallets?.length >= itemCount}
                    onClick={() =>
                        callbacks.insertEmptyPallet({
                            variables: {
                                object: {
                                    type: 'SINGLE',
                                    warehouse_status: 'RECEIVED',
                                },
                            },
                            onCompleted: ({ insert_pallet_items_one }) => {
                                setNewPallets((prev) => [
                                    ...prev,
                                    {
                                        pallet_id: insert_pallet_items_one.pallet_id,
                                        pallet_number: insert_pallet_items_one.pallet_number,
                                        items: [],
                                    },
                                ]);
                            },
                        })
                    }
                >
                    Add Pallet
                </PrimaryButton>
                <PrimaryButton
                    disabled={loading.palletLoading}
                    onClick={() => {
                        callbacks.consolidatePallets(newPallets).then(() => {
                            onClose();
                        });
                    }}
                >
                    {'Save'}
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default ConsolidatePallets;
