export const FREIGHT_TYPES = [
    {
        label: 'Household',
        info: '(furniture, mattress)',
        value: 'household',
    },
    {
        label: 'Pallet',
        value: 'pallet',
    },
    {
        label: 'Remodelling',
        info: '(doors, carpet)',
        value: 'remodelling',
    },
    {
        label: 'Containers',
        value: 'containers',
    },
];

export const ITEM_LABELS_BY_FREIGHT = {
    household: 'Item',
    pallet: 'Pallet',
    remodellling: 'Item',
    containers: 'Container',
};

export const ITEM_TYPES = {
    household: [
        {
            label: 'Furniture',
            value: 'furniture',
        },
        {
            label: 'Mattress',
            value: 'mattress',
        },
        {
            label: 'Box Spring',
            value: 'boxSpring',
        },
        {
            label: 'Bed Frame',
            value: 'bedFrame',
        },
        {
            label: 'Appliance',
            value: 'appliance',
        },
        {
            label: 'Other',
            value: 'other',
        },
    ],
    remodelling: [
        {
            label: 'Cabinets',
            value: 'cabinets',
        },
        {
            label: 'Carpets',
            value: 'carpets',
        },
        {
            label: 'Doors',
            value: 'doors',
        },
        {
            label: 'Windows',
            value: 'windows',
        },
        {
            label: 'Other',
            value: 'other',
        },
    ],
};

export const BED_SIZES = [
    {
        value: 'cali_king',
        display: 'Cali King',
    },
    {
        value: 'king',
        display: 'King',
    },
    {
        value: 'queen',
        display: 'Queen',
    },
    {
        value: 'full_double',
        display: 'Double',
    },
    {
        value: 'twin',
        display: 'Twin',
    },
    {
        value: 'twin_xl',
        display: 'Twin XL',
    },
    {
        value: 'single',
        display: 'Single',
    },
];

export const ASSEMBLY_TYPES = [
    {
        value: 'no_assembly',
        display: 'No Assembly',
        description: 'Items are assembled and wrapped or boxed',
    },
    {
        value: 'minimal_assembly',
        display: 'Minimal Assembly',
        description: 'A few pieces require light assembly',
    },
    {
        value: 'heavy_assembly',
        display: 'Heavy Assembly',
        description: 'Items are completely unassembled and there are numerous parts',
    },
    {
        value: 'appliance',
        display: 'Appliance',
        description: '',
    },
];
