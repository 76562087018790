import React, { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { colors } from '@/styles';
import HubSpotControls from '@/utilities/hubspotControls';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid } from '@material-ui/core';

import { RootContainer } from '../Customer/blocks';
import Track from '../Customer/Track';


function TrackOrder() {
    const isDesktop = useMediaQuery('(min-width: 600px)');

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        HubSpotControls.hide();

        return () => {
            HubSpotControls.show();
        };
    }, []);

    let content = (
        <Grid
            container
            direction="column"
            css={css`
                flex-wrap: nowrap;
            `}
        >
            <Grid
                container
                css={css`
                    padding-top: 60px;
                    padding-bottom: 60px;
                    background: ${colors.white.primary};
                `}
            >
                <RootContainer>
                    <Grid container direction="column">
                        <Track
                            callbacks={{
                                onTrackFailed: () => {
                                    setErrorMessage('Failed to track order using the provided order number.');
                                },
                            }}
                            isDesktop={isDesktop}
                        />
                        {errorMessage && (
                            <div
                                css={css`
                                    padding-top: 2rem;
                                    text-align: center;
                                `}
                            >
                                <span
                                    css={css`
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: ${colors.reds.cancelled};
                                    `}
                                >
                                    {errorMessage}
                                </span>
                            </div>
                        )}
                    </Grid>
                </RootContainer>
            </Grid>
        </Grid>
    );

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
            `}
        >
            <Grid
                container
                css={css`
                    height: 100%;

                    padding-top: ${isDesktop ? `60px` : `30px`};
                `}
            >
                {content}
            </Grid>

        </Grid>
    );
}

export default TrackOrder;
