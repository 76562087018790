import { gql } from '@apollo/client';

import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { WAREHOUSE_EVENT_FIELDS } from '@/graphql/queries/order_wh_events';

export const RESCHEDULE_ORDERS = gql`
    ${ORDER_FIELDS}

    mutation RescheduleOrders($rescheduled_orders: [orders_insert_input!]!) {
        insert_orders(
            objects: $rescheduled_orders
            on_conflict: {
                constraint: orders_pkey
                update_columns: [rescheduled_order_id, mark_for_reschedule, order_status]
            }
        ) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;

export const UPDATE_ORDERS = gql`
    ${ORDER_FIELDS}

    mutation UpdateOrders($updates: [orders_updates!] = []) {
        update_orders_many(updates: $updates) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;

export const INSERT_EVENTS = gql`
    ${WAREHOUSE_EVENT_FIELDS}
    ${ORDER_FIELDS}

    mutation InsertEvents($events: [order_wh_events_insert_input!] = [], $orderUpdates: [orders_updates!] = {}) {
        insert_order_wh_events(objects: $events) {
            affected_rows
            returning {
                ...WarehouseEvents
            }
        }
        update_orders_many(updates: $orderUpdates) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;
