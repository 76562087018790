import React, { createContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '@/utilities/useQuery';
import { useLazyQuery } from '@apollo/client';
import cache from '@/graphql';
import * as Sentry from '@sentry/react';

import { parsePdf } from './queries/parsePdf';
import { createOrderJob } from './queries/createOrderJob';
import { QUERY_JOBS_BY_CLIENT } from './graphql/queries';
import { SHIPMENT_FORM_TYPES, SHIPMENT_FORM_MODAL_PROGRESSION, SHIPMENT_FORM_STATE } from './constants';
import { useShipmentFormCallbacks, useOrderErrors } from './hooks/form';
import { generateOptions } from './utilities/generateOptions';

const POLL_INVERVAL = 30 * 1000; //30s

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const [state, setState] = useState(SHIPMENT_FORM_STATE.CREATE);
    const [formType, setFormType] = useState(null);
    const [progression, setProgression] = useState({
        current: 0,
        type: null,
    });

    const [orders, setOrders] = useState({});
    const [multiPickup, setMultiPickup] = useState(false);
    const [pickupInfo, setPickupInfo] = useState({});
    const [uploadedFile, setUploadedFile] = useState(null);
    const [freightType, setFreightType] = useState('household');
    const [mapping, setMapping] = useState({});
    const [editing, setEditing] = useState(null);
    const [editingPickup, setEditingPickup] = useState(null);
    const [loading, setLoading] = useState(false);
    const [remaining, setRemaining] = useState(null);
    const [templateName, setTemplateName] = useState(null);
    const [pdf, setPdf] = useState({});

    const [successMsg, setSuccessMsg] = useState('');
    const [warningMsg, setWarningMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const csvOptions = useMemo(() => {
        return Object.fromEntries(generateOptions(multiPickup).map((option) => [option.key, option]));
    }, [multiPickup]);

    const [fetchJobs, { data: jobs, refetch }] = useLazyQuery(QUERY_JOBS_BY_CLIENT, {
        pollInterval: POLL_INVERVAL,
    });

    const [submitOrders, { loading: submitting }] = useQuery(createOrderJob, {
        onComplete: (resp) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'jobs' });
            setSuccessMsg('Order(s) successfully submitted!');

            if (resp?.data?.jobs.length > 0) {
                navigate(`/job/${resp.data.jobs[0]}`);
            } else {
                refetch();
            }
        },
        onError: (err) => {
            console.error(err);
            setErrorMsg('Failed to save orders!');
            refetch();

            Sentry.captureException(err);
        },
    });

    const [processPdf, { loading: processingPdf }] = useQuery(parsePdf, {
        onError: (err) => {
            console.error(err);
            setErrorMsg('Failed to parse pdf!');
            refetch();

            Sentry.captureException(err);
        },
    });

    const missingFieldsByOrder = useOrderErrors({ orders });

    const callbacks = useShipmentFormCallbacks(
        {
            orders,
            formType,
            freightType,
            mapping,
            pickupInfo,
            progression,
            state,
            uploadedFile,
            templateName,
            csvOptions,
            multiPickup,
            pdf,
        },
        {
            setEditing,
            setEditingPickup,
            setErrorMsg,
            setFormType,
            setFreightType,
            setLoading,
            setMapping,
            setOrders,
            setMultiPickup,
            setPickupInfo,
            setProgression,
            setRemaining,
            setTemplateName,
            setState,
            setSuccessMsg,
            setUploadedFile,
            setWarningMsg,
            submitOrders,
            processPdf,
        }
    );

    return (
        <Context.Provider
            value={{
                state: {
                    jobs,
                    isFirstStep: progression.current === 0,
                    current: (SHIPMENT_FORM_MODAL_PROGRESSION[progression.type] || [])[progression.current],
                    groupedPickup: multiPickup || formType === SHIPMENT_FORM_TYPES.PDF,
                    editing,
                    editingPickup,
                    formState: state,
                    formType,
                    freightType,
                    isLoading: loading,
                    isSubmitting: submitting,
                    isProcessingPdf: processingPdf,
                    orders,
                    pickupInfo,
                    remaining,
                    savedMapping: mapping,
                    templateName,
                    uploadedFile,
                    csvOptions,
                    multiPickup,
                    pdf,
                },
                callbacks: {
                    ...callbacks,
                    setOrders,
                    setErrorMsg,
                    setEditing,
                    setEditingPickup,
                    setFormType,
                    setFreightType,
                    setMapping,
                    setProgression,
                    setSuccessMsg,
                    setUploadedFile,
                    setWarningMsg,
                    setLoading,
                    setPdf,
                    setTemplateName,
                    fetchJobs,
                    setMultiPickup,
                },
                errors: {
                    errorMsg,
                    missingFieldsByOrder,
                    successMsg,
                    warningMsg,
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};
