import React, { useEffect, useCallback, useMemo, forwardRef, useRef, useState, useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTable, useSortBy, useRowSelect, useFlexLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { TableContainer, Table, TableHead } from '@material-ui/core';
import { validate } from '@onward-delivery/core';
import { useTableColumns } from './columns';
import HeaderCell from './table/HeaderCell';
import BodyCell from './table/BodyCell';
import BodyRow from './table/BodyRow';
import { CustomCheckbox } from './blocks';
import { Context } from './store';
import { useClientUser } from '@/hooks';
import { isEqual } from 'lodash';
import { MODALS } from './constants';
import { useNavigate } from 'react-router';
import { BIDDING_DISABLED } from '@/constants/featureFlags';

export const StickyTable = styled(({ children, ...props }) => <Table {...props}>{children}</Table>)`
    overflow: scroll;
`;

export const StickyHeader = styled(({ children, ...props }) => <TableHead {...props}>{children}</TableHead>)`
    position: sticky;
    z-index: 1;
    width: fit-content;
`;

export const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        if (resolvedRef.current) {
            resolvedRef.current.indeterminate = indeterminate;
        }
    }, [resolvedRef, indeterminate]);

    return (
        <CustomCheckbox
            ref={resolvedRef}
            {...rest}
            css={css`
                .MuiSvgIcon-root {
                    height: 20px;
                    width: 20px;
                }
            `}
        />
    );
});

function SaaSOrderTable() {
    const navigate = useNavigate();
    const [sortBy, setSortBy] = useState([{ id: 'delivery_date', desc: true }]);
    const { circles, stripe_payment_method, payment_type, userType, user_id } = useClientUser();

    const {
        state: { orders, tab, selectedOrderIds },
        callbacks,
    } = useContext(Context);

    const columns = useTableColumns({
        editEnabled: tab.editEnabled,
        marketplaceEnabled: circles?.['ltl-marketplace'],
        userType,
        user_id,
    });

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state } = useTable(
        {
            columns,
            data: orders,
            initialState: {
                selectedRowIds: selectedOrderIds,
                sortBy,
            },
            getRowId: (order) => order.order_id,
            enableRowSelection: true,
            enableMultiRowSelection: true,
            autoResetSelectedRows: true,
        },
        useSortBy,
        useRowSelect,
        useSticky,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    sticky: 'left',
                    width: 50,
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            onClick={(e) => e.stopPropagation()}
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        if (state?.sortBy?.length) {
            setSortBy(state.sortBy);
        }
    }, [state?.sortBy]);

    useEffect(() => {
        callbacks.setSelectedOrderIds(state?.selectedRowIds);
    }, [state?.selectedRowIds]);

    const sendSingleOrderToMarketplace = (order) => {
        callbacks.setSelectedOrderIds({ [order.order_id]: true });

        const [invalidOrder, hasMissingField] = validate({ ...order, oms: false });
        if (hasMissingField?.length > 0) {
            callbacks.editOrder(invalidOrder);
            callbacks.setNotification({ severity: 'warning', message: 'Cannot submit to Onward, order incomplete' });
        } else if (payment_type === 'Invoice' || stripe_payment_method) {
            if (circles?.[BIDDING_DISABLED]) {
                callbacks.setModal(MODALS.MARKETPLACE);
            } else {
                callbacks.setModal(MODALS.LISTING_OPTIONS);
            }
        } else {
            callbacks.setModal(MODALS.CREDIT_CARD);
        }
    };

    const sendSingleOrderToPlanning = (order) => {
        callbacks.setSelectedOrderIds({ [order.order_id]: true });

        switch (tab.value) {
            case 0:
                callbacks.setModal(MODALS.PLANNING);
                break;
            case 1:
                callbacks.setModal(MODALS.RESCHEDULE);
                break;
        }
    };

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.reduce(
                            (acc, subgroup) => [
                                ...acc,
                                subgroup.headers.map((col) => (
                                    <HeaderCell {...col.getHeaderProps()} key={col.id} cell={col} align={col.align} />
                                )),
                            ],
                            []
                        )}
                    </div>
                );
            }

            //BODY
            const row = rows[index - 1];
            prepareRow(row);

            return (
                <BodyRow
                    {...row.getRowProps({
                        style,
                    })}
                    row={row}
                    onClick={() => navigate(`/order/${row.original.order_id}`)}
                >
                    {row.cells.map((cell) => (
                        <BodyCell
                            {...cell.getCellProps()}
                            cell={cell}
                            align={cell.column.align}
                            callbacks={{
                                onEditOrder: callbacks.editOrder,
                                onClickOnward: sendSingleOrderToMarketplace,
                                onClickPlan: sendSingleOrderToPlanning,
                            }}
                        />
                    ))}
                </BodyRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList height={height} itemCount={rows.length + 1} itemSize={57} width={width}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </TableContainer>
    );
}

export default React.memo(SaaSOrderTable);
