import { colors } from '@/styles';
import PlaceIcon from '@mui/icons-material/Place';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import {
    MANIFEST_STATUSES,
    MANIFEST_STATUS_LABELS,
    MANIFEST_TYPE_LABELS,
    RECEIVED_STATUSES,
    STAGED_STATUSES,
} from '@/constants/manifestStatuses';
import { startCase } from 'lodash';
import { formatInTimeZone } from 'date-fns-tz';
import { EXCEPTION_TYPES } from '@onward-delivery/core';

const BASE_COLUMNS = [
    {
        Header: 'Manifest',
        id: 'manifest_number',
        width: 75,
        accessor: (manifest) => manifest.manifest_number,
    },
    {
        Header: 'Type',
        id: 'type',
        width: 150,
        accessor: (manifest) => MANIFEST_TYPE_LABELS[manifest.type] || '--',
    },
];

const STATUS_COLUMN_GROUP = {
    Header: 'Manifest Right',
    id: 'manifest-right-group',
    sticky: 'right',
    columns: [
        {
            Header: 'Status',
            id: 'status',
            width: 150,
            Cell: ({ row, callbacks }) => {
                const { status } = row.original;
                return (
                    <span>
                        <PlaceIcon
                            css={css`
                                color: ${MANIFEST_STATUS_LABELS[status]?.color || colors.greys.secondary};
                            `}
                        />
                        {MANIFEST_STATUS_LABELS[status]?.label || 'Unknown'}
                    </span>
                );
            },
        },
    ],
};

const INBOUND_COLUMNS = [
    {
        Header: 'Manifest Main',
        id: 'manifest-main-group',
        columns: [
            ...BASE_COLUMNS,
            {
                Header: 'Receiving Date',
                id: 'receiving_date',
                width: 150,
                accessor: (manifest) =>
                    manifest.route?.scheduled_delivery_formatted ||
                    (manifest.receiving_date
                        ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                        : '--'),
            },
            {
                Header: 'Cu. Ft.',
                id: 'cubic_feet',
                width: 75,
                accessor: (manifest) => (manifest.cubes ? parseInt(manifest.cubes) : 0),
            },
            {
                Header: 'Orders',
                id: 'orders',
                width: 75,
                accessor: (manifest) => manifest.orders?.length || 0,
            },
            {
                Header: 'Items',
                id: 'items',
                width: 75,
                accessor: (manifest) => manifest.total || 0,
            },
            {
                Header: 'Received',
                id: 'received',
                width: 100,
                accessor: (manifest) => `${manifest.received || 0} / ${manifest.total || 0}`,
            },
            {
                Header: 'Origin',
                id: 'origin',
                width: 150,
                accessor: (manifest) => manifest.origin || '--',
            },
            {
                Header: 'Destination',
                id: 'destination',
                width: 150,
                accessor: (manifest) => manifest.destination || '--',
            },
        ],
    },
    STATUS_COLUMN_GROUP,
];

const OUTBOUND_COLUMNS = [
    {
        Header: 'Manifest Main',
        id: 'manifest-main-group',
        columns: [
            ...BASE_COLUMNS,
            {
                Header: 'Route',
                id: 'route',
                width: 75,
                accessor: (manifest) => manifest.route?.route_number || '--',
            },
            {
                Header: 'Truck',
                id: 'truck',
                width: 100,
                accessor: (manifest) => manifest.route?.truck?.truck_name || '--',
            },
            {
                Header: 'Driver',
                id: 'driver',
                width: 150,
                accessor: (manifest) => manifest.route?.driver?.username || '--',
            },
            {
                Header: 'Scheduled Delivery',
                id: 'scheduled_delivery',
                width: 150,
                accessor: (manifest) =>
                    manifest.route?.scheduled_delivery_formatted ||
                    (manifest.receiving_date
                        ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                        : '--'),
            },
            {
                Header: 'Cu. Ft.',
                id: 'cubic_feet',
                width: 75,
                accessor: (manifest) => (manifest.cubes ? parseInt(manifest.cubes) : 0),
            },
            {
                Header: 'Orders',
                id: 'orders',
                width: 75,
                accessor: (manifest) => manifest.orders?.length || 0,
            },
            {
                Header: 'Items',
                id: 'items',
                width: 75,
                accessor: (manifest) => manifest.total || 0,
            },
            {
                Header: 'Staged',
                id: 'staged',
                width: 100,
                accessor: (manifest) => `${manifest.staged || 0} / ${manifest.total || 0}`,
            },
        ],
    },
    STATUS_COLUMN_GROUP,
];

export const TABS = {
    INBOUND: {
        value: 'INBOUND',
        label: 'Inbound',
        columns: INBOUND_COLUMNS,
        statusOptions: [MANIFEST_STATUSES.NOT_RECEIVED, MANIFEST_STATUSES.IN_PROGRESS, MANIFEST_STATUSES.RECEIVED],
        typeOptions: ['INBOUND', 'CROSS_DOCK'],
        defaultFilters: { status: MANIFEST_STATUSES.NOT_RECEIVED },
    },
    OUTBOUND: {
        value: 'OUTBOUND',
        label: 'Outbound',
        columns: OUTBOUND_COLUMNS,
        statusOptions: [
            MANIFEST_STATUSES.AWAITING_STAGING,
            MANIFEST_STATUSES.IN_PROGRESS,
            MANIFEST_STATUSES.STAGED,
            MANIFEST_STATUSES.COMPLETE,
        ],
        typeOptions: ['OUTBOUND', 'RETURN_TO_SENDER', 'WILL_CALL'],
    },
};

export const DAYS_30 = 2592000000;

export const MANIFEST_SEARCHABLE = [
    'manifest_number',
    'origin',
    'destination',
    'items.item.sku',
    'items.item.description',
    'items.item.order.order_number',
    'items.item.order.po_number',
];
export const ORDER_SEARCHABLE = [
    'order_number',
    'po_number',
    'itemsByOrderId.sku',
    'itemsByOrderId.description',
    'itemsByOrderId.pallet.pallet_number',
    'itemsByOrderId.pallet.pallet_name',
];
