import { STEPS, useCrossdockingSteps } from '@onward-delivery/core';
import { OnwardCardMobile } from '../OrderDetailsPage/Card';
import Claimed from '../OrderDetailsPage/Crossdocking/Claimed';
import Assigned from '../OrderDetailsPage/Crossdocking/Assigned';
import Received from '../OrderDetailsPage/Crossdocking/Received';
import PickedUp from '../OrderDetailsPage/Crossdocking/PickedUp';
import Complete from '../OrderDetailsPage/Crossdocking/Complete';
import Crossdocked from '../OrderDetailsPage/Crossdocking/Crossdocked';
import CrossdockPickup from '../OrderDetailsPage/Crossdocking/CrossdockPickup';
import Attempt from '../OrderDetailsPage/Crossdocking/Attempt';
import { Grid } from '@material-ui/core';

const DriverCrossdockStepper = ({ order, events, warehouse, isLoading, callbacks }) => {
    const params = {
        order,
        isLoading,
        warehouse,
        callbacks,
        isDesktop: false,
    };

    const steps = useCrossdockingSteps(events);
    const driverSteps = steps.slice(
        steps.findLastIndex(([step, meta]) =>
            [STEPS.PICKED_UP, STEPS.CROSS_DOCKED, STEPS.CROSS_DOCK_PICKUP].includes(step)
        )
    );

    const content = driverSteps.map(([step, meta]) => {
        switch (step) {
            case STEPS.CLAIMED:
                return <Claimed {...meta} {...params} />;
            case STEPS.ASSIGNED:
                return <Assigned {...meta} {...params} />;
            case STEPS.RECEIVED:
                return <Received {...meta} {...params} />;
            case STEPS.PICKED_UP:
                return <PickedUp {...meta} {...params} />;
            case STEPS.COMPLETE:
                return <Complete {...meta} {...params} />;
            case STEPS.CROSS_DOCKED:
                return <Crossdocked {...meta} {...params} />;
            case STEPS.CROSS_DOCK_PICKUP:
                return <CrossdockPickup {...meta} {...params} />;
            case STEPS.ATTEMPT:
                return <Attempt {...meta} {...params} />;
        }
    });

    return (
        <OnwardCardMobile>
            <Grid direction="column" container>
                {content}
            </Grid>
        </OnwardCardMobile>
    );
};

export default DriverCrossdockStepper;
