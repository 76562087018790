import React, { useState, useMemo, useCallback } from 'react';
import { isBefore, isAfter, addDays, isEqual } from 'date-fns';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { Grid, DialogContent, Radio, FormControlLabel, TextField } from '@material-ui/core';
import { Incrementor } from '@/components/ShipmentForm/blocks';
import { asDateInTZ, asBrowserDate, asUTCDate } from '@/utilities/convertToISO';
import {
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
    ResponsiveSidebarDialog,
    StickyModalActions,
} from '@/styles/blocks';
import { genAttributes } from '@onward-delivery/core';
import { genResources, genRecommendations } from '@/components/Resources/utils';
import { colors } from '@/styles';

import { Body1 } from '../blocks';

const TODAY = new Date();

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const ScheduleOrderModal = ({ orders, loading, callbacks }) => {
    const [updates, setUpdate] = useState({
        delivery_stair_quantity: 0,
    });

    const updated = useMemo(() => {
        return orders.map((order) => ({
            ...order,
            ...updates,
        }));
    }, [updates, orders]);

    const tz = useMemo(() => {
        if (updated.length > 0) {
            const { zip } = genAttributes(updated);
            const tz = updated[zip] ? zipcode_to_timezone.lookup(updated[zip]) : 'America/New_York';

            return tz;
        }

        return 'America/New_York';
    }, [updated]);

    const interval = useMemo(() => {
        const adjustTZ = (ts) => {
            const utc = new Date(asUTCDate(ts).setUTCHours(0, 0, 0, 0)).toISOString();
            return asDateInTZ(utc, tz).toISOString();
        };

        const rv = [];
        const todayAdjusted = new Date(adjustTZ(TODAY.toISOString()));
        const start = todayAdjusted;
        const end = addDays(start, 30);

        let curr = start;
        while (isEqual(curr, end) || isBefore(curr, end)) {
            const next = addDays(curr, 1);
            const dow = curr.getUTCDay();
            rv.push({
                thisYear: todayAdjusted.getFullYear() === curr.getFullYear(),
                isCurrentMonth: todayAdjusted.getMonth() === curr.getMonth(),
                thisMonth: todayAdjusted.getMonth() === curr.getMonth(),
                isToday: todayAdjusted.getDate() === curr.getDate(),
                date: curr.getDate(),
                start: curr.toISOString(),
                end: next.toISOString(),
                dow,
            });

            curr = next;
        }

        return rv;
    }, [tz]);

    const resources = useMemo(() => {
        const [resources, count] = orders.reduce(
            ([all, count], order) => {
                let resources;
                if (order.oms) {
                    const { default: shipperDefault, override: shipperOverride } = genResources({
                        client: order.order_shipper || {},
                        order: order,
                    });
                    resources = shipperOverride.length > 0 ? shipperOverride : shipperDefault;
                } else {
                    const { default: carrierDefault, override: carrierOverride } = genResources({
                        client: order.order_carrier || {},
                        order: order,
                    });
                    resources = carrierOverride.length > 0 ? carrierOverride : carrierDefault;
                }

                resources.forEach((resource) => {
                    all[resource.resource_id] = resource;
                    count[resource.resource_id] = count[resource.resource_id] ? count[resource.resource_id] + 1 : 1;
                });

                return [all, count];
            },
            [{}, {}]
        );

        return Object.entries(count)
            .filter(([, count]) => {
                return count === orders.length;
            })
            .map(([id]) => resources[id]);
    }, [orders]);

    const recommendations = useMemo(() => {
        if (orders.length === 0) {
            return [];
        }

        return genRecommendations({ resources, range: interval });
    }, [interval, orders, resources]);

    const toDropoffTz = useCallback(
        (d) => {
            const clone = new Date(d);
            clone.setUTCHours(0, 0, 0, 0);
            const fixed = asDateInTZ(clone.toISOString(), tz);
            return fixed.toISOString();
        },
        [tz]
    );

    if (orders.length === 0) {
        return null;
    }

    return (
        <ResponsiveSidebarDialog
            open={orders.length > 0}
            onClose={() => {
                setUpdate({});
                callbacks.onClose();
            }}
            fullWidth={true}
            maxWidth="sm"
            css={css`
                .MuiDialog-container {
                    align-items: normal;
                }
            `}
        >
            <ModalTitle
                border
                title={`Delivery Date`}
                onClose={() => {
                    setUpdate({});
                    callbacks.onClose();
                }}
            />
            <DialogContent
                css={css`
                    width: 100%;
                    padding: 0;
                `}
            >
                <Grid
                    constainer
                    direction="column"
                    css={css`
                        height: 100%;
                        padding: 0 24px;

                        padding-top: 32px;
                    `}
                >
                    <Grid
                        constainer
                        direction="row"
                        css={css`
                            margin-bottom: 40px;
                        `}
                    >
                        <Grid constainer direction="column">
                            {recommendations.slice(0, 5).map((recommendation) => {
                                const inLocal = asBrowserDate(recommendation.start);
                                return (
                                    <Grid
                                        direction="row"
                                        item
                                        css={css`
                                            margin-bottom: 8px;
                                        `}
                                    >
                                        <FormControlLabel
                                            checked={
                                                !!(
                                                    updates.delivery_date &&
                                                    updates.delivery_date === toDropoffTz(inLocal)
                                                )
                                            }
                                            control={<Radio color="primary" />}
                                            label={<Body1>{dateShort.format(inLocal)}</Body1>}
                                            onChange={() => {
                                                setUpdate((prev) => {
                                                    return {
                                                        ...prev,
                                                        delivery_date: toDropoffTz(inLocal),
                                                        planning: true,
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    {orders.length === 1 ? (
                        <>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 20px;
                                `}
                            >
                                <Grid
                                    direction="column"
                                    css={css`
                                        flex: 0;
                                        flex-basis: content;
                                        align-content: center;
                                    `}
                                >
                                    <Body1
                                        css={css`
                                            margin-right: 30px;
                                        `}
                                    >
                                        Flights of Stairs:
                                    </Body1>
                                </Grid>
                                <Grid
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        align-content: center;
                                    `}
                                >
                                    <Incrementor
                                        body={updates.delivery_stair_quantity || 0}
                                        incrementCallback={() =>
                                            setUpdate((prev) => ({
                                                ...prev,
                                                delivery_stair_quantity: (prev.delivery_stair_quantity || 0) + 1,
                                            }))
                                        }
                                        decrementCallback={() =>
                                            setUpdate((prev) => ({
                                                ...prev,
                                                delivery_stair_quantity: Math.max(0, prev.delivery_stair_quantity - 1),
                                            }))
                                        }
                                        css={css`
                                            width: auto;
                                        `}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 20px;
                                `}
                            >
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    placeholder="Special Delivery Instructions"
                                    multiline
                                    value={updates.customer_instructions || ''}
                                    onChange={(e) =>
                                        setUpdate((prev) => ({
                                            ...prev,
                                            customer_instructions: e.target.value,
                                        }))
                                    }
                                />
                            </Grid>

                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 20px;
                                `}
                            >
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    placeholder="Gate Code"
                                    value={updates.gate_code || ''}
                                    onChange={(e) =>
                                        setUpdate((prev) => ({
                                            ...prev,
                                            gate_code: e.target.value,
                                        }))
                                    }
                                />
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </DialogContent>
            <StickyModalActions
                border="true"
                css={css`
                    justify-content: flex-end;
                `}
            >
                <Grid>
                    <SecondaryButton
                        onClick={() => {
                            setUpdate({});
                            callbacks.onClose();
                        }}
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>
                <Grid>
                    <PrimaryButton
                        loading={loading}
                        disabled={!updates.delivery_date}
                        onClick={() => {
                            setUpdate({});
                            callbacks.onSubmit({
                                updates: updated.map((order) => {
                                    return {
                                        order,
                                        updates: {
                                            ...(updated.length === 1
                                                ? {
                                                      dropoff_address_confirmed: true,
                                                      del_stairs: order.delivery_stair_quantity > 0,
                                                      delivery_stair_quantity: order.delivery_stair_quantity,
                                                      customer_instructions: order.customer_instructions,
                                                      gate_code: order.gate_code,
                                                  }
                                                : {}),
                                            delivery_date: order.delivery_date,
                                            planning: true,
                                        },
                                    };
                                }),
                            });
                        }}
                    >
                        Assign
                    </PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default ScheduleOrderModal;
