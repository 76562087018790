import { AbstractAlgorithm } from '@googlemaps/markerclusterer';

export class NoopAlgorithm extends AbstractAlgorithm {
    constructor(options = { maxZoom: 16 }) {
        super(options);
    }

    calculate({ markers, map, mapCanvasProjection }) {
        return {
            clusters: this.cluster({ markers, map, mapCanvasProjection }),
        };
    }

    cluster(input) {
        return this.noop(input);
    }
}
