import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, typography } from '@/styles';

export const ControlContainer = styled((props) => <div {...props} />)`
    display: flex;
    align-items: center;
    margin: 20px 35px;
    height: 36px;
    border-radius: 8px;
    background-color: ${colors.greys.rgbBackground};
    color: ${({ error }) => (error ? colors.reds.mapErrorStatus : colors.greys[3])};

    @media only screen and (max-width: 900px) {
        margin: 4px 7px;
    }
`;

export const ControlIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 36px;
    width: 36px;
    border-radius: 8px;
    background-color: ${colors.greens.primary};
    cursor: pointer;
`;

export const ControlTextBody = styled.span`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 500;
    padding: 0px 12px;
`;

export const ControlTextHeader = styled.span`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 700;
    padding: 0px 12px;
`;
