import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Grid, IconButton, MenuItem, TextField } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useClientUser } from '@/hooks';
import { colors } from '@/styles';
import { useMutation } from '@apollo/client';
import { LIST_TARIFFS_BY_CLIENT_ID, DUPLICATE_PRICING_TARIFF, CREATE_TARIFF, DELETE_TARIFF } from './Tariffs/graphql';
import { getTariffName } from './Tariffs/utils';
import { captureException } from '@sentry/react';
import { TabCard, Title, Body1 } from './blocks';
import { PrimaryButton } from '@/styles/blocks';
import { useState } from 'react';

const ALGO_TYPES = [
    {
        value: 'ONWARD_CONSOLIDATED',
        label: 'Onward V2',
        public: true,
    },
    { value: 'ROCKET_SHIPPING', label: 'Rocket Shipping', public: false },
    { value: 'LIVING_SPACES', label: 'Living Spaces', public: false },
    { value: 'DEFAULT', label: 'Onward V1', public: true },
];

const Tariffs = ({ tariffs, overrides, callbacks }) => {
    const navigate = useNavigate();
    const { user_id } = useClientUser();
    const [algo, setAlgo] = useState();

    const algoTypes = useMemo(() => {
        const overrideTypes = (overrides || []).map((o) => o.algo_type);
        return ALGO_TYPES.filter((algo) => algo.public || overrideTypes.includes(algo.value));
    }, [overrides]);

    const defaultOverride = useMemo(() => {
        return (
            overrides.find((override) => {
                return override.tariff_id && !override.partner_client_id;
            }) || {}
        );
    }, [overrides]);

    const [createTariff] = useMutation(CREATE_TARIFF, {
        onCompleted: (resp) => {
            if (resp?.tariffs?.returning.length > 0) {
                navigate(`/account/tariff/${resp.tariffs.returning[0].tariff_id}`);
            }
        },
        onError: (e) => {
            console.log(e);
            captureException(e);
        },
    });

    const [duplicateTariff] = useMutation(DUPLICATE_PRICING_TARIFF, {
        onCompleted: (resp) => {
            if (resp?.duplicated.length > 0) {
                navigate(`/account/tariff/${resp.duplicated[0].tariff_id}`);
            }
        },
        onError: (e) => {
            console.log(e);
            captureException(e);
        },
    });

    const [deleteTariff, { loading: deleteLoading }] = useMutation(DELETE_TARIFF, {
        update: (cache, { data: { deleted } }) => {
            cache.updateQuery(
                {
                    query: LIST_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: user_id,
                    },
                },
                (data) => {
                    const { tariffs, overrides } = data;
                    let clone = [...tariffs];
                    clone = clone.filter((tariff) => tariff.tariff_id !== deleted.tariff_id);

                    return {
                        tariffs: clone,
                        overrides,
                    };
                }
            );
        },
        onError: (e) => {
            console.log(e);
            captureException(e);
        },
    });

    const loading = deleteLoading;

    return (
        <TabCard>
            <Grid
                container
                direction="column"
                css={css`
                    max-width: 684px;
                    margin: auto;
                    padding-bottom: 48px;
                `}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        padding: 28px 0;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            justify-content: center;
                        `}
                    >
                        <Title>Tariffs</Title>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            justify-content: center;
                        `}
                    >
                        <TextField
                            size="small"
                            label="Type"
                            variant="outlined"
                            select
                            value={algo || ''}
                            onChange={(e) => setAlgo(e.target.value)}
                            error={!algo}
                            css={css`
                                width: 200px;
                                margin-right: 1rem;
                            `}
                        >
                            {algoTypes.map((algo) => (
                                <MenuItem value={algo.value} key={algo.value}>
                                    {algo.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            justify-content: center;
                        `}
                    >
                        <PrimaryButton
                            css={css`
                                margin: 1rem 0rem;
                            `}
                            disabled={!algo}
                            onClick={() => {
                                createTariff({
                                    variables: {
                                        tariffs: [
                                            {
                                                client_id: user_id,
                                                algo_type: algo,
                                            },
                                        ],
                                    },
                                });
                            }}
                        >
                            Create
                        </PrimaryButton>
                    </Grid>
                </Grid>
                {tariffs.map((tariff, idx) => {
                    return (
                        <Grid
                            key={tariff.tariff_id}
                            container
                            direction="row"
                            css={css`
                                cursor: pointer;
                                border: 1px solid #e2e2e2;
                                padding: 16px 12px;
                                border-bottom: ${idx === tariffs.length - 1 ? '1px solid #e2e2e2' : '0'};
                                border-bottom-left-radius: ${idx === tariffs.length - 1 ? '4px' : '0'};
                                border-bottom-right-radius: ${idx === tariffs.length - 1 ? '4px' : '0'};
                                border-top-left-radius: ${idx === 0 ? '4px' : '0'};
                                border-top-right-radius: ${idx === 0 ? '4px' : '0'};
                            `}
                        >
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                                onClick={() => {
                                    navigate(`/account/tariff/${tariff.tariff_id}`);
                                }}
                            >
                                <Grid container direction="row">
                                    <Body1>{getTariffName(tariff, idx)}</Body1>
                                    <Body1
                                        css={css`
                                            margin-left: 12px;
                                            color: #8d8d8d;
                                        `}
                                    >
                                        ({algoTypes.find((algo) => algo.value === tariff.algo_type)?.label})
                                    </Body1>
                                    {tariff.tariff_id === defaultOverride?.tariff_id ? (
                                        <Body1
                                            css={css`
                                                margin-left: 12px;
                                                color: #8d8d8d;
                                            `}
                                        >
                                            (Default)
                                        </Body1>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    css={css`
                                        flex-wrap: nowrap;
                                    `}
                                >
                                    {tariff.tariff_id !== defaultOverride?.tariff_id ? (
                                        <Grid
                                            container
                                            item
                                            direction="column"
                                            css={css`
                                                flex: 0;
                                                flex-basis: 0;
                                                justify-content: center;
                                                margin-right: 14px;
                                            `}
                                        >
                                            <a
                                                css={css`
                                                    white-space: nowrap;
                                                    cursor: pointer;
                                                    color: ${colors.greens.primary};

                                                    :hover {
                                                        ${colors.greens.primary};
                                                    }
                                                `}
                                                onClick={() => {
                                                    callbacks.upsertPo({
                                                        variables: {
                                                            override: {
                                                                client_id: user_id,
                                                                partner_client_id: null,
                                                                tariff_id: tariff.tariff_id,
                                                                algo_type: tariff.algo_type,
                                                                oms: true,
                                                            },
                                                        },
                                                    });
                                                }}
                                            >
                                                <Body1>Change to default</Body1>
                                            </a>
                                        </Grid>
                                    ) : null}

                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        css={css`
                                            flex: 0;
                                            flex-basis: 0;
                                            justify-content: center;
                                        `}
                                    >
                                        <IconButton
                                            disabled={loading}
                                            onClick={() =>
                                                duplicateTariff({
                                                    variables: {
                                                        tariff_id: tariff.tariff_id,
                                                    },
                                                })
                                            }
                                            css={css`
                                                padding: 4px;
                                            `}
                                        >
                                            <FileCopyIcon color={loading ? 'disabled' : undefined} />
                                        </IconButton>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        css={css`
                                            flex: 0;
                                            flex-basis: 0;
                                            justify-content: center;
                                        `}
                                    >
                                        <IconButton
                                            disabled={loading}
                                            onClick={() =>
                                                deleteTariff({
                                                    variables: {
                                                        tariff_id: tariff.tariff_id,
                                                    },
                                                })
                                            }
                                            css={css`
                                                padding: 4px;
                                            `}
                                        >
                                            <DeleteForeverOutlined color={loading ? 'disabled' : 'error'} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </TabCard>
    );
};

export default Tariffs;
