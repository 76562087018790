import React from 'react';

export const RATES = [
    {
        name: 'Curbside',
        xLabels: ['2 items', 'Each item over 2', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76-100 miles', '100+ miles', '100+ miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['MILES_CURBSIDE_BASE_RATE', 'ITEM_MILES_CURBSIDE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'MILES_DEFAULT_INCREMENT_RATE',
                    bucket: 1,
                    decorator: 'per mile',
                },
                {},
            ],
        ],
    },
    {
        name: 'First Dry Space',
        xLabels: ['2 items', 'Each item over 2', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76-100 miles', '100+ miles', '100+ miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['MILES_FIRST_DRY_SPACE_BASE_RATE', 'ITEM_MILES_FIRST_DRY_SPACE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'MILES_DEFAULT_INCREMENT_RATE',
                    bucket: 1,
                    decorator: 'per mile',
                },
                {},
            ],
        ],
    },
    {
        name: 'Room of Choice',
        xLabels: ['2 items', 'Each item over 2', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76-100 miles', '100+ miles', '100+ miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['MILES_ROOM_OF_CHOICE_BASE_RATE', 'ITEM_MILES_ROOM_OF_CHOICE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'MILES_DEFAULT_INCREMENT_RATE',
                    bucket: 1,
                    decorator: 'per mile',
                },
                {},
            ],
        ],
    },
    {
        name: 'White Glove',
        xLabels: ['2 items', 'Each item over 2', ''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76-100 miles', '100+ miles', '100+ miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['MILES_WHITE_GLOVE_BASE_RATE', 'ITEM_MILES_WHITE_GLOVE_RATE'].map((type, col) => {
                    return {
                        type,
                        bucket: idx,
                        ...(col === 2
                            ? {
                                  decorator: 'per lb',
                              }
                            : {}),
                    };
                });
            }),
            [
                {
                    type: 'MILES_DEFAULT_INCREMENT_RATE',
                    bucket: 1,
                    decorator: 'per mile',
                },
                {},
            ],
        ],
    },
];

export const DEFAULT_RATES = {
    MILES_CURBSIDE_BASE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    MILES_FIRST_DRY_SPACE_BASE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    MILES_ROOM_OF_CHOICE_BASE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    MILES_WHITE_GLOVE_BASE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],

    MILES_DEFAULT_INCREMENT_RATE: [
        { min: 0, max: 100, rate: 0 },
        { min: 100, max: 'infinity' },
    ],

    ITEM_MILES_CURBSIDE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    ITEM_MILES_FIRST_DRY_SPACE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    ITEM_MILES_ROOM_OF_CHOICE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    ITEM_MILES_WHITE_GLOVE_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],

    STAIR_BASE_RATE: [{ min: 0, max: 'infinity' }],
    STAIR_INCREMENTAL_RATE: [{ min: 2, max: 'infinity' }],
    LABOR_MAX_RATE: [{ min: 0, max: 'infinity' }],
    LABOR_INCREMENTAL_RATE: [{ min: 0, max: 'infinity' }],
    HAULAWAY_INCREMENTAL_ITEM_RATE: [{ min: 1, max: 'infinity' }],
    HAULAWAY_BASE_ITEM_RATE: [{ min: 0, max: 'infinity' }],
    RETURN_INCREMENTAL_ITEM_RATE: [{ min: 2, max: 'infinity' }],
    RETURN_BASE_ITEM_RATE: [{ min: 0, max: 'infinity' }],
    EXCHANGE_MODIFIER_RATE: [{ min: 0, max: 'infinity' }],
    ATTEMPT_MODIFIER_RATE: [{ min: 0, max: 'infinity' }],
    ATTEMPT_FLAT_RATE: [{ min: 0, max: 'infinity' }],
};

export const ACCESSORIALS = [
    { label: 'Additional Labor Max Rate', type: 'LABOR_MAX_RATE', adornment: '$' },
    { label: 'Additional Labor', type: 'LABOR_INCREMENTAL_RATE', adornment: '$', decorator: 'per 15 min' },
    { label: 'Disposal', type: 'HAULAWAY_BASE_ITEM_RATE', adornment: '$', decorator: 'first 2 pieces' },
    { label: 'Disposal', type: 'HAULAWAY_INCREMENTAL_ITEM_RATE', adornment: '$', decorator: 'additional' },
    { label: 'Return Shipment', type: 'RETURN_BASE_ITEM_RATE', adornment: '$' },
    {
        label: 'Stairs',
        type: 'STAIR_BASE_RATE',
        adornment: '$',
    },
    {
        label: 'Stair Incremental',
        type: 'STAIR_INCREMENTAL_RATE',
        adornment: '$',
        decorator: 'after 2 flights',
    },
    {
        label: 'Storage',
        type: 'STORAGE_RATE',
        adornment: '$',
        decorator: 'per day (after 14 days)',
    },
    { label: 'Exchange (percentage)', type: 'EXCHANGE_MODIFIER_RATE', adornment: '%', isPercentage: true },
    { label: 'Attempt (percentage)', type: 'ATTEMPT_MODIFIER_RATE', adornment: '%', isPercentage: true },
    { label: 'Attempt (flat)', type: 'ATTEMPT_FLAT_RATE', adornment: '$' },
    {
        label: 'Fuel Modifier',
        type: 'FUEL_PRICE_MODIFIER',
        adornment: '%',
        isPercentage: true,
        decorator: 'of subtotal',
    },
    {
        label: 'Additional Mileage',
        type: 'MILEAGE_RATE',
        adornment: '$',
        decorator: 'per mile over limit',
    },
];

export const RenderLSAccessorial = ({
    DefaultAccessorial,
    MaxSplitAccessorial,
    accessorial,
    rates,
    errors,
    callbacks,
}) => {
    switch (accessorial.type) {
        case 'ATTEMPT_MODIFIER_RATE':
        case 'FUEL_PRICE_MODIFIER':
        case 'MILEAGE_RATE':
            return null;
        case 'ATTEMPT_FLAT_RATE': {
            return (
                <MaxSplitAccessorial
                    label="Delivery Attempt"
                    percentage="ATTEMPT_MODIFIER_RATE"
                    flat="ATTEMPT_FLAT_RATE"
                    rates={rates}
                    callbacks={callbacks}
                />
            );
        }
        default: {
            return (
                <DefaultAccessorial
                    key={accessorial.type}
                    {...accessorial}
                    rates={rates}
                    errors={errors}
                    callbacks={callbacks}
                />
            );
        }
    }
};
