import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { colors } from '@/styles';
import { Incrementor } from '@/components/ShipmentForm/blocks';
import { useItemDynamicFields } from '@/components/ShipmentForm/hooks/items';
import { AssemblyType } from '@/components/ShipmentForm/modals/InputFields';

import {
    local,
    Header1,
    BodyLabel1,
    DefaultResponsiveTextField,
    FlexRow,
    SubHeader1,
    SecondaryButton,
} from '../blocks';

import Footer from './Footer';
import UploadItemPic from './UploadItemPic';
import ProgressBar from './ProgressBar';
import { PrimaryButton } from '@/styles/blocks';

const REQUIRED_FIELDS = ['length', 'width', 'height', 'weight', 'assembly_type', 'description'];

const Item = ({ item, callbacks, idx, defaultAssembly }) => {
    useItemDynamicFields(item, (changes) => {
        callbacks.modifyItem((prev) => ({ ...prev, ...changes }));
    });

    const [isDirty, setDirty] = useState({});
    const isEmpty = useCallback(
        (field) => {
            return REQUIRED_FIELDS.includes(field) && (!item[field] || item[field].length === 0);
        },
        [item]
    );

    return (
        <Grid
            container
            direction="row"
            css={css`
                margin-bottom: 30px;
            `}
        >
            <Grid container direction="column">
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <BodyLabel1
                        css={css`
                            color: ${local.greys[4]};
                        `}
                    >{`Item ${idx + 1}`}</BodyLabel1>
                </Grid>
                <Grid
                    wrap="nowrap"
                    container
                    item
                    css={css`
                        align-items: center;
                        margin-bottom: 20px;
                        height: 56px;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-right: 16px;
                        `}
                    >
                        <BodyLabel1>Quantity:</BodyLabel1>
                    </Grid>
                    <Grid item>
                        <Incrementor
                            body={item.quantity}
                            incrementCallback={() =>
                                callbacks.modifyItem((prev) => ({ ...prev, quantity: item.quantity + 1 }))
                            }
                            decrementCallback={() =>
                                callbacks.modifyItem((prev) => ({ ...prev, quantity: item.quantity - 1 }))
                            }
                        />
                    </Grid>
                </Grid>
                <FlexRow>
                    {[
                        { display: 'Description (required)', value: 'description' },
                        { display: 'Height (in)', value: 'height', number: true },
                        { display: 'Length (in)', value: 'length', number: true },
                        { display: 'Width (in)', value: 'width', number: true },
                        { display: 'Weight (lb)', value: 'weight', number: true },
                    ].map(({ display, value, number }) => (
                        <DefaultResponsiveTextField
                            key={value}
                            display={display}
                            value={value}
                            state={item}
                            number={number}
                            isDirty={isDirty}
                            callbacks={{ setPayload: callbacks.modifyItem, setDirty, isEmpty }}
                        />
                    ))}
                </FlexRow>
                {defaultAssembly !== 'no_assembly' && (
                    <Grid item>
                        <AssemblyType
                            value={item.assembly_type}
                            InputLabelProps={{ shrink: !!item.assembly_type }}
                            onChange={(e) =>
                                callbacks.modifyItem((prev) => ({ ...prev, assembly_type: e.target.value }))
                            }
                        />
                    </Grid>
                )}
                <Grid item>
                    <UploadItemPic item={item} addPic={(pic) => callbacks.modifyItem((prev) => ({ ...prev, pic }))} />
                </Grid>
            </Grid>
        </Grid>
    );
};

function Dropoff({ isDesktop, callbacks, state, type }) {
    const [section, setSection] = useState(state.sections[state.progression.current] || { itemsByOrderId: [] });
    const [defaultAssembly, setDefaultAssembly] = useState('no_assembly');

    const delService = useMemo(() => {
        return state.order.dropoff_location_type;
    }, [state.order.dropoff_location_type, section]);

    useEffect(() => {
        switch (delService) {
            //defaults set at Nicki's request
            case 'whiteGlove':
                setDefaultAssembly('heavy_assembly');
                break;
            case 'roomOfChoice':
                setDefaultAssembly('minimal_assembly');
                break;
            default:
                setDefaultAssembly('no_assembly');
                break;
        }
    }, [delService]);

    const DEFAULT_ITEM = useMemo(() => {
        return {
            quantity: 1,
            item_type: 'household',
            is_return: false,
            item_type_details: 'other',
            assembly_type: defaultAssembly,
        };
    }, [type, defaultAssembly]);

    const hasError = useMemo(() => {
        if (section.itemsByOrderId.length > 0) {
            return section.itemsByOrderId.some((item) => {
                return REQUIRED_FIELDS.some((field) => !item[field]);
            });
        }

        return true;
    }, [section]);

    useEffect(() => {
        setSection(state.sections[state.progression.current] || { itemsByOrderId: [{ ...DEFAULT_ITEM }] });
    }, [DEFAULT_ITEM]);

    return (
        <Grid
            container
            direction="column"
            css={css`
                color: ${local.greys[3]};
            `}
        >
            <ProgressBar step={2} maxSteps={7} />
            <Grid
                container
                css={css`
                    margin-bottom: 6px;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid item direction="column">
                    <Header1
                        css={css`
                            color: ${local.greys[4]};
                        `}
                    >
                        Custom Items
                    </Header1>
                </Grid>
                <Grid item direction="column">
                    <IconButton
                        css={css`
                            padding: 4px;
                        `}
                        onClick={callbacks.showInfo}
                    >
                        <HelpIcon
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item direction="row">
                <SubHeader1>
                    <i>Enter details below.</i>
                </SubHeader1>
            </Grid>

            {section.itemsByOrderId.map((item, idx) => {
                return (
                    <Item
                        key={idx}
                        idx={idx}
                        item={item}
                        defaultAssembly={defaultAssembly}
                        callbacks={{
                            modifyItem: (callback) => {
                                setSection((prev) => {
                                    const clone = [...prev.itemsByOrderId];
                                    const modified = { ...clone[idx], ...callback(clone[idx]) };
                                    clone.splice(idx, 1, modified);

                                    return {
                                        ...prev,
                                        itemsByOrderId: clone,
                                    };
                                });
                            },
                        }}
                    />
                );
            })}

            <Grid container direction="row" justifyContent="space-between">
                <PrimaryButton
                    css={css`
                        padding: 12px 24px;
                    `}
                    onClick={() =>
                        setSection((prev) => {
                            return {
                                ...prev,
                                itemsByOrderId: [...prev.itemsByOrderId, { ...DEFAULT_ITEM }],
                            };
                        })
                    }
                >
                    Add Item
                </PrimaryButton>
                <SecondaryButton
                    css={css`
                        padding: 12px 24px;
                    `}
                    onClick={() =>
                        setSection((prev) => {
                            const clone = [...prev.itemsByOrderId];
                            clone.pop();

                            return {
                                ...prev,
                                itemsByOrderId: clone,
                            };
                        })
                    }
                >
                    Remove Last
                </SecondaryButton>
            </Grid>

            <Footer
                state={state}
                isFirst={state.isFirstStage}
                isLast={state.isLastStage}
                hasErrors={hasError}
                callbacks={{ advance: () => callbacks.advance(section), revert: callbacks.revert }}
            />
        </Grid>
    );
}

export default Dropoff;
