import { useMemo } from 'react';

export const useOrderShipperPricing = (orders, circles, payment_type, backupText = 'We will contact you') => {
    if (Array.isArray(orders)) {
        return useMemo(() => {
            if (!orders?.length) return;

            if (circles?.['hide-market-rates']) {
                // Only return summed rate if every order has a value, otherwise return 'Contract Rates'
                if (orders.every((o) => !!o.admin_shipper_rate_override)) {
                    let totalRate = orders.reduce((total, o) => {
                        return total + o.admin_shipper_rate_override;
                    }, 0);
                    return `$${totalRate?.toFixed(2)}`;
                } else {
                    return 'Contract Rates';
                }
            } else {
                // Only return summed rate if every order has a value, otherwise return backup text
                let totalRate = 0;
                for (const order of orders) {
                    if (order.admin_shipper_rate_override) {
                        totalRate += order.admin_shipper_rate_override;
                        continue;
                    }

                    const wonBid = order.listing?.bids?.find((bid) => bid.bid_status === 'succeeded');
                    const orderRate = order.shipper_rate;

                    if (order.listing?.final_accepted_shipper_rate) {
                        totalRate += order.listing.final_accepted_shipper_rate;
                    } else if (wonBid) {
                        totalRate += wonBid.shipper_rate;
                    } else if (orderRate) {
                        totalRate += orderRate;
                    } else {
                        totalRate = null;
                        break;
                    }
                }
                return totalRate ? `$${totalRate?.toFixed(2)}` : backupText;
            }
        }, [orders]);
    } else {
        return useMemo(() => {
            if (!orders) return;

            if (orders?.admin_shipper_rate_override) {
                return `$${orders?.admin_shipper_rate_override?.toFixed(2)}`;
            }

            if (circles?.['hide-market-rates']) {
                return 'Contract Rates';
            }

            if (orders?.listing?.final_accepted_shipper_rate) {
                return `$${orders?.listing?.final_accepted_shipper_rate.toFixed(2)}`;
            }

            if (orders?.listing?.bids?.find((bid) => bid.bid_status === 'succeeded')) {
                return `$${orders.listing.bids.find((bid) => bid.bid_status === 'succeeded').shipper_rate.toFixed(2)}`;
            }

            return orders?.shipper_rate ? `$${orders?.shipper_rate?.toFixed(2)}` : backupText;
        }, [orders]);
    }
};
