import { Card, Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { OnwardButtonIcon, OnwardTooltip, PrimaryButton } from '@/styles/blocks';
import { Body1 } from '../blocks';
import { MANIFEST_TYPE_LABELS } from '@/constants/manifestStatuses';
import { formatInTimeZone } from 'date-fns-tz';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MANIFEST_STATUSES, STAGED_STATUSES } from '@/constants/manifestStatuses';
import { startCase } from 'lodash';

const ManifestCard = ({ manifest = {}, orders = [], callbacks = {}, loading = {} }) => {
    let actions;
    switch (manifest.type) {
        case 'WILL_CALL':
            if (manifest.status !== MANIFEST_STATUSES.COMPLETE) {
                const isStaged = orders.every((order) => {
                    return (order.manifestItems || []).every((item) =>
                        STAGED_STATUSES.includes(item?.pallet?.warehouse_status)
                    );
                });
                actions = (
                    <Grid
                        css={css`
                            margin-top: 1rem;
                        `}
                        item
                    >
                        <OnwardTooltip
                            title={isStaged ? '' : 'All items must be staged before completing pickup'}
                            placement="top"
                        >
                            <span>
                                <PrimaryButton
                                    disabled={!isStaged || loading.ordersLoading}
                                    onClick={() => {
                                        const pallets = orders.reduce((acc, order) => {
                                            const orderPallets = Object.values(
                                                Object.fromEntries(
                                                    (order.manifestItems || []).map((item) => [
                                                        item.pallet_id,
                                                        item.pallet,
                                                    ])
                                                )
                                            );
                                            return [...acc, ...orderPallets];
                                        }, []);

                                        callbacks.pickupPallets(pallets).then(() => {
                                            callbacks.completeOrders(
                                                orders.filter((order) =>
                                                    order.itemsByOrderId.every((item) =>
                                                        STAGED_STATUSES.includes(item.pallet?.warehouse_status)
                                                    )
                                                )
                                            );
                                        });
                                    }}
                                >
                                    Complete Pickup
                                </PrimaryButton>
                            </span>
                        </OnwardTooltip>
                    </Grid>
                );
            }
            break;
    }

    const label = useMemo(() => {
        switch (manifest.type) {
            case 'RETURN_TO_SENDER':
            case 'WILL_CALL':
                return `Outbound - ${MANIFEST_TYPE_LABELS[manifest.type]}`;
            case 'CROSS_DOCK':
                return `Inbound - ${MANIFEST_TYPE_LABELS[manifest.type]}`;
            case 'INBOUND':
            case 'OUTBOUND':
            default:
                return MANIFEST_TYPE_LABELS[manifest.type] || startCase((manifest?.type || '').toLowerCase());
        }
    }, [manifest]);

    return (
        <Card
            css={css`
                margin-top: 2rem;
                padding: 1rem;
            `}
        >
            <Grid
                container
                direction="row"
                css={css`
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-grow: 1;
                        flex-wrap: nowrap;
                    `}
                >
                    <Grid item>
                        <Body1>{label}</Body1>
                    </Grid>
                    <Grid item>
                        <Body1>
                            {manifest.origin || 'No Origin'} - {manifest.destination || 'No Destination'}
                        </Body1>
                    </Grid>
                    {manifest.route ? (
                        <>
                            <Grid item>
                                <Body1>Route: {manifest.route?.route_number || '--'}</Body1>
                            </Grid>
                            <Grid item>
                                <Body1>Truck: {manifest.route?.truck?.truck_name || '--'}</Body1>
                            </Grid>
                            <Grid item>
                                <Body1>
                                    Scheduled Delivery: {manifest.route?.scheduled_delivery_formatted || '--'}
                                </Body1>
                            </Grid>
                        </>
                    ) : (
                        <Grid item>
                            <Body1>
                                {manifest.type === 'INBOUND' ? 'Receiving' : 'Load'} Date:{' '}
                                {manifest.receiving_date
                                    ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                                    : '--'}
                            </Body1>
                        </Grid>
                    )}
                    <Grid item>
                        <Body1>Cu Ft: {manifest.cubes ? parseInt(manifest.cubes) : 0}</Body1>
                    </Grid>
                    <Grid item>
                        <Body1>Orders: {orders.length || 0}</Body1>
                    </Grid>
                    <Grid item>
                        <Body1>Items: {manifest.total || 0}</Body1>
                    </Grid>
                    {actions}
                </Grid>
                {!manifest.route ? (
                    <>
                        <PrimaryButton onClick={() => callbacks.editManifest(manifest)}>Edit</PrimaryButton>
                        <OnwardButtonIcon
                            red
                            small
                            Icon={DeleteForeverIcon}
                            css={css`
                                margin-left: 0.5rem;
                            `}
                            onClick={() => callbacks.deleteManifest(manifest)}
                        />
                    </>
                ) : null}
            </Grid>
        </Card>
    );
};

export default ManifestCard;
