import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { LISTING_FIELDS } from '@/graphql/queries/listings';
import { BID_FIELDS } from '@/graphql/queries/bids';
import { COUNTER_OFFER_FIELDS } from '@/graphql/queries/counterOffers';
import { LOCATION_FIELDS } from '@/graphql/queries/locations';

export const FULL_LISTING_SUBSCRIPTION = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${CLIENT_FIELDS}
    ${LISTING_FIELDS}
    ${BID_FIELDS}
    ${COUNTER_OFFER_FIELDS}
    ${LOCATION_FIELDS}

    subscription listings($listing_id: uuid!) {
        listings(where: { listing_id: { _eq: $listing_id } }) {
            ...ListingFields
            bids {
                ...BidFields
                counter_offers {
                    ...CounterOfferFields
                }
                location {
                    ...LocationFields
                }
            }
            order {
                ...OrderFields
                itemsByOrderId {
                    ...ItemFields
                }
                order_carrier {
                    ...ClientFields
                }
                order_shipper {
                    ...ClientFields
                }
                routes {
                    mapping_id
                    route_id
                    order_id
                    type
                    route {
                        route_id
                        route_number
                    }
                }
            }
        }
    }
`;
