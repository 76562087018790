import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Icon, Switch } from '@material-ui/core';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@/styles';
import { getShapedIconSvgURLString } from '@/components/DispatchPlan/map/MapMarkers';
import { createRoot } from 'react-dom/client';
import { Tooltip } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PrimaryButtonFullW, PrimaryButton } from '@/components/DispatchPlan/blocks';
import { OnwardToggle } from '@/components/ShipmentForm/blocks';
import _ from 'lodash';
import Combination from './Combination';

const controlContainer = document.createElement('div');
const id = 'manufacturer-shape-control-container';
controlContainer.setAttribute('id', id);
const containerRoot = createRoot(controlContainer);

const MarkerIcon = ({ text, ...props }) => {
    return (
        <div
            css={css`
                position: relative;
            `}
        >
            <Icon
                {...props}
                css={css`
                    width: fit-content !important;
                    height: fit-content !important;
                `}
            >
                <img alt src={getShapedIconSvgURLString('black', props.shape)} width={27} height={33} />
            </Icon>
            <IconText
                css={css`
                    position: absolute;
                    left: 0;
                    top: ${text?.length === 1 ? '20%' : '25%'};
                    width: 27px;
                    text-align: center;
                    font-size: ${text?.length === 1 ? '14px' : '11px'};
                    font-weight: ${text?.length === 1 ? '700' : '500'};
                `}
            >
                {text}
            </IconText>
        </div>
    );
};

export const ShapeSelect = ({ entry, shapesOverride, setShapesOverride, showComboShapes, changeComboShape }) => {
    const [selectedShape, setSelectedShape] = useState('default');

    useEffect(() => {
        setSelectedShape(entry.shape || 'default');
    }, [entry.shape]);

    const handleChange = (event) => {
        if (showComboShapes) {
            changeComboShape(event);
        } else {
            let override = [...shapesOverride];
            let newShape = { name: entry.name, shape: event.target.value };
            let idx = override.findIndex((man) => man.name === entry.name);
            override[idx] = newShape;

            setShapesOverride(override);
            setSelectedShape(event.target.value);
        }
    };

    const shapes = [
        'square',
        'circle',
        'triangle',
        'pentagon',
        'star',
        'cross',
        'rectangle',
        'diamond',
        'heart',
        'abstract1',
        'abstract2',
        'abstract3',
        'abstract4',
        'abstract5',
        'abstract6',
        'abstract7',
        'abstract8',
        'abstract9',
        'abstract10',
        'abstract11',
        'abstract12',
        'abstract13',
    ];

    return (
        <FormControl style={{ width: showComboShapes ? '100%' : '40%' }}>
            <Select
                css={css`
                    height: 57px;
                `}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedShape}
                onChange={handleChange}
            >
                <MenuItem key={'default'} value={'default'}>
                    <MarkerIcon shape={'default'} />
                </MenuItem>
                {shapes.map((shape) => (
                    <MenuItem key={shape} value={shape}>
                        <MarkerIcon shape={shape} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const Body1 = styled.span`
    font-size: 14px;
    font-weight: 700;
`;

const Body2 = styled.span`
font-size: 14px;
font-weight: 500;p
`;

const IconText = styled.span`
    font-size: 11px;
    font-weight: 700;
    color: white;
`;

const ShapeControlContent = ({
    shapesOverride,
    saveShapes,
    setShapesOverride,
    shapeFieldType,
    setShapeFieldType,
    shapeFieldTypeOptions,
    savedShapes,
    setLockShapeType,
    comboPotentialChoices,
    showComboShapes,
    setShowComboShapes,
    setComboShapesOverride,
    comboShapesOverride,
}) => {
    const [open, setOpen] = useState(false);
    const [updatedCombinations, setUpdatedCombinations] = useState(comboShapesOverride || []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFieldChange = (e) => {
        setShapeFieldType(e.target.value);
    };

    const handleSave = () => {
        let newShapes = {
            ...savedShapes,
            [`${shapeFieldType}`]: shapesOverride.filter((el) => el.shape !== undefined),
        };
        if (updatedCombinations) {
            newShapes.combinations = updatedCombinations;
            setComboShapesOverride(updatedCombinations);
        }
        setLockShapeType(true);
        setShapesOverride(newShapes[`${shapeFieldType}`]);
        saveShapes(newShapes);
        handleClose();
    };

    const addCombo = () => {
        setUpdatedCombinations([...updatedCombinations, { [shapeFieldTypeOptions[0]]: null, shape: 'default' }]);
    };

    const removeCombo = (index) => {
        const updated = [...updatedCombinations];
        updated.splice(index, 1);
        setUpdatedCombinations(updated);
    };

    const labels = {
        manufacturer: 'Manufacturer',
        item_type: 'Item Type',
        template_name: 'Template',
        custom_shape: 'Custom Shape',
        order_type: 'Order Type',
    };

    return (
        <>
            <Tooltip title="Pin Customization" placement="top">
                <div
                    css={css`
                        margin: 10px;
                        width: 40px;
                        height: 40px;
                        background-color: ${colors.greens.primary};
                        border-radius: 2px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    `}
                    onClick={handleOpen}
                >
                    <CategoryOutlinedIcon
                        css={css`
                            color: white;
                        `}
                    />
                </div>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <Body1
                        css={css`
                            font-size: 20px;
                        `}
                    >
                        Customize Pins
                    </Body1>
                </DialogTitle>
                <DialogContent
                    css={css`
                        width: 500px;
                        max-height: 500px;
                    `}
                >
                    <div
                        css={css`
                            border-bottom: 1px solid lightgrey;
                            margin-left: -1.5rem;
                            margin-right: -1.5rem;
                            margin-bottom: 1rem;
                            padding-left: 1.5rem;
                            padding-bottom: 1rem;
                        `}
                    >
                        <Body2
                            css={css`
                                margin-right: 0.5rem;
                            `}
                        >
                            Single
                        </Body2>
                        <OnwardToggle
                            value={showComboShapes}
                            onChange={() => {
                                setShowComboShapes(!showComboShapes);
                            }}
                            css={css`
                                margin-left: 1rem;
                            `}
                        />
                        <Body2
                            css={css`
                                margin-left: 0.5rem;
                            `}
                        >
                            Combo
                        </Body2>
                    </div>
                    {showComboShapes ? (
                        <>
                            {updatedCombinations?.map((combo, index) => (
                                <Combination
                                    combo={combo}
                                    comboPotentialChoices={comboPotentialChoices}
                                    shapeFieldTypeOptions={shapeFieldTypeOptions}
                                    index={index}
                                    updatedCombinations={updatedCombinations}
                                    setUpdatedCombinations={setUpdatedCombinations}
                                    removeCombo={removeCombo}
                                />
                            ))}
                            <PrimaryButton
                                onClick={() => {
                                    addCombo();
                                }}
                                style={{ marginTop: 15 }}
                            >
                                Add Combo
                            </PrimaryButton>
                        </>
                    ) : (
                        <>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item xs={6}>
                                    <FormControl>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="field-select"
                                            value={shapeFieldType}
                                            onChange={handleFieldChange}
                                        >
                                            <MenuItem
                                                disabled={!shapeFieldTypeOptions.includes('shipper')}
                                                value={'shipper'}
                                            >
                                                <Body1>Shipper</Body1>
                                            </MenuItem>
                                            <MenuItem
                                                disabled={!shapeFieldTypeOptions.includes('manufacturer')}
                                                value={'manufacturer'}
                                            >
                                                <Body1>Manufacturer</Body1>
                                            </MenuItem>
                                            <MenuItem
                                                disabled={!shapeFieldTypeOptions.includes('item_type')}
                                                value={'item_type'}
                                            >
                                                <Body1>Item Type</Body1>
                                            </MenuItem>
                                            <MenuItem
                                                disabled={!shapeFieldTypeOptions.includes('template_name')}
                                                value={'template_name'}
                                            >
                                                <Body1>Template Name</Body1>
                                            </MenuItem>
                                            <MenuItem
                                                disabled={!shapeFieldTypeOptions.includes('custom_shape')}
                                                value={'custom_shape'}
                                            >
                                                <Body1>Custom Shape</Body1>
                                            </MenuItem>
                                            <MenuItem
                                                disabled={!shapeFieldTypeOptions.includes('order_type')}
                                                value={'order_type'}
                                            >
                                                <Body1>Order Type</Body1>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container item justifyContent="center" xs={6}>
                                    <Body1>Shape</Body1>
                                </Grid>
                            </Grid>
                            {shapesOverride?.map((entry) => (
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    css={css`
                                        margin-top: 0.5rem;
                                    `}
                                >
                                    <Body2>{entry.label || entry.name}</Body2>
                                    <ShapeSelect
                                        entry={entry}
                                        shapesOverride={shapesOverride}
                                        setShapesOverride={setShapesOverride}
                                        showComboShapes={showComboShapes}
                                    />
                                </Grid>
                            ))}
                        </>
                    )}
                </DialogContent>
                <DialogActions
                    css={css`
                        border-top: 1px solid lightgrey;
                    `}
                >
                    <PrimaryButtonFullW onClick={handleSave} style={{ marginTop: 15 }}>
                        Save
                    </PrimaryButtonFullW>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const ShapeControl = ({
    mapRef,
    shapesOverride,
    setShapesOverride,
    saveShapes,
    shapeFieldType,
    setShapeFieldType,
    shapeFieldTypeOptions,
    savedShapes,
    setLockShapeType,
    comboPotentialChoices,
    showComboShapes,
    setShowComboShapes,
    setComboShapesOverride,
    comboShapesOverride,
}) => {
    useEffect(() => {
        if (mapRef?.current) {
            const controls = mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP];
            controls.push(controlContainer);
            containerRoot.render(
                <ShapeControlContent
                    shapesOverride={shapesOverride}
                    setShapesOverride={setShapesOverride}
                    saveShapes={saveShapes}
                    shapeFieldType={shapeFieldType}
                    setShapeFieldType={setShapeFieldType}
                    shapeFieldTypeOptions={shapeFieldTypeOptions}
                    savedShapes={savedShapes}
                    setLockShapeType={setLockShapeType}
                    comboPotentialChoices={comboPotentialChoices}
                    showComboShapes={showComboShapes}
                    setShowComboShapes={setShowComboShapes}
                    setComboShapesOverride={setComboShapesOverride}
                    comboShapesOverride={comboShapesOverride}
                />
            );

            return () => {
                const idx = controls.indexOf((el) => el.id === id);
                if (idx >= 0) {
                    controls.removeAt(idx);
                }
            };
        }
    }, [mapRef?.current, shapesOverride]);

    return null;
};
