import React, { useContext, useMemo, useState, useEffect } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { Grid, TextareaAutosize } from '@material-ui/core';
import { SectionSubtitle } from '@/styles/blocks';
import StartTimeSelect from '@/components/misc/StartTimeSelect';
import { useSwappedAttributes } from '../../hooks';
import { Body1, Incrementor, OnwardCheckbox, OnwardToggle, RadioLabelInfo } from '../../blocks';
import { ModalContext } from './';

import { LocationType, DropoffType } from '../InputFields';

const DeliveryTypeTab = ({ opt }) => {
    const { startDirty } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { isDirty, order, hasError, errors } = modalState;
    const { zip, location_type, location, elevators, stairs, stair_quantity } = useSwappedAttributes(order);
    const [appendDebris, setAppendDebris] = useState(false);

    const equipment = useMemo(() => {
        return {
            ...(order.recommended_equipment || {}),
            ...(order.equipment_overrides || {}),
        };
    }, [order]);

    const timezone = useMemo(() => {
        const orderZip = order[zip];

        return (orderZip && zipcode_to_timezone.lookup(orderZip)) || Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [order, zip]);

    const warehouseCloseDate = useMemo(() => {
        const TODAY = new Date();
        if (order.pallet_warehouse_close_time) {
            const date = new Date(order.pallet_warehouse_close_time);
            date.setHours(0, 0, 0, 0);

            return date.toISOString();
        }

        return TODAY.toISOString();
    }, [order]);

    const isPallet = order.freight_type === 'pallet';

    let options = (
        <>
            <Grid
                wrap="nowrap"
                container
                item
                css={css`
                    margin-top: 10px;
                    align-items: center;
                    margin-bottom: 30px;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-right: 16px;
                    `}
                >
                    <Body1>Flights of Stairs:</Body1>
                </Grid>
                <Grid item>
                    <Incrementor
                        body={order[stair_quantity] || 0}
                        incrementCallback={() =>
                            callbacks.applyOrderOperations({
                                [stair_quantity]: (prev) => (prev[stair_quantity] || 0) + 1,
                                [stairs]: (prev) => (prev[stair_quantity] || 0) + 1 > 0,
                            })
                        }
                        decrementCallback={() =>
                            callbacks.applyOrderOperations({
                                [stair_quantity]: (prev) => (prev[stair_quantity] > 0 ? prev[stair_quantity] - 1 : 0),
                                [stairs]: (prev) => (prev[stair_quantity] || 0) - 1 > 0,
                            })
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                item
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <OnwardCheckbox
                    label="Elevator available?"
                    checked={!!order[elevators]}
                    onChange={(_, checked) => {
                        callbacks.modifyOrder({ [elevators]: checked });
                    }}
                />
            </Grid>
        </>
    );
    if (isPallet) {
        options = (
            <>
                <Grid
                    item
                    xs={6}
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <StartTimeSelect
                        onChange={(e) => {
                            callbacks.modifyOrder({
                                pallet_warehouse_close_time: new Date(e.target.value).toISOString(),
                            });
                        }}
                        value={
                            order.pallet_warehouse_close_time
                                ? new Date(order.pallet_warehouse_close_time).getTime()
                                : 0
                        }
                        timeZone={timezone}
                        deliveryDate={warehouseCloseDate}
                        interval={30}
                        label="What time does the warehouse close?"
                        InputLabelProps={{
                            shrink: !!order.pallet_warehouse_close_time,
                        }}
                        styles={{}}
                        css={css`
                            display: flex;
                            justify-content: flex-start;
                            color: #212529;

                            .MuiFormControl-root {
                                width: 100%;
                            }

                            .MuiSelect-root {
                                padding: 18.5px 14px;
                                padding-right: 32px;
                                font-size: 16px;
                            }

                            .MuiInputBase-root fieldset {
                                top: -5px;
                            }
                        `}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="There will be forklift at pickup?"
                        checked={!!order.pallet_forklift_at_pickup}
                        onChange={(_, checked) => {
                            callbacks.modifyOrder({ pallet_forklift_at_pickup: checked });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="There will be forklift at delivery site?"
                        checked={!!order.pallet_forklift_at_dropoff}
                        onChange={(_, checked) => {
                            callbacks.modifyOrder({ pallet_forklift_at_dropoff: checked });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="Delivery can be completed on a flatbed truck?"
                        checked={!!order.pallet_flatbed_truck}
                        onChange={(_, checked) => {
                            callbacks.modifyOrder({ pallet_flatbed_truck: checked });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="Items are required to be unloaded from pallet?"
                        checked={!!order.pallet_unloading_required}
                        onChange={(_, checked) => {
                            callbacks.modifyOrder({ pallet_unloading_required: checked });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="There is extra debris that is required to be removed from delivery site"
                        checked={appendDebris}
                        onChange={(_, checked) => {
                            setAppendDebris(checked);
                        }}
                    />
                </Grid>
                {appendDebris ? (
                    <Grid
                        item
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <TextareaAutosize
                            css={css`
                                width: 100%;
                                padding: 14px;
                                resize: none;
                            `}
                            minRows={4}
                            placeholder="Describe debris"
                            value={order.pallet_debris_removal_notes}
                            onChange={(e) => {
                                callbacks.modifyOrder({ pallet_debris_removal_notes: e.target.value });
                            }}
                        />
                    </Grid>
                ) : null}
            </>
        );
    }

    const isCurbside = useMemo(() => {
        return ['curbside', 'dock'].includes(order[location_type]);
    }, [order, location_type]);

    return (
        <Grid container direction="column">
            <Grid
                container
                item
                xs={6}
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <LocationType
                    value={order[location]}
                    onChange={(e) => {
                        callbacks.makeDirty([location]);
                        callbacks.modifyOrder({ [location]: e.target.value });
                    }}
                    error={hasError[location] && (startDirty || isDirty[location])}
                />
            </Grid>
            <Grid
                container
                item
                xs={6}
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <DropoffType
                    order={order}
                    disabled={!order[location] || order[location].length === 0}
                    value={order[location_type]}
                    locationType={order[location]}
                    onChange={(e) => {
                        callbacks.makeDirty([location_type]);
                        callbacks.modifyOrder({ [location_type]: e.target.value });
                    }}
                    error={hasError[location_type] && (startDirty || isDirty[location_type])}
                />
            </Grid>

            <Grid
                container
                item
                xs={12}
                css={css`
                    margin-bottom: 20px;
                    align-items: center;
                `}
            >
                <OnwardToggle
                    checked={!!equipment['2_man_team']}
                    disabled={!order.oms}
                    onChange={(e) => {
                        callbacks.modifyOrder({
                            equipment_overrides: {
                                ...order.equipment_overrides,
                                ['2_man_team']: e.target.checked,
                            },
                        });
                    }}
                />
                <RadioLabelInfo
                    css={css`
                        margin-right: 36px;
                        margin-left: 12px;
                        color: black;
                    `}
                >
                    2 Man Team
                </RadioLabelInfo>
            </Grid>
            <Grid
                container
                item
                xs={12}
                css={css`
                    margin-bottom: 20px;
                    align-items: center;
                `}
            >
                <OnwardToggle
                    checked={order.no_contact && isCurbside}
                    disabled={!isCurbside}
                    onChange={(e) => {
                        callbacks.modifyOrder({
                            no_contact: e.target.checked,
                        });
                    }}
                />
                <RadioLabelInfo
                    css={css`
                        margin-right: 36px;
                        margin-left: 12px;
                        color: black;
                    `}
                >
                    No Contact
                </RadioLabelInfo>
            </Grid>
            <Grid
                container
                item
                xs={12}
                css={css`
                    margin-bottom: 20px;
                    align-items: center;
                `}
            >
                <OnwardToggle
                    checked={order.assembly_required}
                    onChange={(e) => {
                        callbacks.modifyOrder({
                            assembly_required: e.target.checked,
                        });
                    }}
                />
                <RadioLabelInfo
                    css={css`
                        margin-right: 36px;
                        margin-left: 12px;
                        color: black;
                    `}
                >
                    Assembly Required
                </RadioLabelInfo>
            </Grid>

            {options}
        </Grid>
    );
};

export default DeliveryTypeTab;
