import React from 'react';
import { css } from '@emotion/react';
import { BodyText, StickyCell } from '../blocks';

export default function BodyCell({ cell, span = true, callbacks = {}, isChecked, className, ...props }) {
    return (
        <StickyCell
            align="left"
            className={className}
            {...props}
            css={css`
                position: relative;
            `}
        >
            {span ? (
                <BodyText
                    css={css`
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    `}
                >
                    {cell.render('Cell', { callbacks, isChecked })}
                </BodyText>
            ) : (
                cell.render('Cell', { callbacks, isChecked })
            )}
        </StickyCell>
    );
}
