import React, { useContext } from 'react';
import { UserContext } from '../App';
import { Container, Row, Col } from 'reactstrap';

const AuthContactPage = () => {
    const { user } = useContext(UserContext);

    return (
        <>
            <div
                style={{
                    marginLeft: '0px',
                    padding: '10px 15px',
                    paddingBottom: '88px!important',
                }}
            >
                <h1>Contact Us</h1>
                <hr />
                <Row style={{ marginLeft: '0px' }}>
                    <Row>
                        <h3 style={{ marginLeft: '0px' }}>Need Immediate Assistance? </h3>
                        <br />
                    </Row>
                    <Container>
                        <br />
                        <br />
                        <Row
                            className="row-grid align-items-center my-md"
                            style={{ marginTop: '0%', marginBottom: '7%' }}
                        >
                            <Col lg="6">
                                <h4 className="text-primary font-weight-light mb-2">
                                    {user?.roles?.CARRIER ? 'Carrier Support' : 'Shipper Support'}
                                </h4>
                                <br />
                                <h6 className="mb-0 font-weight-light">support@onwarddelivery.com</h6>
                                <br />
                            </Col>

                            <Col lg="6">
                                <h4 className="text-primary font-weight-light mb-2">Sales Support</h4>
                                <br />
                                <h6 className="mb-0 font-weight-light">sales@onwarddelivery.com</h6>
                                <br />
                            </Col>
                        </Row>
                        <hr />
                    </Container>
                </Row>
            </div>
        </>
    );
};

export default AuthContactPage;
