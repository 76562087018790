import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { addHours, format, isAfter, isBefore } from 'date-fns';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import WarningIcon from '@material-ui/icons/Warning';
import { typography, colors } from '@/styles';
import { Button, Grid, TextField as MuiTextField, IconButton, Radio, FormControlLabel } from '@material-ui/core';
import { poundFormatter, integerFormatter } from '@/constants/formats';
import StartTimeSelect from '@/components/misc/StartTimeSelect';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { SingleDatePicker } from '@/components/ShipmentForm/modals/InputFields';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { formatInTimeZone } from 'date-fns-tz';
import { PrimaryButton } from '@/styles/blocks';

export const local = {
    black: '#000000',
    greys: ['#D9D9D9'],
};

export const fragments = {
    timeSelect: css`
        width: auto;

        #mui-component-select-raw-select {
            padding: 18.5px 14px;
            padding-right: 32px;
            min-height: 19px;
            width: 120px;
        }

        & fieldset {
            top: -5px !important;
        }
    `,
};

export const Anchor = styled(({ children, ...rest }) => <Link {...rest}>{children}</Link>)`
    color: ${colors.greens.primary};
`;

export const HR = styled.div`
    flex: 1;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${colors.greys[1]};
`;

export const Tag = styled.div`
    background: ${colors.greys[4]};
    border-radius: 15px;
    padding: 4px 12px;
    margin: 4px 0;
`;

export const Circle = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 999px;
    margin: 2px;
`;

export const Header1 = styled.span`
    ${typography.sansSerif}

    text-decoration: ${(props) => (props.strikethrough ? 'line-through' : 'initial')};

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
`;

export const Header2 = styled.span`
    ${typography.sansSerif}

    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    text-decoration: ${(props) => (props.strikethrough ? 'line-through' : 'initial')};

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

export const RadioLabel = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

export const StyledRadio = styled(({ children, label, value, ...rest }) => <FormControlLabel value={value} control={<Radio color="primary" />} label={<RadioLabel>{label}</RadioLabel>} {...rest}>{children}</FormControlLabel>)`
`;

export const Step = ({
    isDesktop,
    isComplete,
    inProgress,
    isWarning,
    isLast,
    label = () => {},
    children = () => {},
    ...rest
}) => {
    return (
        <>
            <Grid
                direction="row"
                container
                css={css`
                    align-items: center;
                `}
                {...rest}
            >
                <Grid
                    direction="column"
                    item
                    container
                    css={css`
                        flex: 0;
                        margin-right: 8px;
                    `}
                >
                    {isComplete ? (
                        <CheckCircleIcon
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        />
                    ) : isWarning ? (
                        <WarningIcon
                            css={css`
                                color: ${colors.oranges[1]};
                            `}
                        />
                    ) : (
                        <Circle
                            css={css`
                                background: #d9d9d9;
                            `}
                        />
                    )}
                </Grid>
                <Grid
                    direction="column"
                    item
                    container
                    css={css`
                        flex: 1;
                    `}
                >
                    {isDesktop ? label() : null}
                </Grid>
            </Grid>
            <Grid
                className="left-border"
                direction="row"
                container
                css={css`
                    padding: 13px 36px;
                    border-left: ${isLast ? '0px' : '1px'} solid ${colors.greys[2]};
                    margin: 4px 12px;

                    ${!isDesktop
                        ? css`
                              padding: 0px 12px 24px 24px;
                          `
                        : ''}
                `}
                {...rest}
            >
                <Grid
                    direction="column"
                    container
                    css={css`
                        ${!isDesktop
                            ? css`
                                  box-shadow: 0px 0px 0px 1px #3f3f440d;
                                  background: white;
                                  padding: 12px;
                                  border-radius: 5px;
                                  margin-top: -36px;
                              `
                            : ''}
                    `}
                >
                    {!isDesktop ? label() : null}
                    {inProgress ? children() : null}
                </Grid>
            </Grid>
        </>
    );
};

export const SubStepMobile = ({
    isComplete,
    inProgress,
    isLoading,
    isLast,
    description,
    cta,
    secondaryCta,
    secondaryRed,
    secondaryOrange,
    callback,
    secondaryCallback,
}) => {
    return !isComplete ? (
        <>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 6px;
                    margin-top: 12px;
                `}
            >
                <Grid direction="column" container>
                    {description}
                </Grid>
            </Grid>

            {inProgress && callback ? (
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-top: 12px;
                    `}
                >
                    <Grid direction="column" container>
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: ${secondaryCta && secondaryCallback ? '12px' : '0'};
                            `}
                        >
                            <PrimaryButton fullWidth disabled={isLoading} onClick={callback}>
                                <Header1>{cta}</Header1>
                            </PrimaryButton>
                        </Grid>
                        {secondaryCta && secondaryCallback ? (
                            <Grid direction="row" container>
                                <PrimaryButton
                                    red={secondaryRed}
                                    orange={secondaryOrange}
                                    fullWidth
                                    disabled={isLoading}
                                    onClick={secondaryCallback}
                                >
                                    <Header1>{secondaryCta}</Header1>
                                </PrimaryButton>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            ) : null}
        </>
    ) : null;
};

export const SubStepDesktop = ({
    isComplete,
    inProgress,
    isLoading,
    isLast,
    description,
    cta,
    secondaryCta,
    secondaryRed,
    secondaryOrange,
    callback,
    secondaryCallback,
}) => {
    return (
        <>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 6px;
                `}
            >
                <Body1
                    strikethrough={isComplete}
                    css={css`
                        color: ${!isComplete && !inProgress ? colors.greys[1] : colors.greys[3]};
                    `}
                >
                    {description}
                </Body1>
            </Grid>

            {inProgress && callback ? (
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-top: 6px;
                    `}
                >
                    <Grid
                        direction="column"
                        container
                        css={css`
                            margin-right: 16px;
                            flex: 0;
                        `}
                    >
                        <PrimaryButton disabled={isLoading} onClick={callback}>
                            <Header1>{cta}</Header1>
                        </PrimaryButton>
                    </Grid>

                    {secondaryCta && secondaryCallback ? (
                        <Grid
                            direction="column"
                            container
                            css={css`
                                flex: 0;
                            `}
                        >
                            <PrimaryButton
                                red={secondaryRed}
                                orange={secondaryOrange}
                                disabled={isLoading}
                                onClick={secondaryCallback}
                            >
                                <Header1>{secondaryCta}</Header1>
                            </PrimaryButton>
                        </Grid>
                    ) : null}
                </Grid>
            ) : null}

            {!isLast ? (
                <HR
                    css={css`
                        margin: 20px 0;
                    `}
                />
            ) : null}
        </>
    );
};

export const TextField = ({
    disabled = false,
    display,
    value,
    isDirty,
    number,
    state,
    hasError,
    helper,
    callbacks,
}) => {
    return (
        <MuiTextField
            fullWidth
            variant="outlined"
            disabled={disabled}
            label={display}
            InputLabelProps={{ shrink: !!state[value] }}
            value={state[value] || ''}
            helperText={helper}
            onBlur={(e) => {
                callbacks.setDirty((prev) => ({ ...prev, [value]: true }));
            }}
            onChange={(e) => {
                let next = e.target.value;
                if (number) {
                    next = parseInt(next, 10);
                }

                callbacks.setPayload((prev) => ({ ...prev, [value]: next }));
            }}
            error={hasError ? hasError : callbacks.isEmpty(value) && isDirty[value]}
        />
    );
};

export const DateTimePicker = ({ dateLabel = 'Date', timestamp, tz, onChange, disableDate }) => {
    const date = timestamp ? formatInTimeZone(new Date(timestamp), tz, 'yyyy-MM-dd') : null;
    const minute = 60 * 1000;
    const sanitize = (ts) => Math.floor(ts / (15 * minute)) * 15 * minute;

    return (
        <>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    align-items: flex-start;
                `}
            >
                <SingleDatePicker
                    disableDate={disableDate}
                    label={dateLabel}
                    value={date || ''}
                    onChange={(e) => {
                        let date = null;
                        if (e.target.value) {
                            date = asDateInTZ(e.target.value, tz);
                        }

                        onChange(date);
                    }}
                />
            </Grid>
            <Grid
                container
                direction="column"
                item
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    align-items: flex-end;
                `}
            >
                <StartTimeSelect
                    disabled={!date}
                    onChange={(e) => {
                        let date = null;
                        if (e.target.value) {
                            date = new Date(e.target.value);
                        }
                        onChange(date);
                    }}
                    value={timestamp ? sanitize(new Date(timestamp).getTime()) : 0}
                    timeZone={tz}
                    deliveryDate={date || formatInTimeZone(new Date(), tz, 'yyyy-MM-dd')}
                    interval={15}
                    label="Time"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    styles={{}}
                    css={css`
                        ${fragments.timeSelect}
                    `}
                />
            </Grid>
        </>
    );
};

export const DatePicker = ({ dateLabel = 'Date', timestamp, tz, onChange, disableDate }) => {
    const date = timestamp ? formatInTimeZone(new Date(timestamp), tz, 'yyyy-MM-dd') : null;

    return (
        <>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    align-items: flex-start;
                `}
            >
                <SingleDatePicker
                    disableDate={disableDate}
                    label={dateLabel}
                    value={date || ''}
                    onChange={(e) => {
                        let date = null;
                        if (e.target.value) {
                            date = asDateInTZ(e.target.value, tz);
                        }
                        onChange(date);
                    }}
                />
            </Grid>
        </>
    );
};

export const DateTimeRangePicker = ({ dateLabel = 'Date', range, tz, onChange, disableDate }) => {
    const minute = 60 * 1000;
    const [start, end] = range.map((dateStr) => (dateStr ? new Date(dateStr) : null));
    const sanitize = (ts) => Math.floor(ts / (15 * minute)) * 15 * minute;
    const date = start ? formatInTimeZone(new Date(start), tz, 'yyyy-MM-dd') : null;

    return (
        <>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    align-items: flex-start;
                `}
            >
                <SingleDatePicker
                    label={dateLabel}
                    value={date || ''}
                    disableDate={disableDate}
                    onChange={(e) => {
                        let date = null;
                        if (e.target.value) {
                            date = asDateInTZ(e.target.value, tz);
                        }

                        onChange([date, date]);
                    }}
                />
            </Grid>
            <Grid
                container
                direction="column"
                item
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    align-items: center;
                    margin-right: 8px;
                `}
            >
                <StartTimeSelect
                    disabled={!start}
                    onChange={(e) => {
                        if (e.target.value) {
                            const newStart = new Date(e.target.value);

                            const newEnd = isAfter(newStart, end)
                                ? newStart
                                : isBefore(newStart, addHours(end, -4))
                                ? addHours(newStart, 4)
                                : end;

                            onChange([newStart, newEnd]);
                        } else {
                            onChange([null, null]);
                        }
                    }}
                    value={start ? sanitize(start.getTime()) : 0}
                    timeZone={tz}
                    deliveryDate={date || formatInTimeZone(new Date(), tz, 'yyyy-MM-dd')}
                    interval={15}
                    label="Start"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    styles={{}}
                    css={css`
                        ${fragments.timeSelect}
                    `}
                />
            </Grid>
            <Grid
                container
                direction="column"
                item
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    align-items: flex-end;
                `}
            >
                <StartTimeSelect
                    disabled={!start}
                    onChange={(e) => {
                        if (e.target.value) {
                            const newEnd = new Date(e.target.value);

                            const newStart = isBefore(newEnd, start)
                                ? newEnd
                                : isAfter(newEnd, addHours(start, 4))
                                ? addHours(newEnd, -4)
                                : start;

                            onChange([newStart, newEnd]);
                        } else {
                            onChange([null, null]);
                        }
                    }}
                    value={end ? sanitize(end.getTime()) : 0}
                    timeZone={tz}
                    deliveryDate={date || formatInTimeZone(new Date(), tz, 'yyyy-MM-dd')}
                    interval={15}
                    label="End"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    styles={{}}
                    css={css`
                        ${fragments.timeSelect}
                    `}
                />
            </Grid>
        </>
    );
};

export const ItemCheckbox = ({ item, checked, callbacks }) => {
    return (
        <Grid
            direction="row"
            container
            item
            css={css`
                margin-bottom: 8px;
            `}
        >
            <Grid
                direction="column"
                container
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                `}
            >
                <OnwardCheckbox
                    checked={checked || false}
                    onChange={() => {
                        callbacks.onCheck(item.item_id);
                    }}
                />
            </Grid>
            <Grid
                direction="column"
                container
                css={css`
                    flex: 1;
                    flex-basis: 0;
                `}
            >
                <Grid
                    direction="row"
                    container
                    item
                    css={css`
                        margin-bottom: 8px;
                    `}
                >
                    <Body1>
                        {item.sku ? `${item.sku} / ` : ''}
                        {item.description || item.item_type_details}
                    </Body1>
                </Grid>
                <Grid
                    direction="row"
                    container
                    item
                    css={css`
                        margin-bottom: 8px;
                    `}
                >
                    <Body1>{`Quantity: ${item.quantity}`}</Body1>
                </Grid>
                <Grid direction="row" container item>
                    <Body1
                        css={css`
                            color: ${colors.greys[0]};
                        `}
                    >{`${poundFormatter.format(item.total_weight)} ${item.length}L ${item.width}W ${
                        item.height
                    }H`}</Body1>
                </Grid>
            </Grid>
        </Grid>
    );
};
