import React from 'react';
import { css } from '@emotion/react';

const TermsAndConditions = () => {
    const terms = (
        <p class="paragraph-8" css={css`
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
        `}>
            <strong>‍</strong>Please Read Carefully. <br></br>Last updated: May 1, 2023<br></br>
            <br></br>The services that Onward Delivery Corp. and its representatives, affiliates, officers and directors
            (<strong>"Onward</strong>,<strong>" "we</strong>,<strong>" "our</strong>,<strong>"</strong> and{' '}
            <strong>"us"</strong>) provides to you are subject to the terms and conditions set forth herein (
            <strong>"Terms of Service"</strong>), which govern your access to and/or use of our website located at{' '}
            <a href="https://www.onwarddelivery.com">https://www.onwarddelivery.com</a> (the <strong>"Site"</strong>),
            mobile application (the <strong>"App"</strong>), products, and services provided on the Site and/or App, any
            sites or apps Onward may have now and/or in the future including without limitation, its solution that
            provides persons that contract with Onward (<strong>"you" </strong>or <strong>"Shippers"</strong>) the
            ability to schedule the transportation of freight and household goods by an independent third-party moving,
            logistics and transportation provider (<strong>"Carrier"</strong>) to persons designated by you to receive
            such freight and goods (<strong>"Consignees"</strong>) and to use the order tracking tools located within
            the Site and App to track deliveries (collectively, the <strong>"Onward Platform"</strong>). In addition to
            your use of the Onward Platform, this Terms of Service also governs the transportation brokerage services
            provided by Onward to you pursuant to an executed order form (<strong>"Order Form"</strong>), which shall be
            incorporated herein by reference and made part of this Terms of Service (the "<strong>Services"</strong>)
            and the transportation services provided to you by Carrier ("<strong>Carrier Services</strong>"). <br></br>
            <br></br>In this Terms of Service, "<strong>you</strong>" refers to individuals and/or companies that
            purchase, register for, or use the Onward Platform and/or enter into an Order Form to receive the Services
            and Carrier Services. This Terms of Service sets forth the legally binding terms between you and Onward. You
            represent and warrant that you are at least eighteen (18) years of age and will use and access the Onward
            Platform, Services and/or Carrier Services in accordance with this Terms of Service. <br></br>
            <br></br>In order to use the Onward Platform, Services and/or Carrier Services, you must accept this Terms
            of Service and Onward's Privacy Notice, which is located at{' '}
            <a href="http://www.onwarddelivery.com/privacy">http://www.onwarddelivery.com/privacy</a> ("
            <strong>Privacy Notice</strong>"), and may be updated from time to time by Onward in its sole and absolute
            discretion as described herein. BY REGISTERING A USER ACCOUNT, DOWNLOADING THE APP, ACCESSING OR USING THE
            ONWARD PLATFORM, BY SIGNING AN APPLICABLE ORDER FORM OR OTHERWISE USING THE SERVICES OR CARRIER SERVICES,
            YOU ACKNOWLEDGE THAT YOU HAVE READ AND ACCEPT THIS TERMS OF SERVICE AND AGREE TO BE BOUND BY ALL OF THE
            TERMS AND CONDITIONS CONTAINED HEREIN, AS WELL AS ALL OTHER APPLICABLE RULES OR POLICIES, TERMS AND
            CONDITIONS AND/OR AGREEMENTS THAT ARE AND/OR MAY BE ESTABLISHED BY ONWARD FROM TIME TO TIME AND THE
            FOREGOING SHALL BE INCORPORATED HEREIN BY REFERENCE. If you are an individual agreeing to this Terms of
            Service on behalf of legal entity, you represent that you have the legal authority to bind such entity.{' '}
            <br></br>
            <br></br>If you do not accept this Terms of Service now or in the future you must stop your use of the
            Onward Platform, Services and/or Carrier Services immediately, in which case any continuing access and/or
            use of the Onward Platform, Services and/or Carrier Services is unauthorized. If you do not accept this
            Terms of Service, you must not execute an Order Form. Except as otherwise agreed to by Onward in writing or
            as set forth in an Order Form, this Terms of Service expressly supersedes prior agreements and/or
            arrangements between you and Onward.<br></br>
            <br></br>‍
            <strong>
                PRIVACY NOTICE<br></br>
                <br></br>‍
            </strong>
            You also agree to Onward's Privacy Notice and consent to any personal information we may obtain about you
            being collected, stored, and/or otherwise processed in accordance with the Privacy Notice and this Terms of
            Service.<br></br>
            <br></br>‍
            <strong>
                ARBITRATION NOTICE<br></br>‍<br></br>‍
            </strong>
            EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION CLAUSE BELOW, YOU AGREE THAT DISPUTES
            BETWEEN YOU AND ONWARD WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION AND YOU WAIVE ANY RIGHT TO
            PARTICIPATE IN A CLASS-ACTION LAWSUIT AND/OR CLASS-WIDE ARBITRATION.<br></br>
            <br></br>‍
            <strong>
                CHANGES TO TERMS<br></br>‍<br></br>‍
            </strong>
            Subject to the terms and conditions set forth herein, Onward may, at any time and for any reason, in its
            sole and absolute discretion, make changes to the Onward Platform, Services and/or Carrier Services, and/or
            modify this Terms of Service. If Onward makes changes and/or modifications that affect your access to and/or
            use of the Onward Platform, Services and/or Carrier Services, Onward will provide a notice of such changes
            only by posting the updated Terms of Service on the Onward Platform and Onward will change the "last
            updated" date listed above. We may also provide you with additional forms of notice of modifications and/or
            updates as appropriate under the circumstances. Your use of the Onward Platform, Services and/or Carrier
            Services following any changes and/or modifications will constitute your acceptance of such changes and/or
            modifications. If you do not agree with the changes and/or modifications, you shall not use the Onward
            Platform, Services and/or Carrier Services after the effective date of the changes. Please revisit this
            Terms of Service regularly to ensure that you stay informed of any changes. Any changes to the Services
            and/or Carrier Services provided under an Order Form will be made pursuant to the terms of the applicable
            Order Form. <br></br>
            <br></br>‍
            <strong>
                DESCRIPTION OF SERVICES<br></br>‍<br></br>General.{' '}
            </strong>
            General information about Onward and our products and Services is available to the public on the Site but to
            fully access and use the Onward Platform, Services and/or Carrier Services you will be required to set up a
            User Account (as defined below). Onward provides transportation brokerage services and a related marketplace
            for the pickup and delivery of freight and/or household goods by Carriers. Either through your User Account
            on the Onward Platform or by entering into an applicable Order Form with Onward and requesting a delivery,
            you can arrange for a Carrier to pick up and deliver goods to a select destination and provide other
            transportation and logistics services. Onward contracts with Carriers to complete the transportation of
            goods selected by you when you request a delivery pursuant to an applicable Order Form. Otherwise, you can
            connect directly with Carriers through the Onward Platform to arrange for Carrier Services. <br></br>‍
            <br></br>YOU ACKNOWLEDGE THAT ONWARD IS FEDERALLY LICENSED AND REGISTERED AS A BROKER OF FREIGHT SERVICES,
            THAT ONWARD DOES NOT PROVIDE TRANSPORTATION OR MOVING SERVICES, AND DOES NOT FUNCTION AS A MOTOR CARRIER OR
            FREIGHT FORWARDER AS DEFINED BY THE UNITED STATES DEPARTMENT OF TRANSPORTATION OR FEDERAL MOTOR CARRIER
            SAFETY ADMINISTRATION. IT IS ACKNOWLEDGED AND AGREED THAT ONWARD'S RESPONSIBILITY UNDER THESE TERMS OF
            SERVICE SHALL NOT INCLUDE ACTUALLY PERFORMING, TRANSPORTATION AND/OR MOVEMENT OF YOUR FREIGHT/HOUSEHOLD
            GOODS. THE ONWARD PLATFORM AND ONWARD&#x27;S SERVICES MAY BE USED BY YOU TO REQUEST AND ARRANGE
            TRANSPORTATION, MOVING OR LOGISTICS SERVICES WITH CARRIERS, BUT YOU AGREE THAT ONWARD HAS NO RESPONSIBILITY
            OR LIABILITY TO YOU RELATED TO ANY TRANSPORTATION, MOVING OR LOGISTICS SERVICES PROVIDED TO YOU BY CARRIERS
            THROUGH THE USE OF THE ONWARD PLATFORM OR PURSUANT TO AN APPLICABLE ORDER FORM OTHER THAN AS EXPRESSLY SET
            FORTH IN THESE TERMS OF SERVICE OR APPLICABLE ORDER FORM.<br></br>
            <br></br>‍<strong>Eligibility. </strong> To access and/or use the Onward Platform, Services, Carrier
            Services and/or enter an Order Form you must be eighteen (18) years or older and have the requisite power
            and authority to enter into this Terms of Service and meet any other applicable age and residency
            requirements. The Onward Platform, Services, and/or Carrier Services are not intended for and should not be
            used by anyone under the age of eighteen (18). If you are accepting this Terms of Service on behalf of a
            company, entity, or organization, you represent and warrant that you have the right, authority, and capacity
            to enter into this Terms of Service, on behalf of the company, entity, or organization that you represent.
            The Onward Platform is not intended for and should not be used by anyone under the age of thirteen (13). If
            we discover that a child under thirteen (13) has provided us with personal information, we will delete such
            information. <br></br>
            <br></br>‍<strong>Use and Availability.</strong> Use of the Onward Platform, Services and/or Carrier
            Services is at your own risk. Onward retains the right, in our sole and absolute discretion, to deny service
            and/or access to and/or use of the Onward Platform to anyone at any time and for any reason without
            liability. While we use reasonable efforts to keep the Onward Platform accessible, the Onward Platform may
            be unavailable from time to time. You understand and agree that there may be interruptions to the Onward
            Platform and/or access to your User Account (defined below) due to circumstances both within our control
            (e.g., routine maintenance) and outside of Onward's control, for which Onward will bear no responsibility.
            The Onward Platform may be modified, updated, suspended and/or discontinued at any time without notice
            and/or liability. <br></br>
            <br></br>‍<strong>Creating an Account. </strong>In order to access and use the Onward Platform, you are
            required to agree to this Terms of Service and Privacy Notice. Unless you will be granted free use of the
            Onward Platform pursuant to an Order Form, you will be required to pay the applicable fees to access and use
            the Onward Platform. To establish an account on the Onward Platform, you will be assigned and/or required to
            select a unique username ("<strong>Username</strong>") and a unique user password (&quot;
            <strong>Password</strong>&quot;) (collectively, a &quot;<strong>User Account</strong>&quot;). In order to
            use the App, download the App from the 'Google Play' and/or 'Apple Store' (which are subject to the terms
            and conditions provided by the applicable app store). Once the App is downloaded, you must sign into your
            account on the App to use the tools provided therein, such as delivery tracking. When setting up a User
            Account, you are required to (i) abide by all applicable laws, rules, and regulations, as well as indicate
            agreement to this Terms of Service, (ii) provide contact information, and (iii) submit any other form of
            authentication required during the enrollment process, as determined by Onward in its sole and absolute
            discretion. If you establish a User Account with Onward, you agree to provide true, accurate and current
            information in connection with your User Account. You are responsible for updating and correcting
            information you have submitted to create and/or maintain your User Account. You are solely responsible for
            managing your User Account and are prohibited from using other disguised identities when using the Onward
            Platform. We may refuse to grant you a User Account with a name that impersonates someone else, if it may be
            illegal, vulgar, offensive, and/or if it may be protected by trademark and/or other proprietary rights, as
            determined by Onward in its sole and absolute discretion. Usernames and Passwords may be granted on a per
            Shipper basis, if Shipper is an entity, one Username and Password will be granted for the entity. You are
            solely responsible for maintaining the security and confidentiality of the password you use to access your
            User Account. You understand and agree that Onward shall have no responsibility for any incident arising out
            of, or related to, your account settings. Onward will assume that anyone using the Onward Platform and/or
            transacting through your User Account is you. Therefore, you further agree to immediately notify Onward of
            any unauthorized use of your Username, Password and/or User Account and/or any other breach of security.
            <br></br>
            <br></br>Your User Account is non-transferrable and may not be sold, combined and/or otherwise shared with
            non-authorized users outside of your organization. If you violate any of these limitations and/or this Terms
            of Service, we may terminate your User Account immediately. Upon termination, the provisions of this Terms
            of Service that are by their nature intended to survive termination (including without limitation any
            disclaimers, limitations of liability, and indemnities) shall survive such termination. If we terminate your
            User Account, you may not subscribe under a new account unless we formally invite you. If you commit fraud
            and/or falsify information in connection with your access and/or use of the Onward Platform and/or the
            Services, or in connection with your User Account, your account will be terminated immediately and we
            reserve the right to: (a) hold you liable for any and all damages that we suffer, (b) pursue legal action
            through relevant local and national law enforcement authorities, and/or (c) notify your Internet Service
            Provider of any fraudulent activity we associate with you and/or your access to and/or your use of the
            Onward Platform. <br></br>
            <br></br>‍<strong>Communications From Onward. </strong>By agreeing to this Terms of Service, you agree to
            receive certain communications in connection with your use of the Onward Platform, Services and/or Carrier
            Services. When you access and/or use the Onward Platform, Services and/or schedule Carrier Services, you are
            communicating with us electronically, and you consent to receive communications from us electronically
            including but not limited to notices related to the Onward Platform, Services and/or Carrier Services via
            email and through the Onward Platform. You agree that all agreements, notices, disclosures and/or other
            communications that we provide to you electronically satisfy any legal requirement that such communications
            be in writing. Your consent to receive records and notices electronically will remain in effect until you
            withdraw it. You may withdraw your consent to receive further records and notices electronically at any time
            by contacting us. If you withdraw your consent to receive such records and notices electronically, we will
            revoke your access to the Onward Platform, Services and/or Carrier Services. Any withdrawal of your consent
            to receive records and notices electronically will be effective only after we have a reasonable period of
            time to process your request for withdrawal.<br></br>
            <br></br>‍<strong>Confidentiality. </strong>During your access to and/or use of the Onward Platform,
            Services and/or Carrier Services, you may have access to certain information of Onward that is not generally
            known to others including any and all information relating to Onward and its business, including without
            limitation, its business, legal, and operational practices, financial, technical, design, performance
            characteristics, commercial, marketing, competitive advantage or other information concerning the business
            and affairs, partnerships and potential partnerships, business model, fee structures, employees, funding
            opportunities, metrics, object code, source code, architecture, algorithms, techniques, formulas, processes,
            know-how, systems, ideas, inventions (whether or not patentable or copyrighted), feedback, procedures and
            techniques that have been or may hereafter be provided or shown to you, regardless of the form of the
            communication, (collectively, the &quot;<strong>Confidential Information</strong>&quot;). In addition, the
            structure, sequence, organization, and code of the Onward Platform constitutes valuable trade secrets of
            Onward. You agree to use best efforts to protect and prevent any access to the Confidential Information.
            <br></br>
            <br></br>‍
            <strong>
                USE OF THE ONWARD PLATFORM<br></br>‍<br></br>Your Responsibilities.{' '}
            </strong>
            Onward grants you the rights set forth herein, subject to the following conditions:<br></br>‍<br></br>(a)
            You shall not modify, disassemble, create derivative works, publicly display, reverse engineer and/or
            otherwise reduce to human-perceivable form all or any part of the Onward Platform;<br></br>‍<br></br>(b) You
            agree not to submit and/or transmit any emails, User Content or other materials through the Onward Platform
            that contain a virus, worm, Trojan horse and/or any other harmful component designed to interrupt, destroy
            and/or limit the functionality of any computer software and/or hardware and/or telecommunications equipment,
            and/or that is designed to obtain unauthorized access to any information;<br></br>‍<br></br>(c) You may not
            access all or any part of the Onward Platform in order to build a product or service which competes with the
            Onward Platform and/or any products and/or services offered by Onward;<br></br>‍<br></br>(d) You shall not
            copy, store, reproduce, duplicate, transmit or distribute a significant portion of the content on the Onward
            Platform;<br></br>‍<br></br>(e) You shall not obtain or attempt to obtain any data through any means from
            the App and/or Site, except if we intend to provide and/or make it available to you;<br></br>‍<br></br>(f)
            You shall not pretend to be someone else, use someone else's identity or misrepresent your affiliation with
            a person or entity;<br></br>‍<br></br>(g) You shall not copy or imitate part or all of the design, layout,
            and/or look-and-feel of the Onward Platform and/or individual sections of it, in any form or media;<br></br>
            ‍<br></br>(h) You are only authorized to view, use, copy for your records and download small portions of the
            content on the Onward Platform for your informational, non-commercial use; provided that you leave all
            copyright and other proprietary notices intact;<br></br>‍<br></br>(i) You are responsible for the accuracy
            and quality of the data and content that you submit;<br></br>‍<br></br>(j) You agree to act in accordance
            with all applicable laws, rules and regulations (including rules related to professional ethics and
            conduct);<br></br>‍<br></br>(k) You represent that you have the necessary permissions to use and authorize
            the use of User Content as described herein;<br></br>‍<br></br>(l) You shall not submit User Content
            (defined below), in any form, that is harmful, offensive, inaccurate or otherwise inappropriate including
            without limitation libelous, defamatory, discriminatory, harassing, racially, ethnically, hateful,
            profanatory and/or otherwise objectionable;<br></br>‍<br></br>(m) You shall not imply that User Content is
            in any way sponsored or endorsed by Onward;<br></br>‍<br></br>(n) You agree not to intentionally hold Onward
            and/or our employees and/or directors up to public scorn, ridicule or defamation;<br></br>‍<br></br>(o) You
            will not promote and/or provide information about illegal activities and/or physical harm and/or injury to
            any group, individual, institution and/or property;<br></br>‍<br></br>(p) You will use commercially
            reasonable efforts to prevent unauthorized access to and/or use of the products, services and content
            provided on the Onward Platform, and to notify Onward promptly of any such unauthorized access and/or use;
            <br></br>‍<br></br>(q) You shall not use the Onward Platform, Services, and/or Carrier Services for any
            unlawful purpose and/or to violate any federal, state, international law, code of conduct and/or other
            guidelines which may be applicable to the Onward Platform, Services, and/or Carrier Services provided; and
            <br></br>‍<br></br>(r) You will not submit User Content that violates, plagiarizes and/or infringes the
            rights of third parties including, without limitation, copyright rights, trademarks, rights of privacy or
            publicity and/or any other proprietary right; and/or that is designed to obtain unauthorized access to any
            information.<br></br>
            <br></br>You acknowledge that a breach of any of the foregoing terms and conditions and/or any other terms
            and conditions related to Onward's intellectual property may result in irreparable and continuing damage to
            Onward for which monetary damages may not be sufficient, and agree that Onward will be entitled to seek, in
            addition to its other rights and remedies hereunder or at law, injunctive or all other equitable relief, and
            such further relief as may be proper from a court of competent jurisdiction. The terms of this Section shall
            survive the expiration or termination of this Terms of Service and/or any applicable Order Form. In
            addition, Onward shall not be subject to any obligations of confidentiality regarding any information or
            materials provided by you unless required by law or otherwise set forth in our Privacy Notice. The
            commercial use, reproduction, transmission or distribution of any information, software or other material
            available through the Onward Platform without the prior written consent of Onward is strictly prohibited.
            <br></br>
            <br></br>‍<strong>Errors, Inaccuracies and Omissions. </strong> Occasionally there may be information on the
            Onward Platform that contains typographical errors, inaccuracies, and/or omissions that may relate to
            Service descriptions, pricing, offers and availability. We reserve the right to: (i) revoke any stated
            offer; (ii) correct any errors, inaccuracies, and/or omission; and/or (iii) make changes to prices, content,
            promotion offers, Services and/or product descriptions and/or other information without obligation to issue
            any notice of such changes, except as prohibited by law.<br></br>
            <br></br>‍<strong>Use Outside the Defined Area. </strong>Please be aware that the Onward Platform is
            directed to those individuals and entities located in the United States, and are subject to United States
            laws, including laws governing privacy and security of your information. It is not directed to any person or
            entity in any jurisdiction where (by reason of nationality, residence, citizenship or otherwise) the
            publication or availability of the Onward Platform and its content, including its products and services, are
            unavailable or otherwise contrary to local laws or regulations. If this applies to you, you are not
            authorized to access or use any of the information on the Onward Platform. Those who choose to access the
            Onward Platform from other locations do so at their own risk and are responsible for compliance with
            applicable local laws. This Terms of Service is written in English. To the extent any translated version of
            this Terms of Service conflicts with the English version, the English version controls. In the event you are
            using the Onward Platform outside of the United States, you agree as follows: (i) you consent to having your
            personal data transferred to and processed in the United States; (ii) if you are located in a country
            embargoed by the United States, or are on the U.S. Treasury Department&#x27;s list of 'Specially Designated
            Nationals' you will not engage in commercial activities on the Onward Platform (such as advertising or
            payments); and/or (iii) you will not use the Onward Platform if you are prohibited from receiving products,
            services or software originating from the United States. <br></br>
            <br></br>‍<strong>Spamming. </strong>Gathering email addresses from Onward through harvesting or automated
            means is prohibited. Posting and/or transmitting unauthorized and/or unsolicited advertising, promotional
            materials, and/or any other forms of solicitation to other users is prohibited. We also prohibit crawling,
            scraping, caching, and/or otherwise accessing any content on the Onward Platform via automated means (except
            as may be the result of standard search engine protocols and/or technologies used with Onward's express
            written consent).<br></br>
            <br></br>‍<strong>No Unlawful or Prohibited Use. </strong>As a condition of your use of the Onward Platform,
            Services and/or Carrier Services you warrant to Onward that you will not use the Onward Platform, Services
            and/or Carrier Services for any purpose that is unlawful or prohibited by these terms, conditions, and
            notices. You may not use the Onward Platform, Services and/or Carrier Services in any manner that could
            damage, disable, overburden, and/or impair the Onward Platform, Services and/or Carrier Services and/or
            interfere with any other party&#x27;s use and/or enjoyment of the Onward Platform, Services and/or Carrier
            Services. You may not obtain and/or attempt to obtain any materials and/or information through any means not
            intentionally made available and/or provided for through the Onward Platform and/or Services.<br></br>
            <br></br>‍<strong>User Verification.</strong> Onward may, in its sole discretion, verify a user's identity
            prior to allowing such user to access and/or use the Onward Platform and/or Services. Onward may, without
            liability, refuse to process and/or may cancel access to the Onward Platform, Services and/or Carrier
            Services, as reasonably deemed necessary, to comply with applicable law and/or to respond to a case of
            misrepresentation, fraud and/or known and/or potential violations of the law and/or this Terms of Service.
            Except as expressly provided herein, Onward does not itself verify the information and/or the qualifications
            of users of the Onward Platform and/or Services, including but not limited to the qualifications and/or
            licensing of Carriers providing Carrier Services, nor does it evaluate or control in any ongoing manner
            exchanges between users and/or exchanges between Shippers and Carriers. Any reviews, opinions or statements
            expressed by a user are those of the user alone, and are not to be attributed to Onward and Onward does not
            take on any liability related thereto. Onward cannot and does not assume responsibility for the accuracy,
            completeness, safety, reliability, timeliness, legality and/or applicability of anything said, written,
            posted, displayed and/or otherwise made available by any user and/or Carrier on and/or in relation to the
            Onward Platform, Services and/or Carrier Services. <br></br>
            <br></br>‍<strong>SMS Text Service. </strong>By checking the box to provide consent to text messages, you
            authorize Onward and its affiliates, vendors and/or partners to send calls or text messages, including
            messages that may originate from an automatic dialer, to the mobile phone number provided to Onward. Through
            this program you will receive communications on your mobile device. By opting in for text messaging service,
            you, the user, agree to this Terms of Service as well as our Privacy Policy as it pertains to specific usage
            of text messaging service. You are not required to consent to receive marketing messages or required to
            agree to the receipt of such messages as a condition of purchasing any goods or services. You may revoke
            your consent to receive SMS messages at any time by replying &quot;STOP&quot;. Your carrier&#x27;s standard
            messaging rates apply to your entry or submission message, our confirmation and all subsequent SMS
            correspondence. Onward does not charge for any content, however downloadable content may incur additional
            charges from your cell phone provider. Please contact your wireless carrier for information about your
            messaging plan. Your carrier may impose message or charge limitations on your account that are outside our
            control. All carrier charges are billed by and payable to your mobile service provider. Message and data
            rates may apply. By subscribing, you consent to receiving both transactional and promotional messages using
            automated technology. You represent that you are the owner or authorized user of the wireless device you use
            to subscribe for the service, and that you are authorized to approve the applicable charges. We will not be
            liable for any delays or failures in your receipt of any SMS messages as delivery is subject to effective
            transmission from your network operator and processing by your mobile device. SMS message services are
            provided on an AS IS, AS AVAILABLE basis.<br></br>
            <br></br>‍<strong>Data Charges and Mobile Phones.</strong> You are responsible for any mobile charges that
            you may incur for using the Onward Platform, including but not limited to data and text message charges. If
            you are unsure what those charges may be, you should ask your service provider before using the Onward
            Platform. Data obtained from you in connection with this SMS service only include your cell phone number,
            your carrier&#x27;s name, and the date, time and content of your messages and other information that you may
            provide. We may use this information to contact you and to provide the services or programs you request from
            us, and to otherwise operate, develop and improve the Onward Platform, Services and/or Carrier Services.
            Your wireless carrier and other service providers may also collect data about your SMS usage, and their
            practices are governed by their own policies. We will only use information you provide to the SMS service to
            transmit your text message or as otherwise described in this Terms of Service. Nonetheless, we reserve the
            right at all times to disclose any information as necessary to satisfy any law, regulation or governmental
            request. When you complete forms online or otherwise provide us information in connection with the Onward
            Platform, Services and/or Carrier Services, you agree to provide accurate, complete, and true information.
            <br></br>
            <br></br>‍
            <strong>
                USE OF SERVICES<br></br>
                <br></br>Requesting a Delivery Through the Onward Platform.{' '}
            </strong>{' '}
            After establishing a User Account as described above and providing payment information, you can request a
            pick-up and delivery from a Carrier by submitting an order via the Onward Platform. You are required to
            provide accurate information when entering your order and may be charged extra for inaccurate information.
            After you submit an order, the Onward Platform will display possible Carriers that may be able to perform
            your requested delivery. You may then select an applicable Carrier to fulfill your delivery request and will
            be able to arrange, track and manage the delivery via the Onward Platform. Unless pursuant to an applicable
            Order Form, Onward does not arrange transportation, the Onward Platform only provides a marketplace to
            identify available Carriers to fulfill your pick-up and delivery request and book delivery orders with
            Carriers. Except as otherwise set forth herein or pursuant to an applicable Order Form, Onward's
            responsibility under this Terms of Service shall be limited to providing the Onward Platform, which may help
            you arrange for the transportation of your freight. Onward will never actually perform transportation of
            your freight and is not responsible for the acts of Carriers. <br></br>
            <br></br>‍<strong>Requesting a Delivery Pursuant to an Order Form.</strong> Onward may also provide Services
            pursuant to and as more fully described in a relevant, duly executed Order Form under this Terms of Service.
            Neither party will have any obligation with respect to any draft Order Form unless and until it is executed
            by you and Onward. Onward will never actually perform transportation of your freight and is not responsible
            for the acts of Carriers. Except as otherwise provided in this Terms of Service, if any of the terms or
            conditions of this Terms of Service conflict with the terms or conditions of the Order Form, the terms and
            conditions of the Order Form will control solely with respect to the Services covered under such Order Form.
            If you are provided and/or purchase a subscription to the Onward Platform pursuant to a relevant Order Form
            you may track the status of your scheduled pick-up and delivery through the Onward Platform. <br></br>
            <br></br>‍<strong>Shipper Obligations. </strong> When contracting with Onward for Carrier Services, you
            agree to pay Onward directly for any of the services provided by Carriers and agree not to pay Carriers
            and/or any driver, helper or other person providing the Carrier Services directly. Except for certain
            curb-side delivery services, you or your authorized representative is required to be onsite at the time of
            pick-up and delivery. If you or your authorized representative is not onsite at the scheduled time of
            pick-up or delivery as required for all services except for curb-side drop-off, the applicable delivery
            services must be cancelled by the Carrier. If delivery services are cancelled for your failure to have an
            authorized person onsite at the time of pick-up or delivery, you will be charged an associated cancellation
            fee set forth herein. By using the Onward Platform, Services and/or scheduling Carrier Services, you
            represent and warrant that you have all right, title and permission to allow the loading unloading,
            transportation, moving, packing, or lifting of any such items and that the performance of the Carrier
            Services by a Carrier and its representatives will not be a violation of any third party's ownership or
            privacy rights. You acknowledge and agree that you are responsible for providing timely and accurate
            transportation and delivery specifications and a description of the cargo in connection with any delivery
            request, including, but not limited to, dimensions, weight, temperature, any special handling or security
            requirements, load value and employing reasonable security protocols to reduce risk of cargo theft. You
            agree that you or a Consignee will provide to the Carrier providing the Carrier Services a paper copy of the
            tickets shared with Onward during loading and shall have all items staged and labeled with the appropriate
            names and stop number. Any additional services such as assembly or haul away must be specified prior to
            pickup in the order. Any applicable items will be pre-assembled, as necessary. <br></br>
            <br></br>‍<strong>Proof of Delivery. </strong>Upon completion of a scheduled pick-up, you or your authorized
            representative as designated within the Onward Platform at the time of booking (unless specified otherwise
            in the Order Form) as the 'Pick-Up Customer' are responsible for and agree to sign the associated proof of
            delivery form ("<strong>POD</strong>"). Upon completion of the drop off and/or final delivery, you or your
            Consignee as designated within the Onward Platform at the time of booking as the 'Drop-Off Customer' are
            responsible for and agree to sign the associated POD. If the delivery is a curbside delivery, a picture
            taken by the Carrier making the delivery of the final resting place of the cargo is considered POD. Your
            insertion of Onward's name on the bill of lading shall be for convenience only and shall not change Onward's
            status as a property broker.<br></br>
            <br></br>‍<strong>Restrictions. </strong>By using the Services, you agree that you will not use the Onward
            Platform and/or Services to transport or move weapons, prescription drugs, illegal drugs, alcohol, tobacco,
            hazardous materials, food, animals, people, or other items prohibited by law or not permitted by an
            applicable Carrier's DOT/FMCSA operating authority.<br></br>
            <br></br>‍
            <strong>
                FEES AND PAYMENT<br></br>
                <br></br>Delivery Fees.{' '}
            </strong>{' '}
            You must provide payment information to request a delivery service from a Carrier. You are responsible for
            paying the applicable fees associated with the Carrier Services you request which shall be set forth in the
            applicable Order Form or as you mutually agree to with the Carrier in the Onward Platform, which may
            include: a fee for the pick-up and delivery of cargo; and any additional charges related to the Carrier
            Services, if applicable, such as charges for wait time, time on site, excessive stair carries, additional
            persons required to perform a delivery, and any other service provided outside the normal scope of a
            delivery; (collectively the "<strong>Delivery </strong>"). After you and the applicable Carrier verify that
            the Carrier Services are complete via a signed POD or, in the case of curbside delivery, a picture of the
            delivered goods at the final destination, Onward will automatically charge the payment method provided. If
            you request Carrier Services pursuant to an Order Form, you will be charged for the fees and must provide
            payment according to the payment terms set forth therein. Fees are inclusive of all applicable taxes and/or
            expenses. Shippers or Consignees may elect to add an additional tip for the Carrier on top of the Delivery
            Fees via the Onward Platform. The tip will be paid entirely to the Carrier and shall be considered separate
            from the Delivery Fees. <br></br>
            <br></br>‍
            <strong>
                CANCELLATIONS<br></br>
                <br></br>‍
            </strong>
            ‍<strong>Cancellations. </strong> Scheduled delivery services may only be cancelled by calling Onward's
            Customer Service Line at (720)526-0068. Except as expressly provided for in a duly executed Order Form, if a
            Shipper and/or Consignee cancels a scheduled delivery 2 full business days prior to the scheduled pick-up
            time, no cancellation fee shall be due. If Shipper and/or Consignee cancels a scheduled delivery within the
            2 business day notice period described above, but prior to the Carrier arriving to the scheduled pick-up
            location, Shipper will be charged a $200 cancellation fee or twenty percent (20%) of the contracted Delivery
            Fees for the canceled delivery, whichever is greater. If Shipper and/or Consignee cancels a delivery after
            the freight is loaded on the truck or en route the day of the appointment, Shipper shall pay a cancellation
            fee equal to one hundred percent (100%) of the Delivery Fees for the applicable cancelled delivery..
            Additionally, where the Carrier attempts to fulfill the shipping request but cannot do so for reasons
            outside of the Carrier's control and due to Shipper and/or Consignee's acts or omissions, which may include
            without limitation, Consignee not present at the delivery location during the pick-up window, unsafe jobsite
            with debris, blocked walkway, aggressive dogs, and/or inaccessible or locked gates, Shipper will be charged
            a cancellation fee equal to one hundred percent (100%) of the applicable Delivery Fees. Any Carrier Services
            that cannot be completed for reasons beyond the Carrier's reasonable control unrelated to any acts or
            omissions of Shipper and/or Consignee, including but not limited to reasons such as unsafe road conditions,
            inclement weather or other act of God, shall be rescheduled to a time mutually agreed to by Shipper and
            Onward without Onward and/or Carrier incurring any liability related thereto. Any cancellation fees due to
            Onward as described herein will be due and payable within 5 days of the canceled delivery. <br></br>
            <br></br>‍<strong>Onward Platform Fee. </strong>In order to have complete use of the Onward Platform, you
            must first be provided a license to use the Onward Platform either pursuant to an Order Form or by
            purchasing a monthly subscription to the Onward Platform. If you purchase a subscription to the Onward
            Platform, you agree to pay Onward a subscription fee in the amount set forth in the applicable Order Form or
            in the amount set forth on the Site. Subscriptions may be purchased on a monthly basis ("
            <strong>Subscription Period</strong>"). Subscription fees are due and payable in advance on or before the
            first day of the month. Notwithstanding anything herein to the contrary, Onward may change the fees at any
            time at the end of your Subscription Period, provided that Onward first notifies you by email or through the
            Onward Platform. Onward may from time to time offer a limited time, free or discounted promotional trial
            subscription to access the Onward Platform. Upon sign-up for a promotional trial subscription, you will
            provide payment information. Promotional trial subscriptions may vary in length which will be disclosed to
            you upon sign-up ("<strong>free trial period</strong>"). You will not be charged during the free trial
            period. Prior to the end of any trial subscription, you will be notified through the Onward Platform that
            your promotional trial will be ending. TO CANCEL YOUR SUBSCRIPTION AND AVOID BEING CHARGED, YOU MUST CANCEL
            BEFORE THE END OF THE FREE TRIAL PERIOD. See the Subscription Cancellation section below for more
            information on how to cancel a subscription. In no event will Onward provide a refund of any subscription
            fees paid hereunder. Failure to pay any fees due hereunder in accordance with the terms and conditions set
            forth herein may result in your inability to access and/or use the Onward Platform which includes the App.{' '}
            <br></br>
            <br></br>‍<strong>Authorization to Charge for Services. </strong>You must either use ACH, credit card, or
            other payment method accepted by us to pay the fees due hereunder and you authorize Onward to charge you
            through such payment method. You will also be responsible for charges (including without limitation
            subscription fees and cancellation fees) for the subscriptions, products and/or services that you purchase
            through the Site, Onward Platform or incur in connection with an Order Form. If the payment method you
            provide is declined, you agree to pay all amounts due hereunder upon our demand and will be solely
            responsible for any dispute with your payment method provider.<br></br>
            <br></br>‍<strong>Subscription Renewal. </strong>You agree that we may automatically renew your subscription
            at the end of the Subscription Period for the same duration of time as your then-current Subscription
            Period. You authorize Onward to charge you for the Subscription Period at the beginning of the Subscription
            Period and each renewal thereafter, unless you cancel your subscription prior to its renewal date through
            the procedures described in the Subscription Cancellation section below. Onward will charge your credit card
            (or the other payment method you provided) each month for the then-current applicable price.<br></br>
            <br></br>‍<strong>Subscription Cancellation. </strong>To cancel a subscription and avoid future billing, you
            may cancel such subscription prior to the end of the then-current Subscription Period through the Onward
            Platform or by contacting Onward at . Upon cancellation, you will continue to have access to the products
            and services included in your subscription for the remainder of the Subscription Period; provided that
            Onward has received all fees related to the subscription during the Subscription Period. After you cancel
            your subscription, we will not charge you any subscription fees due and owing after the expiration of your
            then-current Subscription Period. Please note that we do not provide full or partial refunds for prepaid
            Subscription Fees. In any event, you will be able to continue to use the Onward Platform throughout the
            remainder of the Subscription Period for which you have already paid. <br></br>
            <br></br>‍<strong>Payments. </strong>Onward uses a third-party payment platform to process payments. Your
            payment method will be processed and stored by our third-party payment processor, which is currently
            'Bill.com' or 'Stripe', but may be changed from time to time in our sole discretion. You expressly
            understand and agree that all payments and monetary transactions are handled by such third-party and the use
            of such platform is governed by the third-party's terms and conditions and Onward shall have no liability
            related thereto. In the event you elect to pay the fees due hereunder via ACH and/or credit card, you
            authorize Onward to automatically charge the payment account for the fees due and owing hereunder. If your
            payment account on file is closed or the account information is changed, or if, for any reason, a charge is
            rejected by your payment account, you shall immediately update your payment account or supply a new payment
            method, as appropriate,. Additionally, amounts not timely paid are subject to interest charges of 1.5% per
            month after the payment is due. In the case of an ACH transaction being rejected for Non-Sufficient Funds
            (NSF), incorrect payment information, or incorrectly disputed charges, Onward may, at its discretion, charge
            $25 for each returned payment. If Onward believes that you have participated in a fraudulent chargeback,
            Onward will pursue its claims against you to the fullest extent allowed by law. In the event you are owed
            any fees from Onward, such as a cancellation fee, such fees shall be paid pursuant to the applicable payment
            method on file. <br></br>
            <br></br>‍<strong>Taxes, Late Fees, Right to Suspend. </strong>All fees are stated in United States Dollars
            and must be paid in United States Dollars. All fees due and payable under this Terms of Service are
            inclusive of taxes. All fees due and payable by you to Onward hereunder must be paid in full without any
            deduction, set-off, counterclaim or withholding of any kind unless required by law. Except as expressly set
            forth herein or in an applicable Order Form, the fees due to Onward hereunder are non-refundable. In the
            event fees are not paid in accordance with the terms and conditions set forth herein, Onward may immediately
            suspend access to the Onward Platform and/or Services, without liability, until all past due payments are
            made. If all past due payments are not paid in full within 15 (fifteen) days of notice from Onward, your
            applicable subscriptions, User Account and requested Carrier Services may be terminated. You are responsible
            for all costs of collection, including without limitation reasonable attorneys&#x27; fees, for any payment
            default. <br></br>
            <br></br>‍
            <strong>
                PROPRIETARY RIGHTS AND LICENSES<br></br>
                <br></br>Ownership.{' '}
            </strong>
            The Onward Platform, any content and/or products on the Onward Platform and the infrastructure used to
            provide the Onward Platform are proprietary to Onward, our affiliates, and/or other content providers. By
            using the Onward Platform and accepting this Terms of Service, Onward grants you a limited, personal,
            nontransferable, nonexclusive, revocable right to access and/or use the Onward Platform pursuant to this
            Terms of Service and to any additional terms and policies set forth by Onward. Neither this Terms of Service
            nor your use of the Onward Platform, Services and/or Carrier Services convey and/or grant to you any rights:
            (i) in or related to the Onward Platform and/or Services except for as expressly set forth herein; and (ii)
            to use or reference in any manner Onward's names, logos, product and service names, trademarks or services
            marks or those of Onward's licensors.<br></br>
            <br></br>‍<strong>Reservation of Rights. </strong>The materials, headers, videos, illustrations,
            photographs, graphics and/or any other content on the Onward Platform, as well as the organization and
            layout of the Onward Platform, are copyrighted and are protected by United States and international
            copyright laws and treaty provisions. Subject to the limited rights expressly granted hereunder, Onward
            and/or its third-party providers reserve all right, title and interest in and to the Onward Platform and
            content, including all related worldwide intellectual property rights. No rights are granted to you
            hereunder other than as expressly set forth herein. <br></br>
            <br></br>‍<strong>User Content &amp; Aggregated Data</strong>. Please carefully choose the information,
            content, advice, discussions, images, photographs, video, audio, sounds, descriptions, comments, responses,
            posts and/or messages (collectively, the "<strong>User Content</strong>") you choose to submit or transmit
            through the Onward Platform from time to time. You assume all risks associated with User Content, including
            anyone's reliance on its quality, accuracy, and/or reliability by you. You also represent that you own,
            and/or have the necessary permissions to use and authorize use of User Content as described in this Terms of
            Service. You hereby grant Onward a worldwide, perpetual, non-exclusive, royalty-free, irrevocable,
            assignable, sub-licensable, transferable license to use the User Content for purposes of providing the
            Services. We have no obligation to retain or otherwise provide you with copies of User Content.
            Notwithstanding anything herein to the contrary, Onward may collect aggregated, anonymized data that cannot
            identify any person and that is derived from or created through the use of the Onward Platform in order to
            learn about its users generally, improve its product and services and market to new and current users.{' '}
            <br></br>
            <br></br>‍<strong>Other. </strong> Onward reserves the right to take any action it deems necessary to
            protect the personal safety of our users and/or the public. Onward has no liability or responsibility to
            users of the Onward Platform and/or Services and/or any other person and/or entity for performance and/or
            nonperformance of the aforementioned activities.<strong> </strong>‍<br></br>
            <br></br>‍<strong>Feedback.</strong> If you provide us (in a direct mail, email and/or otherwise) with any
            feedback, suggestions, improvements, enhancements, recommendations and/or feature requests relating to the
            Onward Platform and/or Services ("<strong>Feedback</strong>"), then you further grant to Onward a worldwide,
            perpetual, irrevocable, royalty-free license to use and incorporate any such Feedback. Onward has no
            obligation to review any Feedback and may use and redistribute Feedback for any purpose without restriction
            in its sole and absolute discretion.<br></br>
            <br></br>‍
            <strong>
                TERMINATION<br></br>
                <br></br>‍
            </strong>
            If you violate this Terms of Service and/or if we have grounds to suspect that you violated this Terms of
            Service and/or other use parameters provided by Onward on the Onward Platform or pursuant to an Order Form,
            we may suspend and/or terminate your account and/or refuse service. Onward also reserves the right, in its
            sole and absolute discretion, to terminate your access to the Onward Platform or any portion thereof at any
            time, without cause and/or without notice. In the event you fail to pay for the use of the Onward Platform,
            Services, and/or you share the access granted with any unauthorized person or entity, and/or misuse the
            Onward Platform and/or Services by any means actionable under a federal, state, and/or local statute, code,
            regulation, law, and/or civil action, Onward will consider your access as having been acquired by fraud
            and/or misrepresentation and will terminate your access to the Onward Platform immediately and shall refuse
            service. In such an event, Onward retains the right to seek civil and/or criminal redress, the entire cost
            of which shall be borne solely by you. Services provided pursuant to an Order Form shall be provided for the
            term and may be terminated as set forth therein.<br></br>
            <br></br>‍
            <strong>
                INDEMNIFICATION AND WAIVER<br></br>
                <br></br>‍
            </strong>
            You shall indemnify, defend and hold harmless Onward, its officers, agents, employees, contractors,
            subcontractors, suppliers and representatives from and against any and all claims, demands, proceedings,
            losses, costs, damages, awards, fees, expenses, or liabilities of any nature ("<strong>Claim</strong>")
            arising out of and/or in the relation to (i) your violation of any law and/or the rights of a third party,
            (ii) your use of the Onward Platform, Services and/or Carrier Services; (iii) any breach of this Terms of
            Service, Privacy Notice, and/or an applicable Order Form by you, your officers, agents, employees,
            contractors and/or representatives; and/or (iv) fraud you commit and/or your intentional misconduct and/or
            negligence. You shall give prompt notice to Onward upon your receipt of notice of any Claim against you
            which might give rise to a claim against Onward.You acknowledge and agree that by accessing and/or using the
            Onward Platform, Services, and/or Carrier Services you may be exposed to materials from others that you may
            consider offensive, indecent or otherwise objectionable, and agree to accept that risk.<br></br>
            <br></br>‍
            <strong>
                DISCLAIMER OF WARRANTIES<br></br>
                <br></br>‍
            </strong>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW:THE ONWARD
            PLATFORM, SERVICES, AND ANY CONTENT, ARE PROVIDED BY ONWARD TO YOU ON AN &quot;AS IS,&quot; &quot;AS
            AVAILABLE&quot; BASIS WITHOUT WARRANTY OF ANY KIND WHETHER EXPRESS, STATUTORY AND/OR IMPLIED, INCLUDING BUT
            NOT LIMITED TO ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, QUIET ENJOYMENT, MERCHANTABILITY, AND/OR
            FITNESS FOR A PARTICULAR PURPOSE, SYSTEMS INTEGRATION, ACCURACY, AND FROM COURSE OF DEALING, USAGE AND/OR
            TRADE PRACTICE, ALL OF WHICH ONWARD EXPRESSLY DISCLAIMS. WITHOUT LIMITING THE FOREGOING, ONWARD AND/OR ITS
            LICENSORS MAKE NO WARRANTY THAT (A) THE ONWARD PLATFORM, SERVICES, CARRIER SERVICES AND/OR CONTENT WILL MEET
            YOUR REQUIREMENTS AND/OR WILL BE CONSTANTLY AVAILABLE, UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (B) THE
            RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE ONWARD PLATFORM, SERVICES AND/OR CARRIER SERVICES WILL BE
            EFFECTIVE, ACCURATE AND/OR RELIABLE; (C) THE QUALITY OF ANY SERVICES, INFORMATION, OR OTHER MATERIAL
            PURCHASED OR OBTAINED BY YOU THROUGH THE ONWARD PLATFORM AND/OR SERVICES INCLUDING BUT NOT LIMITED TO
            CARRIER SERVICES WILL MEET YOUR EXPECTATIONS, AND/OR (D) ONWARD WILL NOT BE LIABLE AND/OR OTHERWISE
            RESPONSIBLE FOR ANY FAILURE AND/OR DELAY IN UPDATING THE ONWARD PLATFORM AND/OR ANY CONTENT. NO ADVICE
            AND/OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM ONWARD AND/OR THROUGH AND/OR FROM USE OF
            THE ONWARD PLATFORM, SERVICES, AND/OR CARRIER SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN
            THIS TERMS OF SERVICE. <br></br>
            <br></br>‍
            <strong>
                LIMITATION OF LIABILITY<br></br>‍<br></br>‍
            </strong>
            YOUR USE OF THE ONWARD PLATFORM, SERVICES, CARRIER SERVICES, AND CONTENT IS AT YOUR OWN RISK. IN NO EVENT
            SHALL ONWARD, ITS OFFICERS, DIRECTORS, EMPLOYEES, PARTNERS, LICENSORS, AND/OR SUPPLIERS BE LIABLE TO YOU
            AND/OR ANYONE ELSE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL AND/OR PUNITIVE DAMAGES WHATSOEVER,
            INCLUDING THOSE RESULTING FROM LOSS OF USE, DATA AND/OR PROFITS, WHETHER OR NOT FORESEEABLE, REGARDLESS OF
            THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY, STRICT LIABILITY, AND/OR ANY
            OTHER LEGAL OR EQUITABLE THEORY, EVEN IF ONWARD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN
            ADVANCE. THE FOREGOING SHALL APPLY DESPITE ANY NEGLIGENCE, MISCONDUCT, ERRORS AND/OR OMISSIONS BY ONWARD ITS
            EMPLOYEES, REPRESENTATIVES AND/OR AGENTS. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION AND/OR LIMITATION
            OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES, ONWARD'S LIABILITY IS LIMITED TO THE
            MAXIMUM EXTENT PERMITTED BY LAW.ONWARD'S MAXIMUM AGGREGATE LIABILITY, AND THE AGGREGATE LIABILITY OF ITS
            PARTNERS AND LICENSORS, TO YOU IN ANY CIRCUMSTANCE ARISING OUT OF AND/OR RELATING TO THE ONWARD PLATFORM,
            SERVICES, AND/OR CARRIER SERVICES IS LIMITED TO FIVE HUNDRED U.S. DOLLARS (US $500). <br></br>
            <br></br>‍
            <strong>
                LINKED SITES<br></br>‍<br></br>‍
            </strong>
            The Onward Platform may provide links to various other independent third-party websites ("
            <strong>Linked Sites</strong>") that may be of interest to you and for your convenience only. Onward does
            not control and/or endorse such Linked Sites and is not responsible for their content nor is it responsible
            for the accuracy and/or reliability of any information, data, opinions, advice or statements contained
            within such Linked Sites. You will need to make your own independent judgment regarding your interaction
            with these Linked Sites at your own risk. We encourage you to be aware when you leave the Onward Platform
            and to read the terms and conditions and privacy policy of each Linked Site that you visit. Onward reserves
            the right to terminate any link and/or linking program at any time. Onward disclaims all warranties, express
            and implied, as to the accuracy, validity, and legality and/or otherwise of any materials or information
            contained on such Linked Sites.<br></br>
            <br></br>‍
            <strong>
                LINKING TO THE SERVICES<br></br>‍<br></br>‍
            </strong>
            You may link to our homepage (<a href="https://www.onwarddelivery.com">https://www.onwarddelivery.com</a>),
            only with prior consent from Onward, provided you do so in a way that is fair and legal and does not damage
            our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any
            form of association, approval, or endorsement on our part without our express written consent. To obtain
            consent please send an email to{' '}
            <a href="mailto:operations@onwarddelivery.com">operations@onwarddelivery.com</a>. Subject to the foregoing,
            you must not: (i) establish a link from any website that is not owned by you; (ii) cause the Site or
            portions thereof to be displayed on, or appear to be displayed by, any other site, for example, framing,
            deep linking, or in-line linking; (iii) link to any part of the Site other than the homepage; (iv) otherwise
            take any action that is inconsistent with the Terms of Service. You agree to cooperate with us in causing
            any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission
            at any time without notice. We may disable all or any links at any time without notice in our discretion.{' '}
            <br></br>
            <br></br>‍
            <strong>
                THIRD PARTY SERVICES<br></br>
                <br></br>General.{' '}
            </strong>
            The Onward Platform may be made available and/or accessed in connection with third party services and
            content that Onward does not control. Your correspondence, participation in, and/or any other dealings with
            third parties found through the Onward Platform are solely between you and such third party. In no event
            shall Onward be liable for any products and/or services of such third-party providers. You agree that Onward
            shall not be responsible for any loss and/or damage of any sort incurred as a result of any such dealings
            and/or as the result of the presence of such third parties through the Onward Platform. Moreover, Onward
            shall not be responsible and/or liable for the accuracy, quality, suitability, currency and/or content of
            the statements and/or conduct of any third party on the Onward Platform, including but limited to Carriers
            and other users of the Onward Platform. You are solely responsible for the costs, expenses and
            infrastructure required to support your use of the Onward Platform and/or Services. You acknowledge and
            agree that Onward is not liable for the performance of any third-party providers' products and/or services.
            Onward does not direct, is not an employer, has no control over, makes no representations and/or guarantees
            related to Carriers. You agree that you are solely responsible for your interactions with any Carriers
            and/or other users of the Onward Platform. FOR THE AVOIDANCE OF DOUBT, EACH CARRIER IS RESPONSIBLE FOR THE
            SERVICES THEY PROVIDE TO USERS.<br></br>
            <br></br>‍<strong>Notice Regarding Apple</strong>. You acknowledge and agree that Apple, Inc. ("Apple") and
            its subsidiaries are third-party beneficiaries to this Terms of Service and further that (a) upon your
            acceptance of this Terms of Service, Apple will have the right (and will be deemed to have accepted the
            right) to enforce this Terms of Service against you as third party; (b) this Terms of Service is between you
            and Onward only, and not Apple; (c) Onward is, solely responsible for the App and the content thereof; (d)
            Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App;
            (e) to the maximum extent permitted by applicable law, Apple will have no warranty obligation with respect
            to the App; and Onward, not Apple, will be responsible for any claims, losses, liabilities, damages, costs
            or expenses attributable to any failure of the App to conform to any applicable warranty; (f) in the event
            of any third party claim that the App or your possession and use of the App infringes that third party's
            intellectual property rights, Apple will not be responsible for the investigation, defense, settlement and
            discharge of any such intellectual property infringement claim; and (g) Apple will not be responsible for
            addressing any of your claims or any third party claims relating to the App or your possession and/or use of
            the App, including but not limited to: (i) product liability claims, (ii) claims that the App fails to
            conform to the applicable legal or regulatory requirement and (iii) claims arising under consumer protection
            or similar laws.<br></br>
            <br></br>‍
            <strong>
                DISPUTES WITH CARRIERS<br></br>
                <br></br>General.{' '}
            </strong>{' '}
            EACH CARRIER IS RESPONSIBLE FOR THE CARRIER SERVICES THEY PROVIDE TO SHIPPERS AND/OR CONSIGNEES. Unless
            otherwise agreed by the parties in writing or via an Order Form, Onward is merely a neutral means of
            connecting you with Carriers and their representatives. We do not represent or warrant that any Carrier
            and/or their representatives will meet your expectations or instructions in performing any services. Any
            dispute that you may have regarding the performance of any Carrier Services, including any dispute related
            to the time, place, and manner of doing so, is between you and the applicable Carrier; except that Onward
            may help settle claims between you and Carriers as described in the 'Claims' section below. Onward is not
            responsible for the replacement or repair of any property that may be damaged by a Carrier and/or its
            representatives while performing any services contracted through the Onward Platform and/or pursuant to
            applicable Order Form. Notwithstanding the foregoing, Onward will assist with certain disputes as described
            below between you and a Carrier, if the Carrier is providing Carrier Services pursuant to an applicable
            Order, or if Onward in its sole discretion, chooses to become involved in a dispute between you and Carrier.
            Notwithstanding the foregoing, Onward shall not provide any assistance to Shippers for claims that are not
            directly related to cargo loss, delays, damage or shortages, such as damage to property that is not cargo,
            all such claims must be resolved by the Shipper and/or applicable Carrier directly.<br></br>
            <br></br>‍<strong>Claims.</strong> Claims resolution is between the Carrier and Shipper if the relevant
            Carrier Services were booked through the Onward Platform. If the Carrier Services subject to a claim are
            provided pursuant to an Order Form or if Onward decides (in its sole discretion) to assist you with a claim,
            unless otherwise agreed by the parties in writing or via an Order Form, Onward will help settle a claim
            related to Carrier Services, only if such claim is related to cargo loss, damage and/or a shortage. If
            Onward is managing your claim, you agree that you must notify Onward in writing by providing all relevant
            documentation, including but not limited to a signed POD, listing the nature and cause of the claim for
            cargo as described in the Claims Process section below. No claims or allowances for shortages, damage or
            delay will be considered by Onward unless clearly noted on the POD, or bill of lading signed by the
            Consignee at delivery. <br></br>
            <br></br>‍<strong>Claims Process.</strong> If the Carrier Services subject to a claim are provided pursuant
            to an Order Form or if Onward decides (in its sole discretion) to assist you with a claim, you agree that
            claims shall be handled pursuant to the process described in this section entitled 'Claims Process'. Claims
            shall be filed in writing by a Shipper and/or Consignee within 120 days from the date of delivery of a
            shipment, or, in the case of non-delivery, within 60 days from the date the shipment should have been
            delivered. Upon receipt of a claim from Shipper, Onward will discuss and research the claim with the Shipper
            and/or Consignee, including reviewing any pictures or other documentation. Onward will then notify Carrier
            of the claim and review the corresponding documentation with Carrier, including any pictures of damage
            provided by Carrier and Shipper. After such review, Onward will seek to make a determination as to the
            validity of the claim. Upon Onward's finding of a valid claim, the Carrier may reach out to you directly to
            try and settle such claim. If Carrier and Shipper are unable to resolve the claim within seven (7) days,
            Onward may act on Carrier's behalf to handle the claim. You agree that Carriers may submit a formal response
            to any claim that Onward handles on your behalf. By filing a claim with Onward, you authorize Onward to
            submit, negotiate and settle such cargo claim with the responsible Carrier and Onward agrees to keep you
            advised of the status of such claims and will not agree to a settlement without your prior consent. Upon
            your written request, Onward shall cease negotiations and assign its rights against the Carrier to you.
            Nothing herein shall be construed to restrict any right or cause of action you may have against a Carrier
            for Carrier Services. Shipper agrees that Onward shall bare no liability for claims against Carrier even if
            Onward helps negotiate and/or settle such claims between Carrier and Shipper. <br></br>
            <br></br>‍
            <strong>
                DMCA COPYRIGHT POLICY AND COPYRIGHT AGENT<br></br>
                <br></br>‍
            </strong>
            Onward respects the intellectual property rights of others. We reserve the right to: (a) terminate your User
            Account or any other user who infringes third-party copyrights, (b) block access to and/or remove material
            that we believe in good faith to be copyrighted material that has been illegally distributed by you and/or
            other third parties, and/or (c) remove and discontinue service to repeat offenders. We will respond to clear
            notices of copyright infringement when you provide the following: <br></br>‍<br></br>(i) A physical or
            electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is
            allegedly infringed;<br></br>‍<br></br>(ii) Identification of the copyrighted work claimed to have been
            infringed;<br></br>‍<br></br>(iii) Identification of the material that is claimed to be infringing or to be
            the subject of infringing activity and that is to be removed or access to which is to be disabled;<br></br>‍
            <br></br>(iv) Information sufficient to permit Onward to contact you, such as your physical address,
            telephone number, and, if available, an electronic mail address;<br></br>‍<br></br>(v) A statement that the
            complaining party has a good faith belief that use of the material in the manner complained of is not
            authorized by the copyright owner, its agent, or the law; and<br></br>‍<br></br>(vi) A statement that the
            information in the notification is accurate, and under penalty of perjury, that you are authorized to act on
            behalf of the owner of an exclusive right that is allegedly infringed.<br></br>
            <br></br>Contact information for Onward's DMCA Agent for notice of claims of copyright infringement is
            Onward Delivery Corp., Attn: Copyright Agent, 4350 Kearny Street, Unit A, Denver, CO 80216. The Copyright
            Agent will not remove content from the Onward Platform in response to phone or email notifications regarding
            allegedly infringing content, since a valid DMCA notice must be signed, under penalty of perjury, by the
            copyright owner or by a person authorized to act on their behalf. Please submit such notifications by
            ordinary mail only and as further described by this section. The Copyright Agent should be contacted only if
            you believe that your work has been used or copied in a way that constitutes copyright infringement and that
            such infringement is occurring on the Onward Platform linked to and/or from the Onward Platform. All other
            inquiries directed to the Copyright Agent will not be responded to.<br></br>
            <br></br>‍
            <strong>
                RELATIONSHIP OF THE PARTIES <br></br>
                <br></br>‍
            </strong>
            The relationship between you and Onward is that of an independent contractor. This Terms of Service nor any
            applicable Order Form, nor any act or omission of any person including Onward and/or Shipper and/or
            Consignee shall not be construed for any purpose to express or imply a joint venture, partnership,
            principal/agent, fiduciary or employer/employee relationship between the parties. Neither you nor your
            employees or agents (i) is an employee, agent or legal representative of Onward, or (ii) shall have any
            authority to represent Onward or to enter into any contracts or assume any liabilities on behalf of Onward.{' '}
            <br></br>
            <br></br>‍
            <strong>
                DISPUTE RESOLUTION <br></br>
                <br></br>Disputes.{' '}
            </strong>
            We want to address your concerns without needing a formal legal case. Before filing a claim against Onward,
            you agree to try to resolve the dispute informally by contacting us at{' '}
            <a href="mailto:legal@onwarddelivery.com">legal@onwarddelivery.com</a>. We will try to resolve the dispute
            by contacting you via email, but if we cannot resolve the dispute within thirty (30) days of submission, you
            and/or Onward agree to resolve any claims related to this Terms of Service through final and binding
            arbitration, except as set forth under 'Exceptions to Agreement to Arbitrate' section below and/or you opt
            out as described below.<br></br>
            <br></br>‍<strong>Opt-Out. </strong>You can opt-out and decline this agreement to arbitrate by contacting
            Onward within thirty (30) days from the date that you first became subject to this arbitration provision
            (i.e.: the date you initially accepted this Terms of Service). You must write us at Onward Delivery Corp.,
            Attn: Opt-Out Arbitration, 4350 Kearney Street, Unit A, Denver, CO 80216. If you opt out, neither you nor
            Onward can require the other to participate in an arbitration proceeding.<br></br>
            <br></br>‍<strong>Arbitration Procedures. </strong>Except in the event the claim meets the requirements set
            forth in the 'Exceptions to Agreement to Arbitrate' section below and/or if you opt out of arbitration as
            described below, all claims between you and Onward shall be settled by binding arbitration in accordance
            with the commercial arbitration rules, in effect at the time the proceedings begin, of the American
            Arbitration Association. Any such controversy and/or claim shall be arbitrated on an individual basis, and
            shall not be consolidated in any arbitration with any claim or controversy of any other party. All
            information relating to and/or disclosed by any party in connection with the arbitration of any disputes
            shall be treated by the parties, their representatives, and the arbitrator as proprietary business
            information and shall not be disclosed without prior written authorization of the disclosing party. The
            arbitration shall be held in Denver, Colorado, or any other location we agree to. Each party shall bear the
            burden of its own counsel fees incurred in connection with any arbitration proceedings. <br></br>
            <br></br>‍<strong>Exceptions to Agreement to Arbitrate.</strong> Either you and/or Onward may assert claims,
            if it qualifies, in small claims court in Denver, Colorado. Either party may bring a lawsuit solely for
            injunctive relief to stop unauthorized use and/or abuse of the Onward Platform and/or Services, breach of
            Onward's confidential information and/or intellectual property infringement (for example, trademark, trade
            secret, copyright and/or patent rights) without first engaging in arbitration and/or the informal
            dispute-resolution process described herein.<br></br>
            <br></br>‍<strong>Judicial Forum for Disputes. </strong> You and Onward agree that any judicial proceeding
            (other than small claims actions) will be brought in the federal or state courts of Denver, Colorado. Both
            you and Onward consent to the foregoing venue and jurisdiction. The prevailing party of any such dispute
            shall be entitled to collect from the other party all costs incurred in such dispute, including reasonable
            attorneys' fees. <br></br>
            <br></br>‍<strong>California Users. </strong> Pursuant to California Civil Code Section 1789.3, any
            questions about pricing, complaints, or inquiries about Onward can be sent to our agent for notice, Attn:
            Onward Delivery Corp. at 4350 Kearney Street, Unit A, Denver, CO 80216, or by phone at (720)-663-8315.
            California users are also entitled to the following specific consumer rights notice: The Complaint
            Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be
            contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or
            (800) 952-5210. <br></br>
            <br></br>‍
            <strong>
                MISCELLANEOUS<br></br>‍<br></br>
            </strong>
            If any provision(s) and/or term(s) of this Terms of Service shall become and/or be declared illegal, invalid
            and/or unenforceable for any reason whatsoever, such term(s) and/or provision(s) shall be divisible from the
            other terms and conditions and shall be deemed to be deleted from it.<strong> </strong>This Terms of Service
            shall be governed in all respects by the laws of the State of Colorado, without reference to its choice of
            law rules. If an applicable law is in conflict with any part of this Terms of Service, this Terms of Service
            will be deemed modified to conform to the law and the other provisions will not be affected by any such
            modification. No waiver by either you and/or Onward of any breach and/or default and/or failure to exercise
            any right allowed under this Terms of Service is a waiver of any preceding and/or subsequent breach and/or
            default and/or waiver and/or forfeiture of any similar and/or future rights under this Terms of Service. All
            provisions of this Terms of Service which by their nature should survive termination shall survive
            termination, including, without limitation, content provisions, payment provisions, ownership provisions,
            warranty disclaimers, indemnity and limitations of liability. You may not assign your rights and/or
            obligations under this Terms of Service to any other party without our prior written consent. We may assign
            our rights and/or obligations under this Terms of Service to any other party at our discretion. This Terms
            of Service along with the Privacy Notice and any applicable Order Form, constitute the complete and
            exclusive understanding and agreement between the parties regarding the subject matter herein and supersedes
            all prior or contemporaneous agreements or understandings written or oral, relating to its subject matter.
            <br></br>
            <br></br>‍
            <strong>
                CONTACT US<br></br>
                <br></br>‍
            </strong>
            If you would like to request additional information regarding this Terms of Service or have an inquiry or
            feedback about our Services, please contact us at{' '}
            <a href="mailto:legal@onwarddelivery.com">legal@onwarddelivery</a>.com.
        </p>
    );

    return (terms);
};

export default TermsAndConditions;
