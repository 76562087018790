import React, { useMemo } from 'react';
import { useClientUser, useOrderCarrierPricing, useOrderShipperPricing } from '@/hooks';
import { Grid, Typography } from '@material-ui/core';
import { OnwardCard } from '../OrderDetailsPage/Card';
import { isAfter } from 'date-fns';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { toNational } from '@/utilities/formatPhoneNumber';

const NotificationCard = ({ listing, order, selectedBidDetails }) => {
    const { user_id } = useClientUser();

    // Don't display pre-bids to shippers.
    const shipperBids = useMemo(() => {
        if (listing.listing_status === 'pending') {
            return [];
        }
        return listing?.bids || [];
    }, [listing]);

    const shipperRate = useOrderShipperPricing(order);
    const carrierRate = useOrderCarrierPricing(order);

    const unreadCounters = useMemo(() => {
        const bids = selectedBidDetails?.isCarrier ? [selectedBidDetails] : shipperBids;
        let unread = 0;

        if (selectedBidDetails?.isCarrier) {
            bids[0].counter_offers.forEach((counter) => {
                if (
                    isAfter(new Date(counter.created_at), new Date(selectedBidDetails.lastViewedCarrier)) &&
                    counter.client_id !== user_id
                ) {
                    unread++;
                }
            });
        } else {
            bids.forEach((bid) =>
                bid.counter_offers.forEach((counter) => {
                    if (
                        isAfter(new Date(counter.created_at), new Date(bid.last_viewed_shipper)) &&
                        counter.client_id !== user_id
                    ) {
                        unread++;
                    }
                })
            );
        }

        return unread;
    }, [listing]);

    const unreadComments = useMemo(() => {
        const bids = selectedBidDetails?.isCarrier ? [selectedBidDetails] : shipperBids;
        let unread = 0;

        if (selectedBidDetails?.isCarrier) {
            bids[0].bid_comments.forEach((counter) => {
                if (
                    isAfter(new Date(counter.created_at), new Date(counter.last_viewed_carrier)) &&
                    counter.client_id !== user_id
                ) {
                    unread++;
                }
            });
        } else {
            bids.forEach((bid) =>
                bid.bid_comments.forEach((comment) => {
                    if (
                        isAfter(new Date(comment.created_at), new Date(bid.last_viewed_shipper)) &&
                        comment.client_id !== user_id
                    ) {
                        unread++;
                    }
                })
            );
        }

        return unread;
    }, [listing]);

    if (listing.listing_status === 'claimed' && order.order_carrier && !selectedBidDetails?.isCarrier) {
        return (
            <OnwardCard style={{ marginTop: '40px', backgroundColor: '#59B863' }}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={1}>
                        <CelebrationOutlinedIcon fontSize="large" style={{ color: 'white' }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography className="my-2" style={{ color: 'white' }} variant="body1">
                            Congrats! This order has been sent off to the winning carrier.
                        </Typography>
                        <Typography className="my-2" style={{ color: 'white' }} variant="body2">
                            {`Final Price: ${shipperRate || '--'}`}
                        </Typography>
                        <Typography className="my-2" style={{ color: 'white' }} variant="body2">
                            {`Winning Carrier: ${order.order_carrier.business_name}`}
                        </Typography>
                        <Typography className="my-2" style={{ color: 'white' }} variant="body2">
                            {`Phone: ${toNational(
                                (shipperBids || []).find((bid) => bid.bid_status === 'succeeded')?.carrier_phone ||
                                    order?.order_carrier?.business_phone ||
                                    '--'
                            )}`}
                        </Typography>
                        <Typography className="my-2" style={{ color: 'white' }} variant="body2">
                            {`Email: ${
                                (shipperBids || []).find((bid) => bid.bid_status === 'succeeded')?.carrier_email ||
                                order?.order_carrier?.email ||
                                '--'
                            }`}
                        </Typography>
                        <Typography className="my-2" style={{ color: 'white' }} variant="body2">
                            {`Address: ${order.order_carrier.business_address}, ${order.order_carrier.business_city}, ${order.order_carrier.business_state}, ${order.order_carrier.business_zip}`}
                        </Typography>
                    </Grid>
                </Grid>
            </OnwardCard>
        );
    }
    if (listing.listing_status === 'claimed' && order.order_carrier && selectedBidDetails?.isCarrier) {
        return (
            <OnwardCard style={{ marginTop: '40px', backgroundColor: '#59B863' }}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={1}>
                        <CelebrationOutlinedIcon fontSize="large" style={{ color: 'white' }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography className="my-2" style={{ color: 'white' }} variant="body1">
                            Congrats! You have been accepted to delivery this order.
                        </Typography>
                        <Typography className="my-2" style={{ color: 'white' }} variant="body2">
                            {`Final Price: ${carrierRate || '--'}`}
                        </Typography>
                        <Typography className="my-2" style={{ color: 'white' }} variant="body2">
                            {`You can find this order on the unassigned page to add it to a route, or in the "Claimed" tab on mobile.`}
                        </Typography>
                    </Grid>
                </Grid>
            </OnwardCard>
        );
    }

    if (unreadCounters || unreadComments) {
        return (
            <OnwardCard style={{ marginTop: '40px', backgroundColor: '#59B863' }}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={1}>
                        <NotificationImportantIcon fontSize="large" style={{ color: 'white' }} />
                    </Grid>
                    <Grid item xs={11}>
                        {unreadCounters ? (
                            <Typography className="my-2" style={{ color: 'white' }} variant="body1">
                                {`You've received ${unreadCounters} new counter offer${unreadCounters > 1 ? 's' : ''}.`}
                            </Typography>
                        ) : null}
                        {unreadComments ? (
                            <Typography className="my-2" style={{ color: 'white' }} variant="body1">
                                {`You've received ${unreadComments} new comment${unreadComments > 1 ? 's' : ''}.`}
                            </Typography>
                        ) : null}
                    </Grid>
                </Grid>
            </OnwardCard>
        );
    }
};

export default NotificationCard;
