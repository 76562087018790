import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import withAdminRights from '../../Auth/withAdminRights';
import { useNavigate } from 'react-router-dom';
import { useSubscription, useMutation, useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FULL_QUERY as ROUTE_QUERY, QUERY_BY_ID as ROUTE_BY_ID, QUERY_SEARCH } from '@/graphql/queries/routes';
import { ROUTES_SUBSCRIPTION } from '@/graphql/subscriptions/routes';
import { QUERY_BY_STATE } from '@/graphql/queries/orders';
import Skeleton from '@material-ui/lab/Skeleton';
import dateFns from '@/utilities/dateFns';
import AdminFTLFilterButton from './AdminFTLFilterButton';
import { Grid } from '@material-ui/core';
import './style.css';
import withErrorBoundary from '../../ErrorBoundary';
import { asDateInTZ } from '@/utilities/convertToISO';

const statesMap = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    NewHampshire: 'NH',
    NewJersey: 'NJ',
    NewMexico: 'NM',
    NewYork: 'NY',
    NorthCarolina: 'NC',
    NorthDakota: 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    RhodeIsland: 'RI',
    SouthCarolina: 'SC',
    SouthDakota: 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    WestVirginia: 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
};

const columns = [
    {
        label: 'Route #',
        key: 'route_number',
        value: (route) => (route?.route_number ? route.route_number : '--'),
    },
    {
        label: '# of Stops',
        key: 'route.stopsByRouteId.length',
        value: (route) => (route.stopsByRouteId ? route.stopsByRouteId.length : 0),
    },
    {
        label: '# of Drop-offs',
        key: 'stopsByRouteId.dropoffs.length',
        value: (route) => {
            if (!route.stopsByRouteId) {
                return 0;
            }
            let totalDropoffs = 0;
            route.stopsByRouteId.forEach((stop) => {
                if (stop.type === 'DROPOFF') {
                    totalDropoffs += 1;
                }
            });
            return totalDropoffs;
        },
    },
    {
        label: 'Cu. ft.',
        key: 'total_cubes',
        value: (route) => (route?.total_cubes > 0 ? route.total_cubes.toFixed(2) : '--'),
    },
    {
        label: 'ShipRate',
        key: 'total_shipper_rate',
        value: (route) =>
            route.total_shipper_rate && route.total_shipper_rate !== 'TBD'
                ? '$' + route.total_shipper_rate.toFixed(2)
                : 'TBD',
    },
    {
        label: 'Shipper',
        key: 'shipper',
        value: (route) =>
            route.route_shipper && route.route_shipper?.business_name && route.route_shipper?.business_name !== ''
                ? route.route_shipper.business_name
                : '--',
    },
    {
        label: 'Carrier',
        key: 'carrier',
        value: (route) =>
            route.route_carrier && route.route_carrier.business_name && route.route_carrier.business_name !== ''
                ? route.route_carrier.business_name
                : '--',
    },
    {
        label: 'Delivery Date',
        key: 'scheduled_delivery',
        value: (route) => {
            if (route.scheduled_delivery) {
                return dateFns.formatDate(
                    asDateInTZ(route.scheduled_delivery, 'America/New_York').toISOString(),
                    'MM/dd/yyyy'
                );
            }
            return '--';
        },
    },
    {
        label: 'Returns Next Day',
        key: 'finish_returns_next_day',
        value: (route) =>
            route.finish_returns_next_day !== undefined ? (route.finish_returns_next_day ? 'Yes' : 'No') : '--',
        tabs: ['In Progress'],
    },
    {
        label: 'Route Status',
        key: 'routeStatus',
        value: (route) => route.status,
    },
    {
        label: 'Type',
        key: 'type',
        value: (route) => (route.oms ? 'Internal' : 'Onward'),
        tabs: ['All'],
    },
];

const tabs = [
    { label: 'Planning', routes: 'planning' },
    { label: 'Pending', routes: 'pending' },
    { label: 'Open', routes: 'open' },
    { label: 'Active', routes: 'active' },
    { label: 'In Progress', routes: 'inProgress' },
    { label: 'Complete', routes: 'complete' },
    { label: 'In OMS', routes: 'all' },
    { label: 'All', routes: 'all' },
];

const INITIAL_ROUTES = {
    planning: [],
    pending: [],
    open: [],
    active: [],
    inProgress: [],
    complete: [],
    all: [],
};

function AdminFTLBoard(props) {
    const { firebase } = props;
    const yearmilliSecs = 365 * 24 * 60 * 60 * 1000;
    const navigate = useNavigate();
    const [dateFilter, setDateFilter] = useState('6/01/2022');
    const [tab, setTab] = useState(tabs.length - 1);
    const [isDisplayTestAccount, setIsDisplayTestAccount] = useState(false);
    const [routes, setRoutes] = useState(INITIAL_ROUTES);

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(true);
    const [orderBy, setOrderBy] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [remoteSearchInput, setRemoteSearchInput] = useState('');
    const [shipperFilter, setShipperFilter] = useState('');
    const [carrierFilter, setCarrierFilter] = useState('');
    const [stateDropdownOptions, setStateDropdownOptions] = useState([]);
    const [selectedStateFilter, setSelectedStateFilter] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterSubmit, setFilterSubmit] = useState(false);

    const testCondition = isDisplayTestAccount
        ? { test_acc: { _eq: true } }
        : { _or: [{ test_acc: { _eq: false } }, { test_acc: { _is_null: true } }] };
    const searchCondition = remoteSearchInput
        ? {
              route_number: { _eq: parseInt(remoteSearchInput) },
          }
        : {};
    const shipperFilterCondition =
        shipperFilter && filterSubmit
            ? {
                  route_shipper: { business_name: { _iregex: shipperFilter } },
              }
            : {};
    const carrierFilterCondition =
        carrierFilter && filterSubmit
            ? {
                  route_carrier: { business_name: { _iregex: carrierFilter } },
              }
            : {};

    const startDateFilterCondition =
        startDate && filterSubmit
            ? {
                  created_at: { _gte: new Date(startDate) },
              }
            : {};
    const endDateFilterCondition =
        endDate && filterSubmit
            ? {
                  created_at: { _lte: new Date(endDate) },
              }
            : {};
    const stateFilterCondition =
        selectedStateFilter && filterSubmit
            ? {
                  stopsByRouteId: {
                      address: { _iregex: ', ' + statesMap[selectedStateFilter.replace(/\s/g, '')] + ' ' },
                  },
              }
            : {};
    const {
        loading: isStateLoading,
        error: stateError,
        data: stateData,
    } = useSubscription(ROUTES_SUBSCRIPTION, {
        variables: {
            testCondition: testCondition,
            searchCondition: searchCondition,
            shipperFilterCondition: shipperFilterCondition,
            carrierFilterCondition: carrierFilterCondition,
            startDateFilterCondition: startDateFilterCondition,
            endDateFilterCondition: endDateFilterCondition,
            stateFilterCondition: stateFilterCondition,
        },
        onData: ({ data }) => {
            if (data.data.routes) {
                const newRoutes = data.data.routes;
                setRoutes(newRoutes);

                const routes = _.cloneDeep(INITIAL_ROUTES);
                newRoutes?.forEach((route) => {
                    const safeStatus = route?.status?.toLowerCase();
                    if (safeStatus === 'planning') {
                        routes.planning.push(route);
                    }
                    if (safeStatus === 'pending') {
                        routes.pending.push(route);
                    }
                    if (safeStatus === 'open') {
                        routes.open.push(route);
                    }
                    if (safeStatus === 'active') {
                        routes.active.push(route);
                    }
                    if (safeStatus === 'inprogress') {
                        routes.inProgress.push(route);
                    }
                    if (safeStatus === 'complete') {
                        routes.complete.push(route);
                    }
                    if (!['delete', 'deleted'].includes(safeStatus)) {
                        routes.all.push(route);
                    }
                    setRoutes(routes);
                });
            }

            setLoading(false);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const {
        loading: isShipperLoading,
        error: shipperError,
        data: shipperData,
    } = useQuery(QUERY_BY_STATE, {
        variables: {
            created_at: dateFilter,
        },
        onCompleted: (data) => {
            fillDateFilter(data.orders);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const getTabStatus = (tab) => tabs[tab].label;

    const renderRouteData = (route) => {
        if (!route) return;

        const tableData = [];

        columns.forEach((col) => {
            if (!col.tabs || col.tabs.includes(getTabStatus(tab))) {
                tableData.push(col.value(route));
            }
        });

        return (
            <>
                {tableData.map((data, index) => (
                    <TableCell key={index} align="center">
                        {data}
                    </TableCell>
                ))}
            </>
        );
    };

    const onSort = (key) => {
        if (orderBy !== key) {
            setOrderBy(key);
        }
        setOrder(!order);
    };
    const fillDateFilter = (data) => {
        let stateOptions = [];
        data.forEach((order) => {
            let formatState = order.dropoff_state[0].toUpperCase() + order.dropoff_state.slice(1);
            if (stateOptions.includes(formatState)) {
                return;
            } else {
                stateOptions.push(formatState.trim());
            }
        });
        setStateDropdownOptions(stateOptions);
    };

    const routesData = useMemo(() => {
        let data = routes[tabs[tab].routes];

        data = data?.filter((route) => {
            if (isDisplayTestAccount) return true;
            return !route.carrier || !route.carrier.test_acc;
        });

        if (orderBy) {
            if (orderBy === 'total_shipper_rate') {
                data = _.orderBy(
                    data,
                    function (o) {
                        return o.total_shipper_rate && o.total_shipper_rate !== 'TBD'
                            ? parseInt(o.total_shipper_rate)
                            : 0;
                    },
                    [order ? 'asc' : 'desc']
                );
            } else if (orderBy === 'carrier') {
                data = _.orderBy(
                    data,
                    (route) => {
                        return route.route_carrier &&
                            route.route_carrier.business_name &&
                            route.route_carrier.business_name !== ''
                            ? route.route_carrier.business_name
                            : null;
                    },
                    [order ? 'asc' : 'desc']
                );
            } else if (orderBy === 'shipper') {
                data = _.orderBy(
                    data,
                    (route) => {
                        return route.route_shipper &&
                            route.route_shipper.business_name &&
                            route.route_shipper.business_name !== ''
                            ? route.route_shipper.business_name
                            : null;
                    },
                    [order ? 'asc' : 'desc']
                );
            } else if (orderBy === 'scheduled_delivery') {
                data = _.orderBy(
                    data,
                    (order) => {
                        return order.scheduled_delivery ? new Date(order.scheduled_delivery) : new Date(null);
                    },
                    [order ? 'asc' : 'desc']
                );
            } else if (orderBy === 'routeStatus') {
                data = _.orderBy(
                    data,
                    (order) => {
                        return order.order_status ? order.order_status : null;
                    },
                    [order ? 'asc' : 'desc']
                );
            } else if (orderBy === 'stopsByRouteId.dropoffs.length') {
                data = _.orderBy(
                    data,
                    (order) => {
                        return order.stopsByRouteId && order.stopsByRouteId ? order.stopsByRouteId.length : 0;
                    },
                    [order ? 'asc' : 'desc']
                );
            } else if (orderBy === 'route.stopsByRouteId.length') {
                data = _.orderBy(
                    data,
                    (order) => {
                        return order.stopsByRouteId && order.stopsByRouteId ? order.stopsByRouteId.length : 0;
                    },
                    [order ? 'asc' : 'desc']
                );
            } else {
                data = _.orderBy(data, [orderBy], [order ? 'asc' : 'desc']);
            }
        }

        // If searchInput has any input, filter routesData based on searchInput
        if (searchInput !== '') {
            data = data?.filter((route) => {
                return route.route_number ? route.route_number.toString().includes(searchInput) : false;
            });
            if (data?.length === 0) {
                //if no routes are found, query the database for the route number
                setRemoteSearchInput(searchInput);
            }
        }

        // Filter out routes that are still in a shipper's oms
        if (tabs[tab].label === 'In OMS') {
            data = data.filter((route) => route.oms);
        }

        return data;
    }, [
        orderBy,
        order,
        routes,
        tab,
        searchInput,
        isDisplayTestAccount,
        shipperFilter,
        carrierFilter,
        selectedStateFilter,
        startDate,
        endDate,
    ]);

    const selectRoute = (route) => {
        navigate('/admin/editftlroute/' + route.route_id);
    };

    // Scrub route number search input so that only numbers can be entered.
    const scrubRouteNumberSearchInput = (e) => {
        const input = e.target.value;
        if (/[0-9]/.test(input) || input === '') {
            setSearchInput(input);
            setRemoteSearchInput('');
        }
    };

    return (
        <div className={`'collapse-margin'} sidenav-margin-responsive`}>
            <Grid container direction="row">
                <Grid item xs={12} md={5} lg={5} className="admin-ftl-header">
                    <Typography align="center" variant="h4" style={{ fontWeight: 600 }}>
                        Full Truckload Routes
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={4} className="search-input-container">
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={isDisplayTestAccount}
                                onChange={() => setIsDisplayTestAccount((prevState) => !prevState)}
                            />
                        }
                        label="Show Test Accounts"
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={2} className="search-input-container">
                    <TextField
                        id="search-input-textfield"
                        label="Search (by Route #)"
                        variant="outlined"
                        size="small"
                        value={searchInput}
                        onChange={scrubRouteNumberSearchInput}
                        // helperText="Search by route number"
                    />
                </Grid>
                <Grid item xs={12} md={1} lg={1} className="search-input-container">
                    <AdminFTLFilterButton
                        shipperFilter={shipperFilter}
                        setShipperFilter={setShipperFilter}
                        carrierFilter={carrierFilter}
                        setCarrierFilter={setCarrierFilter}
                        stateDropdownOptions={stateDropdownOptions}
                        selectedStateFilter={selectedStateFilter}
                        setSelectedStateFilter={setSelectedStateFilter}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setFilterSubmit={setFilterSubmit}
                        filterSubmit={filterSubmit}
                    />
                </Grid>
            </Grid>

            <Paper className={`MuiTable-root`} style={{ margin: '20px 40px 0 10px' }}>
                <Tabs
                    centered
                    textColor="primary"
                    indicatorColor="primary"
                    value={tab}
                    onChange={(event, newVal) => setTab(newVal)}
                >
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                    ))}
                </Tabs>
            </Paper>

            <Paper className={`MuiTable-root`} style={{ margin: '20px 40px 0 10px', minHeight: '322px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns
                                .filter((col) => !col.tabs || col.tabs.includes(getTabStatus(tab)))
                                .map((column, index) => (
                                    <TableCell
                                        key={index}
                                        style={{ width: '9%', whiteSpace: 'nowrap', overflowWrap: 'break-word' }}
                                        align="center"
                                    >
                                        {column.label}{' '}
                                        <span onClick={() => onSort(column.key)}>
                                            <FontAwesomeIcon icon={faSort} />
                                        </span>
                                    </TableCell>
                                ))}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {loading ? (
                            [...Array(5)].map((i, index) => (
                                <TableRow key={index}>
                                    <TableCell colSpan={columns.length + 1}>
                                        <Skeleton variant="text" />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : routesData?.length > 0 ? (
                            routesData.map((route, index) => (
                                <TableRow key={index}>
                                    {renderRouteData(route)}
                                    <TableCell style={{ width: '60px' }} align="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => selectRoute(route)}
                                            style={{ color: '#eee' }}
                                        >
                                            Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align="center" colSpan={columns.length + 1} rowSpan={5}>
                                    <Typography style={{ color: 'rgba(0,0,0,0.6)' }}>
                                        No {tabs[tab].label === 'All' ? '' : tabs[tab].label.toLowerCase()} routes found
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}

export default React.memo(compose(withAdminRights, withErrorBoundary)(AdminFTLBoard));
