import React, { useContext } from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton } from '../blocks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { css } from '@emotion/react';

const ExceededDailyTrucksModal = () => {
    const { modalOpen, setModalOpen } = useContext(PlanningContext);

    const handleModalClose = () => {
        setModalOpen(null);
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.DAILY_TRUCKS_EXCEEDED} onClose={handleModalClose}>
            <ModalTitle
                css={css`
                    border-bottom: none;
                `}
            >
                <GridItemRow
                    css={css`
                        background-color: #d23e3e;
                        border-radius: 5px;
                        padding: 1rem;
                        color: #ffffff;
                    `}
                >
                    <ReportProblemIcon css={css``} />
                    <span
                        css={css`
                            font-size: 16px;
                            flex-grow: 1;
                            padding-left: 1rem;
                            font-weight: 500;
                            text-wrap: wrap;
                        `}
                    >
                        {`Reminder: Your routes have exceeded the number of trucks that you have set as available for today.`}
                    </span>
                </GridItemRow>
            </ModalTitle>
            <ModalContent
                css={css`
                    font-size: 16px;
                    border: none;
                `}
            >
                <GridItemRow>
                    <span
                        css={css`
                            font-weight: 600;
                            font-size: 16px;
                            text-wrap: wrap;
                        `}
                    >
                        This is just a reminder.
                    </span>
                </GridItemRow>
                <br />
                <GridItemRow>
                    <span
                        css={css`
                            font-weight: 600;
                            font-size: 16px;
                            text-wrap: wrap;
                        `}
                    >
                        You are still able to continue creating and submitting routes as usual.
                    </span>
                </GridItemRow>
            </ModalContent>
            <ModalActions
                css={css`
                    border-top: none;
                    justify-content: end;
                `}
            >
                <PrimaryButton
                    css={css`
                        justify-content: center;
                        width: 128px;
                    `}
                    onClick={handleModalClose}
                >
                    Okay
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default ExceededDailyTrucksModal;
