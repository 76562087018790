import React, { useEffect } from 'react';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Tooltip } from '@material-ui/core';
import { createRoot } from 'react-dom/client';

export const controlContainer = document.createElement('div');
const id = 'clear-order-control-container';
controlContainer.setAttribute('id', id);
const containerRoot = createRoot(controlContainer);

const ClearOrderControlContent = ({ callbacks }) => {
    return (
        <Tooltip title="Clear Selected Pins" placement="top">
            <div
                css={css`
                    margin: 10px;
                    width: 40px;
                    height: 40px;
                    background-color: ${colors.greens.primary};
                    color: ${colors.white};
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                `}
                onClick={callbacks.onClick}
            >
                <BackspaceIcon />
            </div>
        </Tooltip>
    );
};

export const ClearOrderControl = ({ mapRef, callbacks }) => {
    useEffect(() => {
        if (mapRef?.current) {
            const controls = mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP];

            controls.push(controlContainer);
            containerRoot.render(<ClearOrderControlContent callbacks={callbacks} />);

            return () => {
                const idx = controls.indexOf((el) => el.id === id);
                if (idx >= 0) {
                    controls.removeAt(idx);
                }
            };
        }
    }, [mapRef?.current]);

    return null;
};
