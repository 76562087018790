import React from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';

function Footer({ state, loading, callbacks }) {
    const { status } = state?.filter || {};
    const isDisabled = state.selected.length === 0;
    const { exportCsv, setApproved, setPaid } = callbacks;

    return (
        <>
            <Grid
                container
                css={css`
                    padding: 1rem;
                    background-color: white;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-right: 16px;
                    `}
                >
                    <PrimaryButton loading={loading.exportCsv} onClick={exportCsv} disabled={isDisabled}>
                        Export
                    </PrimaryButton>
                </Grid>
                {status === 'SUBMITTED' && (
                    <Grid
                        item
                        css={css`
                            margin-right: 16px;
                        `}
                    >
                        <PrimaryButton loading={loading.marked} onClick={setApproved} disabled={isDisabled}>
                            Approve
                        </PrimaryButton>
                    </Grid>
                )}
                {status === 'APPROVED' && (
                    <Grid
                        item
                        css={css`
                            margin-right: 16px;
                        `}
                    >
                        <PrimaryButton loading={loading.paid} onClick={setPaid} disabled={isDisabled}>
                            Mark as Paid
                        </PrimaryButton>
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default React.memo(Footer);
