import React, { useState, useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';

import Footer from './Footer';
import { TextField } from '../blocks';

const REQUIRED_FIELDS = ['dot_number'];

function CarrierNumber({ state, callbacks }) {
    const [section, setSection] = useState(state.sections[state.progression.current] || {});
    const [isDirty, setDirty] = useState({});

    const isEmpty = useCallback(
        (field) => {
            const invalid = REQUIRED_FIELDS.includes(field) && (!section[field] || section[field].length === 0);
            return invalid;
        },
        [section]
    );

    const hasError = useMemo(() => {
        return REQUIRED_FIELDS.some((field) => isEmpty(field));
    }, [isEmpty]);

    return (
        <>
            {[
                { display: 'Motor Carrier Number (optional)', value: 'mc_num' },
                { display: 'DOT Number', value: 'dot_number' },
            ].map(({ display, value }) => (
                <Grid
                    key={value}
                    container
                    css={css`
                        margin-bottom: 16px;
                    `}
                >
                    <TextField
                        display={display}
                        value={value}
                        state={section}
                        isDirty={isDirty}
                        callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                    />
                </Grid>
            ))}

            <Footer
                state={state}
                isFirst={state.progression.current === 0}
                isLast={state.progression.current === state.progression.stages.length - 1}
                hasErrors={hasError}
                callbacks={{
                    advance: () => callbacks.advance(section),
                    revert: callbacks.revert,
                }}
            />
        </>
    );
}

export default CarrierNumber;
