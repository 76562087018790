import React, { useState, useMemo } from 'react';
const { setDay, setWeek, startOfWeek, endOfWeek } = require('date-fns');
import { startCase } from 'lodash';
import { colors } from '@/styles';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { CheckCircleOutline, HelpOutline, HighlightOffOutlined, SyncOutlined } from '@material-ui/icons';
import { asBrowserDate } from '@/utilities/convertToISO';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import { BodyText } from '@/components/CarrierAccountingOrders/blocks';
import { FILTERS } from './constants';

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const useColumns = ({ callbacks, filter }) => {
    return useMemo(() => {
        return [
            {
                Header: 'Data',
                id: 'data-columns',
                columns: [
                    {
                        Header: 'Invoice',
                        id: 'invoice_number',
                        accessor: (invoice) => invoice.invoice_number || '--',
                    },
                    {
                        Header: 'Carrier',
                        id: 'business_name',
                        span: false,
                        accessor: (invoice) => invoice.client?.business_name,
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Week',
                        id: 'week_number',
                        accessor: (invoice) => invoice.week_number,
                    },
                    {
                        Header: 'Pay Period',
                        id: 'period',
                        span: false,
                        accessor: (invoice) => {
                            let created = new Date(new Date().setHours(0, 0, 0, 0));
                            if (invoice.created_at) {
                                created = new Date(new Date(invoice.created_at).setHours(0, 0, 0, 0));
                            }
                            const week = setWeek(created, invoice.week_number, { weekStartsOn: 1 });
                            const sow = startOfWeek(week, { weekStartsOn: 1 });
                            const eow = endOfWeek(week, { weekStartsOn: 1 });

                            return `${dateNumeric.format(sow)} - ${dateNumeric.format(eow)}`;
                        },
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Edits Due',
                        id: 'edits_due',
                        span: false,
                        accessor: (invoice) => {
                            let created = new Date(new Date().setHours(0, 0, 0, 0));
                            if (invoice.created_at) {
                                created = new Date(new Date(invoice.created_at).setHours(0, 0, 0, 0));
                            }
                            const due = setWeek(created, invoice.week_number + 1, { weekStartsOn: 1 });
                            const thurs = setDay(due, 4);

                            if (!invoice.is_pending) {
                                return `${dateNumeric.format(new Date(thurs))} (submitted)`;
                            }

                            return dateNumeric.format(thurs);
                        },
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Due',
                        id: 'due_date',
                        width: 150,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            return invoice.due_date ? dateNumeric.format(asBrowserDate(invoice.due_date)) : '--';
                        },
                    },
                    {
                        Header: 'POD',
                        id: 'pod',
                        width: 110,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const isMissing = !invoice.shipper_orders.every((order) => {
                                const hasPhoto = order.delivery_photo?.length >= 1 || order.pod?.length >= 1;
                                const hasSignature = order.customer_signature?.length >= 1;
                                return hasSignature || hasPhoto;
                            });

                            return !isMissing ? (
                                <>
                                    <DoneIcon
                                        css={css`
                                            color: ${colors.greens.primary};
                                        `}
                                    />
                                </>
                            ) : (
                                <CloseIcon
                                    css={css`
                                        color: ${colors.reds.primary};
                                        background: none;
                                    `}
                                />
                            );
                        },
                    },
                    {
                        Header: 'Status',
                        id: 'status',
                        width: 150,
                        span: false,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const label = startCase(invoice.status.toLowerCase());
                            let Icon = HelpOutline;
                            let color = 'black';
                            switch (invoice.status) {
                                case 'PAID':
                                    color = colors.greens.primary;
                                    Icon = CheckCircleOutline;
                                    break;
                                case 'APPROVED':
                                    color = colors.greens.primary;
                                    Icon = CheckCircleOutline;
                                    break;
                                case 'UNPAID':
                                case 'CANCELLED':
                                    color = colors.reds.cancelled;
                                    Icon = HighlightOffOutlined;
                                    break;
                                case 'PROCESSING':
                                    color = colors.oranges.primary;
                                    Icon = SyncOutlined;
                                    break;
                                default:
                                    color = 'black';
                                    Icon = HelpOutline;
                                    break;
                            }

                            return (
                                <Grid
                                    container
                                    direction="row"
                                    css={css`
                                        color: ${color};
                                        height: 100%;
                                        align-items: center;
                                        flex-wrap: nowrap;
                                    `}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 0;
                                            flex-basis: 0;
                                            margin-right: 4px;
                                        `}
                                    >
                                        <Icon />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 0;
                                            flex-basis: 0;
                                        `}
                                    >
                                        <BodyText>{label}</BodyText>
                                    </Grid>
                                </Grid>
                            );
                        },
                    },
                    {
                        Header: 'Subtotal',
                        id: 'subtotal',
                        width: 125,
                        canSort: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const amount = (invoice.shipper_orders || []).reduce((acc, order) => {
                                const breakdown = 'shipperBreakdown';

                                const total = FIXED_CHARGES.map(({ key: attr }) => {
                                    return order?.price_breakdown?.[breakdown]?.[attr] || 0;
                                }).reduce((acc, val) => {
                                    return acc + val;
                                }, 0);

                                return acc + total;
                            }, 0);
                            return dollarFormatter.format(amount);
                        },
                    },
                    {
                        Header: 'Accessorials',
                        id: 'accessorials',
                        width: 125,
                        canSort: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const amount = (invoice.shipper_orders || []).reduce((acc, order) => {
                                const breakdown = 'shipperBreakdown';

                                const total = (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce(
                                    (acc, { quantity, rate }) => {
                                        return acc + quantity * rate;
                                    },
                                    0
                                );

                                return acc + total;
                            }, 0);

                            return dollarFormatter.format(amount);
                        },
                    },
                    {
                        Header: 'Total Due',
                        id: 'revenue',
                        width: 125,
                        canSort: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const amount = (invoice.shipper_orders || []).reduce((acc, order) => {
                                return acc + (order?.shipper_rate || 0);
                            }, 0);
                            return dollarFormatter.format(amount);
                        },
                    },
                ],
            },
            {
                sticky: 'right',
                Header: 'Sticky',
                id: 'sticky-group',
                columns: [
                    {
                        Header: '',
                        id: 'action-1',
                        width: 45,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const [anchorEl, setAnchorEl] = React.useState(null);

                            const handleClick = (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setAnchorEl(event.currentTarget);
                            };

                            const handleClose = (event) => {
                                event?.stopPropagation();
                                event?.preventDefault();
                                setAnchorEl(null);
                            };

                            return (
                                <div
                                    css={css`
                                        color: #59b863;
                                        font-weight: 600;
                                        display: flex-start;
                                        justify-content: flex-start;
                                        align-items: center;
                                    `}
                                >
                                    <IconButton
                                        disabled={invoice.status === 'PAID'}
                                        onClick={handleClick}
                                        css={css`
                                            font-weight: 600;
                                            padding: 4px;
                                            justify-content: center;
                                        `}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        style={{ padding: '10px', borderRadius: '20px' }}
                                    >
                                        <Grid direction="column" container>
                                            <Grid direction="row" container>
                                                <Button
                                                    disabled={filter.status !== FILTERS.APPROVED}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        callbacks.payId(invoice.carrier_invoice_id);
                                                        handleClose();
                                                    }}
                                                    css={css`
                                                        width: 100%;
                                                        justify-content: flex-start;
                                                    `}
                                                >
                                                    Mark as Paid
                                                </Button>
                                            </Grid>
                                            <Grid direction="row" container>
                                                <Button
                                                    disabled={filter.status !== FILTERS.SUBMITTED}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        callbacks.approveId(invoice.carrier_invoice_id);
                                                        handleClose();
                                                    }}
                                                    css={css`
                                                        width: 100%;
                                                        justify-content: flex-start;
                                                    `}
                                                >
                                                    Approve
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Popover>
                                </div>
                            );
                        },
                        span: false,
                    },
                ],
            },
        ];
    }, [callbacks, filter]);
};
