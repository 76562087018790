import { gql } from '@apollo/client';
import { LISTING_FIELDS } from '../queries/listings';

export const UPDATE_LISTING_BY_ID = gql`
    ${LISTING_FIELDS}

    mutation UpdateListingById($listing_id: uuid!, $update: listings_set_input = {}) {
        update_listings_by_pk(pk_columns: { listing_id: $listing_id }, _set: $update) {
            ...ListingFields
        }
    }
`;
