import React, { useState, useMemo, useEffect } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { css } from '@emotion/react';
import { genAttributes } from '@onward-delivery/core';
import { OnwardSwitch, SecondaryButton } from '@/styles/blocks';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { ResponsiveSidebarDialog, StickyModalActions, ModalContent, ModalTitle, PrimaryButton } from '@/styles/blocks';

import { Header2, Header1, DateTimePicker, DateTimeRangePicker, Tag, Body1 } from '../Crossdocking/blocks';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import PhoneNumberInput from '@/components/PhoneNumberInput';

const REQUIRED_FIELDS = ['est_dropoff_date_end', 'est_dropoff_date_start', 'est_pickup_date'];

const CrossdockPickupConfigModal = ({ event, order, drivers, action, callbacks }) => {
    const { zip } = genAttributes(order);

    const [tempDriver, setTempDriver] = useState(false);
    const [updates, setUpdates] = useState({});
    const [sendConfirm, setSendConfirm] = useState(false);

    useEffect(() => {
        if (event?.driver_name || event?.driver_phone) {
            setTempDriver(true);
        }
    }, [event]);

    const defaults = useMemo(() => {
        return {
            est_pickup_date: order.preferred_delivery_date,
            est_dropoff_date_start: order.del_window_start || order.preferred_delivery_date,
            est_dropoff_date_end: order.del_window_end || order.preferred_delivery_date,
        };
    }, [order]);

    const updated = useMemo(() => {
        return {
            ...defaults,
            ...(event || {}),
            ...updates,
        };
    }, [updates, event, defaults]);

    const tz = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : 'America/New_York';
    }, [order, zip]);

    const isComplete = useMemo(() => {
        return ![...REQUIRED_FIELDS, ...(tempDriver ? ['driver_name', 'driver_phone'] : ['driver_id'])].some(
            (field) => {
                return !updated[field] || updated[field].length === 0;
            }
        );
    }, [updated, tempDriver]);

    return (
        <ResponsiveSidebarDialog open={!!event} onClose={callbacks.onClose} maxWidth="lg">
            <ModalTitle onClose={callbacks.onClose}>
                <Header2
                    css={css`
                        color: #4c4c4c;
                    `}
                >
                    Update Pickup and Drop off
                </Header2>
            </ModalTitle>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                <Grid direction="row" container>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 12px;
                            `}
                        >
                            <Header1>Pickup</Header1>
                        </Grid>
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 20px;
                            `}
                        >
                            <DateTimePicker
                                tz={tz}
                                timestamp={updated?.est_pickup_date}
                                onChange={(date) => {
                                    const corrected = date.toISOString();

                                    setUpdates((prev) => ({
                                        ...prev,
                                        est_pickup_date: corrected,
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            justify-content: center;
                            margin: 0 20px;
                        `}
                    >
                        <ArrowForwardIcon />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 12px;
                            `}
                        >
                            <Header1>Dropoff</Header1>
                        </Grid>
                        <Grid
                            direction="row"
                            container
                            css={css`
                                flex-wrap: nowrap;
                            `}
                        >
                            <DateTimeRangePicker
                                tz={tz}
                                range={[updated?.est_dropoff_date_start, updated?.est_dropoff_date_end]}
                                onChange={([start, end]) => {
                                    setUpdates((prev) => ({
                                        ...prev,
                                        est_dropoff_date_start: start.toISOString(),
                                        est_dropoff_date_end: end.toISOString(),
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid direction="column" container>
                    <OnwardSwitch
                        label={<Header1>New Driver</Header1>}
                        checked={tempDriver}
                        onChange={() => setTempDriver((prev) => !prev)}
                    />
                    <Grid direction="row" container>
                        {tempDriver ? (
                            <>
                                <TextField
                                    variant="outlined"
                                    label="Driver Name"
                                    value={updated?.driver_name || ''}
                                    onChange={(e) => {
                                        setUpdates((prev) => ({
                                            ...prev,
                                            driver_id: null,
                                            driver_name: e.target.value,
                                        }));
                                    }}
                                    css={css`
                                        width: 240px;
                                    `}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Driver Phone"
                                    InputProps={{
                                        inputComponent: PhoneNumberInput,
                                    }}
                                    value={toNational(updated?.driver_phone || '')}
                                    onChange={(e) => {
                                        setUpdates((prev) => ({
                                            ...prev,
                                            driver_id: null,
                                            driver_phone: toE164(e.target.value),
                                        }));
                                    }}
                                    css={css`
                                        margin-left: 12px;
                                        width: 160px;
                                    `}
                                />
                            </>
                        ) : (
                            <TextField
                                select
                                variant="outlined"
                                label="Driver"
                                value={updated?.driver_id || ''}
                                onChange={(e) => {
                                    setUpdates((prev) => ({
                                        ...prev,
                                        driver_id: e.target.value,
                                        driver_name: null,
                                        driver_phone: null,
                                    }));
                                }}
                                css={css`
                                    width: 240px;
                                `}
                            >
                                {drivers.map((option) => (
                                    <MenuItem key={option.teammate_id} value={option.teammate_id}>
                                        {option.username}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        <OnwardSwitch
                            css={css`
                                margin-left: 30px;
                            `}
                            label={<Body1>Send customer text to confirm drop off date & time</Body1>}
                            checked={sendConfirm}
                            onChange={() => setSendConfirm((prev) => !prev)}
                        />
                    </Grid>
                </Grid>
                <Tag
                    css={css`
                        width: 700px;
                        margin-top: 20px;
                    `}
                >
                    <Body1>{`Customer Preferred Delivery Date: ${
                        order.preferred_delivery_date_formatted || 'N/A'
                    }`}</Body1>
                    <br />
                    <Body1>{`Customer Alternative Delivery Dates: ${
                        order.alternative_delivery_dates ? order.alternative_delivery_dates_formatted.join(', ') : 'N/A'
                    }`}</Body1>
                </Tag>
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton
                        disabled={!isComplete}
                        onClick={() => {
                            const { est_dropoff_date_start, est_dropoff_date_end, ...rest } = {
                                ...defaults,
                                ...updates,
                            };
                            let rv = rest;

                            const adjustTZ = (ts) => {
                                const utc = new Date(asUTCDate(ts).setUTCHours(0, 0, 0, 0)).toISOString();
                                return asDateInTZ(utc, tz).toISOString();
                            };

                            if (event.event_id) {
                                rv = {
                                    ...rv,
                                    event_id: event.event_id,
                                    order_id: order.order_id,
                                    action: event.action,
                                };
                            } else {
                                rv = { ...rv, order_id: order.order_id, action };
                            }

                            setUpdates({});

                            callbacks.onSubmit({
                                event: rv,
                                order: {
                                    ...(rv.est_pickup_date ? { pickup_date: adjustTZ(rv.est_pickup_date) } : {}),
                                    ...(rv.driver_id ? { driver_id: rv.driver_id } : {}),
                                    ...(rv.driver_name ? { driver_id: null } : {}),
                                    ...(est_dropoff_date_start
                                        ? {
                                              del_window_start: est_dropoff_date_start,
                                              delivery_date: adjustTZ(est_dropoff_date_start),
                                          }
                                        : {}),
                                    ...(est_dropoff_date_end ? { del_window_end: est_dropoff_date_end } : {}),
                                },
                                sendConfirm: sendConfirm,
                            });
                        }}
                    >
                        Done
                    </PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default CrossdockPickupConfigModal;
