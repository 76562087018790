import { Box, Button, Chip, Grid, MenuItem, Popover, TextField, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React, { useState } from 'react';
import { useClientUser } from '@/hooks';

const FilterPopover = ({
    applyFilters,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    user_id,
    driverOptions
}) => {
    const [anchor, setAnchor] = useState(null);
    const [ownerChip, setOwnerChip] = useState('All');
    const [driver, setDriver] = useState(null);

    const open = !!anchor;
    const id = open ? 'myorders-popover-filter' : undefined;

    const handleClearFilters = () => {
        setOwnerChip('All');
        setStartDate(null);
        setEndDate(null);
        setDriver(null);

        applyFilters([]);
    };

    const handleApplyFilters = () => {
        let filters = [];

        if (ownerChip !== 'All') {
            switch (ownerChip) {
                case 'Internal':
                    // INTERNAL ORDERS
                    filters.push({ _and: [ 
                        { oms: { _eq: true } },
                        { shipper_id : { _eq: user_id } },
                        { _or: [
                            { carrier_id: { _is_null: true } },
                            { carrier_id: { _eq: user_id } }
                        ]}
                    ]})
                    break;
                case 'Onward':
                    // ORDERS SENT TO MARKETPLACE
                    filters.push({ _or: [
                        { _and: [ 
                        { oms: { _eq: false } },
                        { shipper_id : { _eq: user_id } },
                        { _or: [
                            { carrier_id: { _is_null: true } },
                            { carrier_id: { _neq: user_id } }
                        ]}
                    ]},
                        { _and: [
                            { shipper_id: { _eq: user_id } },
                            { carrier_id: { _is_null: false } },
                            { carrier_id: { _neq: user_id } },
                        ]}
                    ]})
                    break;
                case 'Claimed':
                    //ORDERS CLAIMED FROM MARKETPLACE
                    filters.push({ _or: [
                        { _and: [
                            { oms: { _eq: false } },
                            { carrier_id: { _eq: user_id } }
                        ]},
                        { _and: [
                            { shipper_id: { _neq: user_id } },
                            { carrier_id: { _eq: user_id } }
                        ]}
                    ]})
                    break;
            }
        }
        
        if (driver) {
            filters.push({ driver_id: { _eq: driver } });
        }

        applyFilters(filters);
    };

    const OwnerChip = ({ owner }) => {
        return (
            <Grid item className="pl-3">
                <Chip
                    label={owner}
                    color="primary"
                    variant={ownerChip === owner ? 'default' : 'outlined'}
                    onClick={(e) => setOwnerChip(e.target.innerText)}
                />
            </Grid>
        );
    };

    return (
        <>
            <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={(e) => setAnchor(e.currentTarget)}
            >
                <FilterList />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box className="bg-white p-3" style={{ border: 'solid 1px #4C4C4C', borderRadius: '4px' }}>
                    <Grid container xs={12} className="pb-4" justifyContent="flex-start" direction='column'>
                        <Grid container className="h100" alignContent="center" style={{ width: 'auto' }}>
                            <Typography>Owner</Typography>
                        </Grid>
                        <Grid container className="mt-1" spacing={1}>
                            <OwnerChip owner="All" />
                            <OwnerChip owner="Onward" />
                            <OwnerChip owner="Internal" />
                            <OwnerChip owner="Claimed" />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-4" justifyContent="flex-start" direction='column'>
                        <Grid item xs={12}>
                            <Typography>Scheduled Delivery</Typography>
                        </Grid>
                        <Grid container className="mt-1">
                            <Grid item className="pr-3">
                                <TextField
                                    type="date"
                                    variant="outlined"
                                    size="small"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Grid>
                            <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                                <Typography variant="h3" style={{ height: 'fit-content' }}>
                                    -
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    type="date"
                                    variant="outlined"
                                    size="small"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-4" wrap="nowrap" direction='column'>
                        <Grid item xs={12}>
                            <Typography>Driver</Typography>
                        </Grid>
                        <Grid item xs={12} className="mt-1">
                            <TextField 
                                select 
                                variant="outlined" 
                                value={driver} 
                                onChange={(e) => setDriver(e.target.value)} 
                                fullWidth
                            >
                                {(driverOptions || []).map((option) => (
                                        <MenuItem key={option.label} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                            <Button onClick={handleClearFilters}>Clear</Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" onClick={handleApplyFilters}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </>
    );
};

export default FilterPopover;
