import React from 'react';

import { Grid } from '@material-ui/core';

import styled from '@emotion/styled';
import { OnwardCard } from '../Card';

const StyleSpan = styled.span`
    font-family: Montserrat, Roboto, Arial;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
`;

const ItemDescription = styled.span`
    font-weight: 700;
`;
const ItemDetail = styled.span`
    font-weight: 500;
    font-size: 14px;
    margin-left: 0px;
`;

function HaulawaySingleOrder(item) {
    let itemDesc = item.description || 'Description Not Provided';
    let dimensions = '';
    let weight = '';

    // dimensionAsRanges doesn't seem to apply for haulaway items? Even when property is set as 'true'
    // if (item.dimensions_as_ranges) {
    //    weight = <span style={{'margin-left': '10px'}}>{item.weight[0]}-{item.weight[1]}lbs</span>

    //    dimensions = <>
    //       <span style={{'margin-left': '6px'}}>{item.length[0]}-{item.length[1]}L</span>
    //       <span style={{'margin-left': '6px'}}>{item.width[0]}-{item.width[1]}W</span>
    //       <span style={{'margin-left': '6px'}}>{item.height[0]}-{item.height[1]}H</span>
    //    </>

    // } else {

    weight = item.weight ? `${item.weight}lbs` :`N/A`;

    dimensions = item.length ? `${item.length}L ${item.width}W ${item.height}H` : `N/A`;

    let returnOrDispose =
        item.returnOrDispose === 'return'
            ? 'Return item(s)'
            : 'Dispose item(s)';

    return (
        <OnwardCard>
            <Grid
                container
                spacing={1}
                sx={{ 'border-bottom': '1px solid black' }}
                style={{ width: '100%' }}
            >
                <Grid
                    container
                    sm={12} 
                    direction="row"
                    className="justify-content-around"
                >
                    <Grid item sm={12} className="d-flex align-items-center">
                        <StyleSpan variant="body2">
                            <ItemDescription>{itemDesc}</ItemDescription>
                            
                        </StyleSpan>
                    </Grid>
                    <Grid item sm={12} className="d-flex align-items-center">
                        <ItemDetail>Quantity: {item.quantity || 'N/A'}</ItemDetail>
                    </Grid>
                    <Grid item sm={12} className="d-flex align-items-center">
                        <ItemDetail>Total Cubes: {item.total_cubes ? item.total_cubes.toFixed(1) : 'N/A'}</ItemDetail>
                    </Grid>
                    <Grid item sm={12} className="d-flex align-items-center">
                        <ItemDetail>Dimensions: {dimensions}</ItemDetail>
                    </Grid>
                    <Grid item sm={12} className="d-flex align-items-center">
                        <ItemDetail>Weight: {weight}</ItemDetail>
                    </Grid>
                    <Grid item sm={12} className="d-flex align-items-center">
                        <ItemDetail style={{ color: '#8D8D8D' }}>{returnOrDispose}</ItemDetail>
                    </Grid>
                </Grid>
                <Grid item md={4} style={{ textAlign: 'right' }}>
                    <div></div>
                </Grid>
            </Grid>
        </OnwardCard>
    );
}

export default HaulawaySingleOrder;
