import { useNavigate } from 'react-router';
import Notification from '..';
import { NotificationMetadata, NotificationTimestamp, NotificationTitle } from '../blocks';
import { format } from 'date-fns';

const CarrierAutoAssignedOrderByZipNotification = ({ notification, handleCloseNotification }) => {
    const navigate = useNavigate();

    return (
        <Notification
            notification={notification}
            onClickHandler={() => {
                navigate(`/order/${notification.notification_data?.order_id}`);
                handleCloseNotification();
            }}
        >
            <NotificationTitle>Order Assigned to You</NotificationTitle>
            <NotificationMetadata>An order has been assigned to you from the Onward Marketplace</NotificationMetadata>
            <NotificationMetadata>Order: {notification.notification_data?.order_number || 'N/A'}</NotificationMetadata>
            <NotificationTimestamp>
                {format(new Date(notification.created_at), 'MMM dd, yyyy @ hh:mm a')}
            </NotificationTimestamp>
        </Notification>
    );
};

export default CarrierAutoAssignedOrderByZipNotification;
