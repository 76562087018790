import { gql } from '@apollo/client';

import { RESOURCE_OVERRIDE_MAPPING_FIELDS } from '@/graphql/queries/resource_override_mappings';
import { RESOURCE_REGION_MAPPING_FIELDS } from '@/graphql/queries/resource_region_mappings';
import { RESOURCE_RULE_MAPPING_FIELDS } from '@/graphql/queries/resource_rule_mappings';
import { SHIPPING_PARTNER_FIELDS } from '@/graphql/fragments/shipping_partners';
import { RESOURCE_FIELDS } from '@/graphql/queries/resources';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const UPSERT_RESOURCES = gql`
    ${RESOURCE_OVERRIDE_MAPPING_FIELDS}
    ${RESOURCE_RULE_MAPPING_FIELDS}
    ${RESOURCE_REGION_MAPPING_FIELDS}
    ${RESOURCE_FIELDS}
    ${CLIENT_FIELDS}

    mutation UpsertResources(
        $resources: [resources_insert_input!]!
        $regions: [resource_region_mappings_insert_input!]!
        $region_removals: [uuid!]!
        $rules: [resource_rule_mappings_insert_input!]!
        $rule_removals: [uuid!]!
        $overrides: [resource_override_mappings_insert_input!]!
        $override_removals: [uuid!]!
        $shipping_partners: [resource_shipping_partner_mappings_insert_input!]!
        $globals: [resource_shipping_partner_mappings_updates!]!
    ) {
        upserted_shipping_partners: insert_resource_shipping_partner_mappings(
            objects: $shipping_partners
            on_conflict: { constraint: resource_shipping_partner_const, update_columns: [enabled] }
        ) {
            affected_rows
            returning {
                mapping_id
                enabled
            }
        }
        global_shipping_partners: update_resource_shipping_partner_mappings_many(updates: $globals) {
            affected_rows
            returning {
                mapping_id
                enabled
            }
        }
        added_resource: insert_resources(
            objects: $resources
            on_conflict: { constraint: resources_pkey, update_columns: [name, trucks, capacity_per_truck] }
        ) {
            returning {
                ...ResourceFields
                rules {
                    ...ResourceRuleMappingFields
                }
                overrides {
                    ...ResourceOverrideMappingFields
                }
                subregions {
                    ...ResourceRegionMappingFields
                }
                shipping_partners(where: { enabled: { _eq: true } }) {
                    mapping_id
                    partner_client_id
                    resource_id
                    enabled
                    partner_client {
                        ...ClientFields
                    }
                }
            }
        }
        added_regions: insert_resource_region_mappings(
            objects: $regions
            on_conflict: { constraint: resource_region_mappings_resource_id_subregion_id_key, update_columns: [] }
        ) {
            returning {
                ...ResourceRegionMappingFields
            }
        }
        removed_regions: delete_resource_region_mappings(where: { mapping_id: { _in: $region_removals } }) {
            affected_rows
            returning {
                mapping_id
            }
        }
        added_rules: insert_resource_rule_mappings(
            objects: $rules
            on_conflict: { constraint: resource_rule_mappings_pkey, update_columns: [rule_start, rule_end, rule_type] }
        ) {
            returning {
                ...ResourceRuleMappingFields
            }
        }
        added_overrides: insert_resource_override_mappings(
            objects: $overrides
            on_conflict: {
                constraint: resource_override_mappings_pkey
                update_columns: [override_start, override_end, omit]
            }
        ) {
            returning {
                ...ResourceOverrideMappingFields
            }
        }
        removed_overrides: delete_resource_override_mappings(where: { mapping_id: { _in: $override_removals } }) {
            affected_rows
            returning {
                mapping_id
            }
        }
        removed_rules: delete_resource_rule_mappings(where: { mapping_id: { _in: $rule_removals } }) {
            affected_rows
            returning {
                mapping_id
            }
        }
    }
`;

export const DELETE_RULE = gql`
    ${RESOURCE_OVERRIDE_MAPPING_FIELDS}
    ${RESOURCE_RULE_MAPPING_FIELDS}
    mutation DeleteRules($rules: [uuid!]!, $overrides: [uuid!]!) {
        overrides: delete_resource_override_mappings(where: { mapping_id: { _in: $overrides } }) {
            affected_rows
            returning {
                ...ResourceOverrideMappingFields
            }
        }
        rules: delete_resource_rule_mappings(where: { mapping_id: { _in: $rules } }) {
            affected_rows
            returning {
                ...ResourceRuleMappingFields
            }
        }
    }
`;

export const DELETE_RESOURCE = gql`
    ${RESOURCE_FIELDS}
    mutation DeleteResource($resource: uuid!) {
        deleted: delete_resources(where: { resource_id: { _eq: $resource } }) {
            returning {
                ...ResourceFields
            }
        }
    }
`;
