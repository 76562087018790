import React, { useContext, useState, useEffect, useMemo } from 'react';
import { PlanningContext } from '../context';
import { GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton, Body1 } from '../blocks';
import { MODALS } from '../constants';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDERS_MANY } from '../graphql/mutations';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { useClientUser } from '@/hooks';
import { css } from '@emotion/react';
import { TextField } from '@/components/ShipmentForm/blocks';
import { useNotePermissions } from '@/hooks';

const NotesModal = () => {
    const {
        actionableRoute,
        setActionableRoute,
        actionableStop,
        setActionableStop,
        modalOpen,
        setModalOpen,
        setError,
    } = useContext(PlanningContext);

    const [notes, setNotes] = useState({});
    const { user_id } = useClientUser();

    const notePermissions = useNotePermissions(actionableRoute, user_id);

    const stopOrders = useMemo(() => {
        const ordersByKey = Object.fromEntries(
            (actionableRoute?.orders || []).map((mapping) => [mapping.order_id, mapping.order])
        );
        return (actionableStop?.orders || []).map((order_id) => ordersByKey[order_id]);
    }, [actionableRoute, actionableStop]);

    useEffect(() => {
        if (!stopOrders?.length) {
            setNotes({});
            return;
        }
        const _notes = {};
        stopOrders.forEach((o) => {
            _notes[o.order_id] = {};
            _notes[o.order_id].shipper_notes = o.shipper_notes;
            _notes[o.order_id].carrier_notes = o.carrier_notes;
        });
        setNotes(_notes);
    }, [stopOrders]);

    const handleModalClose = () => {
        setModalOpen(null);
        setActionableRoute(null);
        setActionableStop(null);
    };

    const [updateOrders, { loading }] = useMutation(UPDATE_ORDERS_MANY);

    const handleSave = async () => {
        await updateOrders({
            variables: {
                updates: stopOrders.map((o) => ({
                    where: { order_id: { _eq: o.order_id } },
                    _set: {
                        shipper_notes: notes[o?.order_id]?.shipper_notes,
                        carrier_notes: notes[o?.order_id]?.carrier_notes,
                    },
                })),
            },
            onCompleted: () => {
                handleModalClose();
            },
            onError: (error) => {
                setError(error, 'Error saving notes to orders');
            },
        });
    };

    const NotesRow = (order) => {
        return (
            <>
                <GridItemRow
                    css={css`
                        padding-bottom: 5px;
                    `}
                >
                    <Body1>{order?.order_number}</Body1>
                </GridItemRow>
                {notePermissions.shipper_notes.view && (
                    <GridItemRow
                        css={css`
                            padding-bottom: 15px;
                        `}
                    >
                        <TextField
                            label={notePermissions.shipper_notes.edit ? `Shipper Notes` : `Notes from Shipper`}
                            variant="outlined"
                            name="desc"
                            fullWidth
                            value={notes[order?.order_id]?.shipper_notes || null}
                            onChange={(e) => {
                                setNotes({
                                    ...notes,
                                    [order?.order_id]: {
                                        ...notes[order?.order_id],
                                        shipper_notes: e.target.value,
                                    },
                                });
                            }}
                            multiline
                            rows={5}
                            disabled={!notePermissions.shipper_notes.edit}
                        />
                    </GridItemRow>
                )}
                {notePermissions.carrier_notes.view && (
                    <GridItemRow
                        css={css`
                            padding-bottom: 25px;
                        `}
                    >
                        <TextField
                            label="Carrier Notes"
                            variant="outlined"
                            name="desc"
                            fullWidth
                            value={notes[order?.order_id]?.carrier_notes || null}
                            onChange={(e) => {
                                setNotes({
                                    ...notes,
                                    [order?.order_id]: {
                                        ...notes[order?.order_id],
                                        carrier_notes: e.target.value,
                                    },
                                });
                            }}
                            multiline
                            rows={5}
                            disabled={!notePermissions.carrier_notes.edit}
                        />
                    </GridItemRow>
                )}
            </>
        );
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.NOTES} onClose={handleModalClose}>
            <ModalTitle>{`Notes`}</ModalTitle>
            <ModalContent
                css={css`
                    width: 600px;
                `}
            >
                {stopOrders?.map((o) => NotesRow(o))}
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={handleSave}>
                    Save
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default NotesModal;
