import { gql } from '@apollo/client';

const OVERRIDE_MUTATION = gql`
    mutation updateOverride($override_id: uuid, $enabled: Boolean) {
        update_overrides(where: { override_id: { _eq: $override_id } }, _set: { enabled: $enabled }) {
            affected_rows
            returning {
                override_id
                enabled
            }
        }
    }
`;

export { OVERRIDE_MUTATION };
