import React, { useEffect, createContext, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { removeRefs } from '@/graphql/util';
import { Button } from '@material-ui/core';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import useQuery from '@/utilities/useQuery';
import { colors } from '@/styles';
import * as ROUTES from '@/constants/routes';
import { createOrderJob } from '../queries/createOrderJob';
import { useOrderErrors, useJobDetailsCallbacks } from '../hooks/jobDetails';
import { QUERY_JOB_BY_ID, QUERY_CLIENTS_BY_USERS } from '../graphql/queries';
import { DELETE_ORDER, CONFIRM_ORDERS, CLEAR_DUPLICATES, OVERRIDE_DUPLICATES } from '../graphql/mutations';
import { SHIPMENT_FORM_MODAL_PROGRESSION } from '../constants';
import { createListings } from '../queries/createListings';
import { addDays } from 'date-fns';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { useClientUser } from '@/hooks';

const POLL_INVERVAL = 5 * 1000; //5s

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { circles } = useClientUser();
    const { cache } = useApolloClient();

    const [progression, setProgression] = useState({
        current: 0,
        type: null,
    });

    const [listings, setListings] = useState({});
    const [editingListing, editListing] = useState(null);
    const [expirationDate, setExpirationDate] = useState('');
    const [notification, setNotification] = useState({});

    const [isPolling, setPolling] = useState(false);
    const [editing, setEditing] = useState(null);
    const [editingPickup, setEditingPickup] = useState(null);

    const prevListings = useRef(null);

    const [fetchJob, { data: job, startPolling, stopPolling, refetch }] = useLazyQuery(QUERY_JOB_BY_ID);
    const [fetchCarriers, { data: carriers }] = useLazyQuery(QUERY_CLIENTS_BY_USERS);
    const [deleteOrder] = useMutation(DELETE_ORDER, {
        update: (cache, { data }) => {
            let next;
            cache.modify({
                id: cache.identify(job.result?.[0]),
                fields: {
                    orders: (prev, { toReference }) => {
                        next = removeRefs(prev, data.removed.returning, { toReference });
                        return next;
                    },
                },
            });

            if (next.length === 0) {
                navigate(ROUTES.IMPORT_ORDERS);
            }
        },
    });

    const [clearDuplicates] = useMutation(CLEAR_DUPLICATES, {
        update: (cache, { data }) => {
            cache.modify({
                id: cache.identify(job.result?.[0]),
                fields: {
                    orders: (prev, { toReference }) => {
                        const next = removeRefs(prev, data.removed.returning, { toReference });

                        return next;
                    },
                },
            });
        },
    });

    const [overrideDuplicates] = useMutation(OVERRIDE_DUPLICATES, {
        update: (cache, { data }) => {
            cache.modify({
                id: cache.identify(job.result?.[0]),
                fields: {
                    orders: (prev, { toReference }) => {
                        return prev.map((old) => {
                            const updates = data.updated.returning.find(
                                (updated) => toReference(updated).__ref === old.__ref
                            );

                            if (!!updates) {
                                return { ...old, ...updates };
                            }

                            return old;
                        });
                    },
                },
            });
        },
    });

    const [confirmOrders] = useMutation(CONFIRM_ORDERS, {
        onCompleted: (results) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'jobs' });
            setNotification({
                severity: 'success',
                message: `Successfully created ${results.confirmed_pending.affected_rows + results.confirmed_claimed.affected_rows} order(s)!`,
                customAction: (
                    <Button
                        variant="outlined"
                        onClick={() => navigate(ROUTES.IMPORT_ORDERS)}
                        css={css`
                            background: ${colors.greens.primary};
                            color: ${colors.white.primary};
                            border-color: #ffffff;
                        `}
                    >
                        Return
                    </Button>
                ),
            });
        },
    });

    const [submitOrders, { loading: submitting }] = useQuery(createOrderJob, {
        onComplete: () => {
            refetch();
        },
        onError: (err) => {
            refetch();
            Sentry.captureException(err);
        },
    });

    const [submitListings, { loading: submittingListings }] = useQuery(createListings, {
        onComplete: ({ data }) => {
            if (data?.success?.length === Object.values(listings).length) {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'listings' });
                navigate(ROUTES.IMPORT_ORDERS);
            } else {
                refetch();
            }
        },
        onError: (err) => {
            refetch();
            console.error(err);
            Sentry.captureException(err);
        },
    });

    useEffect(() => {
        const orders = job?.result?.[0]?.orders;
        if (orders?.length > 0) {
            setListings((prev) =>
                Object.fromEntries(
                    orders.map((order) => [
                        order.order_id,
                        {
                            order_id: order.order_id,
                            listing_type: !!prev?.[order.order_id]?.listing_type
                                ? prev?.[order.order_id]?.listing_type
                                : circles?.['bidding-disabled']
                                ? 'claim_now'
                                : 'all',
                            shipper_rate: order.shipper_rate,
                            expiration_date: prev?.[order.order_id]?.expiration_date || expirationDate,
                        },
                    ])
                )
            );
        }
    }, [job]);

    useEffect(() => {
        let updateText = false;
        if (prevListings.current) {
            Object.entries(prevListings.current).forEach(([key, l]) => {
                if (listings?.[key]?.shipper_rate && listings?.[key]?.shipper_rate !== l?.shipper_rate) {
                    updateText = true;
                }
            });
        }
        if (updateText) {
            setNotification({
                severity: 'success',
                message: 'Suggested price has been updated to reflect order updates.',
                disableClose: false,
            });
        }
        prevListings.current = listings;
    }, [listings]);

    const orderTZ = useMemo(() => {
        return job?.orders?.[0]?.dropoff_zip
            ? zipcode_to_timezone.lookup(job.orders[0].dropoff_zip)
            : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [job]);

    const clientLocations = useMemo(() => {
        if (!carriers) {
            return {};
        }

        return Object.fromEntries(
            carriers.results.map((client) => {
                return [client.client_id, client];
            })
        );
    }, [carriers]);

    useEffect(() => {
        const utc = addDays(new Date(asUTCDate(new Date().toISOString()).setUTCHours(0, 0, 0, 0)), 14).toISOString();
        setExpirationDate(asDateInTZ(utc, orderTZ).toISOString());
    }, [orderTZ]);

    useEffect(() => {
        if (job?.result) {
            if (job.result?.[0].status === 'PROCESSING') {
                if (!isPolling) {
                    startPolling(POLL_INVERVAL);
                    setPolling(true);
                }
            } else {
                const clientIds = job?.result.reduce((acc, job) => {
                    return [...acc, ...[...new Set(job.orders.map((order) => order.carrier_id).filter((x) => !!x))]];
                }, []);

                stopPolling();
                setPolling(false);

                fetchCarriers({
                    variables: {
                        client_ids: clientIds,
                    },
                });
            }
        }
    }, [job, isPolling, startPolling, stopPolling]);

    const callbacks = useJobDetailsCallbacks(
        { progression, job: job?.result?.[0], listings, expirationDate },
        {
            setEditing,
            setEditingPickup,
            confirmOrders,
            deleteOrder,
            setProgression,
            submitOrders,
            setListings,
            submitListings,
            overrideDuplicates,
            clearDuplicates,
        }
    );

    const hasErrors = useOrderErrors(job?.result?.[0].orders || []);

    return (
        <Context.Provider
            value={{
                state: {
                    editing,
                    editingPickup,
                    current: (SHIPMENT_FORM_MODAL_PROGRESSION[progression.type] || [])[progression.current],
                    job: job?.result?.[0],
                    clientLocations,
                    isSubmitting: submitting || submittingListings,
                    listings,
                    editingListing,
                    expirationDate,
                    orderTZ,
                    notification,
                    clearNotification: () => {
                        setNotification({});
                    },
                },
                callbacks: {
                    ...callbacks,
                    fetchJob,
                    setListings,
                    editListing,
                    setExpirationDate,
                    setNotification,
                    clearNotification: () => {
                        setNotification({});
                    },
                },
                errors: {
                    hasErrors,
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};
