import React, { createContext, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { View } from '@react-pdf/renderer';
import Image from 'react-bootstrap/Image';
import { saveAs } from 'file-saver';
import { Grid, DialogContent, IconButton } from '@material-ui/core';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ModalTitle, PrimaryButton, ResponsiveSidebarDialog, StickyModalActions } from '@/styles/blocks';
import { CardSubtitle } from '@/components/OrderDetailsPage/blocks';
import getOrdersPhotosAsZip from '@/utilities/getOrdersPhotosAsZip';

const OrderPODModal = ({ order, callbacks }) => {
    if (!order) {
        return null;
    }

    return (
        <ResponsiveSidebarDialog
            open={!!order}
            onClose={callbacks.onClose}
            fullWidth={true}
            maxWidth="md"
            css={css`
                .MuiDialog-container {
                    align-items: normal;
                }
            `}
        >
            <ModalTitle border title="PODs/Delivery Photos" onClose={callbacks.onClose} />
            <DialogContent
                css={css`
                    width: 100%;
                    padding: 0;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        height: 100%;
                        padding: 0 24px;
                        padding-top: 32px;
                        justify-content: space-between;
                    `}
                >
                    <Grid container direction="row">
                        <Grid container direction="column">
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 16px;
                                `}
                            >
                                {order.delivery_photo?.map((photo, i) => {
                                    return (
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex: 0;
                                                flex-basis: 0;
                                                margin-right: 8px;
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <a
                                                href={photo.url}
                                                target="_blank"
                                                css={css`
                                                    height: 148px;
                                                    width: 148px;
                                                `}
                                            >
                                                <Image
                                                    src={photo.url}
                                                    css={css`
                                                        height: 148px;
                                                        width: 148px;
                                                    `}
                                                />
                                            </a>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 16px;
                                `}
                            >
                                {order.pod?.map((photo, i) => {
                                    return (
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex: 0;
                                                flex-basis: 0;
                                                margin-right: 8px;
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <a
                                                href={photo.url}
                                                target="_blank"
                                                css={css`
                                                    height: 148px;
                                                    width: 148px;
                                                `}
                                            >
                                                <Image
                                                    src={photo.url}
                                                    css={css`
                                                        height: 148px;
                                                        width: 148px;
                                                    `}
                                                />
                                            </a>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid direction="row" container>
                                <PrimaryButton
                                    onClick={async () => {
                                        const zip = await getOrdersPhotosAsZip(order);
                                        if (zip) {
                                            const zipBlob = await zip.generateAsync({ type: 'blob' });
                                            saveAs(
                                                zipBlob,
                                                `${
                                                    order.order_number ? order.order_number + ' - ' : ''
                                                }Final Delivery Photos.zip`
                                            );
                                        } else {
                                            const error = new Error(
                                                `Could not successfully retrieve Final Delivery Photo zip file for order ${order.order_number}`
                                            );
                                            console.error(error);
                                        }
                                    }}
                                >
                                    Download
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 16px;
                        `}
                    >
                        <View
                            css={css`
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                margin-top: 10px;

                                width: 100%;
                                background-color: white;
                            `}
                        >
                            <CardSubtitle>
                                Consignee Signature: {order?.customer_signature ? '' : 'Not Uploaded'}
                            </CardSubtitle>
                            <Image
                                css={css`
                                    height: 80px;
                                    width: 100%;
                                    margin-top: 10px;
                                    border: 1px solid lightgrey;
                                `}
                                src={order?.customer_signature}
                            />
                        </View>
                    </Grid>
                </Grid>
            </DialogContent>
        </ResponsiveSidebarDialog>
    );
};

export default OrderPODModal;
