import React, { useState, useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_ROUTES_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID, QUERY_DISTINCT_DRIVERS } from './grahpql';

import { createTheme, Box, Grid, ThemeProvider, TextField, InputAdornment, LinearProgress } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import FilterPopover from './FilterPopover';

import RoutesTable from './RoutesTable';
import { OnwardTabContainer, OnwardTab } from '../Tabs';
import { UserContext } from '../App';
import { useClientUser } from '@/hooks';
import '../MyOrders/styles.css';
import _ from 'lodash';
import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';
import { captureException } from '@sentry/react';
import { Pagination } from '@material-ui/lab';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import { PrimaryHeaderContainer, PrimaryHeaderLabel } from '../MyOrders/blocks';
import { parseDriverUsernames } from './utils';

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
        h2: {
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: 1.25,
            color: '#2B2B2B',
        },
        h3: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: 1.25,
            color: '#2B2B2B',
        },
    },
    palette: {
        text: {
            secondary: '#4c4c4c',
        },
        primary: {
            main: '#59b863',
            contrastText: '#fff',
        },
    },
});

const searchableTerms = [
    'route_alias',
    'route_number',
];
const subSearchableTerms = {
    truck: ['truck_name'],
    teammateByDriverId: ['username'],
}

const addOneDay = (date) => {
    const alteredDate = add(new Date(date), { days: 2 });
    return formatISO(alteredDate, { representation: 'date' });
};

const MyRoutes = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [driverId, setDriverId] = useState(null);
    const [sortBy, setSortBy] = useState({ id: 'deldate', desc: true });
    const [totalCount, setTotalCount] = useState(0);

    const { user, sidebarCollapsed } = useContext(UserContext);
    const { user_id, accountType } = useClientUser();
    const allStatuses = [
        'active',
        'pending',
        'planning',
        'inProgress',
        'complete_final_return',
        'complete',
        'cancelled',
    ];

    const tabFilters = [
        [{ status: { _in: allStatuses } }],
        [{ status: { _in: ['pending', 'planning', 'active'] } }],
        [{ status: { _in: ['inProgress', 'complete_final_return'] } }],
        [{ status: { _in: ['complete'] } }],
        [{ status: { _in: ['cancelled'] } }],
    ];

    const where = useMemo(() => {
        const whereFilters = [
            { _or: [{ carrier_id: { _eq: user_id } }, { shipper_id: { _eq: user_id } }] },
            ...tabFilters[tabIndex],
        ];

        if (startDate) {
            whereFilters.push({ scheduled_delivery: { _gte: startDate } });
        }

        if (endDate) {
            whereFilters.push({ scheduled_delivery: { _lt: addOneDay(endDate) } });
        }

        if (searchTerm) {
            const searchables = [];
            searchables.push(...searchableTerms.map((searchField) => {
                if (searchField === 'route_number') {
                    return { [searchField]: { _eq: parseInt(searchTerm) } };
                } else {
                    return { [searchField]: { _ilike: `%${searchTerm}%` } };
                }
            }));

            Object.keys(subSearchableTerms).forEach(association => {
                const subSearchTerms = subSearchableTerms[association].map((searchField) => {
                        let term = {
                            [association]: { [searchField]: { _ilike: `%${searchTerm}%` } }
                        }
                        return term;
                    });
                    searchables.push(...subSearchTerms);
            });

            whereFilters.push({
                _or: searchables,
            });
        }

        if (filters?.length) {
            whereFilters.push({ _and: filters });
        }

        return { _and: whereFilters };
    }, [tabIndex, searchTerm, startDate, endDate, driverId, filters]);

    const order_by = useMemo(() => {
        const ascOrDesc = sortBy?.desc ? 'desc_nulls_last' : 'asc_nulls_last';
        switch (sortBy?.id) {
            case 'route_name':
                return [
                    { route_alias: ascOrDesc },
                    { route_number: ascOrDesc },
                ];
            case 'scheduled_delivery':
                return [
                    { scheduled_delivery: ascOrDesc },
                ];
            default:
                return [{ scheduled_delivery: 'desc_nulls_last' }, { route_number: 'desc_nulls_last' }];
        }
    }, [sortBy]);

    const PAGE_SIZE = 20;
    const [page, setPage] = useState(0);

    const { loading: loadingRoutes, data } = useQuery(QUERY_ROUTES_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID, {
        variables: {
            limit: PAGE_SIZE,
            offset: page * PAGE_SIZE,
            where,
            order_by,
        },
        onCompleted: (data) => {
            setTotalCount(data.routes_aggregate.aggregate.totalCount);
        },
        onError: (error) => {
            captureException(error);
            console.log(error);
        },
    });

    const allRoutes = useMemo(() => {
        if (data?.routes?.length) {
            // Attach additional search properties to route objects
            const routes = data.routes.reduce((parsed, route) => {
                if (route?.teammateByDriverId?.username || route?.truck?.truck_name) {
                    let objCopy = _.cloneDeep(route);

                    route?.teammateByDriverId?.username && (objCopy.driver_name = route.teammateByDriverId.username);
                    route?.truck?.truck_name && (objCopy.truck_name = route.truck.truck_name);
                    parsed.push(objCopy);
                } else {
                    parsed.push(route);
                }
                return parsed;
            }, []);

            return routes;
        }
        return [];
    }, [data]);

    const { loading: loadingDrivers, data: driversData } = useQuery(
        QUERY_DISTINCT_DRIVERS,
        {
            variables: {
                where,
            },
            onError: (error) => {
                captureException(error);
                console.log(error);
            },
        }
    );

    const driverOptions = useMemo(() => {
        if (driversData?.routes?.length) {
            return driversData.routes.reduce((options, r) => {
                const _driver = r?.teammateByDriverId;
                if (_driver) {
                    const driverOptionName = parseDriverUsernames(_driver?.username);
                    options.push({
                        value: _driver?.teammate_id,
                        label: driverOptionName
                    });
                }
                return options;
            }, []);
        }
        return [];
    }, [driversData]);

    const handleSearch = (e) => {
        const searchText = e.target.value;
        setSearch(searchText);
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        setDebounceTimer(
            setTimeout(() => {
                setSearchTerm(searchText);
            }, 300)
        );
    };

    const handleTabChange = (e, tab) => {
        setTabIndex(tab);
    };

    const handleSortBy = (newSortBy) => {
        if (newSortBy?.id !== sortBy.id || newSortBy.desc !== sortBy.desc) {
            setSortBy(newSortBy);
            setPage(0);
        }
    };

    const handlePage = (_, p) => {
        const pageIdx = p - 1;
        setPage(pageIdx);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                className={`${sidebarCollapsed && 'collapse-margin'}  table-height`}
                sx={{ flexGrow: 1, padding: 0, height: 'calc(100vh - 50px)' }}
            >
                <Grid container direction="column" wrap="nowrap" className="h-100">
                    <Grid container justifyContent="center" className="bg-white border-bottom">
                        <Grid item>
                            <OnwardTabContainer
                                value={tabIndex}
                                onChange={handleTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                            >
                                <OnwardTab label="All" value={0} style={{ outline: 0 }} />
                                <OnwardTab label="Pre-Transit" value={1} style={{ outline: 0 }} />
                                <OnwardTab label="In-Transit" value={2} style={{ outline: 0 }} />
                                <OnwardTab label="Complete" value={3} style={{ outline: 0 }} />
                                <OnwardTab label="Cancelled" value={4} style={{ outline: 0 }} />
                            </OnwardTabContainer>
                        </Grid>
                    </Grid>
                    <PrimaryHeaderContainer item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <PrimaryHeaderLabel>My Routes</PrimaryHeaderLabel>
                            </Grid>
                            <Grid item>
                                <TextField
                                    value={search}
                                    placeholder="Search routes"
                                    onChange={handleSearch}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className="me-3"
                                    InputProps={{
                                        style: { backgroundColor: 'white' },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FilterPopover
                                    applyFilters={(filters) => setFilters(filters)}
                                    email={user?.email}
                                    userType={accountType}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    driverId={driverId}
                                    setDriverId={setDriverId}
                                    user_id={user_id}
                                    driverOptions={driverOptions}
                                />
                            </Grid>
                        </Grid>
                    </PrimaryHeaderContainer>
                    {loadingRoutes && (
                        <Grid item className="ms-5 me-5">
                            <LinearProgress
                                color="primary"
                                css={css`
                                    width: 100%;
                                `}
                            />
                        </Grid>
                    )}
                    <Grid item className="ms-5 me-5 bg-white" style={{ overflow: 'hidden', flexGrow: 1 }}>
                        <RoutesTable routes={allRoutes} sortBy={[sortBy]} handleSortBy={handleSortBy} tabIndex={tabIndex}/>
                    </Grid>
                    <Grid container className="m-5" justifyContent="center">
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            css={css`
                                .Mui-selected {
                                    background-color: ${colors.greens.primary};
                                    color: white;
                                }
                            `}
                            count={Math.ceil(totalCount / PAGE_SIZE)}
                            page={page + 1}
                            onChange={handlePage}
                        />
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default MyRoutes;
