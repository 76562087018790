import React from 'react';
import { PlainCard } from './blocks';
import { css } from '@emotion/react';
import { colors } from '@/styles';

const TextCard = ({ text }) => {
    return (
        <PlainCard>
            <span
                css={css`
                    font-size: 14px;
                    font-weight: 500;
                    color: ${colors.greys.primary};
                `}
            >
                {text}
            </span>
        </PlainCard>
    );
};

export default TextCard;
