import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import { asDateInTZ } from '@/utilities/convertToISO';
import { formatInTimeZone } from 'date-fns-tz';
import { isAfter, subDays } from 'date-fns';

export default function OtherBidsTable({ orderTz, bids = [] }) {
    const isExpired = (dateISO) => {
        const today = asDateInTZ(new Date().toISOString(), orderTz);
        const expiredAt = asDateInTZ(dateISO, orderTz);
        return isAfter(today, expiredAt);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography>Starting Bid</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Current Counter</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Bid Date</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Expiration Date</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Proposed Delivery</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bids.map((bid) => (
                        <TableRow key={bid.bid_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                ${bid.carrier_rate.toFixed(2)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {bid.counter_offers.length
                                    ? `$${bid.counter_offers
                                          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]
                                          ?.carrier_rate.toFixed(2)}`
                                    : `-`}
                            </TableCell>
                            <TableCell align="left">
                                {formatInTimeZone(asDateInTZ(bid.created_at, orderTz), orderTz, 'EEE, MMM d, yyyy')}
                            </TableCell>
                            <TableCell
                                align="left"
                                style={isExpired(bid.expiration_date) ? { color: 'red', fontWeight: 'bold' } : {}}
                            >
                                {formatInTimeZone(
                                    asDateInTZ(subDays(new Date(bid.expiration_date), 1).toISOString(), orderTz),
                                    orderTz,
                                    'EEE, MMM d, yyyy'
                                )}
                            </TableCell>
                            <TableCell align="left">
                                {bid.target_delivery_date
                                    ? formatInTimeZone(
                                          asDateInTZ(bid.target_delivery_date, orderTz),
                                          orderTz,
                                          'EEE, MMM d, yyyy'
                                      )
                                    : '-'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
