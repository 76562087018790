import JSZip from 'jszip';

/**
 * Zip an order's final delivery photo files
 *
 * @param   {object}    order Order object to process
 * @returns             A JSZip object with all of {order}'s final delivery photo files
 */
export default async function getOrdersPhotosAsZip(order, corruptFiles) {
    let files;
    if (typeof order.delivery_photo === 'string' && order.delivery_photo !== '') {
        files = [order.delivery_photo];
    } else {
        files = order.delivery_photo;
    }

    if (!files || files.length === 0) {
        return;
    }

    const fileFetchPromises = [];
    const fileResponseBlobPromises = [];
    for (const file of files) {
        fileFetchPromises.push(fetch(file?.url ? file.url : file));
    }

    const fileFetchResults = await Promise.all(fileFetchPromises);

    for (const fileFetchResult of fileFetchResults) {
        fileResponseBlobPromises.push(fileFetchResult.blob());
    }

    const fileBlobs = await Promise.all(fileResponseBlobPromises);

    const fileTypeMap = {
        'application/pdf': 'pdf',
        'image/jpeg': 'jpg',
        'image/png': 'png',
    };

    const filesWithTypes = files.map((file, index) => {
        const returnObj = { file };

        const type = fileBlobs[index].type;

        if (fileTypeMap[type]) {
            returnObj.type = fileTypeMap[type];
        } else {
            if (type === 'application/json') {
                corruptFiles.push({
                    file,
                    orderNumber: order.order_number,
                    set: 'deliv_photo',
                });
            }
            returnObj.type = type;
        }

        return returnObj;
    });

    const fileNames = [];

    for (let i = 0; i < filesWithTypes.length; i++) {
        // file name format: 'ODXXXX-01.png'
        let fileName = order.order_number ? order.order_number + ' - ' : '';
        fileName += 'Final Delivery Photos - ';
        if (i < 9) {
            fileName += '0';
        }

        fileName += i + 1 + '.' + filesWithTypes[i].type;
        fileNames.push(fileName);
    }

    const zip = new JSZip();
    for (let i = 0; i < filesWithTypes.length; i++) {
        zip.file(fileNames[i], fileBlobs[i]);
    }

    return zip;
}
