import { gql } from '@apollo/client';

export const TRUCK_FIELDS = gql`
    fragment TruckFields on trucks {
        client_id
        created_at
        days_available
        last_modified_at
        truck_capacity
        truck_id
        truck_name
        truck_size
        truck_weight_limit
    }
`;
