import React from 'react';
import { compose } from 'recompose';

import { css } from '@emotion/react';
import withAdminRights from '@/components/Auth/withAdminRights';
import { Grid } from '@material-ui/core';
import { Accounting } from '@/components/Accounting/Invoices';
import { PrimaryButton } from '@/styles/blocks';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';

import Footer from './Footer';
import { Context, ContextProvider } from './store';
import { FILTERS, FILTER_DISPLAY_MAPPINGS } from './constants';

const StatusFilter = ({ state, loading, callbacks }) => {
    return (
        <Grid
            direction="row"
            container
            css={css`
                display: flex;
                background-color: white;
            `}
        >
            <Grid
                direction="column"
                container
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    align-content: center;
                `}
            >
                <OnwardTabContainer
                    value={state.filter.status}
                    onChange={(e, t) => {
                        callbacks.setFilter((prev) => {
                            return {
                                ...prev,
                                status: t,
                            };
                        });
                    }}
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                >
                    {Object.keys(FILTERS).map((key) => {
                        return <OnwardTab label={FILTER_DISPLAY_MAPPINGS[key]} value={key} />;
                    })}
                </OnwardTabContainer>
            </Grid>

            <Grid
                direction="column"
                container
                css={css`
                    flex: 0;
                    flex-basis: content;
                    justify-content: center;
                    margin-right: 20px;
                `}
            >
                <PrimaryButton
                    css={css`
                        height: 40px;
                        align-self: flex-end;
                        justify-content: center;
                        text-align: center;
                    `}
                    onClick={callbacks.create}
                >
                    Create
                </PrimaryButton>
            </Grid>
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} title="Receivables" TopBar={StatusFilter} Footer={Footer} />
        </ContextProvider>
    );

export default compose(withAdminRights, withContext)(Accounting);
