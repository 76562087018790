export const CSV_EXPORT_COLUMNS = [
    {
        header: 'Subregion',
        value: (data) => data.name || '',
        isAdminOnly: false,
    },
    {
        header: 'Zip',
        value: (data) => data.zip || '',
        isAdminOnly: false,
    },
    {
        header: 'Carrier',
        value: (data) => data.carrier_id || '',
        isAdminOnly: true,
    },
    {
        header: 'Warehouse',
        value: (data) => data.warehouse_id || '',
        isAdminOnly: true,
    },
    {
        header: 'Direct',
        value: (data) => !!data.is_direct,
        isAdminOnly: true,
    },
];
