import { getTimezoneOffset } from 'date-fns-tz';
import { calculateGoogleTimeframes } from '@/components/DispatchPlan/util';
import { isSkippedStop } from '@/components/DispatchPlan/util/isSkippedStop';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { convertToISO } from '@/utilities/convertToISO';
import { genAttributes } from '@onward-delivery/core';
import zipcode_to_timezone from 'zipcode-to-timezone';

export const recalculateRoute = async (route, exception, clientUser, orders, extras) => {
    if (!route || !route.stopsByRouteId || exception.route_id !== route.route_id || !exception.recalc_route)
        return null;

    const getRouteTimeZone = () => {
        const order = route?.orders?.[0]?.order || {};
        const { zip } = genAttributes(order);
        return zipcode_to_timezone.lookup(order?.[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;
    };

    const routeTimeZone = getRouteTimeZone();

    const getRouteStartTime = () => {
        if (route.start_time) return new Date(route.start_time);
        const utcDate = new Date(convertToISO(route.scheduled_delivery));
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const browserLocalDate = new Date(utcDate.getTime() - getTimezoneOffset(localTimeZone, utcDate));
        browserLocalDate.setHours(7);

        if (routeTimeZone && localTimeZone !== routeTimeZone) {
            return new Date(
                browserLocalDate.getTime() +
                    getTimezoneOffset(localTimeZone, utcDate) -
                    getTimezoneOffset(routeTimeZone, utcDate)
            );
        } else {
            return browserLocalDate;
        }
    };
    const routeStartTime = getRouteStartTime();

    const allRecalc = (stop) => {
        const stopOrders = orders ? orders.filter((order) => (stop.orders || []).includes(order.order_id)) : [];
        return (
            stopOrders.every(
                (order) =>
                    (order.order_id === exception.order_id && exception.recalc_route) ||
                    order.exceptions.some((ex) => ex.exception.recalc_route)
            ) && !stop.stop_completion
        );
    };

    let newStops = route.stopsByRouteId.filter((stop) => !allRecalc(stop));

    let stopSequence = FTLStopHelpers.getStopSequence(newStops || route.stopsByRouteId);
    const directionsData = await FTLStopHelpers.getGMapsDirections(stopSequence, new Date(routeStartTime));
    const directionsRoute = directionsData?.routes?.[0];
    let directionsExepctedLength = stopSequence.length - 1;
    stopSequence.forEach((s) => {
        if (isSkippedStop(s)) directionsExepctedLength--;
    });
    if (directionsData?.status === 'ZERO_RESULTS' || directionsRoute?.legs?.length !== directionsExepctedLength) {
        return;
    }

    const ordersByKey = Object.fromEntries((route.orders || []).map((mapping) => [mapping.order_id, mapping.order]));

    const { stopsWithTimeframes, estimatedDrivingDistance, estimatedDrivingTime } = calculateGoogleTimeframes(
        stopSequence,
        directionsRoute.legs,
        new Date(routeStartTime),
        ordersByKey,
        directionsData.useTrafficEstimates,
        clientUser,
        extras.default_end_location,
        extras.preferences_start_estimate,
        extras.preferences_warehouse_return_estimate,
        extras.preferences_end_estimate
    );

    return { stopsWithTimeframes, estimatedDrivingDistance, estimatedDrivingTime };
};
