import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { Grid, InputAdornment } from '@material-ui/core';

import { Card, H1, Body1 } from './blocks';

const RateGrid = ({ className, name, rates, buckets, yLabels, xLabels, errors, callbacks }) => {
    return (
        <Card className={className}>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 24px;
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <H1>{name}</H1>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 12px;
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                />
                {xLabels.map((label, idx) => {
                    return (
                        <Grid
                            key={`header-${idx}`}
                            container
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                align-content: center;
                            `}
                        >
                            <Body1>{label}</Body1>
                        </Grid>
                    );
                })}
            </Grid>

            {buckets.map((bucket, idx) => {
                const filled = useMemo(() => {
                    const clone = Array(xLabels.length).fill({}, 0, xLabels.length);
                    bucket.forEach((rate, idx) => {
                        clone[idx] = rate;
                    });

                    return clone;
                }, [bucket, xLabels]);

                return (
                    <Grid
                        key={`bucket-${idx}`}
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 12px;
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <Grid
                            key={`${idx}-label`}
                            container
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                justify-content: center;
                            `}
                        >
                            <Body1>{yLabels[idx]}</Body1>
                        </Grid>
                        {filled.map(({ type, bucket: bucketIdx, decorator }, pidx) => {
                            if (type) {
                                return (
                                    <Grid
                                        key={`${type}-${bucketIdx}`}
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 1;
                                            flex-basis: 0;
                                        `}
                                    >
                                        <Grid container direction="row">
                                            <Grid
                                                container
                                                direction="column"
                                                css={css`
                                                    flex: 1;
                                                    flex-basis: 0;
                                                    justify-content: center;
                                                    margin-right: 8px;
                                                `}
                                            >
                                                <CurrencyTextField
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={rates?.[type]?.[bucketIdx]?.rate || ''}
                                                    onChange={(_, val) => {
                                                        callbacks.setRate({
                                                            bucket: bucketIdx,
                                                            type,
                                                            rate: val,
                                                        });
                                                    }}
                                                    error={errors[type] && !(rates?.[type]?.[bucketIdx]?.rate >= 0)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                container
                                                direction="column"
                                                css={css`
                                                    flex: 1;
                                                    flex-basis: 0;
                                                    justify-content: center;
                                                    margin-left: 8px;
                                                `}
                                            >
                                                <Body1>{decorator}</Body1>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            }

                            return (
                                <Grid
                                    key={`placeholder-${pidx}`}
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                ></Grid>
                            );
                        })}
                    </Grid>
                );
            })}
        </Card>
    );
};

export default RateGrid;
