import { gql } from '@apollo/client';

export const EXCEPTION_FIELDS = gql`
    fragment ExceptionFields on exceptions {
        exception_id
        type
        reason
        notes
        images
        reported_at
        call_or_text
        ring_or_knock
        wait_time
        item_kept
        resolution
        route_id
        instruction
        status
        created_at
        last_modified_at
        recalc_route
        order_id
        route_custom_charge
        route_rev_action
    }
`;
