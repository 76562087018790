import React, { useMemo, useEffect, useCallback, useState, useContext, useRef } from 'react';
import {
    Grid,
    CircularProgress,
    InputLabel,
    FormControl,
    TextField,
    MenuItem,
    Button,
    Popper,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { SwapVert } from '@material-ui/icons';
import { css } from '@emotion/react';

import { useCustomerStops, useCallbacks, useRouteTimeLimits, useInvalidEventStates } from '../hooks';
import { useItemQuantity, useTotalWeight, useTotalCubes, useExchangeCubes } from '@/hooks';
import { poundFormatter, integerFormatter, hourMinuteFormatter } from '@/constants/formats';
import DriverCombobox from '@/components/misc/DriverCombobox';
import StartTimeSelect from '../../misc/StartTimeSelect';
import StopListItem from './StopListItem';
import Select from '@/components/misc/Select';

import { colorCodes } from '@/constants/styles';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import handleGeocode from '@/utilities/handleGeocode';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import routeStatusOptions from '@/constants/routeStatusOptions';
import { differenceInMinutes } from 'date-fns';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { useMutation, useQuery } from '@apollo/client';
import { QUERY_BY_LEADER } from '@/graphql/queries/teammates';
import { useClientUser } from '@/hooks/useClientUser';
import { genAttributes, validate } from '@onward-delivery/core';
import { PlanningContext } from '../context';
import { routeTimeFormatter, routeTimeFormatterTZ } from '@/constants/formats';
import { MODALS } from '../constants';
import StopBookend from './StopBookend';
import {
    Body1,
    Body2,
    GridItemRow,
    GridScrollY,
    GrowingGrid,
    PlanningListItem,
    PrimaryButton,
    H1,
    RouteDetailsIconButton,
} from '../blocks';
import { UPDATE_ROUTE, UPDATE_ROUTE_STOPS } from '../graphql/mutations';
import * as Sentry from '@sentry/react';
import { StrictModeDroppable } from './StrictModeDroppable';
import { addRefs, removeRefs } from '@/graphql/util';
import { calculateGoogleTimeframes } from '../util';
import { cloneDeep } from 'lodash';
import { getTimezoneOffset } from 'date-fns-tz';
import { convertToISO } from '@/utilities/convertToISO';
import { colors } from '@/styles';
import { post } from '@/utilities/onwardClient';
import { MAPBOX_OPTIMIZE } from '@/constants/apiRoutes';
import { calcDefaultServiceTime } from '@/utilities/calculateTimeframes';
import { isSkippedStop } from '../util/isSkippedStop';
import { formatInTimeZone } from 'date-fns-tz';
import { UserContext } from '@/components/App';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import NearMeIcon from '@material-ui/icons/NearMe';
import { Popover } from '@material-ui/core';
import { calcStopTruckCubes, calcStopVolumes } from '@/utilities/calculateTruckStopCubes';
import { CDS_TIMEFRAME_EXPORT } from '@/constants/featureFlags';
import csvDownload from 'json-to-csv-export';
import { createCdsExportRows, cdsRows } from '../util/createCdsExportRows';

function PlanningStops() {
    const {
        user_id,
        circles,
        drivers,
        helpers,
        defaultServiceTime,
        defaultDelTimeframe,
        default_end_location,
        partner_carriers,
        trucks,
        preferences_route_time_limit,
        preferences_route_eod_limit,
        preferences_route_eod_limit_next_day,
        preferences_start_estimate,
        preferences_end_estimate,
        preferences_warehouse_return_estimate,
        preferences_bypass_optimize,
        ...clientUser
    } = useClientUser();
    const {
        selectedRoute,
        setNotification,
        setError,
        setActionableRoute,
        setActionableOrder,
        setActionableStop,
        setModalOpen,
        setClashingRoutes,
        setClashingDriver,
        state: { routes },
        callbacks: { subscribeToMore },
    } = useContext(PlanningContext);
    const { sidebarCollapsed } = useContext(UserContext);

    const route = useMemo(() => {
        return routes?.find((route) => route.route_id === selectedRoute);
    }, [routes, selectedRoute]);
    if (!route) return null;

    const ordersByKey = useMemo(() => {
        return Object.fromEntries((route.orders || []).map((mapping) => [mapping.order_id, mapping.order]));
    }, [route]);

    const partneredCarrier = useMemo(() => {
        return partner_carriers
            ? partner_carriers.find((partnership) => partnership.carrier_id === route?.carrier_id)?.carrier
            : null;
    }, [route, partner_carriers]);

    const isPartnerRoute = useMemo(() => {
        return !!partneredCarrier;
    }, [partneredCarrier]);

    const truck = useMemo(() => {
        return trucks?.find((truck) => truck.truck_id === route?.truck_id);
    }, [route, trucks]);

    const dayOfTheWeek = useMemo(() => {
        if (route?.scheduled_delivery) {
            const _date = new Date(route?.scheduled_delivery);

            return formatInTimeZone(_date, 'utc', 'iiii').toLowerCase();
        }
    }, [route?.scheduled_delivery]);

    const trucksForToday = useMemo(() => {
        if (!dayOfTheWeek || !trucks || !route) return [];
        return trucks.filter((truck) => truck?.days_available?.[dayOfTheWeek]);
    }, [dayOfTheWeek, route, trucks]);

    const [updateStops, { loading: stopsLoading }] = useMutation(UPDATE_ROUTE_STOPS, {
        update: (cache, { data }) => {
            const newStops = data?.insert_stops?.returning || [];
            const removedStops = data?.delete_stops?.returning || [];

            cache.modify({
                id: cache.identify(route),
                fields: {
                    stopsByRouteId: (prev, { toReference }) => {
                        const withNew = addRefs(prev, newStops, { toReference });
                        return removeRefs(withNew, removedStops, { toReference });
                    },
                },
            });

            removedStops.forEach((stop) => cache.evict(cache.identify(stop)));
        },
    });

    const [updateRoute, { loading: routeLoading }] = useMutation(UPDATE_ROUTE);

    const { pushRouteToTracking } = useCallbacks();

    const routeExceedsTimeLimits = useRouteTimeLimits(
        route,
        preferences_route_time_limit,
        preferences_route_eod_limit,
        preferences_route_eod_limit_next_day
    );

    const ordersAwaitingCD = useInvalidEventStates(route);

    const [optimizing, setOptimizing] = useState(false);
    const loading = stopsLoading || routeLoading || optimizing;

    const driver = drivers.find((driver) => route?.driver_id === driver.teammate_id);
    const helper = helpers.find((helper) => route?.helper_id === helper.teammate_id);

    const stopSequenceEnriched = useMemo(() => {
        let sortedStops = FTLStopHelpers.getStopSequence(route?.stopsByRouteId);
        if (!sortedStops) return [];
        let enrichedStops = sortedStops.map((stop) => {
            const orders = (stop.orders || []).map((order_id) => ordersByKey[order_id]).filter((x) => !!x);
            return {
                ...stop,
                orders,
                exchangeCubes: calcStopVolumes(orders),
            };
        });
        return calcStopTruckCubes(enrichedStops);
    }, [route, ordersByKey]);

    const inProgress = useMemo(() => {
        return (route.stopsByRouteId || []).some((s) => s.stop_completion_time);
    }, [route]);

    const routeTimeZone = useMemo(() => {
        const order = route?.orders?.[0]?.order || {};
        const { zip } = genAttributes(order);
        return zipcode_to_timezone.lookup(order?.[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [route]);

    const isLocalTimeZone = routeTimeZone !== Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [startAddressAutocomplete, setStartAddressAutocomplete] = useState(false);
    const [startAddress, setStartAddress] = useState(route?.stopsByRouteId?.find((s) => s.start)?.address || '');
    const [startAddressInfo, setStartAddressInfo] = useState({});
    const [endAddressAutocomplete, setEndAddressAutocomplete] = useState(false);
    const [endAddress, setEndAddress] = useState(route?.stopsByRouteId?.find((s) => s.end)?.address || '');
    const [endAddressInfo, setEndAddressInfo] = useState({});
    const [totalTime, setTotalTime] = useState('');
    const [routeDetailsOpen, setRouteDetailsOpen] = useState(false);
    const [offsetWidth, setOffsetWidth] = useState(0);

    const isLocked = route && route.status !== routeStatusOptions.PLANNING.value;

    const totalItems = useItemQuantity(route?.orders?.map((mapping) => mapping.order) || []);
    const totalWeight = useTotalWeight(route?.orders?.map((mapping) => mapping.order) || []);
    const totalVolume = useTotalCubes(route?.orders?.map((mapping) => mapping.order) || []);
    const peakVolume = useMemo(() => {
        const maxTruckCubes = Math.max(...stopSequenceEnriched.map((stop) => stop.totalTruckCubes));
        return isNaN(maxTruckCubes) ? totalVolume : maxTruckCubes;
    }, [stopSequenceEnriched, totalVolume]);

    const dateTimeFormatter = (time) => {
        const date = new Date(time);
        if (isLocalTimeZone) {
            return routeTimeFormatter.format(date);
        } else {
            return `${routeTimeFormatter.format(date)} (${routeTimeFormatterTZ(routeTimeZone).format(date)})`;
        }
    };

    useEffect(() => {
        if (!route) return;

        const stopSequence = FTLStopHelpers.getStopSequence(route.stopsByRouteId);
        const containsOvernight = stopSequence.some((s) => s.overnight);

        const startTime =
            preferences_start_estimate || containsOvernight
                ? routeStartTime
                : stopSequence.find((s) => !s.start)?.stop_start_time || 0;

        // If the account has a default_end_location associated with it, then the end location IS used in regards to total delivery time, otherwise it is not.
        let lastNonFinalReturnStop;
        const reversedStops = stopSequence.reverse();
        if (preferences_warehouse_return_estimate && preferences_end_estimate) {
            lastNonFinalReturnStop = reversedStops[0];
        } else if (preferences_warehouse_return_estimate && !preferences_end_estimate) {
            lastNonFinalReturnStop = reversedStops?.find((stop) => !stop?.end);
        } else {
            lastNonFinalReturnStop = reversedStops?.find((stop) => !stop?.end && !FTLStopHelpers.isFinalDropoff(stop));
        }

        if (startTime && stopSequence.length > 1 && lastNonFinalReturnStop?.stop_end_time) {
            const timeEnd = new Date(new Date(lastNonFinalReturnStop.stop_end_time).getTime());
            const diff = differenceInMinutes(timeEnd, new Date(startTime));
            setTotalTime(`${(diff / 60).toFixed(2)} hours`);
        } else {
            setTotalTime('');
        }
    }, [route]);

    useEffect(() => {
        //reload start/end location info on route change
        setStartAddress(route?.stopsByRouteId?.find((s) => s.start)?.address || '');
        setEndAddress(route?.stopsByRouteId?.find((s) => s.end)?.address || '');
    }, [route]);

    const routeHasMultiplePickups = useMemo(() => {
        const pickups = route?.stopsByRouteId?.filter((stop) => FTLStopHelpers.isWarehousePickup(stop))?.length || 0;
        return pickups > 1;
    }, [route]);

    const routeStartTime = useMemo(() => {
        if (route.start_time) return new Date(route.start_time);
        const utcDate = new Date(convertToISO(route.scheduled_delivery));

        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const browserLocalDate = new Date(utcDate.getTime() - getTimezoneOffset(localTimeZone, utcDate));

        browserLocalDate.setHours(7);

        if (routeTimeZone && localTimeZone !== routeTimeZone) {
            const routeLocalDate = new Date(
                browserLocalDate.getTime() +
                    getTimezoneOffset(localTimeZone, utcDate) -
                    getTimezoneOffset(routeTimeZone, utcDate)
            );
            return routeLocalDate;
        } else {
            return browserLocalDate;
        }
    }, [route.start_time, routeTimeZone]);

    const getStartAddressInfo = useCallback(
        async (address) => {
            const addressInfo = await handleGeocode({ address });
            setStartAddressInfo(addressInfo);
            if (addressInfo.lat && addressInfo.lng) {
                const newStops = FTLStopHelpers.addStart(route, addressInfo);
                updateStops({
                    variables: {
                        route_id: route.route_id,
                        route_update: { need_to_optimize: true },
                        ...FTLStopHelpers.gqlStopUpdates(newStops, route),
                    },
                    onError: (error) => {
                        setError(error, 'Error setting route start location');
                    },
                });
            } else {
                setNotification({
                    severity: 'warning',
                    message: 'Failed to geocode start location, stop has not been added to route',
                });
            }
        },
        [route]
    );

    const getEndAddressInfo = useCallback(
        async (address) => {
            const addressInfo = await handleGeocode({ address });

            // If the end location address added is the same address as the final dropoff stop of a return, do not add it.
            const addressField = `${addressInfo.street}, ${addressInfo.city}, ${addressInfo.state}, ${addressInfo.zip}`;
            const returnDropoffs = route?.stopsByRouteId.filter((s) => FTLStopHelpers.isFinalDropoff(s));
            if (returnDropoffs.some((s) => s.address === addressField)) {
                setNotification({
                    severity: 'warning',
                    message:
                        'End location is equal to the final dropoff stop of a return order, skipping adding end location.',
                });
                return;
            }

            setEndAddressInfo(addressInfo);
            if (addressInfo.lat && addressInfo.lng) {
                const newStops = FTLStopHelpers.addEnd(route, addressInfo);
                updateStops({
                    variables: {
                        route_id: route.route_id,
                        route_update: { need_to_optimize: true },
                        ...FTLStopHelpers.gqlStopUpdates(newStops, route),
                    },
                    onError: (error) => {
                        setError(error, 'Error setting route end location');
                    },
                });
            } else {
                setNotification({
                    severity: 'warning',
                    message: 'Failed to geocode end location, stop has not been added to route',
                });
            }
        },
        [route]
    );

    const allConfirmed = useMemo(() => {
        if (!route?.orders?.length || !route?.stopsByRouteId?.length || !route.sending_to) return false;
        const ordersByKey = Object.fromEntries(
            (route.orders || []).map((mapping) => [mapping.order_id, mapping.order])
        );
        return (route.stopsByRouteId || []).every((s) => {
            if (!FTLStopHelpers.isCustomerStop(s, route)) {
                return true;
            }
            return (s.orders || []).every(
                (order_id) =>
                    ordersByKey[order_id]?.order_status === 'cancelled' ||
                    ordersByKey[order_id]?.delivery_time_confirmed === 'confirmed'
            );
        });
    }, [route]);

    const routeEndTime = useMemo(() => {
        if (!route?.stopsByRouteId) return;

        const stopSequence = FTLStopHelpers.getStopSequence(route.stopsByRouteId);

        const lastStop = stopSequence[stopSequence.length - 1];

        if (!lastStop?.del_window_start) return;

        const lastStopServiceTime = lastStop.service_time || 0;

        if (lastStop.end) {
            return new Date(lastStop.del_window_start).getTime();
        }

        return new Date(lastStop.del_window_start).getTime() + lastStopServiceTime;
    }, [route]);

    const handleSelectStartAddress = (address) => {
        setStartAddress(address);
        getStartAddressInfo(address);
    };

    const handleSelectEndAddress = (address) => {
        setEndAddress(address);
        getEndAddressInfo(address);
    };

    const handleBlurStartAddress = () => {
        if (!startAddress) {
            setStartAddressInfo({});
        } else {
            setStartAddressAutocomplete(false);
            if (!startAddressInfo.lat || !startAddressInfo.lng) {
                getStartAddressInfo(startAddress);
            }
        }
    };

    const handleBlurEndAddress = () => {
        if (!endAddress) {
            setEndAddressInfo({});
        } else {
            setEndAddressAutocomplete(false);
            if (!endAddressInfo.lat || !endAddressInfo.lng) {
                getEndAddressInfo(endAddress);
            }
        }
    };

    const handleOnClear = (type) => () => {
        const newStops = FTLStopHelpers.removeCustomStop(route.stopsByRouteId, type);
        updateStops({
            variables: {
                route_id: route.route_id,
                route_update: { need_to_optimize: true },
                ...FTLStopHelpers.gqlStopUpdates(newStops, route),
            },
            onCompleted: () => {
                if (type === 'start') {
                    setStartAddress('');
                    setStartAddressInfo({});
                } else {
                    setEndAddress('');
                    setEndAddressInfo({});
                }
            },
            onError: (error) => {
                setError(error, `Error removing route ${type} location`);
            },
        });
    };

    const stopsNeedRecalculating = useMemo(() => {
        const ordersByKey = Object.fromEntries(
            (route.orders || []).map((mapping) => [mapping.order_id, mapping.order])
        );
        return (route.stopsByRouteId || []).some((stop) => {
            return (
                FTLStopHelpers.isCustomerStop(stop, route) &&
                (stop.orders || []).some((order_id) => ordersByKey[order_id]?.delivery_time_confirmed === 'recalculate')
            );
        });
    }, [route]);

    const validateDrag = (result) => {
        if (isLocked) {
            setNotification({
                severity: 'warning',
                message: 'You cannot re-order stops after a route has been started',
            });
            return { validateResult: false, stop0: null };
        }

        if (loading) {
            return { validateResult: false, stop0: null };
        }

        const { destination, source } = result;

        if (!destination) {
            return { validateResult: false, stop0: null };
        }
        let fromIdx = source.index;
        let toIdx = destination.index;

        if (destination.index === source.index) {
            return { validateResult: false, stop0: null };
        }

        const stopSequence = FTLStopHelpers.getStopSequence(route.stopsByRouteId);
        if (stopSequence[0].start) {
            fromIdx += 1;
            toIdx += 1;
        }
        const selectedStop = stopSequence[fromIdx];

        // Disable dragging for pickup stops if route is overnight. It doesn't matter anyway and just makes code more cumbersome.
        if (selectedStop.overnight && FTLStopHelpers.isWarehousePickup(selectedStop)) {
            setNotification({
                severity: 'warning',
                message: 'You cannot re-order overnight pickup stops.',
            });
            return { validateResult: false, stop0: null };
        }

        const stopsToCheck =
            fromIdx < toIdx ? stopSequence.slice(fromIdx + 1, toIdx + 1) : stopSequence.slice(toIdx, fromIdx);

        for (const stop of stopsToCheck) {
            if (stop.stop_completion_time) {
                setNotification({
                    severity: 'warning',
                    message: 'Cannot rearrange completed stops.',
                });
                return { validateResult: false, stop0: null };
            }

            if (fromIdx < toIdx && FTLStopHelpers.isFinalDropoff(stop)) {
                setNotification({
                    severity: 'warning',
                    message: `Cannot manipulate final dropoff.`,
                });
                return { validateResult: false, stop0: null };
            }

            if (fromIdx > toIdx && stop.type === 'PICKUP' && selectedStop.type === 'DROPOFF') {
                for (const dropoffOrderKey of selectedStop.orders) {
                    if (stop.orders.includes(dropoffOrderKey)) {
                        setNotification({
                            severity: 'warning',
                            message: `Cannot place an order's dropoff address before its pickup address.`,
                        });
                        return { validateResult: false, stop0: null };
                    }
                }
            } else if (stop.type === 'DROPOFF' && selectedStop.type === 'PICKUP') {
                for (const pickupOrderKey of selectedStop.orders) {
                    if (stop.orders.includes(pickupOrderKey)) {
                        setNotification({
                            severity: 'warning',
                            message: `Cannot place an order's pickup address after its dropoff address.`,
                        });
                        return { validateResult: false, stop0: null };
                    }
                }
            }
        }

        return { validateResult: true, stop0: stopSequence[0] };
    };

    const onDragEnd = (result) => {
        let { validateResult, stop0 } = validateDrag(result);
        if (!validateResult) {
            return;
        }

        const { destination, source, draggableId } = result;
        const newStops = _.cloneDeep(route.stopsByRouteId);
        let fromIdx = source.index;
        let toIdx = destination.index;

        if (stop0.start) {
            fromIdx += 1;
            toIdx += 1;
        }

        for (const stop of newStops) {
            if (stop.ordering === fromIdx && (stop.stop_id === draggableId || stop.type === 'LUNCH')) {
                stop.ordering = toIdx;
            }
            if (
                fromIdx < toIdx &&
                stop.ordering > fromIdx &&
                stop.ordering <= toIdx &&
                stop.stop_id !== draggableId &&
                stop.type !== 'LUNCH'
            ) {
                stop.ordering -= 1;
            }
            if (
                fromIdx > toIdx &&
                stop.ordering >= toIdx &&
                stop.ordering < fromIdx &&
                stop.stop_id !== draggableId &&
                stop.type !== 'LUNCH'
            ) {
                stop.ordering += 1;
            }
        }

        const stopSequence = FTLStopHelpers.getStopSequence(newStops);
        const stopMovedUp = toIdx - fromIdx > 0;

        const ordersThatNeedRecalculating = (stopMovedUp ? stopSequence.slice(fromIdx) : stopSequence.slice(toIdx))
            .filter((stop) => FTLStopHelpers.isCustomerStop(stop, route))
            .reduce((acc, next) => {
                return [...acc, ...(next?.orders || [])];
            }, []);

        updateStops({
            variables: {
                route_id: route.route_id,
                route_update: {
                    need_to_optimize: true,
                },
                order_updates: [
                    {
                        where: { order_id: { _in: ordersThatNeedRecalculating } },
                        _set: {
                            delivery_time_confirmed: 'recalculate',
                        },
                    },
                ],
                ...FTLStopHelpers.gqlStopUpdates(stopSequence, route),
            },
        });
    };

    const handleChangeStartTime = (e) => {
        if (isLocked) {
            setNotification({
                severity: 'warning',
                message: 'Cannot change the start time for a route that has already been started.',
            });
        } else {
            updateRoute({
                variables: {
                    route_id: route.route_id,
                    update: {
                        start_time: new Date(e.target.value).toISOString(),
                        need_to_optimize: true,
                    },
                },
                onError: (error) => {
                    setError(error, 'Error setting route start time');
                },
            });
        }
    };

    const routeDriverComparison = (newRoute, otherRoute, _driver) => {
        // BOTH routes must have start times, must have stops, and must be optimized for this check to plausible.
        if (
            otherRoute &&
            otherRoute.start_time &&
            newRoute.start_time &&
            otherRoute?.stopsByRouteId[0].del_window_end &&
            newRoute?.stopsByRouteId[0].del_window_end &&
            !otherRoute.need_to_optimize &&
            !newRoute.need_to_optimize
        ) {
            const lastStopServiceTime =
                otherRoute.stopsByRouteId[otherRoute.stopsByRouteId.length - 1]?.service_time ||
                defaultServiceTime * 60 * 1000;

            const startTimeOfOtherRoute = new Date(otherRoute.start_time);
            const endTimeOfOtherRoute = new Date(
                new Date(otherRoute.stopsByRouteId[otherRoute.stopsByRouteId.length - 1].del_window_end).getTime() +
                    lastStopServiceTime
            );
            const startTimeOfNewRoute = new Date(newRoute.start_time);
            const endTimeOfNewRoute = new Date(
                new Date(newRoute.stopsByRouteId[newRoute.stopsByRouteId.length - 1].del_window_end).getTime() +
                    lastStopServiceTime
            );

            // If one route starts between the other route beginning and ending(+30 min)
            // Then notify the user
            if (
                (startTimeOfNewRoute >= startTimeOfOtherRoute &&
                    startTimeOfNewRoute - endTimeOfOtherRoute < 1000 * 60) ||
                (startTimeOfOtherRoute >= startTimeOfNewRoute && startTimeOfOtherRoute - endTimeOfNewRoute < 1000 * 60)
            ) {
                setModalOpen(MODALS.DRIVER_OVERLAP);
                setClashingRoutes([newRoute.route_number, otherRoute.route_number]);
                setClashingDriver(_driver);
                return true;
            }
        }
        return false;
    };

    const handleChangeTruck = (truck_id) => {
        updateRoute({
            variables: {
                route_id: route.route_id,
                update: {
                    truck_id: truck_id || null,
                },
            },
            onError: (error) => {
                setError(error, 'Error assigning route truck');
            },
        });
    };

    const handleChangeDriver = (newDriver, isHelper = false) => {
        if (isLocked) {
            setNotification({
                severity: 'warning',
                message: 'Cannot change the driver for a route that has already been started.',
            });
        } else {
            const attr = isHelper ? 'helper_id' : 'driver_id';
            let driverId = newDriver.teammate_id;
            if (driverId === 'NONE') driverId = null;

            if (driverId) {
                const otherRoute = routes.find((r) => r.route_id !== route.route_id && r[attr] === driverId);

                const driverTimeClash = routeDriverComparison(route, otherRoute, newDriver);
                if (driverTimeClash) return;
            }

            let routeUpdate = {
                [attr]: driverId,
            };
            if (driverId !== null) {
                routeUpdate.carrier_id = null;
            }

            updateRoute({
                variables: {
                    route_id: route.route_id,
                    update: routeUpdate,
                },
                onError: (error) => {
                    setError(error, 'Error setting route driver');
                },
            });
        }
    };

    const handleCarrierSelect = (e) => {
        if (isLocked) {
            setNotification({
                severity: 'warning',
                message: 'Cannot change the assigned carrier for a route that has already been started.',
            });
        } else {
            let routeUpdate = {};
            if (route.sending_to) {
                routeUpdate.sending_to = 'partner';
            }
            if (e.target.value !== 'NONE') {
                routeUpdate.carrier_id = e.target.value;
                routeUpdate.driver_id = null;
            } else {
                routeUpdate.carrier_id = null;
                routeUpdate.driver_id = null;
            }
            updateRoute({
                variables: {
                    route_id: route.route_id,
                    update: routeUpdate,
                },
            });
        }
    };
    const handlePartnerDriverSelect = (e) => {
        if (isLocked) {
            setNotification({
                severity: 'warning',
                message: 'Cannot change the assigned driver for a route that has already been started.',
            });
        } else {
            let routeUpdate = {};
            if (e.target.value !== 'NONE') {
                routeUpdate.driver_id = e.target.value;
            } else {
                routeUpdate.driver_id = null;
            }
            updateRoute({
                variables: {
                    route_id: route.route_id,
                    update: routeUpdate,
                },
            });
        }
    };

    const handleOptimizeRoute = async () => {
        if (isLocked) {
            setNotification({
                severity: 'warning',
                message: 'Cannot optimize a route that has already been started.',
            });
        } else if (route.stopsByRouteId.some((s) => s.stop_completion_time)) {
            setNotification({
                severity: 'warning',
                message: 'Cannot optimize a route that already has completed stops.',
            });
        } else if (!routeStartTime) {
            setNotification({
                severity: 'warning',
                message: 'Cannot optimize a route until a start time has been selected.',
            });
        } else if (route.stopsByRouteId.some((s) => s.overnight) && !startAddress) {
            setNotification({
                severity: 'warning',
                message: 'An route with overnight stops requires a start address to optimize.',
            });
        } else {
            try {
                setOptimizing(true);

                if (circles?.['plan-page-mapbox']) {
                    if (
                        route.stopsByRouteId.find(
                            (stop) => stop.type === 'LUNCH' && (!stop.earliest_start || !stop.latest_end)
                        )
                    ) {
                        setNotification({
                            severity: 'warning',
                            message: 'Lunch stop must have an optimized window set',
                        });
                        setOptimizing(false);
                        return;
                    }

                    const ordersByKey = Object.fromEntries(
                        (route.orders || []).map((mapping) => [mapping.order_id, mapping.order])
                    );
                    const stopsWithSvcTimes = route.stopsByRouteId.map((stop) => {
                        let service_time;
                        if (stop.service_time || stop.service_time === 0) {
                            service_time = stop.service_time;
                        } else {
                            const { hours, minutes } = calcDefaultServiceTime(stop, ordersByKey, clientUser);
                            service_time = ((hours || 0) * 60 + (minutes || 0)) * 60000;
                        }

                        return {
                            ...stop,
                            service_time,
                        };
                    });

                    const response = await post(MAPBOX_OPTIMIZE, {
                        route: {
                            route_id: route.route_id,
                            start_time: routeStartTime,
                            stops: stopsWithSvcTimes,
                        },
                        options: {
                            buffer: clientUser.preferences_timeframe_buffer_apply
                                ? clientUser.preferences_timeframe_buffer
                                : 0,
                            round: clientUser.preferences_timeframe_round,
                            window_length: clientUser.preferences_delivery_timeframe,
                            useStartInEstimate: preferences_start_estimate,
                            useEndInEstimate: preferences_end_estimate,
                            finish_returns_next_day: route?.finish_returns_next_day,
                        },
                    });

                    const { stops: stopsWithTimeframes, summary } = response.data;

                    const updates = stopsWithTimeframes
                        .filter((stop) => FTLStopHelpers.isCustomerStop(stop, route))
                        .map((stop) => {
                            return {
                                where: { order_id: { _in: stop.orders } },
                                _set: {
                                    del_window_start: stop.del_window_start,
                                    del_window_end: stop.del_window_end,
                                    ...(stop.orders.some(
                                        (order_id) => ordersByKey[order_id]?.delivery_time_confirmed === 'recalculate'
                                    )
                                        ? { delivery_time_confirmed: null }
                                        : {}),
                                },
                            };
                        });

                    updateStops({
                        variables: {
                            route_id: route.route_id,
                            route_update: {
                                start_time: routeStartTime,
                                need_to_optimize: false,
                                need_to_submit: true,
                                estimated_driving_distance: `${parseInt(
                                    summary.total_driving_distance * 0.000621371
                                )} miles`,
                                estimated_driving_time: hourMinuteFormatter.format(summary.total_driving_time / 60000),
                            },
                            order_updates: updates,
                            ...FTLStopHelpers.gqlStopUpdates(stopsWithTimeframes, route),
                        },
                        onCompleted: () => {
                            setOptimizing(false);
                        },
                        onError: (error) => {
                            setError(error, 'Error optimizing route');
                            setOptimizing(false);
                        },
                    });
                } else {
                    const optimizedStopSequence = await FTLStopHelpers.fetchOptimalStopsOrderV2(route.stopsByRouteId, {
                        shift_start: new Date(routeStartTime),
                        finish_returns_next_day: route?.finish_returns_next_day,
                    });

                    if (!optimizedStopSequence?.length) {
                        throw new Error(`Empty optimization results, check cloud function logs for more details`);
                    }

                    handleMapRoute(optimizedStopSequence);
                }
            } catch (error) {
                if (error?.response?.data?.error) {
                    setNotification({
                        severity: 'error',
                        message: error.response.data.error,
                    });
                } else {
                    Sentry.captureException(error);
                    console.error(error);
                    setNotification({
                        severity: 'error',
                        message: 'Failed to optimize route',
                    });
                }
                setOptimizing(false);
            }
        }
    };

    const handleMapRoute = async (newStops) => {
        if (isLocked) {
            setNotification({
                severity: 'warning',
                message: 'Cannot map a route that has already been started.',
            });
        } else if (!routeStartTime) {
            setNotification({
                severity: 'warning',
                message: 'Cannot map a route until a start time has been selected.',
            });
        } else {
            let stopSequence = FTLStopHelpers.getStopSequence(newStops || route.stopsByRouteId);
            const directionsData = await FTLStopHelpers.getGMapsDirections(stopSequence, new Date(routeStartTime));
            const directionsRoute = directionsData?.routes?.[0];

            if (directionsData?.status === 'ZERO_RESULTS') {
                setNotification({
                    severity: 'error',
                    message:
                        'Google directions cannot find a route found between the pickup and dropoff location. Please change the dropoff address to a nearby location and provide detailed instructions for drivers to locate the stop.',
                });
                setOptimizing(false);
                return;
            }

            let directionsExepctedLength = stopSequence.length - 1;
            stopSequence.forEach((s) => {
                if (isSkippedStop(s)) directionsExepctedLength--;
            });

            if (directionsRoute?.legs?.length !== directionsExepctedLength) {
                setNotification({
                    severity: 'error',
                    message:
                        'Google directions cannot find a route found between the pickup and dropoff location. Please change the dropoff address to a nearby location and provide detailed instructions for drivers to locate the stop.',
                });
                setOptimizing(false);
                return;
            }

            if (!directionsData.useTrafficEstimates) {
                setNotification({
                    severity: 'info',
                    message:
                        'Route Start Time is in the past. Cannot use traffic predictions, defaulting to historical average.',
                });
            }

            const ordersByKey = Object.fromEntries(
                (route.orders || []).map((mapping) => [mapping.order_id, mapping.order])
            );

            const { stopsWithTimeframes, estimatedDrivingDistance, estimatedDrivingTime } = calculateGoogleTimeframes(
                stopSequence,
                directionsRoute.legs,
                new Date(routeStartTime),
                ordersByKey,
                directionsData.useTrafficEstimates,
                clientUser,
                default_end_location,
                preferences_start_estimate,
                preferences_warehouse_return_estimate,
                preferences_end_estimate
            );

            const updates = stopsWithTimeframes
                .filter((stop) => FTLStopHelpers.isCustomerStop(stop, route))
                .map((stop) => {
                    return {
                        where: { order_id: { _in: stop.orders } },
                        _set: {
                            del_window_start: stop.del_window_start,
                            del_window_end: stop.del_window_end,
                            ...(stop.orders.some(
                                (order_id) => ordersByKey[order_id]?.delivery_time_confirmed === 'recalculate'
                            )
                                ? { delivery_time_confirmed: null }
                                : {}),
                        },
                    };
                });

            if (route.driver_id) {
                const otherRoute = routes.find((r) => r.route_id !== route.route_id && r.driver_id === route.driver_id);
                const _driver = drivers.find((i) => route?.driver_id === i.teammate_id);
                const driverTimeClash = routeDriverComparison(
                    { ...route, stopsByRouteId: stopsWithTimeframes, need_to_optimize: false },
                    otherRoute,
                    _driver
                );
                if (driverTimeClash) {
                    setOptimizing(false);
                    return;
                }
            }

            updateStops({
                variables: {
                    route_id: route.route_id,
                    route_update: {
                        start_time: routeStartTime,
                        need_to_optimize: false,
                        need_to_submit: true,
                        estimated_driving_distance: estimatedDrivingDistance,
                        estimated_driving_time: estimatedDrivingTime,
                    },
                    order_updates: updates,
                    ...FTLStopHelpers.gqlStopUpdates(stopsWithTimeframes, route),
                },
                onCompleted: () => {
                    setOptimizing(false);
                },
                onError: (error) => {
                    setError(error, 'Error getting route directions');
                },
            });
        }
    };

    const handleReverseStops = () => {
        if (isLocked) {
            setNotification({
                severity: 'warning',
                message: 'Cannot reverse stops for a route that has already been started.',
            });
        } else {
            const reversedStops = FTLStopHelpers.reverseStops(route.stopsByRouteId);
            handleMapRoute(reversedStops);
        }
    };

    const handleSubmitCheck = (sendingTo) => {
        if (isLocked) {
            setNotification({
                severity: 'warning',
                message: `Cannot submit a route that has already been started.`,
            });
            return false;
        } else if (routeIsMissingTimeframes()) {
            setNotification({
                severity: 'warning',
                message: 'Route is missing time frames. Please click "Map Route" or "Optimize" to add them.',
            });
            return false;
        } else if (stopsNeedRecalculating) {
            setNotification({
                severity: 'warning',
                message:
                    'Stops have been rearranged. Please click "Map Route" or "Optimize" to recalculate timeframes.',
            });
            return false;
        } else if (route.need_to_optimize && (sendingTo === 'marketplace' || !preferences_bypass_optimize)) {
            setNotification({
                severity: 'warning',
                message:
                    'You have made changes to this route that require timeframes to be recalculated. Please click "Map Route" or "Optimize" to recalculate timeframes.',
            });
            return false;
        }

        return true;
    };

    const handleSendToOnward = () => {
        const sendingTo = 'marketplace';
        if (handleSubmitCheck(sendingTo)) {
            const invalidOrder = (route.orders || []).find((mapping) => {
                const [, hasMissingField] = validate({ ...mapping.order, oms: false });
                return hasMissingField?.length > 0;
            })?.order;
            if (invalidOrder) {
                setActionableOrder({ ...invalidOrder, oms: false });
                setModalOpen(MODALS.ORDER_EDIT);

                setNotification({
                    severity: 'warning',
                    message: `Cannot submit to Onward, order incomplete`,
                });

                return;
            }

            if (allConfirmed) {
                pushRouteToTracking(route, sendingTo);
            } else {
                setActionableRoute(route);
                setModalOpen(MODALS.ROUTE_MARKETPLACE);
            }
        }
    };

    const handleSendToInternal = () => {
        const sendingTo = isPartnerRoute ? 'partner' : 'internal';
        if (handleSubmitCheck(sendingTo)) {
            if (allConfirmed) {
                pushRouteToTracking(route, sendingTo);
            } else {
                setActionableRoute(route);
                setModalOpen(MODALS.ROUTE_INTERNAL);
            }
        }
    };

    const handleCdsExport = () => {
        const data = (route.orders || []).map((mapping) => {
            const order = mapping.order;
            const stop = route?.stopsByRouteId?.find(
                (s) => FTLStopHelpers.isCustomerStop(s, route) && s.orders?.includes(order.order_id)
            );
            return {
                order,
                stop,
            };
        });

        const csvData = createCdsExportRows(data);

        csvDownload({
            data: csvData,
            filename: `${route?.route_alias || `Route ${route?.route_number}`} CDS Export`,
            headers: cdsRows.map((option) => option.display),
            delimiter: ',',
        });
    };

    const StopMetadata = ({ label, value, defaultValue }) => {
        return (
            <GridItemRow>
                {label && <Body2>{label}</Body2>}
                <Body1
                    css={css`
                        text-align: right;
                    `}
                >
                    {value || defaultValue}
                </Body1>
            </GridItemRow>
        );
    };

    const routeIsMissingTimeframes = () =>
        route?.stopsByRouteId?.some((stop) => {
            if (FTLStopHelpers.isFinalDropoff(stop) || stop.start || stop.end) {
                // pass check if stop is a final return stop, optimizeRoute does not create delwindow for final returns at the moment
                return false;
            }
            if (stop.overnight && FTLStopHelpers.isWarehousePickup(stop)) {
                return false;
            }
            return !stop.del_window_start || !stop.del_window_end;
        });

    const stopCount = useCustomerStops(route);

    const lunchStop = route?.stopsByRouteId?.find((stop) => stop.type === 'LUNCH');

    const partnerDrivers = useMemo(() => {
        if (!partner_carriers?.length || !partneredCarrier) return [];
        //iterate through partnereredCarrier and get all users who usertype as driver
        const partnerDrivers = [];
        partneredCarrier?.user?.teammatesByLeaderId?.forEach((user) => {
            if (user?.roles?.driver) {
                partnerDrivers.push(user);
            }
        });
        return partnerDrivers;
    }, [partneredCarrier]);

    const [anchor, setAnchor] = useState(null);
    const anchorRef = useRef(null);
    const containerRef = useRef(null);

    return (
        <>
            <PlanningListItem
                ref={anchorRef}
                css={css`
                    box-shadow: 0px 4px 4px 0px #00000040;
                    z-index: 5;
                `}
            >
                {routeExceedsTimeLimits && (
                    <GridItemRow>
                        <Body1
                            css={css`
                                color: ${colors.reds.primary};
                                text-align: center;
                            `}
                        >
                            Warning: Route exceeds configured time thresholds
                        </Body1>
                    </GridItemRow>
                )}

                {ordersAwaitingCD && (
                    <GridItemRow>
                        <Body1
                            css={css`
                                color: ${colors.reds.primary};
                                text-align: center;
                            `}
                            onClick={() => setModalOpen(MODALS.AWAITING_CD)}
                        >
                            Warning: This route has cross-docked orders with incomplete pickup legs.{' '}
                            <span
                                css={css`
                                    text-decoration: underline;
                                    cursor: pointer;
                                `}
                            >
                                More Info
                            </span>
                        </Body1>
                    </GridItemRow>
                )}

                <GridItemRow
                    css={css`
                        margin-bottom: 8px !important;
                    `}
                >
                    <H1
                        css={css`
                            font-weight: 800;
                            margin-bottom: 0;
                        `}
                    >
                        {route?.route_alias || `Route ${route?.route_number}`}
                    </H1>
                </GridItemRow>
                <GridItemRow
                    css={css`
                        margin-bottom: 16px !important;
                        padding-bottom: 16px !important;
                        border-bottom: 1px solid #e2e2e2;
                    `}
                >
                    <Body2>
                        {integerFormatter.format(totalItems)} items /{' '}
                        <span style={{ color: peakVolume > (truck?.truck_capacity || 1200) ? 'red' : 'inherit' }}>
                            {integerFormatter.format(totalVolume)} cu ft.
                        </span>{' '}
                        / {poundFormatter.format(totalWeight)} / {stopCount} stop{stopCount !== 1 ? 's' : ''}{' '}
                    </Body2>
                </GridItemRow>

                <GridItemRow
                    css={css`
                        margin-bottom: 12px !important;
                    `}
                >
                    <Button
                        onClick={() => setAnchor((prev) => (prev ? null : anchorRef?.current))}
                        variant="contained"
                        css={css`
                            color: black !important;
                            background-color: white !important;
                            border: 1px solid #a6a6a6;
                            margin-right: auto;
                        `}
                    >
                        Route Details
                    </Button>

                    {!isLocked && (
                        <>
                            {loading && <CircularProgress size={25} sx={{ color: 'darkgrey' }} />}

                            <RouteDetailsIconButton
                                onClick={() => handleOptimizeRoute()}
                                disabled={
                                    !route.stopsByRouteId?.length ||
                                    loading ||
                                    routeStartTime === '1970-01-01T00:00:00+00:00'
                                }
                                Icon={SettingsIcon}
                                loading={loading}
                                tooltip={'Optimize Route'}
                                css={css`
                                    margin-left: 21px;
                                `}
                            />

                            <RouteDetailsIconButton
                                onClick={() => handleMapRoute()}
                                disabled={
                                    !route.stopsByRouteId?.length ||
                                    loading ||
                                    routeStartTime === '1970-01-01T00:00:00+00:00'
                                }
                                Icon={NearMeIcon}
                                tooltip={'Map Route'}
                                css={css`
                                    margin-left: 21px;
                                `}
                            />

                            <RouteDetailsIconButton
                                onClick={handleReverseStops}
                                Icon={SwapVert}
                                disabled={routeHasMultiplePickups}
                                tooltip={'Reverse stop order'}
                                disabledTooltip={'Reverse stop order disabled because of multiple pick ups.'}
                                css={css`
                                    margin-left: 21px;
                                `}
                            />
                        </>
                    )}
                </GridItemRow>
            </PlanningListItem>

            <GrowingGrid container>
                <GridScrollY>
                    <StopBookend
                        label="Start Location"
                        open={startAddressAutocomplete}
                        value={startAddress}
                        stop={route?.stopsByRouteId?.find((stop) => stop.start)}
                        timeZone={routeTimeZone}
                        callbacks={{
                            onInput: (address) => setStartAddress(address),
                            onSelect: handleSelectStartAddress,
                            onFocus: () => setStartAddressAutocomplete(true),
                            onBlur: handleBlurStartAddress,
                            onClear: handleOnClear('start'),
                        }}
                        isLocked={isLocked}
                        isLoading={loading}
                        circles={circles}
                        overnight={route?.stopsByRouteId?.some((s) => !!s.overnight) || false}
                        routeLoading={routeLoading || optimizing}
                    />

                    {/* Stops */}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <StrictModeDroppable droppableId="planning-stops">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    css={css`
                                        width: 100%;
                                        min-height: 32px;
                                    `}
                                >
                                    {FTLStopHelpers.getStopSequence(route?.stopsByRouteId)
                                        .filter((stop) => !stop.start && !stop.end)
                                        .map((stop, idx) => (
                                            <Draggable
                                                isDragDisabled={
                                                    isLocked || FTLStopHelpers.isFinalDropoff(stop) || stopsLoading
                                                }
                                                key={`${stop.stop_id}`}
                                                draggableId={`${stop.stop_id}`}
                                                index={idx}
                                            >
                                                {(_provided, _snapshot) => (
                                                    <div
                                                        {..._provided.draggableProps}
                                                        {..._provided.dragHandleProps}
                                                        ref={_provided.innerRef}
                                                    >
                                                        <StopListItem
                                                            stop={stop}
                                                            route={route}
                                                            truck={truck}
                                                            isLocked={isLocked}
                                                            stopSequenceEnriched={stopSequenceEnriched}
                                                            subscribeToMore={subscribeToMore}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>

                    <StopBookend
                        label="End Location"
                        open={endAddressAutocomplete}
                        value={endAddress}
                        stop={route?.stopsByRouteId?.find((stop) => stop.end)}
                        timeZone={routeTimeZone}
                        callbacks={{
                            onInput: (address) => setEndAddress(address),
                            onSelect: handleSelectEndAddress,
                            onFocus: () => setEndAddressAutocomplete(true),
                            onBlur: handleBlurEndAddress,
                            onClear: handleOnClear('end'),
                        }}
                        isLocked={isLocked}
                        isLoading={loading}
                        location={stopCount > 0 ? 'top' : 'bottom'}
                    />
                </GridScrollY>
            </GrowingGrid>

            {!isLocked && (
                <Grid
                    container
                    css={css`
                        padding: 1rem;
                        justify-content: space-between;
                        row-gap: 0.5rem;
                        column-gap: 1rem;
                    `}
                >
                    <PrimaryButton
                        css={css`
                            flex-grow: 1;
                        `}
                        onClick={partneredCarrier ? handleSendToInternal : handleSendToOnward}
                        disabled={!circles?.['ltl-marketplace']}
                    >
                        Send to{' '}
                        {partneredCarrier ? (
                            <span
                                css={css`
                                    overflow: hidden;
                                `}
                            >
                                &nbsp;{`${partneredCarrier?.business_name}`}
                            </span>
                        ) : (
                            'Onward'
                        )}
                    </PrimaryButton>
                    <PrimaryButton
                        css={css`
                            flex-grow: 1;
                        `}
                        onClick={handleSendToInternal}
                        disabled={!!route.carrier_id && route.carrier_id !== user_id}
                    >
                        Send to Internal
                    </PrimaryButton>
                </Grid>
            )}

            <Popper open={Boolean(anchor)} anchorEl={anchor} onClose={() => setAnchor(null)} placement={'right-start'}>
                <PlanningListItem
                    css={css`
                        z-index: 4;
                        width: calc((100vw - ${sidebarCollapsed ? '50' : '246.21'}px) / 3);
                        box-shadow: 0px 4px 4px 0px #00000040;
                    `}
                >
                    <GridItemRow>
                        <GridItemRow>
                            {circles?.[CDS_TIMEFRAME_EXPORT] && (
                                <PrimaryButton onClick={handleCdsExport}>CDS Export</PrimaryButton>
                            )}
                        </GridItemRow>

                        <GridItemRow
                            css={css`
                                align-self: flex-end;
                                margin-bottom: 21px !important;
                                margin-top: 11px !important;
                            `}
                        >
                            <IconButton
                                onClick={() => {
                                    setAnchor(null);
                                }}
                                css={css`
                                    padding: 0 !important;
                                `}
                            >
                                <Clear
                                    css={css`
                                        color: black;
                                    `}
                                />
                            </IconButton>
                        </GridItemRow>
                    </GridItemRow>

                    <StopMetadata
                        label="Estimated Distance"
                        value={route?.estimated_driving_distance}
                        defaultValue="N/A"
                    />

                    <StopMetadata label="Estimated Route Time" value={totalTime} defaultValue="N/A" />

                    <StopMetadata
                        label="Estimated Driving Time"
                        value={route?.estimated_driving_time}
                        defaultValue="N/A"
                    />

                    {!isLocked && route.scheduled_delivery ? (
                        <GridItemRow>
                            <Body2>Pickup Arrival Time:</Body2>
                            <FormControl
                                css={css`
                                    min-width: 120px;
                                    flex-grow: 1;
                                    padding-left: 10px;

                                    .MuiInputLabel-formControl {
                                        position: static;
                                        transform: none;
                                    }
                                `}
                            >
                                <StartTimeSelect
                                    disabled={inProgress}
                                    onChange={handleChangeStartTime}
                                    value={routeStartTime.getTime()}
                                    timeZone={routeTimeZone}
                                    deliveryDate={route.scheduled_delivery}
                                    interval={30}
                                    css={css`
                                        width: auto !important;
                                        & .MuiFormControl-root {
                                            width: 100%;
                                        }
                                    `}
                                />
                            </FormControl>
                        </GridItemRow>
                    ) : (
                        <StopMetadata
                            label="Pickup Arrival Time"
                            value={routeStartTime ? dateTimeFormatter(routeStartTime) : 'N/A'}
                            defaultValue="N/A"
                        />
                    )}

                    {routeEndTime && (
                        <StopMetadata
                            label="Route End Time"
                            value={dateTimeFormatter(routeEndTime)}
                            defaultValue="N/A"
                        />
                    )}

                    <GridItemRow>
                        {lunchStop ? (
                            <>
                                <Body2>Lunch</Body2>
                                <Body1
                                    css={css`
                                        text-decoration: underline;
                                        cursor: pointer;
                                    `}
                                    onClick={(e) => setModalOpen(MODALS.DRIVER_LUNCH)}
                                >
                                    {`${hourMinuteFormatter.format(lunchStop.service_time / 60000)}, ${
                                        lunchStop.ordering === 1
                                            ? `after Pickup`
                                            : `after Stop ${lunchStop.ordering - 1}`
                                    }`}
                                </Body1>
                            </>
                        ) : (
                            <Body1
                                css={css`
                                    text-decoration: underline;
                                    cursor: pointer;
                                `}
                                onClick={(e) => setModalOpen(MODALS.DRIVER_LUNCH)}
                            >
                                Add Lunch
                            </Body1>
                        )}
                    </GridItemRow>

                    {!!drivers.length &&
                        (!isLocked ? (
                            <>
                                <GridItemRow>
                                    <Body2>Driver: </Body2>
                                    <DriverCombobox
                                        drivers={drivers}
                                        onChange={handleChangeDriver}
                                        value={driver?.teammate_id || 'NONE'}
                                        disabled={inProgress || (route?.carrier_id && route.carrier_id !== user_id)}
                                        containerStyles={`
                                    padding-left: 14px;
                                `}
                                    />
                                </GridItemRow>
                                {helpers.length > 0 ? (
                                    <GridItemRow>
                                        <Body2>Helper: </Body2>
                                        <DriverCombobox
                                            helper={true}
                                            drivers={helpers}
                                            onChange={(e) => handleChangeDriver(e, true)}
                                            value={helper?.teammate_id || 'NONE'}
                                            disabled={inProgress || (route?.carrier_id && route.carrier_id !== user_id)}
                                            containerStyles={`
                                    padding-left: 14px;
                                `}
                                        />{' '}
                                    </GridItemRow>
                                ) : null}
                            </>
                        ) : partneredCarrier ? (
                            <></>
                        ) : (
                            <StopMetadata label="Driver" value={driver?.username} defaultValue="N/A" />
                        ))}

                    {!!partner_carriers.length &&
                        (!isLocked ? (
                            <GridItemRow>
                                <Body2>Carrier: </Body2>
                                <FormControl
                                    css={css`
                                        min-width: 120px;
                                        flex-grow: 1;
                                        padding-left: 10px;
                                        margin-top: 5px;

                                        .MuiInputLabel-formControl {
                                            position: static;
                                            transform: none;
                                        }
                                    `}
                                >
                                    <InputLabel>
                                        <Select
                                            defaultLabel="Select a carrier..."
                                            menuItems={partner_carriers?.map((partnership) => ({
                                                key: partnership.carrier_id,
                                                value: partnership.carrier?.business_name,
                                            }))}
                                            containerStyles={{
                                                display: 'flex',
                                                width: '100%',
                                                color: '#212529',
                                                overflow: 'auto',
                                            }}
                                            css={css`
                                                flex-grow: 1;
                                                & .MuiFormControl-root {
                                                    width: 100% !important;
                                                }
                                                & legend {
                                                    display: none;
                                                }
                                            `}
                                            onChange={handleCarrierSelect}
                                            value={route?.carrier_id || 'NONE'}
                                            disabled={inProgress || route?.driver_id}
                                        />
                                    </InputLabel>
                                    {route.carrier_id && (
                                        <InputLabel>
                                            <Select
                                                defaultLabel="Select a partner driver..."
                                                menuItems={partnerDrivers?.map((driver) => ({
                                                    key: driver.teammate_id,
                                                    value: driver.username,
                                                }))}
                                                containerStyles={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    color: '#212529',
                                                    overflow: 'auto',
                                                    marginTop: '5px',
                                                }}
                                                css={css`
                                                    flex-grow: 1;
                                                    & .MuiFormControl-root {
                                                        width: 100% !important;
                                                    }
                                                    & legend {
                                                        display: none;
                                                    }
                                                `}
                                                onChange={handlePartnerDriverSelect}
                                                value={route?.driver_id || 'NONE'}
                                                disabled={
                                                    inProgress ||
                                                    !route?.carrier_id ||
                                                    (route.carrier_id && route.carrier_id === user_id)
                                                }
                                                onCompleted={(e) => console.log(e)}
                                            />
                                        </InputLabel>
                                    )}
                                </FormControl>
                            </GridItemRow>
                        ) : partneredCarrier ? (
                            <StopMetadata label="Carrier" value={partneredCarrier.business_name} defaultValue="N/A" />
                        ) : (
                            <></>
                        ))}

                    {trucks.length > 0 && (
                        <>
                            {isLocked ? (
                                <StopMetadata label="Truck" value={truck?.truck_name} defaultValue="N/A" />
                            ) : (
                                <GridItemRow>
                                    <Body2>Truck: </Body2>
                                    <TextField
                                        css={css`
                                            width: 100%;
                                            margin-left: 1rem;
                                            .MuiSelect-selectMenu {
                                                padding: 9px;
                                            }
                                        `}
                                        select
                                        variant="outlined"
                                        value={truck?.truck_id || ''}
                                        onChange={(e) => handleChangeTruck(e.target.value)}
                                    >
                                        <MenuItem key={'null-truck'} value={''}>
                                            None
                                        </MenuItem>
                                        {trucksForToday?.map((truck) => (
                                            <MenuItem key={truck.truck_id} value={truck.truck_id}>
                                                {truck.truck_name}{' '}
                                                <span style={{ fontSize: 12 }}>
                                                    ({truck.truck_capacity ? truck.truck_capacity : 1200} cubes)
                                                </span>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </GridItemRow>
                            )}
                        </>
                    )}
                </PlanningListItem>
            </Popper>
        </>
    );
}

export default PlanningStops;
