import React from 'react';
import { Row, Container, Card, Form, Col } from 'react-bootstrap';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useClientUser } from '@/hooks';

const theme = createTheme({
    palette: {
        primary: {
            main: '#71b77a',
        },
    },
});

export default function Team() {
    const { email, businessName, businessPhone } = useClientUser();

    return (
        <Card className="tabCard">
            <Card.Body className="overflow-auto">
                <Container fluid>
                    <ThemeProvider theme={theme}>
                        <Form>
                            <Row className="d-flex">
                                <Col>
                                    <Form.Group className="my-2 w-100">
                                        <h5>{businessName}</h5>
                                        <div>Phone: {businessPhone}</div>
                                        <div>Email: {email}</div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </ThemeProvider>
                </Container>
            </Card.Body>
        </Card>
    );
}
