import React, { useState, useEffect, useMemo } from 'react';
import { Grid, DialogContent, Button, TextField } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ResponsiveSidebarDialog } from '@/styles/blocks';
import { colors } from '@/styles';
import { Body1, H2, H3, PrimaryButton } from './blocks';
import { useMutation } from '@apollo/client';
import { UPDATE_STOP_EVENTS_MANY } from './graphql';
import { format } from 'date-fns';
import { toArrayLiteral } from '@/utilities/toArrayLiteral';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Row = styled.div`
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
`;

const CompleteStopModal = ({
    open,
    onClose,
    stop,
    route,
    setErrorMessage,
    setStopSaving,
    rescheduledStops,
    cancelledStops,
    stopOrderMappings,
}) => {
    const [arrivalTime, setArrivalTime] = useState(null);
    const [completionTime, setCompletionTime] = useState(null);
    const [consigneeSignature, setConsigneeSignature] = useState(null);
    const [signatureTime, setSignatureTime] = useState(null);
    const [localError, setLocalError] = useState(null);
    const [pickupError, setPickupError] = useState(null);
    const [pods, setPods] = useState([]);
    const [markStopComplete, { loading }] = useMutation(UPDATE_STOP_EVENTS_MANY);

    useEffect(() => {
        if (stop?.arrival_time) {
            setArrivalTime(format(new Date(stop.arrival_time), "yyyy-MM-dd'T'HH:mm"));
        }
        if (stop?.stop_completion_time) {
            setCompletionTime(format(new Date(stop.stop_completion_time), "yyyy-MM-dd'T'HH:mm"));
        }
    }, [stop]);

    useEffect(() => {
        if (stop.type === 'DROPOFF') {
            const incompletePickups = route.stopsByRouteId.filter(
                (s) =>
                    s.type === 'PICKUP' &&
                    (stop.orders || []).some(
                        (order_id) => (s.orders || []).includes(order_id) && !s.stop_completion_time
                    )
            );
            if (incompletePickups.length > 0) {
                setPickupError(
                    `All pickup stops must be completed before a dropoff can be completed. Please complete the following stops: ${[
                        ...new Set(incompletePickups.map((stop) => stop.ordering)),
                    ]
                        .sort((a, b) => a - b)
                        .join(', ')}`
                );
            } else {
                setPickupError('');
            }
        }
    }, [route, stop]);

    const stopOrderMap = useMemo(
        () => (route?.orders || []).filter((mapping) => (stop.orders || []).includes(mapping.order_id)),
        [route?.orders, stop.orders]
    );

    const markRouteComplete = useMemo(() => {
        const incompleteStops = route?.stopsByRouteId?.filter(
            (s) =>
                !s?.stop_completion_time &&
                !rescheduledStops.some(({ stop: { stop_id }, rescheduled }) => stop_id === s.stop_id && rescheduled) &&
                !cancelledStops.some(({ stop: { stop_id }, cancelled }) => stop_id === s.stop_id && cancelled)
        );
        if (incompleteStops.length === 1 && incompleteStops[0].stop_id === stop.stop_id) {
            return true;
        }
        return false;
    }, [route]);

    const handleMarkComplete = () => {
        try {
            setStopSaving(true);
            const completion_time = new Date().toISOString();
            let stopUpdates = [
                {
                    where: { stop_id: { _eq: stop.stop_id } },
                    _set: {
                        arrival_time: arrivalTime,
                        stop_completion_time: completionTime,
                        actions_completed: stop.orders ? toArrayLiteral(stop.orders) : null,
                    },
                },
            ];
            // Get the order ids that are to be marked as complete
            // If stop is type PICKUP, we want to set the orders as routedInProgress
            // If stop is type DROPOFF, we want to set the orders as routedCompleted

            let orderUpdates = [];
            let eventUpdates = [];
            let insertEvents = [];
            let routeUpdates = [];

            if (markRouteComplete) {
                routeUpdates = [
                    {
                        where: { route_id: { _eq: route.route_id } },
                        _set: {
                            status: 'complete',
                            completed: completion_time,
                            last_update_source: 'web',
                        },
                    },
                ];
            }

            stopOrderMap.forEach(({ type, order }) => {
                if (stop.type === 'PICKUP') {
                    switch (order.event_state) {
                        case 'ROUTED_PU':
                        case 'CD_PENDING':
                            //cross docked pickup route
                            orderUpdates.push({
                                where: { order_id: { _eq: order.order_id } },
                                _set: {
                                    ltl_pickup_completed: completionTime,
                                    last_update_source: 'web',
                                },
                            });
                            const lastRouteEvent = [...order.wh_events]
                                .reverse()
                                .find((event) => event.route_id === route.route_id);

                            eventUpdates.push({
                                where: { event_id: { _eq: lastRouteEvent.event_id } },
                                _set: {
                                    pickup_date: completionTime,
                                },
                            });
                            break;
                        case 'CD_RECEIVED':
                            //cross docked dropoff route
                            orderUpdates.push({
                                where: { order_id: { _eq: order.order_id } },
                                _set: {
                                    order_status: 'routedInProgress',
                                    last_update_source: 'web',
                                },
                            });
                            insertEvents.push({
                                order_id: order.order_id,
                                // route_id: route.route_id,
                                action: 'CD_RECEIVED:CONFIRM',
                                status: null,
                            });
                            break;
                        case 'PICKED_UP':
                            //normal order
                            orderUpdates.push({
                                where: { order_id: { _eq: order.order_id } },
                                _set: {
                                    order_status: 'routedInProgress',
                                    last_update_source: 'web',
                                },
                            });
                            insertEvents.push({
                                order_id: order.order_id,
                                // route_id: route.route_id,
                                action: 'PICKED_UP:CONFIRM',
                                status: null,
                            });
                            break;
                        default:
                            console.log(`No action needed on pickup for event state: ${order.event_state}`);
                    }
                } else if (stop.type === 'DROPOFF') {
                    switch (order.event_state) {
                        case 'ROUTED_PU':
                            //cross docked pickup route (dropoff route not yet assigned)
                            insertEvents.push({
                                order_id: order.order_id,
                                // route_id: route.route_id,
                                action: 'ROUTED_PU:PU_SUCCESS',
                                status: 'RECEIVED',
                            });
                            break;
                        case 'CD_PENDING':
                            //cross docked pickup route (dropoff route assigned)
                            insertEvents.push({
                                order_id: order.order_id,
                                // route_id: route.route_id,
                                action: 'CD_PENDING:PU_SUCCESS',
                                status: 'RECEIVED',
                            });
                            break;
                        case 'CD_COMPLETE':
                            // normal orders & cross docked dropoff route
                            orderUpdates.push({
                                where: { order_id: { _eq: order.order_id } },
                                _set: {
                                    ltl_dropoff_arrival: arrivalTime || new Date().toISOString(),
                                    last_update_source: 'web',
                                    completion_time: completionTime,
                                    ...(order.job_type !== 'PICKUP_AND_WILL_CALL'
                                        ? { order_status: 'routedComplete' }
                                        : {}),
                                },
                            });
                            break;
                        default:
                            console.log(`No action needed on dropoff for event state: ${order.event_state}`);
                    }
                }
            });

            markStopComplete({
                variables: {
                    routeUpdates: routeUpdates,
                    orderUpdates: orderUpdates,
                    stopUpdates: stopUpdates,
                    eventUpdates: eventUpdates,
                    insertEvents: insertEvents,
                },
                onError: (error) => {
                    setErrorMessage('Looks like something went wrong. We were unable to complete your stop.');
                    Sentry.captureException(error);
                    console.error(error.message);
                },
                onCompleted: () => {
                    onClose();
                },
            });
        } catch (error) {
            setErrorMessage('Looks like something went wrong. We were unable to complete your stop.');
            console.error(error);
            Sentry.captureException(error);
        } finally {
            setStopSaving(false);
            onClose();
        }
    };

    return (
        <ResponsiveSidebarDialog
            // collapsed={collapseSideNav}
            open={open}
            onClose={onClose}
        >
            <DialogContent>
                <Header>
                    <H2>Are you sure you want to manually complete the stop?</H2>
                </Header>
                <Body1>This cannot be undone.</Body1>
                <Grid>
                    <Row>
                        <TextField
                            fullWidth
                            label="Arrival Time"
                            type="datetime-local"
                            variant="outlined"
                            value={arrivalTime ? format(new Date(arrivalTime), "yyyy-MM-dd'T'HH:mm") : ''}
                            onChange={(e) => {
                                let dateStr = null;
                                if (e.target.value) {
                                    dateStr = new Date(e.target.value).toISOString();
                                    if (completionTime && new Date(dateStr) > new Date(completionTime)) {
                                        setLocalError('Arrival time cannot be after completion time');
                                    } else {
                                        setLocalError('');
                                    }
                                }
                                setArrivalTime(dateStr);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!localError}
                            helperText={localError}
                        />
                    </Row>
                    <Row>
                        <TextField
                            fullWidth
                            label="Completion Time"
                            type="datetime-local"
                            variant="outlined"
                            value={completionTime ? format(new Date(completionTime), "yyyy-MM-dd'T'HH:mm") : ''}
                            onChange={(e) => {
                                let dateStr = null;
                                if (e.target.value) {
                                    const selectedDate = new Date(e.target.value);
                                    if (arrivalTime && selectedDate > new Date(arrivalTime)) {
                                        dateStr = selectedDate.toISOString();
                                    }
                                }
                                setCompletionTime(dateStr);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={!arrivalTime}
                            InputProps={{
                                min: arrivalTime ? format(new Date(arrivalTime), "yyyy-MM-dd'T'HH:mm") : undefined,
                                max: '9999-12-31T23:59',
                            }}
                        />
                    </Row>
                    {/* <Row>
                        <TextField
                            fullWidth
                            label="Consignee Signature (Print Name)"
                            variant="outlined"
                            value={consigneeSignature}
                            onChange={(e) => setConsigneeSignature(e.target.value)}
                        />
                    </Row>
                    <Row>
                        <TextField
                            fullWidth
                            label="Signature Time"
                            type="datetime-local"
                            variant="outlined"
                            value={signatureTime ? format(new Date(signatureTime), "yyyy-MM-dd'T'HH:mm") : ''}
                            onChange={(e) => {
                                let dateStr = null;
                                if (e.target.value) {
                                    dateStr = new Date(e.target.value).toISOString();
                                }
                                setSignatureTime(dateStr);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Row> */}

                    <Row>
                        <Body1
                            css={css`
                                color: ${colors.greys.primary};
                            `}
                        >
                            Note: This should be reserved only for situations where the stop is in a stuck state due to
                            driver app issues.
                        </Body1>
                    </Row>
                    {pickupError && (
                        <Row>
                            <Body1
                                css={css`
                                    color: ${colors.reds.primary};
                                `}
                            >
                                {pickupError}
                            </Body1>
                        </Row>
                    )}

                    <Row>
                        <Button variant="outlined" onClick={onClose}>
                            <H3>Cancel</H3>
                        </Button>
                        <PrimaryButton
                            variant="contained"
                            onClick={handleMarkComplete}
                            disabled={!arrivalTime || !completionTime || localError || loading || pickupError}
                        >
                            <H3>Submit</H3>
                        </PrimaryButton>
                    </Row>
                </Grid>
            </DialogContent>
        </ResponsiveSidebarDialog>
    );
};

export default CompleteStopModal;
