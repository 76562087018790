class HubSpotControls {
    constructor() {
        this.ref = document.body;
    }

    hide() {
        this.ref.classList.add('hide-hubspot');
    }

    show() {
        this.ref.classList.remove('hide-hubspot');
    }

    positionLeft() {
        this.ref.classList.add('position-left');
    }

    positionRight() {
        this.ref.classList.remove('position-left');
    }

    expanded() {
        this.ref.classList.add('expanded');
    }

    collapsed() {
        this.ref.classList.remove('expanded');
    }
}

export default new HubSpotControls();
