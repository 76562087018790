import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';

export const OuterContainer = styled((props) => <Grid container {...props} />)`
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
`;

export const Container = styled((props) => <Grid container {...props} />)`
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    padding: 1.5rem 3rem;
    flex-grow: 1;
`;

export const Row = styled((props) => <Grid item {...props} />)`
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
    align-items: center;
`;

export const Title = styled.span`
    font-size: 20px;
    font-weight: 800;
`;

export const PopoverContainer = styled((props) => <Grid container {...props} />)`
    flex-direction: column;
    flex-wrap: nowrap;
    width: 600px;
    padding: 0.5rem 0.5rem;
`;

export const PopoverRow = styled((props) => <Grid item {...props} />)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MuiFormControl-root {
        width: 100%;
        margin: 0.5rem 0.5rem;
    }
    .MuiInputLabel-formControl {
        margin-left: 0.5rem;
        margin-top: -0.25rem;
        background-color: white;
        z-index: 5;
        padding: 0 0.5rem;
        color: black;
    }
`;
