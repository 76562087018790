import React, { useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import Haulaway from '@/components/Customer/ShipmentForm/Haulaway';
import { Context } from '../context';
import useQuery from '@/utilities/useQuery';
import * as Sentry from '@sentry/react';

import { local } from '@/components/Customer/blocks';
import UpgradeDialog from './UpgradeDialog';
import { priceCustomerUpgrades } from './queries';
import Pricing from './Pricing';
import Payment from '@/components/Customer/ShipmentForm/Payment';
import { ServiceLevelSelection } from '@/components/Customer/ShipmentForm/Dropoff';
import { DROPOFF_TYPES } from '@/components/ShipmentForm/constants/dropoffOptions';
import Footer from '@/components/Customer/ShipmentForm/Footer';
import Snackbar from '@/components/Snackbar';

const STAGES = {
    HAULAWAY: 'HAULAWAY',
    ASYNC: 'ASYNC',
    PAYMENT: 'PAYMENT',
    SERVICE_LEVEL: 'SERVICE_LEVEL',
};

function AddUpgradeDialog({ order, ordersRefetch }) {
    const { closeUpgradeDialog, upgradeDialog, upgradeDialogOpen } = useContext(Context);
    const DEFAULT_HAULAWAY = {
        quantity: 1,
        item_type: 'household',
        is_return: false,
    };

    const defaultProgressionHaulaway = {
        current: 0,
        stages: [STAGES.HAULAWAY, STAGES.ASYNC, STAGES.PAYMENT],
    };
    const defaultSectionsHaulaway = [
        {
            haulaway_items: [{ ...DEFAULT_HAULAWAY }],
            type: 'haulaway_upgrade',
        },
        {},
        {},
    ];
    const defaultSectionsService = useMemo(() => {
        let currentSelectionTier = DROPOFF_TYPES.Residence.find((i) => i.value === order?.dropoff_location_type)?.tier;
        if (!Number.isInteger(currentSelectionTier)) {
            currentSelectionTier = -1;
        }
        const hiddenSelections = DROPOFF_TYPES.Residence?.filter((i) => i.tier <= currentSelectionTier);

        return [
            {
                type: 'service_upgrade',
                dropoff_location_type: '',
                dropoff_location: order?.dropoff_location || '',
                hiddenSelections: hiddenSelections,
            },
            {},
            {},
        ];
    }, [order]);
    const defaultProgressionService = {
        current: 0,
        stages: [STAGES.SERVICE_LEVEL, STAGES.ASYNC, STAGES.PAYMENT],
    };

    const [progression, setProgression] = useState({});
    const [sections, setSections] = useState({});
    const [paymentInfo, setPaymentInfo] = useState({});
    const [upgradeInfo, setUpgradeInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        resetState();
    }, [upgradeDialog]);

    const resetState = () => {
        const type = upgradeDialog?.type;
        switch (type) {
            case 'haulaway_upgrade':
                setProgression(defaultProgressionHaulaway);
                setSections(defaultSectionsHaulaway);
                break;
            case 'service_upgrade':
                setProgression(defaultProgressionService);
                setSections(defaultSectionsService);
                break;
            default:
                setProgression({});
                setSections({});
                break;
        }
        setPaymentInfo({});
        setUpgradeInfo({});
        setLoading(false);
    };

    const [priceUpgrades] = useQuery(priceCustomerUpgrades, {
        onComplete: ({ data }) => {
            if (data?.free) {
                ordersRefetch().then(() => {
                    setErrorMessage({
                        message: 'Your order has been successfully upgraded.',
                        severity: 'success',
                    });
                    resetState();
                    closeUpgradeDialog();
                });
                return;
            }

            setPaymentInfo(data.paymentInfo);
            setUpgradeInfo({
                upgradePrice: data.upgradePrice,
                upgradePriceBreakdown: data.upgradePriceBreakdown,
            });

            setProgression((prev) => {
                return { ...prev, current: prev.current + 1 };
            });
        },
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
            setErrorMessage({
                message: 'There was an error with pricing your upgrade.',
                severity: 'error',
            });
            closeUpgradeDialog();
            setProgression((prev) => {
                return { ...prev, current: 0 };
            });
        },
    });

    const confirmPayment = useCallback(async (stripe, elements) => {
        if (!stripe || !elements) return;

        setLoading(true);
        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {},
        });

        if (result.error) {
            console.error(result.error.message);
            setErrorMessage({
                message: 'There was an error with confirming your payment.',
                severity: 'error',
            });
        } else {
            setTimeout(() => {
                ordersRefetch().then(() => {
                    setErrorMessage({
                        message: 'Your order has been successfully upgraded.',
                        severity: 'success',
                    });
                    resetState();
                    setLoading(false);
                    closeUpgradeDialog();
                });
            }, 5000);
        }
    }, []);

    const advance = useCallback((section) => {
        const stage = progression.stages[progression.current];

        if (stage === STAGES.PAYMENT) {
            confirmPayment(section.stripe, section.elements);
        } else {
            if (stage === STAGES.HAULAWAY) {
                priceUpgrades({
                    order: order,
                    haulaways: section?.haulaway_items,
                    type: section?.type,
                });
            }
            if (stage === STAGES.SERVICE_LEVEL) {
                priceUpgrades({
                    order: order,
                    type: section?.type,
                    serviceLevel: section?.dropoff_location_type,
                });
            }

            setSections((prev) => {
                const clone = [...prev];
                clone[progression.current] = section;

                return clone;
            });
            setProgression((prev) => {
                return { ...prev, current: prev.current + 1 };
            });
        }
    });

    let content;
    switch (progression?.stages?.[progression?.current]) {
        case STAGES.HAULAWAY:
            content = (
                <Haulaway
                    customerUpgrade={true}
                    state={{
                        progression,
                        sections,
                    }}
                    callbacks={{
                        advance: (section) => {
                            advance(section);
                        },
                        revert: () => {
                            closeUpgradeDialog();
                        },
                    }}
                />
            );
            break;
        case STAGES.ASYNC:
            content = <Pricing />;
            break;
        case STAGES.SERVICE_LEVEL:
            content = (
                <Grid
                    container
                    direction="column"
                    css={css`
                        color: ${local.greys[3]};
                    `}
                >
                    <ServiceLevelSelection
                        section={sections?.[0]}
                        setSection={(newVal) => {
                            setSections((prev) => {
                                const currentSection = prev?.[progression.current];
                                const updatedSection = {
                                    ...currentSection,
                                    dropoff_location_type: newVal,
                                };

                                const sectionsClone = [...prev];
                                sectionsClone[progression.current] = updatedSection;

                                return sectionsClone;
                            });
                        }}
                    />
                    <Footer
                        state={{
                            progression,
                            sections,
                        }}
                        isFirst={false}
                        isLast={false}
                        hasErrors={!sections?.[progression?.current]?.dropoff_location_type}
                        callbacks={{
                            advance: () => advance(sections?.[progression?.current]),
                            revert: () => {
                                closeUpgradeDialog();
                            },
                        }}
                    />
                </Grid>
            );
            break;
        case STAGES.PAYMENT:
            content = (
                <Payment
                    customerUpgrade={true}
                    state={{
                        progression,
                        sections,
                        paymentInfo,
                        upgradeInfo,
                        order: {
                            ...order,
                            shipper_rate: upgradeInfo.upgradePrice,
                            price_breakdown: upgradeInfo.upgradePriceBreakdown,
                        },
                        isLoading: loading,
                    }}
                    callbacks={{
                        advance: (section) => {
                            advance(section);
                        },
                        revert: () => {
                            setProgression((prev) => {
                                return { ...prev, current: 0 };
                            });
                        },
                    }}
                />
            );
            break;
        default:
            content = <></>;
    }

    return (
        <>
            <UpgradeDialog open={!!upgradeDialogOpen}>{content}</UpgradeDialog>
            <Snackbar
                open={!!errorMessage?.message}
                severity={errorMessage?.severity}
                message={errorMessage?.message}
                handleClose={() => setErrorMessage(null)}
            />
        </>
    );
}

export default AddUpgradeDialog;
