import _ from 'lodash';

const defaultOrders = {
    active: [],
    pending: [],
    payable: [],
    complete: [],
};

const searchableProps = [
    'order_number',
    'po_number',
    'business',
    'dropoff_name',
    'pickup_city',
    'dropoff_city',
    'pickup_date',
    'pickup_zip',
    'dropoff_zip',
    'dropoff_location',
    'dropoff_location_info',
    'dropoff_location_type',
    'delivery_date',
    'del_time',
    'pu_date',
    'price',
    'order_type',
    'dropoff_street',
];

const getSearchableValues = (order) =>
    Object.values(_.pick(order, searchableProps)).map((val) => {
        if (val?.constructor === String) {
            return val.toLowerCase();
        }

        return val?.toString() || '';
    });

export const includesSearchProp = (order, searchString) => {
    const searchableValues = getSearchableValues(order);

    for (const val of searchableValues) {
        if (val.includes(searchString)) {
            return true;
        }
    }

    return false;
};

const initializeSearchedOrders = (allOrders) => {
    const searchedOrders = {};

    const orderStatuses = Object.keys(allOrders);

    for (const orderStatus of orderStatuses) {
        searchedOrders[orderStatus] = [];
    }

    return searchedOrders;
};

const searchOrders = (searchString = '', allOrders = defaultOrders) => {
    const lCaseSearchString = searchString.toLowerCase();

    const searchedOrders = initializeSearchedOrders(allOrders);

    Object.keys(allOrders).forEach((orderStatus) => {
        for (const order of allOrders[orderStatus]) {
            const included = includesSearchProp(order, lCaseSearchString);

            if (included) {
                searchedOrders[orderStatus].push(order);
            }
        }
    });

    return searchedOrders;
};

export default searchOrders;
