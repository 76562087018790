import React, { useState, useMemo, useContext } from 'react';
import { css } from '@emotion/react';
import { Grid, TextareaAutosize, Button, IconButton } from '@material-ui/core';
import { ToggleButton, Rating } from '@material-ui/lab';
import { Check } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMutation } from '@apollo/client';

import { UserContext } from '@/components/App';
import {
    ResponsiveSidebarDialog,
    ModalTitle,
    StickyModalActions,
    PrimaryButton,
    SecondaryButton,
} from '@/styles/blocks';

import { UPSERT_RATING } from './graphql/mutations';
import { Body1 } from './blocks';
import { CHIPS } from './constants';

const CarrierReviewModal = ({
    revieweeId,
    revieweeType = 'CARRIER',
    isOpen,
    driverId,
    orderId,
    reviewObject = {},
    callbacks,
}) => {
    const { user } = useContext(UserContext);
    const isDesktop = useMediaQuery('(min-width: 500px)');
    const [submitRating] = useMutation(UPSERT_RATING, {
        update: (cache, { data }) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'ratings' });
        },
    });

    const [userRating, setUserRating] = useState({});

    const combined = useMemo(() => {
        const { __typename, ...remaining } = reviewObject || { __typename: '' };
        const _review = {
            ...remaining,
            ...userRating,
            order_id: orderId,
            chips: {
                ...Object.fromEntries(reviewObject?.chips?.map((name) => [name, true]) || []),
                ...userRating.chips,
            },
            reviewee_id: revieweeId,
            reviewee_type: revieweeType,
            reviewer_id: user?.user_id,
            reviewer_type: user?.roles.SHIPPER ? 'SHIPPER' : user?.roles.CARRIER ? 'CARRIER' : '',
            last_modified_at: new Date(),
        };
        if (driverId) _review.driver_id = driverId;
        return _review;
    }, [reviewObject, userRating]);

    const onSubmit = () => {
        submitRating({
            variables: {
                rating: {
                    ...combined,
                    chips: CHIPS.filter((name) => {
                        return combined.chips[name];
                    }),
                },
            },
        });
    };

    return (
        <ResponsiveSidebarDialog
            open={isOpen}
            onClose={callbacks.onClose}
            fullWidth={true}
            maxWidth="md"
            css={css`
                .MuiDialog-container {
                    align-items: normal;
                }
            `}
        >
            <ModalTitle
                title="How did the delivery team do?"
                onClose={callbacks.onClose}
                css={css`
                    & .MuiGrid-container {
                        flex-wrap: nowrap;
                    }
                `}
            />
            <Grid
                css={css`
                    padding: 12px 24px !important;
                `}
            >
                <Grid container>
                    <Grid container direction="column">
                        <Grid
                            item
                            css={css`
                                margin-bottom: 8px;
                                text-align: ${isDesktop ? 'left' : 'center'};
                            `}
                        >
                            <Body1>Overall Rating</Body1>
                        </Grid>
                        <Grid
                            item
                            container
                            css={css`
                                justify-content: ${isDesktop ? 'flex-start' : 'center'};
                            `}
                        >
                            <Rating
                                value={combined?.rating || 0}
                                onChange={(e, v) =>
                                    setUserRating((prev) => ({
                                        ...prev,
                                        rating: v,
                                    }))
                                }
                                css={css`
                                    font-size: ${isDesktop ? '56px' : '40px'};
                                `}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        css={css`
                            margin-top: 30px;
                        `}
                    >
                        <Grid
                            container
                            item
                            css={css`
                                margin-bottom: 8px;
                                justify-content: ${isDesktop ? 'flex-start' : 'center'};
                            `}
                        >
                            <Body1>Select All That Apply</Body1>
                        </Grid>
                        <Grid item container>
                            {CHIPS.map((chip, index) => (
                                <ToggleButton
                                    key={index}
                                    value="check"
                                    selected={combined?.chips?.[chip]}
                                    onChange={() =>
                                        setUserRating((prev) => ({
                                            ...prev,
                                            chips: { ...prev.chips, [chip]: !combined?.chips?.[chip] },
                                        }))
                                    }
                                    css={css`
                                        width: ${isDesktop ? 'auto' : '100%'};
                                        margin: 5px 0;
                                        margin-right: ${isDesktop ? '10px' : '0'};
                                        border-radius: 60px;
                                        padding: 3px 15px;
                                        font-weight: 700;
                                        font-size: 14px;

                                        &.Mui-selected,
                                        &.Mui-selected:hover {
                                            background: #59b863;
                                            color: #fff;
                                        }

                                        & .MuiToggleButton-label {
                                            white-space: ${isDesktop ? 'nowrap' : 'pre-line'};
                                        }
                                    `}
                                >
                                    {combined?.chips?.[chip] && <Check />}
                                    {chip}
                                </ToggleButton>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        css={css`
                            margin-top: 30px;
                        `}
                    >
                        <Grid
                            item
                            css={css`
                                margin-bottom: 8px;
                                text-align: ${isDesktop ? 'left' : 'center'};
                            `}
                        >
                            <Body1>Written Review</Body1>
                        </Grid>
                        <Grid item>
                            <TextareaAutosize
                                css={css`
                                    width: 100%;
                                    padding: 8px;
                                `}
                                minRows={8}
                                placeholder="Optional: What did you like or dislike about the carrier?"
                                value={combined?.comment || ''}
                                onChange={(e) => {
                                    setUserRating((prev) => ({
                                        ...prev,
                                        comment: e.target.value,
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <StickyModalActions>
                {isDesktop ? (
                    <>
                        <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                        <PrimaryButton
                            disabled={!combined?.rating}
                            onClick={() => {
                                onSubmit();
                                callbacks.onClose();
                            }}
                        >
                            Submit
                        </PrimaryButton>
                    </>
                ) : (
                    <Grid direction="column" container>
                        <Grid
                            item
                            css={css`
                                margin-bottom: 16px;
                            `}
                        >
                            <PrimaryButton
                                disabled={!combined?.rating}
                                onClick={() => {
                                    onSubmit();
                                    callbacks.onClose();
                                }}
                                css={css`
                                    width: 100%;
                                `}
                            >
                                Submit
                            </PrimaryButton>
                        </Grid>
                        <Grid item>
                            <SecondaryButton
                                onClick={callbacks.onClose}
                                css={css`
                                    width: 100%;
                                `}
                            >
                                Cancel
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                )}
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default CarrierReviewModal;
