import React, { useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { Grid, IconButton, TextField, MenuItem } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useClientUser } from '@/hooks';
import { ModalContent, ModalTitle, PrimaryButton, StickyModalActions } from '@/styles/blocks';

const Title1 = styled.span`
    color: ${colors.greys.secondary};
    font-size: 20px;
    font-weight: 900;
`;

const Body = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

const Row = styled((props) => <Grid item {...props} />)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ReceivingWarehouseModal = ({ open, onClose, bid, handleButtonClick, loads }) => {
    const { locations } = useClientUser();

    const [locationId, setLocationId] = useState(null);

    const warehouseOptions = useMemo(() => {
        return (locations || [])?.filter(l => {
            return [
                'business_city',
                'location_type',
                'location_info',
                'business_state',
                'business_address',
                'business_zip',
                'lat',
                'lng',
            ].every(field => !!l[field]);
        })
    }, [locations]);

    useEffect(() => {
        if (bid?.location_id) setLocationId(bid.location_id)
    }, [bid]);
    
    return (
        <NavResponsiveModal open={open} onClose={onClose}>
            <ModalTitle>
                <Row>
                    <Title1>Select a {loads?.[0]?.order_type === 'return' ? 'Crossdock' : 'Receiving'} Warehouse</Title1>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Row>
            </ModalTitle>
            <ModalContent
                css={css`
                    width: 100%;
                    border-top: 1px solid ${colors.greys.primary};
                    border-bottom: 1px solid ${colors.greys.primary};
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                    `}
                >
                    <Row>
                        <Body>
                            This load requires you to select a warehouse location of yours to receive and hold the load
                            at until you handle its delivery. Select a location below from your warehouses.{' '}
                            <span
                                css={css`
                                    font-weight: 700;
                                    color: red;
                                `}
                            >
                                Locations may be added, removed, and modified from your Account page.
                            </span>
                        </Body>
                    </Row>

                    {warehouseOptions.length !== locations.length && (
                        <Row>
                            <Body>
                                If one of your warehouses does not appear in the list below, there was likely an issue
                                when it was submitted in your account page. Consider re-entering the warehouse's address
                                in your account page, and then saving changes at the bottom of the warehouse page. If
                                the issue persists, contact support.
                            </Body>
                        </Row>
                    )}

                    <Row
                        css={css`
                            margin-top: 2rem;
                        `}
                    >
                        <TextField
                            InputLabelProps={{ shrink: locationId ? true : false }}
                            fullWidth
                            id="warehouse-select"
                            label="Warehouses"
                            value={locationId}
                            onChange={(e) => setLocationId(e.target.value)}
                            select
                        >
                            {warehouseOptions?.map((l) => (
                                <MenuItem value={l.location_id}>{l.location_name}</MenuItem>
                            ))}
                        </TextField>
                    </Row>
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <div />
                <PrimaryButton disabled={!locationId} onClick={() => handleButtonClick(false, locationId)}>
                    {bid ? (bid.bid_id ? 'Accept and Update Bid' : 'Accept and Place Bid') : 'Accept and Claim'}
                </PrimaryButton>
            </StickyModalActions>
        </NavResponsiveModal>
    );
};

export default ReceivingWarehouseModal;
