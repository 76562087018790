import dateFnsFormat from 'date-fns/format';
import zipcode_to_timezone from 'zipcode-to-timezone';
const { getTimezoneOffset } = require('date-fns-tz');

export const cdsRows = [
    {
        key: 'po_number',
        display: 'ORDERNUM',
        required: true,
        parser: ({ order }) => order?.po_number,
    },
    {
        key: 'timeframe_start',
        display: 'TIMEFRAMESTART',
        required: true,
        parser: ({ stop, order }) => {
            const zip = order.order_type === 'return' ? order.pickup_zip : order.dropoff_zip;
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const localDate = new Date(stop.stop_start_time);
            let tzDiff = getTimezoneOffset(localtz, localDate) - getTimezoneOffset(tz, localDate);
            let newDate = new Date(localDate.getTime() - tzDiff);
            return dateFnsFormat(newDate, 'M/d/yy h:mm aa');
        },
    },
    {
        key: 'timeframe_end',
        display: 'TIMEFRAMEEND',
        required: true,
        parser: ({ stop, order }) => {
            const zip = order.order_type === 'return' ? order.pickup_zip : order.dropoff_zip;
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const localDate = new Date(stop.stop_end_time);
            let tzDiff = getTimezoneOffset(localtz, localDate) - getTimezoneOffset(tz, localDate);
            let newDate = new Date(localDate.getTime() - tzDiff);
            return dateFnsFormat(newDate, 'M/d/yy h:mm aa');
        },
    },
];

export const createCdsExportRows = (data) => {
    return data.map((d) => ({
        ...Object.fromEntries(cdsRows.map((row) => [row.key, row.parser({ stop: d.stop, order: d.order })])),
    }));
};
