import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BodyCellText, fragments } from '@/components/MyOrders/blocks';
import { StickyCell } from '@/components/DispatchUnassigned/blocks';

const Cell = styled.div`
    ${fragments.cell}

    padding: 10px 24px 10px 16px;
`;

export default function BodyCell({ cell, ...props }) {
    return (
        <StickyCell 
            {...props}
            css={css`
                    white-space: nowrap;
                    overflow: auto;
                `}
            >
            <BodyCellText
                css={css`
                    white-space: nowrap;
                `}
            >
                {cell.render('Cell')}
            </BodyCellText>
        </StickyCell>
    );
}
