import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { colors } from '@/styles';

import { Body1, Body2 } from './blocks';
import { ACTION_DISPLAY_MAPPING, INSTRUCTIONS_DISPLAY_MAPPING, REQUIRES_CARRIER_INSTRUCTION } from './constants';

const ResolutionRadioGroup = ({ exception }) => {
    return (
        <>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body2
                    css={css`
                        color: ${colors.greens.primary};
                    `}
                >
                    Resolved
                </Body2>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body2
                    css={css`
                        color: ${colors.greys[3]};
                    `}
                >
                    Shipper has responded
                </Body2>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>How would you like to resolve this exception?</Body1>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 8px;
                    margin-left: 20px;
                `}
            >
                <Body1>{ACTION_DISPLAY_MAPPING[exception.resolution]}</Body1>
            </Grid>
            {REQUIRES_CARRIER_INSTRUCTION.includes(exception.type) && exception.instruction ? (
                <>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 8px;
                            margin-top: 12px;
                        `}
                    >
                        <Body1>What would you like the carrier to do with the items?</Body1>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 8px;
                            margin-left: 20px;
                        `}
                    >
                        <Body1>{INSTRUCTIONS_DISPLAY_MAPPING[exception.instruction]}</Body1>
                    </Grid>
                </>
            ) : null}
        </>
    );
};

export default ResolutionRadioGroup;
