import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, MenuItem, FormControl, IconButton, Tooltip } from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    StickyModalActions,
    ModalContent,
    ResponsiveSidebarDialog,
    Row,
    PrimaryButton,
    SecondaryButton,
    SectionTitle,
    SectionSubtitle,
} from '@/styles/blocks';
import { colors, typography } from '@/styles';
import { useClientUser } from '@/hooks/useClientUser';

import { QUERY_MAPPINGS_BY_USER } from '../graphql/queries';
import { DELETE_CSV_MAPPING } from '../graphql/mutations';
import { Context } from '../store';
import { MODALS } from '../constants';
import { OnwardCheckbox, Select } from '../blocks';

const LabelSubtext = styled((props) => <span {...props} />)`
    color: ${colors.greys.primary};
`;

const Label = styled(({ required, ...props }) => (
    <span {...props}>
        <>
            {props.children}
            {required && <LabelSubtext>{` (required)`}</LabelSubtext>}
        </>
    </span>
))`
    ${typography.sansSerif}
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

const SelectLabel = styled.span`
    ${typography.sansSerif}
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
`;

const CSVHeadersModal = () => {
    const { state, callbacks } = useContext(Context);
    const open = state.current === MODALS.CSV_HEADERS;

    const { user_id } = useClientUser();
    const [fetchMappings, { data, loading, error }] = useLazyQuery(QUERY_MAPPINGS_BY_USER, {
        fetchPolicy: 'cache-and-network',
    });
    const [deleteMapping] = useMutation(DELETE_CSV_MAPPING, {
        update: (cache, { data: { removed } }) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'csv_mappings' });
        },
        onError: (e) => {
            callbacks.setErrorMsg('Error trying to delete template.');
        },
    });

    const [willSave, setWillSave] = useState(false);
    const [templateKey, setTemplateKey] = useState('');
    const [clickedNext, setClickedNext] = useState(false);

    const headerOptions = state.uploadedFile?.data?.[0];

    const reset = () => {
        setWillSave(false);
        setTemplateKey('');
    };

    useEffect(() => {
        if (!user_id || !open) return;

        fetchMappings({ variables: { user_id, freight_type: state.freightType } });
    }, [user_id, state, open]);

    // Set default header options for required fields IF the imported CSV contains that header field. Otherwise default to empty.
    useEffect(() => {
        callbacks.setMapping(
            Object.fromEntries(
                Object.entries(state.csvOptions).map(([key, option]) => [
                    key,
                    headerOptions?.includes(option.display) ? option.display : '',
                ])
            )
        );
    }, [headerOptions, state.csvOptions]);

    const templates = useMemo(() => {
        if (data) {
            return Object.fromEntries(data.results.map((mapping) => [mapping.mapping_id, mapping]));
        }

        return {};
    }, [data]);

    const hasError = useMemo(() => {
        return Object.fromEntries(
            Object.entries(state.csvOptions).map(([key, option]) => [key, option.required && !state.savedMapping[key]])
        );
    }, [state.savedMapping, state.csvOptions]);

    const isValid = useMemo(() => {
        return Object.entries(state.csvOptions).every(([key, option]) => !option.required || !!state.savedMapping[key]);
    }, [state.savedMapping, state.csvOptions]);

    const handleHeaderSelect = (key, value) => {
        callbacks.setMapping((prev) => ({ ...prev, [key]: value }));

        if (templateKey === 'default') {
            setTemplateKey('');
        }
    };

    const selectTemplate = (e) => {
        const templateKey = e.target.value;
        setTemplateKey(templateKey);
        if (templateKey) {
            callbacks.setMapping(templates[templateKey]?.mapping || {});
            callbacks.setTemplateName(templates[templateKey]?.name || null);
        } else {
            callbacks.setMapping(
                Object.fromEntries(
                    Object.entries(state.csvOptions).map(([key, option]) => [
                        key,
                        headerOptions?.includes(option.display) ? option.display : '',
                    ])
                )
            );
        }
    };

    const handleTemplateDelete = (e, key) => {
        e.stopPropagation();
        deleteMapping({ variables: { mapping_id: key } });
    };

    const filteredHeaderOptions = headerOptions?.filter((i) => !Object.values(state.savedMapping).includes(i)) || [];

    return (
        <ResponsiveSidebarDialog
            open={open}
            onClose={() => {
                reset();
                callbacks.reset();
            }}
        >
            <ModalContent width={740}>
                <Grid
                    css={css`
                        margin-bottom: 24px;
                    `}
                >
                    <SectionTitle>Match Column Headers</SectionTitle>
                </Grid>

                {/* Template Select */}
                <FormControl
                    variant="outlined"
                    css={css`
                        width: 50%;
                        margin-bottom: 40px;
                        & .MuiSelect-selectMenu {
                            padding: 8px;
                        }
                    `}
                >
                    <SelectLabel>Use template</SelectLabel>
                    <Select
                        value={templateKey}
                        onChange={selectTemplate}
                        displayEmpty
                        renderValue={(e) => {
                            return templates[templateKey]?.name || 'Select template...';
                        }}
                    >
                        <MenuItem value={''}>Select template...</MenuItem>
                        {!loading &&
                            !error &&
                            Object.entries(templates).map(([key, template]) => (
                                <MenuItem
                                    key={`template-menu-${key}`}
                                    value={key}
                                    css={css`
                                        justify-content: space-between;
                                    `}
                                >
                                    {template.name}
                                    {key !== 'default' && (
                                        <IconButton
                                            css={css`
                                                padding: 0;
                                            `}
                                        >
                                            <DeleteForeverIcon
                                                onClick={(e) => handleTemplateDelete(e, key)}
                                                css={css`
                                                    color: black;
                                                `}
                                            />
                                        </IconButton>
                                    )}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <Grid className="mb-4" container>
                    <Grid item xs={6}>
                        <SectionSubtitle
                            css={css`
                                font-weight: 800;
                            `}
                        >
                            Our Headers
                        </SectionSubtitle>
                    </Grid>
                    <Grid item xs={6}>
                        <SectionSubtitle
                            css={css`
                                font-weight: 800;
                            `}
                        >
                            Your file headers
                        </SectionSubtitle>
                    </Grid>
                </Grid>
                {/* Map CSV Column Headers */}
                {Object.values(state?.csvOptions || {}).map((option) => (
                    <Row
                        css={css`
                            align-items: center;
                        `}
                        key={`csv-option-${option.key}`}
                    >
                        {/* TODO: add padding/margin between items */}
                        <Grid item xs={5}>
                            <Label required={option.required}>{option.display}</Label>
                        </Grid>
                        <Grid item xs={1}>
                            <ArrowForwardIcon />
                        </Grid>
                        <Grid item xs={5}>
                            <Select
                                variant="outlined"
                                value={state.savedMapping[option.key] || ''}
                                onChange={(e) => handleHeaderSelect(option.key, e.target.value)}
                                css={css`
                                    width: 100%;
                                `}
                                displayEmpty
                                error={clickedNext && hasError[option.key]}
                            >
                                <MenuItem key={`dropdown-${option.key}-empty`} value="">
                                    <span
                                        css={css`
                                            color: grey;
                                            font-style: italic;
                                        `}
                                    >
                                        Select an option...
                                    </span>
                                </MenuItem>
                                {state.savedMapping[option.key] && (
                                    <MenuItem
                                        key={`dropdown-${option.key}-${state.savedMapping[option.key]}`}
                                        value={state.savedMapping[option.key]}
                                    >
                                        {state.savedMapping[option.key]}
                                    </MenuItem>
                                )}
                                {filteredHeaderOptions?.sort()?.map((header) => (
                                    <MenuItem key={`dropdown-${option.key}-${header}`} value={header}>
                                        {header}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        {option.key === 'order_type' && (
                            <Grid className="pl-3" item xs={1}>
                                <Tooltip
                                    title={`Add a "Pickup or Delivery" column to your source file and indicate “Delivery” or “Pickup” in each cell. Defaults to "Delivery" if left unspecified.`}
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </Grid>
                        )}
                    </Row>
                ))}
            </ModalContent>
            {/* Modal Actions  */}
            <StickyModalActions border="true">
                <SecondaryButton
                    onClick={() => {
                        reset();
                        callbacks.revert();
                    }}
                >
                    {state.isFirstStep ? 'Cancel' : 'Back'}
                </SecondaryButton>
                <OnwardCheckbox
                    label="Save as template"
                    checked={willSave}
                    onChange={() => {
                        setWillSave((prev) => !prev);

                        callbacks.toggleSaveTemplate(!willSave);
                    }}
                />
                <PrimaryButton
                    onClick={() => {
                        if (!isValid) {
                            setClickedNext(true);
                            return;
                        }

                        reset();
                        callbacks.advance();
                    }}
                    // disabled={!isValid}
                >
                    Next
                </PrimaryButton>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default CSVHeadersModal;
