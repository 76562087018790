import { gql } from '@apollo/client';

export const COUNTER_OFFER_FIELDS = gql`
    fragment CounterOfferFields on counter_offers {
        counter_offer_id
        bid_id
        client_id
        shipper_rate
        carrier_rate
        created_at
        last_modified_at
    }
`;
