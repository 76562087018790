import React, { useEffect, useMemo, useState, useContext } from 'react';
import { css } from '@emotion/react';
import { LocationOn, NotListedLocation } from '@material-ui/icons';
import { format, isAfter, subDays } from 'date-fns';
import { BodyCellText } from '@/components/MyOrders/blocks';
import { useClientUser } from '@/hooks';
import { UserContext } from '@/components/App';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const useBidColumns = () => {
    const colWidth = 175;

    const getUnreadDetails = (listing) => {
        if (listing.listing_status !== 'open') return {};

        const lastViewed = listing.carrierBid.last_viewed_carrier || listing.carrierBid.created_at;

        let unreadComments = 0;

        let unreadCounters = 0;

        unreadComments += listing.carrierBid.bid_comments?.filter((comment) =>
            isAfter(new Date(comment.created_at), new Date(lastViewed))
        ).length;

        unreadCounters += listing.carrierBid.counter_offers.filter((counter) =>
            isAfter(new Date(counter.created_at), new Date(lastViewed))
        ).length;

        return { unreadCounters, unreadComments };
    };

    return useMemo(() => {
        return [
            {
                id: 'badge',
                accessor: (listing) => listing,
                Cell: ({ value: listing }) => {
                    const { unreadCounters, unreadComments } = getUnreadDetails(listing);

                    if (unreadCounters || unreadComments) {
                        return <FiberManualRecordIcon sx={{ fontSize: 13, color: '#D23E3E', margin: 0, padding: 0 }} />;
                    } else return null;
                },
                disableSortBy: true,
                width: 60,
            },
            {
                id: 'ordernum',
                Header: 'Order',
                accessor: (listing) => listing?.order.order_number,
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'bidplaced',
                Header: 'Bid Placed',
                accessor: (listing) => listing?.carrierBid?.carrier_rate || null,
                Cell: ({ value }) => {
                    if (value) {
                        return `$${value.toFixed(2)}`;
                    }
                    return '-';
                },
                width: colWidth,
                disableSortBy: true,
            },
            {
                id: 'currentOffer',
                Header: 'Current Offer',
                accessor: (listing) => listing?.carrierBid || null,
                Cell: ({ value: carrierBid }) => {
                    if (carrierBid?.counter_offers?.length) {
                        return `$${[...carrierBid.counter_offers]
                            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]
                            ?.carrier_rate.toFixed(2)}`;
                    }
                    return '-';
                },
                width: colWidth,
                disableSortBy: true,
            },
            {
                id: 'bids',
                Header: 'Bids',
                accessor: (listing) => listing?.bids?.length || '-',
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'deliverydate',
                Header: 'Target Delivery Date',
                accessor: (listing) => {
                    return listing?.carrierBid?.target_delivery_date || null;
                },
                Cell: ({ value }) => {
                    if (!value) {
                        return '--';
                    }
                    return format(new Date(value), 'EEE, MMM d, yyyy');
                },
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'yourexpiration',
                Header: 'Your Expiration',
                accessor: (listing) => {
                    return listing?.carrierBid || null;
                },
                Cell: ({ value: bid }) => {
                    if (!bid) {
                        return '--';
                    }
                    const expDate = new Date(bid.expiration_date);
                    const currentDate = new Date();
                    const isPast = expDate < currentDate;

                    return <span style={{ color: isPast ? 'red' : 'inherit' }}>{bid.expiration_date_formatted}</span>;
                },
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'shipperexpiration',
                Header: 'Shipper Expiration',
                Cell: ({ row }) => {
                    const listing = row.original;
                    if (!listing) {
                        return '--';
                    }
                    const expDate = new Date(listing.expiration_date);
                    const currentDate = new Date();
                    const isPast = expDate < currentDate;

                    return (
                        <span style={{ color: isPast ? 'red' : 'inherit' }}>{listing.expiration_date_formatted}</span>
                    );
                },
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'bid_status',
                Header: 'Status',
                width: colWidth,
                accessor: (listing) => listing,
                Cell: ({ value }) => {
                    let bid_status = value?.carrierBid?.bid_status;
                    if (value?.listing_status === 'pending') {
                        bid_status = 'pre-bid';
                    }

                    let fragment;
                    let label;
                    let unknown = false;

                    switch (bid_status) {
                        case 'succeeded': {
                            label = 'Won';
                            fragment = css`
                                color: #4bb71a;
                            `;
                            break;
                        }
                        case 'pre-bid': {
                            label = 'Pre-bid';
                            fragment = css`
                                color: #ffd200;
                            `;
                            break;
                        }
                        case 'open': {
                            label = 'Open';
                            fragment = css`
                                color: #0961f6;
                            `;
                            break;
                        }
                        case 'expired': {
                            label = 'Expired';
                            fragment = css`
                                color: #d23e3e;
                            `;
                            break;
                        }
                        case 'cancelled': {
                            label = 'Cancelled';
                            fragment = css`
                                color: #d23e3e;
                            `;
                            break;
                        }
                        default:
                            unknown = true;
                    }

                    return (
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                white-space: nowrap;
                            `}
                        >
                            {unknown ? (
                                <NotListedLocation
                                    css={css`
                                        color: #000;
                                    `}
                                />
                            ) : (
                                <LocationOn
                                    css={css`
                                        ${fragment}
                                    `}
                                />
                            )}
                            <BodyCellText>{unknown ? 'Unknown' : label}</BodyCellText>
                        </div>
                    );
                },
                disableSortBy: true,
            },
        ];
    }, [colWidth]);
};
