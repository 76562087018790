import { Button } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import styled from '@emotion/styled';
import { colors } from '@/styles';
import { useNavigate } from 'react-router';

export const OnwardBreadcrumbSpacer = styled((props) => <ArrowForwardIos {...props} />)`
    font-size: 12px;
    margin: 0 8px;
`;

export const OnwardBreadcrumbActive = styled.span`
    font-family: Montserrat, Roboto, Arial;
    color: #59b863;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    white-space: nowrap;
`;

export const OnwardBreadcrumbInactive = styled((props) => {
    const navigate = useNavigate();
    return (
        <Button
            onClick={(e) => {
                if (props.href && !e.ctrlKey) {
                    e.preventDefault();
                    navigate(props.href);
                }
            }}
            {...props}
        />
    );
})`
    font-family: Montserrat, Roboto, Arial;
    outline: 0;
    font-weight: 700;
    font-size: 14px;
    color: #262626;
    :hover {
        color: ${colors.greens.primary};
    }
`;
