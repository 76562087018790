import React from 'react';
import { Text, View } from '@react-pdf/renderer';

const HeaderRow = ({ schema }) => {
    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                width: '100%',
                borderBottom: '1px solid #000',
            }}
            key="header"
        >
            {schema.map((col) => {
                return (
                    <Text
                        style={{
                            padding: '4px 4px',
                            display: 'flex',
                            fontWeight: 'bold',
                            fontSize: '7px',
                            ...col.styles,
                        }}
                    >
                        {col.header}
                    </Text>
                );
            })}
        </View>
    );
};

export default HeaderRow;
