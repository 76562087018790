import { gql } from '@apollo/client';

export const LISTING_FIELDS = gql`
    fragment ListingFields on listings {
        carrier_rate
        created_at
        expiration_date
        expiration_date_formatted @client
        final_accepted_shipper_rate
        final_accepted_carrier_rate
        order_id
        payment_intent_id
        shipper_id
        shipper_rate
        last_modified_at
        last_viewed_shipper
        listing_id
        listing_status
        listing_type
    }
`;
