/* eslint-disable */
import React from 'react';

import { Grid } from '@material-ui/core';

import { GridItemRow, PlanningListItem } from '../blocks';
import { css } from '@emotion/react';

export default function RouteShellListItem({ route, style }) {
    return (
        <PlanningListItem
            style={style}
            hover="true"
            css={css`
                cursor: auto;
            `}
        >
            <GridItemRow
                css={css`
                    font-size: 14px;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        text-wrap: wrap;
                    `}
                >
                    <Grid item>You have an available truck for today.</Grid>
                    <Grid
                        item
                        css={css`
                            text-wrap: wrap;
                            margin-top: 20px;
                        `}
                    >
                        Click 'Add Route' below to create a route and use it.
                    </Grid>
                </Grid>
            </GridItemRow>
        </PlanningListItem>
    );
}
