import React, { useContext, useState, useMemo } from 'react';
import { compose } from 'recompose';

import withAdminRights from '@/components/Auth/withAdminRights';

import { Context, ContextProvider } from './store';
import { CircularProgress, Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { GridCard, PrimaryHeaderLabel } from '../CarrierAccountingOrders/blocks';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { startCase } from 'lodash';
import { colors } from '@/styles';
import InvoiceOrdersTable from '../CarrierAccountingInvoices/InvoiceOrdersTable';
import { format } from 'date-fns';

const LineItem = ({ label, value, lg = false, color = 'black' }) => {
    return (
        <Grid
            item
            css={css`
                width: 50%;
                min-width: 400px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            `}
        >
            <span
                css={css`
                    ${lg &&
                    `
                    font-size: 18px;
                    font-weight: 700;
                `}
                `}
            >
                {label}
            </span>
            <span
                css={css`
                    font-size: 16px;
                    font-weight: 700;
                    ${lg &&
                    `
                        font-size: 22px;
                    `}
                    color: ${color};
                `}
            >
                {value || value === 0 ? `$${value.toFixed(2)}` : '--'}
            </span>
        </Grid>
    );
};

const ShipperAccountingInvoice = ({ context }) => {
    const { state, loading, callbacks } = useContext(context);
    const { invoice, types, subtotal, adjustments } = state;

    const statusContent = useMemo(() => {
        let fragment = `
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1rem;
            width: 100px;
            height: 100%;
            font-size: 16px;
            font-weight: 700;
            border: 3px solid;
            border-radius: 3px;
        `;
        switch (invoice.status) {
            case 'PAID':
                fragment = `
                    ${fragment}
                    border-color: ${colors.greens.primary};
                    color: ${colors.greens.primary};
                `;
                break;
            case 'APPROVED':
                fragment = `
                    ${fragment}
                    border-color: ${colors.greens.primary};
                    color: ${colors.greens.primary};
                    `;
                break;
            case 'UNPAID':
            case 'CANCELLED':
                fragment = `
                    ${fragment}
                    border-color: ${colors.reds.cancelled};
                    color: ${colors.reds.cancelled};
                `;
                break;
            case 'PROCESSING':
                fragment = `
                    ${fragment}
                    border-color: ${colors.oranges.primary};
                    color: ${colors.oranges.primary};
                `;
                break;
            default:
                break;
        }

        return (
            <div
                css={css`
                    ${fragment}
                `}
            >
                <span>{startCase(invoice.status?.toLowerCase() || '--')}</span>
            </div>
        );
    }, [invoice]);

    return (
        <Grid container direction="column" wrap="nowrap" className="h-100">
            {loading.init ? (
                <Grid
                    item
                    css={css`
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <CircularProgress color="primary" size={200} />
                </Grid>
            ) : (
                <>
                    <Grid
                        item
                        css={css`
                            padding: 2rem 2rem 1rem 2rem;
                        `}
                    >
                        <Grid
                            item
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            `}
                        >
                            <PrimaryHeaderLabel>Invoice {invoice.invoice_number}</PrimaryHeaderLabel>
                            <Grid
                                item
                                css={css`
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    height: 100%;
                                `}
                            >
                                <SecondaryButton
                                    css={css`
                                        margin-right: 0.5rem;
                                        margin-left: 0.5rem;
                                    `}
                                    onClick={callbacks.exportCSV}
                                >
                                    Export
                                </SecondaryButton>

                                <PrimaryButton
                                    disabled={loading.approve || invoice.status === 'PAID'}
                                    onClick={callbacks.markAsPaid}
                                    css={css`
                                        margin-right: 0.5rem;
                                        margin-left: 0.5rem;
                                    `}
                                >
                                    Mark as Paid
                                </PrimaryButton>
                                <div
                                    css={css`
                                        margin-right: 0.5rem;
                                    `}
                                >
                                    {statusContent}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            padding: 1rem 2rem;
                        `}
                    >
                        <GridCard
                            css={css`
                                display: flex;
                                flex-wrap: nowrap;
                                flex-direction: column;
                                align-items: flex-start;
                            `}
                        >
                            <span>
                                Issued:{' '}
                                {invoice.created_at ? format(new Date(invoice.created_at), 'MMM d, yyyy') : '--'}
                            </span>
                            <span>
                                Due: {invoice.due_date ? format(new Date(invoice.due_date), 'MMM d, yyyy') : '--'}
                            </span>
                            <span>Description: {invoice.description || ''}</span>
                        </GridCard>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            padding: 1rem 2rem;
                            flex-grow: 1;
                        `}
                    >
                        <InvoiceOrdersTable orders={invoice.orders || []} breakdown="shipperBreakdown" types={types} />
                    </Grid>
                    <Grid
                        item
                        css={css`
                            padding: 1rem 2rem;
                        `}
                    >
                        <GridCard
                            css={css`
                                display: flex;
                                flex-wrap: nowrap;
                                flex-direction: column;
                                align-items: flex-end;
                            `}
                        >
                            <LineItem label="Subtotal" value={subtotal} />
                            <LineItem label="Adjustments" value={adjustments} color={colors.greens.primary} />
                            <hr
                                css={css`
                                    width: 50%;
                                    min-width: 400px;
                                    border-width: 2px;
                                `}
                            />
                            <LineItem label="Total" value={subtotal + adjustments} lg />
                        </GridCard>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} />
        </ContextProvider>
    );

export default compose(withAdminRights, withContext)(ShipperAccountingInvoice);
