import React, { useState } from 'react';

import {
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    Grid,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    TextField,
} from '@material-ui/core/';
import dateFns from '@/utilities/dateFns';
import './style.css';

function AdminFTLFilterButton(props) {
    const [anchorEl, setAnchorEl] = useState(null); // Element that the popper attaches to.
    const [filterBGColor, setFilterBGColor] = useState('#3f51b5');
    const [dateFilterType, setDateFilterType] = useState('last30Days');
    const handleClick = (event) => {
        // Material ui has a bug for using a Select inside of a ClickAwayListener, this is a workaround for it.
        if (event.target.nodeName === 'BODY' && event.type === 'click') {
            return;
        }

        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        props.setFilterSubmit(true);
        setFilterBGColor('green');
    };
    const handleClearFilters = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        props.setShipperFilter('');
        props.setCarrierFilter('');
        props.setStartDate('');
        props.setEndDate('');
        props.setSelectedStateFilter('');
        props.setFilterSubmit(false);
        setFilterBGColor('#3f51b5');
        setDateFilterType('last30Days');
    };
    const setLastSevenDays = () => {
        const [firstDay, lastDay] = dateFns.getLastSevenDays();
        props.setStartDate(firstDay);
        props.setEndDate(lastDay);
        setDateFilterType('last7Days')
    };
    const setLastThirtyDays = () => {
        const [firstDay, lastDay] = dateFns.getLastThirtyDays();
        props.setStartDate(firstDay);
        props.setEndDate(lastDay);
        setDateFilterType('last30Days')
    };
    const setAllTime = () => {
        const [firstDay, lastDay] = dateFns.getLastThirtyDays();
        props.setStartDate(0);
        props.setEndDate(dateFns.formatDate(new Date().toISOString(), 'yyyy-MM-dd'));
        setDateFilterType('allTime')
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    // If spacing is off, use the div instead of the fragment
    return (
        <React.Fragment>
            {/* <div> */}

            <Button
                variant="contained"
                disableElevation
                className="filters-button"
                style={{ backgroundColor: filterBGColor, color: 'white' }}
                onClick={(e) => setAnchorEl(e.target)}
            >
                Filters
            </Button>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                style={{ width: '400px', marginRight: '35px', marginTop: '10px', zIndex: 99 }}
            >
                <ClickAwayListener onClickAway={handleClick}>
                    <Paper>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className="ftl-filters-header"
                                style={{ backgroundColor: 'lightgrey', color: 'black' }}
                            >
                                Route Filters
                            </Grid>

                            {/* <Grid item xs={12} className="ftl-fitlers-subheader">
                                Route Info Filters
                            </Grid> */}

                            <Grid item container xs={12} style={{ padding: '8px 0px 0px 12px' }}>
                                <Grid item xs={4} style={{ alignSelf: 'center' }}>
                                    Shipper Name:
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        id="admin-ftl-search-shipper"
                                        label="Search shipper names"
                                        variant="outlined"
                                        size="small"
                                        value={props.shipperFilter}
                                        onChange={(e) => props.setShipperFilter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} style={{ padding: '8px 0px 0px 12px' }}>
                                <Grid item xs={4} style={{ alignSelf: 'center' }}>
                                    Carrier Name:
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        id="admin-ftl-search-carrier"
                                        label="Search carrier names"
                                        variant="outlined"
                                        size="small"
                                        value={props.carrierFilter}
                                        onChange={(e) => props.setCarrierFilter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="ftl-fitlers-subheader">
                                Date Created Range Filter
                            </Grid>
                            <Grid item container xs={12} style={{ padding: '8px 0px 0px 0px' }}>
                                <Grid item xs={4} style={{ textAlign: 'center' }}>
                                    <Button 
                                        variant="contained"
                                        disableElevation
                                        className="filters-button blue-button"
                                        style={{ backgroundColor: dateFilterType === 'last7Days' ? 'navy' : 'white', color: dateFilterType === 'last7Days' ? 'white' : 'navy', alignContent: 'flex-end' }}
                                        onClick={setLastSevenDays}>
                                        Last 7 days
                                    </Button>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: 'center' }}>
                                    <Button 
                                        variant="contained"
                                        disableElevation
                                        className="filters-button blue-button"
                                        style={{ backgroundColor: dateFilterType === 'last30Days' ? 'navy' : 'white', color: dateFilterType === 'last30Days' ? 'white' : 'navy', alignContent: 'flex-end' }}
                                        onClick={setLastThirtyDays}
                                    >
                                        Last 30 Days
                                    </Button>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: 'center' }}>
                                    <Button 
                                        variant="contained"
                                        disableElevation
                                        className="filters-button blue-button"
                                        style={{ backgroundColor: dateFilterType === 'allTime' ? 'navy' : 'white', color: dateFilterType === 'allTime' ? 'white' : 'navy', alignContent: 'flex-end' }}
                                        onClick={setAllTime}
                                    >
                                        All Routes
                                    </Button>
                                </Grid>


                            {/* </Grid> */}
                            </Grid>
                            <Grid item container xs={12} style={{ padding: '8px 0px 0px 0px' }}>
                                <Grid item xs={6} style={{ textAlign: 'center' }}>
                                    <form noValidate>
                                        <TextField
                                            id="date-range-start"
                                            label="Start Date"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.startDate}
                                            onChange={(e) => props.setStartDate(e.target.value)}
                                        />
                                    </form>
                                </Grid>
                                <Grid item xs={6} style={{ 'text-align': 'center' }}>
                                    <form noValidate>
                                        <TextField
                                            id="date-range-end"
                                            label="End Date"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.endDate}
                                            onChange={(e) => props.setEndDate(e.target.value)}
                                        />
                                    </form>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="ftl-fitlers-subheader" style={{ marginBottom: '10px' }}>
                                State Filter
                            </Grid>
                            <Grid item xs={12}>
                                <div id="state-selector">
                                    <FormControl style={{ width: '150px', marginTop: '-12px', zIndex: 999 }}>
                                        <InputLabel>State</InputLabel>
                                        <Select
                                            value={props.selectedStateFilter ? props.selectedStateFilter : null}
                                            onChange={(e) => props.setSelectedStateFilter(e.target.value)}
                                        >
                                            <MenuItem value="none">
                                                <em>None</em>
                                            </MenuItem>
                                            {props.stateDropdownOptions &&
                                                props.stateDropdownOptions.map((stateName) => (
                                                    <MenuItem key={stateName} value={stateName}>
                                                        {stateName}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} style={{ padding: '8px 8px 8px 8px' }}>
                            <Grid item xs={2} style={{ textAlign: 'center', alignContent: 'flex-end' }}></Grid>
                            <Grid item xs={4} style={{ textAlign: 'center', alignContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    className="filters-button"
                                    onClick={handleClearFilters}
                                >
                                    Clear Filters
                                </Button>
                            </Grid>
                            <Grid item xs={4} style={{ 'text-align': 'center' }}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    className="filters-button green-button"
                                    style={{ backgroundColor: 'green', color: 'white', alignContent: 'flex-end' }}
                                    onClick={handleSubmit}
                                >
                                    Apply Filters
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </ClickAwayListener>
            </Popper>

            {/* </div> */}
        </React.Fragment>
    );
}

export default React.memo(AdminFTLFilterButton);
