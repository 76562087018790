import { gql } from '@apollo/client';
import { SUBREGION_CARRIER_FIELDS } from './subregion_carrier_mappings';
import { SUBREGION_ZIP_FIELDS } from './subregion_zip_mappings';

export const SUBREGION_BASE_FIELDS = gql`
    fragment SubregionBaseFields on subregions {
        subregion_id
        client_id
        name
        type
    }
`;

export const SUBREGION_FIELDS = gql`
    ${SUBREGION_ZIP_FIELDS}
    ${SUBREGION_CARRIER_FIELDS}

    fragment SubregionFields on subregions {
        subregion_id
        client_id
        name
        type
        zips {
            ...SubregionZipFields
        }
        carriers {
            ...SubregionCarrierFields
        }
    }
`;

export const QUERY_BY_CLIENT = gql`
    ${SUBREGION_FIELDS}

    query GetSubregionsByClient($client_id: uuid) {
        results: subregions(where: { client_id: { _eq: $client_id } }) {
            ...SubregionFields
        }
    }
`;

export const QUERY_SUBREGIONS = gql`
    ${SUBREGION_FIELDS}

    query GetSubregions($where: subregions_bool_exp!) {
        results: subregions(where: $where) {
            ...SubregionFields
        }
    }
`;

export const QUERY_SUBREGION_TYPES = gql`
    query GetSubregionTypes {
        results: subregions_aggregate(where: { type: { _neq: "CLIENT" } }, distinct_on: type) {
            nodes {
                subregion_id
                type
            }
        }
    }
`;
