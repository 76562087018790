import styled from '@emotion/styled';
import { colors } from '@/styles';

export const SectionHeader = styled.span`
    font-size: 20px;
    font-weight: 800;
    color: ${colors.greys.secondary};
`;

export const Item = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

export const BoldItem = styled.span`
    font-size: 14px;
    font-weight: 800;
`;

export const GreyItem = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.greys.subtitle};
`;

