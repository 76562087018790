import React, { useContext } from 'react';
import { Grid, Typography, Card, CardContent, Dialog } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Image from 'react-bootstrap/Image';

import { Context } from './context';

import './style.css';

const PODModal = ({ order }) => {
    const { closePODModal, PODModalOpen } = useContext(Context);

    return (
        <>
            {PODModalOpen && (
                <div className="small-modal">
                    <Grid item container xs={12} sm={6}>
                        <Typography variant="h1">Proof of Delivery</Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                'flex-wrap': 'wrap',
                                marginRight: '5px',
                                marginLeft: 'auto',
                            }}
                        >
                            <CloseIcon onClick={closePODModal} style={{ cursor: 'pointer' }} />
                        </div>
                    </Grid>
                    <Card style={{ width: '100%', marginTop: '32px' }}>
                        <CardContent>
                            <Typography>
                                <div className="exceptions-modal-container">
                                    <Typography className="exception-source">Final Delivery Photos</Typography>

                                    <div className="exception-images-container">
                                        {order?.delivery_photo?.map?.((imgSrc) => (
                                            <div style={{ 'margin-right': '10px', 'margin-bottom': '10px' }}>
                                                <a href={imgSrc.url || imgSrc} target="_blank">
                                                    <Image
                                                        style={{
                                                            height: '148px',
                                                            width: '148px',
                                                        }}
                                                        src={imgSrc.url || imgSrc}
                                                    />
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            )}

            <Dialog className="exceptions-modal" open={PODModalOpen} onClose={closePODModal}>
                <div className="exceptions-modal-container">
                    <div
                        style={{
                            display: 'flex',
                            paddingBottom: '2rem',
                            alignItems: 'center',
                        }}
                    >
                        <Typography className="exception-modal-header">Proof of Delivery</Typography>
                        <CloseIcon
                            onClick={closePODModal}
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                right: 0,
                                marginRight: '1rem',
                            }}
                        />
                    </div>

                    <Typography className="exception-source">Final Delivery Photos</Typography>

                    <div style={{ display: 'flex' }}>
                        {order?.delivery_photo?.map?.((imgSrc) => (
                            <div style={{ 'margin-right': '10px', 'margin-bottom': '10px', cursor: 'pointer' }}>
                                <a href={imgSrc.url || imgSrc} target="_blank">
                                    <Image
                                        style={{
                                            height: '148px',
                                            width: '148px',
                                        }}
                                        src={imgSrc.url || imgSrc}
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default PODModal;
