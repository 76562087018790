import { post } from '@/utilities/onwardClient';
import { CREATE_ORDER_JOB } from '@/constants/apiRoutes';

export const createOrderJob = async ({ client_id, orders, type, filename }) => {
    return post(CREATE_ORDER_JOB, {
        clientId: client_id,
        type,
        orders,
        filename,
    });
};
