import { ACCOUNTING_V2 } from '@/constants/featureFlags';

export const ACCOUNTING_TABS = [
    {
        label: 'Internal',
        value: 0,
        query: (client_id) => [
            { order_status: { _in: ['complete', 'routedComplete'] } },
            { _or: [{ shipper_id: { _eq: client_id } }, { carrier_id: { _eq: client_id } }] },
            { oms: { _eq: true } },
        ],
        hasPermission: ({ circles, roles }) => {
            return !!circles?.[ACCOUNTING_V2] || roles['ADMIN'];
        },
    },
    {
        label: 'Onward',
        value: 1,
        query: (client_id) => [
            { carrier_id: { _eq: client_id } },
            { shipper_id: { _neq: client_id } },
            { order_status: { _in: ['complete', 'routedComplete'] } },
        ],
        hasPermission: () => true,
    },
];

export const ACCOUNTING_STAGES = {
    COMPLETED_ORDERS: 'COMPLETED_ORDERS',
    CREATE_INVOICE: 'CREATE_INVOICE',
    CREATE_MULTI_INVOICES: 'CREATE_MULTI_INVOICES',
    SUBMIT_CLAIM: 'SUBMIT_CLAIM',
};

export const ACCOUNTING_SUBTABS = [
    { label: 'Pending Approval' },
    { label: 'Ready to Invoice', accessReq: true },
    { label: 'Invoiced', accessReq: true },
    { label: 'Paid' },
];
