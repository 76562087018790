import { CARRIER_INVOICE_FIELDS } from '@/graphql/queries/carrier_invoices';
import { gql } from '@apollo/client';

export const CARRIER_INVOICES = gql`
    ${CARRIER_INVOICE_FIELDS}
    query CarrierInvoices($where: carrier_invoices_bool_exp!) {
        carrier_invoices_aggregate(where: $where) {
            aggregate {
                count
            }
        }
        carrier_invoices(where: $where, order_by: { created_at: desc_nulls_last }) {
            ...CarrierInvoiceFields
            partner_client {
                client_id
                business_name
            }
            orders {
                order_id
                delivery_date
                order_type
                carrier_id
                order_revenue
                order_revenue_adjustments
                order_status
                order_number
                invoice_class
                qbo_tag
                po_number
                dropoff_name
                oms
                price_breakdown
                pickup_name
                dropoff_city
                dropoff_state
                dropoff_zip
                pickup_city
                pickup_state
                pickup_zip
                shipper_id
            }
        }
    }
`;
