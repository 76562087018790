import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDropzone } from 'react-dropzone';

import { Paper, Typography } from '@material-ui/core';
import { PublishOutlined } from '@material-ui/icons';

//necessary for this specific icon until we upgrade to mui v5
import { ReactComponent as UploadIconSvg } from '../../assets/upload_black_36dp.svg';

function CSVDropzone(props) {
    const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone();

    useEffect(() => {
        if (props.disableChanges) {
            return;
        }
        if (acceptedFiles.length > 0) {
            props.handleFileAdd(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    const baseStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '35px',
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
        }),
        [isDragAccept, isDragReject, isDragActive]
    );

    return (
        <Paper variant="outlined" elevation={0} {...getRootProps({ className: 'dropzone', style })}>
            <input {...getInputProps({ disabled: props.disabled })} />
            <div style={{ textAlign: 'center', fontFamily: 'Montserrat' }}>
                <div>{props.icon || <UploadIconSvg fill="#4c4c4c" />}</div>

                {props.label ? (
                    typeof props.label === 'string' ? (
                        <Typography
                            style={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 650, color: '#4c4c4c' }}
                            variant="h6"
                        >
                            {props.label}
                        </Typography>
                    ) : (
                        props.label
                    )
                ) : (
                    <Typography variant="h6">Click or Drag & Drop</Typography>
                )}
                <div style={{ fontFamily: 'Montserrat' }} className="my-4 text-muted">
                    Only .CSV files are accepted
                </div>
            </div>
        </Paper>
    );
}

CSVDropzone.propTypes = {
    icon: PropTypes.element,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    disabled: PropTypes.bool,
    handleFileAdd: PropTypes.func.isRequired,
};

export default CSVDropzone;
