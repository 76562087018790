import { formatInTimeZone } from 'date-fns-tz';
import { asDateInTZ } from './convertToISO';
import zipcode_to_timezone from 'zipcode-to-timezone';

//get formatted date fields for order when not accessible from cache (subscriptions)
export const addFormattedDateFields = (order) => {
    const zip = order.order_type === 'return' ? order.pickup_zip : order.dropoff_zip;
    const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

    return {
        delivery_date_formatted: order.delivery_date
            ? formatInTimeZone(asDateInTZ(order.delivery_date, tz), tz, 'EEE, MMM d, yyyy')
            : null,
        pickup_date_formatted: order.pickup_date
            ? formatInTimeZone(asDateInTZ(order.pickup_date, tz), tz, 'EEE, MMM d, yyyy')
            : null,
        preferred_delivery_date_formatted: order.preferred_delivery_date
            ? formatInTimeZone(asDateInTZ(order.preferred_delivery_date, tz), tz, 'EEE, MMM d, yyyy')
            : null,
        estimated_delivery_range_start_formatted: order.estimated_delivery_range_start
            ? formatInTimeZone(asDateInTZ(order.estimated_delivery_range_start, tz), tz, 'EEE, MMM d, yyyy')
            : null,
        estimated_delivery_range_end_formatted: order.estimated_delivery_range_end
            ? formatInTimeZone(asDateInTZ(order.estimated_delivery_range_end, tz), tz, 'EEE, MMM d, yyyy')
            : null,
        alternative_delivery_dates_formatted: order.alternative_delivery_dates
            ? order.alternative_delivery_dates.map((date) =>
                  formatInTimeZone(asDateInTZ(date, tz), tz, 'EEE, MMM d, yyyy')
              )
            : null,
    };
};
