import React, { useContext } from 'react';
import { UserContext } from '../App';
import ShipperAccount from './ShipperAccount';
import CarrierAccount from './CarrierAccount';
import TeammateAccount from './TeammateAccount';
import CorporateAccount from './CorporateAccount';

const Account = () => {
    const { user } = useContext(UserContext);

    if (user?.roles?.SHIPPER) {
        return <ShipperAccount />;
    } else if (user?.roles?.CARRIER) {
        return <CarrierAccount />;
    } else if (user?.roles?.TEAMMATE) {
        return <TeammateAccount />;
    } else if (user?.roles?.CORPORATE_ADMIN) {
        return <CorporateAccount />;
    }
};

export default Account;
