import React from 'react';
import { Grid } from '@material-ui/core';
import { OnwardCard } from '@/components/OrderDetailsPage/Card';
import { CardTitle, CardItem } from '@/components/OrderDetailsPage/blocks';
import { css } from '@emotion/react';

export default function MiddleMilePickupCard({ isShipper = false, location, order }) {

    if (isShipper) {
        return (
            <OnwardCard css={css`height: 100%;`} contentProps={{style: { height: '100%' }}}>
                
                <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} direction='column' >
                    <CardTitle>
                        Pick Up
                    </CardTitle>
    
                    <CardItem className="my-1">
                        {order?.pickup_city}, {order?.pickup_state}
                    </CardItem>
                    <CardItem className="my-1">
                        Exact pickup details and address will be determined once order is awarded to a winning bid.
                    </CardItem>
                </Grid>
                
            </OnwardCard>
        );
    }

    return (
        <OnwardCard css={css`height: 100%;`} contentProps={{style: { height: '100%' }}}>
            
            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} direction='column' >
                <CardTitle>
                    Pick Up
                </CardTitle>

                <CardItem className="my-1">
                    If you are awarded the winning bid, the pickup address will be:
                </CardItem>
                <CardItem className="my-1">
                    Your warehouse - {location.location_name}
                </CardItem>
                <CardItem className="my-1">
                    {location?.business_address} {location?.business_unit || ''}
                </CardItem>
                <CardItem className="my-1">
                    {location?.business_city}, {location?.business_state}, {location?.business_zip}
                </CardItem>
            </Grid>
            
        </OnwardCard>
    );
}
