import React from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';

import { SUMMARY_SCHEMA, TABLE_SCHEMA } from './constants/pdf';
import Table from './table';

const RoutePdfExport = ({ data }) => {
    return (
        <Document>
            <Page
                orientation='landscape'
                size='A4'
                style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {data.length > 0 ? (
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginBottom: '20px',
                        }}
                    >
                        {SUMMARY_SCHEMA.map((metric) => {
                            const formatter = metric.format || ((x) => x);
                            const value = metric.getValue(data);
                            const placeholder = metric.default || '';
                            const formatted = value !== undefined && value !== null ? formatter(value) : placeholder;

                            return (
                                <Text
                                    style={{
                                        padding: '2px 0px',
                                        fontSize: '7px',
                                        display: 'flex',
                                        ...(metric.styles || {}),
                                    }}
                                >
                                    {`${metric.header}: ${formatted}`}
                                </Text>
                            );
                        })}
                    </View>
                ) : null}
                <Table schema={TABLE_SCHEMA} data={data} />
            </Page>
        </Document>
    );
};

export default RoutePdfExport;
