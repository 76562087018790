import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { EXCEPTION_DISPLAY } from '@onward-delivery/core';
import Image from 'react-bootstrap/Image';

import { Body1, Body2 } from './blocks';

const ExceptionInfo = ({ exception }) => {
    return (
        <>
            <Grid
                direction="row"
                container
                css={css`
                    margin-top: 24px;
                    margin-bottom: 8px;
                `}
            >
                <Body2>Exception</Body2>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 24px;
                `}
            >
                <Body1>{EXCEPTION_DISPLAY[exception?.type]}</Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body2>Does the customer want to keep the items?</Body2>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 24px;
                `}
            >
                <Body1>{exception.item_kept ? 'Yes' : 'No'}</Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body2>Reason</Body2>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 24px;
                `}
            >
                <Body1>{exception?.reason ? exception.reason : '--'}</Body1>
            </Grid>
            {exception?.images?.length > 0 ? (
                <>
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <Body2>Images</Body2>
                    </Grid>
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 16px;
                        `}
                    >
                        {exception?.images.map((url) => {
                            return (
                                <Grid
                                    direction="column"
                                    container
                                    css={css`
                                        flex: 0;
                                        flex-basis: 0;
                                        margin-right: 8px;
                                        margin-bottom: 8px;
                                    `}
                                >
                                    <a
                                        href={url}
                                        target="_blank"
                                        css={css`
                                            height: 148px;
                                            width: 148px;
                                        `}
                                    >
                                        <Image
                                            src={url}
                                            css={css`
                                                height: 148px;
                                                width: 148px;
                                            `}
                                        />
                                    </a>
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            ) : null}
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body2>Notes</Body2>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    padding-bottom: 36px;
                `}
            >
                <Body1>{exception?.notes ? exception?.notes : '--'}</Body1>
            </Grid>
        </>
    );
};

export default ExceptionInfo;
