import React, { useState } from 'react';
import { Grid, DialogContent, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import dateFnsFormat from 'date-fns/format';
import dateFnsAdd from 'date-fns/add';
import * as Sentry from '@sentry/react';
import getDeliveryWindowDelayed from '../../utilities/getDeliveryWindowDelayed';
import api from '../../utilities/api';
import getDeliveryWindow from '../../utilities/getDeliveryWindow';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ResponsiveSidebarDialog } from '@/styles/blocks';
import { colors } from '@/styles';
import { Body1, H2, H3, PrimaryButton } from './blocks';
import { post } from '@/utilities/onwardClient';
import { DELAY_STOP } from '@/constants/apiRoutes';

const thirtyMinutesInMilliSeconds = 30 * 60 * 1000;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Row = styled.div`
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
`;

const iconStyle = css`
    cursor: pointer;
    color: ${colors.greens.primary};
`;

function DelayTimeModal({ open, onClose, stop, route, setErrorMessage, setStopSaving }) {
    const [delWindowDelay, setDelWindowDelay] = useState(stop?.del_window_delay || 0); // in milliseconds

    const formatDelayWindowCounter = (delWindowDelay) => delWindowDelay * (1 / 1000) * (1 / 60);

    const incrementDelayWindow = () =>
        setDelWindowDelay((prevDelayWindow) => prevDelayWindow + thirtyMinutesInMilliSeconds);

    const decrementDelayWindow = () =>
        setDelWindowDelay((prevDelayWindow) => (!!prevDelayWindow ? prevDelayWindow - thirtyMinutesInMilliSeconds : 0));

    const onSave = async () => {
        try {
            setStopSaving(true);
            const response = await post(DELAY_STOP, {
                delWindowDelay,
                routeId: route.route_id,
                stopId: stop.stop_id,
            });

            if (response.status >= 400) {
                setErrorMessage('Looks like something went wrong. We were unable to set the delay on your stop.');
                Sentry.captureException(
                    new Error(
                        `Error delaying stop timeframe. ${response?.data?.error}. Check function logs for more information.`
                    )
                );
            }
        } catch (error) {
            setErrorMessage('Looks like something went wrong. We were unable to set the delay on your stop.');
            Sentry.captureException(error);
        } finally {
            setStopSaving(false);
            onClose();
        }
    };

    return (
        <ResponsiveSidebarDialog
            // collapsed={collapseSideNav}
            open={open}
            onClose={onClose}
        >
            <DialogContent>
                <Header>
                    <H2>Delay Time Frame</H2>
                    <CloseIcon
                        onClick={onClose}
                        css={css`
                            cursor: pointer;
                        `}
                    />
                </Header>

                <Grid>
                    <Row>
                        <Body1>Current time frame: {getDeliveryWindow(stop, route.scheduled_delivery)}</Body1>

                        <div>
                            <RemoveCircleIcon css={iconStyle} onClick={decrementDelayWindow} />

                            <Body1
                                css={css`
                                    margin: 0 1rem;
                                `}
                            >
                                {formatDelayWindowCounter(delWindowDelay)} MIN
                            </Body1>

                            <AddCircleIcon css={iconStyle} onClick={incrementDelayWindow} />
                        </div>
                    </Row>

                    {!!delWindowDelay && (
                        <Row>
                            <Body1
                                css={css`
                                    color: ${colors.golds.primary};
                                `}
                            >
                                Delayed Time Frame:{' '}
                                {getDeliveryWindowDelayed(
                                    { ...stop, del_window_delay: delWindowDelay },
                                    route.scheduled_delivery
                                )}
                            </Body1>
                        </Row>
                    )}

                    <Row>
                        <Body1
                            css={css`
                                color: ${colors.greys.primary};
                            `}
                        >
                            Note: A text will be sent to the customer with the delayed time frame.
                        </Body1>
                    </Row>

                    <Row>
                        <Button variant="outlined" onClick={onClose}>
                            <H3>Cancel</H3>
                        </Button>
                        <PrimaryButton
                            variant="contained"
                            onClick={onSave}
                            disabled={!route?.route_id || !delWindowDelay}
                        >
                            <H3>Save</H3>
                        </PrimaryButton>
                    </Row>
                </Grid>
            </DialogContent>
        </ResponsiveSidebarDialog>
    );
}

export default DelayTimeModal;
