import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import styled from '@emotion/styled';

const Content = styled((props) => <CardContent {...props} />)`
    &:last-child {
        padding-bottom: 21px;
    }

    padding: 21px 16px;
`;

const ContentMobile = styled((props) => <CardContent {...props} />)`
    &:last-child {
        padding-bottom: 21px;
    }

    padding: 21px 12px;
`;

const Wrapper = styled((props) => <Card {...props} />)`
    width: 100%;
    background: #fff;
`;

const WrapperMobile = styled((props) => <Card {...props} />)`
    width: 100%;
    background: inherit;
    box-shadow: none;
`;

export const OnwardCard = ({ children, contentProps, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <Content {...contentProps}>{children}</Content>
        </Wrapper>
    );
};

export const OnwardCardMobile = ({ children, contentProps, ...rest }) => {
    return (
        <WrapperMobile {...rest}>
            <ContentMobile {...contentProps}>{children}</ContentMobile>
        </WrapperMobile>
    );
};
