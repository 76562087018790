import React from 'react';
import { css } from '@emotion/react';
import { Grid, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { PrimaryButton } from '@/styles/blocks';
import { DatePicker } from '@/components/OrderDetailsPage/Crossdocking/blocks';

import { Body1 } from './blocks';
import {
    REQUIRES_CARRIER_INSTRUCTION,
    ACTION_DISPLAY_MAPPING,
    INSTRUCTIONS_DISPLAY_MAPPING,
    ACTIONS,
} from './constants';

const ResolutionRadioGroup = ({
    resolution,
    instruction,
    exception,
    loading,
    updates,
    tz,
    actions,
    instructions,
    isValid,
    callbacks,
    route_id,
}) => {
    return (
        <>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <Body1>How would you like to resolve the exception?</Body1>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <RadioGroup
                    onChange={(e) => {
                        callbacks.setAction(e.target.value);
                    }}
                >
                    {actions.map((action) => {
                        return (
                            <FormControlLabel
                                key={action}
                                value={action}
                                control={<Radio checked={resolution === action} color="primary" />}
                                label={<Body1>{ACTION_DISPLAY_MAPPING[action]}</Body1>}
                            />
                        );
                    })}
                </RadioGroup>
            </Grid>
            {resolution === ACTIONS.RESCHEDULE ? (
                <Grid
                    container
                    direction="row"
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <DatePicker
                            dateLabel="New Pickup Date"
                            tz={tz}
                            timestamp={updates.pickup_date}
                            onChange={(date) =>
                                callbacks.setUpdates((prev) => ({
                                    ...prev,
                                    pickup_date: date ? date.toISOString() : null,
                                }))
                            }
                        />
                    </Grid>
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <DatePicker
                            dateLabel="New Dropoff Date"
                            tz={tz}
                            timestamp={updates.delivery_date}
                            onChange={(date) =>
                                callbacks.setUpdates((prev) => ({
                                    ...prev,
                                    delivery_date: date ? date.toISOString() : null,
                                }))
                            }
                        />
                    </Grid>
                </Grid>
            ) : null}

            {REQUIRES_CARRIER_INSTRUCTION.includes(exception.type) ? (
                <>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <Body1>What would you like the carrier to do with the items?</Body1>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <RadioGroup
                            onChange={(e) => {
                                callbacks.setInstruction(e.target.value);
                            }}
                        >
                            {instructions.map((key) => {
                                return (
                                    <FormControlLabel
                                        key={key}
                                        value={key}
                                        control={<Radio checked={instruction === key} color="primary" />}
                                        label={<Body1>{INSTRUCTIONS_DISPLAY_MAPPING[key]}</Body1>}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </Grid>
                </>
            ) : null}

            {route_id &&
            exception.route_id &&
            exception.route_id === route_id &&
            [ACTIONS.CANCEL_ORDER, ACTIONS.RESCHEDULE, ACTIONS.RESOLVE_NOOP].includes(resolution) ? (
                <>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <Grid item xs={12}>
                            <Body1>Would you like to recalculate the route distance with the stop(s) removed?</Body1>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                onChange={(e) => {
                                    callbacks.setUpdates((prev) => ({
                                        ...prev,
                                        recalc_route: e.target.value,
                                    }));
                                }}
                            >
                                <FormControlLabel
                                    value="true"
                                    control={<Radio checked={updates.recalc_route === 'true'} color="primary" />}
                                    label={<Body1>Yes</Body1>}
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio checked={updates.recalc_route === 'false'} color="primary" />}
                                    label={<Body1>No</Body1>}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <Grid item xs={12}>
                            <Body1>How would you like to update the route revenue?</Body1>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                onChange={(e) => {
                                    callbacks.setUpdates((prev) => ({
                                        ...prev,
                                        route_rev_action: e.target.value,
                                    }));
                                }}
                            >
                                {[
                                    { value: 'keep', label: 'Keep current revenue' },
                                    { value: 'remove', label: 'Remove revenue for this order' },
                                    { value: 'custom', label: 'New custom charge' },
                                ].map(({ value, label }) => (
                                    <FormControlLabel
                                        key={value}
                                        value={value}
                                        control={<Radio checked={updates.route_rev_action === value} color="primary" />}
                                        label={<Body1>{label}</Body1>}
                                    />
                                ))}
                            </RadioGroup>
                        </Grid>
                        {updates.route_rev_action === 'custom' && (
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    label="Custom charge"
                                    value={updates.custom_charge || ''}
                                    onChange={(e) => {
                                        callbacks.setUpdates((prev) => ({
                                            ...prev,
                                            custom_charge: e.target.value,
                                        }));
                                    }}
                                    css={css`
                                        margin-top: 10px;
                                        margin-bottom: 0px !important;
                                    `}
                                />
                            </Grid>
                        )}
                    </Grid>
                </>
            ) : null}

            <Grid container direction="row">
                <PrimaryButton disabled={!isValid || loading} onClick={callbacks.resolve}>
                    Resolve
                </PrimaryButton>
            </Grid>
        </>
    );
};

export default ResolutionRadioGroup;
