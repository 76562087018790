import NavResponsiveModal, { ModalContent, ModalHeader } from '@/components/Navigation/NavResponsiveModal';
import { colors } from '@/styles';
import { format } from 'date-fns';
import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { Body1, fragments } from '../blocks';
import { PALLET_STATUS_LABELS } from '@/constants/manifestStatuses';

const PalletLogs = ({ pallet, callbacks }) => {
    const onClose = () => {
        callbacks.openPalletLog(null);
    };

    return (
        <NavResponsiveModal open={!!pallet} onClose={onClose}>
            <ModalHeader title={'Storage History'} onClose={onClose} />
            <ModalContent
                css={css`
                    width: 400px;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                        max-height: 400px;
                    `}
                >
                    {(pallet?.logs || []).map((log, idx) => (
                        <Grid
                            key={log.log_id}
                            container
                            direction="row"
                            css={css`
                                padding-bottom: 0.5rem;
                                justify-content: space-between;
                                flex-wrap: nowrap;
                                ${idx > 0 ? 'padding-top: 0.5rem;' : ''}
                                ${idx > 0 ? `border-top: 1px solid ${colors.greys.border};` : ''}
                            `}
                        >
                            <Grid container direction="column">
                                <Body1 css={fragments.grey}>
                                    {format(new Date(log.created_at), 'EEE, MMM d, yyyy')}
                                </Body1>
                                <Body1 css={fragments.grey}>{format(new Date(log.created_at), 'h:mm a')}</Body1>
                            </Grid>
                            <Grid item>
                                <Body1>
                                    {PALLET_STATUS_LABELS[log.warehouse_status]} {log.warehouse_location || ''}
                                </Body1>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </ModalContent>
        </NavResponsiveModal>
    );
};

export default PalletLogs;
