import { Row, SectionSubtitle } from '@/styles/blocks';
import { TextField } from '../../blocks';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { ModalContext } from '.';
import { COMPLETE_STATUSES } from '../../constants/statusOptions';
import { ClientSelect, DateInput, InfoInput, PriceInput, TimeSelect } from '../InputFields';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { useSwappedAttributes } from '../../hooks';
import { Alert } from '@material-ui/lab';
import { useClientUser } from '@/hooks';
import { MenuItem } from '@material-ui/core';

const CustomStatusSelect = ({ value, onChange, orderStatuses, ...rest }) => {
    return (
        <TextField
            select
            variant="outlined"
            label="Order Status"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            fullWidth
            {...rest}
        >
            {Object.values(orderStatuses).map((status) => (
                <MenuItem key={status.value} value={status.value}>
                    {status.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

const BrokerTab = ({ hasError }) => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { partner_carriers } = useClientUser();
    const { order } = modalState;

    const { zip } = useSwappedAttributes(order);

    const margin = useMemo(() => {
        if (order?.broker_billed_rate && order?.broker_paid_rate) {
            const diff = order?.broker_billed_rate - order?.broker_paid_rate;
            return `$${diff.toFixed(2)} (${((diff / order?.broker_billed_rate) * 100).toFixed(2)}%)`;
        }
        return 'N/A';
    }, [order]);

    const orderTZ = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : 'America/New_York';
    }, [order, zip]);

    const preClaimedStatuses = [
        { value: 'pending', label: 'Pending' },
        { value: 'open', label: 'Open' },
    ];

    return (
        <>
            <Grid container direction="column">
                <Row>
                    <CustomStatusSelect
                        orderStatuses={[
                            ...(!order?.carrier_id ? preClaimedStatuses : []),
                            { value: 'claimed', label: 'Claimed' },
                            { value: 'inProgress', label: 'In Progress' },
                            { value: 'complete', label: 'Complete' },
                            { value: 'rescheduled', label: 'Rescheduled' },
                        ]}
                        value={order.order_status}
                        onChange={(status) =>
                            callbacks.modifyOrder({
                                order_status: status,
                                ...(COMPLETE_STATUSES.includes(status) &&
                                !COMPLETE_STATUSES.includes(order.order_status)
                                    ? {
                                          completion_time: new Date().toISOString(),
                                          completion_source: 'BROKER_MANUAL',
                                      }
                                    : {}),
                            })
                        }
                    />
                </Row>
                <Row>
                    <ClientSelect
                        value={order.carrier_id}
                        options={partner_carriers.map((p) => p.carrier)}
                        onChange={(carrierId) => {
                            let orderStatus = null;
                            if (carrierId === null) {
                                orderStatus = 'pending';
                            } else if (preClaimedStatuses.map((s) => s.value).includes(order.order_status)) {
                                orderStatus = 'claimed';
                            }

                            callbacks.modifyOrder({
                                carrier_id: carrierId,
                                ...(orderStatus
                                    ? {
                                          order_status: orderStatus,
                                      }
                                    : {}),
                            });
                        }}
                        allowEmpty={true}
                        type="carrier"
                        customMenuRender={(opt) => {
                            let display = '';
                            if (opt.business_city && opt.business_state) {
                                display += `${opt.business_city.trim()}, ${opt.business_state.trim()} - `;
                            }
                            display += opt.business_name;
                            return display;
                        }}
                    />
                </Row>
                <Row>
                    <SectionSubtitle
                        css={css`
                            color: black;
                        `}
                    >
                        Rates
                    </SectionSubtitle>
                </Row>
                <Row>
                    <PriceInput
                        value={order.broker_billed_rate}
                        label="Billed to Customer Rate"
                        onChange={(billed_rate) => callbacks.modifyOrder({ broker_billed_rate: billed_rate })}
                        css={css`
                            margin-right: 0.5rem;
                        `}
                    />
                    <PriceInput
                        value={order.broker_paid_rate}
                        label="Paid to Carrier Rate"
                        onChange={(paid_rate) => callbacks.modifyOrder({ broker_paid_rate: paid_rate })}
                        css={css`
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;
                        `}
                    />
                    <InfoInput
                        value={margin}
                        label="Margin"
                        css={css`
                            margin-left: 0.5rem;
                        `}
                    />
                </Row>
                <Row>
                    <SectionSubtitle
                        css={css`
                            color: black;
                        `}
                    >
                        Scheduling
                    </SectionSubtitle>
                </Row>
                {order.routes?.length > 0 && (
                    <Row>
                        <Alert severity="warning">Order is routed, update time frames in Planning instead.</Alert>
                    </Row>
                )}
                <Row>
                    <DateInput
                        value={order.pickup_date}
                        onChange={(pickupDate) => callbacks.modifyOrder({ pickup_date: pickupDate })}
                        label="Pickup Date"
                        tz={orderTZ}
                        firstAvailable={order.first_available_date}
                        css={css`
                            margin-right: 0.5rem;
                        `}
                        disabled={order.routes?.length}
                        error={hasError.pickup_date}
                    />
                    <DateInput
                        value={order.delivery_date}
                        onChange={(deliveryDate) => callbacks.modifyOrder({ delivery_date: deliveryDate })}
                        label="Delivery Date"
                        tz={orderTZ}
                        firstAvailable={order.first_available_date}
                        css={css`
                            margin-left: 0.5rem;
                        `}
                        disabled={order.routes?.length}
                        error={hasError.delivery_date}
                    />
                </Row>
                <Row>
                    <TimeSelect
                        value={order.pickup_window_start}
                        label="Pickup Start"
                        tz={orderTZ}
                        date={order.pickup_date}
                        onChange={(val) => callbacks.modifyOrder({ pickup_window_start: val })}
                        css={css`
                            margin-right: 0.5rem;
                        `}
                        disabled={order.routes?.length || !order.pickup_date}
                        error={hasError.pickup_window}
                    />
                    <TimeSelect
                        value={order.pickup_window_end}
                        label="Pickup End"
                        tz={orderTZ}
                        date={order.pickup_date}
                        onChange={(val) => callbacks.modifyOrder({ pickup_window_end: val })}
                        css={css`
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;
                        `}
                        disabled={order.routes?.length || !order.pickup_date}
                        error={hasError.pickup_window}
                    />
                    <TimeSelect
                        value={order.del_window_start}
                        label="Delivery Start"
                        tz={orderTZ}
                        date={order.delivery_date}
                        onChange={(val) => callbacks.modifyOrder({ del_window_start: val })}
                        css={css`
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;
                        `}
                        disabled={order.routes?.length || !order.delivery_date}
                        error={hasError.delivery_window}
                    />
                    <TimeSelect
                        value={order.del_window_end}
                        label="Delivery End"
                        tz={orderTZ}
                        date={order.delivery_date}
                        onChange={(val) => callbacks.modifyOrder({ del_window_end: val })}
                        css={css`
                            margin-left: 0.5rem;
                        `}
                        disabled={order.routes?.length || !order.delivery_date}
                        error={hasError.delivery_window}
                    />
                </Row>
                <Row>
                    <SectionSubtitle
                        css={css`
                            color: black;
                        `}
                    >
                        Notes
                    </SectionSubtitle>
                </Row>
                <Row>
                    <TextField
                        placeholder="Place any internal notes you have for the order here"
                        variant="outlined"
                        name="desc"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={order.shipper_notes || null}
                        onChange={(e) => callbacks.modifyOrder({ shipper_notes: e.target.value })}
                        multiline
                        rows={5}
                    />
                </Row>
            </Grid>
        </>
    );
};

export default BrokerTab;
