import React, { useState } from 'react';
import { Snackbar as MuiSnackbar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert 
        elevation={6} 
        variant="filled" 
        {...props} 
    />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function Snackbar(props) {
    const {
        // Required params
        open,
        handleClose,
        severity, // e.g. success, error, info, warning
        message,

        // Optional params for customizing appearance / functionality
        anchorOrigin,
        disableClose,
        customAction,
        autohide,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MuiSnackbar
                open={open}
                autoHideDuration={autohide || 15000}
                onClose={handleClose}
                anchorOrigin={anchorOrigin || { horizontal: 'right', vertical: 'top' }}
                {...props}
            >
                <Alert 
                    onClose={disableClose ? null : handleClose} 
                    severity={severity} 
                    action={customAction ? customAction : null}
                >
                    {message}
                </Alert>
            </MuiSnackbar>
        </div>
    );
}
