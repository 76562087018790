import React from 'react';

import { css } from '@emotion/react';
import { TableRow } from '@material-ui/core';

export default function BodyRow({ row, children, ...props }) {
    return (
        <TableRow
            {...props}
            hover
            css={css`
                &:hover {
                    cursor: pointer;
                    background-color: #f8f8f8;
                }
            `}
        >
            {children}
        </TableRow>
    );
}
