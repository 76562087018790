import { Typography } from '@material-ui/core';
import React, { useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import Image from 'react-bootstrap/Image';
import { PrimaryButton } from '@/styles/blocks';

export default function UploadItemPic({ item, addPic, className }) {
    const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive, open } = useDropzone({
        accept: 'image/*',
    });

    const uploadFile = async (file, fileType) => {
        const fileUrl = URL.createObjectURL(file);
        const fbFileUrl = await uploadPhotoAsync(fileUrl, 'itemPic', fileType);
        addPic(fbFileUrl);
    };

    useEffect(() => {
        const fileTypesMap = {
            'image/png': 'png',
            'image/jpeg': 'jpg',
            'image/jpg': 'jpg',
        };

        if (acceptedFiles.length > 0) {
            const fileType = fileTypesMap[acceptedFiles[0].type];
            uploadFile(acceptedFiles[0], fileType);
        }
    }, [acceptedFiles]);

    const baseStyle = {
        height: '100px',
        width: '100%',
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        borderRadius: '5px',
        outline: '1px solid #c9c9c9',
        fontFamily: 'Roboto, sans-serif',
        cursor: 'pointer',
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
        }),
        [isDragAccept, isDragReject, isDragActive]
    );

    if (item?.pic) {
        return (
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                `}
            >
                <a href={item.pic} target="_blank">
                    <Image
                        style={{
                            width: '100%',
                            marginTop: '20px',
                        }}
                        src={item.pic}
                    />
                </a>
                <PrimaryButton
                    css={css`
                        margin-top: 20px;
                        align-self: center;
                    `}
                    onClick={open}
                >
                    Redo
                </PrimaryButton>
            </div>
        );
    }

    return (
        <div className={className}>
            <div {...getRootProps({ className: 'dropzone', style })}>
                <input {...getInputProps()} />
                <div style={{ textAlign: 'center', padding: '10px 0' }}>
                    <CameraAltOutlinedIcon style={{ color: '#b3b3b3' }} />
                    <Typography style={{ fontSize: '15px', color: '#4c4c4c' }}>Upload Image of Item</Typography>
                </div>
            </div>
        </div>
    );
}
