import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import DayPicker, { DateUtils } from 'react-day-picker-legacy';

import './style.css';
import 'react-day-picker-legacy/lib/style.css';

function AvailabilityCalendar(props) {
    /*add the following to the parent component:
    const [selectedDays, setSelectedDays] = useState([])

    for pages that need this component to be pre-filled with already selected dates (like on orderdetails), add the following useEffect to parent
    where days is an array of date strings (from firebase):
    make editable={false}
    useEffect(() => {
        let dateConvert = days.map((day) => {
            return new Date(day)
        })
        setSelectedDays(dateConvert)
    },[])

    days example:
    [
        "Wed Nov 24 2021 12:00:00 GMT-0700 (Mountain Standard Time)",
        "Thu Nov 25 2021 12:00:00 GMT-0700 (Mountain Standard Time)"
    ]

    disabledDays prop example, will disable everything except the next 2 weeks:
        disabledDays = {[
            {
                after: addDays(new Date(), 14),
                before: new Date()
            }
        ]}

        addDays function is a built in function that can be imported from date-fns
    */

    const { editable, selectedDays, setSelectedDays } = props;

    const modifiers = {
        selected: selectedDays,
    };

    const modifiersStyles = {
        selected: {
            color: 'white',
            position: 'relative',
            backgroundColor: '#59b863',
            borderRadius: '20%',
            borderLeft: '1px solid white',
            borderRight: '1px solid white',
            borderTop: '2px solid white',
            boxSizing: 'border-box',
        },
        outside: {
            visibility: 'hidden',
        },
    };

    const handleDayClick = (day, { selected, disabled }) => {
        if (disabled) {
            return;
        }

        if (!selectedDays || selectedDays?.constructor !== Array) {
            setSelectedDays([day]);
            return;
        }

        const selectedDaysAll = selectedDays.concat();

        if (selected) {
            const selectedIndex = selectedDaysAll.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
            selectedDaysAll.splice(selectedIndex, 1);
        } else {
            selectedDaysAll.push(day);
        }

        setSelectedDays(selectedDaysAll);
    };

    return (
        <DayPicker
            selectedDays={selectedDays}
            onDayClick={editable ? props.handleDayClick || handleDayClick : null}
            modifiers={{ ...modifiers, ...(props.modifiers || {}) }}
            modifiersStyles={{ ...modifiersStyles, ...(props.modifiersStyles || {}) }}
            disabledDays={props.disabledDays}
            {...props}
        />
    );
}

AvailabilityCalendar.propTypes = {
    /* allows user to edit selected days */
    editable: PropTypes.bool,
    /* array of date strings */
    selectedDays: PropTypes.array,
    /* function to change state of parent element */
    setSelectedDays: PropTypes.func,
    handleDayClick: PropTypes.func,
    modifiers: PropTypes.object,
    modifiersStyles: PropTypes.object,
    disabledDays: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.func, PropTypes.string]),
};

export default AvailabilityCalendar;
