import { formatInTimeZone } from 'date-fns-tz';
import { genAttributes } from '@onward-delivery/core';
import { calcItemCount, calcItemVolume, calcItemWeight } from '@/utilities/calculateItemMetrics';
import deliveryStrings from '@/constants/deliveryStrings';
const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const TABLE_SCHEMA = [
    {
        styles: { flex: 1, flexBasis: 0 },
        header: 'Stop #',
        getValue: ({ stop }) => stop.obj.ordering,
    },
    {
        styles: { flex: 2, flexBasis: 0 },
        header: 'Order/PO #s',
        getValue: ({ stop }) => stop.orders?.map((order) => (order?.order_number + '/' + order?.po_number).trim()),
        format: (value) => {
            return value.join(', ');
        },
    },
    {
        styles: { flex: 1, flexBasis: 0 },
        header: 'Type',
        getValue: ({ stop }) => stop.obj.type,
    },
    {
        styles: { flex: 2, flexBasis: 0 },
        header: 'Service Level',
        getValue: ({ stop }) => { 
            if (stop.obj.ordering === 0 && stop.obj.driving_time === null) return null;
            return stop.orders?.map((order) => {
                return stop.obj.type === 'PICKUP' ? deliveryStrings.pickupLocationType[order?.pickup_location_type] : deliveryStrings.pickupLocationType[order?.dropoff_location_type];
            });
        },
        format: (value) => {
            return value[0] ? value.join(', ') : "N/A";
        },
    },
    {
        styles: { flex: 2, flexBasis: 0 },
        header: 'Customer Names',
        getValue: ({ stop }) => stop.orders?.map((order) => order?.dropoff_name),
        format: (value) => {
            return value.join(', ');
        },
    },
    {
        styles: { flex: 2, flexBasis: 0 },
        header: 'Address',
        getValue: ({ stop }) => {
            return stop?.obj?.address;
        },
        format: (value) => {
            return Array.isArray(value) ? value.join(', ') : value;
        },
    },
    
    {
        styles: { flex: 2, flexBasis: 0 },
        header: 'Phone #s',
        getValue: ({ stop }) => stop.orders?.map((order) => order?.dropoff_phone),
        format: (value) => {
            return value.join(', ');
        },
    },    
    {
        styles: { flex: 1, flexBasis: 0 },
        header: 'Pieces',
        getValue: ({ stop }) => {
            return calcItemCount(stop.orders);
        },
        format: (value) => value,
    },
    {
        styles: { flex: 1, flexBasis: 0 },
        header: 'Weight',
        getValue: ({ stop }) => {
            return calcItemWeight(stop.orders);
        },
        format: (value) => value,
    },
    {
        styles: { flex: 1, flexBasis: 0 },
        header: 'Start Time',
        getValue: ({ stop }) => {
            const date = new Date(stop.obj.del_window_start);
            return isNaN(date) ? undefined : date;
        },
        format: (value, { tz = 'America/New_York' }) => formatInTimeZone(value, tz, 'HH:mm'),
    },
    {
        styles: { flex: 1, flexBasis: 0 },
        header: 'End Time',
        getValue: ({ stop }) => {
            const date = new Date(stop.obj.del_window_end);
            return isNaN(date) ? undefined : date;
        },
        format: (value, { tz = 'America/New_York' }) => formatInTimeZone(value, tz, 'HH:mm'),
    },
    {
        styles: { flex: 3, flexBasis: 0 },
        header: 'Comments',
        getValue: ({ stop }) => { 
            if (stop.obj.ordering === 0 && stop.obj.driving_time === null) return null;
            return stop.orders?.map((order) => {
                return stop.obj.type === 'PICKUP' ? order?.pickup_comments : order?.dropoff_comments;
            });
        },
        format: (value) => {
            return value[0] ? value.join(', ') : "N/A";
        },
    },
    {
        styles: { flex: 2, flexBasis: 0 },
        header: 'WH Loc.',
        getValue: ({ stop }) => { 
            if (stop.obj.ordering === 0 && stop.obj.driving_time === null || stop.obj.type === 'PICKUP') return null;
            return stop.orders?.map((order) => {
                return order?.items?.map((item) => item?.pallet?.warehouse_location);
            });
        },
        format: (value) => {
            return value[0] ? value.join(', ') : "N/A";
        },
    },
];

export const SUMMARY_SCHEMA = [
    {
        header: 'Driver',
        getValue: ([{ driver }]) => driver.username,
    },
    {
        header: 'Scheduled Delivery Date',
        getValue: ([{ stop }]) => {
            const date = new Date(stop.obj.del_window_start);
            return isNaN(date) ? undefined : date;
        },
        format: (value) => dateNumeric.format(value),
    },
    {
        header: 'Orders',
        getValue: (stops) => {
            return stops.reduce((sum, { stop }) => {
                return sum + stop.orders.length;
            }, 0);
        },
    },
    {
        header: 'Items',
        getValue: (stops) => {
            return stops.reduce((sum, { stop }) => {
                const count = calcItemCount(stop.orders);
                return sum + count;
            }, 0);
        },
    },
    {
        header: 'Cu Ft.',
        getValue: (stops) => {
            return stops.reduce((sum, { stop }) => {
                const total = calcItemVolume(stop.orders);
                return sum + total;
            }, 0);
        },
        format: (value) => integerFormatter.format(value),
    },
];
