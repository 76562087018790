import { gql } from '@apollo/client';

export const CANCEL_ORDERS = gql`
    mutation CancelOrders($order_ids: [uuid!]!) {
        update_orders(where: { order_id: { _in: $order_ids } }, _set: { order_status: "cancelled" }) {
            returning {
                order_id
                order_status
            }
        }
    }
`;
