import React, { useState, useMemo, useCallback } from 'react';
import { addDays, isEqual, getWeek, setWeek, startOfWeek, endOfWeek } from 'date-fns';
import { css } from '@emotion/react';
import { Grid, DialogContent, Checkbox, FormControlLabel, MenuItem, TextField } from '@material-ui/core';

import {
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
    ResponsiveSidebarDialog,
    StickyModalActions,
} from '@/styles/blocks';

import { Body1 } from '../blocks';

const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
const thisWeek = getWeek(TODAY, { weekStartsOn: 1 });

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const CreateReceivableInvoice = ({ open, shippers = [], loading, callbacks }) => {
    const [params, setParams] = useState({
        shippers: [],
    });

    const options = Array(5)
        .fill(0)
        .map((_, idx) => idx + 1)
        .map((mod) => {
            const day = addDays(TODAY, -1 * mod * 7);
            const week = setWeek(day, -1 * mod + thisWeek, { weekStartsOn: 1 });
            const sow = startOfWeek(week, { weekStartsOn: 1 });
            const eow = endOfWeek(week, { weekStartsOn: 1 });

            return {
                sow,
                eow,
                week_number: -1 * mod + thisWeek,
                label: `${dateNumeric.format(sow)} - ${dateNumeric.format(eow)}`,
            };
        });

    return (
        <ResponsiveSidebarDialog
            open={open}
            onClose={() => {
                setParams({});
                callbacks.onClose();
            }}
            fullWidth={true}
            maxWidth="sm"
            css={css`
                .MuiDialog-container {
                    align-items: normal;
                }
            `}
        >
            <ModalTitle
                border
                title="Create Receivable"
                onClose={() => {
                    setParams({});
                    callbacks.onClose();
                }}
            />
            <DialogContent
                css={css`
                    width: 100%;
                    padding: 0;
                `}
            >
                <Grid
                    constainer
                    direction="column"
                    css={css`
                        height: 100%;
                        padding: 0 24px;

                        padding-top: 32px;
                    `}
                >
                    <Grid
                        constainer
                        direction="row"
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            select
                            fullWidth
                            label="Shipper"
                            value={params.shippers || []}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                setParams((prev) => {
                                    return {
                                        ...prev,
                                        shippers: e.target.value,
                                    };
                                });
                            }}
                            SelectProps={{
                                multiple: true,
                                renderValue: (selected) => {
                                    const map = Object.fromEntries(
                                        shippers.map(({ label, value }) => {
                                            return [value, label];
                                        })
                                    );
                                    return selected.map((opt) => map[opt]).join(', ');
                                },
                            }}
                            css={css`
                                background-color: white;
                                width: 100%;
                                padding-right: 2px;
                                margin-top: 0.2rem;
                            `}
                        >
                            {shippers.map(({ label, value }) => (
                                <MenuItem key={value} value={value}>
                                    <Checkbox color="primary" checked={(params?.shippers || []).indexOf(value) > -1} />
                                    <Body1>{label}</Body1>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid constainer direction="row">
                        <TextField
                            variant="outlined"
                            select
                            fullWidth
                            label="Pay Period"
                            value={params?.week?.week_number || ''}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                const obj = options.find(({ week_number }) => {
                                    return week_number === e.target.value;
                                });
                                setParams((prev) => {
                                    return {
                                        ...prev,
                                        week: obj,
                                    };
                                });
                            }}
                            css={css`
                                background-color: white;
                                width: 100%;
                                padding-right: 2px;
                                margin-top: 0.2rem;
                            `}
                        >
                            {options.map(({ week_number, label }) => (
                                <MenuItem key={week_number} value={week_number}>
                                    <Body1>{label}</Body1>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <StickyModalActions
                border="true"
                css={css`
                    justify-content: flex-end;
                `}
            >
                <Grid>
                    <SecondaryButton
                        onClick={() => {
                            setParams({});
                            callbacks.onClose();
                        }}
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>
                <Grid>
                    <PrimaryButton
                        loading={loading.createReceivable}
                        disabled={!(params.week && params.shippers)}
                        onClick={() => {
                            callbacks.onCreateReceivable(params).then(() => {
                                setParams({});
                                callbacks.onClose();
                            });
                        }}
                    >
                        Create
                    </PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default CreateReceivableInvoice;
