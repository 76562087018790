import React, { useContext, useMemo } from 'react';
import { PlanningContext } from '../context';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import { MODALS } from '../constants';
import { useMutation } from '@apollo/client';
import { UPDATE_ROUTE_STOPS } from '../graphql/mutations';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import * as Sentry from '@sentry/react';
import { cloneDeep } from 'lodash';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { removeRefs } from '@/graphql/util';
import { useClientUser } from '@/hooks';

const StopRemoveModal = () => {
    const {
        actionableRoute,
        setActionableRoute,
        actionableStop,
        setActionableStop,
        modalOpen,
        setModalOpen,
        setError,
        callbacks,
    } = useContext(PlanningContext);
    const { user_id, default_end_location } = useClientUser();

    const stopOrders = useMemo(() => {
        return (actionableRoute?.orders || [])
            .filter((mapping) => actionableStop?.orders?.includes(mapping.order_id))
            .map((mapping) => mapping.order);
    }, [actionableRoute, actionableStop]);

    const [updateStops, { loading }] = useMutation(UPDATE_ROUTE_STOPS, {
        update: (cache, { data }) => {
            const removedStops = data?.delete_stops?.returning || [];
            removedStops.forEach((stop) => cache.evict(cache.identify(stop)));

            cache.modify({
                id: cache.identify(actionableRoute),
                fields: {
                    stopsByRouteId: (prev, { toReference }) => {
                        return removeRefs(prev, removedStops, { toReference });
                    },
                },
            });
        },
    });

    const handleModalClose = () => {
        setModalOpen(null);
        setActionableRoute(null);
        setActionableStop(null);
    };

    const handleRemoveStop = async () => {
        let newStops = cloneDeep(actionableRoute?.stopsByRouteId || []);
        for (const order of stopOrders || []) {
            newStops = await FTLStopHelpers.removeStop(
                order,
                { ...actionableRoute, stopsByRouteId: newStops },
                default_end_location
            );
        }

        const pickups = actionableRoute.orders
            .filter(({ type, order_id }) => type === 'PICKUP' && actionableStop?.orders?.includes(order_id))
            .map(({ order }) => order);
        const deliveries = actionableRoute.orders
            .filter(({ type, order_id }) => type !== 'PICKUP' && actionableStop?.orders?.includes(order_id))
            .map(({ order }) => order);

        updateStops({
            variables: {
                route_id: actionableRoute?.route_id,
                route_update: {
                    need_to_optimize: true,
                },
                events: [
                    ...pickups.map((order) => ({
                        order_id: order.order_id,
                        action: `${order.event_state}:REMOVE_PU`,
                        notes: `Removed route ${actionableRoute.route_number}`,
                    })),
                    ...deliveries.map((order) => ({
                        order_id: order.order_id,
                        action: `${order.event_state}:${order.event_state === 'PICKED_UP' ? 'REMOVE' : 'REMOVE_DO'}`,
                        notes: `Removed route ${actionableRoute.route_number}`,
                    })),
                ],
                order_updates:
                    deliveries?.map((order) => ({
                        where: { order_id: { _eq: order.order_id } },
                        _set: {
                            delivery_time_confirmed: null,
                            del_window_start: null,
                            del_window_end: null,
                            original_del_window_start: null,
                            original_del_window_end: null,
                            order_status:
                                order.shipper_id !== order.carrier_id && order.carrier_id === user_id
                                    ? 'claimed'
                                    : 'pending',
                        },
                    })) || [],
                ...FTLStopHelpers.gqlStopUpdates(newStops, actionableRoute),
            },
            onError: (error) => {
                setError(
                    error,
                    `Failed to remove order(s) ${stopOrders.map((o) => o.order_number).join(', ')} from route ${
                        actionableRoute?.route_number
                    }`
                );
            },
            onCompleted: () => {
                handleModalClose();
                callbacks.refetch();
            },
        });
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ROUTE_REMOVE_STOP} onClose={handleModalClose}>
            <ModalTitle>{`Remove order(s) ${stopOrders.map((o) => o.order_number).join(', ')} from route ${
                actionableRoute?.route_number
            }`}</ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <Body2>
                        By removing this stop, the timeframes of the stops after this will be effected. Confirm to
                        recalculate these timeframes.
                    </Body2>
                </GridItemRow>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={handleRemoveStop}>
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default StopRemoveModal;
