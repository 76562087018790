import React, { useCallback, useMemo } from 'react';
import { useClientUser } from '@/hooks';

import { PROGRESSION_TYPES, SHIPMENT_FORM_MODAL_PROGRESSION } from '../constants';
import { genAttributes, validate } from '@onward-delivery/core';
import { genAttributesPerTab } from './modal';
import { omit } from 'lodash';
import { ITEM_READONLY_FIELDS, ORDER_READONLY_FIELDS } from '@/constants/readonlyFields';

export const useOrderErrors = (orders) => {
    return useMemo(() => {
        if (orders.length === 0) {
            return {};
        }

        return Object.fromEntries(
            orders.map((order) => {
                const contact = genAttributes(order);
                const pickup = genAttributes(order, true);
                const fieldsByTab = genAttributesPerTab({
                    contact,
                    pickup,
                });

                const [, hasMissingField] = validate(order);
                return [
                    order.order_id,
                    Object.fromEntries(
                        Object.keys(fieldsByTab).map((tab) => {
                            return [tab, fieldsByTab[tab].some((field) => hasMissingField?.includes(field))];
                        })
                    ),
                ];
            }, [])
        );
    }, [orders]);
};

export const useJobDetailsCallbacks = (state, callbacks) => {
    const { user_id, payment_type, stripe_payment_method } = useClientUser();

    const advance = useCallback(() => {
        if (state.progression.current === SHIPMENT_FORM_MODAL_PROGRESSION[state.progression.type].length - 1) {
            const isMarketplace = state.job.orders.some((order) => !order.oms);
            const isAssigned = state.job.orders.some((order) => order.carrier_id);
            const isAuctioned = isMarketplace && !isAssigned;

            if (isAuctioned) {
                callbacks.submitListings({
                    shipper_id: user_id,
                    listings: Object.values(state.listings).map((listing) => ({
                        ...listing,
                        expiration_date: listing.expiration_date || state.expirationDate,
                    })),
                });
            } else {
                callbacks.confirmOrders({
                    variables: {
                        order_ids: state.job.orders.map((order) => order.order_id),
                    },
                });
            }

            callbacks.setProgression({
                current: 0,
                type: null,
            });
        } else {
            callbacks.setProgression((prev) => {
                return { ...prev, current: prev.current + 1 };
            });
        }
    }, [state, callbacks]);

    const revert = useCallback(() => {
        callbacks.setProgression((prev) => {
            if (prev.current > 0) {
                return { ...prev, current: prev.current - 1 };
            }

            return {
                current: 0,
                type: null,
            };
        });
    }, []);

    const closeEditModal = useCallback(() => {
        callbacks.setEditing(null);
    }, []);

    const openEditModal = useCallback((order, tab) => {
        callbacks.setEditing({ order, tab });
    }, []);

    const closeEditPickupModal = useCallback(() => {
        callbacks.setEditingPickup(null);
    }, []);

    const openEditPickupModal = useCallback((orders) => {
        callbacks.setEditingPickup({ orders });
    }, []);

    const deleteOrder = useCallback((order) => {
        callbacks.deleteOrder({
            variables: {
                order_id: order.order_id,
            },
        });
    }, []);

    const confirmOrders = useCallback(() => {
        const containsDuplicates = state.job.orders.some((order) => order.order_status === 'duplicate');
        const isMarketplace = state.job.orders.some((order) => !order.oms);

        if (!containsDuplicates && !isMarketplace) {
            callbacks.confirmOrders({
                variables: {
                    order_ids: state.job.orders.map((order) => order.order_id),
                },
            });
        }

        if (containsDuplicates && isMarketplace) {
            callbacks.setProgression((prev) => {
                return {
                    ...prev,
                    type:
                        payment_type === 'Invoice' || stripe_payment_method
                            ? PROGRESSION_TYPES.PO_CONFIRM_FINALIZE_MARKETPLACE_JOB
                            : PROGRESSION_TYPES.PO_CONFIRM_FINALIZE_MARKETPLACE_JOB_PAYMENT,
                };
            });
        } else if (containsDuplicates) {
            callbacks.setProgression((prev) => {
                return {
                    ...prev,
                    type: PROGRESSION_TYPES.PO_CONFIRM,
                };
            });
        } else if (isMarketplace) {
            callbacks.setProgression((prev) => {
                return {
                    ...prev,
                    type:
                        payment_type === 'Invoice' || stripe_payment_method
                            ? PROGRESSION_TYPES.FINALIZE_MARKETPLACE_JOB
                            : PROGRESSION_TYPES.FINALIZE_MARKETPLACE_JOB_PAYMENT,
                };
            });
        }
    }, [state.job]);

    const overrideDuplicates = useCallback(() => {
        callbacks.overrideDuplicates({
            variables: {
                order_ids: state.job.orders.map((order) => order.order_id),
            },
            onCompleted: () => {
                advance();
            },
        });
    }, [state, callbacks]);

    const clearDuplicates = useCallback(() => {
        callbacks.clearDuplicates({
            variables: {
                order_ids: state.job.orders.map((order) => order.order_id),
            },
            onCompleted: () => {
                advance();
            },
        });
    }, [state, callbacks]);

    const submitOrder = useCallback((order) => {
        submitOrders([order]);
    }, []);

    const submitOrders = useCallback((orders) => {
        callbacks.submitOrders({
            client_id: user_id,
            orders: orders.map((order) => {
                const { itemsByOrderId, ...remaining } = order;
                return {
                    ...omit(remaining, ORDER_READONLY_FIELDS),
                    itemsByOrderId: itemsByOrderId.map((item) =>
                        omit(
                            item,
                            ITEM_READONLY_FIELDS.filter((field) => field !== 'pallet')
                        )
                    ),
                };
            }),
            type: 'MANUAL',
        });
    }, []);

    return {
        revert,
        advance,
        openEditModal,
        closeEditModal,
        openEditPickupModal,
        closeEditPickupModal,
        deleteOrder,
        confirmOrders,
        submitOrder,
        submitOrders,
        overrideDuplicates,
        clearDuplicates,
    };
};
