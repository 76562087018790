import { STEPS, useCrossdockingSteps } from '@onward-delivery/core';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Card, Grid } from '@material-ui/core';
import { colors } from '@/styles';
import { Body1, Header2 } from '../OrderDetailsPage/Crossdocking/blocks';
import { startCase } from 'lodash';

const DriverSummaryCard = ({ order, events }) => {
    const steps = useCrossdockingSteps(events);

    const isAssigned = useMemo(() => {
        const idx = steps.findLastIndex(([step, meta]) =>
            [STEPS.PICKED_UP, STEPS.CROSS_DOCKED, STEPS.CROSS_DOCK_PICKUP].includes(step)
        );
        const [assignedStep, assignedMeta] = steps?.[idx - 1] || [];
        return [STEPS.ASSIGNED, STEPS.CROSS_DOCKED].includes(assignedStep) && !!assignedMeta?.after?.event_id;
    }, [steps]);

    const itemQty = useMemo(() => {
        return (order.itemsByOrderId || []).reduce((qty, item) => {
            return (item.quantity || 1) + qty;
        }, 0);
    }, [order]);

    return (
        <Card
            css={css`
                margin: 12px;
                padding: 12px 12px 24px 12px;
            `}
        >
            <Grid direction="column" container>
                {!isAssigned && (
                    <Grid
                        item
                        css={css`
                            font-size: 14px;
                            font-weight: 500;
                            color: ${colors.reds.primary};
                            margin-bottom: 12px;
                        `}
                    >
                        <span>
                            This order has not been assigned to a driver, therefore, cannot be started. Please reach out
                            to your admin to assign you the order.
                        </span>
                    </Grid>
                )}

                <Grid item>
                    <Header2>{order.order_number}</Header2>
                </Grid>
                <Grid item>
                    <Body1>Freight type: {startCase(order.freight_type)}</Body1>
                </Grid>
                <Grid item>
                    <Body1>{startCase(order.order_type)}</Body1>
                </Grid>
                <Grid item>
                    <Body1>{itemQty} Items</Body1>
                </Grid>
                <Grid item>
                    <Body1>Pickup: {order.pickup_date_formatted}</Body1>
                </Grid>
                <Grid item>
                    <Body1>Dropoff: {order.delivery_date_formatted}</Body1>
                </Grid>
            </Grid>
        </Card>
    );
};

export default DriverSummaryCard;
