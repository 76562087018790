import React, { useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getStopCompletionTimes } from './utilities';

import { Typography, Stepper, Step, StepLabel, Grid, StepContent } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { FiberManualRecord as CircleIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';

import { Context } from './context';
import { css } from '@emotion/react';
import { Body1, Body2, Subtitle1 } from './blocks';
import { PrimaryButton } from '@/styles/blocks';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDER_BY_ID } from '@/graphql/mutations/orders';
import * as Sentry from '@sentry/react';
import { useCrossdockingSteps, genAttributes } from '@onward-delivery/core';
import { colors } from '@/styles';

const QontoStepIconRoot = styled('div')(({ theme, owner_state }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(owner_state.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 16,
        height: 16,
        borderRadius: '50%',
        backgroundColor: '#555',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot owner_state={{ active }} className={className}>
            {completed ? (
                <CheckCircleIcon style={{ color: '#59B863' }} className="float-right" />
            ) : (
                <CircleIcon style={{ color: 'gray' }} />
            )}
        </QontoStepIconRoot>
    );
}

const getTime = (date) => {
    const str = new Date(date).toLocaleTimeString();
    return str.substring(0, 5) + str.substring(8);
};

const useSteps = (order, route, cdSteps, firstReview) => {
    const { setUpgradeDialog } = useContext(Context);
    const crossDockEvent = cdSteps.find((step) => step[0] === 'CROSS_DOCKED');
    const { location_type } = genAttributes(order);
    let stopPickupTime, stopDeliveryTime;
    if (route) {
        stopPickupTime = getStopCompletionTimes(order.order_id, route).stopPickupTime;
        stopDeliveryTime = getStopCompletionTimes(order.order_id, route).stopDeliveryTime;
    }
    return useMemo(() => {
        let progress = 1;

        const info = {
            date: '',
            time: '',
        };

        const crossDockedSteps = crossDockEvent
            ? {
                  'In-transit to Local Facility': { ...info },
                  'Arrived at Local Facility': { ...info },
              }
            : {};

        const timeline = {
            'Order Placed': { ...info },
            'Delivery Team Assigned': { ...info },
            Scheduled: { ...info },
            'Timeframe Confirmed': { ...info },
            ...crossDockedSteps,
            'Out for Delivery': { ...info },
            'Delivery Complete': { ...info },
            'Review Submitted': { ...info },
        };

        timeline['Order Placed'] = {
            date: new Date(order.created_at).toDateString(),
            time: getTime(order.created_at),
        };

        if (order.carrier_id || !!route) {
            timeline['Delivery Team Assigned'] = {
                date: new Date(route ? route.created_at : order.claimed_date).toDateString(),
                time: getTime(route ? route.created_at : order.claimed_date),
            };
            progress = 2;
        }

        if (order.delivery_date && order.del_window_start) {
            progress = 3;
        }

        if (order.delivery_time_confirmed) progress = 4;

        if (crossDockEvent) {
            if (crossDockEvent[1].before.ship_date) {
                progress = 5;
                timeline['In-transit to Local Facility'] = {
                    date: new Date(crossDockEvent[1].before.ship_date).toDateString(),
                    time: getTime(crossDockEvent[1].before.ship_date),
                };
            }
            if (crossDockEvent[1].after?.received_date) {
                progress = 6;
                timeline['Arrived at Local Facility'] = {
                    date: new Date(crossDockEvent[1].after.received_date).toDateString(),
                    time: getTime(crossDockEvent[1].after.received_date),
                };
            }
        }

        const pickupEvent = cdSteps?.find((step) => step[0] === 'CROSS_DOCK_PICKUP' || step[0] === 'PICKED_UP');
        if (route && stopPickupTime) {
            progress = crossDockEvent ? 7 : 5;
            timeline['Out for Delivery'] = {
                time: stopPickupTime.toLocaleTimeString(),
                date: stopPickupTime.toDateString(),
            };
        } else if (pickupEvent?.[1]?.after) {
            progress = crossDockEvent ? 7 : 5;
            timeline['Out for Delivery'] = {
                date: new Date(pickupEvent[1].after.pickup_date).toDateString(),
                time: getTime(pickupEvent[1].after.pickup_date),
            };
        }

        const hasBeenDelivered =
            order.order_status === 'complete' || order.order_status === 'routedComplete' || stopDeliveryTime;
        if (hasBeenDelivered) {
            progress = crossDockEvent ? 8 : 6;
            timeline['Out for Delivery'] = {
                date: stopDeliveryTime
                    ? stopDeliveryTime.toDateString()
                    : new Date(order.completion_time).toDateString(),
                time: stopDeliveryTime ? stopDeliveryTime.toLocaleTimeString() : getTime(order.completion_time),
            };
        }

        if (firstReview) progress = crossDockEvent ? 9 : 7;

        // Only allow upgrades if the customer has already paid for the base order, if it's a customer order, and if it hasn't been delivered yet.
        if (order?.customer_rate && order?.source_form === 'CUSTOMER' && !hasBeenDelivered) {
            timeline['Out for Delivery'].stepContent = (
                <>
                    <StepContent
                        TransitionProps={{ ...(progress <= Object.keys(timeline)?.length - 1 ? { in: true } : {}) }}
                    >
                        <Grid
                            container
                            direction="row"
                            css={css`
                                gap: 1rem;
                            `}
                        >
                            <Grid item>
                                <PrimaryButton
                                    onClick={() => {
                                        setUpgradeDialog({ type: 'haulaway_upgrade' });
                                    }}
                                >
                                    Add Haulaway
                                </PrimaryButton>
                            </Grid>
                            {order?.[location_type] !== 'whiteGlove' && (
                                <Grid item>
                                    <PrimaryButton
                                        onClick={() => {
                                            setUpgradeDialog({ type: 'service_upgrade' });
                                        }}
                                    >
                                        Upgrade Service Level
                                    </PrimaryButton>
                                </Grid>
                            )}
                        </Grid>
                    </StepContent>
                </>
            );
        }

        return { progress, timeline };
    }, [order, route, cdSteps, firstReview]);
};

const StepperH = ({ timeline, progress }) => {
    return (
        <Stepper activeStep={progress} alternativeLabel>
            {Object.keys(timeline).map((key, index) => (
                <Step key={index}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                        <div>
                            <Typography className="my-2 font-weight-normal" variant="body2">
                                <b>{key}</b>
                            </Typography>
                            <div>
                                <Typography className="my-2" variant="body2">
                                    <span>{timeline[key].date}</span>
                                </Typography>
                                <Typography className="my-2" variant="body2">
                                    <span>{timeline[key].time}</span>
                                </Typography>
                            </div>
                        </div>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

const StepperV = ({ timeline, progress, confirmTimeframe, order }) => {
    const pendingPieceCount = progress === 1 && order.source_form === 'CUSTOMER' && !order.piece_count_verified;

    return (
        <Stepper
            activeStep={progress}
            orientation="vertical"
            css={css`
                padding: 12px;
            `}
        >
            {Object.keys(timeline).map((key, index) => (
                <Step
                    key={index}
                    active={pendingPieceCount ? index === progress || index === progress - 1 : index === progress}
                >
                    <StepLabel StepIconComponent={QontoStepIcon}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Body2>
                                    <b>{key}</b>
                                </Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    text-align: right;
                                `}
                            >
                                <Body1>{timeline[key].date}</Body1>
                                <br />
                                <Body1>{timeline[key].time}</Body1>
                            </Grid>
                        </Grid>
                    </StepLabel>

                    {timeline[key]?.stepContent}

                    {pendingPieceCount && index === 0 && (
                        <StepContent>
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    font-weight: 500;
                                `}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        css={css`
                                            font-weight: 700;
                                        `}
                                    >
                                        Awaiting Shipper Approval
                                    </Typography>
                                </Grid>

                                <Grid>
                                    <Typography
                                        variant="body2"
                                        css={css`
                                            font-weight: 700;
                                            color: ${colors.greys.subtitle};
                                        `}
                                    >
                                        Reach out to shipper for updates.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </StepContent>
                    )}

                    {progress === 3 &&
                        index === 3 &&
                        order.del_window_start && ( //ftl check
                            <StepContent>
                                <Grid container direction="column">
                                    <Grid item lg={6}>
                                        <Subtitle1>Please confirm the above timeframe.</Subtitle1>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <PrimaryButton onClick={confirmTimeframe}>Confirm Timeframe</PrimaryButton>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        )}
                </Step>
            ))}
        </Stepper>
    );
};

export default function OrderStatus({ order, route, firstReview }) {
    const cdSteps = useCrossdockingSteps(order.wh_events);
    const { progress, timeline } = useSteps(order, route, cdSteps, firstReview);
    const [confirmTimeframe] = useMutation(UPDATE_ORDER_BY_ID, {
        variables: {
            order_id: order.order_id,
            update: {
                delivery_time_confirmed: 'confirmed',
            },
        },
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    return <StepperV progress={progress} timeline={timeline} confirmTimeframe={confirmTimeframe} order={order} />;
}
