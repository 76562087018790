import React, { useState, useEffect, useMemo, useRef } from 'react';
import GoogleMap, { DirectionsRenderer } from '@/components/GoogleMap';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { DropOff, PickUp } from '@/components/DispatchPlan/map/MapMarkers';
import { colors } from '@/styles';

const DEFAULT_CENTER = { lat: 39.74, lng: -104.99 };
const DEFAULT_ZOOM = 17;

const Map = ({ order, ...rest }) => {
    const mapRef = useRef();

    const { lat, long } = useSwappedAttributes(order);
    const { lat: pu_lat, long: pu_long } = useSwappedAttributes(order, true);

    const [directions, setDirections] = useState();
    const bounds = useMemo(() => {
        return [
            { lat: order[pu_lat], lng: order[pu_long] },
            { lat: order[lat], lng: order[long] },
        ];
    }, [lat, long, pu_lat, pu_long, order]);

    useEffect(() => {
        if (!directions) {
            FTLStopHelpers.getLoadDirections(order).then((result) => {
                console.debug(result);
                if (result.status === 'OK') setDirections(result);
            });
        }
    }, [order, directions]);

    return (
        <>
            <GoogleMap
                ref={mapRef}
                bounds={bounds}
                center={!bounds?.length ? DEFAULT_CENTER : undefined}
                zoom={!bounds?.length ? DEFAULT_ZOOM : undefined}
                mapTypeControl={false}
                fullscreenControl={false}
                zoomControl={false}
                streetViewControl={false}
                {...rest}
            >
                {!!order ? (
                    <>
                        <DropOff position={{ lat: order[lat], lng: order[long] }} color={colors.greens.primary} />
                        <PickUp position={{ lat: order[pu_lat], lng: order[pu_long] }} color={colors.greens.primary} />
                    </>
                ) : null}
                {directions && <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />}
            </GoogleMap>
        </>
    );
};

export default React.memo(Map);
