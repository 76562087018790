export const MARKER_CODES = {
    delivery_type: [
        {
            display: 'O',
            full: 'Onward Load Board',
            key: 'marketPlace',
        },
        {
            display: 'W',
            full: 'White Glove',
            key: 'whiteGlove',
        },
        {
            display: 'C',
            full: 'Curbside',
            key: 'curbside',
        },
        {
            display: 'F',
            full: 'First Dry Space',
            key: 'firstDrySpace',
        },
        {
            display: 'R',
            full: 'Room of Choice',
            key: 'roomOfChoice',
        },
        {
            display: 'D',
            full: 'Dock Delivery',
            key: 'dock',
        },
        {
            display: 'S',
            full: 'In Store',
            key: 'inStore',
        },
        {
            display: 'WH',
            full: 'Warehouse',
            key: 'rollUpDoor',
        },
    ],
    order_type: [
        {
            display: 'D',
            full: 'Delivery',
            key: 'delivery',
        },
        {
            display: 'R',
            full: 'Return',
            key: 'return',
        },
        {
            display: 'E',
            full: 'Exchange',
            key: 'exchange',
        },
    ],
};

export const deliveryTypeCode = (deliveryTypes = [], type) => {
    if (!deliveryTypes.length) {
        return ' ';
    }

    const first = Math.min.apply(
        null,
        deliveryTypes.map((code) => MARKER_CODES[type].findIndex(({ key }) => key === code))
    );

    return first >= 0 ? MARKER_CODES[type][first].display : ' ';
};
