import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { getWeek, setWeek, startOfWeek, endOfWeek } from 'date-fns';

import { useClientUser } from '@/hooks';
import { useLazyQuery, useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';

import { useInvoiceExport } from './hooks';
import { CARRIER_INVOICES, GET_PENDING_ORDERS, GET_PRICING_OVERRIDES } from './graphql';
import { COLUMNS } from './columns';

export const Context = createContext();

const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
const thisWeek = getWeek(TODAY, { weekStartsOn: 1 });
const lastWeek = setWeek(TODAY, thisWeek - 1, { weekStartsOn: 1 });
const sow = startOfWeek(lastWeek, { weekStartsOn: 1 });

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { user_id } = useClientUser();
    const [hasMore, setHasMore] = useState(false);
    const [selectedMap, setSelected] = useState({});
    const [filter, setFilter] = useState([]);

    const [getInvoices, { data, loading: initInflight, fetchMore }] = useLazyQuery(CARRIER_INVOICES, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [getPending, { data: pendingData, loading: pendingInflight }] = useLazyQuery(GET_PENDING_ORDERS, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const { data: overrides } = useQuery(GET_PRICING_OVERRIDES, {
        variables: { client_id: user_id },
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const invoices = useMemo(() => {
        return data?.results || [];
    }, [data]);

    const pending = useMemo(() => {
        return pendingData?.results || [];
    }, [pendingData]);

    const [{ selected }, { exportCsv }] = useInvoiceExport({
        invoices,
        selectedMap,
        overrides,
        breakdown: 'carrierBreakdown',
    });

    const pendingInvoices = useMemo(() => {
        return Object.values(
            pending.reduce((acc, order) => {
                const weekNum = getWeek(new Date(order.billing_completion_time), { weekStartsOn: 1 });
                const week = setWeek(TODAY, weekNum, { weekStartsOn: 1 });
                const sow = startOfWeek(week, { weekStartsOn: 1 });

                acc[week] = {
                    ...(acc[week] || {
                        carrier_invoice_id: `invoice-${week}`,
                        is_pending: true,
                        this_week: thisWeek === weekNum,
                        sow,
                        week_number: weekNum,
                        status: 'UNPAID',
                        type: 'PAYABLE',
                    }),
                    orders: [...(acc[week]?.orders || []), order],
                };

                return acc;
            }, {})
        ).sort((l, r) => r.sow.getTime() - l.sow.getTime());
    }, [pending]);

    const combined = useMemo(() => {
        return [...pendingInvoices, ...invoices];
    }, [pendingInvoices, invoices]);

    const where = useMemo(() => {
        let conditions = [{ client_id: { _eq: user_id } }, { type: { _eq: 'PAYABLE' } }];

        if (filter?.searchTerm?.length > 0) {
            const orConditions = [
                { orders: { order_number: { _ilike: `%${filter?.searchTerm}%` } } },
                { orders: { po_number: { _ilike: `%${filter?.searchTerm}%` } } },
            ];

            const parsedInt = parseInt(filter?.searchTerm, 10);
            if (!isNaN(parsedInt) && Number.isInteger(parsedInt)) {
                orConditions.push({
                    invoice_number: { _eq: parsedInt },
                });
            }

            conditions.push({
                _or: orConditions,
            });
        }

        return conditions;
    }, [user_id, filter]);

    const getInvoicesDebounced = useMemo(
        () =>
            debounce((payload) => {
                return getInvoices(payload);
            }, 500),
        []
    );

    useEffect(() => {
        getInvoices({
            variables: {
                where: {
                    _and: where,
                },
            },
        });

        getPending({
            variables: {
                start: sow,
                client_id: user_id,
            },
        });
    }, []);

    useEffect(() => {
        if (!initInflight) {
            getInvoicesDebounced({
                variables: {
                    where: {
                        _and: where,
                    },
                },
            });

            setHasMore(true);
        }
    }, [where, getInvoicesDebounced, initInflight]);

    const cursor = useMemo(() => {
        if (invoices.length === 0) {
            return null;
        }

        return invoices[invoices.length - 1].created_at;
    }, [invoices]);

    const loadMore = useCallback(() => {
        fetchMore({
            variables: {
                where: {
                    _and: [...where, { created_at: { _lte: cursor } }],
                },
            },
            updateQuery: (data, { fetchMoreResult }) => {
                const prev = Object.fromEntries(data.results.map((invoice) => [invoice.carrier_invoice_id, true]));
                const clone = [
                    ...data.results,
                    ...fetchMoreResult.results.filter((invoice) => !prev[invoice.carrier_invoice_id]),
                ];
                return {
                    results: clone,
                };
            },
        }).then((result) => {
            if (result.data.results.length < 100) {
                setHasMore(false);
            }
        });
    }, [where, cursor, fetchMore]);

    const callbacks = {
        exportCsv,
        setFilter,
        loadMore,
        onRowClick: (invoice) => {
            if (invoice.is_pending) {
                return navigate(`/carrier/accounting/marketplace/invoice/${invoice.this_week ? 'current' : 'pending'}`);
            }

            return navigate(`/carrier/accounting/marketplace/invoice/${invoice.carrier_invoice_id}`);
        },
        getRowId: (invoice) => {
            return invoice.carrier_invoice_id;
        },
        selectRows: setSelected,
        hasMore,
    };

    return (
        <Context.Provider
            value={{
                state: {
                    hasMore,
                    filter,
                    selected,
                    invoices: combined,
                    columns: COLUMNS,
                },
                loading: {
                    init: initInflight || pendingInflight,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
