import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTotalCubes } from '../MyOrders/hooks';
import { LocationOn, NotListedLocation, Warning as WarningIcon } from '@material-ui/icons';
import { parseDriverUsernames } from './utils';

import { BodyCellText } from '@/components/MyOrders/blocks';
import { useClientUser } from '@/hooks';

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const useColumns = ({ tabIndex }) => {
    const { user_id } = useClientUser();

    const getOwner = (route) => {
        return route.oms && route.shipper_id == user_id && (route.carrier_id === user_id || route.carrier_id === null)
            ? 'Internal'
            : (!route.oms && route.carrier_id === user_id) ||
              (route.shipper_id !== user_id && route.carrier_id === user_id)
            ? 'Claimed'
            : 'Onward';
    };

    return useMemo(() => {
        let hidePendingReturns = tabIndex !== 2;
        return [
            {
                id: 'route_name',
                Header: 'Route',
                accessor: (route) => route.route_alias || route.route_number || '-',
            },
            {
                id: 'pending_final_returns',
                Header: 'Pending Final Returns?',
                hidden: hidePendingReturns,
                accessor: (route) => route.status,
                Cell: ({ value: status }) => {
                    if (status === 'complete_final_return') {
                        return 'Yes';
                    }
                    return 'No';
                },
                width: 240,
                disableSortBy: true,
            },
            {
                id: 'num_stops',
                Header: 'Stops',
                accessor: (route) => route?.stopsByRouteId?.length || 0,
                width: 120,
                disableSortBy: true,
            },
            {
                id: 'cubic_feet',
                Header: 'Cu. Ft.',
                Cell: ({ row: route }) => {
                    let cubes = useTotalCubes(route?.original?.orders?.map((mapping) => mapping.order));
                    return integerFormatter.format(cubes);
                },
                disableSortBy: true,
                width: 120,
            },
            {
                id: 'price',
                Header: 'Price',
                accessor: (route) => {
                    const owner = getOwner(route);
                    if (owner === 'Claimed') {
                        return route?.total_carrier_rate || '-';
                    }
                    return '-';
                },
                disableSortBy: true,
                width: 120,
            },
            {
                Header: 'Scheduled Delivery',
                id: 'scheduled_delivery',
                width: 185,
                accessor: (route) => route.scheduled_delivery_formatted,
                Cell: ({ value: date }) => {
                    if (!date) {
                        return '--';
                    }
                    return `${date}`;
                },
            },
            {
                id: 'driver',
                Header: 'Driver',
                accessor: (route) => route.driver_name,
                Cell: ({ value: driver }) => {
                    if (driver) {
                        return parseDriverUsernames(driver);
                    }
                    return '-';
                },
                disableSortBy: true,
            },
            {
                id: 'truck',
                Header: 'Truck',
                accessor: (route) => route.truck_name || 'N/A',
                width: 135,
                disableSortBy: true,
            },
            {
                id: 'owner',
                Header: 'Owner',
                accessor: (route) => getOwner(route),
                disableSortBy: true,
            },
            {
                id: 'status',
                Header: 'Status',
                Cell: ({ row: route }) => {
                    let fragment;
                    let label;
                    let unknown = false;
                    switch (route.original.status) {
                        case 'pending':
                        case 'planning':
                        case 'active': {
                            label = 'Pre-transit';
                            fragment = css`
                                color: #ff903d;
                            `;
                            break;
                        }
                        case 'inProgress':
                        case 'complete_final_return': {
                            label = 'In-transit';
                            fragment = css`
                                color: #197ef3;
                            `;
                            break;
                        }
                        case 'complete': {
                            label = 'Complete';
                            fragment = css`
                                color: #59b863;
                            `;
                            break;
                        }
                        case 'cancelled': {
                            label = 'Cancelled';
                            fragment = css`
                                color: #d23e3e;
                            `;
                            break;
                        }
                        default:
                            unknown = true;
                    }

                    return (
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                white-space: nowrap;
                            `}
                        >
                            {unknown ? (
                                <NotListedLocation
                                    css={css`
                                        color: #000;
                                    `}
                                />
                            ) : (
                                <LocationOn
                                    css={css`
                                        ${fragment}
                                    `}
                                />
                            )}
                            <BodyCellText>{unknown ? 'Unknown' : label}</BodyCellText>
                        </div>
                    );
                },
                disableSortBy: true,
            },
        ];
    }, [tabIndex]);
};
