import React, { useMemo, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
} from '@material-ui/core';
import { css } from '@emotion/react';
import { sansSerif } from '../../styles/typography';
import { CANCEL_ORDERS } from './graphql';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { captureException } from '@sentry/react';

const Text = (props) => (
    <Typography
        css={css`
            ${sansSerif}
            color: #4C4C4C;
            font-weight: 600;
        `}
    >
        {props.children}
    </Typography>
);

const CancellationModal = ({ open, onClose, orders = [] }) => {
    const [selectedOrders, setSelectedOrders] = useState({});

    const isValid = useMemo(() => {
        return Object.entries(selectedOrders).some(([_, selected]) => !!selected);
    }, [selectedOrders]);

    const [cancelOrders, { loading }] = useMutation(CANCEL_ORDERS, {
        update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
        },
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
        onCompleted: () => {
            setSelectedOrders({});
            onClose();
        },
    });

    const selectOrder = (order_id) => {
        setSelectedOrders((prev) => ({
            ...prev,
            [order_id]: !prev[order_id],
        }));
    };

    const onConfirm = () => {
        cancelOrders({
            variables: {
                order_ids: Object.entries(selectedOrders)
                    .filter(([_, selected]) => !!selected)
                    .map(([order_id, _]) => order_id),
            },
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent
                css={css`
                    max-width: 500px;
                `}
            >
                <Text>Are you sure you want to cancel this order(s)?</Text>
                <br />{' '}
                <Text>
                    The order(s) will be permanently archived and only viewable on the “Cancelled” tab of your orders
                    page. You will not be able to reuse the order.
                </Text>
                <Grid className="mt-4" container>
                    {orders.map((order) => (
                        <Grid item xs={12} key={order.order_id}>
                            <FormControlLabel
                                css={css`
                                    & span {
                                        padding: 0 0.2rem;
                                    }
                                `}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={!!selectedOrders[order.order_id]}
                                        onChange={() => selectOrder(order.order_id)}
                                    />
                                }
                                label={
                                    <Typography
                                        css={css`
                                            font-weight: 500;
                                        `}
                                    >
                                        {order.order_number}
                                    </Typography>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>

            <DialogActions
                css={css`
                    dislay: flex;
                    justify-content: space-between;
                    margin-bottom: 0.5rem;
                `}
            >
                <Button
                    variant="outlined"
                    css={css`
                        margin-left: 1rem;
                    `}
                    onClick={onClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    css={css`
                        color: #fff;
                        margin-right: 1rem;
                        background: #d23e3e;
                    `}
                    onClick={onConfirm}
                    disabled={loading || !isValid}
                >
                    Cancel Orders
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CancellationModal;
