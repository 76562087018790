import React, { useState, useMemo } from 'react';
import { Typography, Grid, Card, CardContent, Popper, Button } from '@material-ui/core';
import { css } from '@emotion/react';
import { UserContext } from '../App';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { sansSerif } from '../../styles/typography';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDERS } from '../DispatchPlan/graphql/mutations';
import _ from 'lodash';
import { PrimaryButton } from '@/styles/blocks';
import {
    useClientUser,
    useTotalCubesSubscription,
    useTotalWeightSubscription,
    useOrderShipperPricing,
    useOrderCarrierPricing,
} from '@/hooks';
import { hourMinuteFormatter, hourString, minuteString, minuteStringLong } from '@/constants/formats';

const Text = (props) => (
    <Typography
        css={css`
            ${sansSerif}
            color: #4C4C4C;
            font-weight: 600;
            font-size: 14px;
        `}
    >
        {props.children}
    </Typography>
);
const TextBold = (props) => (
    <Typography
        css={css`
            ${sansSerif}
            color: #4C4C4C;
            font-weight: 800;
            font-size: 14px;
        `}
    >
        {props.children}
    </Typography>
);
const TextGray = (props) => (
    <Typography
        css={css`
            ${sansSerif}
            color: gray;
            font-weight: 600;
            font-size: 14px;
        `}
    >
        {props.children}
    </Typography>
);

const OnwardPricePopper = ({ order, callbacks = {} }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { user_id, accountType, circles, payment_type } = useClientUser();

    const handleClick = (event) => {
        if (!order.shipper_rate) return;
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const isClaimed = useMemo(() => {
        return (
            accountType === 'carrier' &&
            order &&
            order?.shipper_id !== order?.carrier_id &&
            order?.carrier_id === user_id
        );
    }, []);
    const sentOrPaid = useMemo(() => {
        const isInternal =
            order?.carrier_id === user_id ||
            (order?.shipper_id === user_id &&
                order.oms &&
                (order?.carrier_id === user_id || order?.carrier_id === null));

        if (!isInternal) {
            return '(Sent)';
        } else if (accountType === 'carrier' && order.payment_status === 'PAID') {
            return '(Paid)';
        }
        return '';
    }, [order]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const carrierRate = useOrderCarrierPricing(order);
    const shipperRate = useOrderShipperPricing(order, circles, payment_type);
    const internalRate = `$${(order.order_revenue || 0).toFixed(2)}`;
    const rate = order.oms ? internalRate : order.shipper_id === user_id ? shipperRate : carrierRate;
    const weight = useTotalWeightSubscription([order]);
    const cubes = useTotalCubesSubscription([order]);
    const duration = hourMinuteFormatter.format(order?.duration_seconds / 60);

    return (
        <>
            <Card onClick={handleClick} style={{ width: 375 }}>
                <CardContent>
                    <Grid
                        container
                        justifyContent="space-between"
                        direction="row"
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            container
                            direction="row"
                            item
                            css={css`
                                width: auto;
                                align-items: center;
                            `}
                        >
                            <Typography
                                css={css`
                                    ${sansSerif}
                                    font-size: 14px;
                                    color: #59b863;
                                    font-weight: 600;
                                    font-family: Montserrat;
                                    margin-right: 7px;
                                `}
                            >
                                {isClaimed ? `Onward Income:` : `Onward Shipping Price:`}
                            </Typography>

                            <Typography
                                css={css`
                                    ${sansSerif}
                                    color: #4C4C4C;
                                    font-weight: 700;
                                    font-size: 18px;
                                `}
                            >
                                {rate}
                            </Typography>
                        </Grid>
                        {sentOrPaid && (
                            <Grid
                                item
                                css={css`
                                    margin-left: 5px;
                                    margin-right: auto;
                                    font-style: italic;
                                `}
                            >
                                <TextGray>{sentOrPaid}</TextGray>
                            </Grid>
                        )}
                        <Grid item>
                            {rate !== 'We will contact you' && !open ? (
                                <KeyboardArrowDownIcon />
                            ) : rate !== 'We will contact you' && open ? (
                                <KeyboardArrowUpIcon />
                            ) : null}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Popper
                placement="bottom"
                id={id}
                open={open}
                anchorEl={anchorEl}
                css={css`
                    left: 1.5px !important;
                `}
            >
                <Card style={{ width: 375, marginTop: -5, boxShadow: '0px 6px 5px lightgray' }}>
                    <CardContent>
                        <Grid container justifyContent="center">
                            {/* mileage and other future pricing variables */}
                            <Grid item container justifyContent="space-between">
                                <Grid item xs={6}>
                                    <Text>Mileage</Text>
                                </Grid>
                                <Grid item style={{ textAlign: 'end' }} xs={6}>
                                    <TextBold>{order.distance || 'N/A'}</TextBold>
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="space-between">
                                <Grid item xs={6}>
                                    <Text>Weight</Text>
                                </Grid>
                                <Grid item style={{ textAlign: 'end' }} xs={6}>
                                    <TextBold>{weight ? weight.toFixed(2) : 'N/A'}</TextBold>
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="space-between">
                                <Grid item xs={6}>
                                    <Text>Cu Ft</Text>
                                </Grid>
                                <Grid item style={{ textAlign: 'end' }} xs={6}>
                                    <TextBold>{cubes ? cubes.toFixed(2) : 'N/A'}</TextBold>
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="space-between">
                                <Grid item xs={8}>
                                    <Text>Estimated Trip Time</Text>
                                </Grid>
                                <Grid item style={{ textAlign: 'end' }} xs={4}>
                                    <TextBold>{duration}</TextBold>
                                </Grid>
                            </Grid>
                            {!isClaimed && (
                                <>
                                    <Grid style={{ marginTop: 20 }}>
                                        <TextGray>Price only applies if sent to Onward Marketplace</TextGray>
                                    </Grid>
                                    <PrimaryButton
                                        style={{ width: '100%', marginTop: 20, fontSize: 14, height: 36 }}
                                        disabled={!order.oms || !order.dropoff_phone}
                                        onClick={callbacks.onSend}
                                    >
                                        {order.oms ? 'Send to Onward' : 'Sent to Onward'}
                                    </PrimaryButton>
                                </>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Popper>
        </>
    );
};

export default OnwardPricePopper;
