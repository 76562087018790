import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { colors } from '@/styles';
import { ITEM_TYPES } from '@/components/ShipmentForm/constants/freightTypes';

import Footer from './Footer';
import ProgressBar from './ProgressBar';
import { ITEM_TYPE_STAGE_MAPPING } from '../constants';
import { local, ItemSwitch, Header1, SubHeader1, Header2 } from '../blocks';

function ItemTypes({ isDesktop, callbacks, state }) {
    const [selectedTypes, setItemTypes] = useState(state.sections[state.progression.current]?._item_types || {});
    const hasError = useMemo(() => {
        return Object.values(selectedTypes).every((isSelected) => !isSelected);
    }, [selectedTypes]);

    return (
        <Grid
            container
            direction="column"
            css={css`
                color: ${local.greys[3]};
            `}
        >
            <ProgressBar step={state.progression.current + 1} maxSteps={7} />
            <Grid
                container
                css={css`
                    margin-bottom: 28px;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid item direction="column">
                    <Header2
                        css={css`
                            color: ${local.greys[4]};
                        `}
                    >
                        What do you need delivered?
                    </Header2>
                </Grid>
                <Grid item direction="column">
                    <IconButton
                        css={css`
                            padding: 4px;
                        `}
                        onClick={callbacks.showInfo}
                    >
                        <HelpIcon
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        />
                    </IconButton>
                </Grid>
                <Grid item direction="row">
                    <SubHeader1
                        css={css`
                            color: ${local.greys[2]};
                        `}
                    >
                        <i>(Select all that apply)</i>
                    </SubHeader1>
                </Grid>
            </Grid>

            {ITEM_TYPES[state.order.freight_type]
                .filter(({ value }) => !!ITEM_TYPE_STAGE_MAPPING[value])
                .map(({ value, label }) => {
                    return (
                        <Grid
                            key={value}
                            container
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            <ItemSwitch
                                label={label}
                                selected={selectedTypes[value]}
                                onClick={() => {
                                    setItemTypes((prev) => ({ ...prev, [value]: !prev[value] }));
                                }}
                            />
                        </Grid>
                    );
                })}
            {selectedTypes?.appliance && (
                <Grid item style={{ padding: '10px', backgroundColor: '#f8d7da', borderRadius: '5px', margin: '10px 0' }}>
                    <Typography variant="body1" style={{ color: '#721c24', fontWeight: 'bold' }}>
                        Alert: Onward can't handle water/gaslines or add new plugs/parts to appliances at this time. If your appliance is electric with an attached chord, we can set it up.
                    </Typography>
                </Grid>
            )}
            <Footer
                state={state}
                isFirst={state.isFirstStage}
                isLast={state.isLastStage}
                hasErrors={hasError}
                callbacks={{
                    advance: () => {
                        const types = Object.entries(selectedTypes)
                            .filter(([, value]) => value)
                            .map(([key]) => key);
                        callbacks.configureItemTypes(types);
                        callbacks.advance({ _item_types: selectedTypes });
                    },
                    revert: callbacks.revert,
                }}
            />
        </Grid>
    );
}

export default ItemTypes;
