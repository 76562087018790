import { differenceInCalendarISOWeeks, isEqual, isAfter, isBefore, addDays } from 'date-fns';
import { genAttributes } from '@onward-delivery/core';

const overlaps = (range, ref) => {
    return (
        (isBefore(range[0], ref[0]) || isEqual(range[0], ref[0])) &&
        (range[1] === null || isEqual(range[1], ref[1]) || isAfter(range[1], ref[0]))
    );
};

export const genResources = ({ client, order }) => {
    const { zip } = genAttributes(order);

    return (client?.available_resources || []).reduce(
        ({ default: defaults, override }, resource) => {
            if (!resource.zips.includes(order[zip])) {
                return { default: defaults, override };
            }

            if (
                resource.shipping_partners.length === 0 ||
                resource.shipping_partners.every((shipping_partner) => !shipping_partner.partner_client_id)
            ) {
                defaults.push(resource);
            }

            if (
                resource.shipping_partners.some(
                    (shipping_partner) => shipping_partner.partner_client_id === order.shipper_id
                )
            ) {
                override.push(resource);
            }

            return { default: defaults, override };
        },
        { default: [], override: [] }
    );
};

export const genDay = (ts) => {
    const day = new Date(ts);
    const next = addDays(day, 1);

    return {
        date: day.getDate(),
        start: day.toISOString(),
        end: next.toISOString(),
        dow: day.getUTCDay(),
    };
};

export const scheduleResource = ({ resource, day }) => {
    const d = [new Date(day.start), new Date(day.end)];
    for (let i = 0; i < resource.overrides.length; i++) {
        const override = resource.overrides[i];
        const o = [new Date(override.override_start), override.override_end ? new Date(override.override_end) : null];

        if (overlaps(o, d)) {
            return [!override.omit, { type: 'overrides', id: override.mapping_id }];
        }
    }

    for (let j = 0; j < resource.rules.length; j++) {
        const rule = resource.rules[j];
        const r = [new Date(rule.rule_start), rule.rule_end ? new Date(rule.rule_end) : null];

        if (overlaps(r, d)) {
            switch (rule.rule_type) {
                case 'DAILY':
                    return [true, { type: 'rules', id: rule.mapping_id }];
                case 'WEEKDAYS':
                    if (day.dow > 0 && day.dow < 6) return [true, { type: 'rules', id: rule.mapping_id }];
                    break;
                case 'WEEKLY':
                    if (day.dow === r[0].getUTCDay()) return [true, { type: 'rules', id: rule.mapping_id }];
                    break;
                case 'BIWEEKLY':
                    if (day.dow === r[0].getUTCDay() && differenceInCalendarISOWeeks(r[0], d[0]) % 2 === 0)
                        return [true, { type: 'rules', id: rule.mapping_id }];
                    break;
                case 'MONTHLY':
                    if (r[0].getDate() === day.date) return [true, { type: 'rules', id: rule.mapping_id }];
                    break;
            }
        }
    }

    return [false, null];
};

export const genRecommendations = ({ resources, range }) => {
    return range.filter((day) => {
        return resources.some((resource) => {
            const [toSchedule] = scheduleResource({ resource, day });

            return toSchedule;
        });
    });
};
