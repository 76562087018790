import React, { useEffect, useState, useMemo } from 'react';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Tooltip } from '@material-ui/core';
import { createRoot } from 'react-dom/client';

import { LassoOverlay } from './LassoOverlay';

const controlContainer = document.createElement('div');
const id = 'lasso-control-container';
controlContainer.setAttribute('id', id);
const containerRoot = createRoot(controlContainer);
const LOCATIONS = [
    window.google.maps.ControlPosition.RIGHT_TOP,
    window.google.maps.ControlPosition.RIGHT_BOTTOM,
    window.google.maps.ControlPosition.LEFT_BOTTOM,
    window.google.maps.ControlPosition.TOP_LEFT,
    window.google.maps.ControlPosition.BOTTOM_LEFT,
];

const LassoControlContent = ({ isEnabled, callbacks }) => {
    return (
        <Tooltip title="Lasso Tool" placement="top">
            <div
                css={css`
                    margin: 10px;
                    width: 40px;
                    height: 40px;
                    background-color: ${isEnabled ? '#FAFAFA' : colors.greens.primary};
                    color: ${isEnabled ? colors.greens.primary : colors.white};
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                `}
                onClick={callbacks.toggleLasso}
            >
                <FormatShapesIcon />
            </div>
        </Tooltip>
    );
};

export const LassoControl = ({ mapRef, isEnabled, callbacks }) => {
    const overlay = useMemo(() => {
        return new LassoOverlay({ onSelect: callbacks.onSelect });
    }, [callbacks.onSelect]);

    useEffect(() => {
        if (mapRef?.current) {
            const controls = mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP];

            controls.push(controlContainer);

            return () => {
                const idx = controls.indexOf((el) => el.id === id);
                if (idx >= 0) {
                    controls.removeAt(idx);
                }
            };
        }
    }, [mapRef?.current]);

    useEffect(() => {
        if (mapRef?.current) {
            mapRef.current.setOptions({
                disableDefaultUI: isEnabled,
            });

            if (isEnabled) {
                overlay.setMap(mapRef.current);
            } else {
                overlay.setMap(null);
            }

            containerRoot.render(<LassoControlContent isEnabled={isEnabled} callbacks={callbacks} />);
        }

        return () => {
            overlay.setMap(null);
        };
    }, [isEnabled, mapRef?.current, overlay]);

    return null;
};
