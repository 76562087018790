import React, { useContext } from 'react';
import { compose } from 'recompose';
import { css } from '@emotion/react';

import { Grid, Snackbar } from '@material-ui/core';
import { PrimaryButton } from '@/styles/blocks';
import { WarehousingContext, withWarehousingContext } from './context';

import { H2, GrowingGrid } from '../DispatchPlan/blocks';
import { Alert } from '@material-ui/lab';

import WarehouseList from './WarehousesList';
import WarehousingButtons from './WarehousingButtons';
import WarehouseViewer from './WarehouseViewer';

function WarehouseSetup() {
    const { handlePrintLabels, notification, setNotification, loadingSaving } = useContext(WarehousingContext);

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            css={css`
                background-color: white;
                padding: 0;
                height: 100%;
                flex-wrap: nowrap;
                align-items: flex-start;
            `}
        >
            {/* Header */}
            <Grid
                item
                css={css`
                    width: 100%;
                `}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    css={css`
                        padding: 14px 14px 14px 45px;
                        background: white;
                        border: 1px solid #e2e2e2;
                    `}
                >
                    <Grid item>
                        <Grid container direction="row" alignItems="center">
                            <H2
                                css={css`
                                    margin: 0;
                                `}
                            >
                                Warehousing
                            </H2>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item container direction="row" alignItems="center">
                            <Grid item>
                                <PrimaryButton
                                    disabled={loadingSaving}
                                    onClick={handlePrintLabels}
                                >
                                    Print Labels
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <GrowingGrid
                css={css`
                    width: 100%;
                    display: flex;
                `}
            >
                {/* Locations */}
                <Grid
                    item
                    xs={3}
                    css={css`
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        border: 1px solid #e2e2e2;
                    `}
                >
                    <WarehouseList />
                </Grid>

                {/* Buttons and such */}
                <Grid
                    item
                    xs={2}
                    css={css`
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        border: 1px solid #e2e2e2;
                    `}
                >
                    <WarehousingButtons />
                </Grid>

                <Grid
                    item
                    xs={7}
                    css={css`
                        height: 100%;
                        background-color: #e2e2e2;
                        position: relative;
                    `}
                >
                    <WarehouseViewer />
                    
                </Grid>
            </GrowingGrid>

            {/* Notification */}
            <Snackbar
                open={!!notification?.message}
                onClose={() => setNotification(null)}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                autoHideDuration={10000}
            >
                <Alert
                    onClose={() => setNotification(null)}
                    severity={notification?.severity}
                    elevation={6}
                    variant="filled"
                >
                    {notification?.message}
                    {notification?.clickable && notification.clickable}
                </Alert>
            </Snackbar>
        </Grid>
    );
}

export default compose(withWarehousingContext)(WarehouseSetup);
