import React, { useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';
import Autocomplete from 'react-autocomplete';
import { XCircleFill } from 'react-bootstrap-icons';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';

import './style.css';
import { QUERY_BY_BUSINESS_NAME } from './queries';
import { UPDATE_CLIENT_BLACKLIST } from './mutations';

const BlockedTargetChip = (props) => {
    return (
        <div className="chip d-flex">
            {props.target.business}
            <XCircleFill className="ms-1 chip-close-icon" onClick={() => props.handleRemove(props.target.key)} />
        </div>
    );
};

export default function Blacklist({ blacklistType, currentUser, clientBlacklist }) {
    const [entry, setEntry] = useState('');
    const [autocompleteList, setAutocompleteList] = useState([]);
    const [blacklist, setBlacklist] = useState(clientBlacklist);
    const [showAutocomplete, setShowAutocomplete] = useState(false);

    useEffect(() => {
        setBlacklist(clientBlacklist);
    }, [clientBlacklist]);

    const [searchBusinesses] = useLazyQuery(QUERY_BY_BUSINESS_NAME, {
        onCompleted: (data) => {
            let list = data.clients.map((client, i) => {
                return {
                    key: client.client_id,
                    business: client?.business_name,
                };
            });
            setAutocompleteList(list);
            setShowAutocomplete(true);
        },
        onError: (error) => console.log(error),
    });

    const [updateBlacklist] = useMutation(UPDATE_CLIENT_BLACKLIST);

    const handleInput = (value) => {
        setEntry(value);
        if (value.length < 2) {
            setAutocompleteList([]);
            setShowAutocomplete(false);
            return;
        }

        searchBusinesses({
            variables: {
                client_type_wanted: blacklistType === 'shipper' ? 'CARRIER' : 'SHIPPER',
                business_name: `%${value}%`,
            },
        });
    };

    const handleSelect = (value) => {
        const target = autocompleteList.find((e) => e.key === value);
        if (!blacklist) {
            updateBlacklist({
                variables: {
                    client_id: currentUser,
                    blacklisted_clients: [target],
                },
            });
        } else if (blacklist === null || !blacklist.find((e) => e.key === target.key)) {
            updateBlacklist({
                variables: {
                    client_id: currentUser,
                    blacklisted_clients: [...blacklist, target],
                },
            });
        }

        setEntry('');
        setShowAutocomplete(false);
    };

    const handleRemove = (key) => {
        const filtered = blacklist.filter((e) => e.key !== key);
        updateBlacklist({
            variables: {
                client_id: currentUser,
                blacklisted_clients: filtered,
            },
        });
    };

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <Autocomplete
                    open={showAutocomplete}
                    items={autocompleteList}
                    value={entry}
                    renderItem={(item, isHighlighted) => (
                        <div
                            className="autocomplete-item"
                            style={{
                                background: isHighlighted ? 'grey' : '',
                                borderRadius: '4px',
                                padding: '1px 6px',
                            }}
                        >
                            {item.business}
                        </div>
                    )}
                    getItemValue={(item) => item.key}
                    onChange={(e) => handleInput(e.target.value)}
                    onSelect={handleSelect}
                    menuStyle={{
                        position: 'absolute',
                        left: '0',
                        top: '30px',
                        zIndex: 999,
                        background: 'white',
                        maxHeight: '150px',
                        overflowY: 'scroll',
                        border: '1px solid black',
                        borderRadius: '5px',
                        padding: '4px',
                        marginBottom: '15px',
                    }}
                    inputProps={{
                        placeholder: 'Search for a company',
                        onBlur: () => setShowAutocomplete(false),
                        onFocus: () => handleInput(entry),
                    }}
                />
            </div>

            <div
                id="chips-display"
                className={`d-flex ${!blacklist || blacklist.length === 0 ? 'justify-content-center' : ''}`}
            >
                {blacklist &&
                    blacklist.map((target, i) => (
                        <BlockedTargetChip
                            blacklistType={blacklistType}
                            key={i}
                            handleRemove={handleRemove}
                            target={target}
                        />
                    ))}

                {(!blacklist || blacklist.length === 0) && <p>Start typing to find a company to block</p>}
            </div>
        </div>
    );
}
