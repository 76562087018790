import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import PlacesAutocomplete from 'react-places-autocomplete';

import { TextField } from '@material-ui/core';

const MenuContainer = styled.div`
    display: ${(props) => (props.active ? 'block' : 'none')};
    position: absolute;
    z-index: 999;
    border: solid 1px rgb(180, 180, 180);
    border-radius: 5px;
    box-shadow: 1px 1px 7px 3px rgba(137, 137, 137, 0.23);
    background-color: white;

    ${(props) => {
        switch (props.location) {
            case 'top':
                return css`
                    bottom: calc(100%);
                `;
            case 'bottom':
            default:
                return css`
                    top: calc(100%);
                `;
        }
    }}
`;

const MenuItem = styled.div`
    background: ${(props) => (props.active ? '#fafafa' : '#ffffff')};
    border-radius: 5px;
    padding: 4px 8px;
    cursor: pointer;
`;

function AddressAutocomplete(props) {
    const {
        value,
        onInput,
        onSelect,
        label,
        showLabel,
        disabled,
        onBlur,
        onFocus,
        style,
        variant,
        location = 'bottom',
        ...otherProps
    } = props;
    const searchOptions = {
        componentRestrictions: { country: 'US' },
    };
    return (
        <PlacesAutocomplete value={value} onChange={onInput} onSelect={onSelect} searchOptions={searchOptions}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                    <div
                        css={css`
                            width: 100%;
                            position: relative;
                        `}
                    >
                        <TextField
                            variant={variant || 'outlined'}
                            size="small"
                            value={value}
                            onChange={onInput}
                            label={showLabel ? label || 'Search for an address' : null}
                            placeholder="Search for an address"
                            fullWidth
                            {...getInputProps({})}
                            disabled={disabled}
                            InputProps={{
                                onBlur,
                                onFocus,
                                style,
                                placeholder: 'Search for an address',
                                autoComplete: 'new-password',
                            }}
                            {...otherProps}
                        />

                        {props.open && (
                            <MenuContainer active={suggestions.length > 0} location={location}>
                                {loading && <div>Loading...</div>}

                                {suggestions.map((suggestion, idx) => {
                                    return (
                                        <MenuItem
                                            active={suggestion.active}
                                            {...getSuggestionItemProps(suggestion)}
                                            key={idx}
                                        >
                                            <span>{suggestion.description}</span>
                                        </MenuItem>
                                    );
                                })}
                            </MenuContainer>
                        )}
                    </div>
                );
            }}
        </PlacesAutocomplete>
    );
}

AddressAutocomplete.propTypes = {
    value: PropTypes.any.isRequired,
    onInput: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    label: PropTypes.any,
};

export default AddressAutocomplete;
