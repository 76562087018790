import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { SecondaryButton } from '@/styles/blocks';
import { colors } from '@/styles';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { Grid } from '@material-ui/core';
import { ResponsiveSidebarDialog, StickyModalActions, ModalContent, PrimaryButton } from '@/styles/blocks';

import { ItemCheckbox, Body1, Header1, DateTimePicker } from '../Crossdocking/blocks';
import { genAttributes } from '@onward-delivery/core';
import zipcode_to_timezone from 'zipcode-to-timezone';

const ChecklistModal = ({ event, open, order, content, items = [], timestamps = [], callbacks }) => {
    const [override, setSelected] = useState({});
    const [updates, setUpdates] = useState({});

    const selected = useMemo(() => {
        return {
            ...Object.fromEntries(items.map((item) => [item.item_id, !!event])),
            ...override,
        };
    }, [override, items, event]);

    const updated = useMemo(() => {
        return {
            ...Object.fromEntries(timestamps.map(({ field }) => [field, order?.[field]])),
            ...updates,
        };
    }, [order, timestamps, updates]);

    const isValid = useMemo(() => {
        return (
            items.map((item) => item.item_id).every((id) => selected[id]) &&
            timestamps.every(({ field, required }) => !required || !!updated[field])
        );
    }, [selected, items, timestamps, updated]);

    const tz = useMemo(() => {
        const { zip } = genAttributes(order);
        return zipcode_to_timezone.lookup(order[zip]) || 'America/New_York';
    }, [order]);

    return (
        <ResponsiveSidebarDialog
            open={open}
            onClose={() => {
                setSelected({});
                callbacks.onClose();
            }}
        >
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                {content}
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-top: 30px;
                        margin-bottom: 4px;
                    `}
                >
                    <Header1>Items</Header1>
                </Grid>
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 8px;
                        color: ${colors.greys[0]};
                    `}
                >
                    <Body1>Checkmark each item after loading.</Body1>
                </Grid>
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 30px;
                    `}
                >
                    <Grid direction="column" container>
                        <Grid
                            direction="row"
                            container
                            item
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                            >
                                <OnwardCheckbox
                                    checked={items.map((item) => item.item_id).every((id) => selected[id])}
                                    onChange={() => {
                                        setSelected((prev) => {
                                            const ids = items.map((item) => item.item_id);
                                            const checked = !ids.every((id) => selected[id]);

                                            return {
                                                ...prev,
                                                ...Object.fromEntries(ids.map((id) => [id, checked ? true : false])),
                                            };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                            >
                                <Header1>Select All</Header1>
                            </Grid>
                        </Grid>

                        {items.map((item) => {
                            return (
                                <ItemCheckbox
                                    key={item.item_id}
                                    item={item}
                                    checked={selected[item.item_id]}
                                    callbacks={{
                                        onCheck: (id) =>
                                            setSelected((prev) => {
                                                return {
                                                    ...prev,
                                                    [id]: !prev[id],
                                                };
                                            }),
                                    }}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid direction="row" container>
                    <Grid direction="column" container>
                        {timestamps.map((timestamp) => (
                            <Grid
                                key={timestamp.field}
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 20px;
                                `}
                            >
                                <DateTimePicker
                                    dateLabel={`${timestamp.name} Date`}
                                    tz={tz}
                                    timestamp={updated[timestamp.field]}
                                    onChange={(date) =>
                                        setUpdates((prev) => ({
                                            ...prev,
                                            [timestamp.field]: date ? date.toISOString() : null,
                                        }))
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton
                        onClick={() => {
                            setSelected({});
                            callbacks.onClose();
                        }}
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton
                        disabled={!isValid}
                        onClick={() => {
                            setSelected({});
                            callbacks.onConfirm(updated);
                        }}
                    >
                        Yes
                    </PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default ChecklistModal;
