import { Marker } from '@/components/GoogleMap';
import { colors } from '@/styles';
import React, { useRef } from 'react';

const circlePath = (cx, cy, r) => {
    return `M ${cx} ${cy} m -${r}, 0 a ${r},${r} 0 1,1 ${r * 2},0 a ${r},${r} 0 1,1 -${r * 2},0`;
};

const OrderMarker = ({ callbacks = {}, ...props }) => {
    const marker = useRef();

    return (
        <Marker
            {...props}
            ref={marker}
            icon={{
                path: circlePath(0, 0, 3),
                fillColor: props.color || colors.greys.secondary,
                fillOpacity: 1,
                strokeColor: 'white',
                strokeWeight: 2,
                rotation: 0,
                scale: 2,
                anchor: new window.google.maps.Point(0, 0),
            }}
            callbacks={{
                ...(callbacks.onContextMenu
                    ? {
                          onContextMenu: () => {
                              callbacks.onContextMenu(marker);
                          },
                      }
                    : {}),
                ...(callbacks.onClick
                    ? {
                          onClick: () => {
                              callbacks.onClick(marker);
                          },
                      }
                    : {}),
                ...(callbacks.onMouseOver
                    ? {
                          onMouseOver: () => {
                              callbacks.onMouseOver(marker);
                          },
                      }
                    : {}),
                ...(callbacks.onMouseOut
                    ? {
                          onMouseOut: () => {
                              callbacks.onMouseOut(marker);
                          },
                      }
                    : {}),
            }}
        />
    );
};

export default OrderMarker;
