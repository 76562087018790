import React, { useMemo } from 'react';
import { isToday, isTomorrow } from 'date-fns';
import { useClientUser } from '@/hooks';

const DAY = 1000 * 60 * 60 * 24;

export const useMenuOptions = (route, user, callbacks) => {
    const { user_id } = useClientUser();

    return useMemo(() => {
        const isRouteCreator = route.shipper_id === user_id;

        return [
            ...(callbacks.generateRoutePDF
                ? [
                      {
                          display: 'Download PDF',
                          callback: callbacks.generateRoutePDF,
                      },
                  ]
                : []),
            ...(callbacks.generateRouteCSV
                ? [
                      {
                          display: 'Download CSV',
                          callback: callbacks.generateRouteCSV,
                      },
                  ]
                : []),
            ...(isRouteCreator && ['active', 'pending'].includes(route.status) && callbacks.sendBackToPlanning
                ? [
                      {
                          display: 'Send back to planning',
                          callback: callbacks.sendBackToPlanning,
                      },
                  ]
                : []),
        ];
    }, [route, user, callbacks]);
};

export const useDrivers = (teammates) => {
    return useMemo(() => {
        if (teammates) {
            return teammates;
        }

        return [];
    }, [teammates]);
};

export const useOrdersByKey = (orders) => {
    return useMemo(() => {
        if (orders) {
            return Object.fromEntries(
                orders.reduce((acc, arr) => {
                    return [...acc, ...arr.map((order) => [order.order_id, order])];
                }, [])
            );
        }

        return {};
    }, [orders]);
};

export const useFiltered = (arr, filter) => {
    return useMemo(() => {
        if (arr && filter) {
            return arr.filter(filter);
        }

        return [];
    }, [arr, filter]);
};

export const useGroupedRoutes = (routes, pageLocation) => {
    return useMemo(() => {
        if (pageLocation === 'admin-LTL') return [{}, [], []];
        if (routes && routes.length > 0) {
            const grouped = {};
            const cols = [{}, [], [], []];
            routes.forEach((route) => {
                if (!grouped[route.route_number]) {
                    // Special case that if it's a final return route without a driver assigned,
                    // it should stay in the in-progress column - unless it's already in the complete_final_return state.
                    if (route.finish_returns_next_day && route.status !== 'complete_final_return') {
                        if (!route.driver_id_final_return) {
                            cols[1].push(route);
                        } else {
                            // If driver is assigned, it should go into the pending final returns section
                            cols[2].push(route);
                        }

                        return;
                    }

                    grouped[route.route_number] = true;
                    switch (route.status) {
                        // PRE TRANSIT
                        case 'active':
                        case 'pending':
                        case 'planning':
                        case 'open': {
                            if (route.scheduled_delivery_formatted) {
                                if (isToday(new Date(route.scheduled_delivery_formatted))) {
                                    cols[0].today = [...(cols[0]?.today || []), route];

                                    return;
                                } else if (isTomorrow(new Date(route.scheduled_delivery_formatted))) {
                                    cols[0].tomorrow = [...(cols[0]?.tomorrow || []), route];

                                    return;
                                }
                            }

                            cols[0].upcoming = [...(cols[0]?.upcoming || []), route];
                            break;
                        }
                        // IN TRANSIT
                        case 'inProgress': {
                            cols[1].push(route);
                            break;
                        }
                        // COMPLETE
                        case 'complete_final_return':
                            cols[2].push(route);
                            break;

                        case 'complete': {
                            cols[3].push(route);
                            break;
                        }
                    }
                }
            });

            const routeDelDesc = (l, r) => {
                return new Date(r.scheduled_delivery).getTime() - new Date(l.scheduled_delivery).getTime();
            };

            ['today', 'tomorrow', 'upcoming'].forEach((attr) => {
                cols[0][attr] = (cols[0][attr] || []).sort(routeDelDesc);
            });
            cols[1] = cols[1].sort(routeDelDesc);
            cols[3] = cols[3].sort(routeDelDesc);

            return cols;
        }
        return [{}, [], [], []];
    }, [routes, pageLocation]);
};

export const useGroupedOrders = (orders, pageLocation) => {
    return useMemo(() => {
        if (pageLocation === 'admin-FTL') return [{}, [], []];
        if (orders && orders.length > 0) {
            const grouped = {};
            const cols = [{}, [], []];
            orders.forEach((order) => {
                if (!grouped[order.order_number]) {
                    grouped[order.order_number] = true;
                    switch (order.order_status) {
                        // PRE TRANSIT
                        case 'payable':
                        case 'claimed':
                        case 'open':
                        case 'pending':
                        case 'routed':
                        case 'routedActive': {
                            if (order.delivery_date_formatted) {
                                if (isToday(new Date(order.delivery_date_formatted))) {
                                    cols[0].today = [...(cols[0]?.today || []), order];

                                    return;
                                } else if (isTomorrow(new Date(order.delivery_date_formatted))) {
                                    cols[0].tomorrow = [...(cols[0]?.tomorrow || []), order];

                                    return;
                                }
                            }

                            cols[0].upcoming = [...(cols[0]?.upcoming || []), order];
                            break;
                        }
                        // IN TRANSIT
                        case 'routedInProgress':
                        case 'inProgress': {
                            cols[1].push(order);
                            break;
                        }
                        // COMPLETE
                        case 'routedComplete':
                        case 'complete_final_return':
                        case 'complete': {
                            cols[2].push(order);
                            break;
                        }
                    }
                }
            });

            const orderDelDesc = (l, r) => {
                return new Date(r.delivery_date).getTime() - new Date(l.delivery_date).getTime();
            };

            ['today', 'tomorrow', 'upcoming'].forEach((attr) => {
                cols[0][attr] = (cols[0][attr] || []).sort(orderDelDesc);
            });
            cols[1] = cols[1].sort(orderDelDesc);
            cols[2] = cols[2].sort(orderDelDesc);

            return cols;
        }

        return [{}, [], []];
    }, [orders, pageLocation]);
};
