import React from 'react';
import { css } from '@emotion/react';
import { Grid, TextField, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { poundFormatter } from '@/constants/formats';
import { colors } from '@/styles';
import { Image } from 'react-bootstrap';

import { EXCEPTION_REASONS } from '../../constants';
import FileDropZone from '../../FileDropZone';
import { Body1, ItemCheckbox, StyledRadio } from '../../Crossdocking/blocks';

const Damaged = ({ exception, required, order, callbacks }) => {
    return (
        <>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>
                    Items
                    {required.includes('items') ? (
                        <Body1
                            css={css`
                                color: #8d8d8d;
                            `}
                        >
                            &nbsp;(required)
                        </Body1>
                    ) : (
                        ''
                    )}
                </Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <Body1
                    css={css`
                        color: #8d8d8d;
                    `}
                >
                    Only 1 damaged item can be reported at a time
                </Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <Grid direction="column" container>
                    {(order?.itemsByOrderId || []).map((item) => {
                        return (
                            <FormControlLabel
                                checked={exception?.items?.[item.item_id]}
                                control={<Radio color="primary" />}
                                label={
                                    <Grid
                                        direction="column"
                                        container
                                        css={css`
                                            width: 100%;
                                        `}
                                    >
                                        <Grid
                                            direction="row"
                                            container
                                            item
                                            css={css`
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <Body1>
                                                {item.sku ? `${item.sku} / ` : ''}
                                                {item.description || item.item_type_details}
                                            </Body1>
                                        </Grid>
                                        <Grid
                                            direction="row"
                                            container
                                            item
                                            css={css`
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <Body1>{`Quantity: ${item.quantity}`}</Body1>
                                        </Grid>
                                        <Grid direction="row" container item>
                                            <Body1
                                                css={css`
                                                    color: ${colors.greys[0]};
                                                `}
                                            >{`${poundFormatter.format(item.total_weight)} ${item.length}L ${
                                                item.width
                                            }W ${item.height}H`}</Body1>
                                        </Grid>
                                    </Grid>
                                }
                                onChange={() => {
                                    const id = item.item_id;
                                    callbacks.setException((prev) => ({
                                        ...prev,
                                        items: {
                                            [id]: !prev?.items?.[id] ? item : null,
                                        },
                                    }));
                                }}
                                css={css`
                                    margin-bottom: 8px;
                                `}
                            />
                        );
                    })}
                </Grid>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>
                    Does customer want to keep the items?
                    {required.includes('item_kept') ? (
                        <Body1
                            css={css`
                                color: #8d8d8d;
                            `}
                        >
                            &nbsp;(required)
                        </Body1>
                    ) : (
                        ''
                    )}
                </Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <RadioGroup
                    value={!!exception.item_kept}
                    onChange={(e) => {
                        callbacks.setException((prev) => ({
                            ...prev,
                            item_kept: e.target.value === 'true',
                        }));
                    }}
                >
                    <StyledRadio value={true} label="Yes" />
                    <StyledRadio value={false} label="No" />
                </RadioGroup>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>
                    Reason
                    {required.includes('reason') ? (
                        <Body1
                            css={css`
                                color: #8d8d8d;
                            `}
                        >
                            &nbsp;(required)
                        </Body1>
                    ) : (
                        ''
                    )}
                </Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <RadioGroup
                    value={exception.reason || ''}
                    onChange={(e) => {
                        callbacks.setException((prev) => ({
                            ...prev,
                            reason: e.target.value,
                        }));
                    }}
                >
                    {EXCEPTION_REASONS[exception.type].map((reason) => (
                        <StyledRadio key={reason} value={reason} label={reason} />
                    ))}
                </RadioGroup>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>
                    Image
                    {required.includes('images') ? (
                        <Body1
                            css={css`
                                color: #8d8d8d;
                            `}
                        >
                            &nbsp;(required)
                        </Body1>
                    ) : (
                        ''
                    )}
                </Body1>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 30px;
                `}
            >
                {(exception.images || []).map((image) => (
                    <a href={image} target="_blank">
                        <Image
                            css={css`
                                height: 100px;
                                width: 100px;
                            `}
                            src={image}
                        />
                    </a>
                ))}
                <FileDropZone handleFileAdd={callbacks.handleFileAdd} />
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>
                    Notes
                    {required.includes('notes') ? (
                        <Body1
                            css={css`
                                color: #8d8d8d;
                            `}
                        >
                            &nbsp;(required)
                        </Body1>
                    ) : (
                        ''
                    )}
                </Body1>
            </Grid>
            <Grid direction="row" container>
                <TextField
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={exception.notes || ''}
                    onChange={(e) =>
                        callbacks.setException((prev) => ({
                            ...prev,
                            notes: e.target.value,
                        }))
                    }
                    multiline
                    rows={5}
                />
            </Grid>
        </>
    );
};

export default Damaged;
