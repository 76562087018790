import React, { useMemo, useState } from 'react';
import { Grid, TextField, InputAdornment, MenuItem, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { css } from '@emotion/react';
import { colors } from '@/styles';

import ZipListItem from './ZipListItem';
import { useClientUser } from '@/hooks';
import { OnwardSwitch, PrimaryButton } from '@/styles/blocks';
import { Close as CloseIcon } from '@material-ui/icons';

const ZipList = ({ subregion, loading, zips, filter, callbacks, carriers, carriersById }) => {
    const { isOnwardAdmin } = useClientUser();
    const [newCarrier, setNewCarrier] = useState(null);

    const list = useMemo(() => {
        if (!zips) {
            return [];
        }

        return zips.filter((zip) => {
            return !filter.zip || zip.zip.toLowerCase().includes(filter?.zip.toLowerCase());
        });
    }, [zips, filter]);

    const carrierList = useMemo(() => {
        return [...(carriers || []), ...(newCarrier ? [newCarrier] : [])];
    }, [carriers, newCarrier]);

    return (
        <>
            {isOnwardAdmin ? (
                <>
                    {carrierList.map((mapping, i) => (
                        <Grid
                            container
                            css={css`
                                flex: 0;
                                flex-basis: 0;
                                flex-wrap: nowrap;
                                padding: 18px 20px 0px 20px;
                                align-items: center;
                            `}
                            key={mapping.mapping_id}
                        >
                            <TextField
                                select
                                variant="outlined"
                                label={`Carrier ${i + 1}`}
                                value={mapping.carrier_id || ''}
                                disabled={loading.insertCarrierMappings}
                                onChange={(e) => {
                                    const carrier_id = e.target.value;
                                    const { __typename, ...rest } = mapping;
                                    callbacks.insertCarrierMapping({ ...rest, carrier_id, warehouse_id: null });
                                    setNewCarrier(null);
                                }}
                                css={css`
                                    width: 250px;
                                `}
                            >
                                {Object.entries(carriersById || {}).map(([carrier_id, carrier]) => (
                                    <MenuItem key={carrier_id} value={carrier_id}>
                                        {carrier.business_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                variant="outlined"
                                label="Warehouse"
                                value={mapping.warehouse_id || ''}
                                disabled={loading.insertCarrierMappings || !mapping.carrier_id}
                                onChange={(e) => {
                                    const warehouse_id = e.target.value;
                                    const { __typename, ...rest } = mapping;
                                    callbacks.insertCarrierMapping({ ...rest, warehouse_id });
                                }}
                                css={css`
                                    margin-left: 20px;
                                    width: 250px;
                                `}
                            >
                                {(carriersById[mapping.carrier_id]?.locations || []).map((location) => (
                                    <MenuItem key={location.location_id} value={location.location_id}>
                                        {location.location_name || 'No Location Name'}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Grid item>
                                <OnwardSwitch
                                    label="Direct"
                                    checked={!!mapping.is_direct}
                                    onChange={(_, checked) => {
                                        const { __typename, ...rest } = mapping;
                                        callbacks.insertCarrierMapping({ ...rest, is_direct: checked });
                                    }}
                                    disabled={loading.insertCarrierMappings}
                                    css={css`
                                        margin-left: 20px;
                                    `}
                                />
                            </Grid>
                            {mapping.mapping_id ? (
                                <Grid item>
                                    <IconButton
                                        css={css`
                                            padding: 8px;
                                            margin-left: 20px;
                                        `}
                                        onClick={() => callbacks.removeCarrierMapping(mapping)}
                                    >
                                        <CloseIcon
                                            css={css`
                                                font-size: 14px;
                                            `}
                                        />
                                    </IconButton>
                                </Grid>
                            ) : null}
                        </Grid>
                    ))}
                    <Grid
                        container
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            padding: 18px 20px 0px 20px;
                        `}
                    >
                        <PrimaryButton
                            onClick={() => {
                                setNewCarrier({ subregion_id: subregion.subregion_id });
                            }}
                        >
                            Add Carrier
                        </PrimaryButton>
                    </Grid>
                </>
            ) : null}
            <Grid
                container
                direction="row"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    padding: 18px 20px;
                    border-bottom: 1px solid ${colors.greys[4]};
                `}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search Zips"
                    value={filter?.zip || ''}
                    onChange={(e) => {
                        callbacks.setFilter((prev) => {
                            return {
                                ...prev,
                                zip: e.target.value,
                            };
                        });
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    overflow-y: scroll;
                `}
            >
                <Grid container direction="column">
                    {list.map((zip, idx) => {
                        return <ZipListItem key={zip.zip} zip={zip} idx={idx} callbacks={callbacks} />;
                    })}
                </Grid>
            </Grid>
        </>
    );
};

export default ZipList;
