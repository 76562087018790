import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { compose } from 'recompose';
import { colors } from '@/styles';
import withFeatureFlag from '@/components/Auth/withFeatureFlag';
import { ADVANCED_SCHEDULING } from '@/constants/featureFlags';

import { Context, ContextProvider } from './store';
import { PageTitle } from './blocks';

import EditResourceModal from './modals/EditResourceModal';

import Calendar from './Calendar';
import Snackbar from '../Snackbar';

const Resources = () => {
    const { callbacks, state, loading } = useContext(Context);

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
                flex-wrap: nowrap;
            `}
        >
            <Grid
                container
                direction="row"
                css={css`
                    padding: 32px 44px;
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <PageTitle>Resources</PageTitle>
            </Grid>

            <Grid
                container
                direction="row"
                css={css`
                    padding: 0 44px;
                    flex: 1;
                    flex-basis: 0;
                `}
            >
                <Calendar intervals={state.intervals} tz={state.tz} />
            </Grid>

            <EditResourceModal
                {...state.editing}
                isLoading={loading.editModal}
                resources={state.resourceById}
                subregions={state.subregionsById}
                subregionsOrdered={state.subregionsOrdered}
                entities={state.entitiesById}
                callbacks={{
                    saveResource: callbacks.upsertResources,
                    deleteRule: callbacks.deleteRule,
                    onClose: callbacks.clearEdit,
                    deleteResource: callbacks.deleteResource,
                }}
            />

            <Snackbar
                open={!!state.notification?.message}
                {...state.notification}
                handleClose={callbacks.clearNotification}
            />
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withFeatureFlag(ADVANCED_SCHEDULING), withContext)(Resources);
