import React from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';

function Footer({ state, loading, callbacks }) {
    return (
        <>
            <Grid
                container
                css={css`
                    padding: 1rem;
                    background-color: white;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-right: 1rem;
                    `}
                >
                    <PrimaryButton onClick={callbacks.exportCsv} disabled={state.selected.length === 0}>
                        Export
                    </PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}

export default Footer;
