import { useItemQuantity, useTotalCubes, useTotalWeight, useOrderCarrierPricing, useClientUser } from '@/hooks';
import { asBrowserDate } from '@/utilities/convertToISO';
import { css } from '@emotion/react';
import { Grid, Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import { Body, border, Price, Title } from './blocks';
import { colors } from '@/styles';
import BidIcon from '../misc/BidIcon';
import { BIDDING_DISABLED } from '@/constants/featureFlags';
import { useMemo } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { withStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
    ORDER_COMMUNICATIONS_EVENT_TYPES,
    INSERT_ORDER_COMMUNICATIONS_ENTRY,
    REMOVE_ORDER_COMMUNICATIONS_ENTRY,
} from '@/graphql/mutations/order_communications';
import { captureException } from '@sentry/react';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

const CustomTooltip = withStyles(() => ({
    tooltip: {
        fontSize: 14,
        backgroundColor: '#E8E8E8',
        color: '#2B2B2B',
        boxShadow: '0px 2px 4px 0px #3F3F4480',
        padding: '14px',
    },
    arrow: {
        "&:before": {
            border: "1px solid #E8E8E8"
        },
        color: '#E8E8E8',
      },
}))(Tooltip);

const Load = ({ order, callbacks, selected }) => {
    const { circles, user_id } = useClientUser();
    const price = useOrderCarrierPricing(order);
    const first_avail = order.first_available_date
        ? format(asBrowserDate(order.first_available_date), 'MM/dd/yyyy')
        : null;

    const items = useItemQuantity([order]);
    const volume = parseInt(useTotalCubes([order]));
    const weight = parseInt(useTotalWeight([order]));

    const bg = selected ? `background-color: ${colors.greys.selected};` : '';

    const bidding = !circles?.[BIDDING_DISABLED] && ['all', 'auction'].includes(order?.listing?.listing_type);

    const carriersBid = useMemo(() => {
        return order?.listing?.bids?.find((bid) => bid.carrier_id === user_id);
    }, [order]);

    const [orderCommId, setOrderCommId] = useState(null);

    const [insertOrderCommsEntry, { loading: insertLoading }] = useMutation(INSERT_ORDER_COMMUNICATIONS_ENTRY);
    const [removeOrderCommsEntry, { loading: deleteLoading }] = useMutation(REMOVE_ORDER_COMMUNICATIONS_ENTRY);

    const loading = insertLoading || deleteLoading;

    useEffect(() => {
        let _id = order?.order_communications?.find((o) => o?.client_id === user_id)?.order_communication_id;
        if (_id) {
            setOrderCommId(_id);
        }
    }, [order]);

    const insertOrderCommSubscription = () => {
        insertOrderCommsEntry({
            variables: {
                client_id: user_id,
                order_id: order.order_id,
                event_type: ORDER_COMMUNICATIONS_EVENT_TYPES.LTL_TO_OPEN,
            },
            onCompleted: (data) => {
                setOrderCommId(data?.insert_order_communications_one?.order_communication_id);
            },
            onError: (err) => {
                console.error(err);
                captureException(err);
            },
        });
    };

    const deleteOrderCommsSubscription = () => {
        const order_comm_id = orderCommId || order?.order_communications?.find(
            (o) => o?.client_id === user_id
        )?.order_communication_id;
        removeOrderCommsEntry({
            variables: {
                order_communication_id: order_comm_id,
            },
            onCompleted: (data) => {
                setOrderCommId(null);
            },
            onError: (err) => {
                console.error(err);
                captureException(err);
            },
        });
    };

    const toggleMark = (e) => {
        e.stopPropagation();
        if (loading) return;

        if (!orderCommId) {
            insertOrderCommSubscription();
        } else {
            deleteOrderCommsSubscription();
        }
    };

    const handlePrebid = (e) => {
        e.stopPropagation();
        callbacks?.selectOrder(order.order_id);
        callbacks.setPlaceBidModalOpen(true);
    };

    return (
        <Grid
            container
            css={css`
                padding: 1rem;
                flex-direction: column;
                border-bottom: ${border};
                cursor: pointer;
                ${bg}
            `}
            onClick={() => callbacks?.selectOrder(order.order_id)}
        >
            {order.order_status === 'pending' && (
                <Grid
                    item
                    container
                    direction="column"
                    css={css`
                        background-color: ${colors.oranges.primary};
                        color: white;
                        border-radius: 5px;
                        padding: 3px 8px 3px 8px;
                        margin-bottom: 14px;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Tooltip
                            title={
                                <span
                                    css={css`
                                        font-size: 14px;
                                    `}
                                >
                                    This order is being reveiwed by Onward admins and is currently pending. It will be
                                    available soon to claim.
                                </span>
                            }
                            arrow
                            placement="top"
                        >
                            <InfoOutlinedIcon
                                color="white"
                                css={css`
                                    margin-right: 5px;
                                `}
                            />
                        </Tooltip>
                        <Body
                            css={css`
                                text-wrap: nowrap;
                                font-size: 13px;
                            `}
                        >
                            Under Review. Available soon.
                        </Body>
                    </Grid>
                    <Grid item container>
                        <Grid
                            item
                            xs={6}
                            css={css`
                                ${loading &&
                                `
                                    opacity: 0.5;
                                `}
                            `}
                        >
                            <CustomTooltip
                                title={
                                    !orderCommId ? (
                                        <span
                                            css={css`
                                                font-size: 14px;
                                            `}
                                        >
                                            Click Mail icon if you would like to be notified when this order becomes
                                            available for claiming or bidding.
                                        </span>
                                    ) : (
                                        <span>
                                            You will receive a notification when this order becomes available! You may
                                            click the mail icon to disable.
                                        </span>
                                    )
                                }
                                arrow
                                placement="top"
                            >
                                <div onClick={(e) => toggleMark(e)} style={{ width: 'fit-content' }}>
                                    {!!orderCommId ? (
                                        <MailIcon style={{ marginRight: '2px' }} />
                                    ) : (
                                        <MailOutlineIcon style={{ marginRight: '2px' }} />
                                    )}
                                    <Body
                                        css={css`
                                            text-wrap: nowrap;
                                            font-size: 13px;
                                        `}
                                    >
                                        Watch Order?
                                    </Body>
                                </div>
                            </CustomTooltip>
                        </Grid>

                        <Grid item xs={6} onClick={(e) => handlePrebid(e)}>
                            <CustomTooltip
                                title={"Click to place a pre-bid"}
                                arrow
                                placement="top"
                            >
                                <div style={{ width: 'fit-content' }}>
                                    <HistoryEduIcon style={{ marginRight: '2px' }} />
                                    <Body
                                        css={css`
                                            text-wrap: nowrap;
                                            font-size: 13px;
                                        `}
                                    >
                                        Pre-bid
                                    </Body>
                                </div>
                            </CustomTooltip>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <Title>{order.order_number}</Title>
                <Price>
                    {bidding && <BidIcon />}
                    {price ? price : 'N/A'}
                </Price>
            </Grid>
            <Grid item>
                <Body>
                    {order.pickup_city} - {order.dropoff_city}
                </Body>
            </Grid>
            <Grid item>
                <Body>Available: {first_avail || 'N/A'}</Body>
            </Grid>
            <Grid item>
                <Body>{`${items} Item(s) / ${volume} cu ft. / ${weight} lbs`}</Body>
            </Grid>

            {carriersBid && (
                <Grid item>
                    <Body
                        css={css`
                            font-weight: 700;
                        `}
                    >{`Bid Submitted: $${carriersBid.carrier_rate.toFixed(2)}`}</Body>
                </Grid>
            )}
        </Grid>
    );
};

export default Load;
