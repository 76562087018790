import { Card, Grid, Divider, CircularProgress } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@/styles';
import { delWindowToFormattedDateSingle } from '@/utilities/delWindowToFormattedDate';
import { toNational } from '@/utilities/formatPhoneNumber';

import { H1, H2, Body1, Body2, Subtitle1, Subtitle2 } from './blocks';
import { STATUS_MAPPINGS, STATUSES } from './constants';
import { useQuery } from '@apollo/client';
import { MESSAGES_BY_ORDER_ID } from '@/graphql/queries/messages';
import { captureException } from '@sentry/react';

const dateShort = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const StatusText = styled(({ children, ...rest }) => <Subtitle2 {...rest}>{children}</Subtitle2>)`
    font-weight: bold;
    color: ${(props) => {
        switch (props.status) {
            case STATUSES.QUEUED:
            case STATUSES.SCHEDULED:
                return '#8d8d8d';
            case STATUSES.SUCCESS:
                return '#42b52c';
            case STATUSES.FAILED:
                return 'red';
        }
    }};
`;

const SourceBadge = styled(({ children, ...rest }) => <H2 {...rest}>{children}</H2>)`
    color: ${colors.white.primary};
    padding: 4px 8px;
    border-radius: 5px;

    ${(props) => {
        switch (props.source) {
            case 'API':
                return css`
                    background: #42b52c;
                `;
            case 'RETRY':
            case 'Customer':
                return css`
                    background: #a6a6a6;
                `;
            case 'Dispatcher':
                return css`
                    background: #42b52c;
                `;
            default:
                return css`
                    background: red;
                `;
        }
    }}
`;

const CommunicationLog = (props) => {
    const { customerName = 'Customer Name', customerPhone = '+10000000000', customerZip, order_id } = props;

    const { loading, data } = useQuery(MESSAGES_BY_ORDER_ID, {
        skip: !order_id,
        variables: { order_id: order_id },
        onError: (error) => {
            console.error(error);
            captureException(error);
        },
    });

    const messages = useMemo(() => {
        if (props.messages) {
            return props.messages;
        }

        // copy into new array since data.messages is read only and cant be sorted
        return (data?.results ? [...data.results] : []).sort((a, b) => {
            if (a.timestamp === b.timestamp) {
                return a.source > b.source ? 1 : -1;
            }

            return new Date(a.timestamp) - new Date(b.timestamp);
        });
    }, [props.messages, data]);

    const isLoading = loading || props.loading;

    return (
        <Card
            css={css`
                height: 100%;
                width: 100%;

                background: ${colors.white.primary};
                padding: 20px;
            `}
        >
            <Grid container>
                <Grid item xs={12}>
                    <H1>{customerName}</H1>
                </Grid>
                <Grid item xs={12}>
                    <Subtitle1
                        css={css`
                            color: ${colors.greys.primary};
                        `}
                    >
                        {toNational(customerPhone)}
                    </Subtitle1>
                </Grid>
                {isLoading && <CircularProgress />}
                {!isLoading &&
                    messages.map((message, idx) => (
                        <React.Fragment key={`${message.sid}:${message.widget_name || '-'}`}>
                            {idx > 0 && (
                                <Divider
                                    css={css`
                                        width: 100%;
                                        margin-top: 26px;
                                        border-bottom: ${colors.greys.primary};
                                    `}
                                />
                            )}
                            <Grid
                                container
                                xs={12}
                                css={css`
                                    padding-top: 26px;
                                `}
                            >
                                <Grid container direction="column" xs={3}>
                                    <Grid
                                        item
                                        css={css`
                                            margin-bottom: 4px;
                                        `}
                                    >
                                        <SourceBadge source={message.source}>{message.source || 'Unknown'}</SourceBadge>
                                    </Grid>
                                    {message.status && (
                                        <Grid item>
                                            <StatusText status={message.status}>
                                                {STATUS_MAPPINGS[message.status]}
                                            </StatusText>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Subtitle2
                                            css={css`
                                                color: ${colors.greys.primary};
                                            `}
                                        >
                                            {message.timestamp ? dateShort.format(new Date(message.timestamp)) : '--'}
                                        </Subtitle2>
                                    </Grid>
                                    <Grid item>
                                        <Subtitle2
                                            css={css`
                                                color: ${colors.greys.primary};
                                            `}
                                        >
                                            {message.timestamp
                                                ? delWindowToFormattedDateSingle(
                                                      new Date(message.timestamp),
                                                      customerZip
                                                  )
                                                : '--'}
                                        </Subtitle2>
                                    </Grid>
                                </Grid>
                                <Grid item xs={9}>
                                    <Body1>{message.message}</Body1>
                                    {message.link_clicked && (
                                        <Body2
                                            css={css`
                                                margin-top: 8px;
                                                font-style: italic;
                                                color: #197ef3;
                                            `}
                                        >
                                            Link Opened
                                        </Body2>
                                    )}
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}
            </Grid>
        </Card>
    );
};

export default CommunicationLog;
