import React, { useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid, Switch, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { SectionTitle, PrimaryButton, Body } from '@/styles/blocks';
import { useClientUser } from '@/hooks';
import { SHIPMENT_FORM_TYPES } from '../constants';
import { Context } from '../store';
import OrderCard from '../cards/OrderCard';
import PickUpCard from '../cards/PickUpCard';
import { InfoCircleOutlined } from '@ant-design/icons';
import FreightTypeCard from '../cards/FreightTypeCard';
import { genAttributes } from '@onward-delivery/core';
import PickUpGroupCard from '../cards/PickUpGroupCard';

const ReviewOrders = () => {
    const { state, errors, callbacks } = useContext(Context);
    const { circles } = useClientUser();

    const sorted = useMemo(() => {
        if (state.orders && Object.values(state.orders).length > 0) {
            return Object.values(state.orders).sort((l, r) => l.key - r.key);
        }

        return [];
    }, [state.orders]);

    const groupedByPickup = useMemo(() => {
        if (state.groupedPickup) {
            return Object.values(state.orders).reduce((acc, order) => {
                const { address: pu_address } = genAttributes(order, true);
                const address = order[pu_address];
                return {
                    ...acc,
                    [address]: [...(acc[address] || []), order],
                };
            }, {});
        }

        return {};
    }, [state.orders, state.groupedPickup]);

    return (
        <>
            <Grid
                item
                container
                css={css`
                    margin-bottom: 16px;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        margin-right: 20px;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <SectionTitle>Freight Type</SectionTitle>
                    </Grid>
                    <Grid item>
                        <FreightTypeCard />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 4;
                    `}
                >
                    {!state.groupedPickup && (
                        <>
                            <Grid
                                item
                                css={css`
                                    margin-bottom: 8px;
                                `}
                            >
                                <SectionTitle>Pick Up</SectionTitle>
                            </Grid>
                            <Grid item>
                                <PickUpCard />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
                <Grid
                    item
                    css={css`
                        margin-bottom: 8px;
                    `}
                >
                    <SectionTitle>Orders</SectionTitle>
                </Grid>
            </Grid>
            {state.groupedPickup ? (
                <>
                    {Object.entries(groupedByPickup).map(([pu_address, pu_orders]) => (
                        <Grid item key={pu_address}>
                            <PickUpGroupCard
                                address={pu_address}
                                orders={pu_orders}
                                validation={{
                                    hasError: (order) => {
                                        return Object.values(errors.missingFieldsByOrder?.[order.key] || {}).some(
                                            (x) => x
                                        );
                                    },
                                    hasErrorTab: (order, tab) => {
                                        return !!errors.missingFieldsByOrder?.[order.key]?.[tab];
                                    },
                                }}
                                callbacks={callbacks}
                            />
                        </Grid>
                    ))}
                </>
            ) : (
                <>
                    {sorted.map((order, idx) => (
                        <Grid item key={order.key}>
                            <OrderCard
                                order={order}
                                orderNumber={idx + 1}
                                validation={{
                                    hasErrorTab: (tab) => {
                                        return !!errors.missingFieldsByOrder?.[order.key]?.[tab];
                                    },
                                }}
                                callbacks={callbacks}
                            />
                        </Grid>
                    ))}
                </>
            )}
            {state.formType === SHIPMENT_FORM_TYPES.MANUAL ? (
                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        margin-top: 24px;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-right: 16px;
                        `}
                    >
                        <PrimaryButton onClick={() => callbacks.addOrder('delivery')}>
                            <span>
                                <Add />
                                Order
                            </span>
                        </PrimaryButton>
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
};

export default ReviewOrders;
