import { gql } from '@apollo/client';

export const RESOURCE_SHIPPING_PARTNER_MAPPING_FIELDS = gql`
    fragment ResourceShippingPartnerMappingFields on resource_shipping_partner_mappings {
        mapping_id
        resource_id
        enabled
        partner_client_id
    }
`;
