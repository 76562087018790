import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { colors } from '@/styles';
import * as typography from '@/styles/typography';

export const Circle = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 999px;
`;

export const PageTitle = styled.span`
    ${typography.sansSerif}

    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
`;

export const Header1 = styled.span`
    ${typography.sansSerif}

    font-size: 14px;
    font-weight: 800;
    line-height: 27px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
`;
