import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Container, Row, Col, Card } from 'reactstrap';
import * as ROUTES from '../../constants/routes';
import * as Sentry from '@sentry/react';
import FooterComponent from '../Footer';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { UserContext } from '../App';

function Auth() {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user) {
            navigate('/account');
        }
    }, [user]);

    const authenticate = async () => {
        await signInWithEmailAndPassword(getAuth(), email.toLowerCase(), password);
        window.analytics.identify({ email: email });
        setEmail('');
        setPassword('');
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        const _errors = [];

        try {
            await authenticate();
        } catch (err) {
            _errors.push(
                'Authentication ' + err.message.split(' ')[1].trim() + ': ' + err.message.split(' ')[2].trim()
            );
        }

        setErrors(_errors);
        setLoading(false);
    };

    return (
        <>
            <Container className="mt-5">
                <Row>
                    <Col className="d-flex justify-content-center mt-4">
                        <Card className="shadow border-0 signupform mt-5">
                            <Form style={{ margin: '5rem' }}>
                                <Container className="d-flex justify-content-center mb-3">
                                    <h4>Sign in to your Onward account</h4>
                                </Container>

                                <Form.Control
                                    name="email"
                                    type="text"
                                    placeholder="Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    style={{ marginBottom: '10px' }}
                                />
                                <Form.Control
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    style={{ marginBottom: '10px' }}
                                />

                                <Container style={{ marginBottom: '10px' }} className="d-flex justify-content-center">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={onSubmit}
                                        disabled={loading || !email || !password}
                                    >
                                        Sign In
                                    </Button>
                                </Container>

                                {!!errors.length &&
                                    errors.map((error) => (
                                        <p style={{ color: 'red', fontWeight: '600', textAlign: 'center' }}>{error}</p>
                                    ))}
                                {/* LEAVING THIS CHUNK HERE IN CASE WE HAVE FUTURE AUTH ISSUES */}
                                {/* <Container style={{ marginBottom: '10px', backgroundColor: "orange" , padding: "10px", borderRadius: "5px", fontWeight: 600}} className="d-flex justify-content-center">
                                    <div>We are currently seeing some issues with signing in. Please try hard refreshing,<br/> clearing your cache and cookies and signing in again, or signing in via incognito mode. <br/>Reach out to your rep if you are still experiencing issues. </div>
                                </Container> */}
                                <Container style={{ textAlign: 'center' }} className="d-flex justify-content-center">
                                    <Link to={ROUTES.PASSWORD_FORGET}>
                                        <b>Forgot password?</b>
                                    </Link>
                                </Container>
                                <Container
                                    style={{ textAlign: 'center' }}
                                    className="d-flex flex-column justify-content-center"
                                >
                                    Don't have an account?
                                    <Link to={ROUTES.SHIPPER_SIGN_UP}>
                                        <b>Shipper Sign Up</b>
                                    </Link>
                                    <Link to={ROUTES.CARRIER_SIGN_UP}>
                                        <b>Carrier Sign Up</b>
                                    </Link>
                                    <Link to={ROUTES.CORPORATE_SIGNUP}>
                                        <b>Corporate Admin Sign Up</b>
                                    </Link>
                                </Container>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <FooterComponent />
        </>
    );
}

export default Auth;
