import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { SecondaryButton } from '@/styles/blocks';
import { Grid, TextField } from '@material-ui/core';
import { ResponsiveSidebarDialog, StickyModalActions, ModalContent, ModalTitle, PrimaryButton } from '@/styles/blocks';
import { Header2 } from '../Crossdocking/blocks';

const PalletDimensionsModal = ({ open, order, action, callbacks }) => {
    const [updates, setUpdates] = useState({});

    const defaults = useMemo(() => {
        return {
            L: order?.palletized_dimensions?.L || 0,
            W: order?.palletized_dimensions?.W || 0,
            H: order?.palletized_dimensions?.H || 0,
        };
    }, [order]);

    const updated = useMemo(() => {
        return {
            ...defaults,
            ...updates,
        };
    }, [updates, defaults]);

    const isComplete = useMemo(() => {
        return ["L", "W", "H"].every(dimension => !!updated[dimension])
    }, [updated]);

    return (
        <ResponsiveSidebarDialog open={!!open} onClose={callbacks.onClose} maxWidth="lg">
            <ModalTitle onClose={callbacks.onClose}>
                <Header2
                    css={css`
                        color: #4c4c4c;
                    `}
                >
                    Add Pallet Dimensions (inches)
                </Header2>
            </ModalTitle>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                <Grid
                    direction="row"
                    container
                    justifyContent="center"
                    css={css`
                        gap: 20px;
                    `}
                >
                    {['L', 'W', 'H'].map((dimension) => (
                        <Grid
                            key={`${dimension}-pallet-key`}
                            item
                            css={css`
                                display: flex;
                                align-items: center;
                                gap: 3px;
                            `}
                        >
                            <TextField
                                css={css`
                                    width: 58px;
                                `}
                                fullWidth={false}
                                variant="outlined"
                                value={updated[dimension] || ''}
                                placeholder={'-'}
                                onChange={(e) => {
                                    let next = e.target.value;
                                    next = parseInt(next, 10);

                                    setUpdates((prev) => ({
                                        ...prev,
                                        [dimension]: next,
                                    }));
                                }}
                            />
                            <span
                                css={css`
                                    font-size: 18px;
                                    font-weight: 700;
                                `}
                            >
                                {dimension}
                            </span>
                        </Grid>
                    ))}
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton
                        disabled={!isComplete}
                        onClick={() => {
                            const newDimensions = {
                                L: updated.L,
                                W: updated.W,
                                H: updated.H,
                            };

                            callbacks.onSubmit({
                                order: {
                                    palletized_dimensions: newDimensions,
                                },
                            })
                        }}
                    >
                        Done
                    </PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default PalletDimensionsModal;
