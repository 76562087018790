import React from 'react';
import { Grid } from '@material-ui/core';
import { OnwardCard } from './Card';
import { CardTitle, CardItem } from './blocks';
import { css } from '@emotion/react';
import { startCase } from 'lodash';
import { toNational } from '@/utilities/formatPhoneNumber';
import { format } from 'date-fns';

const MiddleMileCard = ({ order }) => {
    const statusTs =
        order?.middle_mile_integration_meta?.[`${order?.middle_mile_integration_status?.toLowerCase()}_time`];

    return (
        <OnwardCard
            css={css`
                height: 100%;
            `}
            contentProps={{ style: { height: '100%' } }}
        >
            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} direction="column">
                <CardTitle>Middle Mile Origin Facility</CardTitle>
                {order.middle_mile_is_direct ? (
                    <CardItem
                        className="my-1"
                        css={css`
                            font-weight: 700;
                        `}
                    >
                        Direct Shipment - Integrations Disabled
                    </CardItem>
                ) : (
                    <>
                        <CardItem
                            className="my-1"
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            {order.middle_mile_integration_status
                                ? startCase(order.middle_mile_integration_status.toLowerCase().replace('_', '-'))
                                : 'No Status Available'}
                            {!!statusTs ? `: ${format(new Date(statusTs), 'EEE MMM dd, yyyy h:mm a')}` : null}
                        </CardItem>
                        <CardItem className="my-1">
                            Tracking / Pro #: {order.middle_mile_integration_meta?.tracking_number || '--'}
                        </CardItem>
                        <CardItem className="my-1">
                            Carrier: {order.middle_mile_integration_meta?.carrier_name || '--'}
                        </CardItem>
                    </>
                )}
                <CardItem className="my-1">Origin Name: {order.middle_mile_origin_name || '--'}</CardItem>
                <CardItem className="my-1">Origin Email: {order.middle_mile_origin_email || '--'}</CardItem>
                <CardItem className="my-1">
                    Origin Phone: {order.middle_mile_origin_phone ? toNational(order.middle_mile_origin_phone) : '--'}
                </CardItem>
                <CardItem className="my-1">
                    {[
                        order.middle_mile_origin_address,
                        ...(order.middle_mile_origin_unit ? [order.middle_mile_origin_unit] : []),
                        order.middle_mile_origin_city,
                        order.middle_mile_origin_state,
                        order.middle_mile_origin_zip,
                    ].join(', ')}
                </CardItem>
                <CardItem className="mt-1 mb-4">
                    {startCase(order.middle_mile_origin_location || '')} -{' '}
                    {startCase(order.middle_mile_origin_location_type || '')}
                </CardItem>
            </Grid>
        </OnwardCard>
    );
};

export default MiddleMileCard;
