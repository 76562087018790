import { gql } from '@apollo/client';
export const UPDATE_CARRIER_DOCUMENT = gql`
    mutation UpdateCarrierDocument($client_id: uuid = "", $_set: clients_set_input = {}) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: $_set) {
            w_form
            w_form_uploaded_at
            workers_comp_form
            workers_comp_form_uploaded_at
            insurance_form
            insurance_form_uploaded_at
            onward_form
            onward_form_uploaded_at
            client_id
        }
    }
`;

export const UPDATE_DOCUMENT_EXPIRY = gql`
    mutation UpdateExpiry($client_id: uuid = "", $_set: clients_set_input = {}) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: $_set) {
            client_id
            insurance_form_expiration
            workers_comp_form_expiration
        }
    }
`;
