import { gql } from '@apollo/client';
import { CLIENT_FIELDS } from './clients';
import { ORDER_FIELDS, ORDER_ROUTE_FIELDS } from './orders';
import { STOP_FIELDS } from './stops';
import { ITEM_FIELDS } from './items';
import { TRUCK_FIELDS } from './trucks';
import { TEAMMATE_FIELDS } from './teammates';
import { PALLET_ITEM_FIELDS } from './pallet_items';
import { WAREHOUSE_EVENT_FIELDS } from './order_wh_events';
import { ORDER_ROUTE_MAPPINGS } from './order_route_mappings';

export const BASE_ROUTE_FIELDS = gql`
    ${TRUCK_FIELDS}
    fragment BaseRouteFields on routes {
        route_id
        shipper_id
        bulk_bol
        carrier_id
        driver_id
        legacy_carrier_id
        claimed_at
        complete
        completed
        completed_formatted @client
        created_at
        created_by
        delivery_by
        legacy_driver_id
        estimated_driving_distance
        estimated_driving_time
        finish_returns_next_day
        geo_timestamps
        handle_orders_on_delete
        helper_id
        is_new_route
        last_modified_at
        last_modified_by
        last_modified_source
        last_update_source
        legacy_carrier_id
        legacy_driver_id
        legacy_route_id
        legacy_shipper_id
        margin
        need_to_optimize
        need_to_submit
        oms
        pending_confirmations
        pickup_time
        planning
        route_number
        scheduled_delivery
        scheduled_delivery_formatted @client
        sending_to
        legacy_shipper_id
        shipper_id
        source_form
        start_time
        status
        test_acc
        total_carrier_rate
        total_cubes
        total_shipper_rate
        truck_id
        route_id
        route_alias
        route_color
        driver_id_final_return
        truck {
            ...TruckFields
        }
    }
`;

export const ROUTE_FIELDS = gql`
    ${BASE_ROUTE_FIELDS}
    ${CLIENT_FIELDS}
    fragment RouteFields on routes {
        ...BaseRouteFields
        route_carrier {
            ...ClientFields
        }
        route_shipper {
            ...ClientFields
        }
    }
`;

export const ROUTE_ORDER_FIELDS = gql`
    ${ORDER_FIELDS}
    ${ORDER_ROUTE_FIELDS}
    ${ITEM_FIELDS}
    ${ORDER_ROUTE_MAPPINGS}
    fragment RouteOrderFields on routes {
        orders {
            ...OrderRouteMappings
            order {
                ...OrderFields
                ...OrderRouteFields
                itemsByOrderId {
                    ...ItemFields
                }
                items @client {
                    ...ItemFields
                }
                returns @client {
                    ...ItemFields
                }
            }
        }
    }
`;

export const ROUTE_MANIFEST_FIELDS = gql`
    ${TRUCK_FIELDS}
    ${TEAMMATE_FIELDS}
    ${ROUTE_FIELDS}
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${ORDER_ROUTE_MAPPINGS}
    fragment RouteManifestFields on routes {
        ...RouteFields
        orders {
            ...OrderRouteMappings
            order {
                ...OrderFields
                itemsByOrderId {
                    ...ItemFields
                }
            }
        }
        truck {
            ...TruckFields
        }
        driver: teammateByDriverId {
            ...TeammateFields
        }
    }
`;

export const FULL_QUERY = gql`
    query GetRoutes($test_acc: Boolean) {
        routes(limit: 100, where: { test_acc: { _eq: $test_acc } }) {
            route_id
            shipper_id
            carrier_id
            driver_id
            bulk_bol
            legacy_carrier_id
            claimed_at
            complete
            completed
            created_at
            created_by
            delivery_by
            legacy_driver_id
            estimated_driving_distance
            estimated_driving_time
            finish_returns_next_day
            geo_timestamps
            handle_orders_on_delete
            is_new_route
            last_modified_at
            last_modified_by
            last_modified_source
            last_update_source
            margin
            need_to_optimize
            oms
            pending_confirmations
            pickup_time
            planning
            route_number
            scheduled_delivery
            sending_to
            legacy_shipper_id
            source_form
            start_time
            status
            test_acc
            total_carrier_rate
            total_cubes
            total_shipper_rate
            legacy_route_id
            route_carrier {
                carrier_id
                name
                bizname
            }
            route_shipper {
                shipper_id
                business
            }
            stopsByRouteId {
                stop_id
                type
                returns
                exchanges
                ordering
                address
            }
        }
    }
`;

export const QUERY_BY_ID = gql`
    ${ROUTE_FIELDS}
    ${STOP_FIELDS}
    ${ROUTE_ORDER_FIELDS}
    query routesByPk($route_id: uuid!) {
        routes_by_pk(route_id: $route_id) {
            ...RouteFields
            ...RouteOrderFields
            stopsByRouteId {
                ...StopFields
            }
        }
    }
`;

export const QUERY_SEARCH = gql`
    query routes(
        $testCondition: routes_bool_exp!
        $searchCondition: routes_bool_exp!
        $shipperFilterCondition: routes_bool_exp!
        $carrierFilterCondition: routes_bool_exp!
        $startDateFilterCondition: routes_bool_exp!
        $endDateFilterCondition: routes_bool_exp!
    ) {
        routes(
            limit: 100
            where: {
                _and: [
                    $testCondition
                    $searchCondition
                    $shipperFilterCondition
                    $carrierFilterCondition
                    $startDateFilterCondition
                    $endDateFilterCondition
                ]
            }
        ) {
            bulk_bol
            claimed_at
            complete
            finishReturnsNextDay
            handle_orders_on_delete
            is_new_route
            needToOptimize
            oms
            pendingConfirmations
            planning
            test_acc
            margin
            route_number
            total_cubes
            total_shipper_rate
            carrier
            created_at
            createdBy
            deliverBy
            driver_id
            estimatedDrivingDistance
            estimatedDrivingTime
            geoTimestamps
            key
            lastModified
            lastModifiedAt
            lastModifiedBy
            lastModifiedSource
            lastUpdateSource
            legacy_carrier_id
            legacy_route_id
            legacy_shipper_id
            pickup_time
            routeStatus
            scheduledDelivery
            sendingTo
            sourceForm
            startTime
            status
            stops
            totalCarrRate
            completed
            carrier_id
            route_id
            shipper_id
            route_carrier {
                client_id
                business_name
                name
            }
            route_shipper {
                client_id
                business_name
            }
            stopsByRouteId {
                stop_id
                type
                returns
                exchanges
                ordering
                address
            }
        }
    }
`;
