import React from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton, TextField, InputAdornment } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import { PrimaryButton } from '@/styles/blocks';

import { Card, H1, Body1 } from './blocks';

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const FuelRates = ({ className, rates, errors, callbacks }) => {
    return (
        <Card className={className}>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 24px;
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <H1>Fuel Surcharge</H1>
            </Grid>
            {(rates?.['FUEL_PRICE_MODIFIER'] || []).map(({ min, max, rate }, idx) => {
                return (
                    <Grid
                        key={`rate-${idx}`}
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 16px;
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex: 0;
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            <Body1>{dollarFormatter.format(min)}</Body1>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex: 0;
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            <ArrowForwardIcon />
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            xs={3}
                            css={css`
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            {typeof max === 'string' && max.toLowerCase() === 'infinity' ? (
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    disabled
                                    value={max}
                                    error={!max || max <= min}
                                />
                            ) : (
                                <CurrencyTextField
                                    variant="outlined"
                                    size="small"
                                    value={max || 0}
                                    error={!max || max <= min}
                                    onChange={(_, val) => {
                                        callbacks.setFuelRate({
                                            bucket: idx,
                                            max: val,
                                        });
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">&</InputAdornment>,
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex: 0;
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            =
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            xs={3}
                            css={css`
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            <CurrencyTextField
                                variant="outlined"
                                size="small"
                                value={rate >= 0 ? rate * 100 : 0}
                                error={!(rate >= 0)}
                                onChange={(_, val) => {
                                    callbacks.setFuelRate({
                                        bucket: idx,
                                        rate: val / 100,
                                    });
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                        {rates?.['FUEL_PRICE_MODIFIER']?.length - 1 === idx ? (
                            <Grid
                                container
                                item
                                direction="column"
                                css={css`
                                    flex: 0;
                                    justify-content: center;
                                `}
                            >
                                <IconButton
                                    onClick={() => {
                                        callbacks.deleteFuelRate({ bucket: idx });
                                    }}
                                    css={css`
                                        padding: 4px;
                                    `}
                                >
                                    <DeleteForeverOutlined color="error" />
                                </IconButton>
                            </Grid>
                        ) : null}
                    </Grid>
                );
            })}
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 16px;
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <PrimaryButton onClick={callbacks.addFuelRate}>Add Row</PrimaryButton>
            </Grid>
        </Card>
    );
};

export default FuelRates;
