import { OnwardCard } from "./Card";
import styled from '@emotion/styled';
import { colors } from "@/styles";
import * as typography from '../../styles/typography';

export const OnwardExceptionCard = styled((props) => <OnwardCard {...props} />)`
    background-color: #F18D181F;
    box-shadow: none;
`

export const ExceptionHeader = styled.span`
    ${typography.sansSerif}
    font-weight: 700;
    font-size: 16px;
    line-height: 19.5px;
    color: ${colors.oranges.primary};
`

export const ExceptionReasonText = styled.span`
    ${typography.sansSerif}
    font-weight: 700;
    font-size: 16px;
    line-height: 19.5px;
    color: #2B2B2B;
`

export const ExceptionNotesText = styled.span`
${typography.sansSerif}
    font-weight: 500;
    font-size: 16px;
    line-height: 19.5px;
    color: #2B2B2B;
`

export const ExceptionDetailsText = styled.span`
${typography.sansSerif}
    font-weight: 500;
    font-size: 16px;
    line-height: 19.5px;
    color: ${colors.greys.primary};
`

export const CardItem = styled.span`
    font-weight: 500;
    font-size: 14px;
`;

export const CardTitle = styled.span`
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 0.75rem;
    color: ${colors.greys.secondary};
`;

export const CardSubtitle = styled.span`
    font-weight: 800;
    font-size: 14px;
`;

export const CardItemBold = styled((props) => <span {...props} />)`
    font-weight: 700;
    font-size: 14px;
`;
