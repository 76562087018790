import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Body1, Header1, Step, Tag } from './blocks';
import { css } from '@emotion/react';
import { formatInTimeZone } from 'date-fns-tz';

export default function Claimed({ isDesktop, inProgress, order, callbacks, tz }) {
    const isComplete = true;

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={isComplete}
            inProgress={inProgress}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Claimed</Header1>
                        </Grid>
                        <Grid direction="column">
                            <Tag>
                                <Body1>
                                    {order.assigned_at_date || order.claimed_date || order.created_at
                                        ? `Claimed: ${formatInTimeZone(
                                              new Date(
                                                  order.assigned_at_date || order.claimed_date || order.created_at
                                              ),
                                              tz,
                                              'EEE, MMM dd, yyyy, h:mm a zzz'
                                          )}`
                                        : '-'}
                                </Body1>
                            </Tag>
                        </Grid>
                    </Grid>
                );
            }}
        ></Step>
    );
}
