import styled from '@emotion/styled';
import { typography } from '@/styles';

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
`;

export const H1 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 28px;
`;

export const H2 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 16px;

    margin-bottom: 20px;
`;
