import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const sansSerif = css`
    font-family: Montserrat, Roboto, Arial, sans-serif;
`;

export const bold = css`
    font-weight: 700;
`;

export const PrimaryHeader = styled.h1`
    ${sansSerif}
    font-size: 28px !important;
    font-weight: 800 !important;
    color: #0c0b1d !important;
`;
