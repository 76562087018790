import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { typography, colors } from '@/styles';

export const H1 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 28px;
    line-height: 1.2;
`;
