import { gql } from '@apollo/client';
import { STOP_FIELDS } from '@/graphql/queries/stops';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ROUTE_FIELDS } from '@/graphql/queries/routes';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { RATING_FIELDS } from '@/graphql/queries/ratings';
import { ORDER_ROUTE_MAPPINGS } from '@/graphql/queries/order_route_mappings';

export const ROUTES_BY_CLIENT_ID = gql`
    ${ROUTE_FIELDS}
    ${STOP_FIELDS}
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${RATING_FIELDS}
    ${ORDER_ROUTE_MAPPINGS}
    query RoutesByClientID($cutoff_ts: timestamptz, $condition: [routes_bool_exp!], $allow_test: clients_bool_exp!) {
        results: routes(
            where: {
                _and: [
                    { _or: $condition }
                    { route_shipper: $allow_test }
                    {
                        _or: [
                            {
                                _and: [
                                    { status: { _in: ["pending", "inProgress", "active"] } }
                                    { scheduled_delivery: { _gt: $cutoff_ts } }
                                ]
                            }
                            { _and: [{ status: { _eq: "complete" } }, { completed: { _gt: $cutoff_ts } }] }
                        ]
                    }
                ]
            }
        ) {
            ...RouteFields
            orders {
                ...OrderRouteMappings
                order {
                    ...OrderFields
                    itemsByOrderId {
                        ...ItemFields
                    }
                    ratings {
                        ...RatingFields
                    }
                    returns @client {
                        ...ItemFields
                    }
                    items @client {
                        ...ItemFields
                    }
                }
            }
            stopsByRouteId(order_by: { ordering: asc }) {
                ...StopFields
            }
            teammateByDriverId {
                teammate_id
                username
                email
            }
        }
    }
`;

export const ORDERS_BY_CLIENT_ID = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${RATING_FIELDS}

    query OrdersByClientID(
        $cutoff_ts: timestamptz
        $allow_test: clients_bool_exp!
        $condition: [orders_bool_exp!]
        $user_id: uuid!
    ) {
        results: orders(
            where: {
                _and: [
                    { _or: $condition }
                    { order_shipper: $allow_test }
                    { delivery_date: { _gt: $cutoff_ts } }
                    {
                        order_status: {
                            _in: [
                                "claimed"
                                "active"
                                "routed"
                                "routedActive"
                                "inProgress"
                                "routedInProgress"
                                "complete"
                                "routedComplete"
                            ]
                        }
                    }
                    {
                        _or: [
                            { _not: { routes: {} } }
                            {
                                _and: [
                                    { shipper_id: { _eq: $user_id } }
                                    { routes: { route: { shipper_id: { _neq: $user_id } } } }
                                ]
                            }
                        ]
                    }
                ]
            }
        ) {
            ...OrderFields
            routes(order_by: { created_at: desc }) {
                mapping_id
                route_id
                order_id
                type
                created_at
                route {
                    route_id
                    route_number
                    driver_id
                }
            }
            itemsByOrderId {
                ...ItemFields
            }
            ratings {
                ...RatingFields
            }
            returns @client {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
        }
    }
`;

export const GET_LTL_ORDERS = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${ROUTE_FIELDS}
    ${RATING_FIELDS}
    query GetLtlOrders($start_ts: timestamptz, $end_ts: timestamptz) {
        results: orders(
            where: {
                _and: [
                    { delivery_date: { _is_null: false } }
                    { delivery_date: { _gt: $start_ts } }
                    { delivery_date: { _lt: $end_ts } }
                    { shipper_id: { _is_null: false } }
                    { carrier_id: { _is_null: false } }
                    { order_shipper: { test_acc: { _eq: false } } }
                    { order_carrier: { test_acc: { _eq: false } } }
                    { order_status: { _nin: ["deleted", "cancelled"] } }
                ]
            }
        ) {
            ...OrderFields
            route {
                ...RouteFields
            }
            itemsByOrderId {
                ...ItemFields
            }
            ratings {
                ...RatingFields
            }
            returns @client {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
        }
    }
`;
