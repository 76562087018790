import React from 'react';
import Loading from '../Loading';
import { Grid } from '@material-ui/core';

function Pricing() {
    return (
        <>
            <Grid item>
                <div>Please wait while we update your order</div>
            </Grid>
            <Grid item>
                <Loading />
            </Grid>
        </>
    );
}

export default Pricing;
