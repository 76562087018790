import React, { useMemo, useContext } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { genAttributes } from '@onward-delivery/core';
import { DROPOFF_TYPES } from '@/components/ShipmentForm/constants/dropoffOptions';
import { toNational, toE164 } from '@/utilities/formatPhoneNumber';
import { Header1, Body1, Tag, Step, SubStepDesktop, SubStepMobile, Anchor, Header2 } from './blocks';
import { UserContext } from '@/components/App';
import { useClientUser } from '@/hooks';
import { formatInTimeZone } from 'date-fns-tz';

export default function Complete({ isDesktop, isLoading, warehouse, before, after, order, callbacks, tz }) {
    const { full_address, location_type, location } = genAttributes(order);
    const { superUser } = useContext(UserContext);
    const { user_id } = useClientUser();

    const STEPS = useMemo(() => {
        return [
            ...(isDesktop
                ? [
                      {
                          description: `1. Upload proof of delivery documents`,
                          cta: 'Upload POD',
                          secondaryCta: 'Add Exception',
                          complete: ({ order, before, after }) => {
                              return order.pod && order.pod !== '';
                          },
                          callback: () => {
                              callbacks.gotoFileTab();
                          },
                          secondaryCallback: ({ order, before, after }) => {
                              callbacks.createException({
                                  type: 'DROPOFF',
                                  action: 'CD_COMPLETE:ATTEMPT',
                              });
                          },
                      },
                  ]
                : []),
            {
                description: isDesktop ? (
                    `2. Mark order as 'Delivered'`
                ) : (
                    <>
                        <Grid direction="row">
                            <Body1>{order.dropoff_name}</Body1>
                        </Grid>
                        <Grid direction="row">
                            {order.dropoff_phone ? (
                                <Anchor href={`tel:${toE164(order.dropoff_phone)}`}>
                                    {toNational(order.dropoff_phone)}
                                </Anchor>
                            ) : (
                                <Body1>--</Body1>
                            )}
                        </Grid>
                        <Grid direction="row">
                            <Body1>{order[full_address]}</Body1>
                        </Grid>
                        <Grid direction="row">
                            <Body1>{`${order[location]}, ${
                                DROPOFF_TYPES[order[location]]
                                    ?.find((o) => o.value === order[location_type])
                                    ?.label() || '--'
                            }`}</Body1>
                        </Grid>
                    </>
                ),
                cta: isDesktop ? 'Mark Delivered' : 'Arrived',
                secondaryCta: 'Add Exception',
                complete: ({ order, before, after }) => {
                    return !!after && order.order_status === 'complete';
                },
                secondaryCallback: ({ order, before, after }) => {
                    callbacks.createException({
                        type: 'DROPOFF',
                        action:
                            before?.transition?.current === 'CD_PENDING'
                                ? 'CD_COMPLETE:CD_ATTEMPT'
                                : 'CD_COMPLETE:ATTEMPT',
                    });
                },
                callback: ({ order, before, after }) => {
                    const NOW = new Date();
                    const isAdmin = !!superUser?.user_id;
                    const prompt = (
                        <>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                <Body1>Are you sure you want to mark the order as 'Delivered'?</Body1>
                            </Grid>
                            <Grid direction="row" container>
                                <Body1>
                                    This step will close out the order and let the customer and shipper know the
                                    delivery has been completed.
                                </Body1>
                            </Grid>
                        </>
                    );
                    const callback = (orderUpdates = {}) =>
                        callbacks.upsertEvent({
                            event: {
                                ...(after?.event_id
                                    ? { event_id: after.event_id, action: after.action }
                                    : { action: 'CD_COMPLETE:SUCCESS' }),
                                order_id: order.order_id,
                                delivery_date: orderUpdates?.ltl_dropoff_arrival || NOW.toISOString(),
                            },
                            orderId: order.order_id,
                            order: {
                                order_status: 'complete',
                                ltl_dropoff_arrival: NOW.toISOString(),
                                completion_time: NOW.toISOString(),
                                completion_source: isAdmin
                                    ? 'WEB_ONWARD_ADMIN'
                                    : user_id === order.shipper_id
                                    ? `WEB_SHIPPER${isDesktop ? '' : '_LITE'}`
                                    : `WEB_CARRIER${isDesktop ? '' : '_LITE'}`,
                                ...orderUpdates,
                            },
                        });

                    callbacks.openChecklist(
                        {
                            content: prompt,
                            documents: [
                                { field: 'pod', name: 'Proof of Delivery', required: true },
                                { field: 'delivery_photo', name: 'Final Delivery Photos', required: false },
                            ],
                            signatures: [{ field: 'customer_signature', name: 'Customer Signature', required: false }],
                            timestamps: [
                                { field: 'ltl_dropoff_arrival', name: 'Arrival', required: true },
                                { field: 'completion_time', name: 'Completion', required: true },
                            ],
                            title: 'Deliver Order',
                            itemsHelperText: 'Checkmark each item after unloading.',
                        },
                        callback
                    );
                },
            },
        ];
    }, [isDesktop, full_address, location_type, location, order]);

    const isComplete = useMemo(() => {
        return STEPS.map((step) => {
            return step.complete({ order, before, after });
        });
    }, [before, after, order]);

    const inProgressIdx = useMemo(() => {
        return isComplete.findIndex((x) => !x);
    }, [isComplete]);

    const allComplete = isComplete.every((x) => x);
    const inProgress = !allComplete && order.order_status === 'inProgress';

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={allComplete}
            inProgress={inProgress}
            isLast={true}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Delivered</Header1>
                        </Grid>
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            {!!after ? (
                                <Tag>
                                    <Body1
                                        css={css`
                                            margin-right: 8px;
                                        `}
                                    >
                                        {`Arrived: ${
                                            order.ltl_dropoff_arrival
                                                ? formatInTimeZone(
                                                      order.ltl_dropoff_arrival,
                                                      tz,
                                                      'EEE, MMM dd, yyyy h:mm a zzz'
                                                  )
                                                : 'Unknown'
                                        }`}
                                    </Body1>
                                    <Body1
                                        css={css`
                                            margin-right: 20px;
                                        `}
                                    >
                                        {`Completed: ${
                                            order.completion_time
                                                ? formatInTimeZone(
                                                      order.completion_time,
                                                      tz,
                                                      'EEE, MMM dd, yyyy h:mm a zzz'
                                                  )
                                                : 'Unknown'
                                        }`}
                                    </Body1>
                                    <Anchor
                                        onClick={() => {
                                            const NOW = new Date();
                                            const isAdmin = !!superUser?.user_id;
                                            const prompt = (
                                                <>
                                                    <Grid
                                                        direction="row"
                                                        container
                                                        css={css`
                                                            margin-bottom: 12px;
                                                        `}
                                                    >
                                                        <Body1>
                                                            Are you sure you want to mark the order as 'Delivered'?
                                                        </Body1>
                                                    </Grid>
                                                    <Grid direction="row" container>
                                                        <Body1>
                                                            This step will close out the order and let the customer and
                                                            shipper know the delivery has been completed.
                                                        </Body1>
                                                    </Grid>
                                                </>
                                            );
                                            const callback = (orderUpdates = {}) =>
                                                callbacks.upsertEvent({
                                                    event: {
                                                        ...(after?.event_id
                                                            ? { event_id: after.event_id, action: after.action }
                                                            : { action: 'CD_COMPLETE:SUCCESS' }),
                                                        order_id: order.order_id,
                                                        delivery_date:
                                                            orderUpdates?.ltl_dropoff_arrival || NOW.toISOString(),
                                                    },
                                                    orderId: order.order_id,
                                                    order: {
                                                        order_status: 'complete',
                                                        ltl_dropoff_arrival: NOW.toISOString(),
                                                        completion_time: NOW.toISOString(),
                                                        completion_source: isAdmin
                                                            ? 'WEB_ONWARD_ADMIN'
                                                            : user_id === order.shipper_id
                                                            ? `WEB_SHIPPER${isDesktop ? '' : '_LITE'}`
                                                            : `WEB_CARRIER${isDesktop ? '' : '_LITE'}`,
                                                        ...orderUpdates,
                                                    },
                                                });

                                            callbacks.openChecklist(
                                                {
                                                    content: prompt,
                                                    event: after,
                                                    documents: [
                                                        {
                                                            field: 'pod',
                                                            name: 'Proof of Delivery',
                                                            required: true,
                                                        },
                                                        {
                                                            field: 'delivery_photo',
                                                            name: 'Final Delivery Photos',
                                                            required: false,
                                                        },
                                                    ],
                                                    signatures: [
                                                        {
                                                            field: 'customer_signature',
                                                            name: 'Customer Signature',
                                                            required: false,
                                                        },
                                                    ],
                                                    timestamps: [
                                                        {
                                                            field: 'ltl_dropoff_arrival',
                                                            name: 'Arrival',
                                                            required: true,
                                                        },
                                                        {
                                                            field: 'completion_time',
                                                            name: 'Completion',
                                                            required: true,
                                                        },
                                                    ],
                                                    title: 'Deliver Order',
                                                    itemsHelperText: 'Checkmark each item after unloading.',
                                                },
                                                callback
                                            );
                                        }}
                                    >
                                        <Header1>Edit</Header1>
                                    </Anchor>
                                </Tag>
                            ) : (
                                <Tag>
                                    <Body1>{`Scheduled: ${
                                        before?.est_delivery_date ||
                                        (order.order_type === 'return' ? order.pickup_date : order.delivery_date)
                                            ? formatInTimeZone(
                                                  before?.est_delivery_date ||
                                                      (order.order_type === 'return'
                                                          ? order.pickup_date
                                                          : order.delivery_date),
                                                  tz,
                                                  'EEE, MMM dd yyyy'
                                              )
                                            : 'Pending'
                                    }`}</Body1>
                                </Tag>
                            )}
                        </Grid>
                    </Grid>
                );
            }}
        >
            {() => (
                <>
                    {STEPS.map(({ description, cta, secondaryCta, callback, secondaryCallback }, idx) => {
                        const SubStep = isDesktop ? SubStepDesktop : SubStepMobile;

                        return (
                            <SubStep
                                isComplete={isComplete[idx]}
                                inProgress={inProgressIdx === idx}
                                isLoading={isLoading}
                                isLast={idx === STEPS.length - 1}
                                description={description}
                                cta={cta}
                                secondaryCta={secondaryCta}
                                secondaryOrange={true}
                                callback={
                                    callback
                                        ? () => {
                                              callback({ before, after, order });
                                          }
                                        : null
                                }
                                secondaryCallback={
                                    secondaryCallback
                                        ? () => {
                                              secondaryCallback({ before, after, order });
                                          }
                                        : null
                                }
                            />
                        );
                    })}
                </>
            )}
        </Step>
    );
}
