import { useMemo } from 'react';

export const useTotalRevenue = (orders) => {
    if (Array.isArray(orders)) {
        return useMemo(() => {
            if (!orders) return;
            let totalRevenue = orders.reduce((total, o) => {
                return total + o.order_revenue;
            }, 0);
            return totalRevenue?.toFixed(2);
        }, [orders]);
    } else {
        return useMemo(() => {
            if (!orders) return;

            return orders?.order_revenue ? orders?.order_revenue?.toFixed(2) : null;
        }, [orders]);
    }
};
