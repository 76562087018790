export const TYPES = {
    SCHEDULE: 'SCHEDULE',
    AVAILABILITY: 'AVAILABILITY',
    DEFAULT: 'DEFAULT',
};

export const STAGES = {
    SCHEDULE_SUCCESS: 'SCHEDULE_SUCCESS',
    AVAILABILITY_SUCCESS: 'AVAILABILITY_SUCCESS',
    SCHEDULE_FAILED: 'SCHEDULE_FAILED',
    CONTACT: 'CONTACT',
};
