import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import * as ROLES from '../../../constants/roles';
import './styles.css';
import Profile from './Profile';
import Password from '../Password';
import Stores from './Stores';
import Billing from './Billing';
import PartnerCarriers from './PartnerCarriers';
import Teammates from '../Teammates';
import TariffTable from '../TariffTable';
import { Rating } from '@material-ui/lab';
import api from '@/utilities/api';
import { UserContext } from '@/components/App';
import { LIST_TARIFFS_BY_CLIENT_ID, UPSERT_PRICING_OVERRIDES } from '../Tariffs/graphql';
import { AGGREGATE_RATING_BY_REVIEWEE } from '@/graphql/queries/ratings';
import { QUERY_BY_PK_WITH_USER } from '@/graphql/queries/clients';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { Tooltip } from '@material-ui/core';
import Trucks from '../Trucks';
import ShippingPartners from '../ShippingPartners';
import { useClientUser } from '@/hooks';
import { BROKER } from '@/constants/featureFlags';

function ShipperAccountPage(props) {
    const [userInfo, setUserInfo] = useState({});
    const { user, sidebarCollapsed, superUser } = useContext(UserContext);
    const { circles } = useClientUser();

    const [fetchTariffs, { data }] = useLazyQuery(LIST_TARIFFS_BY_CLIENT_ID, {
        variables: {
            client_id: user.user_id,
        },
    });

    const [fetchOverallRating, { data: ratings }] = useLazyQuery(AGGREGATE_RATING_BY_REVIEWEE);

    const {
        loading: userLoading,
        error: userError,
        data: userData,
    } = useQuery(QUERY_BY_PK_WITH_USER, {
        variables: { client_id: user.user_id },
        onError: (error) => {
            console.log(error);
        },
    });

    const [upsertPo] = useMutation(UPSERT_PRICING_OVERRIDES, {
        update: (cache, { data: { updated } }) => {
            cache.updateQuery(
                {
                    query: LIST_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: user.user_id,
                    },
                },
                (data) => {
                    let { tariffs, overrides } = data;
                    let clone = [...overrides];
                    const [update] = updated;

                    const idx = clone.findIndex((po) => po.pricing_override_id === update.pricing_override_id);
                    if (idx >= 0) {
                        clone[idx] = update;
                    } else {
                        clone = [...clone, update];
                    }

                    return {
                        tariffs,
                        overrides: clone,
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
        },
    });

    const currentShipper = useMemo(() => {
        if (userData) {
            return userData?.clients_by_pk;
        }

        return {};
    }, [userData]);

    useEffect(() => {
        if (user?.user_id) {
            fetchOverallRating({ variables: { reviewee_id: user.user_id } });
            fetchTariffs();
        }
    }, [user]);

    const [tariffs, overrides] = useMemo(() => {
        if (data) {
            const { tariffs, overrides } = data;
            return [tariffs, overrides];
        }

        return [[], []];
    }, [data]);

    const rating = useMemo(() => {
        if (ratings?.results?.aggregate?.avg?.rating) {
            return ratings?.results?.aggregate?.avg?.rating;
        }

        return 0;
    }, [ratings]);

    return (
        <div style={{ margin: '0 80px', paddingBottom: '30px' }}>
            <div className="d-flex justify-content-between my-3">
                <h2 className="  accountwidth" style={{ fontWeight: '700' }}>
                    My Account
                </h2>
                {rating >= 3 && (
                    <div className="align-self-center d-flex">
                        <h5 className="text-nowrap">My Rating: </h5>
                        <Tooltip
                            title={rating.toFixed(1) + ' / 5'}
                            arrow
                            PopperProps={{ style: { marginTop: '-10px' } }}
                        >
                            <div>
                                <Rating readOnly value={rating} precision={0.1} />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
            <Tabs defaultActiveKey="Profile">
                <Tab tabClassName="tabLink" eventKey="Profile" title="Profile">
                    <Profile
                        currentShipperRef={user.user_id}
                        currentShipper={currentShipper}
                        adminLoggedIn={!!Object.keys(superUser).length}
                        device_settings={user?.device_settings}
                    />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Stores" title="Stores">
                    <Stores currentClientRef={user.user_id} currentClient={currentShipper} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Trucks" title="Trucks">
                    <Trucks client={currentShipper} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Billing" title="Billing">
                    <Billing currentShipper={currentShipper} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Password" title="Password">
                    <Password />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Teammates" title="Teammates">
                    <Teammates
                        currentUser={{
                            business: currentShipper.business_name,
                            email: currentShipper.email,
                        }}
                        teamLeaderType="shipper"
                        userId={user.user_id}
                    />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Tariffs" title="Tariffs">
                    <TariffTable
                        client={currentShipper}
                        tariffs={tariffs}
                        overrides={overrides}
                        callbacks={{ upsertPo }}
                    />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Partner Carriers" title="Partner Carriers">
                    <PartnerCarriers />
                </Tab>
            </Tabs>
        </div>
    );
}

export default ShipperAccountPage;
