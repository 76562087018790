import { Box, Button, Chip, Grid, MenuItem, Popover, TextField, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React, { useState } from 'react';
import { useClientUser } from '@/hooks';
import { css } from '@emotion/react';

const volumeOptions = [
    {
        value: '0|100',
        label: '0 - 100',
    },
    {
        value: '100|200',
        label: '100 - 200',
    },
    {
        value: '200|300',
        label: '200 - 300',
    },
    {
        value: '300|400',
        label: '300 - 400',
    },
    {
        value: '400|500',
        label: '400 - 500',
    },
    {
        value: '500|9999999',
        label: '500 +',
    },
];

const distanceOptions = [
    {
        value: '0|5',
        label: '0 - 5',
    },
    {
        value: '5|10',
        label: '5 - 10',
    },
    {
        value: '10|25',
        label: '10 - 25',
    },
    {
        value: '25|50',
        label: '25 - 50',
    },
    {
        value: '50|100',
        label: '50 - 100',
    },
    {
        value: '100|9999999',
        label: '100 +',
    },
];

const scheduledOptions = [
    {
        value: 'All',
        label: 'All',
    },
    {
        value: 'Scheduled',
        label: 'Scheduled',
    },
    {
        value: 'Unscheduled',
        label: 'Unscheduled',
    },
];

const middleMileOptions = [
    { value: 'ALL', label: 'All' },
    { value: 'NONE', label: 'No Status / Status Unknown' },
    { value: 'PENDING', label: 'Pending' },
    { value: 'SCHEDULED', label: 'Scheduled' },
    { value: 'IN_TRANSIT', label: 'In Transit' },
    { value: 'ARRIVED', label: 'Arrived' },
    { value: 'CANCELLED', label: 'Cancelled' },
];

const __TEST_PICKUPS__ = [
    {
        value: 'Aurora|CO|80017',
        label: 'Aurora, CO 80017',
    },
    {
        value: 'Brighton|CO|80603',
        label: 'Brighton, CO 80603',
    },
    {
        value: 'Golden|CO|80401',
        label: 'Golden, CO 80401',
    },
];

const wh_statuses = [
    { value: 'RECEIVED', label: 'Received' },
    { value: 'NOT_DELIVERED', label: 'Not Received' },
];

const order_types = [
    { value: 'delivery', label: 'Delivery' },
    { value: 'return', label: 'Return' },
    { value: 'exchange', label: 'Exchange' },
];

const FilterPopover = ({
    applyFilters,
    email,
    userType,
    locations,
    shippers,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setWarehouseEndDate,
    setWarehouseStartDate,
    warehouseEndDate,
    warehouseStartDate,
    schedDelStart,
    setSchedDelStart,
    schedDelEnd,
    setSchedDelEnd,
    estShipStart,
    setEstShipStart,
    estShipEnd,
    setEstShipEnd,
    actualReceivedStart,
    setActualReceivedStart,
    actualReceivedEnd,
    setActualReceivedEnd,
    warehouseStatus,
    setWarehouseStatus,
    manufacturer,
    setManufacturer,
    manufacturersList,
    orderType,
    setOrderType,
    user_id,
}) => {
    const [anchor, setAnchor] = useState(null);
    const [ownerChip, setOwnerChip] = useState('All');
    const [pickup, setPickup] = useState('');
    const [dropoff, setDropoff] = useState('');
    const [volume, setVolume] = useState('');
    const [distance, setDistance] = useState('');
    const [scheduledStatus, setScheduledStatus] = useState('All');
    const [shipper, setShipper] = useState('All');
    const [middleMileStatus, setMiddleMileStatus] = useState('ALL');
    const { circles } = useClientUser();

    const open = !!anchor;
    const id = open ? 'myorders-popover-filter' : undefined;

    const handleClearFilters = () => {
        setOwnerChip('All');
        setStartDate(null);
        setEndDate(null);
        setWarehouseStartDate(null);
        setWarehouseEndDate(null);
        setSchedDelStart(null);
        setSchedDelEnd(null);
        setEstShipStart(null);
        setEstShipStart(null);
        setActualReceivedStart(null);
        setActualReceivedEnd(null);
        setPickup('');
        setDropoff('');
        setVolume('');
        setDistance('');
        setScheduledStatus('All');
        setShipper('All');
        setWarehouseStatus(null);
        setManufacturer(null);
        setOrderType(null);

        applyFilters([]);
    };

    const handleApplyFilters = () => {
        let filters = [];

        if (ownerChip !== 'All') {
            switch (ownerChip) {
                case 'Internal':
                    // INTERNAL ORDERS
                    filters.push({
                        _and: [
                            { oms: { _eq: true } },
                            { shipper_id: { _eq: user_id } },
                            { _or: [{ carrier_id: { _is_null: true } }, { carrier_id: { _eq: user_id } }] },
                        ],
                    });
                    break;
                case 'Onward':
                    // ORDERS SENT TO MARKETPLACE
                    filters.push({
                        _or: [
                            {
                                _and: [
                                    { oms: { _eq: false } },
                                    { shipper_id: { _eq: user_id } },
                                    { _or: [{ carrier_id: { _is_null: true } }, { carrier_id: { _neq: user_id } }] },
                                ],
                            },
                            {
                                _and: [
                                    { shipper_id: { _eq: user_id } },
                                    { carrier_id: { _is_null: false } },
                                    { carrier_id: { _neq: user_id } },
                                ],
                            },
                        ],
                    });
                    break;
                case 'Claimed':
                    //ORDERS CLAIMED FROM MARKETPLACE
                    filters.push({
                        _or: [
                            { _and: [{ oms: { _eq: false } }, { carrier_id: { _eq: user_id } }] },
                            { _and: [{ shipper_id: { _neq: user_id } }, { carrier_id: { _eq: user_id } }] },
                        ],
                    });
                    break;
            }
        }
        if (shipper !== 'All') {
            filters.push({ shipper_id: { _eq: shipper } });
        }

        if (pickup) {
            const [city, state, zip] = pickup.split('|');
            filters.push(
                { pickup_city: { _eq: city } },
                { pickup_state: { _eq: state } },
                { pickup_zip: { _eq: zip } }
            );
        }

        if (dropoff) {
            const [city, state, zip] = dropoff.split('|');
            filters.push(
                { dropoff_city: { _eq: city } },
                { dropoff_state: { _eq: state } },
                { dropoff_zip: { _eq: zip } }
            );
        }

        if (volume) {
            const [minVolume, maxVolume] = volume.split('|');
            filters.push({
                _or: [
                    {
                        _and: [
                            { order_type: { _eq: 'return' } },
                            { returns_total_cubes: { _lte: parseInt(maxVolume) } },
                            { returns_total_cubes: { _gte: parseInt(minVolume) } },
                        ],
                    },
                    {
                        _and: [
                            { order_type: { _eq: 'delivery' } },
                            { items_total_cubes: { _lte: parseInt(maxVolume) } },
                            { items_total_cubes: { _gte: parseInt(minVolume) } },
                        ],
                    },
                    {
                        _and: [
                            { order_type: { _eq: 'exchange' } },
                            {
                                _or: [
                                    {
                                        _and: [
                                            { returns_total_cubes: { _lte: parseInt(maxVolume) } },
                                            { returns_total_cubes: { _gte: parseInt(minVolume) } },
                                        ],
                                    },
                                    {
                                        _and: [
                                            { items_total_cubes: { _lte: parseInt(maxVolume) } },
                                            { items_total_cubes: { _gte: parseInt(minVolume) } },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            });
        }

        if (distance) {
            const [minDistance, maxDistance] = distance.split('|');
            filters.push({ miles: { _lte: parseInt(maxDistance) } }, { miles: { _gte: parseInt(minDistance) } });
        }

        if (scheduledStatus !== 'All') {
            filters.push({ delivery_date: { _is_null: scheduledStatus === 'Unscheduled' } });
        }

        if (middleMileStatus !== 'ALL') {
            filters.push({
                middle_mile_integration_status:
                    middleMileStatus === 'NONE' ? { _is_null: true } : { _eq: middleMileStatus },
            });
        }

        applyFilters(filters);
    };

    const OwnerChip = ({ owner }) => {
        return (
            <Grid item className="pl-3">
                <Chip
                    label={owner}
                    color="primary"
                    variant={ownerChip === owner ? 'default' : 'outlined'}
                    onClick={(e) => setOwnerChip(e.target.innerText)}
                />
            </Grid>
        );
    };

    const PopoverSelect = ({ label, value, onChange, options = [] }) => {
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="h3">{label}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        style={{ width: '300px', maxHeight: '50px' }}
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </>
        );
    };

    return (
        <>
            <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={(e) => setAnchor(e.currentTarget)}
                css={css`
                    height: 100%;
                `}
            >
                <FilterList />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box className="bg-white p-3" style={{ border: 'solid 1px #4C4C4C', borderRadius: '4px' }}>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid container className="h100" alignContent="center" style={{ width: 'auto' }}>
                            <Typography variant="h3">Owner</Typography>
                        </Grid>
                        <OwnerChip owner="All" />
                        <OwnerChip owner="Onward" />
                        <OwnerChip owner="Internal" />
                        <OwnerChip owner="Claimed" />
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Order Creation Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Scheduled Delivery Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={schedDelStart}
                                onChange={(e) => setSchedDelStart(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={schedDelEnd}
                                onChange={(e) => setSchedDelEnd(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Estimated Shipment Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={estShipStart}
                                onChange={(e) => setEstShipStart(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={estShipEnd}
                                onChange={(e) => setEstShipEnd(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Estimated Warehouse Received Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={warehouseStartDate}
                                onChange={(e) => setWarehouseStartDate(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={warehouseEndDate}
                                onChange={(e) => setWarehouseEndDate(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Actual Received Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={actualReceivedStart}
                                onChange={(e) => setActualReceivedStart(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={actualReceivedEnd}
                                onChange={(e) => setActualReceivedEnd(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Warehouse Status"
                                value={warehouseStatus}
                                onChange={(e) => setWarehouseStatus(e.target.value)}
                                options={wh_statuses || []}
                            />
                        </Grid>
                    </Grid>

                    {userType === 'carrier' || circles?.['broker'] ? (
                        <Grid container xs={12} className="pb-3" wrap="nowrap">
                            <Grid item className="me-3">
                                <PopoverSelect
                                    label="Shipper"
                                    value={shipper}
                                    onChange={(e) => setShipper(e.target.value)}
                                    options={shippers || []}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Manufacturer"
                                value={manufacturer}
                                onChange={(e) => setManufacturer(e.target.value)}
                                options={manufacturersList || []}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Order Type"
                                value={orderType}
                                onChange={(e) => setOrderType(e.target.value)}
                                options={order_types || []}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Scheduled Delivery Status"
                                value={scheduledStatus}
                                onChange={(e) => setScheduledStatus(e.target.value)}
                                options={scheduledOptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Pick up"
                                value={pickup}
                                onChange={(e) => setPickup(e.target.value)}
                                options={locations.pickupKeys || []}
                            />
                        </Grid>
                        <Grid item>
                            <PopoverSelect
                                label="Drop off"
                                value={dropoff}
                                onChange={(e) => setDropoff(e.target.value)}
                                options={locations.dropoffKeys || []}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Cubic Feet"
                                value={volume}
                                onChange={(e) => setVolume(e.target.value)}
                                options={volumeOptions}
                            />
                        </Grid>
                        <Grid item>
                            <PopoverSelect
                                label="Miles"
                                value={distance}
                                onChange={(e) => setDistance(e.target.value)}
                                options={distanceOptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Middle Mile Status"
                                value={middleMileStatus}
                                onChange={(e) => setMiddleMileStatus(e.target.value)}
                                options={middleMileOptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                            <Button onClick={handleClearFilters}>Clear</Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" onClick={handleApplyFilters}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </>
    );
};

export default FilterPopover;
