import React, { useEffect } from 'react';

export const useItemDynamicFields = (item, modifyItem) => {
    //total_cubes
    useEffect(() => {
        if (((item.length && item.width && item.height) || item.cubes_per_unit) && item.quantity) {
            modifyItem({
                total_cubes:
                    item.quantity *
                    (item.cubes_per_unit
                        ? item.cubes_per_unit
                        : (item.length * item.width * item.height) / (12 * 12 * 12)),
            });
        }
    }, [item.length, item.width, item.height, item.quantity, item.cubes_per_unit]);

    //total_weight
    useEffect(() => {
        if (item.weight && item.quantity) {
            modifyItem({ total_weight: item.quantity * item.weight });
        }
    }, [item.weight, item.quantity]);
};
