import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import * as ROUTES from '@/constants/routes';
import { colors } from '@/styles';
import { Grid } from '@material-ui/core';
import { RootContainer, Card, Header1 } from './blocks';

const SuccessPage = () => {
    return (
        <RootContainer>
            <Grid container direction="row">
                <Card
                    css={css`
                        height: fit-content;
                        padding: 32px;
                    `}
                >
                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 40px;
                        `}
                    >
                        <div className="carrierSignedUp" />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            justify-content: center;
                            margin-bottom: 28px;
                        `}
                    >
                        <Header1>Congrats! You now have a carrier account!</Header1>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            justify-content: center;
                            margin-bottom: 28px;
                        `}
                    >
                        <Link
                            to={ROUTES.SIGNIN}
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            <Header1>Login to your account</Header1>
                        </Link>
                    </Grid>
                </Card>
            </Grid>
        </RootContainer>
    );
};

export default SuccessPage;
