/* eslint-disable no-sparse-arrays */
import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';
import { colors } from '@/styles';

import { Body2, Tag } from './blocks';
import { useFormatters } from './hooks';

function Day({ day, resources, callbacks }) {
    return (
        <Grid
            container
            direction="column"
            css={css`
                cursor: pointer;
                flex: 1;
                flex-basis: 0;
                padding: 4px 0;
                border-right: ${day.dow === 6 ? 0 : 1}px solid ${colors.greys[4]};
            `}
            onClick={() => {
                callbacks.editResource([, , day]);
            }}
        >
            <Grid
                direction="row"
                container
                css={css`
                    justify-content: center;
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <Grid
                    container
                    css={css`
                        width: 24px;
                        height: 24px;
                        border-radius: 999px;
                        background: ${day.isToday && day.thisMonth && day.thisYear ? '#007bff' : 'transparent'};
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <Body2
                        css={css`
                            color: ${day.isToday && day.thisMonth && day.thisYear
                                ? colors.white.primary
                                : day.isCurrentMonth
                                ? colors.greys[3]
                                : colors.greys[4]};
                        `}
                    >
                        {day.date}
                    </Body2>
                </Grid>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    overflow: scroll;
                    flex: 1;
                    flex-basis: 0;
                `}
            >
                <Grid container direction="column" css={css``}>
                    {resources.map(([resource, rule], idx) => {
                        return (
                            <Tag
                                key={resource.resource_id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    callbacks.editResource([resource, rule, day]);
                                }}
                            >
                                <Body2>{resource.name}</Body2>
                            </Tag>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Day;
