import { post } from '@/utilities/onwardClient';
import { RESEND_CUSTOMER_PREDELIVERY_SMS } from '@/constants/apiRoutes';

export const sendPredelivery = async ({ order }) => {
    return post(RESEND_CUSTOMER_PREDELIVERY_SMS, {
        order_id: order.order_id,
        dropoff_name: order.dropoff_name,
        shipper_id: order.shipper_id,
    });
};
