import React, { useMemo } from 'react';
import Select from './RawInputs/Select';
import { convertToISO } from '../../utilities/convertToISO';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';

const routeTimeZoneFormatter = (timeZone) =>
    new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone,
        timeZoneName: 'short',
    });

const localFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

function StartTimeSelect(props) {
    const {
        onChange,
        inputStyles,
        value,
        containerStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#212529',
            margin: '1rem 0',
        },
        menuItemStyles = {
            fontWeight: '400',
        },
        timeZone,
        deliveryDate,
        disabled,
    } = props;

    const menuItems = useMemo(() => {
        const utcDate = new Date(convertToISO(deliveryDate));
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const browserLocalDate = new Date(utcDate.getTime() - getTimezoneOffset(localTimeZone, utcDate));
        const items = [];

        for (let hours = 4; hours < 18; hours++) {
            [0, 30].forEach((minutes) => {
                browserLocalDate.setHours(hours);
                browserLocalDate.setMinutes(minutes);
                if (timeZone && localTimeZone !== timeZone) {
                    const routeLocalDate = new Date(
                        browserLocalDate.getTime() +
                            getTimezoneOffset(localTimeZone, utcDate) -
                            getTimezoneOffset(timeZone, utcDate)
                    );

                    items.push({
                        key: routeLocalDate.getTime(),
                        value: `${localFormatter.format(routeLocalDate)} (${routeTimeZoneFormatter(timeZone).format(
                            routeLocalDate
                        )})`,
                    });
                } else {
                    items.push({
                        key: browserLocalDate.getTime(),
                        value: `${localFormatter.format(browserLocalDate)}`,
                    });
                }
            });
        }

        return items;
    }, [timeZone, deliveryDate]);

    return (
        <Select
            defaultLabel="select arrival time..."
            defaultMenuItem={{
                label: 'select arrival time ...',
                value: 0,
            }}
            menuItems={menuItems}
            onChange={onChange}
            inputStyles={inputStyles}
            containerStyles={containerStyles}
            menuItemStyles={menuItemStyles}
            value={value}
            defaultValue=""
            disabled={disabled}
        />
    );
}

export default StartTimeSelect;
