import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TableCell, Button, Checkbox } from '@material-ui/core';
import { typography, colors } from '@/styles';

export const StickyCell = styled(({ children, ...props }) => <TableCell {...props}>{children}</TableCell>)`
    &[data-sticky-td] {
        background: ${colors.white.primary};
        position: sticky;
        display: flex;
        align-items: center;
        height: 57px;
    }

    &[data-sticky-last-left-td] {
        border-left: 2px 0px 3px #ccc;
    }

    &[data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
    }
`;

export const HeaderText = styled.span`
    ${typography.sansSerif}
    font-weight: bold;
    font-size: 14px;
    text-align: left;
`;

export const BodyAnchor = styled.span`
    ${typography.sansSerif}
    color: ${colors.greens.primary};
    font-weight: 600;
    font-size: 14px;
`;

export const BodyText = styled.span`
    ${typography.sansSerif}
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
`;

export const PrimaryHeaderContainer = styled.span`
    margin: 40px 3rem;
`;

export const PrimaryHeaderLabel = styled.span`
    font-family: Montserrat, Roboto, Arial;
    font-weight: 800;
    font-size: 28px;
    line-height: 1.2;
    color: #0c0b1d;
`;

export const Subtitle1 = styled.span`
    ${typography.sansSerif}
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
`;

export const FilterHeader = styled.span`
    ${typography.sansSerif}
    font-weight: 600;
    font-size: 14px;
`;

export const fragments = {
    cellIcon: css`
        width: 16px;
        height: 16px;
    `,
};

export const SelectorButton = styled(({ children, ...props }) => <Button {...props}>{children}</Button>)`
    border-radius: 4;
    background: ${(props) => (props.active ? '#59b863' : '#f0f0f0')};
    color: ${(props) => (props.active ? '#fff' : '#2b2b2b')};
    font-weight: 500;
    border: ${(props) => (props.active ? '#1px solid #a6a6a6' : '1px solid gray')};
    text-transform: none;
    margin-right: 5px;

    &:focus {
        outline: none;
    }

    &:hover {
        background: ${(props) => (props.active ? '#59b863' : '#f0f0f0')};
    }
`;

const CustomIcon = styled.span`
    border-radius: 3px;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));

    .Mui-focusVisible & {
        outline: 2px auto rgba(19, 124, 189, 0.6);
        outline-offset: 2;
    }

    input:hover ~ & {
        background-color: #ebf1f5;
    }

    input:disabled ~ & {
        box-shadow: none;
        background: rgba(206, 217, 224, 0.5);
    }
`;

const CustomCheckedIcon = styled(({ children, ...rest }) => <CustomIcon {...rest}>{children}</CustomIcon>)`
    background-color: #59b863;
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));

    &:before {
        display: block;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
        content: '';
    }

    input:hover ~ & {
        backgroundcolor: #59b863;
    }
`;

export const CustomCheckbox = (props) => (
    <Checkbox
        sx={{
            '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color="default"
        checkedIcon={<CustomCheckedIcon />}
        // icon={<CustomIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
    />
);
