import NavResponsiveModal, { ModalContent, ModalHeader } from '@/components/Navigation/NavResponsiveModal';
import { Grid, Typography } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { css } from '@emotion/react';
import { formatInTimeZone } from 'date-fns-tz';

const SkuHistoryModal = ({ state, callbacks }) => {
    const { selectedSku } = state;

    const simplifiedLogs = useMemo(() => {
        if (!selectedSku) return [];

        let runningQtyIn = 0;
        let runningQtyOut = 0;

        return selectedSku.palletLogs
            .filter((log) => ['RECEIVED', 'STAGED', 'PICKED_UP'].includes(log.warehouse_status))
            .map((log) => {
                let action = '';
                let qtyIn = null;
                let qtyOut = null;

                if (log.warehouse_status === 'RECEIVED') {
                    action = 'Date In';
                    runningQtyIn += log.quantity;
                    qtyIn = runningQtyIn;
                } else if (log.warehouse_status === 'STAGED' || log.warehouse_status === 'PICKED_UP') {
                    action = 'Date Out';
                    runningQtyOut += log.quantity;
                    qtyOut = runningQtyOut;
                }

                return {
                    action,
                    date: formatInTimeZone(new Date(log.created_at), 'UTC', 'MMMM d, yyyy'),
                    qtyIn,
                    qtyOut,
                };
            })
            .reverse();
    }, [selectedSku]);

    const handleClose = useCallback(() => {
        if (callbacks && typeof callbacks.setSelectedSku === 'function') {
            callbacks.setSelectedSku(null);
        } else {
            console.error('setSelectedSku is not a function or callbacks is undefined', callbacks);
        }
    }, [callbacks]);

    if (selectedSku) {
        return (
            <NavResponsiveModal open={!!selectedSku} onClose={handleClose}>
                <ModalHeader title={`SKU: ${selectedSku.sku}`} onClose={handleClose} />
                <ModalContent
                    css={css`
                        width: 400px;
                    `}
                >
                    <Grid container direction="column" spacing={2}>
                        {simplifiedLogs.length ? (
                            simplifiedLogs.map((log, index) => (
                                <Grid
                                    item
                                    key={index}
                                    css={css`
                                        border-top: 1px solid #e0e0e0;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        padding-bottom: 15px !important;
                                        padding-top: 15px !important;
                                    `}
                                >
                                    <Typography variant="body1">
                                        <strong>{log.action}</strong>
                                    </Typography>
                                    <Typography variant="body1">{log.date}</Typography>
                                    {log.qtyIn !== null ? (
                                        <Typography variant="body1">
                                            Qty {log.qtyIn}/{selectedSku.totalQuantity}
                                        </Typography>
                                    ) : log.qtyOut !== null ? (
                                        <Typography variant="body1">
                                            Qty {log.qtyOut}/{selectedSku.totalQuantity}
                                        </Typography>
                                    ) : null}
                                </Grid>
                            ))
                        ) : (
                            <Typography
                                css={css`
                                    margin-bottom: 20px;
                                `}
                                variant="body1"
                            >
                                No items received yet.
                            </Typography>
                        )}
                    </Grid>
                </ModalContent>
            </NavResponsiveModal>
        );
    }
    return null;
};

export default SkuHistoryModal;
