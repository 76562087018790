import { Box, Button, Grid, MenuItem, Popover, TextField, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { addDays, format } from 'date-fns';
import { asBrowserDate } from '@/utilities/convertToISO';

const FilterPopover = ({ callbacks, state }) => {
    const { shipperOptions } = state;
    const [anchor, setAnchor] = useState(null);
    const [shipper, setShipper] = useState('All');
    const [dateIn, setDateIn] = useState(null);
    const [dateOut, setDateOut] = useState(null);
    const [createdFrom, setCreatedFrom] = useState(null);
    const [createdTo, setCreatedTo] = useState(null);

    const open = !!anchor;
    const id = open ? 'myorders-popover-filter' : undefined;

    const handleClearFilters = () => {
        setDateIn(null);
        setDateOut(null);
        setCreatedFrom(null);
        setCreatedTo(null);
        setShipper('All');
        callbacks.setFilters([]);
    };

    const handleApplyFilters = () => {
        let filters = [];
        if (dateIn) {
            filters.push({
                itemsByOrderId: {
                    manifests: {
                        manifest: {
                            type: { _in: ['INBOUND', 'CROSS_DOCK'] },
                            receiving_date: {
                                _gte: new Date(dateIn).toISOString(),
                                _lt: new Date(addDays(dateIn, 1)).toISOString(),
                            },
                        },
                    },
                },
            });
        }

        if (dateOut) {
            filters.push({
                itemsByOrderId: {
                    manifests: {
                        manifest: {
                            type: { _in: ['OUTBOUND', 'RETURN_TO_SENDER', 'WILL_CALL'] },
                            _or: [
                                {
                                    receiving_date: {
                                        _gte: new Date(dateOut).toISOString(),
                                        _lt: new Date(addDays(dateOut, 1)).toISOString(),
                                    },
                                },
                                {
                                    route: {
                                        scheduled_delivery: {
                                            _gte: new Date(dateOut).toISOString(),
                                            _lt: new Date(addDays(dateOut, 1)).toISOString(),
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            });
        }

        if (createdFrom) {
            filters.push({ created_at: { _gte: new Date(createdFrom).toISOString() } });
        }

        if (createdTo) {
            filters.push({ created_at: { _lt: new Date(createdTo).toISOString() } });
        }

        if (shipper !== 'All') {
            filters.push({ shipper_id: { _eq: shipper } });
        }

        callbacks.setFilters(filters);

        setAnchor(null);
    };

    const PopoverSelect = ({ label, value, onChange, options }) => {
        return (
            <>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>{label}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        style={{ width: '300px', maxHeight: '50px' }}
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                    >
                        <MenuItem key="All" value="All">
                            All
                        </MenuItem>
                        {Object.entries(options || {}).map(([value, label]) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </>
        );
    };

    return (
        <>
            <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={(e) => setAnchor(e.currentTarget)}
                css={css`
                    height: 100%;
                    color: white;
                `}
            >
                <FilterList />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box className="bg-white p-3" style={{ border: 'solid 1px #4C4C4C', borderRadius: '4px' }}>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 16, fontWeight: 600 }}>Order Creation Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="medium"
                                InputLabelProps={{ shrink: true }}
                                name="createdFrom"
                                label="Created From"
                                value={createdFrom ? format(new Date(parseInt(createdFrom)), 'yyyy-MM-dd') : null}
                                onChange={(e) => setCreatedFrom(asBrowserDate(e.target.value).getTime())}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    margin-top: 4px;
                                `}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography style={{ height: 'fit-content' }}>-</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="medium"
                                InputLabelProps={{ shrink: true }}
                                name="createdTo"
                                label="Created To"
                                value={createdTo ? format(new Date(parseInt(createdTo)), 'yyyy-MM-dd') : null}
                                onChange={(e) => setCreatedTo(asBrowserDate(e.target.value).getTime())}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    margin-top: 4px;
                                `}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 16, fontWeight: 600 }}>Date In</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="medium"
                                InputLabelProps={{ shrink: true }}
                                name="dateIn"
                                value={dateIn ? format(new Date(parseInt(dateIn)), 'yyyy-MM-dd') : null}
                                onChange={(e) => setDateIn(asBrowserDate(e.target.value).getTime())}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                `}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 16, fontWeight: 600 }}>Date Out</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="medium"
                                InputLabelProps={{ shrink: true }}
                                name="dateOut"
                                value={dateOut ? format(new Date(parseInt(dateOut)), 'yyyy-MM-dd') : null}
                                onChange={(e) => setDateOut(asBrowserDate(e.target.value).getTime())}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                `}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Shipper"
                                value={shipper || ''}
                                onChange={(e) => setShipper(e.target.value)}
                                options={shipperOptions || {}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                            <Button onClick={handleClearFilters}>Clear</Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" onClick={handleApplyFilters}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </>
    );
};

export default FilterPopover;
