import React from 'react';
import { css } from '@emotion/react';
import { Grid, Dialog } from '@material-ui/core';

import { colors } from '@/styles';
import {
    ResponsiveSidebarDialog,
    ModalTitle,
    ModalContent,
    StickyModalActions,
    PrimaryButton,
    SecondaryButton,
} from '@/styles/blocks';

import { H4, H3, local } from '../blocks';

const SendToPlanningModal = ({ open, text, callbacks }) => {
    return (
        <ResponsiveSidebarDialog open={open} onClose={callbacks.onClose}>
            <ModalTitle title="Return route to planning" onClose={callbacks.onClose} />
            <ModalContent width={500}>
                <Grid alignItems="center">
                    <H4>{text}</H4>
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={callbacks.onConfirm}>Yes</PrimaryButton>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default SendToPlanningModal;
