import { postUnauth } from '@/utilities/onwardClient';
import { VALIDATE_EMAIL } from '@/constants/apiRoutes';

const validateEmail = (email) => {
    return postUnauth(VALIDATE_EMAIL, {
        email,
    });
};

export default validateEmail;
