import React, { useMemo, useState } from 'react';
import { Grid, TextField, InputAdornment, IconButton, CircularProgress, MenuItem, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { genName as genSubregionName } from '@/components/Subregions/utils';
import { PrimaryButton } from '@/styles/blocks';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Header1 } from './blocks';
import SubregionListItem from './SubregionListItem';
import { useClientUser } from '@/hooks';
import Papa from 'papaparse';
import csvDownload from 'json-to-csv-export';
import { CSV_EXPORT_COLUMNS } from './constants';
import { format } from 'date-fns';

const SubregionList = ({ subregions, selected, loading, filter, callbacks, profile, types }) => {
    const { isOnwardAdmin } = useClientUser();
    const [editing, setEditing] = useState(null);

    const list = useMemo(() => {
        return subregions
            .map((subregion, idx) => [subregion, idx])
            .filter(([subregion, idx]) => {
                return (
                    !filter.subregion ||
                    genSubregionName(subregion, idx)?.toLowerCase().includes(filter.subregion.toLowerCase())
                );
            });
    }, [subregions, filter]);

    const isNewProfile = useMemo(() => {
        return profile !== null && !types.includes(profile);
    }, [types, profile]);

    const subregionsFileUpload = (e) => {
        try {
            const file = e.target.files?.[0];
            Papa.parse(file, {
                complete: ({ data }) => {
                    const [headers, ...body] = data;
                    if (['Subregion', 'Zip'].some((header) => !headers.includes(header))) {
                        callbacks.setNotification({
                            severity: 'error',
                            message: 'CSV file must contain "Subregion" and "Zip" headers',
                        });
                        return;
                    }

                    callbacks.uploadMappings(headers, body);
                },
                error: (error) => {
                    throw error;
                },
            });
        } catch (error) {
            console.error(error);
            callbacks.setNotification({
                severity: 'error',
                message: 'Error processing CSV file',
            });
        }
    };

    const subregionsFileDownload = () => {
        const data = subregions.reduce((acc, subregion, idx) => {
            const name = genSubregionName(subregion, idx);

            let more = [];
            if (subregion.carriers?.length > 0) {
                subregion.carriers.forEach(({ carrier_id, warehouse_id, is_direct }) => {
                    more.push(
                        ...subregion.zips.map(({ zip }) => ({
                            name,
                            zip,
                            carrier_id,
                            warehouse_id,
                            is_direct,
                        }))
                    );
                });
            } else {
                more = subregion.zips.map(({ zip }) => ({
                    name,
                    zip,
                }));
            }

            return [...acc, ...more];
        }, []);

        const columns = CSV_EXPORT_COLUMNS.filter((col) => !col.isAdminOnly || isOnwardAdmin);

        csvDownload({
            headers: columns.map((col) => col.header),
            data: data.map((row) => columns.map((col) => col.value(row))),
            filename: `${profile?.toLowerCase() || 'delivery_profile'}_${format(new Date(), 'yyyy-MM-dd')}.csv`,
            delimiter: ',',
        });
    };

    return (
        <>
            {isOnwardAdmin ? (
                <>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            flex-wrap: nowrap;
                            padding: 18px 20px 0px 20px;
                        `}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Delivery Profile"
                            select
                            value={profile || ''}
                            InputLabelProps={{ shrink: !!profile }}
                            onChange={(e) => {
                                callbacks.selectProfile(e.target.value);
                            }}
                        >
                            {types.map((type) => (
                                <MenuItem value={type} key={`subregion-type-${type}`}>
                                    {type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            flex-wrap: nowrap;
                            padding: 18px 20px 0px 20px;
                        `}
                    >
                        {isNewProfile ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="New Profile"
                                value={profile || ''}
                                InputLabelProps={{ shrink: !!profile }}
                                onChange={(e) => {
                                    callbacks.editProfile(e.target.value);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    callbacks.selectProfile(null);
                                                }}
                                            >
                                                <CloseIcon
                                                    css={css`
                                                        color: ${colors.reds.primary};
                                                    `}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        ) : (
                            <PrimaryButton
                                fullWidth
                                startIcon={
                                    <AddIcon
                                        css={css`
                                            color: white;
                                        `}
                                    />
                                }
                                onClick={() => {
                                    callbacks.selectProfile(`NEW_PROFILE_${types.length + 1}`);
                                }}
                            >
                                Add Profile
                            </PrimaryButton>
                        )}
                    </Grid>
                </>
            ) : null}
            <Grid
                container
                direction="row"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    flex-wrap: nowrap;
                    padding: 18px 20px 0px 20px;
                `}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search Subregions"
                    value={filter?.subregion || ''}
                    InputLabelProps={{ shrink: !!filter?.subregion }}
                    onChange={(e) => {
                        callbacks.setFilter((prev) => {
                            return {
                                ...prev,
                                subregion: e.target.value,
                            };
                        });
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    flex-wrap: nowrap;
                    padding: 18px 20px;
                    border-bottom: 1px solid ${colors.greys[4]};
                `}
            >
                <Grid
                    item
                    xs={6}
                    css={css`
                        padding-right: 10px;
                    `}
                >
                    <PrimaryButton
                        component="label"
                        disabled={loading.uploadSubregions}
                        startIcon={
                            loading.uploadSubregions ? (
                                <CircularProgress size="24px" color="white" />
                            ) : (
                                <FileUploadIcon
                                    css={css`
                                        color: white;
                                    `}
                                />
                            )
                        }
                        css={css`
                            min-width: 130px;
                        `}
                    >
                        Upload
                        <input
                            disabled={loading.uploadSubregions}
                            type="file"
                            hidden
                            accept=".csv"
                            onChange={subregionsFileUpload}
                        />
                    </PrimaryButton>
                </Grid>
                <Grid
                    item
                    xs={6}
                    css={css`
                        padding-left: 10px;
                    `}
                >
                    <PrimaryButton
                        onClick={subregionsFileDownload}
                        startIcon={
                            <FileDownloadIcon
                                css={css`
                                    color: white;
                                `}
                            />
                        }
                        css={css`
                            min-width: 130px;
                        `}
                    >
                        Download
                    </PrimaryButton>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    overflow-y: scroll;
                `}
            >
                <Grid container direction="column">
                    {list.map(([subregion, idx]) => {
                        return (
                            <SubregionListItem
                                key={subregion.subregion_id}
                                idx={idx}
                                isEditing={subregion.subregion_id === editing}
                                isSelected={selected?.subregion_id === subregion.subregion_id}
                                subregion={subregion}
                                callbacks={{
                                    ...callbacks,
                                    toggleEdit: (id) => {
                                        setEditing((prev) => (prev === id ? null : id));
                                    },
                                    setEdit: (id, status = false) => {
                                        setEditing((prev) => (status ? id : null));
                                    },
                                    resetEdit: () => {
                                        setEditing(null);
                                    },
                                }}
                            />
                        );
                    })}
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    padding: 20px 14px;
                    border-top: 1px solid ${colors.greys[4]};
                `}
            >
                <PrimaryButton
                    fullWidth
                    onClick={() => {
                        callbacks.createSubregion(list.length);
                    }}
                    disabled={loading.addSubregion || (isOnwardAdmin && !profile)}
                >
                    <Header1>Add Subregion</Header1>
                </PrimaryButton>
            </Grid>
        </>
    );
};

export default SubregionList;
