import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { typography, colors } from '@/styles';
import { Button, Grid, TextField, IconButton } from '@material-ui/core';

export const local = {
    greys: ['#8D8D8D', '#696969', '#4C4C4C', '#2b2b2b', '#0C0B1D'],
};

export const HR = styled.div`
    position: absolute;
    top: 18px;
    flex: 1;
    width: 100%;
    height: 3px;
    border-bottom: 3px dashed ${colors.greens.primary};
`;

export const VR = styled.div`
    flex: 0;
    width: 3px;
    height: 24px;
    border-left: 3px dashed ${colors.greens.primary};
`;

export const PrimaryButton = styled((props) => <Button {...props} />)`
    background: ${colors.greens.primary};
    color: ${colors.white.primary};

    &.Mui-disabled {
        background: rgba(206, 217, 224, 0.5);
    }

    &:hover {
        background: ${colors.greens.primary};
    }
`;
export const SecondaryButton = styled((props) => <Button {...props} />)`
    background: ${colors.greys.primary};
    color: ${colors.white.primary};

    &.Mui-disabled {
        background: rgba(206, 217, 224, 0.5);
    }

    &:hover {
        background: ${colors.greys.primary};
    }
`;

export const FlexRow = styled((props) => <Grid container {...props} />)`
    justify-content: space-evenly;
`;

export const FlexColumn = styled((props) => <Grid container direction="column" {...props} />)`
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0 12px;
`;

export const RootContainer = styled((props) => <Grid container {...props} />)`
    height: 100%;
    margin: auto;
    max-width: 360px;

    @media only screen and (min-width: 600px) {
        max-width: 600px;
    }
`;

export const FormContainer = styled((props) => <Grid container {...props} />)`
    height: 100%;
    margin: auto;
    max-width: 360px;
`;

export const IconBubble = styled.div`
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 999px;
    justify-content: center;
`;

export const DefaultResponsiveTextField = ({
    disabled = false,
    display,
    value,
    isDirty,
    number,
    state,
    hasError,
    callbacks,
}) => {
    return (
        <FlexColumn
            item
            css={css`
                margin-bottom: 20px;
            `}
        >
            <TextField
                variant="outlined"
                disabled={disabled}
                label={display}
                InputLabelProps={{ shrink: !!state[value] }}
                value={state[value] || ''}
                onBlur={(e) => {
                    callbacks.setDirty((prev) => ({ ...prev, [value]: true }));
                }}
                onChange={(e) => {
                    let next = e.target.value;
                    if (number) {
                        next = parseInt(Math.abs(next), 10);
                    }

                    callbacks.setPayload((prev) => ({ ...prev, [value]: next }));
                }}
                error={hasError ? hasError : callbacks.isEmpty(value) && isDirty[value]}
            />
        </FlexColumn>
    );
};

export const Icon = styled.div`
    display: flex;
    align-items: center;
    svg {
        font-size: 32px;
    }
`;

export const BodyLabel1 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 16px;
`;

export const BodyDescription1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
`;

export const Header1 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 28px;
`;
export const Header2 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 18px;
`;

export const SubHeader1 = styled.span`
    ${typography.sansSerif}

    font-weight: 600;
    font-size: 14px;
`;

const Body = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    background: ${(props) => (props.selected ? '#fff' : '#f0f0f0')};
    color: ${(props) => (props.selected ? colors.greens.primary : '#4C4C4C')};
    border: 1px solid;
    border-color: ${(props) => (props.selected ? colors.greens.primary : '#4C4C4C')};

    &:hover {
        opacity: 0.6;
    }
`;

export const ItemSwitch = ({ label, selected, onClick }) => {
    return (
        <Body onClick={onClick} selected={selected}>
            <BodyDescription1>{label}</BodyDescription1>
        </Body>
    );
};
