export const FILTERS = {
    SUBMITTED: 'SUBMITTED',
    APPROVED: 'APPROVED',
    PAID: 'PAID',
};

export const FILTER_DISPLAY_MAPPINGS = {
    [FILTERS.SUBMITTED]: 'Not Paid',
    [FILTERS.APPROVED]: 'Approved',
    [FILTERS.PAID]: 'Paid',
};
