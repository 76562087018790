import React from 'react';
import { css } from '@emotion/react';
import { Image } from 'react-bootstrap';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import { Grid, TextField, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

import { EXCEPTION_REASONS } from '../../constants';
import FileDropZone from '../../FileDropZone';
import { Body1, StyledRadio } from '../../Crossdocking/blocks';

const Attempted = ({ exception, required, callbacks }) => {
    return (
        <>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>Reason{required.includes('reason') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}</Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <RadioGroup
                    value={exception.reason || ''}
                    onChange={(e) => {
                        callbacks.setException((prev) => ({
                            ...prev,
                            reason: e.target.value,
                        }));
                    }}
                >
                    {EXCEPTION_REASONS[exception.type].map((reason) => (
                        <StyledRadio
                            key={reason}
                            value={reason}
                            label={reason}
                        />
                    ))}
                </RadioGroup>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>Did you try calling the customer?{required.includes('call_or_text') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}</Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <RadioGroup
                    value={exception.call_or_text ? String(exception.call_or_text) : true}
                    onChange={(e) => {
                        callbacks.setException((prev) => ({
                            ...prev,
                            call_or_text: e.target.value === 'true',
                        }));
                    }}
                >
                    <StyledRadio value={true} label="Yes" />
                    <StyledRadio value={false} label="No" />
                </RadioGroup>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>
                    Did you try ringing doorbell/knocking?{required.includes('ring_or_knock') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}
                </Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <RadioGroup
                    value={exception.ring_or_knock ? String(exception.ring_or_knock) : true}
                    onChange={(e) => {
                        callbacks.setException((prev) => ({
                            ...prev,
                            ring_or_knock: e.target.value === 'true',
                        }));
                    }}
                >
                    <StyledRadio value={true} label="Yes" />
                    <StyledRadio value={false} label="No" />
                </RadioGroup>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>How long did you wait before leaving?</Body1>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 30px;
                `}
            >
                <Grid
                    direction="column"
                    container
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <TextField
                        type="number"
                        value={exception.wait_time || 0}
                        onChange={(e) =>
                            callbacks.setException((prev) => ({
                                ...prev,
                                wait_time: e.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid
                    direction="column"
                    container
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        justify-content: center;
                    `}
                >
                    <Body1>&nbsp;minutes</Body1>
                </Grid>
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>Image{required.includes('image') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}</Body1>
            </Grid>
            <Grid
                direction="row"
                css={css`
                    margin-bottom: 30px;
                `}
            >
                {(exception.images || []).map((image) => (
                    <a href={image} target="_blank">
                        <Image
                            css={css`
                                height: 100px;
                                width: 100px;
                            `}
                            src={image}
                        />
                    </a>
                ))}
                <FileDropZone handleFileAdd={callbacks.handleFileAdd} />
            </Grid>
            <Grid
                direction="row"
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>Notes{required.includes('notes') ? <Body1 css={css`color: #8d8d8d;`}>&nbsp;(required)</Body1> : ''}</Body1>
            </Grid>
            <Grid direction="row" container>
                <TextField
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={exception.notes || ''}
                    onChange={(e) =>
                        callbacks.setException((prev) => ({
                            ...prev,
                            notes: e.target.value,
                        }))
                    }
                    multiline
                    rows={5}
                />
            </Grid>
        </>
    );
};

export default Attempted;
