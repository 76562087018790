import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import { useClientUser } from '@/hooks';
import { GreyBody, ModalContent, ModalTitle, PrimaryButton, StickyModalActions } from '@/styles/blocks';
import { useOrderCarrierPricing } from '@/hooks';
import { uniq } from 'lodash';
import { genAttributes } from '@onward-delivery/core';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { CustomMuiDatePicker } from '../ShipmentForm/modals/InputFields';
import { asDateInTZ } from '@/utilities/convertToISO';
import ReceivingWarehouseModal from './ReceivingWarehouseModal';
import { OnwardCheckbox } from '../ShipmentForm/blocks';

const Title1 = styled.span`
    color: ${colors.greys.secondary};
    font-size: 20px;
    font-weight: 900;
`;

const Title2 = styled.span`
    color: ${colors.greys.secondary};
    font-size: 18px;
    font-weight: 800;
`;

const Title3 = styled.span`
    color: ${colors.greys.secondary};
    font-size: 16px;
    font-weight: 700;
`;

const Body = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

const Bold = styled.span`
    font-size: 14px;
    font-weight: 700;
`;

const Row = styled((props) => <Grid item {...props} />)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Link = styled.a`
    color: ${colors.greens.primary};
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    white-space: nowrap;
`;

const Divider = styled((props) => <hr {...props} />)`
    color: ${colors.greys.primary};
    width: 100%;
`;

const OrderClaimTCModal = ({ open, onClose, bid, loads = [], callbacks, isLoading }) => {
    const { accountType } = useClientUser();
    const orderPrice = useOrderCarrierPricing(loads);
    const price = bid?.carrier_rate ? `$${bid.carrier_rate.toFixed(2)}` : orderPrice;

    const [deliveryDate, setDeliveryDate] = useState(null);
    const [targetDeliveryDate, setTargetDeliveryDate] = useState(true);
    const [receivingWarehouseModal, setReceivingWarehouseModal] = useState(false);

    useEffect(() => {
        if (open) {
            setReceivingWarehouseModal(false);
        }
    }, [open]);

    const availability = useMemo(() => {
        return uniq(
            loads.reduce((dates, load) => {
                return [...dates, load.preferred_delivery_date, ...(load.alternative_delivery_dates || [])];
            }, [])
        ).filter((x) => x);
    }, [loads]);

    const timeZone = useMemo(() => {
        if (!loads.length) return 'America/New_York';
        const { zip } = genAttributes(loads[0]);
        return zipcode_to_timezone.lookup(loads[0][zip]) || 'America/New_York';
    }, [loads]);

    const isMiddleMileDelivery = loads.some((o) => !!o?.is_middle_mile);

    const displayButtonText = () => {
        if (isMiddleMileDelivery) {
            return `Select ${loads?.[0]?.order_type === 'return' ? 'Crossdock' : 'Receiving'} Warehouse`;
        }
        if (bid) {
            return bid.bid_id ? 'Accept and Update Bid' : 'Accept and Place Bid';
        }
        return 'Accept and Claim';
    };

    const handleButtonClick = (_isMiddleMileDelivery, locationId) => {
        if (_isMiddleMileDelivery) {
            setReceivingWarehouseModal(true);
            return;
        }
        if (bid) {
            callbacks.placeBid(bid, locationId);
            setReceivingWarehouseModal(false);
            onClose();
            return;
        }
        callbacks.claimNow(loads, deliveryDate, targetDeliveryDate, locationId);
        setReceivingWarehouseModal(false);
        onClose();
    };

    if (receivingWarehouseModal)
        return (
            <ReceivingWarehouseModal
                handleButtonClick={handleButtonClick}
                open={receivingWarehouseModal}
                onClose={() => {
                    setReceivingWarehouseModal(false);
                    onClose();
                }}
                loads={loads}
                bid={bid}
            />
        );

    return (
        <NavResponsiveModal open={open} onClose={onClose}>
            <ModalTitle>
                <Row>
                    <Title1>Payment</Title1>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Row>
            </ModalTitle>
            <ModalContent
                css={css`
                    width: 100%;
                    border-top: 1px solid ${colors.greys.primary};
                    border-bottom: 1px solid ${colors.greys.primary};
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                    `}
                >
                    <Row>
                        <Body>Total Payout</Body>
                        <Bold>{price}</Bold>
                    </Row>
                    {bid ? (
                        <Row>
                            <Bold>Payment takes effect only if your bid is accepted.</Bold>
                        </Row>
                    ) : (
                        <Row>
                            <Body>Total Orders</Body>
                            <Bold>{loads.length}</Bold>
                        </Row>
                    )}
                    <Row>
                        <Body
                            css={css`
                                color: ${colors.greys.primary};
                            `}
                        >
                            Amount due will be paid to you once delivery is complete. Exceptions may apply in the case
                            of damages or if the delivery isn't completed.
                        </Body>
                        <Link target="_blank" href={`/${accountType}/account`}>
                            View account
                        </Link>
                    </Row>
                    <Divider />
                    {!bid && (
                        <>
                            <Row>
                                <CustomMuiDatePicker
                                    label={targetDeliveryDate ? 'Target Delivery Date' : 'Delivery Date'}
                                    selectedDate={deliveryDate}
                                    timeZone={timeZone}
                                    onChange={(val) => setDeliveryDate(val)}
                                    daysToHighlight={availability}
                                />
                            </Row>
                            <Row>
                                <Body
                                    css={css`
                                        color: ${colors.greys.primary};
                                    `}
                                >
                                    Customer's available dates are highlighted. If you choose another date you may need
                                    to confirm the delivery date with the customer.
                                </Body>
                            </Row>
                            <Row
                                css={css`
                                    justify-content: flex-start;
                                `}
                            >
                                <OnwardCheckbox
                                    label="Target Delivery?"
                                    checked={targetDeliveryDate}
                                    onChange={(e) => {
                                        setTargetDeliveryDate((prev) => !prev);
                                    }}
                                />
                                <Tooltip
                                    css={css`
                                        color: #59b863;
                                        margin-left: 0.5rem;
                                    `}
                                    title={
                                        <span
                                            css={css`
                                                font-size: 15px;
                                                line-height: 21px;
                                            `}
                                        >
                                            Check this box if the provided date is just a target delivery date that you
                                            are aiming for. Uncheck if it is a set delivery date that you plan to
                                            deliver the items on.
                                        </span>
                                    }
                                    placement="top"
                                >
                                    <Info />
                                </Tooltip>
                            </Row>
                            <Divider />
                        </>
                    )}
                    <Row>
                        <Title2>Marketplace Expectations</Title2>
                    </Row>
                    <Row>
                        <Title3>Pick Up</Title3>
                    </Row>
                    <Row>
                        <ol>
                            <li>
                                <Body>
                                    You are required to make updates in the app/website informing the store/warehouse of
                                    the pick up date and time. Store pick up hours will be listed in the app.
                                </Body>
                            </li>
                            <li>
                                <Body>Pick up time frames are 4 hour windows.</Body>
                            </li>
                            <li>
                                <Body>
                                    If there are any issues with pick up or the items are different than described in
                                    the app please call the Onward Support team and we will make adjustments in the app
                                    and price.
                                </Body>
                            </li>
                        </ol>
                    </Row>
                    <Divider />
                    <Row>
                        <Title3>Delivery</Title3>
                    </Row>
                    <Row>
                        <ol>
                            <li>
                                <Body>
                                    You are required to make updates in the app/website informing the store and customer
                                    of the delivery date and time frame.
                                </Body>
                            </li>
                            <li>
                                <Body>Delivery timeframes are 4 hour windows</Body>
                            </li>
                            <li>
                                <Body>Deliveries must be made to the type of delivery specified on the order.</Body>
                                <ol type="a">
                                    <li>
                                        <Body>
                                            Deliveries must be made to the type of delivery specified on the order.
                                        </Body>
                                    </li>
                                    <li>
                                        <Body>First Dry Space is the garage or first room inside the front door.</Body>
                                    </li>
                                    <li>
                                        <Body>
                                            Room of Choice is the consignee's room of their choosing, no assembly
                                            required.
                                        </Body>
                                    </li>
                                    <li>
                                        <Body>
                                            White glove is a full installation of the delivery and removal of all the
                                            trash.
                                        </Body>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <Body>
                                    All customers will receive a text message link allowing them to review their
                                    delivery experience. In order to boost your profile with highly rated reviews, top
                                    service is required.
                                </Body>
                            </li>
                            <li>
                                <Body>
                                    If there are any unplanned accessorials including but not limited to: stairs,
                                    carriers above the second floor, service level upgrades requested, door removals,
                                    requests to move items not part of the order, detention time, please report in the
                                    app with pictures and descriptions and pay will be adjusted accordingly.
                                </Body>
                            </li>
                            <li>
                                <Body>
                                    If there are any issues with making the delivery such as snow/ice/debris blocking
                                    the driveway or inaccurate descriptions provided in the app, please contact the
                                    Onward Support team and we will address.
                                </Body>
                            </li>
                        </ol>
                    </Row>
                    <Divider />
                    <Row>
                        <Title3>Payment Terms</Title3>
                    </Row>
                    <Row>
                        <ol>
                            <li>
                                <Body>
                                    Order Completion: Carriers are required to complete the order in the Onward app
                                    within 24 hours of the delivery.
                                </Body>
                            </li>
                            <li>
                                <Body>
                                    Payment Schedule: Payment to carriers will be processed on a net 30-day basis from
                                    the time the order is marked as completed in the Onward app.
                                </Body>
                            </li>
                        </ol>
                    </Row>
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <div />
                <PrimaryButton
                    disabled={(!bid && !deliveryDate) || isLoading}
                    onClick={() => handleButtonClick(isMiddleMileDelivery)}
                >
                    {displayButtonText()}
                </PrimaryButton>
            </StickyModalActions>
        </NavResponsiveModal>
    );
};

export default OrderClaimTCModal;
