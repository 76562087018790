import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Collapse, Grid, TextField, IconButton, Tooltip } from '@material-ui/core';
import { PrimaryButton } from '@/styles/blocks';
import { WarehousingContext } from './context';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body3, Body4 } from './blocks';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { colors } from '@/styles';

const ButtonRow = styled((props) => <Grid {...props} />)`
    width: 100%;
    text-align: center;
`;

const RowFullWidth = styled((props) => <Grid {...props} />)`
    width: 100%;
`;

const WarehousingButtons = () => {
    const {
        addingRackSettings,
        setAddingRackSettings,
        intiateAddingRack,
        addRack,
        view,
        selectedRack,
        setRackDataChanges,
        deleteRack,
        combinedData,
        selectedWarehouse,
        saveChanges,
        loadingSaving,
        addPool,
        addingPoolSettings,
        setAddingPoolSettings,
    } = useContext(WarehousingContext);

    const [editingSelectedRack, setEditingSelectedRack] = useState(false);
    useEffect(() => {
        setEditingSelectedRack(false);
    }, [selectedWarehouse]);

    const racksExist = useMemo(() => {
        return Object.values(combinedData).some((i) => i?.rackIdentifier);
    }, [combinedData]);

    if (!selectedWarehouse) {
        return (
            <Grid
                container
                alignItems="center"
                direction="column"
                css={css`
                    padding: 1rem 1.5rem 1rem 1rem;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-top: 30px;
                    `}
                >
                    <Body4>Select a warehouse on the left to begin</Body4>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            container
            alignItems="center"
            direction="column"
            css={css`
                padding: 1rem 1.5rem 1rem 1rem;
                height: 100%;
                flex-wrap: nowrap;
                overflow: scroll;
                justify-content: space-between;
            `}
        >
            {view === 'edit' ? (
                <>
                    <Grid item container>
                        <Grid
                            item
                            css={css`
                                display: flex;
                                width: 100%;
                                align-items: center;
                                flex-wrap: nowrap;
                            `}
                        >
                            <PrimaryButton
                                css={css`
                                    width: -webkit-fill-available;
                                `}
                                onClick={() => {
                                    if (!addingRackSettings) {
                                        intiateAddingRack();
                                        setEditingSelectedRack(false);
                                        setAddingPoolSettings(null);
                                    } else {
                                        setAddingRackSettings(null);
                                    }
                                }}
                            >
                                Add Rack
                            </PrimaryButton>
                            <Tooltip
                                title={
                                    <span
                                        css={css`
                                            font-size: 14px;
                                        `}
                                    >
                                        Racks are shelves with height, width, and depth indicating the number of bins
                                        that they contain. Each bin can hold one item or one pallet for storage.
                                    </span>
                                }
                            >
                                <InfoOutlined
                                    css={css`
                                        margin-left: 10px;
                                    `}
                                />
                            </Tooltip>
                        </Grid>

                        <Collapse in={!!addingRackSettings} unmountOnExit>
                            <Grid
                                container
                                justifyContent="center"
                                css={css`
                                    padding-top: 1rem;
                                    gap: 0.5rem;
                                `}
                            >
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        label="(Optional) Custom Name"
                                        value={addingRackSettings?.customName}
                                        placeholder={`(Optional) Custom Name`}
                                        onChange={(e) => {
                                            setAddingRackSettings((prev) => ({
                                                ...prev,
                                                customName: e.target.value,
                                            }));
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        label="Number of Racks"
                                        type="number"
                                        value={addingRackSettings?.numRacks}
                                        onChange={(e) => {
                                            setAddingRackSettings((prev) => ({
                                                ...prev,
                                                numRacks: e.target.value,
                                            }));
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        label="Rack Columns"
                                        type="number"
                                        value={addingRackSettings?.horizontalRows}
                                        onChange={(e) => {
                                            setAddingRackSettings((prev) => ({
                                                ...prev,
                                                horizontalRows: e.target.value,
                                            }));
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        label="Rack Rows"
                                        type="number"
                                        value={addingRackSettings?.verticalRows}
                                        onChange={(e) => {
                                            setAddingRackSettings((prev) => ({
                                                ...prev,
                                                verticalRows: e.target.value,
                                            }));
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        label="Rack Depth"
                                        type="number"
                                        value={addingRackSettings?.rackDepth}
                                        onChange={(e) => {
                                            setAddingRackSettings((prev) => ({
                                                ...prev,
                                                rackDepth: e.target.value,
                                            }));
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <ButtonRow
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-around;
                                        border-bottom: 1px solid ${colors.greys.primary};
                                        padding-bottom: 5px;
                                    `}
                                >
                                    <IconButton
                                        onClick={() => {
                                            setAddingRackSettings(null);
                                        }}
                                    >
                                        <CancelIcon
                                            css={css`
                                                font-size: 28px;
                                                color: ${colors.reds.primary};
                                            `}
                                        />
                                    </IconButton>

                                    <IconButton onClick={addRack}>
                                        <CheckBoxIcon
                                            css={css`
                                                font-size: 28px;
                                                color: ${colors.greens.primary};
                                            `}
                                        />
                                    </IconButton>
                                </ButtonRow>
                            </Grid>
                        </Collapse>

                        <Grid
                            item
                            css={css`
                                display: flex;
                                width: 100%;
                                align-items: center;
                                flex-wrap: nowrap;
                                margin-top: 1rem;
                            `}
                        >
                            <PrimaryButton
                                css={css`
                                    width: -webkit-fill-available;
                                `}
                                onClick={() => {
                                    if (!addingPoolSettings) {
                                        setAddingPoolSettings({
                                            poolWidth: 5,
                                            poolDepth: 5,
                                        });
                                        setEditingSelectedRack(false);
                                        setAddingRackSettings(null);
                                    } else {
                                        setAddingPoolSettings(null);
                                    }
                                }}
                            >
                                Add Pool
                            </PrimaryButton>
                            <Tooltip
                                title={
                                    <span
                                        css={css`
                                            font-size: 14px;
                                        `}
                                    >
                                        Pools are designated floor areas which function as a single large bin in which
                                        an unlimited number of items or pallets may be stored.
                                    </span>
                                }
                            >
                                <InfoOutlined
                                    css={css`
                                        margin-left: 10px;
                                    `}
                                />
                            </Tooltip>
                        </Grid>

                        <Collapse in={!!addingPoolSettings} unmountOnExit>
                            <Grid
                                container
                                justifyContent="center"
                                css={css`
                                    padding-top: 1rem;
                                    gap: 0.5rem;
                                `}
                            >
                                <Grid
                                    item
                                    css={css`
                                        text-align: center;
                                    `}
                                >
                                    <span>(Pool units only affect display size)</span>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        label="(Optional) Custom Name"
                                        value={addingPoolSettings?.customName}
                                        placeholder={`(Optional) Custom Name`}
                                        onChange={(e) => {
                                            setAddingPoolSettings((prev) => ({
                                                ...prev,
                                                customName: e.target.value,
                                            }));
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        label="Width"
                                        type="number"
                                        value={addingPoolSettings?.poolWidth}
                                        onChange={(e) => {
                                            setAddingPoolSettings((prev) => ({
                                                ...prev,
                                                poolWidth: e.target.value,
                                            }));
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        label="Depth"
                                        type="number"
                                        value={addingPoolSettings?.poolDepth}
                                        onChange={(e) => {
                                            setAddingPoolSettings((prev) => ({
                                                ...prev,
                                                poolDepth: e.target.value,
                                            }));
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <ButtonRow
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-around;
                                        border-bottom: 1px solid ${colors.greys.primary};
                                        padding-bottom: 5px;
                                    `}
                                >
                                    <IconButton
                                        onClick={() => {
                                            setAddingPoolSettings(null);
                                        }}
                                    >
                                        <CancelIcon
                                            css={css`
                                                font-size: 28px;
                                                color: ${colors.reds.primary};
                                            `}
                                        />
                                    </IconButton>

                                    <IconButton onClick={addPool}>
                                        <CheckBoxIcon
                                            css={css`
                                                font-size: 28px;
                                                color: ${colors.greens.primary};
                                            `}
                                        />
                                    </IconButton>
                                </ButtonRow>
                            </Grid>
                        </Collapse>

                        {/* Selected Rack section */}
                        {racksExist && (
                            <>
                                <RowFullWidth
                                    css={css`
                                        margin-top: 1.5rem;
                                    `}
                                >
                                    <Body3>Click on a rack to edit it</Body3>
                                </RowFullWidth>

                                <RowFullWidth
                                    css={css`
                                        margin-top: 1rem;
                                    `}
                                >
                                    <Body3>You may also click and drag racks to reposition them</Body3>
                                </RowFullWidth>
                            </>
                        )}

                        {!!selectedRack && (
                            <>
                                <div
                                    css={css`
                                        font-size: 16px;
                                        font-weight: 600;
                                        margin-top: 2rem;
                                        color: ${colors.greys.secondary};
                                    `}
                                >
                                    Selected {selectedRack?.baseRackData?.isPool ? 'Pool' : 'Rack'}:{' '}
                                    {selectedRack.baseRackData.customName || selectedRack.baseRackData.rackIdentifier}
                                </div>

                                <ButtonRow
                                    item
                                    css={css`
                                        margin-top: 1rem;
                                    `}
                                >
                                    <PrimaryButton
                                        css={css`
                                            width: 100%;
                                        `}
                                        onClick={() => {
                                            setEditingSelectedRack(!editingSelectedRack);
                                            setAddingRackSettings(null);
                                            setAddingPoolSettings(null);
                                        }}
                                    >
                                        {editingSelectedRack
                                            ? 'Done Editing'
                                            : selectedRack?.baseRackData?.isPool
                                            ? 'Edit Pool Display Size'
                                            : 'Edit Rack Settings'}
                                    </PrimaryButton>
                                </ButtonRow>

                                <Collapse in={!!editingSelectedRack} unmountOnExit>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        css={css`
                                            padding-top: 1rem;
                                            gap: 0.5rem;
                                            border-bottom: 1px solid ${colors.greys.primary};
                                            padding-bottom: 1rem;
                                        `}
                                    >
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label="Custom Name"
                                                value={selectedRack.baseRackData.customName}
                                                placeholder={`${selectedRack?.baseRackData?.isPool ? 'Pool' : 'Rack'} ${
                                                    selectedRack.baseRackData.rackIdentifier
                                                }`}
                                                onChange={(e) => {
                                                    const updatedBaseRack = {
                                                        ...selectedRack.baseRackData,
                                                        customName: e.target.value,
                                                    };
                                                    setRackDataChanges((prev) => {
                                                        return {
                                                            ...prev,
                                                            [selectedRack.baseRackData.rackIdentifier]: updatedBaseRack,
                                                        };
                                                    });
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        {selectedRack?.baseRackData?.isPool ? (
                                            <>
                                                <Grid item>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Width"
                                                        type="number"
                                                        value={selectedRack.baseRackData.poolWidth}
                                                        onChange={(e) => {
                                                            const updatedBaseRack = {
                                                                ...selectedRack.baseRackData,
                                                                poolWidth: Math.max(e.target.value, 1),
                                                            };
                                                            setRackDataChanges((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    [selectedRack.baseRackData.rackIdentifier]:
                                                                        updatedBaseRack,
                                                                };
                                                            });
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Depth"
                                                        type="number"
                                                        value={selectedRack.baseRackData.poolDepth}
                                                        onChange={(e) => {
                                                            const updatedBaseRack = {
                                                                ...selectedRack.baseRackData,
                                                                poolDepth: Math.max(e.target.value, 1),
                                                            };
                                                            setRackDataChanges((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    [selectedRack.baseRackData.rackIdentifier]:
                                                                        updatedBaseRack,
                                                                };
                                                            });
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Rack Columns"
                                                        type="number"
                                                        value={selectedRack.baseRackData.rows}
                                                        onChange={(e) => {
                                                            const updatedBaseRack = {
                                                                ...selectedRack.baseRackData,
                                                                rows: Math.max(e.target.value, 1),
                                                            };
                                                            setRackDataChanges((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    [selectedRack.baseRackData.rackIdentifier]:
                                                                        updatedBaseRack,
                                                                };
                                                            });
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Rack Rows"
                                                        type="number"
                                                        value={selectedRack.baseRackData.columns}
                                                        onChange={(e) => {
                                                            const updatedBaseRack = {
                                                                ...selectedRack.baseRackData,
                                                                columns: Math.max(e.target.value, 1),
                                                            };
                                                            setRackDataChanges((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    [selectedRack.baseRackData.rackIdentifier]:
                                                                        updatedBaseRack,
                                                                };
                                                            });
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Rack Depth"
                                                        type="number"
                                                        value={selectedRack.baseRackData.rackDepth}
                                                        onChange={(e) => {
                                                            const updatedBaseRack = {
                                                                ...selectedRack.baseRackData,
                                                                rackDepth: Math.max(e.target.value, 1),
                                                            };
                                                            setRackDataChanges((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    [selectedRack.baseRackData.rackIdentifier]:
                                                                        updatedBaseRack,
                                                                };
                                                            });
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Collapse>

                                <ButtonRow
                                    item
                                    css={css`
                                        margin-top: 1rem;
                                    `}
                                >
                                    <PrimaryButton
                                        css={css`
                                            width: 100%;
                                        `}
                                        onClick={() => {
                                            const updatedBaseRack = {
                                                ...selectedRack.baseRackData,
                                                rotation: (selectedRack?.baseRackData?.rotation + 90) % 360,
                                            };
                                            setRackDataChanges((prev) => {
                                                return {
                                                    ...prev,
                                                    [selectedRack.baseRackData.rackIdentifier]: updatedBaseRack,
                                                };
                                            });
                                        }}
                                    >
                                        Rotate Rack
                                    </PrimaryButton>
                                </ButtonRow>

                                {!selectedRack?.baseRackData?.isPool && (
                                    <ButtonRow
                                        item
                                        css={css`
                                            margin-top: 1rem;
                                        `}
                                    >
                                        <PrimaryButton
                                            css={css`
                                                width: 100%;
                                            `}
                                            onClick={() => {
                                                const updatedBaseRack = {
                                                    ...selectedRack.baseRackData,
                                                    flipped: !selectedRack?.baseRackData?.flipped,
                                                };
                                                setRackDataChanges((prev) => {
                                                    return {
                                                        ...prev,
                                                        [selectedRack.baseRackData.rackIdentifier]: updatedBaseRack,
                                                    };
                                                });
                                            }}
                                        >
                                            Flip Rack
                                        </PrimaryButton>
                                    </ButtonRow>
                                )}

                                <ButtonRow
                                    item
                                    css={css`
                                        margin-top: 1rem;
                                    `}
                                >
                                    <PrimaryButton
                                        css={css`
                                            width: 100%;
                                        `}
                                        onClick={() => {
                                            deleteRack();
                                        }}
                                    >
                                        Delete Rack
                                    </PrimaryButton>
                                </ButtonRow>
                            </>
                        )}
                    </Grid>

                    <ButtonRow
                        item
                        css={css`
                            margin-top: auto;
                            margin-bottom: 1rem;
                            margin-top: 2rem;
                        `}
                    >
                        <PrimaryButton
                            disabled={loadingSaving}
                            onClick={saveChanges}
                            css={css`
                                font-size: 16px;
                                height: 3rem;
                                width: 100%;
                            `}
                        >
                            Save
                        </PrimaryButton>
                    </ButtonRow>
                </>
            ) : (
                <>
                    <ButtonRow
                        item
                        css={css`
                            margin-top: auto;
                            margin-bottom: 1rem;
                        `}
                    >
                        <PrimaryButton
                            disabled={loadingSaving}
                            onClick={saveChanges}
                            css={css`
                                font-size: 16px;
                                height: 3rem;
                            `}
                        >
                            Save
                        </PrimaryButton>
                    </ButtonRow>
                </>
            )}
        </Grid>
    );
};

export default WarehousingButtons;
