const Bedframe = require('./bedframeDims.json');
const Boxspring = require('./boxspringDims.json');
const Mattress = require('./mattressDims.json');

const exp = {
    Mattress,
    Boxspring,
    Bedframe,
};

export default exp;
