import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { Grid, InputAdornment } from '@material-ui/core';
import { RenderDefaultAccessorial } from './types/default';
import { RenderLSAccessorial } from './types/livingSpaces';
import { RenderAFAccessorial } from './types/americanFreight';

import { Card, H1, Body1 } from './blocks';

const MaxSplitAccessorial = ({ label, percentage, flat, rates, errors, callbacks }) => {
    return (
        <Grid
            container
            direction="row"
            css={css`
                margin-bottom: 12px;
                flex: 0;
                flex-basis: 0;
            `}
        >
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    justify-content: center;
                `}
            >
                <Body1>{label}</Body1>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 2;
                    flex-basis: 0;
                `}
            >
                <Grid container direction="row">
                    <Grid
                        container
                        item
                        direction="column"
                        xs={3}
                        css={css`
                            justify-content: center;
                            margin-right: 12px;
                        `}
                    >
                        <CurrencyTextField
                            variant="outlined"
                            size="small"
                            value={rates?.[percentage]?.[0]?.rate * 100 || ''}
                            error={!(rates?.[percentage]?.[0]?.rate >= 0)}
                            onChange={(_, val) => {
                                callbacks.setRate({
                                    bucket: 0,
                                    type: percentage,
                                    rate: val / 100,
                                });
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        xs={3}
                        css={css`
                            justify-content: center;
                            margin-right: 12px;
                        `}
                    >
                        <CurrencyTextField
                            variant="outlined"
                            size="small"
                            value={rates?.[flat]?.[0]?.rate || 0}
                            error={!(rates?.[flat]?.[0]?.rate >= 0)}
                            onChange={(_, val) => {
                                callbacks.setRate({
                                    bucket: 0,
                                    type: flat,
                                    rate: val,
                                });
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        css={css`
                            flex: 1;
                            justify-content: center;
                        `}
                    >
                        <Body1>Whichever is greater</Body1>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const DefaultAccessorial = ({ label, adornment, decorator, type, isPercentage, rates, errors, callbacks }) => {
    const mod = isPercentage ? 100 : 1;
    return (
        <Grid
            container
            direction="row"
            css={css`
                margin-bottom: 12px;
                flex: 0;
                flex-basis: 0;
            `}
        >
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    justify-content: center;
                `}
            >
                <Body1>{label}</Body1>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 2;
                    flex-basis: 0;
                `}
            >
                <Grid container direction="row">
                    <Grid
                        container
                        item
                        direction="column"
                        xs={3}
                        css={css`
                            justify-content: center;
                            margin-right: 12px;
                        `}
                    >
                        <CurrencyTextField
                            variant="outlined"
                            size="small"
                            value={rates?.[type]?.[0]?.rate ? rates?.[type]?.[0]?.rate * mod : ''}
                            error={errors[type] && !(rates?.[type]?.[0]?.rate >= 0)}
                            onChange={(_, val) => {
                                callbacks.setRate({
                                    bucket: 0,
                                    type,
                                    rate: val / mod,
                                });
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    {decorator ? (
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex: 1;
                                justify-content: center;
                            `}
                        >
                            <Body1>{decorator}</Body1>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </Grid>
    );
};

const RateGrid = ({ className, algoType: type, accessorials, rates, errors, callbacks }) => {
    const Accessorial = useMemo(() => {
        switch (type) {
            case 'SHIPPER_GLOBAL_DEFAULT':
            case 'CARRIER_GLOBAL_DEFAULT':
            case 'DEFAULT':
                return RenderDefaultAccessorial;
            case 'LIVING_SPACES':
                return RenderLSAccessorial;
            case 'ROCKET_SHIPPING':
                return RenderAFAccessorial;
            default:
                return null;
        }
    }, [type]);

    return (
        <Card className={className}>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 24px;
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <H1>Accessorials</H1>
            </Grid>

            {accessorials.map((props) => {
                return (
                    <Accessorial
                        key={props.type}
                        DefaultAccessorial={DefaultAccessorial}
                        MaxSplitAccessorial={MaxSplitAccessorial}
                        accessorial={props}
                        rates={rates}
                        errors={errors}
                        callbacks={callbacks}
                    />
                );
            })}
        </Card>
    );
};

export default RateGrid;
