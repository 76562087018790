import { gql } from '@apollo/client';

export const UPDATE_WAREHOUSE_RACKS = gql`
    mutation UpdateWarehouseRacks($location_id: uuid!, $warehouse_racks: jsonb) {
        update_locations_by_pk(pk_columns: { location_id: $location_id }, _set: { warehouse_racks: $warehouse_racks }) {
            location_id
            warehouse_racks
        }
    }
`;
