import { gql } from '@apollo/client';

const UPDATE_NOTIFICATIONS = gql`
mutation update_notifications($notification_id: uuid, $notification_status: String) {
    update_notifications(
        where: { notification_id: { _eq: $notification_id } },
      _set: {
        notification_status: $notification_status,
      }
    ) {
      affected_rows
      returning {
        notification_id
        notification_status
      }
    }
  }
`;

const RESOLVE_MANY_NOTIFICATIONS = gql`
    mutation update_notifications_many($notification_ids: [uuid!]) {
        update_notifications_many(
            updates: {
                where: { notification_id: { _in: $notification_ids } }
                _set: { notification_status: "RESOLVED" }
            }
        ) {
            affected_rows
            returning {
                notification_id
                notification_status
            }
        }
    }
`;

const INSERT_NOTIFICATION = gql`
    mutation InsertNotifications(
        $notification_id: String
        $notification_status: String
        $notification_type: String
        $user_id: String
        $notification_data: jsonb
        ) {
          insert_notifications(
              objects: {
                  notification_id: $notification_id
                  notification_status: $notification_status
                  notification_type: $notification_type
                  user_id: $user_id
                  notification_data: $notification_data
                }
                ) {
                    affected_rows
                    returning {
                        notification_id
                        notification_status
                        notification_type
                        user_id
                        notification_data
                      }
                    }
                }
`;

export { UPDATE_NOTIFICATIONS, INSERT_NOTIFICATION, RESOLVE_MANY_NOTIFICATIONS };