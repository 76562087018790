import React, { useMemo } from 'react';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';

export const useStopsAway = (route, order) => {
    return useMemo(() => {
        if (!route) return null;

        const stopSequence = route.stopsByRouteId.filter((stop) => FTLStopHelpers.isCustomerStop(stop, route));

        const currOrderStopIdx = stopSequence.findIndex((stop) => {
            return (
                (stop.type === 'PICKUP' && stop.returns?.includes(order.order_id)) ||
                (stop.type === 'DROPOFF' && stop.orders?.includes(order.order_id) && !stop?.returns?.length)
            );
        });

        if (stopSequence[currOrderStopIdx]?.stop_completion_time) return;

        const currOrderStopOrdering = stopSequence.find((s) => (s.orders || []).includes(order.order_id))?.ordering;
        const stopsBeforeCurrOrder = stopSequence.slice(0, currOrderStopIdx + 1);

        let lastCompletedStopOrdering =
            stopsBeforeCurrOrder.reverse().find((_stop) => _stop.stop_completion_time)?.ordering || 0;

        return Math.max(0, currOrderStopOrdering - lastCompletedStopOrdering - 1);
    }, [route, order]);
};
