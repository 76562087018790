import { gql } from '@apollo/client';

export const PRICING_TARIFF_FIELDS = gql`
    fragment PricingTariffFields on pricing_tariffs {
        algo_type
        client_id
        created_at
        last_modified_at
        name
        tariff_id
        base_rates_axis
        base_rates_axis2
        base_rates_qty
    }
`;
