import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    ModalActions,
    ModalContent,
    ResponsiveSidebarDialog,
    PrimaryButton,
    SecondaryButton,
    SectionTitle,
    ModalBody,
} from '@/styles/blocks';
import { useClientUser } from '@/hooks/useClientUser';

import { UPSERT_CSV_MAPPING } from '../graphql/mutations';
import { Context } from '../store';
import { MODALS } from '../constants';
import { TextField } from '../blocks';

const SaveTemplateModal = () => {
    const { state, callbacks } = useContext(Context);
    const open = state.current === MODALS.SAVE_TEMPLATE;
    const { user_id } = useClientUser();

    const [templateName, setTemplateName] = useState(state.mapping?.name || 'Template Name');
    const [saveMappings, { data, loading, error }] = useMutation(UPSERT_CSV_MAPPING, {
        onError: (e) => {
            console.log(e);
            callbacks.setErrorMsg('Error trying to save template.');
        },
    });

    const reset = () => {
        setTemplateName('Template Name');
    };

    return (
        <ResponsiveSidebarDialog
            open={open}
            onClose={() => {
                reset();
                callbacks.reset();
            }}
        >
            <ModalContent width={490}>
                <SectionTitle>Give Template a Name</SectionTitle>

                <ModalBody>
                    <TextField
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        variant="outlined"
                    />
                </ModalBody>

                <ModalActions>
                    <SecondaryButton
                        onClick={() => {
                            reset();
                            callbacks.revert();
                        }}
                    >
                        {state.isFirstStep ? 'Cancel' : 'Back'}
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            saveMappings({
                                variables: {
                                    mapping: {
                                        mapping: state.savedMapping,
                                        user_id,
                                        freight_type: state.freightType,
                                        name: templateName,
                                    },
                                },
                            });

                            reset();
                            callbacks.advance();
                        }}
                    >
                        Next
                    </PrimaryButton>
                </ModalActions>
            </ModalContent>
        </ResponsiveSidebarDialog>
    );
};

export default SaveTemplateModal;
