import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import React from 'react';
import { css } from '@emotion/react';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Body1 } from '../blocks';

const StagePallets = ({ pallets, loading, callbacks }) => {
    const onClose = () => {
        callbacks.stagePalletsInit(null);
    };

    const submit = () => {
        callbacks.stagePallets(pallets).then(() => onClose());
    };

    const isItem = pallets?.length === 1 && pallets[0].type === 'SINGLE';

    return (
        <NavResponsiveModal open={!!pallets?.length} onClose={onClose}>
            <ModalHeader title={'Stage'} />
            <ModalContent
                css={css`
                    width: 450px;
                `}
            >
                <Body1>{`Are you sure you want to mark this ${isItem ? 'item' : 'pallet'} as staged?`}</Body1>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading.palletLoading} onClick={onClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading.palletLoading} onClick={submit}>
                    Yes
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default StagePallets;
