import React, { useState, useEffect, useContext } from 'react';
import { Row, Button, Container, Card, Form, Col, Modal } from 'react-bootstrap';
import { LocationPermissionsTitle, LocationPermissionsTitleSpan, LocationPermissionsText } from './blocks';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';

export default function LocationPermissions({ device_settings }) {
    const capitalize = (string = '') => string.slice(0, 1).toUpperCase() + string.slice(1);
    return (
        <>
            <Card
                css={css`
                    margin-top: 30px;
                `}
            >
                <Card.Body
                    css={css`
                        padding: 28px 26px 28px 26px;
                    `}
                >
                    <LocationPermissionsTitle>
                        <LocationPermissionsTitleSpan>My Location Permissions</LocationPermissionsTitleSpan>
                    </LocationPermissionsTitle>
                    <Grid css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 30px;
                        `}>
                        <Grid item container justifyContent="space-between" >
                            <Grid item>
                                <LocationPermissionsText>
                                    Mobile app foreground location permission:
                                </LocationPermissionsText>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    text-align: right;
                                `}
                            >
                                <LocationPermissionsText
                                    text={device_settings?.location?.foreground?.status || 'Undetermined'}
                                >
                                    {capitalize(device_settings?.location?.foreground?.status || 'Undetermined')}
                                </LocationPermissionsText>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="space-between">
                            <Grid item>
                                <LocationPermissionsText>
                                    Mobile app background location permission:
                                </LocationPermissionsText>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    text-align: right;
                                `}
                            >
                                <LocationPermissionsText
                                    text={device_settings?.location?.background?.status || 'Undetermined'}
                                >
                                    {capitalize(device_settings?.location?.background?.status || 'Undetermined')}
                                </LocationPermissionsText>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>
        </>
    );
}
