import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { css } from '@emotion/react';
import { Tooltip } from '@material-ui/core';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { colors } from '@/styles';

function ButtonGroup({ enabled, callbacks }) {
    return (
        <>
            <Tooltip title="select zipcodes">
                <button
                    onClick={callbacks.toggleLasso}
                    css={css`
                        background-color: ${enabled ? colors.white.primary : colors.greens.primary} !important;
                        color: ${enabled ? '#000' : colors.white.primary};
                    `}
                >
                    <FormatShapesIcon
                        css={css`
                            font-size: 16px;
                        `}
                    />
                </button>
            </Tooltip>
            <Tooltip title="clear selected zipcodes">
                <button
                    onClick={callbacks.onClear}
                    css={css`
                        background-color: ${colors.greens.primary} !important;
                        color: ${colors.white.primary};
                    `}
                >
                    <BackspaceIcon
                        css={css`
                            font-size: 16px;
                            vertical-align: text-bottom;
                        `}
                    />
                </button>
            </Tooltip>
        </>
    );
}

class Lasso {
    constructor({ callbacks }) {
        this.map = null;
        this.box = null;
        this.start = null;
        this.callbacks = callbacks;
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this.root = createRoot(this.container);

        ['_drawBox', '_clearBox', '_onDown', '_onUp', '_onMove'].forEach((fn) => (this[fn] = this[fn].bind(this)));
    }

    _drawBox([startX, startY], [currX, currY]) {
        if (!this.box) {
            this.box = document.createElement('div');
            this.map.getCanvasContainer().appendChild(this.box);
        }

        const minX = Math.min(startX, currX);
        const maxX = Math.max(startX, currX);
        const minY = Math.min(startY, currY);
        const maxY = Math.max(startY, currY);

        Object.entries({
            transform: `translate(${minX}px, ${minY}px)`,
            width: `${maxX - minX}px`,
            height: `${maxY - minY}px`,
            background: colors.blues[0],
            opacity: 0.4,
            border: `1px dashed ${colors.blues[0]}`,
        }).forEach(([key, value]) => {
            this.box.style[key] = value;
        });
    }

    _clearBox() {
        if (this.box) {
            this.box.parentNode.removeChild(this.box);
            this.box = null;
        }
    }

    _onDown(e) {
        const canvas = this.map.getCanvasContainer();
        const bcr = canvas.getBoundingClientRect();
        this.start = [e.clientX - bcr.left - canvas.clientLeft, e.clientY - bcr.top - canvas.clientTop];

        this._drawBox(this.start, this.start);

        document.addEventListener('mousemove', this._onMove);
        document.addEventListener('mouseup', this._onUp);
    }

    _onUp(e) {
        const canvas = this.map.getCanvasContainer();
        const bcr = canvas.getBoundingClientRect();
        const curr = [e.clientX - bcr.left - canvas.clientLeft, e.clientY - bcr.top - canvas.clientTop];

        this.callbacks.onSelect(this.start, curr);

        this.start = null;
        document.removeEventListener('mousemove', this._onMove);
        document.removeEventListener('mouseup', this._onUp);

        this._clearBox();
    }

    _onMove(e) {
        const canvas = this.map.getCanvasContainer();
        const bcr = canvas.getBoundingClientRect();
        const curr = [e.clientX - bcr.left - canvas.clientLeft, e.clientY - bcr.top - canvas.clientTop];

        this._drawBox(this.start, curr);
    }

    enable() {
        const canvas = this.map.getCanvasContainer();
        this.root.render(<ButtonGroup enabled={true} callbacks={this.callbacks} />);

        Object.entries({ cursor: 'crosshair' }).forEach(([key, value]) => {
            canvas.style[key] = value;
        });
        this.map.boxZoom.disable();
        this.map.dragPan.disable();
        this.map.dragRotate.disable();

        canvas.addEventListener('mousedown', this._onDown, true);
    }

    disable() {
        const canvas = this.map.getCanvasContainer();
        this.root.render(<ButtonGroup callbacks={this.callbacks} />);

        Object.entries({ cursor: null }).forEach(([key, value]) => {
            canvas.style[key] = value;
        });

        canvas.removeEventListener('mousedown', this._onDown, true);

        document.removeEventListener('mousemove', this._onMove);
        document.removeEventListener('mouseup', this._onUp);

        this._clearBox();
        this.map.boxZoom.enable();
        this.map.dragPan.enable();
        this.map.dragRotate.enable();
    }

    onAdd(map) {
        this.map = map;
        this.root.render(<ButtonGroup callbacks={this.callbacks} />);

        return this.container;
    }

    onRemove(map) {
        this.root.unmount();
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }
}

function LassoControl({ map, location = 'top-right', callbacks }) {
    const lasso = useRef(null);
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        if (map) {
            lasso.current = new Lasso({
                callbacks: {
                    toggleLasso: () => {
                        setEnabled((prev) => !prev);
                    },
                    onClear: callbacks.onClear,
                    onSelect: callbacks.onSelect,
                },
            });
            map.addControl(lasso.current, location);
        }

        return () => {
            lasso.current?.disable();

            if (lasso.current) {
                map?.removeControl(lasso.current);
            }
        };
    }, [map]);

    useEffect(() => {
        if (enabled) {
            lasso?.current?.enable();
        } else {
            lasso?.current?.disable();
        }
    }, [enabled]);

    return null;
}

export default LassoControl;
