import { gql } from '@apollo/client';
import { CARRIER_INVOICE_FIELDS } from '@/graphql/queries/carrier_invoices';
import { PRICING_OVERRIDE_FIELDS } from '@/graphql/fragments/pricing_overrides';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const CARRIER_INVOICES = gql`
    ${CARRIER_INVOICE_FIELDS}
    ${CLIENT_FIELDS}
    ${ORDER_FIELDS}

    query GetAllCarrierInvoices($where: carrier_invoices_bool_exp!) {
        results: carrier_invoices(where: $where, order_by: { created_at: desc_nulls_last }, limit: 100) {
            ...CarrierInvoiceFields

            client {
                ...ClientFields
            }

            orders {
                ...OrderFields
            }
        }
    }
`;

export const GET_PENDING_ORDERS = gql`
    ${ORDER_FIELDS}
    query GetPendingOrders($start: timestamptz, $client_id: uuid!) {
        results: orders(
            where: {
                _and: [
                    { billing_completion_time: { _gte: $start } }
                    { carrier_id: { _eq: $client_id } }
                    { carrier_invoice_id: { _is_null: true } }
                    { oms: { _eq: false } }
                    { order_status: { _in: ["complete", "routedComplete"] } }
                ]
            }
        ) {
            ...OrderFields
        }
    }
`;

export const GET_PRICING_OVERRIDES = gql`
    ${PRICING_OVERRIDE_FIELDS}

    query GetPricingOverrides($client_id: uuid!) {
        mktplace: pricing_overrides(
            where: { _and: [{ oms: { _eq: false } }, { client_id: { _eq: $client_id } }] }
            order_by: { partner_client_id: desc }
        ) {
            ...PricingOverrideFields
        }

        internal: pricing_overrides(
            where: { _and: [{ oms: { _eq: true } }, { client_id: { _eq: $client_id } }] }
            order_by: { partner_client_id: asc_nulls_first }
        ) {
            ...PricingOverrideFields
        }
    }
`;
