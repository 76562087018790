import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { useApolloClient } from '@apollo/client';
import { colors } from '@/styles';
import { useClientUser } from '@/hooks';
import { Body } from '@/styles/blocks';
import { BiddingPrice } from '@/components/ShipmentForm/blocks';
import useAction from '@/utilities/useQuery';
import { post } from '@/utilities/onwardClient';
import { SUBMIT_COUNTER } from '@/constants/apiRoutes';
import { captureException } from '@sentry/react';

const useStyles = makeStyles({
    modalTitle: {
        color: colors.greys.secondary,
        fontWeight: '800',
        fontSize: '20px',
    },
    dialogContent: {
        width: 450,
    },
    modalLabel: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        color: '#2B2B2B',
        alignSelf: 'center',
        marginLeft: 20,
    },
});

export default function NewCounterModal({ open, onClose, bidDetails, setNotification }) {
    const { user_id } = useClientUser();
    const [proposal, setProposal] = useState(bidDetails?.isCarrier ? bidDetails.carrier_rate : bidDetails.shipper_rate);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { cache } = useApolloClient();

    const [submitCounter] = useAction(
        async () =>
            post(SUBMIT_COUNTER, {
                client_id: user_id,
                bid_id: bidDetails.bid_id,
                listing_id: bidDetails.listing_id,
                proposed_rate: proposal,
            }),
        {
            onComplete: ({ data }) => {
                if (data?.success) {
                    setNotification({
                        severity: 'success',
                        message: 'Counter successfully placed!',
                    });
                    cache.evict({ id: 'ROOT_QUERY', fieldName: 'bids' });
                } else {
                    setNotification({
                        severity: 'error',
                        message: data?.error || 'Error submitting counter. Please try again.',
                    });
                }
                onClose();
                setLoading(false);
            },
            onError: (err) => {
                console.error(err);
                captureException(err);
                setNotification({
                    severity: 'error',
                    message: 'Error submitting. Please try again.',
                });
                onClose();
                setLoading(false);
            },
        }
    );

    return (
        <>
            <Dialog
                className="carrier-review-modal"
                open={open}
                onClose={onClose}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            color: 'black',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
                <DialogTitle style={{ margin: 0, padding: '16px 24px 0 24px' }}>
                    <Typography variant="h6" className={classes.modalTitle}>
                        Place Counter Bid
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Body>
                        {bidDetails?.isCarrier
                            ? `Shipper's most recent counter: $${
                                  bidDetails?.counterSequence[0]?.carrier_rate.toFixed(2) || null
                              }`
                            : `Carrier's most recent bid/counter: $${
                                  bidDetails.counterSequence.length
                                      ? bidDetails.counterSequence[0].shipper_rate.toFixed(2)
                                      : bidDetails.shipper_rate.toFixed(2)
                              }`}
                    </Body>
                    <BiddingPrice
                        value={proposal}
                        onChange={(val) => setProposal(val)}
                        sliderMin={0}
                        sliderSuggested={proposal}
                        sliderMax={bidDetails.shipper_rate * 2}
                        label="Counteroffer Amount"
                    />
                </DialogContent>
                <DialogActions
                    className="customer-track-rating-modal d-flex justify-content-between"
                    style={{ padding: '24px' }}
                >
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        style={{
                            minWidth: '110px',
                        }}
                        disabled={loading}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#59B863',
                            color: 'white',
                            minWidth: '110px',
                        }}
                        onClick={() => {
                            setLoading(true);
                            submitCounter();
                        }}
                        disabled={loading}
                    >
                        Send Counter
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
