import {
    ADVANCED_SCHEDULING,
    PDF_IMPORT_V1,
    PLAN_PAGE_LOADBOARD,
    PLAN_PAGE_NETWORK_ROUTES,
    BROKER,
    CUSTOM_CUSTOMER_COMMS_RANGE,
    ROCKET_MM_INTEGRATION,
    WMS,
    CDS_TIMEFRAME_EXPORT,
} from '@/constants/featureFlags';

export const featureFlags = [
    { name: 'saas-v1', description: 'Enables access to Software-as-a-Service components (Planning Page, etc.)' },
    {
        name: 'accounting-v2',
        description: 'Enables access to version 2 of the accounting features (CompletedOrders, Invoices)',
    },
    { name: 'plan-reschedule-v1', description: 'Enables access to the re-scheduling feature.' },
    {
        name: WMS,
        description: 'Enables full access to WMS features for internal orders',
    },
    {
        name: 'disable-customer-availability',
        description: 'Disables customer availability sms/email upon order confirmation',
    },
    {
        name: 'ltl-marketplace',
        description: 'Enables access to submitting LTL orders to the marketplace',
    },
    {
        name: PLAN_PAGE_LOADBOARD,
        description: 'Enables Load Board display on the plan page',
    },
    {
        name: PLAN_PAGE_NETWORK_ROUTES,
        description: 'Enables Network Routes toggle on the plan page',
    },
    {
        name: 'plan-page-recommendations',
        description: 'Enables searching for order recommendations on the plan page',
    },
    {
        name: 'disable-review-sms-messages',
        description: 'Disables customer rating/review sms messages on order completion',
    },
    {
        name: 'sms-alert-next-stop-customers',
        description: 'An sms alert for customers when they are the next stop on a route',
    },
    {
        name: 'custom-timeframes-beta',
        description: 'Allow users to select custom timeframes in planning.',
    },
    {
        name: 'plan-page-order-pickups',
        description: 'Enables Pickups to display on the plan page for internal orders',
    },
    {
        name: 'plan-page-marker-clustering',
        description: 'Clusteres nearby markers on the plan page',
    },
    {
        name: 'plan-page-mapbox',
        description:
            'Experimental MapBox features on the plan page. This includes route optimization around locked timeframes.',
    },
    {
        name: 'plan-page-unscheduled',
        description: 'Show unscheduled orders in Planning.',
    },
    {
        name: 'hide-market-rates',
        description: `Should be turned on for accounts with contract rates. Hides Onward Market rates for the user across the platform and instead says 'Contract Rates'. If an admin has overridden the order's rates via the admin load board, these rates will display instead of the 'Contract Rates' text for the user.`,
    },
    {
        name: 'auto-assign-by-zip',
        description: `Auto assign API orders to carriers by the consignee zip code. If a carrier is not found for that zip code the order will be rejected.`,
    },
    {
        name: 'auto-assign-carrier',
        description: `Some shippers will have contracts with a certain carrier, and will use them exclusively. You can set that carrier here, and all orders created by the shipper will be automatically assigned to them.`,
    },
    {
        name: 'auto-assign-mp-comms',
        description: `This re-enables some marketplace related communications that are typically disabled for orders automatically assign to a carrier. This includes an internal slack message and an email to the shipper indicating that the order has been submitted to the marketplace.`,
    },
    {
        name: 'hide-tracking-link-phone',
        description: `By default tracking links include a phone number to the store for customers to contact in case of any issues. Enabling this flag removes the phone number portion of the tracking link.`,
    },
    {
        name: 'curbside-no-autoconfirm',
        description: `By default curbside deliveries are auto confirmed. Be enabling this flag, curbside deliveries will no longer be auto confirmed.`,
    },
    {
        name: 'autoconfirm-all-stops',
        description: `This flag will autocomplete all stops and bypass the need to manually confirm stop timeframes.`,
    },
    {
        name: 'bidding-disabled',
        description: `This flag disables auction features for the account. Shippers can still create marketplace orders but carriers will only be able to claim`,
    },
    {
        name: 'bidding-comms-disabled',
        description: `This flag disables additional bidding communications. This includes emails to the shipper when a carrier has claimed a load or placed a bid, and emails to either party when the other side has submitted or awarded a bid or counter-offer. For bidding comms that apply to both shippers and carriers, this feature flag must be enabled for each receiving party.`,
    },
    {
        name: 'customer-emails-with-sms',
        description:
            'Whenever SMS communications are sent to customers, an email will be sent to the customer as well with the corresponding information.',
    },
    {
        name: 'overnight-routes',
        description: `This flag allows overnight stops/routes. It displays a toggle in dispatch planning where a dispatcher may mark a warehouse pickup stop as 'overnight', in the case of a driver loading the truck up the day before delivery. For routes with an overnight pickup, a start location is required (would be the driver's hotel they are staying at overnight), and route optimization uses this start location, whilst ignoring the overnight warehouse pickup stop from optimization.`,
    },
    {
        name: 'disable-tracking-comms',
        description:
            'When this flag is turned on, the carrier will not have tracking links sent to the customers for orders that they dispatch.',
    },
    {
        name: 'shipper-bidding-v1',
        description: `This flag enables shippers to view and place bids on other shippers' listings.`,
    },
    {
        name: 'customerNewShipmentForm',
        description: `This flag enables the shipper to list their locations on the customer new shipment form.`,
    },
    {
        name: BROKER,
        description: `This flag marks the account as a broker and enables broker feature for the account.`,
    },
    {
        name: ADVANCED_SCHEDULING,
        description: `Advanced scheduling for allocating regions / scheduling resources for shippers / carriers`,
    },
    {
        name: 'sales-representative-comms',
        description: `When comms are sent on behalf of an account with this flag, their phone number is replaced with "Please contact your sales representative"`,
    },
    {
        name: '40-min-tracking-comms',
        description: `For accounts with this flag enabled, tracking links will replace the text saying "15-30 minutes" with "approximately 40 minutes".`,
    },
    {
        name: PDF_IMPORT_V1,
        description: `allow user to import pdf orders`,
    },
    {
        name: CDS_TIMEFRAME_EXPORT,
        description: `Allow users to export a CSV of timeframes on the plan page in a format that may be uploaded into CDS.`,
    },
    {
        name: CUSTOM_CUSTOMER_COMMS_RANGE,
        description: `Allows user to specify a time range on their account profile page in which certain customer communications will only be sent within this range. Default otherwise is 8am to 8pm.`,
    },
    {
        name: 'disable-admin-emails',
        description: `All emails to an account are also sent to all admin users for that account. This flag disables that functionality so that only the main account email will receive emails sent to an account.`,
    },
    {
        name: 'disable-received-comms',
        description: 'Disable emails and in app notifications when an order has been marked as received',
    },
    {
        name: ROCKET_MM_INTEGRATION,
        description: `Accounts with Rocket middle mile integration may turn this flag on so a button will display on orders for Rocket personel to manually fetch new order status updates for the orders we have sent them.`,
    },
    {
        name: 'api-disable-query',
        description: 'Disables the query APIs for this client',
    },
    {
        name: 'api-filter-query-locked-routes',
        description: 'Filters route query APIs to only locked routes',
    },
    {
        name: 'delay-predelivery-comms',
        description: `Don't receive predelivery sms until after the order has been received by the carrier for marketplace orders`,
    },
    {
        name: 'business-type-autoconfirm',
        description: `Automatically confirm timeframes for business type deliveries.`,
    },
    {
        name: 'hide-driver-phone-number',
        description: `Hide driver's phone number from the customer tracking links.`,
    },
    {
        name: 'require-digital-signature',
        description: `Drivers will be required to obtain a digital signature for an order before being able to mark delivery as complete`,
    },
    {
        name: 'require-signature-name',
        description: `Drivers will be required to obtain the customer's typed out name for an order before being able to mark delivery as complete`,
    },
];
