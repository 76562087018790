import React, { createContext, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { captureException } from '@sentry/react';
import { useClientUser } from '@/hooks';
import { UPDATE_WAREHOUSE_RACKS } from './graphql';
import { useMutation } from '@apollo/client';
import { CONFIG } from '@/config';

import { stringToRackNumber, rackNumberToString, generate3DViewerData, generate2DViewerData } from './utils';
import { printBinLabels } from './printBinLabels';
import _ from 'lodash';

import { loadSmplrJs } from '@smplrspace/smplr-loader';

export const WarehousingContext = createContext();

const onwardSpaceInfo = {
    spaceId: 'd8b45c77-38b0-40ee-a2de-56701234c543',
    clientToken: CONFIG.SMPLR_SPACE_TOKEN,
    containerId: 'smplr-container',
};

const WarehousingContextProvider = ({ children }) => {
    const { locations } = useClientUser();

    // Viewer setup
    const [smplr, setSmplr] = useState(null);
    const [space, setSpace] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [mode, setMode] = useState('3d');
    const [view, setView] = useState(null);
    const [hideTooltips, setHideTooltips] = useState(false);
    const [hideSpecificTooltip, setHideSpecificTooltip] = useState(false);
    const [tooltipState, setTooltipState] = useState([]);
    const repositionTooltip = useRef();

    const centerCamera = () => {
        if (!space) return;
        space.setCameraPlacement({
            animate: true,
            animationDuration: 0.9,
            alpha: () => {
                return -(Math.PI / 2);
            },
            beta: () => {
                return (Math.PI / 20) * 7;
            },
            target: {
                x: () => {
                    return 0;
                },
                y: () => {
                    return 0;
                },
                z: () => {
                    return 0;
                },
            },
            radius: () => {
                return 65;
            },
        });
    };

    const onModeChange = useCallback(
        (newMode) => {
            if (newMode === mode) return;
            setMode(newMode);
        },
        [space]
    );
    const onReady = useCallback((space) => setSpace(space), []);

    useEffect(() => {
        setHideTooltips(false);
        setHideSpecificTooltip(false);
    }, [tooltipState]);

    useEffect(() => {
        loadSmplrJs('umd')
            .then((smplr) => {
                const smplrSpace = new smplr.Space(onwardSpaceInfo);
                setSmplr(smplrSpace);
            })
            .catch((error) => {
                console.error(error);
                captureException(error);
            });
    }, []);

    useEffect(() => {
        if (!smplr) return;

        smplr.startViewer({
            preview: false,
            onError: (error) => {
                console.error('Could not start viewer', error);
                captureException(error);
            },
            mode: mode,
            onModeChange: onModeChange,
            onReady: () => {
                onReady(smplr);
            },
            onResize: () => {
                repositionTooltip.current && repositionTooltip?.current();
            },
            cameraPlacement: {
                radius: 85,
            },
        });
    }, [smplr]);

    useEffect(() => {
        if (!space) return;

        space.startViewer({
            preview: false,
            onError: (error) => {
                console.error('Could not start viewer', error);
                captureException(error);
            },
            mode: mode,
            onModeChange: onModeChange,
            // onReady: () => {
            // },
            onResize: () => {
                repositionTooltip.current && repositionTooltip?.current();
            },
            cameraPlacement: {
                radius: 85,
            },
        });
        // onReady doesn't work on subsequent startViewer calls for some reason, so have to do a timeout and hope it is ready by then.
        if (mode === '3d') {
            setTimeout(() => {
                centerCamera();
            }, 1000);
        }
    }, [mode]);

    useEffect(() => {
        if (!space) return;
        space.addEventListener('CameraStartedMoving', () => {
            setHideTooltips(true);
        });
        space.addEventListener('CameraStoppedMoving', () => {
            repositionTooltip?.current();
        });

        return () => {
            if (!space) return;
            space.clearAllEventListeners();
        };
    }, [space]);

    // Editing and Customization
    const [selectedRack, setSelectedRack] = useState(null);
    const [rotation, setRotation] = useState(0);
    const [flipped, setFlipped] = useState(false);
    const [rackDataChanges, setRackDataChanges] = useState({});
    const [addingRackSettings, setAddingRackSettings] = useState(null);
    const initialRack = {
        numRacks: 1,
        horizontalRows: 4,
        verticalRows: 4,
        rackDepth: 2,
    };
    const [defaultRackSettings, setDefaultRackSettings] = useState(initialRack);
    const [addingPoolSettings, setAddingPoolSettings] = useState(null);
    const intiateAddingRack = () => {
        if (defaultRackSettings?.customName) {
            let { customName, ...nameRemovedDefault } = defaultRackSettings;
            setAddingRackSettings(nameRemovedDefault);
        } else {
            setAddingRackSettings(defaultRackSettings);
        }
    };

    useEffect(() => {
        setSelectedRack(null);
    }, [selectedWarehouse]);

    useEffect(() => {
        setHideTooltips(true);
    }, [mode]);

    useEffect(() => {
        if (selectedWarehouse) {
            const currentlySelected = (locations || []).find((l) => l.location_id === selectedWarehouse.location_id);
            setSelectedWarehouse(currentlySelected);
        }
    }, [locations]);

    // By rackIdentifier ('A', 'B', ... 'AA', 'AB', etc.)
    const existingRackData = useMemo(() => {
        const racks = selectedWarehouse?.warehouse_racks || [];
        return Object.fromEntries(
            racks.map((r) => {
                return [r.rackIdentifier, r];
            })
        );
    }, [selectedWarehouse]);

    // By rackIdentifier ('A', 'B', ... 'AA', 'AB', etc.)
    const combinedData = useMemo(() => {
        let combined = {
            ...existingRackData,
            ...rackDataChanges,
        };
        // Remove empty objects
        return _.omitBy(combined, (obj) => {
            return !obj?.rackIdentifier;
        });
    }, [rackDataChanges, existingRackData]);

    const createRacks = useCallback(
        ({ numRacks, horizontalRows: rows, verticalRows: columns, rackDepth, ...rest }) => {
            let maxRackNumber = -1;
            for (const rack of Object.values(combinedData)) {
                maxRackNumber = Math.max(maxRackNumber, rack.rackNumber);
            }
            maxRackNumber++;

            const racks = [];
            for (let i = 0; i < numRacks; i++) {
                const rackNumber = i + maxRackNumber;
                const rackLetter = rackNumberToString(i + maxRackNumber);

                const xOffset = 0;
                const zOffset = 5;

                const newRack = {
                    rows: rows,
                    columns: columns,
                    rackDepth: rackDepth,
                    rotation: 0,
                    flipped: false,
                    rackNumber: rackNumber,
                    rackIdentifier: rackLetter,
                    baseX: i * xOffset,
                    baseZ: i * zOffset,
                    ...(rest?.customName ? {
                        customName: rest.customName
                    } : {}),
                };
                racks.push(newRack);
            }
            return racks;
        },
        [combinedData]
    );

    const addRack = () => {
        const newRacks = createRacks(addingRackSettings);
        const newRacksByRackId = Object.fromEntries(
            newRacks.map((r) => {
                return [r.rackIdentifier, r];
            })
        );

        setRackDataChanges((prev) => {
            let updatedRacks = {
                ...prev,
                ...newRacksByRackId,
            };
            return updatedRacks;
        });

        // Make default settings for adding a rack equal to last settings used for convenience.
        setDefaultRackSettings(addingRackSettings);
        setAddingRackSettings(null);
    };

    const viewerData = useMemo(() => {
        const allRackData = Object.values(combinedData);

        if (view === 'edit') {
            // Generate data for 2D view
            const viewData = allRackData.reduce(
                ([rackDataAccum, itemDataAccum, editDataAccum, labelDataAccum], baseRackData) => {
                    const [currentEditData, currentLabelData] = generate2DViewerData(baseRackData);
                    return [
                        [...rackDataAccum, ...[]],
                        [...itemDataAccum, ...[]],
                        [...editDataAccum, ...currentEditData],
                        [...labelDataAccum, ...currentLabelData],
                    ];
                },
                [[], [], [], []]
            );

            if (!!selectedRack) {
                const newlyGeneratedSelectedRack = viewData[2].find((i) => i.id === selectedRack.id);
                setSelectedRack(newlyGeneratedSelectedRack);
            }

            return viewData;
        } else {
            // Generate data for 3D view
            const viewData = allRackData.reduce(
                ([rackDataAccum, itemDataAccum, editDataAccum, labelDataAccum], baseRackData) => {
                    const [currentRackData, currentItemData, currentLabelData] = generate3DViewerData(baseRackData);
                    return [
                        [...rackDataAccum, ...currentRackData],
                        [...itemDataAccum, ...currentItemData],
                        [...editDataAccum, ...[]],
                        [...labelDataAccum, ...currentLabelData],
                    ];
                },
                [[], [], [], []]
            );
            return viewData;
        }
    }, [combinedData, view]);

    useEffect(() => {
        if (!space || !viewerData?.length) {
            return;
        }

        if (view === 'view') {
            space.addDataLayer({
                id: 'shelves',
                type: 'polygon',
                data: viewerData[0],
                baseHeight: (i) => i.y,
                height: (i) => i.height,
                color: (i) => '#4b4b4b',
                // tooltip: (i) => i.tooltip,
            });
            space.addDataLayer({
                id: 'items',
                type: 'polygon',
                data: viewerData[1],
                baseHeight: (i) => i.y,
                height: (i) => i.height,
                tooltip: (i) => i.tooltip,
                color: (i) => i.color,
            });
        } else if (view === 'edit') {
            space.addDataLayer({
                data: viewerData[2],
                id: 'floorplans',
                type: 'polygon',
                // tooltip: (i) => i.text,
                color: (i) => {
                    return i?.id === selectedRack?.id ? '#4b7646' : '#787878';
                },
                onClick: (rackData) => {
                    if (selectedRack?.id === rackData?.id) {
                        setSelectedRack(null);
                    } else {
                        setSelectedRack(rackData);
                    }
                },
                onDrag: ({ data }) => {
                    setHideSpecificTooltip(`${data?.id}-point`);
                },
                onDrop: ({ data, coordinates }) => {
                    const cornerOffsetX = data?.startingCoord?.x - data?.coordinates[0]?.x;
                    const cornerOffsetY = data?.startingCoord?.z - data?.coordinates[0]?.z;

                    const newRack = {
                        ...data?.baseRackData,
                        baseX: coordinates[0].x + cornerOffsetX,
                        baseZ: coordinates[0].z + cornerOffsetY,
                    };

                    setRackDataChanges((prev) => ({
                        ...prev,
                        [data.baseRackData.rackIdentifier]: newRack,
                    }));

                    setSelectedRack({
                        ...data,
                        baseRackData: newRack,
                    });
                },
                disableReshape: true,
            });
        }

        const layerController = space.addDataLayer({
            data: viewerData?.[3] || [],
            id: 'labels',
            type: 'point',
            shape: 'sphere',
            anchor: 'center',
            diameter: 0,
            alpha: 0,
        });

        repositionTooltip.current = () => {
            const tooltipData = (viewerData?.[3] || []).reduce((accum, d) => {
                const tooltipPosition = layerController.getElementPositionOnScreen(d.id);
                if (tooltipPosition.screenX < 10 || tooltipPosition.screenY < 10) return accum;
                return [
                    ...accum,
                    {
                        ...d,
                        ...tooltipPosition,
                    },
                ];
            }, []);
            setTooltipState(tooltipData);
        };

        return () => {
            if (view === 'view') {
                space.removeDataLayer('shelves');
                space.removeDataLayer('items');
            } else if (view === 'edit') {
                space.removeDataLayer('floorplans');
            }
            space.removeDataLayer('labels');
        };
    }, [space, view, viewerData, selectedRack]);

    useEffect(() => {
        repositionTooltip?.current && repositionTooltip?.current();
    }, [combinedData]);

    const [notification, setNotification] = useState(null);
    const setError = (error, userMsg) => {
        captureException(error);
        setNotification({
            severity: 'error',
            message: userMsg || 'Error processing request',
        });
    };

    const deleteRack = () => {
        setAddingRackSettings(null);
        let maxRackLetterUpdate = -1;
        const updateRackChanges = Object.fromEntries(
            Object.entries(combinedData).reduce((accum, [id, data]) => {
                if (data.rackNumber > selectedRack.baseRackData.rackNumber) {
                    maxRackLetterUpdate = Math.max(maxRackLetterUpdate, data.rackNumber);
                    const newLetter = rackNumberToString(data.rackNumber - 1);
                    return [
                        ...accum,
                        [
                            newLetter,
                            {
                                ...data,
                                rackNumber: data.rackNumber - 1,
                                rackIdentifier: newLetter,
                            },
                        ],
                    ];
                }
                return accum;
            }, [])
        );

        if (maxRackLetterUpdate >= 0) {
            updateRackChanges[rackNumberToString(maxRackLetterUpdate)] = {};
        } else {
            updateRackChanges[selectedRack.baseRackData.rackIdentifier] = {};
        }

        setRackDataChanges((prev) => ({
            ...prev,
            ...updateRackChanges,
        }));

        setSelectedRack(null);
    };

    const [updateWarehouseRacks, { loading: loadingSaving }] = useMutation(UPDATE_WAREHOUSE_RACKS, {
        onError: (err) => {
            setNotification({
                severity: 'error',
                message: 'Error saving warehouse changes.',
            });
            console.error(err);
            captureException(err);
        },
    });

    const saveChanges = async () => {
        const racksToSave = Object.values(combinedData).reduce((rackAccum, d) => {
            if (d.rackIdentifier) {
                return [...rackAccum, d];
            }
            return rackAccum;
        }, []);

        let response = await updateWarehouseRacks({
            variables: {
                location_id: selectedWarehouse.location_id,
                warehouse_racks: racksToSave,
            },
            onCompleted: (d) => {
                setRackDataChanges({});
            },
        });

        return response;
    };

    const handlePrintLabels = async () => {
        if (!selectedWarehouse) {
            // Eventually make this a modal instead where you select a warehouse
            // notification - you must select a warehouse first
        }

        // Save any potential pending changes first, so they're not printing unsaved edits
        let response = await saveChanges();
        const rackArray = response?.data.update_locations_by_pk?.warehouse_racks;
        printBinLabels(rackArray, selectedWarehouse);
    };

    const addPool = () => {
        const defaultPoolSettings = {
            numRacks: 1,
            horizontalRows: 1,
            verticalRows: 1,
            rackDepth: 1,
        };
        const poolData = createRacks(defaultPoolSettings);
        poolData[0] = {
            ...poolData[0],
            isPool: true,
            poolWidth: addingPoolSettings?.poolWidth || 5,
            poolDepth: addingPoolSettings?.poolDepth || 5,
            customName: addingPoolSettings?.customName || null,
        }
        const newRacksByRackId = Object.fromEntries(
            poolData.map((r) => {
                return [r.rackIdentifier, r];
            })
        );

        setRackDataChanges((prev) => {
            let updatedRacks = {
                ...prev,
                ...newRacksByRackId,
            };
            return updatedRacks;
        });

        setAddingPoolSettings(null);
    }

    return (
        <WarehousingContext.Provider
            value={{
                locations: locations || [],
                customization: false,
                intiateAddingRack,
                addRack,
                addingRackSettings,
                setAddingRackSettings,
                onModeChange,
                onReady,
                setRotation,
                setFlipped,
                rotation,
                flipped,
                selectedWarehouse,
                setSelectedWarehouse,
                setMode,
                setView,
                selectedRack,
                setSelectedRack,
                view,
                tooltipState,
                setRackDataChanges,
                deleteRack,
                hideTooltips,
                hideSpecificTooltip,
                combinedData,
                saveChanges,
                loadingSaving,
                handlePrintLabels,
                setNotification,
                notification,
                addPool,
                setAddingPoolSettings,
                addingPoolSettings,
            }}
        >
            {children}
        </WarehousingContext.Provider>
    );
};

export const withWarehousingContext = (Component) => (props) =>
    (
        <WarehousingContextProvider>
            <Component {...props} />
        </WarehousingContextProvider>
    );
