import React, { useEffect, useState, useContext } from 'react';
import { Grid, Button, createTheme, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import Image from 'react-bootstrap/Image';
import * as Sentry from '@sentry/react';
import { FileEarmarkPdf } from 'react-bootstrap-icons';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import FileDropZone from '@/components/OrderDetailsPage/FileDropZone';
import { ModalContext } from '.';

function UploadBOL(props) {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;
    const [fileArray, setFileArray] = useState([]);
    const [fileArrayWithTypes, setFileArrayWithTypes] = useState([]);
    const [fileIndex, setFileIndex] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (typeof order.bol_file === 'string' && order.bol_file !== '') {
            setFileArray([order.bol_file]);
        } else {
            setFileArray(order.bol_file);
        }
    }, [order]);

    useEffect(() => {
        if (fileArray) {
            addFileTypes();
        }
    }, [fileArray]);
    FileDropZone;

    const handleFileDelete = async () => {
        try {
            let updatedFileArray = [...fileArray];
            updatedFileArray.splice(fileIndex, 1);

            callbacks.modifyOrder({
                bol_file: updatedFileArray,
            });
            setFileArray(updatedFileArray);
            setFileIndex(null);
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
        }
        handleClose();
    };

    const handleFileAdd = async (file, fileType) => {
        if (file) {
            try {
                const fileUrl = URL.createObjectURL(file);
                const fbFileUrl = await uploadPhotoAsync(fileUrl, 'bol_file', fileType);
                const fbFile = fbFileUrl;
                const updatedFileArray = fileArray ? [...fileArray, fbFile] : [fbFile];

                callbacks.modifyOrder({
                    bol_file: updatedFileArray,
                });

                setFileArray(updatedFileArray);
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        }
    };

    const addFileTypes = async () => {
        let resultArray = [];

        for (const file of fileArray) {
            let type = await checkType(file);
            resultArray.push([file, type]);
        }

        setFileArrayWithTypes(resultArray);
    };

    const checkType = async (fileSource) => {
        try {
            const res = await fetch(fileSource.url ? fileSource.url : fileSource);
            const blob = await res.blob();

            if (blob.type === 'application/pdf') {
                return 'pdf';
            } else if (blob.type === 'image/jpeg') {
                return 'jpg';
            } else if (blob.type === 'image/png') {
                return 'png';
            } else {
                return blob.type;
            }
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    };

    function ConfirmDeleteDialog() {
        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Are you sure you want to delete this file?'}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleFileDelete} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    const theme = createTheme({
        palette: {
            text: {
                secondary: '#4c4c4c',
            },
            primary: {
                main: '#59b863',
                contrastText: '#fff',
            },
            secondary: {
                main: '#4c4c4c',
                contrastText: '#fff',
            },
        },
        typography: {
            fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
            h6: {
                color: '#8d8d8d',
                fontWeight: '850',
            },
        },
    });

    return (
        <>
            <Grid container spacing={1}>
                {fileArrayWithTypes &&
                    fileArrayWithTypes.map((file, i) => {
                        const fileUrl = file[0]?.hasOwnProperty('url') ? file[0].url : file[0];
                        if (file[1] === 'pdf') {
                            return (
                                <Grid item key={i} className="d-flex position-relative">
                                    <div
                                        onClick={() => {
                                            setFileIndex(i);
                                            handleClickOpen();
                                        }}
                                        style={{
                                            backgroundColor: 'black',
                                            cursor: 'pointer',
                                            opacity: '0.6',
                                            height: '25px',
                                            width: '25px',
                                            position: 'absolute',
                                            top: '5px',
                                            left: '5px',
                                            borderRadius: '50%',
                                            zIndex: 2,
                                        }}
                                    >
                                        <Clear
                                            style={{
                                                position: 'relative',
                                                zIndex: 3,
                                                top: '-2px',
                                                left: '1px',
                                                color: 'white',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </div>
                                    )
                                    <a href={fileUrl} target="_blank">
                                        <FileEarmarkPdf size={100} />
                                    </a>
                                </Grid>
                            );
                        } else
                            return (
                                <Grid item key={i} className="d-flex position-relative">
                                    <div
                                        onClick={() => {
                                            setFileIndex(i);
                                            handleClickOpen();
                                        }}
                                        style={{
                                            backgroundColor: 'black',
                                            cursor: 'pointer',
                                            opacity: '0.6',
                                            height: '25px',
                                            width: '25px',
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            borderRadius: '50%',
                                            zIndex: 2,
                                        }}
                                    >
                                        <Clear
                                            style={{
                                                position: 'relative',
                                                zIndex: 3,
                                                top: '-2px',
                                                left: '1px',
                                                color: 'white',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </div>
                                    <a href={fileUrl} target="_blank">
                                        <Image
                                            style={{
                                                height: '100px',
                                                width: '100px',
                                            }}
                                            src={fileUrl}
                                        />
                                    </a>
                                </Grid>
                            );
                    })}

                {fileArrayWithTypes.length < 1 && (
                    <FileDropZone
                        customLabel={'Upload Order BOL'}
                        customStyle={{
                            height: '100px',
                            width: '925px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            outline: '1px solid #c4c4c4',
                            fontFamily: 'Montserrat',
                            cursor: 'pointer',
                        }}
                        handleFileAdd={handleFileAdd}
                    />
                )}
            </Grid>
            <ConfirmDeleteDialog />
        </>
    );
}

export default UploadBOL;
