import React, { useContext, useMemo } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';
import { Context } from './store';
import { ACCOUNTING_STAGES } from './constants';

const styled = withStyles({
    modal: {
        position: 'absolute',
        top: 'calc(50% -  95px)',
        left: 'calc(50% - 227px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0px',
        width: '502px',
    },
});

function Footer({ state, callbacks }) {
    const { selectedOrders, filters, subTab } = state;
    const { setStage, handleReadyToInvoice } = callbacks;

    const isValid = useMemo(() => {
        return selectedOrders.length > 0 && filters?.completedFrom && filters?.completedTo;
    }, [selectedOrders, filters]);

    const handleReadyClick = (selectedOrders) => {
        const orderIds = selectedOrders.map((order) => order.order_id);
        handleReadyToInvoice(orderIds);
    };

    return (
        <>
            {subTab === 1 && (
                <Grid
                    container
                    css={css`
                        padding: 1rem;
                        background-color: white;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        <PrimaryButton disabled={!isValid} onClick={() => setStage(ACCOUNTING_STAGES.CREATE_INVOICE)}>
                            Create Invoice
                        </PrimaryButton>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-left: 1rem;
                        `}
                    >
                        <PrimaryButton
                            disabled={!isValid}
                            onClick={() => setStage(ACCOUNTING_STAGES.CREATE_MULTI_INVOICES)}
                        >
                            Create Multiple Invoices
                        </PrimaryButton>
                    </Grid>
                </Grid>
            )}
            {subTab === 0 && (
                <Grid
                    container
                    css={css`
                        padding: 1rem;
                        background-color: white;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        <PrimaryButton
                            disabled={!isValid}
                            onClick={() => {
                                handleReadyClick(selectedOrders);
                            }}
                        >
                            Approve for Invoicing
                        </PrimaryButton>
                    </Grid>
                </Grid>
            )}
            {/* {subTab === 2 && null} */}
        </>
    );
}

export default styled(Footer);
