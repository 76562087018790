import { gql } from '@apollo/client';
import { CARRIER_INVOICE_FIELDS } from '@/graphql/queries/carrier_invoices';

export const CARRIER_INVOICE = gql`
    ${CARRIER_INVOICE_FIELDS}
    query CarrierInvoice($invoice_id: uuid!) {
        result: carrier_invoices_by_pk(carrier_invoice_id: $invoice_id) {
            ...CarrierInvoiceFields
            partner_client {
                client_id
                business_name
            }

            orders: shipper_orders {
                carrier_id
                dropoff_city
                dropoff_name
                dropoff_state
                dropoff_zip
                delivery_date
                order_type
                invoice_class
                order_id
                order_number
                order_revenue
                order_revenue_adjustments
                order_status
                pickup_city
                pickup_name
                pickup_state
                pickup_zip
                po_number
                price_breakdown
                qbo_tag
                shipper_id
            }
        }
    }
`;
