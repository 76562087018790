import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';

const envUrls = {
    development: 'http://localhost:5001/onward-new/us-central1',
    production: 'https://us-central1-onward-new.cloudfunctions.net',
    staging: 'https://us-central1-onward-staging.cloudfunctions.net',
};

const OnwardClient = axios.create({
    baseURL: envUrls[process.env.REACT_APP_ENVIRONMENT || 'development'],
});

export const get = async (url, options) => {
    const token = await getIdToken(getAuth().currentUser);

    return OnwardClient.get(url, {
        ...options,
        headers: {
            ...(options?.headers || {}),
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postUnauth = async (url, data = {}, options = {}) => {
    return OnwardClient.post(url, data, options);
};

export const post = async (url, data = {}, options = {}) => {
    let headers = options.headers || {};
    if (!headers['Authorization']) {
        const token = await getIdToken(getAuth().currentUser);
        headers['Authorization'] = `Bearer ${token}`;
    }

    return OnwardClient.post(url, data, {
        ...options,
        headers,
    });
};
