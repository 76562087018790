import React, { useEffect, useState } from 'react';
import handleGeocode from '@/utilities/handleGeocode';
import { gql } from '@apollo/client';
import { LOCATION_FIELDS } from '@/graphql/queries/locations';
import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';

export const UPDATE_LOCATIONS_WITH_GEODATA = gql`
    ${LOCATION_FIELDS}

    mutation UpdateLocationWithGeodata($location_updates: [locations_updates!]! = []) {
        update_locations_many(updates: $location_updates) {
            affected_rows
            returning {
                ...LocationFields
            }
        }
    }
`;

export const checkAndGeocodeLocations = (locations = []) => {
    const [checked, setChecked] = useState(false);
    const [updateLocations] = useMutation(UPDATE_LOCATIONS_WITH_GEODATA);

    useEffect(() => {
        (async () => {
            if (checked || !locations.length || locations.every((l) => l.lat && l.lng)) return;

            const location_updates = [];

            await Promise.all(
                locations.map(async (loc) => {
                    if (loc.lat && loc.lng) return;

                    const { lat, lng } = await handleGeocode({
                        address: `${loc.business_address}, ${loc.business_city}, ${loc.business_state}, ${loc.business_zip}`,
                    });
                    if (lat && lng) {
                        location_updates.push({
                            where: { location_id: { _eq: loc.location_id } },
                            _set: {
                                lat: lat,
                                lng: lng,
                            },
                        });
                    }
                    return;
                })
            );

            await updateLocations({
                variables: {
                    location_updates,
                },
                onError: (err) => {
                    console.error(err);
                    captureException(err);
                },
            });

            setChecked(true);
        })();
    }, [locations, checked]);
};
