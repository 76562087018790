import React, { useState, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton, FormControlLabel, CircularProgress } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { colors } from '@/styles';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';

import { local, Header1, BodyLabel1, BodyDescription1 } from '../blocks';

import Map from './Map';
import Footer from './Footer';
import OrderSummary from './OrderSummary';

import { CONFIG } from '@/config';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ProgressBar from './ProgressBar';

const Checkout = ({ state, callbacks }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [accepted, setAccepted] = useState(false);

    return (
        <>
            <Grid
                item
                css={css`
                    margin-top: 20px;
                `}
            >
                <BodyLabel1
                    css={css`
                        color: ${local.greys[4]};
                    `}
                >
                    Enter Payment Information
                </BodyLabel1>
            </Grid>
            <PaymentElement
                css={css`
                    height: fit-content;
                `}
            />
            <Grid
                item
                direction="row"
                css={css`
                    margin-top: 40px;
                    margin-bottom: 40px;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid
                    item
                    container
                    direction="column"
                    css={css`
                        justify-content: center;
                    `}
                >
                    <BodyDescription1>
                        <OnwardCheckbox
                            color="primary"
                            checked={accepted}
                            onChange={() => setAccepted((prev) => !prev)}
                        />
                        I agree to the{' '}
                        <a
                            target="_blank"
                            href="https://www.onwarddelivery.com/terms-and-conditions"
                            css={css`
                                text-decoration: none;
                            `}
                        >
                            <BodyDescription1
                                css={css`
                                    font-weight: 700;
                                    cursor: pointer;
                                    color: ${colors.greens.primary};
                                `}
                            >
                                Terms and Conditions
                            </BodyDescription1>
                        </a>
                    </BodyDescription1>
                </Grid>
            </Grid>

            <Grid
                item
                css={css`
                    background: #ececec;
                    margin: 0 12px;
                    padding: 14px;
                    border-radius: 5px;
                    margin-bottom: 20px;
                `}
            >
                <Grid container direction="column">
                    <Grid item>
                        <BodyDescription1>What should I expect after submitting?</BodyDescription1>
                    </Grid>
                    <Grid item>
                        <BodyDescription1
                            css={css`
                                font-weight: 700;
                                cursor: pointer;
                                color: ${colors.greens.primary};
                            `}
                            onClick={callbacks.showExpectations}
                        >
                            Find out here &gt;
                        </BodyDescription1>
                    </Grid>
                </Grid>
            </Grid>

            <Footer
                state={state}
                isFirst={state.isFirstStage}
                isLast={state.isLastStage}
                hasErrors={!accepted}
                callbacks={{ advance: () => callbacks.advance({ stripe, elements }), revert: callbacks.revert }}
            />
        </>
    );
};

const Header = ({ isDesktop, callbacks }) => {
    return (
        <Grid
            container
            css={css`
                margin-bottom: 28px;
                justify-content: space-between;
                align-items: center;
                padding: 0 ${isDesktop ? `0` : '12px'};
            `}
        >
            <Grid item direction="column">
                <Header1
                    css={css`
                        color: ${local.greys[4]};
                    `}
                >
                    Review Delivery
                </Header1>
            </Grid>
            <Grid item direction="column">
                <IconButton
                    css={css`
                        padding: 4px;
                    `}
                    onClick={callbacks.showInfo}
                >
                    <HelpIcon
                        css={css`
                            color: ${colors.greens.primary};
                        `}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

const Summary = ({ state, callbacks, isDesktop }) => {
    const stripePromise = loadStripe(CONFIG.STRIPE_PK);
    const stripeOptions = {
        clientSecret: state.paymentInfo.clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: colors.greens.primary,
            },
        },
    };

    return (
        <Grid
            container
            css={css`
                color: ${local.greys[3]};
                width: ${isDesktop ? `400px` : 'auto'};
                padding: 0 ${isDesktop ? `35px` : '12px'};
            `}
        >
            <Grid item container direction="column">
                {isDesktop ? <Header callbacks={callbacks} isDesktop={isDesktop} /> : null}

                <OrderSummary callbacks={callbacks} state={state} />

                {state.paymentInfo?.clientSecret ? (
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <Checkout callbacks={callbacks} state={state} />
                    </Elements>
                ) : (
                    <>
                        <div
                            css={css`
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                margin-top: 40px;
                                margin-bottom: 40px;
                            `}
                        >
                            <CircularProgress
                                size={200}
                                css={css`
                                    color: ${colors.greens.primary};
                                `}
                            />
                        </div>
                        <Footer
                            state={state}
                            isFirst={state.isFirstStage}
                            isLast={state.isLastStage}
                            hasErrors={true}
                            callbacks={{ advance: () => callbacks.advance({}), revert: callbacks.revert }}
                        />
                    </>
                )}
            </Grid>
        </Grid>
    );
};

function Payment({ isDesktop, callbacks, state, customerUpgrade = false }) {
    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
                flex-wrap: nowrap;
            `}
        >
            {!customerUpgrade && (<ProgressBar 
                step={state.progression.current + 1} 
                maxSteps={7}
                containerStyles={isDesktop && `
                    padding-left: 35px;
                `}
            />)}
            {!isDesktop ? (
                <>
                    <Header callbacks={callbacks} isDesktop={isDesktop} />

                    <Grid
                        container
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <Map
                            order={state.order}
                            css={css`
                                height: 300px;
                            `}
                        />
                    </Grid>

                    <Summary callbacks={callbacks} state={state} />
                </>
            ) : (
                <Grid container>
                    <Grid
                        container
                        direction="column"
                        item
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <Summary callbacks={callbacks} state={state} isDesktop={isDesktop} />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        item
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Map order={state.order} />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default Payment;
