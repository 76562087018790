import { postUnauth } from '@/utilities/onwardClient';
import { CREATE_CLIENT } from '@/constants/apiRoutes';

const createClient = (client) => {
    const { username, email, business_name, business_phone, password, ...remaining } = client;

    Object.keys(remaining).forEach(key => {
        if (remaining[key] === null) {
            delete remaining[key];
        }
    });

    return postUnauth(CREATE_CLIENT, {
        clientType: 'carrier',
        username,
        email,
        business: business_name,
        phone: business_phone,
        password,
        carrierData: remaining,
    });
};

export default createClient;
