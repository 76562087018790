import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Typography, Switch, TextField, MenuItem } from '@material-ui/core';
import { css } from '@emotion/react';
import useShippersAndCarriers from '@/hooks/useShippersAndCarriers';
import { featureFlags } from './featureFlagsConstants';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_FEATURE_FLAGS } from '@/graphql/mutations/users';
import { QUERY_BY_LEADER } from '@/graphql/queries/teammates';
import { ClientSelect } from '@/components/ShipmentForm/modals/InputFields';
import { UPDATE_CLIENT_BY_PK } from '@/graphql/mutations/clients';
import { QUERY_SUBREGION_TYPES } from '@/graphql/queries/subregions';

export default function AdminFeatureFlags(props) {
    const { uid, userObject, setUserObject, client } = props;
    const [loading, setLoading] = useState(false);
    const [teammates, setTeammates] = useState([]);
    const [_, carriers] = useShippersAndCarriers(client.test_acc);
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [selectedZipType, setSelectedZipType] = useState('');

    const updated = useMemo(() => {
        return {
            ...(client || {}),
            ...(selectedCarrier ? { auto_assigned_carrier: selectedCarrier } : {}),
            ...(selectedZipType ? { auto_assign_zip_type: selectedZipType } : {}),
        };
    }, [client, selectedCarrier, selectedZipType]);

    useQuery(QUERY_BY_LEADER, {
        variables: { leader_id: uid },
        onCompleted: (data) => setTeammates(data.results.map((teammate) => teammate.teammate_id)),
    });

    const { data: types } = useQuery(QUERY_SUBREGION_TYPES, {});

    const [updateCircles] = useMutation(UPDATE_FEATURE_FLAGS, {
        onError: (e) => console.log(e),
        onCompleted: (data) => {
            setUserObject({
                ...userObject,
                circles: data.update_users.returning[0].circles,
            });
        },
    });

    const [updateClient] = useMutation(UPDATE_CLIENT_BY_PK, {
        onError: (e) => console.log(e),
        onCompleted: (data) => console.log(data),
    });

    const handleToggle = async (flag) => {
        setLoading(true);

        let newCircles = { ...(userObject?.circles || {}) };
        newCircles[flag] = !userObject?.circles?.[flag];

        let userList = [uid, ...teammates];

        updateCircles({
            variables: {
                user_list: userList,
                circles: newCircles,
            },
        });

        setLoading(false);
    };

    const handleSelectCarrier = async (carrierId) => {
        setSelectedCarrier(carrierId);
        updateClient({
            variables: {
                client_id: uid,
                update: {
                    auto_assigned_carrier: carrierId,
                },
            },
        });
    };

    return (
        <Col
            css={css`
                padding-top: 45px;
            `}
        >
            <Row>
                <Typography variant="h4" className="mb-2">
                    Feature Flags
                </Typography>
            </Row>
            {featureFlags.map((flag) => (
                <>
                    <Row>
                        <Col xs={4}>
                            <Typography
                                variant="subtitle2"
                                css={css`
                                    align-self: center;
                                    font-size: 1.15rem;
                                `}
                            >
                                {flag.name}
                            </Typography>
                        </Col>
                        <Col xs={1}>
                            <Switch
                                color="primary"
                                checked={!!userObject?.circles?.[flag.name]}
                                onChange={() => handleToggle(flag.name)}
                                disabled={loading || !Object.keys(userObject).length}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Typography variant="subtitle2" className="ms-1">
                                <i>{flag.description}</i>
                            </Typography>
                        </Col>
                    </Row>
                    {flag.name === 'auto-assign-by-zip' ? (
                        <Row>
                            <Col xs={12}>
                                <TextField
                                    select
                                    variant="outlined"
                                    label="Region Mapping Type"
                                    value={updated.auto_assign_zip_type || ''}
                                    fullWidth
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        setSelectedZipType(val);
                                        updateClient({
                                            variables: {
                                                client_id: uid,
                                                update: {
                                                    auto_assign_zip_type: val || null,
                                                },
                                            },
                                        });
                                    }}
                                >
                                    {['', ...(types?.results?.nodes?.map((node) => node.type) || [])].map((type) => (
                                        <MenuItem key={`type-${type}`} value={type}>
                                            {type || 'UNASSIGNED'}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                    ) : null}
                    {flag.name === 'auto-assign-carrier' ? (
                        <Row>
                            <Col xs={12}>
                                <Typography variant="subtitle2" className="ms-1">
                                    <ClientSelect
                                        value={updated.auto_assigned_carrier || ''}
                                        options={carriers}
                                        onChange={(carrierId) => handleSelectCarrier(carrierId)}
                                        allowEmpty={true}
                                        type="carrier"
                                        css={css`
                                            margin-left: 0.5rem;
                                        `}
                                    />
                                    <hr />
                                </Typography>
                            </Col>
                        </Row>
                    ) : null}
                </>
            ))}
        </Col>
    );
}
