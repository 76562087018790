import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TableRow } from '@material-ui/core';

const Row = styled(({ children, ...rest }) => <TableRow {...rest}>{children}</TableRow>)`
    cursor: pointer;

    :hover td {
        background-color: #f5f5f5;
    }

    color: ${(props) => (props.error ? '#f44336' : '#2b2b2b')};
`;

export default function BodyRow({ row, children, ...props }) {
    return <Row {...props}>{children}</Row>;
}
