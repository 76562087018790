const { PrimaryButton } = require('@/styles/blocks');
const { TextField, Grid, InputAdornment, IconButton } = require('@material-ui/core');
const { useEffect, useState, useMemo } = require('react');
import { css } from '@emotion/react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getAuth, updateCurrentUser, updatePassword } from 'firebase/auth';

const PW_ERROR_MESSAGES = {
    containsSpecial: 'Password must contain at least one special character',
    containsUpper: 'Password must contain at least one upper case letter',
    containsLower: 'Password must contain at least one lower case letter',
    containsNumber: 'Password must contain at least one number',
    withinLength: 'Password must be at least 8 characters long and no longer than 32 characters',
};

const PasswordTab = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const [dirty, setDirty] = useState({});
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [authLoading, setAuthloading] = useState(false);

    const passwordValidation = useMemo(() => {
        return {
            containsSpecial: /(?=.*?[?!@#$%^&*])/.test(password),
            containsUpper: /(?=.*?[A-Z])/.test(password),
            containsLower: /(?=.*?[a-z])/.test(password),
            containsNumber: /(?=.*?[0-9])/.test(password),
            withinLength: password.length >= 8 && password.length < 32,
        };
    }, [password]);

    const onSave = () => {
        setSuccess(false);
        if (Object.values(passwordValidation).some((x) => !x) || password !== confirmPassword) {
            setError('Please fix the following errors');
            setDirty(Object.fromEntries(['email', 'password', 'confirmPassword'].map((key) => [key, true])));
            return;
        }

        setAuthloading(true);
        updatePassword(getAuth().currentUser, password)
            .then(() => {
                setPassword('');
                setConfirmPassword('');
                setSuccess(true);
            })
            .catch((error) => {
                setError(error?.message || error?.toString() || `We're sorry, something went wrong`);
            })
            .finally(() => {
                setAuthloading(false);
            });
    };

    return (
        <Grid
            container
            spacing={3}
            css={css`
                flex-direction: column;
                flex-wrap: nowrap;
            `}
        >
            {success && (
                <Alert severity="success">
                    <AlertTitle>Password successfully changed!</AlertTitle>
                </Alert>
            )}
            {error && (
                <Alert severity="error">
                    <AlertTitle>{error}</AlertTitle>
                    {Object.entries(passwordValidation).map(([key, valid]) =>
                        valid ? null : <li>{PW_ERROR_MESSAGES[key]}</li>
                    )}
                </Alert>
            )}
            <Grid item>
                <TextField
                    value={password}
                    variant="outlined"
                    label="New Password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={authLoading}
                    error={dirty.password && Object.values(passwordValidation).some((x) => !x)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                                    {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item>
                <TextField
                    value={confirmPassword}
                    variant="outlined"
                    label="Confirm Password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    disabled={authLoading}
                    error={dirty.confirmPassword && password !== confirmPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                                    {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item>
                <PrimaryButton onClick={onSave} disabled={authLoading}>
                    Change Password
                </PrimaryButton>
            </Grid>
        </Grid>
    );
};

export default PasswordTab;
