import { gql } from '@apollo/client';

export const EVENT_SUBSCRIPTION_FIELDS = gql`
    fragment EventSubscriptionFields on event_subscriptions {
        event_subscription_id
        service_id
        target_url
        subscribed_event
        created_at
        updated_at
        enabled
    }
`;
