import { useClientUser } from '@/hooks';
import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Popover,
    Select,
    TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { FilterRow } from './blocks';
import STATES from '@/constants/states';
import FilterListIcon from '@mui/icons-material/FilterList';
import { colors } from '@/styles';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import BidIcon from '../misc/BidIcon';
import { OnwardSwitch } from '@/styles/blocks';
import { BIDDING_DISABLED } from '@/constants/featureFlags';
import { checkAndGeocodeLocations } from './utils';
import { OnwardCheckbox } from '../ShipmentForm/blocks';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { HelpOutline } from '@material-ui/icons';

const CustomTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '14px !important',
        backgroundColor: 'rgba(97, 97, 97, 1.0) !important',
    },
}))(Tooltip);

const Filters = (props) => {
    const {
        takingBids,
        setTakingBids,
        setDeliveryTypeFilters,
        setStateFilters,
        setFirstAvailableFilter,
        setMaxCubes,
        setTotalPeople,
        setWithinRangeOrderPoint,
        setWithinRangeMiles,
        setWithinRangeSetLocation,
    } = props;
    const { circles, locations } = useClientUser();

    const [anchorEl, setAnchorEl] = useState(null);

    // Delivery Type filter
    const [localDeliveryTypes, setLocalDeliveryTypes] = useState([]);
    const [deliveryTypesEnabled, setDeliveryTypesEnabled] = useState(false);
    const handleDeliveryTypeFiltersChange = (val) => {
        setLocalDeliveryTypes(typeof val === 'string' ? val.split(',') : val);
    };
    useEffect(() => {
        if (deliveryTypesEnabled && localDeliveryTypes.length) {
            setDeliveryTypeFilters(localDeliveryTypes);
        } else {
            setDeliveryTypeFilters([]);
        }
    }, [deliveryTypesEnabled, localDeliveryTypes]);

    // State filter
    const [localStateFilters, setLocalStateFilters] = useState([]);
    const [stateFiltersEnabled, setStateFiltersEnabled] = useState(false);
    const handleStateFiltersChange = (val) => {
        setLocalStateFilters(typeof val === 'string' ? val.split(',') : val);
    };
    useEffect(() => {
        if (stateFiltersEnabled && localStateFilters.length) {
            setStateFilters(localStateFilters);
        } else {
            setStateFilters([]);
        }
    }, [stateFiltersEnabled, localStateFilters]);

    // Within Range Of... filter
    const [localWithinRangeOrderPoint, setLocalWithinRangeOrderPoint] = useState(null);
    const [localWithinRangeMiles, setLocalWithinRangeMiles] = useState(null);
    const [localWithinRangeLocation, setLocalWithinRangeLocation] = useState(null);
    const [withinRangeEnabled, setWithinRangeEnabled] = useState(false);
    const handleChangeWithinRangeOrderPoint = (val) => {
        console.log(val);
        setLocalWithinRangeOrderPoint(val);
    };
    const handleChangeWithinRangeMiles = (val) => {
        console.log(val);
        setLocalWithinRangeMiles(val);
    };
    const handleChangeWithinRangeLocation = (val) => {
        setLocalWithinRangeLocation(val);
    };
    useEffect(() => {
        if (withinRangeEnabled && localWithinRangeOrderPoint && localWithinRangeMiles && localWithinRangeLocation) {
            const _location = locations.find(l => l.location_id === localWithinRangeLocation);
            setWithinRangeOrderPoint(localWithinRangeOrderPoint);
            setWithinRangeMiles(localWithinRangeMiles);
            setWithinRangeSetLocation(_location?.geo_point);
        } else {
            setWithinRangeOrderPoint(null);
            setWithinRangeMiles(null);
            setWithinRangeSetLocation(null);
        }
    }, [withinRangeEnabled, localWithinRangeOrderPoint, localWithinRangeMiles, localWithinRangeLocation]);

    // First Available filter
    const [localFirstAvailableFilter, setLocalFirstAvailableFilter] = useState(null);
    const [firstAvailableEnabled, setFirstAvailableEnabled] = useState(false);
    const handleFirstAvailableChange = (val) => {
        setLocalFirstAvailableFilter(val);
    };
    useEffect(() => {
        if (firstAvailableEnabled && localFirstAvailableFilter) {
            setFirstAvailableFilter(localFirstAvailableFilter);
        } else {
            setFirstAvailableFilter(null);
        }
    }, [firstAvailableEnabled, localFirstAvailableFilter]);

    // Max Cubic Feet filter
    const [localMaxCubes, setLocalMaxCubes] = useState(null);
    const [maxCubesEnabled, setMaxCubesEnabled] = useState(false);
    const handleMaxCubesChange = (val) => {
        setLocalMaxCubes(val);
    };
    useEffect(() => {
        if (maxCubesEnabled && localMaxCubes) {
            setMaxCubes(localMaxCubes);
        } else {
            setMaxCubes(null);
        }
    }, [maxCubesEnabled, localMaxCubes]);

    // Max Total People filter
    const [localTotalPeople, setLocalTotalPeople] = useState(null);
    const [totalPeopleEnabled, setTotalPeopleEnabled] = useState(false);
    const handleChangeTotalPeople = (val) => {
        setLocalTotalPeople(val);
    };
    useEffect(() => {
        if (totalPeopleEnabled && localTotalPeople) {
            setTotalPeople(localTotalPeople);
        } else {
            setTotalPeople(null);
        }
    }, [totalPeopleEnabled, localTotalPeople]);

    checkAndGeocodeLocations(locations);

    const handleFiltersOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleFiltersClose = () => {
        setAnchorEl(null);
    };

    const CustomCheck = ({ checked, onChange }) => {
        return (
            <Grid item>
                <CustomTooltip title="Enable / Disable filter" placement="left">
                    <div>
                        <OnwardCheckbox checked={checked} onChange={onChange} />
                    </div>
                </CustomTooltip>
            </Grid>
        );
    };

    return (
        <>
            <FilterListIcon
                css={css`
                    color: white;
                    background-color: ${colors.greens.primary};
                    border-radius: 5px;
                    height: 40px;
                    width: 40px;
                    margin-left: 1rem;
                    cursor: pointer;
                `}
                onClick={handleFiltersOpen}
            />
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleFiltersClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <Grid
                    css={css`
                        padding: 1rem;
                        min-width: 400px;
                        .MuiInputLabel-formControl {
                            margin-left: 0.5rem;
                            margin-top: -0.25rem;
                            background-color: white;
                            z-index: 5;
                            padding: 0 0.5rem;
                            color: black;
                        }
                    `}
                >
                    {!circles?.[BIDDING_DISABLED] && (
                        <FilterRow>
                            <OnwardSwitch
                                label={
                                    <>
                                        <BidIcon />
                                        <span style={{ fontSize: '14px' }}>Taking Bids</span>
                                    </>
                                }
                                checked={takingBids}
                                onChange={() => setTakingBids((prev) => !prev)}
                                css={css`
                                    margin-bottom: 0.5rem;
                                `}
                            />
                        </FilterRow>
                    )}

                    {/* Delivery Types */}
                    <FilterRow>
                        <CustomCheck
                            checked={deliveryTypesEnabled}
                            onChange={(e) => setDeliveryTypesEnabled(!deliveryTypesEnabled)}
                        />
                        <Grid
                            item
                            css={css`
                                width: 100%;
                                ${!deliveryTypesEnabled &&
                                `
                    pointer-events: none;
                    opacity: 0.5;
                `}
                            `}
                        >
                            <FormControl
                                css={css`
                                    width: 100%;

                                    & .MuiInputBase-root {
                                        height: 3rem;
                                        font-size: 14px;
                                    }

                                    & .MuiInputLabel-root {
                                        font-size: 14px;
                                        margin-top: -0.5rem;
                                    }
                                `}
                            >
                                <InputLabel id="delivery-type-select-label">Delivery Type(s)</InputLabel>
                                <Select
                                    labelId="delivery-type-select-label"
                                    id="delivery-type-select"
                                    multiple
                                    value={localDeliveryTypes}
                                    onChange={(e) => handleDeliveryTypeFiltersChange(e.target.value)}
                                    input={<OutlinedInput label="Delivery Type(s)" />}
                                    renderValue={(selected) => selected.map((type) => LOCATION_TYPES[type]).join(', ')}
                                    MenuProps={{ PaperProps: { style: { maxHeight: '50%' } } }}
                                >
                                    {Object.entries(LOCATION_TYPES).map(([type, label]) => (
                                        <MenuItem
                                            key={`delivery-type-filter-${type}`}
                                            value={type}
                                            css={css`
                                                & .Mui-checked {
                                                    color: ${colors.greens.primary};
                                                }
                                            `}
                                        >
                                            <Checkbox checked={localDeliveryTypes.indexOf(type) > -1} />
                                            <ListItemText primary={label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </FilterRow>

                    {/* State Select */}
                    <FilterRow>
                        <CustomCheck
                            checked={stateFiltersEnabled}
                            onChange={(e) => setStateFiltersEnabled(!stateFiltersEnabled)}
                        />

                        <Grid
                            item
                            css={css`
                                width: 100%;
                                ${!stateFiltersEnabled &&
                                `
                                    pointer-events: none;
                                    opacity: 0.5;
                                `}
                            `}
                        >
                            <FormControl
                                css={css`
                                    width: 100%;

                                    & .MuiInputBase-root {
                                        height: 3rem;
                                        font-size: 14px;
                                    }

                                    & .MuiInputLabel-root {
                                        font-size: 14px;
                                        margin-top: -0.5rem;
                                    }
                                `}
                            >
                                <InputLabel id="state-filter-select-label">State(s)</InputLabel>
                                <Select
                                    labelId="state-filter-select-label"
                                    id="state-filter-select"
                                    multiple
                                    value={localStateFilters}
                                    onChange={(e) => handleStateFiltersChange(e.target.value)}
                                    input={<OutlinedInput label="State(s)" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={{ PaperProps: { style: { maxHeight: '50%' } } }}
                                >
                                    {Object.entries(STATES).map(([stateCode, state]) => (
                                        <MenuItem
                                            key={`state-filter-${stateCode}`}
                                            value={stateCode}
                                            css={css`
                                                & .Mui-checked {
                                                    color: ${colors.greens.primary};
                                                }
                                            `}
                                        >
                                            <Checkbox checked={localStateFilters.indexOf(stateCode) > -1} />
                                            <ListItemText primary={state} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </FilterRow>

                    {/* Within Range Of... */}
                    <FilterRow>
                        <CustomCheck
                            checked={withinRangeEnabled}
                            onChange={(e) => setWithinRangeEnabled(!withinRangeEnabled)}
                        />
                        <Grid
                            item
                            css={css`
                                width: 100%;
                                display: flex;
                                align-items: center;
                                ${!withinRangeEnabled &&
                                `
                                    pointer-events: none;
                                    opacity: 0.5;
                                `}
                            `}
                        >
                            <FormControl
                                css={css`
                                    min-width: 140px;
                                    width: 100%;

                                    & .MuiInputBase-root {
                                        height: 3rem;
                                        font-size: 14px;
                                    }

                                    & .MuiInputLabel-root {
                                        font-size: 14px;
                                        margin-top: -0.5rem;
                                    }

                                    & .MuiListItemText-primary {
                                        font-size: 14px;
                                    }
                                `}
                            >
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    value={localWithinRangeOrderPoint}
                                    onChange={(e) => handleChangeWithinRangeOrderPoint(e.target.value)}
                                >
                                    {['Order pickup', 'Order dropoff'].map((point) => (
                                        <MenuItem
                                            key={`range-order-point-${point}`}
                                            value={point}
                                        >
                                            <ListItemText primary={`${point}`} />
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <span
                                css={css`
                                    margin-right: 8px;
                                    margin-left: 8px;
                                    font-size: 14px;
                                    text-wrap: nowrap;
                                `}
                            >
                                within
                            </span>
                            <FormControl
                                css={css`
                                    min-width: 70px;
                                    width: 100%;

                                    & .MuiInputBase-root {
                                        height: 3rem;
                                        font-size: 14px;
                                    }

                                    & .MuiInputLabel-root {
                                        font-size: 14px;
                                        margin-top: -0.5rem;
                                    }

                                    & .MuiListItemText-primary {
                                        font-size: 14px;
                                    }
                                `}
                            >
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    value={localWithinRangeMiles}
                                    onChange={(e) => handleChangeWithinRangeMiles(e.target.value)}
                                >
                                    {[15, 25, 50, 75, 100, 150, 250].map((miles) => (
                                        <MenuItem
                                            key={`range-miles-${miles}`}
                                            value={miles}
                                        >
                                            <ListItemText primary={`${miles}`} />
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>

                            <span
                                css={css`
                                    margin-right: 8px;
                                    margin-left: 8px;
                                    font-size: 14px;
                                    text-wrap: nowrap;
                                `}
                            >
                                mi. range of
                            </span>

                            <FormControl
                                css={css`
                                    min-width: 140px;
                                    max-width: 140px;

                                    & .MuiInputBase-root {
                                        height: 3rem;
                                        font-size: 14px;
                                    }

                                    & .MuiInputLabel-root {
                                        font-size: 14px;
                                        margin-top: -0.5rem;
                                    }

                                    & .MuiListItemText-primary {
                                        font-size: 14px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                `}
                            >
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    value={localWithinRangeLocation}
                                    onChange={(e) => handleChangeWithinRangeLocation(e.target.value)}
                                >
                                    {!!locations?.length ? locations?.map((location) => (
                                        <MenuItem
                                            key={`range-location-${location.location_id}`}
                                            value={location.location_id}
                                            disabled={!location.geo_point}
                                        >
                                            <ListItemText primary={`${location.location_name}`} />
                                        </MenuItem>
                                    )) : (
                                        <MenuItem
                                            key={`range-location-no-inputs`}
                                            value={null}
                                            disabled={true}
                                        >
                                            <ListItemText primary={`Requires store`} />
                                        </MenuItem>
                                    )}
                                </TextField>
                            </FormControl>

                            <CustomTooltip
                                title="You may add stores in your My Account page."
                                placement="top"
                                >
                                <HelpOutline
                                    css={css`
                                        color: rgba(0, 0, 0, 0.54);
                                        margin-left: 0.3rem;
                                    `}
                                />
                            </CustomTooltip>
                        </Grid>
                    </FilterRow>

                    {/* First Available after */}
                    <FilterRow>
                        <CustomCheck
                            checked={firstAvailableEnabled}
                            onChange={(e) => setFirstAvailableEnabled(!firstAvailableEnabled)}
                        />
                        <Grid
                            item
                            css={css`
                                width: 100%;
                                display: flex;
                                align-items: center;
                                ${!firstAvailableEnabled &&
                                `
                                    pointer-events: none;
                                    opacity: 0.5;
                                `}
                            `}
                        >
                            <span
                                css={css`
                                    margin-right: 10px;
                                    font-size: 14px;
                                    text-wrap: nowrap;
                                `}
                            >
                                First Available date after:
                            </span>
                            <FormControl
                                css={css`
                                    width: 100%;

                                    & .MuiInputBase-root {
                                        height: 3rem;
                                        font-size: 14px;
                                    }

                                    & .MuiInputLabel-root {
                                        font-size: 14px;
                                        margin-top: -0.5rem;
                                    }
                                `}
                            >
                                <TextField
                                    type="date"
                                    variant="outlined"
                                    size="small"
                                    value={localFirstAvailableFilter}
                                    onChange={(e) => handleFirstAvailableChange(e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </FilterRow>

                    {/* Max cubic feet */}
                    <FilterRow>
                        <CustomCheck checked={maxCubesEnabled} onChange={(e) => setMaxCubesEnabled(!maxCubesEnabled)} />
                        <Grid
                            item
                            css={css`
                                width: 100%;
                                display: flex;
                                align-items: center;
                                ${!maxCubesEnabled &&
                                `
                                    pointer-events: none;
                                    opacity: 0.5;
                                `}
                            `}
                        >
                            <span
                                css={css`
                                    margin-right: 10px;
                                    font-size: 14px;
                                    text-wrap: nowrap;
                                `}
                            >
                                Max cubic feet:
                            </span>
                            <FormControl
                                css={css`
                                    width: 100%;

                                    & .MuiInputBase-root {
                                        height: 3rem;
                                        font-size: 14px;
                                    }

                                    & .MuiInputLabel-root {
                                        font-size: 14px;
                                        margin-top: -0.5rem;
                                    }

                                    & .MuiListItemText-primary {
                                        font-size: 14px;
                                    }
                                `}
                            >
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    value={localMaxCubes}
                                    onChange={(e) => handleMaxCubesChange(e.target.value)}
                                >
                                    {Array.from(Array(12).keys())
                                        .map((_, idx) => (idx + 1) * 25)
                                        .map((cubes) => (
                                            <MenuItem
                                                key={`max-cubes-filter-${cubes}`}
                                                value={cubes}
                                                css={css`
                                                    & .Mui-checked {
                                                        color: ${colors.greens.primary};
                                                    }
                                                `}
                                            >
                                                <ListItemText primary={cubes} />
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                    </FilterRow>

                    {/* Total People filter */}
                    <FilterRow>
                        <CustomCheck
                            checked={totalPeopleEnabled}
                            onChange={(e) => setTotalPeopleEnabled(!totalPeopleEnabled)}
                        />
                        <Grid
                            item
                            css={css`
                                width: 100%;
                                display: flex;
                                align-items: center;
                                ${!totalPeopleEnabled &&
                                `
                                    pointer-events: none;
                                    opacity: 0.5;
                                `}
                            `}
                        >
                            <span
                                css={css`
                                    margin-right: 10px;
                                    font-size: 14px;
                                    text-wrap: nowrap;
                                `}
                            >
                                Max Total People:
                            </span>
                            <FormControl
                                css={css`
                                    width: 100%;

                                    & .MuiInputBase-root {
                                        height: 3rem;
                                        font-size: 14px;
                                    }

                                    & .MuiInputLabel-root {
                                        font-size: 14px;
                                        margin-top: -0.5rem;
                                    }

                                    & .MuiListItemText-primary {
                                        font-size: 14px;
                                    }
                                `}
                            >
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    value={localTotalPeople}
                                    onChange={(e) => handleChangeTotalPeople(e.target.value)}
                                >
                                    {[1, 2].map((people) => (
                                        <MenuItem
                                            key={`max-total-people-${people}`}
                                            value={people}
                                        >
                                            <ListItemText primary={`${people} man team`} />
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                    </FilterRow>
                </Grid>
            </Popover>
        </>
    );
};

export default Filters;
