import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { OnwardCard } from './Card';
import InfoIcon from '@material-ui/icons/Info';

export default function RoutedOrderCard({ order }) {
    const routeNumbers = useMemo(() => {
        const pickup = order?.routes?.find((mapping) => mapping.type === 'PICKUP');
        const dropoff = order?.routes?.find((mapping) => mapping.type !== 'PICKUP');
        if (pickup) {
            return `${pickup.route.route_number} / ${dropoff?.route?.route_number || '-'}`;
        }
        return dropoff?.route?.route_number || '-';
    }, [order]);

    return (
        <>
            <OnwardCard
                className="my-3"
                style={{ backgroundColor: 'lightgrey', padding: '0px', justifyContent: 'center' }}
            >
                <Typography className="my-5" variant="caption">
                    <InfoIcon
                        style={{ color: 'darkorange', marginRight: '5px' }}
                        className="float-left"
                        fontSize="small"
                    />
                    <b>Routed: {routeNumbers}</b>
                    <br />
                    You cannot adjust the delivery date/time for this order because it has been placed on a route. To
                    update this information, please remove the order from the current route, send the order back to
                    unassigned, and then send back to planning with the newly selected date.
                </Typography>
            </OnwardCard>
        </>
    );
}
