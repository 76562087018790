import React, { useContext, useMemo, useState, useEffect } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { Grid, TextareaAutosize } from '@material-ui/core';
import { SectionSubtitle } from '@/styles/blocks';
import StartTimeSelect from '@/components/misc/StartTimeSelect';
import { useSwappedAttributes } from '../../hooks';
import { Body1, Incrementor, OnwardCheckbox, TextField } from '../../blocks';
import { ModalContext } from './';
import { SectionSubtitle2, GreyBody } from '@/styles/blocks';

const NotesTab = () => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;

    return (
        <Grid container direction="column">
            <Grid 
                container
                item
                xs={12}
                css={css`
                    margin-bottom: 20px;    
                `}
            >
                <SectionSubtitle2>Notes</SectionSubtitle2>
            </Grid>
            <Grid
                container
                item
                xs={12}
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <TextField
                    label="Shipper Notes"
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={order.shipper_notes || null}
                    onChange={(e) => callbacks.modifyOrder({ shipper_notes: e.target.value })}
                    multiline
                    rows={5}
                />
            </Grid>
            <Grid
                container
                item
                xs={12}
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <TextField
                    label="Carrier Notes"
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={order.carrier_notes || null}
                    onChange={(e) => callbacks.modifyOrder({ carrier_notes: e.target.value })}
                    multiline
                    rows={5}
                />
            </Grid>
        </Grid>
    );
};

export default NotesTab;

