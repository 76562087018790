import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { calcDefaultServiceTime } from '@/utilities/calculateTimeframes';
import { poundFormatter, integerFormatter, minuteString } from '@/constants/formats';
import { Body1, Body2 } from '../blocks';
import {
    useItemQuantity,
    useTotalWeight,
    useTotalCubes,
    useOrderShipperPricing,
    useOrderCarrierPricing,
    useTotalRevenue,
} from '@/hooks';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import deliveryStrings from '@/constants/deliveryStrings';
import { Row, Col } from 'react-bootstrap';
import { capitalize, startCase } from 'lodash';
import dateFns from '@/utilities/dateFns';
import { genAttributes } from '@onward-delivery/core';
import { toNational } from '@/utilities/formatPhoneNumber';
const Metric = ({ label, value }) => {
    const render = [...(label ? [label] : []), value];
    return (
        <Grid
            item
            css={css`
                margin: 2px 0;
            `}
        >
            <Body2>{render.join(': ')}</Body2>
        </Grid>
    );
};

const BoldMetric = ({ label, value }) => {
    const render = [...(label ? [label] : []), value];
    return (
        <Grid
            item
            css={css`
                margin: 4px 0;
            `}
        >
            <Body1>{render.join(': ')}</Body1>
        </Grid>
    );
};

const OrderTooltip = ({ order, stop, circles, user_id, payment_type }) => {
    const totalRevenue = useTotalRevenue([order]);
    const totalItems = useItemQuantity([order]);
    const totalWeight = useTotalWeight([order]);
    const totalVolume = useTotalCubes([order]);
    const shipperRate = useOrderShipperPricing([order], circles, payment_type);
    const carrierRate = useOrderCarrierPricing([order]);
    const rate = order.oms ? `$${totalRevenue}` : order.shipper_id === user_id ? shipperRate : carrierRate;

    if (!order) {
        return <>No Order Details</>;
    }

    const { street, unit, city, state, zip, location_type } = genAttributes(order);

    const formatPhone = (phone) => {
        try {
            return toNational(phone) || phone || 'N/A';
        }
        catch (e) {
            return phone || 'N/A';
        }
    }

    return (
        <>
            {order.oms === false ? (
                <Col style={{ backgroundColor: 'green', color: 'white', textAlign: 'center' }}>
                    <BoldMetric value={'Onward - Claimed Order'} />
                </Col>
            ) : (
                <Col style={{ backgroundColor: 'orange', color: 'white', textAlign: 'center' }}>
                    <BoldMetric value={'Internal Order'} />
                </Col>
            )}
            <BoldMetric label="Order" value={order.order_number} />
            <BoldMetric label="PO" value={order.po_number} />
            <BoldMetric label="Consignee" value={order.dropoff_name} />
            <Metric value={[order[street], order[unit]].filter((x) => x).join(', ')} />
            <Metric value={[order[city], order[state], order[zip]].filter((x) => x).join(', ')} />
            <Metric label="Phone" value={formatPhone(order.dropoff_phone)} />
            <Metric label="Order Type" value={startCase(order.order_type)} />
            <Metric label="Delivery Type" value={LOCATION_TYPES[order[location_type]] || order[location_type]} />
            <Metric label="Freight Type" value={startCase(order.freight_type)} />
            <Metric
                label="Est. Del Range"
                value={
                    order.estimated_delivery_range_start
                        ? dateFns.formatDate(new Date(order.estimated_delivery_range_start), 'MM/dd/yyyy') +
                          '- ' +
                          dateFns.formatDate(new Date(order.estimated_delivery_range_end), 'MM/dd/yyyy')
                        : 'N/A'
                }
            />
            {order.manufacturer && <Metric label="Manufacturer ID" value={order.manufacturer} />}
            {order.reference_id && <Metric label="Reference ID" value={order.reference_id} />}
            {!!rate && <Metric label="Revenue" value={rate} />}
            <Metric
                value={[
                    `${integerFormatter.format(totalItems)} items`,
                    `${integerFormatter.format(totalVolume)} cu ft.`,
                    poundFormatter.format(totalWeight),
                ].join(' / ')}
            />
            {order.itemsByOrderId.map((item) => (
                <Metric
                    key={item.item_id}
                    label=""
                    value={`${item.quantity} x ${item.sku || ''} ${item.description}`}
                />
            ))}
            {order.dropoff_comments && <Metric label="Comments" value={order.dropoff_comments} />}
        </>
    );
};

const MapStopTooltip = ({ orders, stop, type, timeframeOptions, circles, user_id, payment_type }) => {
    if (!orders?.length) return <Body1>Error! Missing Order Information</Body1>;

    const totalItems = useItemQuantity(orders);
    const totalWeight = useTotalWeight(orders);
    const totalVolume = useTotalCubes(orders);
    const shipperRate = useOrderShipperPricing(orders, circles, payment_type);
    const carrierRate = useOrderCarrierPricing(orders);
    const rate = orders?.[0]?.shipper_id === user_id ? shipperRate : carrierRate;

    const serviceTime = useMemo(() => {
        if (!stop) {
            return null;
        }

        if (stop.service_time === 0 || stop.service_time) {
            return stop.service_time / 1000 / 60;
        }

        const ordersByKey = Object.fromEntries(orders.map((order) => [order.order_id, order]));
        const { hours, minutes } = calcDefaultServiceTime(stop, ordersByKey, timeframeOptions);
        return (hours || 0) * 60 + (minutes || 0);
    }, [stop, orders]);

    const orderLocationTypes = useMemo(() => {
        const uniqueTypes = new Set(
            orders
                .map((order) =>
                    order.order_type === 'return' ? order.pickup_location_type : order.dropoff_location_type
                )
                .filter((o) => o)
        );
        return Array.from(uniqueTypes);
    }, [orders]);

    const freightTypes = useMemo(() => {
        const freights = new Set();
        orders.forEach((order) => {
            if (order?.freight_type) freights.add(capitalize(order.freight_type));
        });
        return Array.from(freights);
    }, [orders]);

    return (
        <Grid container direction="column" style={{paddingTop: '0 !important', width: '300px !important', overflow: 'clip'}}>
            {orders.length === 1 ? (
                <>
                    {type === 'load' ? (
                        <>
                            <Row style={{ height: '20px' }}>
                                <Col style={{ padding: '0rem !important', textAlign: 'start' }}>
                                    <BoldMetric style={{ padding: '0' }} value={orders[0].order_number} />
                                </Col>
                                <Col style={{ color: 'green', textAlign: 'end', padding: '0rem !important', marginRight: '25px' }}>
                                    <BoldMetric style={{ marginRight: '25px' }} label="Rate" value={rate} />
                                </Col>
                            </Row>
                            <Metric label="Shipper" value={orders[0].order_shipper.business_name} />
                            <Metric label="Pickup" value={orders[0].pickup_city} />
                            <Metric label="Dropoff" value={orders[0].dropoff_city} />

                            <Metric
                                label="Order Type"
                                value={deliveryStrings.deliveryTypes[orders[0].order_type] || orders[0].order_type}
                            />

                            <Metric
                                label="Delivery Type"
                                value={orderLocationTypes.map(
                                    (locationType) => LOCATION_TYPES[locationType] || locationType
                                )}
                            />
                            <Metric label="Freight Type" value={freightTypes} />
                            <Metric
                                value={[
                                    `${integerFormatter.format(totalItems)} items`,
                                    `${integerFormatter.format(totalVolume)} cu ft.`,
                                    poundFormatter.format(totalWeight),
                                ].join(' / ')}
                            />
                            <Metric
                                label="Dropoff Details"
                                value={orders[0].dropoff_location + ' - ' + orders[0].dropoff_location_info}
                            />
                            {serviceTime && <Metric label="Service Time" value={minuteString.format(serviceTime)} />}
                            {orders[0].manufacturer && (
                                <Metric label="Manufacturer ID" value={orders[0].manufacturer} />
                            )}
                            {/* <Button style={{backgroundColor: "green"}}>Send to Onward</Button> */}
                        </>
                    ) : (
                        <>
                            {orders.map((order) => (
                                <OrderTooltip
                                    key={order.order_id}
                                    {...{ order, stop, circles, user_id, payment_type }}
                                />
                            ))}
                        </>
                    )}
                </>
            ) : (
                <>
                    {type === 'load' ? (
                        <>
                            <Row style={{ padding: '0rem !important', marginBottom: '0 !important' }}>
                                <Col style={{ padding: '0', textAlign: 'start' }}>
                                    <BoldMetric value={`${orders.length} Orders`} />
                                </Col>
                                <Col style={{ color: 'blue', textAlign: 'end', padding: '0', marginRight: '25px' }}>
                                    <BoldMetric style={{ padding: '0', marginRight: '25px' }} label="Rate" value={rate} />
                                </Col>
                            </Row>
                            <Metric label="Orders" value={orders.map((o) => o.order_number).join(', ')} />
                            <Metric label="Pickup" value={orders[0].pickup_city} />
                            <Metric label="Dropoff" value={orders[0].dropoff_city} />
                            <Metric label="Order Type" value={orders[0].order_type} />
                            <Metric
                                label="Delivery Type"
                                value={orderLocationTypes.map(
                                    (locationType) => LOCATION_TYPES[locationType] || locationType
                                )}
                            />
                            <Metric label="Freight Type" value={freightTypes} />
                            <Metric
                                value={[
                                    `${integerFormatter.format(totalItems)} items`,
                                    `${integerFormatter.format(totalVolume)} cu ft.`,
                                    poundFormatter.format(totalWeight),
                                ].join(' / ')}
                            />
                            {serviceTime && <Metric label="Service Time" value={minuteString.format(serviceTime)} />}
                            {orders[0].manufacturer && (
                                <Metric label="Manufacturer ID" value={orders[0].manufacturer} />
                            )}
                        </>
                    ) : (
                        <>
                            {orders.map((order) => (
                                <OrderTooltip
                                    key={order.order_id}
                                    {...{ order, stop, circles, user_id, payment_type }}
                                />
                            ))}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
};

export default MapStopTooltip;
