import React, { useContext, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Context } from '../context';
import { ResponsiveSidebarDialog, ModalContent } from '../../../styles/blocks';
import { css } from '@emotion/react';
import Exception from './Exception';

import '../style.css';

const ExceptionsModal = ({ order }) => {
    const { closeExceptionsModal, exceptionsModalOpen } = useContext(Context);

    const renderItemExceptions = (items) => {
        const itemExceptions = items?.reduce((accum, i) => {
            if (i?.item_exception) {
                accum.push(i);
            }
            return accum;
        }, []);

        const pickupExceptions = itemExceptions.filter(i => i?.item_exception?.reported_at === 'PICKUP');
        const dropoffExceptions = itemExceptions.filter(i => i?.item_exception?.reported_at === 'DROPOFF');

        return (
            <>
            {!!pickupExceptions.length && (
                pickupExceptions.map(i => (
                    <Exception exception={i.item_exception} item={i} />
                )
            ))}

            {!!dropoffExceptions.length && !!pickupExceptions.length && (
                <hr css={css`margin: 2rem 0 3rem 0;`}/>
            )}

            {!!dropoffExceptions.length && (
                dropoffExceptions.map(i => (
                    <Exception exception={i.item_exception} item={i} />
                )
            ))}
            </>
        
    )}


    const content = useMemo(() => {
        if (!order) return '';
        if (!order?.order_exception) {
            return (
                <Exception exception={order.order_exception} />
            )
        } else {
            return renderItemExceptions(order?.itemsByOrderId);
        }
    }, [order])

    return (
        exceptionsModalOpen && (
            <ResponsiveSidebarDialog open={exceptionsModalOpen} onClose={closeExceptionsModal}>
                <ModalContent width={620}>
                    <div css={css`
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 3rem;
                    `}>
                        <Typography className="exception-modal-header">{order?.order_exception ? 'Exception ' : 'Exception(s) '} Reported</Typography>
                        <CloseIcon onClick={closeExceptionsModal} style={{ cursor: 'pointer' }} />
                    </div>
                    {content}
                </ModalContent>
            </ResponsiveSidebarDialog>
        )
    );
};

export default ExceptionsModal;
