import React from 'react';
import { BodyText, StickyCell } from '../blocks';

export default function BodyCell({ cell, callbacks = {}, isChecked, ...props }) {
    return (
        <StickyCell align="left" {...props}>
            <BodyText>{cell.render('Cell', { callbacks, isChecked })}</BodyText>
        </StickyCell>
    );
}
