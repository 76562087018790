import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Body1, Body2, Header1, Subtitle1 } from './blocks';
import { css } from '@emotion/react';

const ItemsCard = ({ orders = [] }) => {
    const items = useMemo(() => {
        return orders.reduce((acc, order) => {
            if (order?.items) {
                return [...acc, ...(order?.items || []).map((item) => [order, item])];
            }
            // Individual LTL orders
            return [
                ...acc,
                ...(order?.itemsByOrderId || []).filter((item) => !item.is_return).map((item) => [order, item]),
            ];
        }, []);
    }, [orders]);

    const returnItems = useMemo(() => {
        return orders.reduce((acc, order) => {
            if (order?.returns) {
                return [...acc, ...order.returns.map((item) => [order, item])];
            }
            // Individual LTL orders
            return [
                ...acc,
                ...(order?.itemsByOrderId || []).filter((item) => !!item.is_return).map((item) => [order, item]),
            ];
        }, []);
    }, [orders]);

    return (
        <Grid container>
            {items.length > 0 && (
                <Grid item xs={12}>
                    <Grid container>
                        <Header1>Items</Header1>
                        {items.map(([order, item]) => (
                            <Grid
                                item
                                key={item.item_id}
                                xs={12}
                                css={css`
                                    margin-top: 20px;
                                `}
                            >
                                <Body1>{item?.description ? item.description : 'Item Description Not Provided'}</Body1>
                                <br />
                                {order?.po_number && (
                                    <>
                                        <Subtitle1>PO Number: {order.po_number}</Subtitle1>
                                        <br />
                                    </>
                                )}
                                {item?.sku && (
                                    <>
                                        <Subtitle1>Item ID: {item.sku}</Subtitle1>
                                        <br />
                                    </>
                                )}
                                {item?.total_weight && (
                                    <>
                                        <Subtitle1>Weight: {item.total_weight}</Subtitle1>
                                        <br />
                                    </>
                                )}
                                <Subtitle1>Quantity: {item.quantity}</Subtitle1>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
            {returnItems.length > 0 && (
                <Grid item xs={12}>
                    <Grid
                        container
                        css={css`
                            ${items.length > 0 ? 'margin-top: 32px;' : ''}
                        `}
                    >
                        <Header1>Return Items</Header1>
                        {returnItems.map(([order, item]) => (
                            <Grid
                                item
                                key={item.item_id}
                                xs={12}
                                css={css`
                                    margin-top: 20px;
                                `}
                            >
                                <Body1>
                                    {item.description}
                                    {order.po_number ? ` - ${order.po_number}` : ''}
                                </Body1>
                                <br />
                                <Subtitle1>Quantity: {item.quantity}</Subtitle1>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default ItemsCard;
