import React, { useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { iconcss, LinkContainer, LinkLabel, SublinkIcon } from './blocks';
import { css } from '@emotion/react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { UserContext } from '../App';
import { Popover } from '@material-ui/core';
import { useClientUser } from '@/hooks';

const NavLink = ({ link }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { sidebarCollapsed } = useContext(UserContext);
    const { circles } = useClientUser();

    const sublinks = useMemo(() => {
        return (link?.sublinks || []).filter((sublink) => {
            if (!sublink.feature) return true;
            if (Array.isArray(sublink.feature)) {
                return sublink.feature.some((flag) => circles?.[flag]);
            } else {
                return circles?.[sublink.feature];
            }
        });
    }, [link]);

    // Current link or one of its sublinks is selected
    const selected = useMemo(() => {
        return sublinks
            ? sublinks.find((sublink) => sublink.path === location.pathname)
            : location.pathname === link.path;
    }, [link, sublinks, location.pathname]);

    // Auto open sublinks if one is selected
    const [sublinksOpen, setSublinksOpen] = useState(sublinks && selected);
    const [anchorEl, setAnchorEl] = useState(null);

    // Open sublinks or navigate to single link path
    const handleSelect = () => {
        if (sublinks.length > 0) {
            setSublinksOpen(!sublinksOpen);
            if (sublinks.length === 1 && !sublinksOpen) {
                navigate(sublinks[0].path);
            }
        } else {
            navigate(link.path);
        }
    };

    // Open collapsed sublink menu or navigate to single link path
    const handleSelectCollapsed = (event) => {
        if (sublinks.length > 0) {
            setAnchorEl(event.currentTarget);
        } else {
            navigate(link.path);
        }
    };

    // Navigate to sublink path
    const handleSublinkSelect = (sublink) => () => {
        navigate(sublink.path);
    };

    // Close collapsed sublink menu
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return sidebarCollapsed ? (
        <>
            <LinkContainer onClick={handleSelectCollapsed} selected={selected}>
                <link.Icon css={iconcss} />
            </LinkContainer>
            {sublinks.length > 0 && (
                <Popover
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={handleMenuClose}
                    css={css`
                        .MuiPaper-root {
                            background-color: rgb(12, 11, 29);
                        }
                        .MuiMenu-list {
                            padding: 0;
                        }
                        .MuiMenuItem-root {
                            padding: 0;
                        }
                    `}
                >
                    {sublinks.map((sublink) => (
                        <LinkContainer
                            selected={selected?.path === sublink.path}
                            onClick={handleSublinkSelect(sublink)}
                            key={`sublink-${sublink.path}`}
                            css={css`
                                width: 100%;
                                padding: 8px;
                            `}
                        >
                            <LinkLabel>{sublink.label}</LinkLabel>
                        </LinkContainer>
                    ))}
                </Popover>
            )}
        </>
    ) : (
        <>
            <LinkContainer onClick={handleSelect} selected={selected}>
                <link.Icon css={iconcss} />
                <LinkLabel>{link.label}</LinkLabel>
                {sublinks.length > 0 && (
                    <div
                        css={css`
                            flex-grow: 1;
                            color: ${selected ? '#eee' : '#ffffff80'};
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        {sublinksOpen ? <KeyboardArrowUp css={iconcss} /> : <KeyboardArrowDown css={iconcss} />}
                    </div>
                )}
            </LinkContainer>
            {sublinks.length > 0 && sublinksOpen && (
                <>
                    {sublinks.map((sublink) => (
                        <LinkContainer
                            selected={selected?.path === sublink.path}
                            onClick={handleSublinkSelect(sublink)}
                            key={`sublink-${sublink.path}`}
                        >
                            <SublinkIcon />
                            <LinkLabel>{sublink.label}</LinkLabel>
                        </LinkContainer>
                    ))}
                </>
            )}
        </>
    );
};

export default NavLink;
