import { Body, PrimaryButton } from '@/styles/blocks';
import { Grid, Popover } from '@material-ui/core';
import { Store } from '@material-ui/icons';
import React, { useContext } from 'react';
import { useState } from 'react';
import { Context } from './store';
import { css } from '@emotion/react';
import { BiddingDate, BiddingSwitch } from '../blocks';

const AuctionsPopover = ({ ...props }) => {
    const { state, callbacks } = useContext(Context);
    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    return (
        <div {...props}>
            <PrimaryButton onClick={(e) => setAnchor(e.currentTarget)} className={'mx-3'}>
                <Store />
                Auction
            </PrimaryButton>
            <Popover
                open={open}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        align-items: center;
                        padding: 20px;
                        width: 400px;
                    `}
                >
                    <Grid item xs={12}>
                        <Body
                            css={css`
                                font-weight: 600;
                                font-size: 16px;
                                margin-bottom: 10px;
                            `}
                        >
                            Onward Auction Bulk Actions
                        </Body>
                    </Grid>

                    <BiddingSwitch
                        checked={
                            Object.keys(state.listings).length > 0 &&
                            Object.values(state.listings).every((listing) =>
                                ['all', 'auction'].includes(listing.listing_type)
                            )
                        }
                        onChange={(_, checked) => {
                            callbacks.setListings((prev) =>
                                Object.fromEntries(
                                    Object.entries(prev).map(([key, listing]) => [
                                        key,
                                        { ...listing, listing_type: checked ? 'all' : 'claim_now' },
                                    ])
                                )
                            );
                        }}
                    />

                    <BiddingDate
                        selectedDate={state.expirationDate}
                        tz={state.orderTZ}
                        onChange={(val) => {
                            callbacks.setExpirationDate(val);
                            callbacks.setListings((prev) =>
                                Object.fromEntries(
                                    Object.entries(prev).map(([key, listing]) => [
                                        key,
                                        { ...listing, expiration_date: val },
                                    ])
                                )
                            );
                        }}
                    />
                    <Grid item xs={12} className="mt-3">
                        <Body>
                            *Any changes you make in this pop-up will overwrite any auction options you may have made on
                            an individual order below.
                        </Body>
                    </Grid>
                </Grid>
            </Popover>
        </div>
    );
};

export default AuctionsPopover;
