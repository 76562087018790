import { gql } from '@apollo/client';

import { LOCATION_FIELDS } from '@/graphql/queries/locations';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const QUERY_LOCATIONS_BY_STATE = gql`
    ${LOCATION_FIELDS}
    ${CLIENT_FIELDS}

    query LocationsByState($abb: String!, $full: String!, $feature_flag: jsonb! = { customerNewShipmentForm: true } ) {
        results: locations(
            where: {
                _or: [{ business_state: { _ilike: $abb } }, { business_state: { _eq: $full } }]
                client: { 
                    test_acc: { _eq: false }, 
                    user: { _and: [ 
                    { roles: { _has_key: "SHIPPER" } },
                    { circles: { _contains: $feature_flag } }
                    ]}
                }
            }
        ) {
            ...LocationFields

            client {
                ...ClientFields
            }
        }
    }
`;
