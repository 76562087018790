import { ITEM_FIELDS } from '@/graphql/queries/items';
import { MANIFEST_FIELDS } from '@/graphql/queries/manifests';
import { INBOUND_ITEM_FIELDS } from '@/graphql/queries/manifests_inbound_items';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { WAREHOUSE_EVENT_FIELDS } from '@/graphql/queries/order_wh_events';
import { PALLET_ITEM_FIELDS } from '@/graphql/queries/pallet_items';
import { ROUTE_MANIFEST_FIELDS } from '@/graphql/queries/routes';
import { gql } from '@apollo/client';

export const GET_MANIFEST = gql`
    ${MANIFEST_FIELDS}
    ${INBOUND_ITEM_FIELDS}
    ${ROUTE_MANIFEST_FIELDS}
    query GetManifest($manifest_id: uuid!) {
        manifest: manifests_by_pk(manifest_id: $manifest_id) {
            ...ManifestFields
            items {
                ...InboundItemFields
            }
            route {
                ...RouteManifestFields
            }
        }
    }
`;

export const GET_RACKS = gql`
    query GetRacks($location_id: uuid!) {
        location: locations_by_pk(location_id: $location_id) {
            location_id
            warehouse_racks
        }
    }
`;

export const UPDATE_MANIFEST = gql`
    ${MANIFEST_FIELDS}
    ${INBOUND_ITEM_FIELDS}
    ${ROUTE_MANIFEST_FIELDS}
    mutation UpdateManifest($manifest_id: uuid!, $update: manifests_set_input!) {
        updated: update_manifests_by_pk(pk_columns: { manifest_id: $manifest_id }, _set: $update) {
            ...ManifestFields
            items {
                ...InboundItemFields
            }
            route {
                ...RouteManifestFields
            }
        }
    }
`;

export const DELETE_MANIFEST = gql`
    mutation DeleteManifest($manifest_id: uuid!) {
        removed: delete_manifests_by_pk(manifest_id: $manifest_id) {
            manifest_id
        }
    }
`;

export const INSERT_PALLET = gql`
    ${ITEM_FIELDS}
    ${WAREHOUSE_EVENT_FIELDS}
    mutation InsertItemPallet(
        $item_id: uuid!
        $pallet: pallet_items_insert_input!
        $events: [order_wh_events_insert_input!] = []
    ) {
        item: insert_items_one(
            object: { item_id: $item_id, pallet: { data: $pallet } }
            on_conflict: { constraint: items_pkey, update_columns: [pallet_id] }
        ) {
            ...ItemFields
        }
        events: insert_order_wh_events(
            objects: $events
            on_conflict: { constraint: order_wh_events_pkey, update_columns: [status, received_date] }
        ) {
            returning {
                ...WarehouseEvents
            }
        }
    }
`;

export const INSERT_EMPTY_PALLET = gql`
    ${PALLET_ITEM_FIELDS}
    mutation InsertEmptyPallet($object: pallet_items_insert_input = {}) {
        insert_pallet_items_one(object: $object) {
            ...PalletItemFields
        }
    }
`;

export const UPDATE_PALLET = gql`
    ${PALLET_ITEM_FIELDS}
    mutation UpdateItemPallet($pallet_id: uuid!, $update: pallet_items_set_input!) {
        pallet: update_pallet_items_by_pk(pk_columns: { pallet_id: $pallet_id }, _set: $update) {
            ...PalletItemFields
        }
    }
`;

export const UPDATE_PALLETS_MANY = gql`
    ${PALLET_ITEM_FIELDS}
    mutation UpdateItemPallets($updates: [pallet_items_updates!]!) {
        updated: update_pallet_items_many(updates: $updates) {
            returning {
                ...PalletItemFields
            }
        }
    }
`;

export const UPDATE_ORDERS = gql`
    ${ORDER_FIELDS}
    mutation UpdateOrders($order_ids: [uuid!]!, $update: orders_set_input!) {
        update_orders(where: { order_id: { _in: $order_ids } }, _set: $update) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;

export const UPDATE_ITEM_PALLETS_MANY = gql`
    ${ITEM_FIELDS}
    ${PALLET_ITEM_FIELDS}
    ${ORDER_FIELDS}
    mutation UpdateItemPallets(
        $itemUpdates: [items_updates!] = {}
        $palletUpdates: [pallet_items_updates!] = {}
        $palletsToDelete: [uuid!] = ""
    ) {
        update_items_many(updates: $itemUpdates) {
            affected_rows
            returning {
                ...ItemFields
            }
        }
        update_pallet_items_many(updates: $palletUpdates) {
            affected_rows
            returning {
                ...PalletItemFields
                items {
                    ...ItemFields
                    order {
                        ...OrderFields
                    }
                }
            }
        }
        delete_pallet_items(where: { pallet_id: { _in: $palletsToDelete } }) {
            affected_rows
        }
    }
`;
