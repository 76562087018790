import React, { createContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { endOfMonth, startOfMonth, addDays, subDays, isEqual, subMonths, addMonths, isBefore } from 'date-fns';
import { useQuery, useMutation } from '@apollo/client';
import { useClientUser } from '@/hooks';
import { asUTCDate, asDateInTZ } from '@/utilities/convertToISO';
import cache from '@/graphql';
import * as Sentry from '@sentry/react';
import { UPSERT_RESOURCES, DELETE_RULE, DELETE_RESOURCE } from './graphql/mutations';
import { QUERY_BY_CLIENT as RESOURCES_BY_CLIENT } from '@/graphql/queries/resources';
import { QUERY_BY_CLIENT as SUBREGIONS_BY_CLIENT } from '@/graphql/queries/subregions';

import { useResourceCallbacks } from './hooks';
import { scheduleResource } from './utils';

export const Context = createContext();

const TODAY = new Date().toISOString();
const cutoff = subMonths(new Date(TODAY), 1);

export const ContextProvider = ({ children }) => {
    const user = useClientUser();
    const [notification, setNotification] = useState({});
    const [editing, editResource] = useState({});
    const [offset, setMonthOffset] = useState(0);

    const base = useMemo(() => {
        return addMonths(new Date(TODAY), offset);
    }, [offset]);

    const tz = useMemo(() => {
        return user.businessZip ? zipcode_to_timezone.lookup(user.businessZip) : 'America/New_York';
    }, [user]);

    const intervals = useMemo(() => {
        const adjustTZ = (ts) => {
            const utc = new Date(asUTCDate(ts).setUTCHours(0, 0, 0, 0)).toISOString();
            return asDateInTZ(utc, tz).toISOString();
        };

        const rv = [];
        const todayAdjusted = new Date(adjustTZ(TODAY));
        const adjusted = new Date(adjustTZ(base.toISOString()));
        const som = adjustTZ(startOfMonth(adjusted).toISOString());
        const eom = adjustTZ(endOfMonth(adjusted).toISOString());
        const somObj = new Date(som);
        const eomObj = new Date(eom);
        const start = subDays(somObj, somObj.getUTCDay());
        const end = addDays(eomObj, 6 - eomObj.getUTCDay());

        let curr = start;
        while (isEqual(curr, end) || isBefore(curr, end)) {
            const next = addDays(curr, 1);
            const dow = curr.getUTCDay();
            if (dow === 0) {
                rv.push([
                    {
                        thisYear: todayAdjusted.getFullYear() === curr.getFullYear(),
                        isCurrentMonth: adjusted.getMonth() === curr.getMonth(),
                        thisMonth: todayAdjusted.getMonth() === curr.getMonth(),
                        isToday: todayAdjusted.getDate() === curr.getDate(),
                        date: curr.getDate(),
                        start: curr.toISOString(),
                        end: next.toISOString(),
                        dow,
                    },
                ]);
            } else {
                rv[rv.length - 1].push({
                    thisYear: todayAdjusted.getFullYear() === curr.getFullYear(),
                    isCurrentMonth: adjusted.getMonth() === curr.getMonth(),
                    thisMonth: todayAdjusted.getMonth() === curr.getMonth(),
                    isToday: todayAdjusted.getDate() === curr.getDate(),
                    date: curr.getDate(),
                    start: curr.toISOString(),
                    end: next.toISOString(),
                    dow,
                });
            }

            curr = next;
        }

        return rv;
    }, [tz, base]);

    const { data: resources } = useQuery(RESOURCES_BY_CLIENT, {
        variables: {
            client_id: user?.user_id,
            cutoff,
        },
    });

    const { data: subregions } = useQuery(SUBREGIONS_BY_CLIENT, {
        variables: {
            client_id: user?.user_id,
        },
    });

    const [deleteResource, { loading: deleteResourceInFlight }] = useMutation(DELETE_RESOURCE, {
        update: (cache, { data: { deleted } }) => {
            cache.updateQuery(
                {
                    query: RESOURCES_BY_CLIENT,
                    variables: {
                        client_id: user?.user_id,
                        cutoff,
                    },
                },
                (data) => {
                    let rv = [...data.results];

                    return {
                        ...data,
                        results: rv.filter(
                            (resource) =>
                                !deleted.returning.map((deleted) => deleted.resource_id).includes(resource.resource_id)
                        ),
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to delete resource' });
        },
    });

    const [upsertResources, { loading: upsertResourceInFlight }] = useMutation(UPSERT_RESOURCES, {
        update: (cache, { data: { added_resource, removed_rules, removed_overrides, removed_regions } }) => {
            cache.updateQuery(
                {
                    query: RESOURCES_BY_CLIENT,
                    variables: {
                        client_id: user?.user_id,
                        cutoff,
                    },
                },
                (data) => {
                    let rv = [...data.results];
                    const idx = rv.findIndex(
                        (resource) => resource.resource_id === added_resource.returning[0].resource_id
                    );
                    let updated = added_resource.returning[0];
                    updated = {
                        ...updated,
                        subregions: updated.subregions.filter(
                            (subregion) =>
                                !removed_regions.returning
                                    .map((removed) => removed.mapping_id)
                                    .includes(subregion.mapping_id)
                        ),
                        rules: updated.rules.filter(
                            (rule) =>
                                !removed_rules.returning.map((removed) => removed.mapping_id).includes(rule.mapping_id)
                        ),
                        overrides: updated.overrides.filter(
                            (override) =>
                                !removed_overrides.returning
                                    .map((removed) => removed.mapping_id)
                                    .includes(override.mapping_id)
                        ),
                    };

                    if (idx === -1) {
                        rv = [...rv, updated];
                    } else {
                        rv[idx] = updated;
                    }

                    return {
                        ...data,
                        results: rv,
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to update resource' });
        },
    });

    const [deleteRule, { loading: deleteRuleInFlight }] = useMutation(DELETE_RULE, {
        update: (cache, { data: { rules, overrides } }) => {
            cache.updateQuery(
                {
                    query: RESOURCES_BY_CLIENT,
                    variables: {
                        client_id: user?.user_id,
                        cutoff,
                    },
                },
                (data) => {
                    let rv = [...data.results];

                    rules.returning.forEach(({ mapping_id, resource_id }) => {
                        const idx = rv.findIndex((resource) => resource.resource_id === resource_id);
                        if (idx >= 0) {
                            rv[idx] = {
                                ...rv[idx],
                                rules: rv[idx].rules.filter((rule) => rule.mapping_id !== mapping_id),
                            };
                        }
                    });

                    overrides.returning.forEach(({ mapping_id, resource_id }) => {
                        const idx = rv.findIndex((resource) => resource.resource_id === resource_id);
                        if (idx >= 0) {
                            rv[idx] = {
                                ...rv[idx],
                                overrides: rv[idx].overrides.filter((override) => override.mapping_id !== mapping_id),
                            };
                        }
                    });

                    return {
                        ...data,
                        results: rv,
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to delete resource' });
        },
    });

    const dayToResource = useMemo(() => {
        const rv = {};
        intervals.forEach((week, weekIdx) => {
            week.forEach((day, dayIdx) => {
                (resources?.results || []).forEach((resource) => {
                    const [toSchedule, entity] = scheduleResource({ resource, day });

                    if (toSchedule) {
                        rv[weekIdx] = {
                            ...rv[weekIdx],
                            [dayIdx]: {
                                ...(rv?.[weekIdx]?.[dayIdx] || {}),
                                [resource.resource_id]: entity,
                            },
                        };
                    }
                });
            });
        });

        return rv;
    }, [resources, intervals]);

    const subregionsById = useMemo(() => {
        return Object.fromEntries((subregions?.results || []).map((subregion) => [subregion.subregion_id, subregion]));
    }, [subregions]);

    const resourceById = useMemo(() => {
        return Object.fromEntries((resources?.results || []).map((resource) => [resource.resource_id, resource]));
    }, [resources]);

    const entitiesById = useMemo(() => {
        return (resources?.results || []).reduce((acc, resource) => {
            const { overrides, rules, ...rest } = resource;

            return {
                overrides: {
                    ...acc.overrides,
                    ...Object.fromEntries(
                        (overrides || []).map((o) => {
                            const { __typename, ...override } = o;
                            return [override.mapping_id, override];
                        })
                    ),
                },
                rules: {
                    ...acc.rules,
                    ...Object.fromEntries(
                        (rules || []).map((o) => {
                            const { __typename, ...rule } = o;
                            return [rule.mapping_id, rule];
                        })
                    ),
                },
            };
        }, {});
    }, [resources]);

    const callbacks = useResourceCallbacks({}, { upsertResources, editResource, deleteRule, deleteResource });

    return (
        <Context.Provider
            value={{
                state: {
                    subregionsOrdered: subregions?.results || [],
                    editing,
                    notification,
                    intervals,
                    tz,
                    dayToResource,
                    resourceById,
                    entitiesById,
                    subregionsById,
                    baseDate: base,
                },
                loading: {
                    editModal: upsertResourceInFlight || deleteRuleInFlight || deleteResourceInFlight,
                },
                callbacks: {
                    ...callbacks,
                    nextMonth: () => {
                        setMonthOffset((prev) => prev + 1);
                    },
                    prevMonth: () => {
                        setMonthOffset((prev) => prev - 1);
                    },
                    clearEdit: () => {
                        editResource({});
                    },
                    clearNotification: () => {
                        setNotification({});
                    },
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};
