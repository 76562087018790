import React, { useState } from 'react';
import { css } from '@emotion/react';
import { SecondaryButton } from '@/styles/blocks';
import { Elements } from '@stripe/react-stripe-js';
import { Grid } from '@material-ui/core';
import { ResponsiveSidebarDialog, StickyModalActions, ModalContent, ModalTitle } from '@/styles/blocks';
import CardSetupForm from '@/components/Stripe/CardSetupForm';
import { useClientUser } from '@/hooks';
import { loadStripe } from '@stripe/stripe-js';

const getStripeKey = () =>
    process.env.REACT_APP_ENVIRONMENT === 'production'
        ? 'pk_live_GkLHbJguG8egPK2kovoxdBCa006YTIcMMU'
        : 'pk_test_HrMrx9FzlVtiZdchU0ptwIK100cCrxV6l1';

const CreditCardModal = ({ handleClose, onSuccess, open }) => {
    const { stripe_user_id, username, user_id, email, client_id } = useClientUser();

    const [stripePromise] = useState(() => loadStripe(getStripeKey()));

    return (
        <ResponsiveSidebarDialog open={open} onClose={() => handleClose()}>
            <ModalContent width={800}>
                <Grid container>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 2rem;
                        `}
                    >
                        A credit card on the account is required to send orders to the Onward Marketplace. A card may be
                        added below.
                    </Grid>
                </Grid>
                <Elements stripe={stripePromise} id={stripe_user_id} curr_user={user_id} email={email}>
                    <CardSetupForm
                        user={{
                            stripe_user_id,
                            username,
                            client_id,
                        }}
                        onSuccess={onSuccess}
                    />
                </Elements>
            </ModalContent>
            <StickyModalActions className="mb-2">
                <Grid container direction="column">
                    <Grid container item justifyContent="flex-end" className="px-5">
                        <SecondaryButton onClick={() => handleClose()}>Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default CreditCardModal;
