import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { typography } from '@/styles';
import { STATUSES } from './constants';

export const H1 = styled.span`
    ${typography.sansSerif}

    font-weight: 900;
    font-size: 20px;
`;

export const H2 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 12px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 16px;
`;

export const Body2 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 16px;
`;

export const Subtitle1 = styled.span`
    ${typography.sansSerif}

    font-weight: 600;
    font-size: 16px;
`;

export const Subtitle2 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
`;
