import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import { useLazyQuery } from '@apollo/client';
import { Grid, TextField, FormControlLabel, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { colors } from '@/styles';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import { enrich, addressSplit } from '@/components/ShipmentForm/utilities/processOrders';
import { validatePhoneNumber } from '@/utilities/validatePhoneNumber';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import {
    LocationType,
    PickupType,
    StateSelect,
    StoreSelect,
    ClientSelect,
} from '@/components/ShipmentForm/modals/InputFields';

import Footer from './Footer';

import { QUERY_LOCATIONS_BY_STATE } from '../graphql/queries';
import { local, FlexRow, SubHeader1, FlexColumn, DefaultResponsiveTextField, Header1, PrimaryButton } from '../blocks';
import { FORM_STATUS, SUPPORT_PHONE_NUMBER } from '../constants';
import ProgressBar from './ProgressBar';

const REQUIRED_FIELDS = [
    'pickup_name',
    'pickup_phone',
    'pickup_street_address',
    'pickup_state',
    'pickup_city',
    'pickup_zip',
    'pickup_lat',
    'pickup_lng',
    'pickup_address',
    'pickup_full_address',
    'pickup_location',
    'pickup_location_type',
    'shipper_id',
];

function PickupBusiness({ isDesktop, callbacks, state }) {
    const [section, setSection] = useState(state.sections[state.progression.current] || {});
    const [isDirty, setDirty] = useState({});
    const [isCustomOverride, setCustom] = useState(false);
    const [storeState, setStoreState] = useState([]);
    const [client, setClient] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);

    const [fetchLocations, { data: matches }] = useLazyQuery(QUERY_LOCATIONS_BY_STATE, {
        variables: { abb: `${storeState[0]}%`, full: `${storeState[1]}` },
        onError: (e) => {
            console.error(e);
            callbacks.setStatus(FORM_STATUS.FAILED);
        },
    });

    useEffect(() => {
        if (storeState.length > 0) {
            fetchLocations();
        }
    }, [storeState]);

    const locations = useMemo(() => {
        if (matches?.results.length > 0) {
            let locationsArray = matches?.results.filter((location) => location.client_id === client);
            locationsArray.sort((a, b) => {
                if (a.location_name < b.location_name) {
                    return -1;
                }
                if (a.location_name > b.location_name) {
                    return 1;
                }
                return 0;
            });
            return locationsArray;
        }

        return [];
    }, [matches, client]);

    const clients = useMemo(() => {
        if (matches?.results.length > 0) {
            let clientsArray = Object.values(
                matches?.results.reduce((acc, { client }) => {
                    acc[client.client_id] = client;

                    return acc;
                }, {})
            );
            clientsArray.sort((a, b) => {
                if (a.business_name < b.business_name) {
                    return -1;
                }
                if (a.business_name > b.business_name) {
                    return 1;
                }
                return 0;
            });
            return clientsArray;
        }
        return [];
    }, [matches]);

    useEffect(() => {
        if (selectedStore !== null && locations.length > 0) {
            const store = locations[selectedStore];
            const [address1, address2] = addressSplit(store.business_address || '');
            const address = [
                address1,
                ...(store.business_city ? [store.business_city] : []),
                ...(store.business_state ? [store.business_state] : []),
                ...(store.business_zip ? [store.business_zip] : []),
            ].join(', ');

            enrich(
                {
                    pickup_address: address,
                },
                {},
                { pickup: true }
            ).then(([{ geocodePartialMatch, geocodeFailed, ...enriched }]) => {
                const meta = {
                    pickup_name: store.location_name,
                    pickup_phone: store.client.business_phone,
                    pickup_email: store.client.email,
                    pickup_location: 'Business',
                    pickup_location_type: 'inStore',
                };

                if (geocodePartialMatch || geocodeFailed) {
                    setSection((prev) => ({
                        ...prev,
                        ...meta,
                        pickup_address: address,
                        pickup_street_address: null,
                        pickup_city: null,
                        pickup_zip: null,
                        pickup_state: null,
                    }));
                } else {
                    setSection((prev) => ({
                        ...prev,
                        ...meta,
                        ...enriched,
                        pickup_unit: address2,
                    }));
                }

                setDirty(Object.fromEntries(REQUIRED_FIELDS.map((field) => [field, true])));
            });
        }
    }, [selectedStore, locations]);

    const isEmpty = useCallback(
        (field) => {
            const invalid = REQUIRED_FIELDS.includes(field) && (!section[field] || section[field].length === 0);
            switch (field) {
                case 'pickup_phone':
                    return invalid || !validatePhoneNumber(section[field]);
                default:
                    return invalid;
            }
        },
        [section]
    );

    const hasError = useMemo(() => {
        return REQUIRED_FIELDS.some((field) => isEmpty(field));
    }, [isEmpty]);

    const isCustom = useMemo(() => {
        if (isCustomOverride) {
            return isCustomOverride;
        }

        const storeHasErrors = hasError && selectedStore !== null;
        const customStore = selectedStore === null && !hasError;

        return storeHasErrors || customStore;
    }, [isCustomOverride, storeState, selectedStore, clients, hasError]);

    const custom = (
        <>
            {[
                { display: 'Store Name', value: 'pickup_name' },
                { display: 'Email', value: 'pickup_email' },
            ].map(({ display, value }) => (
                <DefaultResponsiveTextField
                    key={value}
                    display={display}
                    value={value}
                    state={section}
                    isDirty={isDirty}
                    callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                />
            ))}
            <FlexColumn
                item
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <TextField
                    variant="outlined"
                    label="Phone"
                    InputProps={{
                        inputComponent: PhoneNumberInput,
                    }}
                    InputLabelProps={{ shrink: !!section.pickup_phone }}
                    value={section.pickup_phone ? toNational(section.pickup_phone) : ''}
                    onBlur={(e) => {
                        setDirty((prev) => ({ ...prev, pickup_phone: true }));
                    }}
                    onChange={(e) => {
                        setSection((prev) => ({ ...prev, pickup_phone: toE164(e.target.value) }));
                    }}
                    error={isEmpty('pickup_phone') && isDirty.pickup_phone}
                />
            </FlexColumn>
            <FlexColumn
                item
                css={css`
                    margin-bottom: 20px;
                `}
            >
                {['pickup_address', 'pickup_street_address', 'pickup_city', 'pickup_state', 'pickup_zip'].some(
                    (attr) => isEmpty(attr) && isDirty[attr]
                ) ? (
                    <Grid
                        item
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <SubHeader1
                            css={css`
                                color: #d23e3e;
                            `}
                        >
                            {`Address not found. For help please contact us at ${toNational(SUPPORT_PHONE_NUMBER)}.`}
                        </SubHeader1>
                    </Grid>
                ) : null}
                <Grid item>
                    <AddressAutocomplete
                        state={{
                            street: section.pickup_street_address,
                            city: section.pickup_city,
                            state: section.pickup_state,
                            zip: section.pickup_zip,
                        }}
                        onBlur={(e) => {
                            setDirty((prev) => ({
                                ...prev,
                                ...Object.fromEntries(
                                    [
                                        'pickup_address',
                                        'pickup_street_address',
                                        'pickup_city',
                                        'pickup_state',
                                        'pickup_zip',
                                    ].map((field) => [field, true])
                                ),
                            }));
                        }}
                        handleAddressUpdate={async (value) => {
                            const [{ geocodePartialMatch, geocodeFailed, ...enriched }] = await enrich(
                                { pickup_address: value },
                                {},
                                { pickup: true }
                            );
                            if (geocodePartialMatch || geocodeFailed) {
                                setSection((prev) => ({
                                    ...prev,
                                    pickup_address: value,
                                    pickup_street_address: null,
                                    pickup_city: null,
                                    pickup_zip: null,
                                    pickup_state: null,
                                }));
                            } else {
                                setSection((prev) => ({ ...prev, ...enriched }));
                            }
                        }}
                        error={[
                            'pickup_address',
                            'pickup_street_address',
                            'pickup_city',
                            'pickup_state',
                            'pickup_zip',
                        ].some((attr) => isEmpty(attr) && isDirty[attr])}
                    />
                </Grid>
            </FlexColumn>
            {[
                { display: 'Unit/Suite/Lot', value: 'pickup_unit' },
                { display: 'City', value: 'pickup_city', disabled: true },
                { display: 'State', value: 'pickup_state', disabled: true },
                { display: 'Zip', value: 'pickup_zip', disabled: true },
            ].map(({ display, value, disabled }) => (
                <DefaultResponsiveTextField
                    disabled={!!disabled}
                    key={value}
                    display={display}
                    value={value}
                    state={section}
                    isDirty={isDirty}
                    callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                />
            ))}
            <FlexColumn
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <LocationType
                    value={section.pickup_location}
                    onBlur={(e) => {
                        setDirty((prev) => ({ ...prev, pickup_location: true }));
                    }}
                    onChange={(e) =>
                        setSection((prev) => ({
                            ...prev,
                            pickup_location: e.target.value,
                        }))
                    }
                    error={isEmpty('pickup_location') && isDirty.pickup_location}
                />
            </FlexColumn>
            <FlexColumn
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <PickupType
                    disabled={isEmpty('pickup_location')}
                    value={section.pickup_location_type}
                    locationType={section.pickup_location}
                    onBlur={(e) => {
                        setDirty((prev) => ({ ...prev, pickup_location: true }));
                    }}
                    onChange={(e) =>
                        setSection((prev) => ({
                            ...prev,
                            pickup_location_type: e.target.value,
                        }))
                    }
                    error={isEmpty('pickup_location_type') && isDirty.pickup_location_type}
                />
            </FlexColumn>
        </>
    );

    return (
        <Grid
            container
            direction="column"
            css={css`
                color: ${local.greys[3]};
            `}
        >
            <ProgressBar step={state.progression.current + 1} maxSteps={7} />
            <Grid
                container
                css={css`
                    margin-bottom: 28px;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid item direction="column">
                    <Header1
                        css={css`
                            color: ${local.greys[4]};
                        `}
                    >
                        Pickup Information
                    </Header1>
                </Grid>
                <Grid item direction="column">
                    <IconButton
                        css={css`
                            padding: 4px;
                        `}
                        onClick={callbacks.showInfo}
                    >
                        <HelpIcon
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        />
                    </IconButton>
                </Grid>
            </Grid>

            <FlexRow>
                <FlexColumn
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <StateSelect
                        value={storeState[0]}
                        onChange={(value) => {
                            setSelectedStore(null);
                            setClient(null);
                            setStoreState(value);
                            setSection((prev) => ({ shipper_id: null }));
                        }}
                    />
                </FlexColumn>
                {clients.length > 0 ? (
                    <FlexColumn
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <ClientSelect
                            value={client}
                            options={clients}
                            onChange={(id) => {
                                setSelectedStore(null);
                                setClient(id);
                                setSection((prev) => ({ shipper_id: id }));
                            }}
                            type="shipper"
                        />
                    </FlexColumn>
                ) : null}
                {locations.length > 0 ? (
                    <FlexColumn
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <StoreSelect
                            value={selectedStore}
                            onChange={(e) => {
                                setSelectedStore(parseInt(e.target.value, 10));
                            }}
                            storeList={locations}
                        />
                    </FlexColumn>
                ) : null}
            </FlexRow>

            {clients.length === 0 && storeState.length > 0 ? (
                <FlexRow
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <SubHeader1>We're Sorry, Onward isn't available in your area.</SubHeader1>
                </FlexRow>
            ) : null}

            {locations.length > 0 || isCustom ? (
                <FlexRow
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <FormControlLabel
                        control={
                            <OnwardCheckbox
                                color="primary"
                                checked={isCustom || false}
                                onChange={() => setCustom((prev) => !prev)}
                            />
                        }
                        label="Can’t find the store? Enter it manually."
                        css={css`
                            margin-left: 0;
                        `}
                    />
                </FlexRow>
            ) : null}

            <FlexRow>{isCustom ? custom : null}</FlexRow>

            <Footer
                state={state}
                isFirst={state.isFirstStage}
                isLast={state.isLastStage}
                hasErrors={hasError}
                callbacks={{ advance: () => callbacks.advance(section), revert: callbacks.revert }}
            />
        </Grid>
    );
}

export default PickupBusiness;
