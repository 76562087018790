import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import MoreVert from '@material-ui/icons/MoreVert';

const StopCardMenu = ({ stop, routeStatus, callbacks }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAction = (option, e) => {
        e.stopPropagation();
        setAnchorEl(null);

        switch (option) {
            case 'Show Details':
                callbacks.setStopDetailsModal(true);
                break;
            case 'Delay Timeframe':
                callbacks.setDelayTimeframeModalOpened(true);
                break;
            case 'Cancel Order(s)':
                callbacks.setCancelModalOpened(true);
                break;
            case 'Complete Stop':
                callbacks.setCompleteStopModalOpened(true);
                break;
        }
    };

    const options = ['Show Details', 'Delay Timeframe', 'Complete Stop', 'Cancel Order(s)'];
    return (
        <>
            <IconButton id="long-button" onClick={handleClick} className="p-1">
                <MoreVert />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options.map((option, i) => (
                    <MenuItem
                        key={i}
                        onClick={(e) => handleAction(option, e)}
                        style={{
                            color: option === 'Cancel Order(s)' ? 'red' : 'inherit',
                        }}
                        disabled={
                            (stop.stop_completion_time &&
                                ['Delay Timeframe', 'Complete Stop', 'Cancel Order(s)'].includes(option)) ||
                            (routeStatus !== 'inProgress' && option === 'Complete Stop')
                        }
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default StopCardMenu;
