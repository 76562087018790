import React, { useEffect, useState } from 'react';
import { getAuth, updateEmail } from 'firebase/auth';
import { Row, Button, Container, Card, Form, Col, Modal } from 'react-bootstrap';
import { TextField, InputLabel, FormControl, Select } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import states from '../CarrierAccount/constants/states';
import { useMutation } from '@apollo/client';
import {
    UPDATE_TEAMMATE_PERSONAL_INFO,
    UPDATE_TEAMMATE_USER_PERSONAL_INFO,
    TEAMMATE_UPDATE_MAIN_CARRIER_INFO,
} from '../mutations';
import { toNational, toE164, validate } from '@/utilities/formatPhoneNumber';
import { css } from '@emotion/react';
import FleetSizeInput from '../FleetSizeInput';

export default function CarrierTeammateProfile(props) {
    const { user = {}, adminLoggedIn } = props;

    const [personalName, setPersonalName] = useState('');
    const [personalEmail, setPersonalEmail] = useState('');
    const [personalPhone, setPersonalPhone] = useState('');

    const [businessName, setBusinessName] = useState('');
    const [primaryName, setPrimaryName] = useState('');
    const [primaryEmail, setPrimaryEmail] = useState('');
    const [primaryPhone, setPrimaryPhone] = useState('');
    const [supportPhone, setSupportPhone] = useState('');
    const [updateMessage, setUpdateMessage] = useState('');
    const [billingState, setBillingState] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [billingZip, setBillingZip] = useState('');
    const [emailModal, setEmailModal] = useState(false);
    const [mcNumber, setMcNumber] = useState('');
    const [einNumber, setEinNumber] = useState('');
    const [fleetSize, setFleetSize] = useState(null);
    const [dotNumber, setDotNumber] = useState('');
    const [validated, setValidated] = useState(false);
    const [mainAccountEmailModal, setMainAccountEmailModal] = useState(false);

    const displayMainAccountInfo =
        user?.teammate?.userByTeamLeaderId?.circles?.['saas-v1'] && user?.teammate?.roles?.admin;

    const [UpdateTeammatePersonalInfo] = useMutation(UPDATE_TEAMMATE_PERSONAL_INFO, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const [UpdateUserPersonalInfo] = useMutation(UPDATE_TEAMMATE_USER_PERSONAL_INFO, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const [UpdateMainCarrierInfo] = useMutation(TEAMMATE_UPDATE_MAIN_CARRIER_INFO, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const AdminEmailModal = (props) => {
        const { show, onHide, adminLoggedIn } = props;

        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header>Email Change</Modal.Header>

                <Modal.Body>
                    Please be aware that changing your primary contact email will also change the login email for this
                    account.
                    <div className="text-danger">
                        {adminLoggedIn ? 'Super admins cannot change account emails at this time.' : ''}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const MainAccountEmailModal = (props) => {
        const { show, onHide, adminLoggedIn } = props;

        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header>Email Change</Modal.Header>

                <Modal.Body>
                    Please be aware that changing your primary contact email will also change the login email for this
                    account.
                    <div className="text-danger">
                        {adminLoggedIn
                            ? 'Super admins cannot change account emails at this time.'
                            : 'Only the main account holder may update this email. Changes to it will not be saved.'}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            try {
                await UpdateTeammatePersonalInfo({
                    variables: {
                        teammate_id: user?.user_id,
                        username: personalName,
                        email: adminLoggedIn ? user.email : personalEmail,
                        phone: validate(personalPhone) ? toE164(personalPhone) : personalPhone,
                    },
                });

                await UpdateUserPersonalInfo({
                    variables: {
                        user_id: user?.user_id,
                        username: personalName,
                        email: adminLoggedIn ? user.email : personalEmail,
                    },
                });

                if (!adminLoggedIn && personalEmail !== user?.email) {
                    const auth = getAuth();
                    const user = auth.currentUser;
                    await updateEmail(user, primaryEmail);
                }

                if (displayMainAccountInfo) {
                    let mainAccountUpdatePayload = {
                        business_phone: validate(primaryPhone) ? toE164(primaryPhone) : primaryPhone,
                        support_phone: validate(supportPhone) ? toE164(supportPhone) : supportPhone,
                        business_name: businessName,
                        billing_city: billingCity,
                        billing_zip: billingZip,
                        billing_state: billingState,
                        billing_address: billingAddress,
                        mc_num: mcNumber,
                        business_ein: einNumber,
                        dot_number: dotNumber,
                        ...(Number.isInteger(fleetSize) ? { fleet_size: fleetSize } : {}),
                    };

                    await UpdateMainCarrierInfo({
                        variables: {
                            client_id: user?.teammate?.userByTeamLeaderId?.user_id,
                            ...mainAccountUpdatePayload,
                        },
                    });
                }

                setUpdateMessage('Profile updated!');
            } catch (e) {
                console.log({ e });
                setUpdateMessage(e);
                Sentry.captureException(e);
            }
        } else {
            setUpdateMessage('Please fill out required fields');
        }
        setValidated(true);
    };

    useEffect(() => {
        (async () => {
            setPersonalName(user?.teammate?.username || '');
            setPersonalEmail(user?.email || '');

            const _personalPhone = user?.teammate?.phone ? (
                validate(user?.teammate?.phone) ? toNational(user?.teammate?.phone) : user?.teammate?.phone
            ) : '';
            setPersonalPhone(_personalPhone);

            if (displayMainAccountInfo) {
                let mainAccData = user?.teammate?.userByTeamLeaderId?.client;

                const _primaryPhone = mainAccData.business_phone ? (
                    validate(mainAccData.business_phone) ? toNational(mainAccData.business_phone) : mainAccData.business_phone
                ) : '';
                const _supportPhone = mainAccData.support_phone ? (
                    validate(mainAccData.support_phone) ? toNational(mainAccData.support_phone) : mainAccData.support_phone
                ) : '';

                setPrimaryPhone(_primaryPhone);
                setSupportPhone(_supportPhone);
                setPrimaryName(mainAccData.username || '');
                setPrimaryEmail(mainAccData.email || '');
                setBusinessName(mainAccData.business_name || '');
                setBillingCity(mainAccData.billing_city || '');
                setBillingZip(mainAccData.billing_zip || '');
                setBillingState(mainAccData.billing_state || '');
                setBillingAddress(mainAccData.billing_address || '');
                setMcNumber(mainAccData.mc_num || '');
                setEinNumber(mainAccData.business_ein || '');
                setDotNumber(mainAccData.dot_number || '');
                setFleetSize(mainAccData.fleet_size);

                if (mainAccData.billing_state) {
                    let trimmedBillingState = mainAccData.billing_state.trim();
                    setBillingState(trimmedBillingState);
                }
            }
        })();
    }, [user]);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    return (
        <Card className="tabCard">
            <Card.Body className="overflow-auto">
                <Container fluid>
                    <ThemeProvider theme={theme}>
                        <Form id="accountDetails" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="d-flex">
                                <Col>
                                    <Form.Group className="my-2 w-100">
                                        <TextField
                                            required
                                            fullWidth
                                            className=""
                                            value={personalName}
                                            variant="outlined"
                                            label={'Name'}
                                            onChange={(e) => setPersonalName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="my-2 w-100">
                                        <TextField
                                            required
                                            fullWidth
                                            className=""
                                            value={personalEmail}
                                            variant="outlined"
                                            label={'Email'}
                                            onChange={(e) => (e) => setPersonalEmail(e.target.value)}
                                            onClick={(e) => setEmailModal(true)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="my-2 w-100">
                                        <TextField
                                            label="Personal Phone"
                                            variant="outlined"
                                            className="w-100"
                                            required
                                            onChange={(e) => setPersonalPhone(e.target.value)}
                                            name="phone"
                                            value={personalPhone}
                                            id="formatted-phone-input"
                                            InputProps={{
                                                inputComponent: PhoneNumberInput,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {displayMainAccountInfo && (
                                <>
                                    <Form.Label 
                                        className="align-self-center font-weight-bold mt-3" 
                                        css={css`
                                            font-weight: 700;
                                        `}
                                    >
                                        Main Account Info
                                    </Form.Label>

                                    <Row className="d-flex">
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    className=""
                                                    error={!primaryName && validated}
                                                    value={primaryName || ''}
                                                    variant="outlined"
                                                    label={'Name'}
                                                    onChange={(e) => setPrimaryName(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    className=""
                                                    value={primaryEmail || ''}
                                                    error={!primaryEmail && validated}
                                                    variant="outlined"
                                                    label={'Email'}
                                                    onChange={(e) => setPrimaryEmail(e.target.value)}
                                                    onClick={(e) => setMainAccountEmailModal(true)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    label="Phone"
                                                    variant="outlined"
                                                    className="w-100"
                                                    required
                                                    onChange={(e) => setPrimaryPhone(e.target.value)}
                                                    name="phone"
                                                    value={primaryPhone}
                                                    error={!primaryPhone && validated}
                                                    id="formatted-phone-input"
                                                    InputProps={{
                                                        inputComponent: PhoneNumberInput,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    label="Support Phone"
                                                    variant="outlined"
                                                    className="w-100"
                                                    onChange={(e) => setSupportPhone(e.target.value)}
                                                    name="phone"
                                                    value={supportPhone}
                                                    id="formatted-phone-input"
                                                    InputProps={{
                                                        inputComponent: PhoneNumberInput,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    className=""
                                                    error={!businessName && validated}
                                                    value={businessName || ''}
                                                    variant="outlined"
                                                    label={'Business Name'}
                                                    onChange={(e) => setBusinessName(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    className=""
                                                    error={!mcNumber && validated}
                                                    value={mcNumber || ''}
                                                    variant="outlined"
                                                    label={'MC #'}
                                                    onChange={(e) => setMcNumber(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    error={!dotNumber && validated}
                                                    className=""
                                                    value={dotNumber || ''}
                                                    variant="outlined"
                                                    label={'DOT #'}
                                                    onChange={(e) => setDotNumber(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    className=""
                                                    error={!einNumber && validated}
                                                    value={einNumber || ''}
                                                    variant="outlined"
                                                    label={'EIN #'}
                                                    onChange={(e) => setEinNumber(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <FleetSizeInput 
                                                fleetSize={fleetSize}
                                                setFleetSize={setFleetSize}
                                            />
                                        </Col>
                                    </Row>
                                    <Col>
                                        <Form.Label>Billing Address</Form.Label>
                                    </Col>
                                    <Row className="d-flex">
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    error={!billingAddress && validated}
                                                    fullWidth
                                                    className=""
                                                    value={billingAddress}
                                                    variant="outlined"
                                                    label={'Street Address'}
                                                    onChange={(e) => setBillingAddress(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex">
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <Form.Group className="my-2 w-100">
                                                    <TextField
                                                        required
                                                        error={!billingCity && validated}
                                                        fullWidth
                                                        className=""
                                                        value={billingCity}
                                                        variant="outlined"
                                                        label={'City'}
                                                        onChange={(e) => setBillingCity(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <FormControl className="w-100 my-2" variant="outlined">
                                                <InputLabel>State</InputLabel>
                                                <Select
                                                    error={!billingState && validated}
                                                    required
                                                    native
                                                    value={billingState}
                                                    label="State"
                                                    onChange={(e) => setBillingState(e.target.value)}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {states.map((state) => (
                                                        <option key={state.abbreviation} value={state.abbreviation}>
                                                            {state.abbreviation}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    type="number"
                                                    required
                                                    error={!billingZip && validated}
                                                    fullWidth
                                                    className=""
                                                    value={billingZip}
                                                    variant="outlined"
                                                    label={'Zip Code'}
                                                    onChange={(e) => setBillingZip(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Button variant="success" type="submit" form="accountDetails" className="my-2 ml-3">
                                Save
                            </Button>
                            {updateMessage && <div>{updateMessage}</div>}
                        </Form>
                    </ThemeProvider>
                </Container>
            </Card.Body>
            <AdminEmailModal show={emailModal} onHide={() => setEmailModal(false)} adminLoggedIn={adminLoggedIn} />
            <MainAccountEmailModal
                show={mainAccountEmailModal}
                onHide={() => setMainAccountEmailModal(false)}
                adminLoggedIn={adminLoggedIn}
            />
        </Card>
    );
}
