import React from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { Body1, Header1 } from '../OrderDetailsPage/Crossdocking/blocks';
import { colors } from '@/styles';
import { Cancel, ImageOutlined, PhotoCameraOutlined } from '@material-ui/icons';

const FileUpload = ({ name, required, files = [], callbacks, cloudFiles = [] }) => {
    const inputId = `file-upload-${name}`;

    const filesOnChange = (uploadedFiles) => {
        const newFiles = [...files];
        for (const file of uploadedFiles) {
            if (!newFiles.find((existingFile) => existingFile.name === file.name)) {
                newFiles.push({
                    name: file.name,
                    type: file.type,
                    src: URL.createObjectURL(file),
                });
            }
        }
        callbacks.setFiles(newFiles);
    };

    const removeFile = (removedFile) => {
        callbacks.setFiles(files.filter((file) => file.src !== removedFile.src));
    };

    const removeCloudFile = (removedFile) => {
        callbacks.setCloudFiles(cloudFiles.filter((file) => file !== removedFile));
    };

    return (
        <>
            <Grid
                container
                css={css`
                    margin-top: 12px;
                    margin-bottom: 4px;
                `}
            >
                <Header1>{name}</Header1>
                {required ? (
                    <Body1
                        css={css`
                            font-style: italic;
                            color: ${colors.greys[0]};
                        `}
                    >
                        &nbsp;(required)
                    </Body1>
                ) : null}
            </Grid>
            {files.length > 0 || cloudFiles.length > 0 ? (
                <Grid
                    container
                    direction="row"
                    spacing={1}
                    css={css`
                        flex-wrap: wrap;
                    `}
                >
                    {cloudFiles.map((file) => (
                        <Grid
                            item
                            xs={4}
                            css={css`
                                position: relative;
                            `}
                        >
                            <img
                                src={file}
                                css={css`
                                    width: 100%;
                                    aspect-ratio: 1;
                                    object-fit: cover;
                                    border: 1px solid ${colors.greys[0]};
                                    border-radius: 5px;
                                `}
                            />
                            <Cancel
                                css={css`
                                    color: ${colors.greys[0]};
                                    position: absolute;
                                    top: 5px;
                                    right: 5px;
                                `}
                                onClick={() => removeCloudFile(file)}
                            />
                        </Grid>
                    ))}
                    {files.map((file) => (
                        <Grid
                            item
                            xs={4}
                            css={css`
                                position: relative;
                            `}
                        >
                            <img
                                src={file.src}
                                css={css`
                                    width: 100%;
                                    aspect-ratio: 1;
                                    object-fit: cover;
                                    border: 1px solid ${colors.greys[0]};
                                    border-radius: 5px;
                                `}
                            />
                            <Cancel
                                css={css`
                                    color: ${colors.greys[0]};
                                    position: absolute;
                                    top: 5px;
                                    right: 5px;
                                `}
                                onClick={() => removeFile(file)}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={4}>
                        <label
                            htmlFor={inputId}
                            css={css`
                                width: 100%;
                                aspect-ratio: 1;
                            `}
                        >
                            <input
                                id={inputId}
                                type="file"
                                hidden
                                multiple
                                accept="image/png,image/jpg,image/jpeg,application/pdf"
                                onChange={(e) => filesOnChange(e.target.files)}
                            />
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    border: 1px solid ${colors.greys[0]};
                                    border-radius: 5px;
                                    padding: 8px;
                                    height: 100%;
                                    flex-wrap: nowrap;
                                `}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    css={css`
                                        display: flex;
                                        border-bottom: 1px solid ${colors.greys[0]};
                                        width: 100%;
                                        max-width: 100%;
                                    `}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            align-items: center;
                                            justify-content: center;
                                        `}
                                    >
                                        <PhotoCameraOutlined
                                            css={css`
                                                color: ${colors.greys[0]};
                                            `}
                                        />
                                        <Body1>Camera</Body1>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    css={css`
                                        display: flex;
                                        width: 100%;
                                        max-width: 100%;
                                    `}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            align-items: center;
                                            justify-content: center;
                                        `}
                                    >
                                        <ImageOutlined
                                            css={css`
                                                color: ${colors.greys[0]};
                                            `}
                                        />
                                        <Body1>Camera Roll</Body1>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </label>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <label
                        htmlFor={inputId}
                        css={css`
                            width: 100%;
                            aspect-ratio: 3;
                        `}
                    >
                        <input
                            id={inputId}
                            type="file"
                            hidden
                            multiple
                            accept="image/png,image/jpg,image/jpeg,application/pdf"
                            onChange={(e) => filesOnChange(e.target.files)}
                        />
                        <Grid
                            container
                            css={css`
                                border: 1px solid ${colors.greys[0]};
                                border-radius: 5px;
                                padding: 8px;
                                height: 100%;
                            `}
                        >
                            <Grid
                                item
                                xs={6}
                                css={css`
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-right: 1px solid ${colors.greys[0]};
                                `}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        width: auto;
                                        align-items: center;
                                    `}
                                >
                                    <PhotoCameraOutlined
                                        css={css`
                                            color: ${colors.greys[0]};
                                        `}
                                        fontSize="large"
                                    />
                                    <Body1>Camera</Body1>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                css={css`
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        width: auto;
                                        align-items: center;
                                    `}
                                >
                                    <ImageOutlined
                                        css={css`
                                            color: ${colors.greys[0]};
                                        `}
                                        fontSize="large"
                                    />
                                    <Body1>Camera Roll</Body1>
                                </Grid>
                            </Grid>
                        </Grid>
                    </label>
                </>
            )}
        </>
    );
};

export default FileUpload;
