import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';

import { BodyLabel1, BodyDescription1 } from '../blocks';
import PricingInfo from '@/components/CustomerTrack/PricingInfo';

const dollar = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const miles = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'mile',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
});

const HR = styled.div`
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    border: 1px solid rgba(76, 76, 76, 0.5);
`;

const OrderSummary = ({ state, callbacks }) => {
    const { city } = useSwappedAttributes(state.order);
    const { city: pu_city } = useSwappedAttributes(state.order, true);

    return (
        <>
            {state.order?.shipper_rate ? (
                <PricingInfo order={state.order} />
            ) : (
                <Grid
                    item
                    container
                    css={css`
                        justify-content: space-between;
                    `}
                >
                    <Grid item direction="column">
                        <BodyDescription1>Price</BodyDescription1>
                    </Grid>
                    <Grid item direction="column">
                        <BodyLabel1>
                            {state.order?.shipper_rate ? dollar.format(state.order?.shipper_rate) : 'Calculating...'}
                        </BodyLabel1>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default OrderSummary;
