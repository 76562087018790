import { useClientUser } from '@/hooks';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { get } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { SERVICE_CLIENTS } from './graphql';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PageTitle, PrimaryButton } from '@/styles/blocks';
import { Skeleton } from '@material-ui/lab';
import { QUERY_BY_ID } from '@/graphql/queries/users';
import { UserContext } from '../App';
import { useNavigate } from 'react-router';
import * as ROUTES from '../../constants/routes';
import SearchInput from '../misc/SearchInput';
import { captureException } from '@sentry/react';
import { colors } from '@/styles';

const SEARCHABLE = ['user.email', 'user.username', 'business_name', 'user.user_id'];
const COLUMNS = [
    {
        label: 'Username',
        value: (client) => client?.user?.username || '--',
    },
    {
        label: 'Email',
        value: (client) => client?.user?.email || '--',
    },
    {
        label: 'Business Name',
        value: (client) => client?.business_name || '--',
    },
];

const HeaderCell = styled.span`
    font-size: 16px;
    font-weight: 600;
`;

const CorporateUsers = () => {
    const { user_id } = useClientUser();
    const [search, setSearch] = useState('');
    const { user: authUser, setSuperUser } = useContext(UserContext);
    const navigate = useNavigate();

    const { data, loading } = useQuery(SERVICE_CLIENTS, { variables: { user_id } });
    const clients = useMemo(() => {
        return (data?.corporate_admins_by_pk?.service?.service_clients || [])
            .map((service_client) => service_client.client)
            .filter((client) => {
                if (search) {
                    return SEARCHABLE.some((field) => {
                        const value = get(client, field);
                        return value && value.toString().includes(search);
                    });
                }
                return true;
            });
    }, [data, search]);

    const [getClientUser] = useLazyQuery(QUERY_BY_ID);

    const signInAsUser = (client) => {
        getClientUser({
            variables: { user_id: client.client_id },
            onCompleted: ({ user }) => {
                let userObj = {
                    ...user,
                    authUserRoles: authUser.roles,
                    adminLoggedIn: true,
                    originalAdminEmail: authUser.email,
                    originalAdminName: authUser.username,
                    isTestAccount: user.roles.TEAMMATE ? user.teammate.test_acc : user.client.test_acc,
                };

                window?.sessionStorage?.setItem('admin_user_object', JSON.stringify(userObj));

                setSuperUser(userObj);

                if (user.roles?.CARRIER) {
                    navigate(ROUTES.CARRIER_ACCOUNT);
                } else if (user.roles?.SHIPPER) {
                    navigate(ROUTES.SHIPPER_ACCOUNT);
                } else if (user.roles?.TEAMMATE) {
                    navigate(ROUTES.TEAMMATE_ACCOUNT);
                }

                alert(
                    `You will now see the same side bar and navigate around as if you were logged in as ${user.username}. To return to your admin account, click 'Back to Admin' at the bottom of the navbar.`
                );
            },
            onError: (err) => {
                console.error(err);
                captureException(err);
            },
        });
    };

    return (
        <Grid
            container
            css={css`
                padding: 5rem;
                flex-direction: column;
                flex-wrap: nowrap;
                height: 100%;
            `}
        >
            <Grid
                item
                css={css`
                    justify-content: space-between;
                    display: flex;
                `}
            >
                <PageTitle>Organization Users</PageTitle>
                <SearchInput onChange={(value) => setSearch(value)} placeholder="Search Users" />
            </Grid>

            <Grid
                item
                css={css`
                    display: flex;
                    flex-grow: 1;
                    overflow-y: hidden;
                `}
            >
                <Paper
                    css={css`
                        width: 100%;
                        height: 100% !important;
                        overflow-y: scroll;
                        border: none;
                        border-radius: 0;
                    `}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {COLUMNS.map((column) => (
                                    <TableCell key={column.label}>
                                        <HeaderCell>{column.label}</HeaderCell>
                                    </TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading
                                ? [...Array(5)].map((...[, i]) => (
                                      <TableRow key={`row-${i}`}>
                                          <TableCell colSpan={COLUMNS.length + 1}>
                                              <Skeleton variant="text" />
                                          </TableCell>
                                      </TableRow>
                                  ))
                                : clients.map((client) => (
                                      <TableRow
                                          key={client.client_id}
                                          css={css`
                                              :hover {
                                                  background-color: ${colors.greys.selected};
                                              }
                                          `}
                                      >
                                          {COLUMNS.map((column) => (
                                              <TableCell key={`${client.client_id}-${column.label}`}>
                                                  {column.value(client)}
                                              </TableCell>
                                          ))}
                                          <TableCell align="center">
                                              <PrimaryButton onClick={() => signInAsUser(client)}>
                                                  Sign&nbsp;In
                                              </PrimaryButton>
                                          </TableCell>
                                      </TableRow>
                                  ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CorporateUsers;
