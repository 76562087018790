import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { EXCEPTION_DISPLAY } from '@onward-delivery/core';
import { useFormatters } from './hooks';
import { HR, Header1, Body1, Tag, Step } from './blocks';

export default function Attempt({ isDesktop, before, order, after, callbacks }) {
    const formatters = useFormatters(order);
    const exception = order.exceptions.find((mapping) => mapping.event_id === after.event_id);
    return (
        <Step
            isDesktop={isDesktop}
            isComplete={false}
            inProgress={false}
            isWarning={true}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Attempted - Unable to Deliver</Header1>
                        </Grid>
                        <Grid
                            direction="column"
                            css={css`
                                margint-right: 8px;
                            `}
                        >
                            <Tag>
                                <Body1
                                    css={css`
                                        margin-right: 20px;
                                    `}
                                >
                                    {EXCEPTION_DISPLAY[exception?.type]}
                                </Body1>
                                <Body1
                                    css={css`
                                        margin-right: 20px;
                                    `}
                                >{`Reported at ${exception?.reported_at === 'DROPOFF' ? 'Dropoff' : 'Pickup'}`}</Body1>
                                <Body1>
                                    {exception ? formatters.dateShort.format(new Date(exception?.created_at)) : '--'}
                                </Body1>
                            </Tag>
                        </Grid>
                    </Grid>
                );
            }}
        ></Step>
    );
}
