import { gql } from '@apollo/client';

export const NOTIFICATION_FIELDS = gql`
    fragment NotificationFields on notifications {
        notification_id
        notification_data
        notification_status
        created_at
        notification_type
        user_id
        last_modified_at
    }
`;
