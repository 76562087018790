import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { OnwardLink, SecondaryButton } from '@/styles/blocks';
import { Grid } from '@material-ui/core';
import { ResponsiveSidebarDialog, StickyModalActions, ModalContent, PrimaryButton } from '@/styles/blocks';
import { genAttributes } from '@onward-delivery/core';
import { Body1, DateTimePicker } from '../Crossdocking/blocks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { Alert } from '@material-ui/lab';

const ReceiveModal = ({ event, order, callbacks }) => {
    const [updates, setUpdates] = useState({});

    const updated = useMemo(() => {
        return {
            ...(event || {}),
            received_date: event?.received_date || event?.est_received_date || new Date().toISOString(),
            ...updates,
        };
    }, [updates, event]);

    const tz = useMemo(() => {
        const { zip } = genAttributes(order);
        return zipcode_to_timezone.lookup(order[zip]) || 'America/New_York';
    }, [order]);

    const submit = () => {
        callbacks.onSubmit({
            event: {
                event_id: event.event_id,
                order_id: event.order_id,
                action: event.action,
                received_date: updated.received_date,
                status: updated.received_date ? 'RECEIVED' : 'NOT_DELIVERED',
            },
        });
    };

    return (
        <ResponsiveSidebarDialog open={!!event} onClose={callbacks.onClose}>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Body1>Are you sure you want to mark the warehouse status as 'Received'?</Body1>
                </Grid>
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Body1>This will let the shipper know the order has been received into your warehouse</Body1>
                </Grid>
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <DateTimePicker
                        dateLabel="Received Date"
                        tz={tz}
                        timestamp={updated.received_date}
                        onChange={(date) =>
                            setUpdates((prev) => ({ ...prev, received_date: date ? date.toISOString() : null }))
                        }
                    />
                </Grid>
                <Alert severity="warning">
                    <Body1>
                        {'Please report any overages, shortages, or damages to '}
                        <OnwardLink href={'mailto:osd@onwarddelivery.com'}>osd@onwarddelivery.com</OnwardLink>
                    </Body1>
                </Alert>
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton onClick={submit}>Yes</PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default ReceiveModal;
