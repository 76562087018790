import styled from '@emotion/styled';
import { typography, colors } from '@/styles';
import { Button, Grid } from '@material-ui/core';

export const local = {
    greys: ['#8D8D8D', '#4C4C4C', '#2b2b2b'],
};

export const PrimaryButton = styled((props) => <Button {...props} />)`
    background: ${colors.greens.primary};
    color: ${colors.white.primary};
`;

export const Container = styled((props) => <Grid container {...props} />)`
    padding-top: 64px;
    height: 100%;
    margin: auto;
    max-width: 360px;

    @media only screen and (min-width: 600px) {
        max-width: 600px;
    }
`;

export const Card = styled((props) => <Grid item {...props} />)`
    width: 100%;
    background: ${colors.white.primary};
    padding: 20px;
    border-radius: 4px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    color: ${local.greys[2]};

    font-weight: 500;
    font-size: 14px;
`;

export const Header1 = styled.span`
    ${typography.sansSerif}

    color: ${local.greys[2]};

    font-weight: 900;
    font-size: 16px;
`;
