import React, { useEffect, useState } from 'react';

/**
 * See https://developers.google.com/maps/documentation/javascript/reference/directions?hl=en#DirectionsRenderer
 * for reference on what options are available to the DirectionsRenderer class
 *
 */
export default function DirectionsRenderer(options) {
    const [renderer, setRenderer] = useState();

    useEffect(() => {
        if (!renderer) {
            setRenderer(
                new window.google.maps.DirectionsRenderer({
                    preserveViewport: true,
                })
            );
        }

        return () => {
            if (renderer) {
                renderer.setMap(null);
            }
        };
    }, [renderer]);

    useEffect(() => {
        if (renderer && options.directions) {
            renderer.setOptions(options);
        }
    }, [renderer, options]);

    return null;
}
