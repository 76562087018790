import QRCode from 'qrcode';
import printJS from 'print-js';
import { captureException } from '@sentry/react';

// Styles properties defined here must also be stated in 'targetStyle' in the printJS function below or else they will be ignored.

const getBinLabelBody = (bin, qrSvg) => `
    <div class="label-border" 
        style="margin: 0; 
        padding: 0;
        display: flex;
        flex-direction: column;
        // height: 85vh;
        height: 100%;
        justify-content: space-between;
    ">
        <div class="header" style="
            margin: 0;
            overflow: auto;
        ">
            <h1 style="
                width: 100%; 
                text-align: center;
                margin-bottom: 2rem;
            ">ONWARD</h1>
        </div>
        <div style="
            width: 100%;
            text-align: center;
            height: 100%;
            overflow: auto;
        ">
            ${qrSvg}
        </div>
        <div style="
            width: 100%;
            text-align: center;
            overflow: auto;
            text-wrap: balance;
            font-size: 24px;
        ">
            <h1 class="less-margin">${bin.bin_id}</h1>
        </div>
        <div class="border-line"></div>
    </div>`;

const getLabelHtml = (binLabelBody) => `
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
            </style>
          </head>
          <body>
            ${binLabelBody
                .map((label, i) =>
                    i !== binLabelBody.length - 1
                        ? `<div class="page" style="
                    height: 100%;
                ">${label}</div>`
                        : label
                )
                .join('')}
          </body>
        </html>
        `;

const getQR = (bin) => {
    let svgString;
    const qrValues = JSON.stringify({
        labelType: 'bin',
        value: bin.bin_id,
        warehouse_id: bin.warehouse_id,
        rack: bin.rack,
        width: bin.width,
        height: bin.height,
        depth: bin.depth,
        isPool: !!bin.isPool,
    });
    QRCode.toString(qrValues, { type: 'svg', width: 600 }, function (err, string) {
        if (err) throw err;
        svgString = string;
    });
    return svgString;
};

export const printBinLabels = async (racks, location) => {
    let bins = [];

    racks.forEach((data) => {
        const binLabel = data?.customName || `${data?.isPool ? 'Pool' : 'Rack'} ${data.rackIdentifier}`;
        if (data?.isPool) {
            bins.push({
                bin_id: binLabel,
                warehouse_id: location.location_id,
                rack: data.rackIdentifier,
                width: 1,
                height: 1,
                depth: 1,
                isPool: true,
            });
        } else {
            for (let r = 1; r <= data.rows; r++) {
                for (let c = 1; c <= data.columns; c++) {
                    for (let d = 1; d <= data.rackDepth; d++) {
                        const labelId = `${binLabel}-${r}-${c}-${d}`;
                        bins.push({
                            bin_id: labelId,
                            warehouse_id: location.location_id,
                            rack: data.rackIdentifier,
                            width: r,
                            height: c,
                            depth: d,
                            isPool: false,
                        });
                    }
                }
            }
        }
    });

    let qrCodes = [];
    bins.forEach((bin) => {
        qrCodes.push(getQR(bin));
    });

    let palletLabelBody = bins.map((bin, i) => getBinLabelBody(bin, qrCodes[i]));

    const htmlString = getLabelHtml(palletLabelBody);

    try {
        const html = new DOMParser().parseFromString(htmlString, 'text/html');
        let toPrint = html.children[0];
        const head = toPrint.children[0];
        const body = toPrint.children[1];
        const svgHolder = document.createElement('div');
        svgHolder.setAttribute('id', 'svg-holder');
        // svgHolder.setAttribute("style", "overflow: hidden; height:0px;");
        for (let i = 0; i < head.children.length; i++) {
            document.head.appendChild(head.children[i]);
        }
        for (let bodyNode of body.children) {
            svgHolder.appendChild(bodyNode.cloneNode(true));
        }

        document.body.appendChild(svgHolder);

        printJS({
            printable: 'svg-holder',
            type: 'html',
            style: `
                .header { 
                    text-align: center;
                    width: 100%;
                }
                .info {
                    text-align: center;
                }
                .page {
                    page-break-after: always;
                    page-break-inside: avoid;
                    page-break-before: avoid;
                    break-after: always;
                    break-inside: avoid;
                    break-before: avoid;
                    height: 100%;
                }
                svg {
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    object-fit: scale-down;
                }
                body, div, h1, h2, h4, p {
                    font-family: 'Montserrat', sans-serif;
                }
            `,
            targetStyle: [
                'width',
                'height',
                'text-align',
                'font-size',
                'font-family',
                'display',
                'flex-direction',
                'justify-content',
                'object-fit',
                'margin-bottom',
                'overflow',
                'display',
                'flex-shrink',
            ],
            scanStyles: false,
            targetStyles: ['break', 'flex', '*'],
            font_size: '',
            onPrintDialogClose: () => {
                svgHolder.remove();
            },
        });
    } catch (e) {
        console.error(e);
        captureException(e);
    }
};
