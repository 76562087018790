import React, { useState, useMemo, useContext } from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { colors } from '@/styles';

import { local, Header1, Body1 } from './blocks';
import { Context, ContextProvider } from './store';
import { useFormatters } from './hooks';
import Day from './Day';

function Calendar({ intervals, tz }) {
    const { callbacks, state, loading } = useContext(Context);
    const formatters = useFormatters(tz);

    return (
        <Grid
            container
            direction="column"
            css={css`
                box-shadow: 0px 1px 3px 0px #3f3f4426;
                box-shadow: 0px 0px 0px 1px #3f3f440d;
                border-radius: 5px;
                background: ${colors.white.primary};
                margin-bottom: 44px;
            `}
        >
            <Grid
                container
                direction="row"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        padding: 20px 0;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <Header1
                        css={css`
                            color: ${local.greys[0]};
                        `}
                    >
                        {formatters.monthFull.format(state.baseDate)}
                    </Header1>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                        flex-basis: 0;
                        justify-content: center;
                    `}
                >
                    <Grid
                        direction="row"
                        container
                        css={css`
                            flex-wrap: nowrap;
                        `}
                    >
                        <Grid
                            direction="column"
                            container
                            item
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <IconButton
                                css={css`
                                    padding: 8px;
                                `}
                                onClick={callbacks.prevMonth}
                            >
                                <KeyboardArrowLeftIcon
                                    css={css`
                                        color: black;
                                    `}
                                />
                            </IconButton>
                        </Grid>
                        <Grid
                            direction="column"
                            container
                            item
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <IconButton
                                css={css`
                                    padding: 8px;
                                `}
                                onClick={callbacks.nextMonth}
                            >
                                <KeyboardArrowRightIcon
                                    css={css`
                                        color: black;
                                    `}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    border-top: 1px solid ${colors.greys[4]};
                    border-bottom: 1px solid ${colors.greys[4]};
                `}
            >
                {intervals[0].map((interval, idx) => (
                    <Grid
                        key={`dow-${interval.start}`}
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            align-items: center;
                            padding: 12px 0;
                            border-right: ${idx === intervals[0].length - 1 ? 0 : 1}px solid ${colors.greys[4]};
                        `}
                    >
                        <Body1
                            css={css`
                                color: ${local.greys[0]};
                                text-transform: uppercase;
                            `}
                        >
                            {formatters.dowShort.format(new Date(interval.start))}
                        </Body1>
                    </Grid>
                ))}
            </Grid>
            {intervals.map((week, weekIdx) => (
                <Grid
                    key={`week-${weekIdx}`}
                    container
                    direction="row"
                    css={css`
                        flex-grow: 1;
                        flex-basis: 0;
                        border-bottom: ${weekIdx === intervals[weekIdx].length - 1 ? 0 : 1}px solid ${colors.greys[4]};
                    `}
                >
                    {week.map((day, dayIdx) => {
                        const resources = Object.entries(state.dayToResource?.[weekIdx]?.[dayIdx] || {}).map(
                            ([id, entity]) => {
                                return [state.resourceById[id], entity];
                            }
                        );

                        return (
                            <Day
                                key={`day-${dayIdx}`}
                                day={day}
                                resources={resources}
                                callbacks={{
                                    editResource: callbacks.editResource,
                                }}
                            />
                        );
                    })}
                </Grid>
            ))}
        </Grid>
    );
}

export default Calendar;
