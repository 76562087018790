import React from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Grid } from '@material-ui/core';

import { Header2, Body2 } from '../../blocks';

import { UPDATE_TYPE_TITLES } from '../../constants';

const RadioLabel = ({ type }) => {
    return (
        <Grid direction="column">
            <Grid direction="row">
                <Body2
                    css={css`
                        color: ${colors.greys[3]};
                    `}
                >
                    {UPDATE_TYPE_TITLES[type].title}
                </Body2>
            </Grid>
            <Grid direction="row">
                <Body2
                    css={css`
                        color: ${colors.greys[6]};
                    `}
                >
                    {UPDATE_TYPE_TITLES[type].subtitle}
                </Body2>
            </Grid>
        </Grid>
    );
};

export default RadioLabel;
