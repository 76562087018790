import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { css } from '@emotion/react';

const RenamePallet = ({ pallet, loading, callbacks }) => {
    const [name, setName] = useState(pallet?.pallet_name || '');

    const onClose = () => {
        setName('');
        callbacks.renamePalletInit(null);
    };

    const submit = () => {
        callbacks.renamePallet([pallet], name).then(() => {
            onClose();
        });
    };

    return (
        <NavResponsiveModal open={!!pallet} onClose={onClose}>
            <ModalHeader title={'Rename Pallet'} />
            <ModalContent
                css={css`
                    width: 400px;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                        max-height: 400px;
                    `}
                >
                    <TextField
                        variant="outlined"
                        required
                        label={'Pallet Name'}
                        name="pallet_name"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Grid>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading.palletLoading} onClick={onClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading.palletLoading} onClick={submit}>
                    Done
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default RenamePallet;
