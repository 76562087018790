import React, { useCallback, useMemo } from 'react';
import { useClientUser } from '@/hooks';

export const useFormatters = (tz) => {
    const dowShort = useMemo(() => {
        return new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            timeZone: tz,
        });
    }, [tz]);

    const monthFull = useMemo(() => {
        return new Intl.DateTimeFormat('en-US', {
            month: 'long',
            timeZone: tz,
        });
    }, [tz]);

    return {
        dowShort,
        monthFull,
    };
};

export const useResourceCallbacks = (state, callbacks) => {
    const user = useClientUser();

    const upsertResources = useCallback(({ resource, rules, overrides, regions, shipping_partners }) => {
        return callbacks.upsertResources({
            variables: {
                resources: {
                    ...resource,
                    client_id: user?.user_id,
                    subregions: {
                        data:
                            (regions.add || []).map(({ subregion_id }) => ({
                                subregion_id,
                            })) || [],
                    },
                    overrides: {
                        data: overrides.add || [],
                    },
                    rules: {
                        data: rules.add || [],
                    },
                    shipping_partners: {
                        data: shipping_partners.add || [],
                        on_conflict: {
                            constraint: 'resource_shipping_partner_const',
                            update_columns: ['enabled'],
                        },
                    },
                },
                regions: regions.update || [],
                region_removals: (regions.remove || []).map(({ mapping_id }) => mapping_id),
                rules: rules.update || [],
                rule_removals: (rules.remove || []).map(({ mapping_id }) => mapping_id),
                overrides: overrides.update || [],
                override_removals: (overrides.remove || []).map(({ mapping_id }) => mapping_id),
                shipping_partners: shipping_partners?.update || [],
                globals: (shipping_partners?.globals || []).map((set) => ({
                    _set: {
                        enabled: set.enabled,
                    },
                    where: {
                        _and: [
                            {
                                resource_id: { _eq: set.resource_id },
                            },
                            {
                                partner_client_id: { _is_null: true },
                            },
                        ],
                    },
                })),
            },
        });
    }, []);

    const deleteResource = useCallback(({ resource }) => {
        return callbacks.deleteResource({
            variables: {
                resource: resource.resource_id,
            },
        });
    }, []);

    const deleteRule = useCallback(({ rules, overrides }) => {
        return callbacks.deleteRule({
            variables: {
                rules,
                overrides,
            },
        });
    }, []);

    const editResource = useCallback(([resource, rule, day]) => {
        callbacks.editResource({
            resource,
            rule,
            day,
        });
    }, []);

    return { upsertResources, editResource, deleteRule, deleteResource };
};
