import {
    PickupSVGs,
    DropoffSVGs,
} from '../map/Marker';

export default class MarkerStyleHolder {
    constructor() {
        this.svgStyles = [
            {
                pickupSvgFunction: PickupSVGs[0],
                dropoffSvgFunction: DropoffSVGs[0],
                stops: {},
                pickupOrders: {},
                activeCount: 0,
            },
            {
                pickupSvgFunction: PickupSVGs[1],
                dropoffSvgFunction: DropoffSVGs[1],
                stops: {},
                pickupOrders: {},
                activeCount: 0,
            },
            {
                pickupSvgFunction: PickupSVGs[2],
                dropoffSvgFunction: DropoffSVGs[2],
                stops: {},
                pickupOrders: {},
                activeCount: 0,
            },
            {
                pickupSvgFunction: PickupSVGs[3],
                dropoffSvgFunction: DropoffSVGs[3],
                stops: {},
                pickupOrders: {},
                activeCount: 0,
            }
        ]
    }

    addMarkerAndGetStyle(stop, stops) {
        const existingAssociatedStyle = this.svgStyles.find(style => stop.orders?.every(o => style.pickupOrders[o]));

        if (existingAssociatedStyle) {
            if (!existingAssociatedStyle.stops[stop.stop_id]) {
                existingAssociatedStyle.stops[stop.stop_id] = true;
                existingAssociatedStyle.activeCount++;
            }
            return existingAssociatedStyle.dropoffSvgFunction;

        } else {
            // const firstAvailPool = this.svgStyles.find(svgs => svgs.activeCount === 0);
            const firstAvailPool = this.svgStyles.find(svgs => Object.values(svgs.pickupOrders).length === 0);
            if (!firstAvailPool) return this.svgStyles[0].dropoffSvgFunction;  // default fallback

            firstAvailPool.stops[stop.stop_id] = true;
            firstAvailPool.activeCount++;

            const relatedPickupStop = stops.find(s => s.type === 'PICKUP' && !s.returns?.length && stop.orders?.every(o => s.orders?.includes(o)));
            firstAvailPool.pickupOrders = {
                ...relatedPickupStop.orders.reduce((obj, order) => ({ ...obj, [order]: true}), {})
            }

            return firstAvailPool.dropoffSvgFunction;
        }
    }

    turnMarkerStyleOff(stop) {
        const existingAssociatedStyle = this.svgStyles.find(style => style.stops[stop.stop_id]);
        if (existingAssociatedStyle) {
            existingAssociatedStyle.stops[stop.stop_id] = false;
            existingAssociatedStyle.activeCount--;

            if (existingAssociatedStyle.activeCount === 0) existingAssociatedStyle.pickupOrders = {};
        }
    }

    addAndGetPickupMarkerStyle(stop) {
        const existingAssociatedStyle = this.svgStyles.find(style => stop.orders.some(o => style.pickupOrders[o]));
        if (existingAssociatedStyle) return existingAssociatedStyle.pickupSvgFunction;

        const firstAvailPool = this.svgStyles.find(svgs => Object.values(svgs.pickupOrders).length === 0);
        if (!firstAvailPool) return this.svgStyles[0].pickupSvgFunction; // default fallback

        firstAvailPool.pickupOrders = {
            ...stop.orders.reduce((obj, order) => ({ ...obj, [order]: true}), {})
        }

        return firstAvailPool.pickupSvgFunction;
    }
}
