export const TABS = [
    {
        label: 'Unresolved',
        value: 'OPEN',
    },
    {
        label: 'Resolved',
        value: 'RESOLVED',
    },
];
