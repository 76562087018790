import styled from '@emotion/styled';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Card,
    FormControlLabel,
    Switch,
    IconButton,
    TextField,
    CircularProgress,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as typography from './typography';
import * as colors from './colors';
import { Pagination } from '@material-ui/lab';
import { css } from '@emotion/react';
import { useState } from 'react';

export const ResponsiveSidebarContainer = styled((props) => <Box {...props} />)`
    margin-left: 56px;

    @media only screen and (min-width: 1280px) {
        margin-left: ${(props) => (props.collapsed ? '56px' : '240px')};
    }

    padding: 0;
    height: calc(100vh - 50px);
    flex-wrap: nowrap;
    align-items: flex-start;
`;

export const ResponsiveSidebarDialog = styled((props) => <Dialog maxWidth="lg" {...props} />)`
    margin-left: 56px;
    @media only screen and (max-width: 600px) {
        margin-left: 0;
    }
    @media only screen and (min-width: 1280px) {
        margin-left: ${(props) => (props.collapsed ? '56px' : '240px')};
    }
    padding: 0;
    height: 100vh;
    flex-wrap: nowrap;
    align-items: center;
`;

export const MobileDialog = styled((props) => <Dialog {...props} />)`
    width: 100vw;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 0;

    .MuiDialog-container {
        width: 100%;
        align-items: flex-start;
    }

    .MuiDialog-paper {
        margin: 2px;
        border-radius: 10px;
        width: 100%;
    }

    @media only screen and (min-width: 600px) {
        .MuiDialog-paper {
            width: 600px;
        }
    }
`;

const BORDER_STYLE = '1px rgba(76, 76, 76, 0.5) solid';

export const ModalTitle = styled((props) => (
    <DialogTitle {...props}>
        <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={props.onClose ? 11 : 12}>
                {!!props.title ? <SectionTitle>{props.title}</SectionTitle> : props.children}
            </Grid>
            {props.onClose ? (
                <Grid className="icon-container" item xs={1}>
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={props.onClose} />
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    </DialogTitle>
))`
    border-bottom: ${(props) => (props.border ? BORDER_STYLE : '')};
    padding: ${(props) => (props.padding ? props.padding : '')} !important;

    & .icon-container {
        display: flex;
        justify-content: center;
    }
`;

export const ModalContent = styled((props) => <DialogContent {...props} />)`
    padding: 22px !important;
    width: ${(props) => props.width || 400}px;
`;

export const ModalBody = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
`;

export const ModalActions = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
`;

export const StickyModalActions = styled((props) => <DialogActions {...props} />)`
    border-top: ${(props) => (props.border ? BORDER_STYLE : '')};
    box-shadow: ${(props) => (props.shadow ? '0px -4px 4px rgba(0,0,0,0.25)' : '')};
    dislay: flex;
    justify-content: space-between;
    padding: 1rem;
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 20px;
`;

export const Body = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;
`;

export const GreyBody = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;

    color: ${colors.greys[2]};

    margin-bottom: 18px;
`;

export const PageContent = styled((props) => (
    <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10} {...props} />
        <Grid item xs={1} />
    </Grid>
))`
    margin-top: 40px;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
`;

export const PageTitle = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 28px;
    line-height: 1.25;

    margin-bottom: 34px;
`;

export const SectionTitle = styled.span`
    ${typography.sansSerif}

    font-weight: 900;
    font-size: 20px;
    line-height: 1.25;

    color: ${colors.greys[2]};

    margin-bottom: 18px;
`;

export const SectionSubtitle = styled.span`
    ${typography.sansSerif}

    font-weight: 600;
    font-size: 16px;
    line-height: 1.25;

    color: ${colors.greys[0]};

    margin-bottom: 10px;
`;

export const SectionSubtitle2 = styled((props) => <Body {...props} />)`
    font-weight: 800;
    font-size: 1rem;
`;

export const GreySubheader = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;

    color: ${colors.greys[2]};

    margin-bottom: 18px;
`;

export const OnwardGreenButton = styled((props) => <Button variant="contained" {...props} />)`
    font-size: 14px;
    font-family: Montserrat;
    color: white;
    background-color: ${colors.greens.primary};
`;

export const PrimaryButton = styled(({ loading, disabled, children, ...rest }) => {
    return (
        <Button variant="contained" disabled={loading || disabled} {...rest}>
            <Grid
                direction="column"
                container
                css={css`
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                {children}
            </Grid>
            {loading ? (
                <Grid
                    direction="column"
                    container
                    css={css`
                        flex: 0;
                        flex-basis: 0;

                        margin-left: 12px;
                    `}
                >
                    <CircularProgress
                        size={16}
                        css={css`
                            color: ${colors.greys[2]};
                        `}
                    />
                </Grid>
            ) : null}
        </Button>
    );
})`
    ${typography.sansSerif}

    font-size: 14px;
    font-weight: 700;
    line-height: 1.25;
    color: ${colors.white.primary};
    background-color: ${(props) =>
        props.red ? colors.reds.primary : props.orange ? colors.oranges.primary : colors.greens.primary};
    min-width: 140px;
    text-transform: none;

    &.MuiButton-contained:hover {
        background: ${(props) => (props.red ? colors.reds[1] : props.orange ? colors.oranges[2] : '#187522')};
    }
`;

export const SecondaryButton = styled((props) => <Button variant="outlined" {...props} />)`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 700;
    line-height: 1.25;
    color: ${colors.greys[3]};
    background-color: ${colors.white[1]};
    min-width: 140px;
`;

export const WarningButton = styled((props) => <Button variant="outlined" {...props} />)`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 700;
    line-height: 1.25;
    color: ${colors.white.primary};
    background-color: ${colors.reds[0]};
    min-width: 140px;

    &.MuiButton-root:hover {
        background: #9d0909;
    }
`;

export const ToggleButton = styled((props) => <Button variant="outlined" {...props} />)`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
    min-width: 100px;
    text-transform: none;

    ${(props) =>
        props.selected
            ? `
        color: ${colors.white.primary};
        background-color: ${colors.greens.primary};
    `
            : `
        color: black;
        background-color: rgba(0, 0, 0, 0);
    `}
`;

export const GrayBgCard = styled(({ fullWidth, validationError, validationWarning, ...props }) => (
    <Card variant="outlined" {...props} />
))`
    background-color: rgba(166, 166, 166, 0.3);
    borderradius: 5px;
    border: 0;
    padding: 5px;
    height: 100px;
    width: ${(props) => (props.fullWidth ? '100%' : '')};
    cursor: ${(props) => (props.pointer ? 'pointer' : '')};
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: ${(props) =>
        props.validationWarning
            ? 'solid 2px ' + colors.golds[2]
            : props.validationError
            ? 'solid 2px red'
            : 'solid 2px transparent'};
    &:hover {
        background-color: rgba(166, 166, 166, 0.6);
        border: solid 2px rgba(166, 166, 166, 1);
    }
`;

export const SpacerV1 = styled.div`
    height: 1rem;
`;

export const SpacerV2 = styled.div`
    height: 2rem;
`;

export const SpacerV3 = styled.div`
    height: 3rem;
`;

export const SpacerH1 = styled.div`
    width: 1rem;
`;

export const SpacerH2 = styled.div`
    width: 2rem;
`;

export const SpacerH3 = styled.div`
    width: 3rem;
`;

export const OnwardPagination = styled((props) => <Pagination variant="outlined" shape="rounded" {...props} />)`
    .Mui-selected {
        background-color: ${colors.greens.primary};
        color: white;
    }
`;

export const OnwardSwitch = ({ onChange, checked, ...props }) => {
    return (
        <FormControlLabel
            control={<Switch checked={Boolean(checked)} onChange={onChange} />}
            color="primary"
            css={css`
                margin-right: 0;
                .MuiSvgIcon-root {
                    color: ${checked ? colors.greens.primary : 'black'};
                }
                .Mui-checked {
                    color: ${colors.greens.primary};
                    & + .MuiSwitch-track {
                        background-color: ${colors.greens.primary};
                    }
                }
            `}
            {...props}
        />
    );
};

export const OnwardLink = ({ children, ...props }) => {
    return (
        <a
            {...props}
            css={css`
                font-size: 14px;
                font-weight: 600;
                color: ${colors.greens.primary};
                text-decoration: underline;
                cursor: pointer;
            `}
        >
            {children}
        </a>
    );
};

export const OnwardButtonIcon = ({ Icon, red, small, ...props }) => {
    return (
        <IconButton
            css={css`
                padding: ${small ? '3px' : '8px'};
                background-color: ${red ? colors.reds.primary : colors.greens.primary};
                border-radius: 5px;
                :hover {
                    background-color: ${red ? colors.reds.primary : colors.greens.primary};
                    filter: brightness(75%);
                }
            `}
            {...props}
        >
            <Icon
                css={css`
                    width: 24px;
                    height: 24px;
                    color: white;
                `}
            />
        </IconButton>
    );
};

export const OnwardTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '14px !important',
        backgroundColor: 'rgba(97, 97, 97, 1.0) !important',
    },
}))(Tooltip);
