import { gql } from '@apollo/client';
import { CARRIER_INVOICE_FIELDS } from '@/graphql/queries/carrier_invoices';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { ORDER_FIELDS } from '@/graphql/queries/orders';

export const CARRIER_INVOICE = gql`
    ${CARRIER_INVOICE_FIELDS}
    ${CLIENT_FIELDS}
    ${ORDER_FIELDS}

    query GetAllCarrierInvoices($invoice_id: uuid!) {
        result: carrier_invoices(
            where: { carrier_invoice_id: { _eq: $invoice_id } }
            order_by: { created_at: desc_nulls_last }
        ) {
            ...CarrierInvoiceFields

            client {
                ...ClientFields
            }

            orders {
                ...OrderFields

                shipper_invoice {
                    ...CarrierInvoiceFields
                }

                order_shipper {
                    ...ClientFields
                }

                order_carrier {
                    ...ClientFields
                }
            }

            shipper_orders {
                ...OrderFields

                order_shipper {
                    ...ClientFields
                }

                order_carrier {
                    ...ClientFields
                }
            }
        }
    }
`;

export const CREATE_INVOICES = gql`
    mutation CreateCarrierInvoices($invoices: [carrier_invoices_insert_input!]!) {
        created: insert_carrier_invoices(objects: $invoices) {
            affected_rows
            returning {
                carrier_invoice_id
            }
        }
    }
`;

export const UPDATE_COMMENTS = gql`
    mutation UpdateCarrierInvoiceComments($invoice_id: uuid!, $client_id: uuid!, $comment: jsonb!) {
        updated: update_carrier_invoices(
            where: { carrier_invoice_id: { _eq: $invoice_id }, client_id: { _eq: $client_id } }
            _append: { invoice_comments: $comment }
        ) {
            affected_rows
            returning {
                carrier_invoice_id
                invoice_comments
            }
        }
    }
`;

export const SET_INVOICE_STATUS = gql`
    ${CARRIER_INVOICE_FIELDS}
    mutation SetInvoiceStatus($ids: [uuid!]!, $status: String!) {
        update_carrier_invoices(where: { carrier_invoice_id: { _in: $ids } }, _set: { status: $status }) {
            returning {
                ...CarrierInvoiceFields
            }

            affected_rows
        }
    }
`;
