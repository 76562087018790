import { gql } from '@apollo/client';
import { NOTIFICATION_FIELDS } from '../queries/notifications';

export const NOTIFICATIONS_SUBSCRIPTION = gql`
    ${NOTIFICATION_FIELDS}
    subscription ($user_id: uuid!, $from_date: timestamptz) {
        notifications(
            where: { user_id: { _eq: $user_id }, created_at: { _gte: $from_date } }
            order_by: { created_at: desc_nulls_last }
        ) {
            ...NotificationFields
        }
    }
`;
