import React, { useContext } from 'react';
import { Context } from '../store';
import { CardHeaderLine, CardHeaderText, CardLine, CardText, ListCard } from './blocks';

const SupplyOrderCard = ({ order }) => {
    const { state, callbacks } = useContext(Context);

    const carrier = order.external_carrier_name || order.external_carrier_id;

    return (
        <ListCard
            clickable={true}
            selected={state.selectedOrder?.order_id === order.order_id}
            onClick={() => callbacks.selectOrder(order)}
        >
            <CardHeaderLine>
                <CardHeaderText>OX-{order.order_id.substring(0, 6)}</CardHeaderText>
            </CardHeaderLine>
            <CardLine>
                <CardText>Carrier: {carrier}</CardText>
            </CardLine>
            <CardLine>
                <CardText>External ID: {order.external_order_id}</CardText>
            </CardLine>
            <CardLine>
                <CardText>
                    {order.pickup_city} - {order.dropoff_city}
                </CardText>
            </CardLine>
        </ListCard>
    );
};

export default SupplyOrderCard;
