import React from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import * as ROUTES from '@/constants/routes';

import OrderSummary from './OrderSummary';
import { local, Header1, PrimaryButton, BodyDescription1 } from '../blocks';

function Confirmation({ isDesktop, callbacks, state }) {
    const navigate = useNavigate();

    return (
        <Grid
            container
            direction="column"
            css={css`
                color: ${local.greys[3]};
            `}
        >
            <Grid
                container
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <Header1
                    css={css`
                        color: ${local.greys[4]};
                    `}
                >
                    Thank you for using Onward!
                </Header1>
            </Grid>
            <Grid
                container
                css={css`
                    margin-bottom: 28px;
                `}
            >
                <BodyDescription1>We’ll be in touch within 24 hours to schedule your delivery date.</BodyDescription1>
            </Grid>
            <Grid
                container
                css={css`
                    background: #ececec;
                    padding: 20px 12px;
                    margin-bottom: 40px;
                `}
            >
                <OrderSummary callbacks={callbacks} state={state} />
            </Grid>

            <Grid
                container
                css={css`
                    margin-bottom: 24px;
                `}
            >
                <PrimaryButton
                    fullWidth
                    onClick={callbacks.reset}
                    css={css`
                        padding: 12px 24px;
                    `}
                >
                    Create Another Delivery
                </PrimaryButton>
            </Grid>

            <Grid container>
                <PrimaryButton
                    fullWidth
                    onClick={callbacks.reset}
                    css={css`
                        padding: 12px 24px;
                    `}
                >
                    Track an Order
                </PrimaryButton>
            </Grid>
        </Grid>
    );
}

export default Confirmation;
