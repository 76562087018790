import React, { useState, useContext, useMemo } from 'react';
import { useClientUser, useOrderShipperPricing } from '@/hooks';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
    createTheme,
    ThemeProvider,
    Grid,
    Box,
    Typography,
    Button,
    CircularProgress,
    Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '../Breadcrumbs';
import * as Sentry from '@sentry/react';
import { post } from '@/utilities/onwardClient';
import { CANCEL_LISTING } from '@/constants/apiRoutes';
import AuctionModal from '../ShipmentForm/modals/ListingModal';
import { PrimaryButton, SectionTitle, WarningButton } from '@/styles/blocks';
import useAction from '@/utilities/useQuery';
import { css } from '@emotion/react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { OnwardCard } from '../OrderDetailsPage/Card';
import { UserContext } from '../App';
import { useSubscription, useApolloClient } from '@apollo/client';
import { FULL_LISTING_SUBSCRIPTION } from './graphql';
import { addFormattedDateFields } from '@/utilities/addFormattedDateFields';
import _ from 'lodash';
import { formatInTimeZone } from 'date-fns-tz';
import { asDateInTZ, asBrowserDate } from '@/utilities/convertToISO';
import ShipperBidTable from './ShipperBidTable';
import PickUpCard from '../OrderDetailsPage/PickUpCard';
import DropOffCard from '../OrderDetailsPage/DropOffCard';
import OrderSummaryCard from '../OrderDetailsPage/OrderSummaryCard';
import OrderItems from './OrderItems';
import OtherBidsTable from './OtherBidsTable';
import { format, subDays } from 'date-fns';
import CancelBidModal from './modals/CancelBidModal';
import BackToUnassignedModal from '../OrderDetailsPage/modals/BackToUnassignedModal';
import { createListings } from '../ShipmentForm/queries/createListings';
import BidComments from './BidComments';
import CounterBids from './CounterBids';
import NotificationCard from './NotificationCard';
import MiddleMilePickupCard from './MiddleMilePickupCard';

function ListingDetailsPage() {
    const { cache } = useApolloClient();
    const { circles, payment_type, user_id } = useClientUser();
    const { listing_id } = useParams();
    const navigate = useNavigate();
    const { sidebarCollapsed } = useContext(UserContext);
    const [listing, setListing] = useState(null);
    const [order, setOrder] = useState(null);
    const [selectedBid, setSelectedBid] = useState(null);
    const [notification, setNotification] = useState(null);
    const [cancelBidModalOpen, setCancelBidModalOpen] = useState(false);
    const [backToUnassignedModalOpen, setBackToUnassignedModalOpen] = useState(false);
    const [openListingModal, setOpenListingModal] = useState(false);

    const { loading, error, data } = useSubscription(FULL_LISTING_SUBSCRIPTION, {
        variables: {
            listing_id: listing_id,
        },
        onData: ({ data }) => {
            const _listing = data?.data?.listings?.[0];
            if (!_listing) return;

            setListing(_listing);
            setOrder({ ..._listing.order, ...addFormattedDateFields(_listing.order) });
        },
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const competingBids = useMemo(() => {
        //only populate for carriers
        if (listing?.shipper_id !== user_id) {
            return listing?.bids?.filter((bid) => bid.carrier_id !== user_id) || [];
        } else return [];
    }, [listing, user_id]);

    const price = useOrderShipperPricing({ ...order, listing }, circles, payment_type, '--');

    const orderTz = useMemo(() => {
        if (order) {
            return order.dropoff_zip
                ? zipcode_to_timezone.lookup(order.dropoff_zip)
                : Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
    }, [order]);

    const selectedBidDetails = useMemo(() => {
        const carrierBid = listing?.bids?.find((bid) => bid.carrier_id === user_id);
        if (carrierBid) {
            const counterSequence = carrierBid.counter_offers
                ? carrierBid.counter_offers.sort((a, b) => {
                      return new Date(b.created_at) - new Date(a.created_at);
                  })
                : [];
            return {
                ...carrierBid,
                counterSequence: counterSequence,
                isCarrier: true,
                lastViewedCarrier: carrierBid.last_viewed_carrier,
            };
        } else if (selectedBid) {
            const _selectedBid = listing?.bids?.find((_bid) => _bid.bid_id === selectedBid);
            const counterSequence = _selectedBid.counter_offers
                ? _selectedBid.counter_offers.sort((a, b) => {
                      return new Date(b.created_at) - new Date(a.created_at);
                  })
                : [];
            return { ..._selectedBid, counterSequence: counterSequence, isCarrier: false };
        }
    }, [selectedBid, listing]);

    const [handleSendBackToUnassigned] = useAction(async (body) => post(CANCEL_LISTING, body), {
        onComplete: ({ data }) => {
            if (data?.listing?.listing_status === 'cancelled') {
                setNotification({
                    severity: 'success',
                    message: 'Order sent back to unassigned.',
                });
            } else {
                setNotification({
                    severity: 'error',
                    message: data?.error || 'Error cancelling order.',
                });
            }
            setBackToUnassignedModalOpen(false);
        },
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
            setNotification({
                severity: 'error',
                message: 'Error cancelling order. Please try again.',
            });
            setBackToUnassignedModalOpen(false);
        },
    });

    const [renewListing, { loading: renewLoading }] = useAction(createListings, {
        onComplete: ({ data }) => {
            if (data?.success?.length) {
                setNotification({
                    severity: 'success',
                    message: 'Listing Renewed.',
                });
            } else {
                setNotification({
                    severity: 'error',
                    message: data?.error || 'Error renewing listing.',
                });
            }
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'listings' });
            setOpenListingModal(false);
        },
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
            setNotification({
                severity: 'error',
                message: 'Error renewing listing. Please try again.',
            });
            setOpenListingModal(false);
        },
    });

    function getTypeLabel(type) {
        switch (type) {
            case 'all':
                return 'Auction/Claim Now';
            case 'auction':
                return 'Auction';
            case 'claim_now':
                return 'Claim Now';
            default:
                return 'Claim Now';
        }
    }

    const getColorByStatus = (status) => {
        switch (status) {
            case 'open':
                return '#007BFF';
            case 'pending':
                return '#FF903D';
            case 'claimed':
            case 'success':
                return '#59B863';
            case 'cancelled':
            case 'expired':
                return '#D23E3E';
            default:
                return 'black';
        }
    };

    function getListingStatusMessage(listing_status) {
        switch (listing_status) {
            case 'open':
                return 'Awaiting shipper to accept a bid';
            case 'pending':
                return 'Order is pending';
            case 'claimed':
                return 'Order has been claimed';
            case 'expired':
                return 'Order has expired';
            case 'cancelled':
                return 'Order has been cancelled';
            default:
                return 'Status unavailable';
        }
    }

    const bidStatus =
        selectedBidDetails?.bid_status === 'open' && listing?.listing_status === 'pending'
            ? 'Pre-bid'
            : selectedBidDetails?.bid_status;
    const isPrebid = bidStatus === 'Pre-bid';

    const theme = createTheme({
        typography: {
            fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
            h1: {
                fontWeight: 800,
                fontSize: '28px !important',
            },
            h3: {
                fontSize: 25,
                fontWeight: '800',
            },
            h4: {
                color: '#4c4c4c',
                fontWeight: '900',
                fontSize: 15,
            },
            h6: {
                color: '#8d8d8d',
                fontWeight: '800',
                fontSize: 18,
            },
            body1: {
                fontWeight: '650',
                fontSize: 16,
            },
            body2: {
                fontWeight: '500',
                fontSize: 16,
            },
            subtitle1: {
                color: '#8d8d8d',
                fontWeight: '500',
                fontSize: '10',
            },
            subtitle2: {
                color: `${getColorByStatus(selectedBidDetails?.isCarrier ? bidStatus : listing?.listing_status)}`,
                fontWeight: '700',
                fontSize: '10',
                border: '3px solid',
                borderRadius: '5px',
                paddingLeft: '4px',
                paddingRight: '4px',
                height: '36px',
                display: 'flex',
                alignItems: 'center',
            },
            button: {
                fontWeight: '550',
                textTransform: 'capitalize',
            },
        },
        palette: {
            text: {
                secondary: '#4c4c4c',
            },
            primary: {
                main: '#59b863',
                contrastText: '#fff',
            },
            secondary: {
                main: '#4c4c4c',
                contrastText: '#fff',
            },
        },
    });

    if (loading) {
        return (
            <ThemeProvider theme={theme}>
                <Box className={`${sidebarCollapsed && 'collapse-margin'} sidenav-margin-responsive p-0 vh-100`}>
                    <Grid
                        container
                        xs={12}
                        className="ms-5 me-5 bg-white"
                        justifyContent="center"
                        alignContent="center"
                        css={css`
                            height: 100vh;
                            width: 100vw;
                        `}
                    >
                        <CircularProgress size="200px" color="primary" />
                    </Grid>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box
                    className={`${
                        sidebarCollapsed && 'collapse-margin'
                    } sidenav-margin-responsive p-0 vh-100 align-items-center`}
                >
                    <Grid container>
                        <Grid
                            container
                            sm={12}
                            css={css`
                                padding: 0 40px;
                                background: #fff;
                                justify-content: space-between;
                                height: 70px;
                            `}
                        >
                            <Grid item sm={6} className="d-flex align-items-center">
                                <React.Fragment>
                                    <OnwardBreadcrumbInactive onClick={() => navigate(-1)}>
                                        Auctioned Orders
                                    </OnwardBreadcrumbInactive>
                                    <OnwardBreadcrumbSpacer />
                                    <OnwardBreadcrumbActive>Order {order.order_number}</OnwardBreadcrumbActive>
                                </React.Fragment>
                            </Grid>
                            <Grid item sm={6} className="d-flex align-items-center justify-content-end">
                                {selectedBidDetails?.isCarrier && selectedBidDetails.bid_status === 'open' ? (
                                    <Button
                                        onClick={() => setCancelBidModalOpen(true)}
                                        variant="contained"
                                        color="success"
                                        style={{
                                            minWidth: '110px',
                                            backgroundColor: '#d23e3f',
                                            color: 'white',
                                        }}
                                    >
                                        <Typography variant="body1">Cancel Bid</Typography>
                                    </Button>
                                ) : (
                                    <>
                                        {order.shipper_id === user_id &&
                                            (order.order_status === 'open' || order.order_status === 'pending') &&
                                            (listing?.listing_type === 'all' || listing?.listing_type === 'auction') &&
                                            !order.oms && (
                                                <PrimaryButton
                                                    onClick={() => setOpenListingModal(true)}
                                                    className="mx-3"
                                                >
                                                    Renew Auction
                                                </PrimaryButton>
                                            )}
                                        {order.shipper_id === user_id &&
                                            (order.order_status === 'open' || order.order_status === 'pending') &&
                                            !order.oms &&
                                            !order.routes?.length &&
                                            !order.is_middle_mile && (
                                                <WarningButton onClick={() => setBackToUnassignedModalOpen(true)}>
                                                    Cancel Order
                                                </WarningButton>
                                            )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container className="h-100">
                            <Grid item sm={1} />
                            <Grid container sm={10}>
                                <Grid item sm={12} className="d-flex justify-content-between align-items-center mt-4">
                                    <Typography variant="h1">{order.order_number}</Typography>
                                    {((listing && listing.listing_status) || selectedBidDetails) && (
                                        <Typography variant="subtitle2">
                                            {_.startCase(
                                                selectedBidDetails?.isCarrier ? bidStatus : listing?.listing_status
                                            )}
                                        </Typography>
                                    )}
                                </Grid>
                                <NotificationCard
                                    listing={listing}
                                    order={{ ...order, listing: { ...listing } }}
                                    selectedBidDetails={selectedBidDetails}
                                />
                                <OnwardCard style={{ marginTop: '40px' }}>
                                    {selectedBidDetails?.isCarrier ? (
                                        <>
                                            <Grid item sm={12}>
                                                <b>
                                                    {' '}
                                                    Listing Status: {getListingStatusMessage(listing?.listing_status)}
                                                </b>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item md={6}>
                                                    {(listing?.listing_type === 'claim_now' ||
                                                        listing?.listing_type === 'all') && (
                                                        <Typography className="my-2" variant="body2">
                                                            Shipper Claim Now Price
                                                        </Typography>
                                                    )}
                                                    <Typography className="my-2" variant="body2">
                                                        Bids
                                                    </Typography>
                                                    <Typography className="my-2" variant="body2">
                                                        Proposed Delivery Date
                                                    </Typography>
                                                    <Typography className="my-2" variant="body2">
                                                        Your Expiration
                                                    </Typography>
                                                    <Typography className="my-2" variant="body2">
                                                        Shipper Expiration
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    className="d-flex flex-column justify-content-end align-items-end"
                                                >
                                                    {(listing?.listing_type === 'claim_now' ||
                                                        listing?.listing_type === 'all') && (
                                                        <Typography variant="h6" color="secondary">
                                                            {((order?.admin_carrier_rate_override || order?.carrier_rate) &&
                                                                `$${(order?.admin_carrier_rate_override || order?.carrier_rate)?.toFixed(2)}`) ||
                                                                'NA'}
                                                        </Typography>
                                                    )}
                                                    <Typography className="my-1" variant="body1">
                                                        {listing?.bids?.length || 'NA'}
                                                    </Typography>
                                                    <Typography className="my-1" variant="body1">
                                                        {selectedBidDetails.target_delivery_date
                                                            ? format(
                                                                  new Date(selectedBidDetails.target_delivery_date),
                                                                  'EEE, MMM d, yyyy'
                                                              )
                                                            : 'N/A'}
                                                    </Typography>
                                                    <Typography className="my-1" variant="body1">
                                                        {selectedBidDetails?.expiration_date
                                                            ? format(
                                                                  asBrowserDate(
                                                                      subDays(
                                                                          new Date(selectedBidDetails.expiration_date),
                                                                          1
                                                                      ).toISOString()
                                                                  ),
                                                                  'EEE, MMM d, yyyy'
                                                              )
                                                            : 'N/A'}
                                                    </Typography>
                                                    <Typography className="my-1" variant="body1">
                                                        {listing?.expiration_date
                                                            ? format(
                                                                  asBrowserDate(
                                                                      subDays(
                                                                          new Date(listing.expiration_date),
                                                                          1
                                                                      ).toISOString()
                                                                  ),
                                                                  'EEE, MMM d, yyyy'
                                                              )
                                                            : 'N/A'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid container spacing={1}>
                                            <Grid item md={6}>
                                                <Typography className="my-2" variant="body2">
                                                    Type
                                                </Typography>
                                                <Typography className="my-2" variant="body2">
                                                    Starting Price
                                                </Typography>
                                                <Typography className="my-2" variant="body2">
                                                    Expiration Date
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                className="d-flex flex-column align-items-end justify-content-end"
                                            >
                                                <Typography className="my-1" variant="body1">
                                                    {getTypeLabel(listing?.listing_type)}
                                                </Typography>
                                                <Typography className="my-1" variant="body1">
                                                    {price}
                                                </Typography>
                                                <Typography className="my-1" variant="body1">
                                                    {formatInTimeZone(
                                                        asDateInTZ(
                                                            subDays(
                                                                new Date(listing?.expiration_date),
                                                                1
                                                            ).toISOString(),
                                                            orderTz
                                                        ),
                                                        orderTz,
                                                        'EEE, MMM d, yyyy'
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </OnwardCard>

                                {!selectedBidDetails?.isCarrier && (
                                    <ShipperBidTable
                                        listing={listing}
                                        orderTz={orderTz}
                                        setSelectedBid={setSelectedBid}
                                        selectedBid={selectedBid}
                                        setNotification={setNotification}
                                        selectedBidDetails={selectedBidDetails}
                                    />
                                )}

                                {selectedBidDetails?.isCarrier && !isPrebid && (
                                    <>
                                        <OnwardCard className="my-5">
                                            <Grid
                                                item
                                                xs={12}
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <SectionTitle>Counter Bids</SectionTitle>
                                            </Grid>
                                            <CounterBids
                                                bid={selectedBidDetails}
                                                listing={listing}
                                                setNotification={setNotification}
                                            />
                                        </OnwardCard>
                                    </>
                                )}

                                {/* BidComments component (carrier) */}
                                {selectedBidDetails?.isCarrier && !isPrebid && (
                                    <BidComments bidDetails={selectedBidDetails} />
                                )}

                                {competingBids.length > 0 && (
                                    <>
                                        <OnwardCard className="mt-5">
                                            <Grid
                                                item
                                                xs={12}
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <SectionTitle>Other Bidders</SectionTitle>
                                            </Grid>
                                            <OtherBidsTable bids={competingBids} />
                                        </OnwardCard>
                                    </>
                                )}

                                <Grid
                                    container
                                    className="d-flex justify-content-between align-items-start mt-5"
                                    spacing={3}
                                >
                                    <Grid item sm={6}>
                                        {order?.is_middle_mile &&
                                        ['pending', 'open'].includes(listing?.listing_status) ? (
                                            <MiddleMilePickupCard
                                                location={
                                                    listing?.bids?.find((bid) => bid.carrier_id === user_id)?.location
                                                }
                                                isShipper={listing?.shipper_id === user_id}
                                                order={order}
                                            />
                                        ) : (
                                            <PickUpCard order={order} editable={false} />
                                        )}
                                    </Grid>
                                    <Grid item sm={6}>
                                        <DropOffCard order={order} editable={false} />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    className="d-flex justify-content-between align-items-start mt-5"
                                    spacing={3}
                                >
                                    <Grid item sm={6}>
                                        <OrderSummaryCard order={order} editable={false} />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <OrderItems order={order} />
                                    </Grid>
                                </Grid>
                                <Grid item sm={1} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
            <CancelBidModal
                setOpenModal={setCancelBidModalOpen}
                isOpen={cancelBidModalOpen}
                onClose={() => setCancelBidModalOpen(false)}
                bid={selectedBidDetails}
            />
            <BackToUnassignedModal
                isOpen={backToUnassignedModalOpen}
                onClose={() => setBackToUnassignedModalOpen(false)}
                handleSubmit={() => handleSendBackToUnassigned({ listing_id: listing?.listing_id })}
            />
            <AuctionModal
                open={openListingModal}
                order={order}
                listing={listing}
                loading={renewLoading}
                callbacks={{
                    onClose: () => {
                        setOpenListingModal(false);
                    },
                    onSave: (val) => {
                        renewListing({ shipper_id: user_id, listings: [val.listing] });
                    },
                }}
            />

            {/* Snackbar Notifications */}
            <Snackbar
                open={!!notification?.message}
                onClose={() => setNotification(null)}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                autoHideDuration={10000}
            >
                <Alert
                    onClose={() => setNotification(null)}
                    severity={notification?.severity}
                    elevation={6}
                    variant="filled"
                >
                    {notification?.message}
                    {notification?.clickable && notification.clickable}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ListingDetailsPage;
