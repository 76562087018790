import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Button, Divider } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useMutation } from '@apollo/client';
import { UPDATE_NOTIFICATIONS  } from '../../graphql/mutations/notifications';

export default function Notification({ children, notification, onClickHandler }) {
    const [isResolved, setIsResolved] = useState(false);

    const [updateNotificationStatus] = useMutation(UPDATE_NOTIFICATIONS, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const handleClickResolve = async (event) => {
        event.preventDefault();
        await updateNotificationStatus({ 
            variables: {
                notification_id: notification.notification_id, 
                notification_status: "RESOLVED",
            },
        });
        setIsResolved(true);
      };


    return (
        <>
            <div 
             
                css={css `
                    min-height: 100px; 
                    min-width: 30vw; 
                    max-width: 30vw;
                    padding: 20px; 
                    margin-left: 0px;
                    display: flex;
                    border-radius: 4px;
                    cursor: pointer;
                `} 
            >  
                    <div 
                    onClick={onClickHandler}
                        css={css `
                            width: 25vw;
                            margin-bottom: 0px; 
                        `} 
                       
                    >
                        <div
                            css={css `
                                display: flex;
                                flex-direction: column; 
                            `}
                        >
                            {children}
                        </div>
                    </div> 
                        <>
                            {notification?.notification_status === "UNRESOLVED" && !isResolved? (
                                <Button 
                                key={notification.notification_id}
                                    css={css `
                                        height: 36px;
                                        width: 36px;
                                        margin-top: 0px;
                                        border-radius: 4px;
                                        background-color: #59B863;
                                        color: white;
                                        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
                                        &:hover  {
                                            background-color: #28a745;
                                            color: white;
                                        }
                                    `} 
                                    onClick={handleClickResolve}
                                >
                                    <CheckIcon />
                                </Button>
                             ) : (
                                <p 
                                    css={css `
                                        cursor: pointer;
                                        color: #59B863;
                                        font-size: 14px;
                                        font-style: italic;
                                        font-family: 'Montserrat';
                                        font-weight: 500;
                                        line-height: 17px;
                                        letter-spacing: -0.0571429px;
                                        border: 0px solid red;
                                        height: 36px;
                                        width: 70px;
                                        padding-top: 5px;
                                    `}  
                                
                                   onClick={handleClickResolve}
                                >
                                        Resolved
                                </p>
                            )} 
                        </>
                   
                </div>
                <Divider 
                    css={css `
                        margin-bottom: 10px; 
                        min-width: 29vw; 
                        max-width: 29vw;
                    `} 
                />
            </>
      );
    }
