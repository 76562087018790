import React, { useContext, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@material-ui/icons/Close';
import { css } from '@emotion/react';
import Papa from 'papaparse';
import { Grid } from '@material-ui/core';
import { SectionTitle, ModalContent, ResponsiveSidebarDialog } from '@/styles/blocks';
import { ReactComponent as UploadIconSvg } from '@/assets/upload_black_36dp.svg';
import { Body1, Body2 } from '../blocks';
import { Context } from '../store';
import { MODALS } from '../constants';
import Loader from '../Loader';

const PdfUploadModal = () => {
    const { state, callbacks } = useContext(Context);
    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    callbacks.setPdf({
                        filename: file.name,
                        preview: URL.createObjectURL(file),
                        base64: e.target.result,
                        page: 0,
                    });

                    callbacks.advance();
                };

                reader.readAsDataURL(file);
            });
        },
        [callbacks]
    );

    const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
        accept: '.pdf',
        onDrop,
    });

    const open = state.current === MODALS.PDF_UPLOAD;

    return (
        <ResponsiveSidebarDialog open={open} onClose={callbacks.reset}>
            <ModalContent width={740}>
                <Grid
                    css={css`
                        display: flex;
                        justify-content: space-between;
                    `}
                >
                    <SectionTitle>Upload PDF</SectionTitle>
                    <CloseIcon
                        css={css`
                            cursor: pointer;
                        `}
                        onClick={callbacks.revert}
                    />
                </Grid>

                <Grid
                    css={css`
                        margin: 12px 32px;
                    `}
                >
                    {state.isLoading && <Loader />}
                    {!state.isLoading && (
                        <div
                            {...getRootProps()}
                            css={css`
                                display: flex;
                                cursor: pointer;
                                padding: 35px;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid #4c4c4c;
                            `}
                        >
                            <input {...getInputProps()} />
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: column;
                                `}
                            >
                                <div>
                                    <UploadIconSvg
                                        css={css`
                                            fill: #4c4c4c;
                                        `}
                                    />
                                </div>

                                <div
                                    css={css`
                                        margin-bottom: 24px;
                                    `}
                                >
                                    <Body1>Select a file to upload</Body1>
                                </div>

                                <Body2>Only .pdf files are accepted</Body2>
                            </div>
                        </div>
                    )}
                </Grid>
            </ModalContent>
        </ResponsiveSidebarDialog>
    );
};

export default PdfUploadModal;
