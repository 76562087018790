import React from 'react';
import { css } from '@emotion/react';
import { SectionTitle } from '../../styles/blocks';
import { Box, LinearProgress } from '@material-ui/core';

const Loader = () => {
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                margin-top: 60px;
            `}
        >
            <SectionTitle>Processing Orders</SectionTitle>
            <Box
                css={css`
                    width: 50%;
                    margin-top: 12px;
                `}
            >
                <LinearProgress
                    css={css`
                        height: 10px;
                    `}
                />
            </Box>
        </div>
    );
};

export default Loader;
