import { gql } from '@apollo/client';
import { EVENT_SUBSCRIPTION_FIELDS } from '@/graphql/queries/event_subscriptions';

export const SERVICE_CLIENTS_AND_SUBCRIPTIONS_BY_CLIENT_ID = gql`
    subscription ServiceClientsAndSubscriptionsByClientId($client_id: uuid!) {
        clients_by_pk(client_id: $client_id) {
            client_id
            service_clients {
                service_id
                event_subscriptions {
                    event_subscription_id
                    service_id
                    target_url
                    subscribed_event
                    enabled
                }
            }
        }
    }
`;

export const ADD_EVENT_SUBSCRIPTION = gql`
    ${EVENT_SUBSCRIPTION_FIELDS}
    mutation AddEventSubscription($event_subscriptions_input_object: [event_subscriptions_insert_input!]! = []) {
        insert_event_subscriptions(objects: $event_subscriptions_input_object) {
            affected_rows
            returning {
                ...EventSubscriptionFields
            }
        }
    }
`;

export const TOGGLE_EVENT_SUBSCRIPTION = gql`
    ${EVENT_SUBSCRIPTION_FIELDS}
    mutation TogglEventSubscription($id: uuid!, $toggleTo: Boolean) {
        update_event_subscriptions_by_pk(pk_columns: { event_subscription_id: $id }, _set: { enabled: $toggleTo }) {
            ...EventSubscriptionFields
        }
    }
`;

export const DELETE_EVENT_SUBSCRIPTION_BY_ID = gql`
    ${EVENT_SUBSCRIPTION_FIELDS}
    mutation DeleteEventSubscriptionById($service_id: String, $target_url: String) {
        delete_event_subscriptions(
            where: { _and: { service_id: { _eq: $service_id }, target_url: { _eq: $target_url } } }
        ) {
            ...EventSubscriptionFields
        }
    }
`;
