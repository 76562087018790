import { useNavigate } from 'react-router';
import Notification from '..';
import { NotificationMetadata, NotificationTimestamp, NotificationTitle } from '../blocks';
import { format } from 'date-fns';

const BidAcceptedNotification = ({ notification, handleCloseNotification }) => {
    const navigate = useNavigate();

    return (
        <Notification
            notification={notification}
            onClickHandler={() => {
                navigate(
                    notification.notification_data?.listing_id
                        ? `/listing/${notification.notification_data?.listing_id}`
                        : '/auction/bids'
                );
                handleCloseNotification();
            }}
        >
            <NotificationTitle>
                {notification.notification_data?.counter_offer_accepted
                    ? `Congrats! Your counteroffer has been accepted`
                    : `Bid Won`}
            </NotificationTitle>
            <NotificationMetadata>
                {notification.notification_data?.counter_offer_accepted
                    ? `The carrier has accepted your counteroffer!`
                    : `Your bid has won the auction and the order has been assigned to you`}
            </NotificationMetadata>
            <NotificationMetadata>Order: {notification.notification_data?.order_number || 'N/A'}</NotificationMetadata>
            <NotificationTimestamp>
                {format(new Date(notification.created_at), 'MMM dd, yyyy @ hh:mm a')}
            </NotificationTimestamp>
        </Notification>
    );
};

export default BidAcceptedNotification;
