import { gql } from '@apollo/client';

const FULL_QUERY = gql`
    query GetOverrides {
        overrides(limit: 100) {
            override_id
            description
            enabled
        }
    }
`;

const QUERY_BY_ID = gql`
    query overrides($override_id: uuid!) {
        override: overrides_by_pk(override_id: $override_id) {
            override_id
            description
            enabled
        }
    }
`;

export { FULL_QUERY, QUERY_BY_ID };
