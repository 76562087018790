import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { OnwardCard } from './Card';
import { CardTitle, CardItem } from './blocks';
import { css } from '@emotion/react';

export default function WHCrossdockCard({ order }) {
    const wh_address = useMemo(() => {
        const event = (order?.wh_events || []).findLast(
            (event) => event.location_id && event.action.includes('ADD_CD')
        );
        if (!event) return 'No warehouse found';
        return `${event.location.business_address}, ${event.location.business_city}, ${event.location.business_state}, ${event.location.business_zip}`;
    }, [order]);

    return (
        <OnwardCard
            css={css`
                height: 100%;
            `}
            contentProps={{ style: { height: '100%' } }}
        >
            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} direction="column">
                <CardTitle>Cross Dock</CardTitle>
                <CardItem className="my-1">Warehouse</CardItem>
                <CardItem className="my-1">{wh_address}</CardItem>
            </Grid>
        </OnwardCard>
    );
}
