export const mileString = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'mile',
    unitDisplay: 'long',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export const minuteString = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'minute',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const minuteStringLong = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'minute',
    unitDisplay: 'long',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const hourString = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'hour',
    unitDisplay: 'long',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const hourStringShort = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'hour',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const hourMinuteFormatter = {
    format: (minutes) => {
        const hrs = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hrs ? `${hourString.format(hrs)} ` : ''}${mins ? minuteStringLong.format(mins) : ''}`;
    },
};

export const poundFormatter = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'pound',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const routeTimeFormatterTZ = (timeZone) =>
    new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone,
        timeZoneName: 'short',
    });

export const routeTimeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});
