import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { useClientUser } from '@/hooks';
import { useMutation } from '@apollo/client';
import {
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
    Body2,
    GridItemRow,
    ModalActions,
    ModalContent,
    ModalSubtitle,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
} from '../blocks';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { UPDATE_SETTINGS } from '../graphql/mutations';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@/styles';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { HelpOutline } from '@material-ui/icons';
import { hourMinuteFormatter, hourString, minuteStringLong } from '@/constants/formats';
import format from 'date-fns-tz/format';
import { withStyles } from '@material-ui/core/styles';

const TIMEFRAME_OPTIONS = [1, 2, 3, 4];
const BUFFER_OPTIONS = [30, 45, 60, 75, 90, 105, 120];
const SERVICE_TIME_OPTIONS = Array.from(Array(19).keys()).map((_, idx) => idx * 5);
const SERVICE_TIME_INCR_OPTIONS = [5, 10, 15];
const TOTAL_ROUTE_TIME_OPTIONS = Array.from(Array(32).keys()).map((_, idx) => ({
    hours: Math.floor((idx + 1) / 2),
    minutes: ((idx + 1) % 2) * 30,
}));

const ROUTE_EOD_TIME_OPTIONS = Array.from(Array(48).keys()).map((_, idx) => {
    const totalMins = idx * 30;
    const minutes = totalMins % 60;
    const hours = Math.floor(totalMins / 60);

    return {
        hours,
        minutes,
    };
});

const Row = styled((props) => <Grid container xs={12} {...props} />)`
    display: flex;
    padding: 0.5rem 0.5rem;
    align-items: center;
`;

const Item = styled((props) => <Grid item xs={6} {...props} />)`
    display: flex;
    align-items: center;
    padding: 0rem 0.5rem;
    width: 300px;
`;

const SettingsTitle = styled((props) => <ModalTitle {...props} />)`
    padding-bottom: 0;
    font-weight: 900;
    font-size: 18px;
    color: ${colors.greys.secondary};
`;

const SettingsContent = styled((props) => <ModalContent {...props} />)`
    padding: 0.5rem 0;
    .MuiFormControl-root {
        width: 100%;
    }
    .MuiInputLabel-formControl {
        margin-left: 0.5rem;
        margin-top: -0.25rem;
        background-color: white;
        z-index: 5;
        padding: 0 0.5rem;
        color: black;
    }
    .input[type='number'] {
        -moz-appearance: textfield;
    }
    .input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
    .input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    border-top: none;
    overflow-y: visible;
`;

const CustomTooltip = withStyles(() => ({
    tooltip: {
        fontSize: '14px !important',
        backgroundColor: 'rgba(97, 97, 97, 1.0) !important',
    },
}))(Tooltip);

const SettingsModal = () => {
    const {
        user_id,
        preferences_service_time,
        preferences_service_time_incr,
        preferences_service_time_curbside,
        preferences_service_time_first_dry_space,
        preferences_service_time_room_of_choice,
        preferences_service_time_white_glove,
        preferences_delivery_timeframe,
        preferences_timeframe_buffer,
        preferences_timeframe_buffer_apply,
        preferences_timeframe_round,
        preferences_route_time_limit,
        preferences_route_eod_limit,
        preferences_route_eod_limit_next_day,
        preferences_start_estimate,
        preferences_end_estimate,
        preferences_warehouse_return_estimate,
        preferences_use_service_default_as_minimum,
        preferences_next_day_returns,
        preferences_bypass_optimize,
        circles,
    } = useClientUser();
    const { modalOpen, setModalOpen, setNotification, setError } = useContext(PlanningContext);

    const [settings, setSettings] = useState({
        preferences_service_time,
        preferences_service_time_incr,
        preferences_service_time_curbside,
        preferences_service_time_first_dry_space,
        preferences_service_time_room_of_choice,
        preferences_service_time_white_glove,
        preferences_delivery_timeframe,
        preferences_timeframe_buffer,
        preferences_timeframe_buffer_apply,
        preferences_timeframe_round,
        preferences_route_time_limit,
        preferences_route_eod_limit,
        preferences_route_eod_limit_next_day,
        preferences_start_estimate,
        preferences_end_estimate,
        preferences_warehouse_return_estimate,
        preferences_use_service_default_as_minimum,
        preferences_next_day_returns,
        preferences_bypass_optimize,
    });

    useEffect(() => {
        if (modalOpen) {
            setSettings({
                preferences_service_time,
                preferences_service_time_incr,
                preferences_service_time_curbside,
                preferences_service_time_first_dry_space,
                preferences_service_time_room_of_choice,
                preferences_service_time_white_glove,
                preferences_delivery_timeframe,
                preferences_timeframe_buffer,
                preferences_timeframe_buffer_apply,
                preferences_timeframe_round,
                preferences_route_time_limit,
                preferences_route_eod_limit,
                preferences_route_eod_limit_next_day,
                preferences_start_estimate,
                preferences_end_estimate,
                preferences_warehouse_return_estimate,
                preferences_use_service_default_as_minimum,
                preferences_next_day_returns,
                preferences_bypass_optimize,
            });
        }
    }, [modalOpen]);

    const updateSettings = (key, val) => setSettings((prev) => ({ ...prev, [key]: val }));

    const [saveSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
        onCompleted: () => {
            setNotification({
                severity: 'success',
                message: 'Successfully updated delivery settings',
            });
            handleModalClose();
        },
        onError: (error) => {
            setError(error, 'Error saving delivery settings');
        },
    });

    const handleModalClose = () => {
        setModalOpen(null);
    };

    const handleSaveSettings = () => {
        saveSettings({
            variables: {
                client_id: user_id,
                update: {
                    ...settings,
                    preferences_route_time_limit: settings.preferences_route_time_limit
                        ? settings.preferences_route_time_limit
                        : null,
                    preferences_route_eod_limit: settings.preferences_route_eod_limit
                        ? settings.preferences_route_eod_limit
                        : null,
                },
            },
        });
    };

    return (
        <NavResponsiveModal maxWidth="lg" open={modalOpen === MODALS.SETTINGS} onClose={handleModalClose}>
            <SettingsTitle>Delivery Time Frame Settings</SettingsTitle>
            <ModalSubtitle>
                Set default time frame you'd like to give your customers as to when you will arrive with delivery.
            </ModalSubtitle>
            <SettingsContent>
                <Row>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-timeframe-label">
                                Arrival Time Frame
                            </InputLabel>
                            <Select
                                labelId="settings-timeframe-label"
                                id="settings-timeframe"
                                variant="outlined"
                                label="Arrival Time Frame"
                                value={settings.preferences_delivery_timeframe}
                                onChange={(e) =>
                                    updateSettings('preferences_delivery_timeframe', parseInt(e.target.value))
                                }
                            >
                                {TIMEFRAME_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {hourString.format(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                    <Item>
                        <OnwardCheckbox
                            label="Round arrival time frame"
                            checked={settings.preferences_timeframe_round}
                            onChange={(e) => updateSettings('preferences_timeframe_round', Boolean(e.target.checked))}
                        />
                        <CustomTooltip
                            title="The arrival time frame will be rounded to the nearest 30 minutes. If a buffer is added this will always round down to ensure the minimum buffer amount is added."
                            placement="top"
                        >
                            <HelpOutline
                                css={css`
                                    color: ${colors.greens.primary};
                                    margin-left: 1rem;
                                `}
                            />
                        </CustomTooltip>
                    </Item>
                </Row>
                <Row>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-buffer-label">
                                Arrival Buffer
                            </InputLabel>
                            <Select
                                labelId="settings-buffer-label"
                                id="settings-buffer"
                                variant="outlined"
                                value={settings.preferences_timeframe_buffer}
                                onChange={(e) =>
                                    updateSettings('preferences_timeframe_buffer', parseInt(e.target.value))
                                }
                            >
                                {BUFFER_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {hourMinuteFormatter.format(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                    <Item>
                        <OnwardCheckbox
                            label="Buffer arrival time frame"
                            checked={settings.preferences_timeframe_buffer_apply}
                            onChange={(e) =>
                                updateSettings('preferences_timeframe_buffer_apply', Boolean(e.target.checked))
                            }
                        />
                        <CustomTooltip
                            title="The arrival time frame will be increased by this amount. Expect the driver to arrive this far into the time frame."
                            placement="top"
                        >
                            <HelpOutline
                                css={css`
                                    color: ${colors.greens.primary};
                                    margin-left: 1rem;
                                `}
                            />
                        </CustomTooltip>
                    </Item>
                </Row>
            </SettingsContent>
            <SettingsTitle>Delivery Duration Settings</SettingsTitle>
            <ModalSubtitle>Customize the amount of time per delivery.</ModalSubtitle>
            <SettingsContent>
                <Row>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-service_time-label">
                                Default Service Time
                            </InputLabel>
                            <Select
                                labelId="settings-service_time-label"
                                id="settings-service_time"
                                variant="outlined"
                                value={settings.preferences_service_time}
                                onChange={(e) => updateSettings('preferences_service_time', parseInt(e.target.value))}
                            >
                                {SERVICE_TIME_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {minuteStringLong.format(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-service_time_incr-label">
                                Service Time Increment
                            </InputLabel>
                            <Select
                                labelId="settings-service_time_incr-label"
                                id="settings-service_time_incr"
                                variant="outlined"
                                value={settings.preferences_service_time_incr}
                                onChange={(e) =>
                                    updateSettings('preferences_service_time_incr', parseInt(e.target.value))
                                }
                            >
                                {SERVICE_TIME_INCR_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {minuteStringLong.format(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                </Row>
                <Row>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-service_time_curbside-label">
                                Curbside
                            </InputLabel>
                            <Select
                                labelId="settings-service_time_curbside-label"
                                id="settings-service_time_curbside"
                                variant="outlined"
                                value={settings.preferences_service_time_curbside}
                                onChange={(e) =>
                                    updateSettings('preferences_service_time_curbside', parseInt(e.target.value))
                                }
                            >
                                {SERVICE_TIME_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {minuteStringLong.format(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-service_time_first_dry_space-label">
                                First Dry Space
                            </InputLabel>
                            <Select
                                labelId="settings-service_time_first_dry_space-label"
                                id="settings-service_time_first_dry_space"
                                variant="outlined"
                                value={settings.preferences_service_time_first_dry_space}
                                onChange={(e) =>
                                    updateSettings('preferences_service_time_first_dry_space', parseInt(e.target.value))
                                }
                            >
                                {SERVICE_TIME_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {minuteStringLong.format(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                </Row>
                <Row>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-service_time_room_of_choice-label">
                                Room of Choice
                            </InputLabel>
                            <Select
                                labelId="settings-service_time_room_of_choice-label"
                                id="settings-service_time_room_of_choice"
                                variant="outlined"
                                value={settings.preferences_service_time_room_of_choice}
                                onChange={(e) =>
                                    updateSettings('preferences_service_time_room_of_choice', parseInt(e.target.value))
                                }
                            >
                                {SERVICE_TIME_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {minuteStringLong.format(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-service_time_white_glove-label">
                                White Glove
                            </InputLabel>
                            <Select
                                labelId="settings-service_time_white_glove-label"
                                id="settings-service_time_white_glove"
                                variant="outlined"
                                value={settings.preferences_service_time_white_glove}
                                onChange={(e) =>
                                    updateSettings('preferences_service_time_white_glove', parseInt(e.target.value))
                                }
                            >
                                {SERVICE_TIME_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {minuteStringLong.format(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                </Row>
                <Item
                    css={css`
                        width: 400px;
                    `}
                >
                    <OnwardCheckbox
                        label="Use defaults as minimums"
                        checked={settings.preferences_use_service_default_as_minimum}
                        onChange={(e) =>
                            updateSettings('preferences_use_service_default_as_minimum', Boolean(e.target.checked))
                        }
                    />
                    <CustomTooltip
                        title="If checked, this setting will use the above service times as the minimum service time for each respective service type."
                        placement="top"
                    >
                        <HelpOutline
                            css={css`
                                color: ${colors.greens.primary};
                                margin-left: 1rem;
                            `}
                        />
                    </CustomTooltip>
                </Item>
            </SettingsContent>
            <SettingsTitle>Route Time Limit Settings</SettingsTitle>
            <ModalSubtitle>
                Customize time limit constraints for your routes, before a warning appears letting you know that a route
                has gone over.
            </ModalSubtitle>
            <SettingsContent>
                <Row>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-total_route_time-label">
                                Total Route Time Limit
                            </InputLabel>
                            <Select
                                labelId="settings-total_route_time-label"
                                id="settings-total_route_time"
                                variant="outlined"
                                value={settings.preferences_route_time_limit}
                                onChange={(e) => updateSettings('preferences_route_time_limit', e.target.value)}
                                renderValue={(value) => {
                                    if (value) {
                                        let text = '';
                                        if (value.hours >= 1) {
                                            text += `${hourString.format(value.hours)}`;
                                        }
                                        if (value.minutes) {
                                            text += ` ${minuteStringLong.format(value.minutes)}`;
                                        }
                                        return text;
                                    }
                                    return 'None';
                                }}
                            >
                                <MenuItem key={'None'} value={0}>
                                    None
                                </MenuItem>
                                {TOTAL_ROUTE_TIME_OPTIONS.map((option) => {
                                    let text = '';
                                    if (option.hours >= 1) {
                                        text += `${hourString.format(option.hours)}`;
                                    }
                                    if (option.minutes) {
                                        text += ` ${minuteStringLong.format(option.minutes)}`;
                                    }
                                    return (
                                        <MenuItem key={`${option.hours}-${option.minutes}`} value={option}>
                                            {text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Item>
                </Row>
                <Row>
                    <Item>
                        <FormControl>
                            <InputLabel shrink id="settings-route_eod_limit-label">
                                Route End of Day Limit
                            </InputLabel>
                            <Select
                                labelId="settings-route_eod_limit-label"
                                id="settings-route_eod_limit"
                                variant="outlined"
                                value={settings.preferences_route_eod_limit}
                                onChange={(e) => {
                                    updateSettings('preferences_route_eod_limit', e.target.value);
                                }}
                                renderValue={(value) => {
                                    if (value) {
                                        let _date = new Date();
                                        _date.setHours(value.hours);
                                        _date.setMinutes(value.minutes);
                                        const text = format(_date, 'h:mm aa');
                                        return text;
                                    }
                                    return 'None';
                                }}
                            >
                                <MenuItem key={'None'} value={0}>
                                    None
                                </MenuItem>
                                {ROUTE_EOD_TIME_OPTIONS.map((option) => {
                                    let _date = new Date();
                                    _date.setHours(option.hours);
                                    _date.setMinutes(option.minutes);
                                    const text = format(_date, 'h:mm aa');
                                    return (
                                        <MenuItem key={`${option.hours}-${option.minutes}`} value={option}>
                                            {text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Item>
                    <Item>
                        <OnwardCheckbox
                            label="Next day"
                            checked={settings.preferences_route_eod_limit_next_day}
                            onChange={(e) =>
                                updateSettings('preferences_route_eod_limit_next_day', Boolean(e.target.checked))
                            }
                        />
                        <CustomTooltip
                            title="If the end of day cutoff is at or after midnight, then this box should be checked."
                            placement="top"
                        >
                            <HelpOutline
                                css={css`
                                    color: ${colors.greens.primary};
                                    margin-left: 1rem;
                                `}
                            />
                        </CustomTooltip>
                    </Item>
                </Row>
            </SettingsContent>

            {/* Route Estimate Settings */}
            <SettingsTitle>Route Estimate Settings</SettingsTitle>
            <ModalSubtitle>
                Customize which stop types are factored into or skipped for route estimates, such as total route
                distance and driving time. Checked means that the stop type IS considered for route estimate
                calculations.
            </ModalSubtitle>
            <SettingsContent>
                <Row>
                    <Item>
                        <OnwardCheckbox
                            label="Route Start Location"
                            checked={settings.preferences_start_estimate}
                            onChange={(e) => updateSettings('preferences_start_estimate', Boolean(e.target.checked))}
                        />
                        {circles?.['overnight-routes'] && (
                            <CustomTooltip
                                title={
                                    'Note: If a route contains stops that are marked as Overnight, then the start location WILL be used as part of route total estimates, regardless of this setting.'
                                }
                                placement="top"
                            >
                                <HelpOutline
                                    css={css`
                                        color: ${colors.greens.primary};
                                        margin-left: 1rem;
                                    `}
                                />
                            </CustomTooltip>
                        )}
                    </Item>
                </Row>
                <Row>
                    {!circles?.['plan-page-mapbox'] && (
                        <Item>
                            <OnwardCheckbox
                                label="Final Warehouse Dropoffs for Returns"
                                checked={settings.preferences_warehouse_return_estimate}
                                onChange={(e) => {
                                    updateSettings('preferences_warehouse_return_estimate', Boolean(e.target.checked));
                                    if (!e.target.checked) {
                                        updateSettings('preferences_end_estimate', Boolean(false));
                                    }
                                }}
                            />
                            <CustomTooltip
                                title={
                                    'After a return order has been picked up from a customer, a stop is added to the route to drop the items off back at the warehouse. Check this for this stop to be considered for route estimate calculations. Note: if this is disabled, route end location cannot be enabled for estimates.'
                                }
                                placement="top"
                            >
                                <HelpOutline
                                    css={css`
                                        color: ${colors.greens.primary};
                                        margin-left: 1rem;
                                    `}
                                />
                            </CustomTooltip>
                        </Item>
                    )}
                    <Item>
                        <OnwardCheckbox
                            label="Route End Location"
                            checked={settings.preferences_end_estimate}
                            onChange={(e) => {
                                updateSettings('preferences_end_estimate', Boolean(e.target.checked));
                                if (e.target.checked) {
                                    updateSettings('preferences_warehouse_return_estimate', Boolean(true));
                                }
                            }}
                        />
                        <CustomTooltip
                            title={`Check this for custom route end locations to be considered for route estimate calculations.${
                                !circles?.['plan-page-mapbox']
                                    ? ' Note: if this is enabled, final warehouse dropoffs must be enabled for estimates.'
                                    : ''
                            }`}
                            placement="top"
                        >
                            <HelpOutline
                                css={css`
                                    color: ${colors.greens.primary};
                                    margin-left: 1rem;
                                `}
                            />
                        </CustomTooltip>
                    </Item>
                </Row>
            </SettingsContent>

            {/* Return Settings */}
            <SettingsTitle>Returns</SettingsTitle>
            <ModalSubtitle>Settings regarding return orders</ModalSubtitle>
            <SettingsContent>
                <Row>
                    <Item>
                        <FormControl>
                            <InputLabel id="settings-preferences_next_day_returns-label">
                                Return to Warehouse
                            </InputLabel>
                            <Select
                                labelId="settings-preferences_next_day_returns-label"
                                id="settings-preferences_next_day_returns"
                                variant="outlined"
                                value={settings.preferences_next_day_returns || false}
                                onChange={(e) => updateSettings('preferences_next_day_returns', e.target.value)}
                            >
                                <MenuItem key={'same_day'} value={false}>
                                    Same Day
                                </MenuItem>
                                <MenuItem key={'next_day'} value={true}>
                                    Next Day
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Item>
                </Row>
            </SettingsContent>

            {/* Advanced Settings */}
            <SettingsTitle>Advanced Settings</SettingsTitle>
            <ModalSubtitle>Custom or experimental settings for advanced users</ModalSubtitle>
            <SettingsContent>
                <Row>
                    <Item>
                        <OnwardCheckbox
                            label="Bypass Optimization Requirements"
                            checked={settings.preferences_bypass_optimize}
                            onChange={(e) => {
                                updateSettings('preferences_bypass_optimize', Boolean(e.target.checked));
                            }}
                        />
                        <CustomTooltip
                            title={`Mapping or Optimizing a route after performing certain actions such as removing a stop will not be required. This will disable sending routes to Onward as it could potentially create poorly optimized routes with inaccurate timeframes.`}
                            placement="top"
                        >
                            <HelpOutline
                                css={css`
                                    color: ${colors.greens.primary};
                                    margin-left: 1rem;
                                `}
                            />
                        </CustomTooltip>
                    </Item>
                </Row>
            </SettingsContent>

            <ModalActions>
                <SecondaryButton onClick={handleModalClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleSaveSettings} disabled={loading}>
                    Save
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default SettingsModal;
