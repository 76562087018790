import { parsePhoneNumber } from 'libphonenumber-js';

export const toNational = (phone, defaultValue) => {
    if (phone && phone.length) {
        try {
            const parsedPhoneNumber = parsePhoneNumber(phone, 'US');
            if (parsedPhoneNumber.isValid()) {
                return parsedPhoneNumber.formatNational();
            }
        } catch (e) {
            // console.error(e);
        }

        return defaultValue || `${phone} (Invalid Phone #)`;
    }
    return defaultValue || 'Missing Phone #';
};

export const toE164 = (phone, defaultValue) => {
    if (phone && phone.length) {
        try {
            const parsedPhoneNumber = parsePhoneNumber(phone, 'US');
            if (parsedPhoneNumber.isValid()) {
                return parsedPhoneNumber.format('E.164');
            }
        } catch (e) {
            // console.error(e);
        }

        return phone.length > 0 || defaultValue === undefined ? `${phone} (Invalid Phone #)` : defaultValue;
    }

    return defaultValue === undefined ? 'Missing Phone #' : defaultValue;
};

export const validate = (phone) => {
    if (phone && phone.length) {
        try {
            const parsedPhoneNumber = parsePhoneNumber(phone, 'US');
            if (parsedPhoneNumber.isValid()) {
                return true;
            }
        } catch (e) {
            // console.error(e);
        }
    }
    return false;
};
