import React, { useState } from 'react';
import {
    Typography,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Paper,
    Button,
    IconButton,
    TextField,
    InputLabel,
} from '@material-ui/core';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import { css } from '@emotion/react';
import { useMutation, useSubscription } from '@apollo/client';
import {
    SERVICE_CLIENTS_AND_SUBCRIPTIONS_BY_CLIENT_ID,
    ADD_EVENT_SUBSCRIPTION,
    DELETE_EVENT_SUBSCRIPTION_BY_ID,
    TOGGLE_EVENT_SUBSCRIPTION,
} from './graphql';

const EventSubscriptions = ({ client_id }) => {
    const [subscriptions, setSubscriptions] = useState({});
    const [urlText, setUrlText] = useState(null);
    const [newSubServiceId, setNewSubServiceId] = useState('Select a service ID');
    const [serviceIds, setServiceIds] = useState([]);

    const checkboxMap = {
        order_created: {
            display: 'Order Created',
        },
        order_in_transit: {
            display: 'Order In Transit',
        },
        order_completed: {
            display: 'Order Completed',
        },
        order_cancelled: {
            display: 'Order Cancelled',
        },
        exception_reported: {
            display: 'Exception Reported',
        },
    };

    const { data: subscriptionData } = useSubscription(SERVICE_CLIENTS_AND_SUBCRIPTIONS_BY_CLIENT_ID, {
        variables: {
            client_id: client_id,
        },
        onData: ({ data }) => {
            const subs = {};
            const _serviceIds = [];

            data?.data?.clients_by_pk?.service_clients?.forEach((service_client) => {
                if (!_serviceIds.includes(service_client.service_id)) {
                    _serviceIds.push(service_client.service_id);
                }

                if (!subs[service_client.service_id]) {
                    subs[service_client.service_id] = {};
                }

                service_client?.event_subscriptions?.forEach((sub) => {
                    if (!subs[service_client.service_id][sub.target_url]) {
                        subs[service_client.service_id][sub.target_url] = {};
                    }
                    subs[service_client.service_id][sub.target_url][sub.subscribed_event] = {
                        enabled: sub.enabled,
                        id: sub.event_subscription_id,
                    };
                });
            });

            setServiceIds(_serviceIds);
            setSubscriptions(subs);
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const [addEventSubscription, { loading }] = useMutation(ADD_EVENT_SUBSCRIPTION);
    const [toggleEventSubscription, { toggleLoading }] = useMutation(TOGGLE_EVENT_SUBSCRIPTION);
    const [deleteEventSubscription, { deleteLoading }] = useMutation(DELETE_EVENT_SUBSCRIPTION_BY_ID);

    const saveUrlText = () => {
        if (newSubServiceId === 'Select a service ID') return;

        addEventSubscription({
            variables: {
                event_subscriptions_input_object: [
                    ...Object.keys(checkboxMap).map((_event) => ({
                        service_id: newSubServiceId,
                        target_url: urlText,
                        subscribed_event: _event,
                        enabled: true,
                    })),
                ],
            },
            onError: (err) => {
                console.log(err);
            },
        });

        const newSubscription = {};
        Object.keys(checkboxMap).forEach((_event) => {
            newSubscription[_event] = true;
        });

        setSubscriptions({
            ...subscriptions,
            [newSubServiceId]: {
                ...subscriptions[newSubServiceId],
                urlText: {
                    ...newSubscription,
                },
            },
        });
        setUrlText(null);
        setNewSubServiceId('Select a service ID');
    };

    const handleDeleteSubscription = (service_id, target_url) => {
        deleteEventSubscription({
            variables: {
                service_id,
                target_url,
            },
            onError: (e) => {
                console.log(e);
            },
        });
    };

    const handleCheckToggle = (id, toggleTo) => {
        toggleEventSubscription({
            variables: {
                id,
                toggleTo,
            },
            onError: (e) => {
                console.log(e);
            },
        });
    };

    if (!serviceIds.length) return <></>;
    return (
        <>
            <h5 className="mt-3">Event Subscriptions</h5>
            {Object.keys(subscriptions)?.map((service_id) =>
                Object.keys(subscriptions[service_id]).map((link) => (
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        css={css`
                            margin-bottom: 10px;
                        `}
                    >
                        <Grid item container xs={4} direction="row" alignItems="center">
                            <Grid
                                item
                                xs={2}
                                css={css`
                                    text-align: center;
                                `}
                            >
                                <IconButton
                                    onClick={() => handleDeleteSubscription(service_id, link)}
                                    css={css`
                                        color: white;
                                        background-color: red;
                                        height: 2rem;
                                        width: 2rem;
                                    `}
                                >
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField disabled fullWidth value={link} variant="outlined" label={'URL'} />
                            </Grid>
                        </Grid>

                        {/* Map over checkboxes */}
                        <Grid item container direction="row" xs={5}>
                            {Object.keys(checkboxMap).map((_event) => {
                                if (subscriptions[service_id][link][_event]) {
                                    return (
                                        <Grid item xs={6}>
                                            <OnwardCheckbox
                                                disabled={toggleLoading}
                                                label={checkboxMap[_event]?.display || ''}
                                                checked={
                                                    subscriptions[service_id][link][_event].enabled === undefined
                                                        ? true
                                                        : subscriptions[service_id][link][_event].enabled
                                                }
                                                onChange={(e) =>
                                                    handleCheckToggle(
                                                        subscriptions[service_id][link][_event]?.id,
                                                        !subscriptions[service_id][link][_event].enabled
                                                    )
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                            })}
                        </Grid>
                        <Grid item xs={3}>
                            <TextField disabled fullWidth value={service_id} variant="outlined" label={'Service id'} />
                        </Grid>
                        <Grid item></Grid>
                    </Grid>
                ))
            )}

            {typeof urlText === 'string' && (
                <Grid direction="row" container alignItems="center">
                    <Grid item>
                        <TextField
                            fullWidth
                            className=""
                            value={urlText}
                            variant="outlined"
                            label={'URL'}
                            onChange={(e) => {
                                setUrlText(e.target.value);
                            }}
                        ></TextField>
                    </Grid>

                    <Grid
                        item
                        css={css`
                            margin-left: 10px;
                        `}
                    >
                        <FormControl className="w-100" variant="outlined">
                            <InputLabel>Service ID</InputLabel>
                            <Select
                                fullWidth
                                className=""
                                value={newSubServiceId}
                                variant="outlined"
                                label={'Service ID'}
                                required
                                onChange={(e) => setNewSubServiceId(e.target.value)}
                            >
                                <option value="Select a service ID">Select a service ID</option>
                                {serviceIds.map((serv_id) => (
                                    <option value={serv_id}>{serv_id}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={() => {
                                saveUrlText();
                            }}
                            variant="contained"
                            color="primary"
                            css={css`
                                margin-left: 10px;
                                color: #eee;
                            `}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}

            {typeof urlText !== 'string' && (
                <Grid container direction="row">
                    <IconButton
                        onClick={() => setUrlText('')}
                        css={css`
                            color: white;
                            background-color: green;
                            height: 2rem;
                            width: 2rem;
                            margin-top: 10px;
                        `}
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
            )}
        </>
    );
};

export default EventSubscriptions;
