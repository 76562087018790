import React, { useState, useEffect } from 'react';
import { UPDATE_QBO_TAGS } from '../mutations';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useClientUser } from '@/hooks';
import { css } from '@emotion/react';
import { TabCard } from '../blocks';
import Snackbar from '@/components/Snackbar';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { IconButton, TextField } from '@material-ui/core';
import { DeleteForeverOutlined, SaveOutlined } from '@material-ui/icons';

const ProductService = ({ tag, index, callbacks, disabled }) => {
    const [tagLocal, setTagLocal] = useState(tag);
    const modified = tagLocal !== tag;

    return (
        <>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    padding: .25rem 0rem;
                `}
            >
                <TextField
                    label="Product/Service"
                    variant="outlined"
                    value={tagLocal}
                    disabled={disabled}
                    size="small"
                    onChange={(e) => {
                        setTagLocal(e.target.value);
                        callbacks.onSave(tag, e.target.value, index);
                    }}
                />
                <IconButton disabled={disabled} onClick={() => callbacks.onDelete(tag, index)}>
                    <DeleteForeverOutlined fontSize="medium" color={disabled ? 'disabled' : 'error'} />
                </IconButton>
            </div>
        </>
    );
};
const ProductServices = (currentCarrier) => {
    const { user_id, qbo_tags, roles, teammateRoles, isImposter } = useClientUser();
    const [notification, setNotification] = useState({});
    const [qborefs, setQborefs] = useState([]);

    useEffect(() => {
        setQborefs(qbo_tags || []);
    }, [qbo_tags]);

    const invoiceAccess = roles["ADMIN"] || roles["CARRIER"] || teammateRoles?.invoicing || isImposter ? true : false;

    const [updateQboRefs] = useMutation(UPDATE_QBO_TAGS, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const handleSaveOption = async () => {
        if (qborefs.includes('')) {
            setNotification({ message: 'Invalid empty entry detected. Please ensure all fields are filled out correctly.', type: 'error' });
            return;
        }
        try {
            await updateQboRefs({
                variables: {
                    client_id: user_id,
                    qbo_tags: qborefs,
                },
            });
            setNotification({ message: 'Save successful!', type: 'success' });
        } catch (error) {
            setNotification({ message: 'Save failed!', type: 'error' });
        }
    };
    const addProduct = () => {
        setQborefs([...qborefs, '']);
    };
    const handleDelete = (tag) => {
        setQborefs(qborefs.filter((t) => t !== tag));
    };

    const handleSave = (tag, updates, i) => {
        const newQboRefs = [...qborefs];
        newQboRefs[i] = updates;
        setQborefs(newQboRefs);
        return;
    };
    return (
            <TabCard
            css={css`
                    margin-top: 1rem;
                `}>
                <div
                css={css`
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    padding: 1rem 2rem;
                `}
            >
                <div>
                    <h5>Accounting - QBO Product/Service Options</h5>
                    
                    {qborefs && qborefs.length > 0 ? qborefs.map((tag, i) => (
                        <ProductService
                            key={i}
                            index={i}
                            tag={tag}
                            disabled={!invoiceAccess}
                            callbacks={{
                                onSave: handleSave,
                                onDelete: handleDelete,
                            }}
                        />
                    )) : null}
                    <SecondaryButton
                        css={css`
                            margin: 1rem 0rem;
                            background-color: lightgrey !important;
                        `}
                        onClick={addProduct}
                        disabled={!invoiceAccess}
                    >
                        Add Product/Service
                    </SecondaryButton>
                    <br />
                    <div
                        css={css`
                            display: flex;
                            align-items: flex-end;
                        `}
                    >
                        <PrimaryButton
                            css={css`
                                margin: 1rem 1rem 1rem 0rem;
                            `}
                            onClick={handleSaveOption}
                            disabled={!invoiceAccess}
                        >
                            Save Changes
                        </PrimaryButton>
                        
                        
                    </div>
                    {notification.message && 
                            <div>
                                <p style={{color: notification.type === 'success' ? 'green' : 'red', fontWeight: 500}}>
                                    {notification.message}
                                </p>
                            </div>
                        }
                </div>

            </div>

            </TabCard>
    );
};

export default ProductServices;

