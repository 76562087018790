import React, { useMemo, useState } from 'react';
import { SectionHeader, Item, GreyItem, BoldItem } from './blocks';
import { Grid } from '@material-ui/core/';
import { OnwardCard } from '../OrderDetailsPage/Card';
import { PrimaryButton } from '@/styles/blocks';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { format } from 'date-fns';
import NewCommentModal from './modals/NewCommentModal';

export default function BidComments({ bidDetails }) {
    const [newCommentModalOpen, setNewCommentModalOpen] = useState(false);

    const sortedComments = useMemo(() => {
        if (!bidDetails) return [];
        return bidDetails?.bid_comments?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }, [bidDetails]);

    const commentOwner = (_comment) => {
        return _comment.client_id === bidDetails.carrier_id ? 'Carrier' : 'Shipper';
    };

    const CommentItem = (_comment, first, last) => {
        return (
            <>
                {!first && (
                    <hr
                        css={css`
                            border-top: 1px solid ${colors.greys.border};
                            margin: 0;
                        `}
                    />
                )}
                <Grid
                    item
                    container
                    css={css`
                        margin-top: 20px;
                        ${!last && 'margin-bottom: 20px;'}
                    `}
                >
                    <Grid item container justifyContent="space-between">
                        <Grid item>
                            <BoldItem>{commentOwner(_comment)}</BoldItem>
                        </Grid>
                        <Grid item>
                            <GreyItem>{format(new Date(_comment.created_at), 'eee, MMMM d, yyyy @ hh:mm aa')}</GreyItem>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Item>{_comment.comment_text}</Item>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            <OnwardCard>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    css={css`
                        margin-bottom: 40px;
                    `}
                >
                    <Grid item>
                        <SectionHeader>Comments</SectionHeader>
                    </Grid>
                    <Grid item>
                        <PrimaryButton onClick={() => setNewCommentModalOpen(true)}>New Comment</PrimaryButton>
                    </Grid>
                </Grid>
                {sortedComments.map((_comment, idx) =>
                    CommentItem(_comment, idx === 0, idx === sortedComments.length - 1)
                )}
            </OnwardCard>
            <NewCommentModal
                open={newCommentModalOpen}
                onClose={() => setNewCommentModalOpen(false)}
                bidDetails={bidDetails}
            />
        </>
    );
}
