import { gql } from '@apollo/client';
import { RATING_FIELDS } from '@/graphql/queries/ratings';

export const UPSERT_RATING = gql`
    ${RATING_FIELDS}

    mutation UpsertRating($rating: ratings_insert_input!) {
        insert_ratings(
            objects: [$rating]
            on_conflict: { constraint: ratings_pkey, update_columns: [chips, comment, rating, last_modified_at] }
        ) {
            returning {
                ...RatingFields
            }
        }
    }
`;
