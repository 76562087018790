import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton } from '../../DispatchPlan/blocks';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const AssignedToRouteErrorModal = ({ open, onClose, order }) => {
    const [pickupRoute, dropoffRoute] = useMemo(() => {
        return [
            (order?.routes || []).find((mapping) => mapping.type === 'PICKUP')?.route,
            (order?.routes || []).find((mapping) => mapping.type !== 'PICKUP')?.route,
        ];
    }, [order]);

    return (
        <NavResponsiveModal open={open} onClose={onClose}>
            <ModalTitle
                css={css`
                    border-bottom: none;
                `}
            >
                <GridItemRow
                    css={css`
                        background-color: #d23e3e;
                        border-radius: 5px;
                        padding: 1rem;
                        color: #ffffff;
                    `}
                >
                    <ReportProblemIcon />
                    <span
                        css={css`
                            font-size: 16px;
                            flex-grow: 1;
                            padding-left: 1rem;
                        `}
                    >
                        {`This order is assigned to a route.`}
                    </span>
                </GridItemRow>
            </ModalTitle>
            <ModalContent
                css={css`
                    font-size: 16px;
                `}
            >
                <GridItemRow>
                    <span
                        css={css`
                            font-weight: 700;
                        `}
                    >
                        Route assigned:{' '}
                        {[...(pickupRoute ? [pickupRoute.route_number] : []), dropoffRoute?.route_number || '-'].join(
                            ' / '
                        )}
                    </span>
                </GridItemRow>
                <br />
                <GridItemRow>
                    <span
                        css={css`
                            font-weight: 500;
                        `}
                    >
                        You must remove it from the route to send to Onward.
                    </span>
                </GridItemRow>
            </ModalContent>
            <ModalActions
                css={css`
                    border-top: none;
                    justify-content: end;
                `}
            >
                <PrimaryButton
                    css={css`
                        justify-content: center;
                        width: 128px;
                    `}
                    onClick={onClose}
                >
                    Okay
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default AssignedToRouteErrorModal;
