import { BID_FIELDS } from '@/graphql/queries/bids';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { LISTING_FIELDS } from '@/graphql/queries/listings';
const { ITEM_FIELDS } = require('@/graphql/queries/items');
const { ORDER_FIELDS } = require('@/graphql/queries/orders');
import { USER_FIELDS } from '@/graphql/queries/users';
const { gql } = require('@apollo/client');
import { ORDER_COMMUNICATIONS_FIELDS } from '@/graphql/queries/order_communications';

export const LTL_LOADS = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${CLIENT_FIELDS}
    ${USER_FIELDS}
    ${LISTING_FIELDS}
    ${BID_FIELDS}
    ${ORDER_COMMUNICATIONS_FIELDS}
    query GetLoadBoardLTLs($condition: orders_bool_exp!, $currentTime: timestamptz) {
        orders(
            order_by: { created_at: desc_nulls_last }
            where: {
                _and: [
                    { order_status: { _in: ["open", "pending"] } }
                    { _not: { routes: {} } }
                    { oms: { _eq: false } }
                    { carrier_id: { _is_null: true } }
                    { listing: { expiration_date: { _gte: $currentTime } } }
                    $condition
                ]
            }
        ) {
            ...OrderFields
            order_shipper {
                ...ClientFields
                user {
                    ...UserFields
                }
            }
            itemsByOrderId {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
            returns @client {
                ...ItemFields
            }
            listing {
                ...ListingFields
                bids {
                    ...BidFields
                }
            }
            order_communications {
                ...OrderCommunicationsFields
            }
        }
    }
`;

export const CLAIM_LOADS = gql`
    ${ORDER_FIELDS}

    mutation ClaimLoads($order_ids: [uuid!]!, $update: orders_set_input!) {
        update_orders(
            where: { _and: [{ order_id: { _in: $order_ids } }, { carrier_id: { _is_null: true } }] }
            _set: $update
        ) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
    }
`;
