import { GreyBody, ModalTitle, PrimaryButton, SecondaryButton, StickyModalActions } from '@/styles/blocks';
import { Grid, Tooltip } from '@material-ui/core';
import NavResponsiveModal from '../Navigation/NavResponsiveModal';
import { css } from '@emotion/react';
import { BiddingPrice, TextField } from '../ShipmentForm/blocks';
import { useEffect, useMemo, useState } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { CustomMuiDatePicker } from '../ShipmentForm/modals/InputFields';
import { addDays, subDays } from 'date-fns';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { useClientUser } from '@/hooks';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { OnwardCheckbox } from '../ShipmentForm/blocks';
import { Info } from '@material-ui/icons';

const PlaceBidModal = ({ open, onClose, load, allLoads, callbacks, existingBid = {} }) => {
    const { email: dbEmail, businessPhone } = useClientUser();
    const [bid, setBid] = useState(existingBid);
    const [targetDeliveryDate, setTargetDeliveryDate] = useState(false);
    const [comments, setComments] = useState('');

    // If there are pending orders, then this is a pre-bid
    const anyOrdersPending = allLoads?.some((l) => l.order_status === 'pending');

    const orderTZ = useMemo(() => {
        return load?.dropoff_zip
            ? zipcode_to_timezone.lookup(load?.dropoff_zip)
            : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [load]);

    const carrierPriceDefault = load?.admin_carrier_rate_override || load?.carrier_rate || 0;

    useEffect(() => {
        if (existingBid?.bid_id) {
            setBid(existingBid);
        } else {
            const utc = addDays(
                new Date(asUTCDate(new Date().toISOString()).setUTCHours(0, 0, 0, 0)),
                21
            ).toISOString();
            setBid(
                load?.listing
                    ? {
                          listing_id: load.listing.listing_id,
                          carrier_rate: carrierPriceDefault,
                          expiration_date: asDateInTZ(utc, orderTZ).toISOString(),
                          target_delivery_date: load.preferred_delivery_date,
                          carrier_email: dbEmail || '',
                          carrier_phone: businessPhone || '',
                      }
                    : {}
            );
        }
    }, [load, existingBid?.bid_id]);

    if (!load?.listing) {
        return null;
    }

    return (
        <NavResponsiveModal open={open} onClose={onClose}>
            <ModalTitle title={`${anyOrdersPending ? 'Place Pre-Bid' : 'Place Bid'}`} onClose={onClose} />
            <Grid
                container
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                    width: 100%;
                    max-width: 450px;
                    padding: 1rem;
                `}
            >
                {anyOrdersPending && (
                    <Grid item xs={12}>
                        <GreyBody>
                            Since this load is still pending, you may only place a{' '}
                            <span style={{ fontWeight: 700 }}>pre-bid</span> currently. Once the load is approved and
                            becomes available, your pre-bid will become a normal bid and will be automatically placed on
                            this load.
                        </GreyBody>
                    </Grid>
                )}

                <BiddingPrice
                    value={bid.carrier_rate}
                    onChange={(val) => setBid((prev) => ({ ...prev, carrier_rate: val }))}
                    sliderMin={0}
                    sliderSuggested={carrierPriceDefault}
                    sliderMax={carrierPriceDefault * 2}
                    label="Bid Price"
                />
                <Grid item xs={12} className="mt-3">
                    <CustomMuiDatePicker
                        label={'Delivery Date'}
                        selectedDate={bid.target_delivery_date || bid.delivery_date}
                        timeZone={orderTZ}
                        onChange={(val) => setBid((prev) => ({ 
                            ...prev,
                            ...(targetDeliveryDate
                                ? {
                                    target_delivery_date: val,
                                    delivery_date: null,
                                }
                                : {
                                    target_delivery_date: null,
                                    delivery_date: val,
                                })
                        }))}
                        daysToHighlight={[
                            ...(load.preferred_delivery_date ? [load.preferred_delivery_date] : []),
                            ...(load.alternative_delivery_dates || []),
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GreyBody>
                        Customer's available dates are highlighted. If you choose another date you may need to confirm
                        the delivery date with the customer.
                    </GreyBody>
                </Grid>
                <Grid item xs={12}>
                    <GreyBody>Delivery date is not set until bid is accepted.</GreyBody>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className="mb-2"
                    css={css`
                        justify-content: flex-start;
                    `}
                >
                    <OnwardCheckbox
                        label="Target Delivery?"
                        checked={targetDeliveryDate}
                        onChange={(e) => {
                            setTargetDeliveryDate((prev) => !prev);
                            const currDate = bid.target_delivery_date || bid.delivery_date;
                            setBid((prev) => ({ 
                                ...prev,
                                ...(!targetDeliveryDate
                                    ? {
                                        target_delivery_date: currDate || null,
                                        delivery_date: null,
                                    }
                                    : {
                                        target_delivery_date: null,
                                        delivery_date: currDate || null,
                                    })
                            }))
                        }}
                    />
                    <Tooltip
                        css={css`
                            color: #59b863;
                            margin-left: 0.5rem;
                        `}
                        title={
                            <span
                                css={css`
                                    font-size: 15px;
                                    line-height: 21px;
                                `}
                            >
                                Check this box if the provided date is just a target delivery date that you are aiming
                                for. Leave it unchecked if it is a set delivery date that you plan to deliver the items
                                on.
                            </span>
                        }
                        placement="top"
                    >
                        <Info />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} className="mt-3">
                    <CustomMuiDatePicker
                        label={'Expiration Date'}
                        //we are storing the exact expiration time in db, but want to display that they have till eod through a selected date, hence adddays/subdays
                        selectedDate={subDays(new Date(bid?.expiration_date), 1)}
                        timeZone={orderTZ}
                        onChange={(val) => {
                            setBid((prev) => ({ ...prev, expiration_date: addDays(new Date(val), 1).toISOString() }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GreyBody>Your bid price expires after this date.</GreyBody>
                </Grid>
                <Grid item container justifyContent="space-between" xs={12}>
                    <Grid item xs={6} className="mt-3">
                        <TextField
                            variant="outlined"
                            css={css`
                                width: 95%;
                            `}
                            label="Phone"
                            InputProps={{
                                inputComponent: PhoneNumberInput,
                            }}
                            value={toNational(bid?.carrier_phone)}
                            onChange={(e) => {
                                setBid((prev) => ({ ...prev, carrier_phone: toE164(e.target.value) }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className="mt-3">
                        <TextField
                            variant="outlined"
                            className="ml-2"
                            label="Email"
                            value={bid?.carrier_email}
                            onChange={(e) => {
                                setBid((prev) => ({ ...prev, carrier_email: e.target.value }));
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="mt-3">
                    <TextField
                        css={css`
                            width: 100%;
                        `}
                        variant="outlined"
                        label="Comments"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        multiline
                        minRows={3}
                    />
                </Grid>
            </Grid>
            <StickyModalActions>
                <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={() => callbacks.placeBid(bid, comments)}>
                    {' '}
                    {existingBid.listing_id ? 'Save' : 'Next'}{' '}
                </PrimaryButton>
            </StickyModalActions>
        </NavResponsiveModal>
    );
};

export default PlaceBidModal;
