import { gql } from '@apollo/client';

export const CSV_MAPPINGS_FIELDS = gql`
    fragment CsvMappingsFields on csv_mappings {
        created_at
        freight_type
        legacy_user_id
        mapping
        mapping_id
        name
        user_id
    }
`;

export const QUERY_BY_USER = gql`
    ${CSV_MAPPINGS_FIELDS}

    query CsvMappingByUserId($user_id: uuid!) {
        results: csv_mappings(where: { user_id: { _eq: $user_id } }) {
            ...CsvMappingsFields
        }
    }
`;
