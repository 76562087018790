import React, { useContext, useState } from 'react';
import { compose } from 'recompose';
import Snackbar from '@/components/Snackbar';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { Context, ContextProvider } from './store';
import InventoryManagement from './InventoryManagement';

export const CarrierAccountingOrders = ({ context, Footer }) => {
    const { state, loading, callbacks } = useContext(context);

    return (
        <Grid container direction="column" wrap="nowrap" className="h-100">
            <Snackbar
                open={state.notification.message}
                handleClose={() => callbacks.setNotification({})}
                {...state.notification}
            />

            <InventoryManagement loading={loading} state={state} callbacks={callbacks} />
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} />
        </ContextProvider>
    );

export default compose(withContext)(CarrierAccountingOrders);
