import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { PrimaryButton } from '@/styles/blocks';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import { css } from '@emotion/react';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getAuth, updateEmail } from 'firebase/auth';
import { captureException } from '@sentry/react';

const ProfileTab = ({ user, callbacks = {}, loading = false }) => {
    const [username, setUsername] = useState(user?.username || '');
    const [email, setEmail] = useState(user?.email || '');
    const [phone, setPhone] = useState(user?.corporate_admin?.phone || '');

    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [authLoading, setAuthLoading] = useState(false);

    useEffect(() => {
        if (user?.corporate_admin) {
            setUsername(user.username);
            setEmail(user.email);
            setPhone(user.corporate_admin.phone);
        }
    }, [user]);

    const onSave = () => {
        const formattedEmail = email.toLowerCase();

        setSuccess(false);
        setAuthLoading(true);

        updateEmail(getAuth().currentUser, formattedEmail)
            .then(() => {
                callbacks.saveUser({
                    variables: {
                        user_id: user.user_id,
                        user_update: {
                            username,
                            email: formattedEmail,
                        },
                        corporate_admin_update: {
                            username,
                            email: formattedEmail,
                            phone,
                        },
                    },
                    onError: (err) => {
                        captureException(err);
                        setError(`We're sorry, something went wrong`);
                    },
                    onCompleted: () => {
                        setSuccess(true);
                    },
                });
            })
            .catch((err) => {
                if (err?.message?.includes('auth/email-already-in-use')) {
                    setError('An account with this email already exists');
                } else {
                    console.error(err);
                    captureException(err);
                    setError(`We're sorry, something went wrong`);
                }
            })
            .finally(() => {
                setAuthLoading(false);
            });
    };

    return (
        <Grid
            container
            spacing={3}
            css={css`
                flex-direction: column;
                flex-wrap: nowrap;
            `}
        >
            {success && (
                <Alert severity="success">
                    <AlertTitle>Profile successfully updated!</AlertTitle>
                </Alert>
            )}

            {error && (
                <Alert severity="error">
                    <AlertTitle>{error}</AlertTitle>
                </Alert>
            )}

            <Grid item>
                <TextField
                    value={username}
                    variant="outlined"
                    label="Full Name"
                    onChange={(e) => setUsername(e.target.value)}
                    disabled={loading || authLoading}
                />
            </Grid>

            <Grid item>
                <TextField
                    value={email}
                    variant="outlined"
                    label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={loading || authLoading}
                />
            </Grid>

            <Grid item>
                <TextField
                    value={toNational(phone)}
                    variant="outlined"
                    label="Phone"
                    onChange={(e) => setPhone(toE164(e.target.value))}
                    InputProps={{
                        inputComponent: PhoneNumberInput,
                    }}
                    disabled={loading || authLoading}
                />
            </Grid>

            <Grid item>
                <PrimaryButton onClick={onSave} disabled={loading || authLoading}>
                    Save
                </PrimaryButton>
            </Grid>
        </Grid>
    );
};

export default ProfileTab;
