import React from 'react';
import { compose } from 'recompose';
import { AdminExceptions as ExceptionManager } from '@/components/admin/AdminExceptions';

import { Context, ContextProvider } from './store';

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} />
        </ContextProvider>
    );

export default compose(withContext)(ExceptionManager);
