import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { SUBMIT_ROUTE } from '@/constants/apiRoutes';
import { post } from '@/utilities/onwardClient';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
    Body1,
    Body2,
    GridItemRow,
    ModalActions,
    ModalContent,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
} from '../blocks';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { useAfterHours } from '../hooks';
import { captureException } from '@sentry/react';
import { useClientUser } from '@/hooks';

const RouteSubmitInternalModal = () => {
    const {
        modalOpen,
        setModalOpen,
        actionableRoute,
        setActionableRoute,
        setError,
        setNotification,
        callbacks: { refetch },
    } = useContext(PlanningContext);
    const [sendSMS, setSendSMS] = useState('yes');
    const [loading, setLoading] = useState(false);
    const { partner_carriers } = useClientUser();

    const afterHours = useAfterHours(actionableRoute);

    const hasCommingledOrders = useMemo(() => {
        return actionableRoute?.orders?.some(
            ({ order }) => order.shipper_id !== order.carrier_id && order.shipper_id !== actionableRoute.shipper_id
        );
    }, [actionableRoute]);

    const handleModalClose = () => {
        setLoading(false);
        setModalOpen(null);
        setActionableRoute(null);
    };

    const partneredCarrier = useMemo(() => {
        return partner_carriers
            ? partner_carriers.find((_carrier) => _carrier.client_id === actionableRoute?.carrier_id)
            : null;
    }, [actionableRoute, partner_carriers]);

    const missingPhoneNumbers = useMemo(() => {
        let ordersWithoutPhone = [];
        if (actionableRoute) {
            actionableRoute?.orders.forEach(({ type, order }) => {
                if (type !== 'PICKUP' && !order.dropoff_phone) {
                    ordersWithoutPhone.push(order.order_number);
                }
            });
        }
        if (ordersWithoutPhone.length > 0) {
            setSendSMS('no');
        }
        return ordersWithoutPhone;
    }, [actionableRoute]);

    const handleSubmitInternal = async () => {
        try {
            setLoading(true);

            const submitResponse = await post(SUBMIT_ROUTE, {
                routeId: actionableRoute.route_id,
                sendingTo: partneredCarrier ? 'partner' : 'internal',
                sendSMS: sendSMS === 'yes' || sendSMS === 'commingledOnly',
                commingledOnlySMS: sendSMS === 'commingledOnly',
            });

            if (submitResponse.status === 200) {
                setNotification({
                    severity: 'success',
                    message: `Successfully submitted Route ${actionableRoute.route_number} to ${
                        partneredCarrier?.business_name || 'Internal'
                    }!`,
                });
                refetch();
                handleModalClose();
            } else {
                captureException(
                    new Error(
                        `Error submitting route. ${
                            submitResponse?.data?.error || submitResponse?.data?.errorMessage
                        } Check function logs for more information.`
                    )
                );
                setNotification({
                    severity: 'error',
                    message:
                        submitResponse?.data?.errorMessage ||
                        'Error submitting route, please verify all stops have timeframes and try again',
                });
                setLoading(false);
            }
        } catch (error) {
            setError(error, 'Error submitting route, please try again');
            setLoading(false);
        }
    };

    if (!actionableRoute) return null;

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ROUTE_INTERNAL} onClose={handleModalClose}>
            <ModalTitle>{`Send Route ${actionableRoute.route_number} to Internal?`}</ModalTitle>
            <ModalContent>
                {afterHours && sendSMS === 'yes' && (
                    <GridItemRow>
                        <Alert severity="error">
                            {`You are about to send SMS messages to customers outside of regular business hours (8 AM - 5 PM)`}
                        </Alert>
                    </GridItemRow>
                )}
                <GridItemRow>
                    <Alert severity="warning">
                        Confirm all service times are correct. Customers will only receive reschedule texts if the time
                        frame changes by more than 30 minutes since the last text.
                    </Alert>
                </GridItemRow>
                <GridItemRow>
                    <Body2>Who should handle delivery timeframes?</Body2>
                </GridItemRow>
                <RadioGroup value={sendSMS} onChange={(e) => setSendSMS(e.target.value)}>
                    <GridItemRow
                        css={css`
                            margin-bottom: 10px !important;
                        `}
                    >
                        <FormControlLabel control={<Radio color="primary" />} value={'yes'} />
                        <Grid container>
                            <Body1>
                                Send route to your internal drivers and use Onward's system to contact customers to
                                confirm timeframes.
                            </Body1>
                            <Body2>
                                Onward will use automated text messages to relay timeframes and receive confirmations.
                            </Body2>
                        </Grid>
                    </GridItemRow>
                    <GridItemRow>
                        <FormControlLabel control={<Radio color="primary" />} value={'no'} />
                        <Grid container>
                            <Body1>
                                Send route to your internal drivers but do NOT use Onward's system to contact customers
                                to confirm timeframes.
                            </Body1>
                            <Body2>
                                Onward will NOT use automated text messages to relay timeframes and receive
                                confirmations.
                            </Body2>
                        </Grid>
                    </GridItemRow>
                    {hasCommingledOrders && (
                        <GridItemRow
                            css={css`
                                margin-top: 10px !important;
                            `}
                        >
                            <FormControlLabel control={<Radio color="primary" />} value={'commingledOnly'} />
                            <Grid container>
                                <Body1>
                                    Send route to your internal drivers and use Onward's system to ONLY contact
                                    customers with Onward Marketplace orders.
                                </Body1>
                                <Body2>
                                    Onward will use automated text messages to relay timeframes and receive
                                    confirmations, but only for customers for orders that were claimed from the Onward
                                    Marketplace.
                                </Body2>
                            </Grid>
                        </GridItemRow>
                    )}
                </RadioGroup>
                {missingPhoneNumbers.length > 0 && (
                    <Body2 style={{ color: 'red' }}>
                        Warning: The following orders are missing a customer phone number:{' '}
                        {missingPhoneNumbers.join(', ')}
                    </Body2>
                )}
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={handleSubmitInternal}>
                    Submit to{' '}
                    {partneredCarrier ? (
                        <span
                            css={css`
                                overflow: hidden;
                            `}
                        >
                            &nbsp;{partneredCarrier?.business_name}
                        </span>
                    ) : (
                        'Internal'
                    )}
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default RouteSubmitInternalModal;
