import React, { useMemo, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { css } from '@emotion/react';
import { SectionSubtitle2, GreyBody } from '@/styles/blocks';
import { useItemDynamicFields } from '@/components/ShipmentForm/hooks/items';
import {
    ItemType,
    ItemTypeDetails,
    BedSize,
    AssemblyType,
    ItemIsPackagedSelect,
    ItemPieceCountSelect,
} from '../../InputFields';
import { Body1, OnwardToggle, RadioLabelInfo, Incrementor, TextField } from '../../../blocks';
import { ITEM_LABELS_BY_FREIGHT } from '@/components/ShipmentForm/constants/freightTypes';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';

const ItemDetails = ({ order, item, index, callbacks, hasError, isDirty, startDirty }) => {
    useItemDynamicFields(item, (changes) => {
        callbacks.editItem(index, changes);
    });

    const isPallet = order.freight_type === 'pallet';
    const isContainer = order.freight_type === 'containers';
    const isBedItem = useMemo(() => {
        if (item?.item_type_details) {
            return ['mattress', 'boxSpring', 'bedFrame'].includes(item.item_type_details);
        }

        return false;
    }, [item]);

    const isWillCall = useMemo(() => {
        return ['WILL_CALL', 'PICKUP_AND_WILL_CALL'].includes(order.job_type);
    }, [order]);

    let config = (
        <>
            <Grid
                item
                container
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <Grid item xs={12} sm={6}>
                    <ItemType
                        disabled={isWillCall}
                        isWillCall={isWillCall}
                        value={item.is_return ? 'return' : 'delivery'}
                        onChange={(e) => {
                            callbacks.editItem(index, {
                                is_return: e.target.value === 'return',
                            });
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    type="number"
                    variant="outlined"
                    label="Quantity"
                    value={item.quantity}
                    fullWidth
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                        callbacks.editItem(index, {
                            quantity: Number(e.target.value),
                        });
                    }}
                    css={css`
                        margin-bottom: 20px;
                    `}
                />
            </Grid>
        </>
    );
    if (!isPallet && !isContainer) {
        config = (
            <>
                <Grid
                    item
                    container
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        css={css`
                            padding-right: 16px;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            color="primary"
                            label="SKU #"
                            value={item.sku}
                            onChange={(e) => callbacks.editItem(index, { sku: e.target.value })}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        css={css`
                            padding-right: 16px;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            color="primary"
                            label="Pallet ID"
                            value={item.pallet?.data?.pallet_name || item.pallet?.pallet_name}
                            onChange={(e) =>
                                callbacks.editPallet(index, item.pallet_id, { pallet_name: e.target.value || null })
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ItemType
                            value={item.is_return ? 'return' : 'delivery'}
                            onChange={(e) => {
                                callbacks.editItem(index, {
                                    is_return: e.target.value === 'return',
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        css={css`
                            padding-right: 16px;
                        `}
                    >
                        <ItemTypeDetails
                            helperText={'Required if sending to Onward'}
                            value={item.item_type_details}
                            freightType={order.freight_type}
                            onChange={(e) => {
                                callbacks.editItem(index, { item_type_details: e.target.value });
                                const toDirty = ['item_type_details'];
                                if (e.target.value !== 'mattress') {
                                    toDirty.concat(['length', 'width', 'height', 'weight', 'cubes_per_unit']);
                                }
                                callbacks.makeItemDirty(index, toDirty);
                            }}
                            error={hasError.item_type_details && (startDirty || isDirty.item_type_details)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        css={css`
                            padding-right: 16px;
                        `}
                    >
                        <TextField
                            type="number"
                            variant="outlined"
                            label="Quantity"
                            value={item.quantity}
                            fullWidth
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={(e) => {
                                callbacks.editItem(index, {
                                    quantity: Number(e.target.value),
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Service time per item (minutes)"
                            variant="outlined"
                            type="number"
                            fullWidth
                            value={Number.isFinite(item.service_time) ? item.service_time : undefined}
                            onChange={(e) => {
                                callbacks.editItem(index, {
                                    service_time: Number.isFinite(parseInt(e.target.value))
                                        ? parseInt(e.target.value, 10)
                                        : undefined,
                                });
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        css={css`
                            padding-right: 16px;
                        `}
                    >
                        {isBedItem && (
                            <BedSize
                                value={{ height: item.height, width: item.width, length: item.length }}
                                itemType={item.item_type_details}
                                onChange={({ height, length, weight, width }) => {
                                    callbacks.editItem(index, {
                                        height,
                                        length,
                                        weight,
                                        width,
                                    });
                                    callbacks.makeItemDirty(index, [
                                        'length',
                                        'width',
                                        'height',
                                        'weight',
                                        'cubes_per_unit',
                                    ]);
                                }}
                                error={
                                    isBedItem &&
                                    ['length', 'width', 'height'].some(
                                        (attr) => hasError[attr] && (startDirty || isDirty[attr])
                                    )
                                }
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        css={css`
                            padding-right: 16px;
                        `}
                    >
                        <ItemIsPackagedSelect
                            helperText={'Required if sending to Onward'}
                            value={item.is_packaged}
                            onChange={(e) => {
                                callbacks.editItem(index, { is_packaged: e.target.value });
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        css={css`
                            padding-right: 16px;
                        `}
                    >
                        <ItemPieceCountSelect
                            helperText={'Required if sending to Onward'}
                            value={item.item_piece_count}
                            onChange={(e) => {
                                callbacks.editItem(index, { item_piece_count: e.target.value });
                                callbacks.makeItemDirty(index, ['item_piece_count']);
                            }}
                            error={hasError.item_piece_count && (startDirty || isDirty.item_piece_count)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <AssemblyType
                            helperText={
                                order.assembly_required ? 'Required by Service Level' : 'Required if sending to Onward'
                            }
                            value={item.assembly_type}
                            onChange={(e) => {
                                callbacks.editItem(index, { assembly_type: e.target.value });
                                callbacks.makeItemDirty(index, ['assembly_type']);
                            }}
                            error={hasError.assembly_type && (startDirty || isDirty.assembly_type)}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }

    return (
        <Grid container direction="column">
            <Grid
                container
                item
                direction="row"
                css={css`
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                `}
            >
                <Grid
                    item
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <SectionSubtitle2>{`${ITEM_LABELS_BY_FREIGHT[order.freight_type] || 'Item'} ${
                        index + 1
                    }`}</SectionSubtitle2>
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<FileCopyIcon />}
                        onClick={() => callbacks.copyItem({ ...item })}
                        css={css`
                            margin-left: 16px;
                        `}
                    >
                        Clone Item
                    </Button>
                </Grid>
                <Grid item>
                    <IconButton
                        css={css`
                            padding: 4px;
                        `}
                    >
                        <CloseIcon
                            onClick={() => {
                                callbacks.deleteItem(index);
                            }}
                            css={css`
                                color: black;
                            `}
                        />
                    </IconButton>
                </Grid>
            </Grid>

            {config}

            <Grid
                item
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <TextField
                    label={isPallet ? 'Description of palletized item' : 'Description'}
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={item.description}
                    onChange={(e) => callbacks.editItem(index, { description: e.target.value })}
                />
            </Grid>
            <Grid
                item
                container
                direction="row"
                css={css`
                    margin-top: 12px;
                `}
            >
                <Grid
                    item
                    css={css`
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        label="Length (in)"
                        variant="outlined"
                        fullWidth
                        // disabled={isBedItem}
                        value={item.length}
                        error={hasError.length && (startDirty || isDirty.length)}
                        onBlur={() => callbacks.makeItemDirty(index, ['length'])}
                        onChange={(e) => callbacks.editItem(index, { length: parseInt(e.target.value, 10) || 0 })}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        label="Width (in)"
                        variant="outlined"
                        fullWidth
                        // disabled={isBedItem}
                        value={item.width}
                        error={hasError.width && (startDirty || isDirty.width)}
                        onBlur={() => callbacks.makeItemDirty(index, ['width'])}
                        onChange={(e) => callbacks.editItem(index, { width: parseInt(e.target.value, 10) || 0 })}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1;
                    `}
                >
                    <TextField
                        label="Height (in)"
                        variant="outlined"
                        fullWidth
                        // disabled={isBedItem}
                        value={item.height}
                        error={hasError.height && (startDirty || isDirty.height)}
                        onBlur={() => callbacks.makeItemDirty(index, ['height'])}
                        onChange={(e) => callbacks.editItem(index, { height: parseInt(e.target.value, 10) || 0 })}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    align-items: baseline;
                    margin-top: 12px;
                `}
            >
                <GreyBody
                    css={css`
                        margin: 4px 4px;
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.54);
                    `}
                >
                    Enter dimensions or cubic feet.
                </GreyBody>
            </Grid>
            <Grid
                item
                container
                direction="row"
                css={css`
                    margin-top: 12px;
                `}
            >
                <Grid
                    item
                    css={css`
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        label="Cubic Feet"
                        variant="outlined"
                        fullWidth
                        // disabled={isBedItem}
                        value={item.cubes_per_unit}
                        error={hasError.cubes_per_unit && (startDirty || isDirty.cubes_per_unit)}
                        onBlur={() => callbacks.makeItemDirty(index, ['cubes_per_unit'])}
                        onChange={(e) =>
                            callbacks.editItem(index, { cubes_per_unit: parseInt(e.target.value, 10) || 0 })
                        }
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        label="Weight (lb)"
                        variant="outlined"
                        fullWidth
                        // disabled={isBedItem}
                        value={item.weight}
                        error={hasError.weight && (startDirty || isDirty.weight)}
                        onBlur={() => callbacks.makeItemDirty(index, ['weight'])}
                        onChange={(e) => {
                            const str = `${e.target.value}`.replaceAll(/,|\s/g, '');
                            callbacks.editItem(index, { weight: parseInt(str, 10) || 0 });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1;
                    `}
                />
            </Grid>
        </Grid>
    );
};
export default ItemDetails;
