import { gql } from '@apollo/client';
import { WAREHOUSE_EVENT_FIELDS } from './order_wh_events';
import { EXCEPTION_FIELDS } from './db_exceptions';

export const ORDER_FIELDS = gql`
    ${WAREHOUSE_EVENT_FIELDS}
    ${EXCEPTION_FIELDS}

    fragment OrderFields on orders {
        admin_carrier_rate_override
        admin_shipper_rate_override
        airline
        apartment_number
        approved
        assembly_required
        auto_assign_failed
        billing_completion_time
        billing_completion_date_formatted @client
        bol_file
        bol_file_name
        broker_billed_rate
        broker_paid_rate
        business
        came_from_slumberland_api
        carrier
        carrier_change
        carrier_contact
        carrier_email
        carrier_id
        carrier_info
        carrier_net_terms
        carrier_invoice_id
        customer_rate
        shipper_notes
        carrier_phone
        carrier_price
        carrier_rate
        is_middle_mile
        charge_to
        chosen_store_name
        claimed_date
        claimed_date_formatted
        completion_date_formatted @client
        completion_time
        confirmed_date
        created_at
        cubic_feet
        custom_shape
        customer_budget
        customer_instructions
        customer_signature
        customer_signature_meta
        default_pickup_details_used
        del_elev
        del_end
        del_hour
        del_stairs
        del_window
        del_window_delay
        del_window_end
        del_window_start
        deliver_by
        delivery_date
        target_delivery_date
        target_delivery_date_formatted @client
        delivery_date_formatted @client
        delivery_photo
        delivery_photo_filename
        delivery_requests
        delivery_stair_quantity
        delivery_time_confirmed
        details
        dispatch_integration_meta
        distance
        driver_id
        dropoff_address_confirmed
        carrier_notes
        dropoff_address
        dropoff_address_confirmed
        dropoff_address_geocode_failed
        dropoff_address_is_custom
        dropoff_check
        dropoff_city
        dropoff_comments
        dropoff_contact_same_as_pickup
        dropoff_contact_same_as_sender
        dropoff_date
        dropoff_elevator
        dropoff_email
        dropoff_error
        dropoff_floor
        dropoff_full_address
        dropoff_lat
        dropoff_lng
        dropoff_location
        dropoff_location_info
        dropoff_location_type
        dropoff_name
        dropoff_phone
        dropoff_point
        dropoff_secondary_contact
        dropoff_secondary_phone
        dropoff_state
        dropoff_street
        dropoff_street_address
        dropoff_unit
        dropoff_zip
        duration
        duration_seconds
        email
        equipment_overrides
        estimated_del_window
        estimated_del_window_string
        estimated_delivery_range_start
        estimated_delivery_range_end
        estimated_delivery_range_start_formatted @client
        estimated_delivery_range_end_formatted @client
        event_state
        finalize_display
        first_available_date
        first_available_date_formatted @client
        footboard
        footboard_count
        form_type
        formatted_timestamp
        freight_type
        full_truck
        furniture
        furniture_count
        gate_code
        has_middle_mile_origin
        has_secondary_contact
        haul_fridge
        haulaway
        haulaway_check
        haulaway_elevator
        haulaway_items
        haulaway_items_changed
        haulaway_options_changed
        haulaway_stair_quantity
        haulaway_stairs
        headboard
        headboard_count
        invoice_number
        item_count
        item_description
        item_display
        item_error
        item_type
        item_type_details
        item_type_display
        items_changed
        items_total_cubes
        items_total_quantity
        items_total_weight
        job_id
        job_type
        known_availability
        last_modified_at
        last_modified_by
        last_modified_formatted
        last_modified_source
        last_update_source
        legacy_carrier_id
        legacy_order_id
        legacy_shipper_id
        loading
        ltl_dropoff_arrival
        ltl_pickup_completed
        manual_scheduled_delivery
        manufacturer
        margin
        mark_for_reschedule
        middle_mile_is_direct
        middle_mile_integration_meta
        middle_mile_integration_status
        middle_mile_origin_address
        middle_mile_origin_unit
        middle_mile_origin_city
        middle_mile_origin_state
        middle_mile_origin_zip
        middle_mile_origin_name
        middle_mile_origin_email
        middle_mile_origin_phone
        middle_mile_origin_location
        middle_mile_origin_location_info
        middle_mile_origin_location_type
        miles
        name
        new_pod
        no_contact
        oms
        onward_fees
        onward_message
        onward_notes
        order_change
        order_date
        order_id
        order_number
        order_revenue
        order_revenue_adjustments
        order_revenue_override
        order_status
        order_type
        original_carrier_rate
        original_del_window_end
        original_del_window_start
        original_shipper_rate
        page
        pallet_count
        pallet_debris_removal_notes
        pallet_display
        pallet_expedite
        pallet_flatbed_truck
        pallet_forklift_at_dropoff
        pallet_forklift_at_pickup
        pallet_overweight
        pallet_unloading_required
        pallet_warehouse_close_time
        palletize_returns
        palletized_dimensions
        pay_out_status
        pay_status
        pay_type
        payment_id
        payment_status
        payment_type
        phone
        pickup_address
        pickup_address_geocode_failed
        pickup_address_is_custom
        pickup_city
        pickup_comments
        pickup_date
        pickup_date_formatted @client
        pickup_elevator
        pickup_email
        pickup_end
        pickup_error
        pickup_full_address
        pickup_hour
        pickup_lat
        pickup_lng
        pickup_location
        pickup_location_info
        pickup_location_type
        pickup_name
        pickup_phone
        pickup_point
        pickup_planning
        pickup_scheduling_required
        pickup_secondary_contact
        pickup_stair_quantity
        pickup_stairs
        pickup_state
        pickup_street
        pickup_street_address
        pickup_unit
        pickup_window_end
        pickup_window_start
        pickup_zip
        piece_count_verified
        planning
        po_duplicate_override
        po_number
        pod
        pod_file_name
        posted_date
        posted_date_formatted
        preferred_delivery_date
        preferred_delivery_date_formatted @client
        previous_order_status
        price_breakdown
        proof_of_pickup
        invoice_class
        invoice_notes
        qbo_tag
        ready_to_invoice
        recommended_equipment
        reference_id
        residence_type
        return
        returns_total_cubes
        returns_total_quantity
        returns_total_weight
        scheduled_delivery
        secondary_dropoff_contact_email
        secondary_dropoff_contact_name
        secondary_dropoff_contact_phone
        secondary_email
        secondary_name
        secondary_phone
        secondary_pickup_contact_email
        secondary_pickup_contact_name
        secondary_pickup_contact_phone
        selected_type
        send_sms
        sending_to
        service_id
        shipper_id
        shipper_invoice_id
        shipper_rate
        show_carrier_new_shipment_info_modal
        show_modal
        show_revenue_on
        show_shipper_new_shipment_info_modal
        source_form
        special_pallet_info
        stairs_flights
        submit_error
        submit_failure
        template_name
        test_acc
        timestamp_checks
        total_haulaway_items
        twilio_sid
        alternative_delivery_dates
        alternative_delivery_dates_formatted @client
        validation
        warehouse_control_entity
        warehouse_file
        warehouse_delivery_status
        warehouse_estimated_ship_date
        warehouse_status
        wh_driver_id
        exceptions {
            created_at
            exception_type
            event_id
            exception {
                ...ExceptionFields
            }
        }
        order_exception @client {
            ...ExceptionFields
        }
        wh_events(order_by: [{ created_at: asc }, { transition: { current: asc } }]) {
            ...WarehouseEvents
        }
    }
`;

export const ORDER_ROUTE_FIELDS = gql`
    fragment OrderRouteFields on orders {
        routes {
            mapping_id
            order_id
            route_id
            type
        }
    }
`;

export const FULL_QUERY = gql`
    ${ORDER_FIELDS}
    query GetOrders {
        orders(limit: 100) {
            ...OrderFields
        }
    }
`;

export const QUERY_BY_ID = gql`
    ${ORDER_FIELDS}

    query orders($order_id: uuid) {
        orders(limit: 100, where: { order_id: { _eq: $order_id } }) {
            ...OrderFields
        }
    }
`;

export const QUERY_BY_STATE = gql`
    query orders($created_at: timestamptz) {
        orders(distinct_on: dropoff_state, where: { test_acc: { _eq: false }, created_at: { _gte: $created_at } }) {
            dropoff_state
            order_number
            order_id
            created_at
        }
    }
`;
