import React, { useMemo, useContext, useState } from 'react';
import { captureException } from '@sentry/react';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { MODALS } from '../constants';
import { ModalActions, ModalContent, ModalTitle, PrimaryButton } from '../blocks';
import { useClientUser } from '@/hooks';
import { PlanningContext } from '../context';
import { useQuery } from '@apollo/client';
import { QUERY_STOP_MESSAGES } from '../graphql/queries';
import { post } from '@/utilities/onwardClient';
import { RETRY_STOP } from '@/constants/apiRoutes';
import CommunicationLog from '@/components/CommunicationLog';

export default function CommunicationModal() {
    const {
        modalOpen,
        setModalOpen,
        setNotification,
        actionableRoute,
        actionableStop,
        setActionableRoute,
        setActionableStop,
        setError,
        callbacks: { refetch },
    } = useContext(PlanningContext);

    const [loading, setLoading] = useState(false);

    const stopOrders = useMemo(() => {
        const ordersByKey = Object.fromEntries(
            (actionableRoute?.orders || []).map((mapping) => [mapping.order_id, mapping.order])
        );
        return (actionableStop?.orders || []).map((order_id) => ordersByKey[order_id]);
    }, [actionableRoute, actionableStop]);

    const { loading: queryLoading, data } = useQuery(QUERY_STOP_MESSAGES, {
        variables: {
            order_ids: stopOrders.map((order) => order.order_id),
        },
        skip: !stopOrders.length,
    });

    const messages = useMemo(() => {
        // copy into new array since data.messages is read only and cant be sorted
        return (data?.messages ? [...data.messages] : []).sort((a, b) => {
            if (a.timestamp === b.timestamp) {
                return a.source > b.source ? 1 : -1;
            }

            return new Date(a.timestamp) - new Date(b.timestamp);
        });
    }, [data]);

    const handleModalClose = () => {
        setLoading(false);
        setModalOpen(null);
        setActionableStop(null);
        setActionableRoute(null);
    };

    const handleRetryClick = async () => {
        try {
            setLoading(true);

            const retryResponse = await post(RETRY_STOP, {
                routeId: actionableRoute.route_id,
                stopId: actionableStop.stop_id,
            });

            if (retryResponse?.status === 200) {
                setNotification({
                    severity: 'success',
                    message: `Successfully sent timeframe confirmation text to ${stopOrders
                        .map((order) => order.dropoff_name)
                        .join(', ')}`,
                });
                refetch();
                handleModalClose();
            } else {
                captureException(
                    new Error(
                        `Error sending timeframe text. ${
                            retryResponse?.data?.error || retryResponse?.data?.errorMessage
                        } Check function logs for more information.`
                    )
                );
                setNotification({
                    severity: 'error',
                    message:
                        retryResponse?.data?.errorMessage ||
                        'Error sending timeframe text, please verify stop and try again.',
                });
                setLoading(false);
            }
        } catch (error) {
            setError(error, 'Error sending timeframe text, please verify stop and try again.');
            setLoading(false);
        }
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.COMMUNICATIONS} onClose={handleModalClose}>
            <ModalTitle>Communications Log</ModalTitle>

            <ModalContent>
                <CommunicationLog
                    customerName={stopOrders[0]?.dropoff_name}
                    customerPhone={stopOrders[0]?.dropoff_phone}
                    customerZip={stopOrders[0]?.dropoff_zip}
                    loading={queryLoading || loading}
                    messages={messages}
                />
            </ModalContent>

            <ModalActions>
                <PrimaryButton disabled={queryLoading || loading} onClick={() => handleRetryClick()}>
                    Send Timeframe SMS
                </PrimaryButton>
                <PrimaryButton disabled={queryLoading || loading} onClick={handleModalClose}>
                    Done
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
}
