import { colors } from '@/styles';
import styled from '@emotion/styled';

export const PlainCard = styled.div`
    display: flex;
    padding: 1rem;
    width: 100%;
    justify-content: center;
`;

export const CenteredCard = styled.div`
    display: flex;
    padding: 1rem;
    width: 100%;
    justify-content: center;
`;

export const ListCard = styled((props) => <div {...props} />)`
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid ${colors.greys.border};
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    ${(props) => (props.clickable ? 'cursor: pointer;' : '')}
    ${(props) => (props.selected ? `background-color: ${colors.greys.selected};` : '')}
`;

export const CardHeaderLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CardHeaderText = styled.span`
    font-size: 16px;
    font-weight: 800;
`;

export const CardLine = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const IndentedCardLine = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
`;

export const CardText = styled.span`
    font-size: 14px;
    font-weight: 500;
`;
