import { getAuth, updatePassword } from 'firebase/auth';

import React, { useState, useContext } from 'react';
import { Row, Container, Card, Form } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { UserContext } from '@/components/App';

import * as Sentry from '@sentry/react';
function Password(props) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const { superUser } = useContext(UserContext);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    const submitPassword = (event) => {
        if (password !== confirmPassword) {
            return setMessage('Passwords must match');
        } else if (Object.keys(superUser).length) {
            return setMessage('Action cancelled: Admins should not modify user passwords');
        }

        const auth = getAuth();
        const user = auth.currentUser;

        updatePassword(user, password)
            .then(() => {
                setPassword('');
                setConfirmPassword('');
                setMessage('Password Updated!');
            })
            .catch((error) => {
                let errMess = error.message.split(': ');
                setMessage("Error: " + errMess[1]);
                Sentry.captureException(error);
            });

        event.preventDefault();
    };

    return (
        <Card className="tabCard">
            <ThemeProvider theme={theme}>
                <Card.Body className="">
                    <Container fluid>
                        <Form>
                            <Row className="d-flex flex-column mt-3">
                                <Form.Group>
                                    <h5 className="mb-1">Update Password</h5>
                                </Form.Group>
                            </Row>
                            <Row className="d-flex flex-column mt-3">
                                <Form.Group>
                                    <Form.Control
                                        name="passwordOne"
                                        type="password"
                                        className="password-height"
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        style={{ width: '250px' }}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="d-flex flex-column my-1">
                                <Form.Group>
                                    <Form.Control
                                        name="passwordTwo"
                                        type="password"
                                        className="password-height"
                                        placeholder="Confirm Password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        value={confirmPassword}
                                        style={{ width: '250px' }}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="d-flex flex-column my-1">
                                <Form.Group>
                                    <Button
                                        onClick={submitPassword}
                                        className="my-1 account-btn"
                                        style={{ width: '250px' }}
                                    >
                                        Update
                                    </Button>
                                </Form.Group>
                            </Row>
                            {message && <div>{message}</div>}
                        </Form>
                    </Container>
                </Card.Body>
            </ThemeProvider>
        </Card>
    );
}

export default Password;
