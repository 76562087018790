import { CircularProgress, Grid, Popover, StylesProvider, Tooltip } from '@material-ui/core';
import { StarsOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import styled from '@emotion/styled';

const controlContainer = document.createElement('div');
const id = 'recommendations-control-container';
controlContainer.setAttribute('id', id);
const containerRoot = createRoot(controlContainer);

const RecLabel = styled.span`
    font-size: 12px;
    font-weight: 600;
`;

const RecTitle = styled.span`
    font-size: 14px;
    font-weight: 700;
`;

const Text = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

const RecommendationCard = ({ recommendation, idx, ...rest }) => {
    const idxLabels = ['Highest Recommendation', '2nd Recommendation', '3rd Recommendation'];
    const defaultLabel = 'Recommendation';

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                padding: 1rem;
                border-top: 1px solid ${colors.greys.border};
                border-bottom: 1px solid ${colors.greys.border};
                cursor: pointer;
                :hover {
                    background-color: ${colors.greys.selected};
                }
            `}
            {...rest}
        >
            <RecLabel
                css={css`
                    color: ${idx === 0 ? colors.greens.primary : colors.greys.primary};
                `}
            >
                {idxLabels[idx] || defaultLabel}
            </RecLabel>
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <RecTitle>{recommendation.order.order_number}</RecTitle>
                <RecTitle
                    css={css`
                        color: ${colors.greens.primary};
                    `}
                >
                    Rate: ${recommendation.rate?.toFixed(0)}
                </RecTitle>
            </div>
            <Text>Additional mileage: {recommendation.min_detour.toFixed(0)} mi</Text>
            <Text>{recommendation.cubes?.toFixed(0) || '-'} cubic ft.</Text>
        </div>
    );
};

const RecommendationsControlContent = ({ loading, recommendations, callbacks }) => {
    const [anchor, setAnchor] = useState(null);
    const popoverId = 'recommendations-popover';

    return (
        <StylesProvider injectFirst>
            <div
                css={css`
                    margin: 10px;
                    width: 40px;
                    height: 40px;
                    background-color: ${colors.greens.primary};
                    color: ${colors.white};
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                `}
                aria-owns={anchor ? popoverId : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                    setAnchor(e.currentTarget);
                    callbacks.getRecommendations();
                }}
            >
                <Tooltip title="Order Recommendations" placement="top">
                    <StarsOutlined />
                </Tooltip>
            </div>
            <Popover
                id={popoverId}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div
                    css={css`
                        width: 340px;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                    `}
                >
                    {loading && (
                        <div
                            css={css`
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                min-height: 120px;
                                margin: 1rem 0;
                            `}
                        >
                            <CircularProgress
                                size={100}
                                css={css`
                                    color: ${colors.greens.primary};
                                `}
                            />
                        </div>
                    )}
                    {!loading && !recommendations?.length && <span>No order recommendations found</span>}
                    {!loading && recommendations?.length > 0 && (
                        <>
                            <div
                                css={css`
                                    padding: 1rem;
                                `}
                            >
                                <RecTitle>Onward Recommendations</RecTitle>
                            </div>
                            {recommendations.map((rec, idx) => (
                                <RecommendationCard
                                    recommendation={rec}
                                    key={`rec-${rec.order.order_id}`}
                                    idx={idx}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        callbacks.selectRecommendation(rec.order.order_id);
                                    }}
                                />
                            ))}
                        </>
                    )}
                </div>
            </Popover>
        </StylesProvider>
    );
};

export const RecommendationsControl = ({ mapRef, ...props }) => {
    useEffect(() => {
        if (mapRef?.current) {
            const controls = mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP];
            controls.push(controlContainer);

            return () => {
                const idx = controls.indexOf((el) => el.id === id);
                if (idx >= 0) {
                    controls.removeAt(idx);
                }
            };
        }
    }, [mapRef?.current]);

    useEffect(() => {
        containerRoot.render(<RecommendationsControlContent {...props} />);
    }, [mapRef?.current, props]);

    return null;
};
