import React, { useState, useCallback, useMemo } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { css } from '@emotion/react';
import { genAttributes } from '@onward-delivery/core';
import { OnwardSwitch, SecondaryButton } from '@/styles/blocks';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { ResponsiveSidebarDialog, StickyModalActions, ModalContent, ModalTitle, PrimaryButton } from '@/styles/blocks';

import { Header2, Header1, DateTimePicker, DateTimeRangePicker, Body1, Tag } from '../Crossdocking/blocks';

const REQUIRED_FIELDS = {
    WAREHOUSE: ['est_ship_date', 'est_received_date', 'wh_driver_id', 'location_id'],
    CUSTOMER: ['est_dropoff_date_start', 'est_dropoff_date_end', 'est_pickup_date', 'driver_id'],
};

const CrossdockConfigModal = ({ event, order, drivers, action, warehouses, stage, callbacks }) => {
    const { zip } = genAttributes(order);
    const [stageOverride, setCurrentStage] = useState(stage || 'WAREHOUSE');
    const [updates, setUpdates] = useState({});
    const [sendConfirm, setSendConfirm] = useState(false);

    const currentStage = useMemo(() => {
        return stageOverride || stage || 'WAREHOUSE';
    }, [stageOverride, stage]);

    const defaults = useMemo(() => {
        return {
            est_pickup_date: order.preferred_delivery_date,
            est_dropoff_date_start: order.del_window_start || order.preferred_delivery_date,
            est_dropoff_date_end: order.del_window_end || order.preferred_delivery_date,
        };
    }, [order]);

    const updated = useMemo(() => {
        return {
            ...defaults,
            ...(event || {}),
            ...updates,
        };
    }, [updates, event, defaults]);

    const tz = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : 'America/New_York';
    }, [order, zip]);

    const isComplete = useMemo(() => {
        return !REQUIRED_FIELDS[currentStage].some((field) => {
            return !updated[field] || updated[field].length === 0;
        });
    }, [updated, currentStage]);

    const onClose = () => {
        setCurrentStage(null);
        callbacks.onClose();
    };

    const onRevert = useCallback(() => {
        if (currentStage === 'CUSTOMER') {
            setCurrentStage('WAREHOUSE');
        } else {
            setCurrentStage(null);
            callbacks.onClose();
        }
    }, [currentStage, callbacks]);

    const onAdvance = useCallback(() => {
        if (currentStage === 'WAREHOUSE') {
            setCurrentStage('CUSTOMER');
        } else {
            const adjustTZ = (ts) => {
                const utc = new Date(asUTCDate(ts).setUTCHours(0, 0, 0, 0)).toISOString();
                return asDateInTZ(utc, tz).toISOString();
            };

            const { est_dropoff_date_start, est_dropoff_date_end, ...rest } = { ...defaults, ...updates };
            let rv = rest;

            if (event.event_id) {
                rv = { ...rv, event_id: event.event_id, order_id: order.order_id, action: event.action };
            } else {
                rv = { ...rv, order_id: order.order_id, action };
            }

            setUpdates({});

            callbacks.onSubmit({
                event: rv,
                order: {
                    ...(rv.est_pickup_date ? { pickup_date: adjustTZ(rv.est_pickup_date) } : {}),
                    ...(rv.driver_id ? { driver_id: rv.driver_id } : {}),
                    ...(rv.wh_driver_id ? { wh_driver_id: rv.wh_driver_id } : {}),
                    ...(est_dropoff_date_start
                        ? {
                              del_window_start: est_dropoff_date_start,
                              delivery_date: adjustTZ(est_dropoff_date_start),
                          }
                        : {}),
                    ...(est_dropoff_date_end ? { del_window_end: est_dropoff_date_end } : {}),
                },
                sendConfirm: sendConfirm,
            });
        }
    }, [currentStage, updates, updated, event, callbacks, tz, defaults]);

    let content;
    switch (currentStage) {
        case 'WAREHOUSE': {
            content = (
                <Grid direction="row" container>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 12px;
                            `}
                        >
                            <Header1>Pickup</Header1>
                        </Grid>
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 20px;
                            `}
                        >
                            <DateTimePicker
                                tz={tz}
                                timestamp={updated?.est_ship_date}
                                onChange={(date) => {
                                    const corrected = date.toISOString();

                                    setUpdates((prev) => ({
                                        ...prev,
                                        est_ship_date: corrected,
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid direction="row" container>
                            <TextField
                                select
                                variant="outlined"
                                label="Driver"
                                value={updated?.wh_driver_id || ''}
                                onChange={(e) => {
                                    setUpdates((prev) => ({
                                        ...prev,
                                        wh_driver_id: e.target.value,
                                    }));
                                }}
                                fullWidth
                            >
                                {drivers?.map((option) => (
                                    <MenuItem key={option.teammate_id} value={option.teammate_id}>
                                        {option.username}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            justify-content: center;
                            margin: 0 20px;
                        `}
                    >
                        <ArrowForwardIcon />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 12px;
                            `}
                        >
                            <Header1>Warehouse Dropoff</Header1>
                        </Grid>
                        <Grid
                            direction="row"
                            container
                            css={css`
                                margin-bottom: 20px;
                            `}
                        >
                            <DateTimePicker
                                tz={tz}
                                disableDate={!updated?.est_ship_date}
                                timestamp={updated?.est_received_date}
                                onChange={(date) => {
                                    const corrected = date.toISOString();

                                    setUpdates((prev) => ({
                                        ...prev,
                                        est_received_date: corrected,
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid direction="row" container>
                            <TextField
                                select
                                variant="outlined"
                                label="Warehouse Dropoff"
                                value={updated?.location_id || ''}
                                onChange={(e) => {
                                    setUpdates((prev) => ({
                                        ...prev,
                                        location_id: e.target.value,
                                    }));
                                }}
                                fullWidth
                            >
                                {warehouses?.map((option) => (
                                    <MenuItem key={option.location_id} value={option.location_id}>
                                        {`${option.location_name} - ${option.business_address || option.address}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            );

            break;
        }
        case 'CUSTOMER': {
            content = (
                <>
                    <Grid direction="row" container>
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                            `}
                        >
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                <Header1>Warehouse Pickup</Header1>
                            </Grid>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 20px;
                                `}
                            >
                                <DateTimePicker
                                    tz={tz}
                                    timestamp={updated?.est_pickup_date}
                                    onChange={(date) => {
                                        const corrected = date.toISOString();

                                        setUpdates((prev) => ({
                                            ...prev,
                                            est_pickup_date: corrected,
                                        }));
                                    }}
                                />
                            </Grid>
                            <Grid direction="row" container>
                                <TextField
                                    select
                                    variant="outlined"
                                    label="Driver"
                                    value={updated?.driver_id || ''}
                                    onChange={(e) => {
                                        setUpdates((prev) => ({
                                            ...prev,
                                            driver_id: e.target.value,
                                        }));
                                    }}
                                    fullWidth
                                >
                                    {drivers?.map((option) => (
                                        <MenuItem key={option.teammate_id} value={option.teammate_id}>
                                            {option.username}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 0;
                                flex-basis: 0;
                                justify-content: center;
                                margin: 0 20px;
                            `}
                        >
                            <ArrowForwardIcon />
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                            `}
                        >
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                <Header1>Customer Dropoff</Header1>
                            </Grid>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    flex-wrap: nowrap;
                                `}
                            >
                                <DateTimeRangePicker
                                    tz={tz}
                                    range={[updated?.est_dropoff_date_start, updated?.est_dropoff_date_end]}
                                    onChange={([start, end]) => {
                                        setUpdates((prev) => ({
                                            ...prev,
                                            est_dropoff_date_start: start.toISOString(),
                                            est_dropoff_date_end: end.toISOString(),
                                        }));
                                    }}
                                />
                            </Grid>
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    justify-content: flex-start;
                                `}
                            >
                                <OnwardSwitch
                                    label={<Body1>Send customer text to confirm drop off date & time</Body1>}
                                    checked={sendConfirm}
                                    onChange={() => setSendConfirm((prev) => !prev)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid direction="row" container>
                        <Tag
                            css={css`
                                width: 700px;
                                margin-top: 20px;
                            `}
                        >
                            <Body1>{`Customer Preferred Delivery Date: ${
                                order.preferred_delivery_date_formatted || 'N/A'
                            }`}</Body1>
                            <br />
                            <Body1>{`Customer Alternative Delivery Dates: ${
                                order.alternative_delivery_dates
                                    ? order.alternative_delivery_dates_formatted.join(', ')
                                    : 'N/A'
                            }`}</Body1>
                        </Tag>
                    </Grid>
                </>
            );

            break;
        }
    }

    return (
        <ResponsiveSidebarDialog open={!!event} onClose={onClose}>
            <ModalTitle onClose={onClose}>
                <Header2
                    css={css`
                        color: #4c4c4c;
                    `}
                >
                    {currentStage === 'WAREHOUSE'
                        ? 'Crossdock - Pickup to Warehouse'
                        : 'Crossdock - Warehouse to Customer'}
                </Header2>
            </ModalTitle>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                {content}
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={onRevert}>
                        {currentStage === 'WAREHOUSE' ? 'Cancel' : 'Back'}
                    </SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton disabled={!isComplete} onClick={onAdvance}>
                        {currentStage === 'WAREHOUSE' ? 'Next' : 'Done'}
                    </PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default CrossdockConfigModal;
