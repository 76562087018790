import React, { useContext, useState } from 'react';
import { compose } from 'recompose';
import { asBrowserDate } from '@/utilities/convertToISO';
import OrderPODModal from '@/components/CarrierAccountingOrders/modals/OrderPODModal';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '@/components/Breadcrumbs';
import { TextField, Grid, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PrimaryButton } from '@/styles/blocks';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import { INVOICE_STATUS_DISPLAY_MAPPINGS } from './constants';
import Table from './Table';
import Totals from './Totals';
import { Context, ContextProvider } from './store';
import { H1, H2, Body1, Body2, StatusBadge } from './blocks';

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const Stat = ({ label, value, ...rest }) => {
    return (
        <Grid container direction="row" {...rest}>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: content;
                    justify-content: center;
                    margin-right: 12px;
                `}
            >
                <Body1>{label}</Body1>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: content;
                    justify-content: center;
                `}
            >
                <Body1>{value}</Body1>
            </Grid>
        </Grid>
    );
};

export const Invoice = ({ context }) => {
    const { state, loading, callbacks } = useContext(context);

    return (
        <>
            <Grid
                direction="column"
                container
                css={css`
                    width: 100%;
                    height: 100%;
                `}
            >
                <Grid
                    direction="row"
                    container
                    css={css`
                        align-items: center;
                        background: white;
                        padding: 12px 30px;
                        box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
                        border-bottom: 1px solid #ececec;
                    `}
                >
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Grid
                            direction="row"
                            container
                            css={css`
                                align-items: center;
                            `}
                        >
                            <OnwardBreadcrumbInactive disabled>Invoices</OnwardBreadcrumbInactive>
                            <OnwardBreadcrumbSpacer />
                            <OnwardBreadcrumbActive disabled>
                                {state?.isPending
                                    ? 'Pending'
                                    : state?.isCurrent
                                    ? 'Current'
                                    : state?.invoice?.invoice_number}
                            </OnwardBreadcrumbActive>
                        </Grid>
                    </Grid>
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <PrimaryButton
                            css={css`
                                height: 40px;
                                align-self: flex-end;
                                justify-content: center;
                                text-align: center;
                            `}
                            onClick={callbacks.exportCsv}
                            loading={loading.export}
                        >
                            Export
                        </PrimaryButton>
                    </Grid>
                    {state.perspective === 'carrier' && !state?.isOnwardAdmin ? (
                        <Grid
                            direction="column"
                            container
                            css={css`
                                margin-left: 12px;
                                flex: 0;
                                flex-basis: 0;
                            `}
                        >
                            <PrimaryButton
                                css={css`
                                    height: 40px;
                                    align-self: flex-end;
                                    justify-content: center;
                                    text-align: center;
                                `}
                                onClick={() => callbacks.submit(state.invoice)}
                                loading={loading.submit}
                                disabled={!state.isPending}
                            >
                                Submit
                            </PrimaryButton>
                        </Grid>
                    ) : null}
                    {state?.isOnwardAdmin ? (
                        <Grid
                            direction="column"
                            container
                            css={css`
                                margin-left: 12px;
                                flex: 0;
                                flex-basis: 0;
                            `}
                        >
                            {state?.invoice?.status === 'UNPAID' && (
                                <PrimaryButton
                                    css={css`
                                        height: 40px;
                                        align-self: flex-end;
                                        justify-content: center;
                                        text-align: center;
                                    `}
                                    onClick={() => callbacks.setApproved()}
                                    loading={loading.invoiceStatusInflight}
                                >
                                    Approve
                                </PrimaryButton>
                            )}
                            {state?.invoice?.status === 'APPROVED' && (
                                <PrimaryButton
                                    css={css`
                                        height: 40px;
                                        align-self: flex-end;
                                        justify-content: center;
                                        text-align: center;
                                    `}
                                    onClick={() => callbacks.setPaid()}
                                    loading={loading.invoiceStatusInflight}
                                >
                                    Mark as Paid
                                </PrimaryButton>
                            )}
                            {state?.invoice?.status === 'PAID' && (
                                <PrimaryButton
                                    css={css`
                                        height: 40px;
                                        align-self: flex-end;
                                        justify-content: center;
                                        text-align: center;
                                    `}
                                    onClick={() => callbacks.submit(state.invoice)}
                                    loading={loading.submit}
                                    disabled
                                >
                                    Mark as Paid
                                </PrimaryButton>
                            )}
                        </Grid>
                    ) : null}
                </Grid>

                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        padding: 0 40px;
                        margin-bottom: 30px;
                        margin-top: 30px;
                    `}
                >
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            justify-content: center;
                        `}
                    >
                        <Grid container item direction="row">
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 0;
                                    flex-basis: content;
                                    justify-content: center;
                                    margin-right: 12px;
                                `}
                            >
                                <H1>
                                    {state.isPending || state.isCurrent
                                        ? 'Invoice --'
                                        : `Invoice ${state.invoice.invoice_number}`}
                                </H1>
                            </Grid>
                            {state.isPending ? (
                                <Grid
                                    direction="column"
                                    container
                                    css={css`
                                        flex: 0;
                                        flex-basis: content;
                                        justify-content: center;
                                    `}
                                >
                                    <Body1
                                        css={css`
                                            color: #d23e3e;
                                        `}
                                    >{`Edits due by ${
                                        state.editsBy ? dateNumeric.format(state.editsBy) : '--'
                                    }`}</Body1>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>

                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            justify-content: center;
                        `}
                    >
                        <StatusBadge error={state?.invoice?.status !== 'PAID' && state?.invoice?.status !== 'APPROVED'}>
                            <H2
                                css={css`
                                    white-space: nowrap;
                                `}
                            >
                                {INVOICE_STATUS_DISPLAY_MAPPINGS[state?.invoice?.status] || '--'}
                            </H2>
                        </StatusBadge>
                    </Grid>
                </Grid>

                {state.missingPOD ? (
                    <Grid
                        container
                        direction="row"
                        css={css`
                            padding: 0 40px;
                            margin-bottom: 20px;
                        `}
                    >
                        <Grid
                            container
                            item
                            css={css`
                                padding: 18px 24px;
                                border-radius: 5px;
                                background: #d23e3e;
                            `}
                        >
                            <Body2
                                css={css`
                                    color: ${colors.white.primary};
                                `}
                            >
                                Invoices with missing PODs will not be paid until resolved
                            </Body2>
                        </Grid>
                    </Grid>
                ) : null}

                <Grid
                    direction="row"
                    container
                    css={css`
                        padding: 0 40px;
                        margin-bottom: 20px;
                    `}
                >
                    <Accordion
                        css={css`
                            width: 100%;
                        `}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <H2>Billing Comments ({state.invoiceComments?.length || 0})</H2>
                        </AccordionSummary>
                        <AccordionDetails
                            css={css`
                                display: flex;
                                flex-direction: column;
                                padding: 0 1rem 0rem 1rem;
                                margin-bottom: 0px;
                                justify-content: space-between;
                            `}
                        >
                            {(state?.invoiceComments || [])?.map((comment, i) => (
                                <Grid
                                    container
                                    direction="row"
                                    key={i}
                                    style={{
                                        border: '1px solid #ddd',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            width: 100%;
                                        `}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            css={css`
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <Body1
                                                css={css`
                                                    font-size: 12px;
                                                    color: #4c4c4c;
                                                `}
                                            >
                                                {comment.created_by} - {new Date(comment.created_at).toLocaleString()}
                                            </Body1>
                                        </Grid>
                                        <Grid container direction="row">
                                            <Body1>{comment.comment}</Body1>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <TextField
                                multiline
                                charLimit={200}
                                maxRows={2}
                                value={state.newComment || ''}
                                css={css`
                                    width: 100%;
                                    height: 100px;
                                    justify-content: center;
                                `}
                                variant="outlined"
                                placeholder="Add a comment..."
                                onChange={(e) => {
                                    callbacks.setNewComment(e.target.value);
                                }}
                            />
                            <PrimaryButton
                                css={css`
                                    margin-bottom: 10px;
                                    height: 40px;
                                    align-self: flex-end;
                                    justify-content: center;
                                    text-align: center;
                                `}
                                disabled={state?.updateInFlight || !state?.newComment || !state?.clientID}
                                onClick={() => {
                                    callbacks.saveComments();
                                }}
                            >
                                Submit
                            </PrimaryButton>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {!(state.isPending || state.isCurrent) ? (
                    <Grid
                        container
                        direction="row"
                        css={css`
                            padding: 0 40px;
                            margin-bottom: 20px;
                        `}
                    >
                        <Grid
                            container
                            direction="column"
                            css={css`
                                width: 100%;

                                padding: 18px;
                                background: ${colors.white.primary};

                                box-shadow: 0px 1px 3px 0px #3f3f4426;
                                box-shadow: 0px 0px 0px 1px #3f3f440d;
                            `}
                        >
                            {state?.isOnwardAdmin ? (
                                <Grid
                                    container
                                    direction="row"
                                    css={css`
                                        margin-bottom: 4px;
                                    `}
                                >
                                    <Body1>{state?.invoice?.client?.business_name}</Body1>
                                </Grid>
                            ) : null}
                            <Grid
                                container
                                direction="row"
                                css={css`
                                    margin-bottom: 4px;
                                `}
                            >
                                <Body1>Status: {INVOICE_STATUS_DISPLAY_MAPPINGS[state?.invoice?.status] || '--'}</Body1>
                            </Grid>
                            <Stat
                                label="Issued:"
                                value={
                                    state?.invoice?.created_at
                                        ? dateNumeric.format(asBrowserDate(state?.invoice?.created_at))
                                        : '--'
                                }
                                css={css`
                                    margin-bottom: 4px;
                                `}
                            />
                            <Stat
                                label="Due:"
                                value={
                                    state?.invoice?.due_date
                                        ? dateNumeric.format(asBrowserDate(state?.invoice?.due_date))
                                        : '--'
                                }
                                css={css`
                                    margin-bottom: 4px;
                                `}
                            />
                            <Stat label="Description:" value={state?.invoice?.description || '--'} />
                        </Grid>
                    </Grid>
                ) : null}

                <Grid
                    item
                    css={css`
                        overflow: hidden;
                        flex-grow: 1;
                        padding: 0 40px;
                        margin-bottom: 20px;
                    `}
                >
                    <Table state={state} loading={loading} callbacks={callbacks} />
                </Grid>

                <Totals state={state} />
            </Grid>

            <OrderPODModal
                order={state.viewPod}
                callbacks={{
                    onClose: () => {
                        callbacks.viewOrderPod(null);
                    },
                }}
            />
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} />
        </ContextProvider>
    );

export default compose(withContext)(Invoice);
