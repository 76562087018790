import React from 'react';
import dateFnsFormat from 'date-fns/format';
import { css } from '@emotion/react';
import { CheckCircle as CheckCircleIcon, Warning as WarningIcon } from '@material-ui/icons';
import { useItemStats } from '@/hooks';
import { colors } from '@/styles';
import { Status } from './blocks';
import { calcAnyItemTypeCount } from '@/utilities/calculateItemMetrics';
const capitalize = (string = '') => string?.slice(0, 1).toUpperCase() + string?.slice(1);

export const TABLE_COLUMNS = [
    {
        id: 'stop',
        Header: 'Stop',
        accessor: ([order, { pickup, dropoff }]) =>
            pickup?.returns ? pickup.ordering : dropoff ? dropoff.ordering : '-',
        align: 'left',
    },
    {
        id: 'order',
        Header: 'Order',
        accessor: ([order]) => order.order_number,
        align: 'left',
    },
    {
        id: 'po',
        Header: 'PO',
        accessor: ([order]) => order.po_number || '-',
        align: 'left',
    },
    {
        id: 'items',
        Header: 'Items',
        accessor: ([order]) => {
            return calcAnyItemTypeCount(order.itemsByOrderId) || 0;
        },
        align: 'left',
    },
    {
        id: 'customer',
        Header: 'Customer',
        accessor: ([order]) => order.dropoff_name || 'Customer',
        align: 'left',
    },
    {
        id: 'pickup',
        Header: 'Pick Up',
        accessor: ([order, { pickup }]) =>
            pickup && pickup.stop_completion_time
                ? dateFnsFormat(new Date(pickup.stop_completion_time), 'PPPP @ p')
                : '-',
        align: 'left',
    },
    {
        id: 'dropoff',
        Header: 'Drop Off',
        accessor: ([order, { dropoff }]) =>
            dropoff && dropoff.stop_completion_time
                ? dateFnsFormat(new Date(dropoff.stop_completion_time), 'PPPP @ p')
                : '-',
        align: 'left',
    },
    {
        id: 'status',
        Header: 'Status',
        Cell: ({ value: status }) => {
            return <Status status={status}>{status}</Status>;
        },
        accessor: ([order, { dropoff, pickup }]) => {
            if (dropoff && dropoff.stop_completion_time) {
                return 'Complete';
            } else if (pickup && pickup.stop_completion_time) {
                return 'In Transit';
            }
            return 'Incomplete';
        },
        align: 'left',
    },
    {
        id: 'pod',
        Header: 'POD',
        Cell: ({ value: check }) => {
            return check ? (
                <CheckCircleIcon
                    css={css`
                        color: ${colors.greens.primary};
                    `}
                />
            ) : (
                '-'
            );
        },
        accessor: ([order]) => order.pod && order.pod.length,
        align: 'center',
    },
    {
        id: 'pictures',
        Header: 'Pictures',
        Cell: ({ value: checked }) => {
            return checked ? (
                <CheckCircleIcon
                    css={css`
                        color: ${colors.greens.primary};
                    `}
                />
            ) : (
                '-'
            );
        },
        accessor: ([order]) => order.delivery_photo && order.delivery_photo.length,
        align: 'center',
    },
    {
        id: 'exceptions',
        Header: 'Exceptions',
        Cell: ({ value: warn }) => {
            return warn ? (
                <WarningIcon
                    css={css`
                        color: #ff903d;
                    `}
                />
            ) : (
                '-'
            );
        },
        accessor: ([order]) =>
            order.exceptions?.length > 0 || order.itemsByOrderId?.some((item) => item.exceptions?.length > 0),
        align: 'center',
    },
    {
        id: 'type',
        Header: 'Order Type',
        accessor: ([order]) => {
            return capitalize(order?.order_type) || '-';
        },
    },
];
