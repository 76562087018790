import { gql } from '@apollo/client';

const COMMUNICATION_FIElDS = gql`
    fragment CommunicationFields on communications {
        communication_id
        description
        display
        enabled
        name
        type
    }
`;

const FULL_QUERY = gql`
    query GetCommunications {
        communications(limit: 100) {
            communication_id
            description
            display
            enabled
            name
            type
        }
    }
`;

const QUERY_BY_ID = gql`
    query communications($communication_id: String) {
        communications(limit: 100, where: { communication_id: { _eq: $communication_id } }) {
            communication_id
            description
            display
            enabled
            name
            type
        }
    }
`;

export { FULL_QUERY, QUERY_BY_ID, COMMUNICATION_FIElDS };
