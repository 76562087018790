import React, { useContext, useMemo } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { css } from '@emotion/react';
import { validate } from '@onward-delivery/core';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { MODALS } from './constants';
import { useClientUser } from '@/hooks';
import { Context } from './store';
import { BIDDING_DISABLED } from '@/constants/featureFlags';

const styled = withStyles({
    modal: {
        position: 'absolute',
        top: 'calc(50% -  95px)',
        left: 'calc(50% - 227px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0px',
        width: '502px',
    },
});

function Footer() {
    const { circles, stripe_payment_method, payment_type } = useClientUser();
    const {
        state: { selectedOrders },
        callbacks: { setModal, editOrder, setNotification },
    } = useContext(Context);

    const disabled = useMemo(() => {
        return !(selectedOrders.length > 0);
    }, [selectedOrders]);

    const handleSendToOnwardClick = () => {
        const invalidOrder = selectedOrders.find((order) => {
            const [, hasMissingField] = validate({ ...order, oms: false });
            return hasMissingField?.length > 0;
        });

        if (invalidOrder) {
            editOrder(invalidOrder);
            setNotification({ severity: 'warning', message: 'Cannot submit to Onward, order incomplete' });
        } else if (payment_type === 'Invoice' || stripe_payment_method) {
            if (circles?.[BIDDING_DISABLED]) {
                setModal(MODALS.MARKETPLACE);
            } else {
                setModal(MODALS.LISTING_OPTIONS);
            }
        } else {
            setModal(MODALS.CREDIT_CARD);
        }
    };

    const handleSendToPlanning = () => {
        setModal(MODALS.PLANNING);
    };

    return (
        <>
            <Grid
                css={css`
                    padding: 1rem 6rem 1rem 2rem;
                    background-color: white;
                `}
            >
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item>
                        <SecondaryButton onClick={() => setModal(MODALS.CANCELLATION)} disabled={disabled}>
                            Cancel
                        </SecondaryButton>
                    </Grid>
                    <Grid item>
                        {circles?.['ltl-marketplace'] ? (
                            <PrimaryButton
                                disabled={disabled}
                                onClick={handleSendToOnwardClick}
                                css={css`
                                    margin-right: 30px;
                                `}
                            >
                                Send To Onward
                            </PrimaryButton>
                        ) : null}

                        <PrimaryButton disabled={disabled} onClick={handleSendToPlanning}>
                            Send to Planning
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default styled(Footer);
