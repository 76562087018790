import { gql } from '@apollo/client';
import { SUBREGION_FIELDS } from '@/graphql/queries/subregions';
import { SUBREGION_ZIP_FIELDS } from '@/graphql/queries/subregion_zip_mappings';
import { SUBREGION_CARRIER_FIELDS } from '@/graphql/queries/subregion_carrier_mappings';

export const INSERT_SUBREGIONS_MANY = gql`
    ${SUBREGION_FIELDS}

    mutation InsertSubregionsMany($subregions: [subregions_insert_input!]!) {
        created: insert_subregions(objects: $subregions) {
            returning {
                ...SubregionFields
            }
        }
    }
`;

export const UPDATE_SUBREGION = gql`
    ${SUBREGION_FIELDS}

    mutation UpdateSubregion($update: subregions_set_input, $subregion_id: uuid!) {
        created: update_subregions_by_pk(_set: $update, pk_columns: { subregion_id: $subregion_id }) {
            ...SubregionFields
        }
    }
`;

export const INSERT_SUBREGION = gql`
    ${SUBREGION_FIELDS}

    mutation InsertSubregion($subregion: subregions_insert_input!) {
        created: insert_subregions_one(object: $subregion) {
            ...SubregionFields
        }
    }
`;

export const DELETE_SUBREGION = gql`
    mutation DeleteSubregion($subregion_id: uuid!) {
        removed: delete_subregions_by_pk(subregion_id: $subregion_id) {
            subregion_id
        }
    }
`;

export const INSERT_ZIP_MAPPINGS = gql`
    ${SUBREGION_ZIP_FIELDS}

    mutation InsertZipMappings($mappings: [subregion_zip_mappings_insert_input!]!) {
        created: insert_subregion_zip_mappings(
            objects: $mappings
            on_conflict: { constraint: subregion_zip_mappings_subregion_id_zip_key, update_columns: [] }
        ) {
            returning {
                ...SubregionZipFields
            }
        }
    }
`;

export const DELETE_ZIP_MAPPINGS = gql`
    ${SUBREGION_ZIP_FIELDS}

    mutation DeleteZipMappings($mappings: [uuid!]!) {
        removed: delete_subregion_zip_mappings(where: { mapping_id: { _in: $mappings } }) {
            returning {
                ...SubregionZipFields
            }
        }
    }
`;

export const INSERT_CARRIER_MAPPINGS = gql`
    ${SUBREGION_CARRIER_FIELDS}

    mutation InsertCarrierMappings($mappings: [subregion_carrier_mappings_insert_input!]!) {
        created: insert_subregion_carrier_mappings(
            objects: $mappings
            on_conflict: {
                constraint: subregion_carrier_mappings_subregion_id_carrier_id_key
                update_columns: [warehouse_id, is_direct]
            }
        ) {
            returning {
                ...SubregionCarrierFields
            }
        }
    }
`;

export const DELETE_CARRIER_MAPPINGS = gql`
    ${SUBREGION_CARRIER_FIELDS}

    mutation DeleteCarrierMappings($mappings: [uuid!]!) {
        removed: delete_subregion_carrier_mappings(where: { mapping_id: { _in: $mappings } }) {
            returning {
                ...SubregionCarrierFields
            }
        }
    }
`;
