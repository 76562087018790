import React, { useMemo } from 'react';
import { Card, Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { Body1, Header1, Header2 } from '../OrderDetailsPage/Crossdocking/blocks';

const DriverItemsList = ({ order }) => {
    const [items, returns] = useMemo(() => {
        return (order.itemsByOrderId || []).reduce(
            ([itemsAcc, returnsAcc], item) => {
                if (item.is_return) {
                    return [itemsAcc, [...returnsAcc, item]];
                }
                return [[...itemsAcc, item], returnsAcc];
            },
            [[], []]
        );
    }, [order]);

    const itemsContent = (items, type) => {
        if (items.length <= 0) return null;
        return (
            <>
                <Grid
                    item
                    css={css`
                        margin-top: 12px;
                    `}
                >
                    <Header2>{type}</Header2>
                </Grid>
                {items.map((item) => (
                    <>
                        <Grid item>
                            <Header1>
                                {item.sku ? `${item.sku} / ` : ''}
                                {item.description || item.item_type_details}
                            </Header1>
                        </Grid>
                        <Grid item>
                            <Body1>Quantity: {item.quantity}</Body1>
                        </Grid>
                        <Grid item>
                            <Body1>
                                {parseInt(item.weight)}lbs {parseInt(item.length)}L {parseInt(item.width)}W{' '}
                                {parseInt(item.height)}H
                            </Body1>
                        </Grid>
                    </>
                ))}
            </>
        );
    };

    return (
        <Card
            css={css`
                margin: 12px;
                padding: 0px 12px 24px 12px;
            `}
        >
            <Grid direction="column" container>
                {itemsContent(items, 'Items')}
                {itemsContent(returns, 'Return Items')}
                {itemsContent(order.haulaway_items || [], 'Haul-aways')}
            </Grid>
        </Card>
    );
};

export default DriverItemsList;
