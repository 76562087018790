import React, { useContext, useState, useEffect } from 'react';

import PickupResidential from './PickupResidential';
import PickupBusiness from './PickupBusiness';
import Dropoff from './Dropoff';
import ItemTypes from './ItemTypes';
import Bedding from './Bedding';
import Appliances from './Appliances';
import Furniture from './Furniture';
import Haulaway from './Haulaway';
import Payment from './Payment';
import Other from './Other';
import Confirmation from './Confirmation';

import InfoModal from '../modals/Info';
import ExpectationsModal from '../modals/Expectations';

import { Context } from '../store';
import { STAGES, FORM_STATUS } from '../constants';
import { FormContainer } from '../blocks';

function ShipmentForm({ isDesktop }) {
    const { callbacks: contextCallbacks, state } = useContext(Context);
    const [showInfoModal, openInfoModal] = useState(false);
    const [showExpectationsModal, openExpectationsModal] = useState(false);

    const callbacks = {
        ...contextCallbacks,
        showInfo: () => openInfoModal(true),
        showExpectations: () => openExpectationsModal(true),
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, [state.current]);

    if (state.status === FORM_STATUS.COMPLETE) {
        return (
            <FormContainer>
                <Confirmation callbacks={callbacks} state={state} />
            </FormContainer>
        );
    }

    let content;
    switch (state.current) {
        case STAGES.PICKUP_RESIDENTIAL:
            content = (
                <FormContainer>
                    <PickupResidential callbacks={callbacks} state={state} isDesktop={isDesktop} />
                </FormContainer>
            );
            break;
        case STAGES.PICKUP_BUSINESS:
            content = (
                <FormContainer>
                    <PickupBusiness callbacks={callbacks} state={state} isDesktop={isDesktop} />
                </FormContainer>
            );
            break;
        case STAGES.DROPOFF:
            content = (
                <FormContainer>
                    <Dropoff callbacks={callbacks} state={state} isDesktop={isDesktop} />
                </FormContainer>
            );
            break;
        case STAGES.ITEM_TYPES:
            content = (
                <FormContainer>
                    <ItemTypes callbacks={callbacks} state={state} isDesktop={isDesktop} />
                </FormContainer>
            );
            break;
        case STAGES.MATTRESS:
            content = (
                <FormContainer>
                    <Bedding callbacks={callbacks} state={state} isDesktop={isDesktop} type="mattress" />
                </FormContainer>
            );
            break;
        case STAGES.BOX_SPRINGS:
            content = (
                <FormContainer>
                    <Bedding callbacks={callbacks} state={state} isDesktop={isDesktop} type="boxSpring" />
                </FormContainer>
            );
            break;
        case STAGES.BED_FRAMES:
            content = (
                <FormContainer>
                    <Bedding callbacks={callbacks} state={state} isDesktop={isDesktop} type="bedFrame" />
                </FormContainer>
            );
            break;
        case STAGES.APPLIANCES:
            content = (
                <FormContainer>
                    <Appliances callbacks={callbacks} state={state} isDesktop={isDesktop} />
                </FormContainer>
            );
            break;
        case STAGES.FURNITURE:
            content = (
                <FormContainer>
                    <Furniture callbacks={callbacks} state={state} isDesktop={isDesktop} />
                </FormContainer>
            );
            break;
        case STAGES.HAULAWAY:
            content = (
                <FormContainer>
                    <Haulaway callbacks={callbacks} state={state} isDesktop={isDesktop} />
                </FormContainer>
            );
            break;
        case STAGES.OTHER:
            content = (
                <FormContainer>
                    <Other callbacks={callbacks} state={state} isDesktop={isDesktop} />
                </FormContainer>
            );
            break;
        case STAGES.PAYMENT:
            content = <Payment callbacks={callbacks} state={state} isDesktop={isDesktop} />;
            break;
    }

    return (
        <>
            {content}
            <InfoModal
                open={showInfoModal}
                callbacks={{
                    onClose: () => openInfoModal(false),
                }}
            />
            <ExpectationsModal
                open={showExpectationsModal}
                callbacks={{
                    onClose: () => openExpectationsModal(false),
                }}
            />
        </>
    );
}

export default ShipmentForm;
