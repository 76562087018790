import React from 'react';
import Form from 'react-bootstrap/Form';
import { Button, Row, Col, Container } from 'reactstrap';

import { FormControlLabel, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import transportTypes from '../../constants/transportTypes';

const GreenSwitch = withStyles({
    switchBase: {
        color: '#71b77a',
        '&$checked': {
            color: '#71b77a',
        },
        '&$checked + $track': {
            backgroundColor: '#71b77a',
        },
    },
    checked: {},
    track: {},
})((props) => <Switch {...props} />);

class DeliveryTypeFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryTypeFilters: this.props.filters,
            defaultFilterByMyState: false,
        };

        this.fetchFilter = this.fetchFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.updatedFilters = this.updatedFilters.bind(this);
    }

    componentDidUpdate(prevProps) {
        const filtersAreSame = prevProps.filters === this.props.filters;
        const defaultSettingIsSame = this.props.defaultFilterByMyState === prevProps.defaultFilterByMyState;

        if (!filtersAreSame) {
            if (this.props.defaultFilterByMyState !== undefined && !defaultSettingIsSame) {
                this.setState({
                    deliveryTypeFilters: this.props.filters,
                    defaultFilterByMyState: this.props.defaultFilterByMyState,
                });
            } else {
                this.setState({ deliveryTypeFilters: this.props.filters });
            }
        } else if (this.props.defaultFilterByMyState !== undefined && !defaultSettingIsSame) {
            this.setState({
                deliveryTypeFilters: this.props.filters,
                defaultFilterByMyState: this.props.defaultFilterByMyState,
            });
        }
    }

    setFilter(e) {
        const { value, checked } = e.target;

        const deliveryTypeFilters = this.updatedFilters({ value, checked });

        this.setState({ deliveryTypeFilters });

        const { updateHandler } = this.props;

        if (updateHandler) {
            updateHandler(deliveryTypeFilters);
        }
    }

    fetchFilter(value) {
        return this.state.deliveryTypeFilters.find((f) => f.value === value);
    }

    updatedFilters(update) {
        const { deliveryTypeFilters } = this.state;

        const wasPreviouslySelected = this.fetchFilter(update.value);

        let newFilters;

        if (wasPreviouslySelected) {
            newFilters = deliveryTypeFilters.reduce((acc, filter) => {
                if (filter.value === update.value) {
                    return [...acc, update];
                }

                return [...acc, filter];
            }, []);
        } else {
            newFilters = [...deliveryTypeFilters, update];
        }

        return newFilters;
    }

    render() {
        return (
            <>
                <Container className={`d-flex ${this.props.specialStyling}`}>
                </Container>
                <Row className="mt-2 mb-1">
                    <Col>
                        <h5>Delivery Type Filters</h5>
                    </Col>
                </Row>

                <p className="mx-3">{this.props.textContent}</p>

                <Row className="mt-2 mb-1">
                    <Col sm={{ size: 11, offset: 1 }} md={{ size: 4, offset: 0 }}>
                        <h5>Business</h5>

                        {transportTypes.businessDelivery.map((deliveryType, i) => (
                            <Form.Check
                                required
                                key={i}
                                value={deliveryType.value}
                                label={deliveryType.display}
                                id={deliveryType.value}
                                checked={this.fetchFilter(deliveryType.value)?.checked}
                                onChange={this.setFilter}
                            />
                        ))}
                    </Col>

                    <Col className="mt-sm-2" sm={{ size: 11, offset: 1 }} md={{ size: 4, offset: 0 }}>
                        <h5>Residence</h5>

                        {transportTypes.residenceDelivery.map((deliveryType, i) => (
                            <Form.Check
                                required
                                key={i}
                                value={deliveryType.value}
                                label={deliveryType.display}
                                id={deliveryType.value}
                                checked={this.fetchFilter(deliveryType.value)?.checked}
                                onChange={this.setFilter}
                            />
                        ))}
                    </Col>
                </Row>

                {window.location.pathname === '/carrier/account' ||
                    (window.location.pathname === '/carrier/account2' && (
                        <Row className="justify-content-center">
                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        checked={this.state.defaultFilterByMyState}
                                        onChange={() =>
                                            this.setState({
                                                defaultFilterByMyState: !this.state.defaultFilterByMyState,
                                            })
                                        }
                                        name="defaultFilterStateSwitch"
                                    />
                                }
                                label={`Default the load board to be filtered to my state (${this.props.bizstate})`}
                            />
                        </Row>
                    ))}
            </>
        );
    }
}

export default DeliveryTypeFilters;
