import React, { useState, useContext, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';

import HaulAwayItem from './HaulAwayItem';
import { SectionSubtitle, PrimaryButton } from '@/styles/blocks';
import { ModalContext } from '..';

const HR = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
    border: 1px solid rgba(76, 76, 76, 0.5);
`;

function ItemsEditModal() {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;

    return (
        <Grid container direction="column">
            {(order.haulaway_items || []).map((item, idx) => (
                <>
                    <HaulAwayItem key={idx} order={order} item={item} index={idx} callbacks={callbacks} />
                    <HR />
                </>
            ))}

            <Grid item>
                <PrimaryButton onClick={() => callbacks.addItem({ type: 'haulaway' })}>Add Item</PrimaryButton>
            </Grid>
        </Grid>
    );
}

export default ItemsEditModal;
