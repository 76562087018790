import React, { useState, useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_LISTINGS_AUCTIONED_ORDERS_TABLE } from './queries';
import { Box, Grid, LinearProgress } from '@material-ui/core';
import FilterPopover from './FilterPopover';
import OrdersTable from './OrdersTable';
import { OnwardTabContainer, OnwardTab } from '../Tabs';
import { UserContext } from '../App';
import { useClientUser } from '@/hooks';
import _, { startCase } from 'lodash';
import dateFns from '../../utilities/dateFns';
import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';
import { captureException } from '@sentry/react';
import { Pagination } from '@material-ui/lab';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import { PrimaryHeaderContainer, PrimaryHeaderLabel } from '../MyOrders/blocks';
import SearchInput from '../misc/SearchInput';
import { isAfter } from 'date-fns';

const orderSearchable = [
    'order_number',
    'po_number',
    'pickup_city',
    'pickup_state',
    'pickup_zip',
    'dropoff_name',
    'dropoff_city',
    'dropoff_state',
    'dropoff_zip',
];

const addOneDay = (date) => {
    const alteredDate = add(new Date(date), { days: 2 });
    return formatISO(alteredDate, { representation: 'date' });
};

const AuctionedOrders = ({ viewType }) => {
    const [tabIndex, setTabIndex] = useState(2);
    const [orderLocations, setOrderLocations] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState('');
    const [onlyNew, setOnlyNew] = useState(false);

    const { user, sidebarCollapsed } = useContext(UserContext);
    const { user_id, accountType, isOnwardAdmin, isImposter } = useClientUser();

    const adminView = isOnwardAdmin && !isImposter;

    const LISTING_STATUSES = ['pending', 'open', 'claimed', 'cancelled', 'expired'];
    const BID_STATUSES = ['open', 'succeeded', 'cancelled', 'expired'];

    const listingTabFilters = [
        [{ listing_status: { _in: LISTING_STATUSES } }],
        [{ listing_status: { _in: ['pending'] } }],
        [{ listing_status: { _in: ['open'] } }],
        [{ listing_status: { _in: ['claimed'] } }],
        [{ listing_status: { _in: ['cancelled'] } }],
        [{ listing_status: { _in: ['expired'] } }],
    ];

    const bidTabFilters = [
        [{ bids: { bid_status: { _in: BID_STATUSES } } }],
        [{ bids: { bid_status: { _in: ['open'] } } }, { bids: { listing: { listing_status: { _in: ['pending'] } } } }],
        [{ bids: { bid_status: { _in: ['open'] } } }, { bids: { listing: { listing_status: { _nin: ['pending'] } } } }],
        [{ bids: { bid_status: { _in: ['succeeded'] } } }],
        [{ bids: { bid_status: { _in: ['cancelled'] } } }],
        [{ bids: { bid_status: { _in: ['expired'] } } }],
    ];

    const where = useMemo(() => {
        const whereFilters = [
            ...(!adminView
                ? [
                      {
                          ...(viewType === 'listings'
                              ? { shipper_id: { _eq: user_id } }
                              : { bids: { carrier_id: { _eq: user_id } } }),
                      },
                  ]
                : []),
            ...(viewType === 'listings' ? listingTabFilters[tabIndex] : bidTabFilters[tabIndex]),
        ];

        if (startDate) {
            whereFilters.push({ created_at: { _gte: startDate } });
        }

        if (endDate) {
            whereFilters.push({ created_at: { _lt: addOneDay(endDate) } });
        }

        if (search) {
            whereFilters.push({
                _or: orderSearchable.map((searchField) => ({ order: { [searchField]: { _ilike: `%${search}%` } } })),
            });
        }

        if (onlyNew) {
            whereFilters.push({
                listing_status: { _eq: 'open' },
            });
        }

        return { _and: whereFilters };
    }, [tabIndex, startDate, endDate, viewType, search, onlyNew]);

    // TODO: paginate inside table since "new" filter is not implemented in gql
    const PAGE_SIZE = 500;
    const [page, setPage] = useState(0);

    const { loading: loadingOrdersByUserId, data } = useQuery(QUERY_LISTINGS_AUCTIONED_ORDERS_TABLE, {
        variables: {
            limit: PAGE_SIZE,
            offset: page * PAGE_SIZE,
            where,
        },
        onCompleted: (data) => {
            setTotalCount(data.listings.length);
        },
        onError: (error) => {
            captureException(error);
            console.log(error);
        },
    });

    const getOrderLocations = async (orders) => {
        let locationMap = {};
        orders.forEach((order) => {
            const pickupLocationKey = `${order.pickup_city}|${order.pickup_state}|${
                order?.pickup_zip || order.order_number
            }`;
            if (!locationMap[pickupLocationKey]) {
                locationMap[pickupLocationKey] = {
                    value: pickupLocationKey,
                    label: `${order.pickup_city}, ${order.pickup_state}  ${order?.pickup_zip || order.order_number}`,
                };
            }

            const dropoffLocationKey = `${order.dropoff_city}|${order.dropoff_state}|${
                order?.dropoff_zip || order.order_number
            }`;
            if (!locationMap[dropoffLocationKey]) {
                locationMap[dropoffLocationKey] = {
                    value: dropoffLocationKey,
                    label: `${order.dropoff_city}, ${order.dropoff_state}  ${order?.dropoff_zip || order.order_number}`,
                };
            }
        });
        setOrderLocations(
            Object.keys(locationMap)
                .sort()
                .map((key) => locationMap[key])
        );
    };

    const allListings = useMemo(() => {
        if (data?.listings?.length) {
            let _listings = data?.listings;

            if (onlyNew && viewType === 'listings') {
                _listings = _listings.filter((listing) => {
                    const lastViewed = listing.last_viewed_shipper || listing.created_at;
                    const hasUnreadCounterOfferOrBid = listing.bids.some(
                        (bid) =>
                            isAfter(new Date(bid.created_at), new Date(lastViewed)) ||
                            bid.counter_offers.some((counter_offer) =>
                                isAfter(new Date(counter_offer.created_at), new Date(lastViewed))
                            )
                    );
                    return hasUnreadCounterOfferOrBid;
                });
            }

            if (viewType === 'bids') {
                _listings = _listings.map((listing) => {
                    const carrierBid = listing.bids?.find((bid) => bid.carrier_id === user_id);
                    return { ...listing, carrierBid };
                });
                if (onlyNew) {
                    _listings = _listings.filter((listing) => {
                        const lastViewed = listing.carrierBid.last_viewed_carrier || listing.carrierBid.created_at;
                        const hasUnreadCounterOffer = listing.carrierBid?.counter_offers.some((counter) =>
                            isAfter(new Date(counter.created_at), new Date(lastViewed))
                        );
                        return hasUnreadCounterOffer;
                    });
                }
            }

            const orders = _listings.map((listing) => {
                return listing.order;
            });

            getOrderLocations(orders);

            return _listings;
        }
        return [];
    }, [data, onlyNew]);

    const handleTabChange = (e, tab) => {
        setTabIndex(tab);
    };

    const handlePage = (_, p) => {
        const pageIdx = p - 1;
        setPage(pageIdx);
    };

    const listingTabs = () => {
        return (
            <OnwardTabContainer
                value={tabIndex}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
            >
                <OnwardTab label="All" value={0} style={{ outline: 0 }} />
                <OnwardTab label="Pending" value={1} style={{ outline: 0 }} />
                <OnwardTab label="Open" value={2} style={{ outline: 0 }} />
                <OnwardTab label="Accepted" value={3} style={{ outline: 0 }} />
                <OnwardTab label="Cancelled" value={4} style={{ outline: 0 }} />
                <OnwardTab label="Expired" value={5} style={{ outline: 0 }} />
            </OnwardTabContainer>
        );
    };

    const bidTabs = () => {
        return (
            <OnwardTabContainer
                value={tabIndex}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
            >
                <OnwardTab label="All" value={0} style={{ outline: 0 }} />
                <OnwardTab label="Pre-bid" value={1} style={{ outline: 0 }} />
                <OnwardTab label="Open" value={2} style={{ outline: 0 }} />
                <OnwardTab label="Accepted" value={3} style={{ outline: 0 }} />
                <OnwardTab label="Cancelled" value={4} style={{ outline: 0 }} />
                <OnwardTab label="Expired" value={5} style={{ outline: 0 }} />
            </OnwardTabContainer>
        );
    };

    return (
        <Box
            className={`${sidebarCollapsed && 'collapse-margin'}  table-height`}
            sx={{ flexGrow: 1, padding: 0, height: 'calc(100vh - 50px)' }}
        >
            <Grid container direction="column" wrap="nowrap" className="h-100">
                <Grid container justifyContent="center" className="bg-white border-bottom">
                    <Grid item>{viewType === 'listings' ? listingTabs() : bidTabs()}</Grid>
                </Grid>
                <PrimaryHeaderContainer item>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <PrimaryHeaderLabel>Auctioned {startCase(viewType)}</PrimaryHeaderLabel>
                        </Grid>
                        <Grid item>
                            <SearchInput
                                className="mx-3"
                                onChange={(value) => setSearch(value)}
                                placeholder="Search orders"
                            />
                            <FilterPopover
                                email={user?.email}
                                userType={accountType}
                                locations={orderLocations}
                                startDate={startDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}
                                onlyNew={onlyNew}
                                setOnlyNew={setOnlyNew}
                            />
                        </Grid>
                    </Grid>
                </PrimaryHeaderContainer>
                {loadingOrdersByUserId && (
                    <Grid item className="ms-5 me-5">
                        <LinearProgress
                            color="primary"
                            css={css`
                                width: 100%;
                            `}
                        />
                    </Grid>
                )}
                <Grid item className="ms-5 me-5 bg-white" style={{ overflow: 'hidden', flexGrow: 1 }}>
                    {loadingOrdersByUserId || allListings.length ? (
                        <OrdersTable listings={allListings} viewType={viewType} />
                    ) : (
                        <div
                            css={css`
                                font-size: 18px;
                                font-weight: 600;
                                padding-top: 50px;
                                text-align: center;
                            `}
                        >
                            You currently have no {viewType}.
                        </div>
                    )}
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                    css={css`
                        padding: 3rem 0;
                    `}
                >
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        css={css`
                            .Mui-selected {
                                background-color: ${colors.greens.primary};
                                color: white;
                            }
                        `}
                        count={Math.ceil(totalCount / PAGE_SIZE)}
                        page={page + 1}
                        onChange={handlePage}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AuctionedOrders;
