export const getTimeAgo = (lastUpdated) => {
    if (!lastUpdated) return ['-', 1000];
    const ms = Date.now() - lastUpdated.getTime();
    if (ms > 3600000) {
        // 'X hr ago', update every 5 min
        return [`${Math.floor(ms / 3600000)} hr ago`, 300000];
    } else if (ms > 60000) {
        // 'X min ago', update every 5 sec
        return [`${Math.floor(ms / 60000)} min ago`, 5000];
    } else {
        // 'X sec ago', update every 1 sec
        return [`${Math.floor(ms / 1000)} sec ago`, 1000];
    }
};
