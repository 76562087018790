import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useClientUser } from '../../hooks';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { calcDefaultServiceTime } from '@/utilities/calculateTimeframes';
import { colors } from '@/styles';
import { H2, Body1, local, fragments } from './blocks';
const minuteString = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'minute',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const LunchStopCard = ({ stopNumber, stop, orders, route }) => {
    const { accountType, originalAdminEmail, roles, ...timeframeOptions } = useClientUser();

    const ordersByKey = useMemo(() => {
        if (!orders) {
            return {};
        }

        return Object.fromEntries(orders.map((order) => [order.order_id, order]));
    }, [orders]);

    const serviceTime = useMemo(() => {
        if (!stop) {
            return 0;
        }

        if (stop.service_time === 0 || stop.service_time) {
            return stop.service_time / 1000 / 60;
        }

        const { hours, minutes } = calcDefaultServiceTime(stop, ordersByKey, timeframeOptions);
        return (hours || 0) * 60 + (minutes || 0);
    }, [stop, ordersByKey]);

    const Card = styled((props) => <Grid {...props} />)`
        ${fragments.card}
        padding: 16px;

        ${stop.nextStop &&
        css`
            border: 2px solid #000000;
            box-shadow: 0px 1px 8px 3px rgba(63, 63, 68, 0.2);
            border-radius: 4px;
        `}
    `;

    return (
        <Card container>
            <Grid container direction="column">
                <Grid container>
                    <Grid item xs={6}>
                        <H2>
                            Lunch
                        </H2>
                    </Grid>

                    <Grid container alignItems="center" justifyContent="flex-end" xs={6}>
                        {!!stop.stop_completion_time && (
                            <CheckCircleIcon
                                css={css`
                                    font-size: 20px;
                                    color: ${colors.greens.primary};
                                `}
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid container>
                    <Body1
                        css={css`
                            color: ${local.grey};
                        `}
                    >
                        {`Lunch duration: ${minuteString.format(serviceTime)}`}
                    </Body1>
                </Grid>
            </Grid>
        </Card>
    );
};

export default LunchStopCard;
