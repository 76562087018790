import React from 'react';
import { Row, Button, Container, Card, Col } from 'react-bootstrap';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import DeliveryTypeFilters from '@/components/OrderFilters/DeliveryTypeFilters';
import transportTypes from '@/constants/transportTypes';
import Blacklist from '@/components/Blacklist';
import * as Sentry from '@sentry/react';
import { useClientUser } from '@/hooks';
import { UPDATE_CARRIER_BLACKLIST, UPDATE_DELIVERY_FILTERS } from '../mutations';
import { useMutation } from '@apollo/client';
import { Switch, FormControlLabel } from '@material-ui/core';
import { UPDATE_CLIENT_BY_PK } from '@/graphql/mutations/clients';
import { UPDATE_FEATURE_FLAGS } from '@/graphql/mutations/users';

function LoadPreferences(props) {
    const deliveryFiltersRef = React.createRef(null);
    const { user_id, commingled_internal_mandatory, circles } = useClientUser();

    const [UpdateCarrierBlacklist] = useMutation(UPDATE_CARRIER_BLACKLIST, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const [UpdateDeliveryTypeFilters] = useMutation(UPDATE_DELIVERY_FILTERS, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const [updateClient] = useMutation(UPDATE_CLIENT_BY_PK, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    const commingleToggle = (bool) => {
        updateClient({
            variables: {
                client_id: user_id,
                update: {
                    commingled_internal_mandatory: bool,
                },
            },
        });
    };

    const updateDeliveryTypeFilters = () => {
        const { deliveryTypeFilters, default_filter_by_my_state } = deliveryFiltersRef.current.state;

        try {
            UpdateDeliveryTypeFilters({
                variables: {
                    client_id: user_id,
                    delivery_filters: deliveryTypeFilters,
                    default_filter_by_my_state: default_filter_by_my_state,
                },
            });
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    };

    const addToBlacklist = (shipper) => {
        if (props.currentCarrier.blacklist) {
            const prevList = props.currentCarrier.blacklist;
            if (!prevList.find((e) => shipper.key === e.key)) {
                UpdateCarrierBlacklist({
                    variables: {
                        client_id: user_id,
                        blacklisted_clients: [...prevList, shipper],
                    },
                });
            }
        } else {
            UpdateCarrierBlacklist({
                variables: {
                    client_id: user_id,
                    blacklisted_clients: [shipper],
                },
            });
        }
    };

    const [updateCircles] = useMutation(UPDATE_FEATURE_FLAGS, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const toggleFeatureFlag = async (featureFlag) => {
        const _updated = {
            ...(circles || {}),
            [featureFlag]: !circles?.[featureFlag],
        };

        updateCircles({
            variables: {
                user_list: [user_id],
                circles: _updated,
            },
        });
    };

    if (!props?.currentCarrier?.client_id) {
        return <></>;
    }

    return (
        <Card className="tabCard">
            <ThemeProvider theme={theme}>
                <Card.Body className="">
                    <Container fluid>
                        {props.currentCarrier.delivery_filters ? (
                            <DeliveryTypeFilters
                                textContent="Unchecking these will cause orders of the corresponding delivery type to not be displayed on the Load Board."
                                filters={props.currentCarrier.delivery_filters}
                                defaultFilterByMyState={props.currentCarrier.default_filter_by_my_state}
                                bizstate={props.currentCarrier.business_state}
                                ref={deliveryFiltersRef}
                            />
                        ) : (
                            <DeliveryTypeFilters
                                textContent="Unchecking these will cause orders of the corresponding delivery type to not be displayed on the Load Board."
                                filters={transportTypes.all}
                                defaultFilterByMyState={props.currentCarrier.default_filter_by_my_state}
                                bizstate={props.currentCarrier.business_state}
                                ref={deliveryFiltersRef}
                            />
                        )}
                        <Row className="d-flex justify-content-center mt-3 mb-2">
                            <Col>
                                <Button
                                    className="mb-4 align-self-center"
                                    variant="success"
                                    onClick={updateDeliveryTypeFilters}
                                >
                                    Update
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-1">
                            <Col>
                                <h5>Shipper Blacklist</h5>
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-1">
                            <Col>
                                <p>
                                    Here you may select shipping companies that you wish to hide from your load board.
                                    Orders from selected companies will not be visible to you on the load board.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-1">
                            <Col>
                                <Blacklist
                                    blacklistType="carrier"
                                    currentUser={user_id}
                                    update={addToBlacklist}
                                    clientBlacklist={props.currentCarrier.blacklisted_clients}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-1">
                            <Col>
                                <h5>Co-mingled Routes</h5>
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-1">
                            <Col>
                                <p>
                                    This option is for routes you have created that include both your own internal
                                    orders and orders that you have claimed from the Onward Marketplace. With this
                                    option turned off, you can skip having to click through and complete internal orders
                                    on the mobile app. Onward orders will always have to be completed. On by default.
                                </p>
                                <p>
                                    *If this is disabled, tracking links will not be sent to your own internal orders,
                                    they will only be sent to orders on the route that were claimed from the Onward
                                    Marketplace.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={commingled_internal_mandatory}
                                            onChange={() => commingleToggle(!commingled_internal_mandatory)}
                                        />
                                    }
                                    label="Internal orders are mandatory in co-mingled routes"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-1">
                            <Col>
                                <h5>Require customer signature</h5>
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-1">
                            <Col>
                                <p>
                                    Here you can choose whether or not to have a customer signature be required on order
                                    delivery. You can choose to require an actual digital signature, or just the typed
                                    out customer's name, or both.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={circles?.['require-digital-signature']}
                                            onChange={() => toggleFeatureFlag('require-digital-signature')}
                                        />
                                    }
                                    label="Require digital signature"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={circles?.['require-signature-name']}
                                            onChange={() => toggleFeatureFlag('require-signature-name')}
                                        />
                                    }
                                    label="Require customer name"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </ThemeProvider>
        </Card>
    );
}

export default LoadPreferences;
