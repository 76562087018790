export const FILTERS = {
    ALL: 'ALL',
    SUBMITTED: 'SUBMITTED',
    APPROVED: 'APPROVED',
    PAID: 'PAID',
};

export const FILTER_DISPLAY_MAPPINGS = {
    [FILTERS.ALL]: 'All',
    [FILTERS.SUBMITTED]: 'Submitted',
    [FILTERS.APPROVED]: 'Approved',
    [FILTERS.PAID]: 'Paid',
};
