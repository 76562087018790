import { gql } from '@apollo/client';

export const EXCEPTION_MAPPINGS_FIELD = gql`
    fragment ExceptionMappingsFields on exception_mappings {
        created_at
        exception_id
        exception_type
        item_id
        last_modified_at
        mapping_id
        order_id
        event_id
    }
`;
