import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';

const useStyles = makeStyles({
    dialogContent: {
        width: 565
    },
});

export default function BackToUnassignedModal(props) {
    const { open, handleClose, onSuccess } = props;
    const classes = useStyles();

    return (
        <>
            <Dialog
                className="carrier-review-modal"
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                <DialogContent className={classes.dialogContent}>
                    <Typography className="mb-4" variant="body2">Sending this order to the Onward marketplace means certain fields be reset and you will no longer be able to edit this order whilst it is in the marketplace. You are able to regain full control over the order later by canceling the marketplace listing.</Typography>
                    <Typography className="mb-4" variant="body2">While in the marketplace, your order may be bid on by carriers and you may select a winning bid to award the order to that carrier, alternatively an order can be instantly claimed by a carrier using its Claim Now price.</Typography>
                    <Typography className="mb-1" variant="body2">You may view and cancel your listings from the 'Listings' link in the sidebar.</Typography>
                </DialogContent>
                <DialogActions
                    className="customer-track-rating-modal d-flex justify-content-between"
                    style={{ padding: '24px' }}
                >
                    <SecondaryButton onClick={handleClose}>
                        Cancel
                    </SecondaryButton>

                    <PrimaryButton onClick={onSuccess}>Continue</PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
