import React, { useEffect, useState, useRef, forwardRef } from 'react';
import mapboxgl from '!mapbox-gl';

import { CONFIG } from '@/config';

import 'mapbox-gl/dist/mapbox-gl.css';

function Map({ node, children, callbacks, minZoom = 3, ...rest }, ref) {
    const ob = useRef(null);
    const [map, setMap] = useState(null);

    useEffect(() => {
        if (!ob.current && node) {
            ob.current = new ResizeObserver((entries) => {
                if (map?.current) {
                    map.current.resize();
                }
            });

            ob.current.observe(node);
        }

        return () => {
            ob.current?.disconnect();
        };
    }, [node]);

    useEffect(() => {
        if (node) {
            ref.current = new mapboxgl.Map({
                ...rest,
                container: node,
                style: `mapbox://styles/mapbox/streets-v12`,
                accessToken: CONFIG.MAPBOX_PK,
                minZoom,
            });

            ref.current.on('load', () => {
                callbacks.onLoad(ref.current);
            });

            setMap(ref.current);
        }
    }, [node]);

    return <>{children({ map })}</>;
}

export default forwardRef(Map);
