import React from 'react';
import { css } from '@emotion/react';
import {
    ModalActions,
    PrimaryButton,
    SectionTitle,
    ModalBody,
} from '@/styles/blocks';
import { Clear } from '@material-ui/icons';
import { Dialog, DialogContent, } from '@material-ui/core';
import TermsAndConditions from '../TermsAndConditions';

const TermsAndConditionsModal = ({open, onClose, acceptTerms}) => {

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            fullWidth
            maxWidth={'xl'}
        >
            <DialogContent css={css`
                padding: 1.5rem !important;
            `}>
                <SectionTitle css={css`
                    justify-content: space-between;
                    display: flex;
                `}>
                    Terms and Conditions
                    <Clear 
                        css={css`
                            cursor: pointer;
                        `}
                        onClick={() => onClose()}
                    />
                </SectionTitle>

                <ModalBody>
                    <TermsAndConditions />
                </ModalBody>

                <ModalActions>
                    <PrimaryButton
                        onClick={() => acceptTerms()}
                        css={css`
                            width: 100%;
                        `}
                    >
                        Accept Terms and Conditions
                    </PrimaryButton>
                </ModalActions>
            </DialogContent>
        </Dialog>
    );
};

export default TermsAndConditionsModal;
