import { gql } from '@apollo/client';

import { BID_COMMENT_FIELDS } from '../mutations/bid_comments';

export const BID_FIELDS = gql`
    ${BID_COMMENT_FIELDS}
    fragment BidFields on bids {
        bid_id
        bid_status
        carrier_id
        carrier_rate
        carrier_email
        carrier_phone
        created_at
        expiration_date
        expiration_date_formatted @client
        last_modified_at
        last_viewed_carrier
        last_viewed_shipper
        listing_id
        location_id
        shipper_rate
        target_delivery_date
        delivery_date
        bid_comments {
            ...BidCommentFields
        }
    }
`;
