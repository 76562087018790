import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import { getTimeAgo } from '../util';
import { ControlContainer, ControlIconContainer, ControlTextBody } from '../blocks';
import { createRoot } from 'react-dom/client';

const controlContainer = document.createElement('div');
const id = 'last-updated-control-container';
controlContainer.setAttribute('id', id);
const containerRoot = createRoot(controlContainer);

const LastUpdatedControlContent = ({ lastUpdated, onRefresh }) => {
    const [timeAgo, setTimeAgo] = useState('');
    const [timerId, setTimerId] = useState();

    const updateTimeAgo = () => {
        const [newTimeAgo, interval] = getTimeAgo(lastUpdated);

        setTimeAgo(newTimeAgo);

        if (timerId) clearTimeout(timerId);
        setTimerId(
            setTimeout(() => {
                updateTimeAgo();
            }, interval)
        );
    };

    useEffect(() => {
        updateTimeAgo();
        return () => clearTimeout(timerId);
    }, [lastUpdated]);

    return (
        <ControlContainer
            style={{ backgroundColor: 'rgba(250, 250, 250, 0.85)' }}
            error={lastUpdated ? '' : 'no location'}
        >
            <ControlIconContainer onClick={onRefresh}>
                <RefreshIcon
                    css={css`
                        color: ${colors.white.primary};
                    `}
                />
            </ControlIconContainer>
            <ControlTextBody>{lastUpdated ? `last updated ${timeAgo}` : 'location not available'}</ControlTextBody>
        </ControlContainer>
    );
};

export const LastUpdatedControl = ({ mapRef, lastUpdated, onRefresh }) => {
    useEffect(() => {
        containerRoot.render(<LastUpdatedControlContent lastUpdated={lastUpdated} onRefresh={onRefresh} />);
    }, [lastUpdated]);

    useEffect(() => {
        if (mapRef?.current) {
            const controls = mapRef.current.controls[window.google.maps.ControlPosition.TOP_LEFT];

            controls.push(controlContainer);
            containerRoot.render(<LastUpdatedControlContent lastUpdated={lastUpdated} onRefresh={onRefresh} />);

            return () => {
                const idx = controls.indexOf((el) => el.id === id);
                if (idx >= 0) {
                    controls.removeAt(idx);
                }
            };
        }
    }, [mapRef?.current]);

    return null;
};
