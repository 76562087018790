import React, { useState, useMemo, useContext } from 'react';
import { PrimaryButton, Row } from '@/styles/blocks';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { Grid, MenuItem, Tooltip } from '@material-ui/core';
import { UserContext } from '@/components/App';
import GoogleMap, { Marker } from '@/components/GoogleMap';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import { Body, SectionSubtitle } from '@/styles/blocks';
import { colors } from '@/styles';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { asBrowserDate } from '@/utilities/convertToISO';

import {
    addressSplit,
    sanitizeEnum,
    VALID_LOCATION_TYPES,
    VALID_DROPOFF_TYPES as VALID_PICKUP_TYPES,
} from '../../utilities/processOrders';
import { QUERY_CLIENTS_BY_USER, QUERY_LOCATIONS_BY_CLIENT_ID } from '../../graphql/queries';
import { useSwappedAttributes } from '../../hooks';
import { ErrorText, TextField, OnwardToggle, RadioLabelInfo, ToggleBlurb } from '../../blocks';
import { ModalContext } from './';
import { format } from 'date-fns';

import {
    SingleDatePicker,
    SecondaryContactBtn,
    StoreSelect,
    NewAddressCheckbox,
    LocationType,
    PickupType,
    StoreDetails,
} from '../InputFields';
import { useClientUser } from '@/hooks';
import { Info } from '@material-ui/icons';

const ContactFields = ({ order, attrs, hasError, isDirty, callbacks, opt }) => {
    const [name, phone, email] = attrs;

    return (
        <Row
            css={css`
                margin-top: 16px;
                align-items: center;
            `}
        >
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                    margin-right: 16px;
                `}
            >
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={order[name]}
                    onBlur={() => callbacks.makeDirty([name])}
                    onChange={(e) => callbacks.modifyOrder({ [name]: e.target.value })}
                    error={hasError[name] && (opt.startDirty || isDirty[name])}
                />
            </Grid>
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                    margin-right: 16px;
                `}
            >
                <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneNumberInput,
                    }}
                    value={toNational(order[phone])}
                    onBlur={() => callbacks.makeDirty([phone])}
                    onChange={(e) => callbacks.modifyOrder({ [phone]: toE164(e.target.value) })}
                    error={hasError[phone] && (opt.startDirty || isDirty[phone])}
                />
            </Grid>
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                `}
            >
                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={order[email]}
                    onBlur={() => callbacks.makeDirty([email])}
                    onChange={(e) => callbacks.modifyOrder({ [email]: e.target.value })}
                />
            </Grid>
        </Row>
    );
};

const PickupTab = ({ opt }) => {
    const { disableAddressEditing, disableGeocoding, startDirty, isInternal } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order, isDirty, hasError, errors } = modalState;
    const { user } = useContext(UserContext);

    const [viewMapOverride, setViewMap] = useState(false);
    const [hasSecondaryContactOverride, setHasSecondaryContact] = useState(null);
    const [hasNewAddressOverride, setHasNewAddress] = useState(null);
    const [storeOverride, setStoreOverride] = useState(null);

    const { data } = useQuery(QUERY_CLIENTS_BY_USER, {
        variables: { client_id: user.user_id },
        onError: (e) => {
            callbacks.onError(e);
        },
    });

    const { data: locationsData } = useQuery(QUERY_LOCATIONS_BY_CLIENT_ID, {
        variables: {
            client_id:
                order.carrier_id && (order.wh_events.find((e) => e.action === 'START:RECEIVING') || order.oms)
                    ? order.carrier_id
                    : order.shipper_id,
        },
        onError: (e) => {
            callbacks.onError(e);
        },
    });

    const {
        is_custom,
        geocode_failed,
        address,
        city,
        state,
        street,
        zip,
        lat,
        long,
        comments,
        unit,
        location,
        location_type,
    } = useSwappedAttributes(order, true);

    const { user_id, shipping_partners } = useClientUser();

    const client = useMemo(() => {
        return data?.result?.length > 0 ? data.result[0] : null;
    }, [data]);

    const locations = useMemo(() => {
        return locationsData?.locations || [];
    }, [locationsData]);

    const viewMap = useMemo(() => {
        return order[is_custom] || viewMapOverride;
    }, [viewMapOverride, order, is_custom]);

    const hasSecondaryContact = useMemo(() => {
        return hasSecondaryContactOverride !== null
            ? hasSecondaryContactOverride
            : order?.secondary_pickup_contact_name?.length > 0;
    }, [order, hasSecondaryContactOverride]);

    const store = useMemo(() => {
        if (!storeOverride && locations.length) {
            const match = locations.findIndex(
                (location) =>
                    location.address === order[address] ||
                    (location.location_name && location.location_name === order.chosen_store_name)
            );
            return match > -1 ? match : null;
        }
        return storeOverride;
    }, [client, storeOverride, order, locations]);

    const hasNewAddress = useMemo(() => {
        let hasNewAddress = false;
        if (hasNewAddressOverride !== null) {
            hasNewAddress = hasNewAddressOverride;
        } else {
            hasNewAddress =
                (locations?.length || 0) === 0 ||
                ((order[address]?.length || 0) > 0 && store === null) ||
                hasError[location] ||
                hasError[location_type] ||
                order[is_custom];
        }

        return hasNewAddress;
    }, [client, hasNewAddressOverride, order, store, hasError, location, location_type, is_custom, locations]);

    const coords = useMemo(() => {
        if (order && lat && long) {
            return order[lat] && order[long] ? { lat: order[lat], lng: order[long] } : null;
        }

        return null;
    }, [order, lat, long]);

    const allowCustom = useMemo(() => {
        return order[lat] || order[long] || order[geocode_failed];
    }, [order, lat, long, geocode_failed]);

    const handleStoreSelect = async (e) => {
        setStoreOverride(e.target.value);
        let _store = locations[e.target.value];

        const [address1, address2] = addressSplit(_store.business_address);
        if (disableGeocoding) {
            callbacks.modifyOrder({
                [is_custom]: false,
                [geocode_failed]: false,
                [unit]: address2,
                [address]: [address1, _store.business_city, _store.business_state, _store.business_zip].join(', '),
                [street]: address1,
                [city]: _store.business_city,
                [state]: _store.business_state,
                [zip]: _store.business_zip,
            });
        } else {
            const results = await callbacks.enrichOrder(
                address,
                [
                    address1,
                    ...(_store.business_city ? [_store.business_city] : []),
                    ...(_store.business_state ? [_store.business_state] : []),
                    ...(_store.business_zip ? [_store.business_zip] : []),
                ].join(', '),
                { pickup: true }
            );
            if (results.errors.geocodeFailed) {
                callbacks.onError('Geocode failed. Please try again.');
            }

            callbacks.modifyOrder({
                [is_custom]: false,
                [unit]: address2,
                [location]: sanitizeEnum(_store.location_type, VALID_PICKUP_TYPES) || 'Business',
                [location_type]: sanitizeEnum(_store.location_info, VALID_LOCATION_TYPES) || 'rollUpDoor',
            });
        }

        callbacks.makeDirty([is_custom, unit, address, street, city, state, zip]);
    };

    let addressError = null;
    if (order.auto_assign_failed) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Address provided could not be auto assigned to carrier</ErrorText>
            </Grid>
        );
    } else if (
        allowCustom &&
        hasNewAddress &&
        ([address, street, city, state, zip, lat, long].some(
            (attr) => hasError[attr] && (startDirty || isDirty[attr])
        ) ||
            order[geocode_failed])
    ) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Address is invalid</ErrorText>
            </Grid>
        );
    } else if (
        allowCustom &&
        hasNewAddress &&
        ['distance', 'miles', 'duration_seconds'].some((attr) => hasError[attr] && (startDirty || isDirty[attr]))
    ) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Failed to find directions between pick up and dropoff address</ErrorText>
            </Grid>
        );
    }

    return (
        <Grid container direction="column">
            <ContactFields
                order={order}
                isDirty={isDirty}
                attrs={['pickup_name', 'pickup_phone', 'pickup_email']}
                hasError={hasError}
                callbacks={callbacks}
                opt={opt}
            />

            <Grid
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <SecondaryContactBtn
                    hasSecondaryContact={hasSecondaryContact}
                    onClick={() => {
                        setHasSecondaryContact(!hasSecondaryContact);
                        callbacks.modifyOrder({
                            secondary_pickup_contact_name: null,
                            secondary_pickup_contact_phone: null,
                            secondary_pickup_contact_email: null,
                        });
                    }}
                />
            </Grid>

            {hasSecondaryContact && (
                <ContactFields
                    order={order}
                    isDirty={isDirty}
                    attrs={[
                        'secondary_pickup_contact_name',
                        'secondary_pickup_contact_phone',
                        'secondary_pickup_contact_email',
                    ]}
                    hasError={hasError}
                    callbacks={callbacks}
                    opt={opt}
                />
            )}

            <Grid
                css={css`
                    margin-top: 16px;
                    margin-bottom: 24px;
                `}
                container
                direction="row"
            >
                <Grid
                    container
                    item
                    css={css`
                        flex-grow: 2;
                        flex-basis: 0;
                        margin-right: 16px;
                    `}
                >
                    <StoreSelect
                        value={store}
                        onChange={handleStoreSelect}
                        error={hasError[address] && !hasNewAddress && (startDirty || isDirty[address])}
                        storeList={locations || []}
                    />
                </Grid>

                <Grid
                    container
                    item
                    css={css`
                        flex: 0;
                        flex-basis: 0;
                    `}
                >
                    <SingleDatePicker
                        label="First Available"
                        tooltipText="This is the earliest date that these orders can be scheduled for delivery. Will be defaulted to today if left blank."
                        value={
                            order.first_available_date
                                ? format(new Date(order.first_available_date), 'yyyy-MM-dd')
                                : null
                        }
                        onChange={(e) =>
                            callbacks.modifyOrder({
                                first_available_date: asBrowserDate(e.target.value).toISOString(),
                            })
                        }
                        error={hasError.first_available_date && (startDirty || isDirty.first_available_date)}
                    />
                </Grid>
            </Grid>

            <Row
                css={css`
                    align-items: center;
                `}
            >
                <TextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    label="Comments"
                    multiline={true}
                    value={order[comments]}
                    onChange={(e) => callbacks.modifyOrder({ [comments]: e.target.value })}
                />
            </Row>

            {!hasNewAddress && store !== null ? (
                <Row>
                    <Grid container direction="column">
                        <StoreDetails
                            storeName={locations?.[store]?.location_name}
                            fullAddress={order[address]}
                            locationType={order[location]}
                            pickupType={order[location_type]}
                        />
                    </Grid>
                </Row>
            ) : null}

            <Row>
                <NewAddressCheckbox
                    hasNewAddress={hasNewAddress}
                    disabled={locations?.length === 0}
                    onChange={() => {
                        const next = !hasNewAddress;
                        if (!next) {
                            callbacks.modifyOrder({ [is_custom]: false });
                        }
                        setHasNewAddress(next);
                    }}
                />
            </Row>

            {addressError ? addressError : null}
            {hasNewAddress ? (
                <>
                    <Row>
                        {order[is_custom] ? (
                            <TextField
                                variant="outlined"
                                color="primary"
                                label="Address"
                                value={order[street]}
                                error={hasError[street] && (startDirty || isDirty[street])}
                                disabled={disableAddressEditing}
                                onBlur={() => callbacks.makeDirty([street])}
                                onChange={(e) => {
                                    callbacks.modifyOrder({
                                        [street]: e.target.value,
                                        [geocode_failed]: false,
                                        auto_assign_failed: false,
                                    });
                                    callbacks.makeDirty([address, street]);
                                }}
                                fullWidth
                            />
                        ) : (
                            <AddressAutocomplete
                                disabled={disableAddressEditing}
                                state={{
                                    street: order[street],
                                    city: order[city],
                                    state: order[state],
                                    zip: order[zip],
                                }}
                                handleAddressUpdate={async (value, split) => {
                                    if (disableGeocoding) {
                                        callbacks.modifyOrder({
                                            [geocode_failed]: false,
                                            [address]: value,
                                            [street]: split.street,
                                            [city]: split.city,
                                            [state]: split.state,
                                            [zip]: split.zip,
                                            auto_assign_failed: false,
                                        });
                                    } else {
                                        const results = await callbacks.enrichOrder(address, value, { pickup: true });
                                        if (results.errors.geocodeFailed) {
                                            callbacks.onError('Geocode failed. Please try again.');
                                        }
                                    }

                                    callbacks.makeDirty([address, street, city, state, zip]);
                                }}
                                error={
                                    (hasNewAddress &&
                                        [
                                            address,
                                            street,
                                            city,
                                            state,
                                            zip,
                                            lat,
                                            long,
                                            'distance',
                                            'miles',
                                            'duration_seconds',
                                        ].some((attr) => hasError[attr] && (startDirty || isDirty[attr]))) ||
                                    order[geocode_failed] ||
                                    order.auto_assign_failed
                                }
                            />
                        )}
                    </Row>
                    <Row>
                        <TextField
                            variant="outlined"
                            color="primary"
                            label="Unit/Suite #"
                            value={order[unit]}
                            onChange={(e) => callbacks.modifyOrder({ [unit]: e.target.value })}
                            fullWidth
                        />
                    </Row>
                    <Row
                        css={css`
                            margin-top: 16px;
                        `}
                    >
                        <Grid
                            container
                            item
                            css={css`
                                flex-grow: 1;
                                margin-right: 16px;
                            `}
                        >
                            <TextField
                                fullWidth
                                label="City"
                                variant="outlined"
                                color="primary"
                                value={order[city]}
                                error={hasError[city] && order[is_custom] && (startDirty || isDirty[city])}
                                disabled={!order[is_custom]}
                                onBlur={() => callbacks.makeDirty([city])}
                                onChange={(e) => callbacks.modifyOrder({ [city]: e.target.value })}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            css={css`
                                flex-grow: 1;
                                margin-right: 16px;
                            `}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="primary"
                                label="State"
                                disabled={!order[is_custom]}
                                error={hasError[state] && order[is_custom] && (startDirty || isDirty[state])}
                                value={order[state]}
                                onBlur={() => callbacks.makeDirty([state])}
                                onChange={(e) => callbacks.modifyOrder({ [state]: e.target.value })}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            css={css`
                                flex-grow: 1;
                            `}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="primary"
                                label="Zip"
                                value={order[zip]}
                                error={hasError[zip] && order[is_custom] && (startDirty || isDirty[state])}
                                disabled={!order[is_custom]}
                                onBlur={() => callbacks.makeDirty([zip])}
                                onChange={(e) => callbacks.modifyOrder({ [zip]: e.target.value })}
                            />
                        </Grid>
                    </Row>

                    {allowCustom ? (
                        <Row>
                            <Grid
                                container
                                item
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    justify-content: center;
                                `}
                            >
                                <OnwardToggle
                                    css={css`
                                        margin: 0;
                                    `}
                                    disabled={disableAddressEditing}
                                    value={order[is_custom] || false}
                                    onChange={(e) => {
                                        callbacks.modifyOrder({
                                            [is_custom]: e.target.checked,
                                            [geocode_failed]: false,
                                        });
                                        callbacks.makeDirty([address, street]);
                                    }}
                                />
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    margin-left: 12px;
                                    justify-content: center;
                                `}
                            >
                                <Grid container item>
                                    <RadioLabelInfo
                                        css={css`
                                            color: #000;
                                        `}
                                    >
                                        Having trouble with your address?&nbsp;
                                        <a
                                            css={css`
                                                cursor: pointer;
                                            `}
                                            onClick={() => {
                                                callbacks.modifyOrder({
                                                    [is_custom]: true,
                                                    [geocode_failed]: false,
                                                });
                                                callbacks.makeDirty([address, street]);
                                            }}
                                        >
                                            Place a pin on the map
                                        </a>
                                    </RadioLabelInfo>
                                </Grid>

                                {order[is_custom] ? (
                                    <Grid
                                        container
                                        item
                                        css={css`
                                            margin-top: 8px;
                                        `}
                                    >
                                        <ToggleBlurb />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Row>
                    ) : null}

                    {!order[is_custom] && !disableGeocoding ? (
                        <Grid item>
                            <PrimaryButton
                                css={css`
                                    color: #fff;
                                `}
                                onClick={() => setViewMap((prevState) => !prevState)}
                                variant="contained"
                                color="primary"
                            >
                                {viewMap ? 'Hide Map' : 'Find Address on Map'}
                            </PrimaryButton>
                        </Grid>
                    ) : null}

                    {viewMap && (
                        <Grid
                            item
                            css={css`
                                height: 350px;
                                margin-top: 24px;
                                border-radius: 6px;
                                border: ${hasError[lat] || hasError[long]
                                    ? `1px solid #f44336;`
                                    : '1px solid transparent;'};
                            `}
                        >
                            <GoogleMap
                                zoom={15}
                                onClick={(e) => {
                                    if (disableGeocoding) {
                                        const x = e.latLng.lat();
                                        const y = e.latLng.lng();

                                        callbacks.modifyOrder({
                                            [lat]: x,
                                            [long]: y,
                                        });
                                    } else {
                                        callbacks.geocodeLatLong(e);
                                    }

                                    callbacks.makeDirty([lat, long]);
                                }}
                                draggableCursor="pointer"
                                gestureHandling="greedy"
                                center={coords ? coords : undefined}
                            >
                                {coords && <Marker position={coords} />}
                            </GoogleMap>
                        </Grid>
                    )}

                    <Grid
                        container
                        css={css`
                            margin-top: 32px;
                        `}
                        xs={12}
                    >
                        <Grid
                            container
                            item
                            xs={4}
                            css={css`
                                margin-right: 16px;
                            `}
                        >
                            <LocationType
                                value={order[location]}
                                onChange={(e) => {
                                    callbacks.modifyOrder({ [location]: e.target.value });
                                    callbacks.makeDirty([location]);
                                }}
                                error={hasError[location] && (startDirty || isDirty[location])}
                            />
                        </Grid>
                        <Grid container item xs={4}>
                            <PickupType
                                disabled={!order[location] || order[location].length === 0}
                                value={order[location_type]}
                                locationType={order[location]}
                                onChange={(e) => {
                                    callbacks.modifyOrder({ [location_type]: e.target.value });
                                    callbacks.makeDirty([location_type]);
                                }}
                                error={hasError[location_type] && (startDirty || isDirty[location_type])}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : null}
            {shipping_partners.length > 0 && isInternal ? (
                <Grid item className="mt-3" container alignItems="center" xs={6}>
                    <Grid item xs={11}>
                        <TextField
                            select
                            variant="outlined"
                            fullWidth
                            label="Shipper"
                            value={order.shipper_id || ''}
                            onChange={(e) => {
                                const shipper_id = e.target.value;
                                callbacks.modifyOrder({
                                    shipper_id,
                                    carrier_id: user_id !== shipper_id ? user_id : null,
                                });
                            }}
                        >
                            <MenuItem value={user_id}>None</MenuItem>
                            {shipping_partners.map((shipper) => (
                                <MenuItem key={shipper.shipper_id} value={shipper.shipper_id}>
                                    {shipper.shipper.business_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={1} container justifyContent="center">
                        <Tooltip
                            style={{ color: '#59B863' }}
                            title={
                                'Here you can choose a partnered shipper for all orders in this current import. Changing this value will overwrite any already existing partner shipper selections you have made on individual orders.'
                            }
                            placement="top"
                        >
                            <Info />
                        </Tooltip>
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default PickupTab;
