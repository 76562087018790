export const RECURRING_TYPES = {
    OVERRIDE: 'OVERRIDE',
    EVERYDAY: 'DAILY',
    WEEKDAYS: 'WEEKDAYS',
    WEEKLY: 'WEEKLY',
    BIWEEKLY: 'BIWEEKLY',
    MONTHLY: 'MONTHLY',
};

export const RECURRING_TYPE_DISPLAYS = {
    [RECURRING_TYPES.OVERRIDE]: 'Does not repeat',
    [RECURRING_TYPES.MONTHLY]: 'Monthly',
    [RECURRING_TYPES.EVERYDAY]: 'Everyday',
    [RECURRING_TYPES.WEEKDAYS]: 'Weekdays',
    [RECURRING_TYPES.WEEKLY]: 'Weekly',
    [RECURRING_TYPES.BIWEEKLY]: 'Biweekly',
};

export const UPDATE_TYPES = {
    ONE_OFF: 'ONE_OFF',
    SPLIT: 'SPLIT',
    OVERWRITE: 'OVERWRITE',
    DELETE_RESOURCE: 'DELETE_RESOURCE',
};

export const UPDATE_TYPE_TITLES = {
    [UPDATE_TYPES.ONE_OFF]: {
        title: 'This resouce',
    },
    [UPDATE_TYPES.SPLIT]: {
        title: 'This resource and following resources',
        subtitle: 'All recurring resources after day selected',
    },
    [UPDATE_TYPES.OVERWRITE]: {
        title: 'All resources',
        subtitle: 'All recurring resources from today and on',
    },
    [UPDATE_TYPES.DELETE_RESOURCE]: {
        title: 'Delete entire resource',
        subtitle: 'Completely erase. Cannot be undone',
    },
};

export const STEPS = {
    RESOURCE: 'RESOURCE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
};
