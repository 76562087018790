import React, { useMemo } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { genAttributes, STEPS, STEP_MAPPINGS } from '@onward-delivery/core';

export const useFormatters = (order) => {
    const dateNumeric = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });

    const dateShort = useMemo(() => {
        const { zip } = genAttributes(order);
        const tz = zipcode_to_timezone.lookup(order[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;

        return new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: tz,
            timeZoneName: 'short',
        });
    }, [order]);

    const timeShort = useMemo(() => {
        const { zip } = genAttributes(order);
        const tz = zipcode_to_timezone.lookup(order[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;

        return new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: tz,
            timeZoneName: 'short',
        });
    }, [order]);

    return {
        timeShort,
        dateShort,
        dateNumeric,
    };
};
