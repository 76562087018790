import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import Password from '../Password';
import AccountDetails from './AccountDetails';
import LoadPreferences from './LoadPreferences';
import Warehouse from './Warehouse';
import Documents from './Documents';
import Teammates from '../Teammates';
import TariffTable from '../TariffTable';
import AccountCardSetup from '../ShipperAccount/AccountCardSetup';
import QboRefs from './QboRefs';
import api from '@/utilities/api';
import * as Sentry from '@sentry/react';
import _ from 'lodash';

import { Tooltip } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { UserContext } from '@/components/App';
import { LIST_TARIFFS_BY_CLIENT_ID, UPSERT_PRICING_OVERRIDES } from '../Tariffs/graphql';
import { QUERY_BY_PK_WITH_USER } from '@/graphql/queries/clients';
import { AGGREGATE_RATING_BY_REVIEWEE } from '@/graphql/queries/ratings';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { UPDATE_CARRIER_DEFAULT_STATE_FILTER } from '../mutations';
import Trucks from '../Trucks';
import Stores from '../ShipperAccount/Stores';
import ShippingPartners from '../ShippingPartners';

function CarrierAccount() {
    const [currentCarrier, setCurrentCarrier] = useState({});
    const { user, sidebarCollapsed, superUser } = useContext(UserContext);

    const [fetchTariffs, { data }] = useLazyQuery(LIST_TARIFFS_BY_CLIENT_ID, {
        variables: {
            client_id: user.user_id,
        },
    });

    const [updateCarrierDefaultStateFilter] = useMutation(UPDATE_CARRIER_DEFAULT_STATE_FILTER, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const [upsertPo] = useMutation(UPSERT_PRICING_OVERRIDES, {
        update: (cache, { data: { updated } }) => {
            cache.updateQuery(
                {
                    query: LIST_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: user.user_id,
                    },
                },
                (data) => {
                    let { tariffs, overrides } = data;
                    let clone = [...overrides];
                    const [update] = updated;

                    const idx = clone.findIndex((po) => po.pricing_override_id === update.pricing_override_id);
                    if (idx >= 0) {
                        clone[idx] = update;
                    } else {
                        clone = [...clone, update];
                    }

                    return {
                        tariffs,
                        overrides: clone,
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
        },
    });

    const [fetchOverallRating, { data: ratings }] = useLazyQuery(AGGREGATE_RATING_BY_REVIEWEE);

    const {
        loading: userLoading,
        error: userError,
        data: userData,
    } = useQuery(QUERY_BY_PK_WITH_USER, {
        variables: { client_id: user.user_id },

        onCompleted: async (data) => {
            let client = data.clients_by_pk;

            if (client) {
                if (client.default_filter_by_my_state === undefined || client.default_filter_by_my_state === null) {
                    await updateCarrierDefaultStateFilter({
                        variables: {
                            client_id: user.user_id,
                            defaultStateFilter: true,
                        },
                    });
                    client = _.cloneDeep(client);
                    client.default_filter_by_my_state = true;
                }
                setCurrentCarrier(client);
            }
        },
        onError: (error) => {
            console.log(error);
        },
    });

    useEffect(() => {
        if (user?.user_id) {
            fetchOverallRating({ variables: { reviewee_id: user.user_id } });
            fetchTariffs();
        }
    }, [user]);

    const [tariffs, overrides] = useMemo(() => {
        if (data) {
            const { tariffs, overrides } = data;
            return [tariffs, overrides];
        }

        return [[], []];
    }, [data]);

    const rating = useMemo(() => {
        if (ratings?.results?.aggregate?.avg?.rating) {
            return ratings?.results?.aggregate?.avg?.rating;
        }

        return 0;
    }, [ratings]);

    return (
        <div className={`${sidebarCollapsed && 'collapse-margin'} sidenav-margin-responsive`}>
            <div className="d-flex justify-content-between my-3">
                <h2 className="  accountwidth" style={{ fontWeight: '700' }}>
                    My Account
                </h2>
                {rating >= 3 && (
                    <div className="align-self-center d-flex">
                        <h5 className="text-nowrap">My Rating: </h5>
                        <Tooltip
                            title={rating.toFixed(1) + ' / 5'}
                            arrow
                            PopperProps={{ style: { marginTop: '-10px' } }}
                        >
                            <div>
                                <Rating readOnly value={rating} precision={0.1} />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
            <Tabs defaultActiveKey="Account Details">
                <Tab tabClassName="tabLink" eventKey="Account Details" title="Account Details">
                    <AccountDetails
                        currentCarrierRef={user.user_id}
                        currentCarrier={currentCarrier}
                        adminLoggedIn={Object.keys(superUser).length}
                        user={user}
                    />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Warehouse Details" title="Warehouse Details">
                    <Stores currentClientRef={user.user_id} currentClient={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Trucks" title="Trucks">
                    <Trucks client={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Load Preferences" title="Load Preferences">
                    <LoadPreferences currentCarrier={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Teammates" title="Teammates">
                    <Teammates
                        currentUser={{
                            business: currentCarrier.business_name,
                            email: currentCarrier.email,
                        }}
                        teamLeaderType="carrier"
                        userId={user.user_id}
                    />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Shippers" title="Shippers">
                    <ShippingPartners tariffs={tariffs} overrides={overrides} callbacks={{ upsertPo }} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Tariffs" title="Tariffs">
                    <TariffTable
                        client={currentCarrier}
                        tariffs={tariffs}
                        overrides={overrides}
                        callbacks={{ upsertPo }}
                    />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Documents" title="Documents">
                    <Documents currentCarrier={userData} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Billing" title="Billing">
                    <AccountCardSetup currentClient={currentCarrier} />
                    <QboRefs currentClient={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Password" title="Password">
                    <Password />
                </Tab>
            </Tabs>
        </div>
    );
}

export default CarrierAccount;
