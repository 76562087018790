import { hourMinuteFormatter } from '@/constants/formats';
import { delWindowToFormattedDateRange, delWindowToFormattedDateSingle } from '@/utilities/delWindowToFormattedDate';
import { startCase } from 'lodash';
import React, { useMemo } from 'react';
import { CardHeaderLine, CardHeaderText, CardLine, CardText, IndentedCardLine, ListCard } from './blocks';

const SupplyStopCard = ({ route, stop }) => {
    const remainingCapacity = useMemo(() => {
        let remaining = route.capacity;
        route.activity_stops
            .filter((s) => s.ordering <= stop.ordering)
            .forEach((s) => {
                remaining -= s.cubes;
            });
        return remaining;
    }, [route, stop]);

    return (
        <ListCard>
            <CardHeaderLine>
                <CardHeaderText>
                    {stop.ordering + 1}. {startCase(stop.type.toLowerCase())}
                </CardHeaderText>
            </CardHeaderLine>
            <IndentedCardLine>
                <CardText>
                    {Math.abs(stop.cubes).toFixed(0)} cu ft. / {stop.weight?.toFixed(0) || '-'} lbs
                </CardText>
            </IndentedCardLine>
            <IndentedCardLine>
                <CardText>Remaining Capacity: {remainingCapacity.toFixed(0)} cu ft.</CardText>
            </IndentedCardLine>
            {stop.timeframe_start && stop.timeframe_end && (
                <IndentedCardLine>
                    <CardText>
                        Timeframe: {delWindowToFormattedDateRange(stop.timeframe_start, stop.timeframe_end)}
                    </CardText>
                </IndentedCardLine>
            )}
            {stop.arrival_time && (
                <IndentedCardLine>
                    <CardText>Arrival Time: {delWindowToFormattedDateSingle(stop.arrival_time)}</CardText>
                </IndentedCardLine>
            )}
            {stop.service_time && (
                <IndentedCardLine>
                    <CardText>Service Time: {hourMinuteFormatter.format(stop.service_time / 60000)}</CardText>
                </IndentedCardLine>
            )}
        </ListCard>
    );
};

export default SupplyStopCard;
