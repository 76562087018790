import React, { useContext, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';

function Footer({ state, callbacks }) {
    return (
        <>
            <Grid
                container
                css={css`
                    padding: 1rem;
                    background-color: white;
                    justify-content: center;
                    align-items: center;
                `}
            >
                {state.invoiceType === 'INTERNAL' && state.status === 'UNPAID' ? (
                    <Grid
                        item
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        <PrimaryButton onClick={callbacks.setPaid} disabled={state.selected.length === 0}>
                            Mark as Paid
                        </PrimaryButton>
                    </Grid>
                ) : null}

                <Grid
                    item
                    css={css`
                        margin-right: 1rem;
                    `}
                >
                    <PrimaryButton onClick={callbacks.exportSelected} disabled={state.selected.length === 0}>
                        Export
                    </PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}

export default Footer;
