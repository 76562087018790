import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { Grid, DialogContent, IconButton } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { Close } from '@material-ui/icons';
import { ResponsiveSidebarDialog } from '@/styles/blocks';
import { colors } from '@/styles';
import { toNational } from '@/utilities/formatPhoneNumber';

import { SUPPORT_PHONE_NUMBER } from '../constants';
import { BodyDescription1 } from '../blocks';
import { Context } from '../store';

const ErrorModal = ({ open, callbacks }) => {
    const { state } = useContext(Context);
    return (
        <ResponsiveSidebarDialog open={open} onClose={callbacks.onClose} fullWidth={true} maxWidth="sm">
            <DialogContent
                css={css`
                    width: 100%;

                    &.MuiDialogContent-root {
                        padding: 42px;
                        padding-top: 24px;
                    }
                `}
            >
                <Grid
                    container
                    css={css`
                        justify-content: flex-end;
                        margin-bottom: 20px;
                    `}
                >
                    <Grid item direction="column">
                        <IconButton
                            css={css`
                                padding: 4px;
                            `}
                            onClick={callbacks.onClose}
                        >
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid
                        container
                        css={css`
                            background: #d23e3e;
                            padding: 24px 17px;
                            color: ${colors.white.primary};
                        `}
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            css={css`
                                flex: 0;
                                flex-basis: 0;
                                align-items: center;
                                justify-content: center;
                                margin-right: 16px;
                            `}
                        >
                            <WarningIcon />
                        </Grid>
                        <Grid
                            item
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                            `}
                        >
                            <BodyDescription1>
                                {`Something went wrong when trying to create your delivery. Please try again or contact
                                support at ${toNational(SUPPORT_PHONE_NUMBER)}. ${
                                    state.errorInfo ? 'Reason for error: ' + state.errorInfo : ''
                                }`}
                            </BodyDescription1>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </ResponsiveSidebarDialog>
    );
};

export default ErrorModal;
