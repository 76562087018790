import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, TextField, Button, MenuItem, Typography, useMediaQuery, Paper } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { ThumbUp, ThumbDown } from '@material-ui/icons';
import * as ROUTES from '../../constants/routes';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { css } from '@emotion/react';
import { Body1, Body2, Body3, InputLabel1, Title1, Title2, Title3, Price, FormContainer } from './blocks';
import itemTypes from '../NewShipmentV4/constants/itemTypes';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import api from '@/utilities/api';
import hubspotControls from '@/utilities/hubspotControls';
import { PrimaryButton } from '@/styles/blocks';
import { Alert } from '@material-ui/lab';
import { colors } from '@/styles';
import { isNumber } from 'lodash';
const { household: householdItemTypes } = itemTypes;

function InstantQuote() {
    const [loading, setLoading] = useState(false);
    const [contactLoading, setContactLoading] = useState(false);
    const [contactSubmitted, setContactSubmitted] = useState(false);
    const [quoteErrors, setQuoteErrors] = useState([]);
    const [contactErrors, setContactErrors] = useState([]);
    const [quoteValidated, setQuoteValidated] = useState(false);
    const [contactValidated, setContactValidated] = useState(false);
    const [pickupZipcode, setPickupZipcode] = useState('');
    const [dropoffZipcode, setDropoffZipcode] = useState('');
    const [itemType, setItemType] = useState(Object.entries(householdItemTypes)[0][1].value);
    const [locationType, setLocationType] = useState(Object.entries(LOCATION_TYPES)[0][0]);
    const [numberOfItems, setNumberOfItems] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [thumbsUpSent, setThumbsUpSent] = useState(false);
    const [thumbsDownSent, setThumbsDownSent] = useState(false);
    const [quote, setQuote] = useState('');
    const analytics = getAnalytics();
    const isDesktop = useMediaQuery('(min-width: 600px)');

    useEffect(() => {
        if (window.self !== window.top || !isDesktop) {
            hubspotControls.hide();
        }

        return () => {
            hubspotControls.show();
        };
    }, []);

    const onQuoteSubmit = async (event) => {
        event.preventDefault();
        setQuoteValidated(true);

        if (!pickupZipcode || !dropoffZipcode || !numberOfItems) {
            setQuoteErrors(['Please fill out all required fields']);
            setLoading(false);
            return;
        }

        const params = {
            pickupZipcode,
            dropoffZipcode,
            itemType,
            locationType,
            numberOfItems,
            totalWeight,
        };

        setLoading(true);
        setQuoteErrors([]);
        const quoteResult = await api.post({
            routeName: 'instantQuote',
            data: params,
        });

        if (quoteResult && isNumber(quoteResult)) {
            setQuote(quoteResult);
            logEvent(analytics, 'quote_success', {
                ...params,
                quoteResult,
            });
        } else {
            logEvent(analytics, 'quote_failure', params);
            setQuoteErrors([
                'Sorry, we could not estimate a quote with the given info! Please reach out to our sales team to get an estimate!',
            ]);
        }
        setLoading(false);
    };

    const onContactSubmit = async (event) => {
        event.preventDefault();
        setContactValidated(true);

        if (!name || !company || !email || !phone) {
            setContactErrors(['Please fill out all required fields']);
            return;
        }

        setContactLoading(true);
        setContactErrors([]);
        const result = await api.post({
            routeName: 'sendSlackMessage',
            data: {
                channel: 'contact-us',
                message: `${name} from ${company} is requesting to contact us! Email: ${email}, Phone: ${phone}`,
            },
        });
        if (result.error) {
            setContactErrors(['Sorry, something went wrong.']);
        } else {
            setContactSubmitted(true);
            setContactErrors([]);
        }
        setContactLoading(false);
    };

    const onThumbsUp = async () => {
        setThumbsUpSent(true);
        setThumbsDownSent(false);
        logEvent(analytics, 'price_quote_thumbs_up');
    };

    const onThumbsDown = () => {
        setThumbsDownSent(true);
        setThumbsUpSent(false);
        logEvent(analytics, 'price_quote_thumbs_down');
    };

    const inputStyles = css`
        width: 100%;
        ${isDesktop ? 'max-width: 300px;' : ''}
    `;

    const textRowStyles = css`
        padding: 0 0.5rem;
    `;

    const formRowStyles = css`
        padding: 0.5rem;
    `;

    const rowCentered = css`
        padding: 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-wrap: balance;
    `;

    const paperRowStyles = css`
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-wrap: balance;
    `;

    return (
        <Grid
            container
            css={css`
                width: 100%;
                height: 100%;
            `}
        >
            <Grid item xs={1} />
            <Grid item xs={10}>
                <Grid
                    container
                    css={css`
                        flex-direction: column;
                        flex-wrap: nowrap;
                        width: fit-content;
                        max-width: calc(600px + 2rem);
                        padding: 3rem 0;
                    `}
                >
                    <Title1 css={textRowStyles}>Instant Quote Calculator</Title1>
                    <Body1 css={textRowStyles}>Per delivery estimated quotes</Body1>
                    <FormContainer>
                        {!!quoteErrors.length && (
                            <Alert
                                severity="error"
                                css={css`
                                    width: 100%;
                                `}
                            >
                                {quoteErrors.map((error) => (
                                    <span>{error}</span>
                                ))}
                            </Alert>
                        )}
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Pickup Zip Code</InputLabel1>
                            <TextField
                                variant="outlined"
                                required
                                size="small"
                                type="number"
                                error={!pickupZipcode && quoteValidated}
                                value={pickupZipcode}
                                onChange={(e) => setPickupZipcode(e.target.value)}
                                css={inputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Delivery Zip Code</InputLabel1>
                            <TextField
                                variant="outlined"
                                required
                                size="small"
                                type="number"
                                error={!dropoffZipcode && quoteValidated}
                                value={dropoffZipcode}
                                onChange={(e) => setDropoffZipcode(e.target.value)}
                                css={inputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Delivery Type *</InputLabel1>
                            <TextField
                                select
                                variant="outlined"
                                required
                                size="small"
                                value={locationType}
                                onChange={(e) => setLocationType(e.target.value)}
                                css={inputStyles}
                            >
                                {Object.entries(LOCATION_TYPES).map(([key, location]) => (
                                    <MenuItem key={key} value={key}>
                                        {location}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Item Type *</InputLabel1>
                            <TextField
                                select
                                variant="outlined"
                                required
                                size="small"
                                value={itemType}
                                onChange={(e) => setItemType(e.target.value)}
                                css={inputStyles}
                            >
                                {Object.entries(householdItemTypes).map(([key, item]) => (
                                    <MenuItem key={key} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Number of Items *</InputLabel1>
                            <TextField
                                variant="outlined"
                                required
                                size="small"
                                type="number"
                                error={!numberOfItems && quoteValidated}
                                value={numberOfItems}
                                onChange={(e) => setNumberOfItems(e.target.value)}
                                css={inputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Total Weight</InputLabel1>
                            <TextField
                                variant="outlined"
                                required
                                size="small"
                                value={totalWeight}
                                type="number"
                                onChange={(e) => setTotalWeight(e.target.value)}
                                css={inputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} css={formRowStyles}>
                            <PrimaryButton
                                onClick={onQuoteSubmit}
                                disabled={loading}
                                css={css`
                                    width: 100%;
                                `}
                            >
                                Get Quote&nbsp; {loading && <Spinner size="sm" />}
                            </PrimaryButton>
                        </Grid>
                    </FormContainer>
                    {quote && (
                        <FormContainer>
                            <Grid item xs={12} css={rowCentered}>
                                <span>
                                    <Title3>Total Estimated Cost:&nbsp;</Title3>
                                    <Price>${quote}</Price>
                                </span>
                            </Grid>
                            <Grid item xs={12} css={rowCentered}>
                                <Body3>
                                    Note: This is not a locked price. Variables such as white glove delivery, flights of
                                    stairs, size and weight of items may impact price.
                                </Body3>
                            </Grid>

                            <Grid item xs={12} css={rowCentered}>
                                <Title3>Like the price?&nbsp;</Title3>
                            </Grid>

                            <Grid item xs={12} css={rowCentered}>
                                {thumbsUpSent ? (
                                    <Button variant="contained" color="primary">
                                        <ThumbUp />
                                    </Button>
                                ) : (
                                    <Button variant="outlined" onClick={() => onThumbsUp()}>
                                        <ThumbUp />
                                    </Button>
                                )}
                                {thumbsDownSent ? (
                                    <Button variant="contained" color="primary" style={{ marginLeft: '12px' }}>
                                        <ThumbDown />
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        style={{ marginLeft: '12px' }}
                                        onClick={() => onThumbsDown()}
                                    >
                                        <ThumbDown />
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} css={rowCentered}>
                                {(thumbsUpSent || thumbsDownSent) && <Body3>Thanks for your input!</Body3>}
                            </Grid>
                        </FormContainer>
                    )}
                    <FormContainer>
                        <Grid item xs={12} css={rowCentered}>
                            <Title2>{isDesktop ? 'Questions about the price?' : 'Questions?'}</Title2>
                        </Grid>
                        <Grid item xs={12} css={rowCentered}>
                            <Body2>
                                Give us your contact info, and we'll call you within 24 hours or contact our sales team
                                at{' '}
                                <a
                                    href="tel:+17206638315"
                                    css={css`
                                        color: ${colors.greens.primary};
                                    `}
                                >
                                    (720) 663-8315
                                </a>
                            </Body2>
                        </Grid>
                        {!!contactErrors.length && (
                            <Alert
                                severity="error"
                                css={css`
                                    width: 100%;
                                `}
                            >
                                {contactErrors.map((error) => (
                                    <span>{error}</span>
                                ))}
                            </Alert>
                        )}
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Name *</InputLabel1>
                            <TextField
                                variant="outlined"
                                size="small"
                                required
                                error={!name && contactValidated}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                css={inputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Company *</InputLabel1>
                            <TextField
                                variant="outlined"
                                size="small"
                                required
                                error={!company && contactValidated}
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                css={inputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Email *</InputLabel1>
                            <TextField
                                variant="outlined"
                                size="small"
                                required
                                error={!email && contactValidated}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                css={inputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} css={formRowStyles}>
                            <InputLabel1>Phone *</InputLabel1>
                            <TextField
                                variant="outlined"
                                size="small"
                                required
                                error={!phone && contactValidated}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                css={inputStyles}
                            />
                        </Grid>
                        <Grid item xs={12} css={formRowStyles}>
                            <PrimaryButton
                                onClick={onContactSubmit}
                                disabled={contactLoading}
                                css={css`
                                    width: 100%;
                                `}
                            >
                                Contact Us&nbsp; {contactLoading && <Spinner size="sm" />}
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={12} css={rowCentered}>
                            <Body2>
                                By providing a telephone number and submitting the form you are consenting to be
                                contacted by SMS text message. Message & data rates may apply. Reply STOP to opt out of
                                further messaging.{' '}
                                <a
                                    href="https://www.onwarddelivery.com/terms-and-conditions"
                                    css={css`
                                        color: ${colors.greens.primary};
                                    `}
                                >
                                    Full terms and conditions
                                </a>
                            </Body2>
                        </Grid>
                        <Grid item xs={12} css={rowCentered}>
                            {contactSubmitted && <Body3>Thank you! We'll be in touch shortly.</Body3>}
                        </Grid>
                    </FormContainer>
                    <FormContainer>
                        <Paper
                            css={css`
                                background-color: lightgrey;
                                border-radius: 8px;
                                padding: 1rem 0.5rem;
                                margin: 0 0.5rem;
                                width: 100%;
                            `}
                        >
                            <Grid item xs={12} css={paperRowStyles}>
                                <Title3>Get Started</Title3>
                            </Grid>
                            <Grid item xs={12} css={paperRowStyles}>
                                <Body1>Create an account today and get your orders on the marketplace</Body1>
                            </Grid>
                            <Grid item xs={12} css={paperRowStyles}>
                                <PrimaryButton component={Link} to={ROUTES.SHIPPER_SIGN_UP}>
                                    Create Account
                                </PrimaryButton>
                            </Grid>
                        </Paper>
                    </FormContainer>
                </Grid>
            </Grid>
            <Grid item xs={1} />
        </Grid>
    );
}

export default InstantQuote;
