import { colors } from '@/styles';
import { CircularProgress } from '@material-ui/core';
import { css } from '@emotion/react';
import React from 'react';

export default function Loader() {
    return (
        <div
            css={css`
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            `}
        >
            <CircularProgress
                size={100}
                css={css`
                    color: ${colors.greens.primary};
                `}
            />
        </div>
    );
}
