import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { css } from '@emotion/react';
import { colors } from '@/styles';

function DriverCombobox({
    helper = false,
    drivers = [],
    onChange,
    value,
    containerStyles = {},
    clashingDriver = null,
    disabled,
    otherProps,
}) {
    let sortedDrivers = [...drivers].sort((a, b) => {
        if (a.username < b.username) {
            return -1;
        }
        if (a.username > b.username) {
            return 1;
        }
        return 0;
    });

    const driverOptions = [
        { teammate_id: 'NONE', username: `Search ${helper ? 'helpers' : 'drivers'}` },
        ...sortedDrivers,
    ];

    return (
        <Autocomplete
            id="driver-combobox"
            css={css`
                ${containerStyles}

                display: flex;
                align-items: center;
                flex-grow: 1;

                & .MuiInputBase-root {
                    padding: 0 !important;
                    color: ${clashingDriver ? '#dc3545' : disabled ? colors.greys.primary : 'inherit'};
                    font-size: 14px;
                }
            `}
            options={driverOptions}
            getOptionLabel={(option) => option.username}
            disableClearable
            renderInput={(params) => (
                <TextField
                    {...params}
                    disableLabel
                    variant="outlined"
                    error={!!clashingDriver}
                    css={css`
                        & .MuiAutocomplete-root {
                            display: flex;
                            align-items: center;
                            flex-grow: 1;
                        }
                    `}
                />
            )}
            value={driverOptions.find((d) => d.teammate_id === value)}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            disabled={disabled}
        />
    );
}

export default DriverCombobox;
