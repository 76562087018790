import React, { useContext, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    Grid,
    Switch,
    FormGroup,
    FormControlLabel,
    IconButton,
    Popper,
    ClickAwayListener,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { PlanningContext } from './context';
import { Body1, Body2, H2, PlanningIconButton, PrimaryButton } from './blocks';
import { useClientUser } from '@/hooks/useClientUser';
import { SettingsOutlined } from '@material-ui/icons';
import { MODALS } from './constants';
import routeStatusOptions from '@/constants/routeStatusOptions';
import { useItemQuantity, useTotalCubes } from '@/hooks';
import { integerFormatter } from '@/constants/formats';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import styled from '@emotion/styled';
import { PLAN_PAGE_LOADBOARD, PLAN_PAGE_NETWORK_ROUTES } from '@/constants/featureFlags';
import { colors } from '@/styles';
import Settings from '@material-ui/icons/Settings';
import DriverCombobox from '../misc/DriverCombobox';

const ToggleLabel = styled.span`
    font-size: 16px;
    font-weight: 500;
`;

const PlanningHeader = () => {
    const {
        deliveryDate,
        updateDeliveryDate,
        setModalOpen,
        showAllDirections,
        toggleShowAllDirections,
        directionLoading: { complete, total },
        showLoadBoardLoads,
        toggleShowLoadBoardLoads,
        showUnscheduledOrders,
        toggleShowUnscheduledOrders,
        showNetworkRoutes,
        toggleShowNetworkRoutes,
        state: { orders, routes, loading, selectedDrivers },
        callbacks,
    } = useContext(PlanningContext);
    const { circles, drivers } = useClientUser();

    const [anchor, setAnchor] = useState(null);

    const driversByKey = useMemo(() => {
        return Object.fromEntries((drivers || []).map((driver) => [driver.teammate_id, driver]));
    }, [drivers]);

    const lockedRoutes = routes.filter((route) => route && route.status !== routeStatusOptions.PLANNING.value).length;
    const linkedOrders = useMemo(() => {
        return routes.reduce((acc, route) => [...acc, ...route.orders.map((mapping) => mapping.order)], []);
    }, [routes]);

    const totalItems = useItemQuantity(orders);
    const totalLinkedItems = useItemQuantity(linkedOrders);
    const totalVolume = useTotalCubes(orders);
    const totalLinkeVolume = useTotalCubes(linkedOrders);
    const unconfirmedDropoffs = useMemo(() => {
        return routes.reduce((prevVal, currRoute) => {
            const routeUnconfirmedDropoffs = currRoute?.stopsByRouteId?.reduce((accumulator, stop) => {
                if (FTLStopHelpers.isCustomerStop(stop, currRoute)) {
                    const stopOrders =
                        currRoute?.orders
                            ?.filter((mapping) => stop?.orders?.includes(mapping.order_id))
                            ?.map((mapping) => mapping.order) || [];

                    // If every order on stop is not confirmed, increment number of unconfirmed stops on route.
                    if (!stopOrders.every((stopOrder) => stopOrder?.delivery_time_confirmed === 'confirmed')) {
                        accumulator++;
                    }
                }
                return accumulator;
            }, 0);
            return prevVal + routeUnconfirmedDropoffs;
        }, 0);
    }, [routes]);

    const planningStatistics = useMemo(() => {
        return [
            `${lockedRoutes}/${routes?.length} Routes Locked`,
            `${unconfirmedDropoffs} Unconfirmed Stop${unconfirmedDropoffs !== 1 ? 's' : ''}`,
            `${integerFormatter.format(totalItems - totalLinkedItems)} Items`,
            `${integerFormatter.format(totalVolume - totalLinkeVolume)} cu ft.`,
            `${(orders?.length || 0) - (linkedOrders?.length || 0)} Orders`,
        ];
    }, [totalItems, totalVolume, orders, routes, lockedRoutes, unconfirmedDropoffs, linkedOrders]);

    return (
        <Grid container direction="column">
            <Grid
                container
                direction="row"
                alignItems="center"
                css={css`
                    padding: 0.75rem 1rem 0.75rem 2rem;
                    background: white;
                    border: 1px solid #e2e2e2;
                    flex-wrap: nowrap;
                `}
            >
                <Grid
                    container
                    css={css`
                        align-items: center;
                        justify-content: flex-start;
                    `}
                >
                    <Grid item>
                        <H2
                            css={css`
                                margin: 0;
                            `}
                        >
                            Plan
                        </H2>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-left: 2rem;
                            input {
                                font-size: 14px;
                                font-weight: 500;
                                color: black;
                                padding: 10px;
                            }
                        `}
                    >
                        <TextField
                            type="date"
                            variant="outlined"
                            disabled={loading}
                            value={deliveryDate}
                            onChange={(e) => updateDeliveryDate(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-left: 2rem;
                            width: 300px;
                        `}
                    >
                        <Select
                            css={css`
                                width: 100%;
                                .MuiSelect-select {
                                    padding: 10px;
                                    font-size: 14px;
                                    font-weight: 500;
                                }
                            `}
                            variant="outlined"
                            multiple
                            value={Object.entries(selectedDrivers)
                                .filter(([_, selected]) => !!selected)
                                .map(([driver_id]) => driver_id)}
                            onChange={(e) => {
                                callbacks.setSelectedDrivers(
                                    Object.fromEntries((e.target.value || []).map((driver_id) => [driver_id, true]))
                                );
                            }}
                            renderValue={(selected) =>
                                selected.map((driver_id) => driversByKey[driver_id]?.username).join(', ') ||
                                'Select Driver(s)'
                            }
                            placeholder="Search Driver(s)"
                            displayEmpty
                        >
                            {(drivers || []).map((driver) => (
                                <MenuItem key={driver.teammate_id} value={driver.teammate_id}>
                                    <Checkbox color="primary" checked={!!selectedDrivers[driver.teammate_id]} />
                                    <ListItemText primary={driver.username} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid
                    container
                    css={css`
                        justify-content: flex-end;
                        align-items: center;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        <PrimaryButton onClick={() => setModalOpen(MODALS.ROUTE_EXPORT)}>Export Routes</PrimaryButton>
                    </Grid>
                    <Grid item>
                        <PrimaryButton onClick={(e) => setAnchor((prev) => (prev ? null : e.currentTarget))}>
                            Filter Map
                        </PrimaryButton>
                        <Popper
                            open={Boolean(anchor)}
                            anchorEl={anchor}
                            onClose={() => setAnchor(null)}
                            placement={'bottom-end'}
                        >
                            <ClickAwayListener onClickAway={() => setAnchor(null)}>
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        background-color: #ececec;
                                        border: 1px solid #8d8d8d;
                                        padding: 10px 1px 10px 13px;
                                        border-radius: 5px;
                                    `}
                                >
                                    <Grid item>
                                        <FormGroup>
                                            <FormControlLabel
                                                label={<ToggleLabel>Show All Directions</ToggleLabel>}
                                                control={
                                                    <Switch
                                                        disabled={routes?.length >= 20}
                                                        color="primary"
                                                        checked={showAllDirections}
                                                        onChange={() => toggleShowAllDirections()}
                                                        size="medium"
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    {circles?.['plan-page-unscheduled'] && (
                                        <Grid item>
                                            <FormGroup>
                                                <FormControlLabel
                                                    label={<ToggleLabel>Unscheduled Orders</ToggleLabel>}
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={showUnscheduledOrders}
                                                            onChange={() => toggleShowUnscheduledOrders()}
                                                            size="medium"
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                    )}

                                    {circles?.[PLAN_PAGE_LOADBOARD] && (
                                        <Grid item>
                                            <FormGroup>
                                                <FormControlLabel
                                                    label={<ToggleLabel>Load Board</ToggleLabel>}
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={showLoadBoardLoads}
                                                            onChange={() => toggleShowLoadBoardLoads()}
                                                            size="medium"
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                    )}

                                    {circles?.[PLAN_PAGE_NETWORK_ROUTES] && (
                                        <Grid item>
                                            <FormGroup>
                                                <FormControlLabel
                                                    label={<ToggleLabel>Network Routes</ToggleLabel>}
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={showNetworkRoutes}
                                                            onChange={() => toggleShowNetworkRoutes()}
                                                            size="medium"
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                    )}
                                </Grid>
                            </ClickAwayListener>
                        </Popper>
                    </Grid>
                    <Grid item>
                        <PlanningIconButton>
                            <Settings
                                css={css`
                                    color: ${colors.greens.primary};
                                    width: 32px;
                                    height: 32px;
                                `}
                                onClick={() => setModalOpen(MODALS.SETTINGS)}
                            />
                        </PlanningIconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="center"
                css={css`
                    padding: 0.75rem 1rem 0.75rem 2rem;
                    background: white;
                    border-bottom: 1px solid #e2e2e2;
                    flex-wrap: nowrap;
                    white-space: pre;
                `}
            >
                <Grid item>
                    <Body2>{planningStatistics.join(`  •  `)}</Body2>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PlanningHeader;
