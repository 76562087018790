export default function generateCSV(schema, data) {
    let content = [schema.map((col) => col.header).join(',')];

    data.forEach((row) => {
        content.push(
            schema
                .map((col) => {
                    const formatter = col.format || ((x) => x);
                    const value = col.getValue(row);
                    const placeholder = col.default || '';

                    return value !== undefined && value !== null ? formatter(value, row.opt || {}) : placeholder;
                })
                .join(',')
        );
    });

    return new Blob([content.join('\n')], { type: 'text/csv;charset=utf-8;' });
}
