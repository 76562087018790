import React, { useState, useEffect } from 'react';
import { Row, Container, Card, Col } from 'react-bootstrap';
import { LocationPermissionsTitle, LocationPermissionsTitleSpan, LocationPermissionsText, CardSubtext } from './blocks';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_CLIENT_DATA_SHARE } from './mutations';
import { css } from '@emotion/react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { useClientUser } from '@/hooks';
import { Grid } from '@material-ui/core';
import { OnwardToggle } from '../ShipmentForm/blocks';

export default function DataSharing({dataSharing}) {
    const user = useClientUser();
    const [dataShare, setDataShare] = useState(false);
    useEffect(() => {
            setDataShare(dataSharing);
    }, [dataSharing]);
    const updateDataShare = () => {
        updateUserDataShare({
            variables: {
                data_share: !dataShare,
                user_id: user.user_id
            }
        });
        setDataShare(!dataShare);
    }
    const [updateUserDataShare] = useMutation(UPDATE_CLIENT_DATA_SHARE);
    return (
        <>
            <Card
                css={css`
                    margin-top: 30px;
                `}
            >
                <Card.Body
                    css={css`
                        padding: 28px 26px 28px 26px;
                    `}
                >
                    <LocationPermissionsTitle>
                        <LocationPermissionsTitleSpan>Data Sharing Preferences</LocationPermissionsTitleSpan>
                    </LocationPermissionsTitle>
                    <Grid
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 30px;
                        `}
                    >
                        <Grid
                            container
                            css={css`
                                flex-wrap: nowrap;
                            `}
                        >
                            <Grid item>
                                <OnwardToggle value={dataShare} onChange={() => updateDataShare()} />
                            </Grid>
                            <Grid
                                item
                                container
                                direction="column"
                                css={css`
                                    margin-left: 13px;
                                `}
                            >
                                <Grid item>
                                    <LocationPermissionsText>Allow Data Sharing:</LocationPermissionsText>
                                </Grid>
                                <Grid item>
                                    <CardSubtext>
                                        We only share data internally with other users for platform collaboration
                                        purposes. We will never share your data with anyone outside of the Onward
                                        platform.
                                    </CardSubtext>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>
        </>
    );
}
