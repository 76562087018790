import React, { useContext, useEffect, useState } from 'react';
import {
    ModalActions,
    ModalContent,
    ResponsiveSidebarDialog,
    PrimaryButton,
    SecondaryButton,
    Body,
} from '@/styles/blocks';
import { HeaderText } from '../blocks';
import { Context } from '../store';
import { useClientUser } from '@/hooks';
import { MenuItem, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { css } from '@emotion/react';

export default function CrossdockModal({ open, onClose, orderCount }) {
    const { state, callbacks } = useContext(Context);
    const { locations } = useClientUser();
    const [warehouseId, setWarehouseId] = useState(null);
    const [showRevenueOn, setShowRevenueOn] = useState('DROPOFF');

    useEffect(() => {
        if (!warehouseId && locations?.length > 0) {
            setWarehouseId((locations.find((wh) => wh.preferred_inbound) || locations[0]).location_id);
        }
    }, [locations]);

    return (
        <ResponsiveSidebarDialog open={open} onClose={onClose}>
            <ModalContent width={490}>
                <HeaderText>Cross-docking allows you to pick up and drop off an order on separate days.</HeaderText>
                <div>
                    <Body>
                        Are you sure you want to cross-dock{' '}
                        {state.selectedOrders?.length > 1 ? 'these orders' : 'this order'}?
                    </Body>
                </div>
                <div>
                    <Body>
                        By cross-docking orders on this stop:
                        <ul>
                            <li>
                                Once added to a route, a "Final Return" stop will be added at the end of the route to
                                ensure items are brought back to the warehouse.
                            </li>
                        </ul>
                    </Body>
                </div>

                {locations?.length > 0 ? (
                    <TextField
                        variant="outlined"
                        required
                        select
                        label="Cross-dock Warehouse"
                        name="warehouse_id"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={warehouseId || ''}
                        onChange={(e) => setWarehouseId(e.target.value)}
                        css={css`
                            margin: 1rem 0;
                        `}
                    >
                        {locations.map((location) => (
                            <MenuItem key={location.location_id} value={location.location_id}>
                                {location.location_name}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <Alert severity="warning">
                        <Body>You must create a warehouse before cross-docking orders.</Body>
                    </Alert>
                )}
                <TextField
                    variant="outlined"
                    required
                    select
                    label="Order revenue applied to"
                    name="warehouse_id"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={showRevenueOn}
                    onChange={(e) => setShowRevenueOn(e.target.value)}
                    css={css`
                        margin: 1rem 0;
                    `}
                >
                    <MenuItem key={'DROPOFF'} value={'DROPOFF'}>
                        Delivery
                    </MenuItem>
                    <MenuItem key={'PICKUP'} value={'PICKUP'}>
                        Pickup
                    </MenuItem>
                </TextField>

                <ModalActions>
                    <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                    <PrimaryButton
                        disabled={!warehouseId}
                        onClick={() => callbacks.crossdock(state.selectedOrders, warehouseId, showRevenueOn)}
                    >
                        Confirm
                    </PrimaryButton>
                </ModalActions>
            </ModalContent>
        </ResponsiveSidebarDialog>
    );
}
