import { colors, typography } from '@/styles';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Body1 = styled.span`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 500;
    text-wrap: nowrap;
`;

export const Body2 = styled.span`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 700;
`;

export const CardHeader = styled.span`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 900;
    color: ${colors.greys[2]};
`;

export const Status = styled.span`
    ${typography.sansSerif}
    font-size: 14px;
    font-weight: 700;
    color: ${colors.greys[2]};
    border: 3px solid ${colors.greys[2]};
    border-radius: 5px;
    padding: 8px 12px;
`;

export const fragments = {
    grey: css`
        color: ${colors.greys[1]};
    `,
};
