export const calcItemCount = (orders) => {
    const fields = ['items', 'returns'];
    if (orders?.length > 0) {
        return orders.reduce((total, order) => {
            let count = 0;
            fields.forEach((field) => {
                if (order[field]?.length > 0) {
                    order[field].forEach((item) => {
                        count += item.quantity;
                    });
                }
            });

            return total + count;
        }, 0);
    }
    return 0;
};

export const calcAnyItemTypeCount = (items) => {
    if (items?.length > 0) {
        return items.reduce((total, item) => {
            let count = 0;
            count += item.quantity;
            return total + count;
        }, 0);
    }
    return 0;
};

export const calcItemWeight = (orders) => {
    const fields = ['items', 'returns'];
    if (orders?.length > 0) {
        return orders.reduce((totalWeight, order) => {
            let weight = 0;
            fields.forEach((field) => {
                if (order[field]?.length > 0) {
                    order[field].forEach((item) => {
                        weight += item.total_weight;
                    });
                }
            });

            return totalWeight + weight;
        }, 0);
    }

    return 0;
};

export const calcItemVolume = (orders) => {
    const fields = ['items', 'returns'];
    if (orders?.length > 0) {
        return orders.reduce((totalCubes, order) => {
            let cubes = 0;
            fields.forEach((field) => {
                if (order[field]?.length > 0) {
                    order[field].forEach((item) => {
                        cubes += item.total_cubes;
                    });
                }
            });

            return totalCubes + cubes;
        }, 0);
    }

    return 0;
};

export const calcItemVolumeSubscription = (orders) => {
    const fields = ['items', 'returns'];
    if (orders?.length > 0) {
        return orders.reduce((totalCubes, order) => {
            let cubes = 0;
            if (order.itemsByOrderId?.length > 0) {
                order.itemsByOrderId.forEach((item) => {
                    cubes += item.total_cubes;
                });
            }
            return totalCubes + cubes;
        }, 0);
    }

    return 0;
};

export const calcExchangeVolumes = (orders) => {
    const fields = ['items', 'returns', 'haulaway_items'];
    if (orders?.length > 0) {
        return orders.reduce((totalCubes, order) => {
            let returnsCubes = 0;
            let itemsCubes = 0;
            let haulawayCubes = 0;
            fields.forEach((field) => {
                if (order[field]?.length > 0) {
                    order[field].forEach((item) => {
                        if (field === 'returns') {
                            returnsCubes += item.total_cubes;
                        } else if (field === 'items') {
                            itemsCubes += item.total_cubes;
                        }
                        else if (field === 'haulaway_items') {
                            if (item?.cubes_per_unit) {
                                haulawayCubes += item.cubes_per_unit * item.quantity;
                            }
                            else {
                                haulawayCubes += ((item.height * item.width * item.length) / 1728) * item.quantity;
                            }
                        }
                    });
                }
            });

            totalCubes.returnsCubes += returnsCubes;
            totalCubes.itemsCubes += itemsCubes;
            totalCubes.haulawayCubes += haulawayCubes;
            return totalCubes;
        }, { returnsCubes: 0, itemsCubes: 0, haulawayCubes: 0 });
    }

    return 0;
};

export const calcItemWeightSubscription = (orders) => {
    const fields = ['items', 'returns'];
    if (orders?.length > 0) {
        return orders.reduce((totalCubes, order) => {
            let cubes = 0;
            if (order.itemsByOrderId?.length > 0) {
                order.itemsByOrderId.forEach((item) => {
                    cubes += item.total_weight;
                });
            }
            return totalCubes + cubes;
        }, 0);
    }

    return 0;
};
