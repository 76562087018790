import React, { useState, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { colors } from '@/styles';
import { Incrementor } from '@/components/ShipmentForm/blocks';
import { useItemDynamicFields } from '@/components/ShipmentForm/hooks/items';
import { BedSize, AssemblyType } from '@/components/ShipmentForm/modals/InputFields';
import { ITEM_TYPES } from '@/components/ShipmentForm/constants/freightTypes';

import { local, Header1, BodyLabel1, SubHeader1, PrimaryButton, SecondaryButton } from '../blocks';

import Footer from './Footer';
import UploadItemPic from './UploadItemPic';
import ProgressBar from './ProgressBar';

const Item = ({ item, callbacks, idx, itemType, defaultAssembly }) => {
    useItemDynamicFields(item, (changes) => {
        callbacks.modifyItem(changes);
    });

    return (
        <Grid
            container
            direction="row"
            css={css`
                margin-bottom: 30px;
            `}
        >
            <Grid container direction="column">
                <Grid
                    item
                    css={css`
                        margin-bottom: 8px;
                    `}
                >
                    <BodyLabel1
                        css={css`
                            color: ${local.greys[4]};
                        `}
                    >{`${itemType.label} ${idx + 1}`}</BodyLabel1>
                </Grid>

                <Grid
                    wrap="nowrap"
                    container
                    item
                    css={css`
                        align-items: center;
                        margin-bottom: 20px;
                        height: 56px;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-right: 16px;
                        `}
                    >
                        <BodyLabel1>Quantity:</BodyLabel1>
                    </Grid>
                    <Grid item>
                        <Incrementor
                            body={item.quantity}
                            incrementCallback={() => callbacks.modifyItem({ quantity: item.quantity + 1 })}
                            decrementCallback={() => callbacks.modifyItem({ quantity: item.quantity - 1 })}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <BedSize
                        value={{ height: item.height, width: item.width, length: item.length }}
                        itemType={item.item_type_details}
                        InputLabelProps={{ shrink: !!item.height }}
                        onChange={({ height, length, weight, width, display }) =>
                            callbacks.modifyItem({
                                height,
                                length,
                                weight,
                                width,
                                description: `${itemType.label} - ${display}`,
                            })
                        }
                    />
                </Grid>
                {defaultAssembly !== 'no_assembly' && (
                    <Grid
                        item
                        css={css`
                            margin-top: 20px;
                        `}
                    >
                        <AssemblyType
                            value={item.assembly_type}
                            InputLabelProps={{ shrink: !!item.assembly_type }}
                            onChange={(e) => callbacks.modifyItem({ assembly_type: e.target.value })}
                        />
                    </Grid>
                )}
                <Grid item>
                    <UploadItemPic item={item} addPic={(pic) => callbacks.modifyItem({ pic: pic })} />
                </Grid>
            </Grid>
        </Grid>
    );
};

function Dropoff({ isDesktop, callbacks, state, type }) {
    const [section, setSection] = useState(state.sections[state.progression.current] || { itemsByOrderId: [] });
    const [defaultAssembly, setDefaultAssembly] = useState('no_assembly');

    const delService = useMemo(() => {
        return state.order.dropoff_location_type;
    }, [state.order.dropoff_location_type, section]);

    useEffect(() => {
        switch (delService) {
            //defaults set at Nicki's request
            case 'whiteGlove':
                setDefaultAssembly('heavy_assembly');
                break;
            case 'roomOfChoice':
                setDefaultAssembly('minimal_assembly');
                break;
            default:
                setDefaultAssembly('no_assembly');
                break;
        }
    }, [delService]);

    const DEFAULT_BEDDING = useMemo(() => {
        return {
            quantity: 1,
            item_type: 'household',
            is_return: false,
            item_type_details: type,
            assembly_type: defaultAssembly,
        };
    }, [type, defaultAssembly]);

    const hasError = useMemo(() => {
        if (section.itemsByOrderId.length > 0) {
            return section.itemsByOrderId.some((item) => {
                return ['height', 'width', 'length', 'assembly_type'].some((field) => !item[field]);
            });
        }

        return true;
    }, [section]);

    const itemType = useMemo(() => {
        return ITEM_TYPES[state.order.freight_type].find(({ value }) => value === type);
    }, [type]);

    useEffect(() => {
        setSection(state.sections[state.progression.current] || { itemsByOrderId: [{ ...DEFAULT_BEDDING }] });
    }, [DEFAULT_BEDDING]);

    return (
        <Grid
            container
            direction="column"
            css={css`
                color: ${local.greys[3]};
            `}
        >
            <ProgressBar step={2} maxSteps={7} />
            <Grid
                container
                css={css`
                    margin-bottom: 6px;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid item direction="column">
                    <Header1
                        css={css`
                            color: ${local.greys[4]};
                        `}
                    >
                        {itemType.label || '--'}
                    </Header1>
                </Grid>
                <Grid item direction="column">
                    <IconButton
                        css={css`
                            padding: 4px;
                        `}
                        onClick={callbacks.showInfo}
                    >
                        <HelpIcon
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item direction="row">
                <SubHeader1>
                    <i>Enter details below.</i>
                </SubHeader1>
            </Grid>

            {section.itemsByOrderId.map((item, idx) => {
                return (
                    <Item
                        key={`${item.item_type_details}-${idx}`}
                        idx={idx}
                        item={item}
                        itemType={itemType}
                        defaultAssembly={defaultAssembly}
                        callbacks={{
                            modifyItem: (mod) => {
                                setSection((prev) => {
                                    const clone = [...prev.itemsByOrderId];
                                    const modified = { ...clone[idx], ...mod };
                                    clone.splice(idx, 1, modified);

                                    return {
                                        ...prev,
                                        itemsByOrderId: clone,
                                    };
                                });
                            },
                        }}
                    />
                );
            })}

            <Grid container direction="row" justifyContent="space-between">
                <PrimaryButton
                    css={css`
                        padding: 12px 24px;
                    `}
                    onClick={() =>
                        setSection((prev) => {
                            return {
                                ...prev,
                                itemsByOrderId: [...prev.itemsByOrderId, { ...DEFAULT_BEDDING }],
                            };
                        })
                    }
                >
                    Add Piece
                </PrimaryButton>
                <SecondaryButton
                    css={css`
                        padding: 12px 24px;
                    `}
                    onClick={() =>
                        setSection((prev) => {
                            const clone = [...prev.itemsByOrderId];
                            clone.pop();

                            return {
                                ...prev,
                                itemsByOrderId: clone,
                            };
                        })
                    }
                >
                    Remove Last
                </SecondaryButton>
            </Grid>

            <Footer
                state={state}
                isFirst={state.isFirstStage}
                isLast={state.isLastStage}
                hasErrors={hasError}
                callbacks={{ advance: () => callbacks.advance(section), revert: callbacks.revert }}
            />
        </Grid>
    );
}

export default Dropoff;
