import { gql } from '@apollo/client';

import { RESOURCE_OVERRIDE_MAPPING_FIELDS } from '@/graphql/queries/resource_override_mappings';
import { RESOURCE_RULE_MAPPING_FIELDS } from '@/graphql/queries/resource_rule_mappings';
import { RESOURCE_FIELDS } from '@/graphql/queries/resources';
import { SUBREGION_FIELDS } from '@/graphql/queries/subregions';
import { RESOURCE_REGION_MAPPING_FIELDS } from '@/graphql/queries/resource_region_mappings';
import { RESOURCE_SHIPPING_PARTNER_MAPPING_FIELDS } from '@/graphql/fragments/resource_shipping_partner_mappings';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const SET_DELIVERY_DATE = gql`
    ${ORDER_FIELDS}

    mutation SetOrderDeliveryDate($updates: [orders_updates!]!) {
        updated: update_orders_many(updates: $updates) {
            returning {
                ...OrderFields
            }
        }
    }
`;

export const GET_SHIPPERS = gql`
    ${CLIENT_FIELDS}

    query GetShippers($where: orders_bool_exp!) {
        results: orders(distinct_on: [shipper_id], where: $where) {
            order_id
            shipper_id
            order_shipper {
                ...ClientFields
            }
        }
    }
`;

export const GET_ORDERS = gql`
    ${ORDER_FIELDS}
    ${CLIENT_FIELDS}
    ${RESOURCE_FIELDS}
    ${RESOURCE_OVERRIDE_MAPPING_FIELDS}
    ${RESOURCE_REGION_MAPPING_FIELDS}
    ${RESOURCE_RULE_MAPPING_FIELDS}
    ${RESOURCE_SHIPPING_PARTNER_MAPPING_FIELDS}
    ${SUBREGION_FIELDS}

    query GetOrders($where: orders_bool_exp!, $cutoff: timestamptz) {
        results: orders(where: $where, order_by: [{ created_at: desc }], limit: 100) {
            ...OrderFields

            messages(where: { link_type: { _eq: "customer_predelivery" } }, order_by: [{ created_at: desc }]) {
                created_at
                link_type
                message_id
                scheduled_timestamp
                status
            }

            order_shipper {
                ...ClientFields

                available_resources {
                    ...ResourceFields
                    zips @client

                    rules(where: { _or: [{ rule_end: { _gte: $cutoff } }, { rule_end: { _is_null: true } }] }) {
                        ...ResourceRuleMappingFields
                    }

                    overrides(
                        where: { _or: [{ override_end: { _gte: $cutoff } }, { override_end: { _is_null: true } }] }
                    ) {
                        ...ResourceOverrideMappingFields
                    }

                    subregions {
                        ...ResourceRegionMappingFields

                        subregion {
                            subregion_id
                            client_id
                            name
                            type
                            zips {
                                mapping_id
                                zip
                            }
                        }
                    }

                    shipping_partners(where: { enabled: { _eq: true } }) {
                        ...ResourceShippingPartnerMappingFields
                    }
                }
            }

            order_carrier {
                ...ClientFields

                available_resources {
                    ...ResourceFields
                    zips @client

                    rules(where: { _or: [{ rule_end: { _gte: $cutoff } }, { rule_end: { _is_null: true } }] }) {
                        ...ResourceRuleMappingFields
                    }

                    overrides(
                        where: { _or: [{ override_end: { _gte: $cutoff } }, { override_end: { _is_null: true } }] }
                    ) {
                        ...ResourceOverrideMappingFields
                    }

                    subregions {
                        ...ResourceRegionMappingFields

                        subregion {
                            subregion_id
                            client_id
                            name
                            type
                            zips {
                                mapping_id
                                zip
                            }
                        }
                    }

                    shipping_partners(where: { enabled: { _eq: true } }) {
                        ...ResourceShippingPartnerMappingFields
                    }
                }
            }
        }
    }
`;
